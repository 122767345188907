import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    position: 'relative',
    padding: 24,
    height: 132,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
    marginBottom: 16,
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    position: 'absolute',
    right: 24,
    top: 24,
    '& svg': {
      width: 30,
    },
  },
  content: {
    height: 'calc(100vh - 132px)',
  },
});
