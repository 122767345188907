import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  heading: {
    '& div span': {
      textTransform: 'capitalize',
    },
  },
  btn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    '& svg': {
      width: 18,
      marginRight: 6,
    },
  },
});
