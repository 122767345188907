/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import HelpIcon from '@mui/icons-material/Help';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { btnSize } from '@confidant-health/lib/ui/atoms/button/Button.types';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { EVALUATION_QUESTION_TYPES } from 'constants/CommonConstants';

import { ContentfulClient } from 'config/contentful-config';

import { noteTimeStampForEvaluationContentBlock } from 'services/trembit/trembit.service';
import { getAuth } from 'redux/modules/auth/selectors';
import EvaluationNervous from './components/evaluation-nervous';
import EvaluationPrevHospitalization from './components/evaluation-prev-hospitalization';
// import EvaluationAnotherQuestion from './components/evaluation-another-question';
import EvaluationEducation from './components/evaluation-education';
import { EvaluationEducationDetail } from './components/evaluation-education/EvaluationEducationDetail';
import EvaluationAddText from './components/evaluation-add-text';
import { useStyles } from './EvaluationSectionDetail.styles';
import { EvaluationRatingScale } from './components/evaluation-rating-scale/EvaluationRatingScale';
import EvaluationTextInput from './components/evaluation-text-input';
import { Prompt } from './components/prompt-accordion/Prompt';
import { RelatedElementsSection } from './components/related-elements/RelatedElementsSection';
import AlfieValidationMultiSelect from './components/alfie-validation';
import { provideAIFeedback } from '../../../../../services/conversation/conversation.service';
import { conversationActionCreators } from '../../../../../redux/modules/conversation';
import EvaluationExercise from './components/evaluation-exercise';

type Props = {
  isDisablePrevious: boolean;
  telesessionId?: string;
  evaluationId: string;
  onClickPrevious: () => void;
  onClickNext: () => void;
  selectedSection: any; // IEvaluationSectionItem;
  selectMultiSelect: boolean;
  alfieValidation?: boolean;
  provider?: any;
  appointmentId?: string;
  isLastSection?: boolean;
  skipNonRequiredCB?: (cbId: string) => void;
  onSelectSection?: (question: any) => void;
  analysisInProgress?: boolean;
};

const EvaluationSectionDetail: FC<Props> = ({
  isDisablePrevious,
  onClickNext,
  onClickPrevious,
  selectedSection,
  telesessionId,
  evaluationId,
  selectMultiSelect,
  alfieValidation,
  provider,
  appointmentId,
  isLastSection,
  onSelectSection,
  skipNonRequiredCB,
  analysisInProgress,
}) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);
  const bodyRef = useRef(null);
  const [showEducation, setShowEducation] = useState(false);
  const [showAddText, setShowAddText] = useState(false);
  const [showAddPersonalNotes, setShowAddPersonalNotes] = useState(false);
  const [educationalContent, setEducationalContent] = useState<ReactNode>();
  const [educationalContentTextOnly, setEducationalContentTextOnly] = useState('');
  const [trainingAI, setTrainingAI] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [amendingNote, setAmendingNote] = useState(false);
  const [title, setTitle] = useState('');
  const [multiSelectChange, setMultiSelectChange] = useState(false);
  const [duration, setDuration] = useState(null);
  const [wrongAlfieChoices, setWrongAlfieChoices] = useState([]);
  const [amendedNote, setAmendedNote] = useState('');
  const [reviewerResponses, setReviewerResponses] = useState([]);
  const dispatch = useDispatch();
  /**
   * @Name toggleShowEducation
   * @description This method is used to toggle between showing Education Component .
   */
  const toggleShowEducation = () => {
    setShowEducation(!showEducation);
  };

  const addCorrections = correction => {
    setWrongAlfieChoices([...wrongAlfieChoices, correction]);
  };
  /**
   * @Name toggleShowAddText
   * @description This method is used to toggle Add Text component .
   */
  const toggleShowAddText = () => {
    setShowAddText(!showAddText);
  };
  /**
   * @Name toggleShowAddPersonalNotes
   * @description This method is used to toggle Add Personal Notes input field.
   */
  const toggleShowAddPersonalNotes = () => {
    setShowAddPersonalNotes(true);
    toggleShowAddText();
  };

  const selectChoiceByReviewer = (choice: string, checked: boolean) => {
    if (checked) {
      setReviewerResponses([...reviewerResponses, choice]);
    } else {
      setReviewerResponses(reviewerResponses.filter(ans => ans !== choice));
    }
  };
  const forceRender = () => {
    setMultiSelectChange(!multiSelectChange);
  };
  /**
   * @Name renderDetail
   * @description This method is used to render Question's UI according to type.
   */
  const renderDetail = () => {
    switch (selectedSection.cbType) {
      case EVALUATION_QUESTION_TYPES.SINGLE_SELECT:
        if (alfieValidation) {
          return (
            <AlfieValidationMultiSelect
              selectedSection={selectedSection}
              provider={provider}
              trainingAI={trainingAI}
              addCorrection={addCorrections}
              amendingNote={amendingNote}
              wrongAlfieChoices={wrongAlfieChoices}
              selectChoiceByReviewer={selectChoiceByReviewer}
              reviewerResponses={reviewerResponses}
            />
          );
        }
        return (
          <EvaluationPrevHospitalization
            selectedSection={selectedSection}
            forceRender={forceRender}
            adminFlow
          />
        );
      case EVALUATION_QUESTION_TYPES.MULTI_SELECT:
        if (alfieValidation) {
          return (
            <AlfieValidationMultiSelect
              selectedSection={selectedSection}
              provider={provider}
              trainingAI={trainingAI}
              addCorrection={addCorrections}
              wrongAlfieChoices={wrongAlfieChoices}
              amendingNote={amendingNote}
              selectChoiceByReviewer={selectChoiceByReviewer}
              reviewerResponses={reviewerResponses}
            />
          );
        }
        return (
          <EvaluationNervous
            selectedSection={selectedSection}
            multiSelectChange={multiSelectChange}
            multiSelect={selectMultiSelect}
            forceRender={forceRender}
          />
        );
      case EVALUATION_QUESTION_TYPES.EXERCISE:
        return <EvaluationExercise selectedSection={selectedSection?.exerciseContent} />;
      case EVALUATION_QUESTION_TYPES.EDUCATION:
        return (
          <EvaluationEducation
            selectedSection={{ ...selectedSection, duration, title }}
            onClickDetail={toggleShowEducation}
          />
        );
      case EVALUATION_QUESTION_TYPES.RATING_SCALE:
        return <EvaluationRatingScale selectedSection={selectedSection} forceRender={forceRender} />;
      case EVALUATION_QUESTION_TYPES.TEXT_INPUT:
        if (alfieValidation) {
          return (
            <AlfieValidationMultiSelect
              selectedSection={selectedSection}
              provider={provider}
              trainingAI={trainingAI}
              addCorrection={addCorrections}
              wrongAlfieChoices={wrongAlfieChoices}
              amendingNote={amendingNote}
              selectChoiceByReviewer={selectChoiceByReviewer}
              reviewerResponses={reviewerResponses}
            />
          );
        }
        return <EvaluationTextInput selectedSection={selectedSection} forceRender={forceRender} />;
      default:
        // return <EvaluationAnotherQuestion selectedSection={selectedSection} />;
        return <></>;
    }
  };

  /**
   * @Name onClickNextEvaluationResponseHandler
   * @description This method is used to move to next question by adding isDone flag .
   */
  const onClickNextEvaluationResponseHandler = () => {
    if (selectedSection.evaluationResponses?.length > 0) {
      selectedSection.isDone = true;
      setMultiSelectChange(!multiSelectChange);
    } else if (!selectedSection.required && !alfieValidation) {
      skipNonRequiredCB(selectedSection?.cbId);
    }
    if (telesessionId && evaluationId && selectedSection?.cbId) {
      noteTimeStampForEvaluationContentBlock(
        {
          trembitSessionId: telesessionId,
          cbId: selectedSection?.cbId,
          evaluationId,
          event: 'end',
        },
        {
          capturedAt: new Date().getTime(),
        },
      );
    }
    onClickNext();
  };

  const handleKeyPress = (event: any) => {
    if ((event?.charCode === 13 || event?.keyCode === 13) && event?.ctrlKey) {
      onClickNextEvaluationResponseHandler();
    }
  };
  const onClickPreviousHandler = () => {
    setMultiSelectChange(!multiSelectChange);
    if (telesessionId && evaluationId && selectedSection?.cbId) {
      noteTimeStampForEvaluationContentBlock(
        {
          trembitSessionId: telesessionId,
          cbId: selectedSection?.cbId,
          evaluationId,
          event: 'end',
        },
        {
          capturedAt: new Date().getTime(),
        },
      );
    }
    onClickPrevious();
  };

  const getEducationalContent = () => {
    ContentfulClient.getEntry(selectedSection?.educationContentSlug)
      .then(educationalContentRecord => {
        setTitle(educationalContentRecord?.fields?.title);
        setDuration(educationalContentRecord?.fields?.contentLengthduration);
        const formatedData = documentToReactComponents(educationalContentRecord?.fields?.content);
        setEducationalContent(formatedData);
      })
      .catch(err => {
        console.log('Error', err);
      });
  };
  const handleScroll = () => {
    const eRef = bodyRef?.current;
    const scrollPercentage = (eRef.scrollTop / (eRef.scrollHeight - eRef.clientHeight)) * 100;
    if (scrollPercentage >= 80) {
      setIsRead(true);
      selectedSection.evaluationResponses = [true];
    }
  };
  useEffect(() => {
    if (selectedSection?.educationContentSlug) {
      getEducationalContent();
    }
  }, [selectedSection?.educationContentSlug]);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
      setIsRead(false);
    }
  }, [selectedSection]);

  useEffect(() => {
    const eRef = bodyRef?.current;
    const isScrollEnabled = eRef?.scrollHeight > eRef?.clientHeight;
    if (isScrollEnabled === false && selectedSection?.cbType === 'exercise') {
      selectedSection.evaluationResponses = [true];
    }
    if (eRef && selectedSection?.cbType === 'exercise' && isScrollEnabled) {
      eRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (eRef && isScrollEnabled && selectedSection?.cbType === 'exercise') {
        eRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [bodyRef, selectedSection.cbType]);

  const cancelFeedback = () => {
    setTrainingAI(false);
    setAmendingNote(false);
    setWrongAlfieChoices([]);
    if (selectedSection) {
      if (selectedSection.amendNote) {
        setAmendedNote(selectedSection.amendNote);
      } else {
        setAmendedNote('');
      }
      if (selectedSection.reviewerResponses) {
        setReviewerResponses([...selectedSection.reviewerResponses]);
      } else if (selectedSection.evaluationResponses) {
        setReviewerResponses([...selectedSection.evaluationResponses]);
      } else {
        setReviewerResponses([]);
      }
      if (selectedSection.wrongAlfieChoices) {
        setWrongAlfieChoices([...selectedSection.wrongAlfieChoices]);
      } else {
        setWrongAlfieChoices([]);
      }
    }
  };

  useEffect(() => {
    if (showEducation) {
      setShowEducation(false);
    }
    if (telesessionId && evaluationId && selectedSection?.cbId) {
      noteTimeStampForEvaluationContentBlock(
        {
          trembitSessionId: telesessionId,
          cbId: selectedSection?.cbId,
          evaluationId,
          event: 'start',
        },
        {
          capturedAt: new Date().getTime(),
        },
      );
    }
    if (selectedSection?.userResponse?.providerNarrative) {
      selectedSection.providerNarrative = selectedSection.userResponse.providerNarrative;
    }
    cancelFeedback();
    if (telesessionId && evaluationId && selectedSection?.cbId) {
      noteTimeStampForEvaluationContentBlock(
        {
          trembitSessionId: telesessionId,
          cbId: selectedSection?.cbId,
          evaluationId,
          event: 'start',
        },
        {
          capturedAt: new Date().getTime(),
        },
      );
    }
  }, [selectedSection]);

  if (showEducation) {
    return (
      <EvaluationEducationDetail
        title={title}
        duration={duration}
        educationalContent={educationalContent}
        educationalContentTextOnly={educationalContentTextOnly}
        onClose={toggleShowEducation}
        selectedSection={selectedSection}
      />
    );
  }

  if (showAddText) {
    return (
      <EvaluationAddText
        onClose={toggleShowAddText}
        narrative={selectedSection?.providerNarrative ?? ''}
        onUpdateNarrative={text => {
          selectedSection.providerNarrative = text;
          setMultiSelectChange(!multiSelectChange);
        }}
        isAddPersonalNote={showAddPersonalNotes}
      />
    );
  }

  const previousResponses =
    selectedSection?.cbProfileElementDetails?.length > 0 &&
    selectedSection?.cbProfileElementDetails
      .map(profileElementDetail => {
        return profileElementDetail.valuesList;
      })
      .flat();

  let multiSelectElementValues = [];
  if (
    selectedSection.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT ||
    selectedSection.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT
  ) {
    multiSelectElementValues =
      (previousResponses &&
        previousResponses.filter(response => response.contextId === previousResponses[0].contextId)) ||
      [];
  }

  return (
    <>
      <div
        onKeyDown={handleKeyPress}
        className={clsx(classes.container, {
          [classes.alfieContainer]: alfieValidation,
        })}
      >
        {(trainingAI || amendingNote) && (
          <Box className={classes.feedbackHeader}>
            <Button variant={btnType.OUTLINE} className={classes.cancelFeedbackBtn} onClick={cancelFeedback}>
              <Icons color={colors.primary500} glyph="arrow-left" />
            </Button>
            <Heading weight={fontWeight.BOLD} className={classes.title}>
              {amendingNote ? 'Amend Note' : 'Update AI Training'}
            </Heading>
          </Box>
        )}
        <Box className={classes.header}>
          {selectedSection?.isDct && (
            <Heading weight={fontWeight.SEMI_BOLD} className={classes.responderType}>
              Questions for the{' '}
              {selectedSection?.responderType !== ''
                ? selectedSection?.responderType?.toLowerCase()
                : 'member'}
            </Heading>
          )}
          <Box display="flex" flexDirection="column" gap={1}>
            <Heading weight={fontWeight.BOLD} className={classes.title}>
              {selectedSection?.text ?? selectedSection?.title}
            </Heading>
            <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
              {selectedSection?.subtitle ?? selectedSection?.description}
            </Text>
          </Box>
        </Box>
        <Box
          className={`${classes.body} ${selectedSection.cbType === 'exercise' ? classes.exBody : ''}`}
          ref={bodyRef}
        >
          {selectedSection?.prompt && <Prompt label="Prompt" text={selectedSection?.prompt} />}
          {selectedSection?.cbProfileElementDetails?.length > 0 && (
            <RelatedElementsSection label="Related Profile Elements">
              {selectedSection?.cbProfileElementDetails?.map(profileElementDetail => {
                return (
                  <Text
                    weight={fontWeight.SEMI_BOLD}
                    className={classes.infoKey}
                    key={profileElementDetail.key}
                  >
                    {profileElementDetail.key}
                    <br />
                    {profileElementDetail.valuesList?.length === 0 ? (
                      <span className={classes.infoValueNoData}>No Data</span>
                    ) : (
                      profileElementDetail.valuesList?.map((profileElementValue, index) => (
                        <span className={classes.infoValue} key={profileElementValue.value}>
                          {`${profileElementValue.value}${
                            index < profileElementDetail.valuesList.length - 1 ? ', ' : ''
                          }`}
                        </span>
                      ))
                    )}
                  </Text>
                );
              })}
            </RelatedElementsSection>
            // <Box className={classes.info}>
            //   <Heading weight={fontWeight.BOLD} className={classes.infoTitle}>
            //     Related Profile Elements
            //   </Heading>
            //
            // </Box>
          )}
          {renderDetail()}
          {selectedSection?.isDct && (
            <Box className={classes.questionsBox}>
              <Text weight={fontWeight.SEMI_BOLD} className={classes.questionsHeading}>
                Questions in this DCT:
              </Text>
              <Box className={classes.questionsList}>
                {selectedSection?.items.map(item => (
                  <Box key={item.cbId}>
                    {alfieValidation ? (
                      <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                        {item.matchStatus === 'no-match' ? (
                          <CancelIcon className={classes.noMatchIcon} />
                        ) : item.matchStatus === 'full-match' ? (
                          <CheckCircleIcon className={classes.fullMatchIcon} />
                        ) : item.matchStatus === 'resolved' ? (
                          <BorderColorRoundedIcon className={classes.resolvedIcon} />
                        ) : (
                          <HelpIcon className={classes.partialMatchIcon} />
                        )}
                        <Text
                          weight={fontWeight.MEDIUM}
                          className={clsx(classes.questionsText, classes.pointer)}
                          onClick={() => {
                            onSelectSection(item);
                          }}
                        >
                          {item.text}
                        </Text>
                      </Box>
                    ) : (
                      <Text weight={fontWeight.MEDIUM} className={classes.questionsText}>
                        {`\u2022 ${item.text}`}
                      </Text>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {!alfieValidation && !selectedSection.isDct && (
            <Box className={classes.addPersonalNote}>
              <IconButton icon="plus" onClick={toggleShowAddPersonalNotes}>
                Add narrative
              </IconButton>
            </Box>
          )}
          {alfieValidation && amendingNote && (
            <Box>
              <Heading weight={fontWeight.BOLD} className={classes.additionalNotesTitle}>
                Additional Notes
              </Heading>
              <Textarea
                value={amendedNote}
                name="text"
                placeholder="Enter your notes"
                onChange={({ target }) => {
                  setAmendedNote(target.value);
                }}
                minRows={3}
                maxRows={5}
              />
            </Box>
          )}
        </Box>
        {!alfieValidation &&
          previousResponses &&
          previousResponses.length > 0 &&
          (selectedSection.cbType === EVALUATION_QUESTION_TYPES.TEXT_INPUT ||
            selectedSection.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT ||
            selectedSection.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT ||
            selectedSection.cbType === EVALUATION_QUESTION_TYPES.RATING_SCALE) && (
            <Box className={classes.previousResponseBox}>
              <Text weight={fontWeight.SEMI_BOLD} className={classes.prevText}>
                Previous response
              </Text>
              <Box className={classes.prevResponseItem}>
                <Text weight={fontWeight.MEDIUM} className={classes.prevResponseValue}>
                  {selectedSection.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT
                    ? multiSelectElementValues.map(response => response.value).join(', ')
                    : previousResponses[0].value}
                </Text>
                <Button
                  variant={btnType.OUTLINE}
                  size={btnSize.SMALL}
                  onClick={() => {
                    if (
                      selectedSection.cbType === EVALUATION_QUESTION_TYPES.TEXT_INPUT ||
                      selectedSection.cbType === EVALUATION_QUESTION_TYPES.RATING_SCALE
                    ) {
                      selectedSection.evaluationResponses = [previousResponses[0].value];
                      setMultiSelectChange(!multiSelectChange);
                    } else if (
                      selectedSection.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT ||
                      selectedSection.cbType === EVALUATION_QUESTION_TYPES.MULTI_SELECT
                    ) {
                      const choices = selectedSection.choices
                        .filter(ch => {
                          return multiSelectElementValues.find(
                            el => el.value?.toLowerCase() === ch.value?.toLowerCase(),
                          );
                        })
                        .map(ch => ch.choice);
                      if (choices.length > 0) {
                        if (selectedSection.cbType === EVALUATION_QUESTION_TYPES.SINGLE_SELECT) {
                          selectedSection.evaluationResponses = choices;
                        } else {
                          selectedSection.evaluationResponses = choices;
                        }
                        setMultiSelectChange(!multiSelectChange);
                      }
                    }
                  }}
                >
                  <Text weight={fontWeight.BOLD} className={classes.useText}>
                    Use
                  </Text>
                </Button>
              </Box>
            </Box>
          )}
        {alfieValidation &&
          !analysisInProgress &&
          (selectedSection.matchStatus === 'partial-match' ||
            selectedSection.matchStatus === 'no-match' ||
            selectedSection.matchStatus === 'resolved') &&
          !amendingNote &&
          !trainingAI &&
          isAdmin && (
            <Box className={classes.aiFeedbackBox}>
              <Button
                variant={btnType.OUTLINE}
                onClick={() => {
                  setTrainingAI(true);
                }}
                className={classes.aiBtn}
              >
                Update AI Training
              </Button>
              <Button
                variant={btnType.OUTLINE}
                onClick={() => {
                  setAmendingNote(true);
                }}
                className={classes.aiBtn}
              >
                Amend Note
              </Button>
            </Box>
          )}
        {amendingNote ? (
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={cancelFeedback}>
              Cancel
            </Button>
            <Button
              variant={iconBtnType.PRIMARY}
              onClick={() => {
                const request = {
                  amendNote: amendedNote,
                  reviewerResponses,
                  cbId: selectedSection.cbId,
                  feedbackType: 'RATING',
                };
                provideAIFeedback(request, appointmentId)
                  .then(() => {
                    console.log('Feedback provided for AI validation');
                    setAmendingNote(false);
                    dispatch(
                      conversationActionCreators.fetchEvaluationContext({ appointmentId, evaluationId }),
                    );
                  })
                  .catch(err => {
                    console.warn('Error providing feedback to AI validation', err);
                  });
              }}
              className={classes.nextBtn}
            >
              Amend Note
            </Button>
          </Box>
        ) : trainingAI ? (
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={cancelFeedback}>
              Cancel
            </Button>
            <IconButton
              variant={iconBtnType.PRIMARY}
              onClick={() => {
                const choices = selectedSection?.choices?.map(choice => choice.choice) || [];
                const request = {
                  corrections: [
                    ...selectedSection.evaluationResponses,
                    ...selectedSection.alfieResponses.filter(ans => !wrongAlfieChoices.includes(ans)),
                  ]
                    .filter(ans => {
                      if (selectedSection.cbType === 'text-input') {
                        return true;
                      }
                      return choices.includes(ans);
                    })
                    .filter((x, i, a) => a.indexOf(x) === i),
                  wrongAlfieChoices,
                  cbId: selectedSection.cbId,
                  feedbackType: 'CORRECTION',
                };
                provideAIFeedback(request, appointmentId)
                  .then(() => {
                    console.log('Feedback provided for AI validation');
                    setTrainingAI(false);
                    dispatch(
                      conversationActionCreators.fetchEvaluationContext({ appointmentId, evaluationId }),
                    );
                  })
                  .catch(err => {
                    console.warn('Error providing feedback to AI validation', err);
                  });
              }}
              className={classes.nextBtn}
            >
              Update AI Training
            </IconButton>
          </Box>
        ) : (
          <Box className={classes.footer}>
            <IconButton
              icon="arrow-left"
              disabled={isDisablePrevious}
              onClick={onClickPreviousHandler}
              className={classes.previousBtn}
            >
              Previous question
            </IconButton>
            <IconButton
              variant={iconBtnType.PRIMARY}
              onClick={onClickNextEvaluationResponseHandler}
              className={classes.nextBtn}
              onKeyPress={handleKeyPress}
              disabled={alfieValidation && isLastSection && !isAdmin}
            >
              {selectedSection?.isDct && !alfieValidation
                ? 'Start DCT'
                : alfieValidation && isLastSection
                ? 'Submit'
                : selectedSection.required || selectedSection.evaluationResponses?.length > 0 || isRead
                ? 'Next question'
                : 'Skip question'}
            </IconButton>
          </Box>
        )}
      </div>
    </>
  );
};

export { EvaluationSectionDetail };
