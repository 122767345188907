// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: colors.white,
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
  },
  breadcrumbs: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  topHeading: {},
  headWrap: {},
  headLeft: {},
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
    fontSize: 40,
  },
  heading2: {
    fontSize: 32,
  },
  headBadge: {
    backgroundColor: colors.success500,
    color: colors.white,
    fontSize: 14,
    borderRadius: 100,
    marginLeft: 8,
  },
  headBadge2: {
    backgroundColor: colors.success500,
    color: colors.white,
    fontSize: 14,
    borderRadius: 100,
    marginLeft: '16px',
  },
  purpleBadge: {
    backgroundColor: '#e8e7f8',
    color: '#6938ef',
    fontSize: 14,
    borderRadius: 100,
    marginLeft: -2,
  },
  timeText: {
    marginLeft: 4,
  },
  badge: {
    border: 0,
    height: 24,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    whiteSpace: 'nowrap',
  },
  badge2: {
    borderRadius: 100,
    height: 18,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  feedbackCards: {
    marginTop: 48,
    display: 'flex',
    gap: 16,
  },
  cards: {
    marginTop: 56,
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  colLabel: {
    color: colors.neutral500,
  },
  marginTop8: {
    marginTop: 8,
  },
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    fontSize: 14,
    marginLeft: '1rem',
    textWrap: 'nowrap',
  },
  cancelledStatusText: {
    whiteSpace: 'nowrap',
  },
  cptCode: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'left',
  },
  p0: {
    padding: 0,
  },
  noPointer: {
    cursor: 'default',
  },
});
