import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  addBtn: {
    color: '#fff',
    fontSize: 16,
    whiteSpace: 'nowrap',
    height: 64,
    padding: '20px 24px',
    '& svg': {
      width: 24,
      '& path': {
        fill: '#fff',
      },
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    maxWidth: '196px',
  },
  table: {
    backgroundColor: colors.white,
    overflowX: 'hidden',
  },
  id: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  name: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  fund: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral700,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  status: {
    height: 22,
    width: 86,
    padding: 0,
    borderRadius: 20,
    fontSize: 12,
    fontWeight: 600,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginRight: 6,
    display: 'inline-block',
    backgroundColor: colors.neutral600,
  },
  dotActive: {
    backgroundColor: colors.green600,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addMemberBtn: {
    height: 64,
    width: 178,
    color: '#FFF',
    padding: '20px 24px',
    borderRadius: 8,
  },
  memberColumn: {
    textDecoration: 'none',
  },
  engagement: {
    // background: '#2DA834',
    color: 'white',
    height: 18,
    padding: '0px 8px',
    display: 'block',
    textAlign: 'center',
    lineHeight: '16px',
    fontSize: 12,
    width: 'max-content',
    borderRadius: 10,
    fontWeight: '600',
  },
  engaged: {
    background: colors.success500,
  },
  outreachNeeded: {
    background: colors.warning600,
  },
  notEngaged: {
    background: colors.destructive500,
  },
  centerDiv: {
    display: 'block',
    textAlign: 'center',
    margin: '0 auto',
  },
});
