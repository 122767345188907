import { Box, ButtonGroup } from '@mui/material';
import cx from 'clsx';
import { useRef, useState, useEffect } from 'react';

import { iconBtnStyle, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { convertSecondToTime } from 'utils/CommonUtils';

import { useStyles } from './TopHeader.style';

export const tabsLeft = [
  {
    type: 'all',
    label: 'Default',
  },
  // {
  //   type: 'participants',
  //   label: 'Participants',
  // },
  {
    type: 'Chat',
    label: 'Chat',
  },
  {
    type: 'scratchpad',
    label: 'Scratchpad',
  },
  {
    type: 'schedule',
    label: 'Schedule',
  },
  {
    type: 'members',
    label: 'Members',
  },
];

export const tabsRight = [];

const TopHeader = ({ selectedSession = 'all', onChange, numberOfParticipants, onEndSession }) => {
  const classes = useStyles();
  const serviceCountdownRef = useRef(null);
  const [callDuration, setCallDuration] = useState(0);

  useEffect(() => {
    let duration = callDuration;
    setCallDuration(0);
    serviceCountdownRef.current = setInterval(() => {
      duration++;
      setCallDuration(duration);
    }, 1000);
    return () => clearInterval(serviceCountdownRef.current);
  }, []);

  return (
    <Box className={classes.filtersWrap}>
      <Box className={classes.dflex}>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          {tabsLeft.map(({ type, label }) => (
            <Button
              key={type}
              className={cx(classes.btnGroupIcon, {
                [classes.btnGroupIconActive]: type === selectedSession,
              })}
              onClick={() => onChange(type)}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          {tabsRight?.map(({ type, label, total, amount }) => (
            <Button key={type} className={cx(classes.btnGroupIcon)}>
              {label}
              <Badge variant={badgeType.OUTLINED} style={badgeStyle.MEDIUM} className={cx(classes.badge)}>
                {`${amount}/${total}`}
              </Badge>
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Box className={classes.dflex}>
        <Text className={classes.time}>
          {convertSecondToTime(callDuration)} / {numberOfParticipants} participants
        </Text>
        <IconButton
          onClick={onEndSession}
          style={iconBtnStyle.PRIMARY}
          className={cx(classes.btn, classes.endBtn)}
        >
          End session
        </IconButton>
      </Box>
    </Box>
  );
};

export { TopHeader };
