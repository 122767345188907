export const stepTitles = {
  0: 'Name & Filters',
  1: 'Data included',
};

export const eventTypeSelect = [{ label: 'DCT completed', value: 'DCT completed' }];

export const eventReferenceSelect = [{ label: 'Heroin use', value: 'Heroin use' }];

export const actionTypeSelect = [{ label: 'Assign education', value: 'Assign education' }];

export const actionEducationSelect = [{ label: 'Can you shoot Suboxone?', value: 'Can you shoot Suboxone?' }];

export const actionDelayUnitSelect = [
  { label: 'Days', value: 'Days' },
  { label: 'Weeks', value: 'Weeks' },
  { label: 'Months', value: 'Months' },
  { label: 'Years', value: 'Years' },
];

export const actionRepeatSelect = [
  { label: 'Do not repeat', value: 'Do not repeat' },
  { label: 'Once', value: 'Once' },
  { label: 'Every day', value: 'Every day' },
  { label: 'Every week', value: 'Every week' },
  { label: 'Every month', value: 'Every month' },
  { label: 'Every year', value: 'Every year' },
];

export const automationGoalSelect = [{ label: 'Schedule appointment', value: 'Schedule appointment' }];
export const automationGoalReferenceSelect = [{ label: 'With therapist', value: 'With therapist' }];
