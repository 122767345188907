import { Box } from '@mui/material';
import { useMemo } from 'react';

import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { useDomainType } from 'hooks/useDomainType';
import { PrioritiesCardLabel } from '../AlfieAnalysisPlayground.contstant';

import { useStyles } from '../AlfieAnalysisPlayground.styles';

type Props = {
  type: string;
  filteredPriorities: any;
  onSelectElement: (v: any) => void;
};

const PriorityView = ({ filteredPriorities, type, onSelectElement }: Props) => {
  const classes = useStyles();
  const [isLoading, domainData] = useDomainType({
    type: PrioritiesCardLabel[type],
  });

  const addedProiorites = useMemo(() => {
    return (
      filteredPriorities?.filter(item1 => {
        return domainData?.relatedElements?.some(item2 => item2?.domainElementId === item1.domainElementId);
      }) || []
    );
  }, [domainData, filteredPriorities]);

  const priorities = useMemo(() => {
    if (!addedProiorites || addedProiorites?.length === 0) return filteredPriorities;
    return (
      filteredPriorities.filter(item1 =>
        addedProiorites.some(item2 => item2?.domainElementId !== item1.domainElementId),
      ) || []
    );
  }, [addedProiorites, filteredPriorities]);

  if (isLoading) {
    return <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 3 }}>
      {addedProiorites?.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Text level={textLevel.XS} weight={fontWeight.EXTRA_BOLD} color={colors.neutral600}>
            {PrioritiesCardLabel[type]?.toUpperCase()} ADDED
          </Text>
          {addedProiorites.map((priority, index) => (
            <Box
              key={index}
              sx={{
                border: '1px solid #E5EBF0',
                borderRadius: 1,
                px: 2,
                py: 1.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
                {priority.name}
              </Text>
              <Icons glyph="delete-outlined-2" className={classes.removeIcon} />
            </Box>
          ))}
        </Box>
      )}
      {priorities?.length !== 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Text level={textLevel.XS} weight={fontWeight.EXTRA_BOLD} color={colors.neutral600}>
            {PrioritiesCardLabel[type]?.toUpperCase()} FOUND IN CONVERSATION
          </Text>
          {priorities.map((priority, index) => (
            <Box
              key={index}
              sx={{
                border: '1px solid #E5EBF0',
                borderRadius: 1,
                px: 2,
                py: 1.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
                {priority.name}
              </Text>
              <Icons glyph="plus" className={classes.plusIcon} onClick={() => onSelectElement(priority)} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PriorityView;
