import * as yup from 'yup';

const ProfileSchema = yup.object({
  name: yup.string().required('Name is required'),
  role: yup.string().required('Role is required'),
  specialities: yup
    .array()
    .of(
      yup.object({
        title: yup.string().required('Speciality is required'),
        value: yup.string().required('Speciality is required'),
      }),
    )
    .min(1, 'At least one speciality is required')
    .required('Specialities is required'),
});

export { ProfileSchema };
