import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { tz } from 'moment-timezone';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'redux/modules/auth/selectors';
import { Text, fontWeight, textLevel, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { AppointmentStatus } from '@confidant-health/lib/constants/CommonConstants';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Icons, IGlyphs } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { useStyles } from './AppointmentCancel.styles';

interface IProps {
  open: boolean;
  markNoShow?: boolean;
  onClose: () => void;
  appointment: IAppointment;
  updateAppointment: (item: IAppointment) => void;
  handleMarkNoShowClick?: () => void;
}

const AppointmentCancel: FC<IProps> = ({
  open,
  markNoShow,
  onClose,
  appointment,
  updateAppointment,
  handleMarkNoShowClick,
}) => {
  const classes = useStyles();
  const [reason, setReason] = React.useState('0');
  const [error, setError] = React.useState('');
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(getAuth);

  useEffect(() => {
    if (!open) {
      setReason('0');
      setError('');
    }
  }, [open]);

  const onCancel = () => {
    if (reason === '0') {
      setError('Please select a reason');
      return;
    }
    setError(null);
    dispatch(
      appointmentActionCreators.cancelAppointment({
        appointmentId: appointment.appointmentId,
        appointmentCancelParams: { reason },
        callback: (errorApi?: string) => {
          if (errorApi) {
            setError(errorApi);
          } else {
            updateAppointment({ ...appointment, status: AppointmentStatus.CANCELLED });
            onClose();
          }
        },
      }),
    );
  };

  const onChangeReason = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    if (target.value !== '0' && error) {
      setError('');
    }
    setReason(target.value);
  };

  const renderInfo = (icon: IGlyphs['glyph'], value) => (
    <Box className={classes.boxInfo}>
      <Icons color={colors.neutral600} glyph={icon} />
      <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
        {value}
      </Text>
    </Box>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={markNoShow ? 'Mark As No Show' : 'Cancel appointment'}
      variant={drawerType.FORM}
      footer={<div />}
      className={classes.drawer}
    >
      {!!appointment && (
        <>
          <Box flex={1}>
            <Box className={classes.boxTop}>
              <Box>
                <Text weight={fontWeight.BOLD} className={classes.title}>
                  {appointment.serviceName}
                </Text>
                <Text weight={fontWeight.MEDIUM} className={clsx(classes.text14, classes.duration)}>
                  {appointment?.serviceDuration} minutes session
                </Text>
              </Box>
              <Box style={{ height: 24 }} />

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <ProfileInfo
                  type={appointment?.providerRole ? 'provider' : 'member'}
                  photo={appointment?.participantImage}
                  nickName={appointment?.participantName}
                  fullName={
                    appointment?.providerName ||
                    `${appointment?.patientFirstName || ''} ${appointment.patientLastName || ''}`
                  }
                  role={appointment?.providerRole}
                  memberId={
                    appointment?.providerRole ? appointment?.provider?.userAccountId : appointment?.patientId
                  }
                  isProvider={!isAdmin}
                />
                <Box sx={{ paddingRight: 6 }}>
                  {renderInfo('event-outlined', dayjs(appointment.startTime).format('MMMM DD, YYYY'))}
                  {renderInfo('clock', `${dayjs(appointment.startTime).format('hh:mma')} ${tz.guess()}`)}
                </Box>
              </Box>
            </Box>
            {!markNoShow && (
              <Box className={classes.selectWrap}>
                <Text
                  className={clsx(classes.text14, classes.reason)}
                  level={textLevel.S}
                  weight={fontWeight.BOLD}
                >
                  Reason {!markNoShow && 'for Cancellation'}
                </Text>
                <Select
                  variant={selectType.SECONDARY}
                  value={reason}
                  className={classes.select}
                  onChange={onChangeReason}
                  options={[
                    { label: 'Select a reason', value: '0' },
                    { label: 'Member requested', value: 'Member requested' },
                    {
                      label: 'Member needs higher level of care',
                      value: 'Member needs higher level of care',
                    },
                    {
                      label: 'Member not responding to messages',
                      value: 'Member not responding to messages',
                    },
                    { label: 'Change in provider availability', value: 'Change in provider availability' },
                  ]}
                />
                <TextError errorMsg={error} />
              </Box>
            )}
          </Box>
          <Box className={classes.actions}>
            <IconButton
              variant={iconBtnType.PRIMARY}
              className={clsx(classes.btn)}
              onClick={markNoShow ? handleMarkNoShowClick : onCancel}
            >
              {markNoShow ? 'Mark as no show' : 'Cancel appointment'}
            </IconButton>
          </Box>
        </>
      )}
    </Drawer>
  );
};

export { AppointmentCancel };
