import { Box, useMediaQuery } from '@mui/material';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { theme } from '@confidant-health/lib/theme';
import { colors } from '@confidant-health/lib/colors';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import cx from 'clsx';

import { scheduleActionCreators } from 'redux/modules/schedule';
import { getAuth } from 'redux/modules/auth/selectors';
import { updateProviderSchedule } from 'services/schedule/schedule.service';
import { getUserTimeZone } from 'utils/dayjs';

import Heading from 'components/v2/Heading';
import { HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import SlotCard from './components/SlotCard';

import { useStyles } from './index.styles';

const BlockedSlots = ({ slots, providerSchedule, currentDate, setOpenUnblockWholeDay }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { meta } = useSelector(getAuth);

  return (
    <Box className={classes.cflex} gap={4} paddingRight="2px">
      <Box className={classes.sbflex2}>
        <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.SEMI_BOLD}>
          Blocked slots
        </Heading>
        {!isTablet && (
          <Box className={classes.rflex2} gap={4}>
            <Box sx={{ '& button': { gap: 1, color: colors.primary600 } }}>
              <IconButton onClick={() => setOpenUnblockWholeDay(true)}>
                <Typography {...typography.body.normal.medium.bold}>Unblock all</Typography>
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.cflex} gap={2}>
        {slots.map((slot, index) => {
          return (
            <SlotCard
              key={index}
              slot={slot}
              isBlocked
              actions={[
                {
                  variant: btnType.OUTLINE,
                  className: cx(classes.blockBtn, classes.xsmallBtn),
                  children: 'Unblock',
                  onClick: async () => {
                    await updateProviderSchedule(meta?.userId, {
                      availability: [
                        {
                          slots: [
                            {
                              start: dayjs(slot?.start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                              end: dayjs(slot?.end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            },
                          ],
                          active: true,
                          removed: false,
                        },
                      ],
                      timezone: providerSchedule?.timezone || getUserTimeZone(),
                    });
                    const endOfDay = currentDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                    const startOfDay = currentDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                    dispatch(
                      scheduleActionCreators.fetchDailySchedule({
                        userId: meta.userId,
                        queryParams: {
                          startTime: startOfDay,
                          endTime: endOfDay,
                        },
                      }),
                    );
                  },
                },
              ]}
            />
          );
        })}
        {isTablet && (
          <Box className={classes.rflex} gap={4} justifyContent="flex-end">
            <Box sx={{ '& button': { gap: 1, color: colors.primary600, paddingRight: 1 } }}>
              <IconButton onClick={() => setOpenUnblockWholeDay(true)}>
                <Typography {...typography.body.normal.medium.bold}>Unblock all</Typography>
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BlockedSlots;
