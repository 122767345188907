export const levels = [
  { label: 'Critical', value: 'CRITICAL' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
  { label: 'Resolved', value: 'RESOLVED' },
  { label: 'Misreported', value: 'MISREPORTED' },
  { label: 'Unrelated', value: 'UNRELATED' },
];

export const lifeEventLevels = [
  { label: 'Positive', value: 'POSITIVE' },
  { label: 'Negative', value: 'NEGATIVE' },
  { label: 'Neutral', value: 'NEUTRAL' },
];
