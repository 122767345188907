import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrayHelpers, FieldArray, FormikProvider, getIn, useFormik } from 'formik';

import { Box, InputAdornment } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';

import { stateActionCreators } from 'redux/modules/state/actions';
import { stateSelector } from 'redux/modules/state/selectors';
import { ICPTCode } from 'redux/modules/state/types';

// constant
import { FeeTypes, feeScheduleTypeOptions } from './PayerDetail.constants';

// schema
import { AddCPTSchema } from './PayerDetail.schema';

// styles
import { useStyles } from './PayerDetail.styles';

interface IAddCPTProps {
  isOpen: boolean;
  planName: string;
  payerName: string;
  selectedCPT: ICPTCode;
  onSubmit: (payload: ICPTCode) => void;
  onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AddCPTDrawer: FC<IAddCPTProps> = ({ isOpen, planName, payerName, selectedCPT, onSubmit, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cpts } = useSelector(stateSelector);

  const defaultValues = {
    code: cpts?.find((cpt: ICPTCode) => cpt.code === selectedCPT?.code),
    description: selectedCPT?.description || '',
    displayName: selectedCPT?.displayName,
    feeType: selectedCPT?.feeType || FeeTypes.SINGLE,
    durationWisePrice: selectedCPT?.durationWisePrice || [
      {
        duration: '',
        fee: '',
        providerFee: '',
        confidantFee: 0,
        contractedFee: 0,
      },
    ],
    fee: selectedCPT?.fee || '',
    confidantFee: selectedCPT?.confidantFee || '',
    providerFee: selectedCPT?.providerFee || '',
    contractedFee: selectedCPT?.contractedFee || '',
    isTimeRequired: selectedCPT?.isTimeRequired || false,
    requiredTimeLimits: selectedCPT?.requiredTimeLimits || { min: 0, max: 0 },
    requireAnotherCPTCode: selectedCPT?.requireAnotherCPTCode || false,
    requiredCPTCodes: selectedCPT?.requiredCPTCodes.map(item => ({ title: item, value: item })),
    requireDiagnosisToBill: selectedCPT?.requireDiagnosisToBill || false,
    requiredDiagnosis: selectedCPT?.requiredDiagnosis.map(item => ({ title: item, value: item })),
    isActive: selectedCPT?.isActive || false,
  };

  const formik = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema: AddCPTSchema,
    onSubmit: (payload: typeof defaultValues) => {
      const { code } = payload.code;
      delete payload.code;
      if (!payload.isTimeRequired) {
        delete payload.requiredTimeLimits;
      }
      const bodyRequest = {
        ...payload,
        code,
        // displayName: payload.displayName,
        durationWisePrice: payload.feeType === FeeTypes.SINGLE ? [] : payload.durationWisePrice,
        fee: payload.feeType === FeeTypes.TIME ? 0 : payload.fee,
        providerFee: payload.feeType === FeeTypes.TIME ? 0 : payload.providerFee,
        contractedFee: payload.feeType === FeeTypes.TIME ? 0 : payload.contractedFee,
        confidantFee:
          payload.feeType === FeeTypes.TIME ? 0 : Number(payload.fee) - Number(payload.providerFee),
        requiredCPTCodes: payload.requiredCPTCodes ? payload.requiredCPTCodes.map(item => item.value) : [],
        requiredDiagnosis: [],
      };

      onSubmit(bodyRequest);
    },
  });
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = formik;

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const setTouchedNested = (parent: string, child: string) => async () => {
    await rest.setTouched({ ...touched, [parent]: { ...touched[parent], [child]: true } });
  };

  const onChangeValues = async (_, newValue: ICPTCode) => {
    await setFieldValue('code', newValue);
    await setFieldValue('description', newValue.description);
  };

  const onChangeTag = async (_, newValue: string[]) => {
    await setFieldValue('requiredCPTCodes', newValue);
  };

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(stateActionCreators.fetchCPTs());
  }, []);

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderSingleFee = () => (
    <>
      <Box className={classes.section}>
        {renderLabel('Total fee')}
        <Input
          fullWidth
          type="number"
          name="fee"
          value={values.fee}
          placeholder=""
          onChange={handleChange}
          onBlur={setTouched('fee')}
          size={inputSize.M}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.startAdorment}>
                $
              </InputAdornment>
            ),
          }}
        />
        <TextError errorMsg={touched.fee ? errors.fee?.toString() : null} />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Provider fee')}
        <Input
          fullWidth
          type="number"
          name="providerFee"
          value={values.providerFee}
          placeholder=""
          onChange={handleChange}
          onBlur={setTouched('providerFee')}
          size={inputSize.M}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.startAdorment}>
                $
              </InputAdornment>
            ),
          }}
        />
        <TextError errorMsg={touched.providerFee ? errors.providerFee?.toString() : null} />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Contracted fee')}
        <Input
          fullWidth
          type="number"
          name="contractedFee"
          value={values?.contractedFee}
          placeholder=""
          onChange={handleChange}
          onBlur={setTouched('contractedFee')}
          size={inputSize.M}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={classes.startAdorment}>
                $
              </InputAdornment>
            ),
          }}
        />
        <TextError errorMsg={touched.providerFee ? errors?.contractedFee?.toString() : null} />
      </Box>
    </>
  );

  const renderTimeBased = (arrayHelpers: ArrayHelpers) =>
    values.durationWisePrice?.map((_, index: number) => (
      <Box key={`time-based-duration-${index}`} className={classes.timeBasedSection}>
        <Box className={classes.section}>
          {renderLabel('Duration')}
          <Box display="flex" gap={3}>
            <Input
              fullWidth
              type="number"
              name={`durationWisePrice.${index}.duration`}
              value={values.durationWisePrice[index]?.duration}
              placeholder=""
              onChange={handleChange}
              size={inputSize.M}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.endAdorment}>
                    min
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              icon="delete-outlined-2"
              className={classes.removeBtn}
              onClick={() => {
                if (values.durationWisePrice.length > 1) {
                  arrayHelpers.remove(index);
                }
              }}
            />
          </Box>
          <TextError
            errorMsg={
              touched.durationWisePrice?.length > 0 &&
              touched.durationWisePrice[index]?.duration &&
              errors.durationWisePrice?.length > 0
                ? getIn(errors.durationWisePrice[0], 'duration')
                : null
            }
          />
          <Box className={classes.section}>
            {renderLabel('Total fee')}
            <Input
              fullWidth
              type="number"
              name={`durationWisePrice.${index}.fee`}
              value={values.durationWisePrice[index]?.fee}
              placeholder=""
              onChange={handleChange}
              size={inputSize.M}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.startAdorment}>
                    $
                  </InputAdornment>
                ),
              }}
            />
            <TextError
              errorMsg={
                touched.durationWisePrice?.length > 0 &&
                touched.durationWisePrice[index]?.fee &&
                errors.durationWisePrice?.length > 0
                  ? getIn(errors.durationWisePrice[0], 'fee')
                  : null
              }
            />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Provider fee')}
            <Input
              fullWidth
              type="number"
              name={`durationWisePrice.${index}.providerFee`}
              value={values.durationWisePrice[index]?.providerFee}
              placeholder=""
              onChange={handleChange}
              size={inputSize.M}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.startAdorment}>
                    $
                  </InputAdornment>
                ),
              }}
            />
            <TextError
              errorMsg={
                touched.durationWisePrice?.length > 0 &&
                touched.durationWisePrice[index]?.providerFee &&
                errors.durationWisePrice?.length > 0
                  ? getIn(errors.durationWisePrice[0], 'providerFee')
                  : null
              }
            />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Contracted fee')}
            <Input
              fullWidth
              type="number"
              name={`durationWisePrice.${index}.contractedFee`}
              value={values?.durationWisePrice[index]?.contractedFee}
              placeholder=""
              onChange={handleChange}
              size={inputSize.M}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.startAdorment}>
                    $
                  </InputAdornment>
                ),
              }}
            />
            <TextError
              errorMsg={
                touched.durationWisePrice?.length > 0 &&
                touched.durationWisePrice[index]?.contractedFee &&
                errors.durationWisePrice?.length > 0
                  ? getIn(errors.durationWisePrice[0], 'contractedFee')
                  : null
              }
            />
          </Box>
        </Box>
        <IconButton
          icon="plus"
          onClick={() => arrayHelpers.push({ duration: '', fee: '', providerFee: '', confidantFee: 0 })}
          className={classes.addBtn}
        >
          Add Duration
        </IconButton>
      </Box>
    ));

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      variant={drawerType.NORMAL}
      subTitle={`${planName} - ${payerName}`}
      className={classes.drawer}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{selectedCPT ? 'Edit' : 'Add'} CPT Code</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <FormikProvider value={formik}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Box className={classes.formContent}>
              <Box className={classes.section}>
                {renderLabel('CPT Code')}
                <Input
                  fullWidth
                  name="code"
                  value={values.code}
                  options={cpts}
                  getOptionLabel={(option: ICPTCode) => `${option.displayName}`}
                  renderOption={(props: any, option: ICPTCode) => (
                    <Box key={option._id} {...props}>
                      {/* {option.code} - {option.description} */}
                      {option.displayName}
                    </Box>
                  )}
                  placeholder="Select a code"
                  size={inputSize.M}
                  variant={inputType.AUTOCOMPLETE}
                  onBlur={setTouched('code')}
                  onChange={onChangeValues}
                />
                <TextError errorMsg={touched.code ? errors.code?.toString() : null} />
              </Box>
              <Box className={classes.section}>
                {renderLabel('Description')}
                <Textarea
                  value={values.description}
                  name="description"
                  placeholder="Enter description"
                  onChange={handleChange}
                  minRows={2}
                  maxRows={3}
                  onBlur={setTouched('description')}
                />
                <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
              </Box>
              <Box className={classes.section}>
                {renderLabel('Fee Schedule Type')}
                <Select
                  value={values.feeType}
                  name="feeType"
                  variant={selectType.SECONDARY}
                  options={feeScheduleTypeOptions}
                  emptyText="Select type"
                  displayEmpty
                  onChange={handleChange}
                />
                <TextError errorMsg={touched.feeType ? errors.feeType?.toString() : null} />
              </Box>
              {values.feeType === FeeTypes.SINGLE ? (
                renderSingleFee()
              ) : (
                <FieldArray
                  name="durationWisePrice"
                  render={(arrayHelpers: ArrayHelpers) => renderTimeBased(arrayHelpers)}
                />
              )}
              <Box className={classes.section}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={values.isTimeRequired}
                  name="isTimeRequired"
                  onChange={() => {
                    void setFieldValue('isTimeRequired', !values.isTimeRequired);
                  }}
                  label="Required time"
                />
                {values.isTimeRequired && (
                  <Box display="flex" alignItems="center">
                    <Box className={classes.section}>
                      {renderLabel('Min')}
                      <Input
                        fullWidth
                        type="number"
                        name="requiredTimeLimits.min"
                        value={values.requiredTimeLimits?.min}
                        placeholder=""
                        size={inputSize.M}
                        onBlur={setTouchedNested('requiredTimeLimits', 'min')}
                        onChange={handleChange}
                      />
                      <TextError
                        errorMsg={
                          touched.requiredTimeLimits?.min ? errors.requiredTimeLimits?.min?.toString() : null
                        }
                      />
                    </Box>
                    <Box display="flex" alignItems="bottom" mx={2} mt="28px">
                      -
                    </Box>
                    <Box className={classes.section}>
                      {renderLabel('Max')}
                      <Input
                        fullWidth
                        type="number"
                        name="requiredTimeLimits.max"
                        value={values.requiredTimeLimits?.max}
                        placeholder=""
                        size={inputSize.M}
                        onBlur={setTouchedNested('requiredTimeLimits', 'max')}
                        onChange={handleChange}
                      />
                      <TextError
                        errorMsg={
                          touched.requiredTimeLimits?.max ? errors.requiredTimeLimits?.max?.toString() : null
                        }
                      />
                    </Box>
                    <Box display="flex" alignItems="bottom" mx={2} mt="28px">
                      minutes
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className={classes.section}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={values.requireAnotherCPTCode}
                  name="requireAnotherCPTCode"
                  onChange={() => {
                    void setFieldValue('requireAnotherCPTCode', !values.requireAnotherCPTCode);
                  }}
                  label="Require another code to bill"
                />
                {values.requireAnotherCPTCode && (
                  <>
                    <Input
                      freeSolo
                      fullWidth
                      name="requiredCPTCodes"
                      value={values.requiredCPTCodes}
                      options={cpts?.map(cpt => ({ title: cpt.code, value: cpt.code }))}
                      placeholder="Select codes"
                      size={inputSize.M}
                      variant={inputType.TAGS}
                      onBlur={setTouched('requiredCPTCodes')}
                      onChange={onChangeTag}
                    />
                    <TextError
                      errorMsg={touched.requiredCPTCodes ? errors.requiredCPTCodes?.toString() : null}
                    />
                  </>
                )}
              </Box>
              <Box className={classes.section}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={values.requireDiagnosisToBill}
                  name="requireDiagnosisToBill"
                  onChange={() => {
                    void setFieldValue('requireDiagnosisToBill', !values.requireDiagnosisToBill);
                  }}
                  label="Require diagnosis to bill"
                />
                {values.requireDiagnosisToBill && (
                  <>
                    <Input
                      fullWidth
                      name="requiredDiagnosis"
                      value={values.requiredDiagnosis}
                      options={cpts}
                      getOptionLabel={(option: ICPTCode) => `${option.code} - ${option.description}`}
                      renderOption={(props: any, option: ICPTCode) => (
                        <Box key={option._id} {...props}>
                          {option.code} - {option.description}
                        </Box>
                      )}
                      placeholder="Select codes"
                      size={inputSize.M}
                      variant={inputType.TAGS}
                      onBlur={setTouched('requiredDiagnosis')}
                      onChange={onChangeValues}
                    />
                    <TextError
                      errorMsg={touched.requiredDiagnosis ? errors.requiredDiagnosis?.toString() : null}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Box className={classes.footer}>
              <Button variant={btnType.TEXT} onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>Save</Button>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </Drawer>
  );
};

export { AddCPTDrawer };
