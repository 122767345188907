// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
    height: 'calc(100vh - 80px)',
    overflowY: 'auto',
    backgroundColor: colors.neutral50,
  },
  loader: {
    position: 'relative',
    top: '50%',
    left: '50%',
  },
  top: {
    width: '100%',
    marginBottom: 24,
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
});
