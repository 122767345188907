import dayjs from 'dayjs';

export type ColumnChartOpts = {
  color: string;
  categories: string[];
  series: { name: string; data: number[] }[];
  isDatetime?: boolean;
};

export const getChartOptions = (chart: ColumnChartOpts): any => {
  return {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: '',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 40,
      },
    },
    colors: [chart.color],
    xaxis: {
      categories: chart.categories?.map((item: string) =>
        item.length > 20 ? `${item.slice(0, 20)}...` : item,
      ),
      labels: {
        formatter: (val: string): string => {
          if (chart.isDatetime) {
            return dayjs(val).format('MMM D');
          }
          return val;
        },
        style: {
          fontFamily: 'Manrope',
          fontWeight: 600,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      style: {
        fontFamily: 'Manrope',
      },
      custom: ({ series, dataPointIndex }: Record<string, any>): string => {
        const value = series.reduce((tt, item) => +tt + +item[dataPointIndex], 0);
        return `
          <div class="chart-tooltip">
            <div class="date">${chart.categories[dataPointIndex]}</div>
            <div class="level">${value}</div>
          </div>
        `;
      },
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
  };
};
