import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Box } from '@mui/material';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { TextField } from '@confidant-health/lib/ui/atoms/text-field';
import { iconBtnPosition, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import history from 'utils/history';
import { getAuth } from 'redux/modules/auth/selectors';
import { useStyles } from './AppointmentEvaluationTopBar.styles';
import { EvaluationShareDrawer } from '../evaluation-share-drawer/EvaluationShareDrawer';
import { GLOBAL_DATE_FORMAT } from '../../../../constants/CommonConstants';

type Props = {
  appointment?: any;
  sessionDuration: number | null;
  analysisInProgress: boolean;
  onReprocess: () => void;
};
const AppointmentEvaluationTopBar: FC<Props> = ({
  appointment,
  sessionDuration,
  analysisInProgress,
  onReprocess,
}) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);
  const [openDrawer, setOpenDrawer] = useState(false);

  const onShare = () => {
    setOpenDrawer(true);
  };

  const member = useMemo(
    () => appointment?.participants?.filter(participant => !participant?.isPractitioner)?.[0],
    [appointment],
  );

  const provider = useMemo(
    () => appointment?.participants?.filter(participant => participant?.isPractitioner)?.[0],
    [appointment],
  );

  const renderInfo = (label: string, text: string, className = '') => {
    return (
      <Box className={className}>
        <TextField label={label} text={text || '-'} />
      </Box>
    );
  };

  const getFriendlyDuration = () => {
    if (!sessionDuration) {
      return '-';
    }
    const minutes = Math.floor(sessionDuration / 60);
    const seconds = Math.ceil(sessionDuration % 60);
    return seconds > 0 ? `${minutes} min ${seconds} sec` : `${minutes} min`;
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flex="1">
        <Box className={classes.leftPane}>
          <Button variant={btnType.OUTLINE} className={classes.btnBack} onClick={history.back}>
            <Icons color={colors.primary500} glyph="arrow-left" />
          </Button>
          {/* <Avatar src={member?.profilePicture} name={member?.name} size={48} variant={avatarType.CIRCLE} /> */}
          <Box className={classes.info}>
            <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.title}>
              {appointment?.serviceName}
            </Heading>
            <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
              {dayjs(appointment?.startTime).format('MMMM D, YYYY')} •{' '}
              {dayjs(appointment?.startTime).format('hh:mm A')} -{' '}
              {dayjs(appointment?.endTime).format('hh:mm A')}
            </Text>
          </Box>
        </Box>
        <Box className={classes.rightPane} gap={3}>
          <ProfileInfo
            type="member"
            photo={member?.profilePicture}
            nickName={`${member?.firstName || ''} ${member?.lastName || ''}`}
            fullName={member?.uuid}
            memberId={member?.participantId}
            isProvider={!isAdmin}
          />
          <Box className={classes.divider} />
          {/* eslint-disable jsx-a11y/aria-role */}
          <ProfileInfo
            type="provider"
            photo={provider?.profilePicture}
            role="Provider"
            fullName={provider?.name}
          />
          <Box className={classes.divider} />
          {renderInfo('Date of Birth', dayjs(member?.dateOfBirth).format(GLOBAL_DATE_FORMAT))}
          <Box className={classes.divider} />
          {renderInfo('Session Duration', getFriendlyDuration())}
          {isAdmin && (
            <>
              <Box className={classes.divider} />
              <IconButton
                disabled={analysisInProgress}
                onClick={onReprocess}
                variant={btnType.TEXT}
                icon="analyze"
                iconPosition={iconBtnPosition.RIGHT}
              >
                {analysisInProgress
                  ? `AI Analysis in Progress \u00A0\u00A0`
                  : `Rerun AI Analysis \u00A0\u00A0`}
              </IconButton>
              <Box className={classes.divider} />
              <IconButton
                onClick={onShare}
                variant={btnType.TEXT}
                icon="forward"
                className={classes.shareBtn}
              />
            </>
          )}
        </Box>
      </Box>
      <EvaluationShareDrawer openDrawer={openDrawer} onClose={() => setOpenDrawer(false)} />
    </Box>
  );
};

export { AppointmentEvaluationTopBar };
