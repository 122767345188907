import { useGoogleLogin } from '@react-oauth/google';
import { OAuth2Client } from 'google-auth-library';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import getConfig from 'config';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

import { useStyles } from './common.styles';

const getDecodedOAuthJwtGoogle = async token => {
  const CLIENT_ID_GOOGLE = getConfig.api.GC_WEBAPP_AUTH_ID;

  try {
    const client = new OAuth2Client(CLIENT_ID_GOOGLE);

    const ticket = await client.getTokenInfo(token);

    return ticket;
  } catch (error) {
    return { status: 500, data: error };
  }
};

const CustomGoogleButton = ({ onClick, className }: { onClick: any; className?: any }) => {
  const classes = useStyles();
  const login = useGoogleLogin({
    onSuccess: async response => {
      const userInfo = await getDecodedOAuthJwtGoogle(response?.access_token);
      onClick({
        accessToken: response?.access_token,
        profileObj: userInfo,
      });
    },
    onError: error => console.log(error),
    scope: 'openid email profile https://www.googleapis.com/auth/calendar',
  });
  return (
    <Button className={className ?? classes.connectButton} onClick={() => login()}>
      <Typography {...typography.body.s.bold}>Connect {className && 'calendar'}</Typography>
    </Button>
  );
};

export default CustomGoogleButton;
