// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  container: {
    display: 'flex',
    flexGrow: 1,
  },
  column: {
    borderRight: `1px solid ${colors.neutral100}`,
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
  },
  column1_1_1: {
    padding: '16px 24px',
    background: colors.neutral25,
    borderBottom: `1px solid ${colors.neutral100}`,
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
    height: 80,
  },
  column1_2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    '& svg': {
      width: 20,
      height: 18,
    },
  },
  column1_2_1: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    cursor: 'pointer',
    color: colors.neutral600,
    fontWeight: 600,
    fontSize: 14,
  },
  column1_2_2: {
    paddingTop: 9,
    color: '#94a2ad',
    fontSize: 14,
    fontWeight: 600,
  },
  column2_1_1: {
    padding: '16px 20px',
    background: colors.white,
    borderBottom: `1px solid ${colors.neutral100}`,
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
    display: 'flex',
    height: 80,
    '& .rhap_container': {
      padding: 1,
    },
  },
  column3_1: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    height: '80.7vh',
  },
  column3_2: {
    marginLeft: -16,
    boxShadow: '0 -4px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19)',
    textAlign: 'center',
    height: '13vh',
    width: 'calc(100% + 17px)',
  },
  buttonGrid: {
    paddingTop: '10%',
  },
  btn: {
    height: 50,
  },
  btn2: {
    marginTop: -12,
    height: 50,
  },
  threeDotBtn: {
    paddingLeft: 20,
  },
  searchBox: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 16,
  },
  title: {
    margin: 0,
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    color: colors.neutral600,
  },
  subtitle1: { fontSize: 12, color: colors.neutral600 },
  subtitle3: {
    fontSize: 14,
    color: '#414e58',
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: 14,
    color: '#e22c8a',
    fontWeight: 600,
  },
  title3: {
    paddingTop: 20,
    margin: 0,
    fontSize: 18,
  },
  colScroll: {
    flexGrow: 1,
  },
  colSubtitlesScroll: {
    flexGrow: 1,
    overflow: 'auto',
  },
  colQuestionScroll: {
    flexGrow: 1,
    overflow: 'auto',
    maxHeight: '46vh',
  },
});
