import { Box } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Heading, Text, fontWeight, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import dayjs from 'dayjs';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import clsx from 'clsx';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { getAuth } from 'redux/modules/auth/selectors';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';

import { IRecurringSlot } from './AddSchedule.types';
import { useStyles } from './AddSchedule.styles';

type Props = {
  slot: IRecurringSlot;
  showNotAvailableReason?: boolean;
  onClickReschedule?: () => void;
  isSmallView?: boolean;
};

const SlotComponent: FC<Props> = ({ slot, showNotAvailableReason = true, isSmallView = false }) => {
  const classes = useStyles();
  const {
    meta: { userId },
  } = useSelector(getAuth);

  const renderAppointmentCard = () => {
    return (
      <Box className={classes.availabilitySlotActionContainer}>
        <Box className={classes.card}>
          <Box className={classes.cardTopSection}>
            <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
              Another appointment scheduled
            </Heading>
            {/* <IconButton
                variant={iconBtnType.OUTLINE}
                className={clsx(classes.btn, classes.btnReschedule)}
                onClick={onClickReschedule}
              >
                Reschedule
              </IconButton> */}
          </Box>
          <Box
            className={classes.cardContentSection}
            gridTemplateColumns="2fr 3fr"
            rowGap="32px"
            display="grid"
          >
            <Text className={classes.text16}>{slot?.appointmentMetaData?.serviceName}</Text>
            <Box display="flex" alignItems="center" gap="12px">
              <Avatar
                variant={avatarType.CIRCLE}
                src={slot?.appointmentMetaData?.memberProfilePicture}
                name={slot?.appointmentMetaData?.memberFirstName}
                size={40}
              />
              <Box>
                <Text weight={fontWeight.SEMI_BOLD} className={classes.nickName} id="nickName">
                  {`${slot?.appointmentMetaData?.memberFirstName} ${slot?.appointmentMetaData?.memberLastName}`}
                </Text>
                {userId === slot?.appointmentMetaData?.providerId ? (
                  <Text
                    weight={fontWeight.MEDIUM}
                    className={classes.nickName}
                    id="nickName"
                    color={colors.neutral600}
                  >
                    {slot?.appointmentMetaData?.memberUuid}
                  </Text>
                ) : (
                  <Badge
                    style={badgeStyle.PRIMARY}
                    className={clsx(classes.badge, slot?.appointmentMetaData?.providerDesignation)}
                    variant={badgeType.FILLED}
                  >
                    {slot?.appointmentMetaData?.providerDesignation ?? 'Provider'}
                  </Badge>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  const renderBlockedCard = () => {
    return (
      <Box className={classes.availabilitySlotActionContainer}>
        <Box className={classes.card}>
          <Box className={classes.cardTopSection}>
            <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
              Time slot is blocked
            </Heading>
            {/* <IconButton
                variant={iconBtnType.OUTLINE}
                className={clsx(classes.btn, classes.btnReschedule)}
                onClick={onClickReschedule}
              >
                Reschedule
              </IconButton> */}
          </Box>
        </Box>
      </Box>
    );
  };
  const renderSlotCard = () => {
    if (showNotAvailableReason && !slot?.available) {
      return slot?.appointmentMetaData ? renderAppointmentCard() : renderBlockedCard();
    }
    return <></>;
  };
  return (
    <Box className={classes.availabilitySlotContainer}>
      <Box className={classes.availabilitySlot}>
        <Box className={classes.dateTimeContainer}>
          <Text className={classes.text14_2}>{dayjs(slot?.startTime).format('MMMM DD, YYYY')}</Text>
          <Text className={classes.text14}>{dayjs(slot?.startTime).format('hh:mm a')}</Text>
        </Box>
        <Box className={isSmallView ? classes.dashedLineSmall : classes.dashedLine} />
        <Box className={classes.dateTimeContainer}>
          {slot?.available ? (
            <Box display="flex" alignItems="flex-start" justifyContent="stretch" gap="6px">
              <Box>
                <Icons glyph="check-circle" color={colors.success600} className={classes.insuranceIcon} />
              </Box>
              <Box>
                <Text weight={fontWeight.MEDIUM} className={classes.insuranceText} color={colors.success600}>
                  Available
                </Text>
              </Box>
            </Box>
          ) : slot?.appointmentMetaData ? (
            <Box display="flex" alignItems="flex-start" justifyContent="stretch" gap="6px">
              <Icons glyph="clear-circle" color={colors.destructive600} className={classes.noInsuranceIcon} />
              <Text
                weight={fontWeight.MEDIUM}
                className={classes.insuranceText}
                color={colors.destructive600}
              >
                Conflict
              </Text>
            </Box>
          ) : (
            <Box display="flex" alignItems="flex-start" justifyContent="stretch" gap="6px">
              <Icons glyph="clear-circle" color={colors.neutral600} className={classes.noInsuranceIcon} />
              <Text weight={fontWeight.MEDIUM} className={classes.insuranceText} color={colors.neutral600}>
                Blocked
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      {renderSlotCard()}
    </Box>
  );
};

export default SlotComponent;
