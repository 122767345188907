import dayjs from 'dayjs';
import { DomainPriorities, IDomainElement } from 'redux/modules/profile/types';

/* eslint-disable max-len */
export const socialList: IDomainElement[] = [
  {
    id: '1',
    name: 'Expereincing homelessness',
    description: '',
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
    assignedBy: 'Patient, self-report',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: null,
    priority: {
      name: DomainPriorities.CRITICAL,
      color: 'red',
    },
    history: [],
  },
  {
    id: '2',
    name: 'Practices mindfulness',
    description: '',
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
    assignedBy: 'Patient, self-report',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: null,
    priority: {
      name: DomainPriorities.CRITICAL,
      color: 'red',
    },
    history: [],
  },
];

export const socicalItems = [
  {
    label: 'Housing',
    groupId: 'Housing',
    items: [
      { label: 'Unstable housing', value: 'Unstable housing' },
      { label: 'Has housing', value: 'Has housing' },
      { label: 'Unsafe home', value: 'Unsafe home' },
    ],
  },
  {
    label: 'Employment',
    groupId: 'Employment',
    items: [
      { label: 'Employment 1', value: 'Employment 1' },
      { label: 'Employment 2', value: 'Employment 2' },
      { label: 'Employment 3', value: 'Employment 3' },
      { label: 'Employment 4', value: 'Employment 4' },
    ],
  },
  {
    label: 'Nutrition',
    groupId: 'Nutrition',
    items: [
      { label: 'Nutrition 1', value: 'Nutrition 1' },
      { label: 'Nutrition 2', value: 'Nutrition 2' },
      { label: 'Nutrition 3', value: 'Nutrition 3' },
      { label: 'Nutrition 4', value: 'Nutrition 4' },
    ],
  },
  {
    label: 'Food Security',
    groupId: 'Food Security',
    items: [
      { label: 'Food Security 1', value: 'Food Security 1' },
      { label: 'Food Security 2', value: 'Food Security 2' },
      { label: 'Food Security 3', value: 'Food Security 3' },
      { label: 'Food Security 4', value: 'Food Security 4' },
    ],
  },
  {
    label: 'Education',
    groupId: 'Education',
    items: [
      { label: 'Education 1', value: 'Education 1' },
      { label: 'Education 2', value: 'Education 2' },
      { label: 'Education 3', value: 'Education 3' },
      { label: 'Education 4', value: 'Education 4' },
    ],
  },
  {
    label: 'Financial Stress',
    groupId: 'Financial Stress',
    items: [
      { label: 'Financial Stress 1', value: 'Financial Stress 1' },
      { label: 'Financial Stress 2', value: 'Financial Stress 2' },
      { label: 'Financial Stress 3', value: 'Financial Stress 3' },
      { label: 'Financial Stress 4', value: 'Financial Stress 4' },
    ],
  },
];

export const stepTitles = {
  0: 'Step 1 - select social determinants',
  1: 'Step 2 - social determinant details',
  2: 'Step 3 - review social determinants',
};
