import * as yup from 'yup';

const AvailableStateSchema = yup.object().shape({
  states: yup.array().of(
    yup.object().shape({
      state: yup.string().required('State is required'),
      insurancesInState: yup
        .array()
        .of(
          yup.object({
            title: yup.string().required('Insurance is required'),
            value: yup.string().required('Insurance is required'),
          }),
        )
        // .min(1, 'At least one insurance is required')
        .required('Insurances in state is required'),
    }),
  ),
});

export { AvailableStateSchema };
