import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  content: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    padding: '18px 16px',
    gap: 19,
    backgroundColor: colors.white,
    borderRadius: 8,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  form: {
    marginTop: 32,
    width: 500,
  },
  input: {
    backgroundColor: colors.white,
  },
  blockInput: {
    marginBottom: 32,
  },
  label: {
    fontSize: 16,
    margin: '0 0 8px 0',
  },
});
