// types
import { INavItemType } from '@confidant-health/lib/ui/templates/side-navigation';
import dayjs from 'dayjs';

export const rightSideNavList: INavItemType[] = [
  {
    text: 'Dashboard',
    query: { type: 'name', value: 'dashboard' },
  },
  {
    text: 'Patient information',
    query: { type: 'name', value: 'general-information' },
  },
  {
    text: 'Appointments',
    // link: '?name=appointments-current',
    query: { type: 'name', value: 'appointments-current' },
  },
  {
    text: 'Care team',
    query: { type: 'name', value: 'care-team' },
  },
  {
    text: 'Case notes',
    query: { type: 'name', value: 'notes' },
  },
  {
    text: 'Chatbots',
    query: { type: 'name', value: 'chatbots' },
  },
  {
    text: 'Insurance',
    query: { type: 'name', value: 'insurance-information' },
  },
  // {
  //   text: 'Plan items',
  //   query: { type: 'name', value: 'plan-items' },
  // },
  // {
  //   text: 'Invoices',
  //   query: { type: 'name', value: 'invoices' },
  // },
  /* {
    text: 'Completed appointments',
    query: { type: 'name', value: 'appointments-past' },
  }, */
  {
    text: 'Symptomatic factors',
    query: { type: 'name', value: 'symptomatic-factor' },
    badge: 11,
  },
  {
    text: 'Environmental factors',
    query: { type: 'name', value: 'environmental-factor' },
    badge: 12,
  },
  {
    text: 'Cognitive issues',
    query: { type: 'name', value: 'cognitive-issues' },
    badge: 13,
  },
  {
    text: 'Diagnoses',
    query: { type: 'name', value: 'diagnoses' },
    badge: 2,
  },
  {
    text: 'Medications',
    query: { type: 'name', value: 'medications' },
    badge: 3,
  },
  // {
  //   text: 'General',
  //   link: '?name=general-information',
  //   children: [
  //     {
  //       text: 'Dashboard',
  //       query: { type: 'name', value: 'dashboard' },
  //     },
  //     {
  //       text: 'Demographics',
  //       query: { type: 'name', value: 'general-information' },
  //     },
  //     {
  //       text: 'Insurance information',
  //       query: { type: 'name', value: 'insurance-information' },
  //     },
  //     /* {
  //       text: 'Completed appointments',
  //       query: { type: 'name', value: 'appointments-past' },
  //     }, */
  //     {
  //       text: 'General notes',
  //       query: { type: 'name', value: 'notes' },
  //     },
  //     {
  //       text: 'Care team',
  //       query: { type: 'name', value: 'care-team' },
  //     },
  //     {
  //       text: 'Groups',
  //       query: { type: 'name', value: 'groups' },
  //     },
  //     {
  //       text: 'Plan Items',
  //       query: { type: 'name', value: 'plan-items' },
  //     },
  //     {
  //       text: 'Invoices',
  //       query: { type: 'name', value: 'invoices' },
  //     },
  //   ],
  // },
  // {
  //   text: 'Data Collection',
  //   link: '?name=data-collection',
  //   children: [
  //     {
  //       text: 'Evaluations',
  //       query: { type: 'name', value: 'evaluations' },
  //     },
  //     {
  //       text: 'Chatbots',
  //       query: { type: 'name', value: 'chatbots' },
  //     },
  //     {
  //       text: 'Activity',
  //       query: { type: 'name', value: 'activity' },
  //     },
  //   ],
  // },
  // {
  //   text: 'Scored Outcomes',
  //   link: '?name=scored-outcome',
  //   children: [
  //     {
  //       text: 'Provider reported',
  //       query: { type: 'name', value: 'provider-reported' },
  //     },
  //     {
  //       text: 'Member reported',
  //       query: { type: 'name', value: 'member-reported' },
  //     },
  //   ],
  // },
  // {
  //   text: 'Priorities',
  //   link: '?name=history',
  //   children: [
  //     {
  //       text: 'History',
  //       query: { type: 'name', value: 'history' },
  //     },
  //     // {
  //     //   text: 'Symptoms',
  //     //   query: { type: 'name', value: 'symptoms' },
  //     //   badge: 6,
  //     // },
  //     // {
  //     //   text: 'Diagnoses',
  //     //   query: { type: 'name', value: 'diagnoses' },
  //     //   badge: 2,
  //     // },
  //     // {
  //     //   text: 'Medications',
  //     //   query: { type: 'name', value: 'medications' },
  //     //   badge: 3,
  //     // },
  //     // {
  //     //   text: 'Side effects',
  //     //   query: { type: 'name', value: 'side-effects' },
  //     //   badge: 4,
  //     // },
  //     // {
  //     //   text: 'Substance use',
  //     //   query: { type: 'name', value: 'substance-use' },
  //     //   badge: 3,
  //     // },
  //     // {
  //     //   text: 'Life events',
  //     //   query: { type: 'name', value: 'life-events' },
  //     //   badge: 10,
  //     // },
  //     // {
  //     //   text: 'Social determinants',
  //     //   query: { type: 'name', value: 'social-determinants' },
  //     //   badge: 9,
  //     // },
  //     {
  //       text: 'Symptomatic factors',
  //       query: { type: 'name', value: 'symptomatic-factor' },
  //       badge: 11,
  //     },
  //     {
  //       text: 'Environmental factors',
  //       query: { type: 'name', value: 'environmental-factor' },
  //       badge: 12,
  //     },
  //     {
  //       text: 'Cognitive Issues',
  //       query: { type: 'name', value: 'cognitive-issues' },
  //       badge: 13,
  //     },
  //     {
  //       text: 'Diagnoses',
  //       query: { type: 'name', value: 'diagnoses' },
  //       badge: 2,
  //     },
  //     {
  //       text: 'Medications',
  //       query: { type: 'name', value: 'medications' },
  //       badge: 3,
  //     },
  //   ],
  // },
];

export const cityList = [{ label: 'Santa Ana', value: 'Santa Ana' }];

export const stateList = [{ label: 'Illinois', value: 'Illinois' }];

export const downloadDateRange = {
  startDate: '2018-01-01',
  endDate: dayjs().add(1, 'days').format('YYYY-MM-DD'),
};
