/* eslint-disable react/destructuring-assignment */
import { FC, useState, useEffect, useMemo, ReactNode } from 'react';
import clsx from 'clsx';
import { useDownloadExcel } from 'table-to-excel-react';

import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box, Grid, Stack } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { boxType, DashboardBox } from '@confidant-health/lib/ui/templates/dashboard-box';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { IReport } from 'redux/modules/reporting/types';
import { getElkEvaluationReport } from 'services/reporting/reporting.service';
import { DctTable } from '../data-collection-templates/dct-table/DctTable';
import HeadCard from '../head-card';
import AssignmentsChart from '../assignments-chart';
import ChartSeries from '../chatbots/chart-series';

import {
  memberColumns,
  getBoxData,
  getLast30DaysAssignedCounts,
  getLast30DaysCompletedCounts,
  getMostCompletedEvaluations,
  getMostMembersEvaluations,
  getChartSeries,
  getEvaluationReportQuery,
} from './Evaluations.constants';
import { IEvaluationReport } from './Evaluations.types';
import { useStyles } from '../profile-elements/ProfileElements.styles';
import { ViewAllDrawer } from '../data-collection-templates/ViewAllDrawer';
import { EvaluationDetail } from './evaluation-detail/EvaluationDetail';

type Props = {
  report: IReport;
  showNewReport: boolean;
  componentRef: any;
};

const Evaluations: FC<Props> = ({ report, showNewReport, componentRef }) => {
  const [evaluationsReport, setEvaluationsReport] = useState<IEvaluationReport>(null);
  const classes = useStyles();
  const [showViewAllType, setShowViewAllType] = useState(null);
  const [selectedItem, setSelectedItemDetail] = useState(null);
  const { onDownload } = useDownloadExcel({
    fileName: 'evaluations_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const boxData = useMemo(() => getBoxData(evaluationsReport, report), [evaluationsReport, report]);
  const last30DaysAssignedCounts = useMemo(
    () => getLast30DaysAssignedCounts(evaluationsReport),
    [evaluationsReport],
  );
  const last30DaysCompletedCounts = useMemo(
    () => getLast30DaysCompletedCounts(evaluationsReport),
    [evaluationsReport],
  );
  const mostCompletedData = useMemo(
    () => getMostCompletedEvaluations(evaluationsReport),
    [evaluationsReport],
  );
  const mostMembersData = useMemo(() => getMostMembersEvaluations(evaluationsReport), [evaluationsReport]);
  const chartSeries = useMemo(() => getChartSeries(evaluationsReport), [evaluationsReport]);
  const evaluationsSection = useMemo(() => report?.data?.evaluationsSection, [report]);

  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await getElkEvaluationReport(getEvaluationReportQuery(report), null);
        // const countResponse = await getElkReportsCount(
        //   getReportAllFiltersQuery(report, REPORT_SECTION_NAMES.evaluationsSection),
        //   null,
        // );
        setEvaluationsReport({
          ...resp.data,
          // total_chatbots_count: countResponse?.data?.count,
          total_assignments: resp.data?.aggregations?.total_assignments?.value || 0,
          total_evaluations: resp.data?.aggregations?.total_evaluations?.value || 0,
        });
      })();
  }, [showNewReport]);

  const onCloseViewDetail = () => {
    setSelectedItemDetail(null);
  };

  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };

  const onClickAll = (type: string) => () => {
    setShowViewAllType(type || showViewAllType);
  };

  const onRowClick = item => {
    setSelectedItemDetail(item);
  };

  // eslint-disable-next-line react/display-name
  const renderMenu = () => () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };

  const getContentsInViewAll = (): {
    title: string;
    subtitle: string;
    tableColumns: TableGrid.TableColumn[];
    data: unknown[];
    renderMenuAction: (payload: any) => ReactNode;
    onRowClick: (item: any) => void;
  } => {
    if (showViewAllType === 'mostCompleted') {
      return {
        title: 'By Most completed',
        subtitle: `${mostCompletedData.length} total`,
        tableColumns: memberColumns,
        data: mostCompletedData,
        onRowClick,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === 'mostMembers') {
      return {
        title: 'By Most members',
        subtitle: `${mostMembersData.length} total`,
        tableColumns: memberColumns,
        data: mostMembersData,
        onRowClick,
        renderMenuAction: renderMenu(),
      };
    }

    return null;
  };

  const renderTable = (title, data, columns, onClickViewAll, renderMenuAction, className = '') => (
    <Box className={clsx(classes.tableWrap, className)} flex={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
          {title}
        </Text>
        <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
          View top ({data.length})
        </IconButton>
      </Stack>
      <DctTable
        className={classes.tableBordered}
        isShowAll={false}
        tableColumns={columns}
        onRowClick={onRowClick}
        data={data}
        renderMenuAction={renderMenuAction}
      />
    </Box>
  );

  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {...[
          {
            title: 'Most Recent Appointments',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: memberColumns,
            data: mostCompletedData as any,
          },
          {
            title: 'Most Completed Appointments',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: memberColumns,
            data: mostMembersData as any,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {renderAllTables()}
      <EvaluationDetail open={!!selectedItem} onClose={onCloseViewDetail} item={selectedItem} />
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <Box className={classes.card}>
        <HeadCard title="Evaluations" componentRef={componentRef} onDownloadClick={onDownload} />
        <Box className={classes.card}>
          <Box className={classes.reports}>
            <Grid container spacing={2}>
              {boxData.map(box => (
                <Grid item xs={4} key={box.label}>
                  <DashboardBox className={classes.reportItem} {...box} type={boxType.TOTAL} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {evaluationsSection.updatedInLast30Days?.isChecked && (
            <Box className={classes.chartWrap}>
              <AssignmentsChart
                title="Evaluations in the last 30 days"
                className={classes.chart}
                chart={{
                  series: [
                    {
                      name: 'Last 30 days Assigned',
                      color: colors.success500,
                      data: last30DaysAssignedCounts,
                    },
                    {
                      name: 'Last 30 days Completed',
                      color: colors.neutral600,
                      data: last30DaysCompletedCounts,
                    },
                  ],
                  tooltipConfig: {
                    isShowTotal: false,
                    title: 'Evaluations',
                  },
                }}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {evaluationsSection.mostCompleted?.isChecked &&
              renderTable(
                'By Most completed',
                mostCompletedData,
                memberColumns,
                onClickAll('mostCompleted'),
                renderMenu(),
                !evaluationsSection.mostMembers?.isChecked ? classes.halfTable : '',
              )}
            {evaluationsSection.mostMembers?.isChecked &&
              renderTable(
                'By Most members',
                mostMembersData,
                memberColumns.map(col => {
                  if (col.id === 'total') {
                    return {
                      ...col,
                      label: 'Assignments',
                    };
                  }
                  return col;
                }),
                onClickAll('mostMembers'),
                renderMenu(),
                !evaluationsSection.mostCompleted?.isChecked ? classes.halfTable : '',
              )}
          </Box>
          {evaluationsSection.evaluationList?.isChecked && (
            <ChartSeries data={chartSeries} pagination onHeadingClick={onRowClick} />
          )}
        </Box>
      </Box>
    </>
  );
};

export { Evaluations };
