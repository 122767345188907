import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  header: {
    position: 'relative',
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  closeBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    '& svg': {
      width: 20,
    },
  },
  formBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  formBlockNoBorder: {
    borderTop: 'none',
  },
  blockHeader: {
    marginBottom: 16,
  },
  topWrap2: {
    marginBottom: 24,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  twoSection: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 32,
    '& $section': {
      flex: 1,
    },
  },
  section: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  noMb: {
    marginBottom: 0,
  },
  checkbox: {
    paddingTop: 40,
    '& .MuiTypography-body1': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
    },
  },
  form: {
    flex: 1,
    height: 'calc(100% - 108px)',
  },
  formContent: {
    flex: 1,
    overflowY: 'auto',
    height: 'calc(100% - 80px)',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
    },
  },
  saveTempBtn: {
    fontSize: 14,
    color: colors.primary600,

    '& svg': {
      width: 14,
      marginRight: 11,
    },
  },
  removeIconBtn: {
    width: 40,
    height: 40,
    minWidth: 'unset',
    border: `1px solid ${colors.destructive300}`,
    borderRadius: 4,

    '& svg': {
      width: 20,
      height: 20,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
  removeContentBtn: {
    fontSize: 14,
    color: colors.destructive500,
    '& svg': {
      width: 18,
      marginRight: 6,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
  addIconBtn: {
    fontSize: 14,
    alignSelf: 'flex-start',
    color: colors.primary600,
    '& svg': {
      width: 24,
      marginRight: 3,
      '& path': {
        fill: colors.primary500,
      },
    },
  },
  menu: {
    '& svg path': {
      fill: colors.primary600,
    },
  },
  menuLabel: {
    fontWeight: 700,
    color: colors.primary600,
  },
  cbDeleteButton: {
    color: colors.error,
  },
  addAnotherBtn: {
    color: colors.primary,
  },
  messageIcon: {
    color: 'rgba(150, 163, 198, 0.50478)',
  },
  rowFlexBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    position: 'relative',
    marginBottom: 24,
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    position: 'relative',
    width: 480,
  },
  content: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '56px 40px 40px',
  },
  textarea: {
    padding: '16px 20px',
  },
});
