import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  left: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    padding: 32,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  icon: {
    minWidth: 'auto',
    width: 48,
    borderColor: colors.primary300,
    color: colors.primary500,
  },
  headBtn: {
    borderColor: colors.primary300,
    color: colors.primary500,
  },
  iconBtn: {
    color: colors.white,
  },
  dayHeadText: {
    textAlign: 'left',
    position: 'absolute',
    left: 16,
    top: 16,
  },
  dayHeadDay: {
    fontSize: 18,
    color: colors.neutral900,
    fontWeight: 600,
  },
  dayHeadMonth: {
    fontSize: 14,
    color: colors.neutral500,
    fontWeight: 500,
  },
  calendarInnerView: {
    top: '80px !important',
  },
  calendarView: {
    position: 'relative',
    fontFamily: 'Manrope',
    /* '& table': {
      height: '1000px !important',
    }, */
    '& .fc-timegrid .fc-col-header-cell': {
      height: 80,
    },
    '& .temp-cell': {
      border: 'none',
      cursor: 'pointer',
      position: 'relative',
      //   '&:hover:before': generateAddPlus(36),
    },
    // timegrid
    '& .fc-timegrid-event-harness': {
      // height: 64,
    },
    '& .fc-timegrid-event': {
      boxShadow: 'none',
      border: 'none',
      // height: 'calc(100% - 1px)',
      // overflow: 'hidden',
      left: -2,
      borderRadius: 0,
      '& .fc-event-main': {
        padding: '0px 8px',
        border: 'none',
        backgroundColor: colors.white,
        '& $eventTitle': {
          // paddingTop: 12,
        },
      },
      '& $event > div': {
        // height: '100%',
      },
    },
    '& .fc-timegrid-col-events': {
      margin: '0 0 0 2px',
    },
    '& .fc .fc-timegrid-slot-minor': {
      borderTop: 'none',
    },
    '& .fc .fc-timegrid-col.fc-day-today': {
      backgroundColor: colors.white,
    },
    '& .fc-non-business': {
      background: 'unset',
    },
    '& .fc-timeGridDay-view': {
      //   '& .fc-timegrid-slot:hover:before': generateAddPlus(32),
      '& .fc-timegrid-slot .day-available': {
        cursor: 'pointer',
      },
    },
    '& .fc .fc-timegrid-slot': {
      height: 64,
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
    },
    '& .fc-view-harness.fc-view-harness-active': {
      height: '1100px !important',
    },
    '& .fc-scrollgrid-sync-table': {
      height: '1043px !important',
    },
    '& .fc-header-toolbar.fc-toolbar ': {
      height: 80,
      display: 'none',
      marginBottom: 0,
      backgroundColor: colors.neutral25,
      '& .fc-toolbar-chunk': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .fc-toolbar-title': {
      fontSize: 18,
      fontWeight: 800,
      color: colors.neutral900,
    },
    '& .fc-button-group .fc-button': {
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
      '&:active': {
        outline: 'none !important',
        boxShadow: 'none !important',
      },
    },
    '& .fc-button-group .fc-prev-button, & .fc-button-group .fc-next-button': {
      backgroundColor: alpha(colors.primary, 0.1),
      color: colors.primary,
      width: 40,
      height: 40,
      borderRadius: '4px !important',
      borderColor: 'transparent !important',
      '&:active': {
        backgroundColor: alpha(colors.primary, 0.8),
      },
      '&:hover': {
        backgroundColor: alpha(colors.primary, 0.8),
        color: colors.white,
      },
      '&:first-child': {
        marginRight: 16,
        '& span': {
          left: -4,
        },
      },
      '&:last-child span': {
        left: -2,
      },
      '& span': {
        position: 'relative',
        top: -1,
      },
    },
    '& .fc-daygrid-day, & .fc-timegrid-col': {
      '&.fc-day-today': {
        backgroundColor: 'transparent',
        '& $dayCellText': {
          color: colors.secondary,
          fontWeight: 700,
        },
      },
      '&:not(.fc-day-past)': {
        '&.day-available': {
          backgroundColor: colors.white,
          '& .fc-daygrid-day-events': {
            '&:hover': {
              cursor: 'pointer',
              //   '&:before': generateAddPlus(40),
            },
          },
          '& .& .fc-timegrid-col-frame': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      },
      '&.day-unavailable': {
        filter: 'opacity(0.5)',
      },
    },
    '& .fc-daygrid-event': {
      height: '100%',
      margin: '0 !important',
      marginTop: '0 !important',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  },
  btnGroup: {
    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 100,
    borderRadius: 4,
  },
  btn: {
    border: `1px solid ${colors.neutral200}`,
    height: 48,
    minWidth: 'auto',
    backgroundColor: colors.white,
    color: colors.neutral700,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: colors.neutral700,
      color: colors.white,
    },
  },
  btnActive: {
    border: 'none',
    backgroundColor: colors.neutral800,
    color: colors.white,
  },
  dayHead: {
    fontWeight: '800',
    fontSize: 12,
    lineHeight: '20px',
    color: colors.neutral700,
    height: 52,
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      position: 'relative',
      paddingTop: 16,
    },
  },
  dayCell: {
    '& .fc-daygrid-day-top': {
      textAlign: 'center',
      justifyContent: 'center',
    },
    '& .fc-scrollgrid-sync-inner': {
      position: 'relative',
      height: 120,
      '& .fc-daygrid-day-top': {
        position: 'absolute',
        zIndex: 10,
        width: '100%',
      },
      '& .fc-daygrid-day-events': {
        position: 'absolute',
        height: '100%',
        minHeight: '100%',
        top: 0,
        width: '100%',
        marginTop: 0,
        '& .fc-daygrid-event-harness': {
          height: '100%',
          '& .fc-daygrid-event': {
            '&:focus': {
              backgroundColor: 'unset',
              boxShadow: 'none',
              '&:after': {
                backgroundColor: 'unset',
              },
            },
          },
        },
      },
    },
  },
  dayCellText: {
    fontWeight: '600',
    fontSize: 12,
    color: colors.neutral700,
  },
  event: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    '& > div': {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  statusCollapse: {
    '& .MuiCollapse-wrapper.MuiCollapse-vertical .MuiFormGroup-root': {
      marginTop: 16,
      display: 'flex',
      gap: 16,
      '& .status-check-item span': {
        width: 12,
        height: 12,
        display: 'inline-block',
        borderRadius: '800px',
        marginRight: 4,
        '&.status-scheduled': {
          background: colors.primary500,
        },
        '&.status-requested': {
          background: colors.orange500,
        },
        '&.status-completed': {
          background: colors.success500,
        },
        '&.status-cancelled': {
          background: colors.neutral500,
        },
        '&.status-no_show': {
          background: colors.destructive500,
        },
        '&.status-blocked_slots': {
          background: colors.neutral900,
        },
      },
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '540px !important',
    },
    '& .drawerBody': {
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
      padding: 0,
    },
  },
  tabs: {
    '& button': {
      color: colors.neutral600,
      fontWeight: 700,
      textTransform: 'none',
      '&.Mui-selected': {
        color: `${colors.neutral900} !important`,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.neutral900,
    },
  },
  imageIcon: {
    width: 40,
    height: 40,
  },
  connectButton: { height: 40, border: `1px solid ${colors.primary300}` },
  input: {
    width: 60,
  },
  destructive: {
    color: colors.destructive600,
  },

  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    position: 'relative',
    width: 480,
  },
  editIconWrap: {
    border: `8px solid ${colors.white}`,
    borderRadius: '50%',
    width: 72,
    height: 72,
    position: 'absolute',
    top: -36,
    left: 36,
    backgroundColor: colors.secondary100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editIcon: {
    '& path': {
      fill: colors.secondary500,
    },
  },
  content: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '56px 40px 40px',
  },
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  title: {
    fontSize: 32,
    color: colors.neutral900,
    fontWeight: 700,
    margin: 0,
    marginBottom: 8,
    fontFamily: 'Source Serif Pro',
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.neutral900,
    display: 'block',
    marginTop: 24,
    marginBottom: 8,
  },
  modalBtn: {
    marginTop: 40,
    width: '100%',
    padding: 0,
  },
  cancelBtn: {
    backgroundColor: colors.white,
    color: colors.neutral800,
  },
  okBtn: {
    backgroundColor: colors.destructive600,
    color: colors.white,
    border: 'none',
  },
  loading: {
    marginRight: 8,
  },
  editCalendarIcon: {
    marginRight: 12,
    '& svg': {
      width: 16,
    },
  },
  deleteIcon: {
    marginRight: 12,
    color: colors.textError,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      '&:last-child': {
        color: colors.destructive600,
        '& svg path': {
          fill: colors.destructive600,
        },
      },
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    minHeight: 'calc(90vh - 160px)',
    flexDirection: 'column',
    gap: 24,
    padding: 24,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  header: {
    padding: 24,
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '32px',
  },
  calendarBtn: {
    padding: 0,
  },
  googleBtn: {
    height: 20,
    backgroundColor: colors.white,
    color: colors.primary600,
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  googleMeetIcon: {
    width: 24,
    height: 24,
  },
});
