import * as yup from 'yup';

const TagSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),
  importance: yup.string().required('Priority is required'),
  assignmentMethod: yup.string().required('Method is required'),
});

export { TagSchema };
