/* eslint-disable react/destructuring-assignment */
import { FC, useEffect, useMemo, useState } from 'react';

import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box, Stack } from '@mui/material';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import RecentAssignmentsTable from 'pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import { getElkDataDomainElementReport, getElkPatientReport } from 'services/reporting/reporting.service';
import { DDE_REPORT_TYPES } from 'pages/admin/report-detail/ReportDetail.constants';

import {
  getDemographicsDetailQuery,
  tableColumns,
  getDDERecentAssignedData,
  getDemographicsRecentAssignedData,
} from './DemographicsDetail.constants';
import { IDemographicsDetailReport } from './DemographicsDetail.types';
import { useStyles } from './DemographicsDetail.styles';

type Props = {
  open: boolean;
  demographicsDetail: any;
  onClose: () => void;
};

const DemographicsDetail: FC<Props> = ({ open, onClose, demographicsDetail }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [demographicsReportDetail, setDemographicsReportDetail] = useState<IDemographicsDetailReport>(null);

  const getRecentAssignedData = () => {
    switch (demographicsDetail?.type) {
      case DDE_REPORT_TYPES.DEFAULT:
        return getDDERecentAssignedData(demographicsReportDetail, search);

      default:
        return getDemographicsRecentAssignedData(demographicsReportDetail, search);
    }
  };
  const recentAssignedData = useMemo(() => getRecentAssignedData(), [demographicsReportDetail, search]);

  useEffect(() => {
    if (!demographicsDetail || !demographicsDetail.name) {
      return;
    }
    void (async () => {
      setIsLoading(true);
      if (demographicsDetail?.type === DDE_REPORT_TYPES.DEFAULT) {
        const resp = await getElkDataDomainElementReport(
          getDemographicsDetailQuery(demographicsDetail.name, demographicsDetail.type),
          null,
        );
        setDemographicsReportDetail(resp.data);
        setIsLoading(false);
        return;
      }
      const resp = await getElkPatientReport(
        getDemographicsDetailQuery(demographicsDetail.name, demographicsDetail.type),
        null,
      );
      setDemographicsReportDetail(resp.data);
      setIsLoading(false);
    })();
  }, [demographicsDetail]);

  const renderMenu = () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };
  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {open && (
        <Box className={classes.drawerContent}>
          {isLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <>
              <Box className={classes.headerDetail}>
                <Stack direction="column">
                  <Text className={classes.headerTitle} weight={fontWeight.BOLD}>
                    {demographicsDetail.name}
                  </Text>
                  <Text className={classes.headerSubtitle}>{demographicsDetail.members} members</Text>
                </Stack>
                <IconButton icon="close" onClick={onClose} className={classes.closeIconBtn} />
              </Box>
              <Box className={classes.content}>
                <Box className={classes.tableWrap}>
                  {/* <Text className={classes.recentText}>Recent assignments</Text> */}
                  <Input
                    variant={inputType.SEARCH}
                    placeholder="Search"
                    value={search}
                    className={classes.inputSearch}
                    onChange={setSearch}
                    size={inputSize.M}
                    fullWidth
                  />
                  <RecentAssignmentsTable
                    className={classes.tableBordered}
                    isShowAll
                    tableColumns={tableColumns}
                    data={recentAssignedData}
                    renderMenuAction={renderMenu}
                  />
                </Box>
              </Box>
              <Box className={classes.drawerFooter}>
                <Button variant={btnType.PRIMARY} onClick={onClose}>
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Drawer>
  );
};

export { DemographicsDetail };
