import { createAction } from 'redux-actions';

/* Fetch types */
export const FETCH_PRIORITY_DOMAIN_TYPES = 'priority/domainTypes/fetch';
export const FETCH_PRIORITY_DOMAIN_TYPES_SUCCESSFUL = 'priority/domainTypes/fetchSuccessful';
export const FETCH_PRIORITY_DOMAIN_TYPES_FAILED = 'priority/domainTypes/fetchFailed';

/* Create types */
export const CREATE_DOMAIN_TYPE = 'priority/domainType/create';
export const CREATE_DOMAIN_TYPE_SUCCESSFUL = 'priority/domainType/createSuccessful';
export const CREATE_DOMAIN_TYPE_FAILED = 'priority/domainType/createFailed';

/* Edit types */
export const UPDATE_DOMAIN_TYPE = 'priority/domainType/update';
export const UPDATE_DOMAIN_TYPE_SUCCESSFUL = 'priority/domainType/updateSuccessful';
export const UPDATE_DOMAIN_TYPE_FAILED = 'priority/domainType/updateFailed';

/* Delete types */
export const DELETE_DOMAIN_TYPE = 'priority/domainType/delete';
export const DELETE_DOMAIN_TYPE_SUCCESSFUL = 'priority/domainType/deleteSuccessful';
export const DELETE_DOMAIN_TYPE_FAILED = 'priority/domainType/deleteFailed';

/* Fetch elements */
export const FETCH_PRIORITY_DOMAIN_ELEMENTS = 'priority/domainElements/fetch';
export const FETCH_PRIORITY_DOMAIN_ELEMENTS_SUCCESSFUL = 'priority/domainElements/fetchSuccessful';
export const FETCH_PRIORITY_DOMAIN_ELEMENTS_FAILED = 'priority/domainElements/fetchFailed';

/* Create element */
export const CREATE_DOMAIN_ELEMENT = 'priority/domainElement/create';
export const CREATE_DOMAIN_ELEMENT_SUCCESSFUL = 'priority/domainElement/createSuccessful';
export const CREATE_DOMAIN_ELEMENT_FAILED = 'priority/domainElement/createFailed';

/* Edit element */
export const UPDATE_DOMAIN_ELEMENT = 'priority/domainElement/update';
export const UPDATE_DOMAIN_ELEMENT_SUCCESSFUL = 'priority/domainElement/updateSuccessful';
export const UUPDATE_DOMAIN_ELEMENT_FAILED = 'priority/domainElement/updateFailed';

/* Delete elements */
export const DELETE_DOMAIN_ELEMENT = 'priority/domainElement/delete';
export const DELETE_DOMAIN_ELEMENT_SUCCESSFUL = 'priority/domainElement/deleteSuccessful';
export const DELETE_DOMAIN_ELEMENT_FAILED = 'priority/domainElement/deleteFailed';

/* Fetch groups */
export const FETCH_PRIORITY_DOMAIN_GROUPS = 'priority/domainGroups/fetch';
export const FETCH_PRIORITY_DOMAIN_GROUPS_SUCCESSFUL = 'priority/domainGroups/fetchSuccessful';
export const FETCH_PRIORITY_DOMAIN_GROUPS_FAILED = 'priority/domainGroups/fetchFailed';

/* Create group */
export const CREATE_DOMAIN_GROUP = 'priority/domainGroup/create';
export const CREATE_DOMAIN_GROUP_SUCCESSFUL = 'priority/domainGroup/createSuccessful';
export const CREATE_DOMAIN_GROUP_FAILED = 'priority/domainGroup/createFailed';

/* Edit group */
export const UPDATE_DOMAIN_GROUP = 'priority/domainGroup/update';
export const UPDATE_DOMAIN_GROUP_SUCCESSFUL = 'priority/domainGroup/updateSuccessful';
export const UPDATE_DOMAIN_GROUP_FAILED = 'priority/domainGroup/updateFailed';

/* Delete groups */
export const DELETE_DOMAIN_GROUP = 'priority/domainGroup/delete';
export const DELETE_DOMAIN_GROUP_SUCCESSFUL = 'priority/domainGroup/deleteSuccessful';
export const DELETE_DOMAIN_GROUP_FAILED = 'priority/domainGroup/deleteFailed';

/* Fetch profile elements */
export const FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS = 'priority/domainProfileElements/fetch';
export const FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_SUCCESSFUL =
  'priority/domainProfileElements/fetchSuccessful';
export const FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_FAILED = 'priority/domainProfileElements/fetchFailed';

/* Fetch lookup  */
export const FETCH_PRIORITY_LOOKUP = 'priority/domainLookups/fetch';
export const FETCH_PRIORITY_LOOKUP_SUCCESSFUL = 'priority/domainLookups/fetchSuccessful';
export const FETCH_PRIORITY_LOOKUP_FAILED = 'priority/domainLookups/fetchFailed';

export const FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID = '/conversation/builder/domainElements/byType/fetch';
export const FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_SUCCESSFUL =
  '/conversation/builder/domainElements/byType/fetchSuccessful';
export const FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_FAILED =
  '/conversation/builder/domainElements/byType/fetchFailed';

export const priorityActionCreators = {
  fetchPriorityDomainTypes: createAction(FETCH_PRIORITY_DOMAIN_TYPES),
  fetchPriorityDomainElements: createAction(FETCH_PRIORITY_DOMAIN_ELEMENTS),
  fetchPriorityDomainGroups: createAction(FETCH_PRIORITY_DOMAIN_GROUPS),
  createPriorityDomainType: createAction(CREATE_DOMAIN_TYPE),
  deletePriorityDomainType: createAction(DELETE_DOMAIN_TYPE),
  deletePriorityDomainElement: createAction(DELETE_DOMAIN_ELEMENT),
  deletePriorityDomainGroup: createAction(DELETE_DOMAIN_GROUP),
  updatePriorityDomainType: createAction(UPDATE_DOMAIN_TYPE),
  fetchPriorityDomainProfileElements: createAction(FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS),
  createPriorityDomainGroup: createAction(CREATE_DOMAIN_GROUP),
  updatePriorityDomainGroup: createAction(UPDATE_DOMAIN_GROUP),
  fetchPriorityLookup: createAction(FETCH_PRIORITY_LOOKUP),
  createPriorityDomainElement: createAction(CREATE_DOMAIN_ELEMENT),
  updatePriorityDomainElement: createAction(UPDATE_DOMAIN_ELEMENT),
  fetchDomainElementsByTypeId: createAction(FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID),
};
