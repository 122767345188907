import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Button, btnSize, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';

import { appointmentActionCreators } from 'redux/modules/appointment';
import { getProfile } from 'redux/modules/profile/selectors';
import { selectProviderServicesState } from 'redux/modules/appointment/selectors';
import { showSnackbar } from 'redux/modules/snackbar';
import * as memberService from 'services/member/member.service';

import worldMapImg from 'assets/images/world-map.png';
import StepOne from 'pages/admin/appointments/add-schedule/step-one/StepOne';
import { useParams } from 'react-router-dom';
import getConfig from 'config';

import { useStyles } from './ScheduleLink.styles';

type GenerateLinkDrawerProps = {
  open: boolean;
  onClose: () => void;
  providerId: string;
  hostURL: string;
};

const GenerateLinkDrawer = ({ open, onClose, providerId }: GenerateLinkDrawerProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const [foundMember, setFoundMember] = useState<any>(null);
  const {
    payload: { patientsList },
  } = useSelector(getProfile);
  const { services } = useSelector(selectProviderServicesState);

  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [formData, setFormData] = useState({
    member: null,
    serviceId: '',
  });
  const [loadMore, setLoadMore] = useState(false);
  const [memberList, setMemberList] = useState(patientsList);
  const [withSearchLoadMoreCount, setWithSearchLoadMoreCount] = useState(0);
  const [withoutSearchLoadMoreCount, setWithoutSearchLoadMoreCount] = useState(0);
  const [previousSearchKey, setPreviousSearchKey] = useState('');
  const [scheduleLink, setScheduleLink] = useState('');
  const appointmentValues = {
    currentState: formData.member?.state?.replaceAll(' ', '-') || null,
    selectedInsurance: formData.member?.insurance?.replaceAll(' ', '-') || null,
    selectedServiceId: formData.serviceId || null,
    selectedProvider: providerId || null,
    memberId: formData.member?.id || null,
  };

  const fetchPatients = async (search?: string, page?: number) => {
    setLoadMore(true);
    const response = await memberService.getPatientsList(
      {
        searchQuery: search ?? searchKey,
        pageNumber: pageNumber < 0 || page < 0 ? 0 : page ?? pageNumber,
        pageSize: 10,
        orderBy: 'asc',
        sortBy: '',
      },
      null,
    );
    if (response.status === 200) {
      const { data } = response;

      const updatedPatientsList = data.patientsList.map(member => {
        return {
          member: {
            id: member.userId,
            nickName: member.fullName,
            fullName: `${member.firstName || ''} ${member.lastName || ''}`,
            firstName: member.firstName,
            lastName: member.lastName,
            photo: member?.photo,
            uuid: member.uuid,
          },
          email: member.email,
          phone: member.phoneNumber,
          insurance: member.insurance,
          state: member.state,
          activeChat: member.activeChat || false,
          completed: member.totalCompletedSessions,
          fundsInWallet: member.fundsInWallet,
          dob: member.dob ? dayjs(member.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
          actions: {
            id: member.userId,
          },
        };
      });

      if (search) {
        setPreviousSearchKey(search);
        setWithoutSearchLoadMoreCount(0);
        withSearchLoadMoreCount === 0 || search !== previousSearchKey
          ? (setWithSearchLoadMoreCount(withSearchLoadMoreCount + 1), setMemberList(updatedPatientsList))
          : setMemberList([
              ...memberList.filter(i => !updatedPatientsList.find(item => item.member.id === i.member.id)),
              ...updatedPatientsList,
            ]);
      } else {
        setWithSearchLoadMoreCount(0);
        withoutSearchLoadMoreCount === 0
          ? (setWithoutSearchLoadMoreCount(withoutSearchLoadMoreCount + 1),
            setMemberList(updatedPatientsList))
          : setMemberList([
              ...updatedPatientsList,
              ...memberList.filter(i => !updatedPatientsList.find(item => item.member.id === i.member.id)),
            ]);
      }
    }
    setLoadMore(false);
  };
  const fetchMembers = (searchQuery = '', page) => {
    fetchPatients(searchQuery, page).catch(error => console.log({ error }));
  };

  useEffect(() => {
    dispatch(appointmentActionCreators.fetchProviderServices(providerId));
    fetchMembers('', 0);
  }, []);

  const selectedServiceName = useMemo(() => {
    return services?.find(service => service?.id === formData?.serviceId)?.name;
  }, [services, formData?.serviceId]);

  const onChangeFormData = key => payload => {
    setFormData({ ...formData, [key]: payload });
  };

  const onChangeSessionType = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, serviceId: target.value });
  };

  // const onGenerateLink = () => {
  //   if (!formData.serviceId && !formData.member) {
  //     setScheduleLink(`${hostURL}providers/${providerId}`);
  //   } else {
  //     const params = new URLSearchParams({
  //       q: JSON.stringify({
  //         providerId,
  //         timeSlot: { date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'), slot: { start: '', end: '' } },
  //         ...insertIfObj(Boolean(formData.serviceId), {
  //           serviceId: formData.serviceId,
  //         }),
  //         ...insertIfObj(Boolean(formData.member?.id), {
  //           memberId: formData.member?.id,
  //         }),
  //       }),
  //     })?.toString();
  //     setScheduleLink(`${hostURL}book-appointment?${params}`);
  //   }
  // };

  const generateAppointmentLink = (values: Record<string, string | number | boolean>) => {
    const baseUrl = `${getConfig.memberAppUrl}public/book-appointment`;

    const queryParams = Object.entries(values)
      .filter(([, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    setScheduleLink(queryParams ? `${baseUrl}?${queryParams}` : baseUrl);
  };

  useEffect(() => {
    if (memberId) {
      const found = memberList
        // .map(({ member, ...rest }) => ({ ...rest, ...member }))
        .find(item => item.member.id === memberId);
      if (found) {
        setFoundMember(found);
        const { member, ...rest } = found;
        onChangeFormData('member')({ ...rest, ...member });
      }
    }
  }, [memberId, patientsList]);

  useEffect(() => {
    if (foundMember && memberList.length > 0) {
      const exists = memberList.some(member => member.member.id === foundMember.member.id);
      if (!exists) {
        setMemberList([foundMember, ...memberList]);
      }
    }
  }, [memberList, foundMember]);

  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {scheduleLink ? (
        <Box
          sx={{
            pt: 2.5,
            pb: 2.5,
            pr: 3,
            pl: 3,
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            borderBottom: `1px solid ${colors.neutral100}`,
          }}
        >
          <MuiIconButton
            sx={{ border: `1px solid ${colors.primary300}`, borderRadius: 1, color: colors.primary500 }}
            onClick={() => setScheduleLink('')}
          >
            <ChevronLeftIcon />
          </MuiIconButton>
          <Box sx={{ fontSize: 18, fontWeight: 700, lineHeight: '24px' }}>Copy & save your link</Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pt: 2.5,
            pb: 2.5,
            pr: 3,
            pl: 3,
            borderBottom: `1px solid ${colors.neutral100}`,
          }}
        >
          <Box sx={{ fontSize: 18, fontWeight: 700, lineHeight: '24px' }}>Generate new link</Box>
          <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
        </Box>
      )}
      {scheduleLink ? (
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 5,
              flex: 1,
              p: 4,
            }}
          >
            <img src={worldMapImg} alt="world map url" />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: colors.neutral900,
                fontSize: 24,
                fontWeight: 600,
                lineHeight: '32px',
              }}
            >
              <Box>Here is the URL</Box>
              <Box>
                {formData?.member?.fullName && (
                  <>
                    for{' '}
                    <span
                      style={{
                        color: '#B80261',
                      }}
                    >
                      {formData?.member?.fullName}{' '}
                    </span>
                  </>
                )}
                {selectedServiceName && (
                  <>
                    for{' '}
                    <span
                      style={{
                        color: '#B80261',
                      }}
                    >
                      {selectedServiceName}
                    </span>
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{ width: 357, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <Icons glyph="copy-link" color={colors.neutral400} />
                  </InputAdornment>
                }
                disabled
                value={scheduleLink}
              />
              <IconButton
                icon="copy-outlined"
                variant={iconBtnType.PRIMARY}
                className={classes.copyLinkBtn}
                onClick={async () => {
                  await navigator.clipboard.writeText(scheduleLink);
                  dispatch(
                    showSnackbar({
                      snackType: 'success',
                      snackMessage: 'Scheduling link copied successfully',
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                }}
              >
                Copy link
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
              boxShadow:
                '0 0 1px 0 rgba(0, 0, 0, 0.04), 0 -4px 8px 0 rgba(0, 0, 0, 0.04), 0 -16px 24px 0 rgba(0, 0, 0, 0.04), 0 -24px 32px 0 rgba(0, 0, 0, 0.04)',
              background: colors.white,
            }}
          >
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button size={btnSize.SMALL} onClick={onClose}>
              Done
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 5, pl: 3, pr: 3, pb: 4 }}>
            <Box className={classes.header}>Select service</Box>
            <Select
              variant={selectType.SECONDARY}
              value={formData.serviceId}
              displayEmpty
              emptyText="Select service"
              className={classes.select}
              onChange={onChangeSessionType}
              options={services?.map(s => ({ label: s.name, value: s.id }))}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '& .list-item': { height: 'calc(100vh - 420px)', overflowX: 'scroll' },
            }}
          >
            <Box className={classes.header} sx={{ ml: 3 }}>
              Select member
            </Box>
            <StepOne
              className={classes.selectMember}
              error=""
              selectedItem={formData.member}
              fetchMembers={fetchMembers}
              onChange={onChangeFormData('member')}
              options={memberList.map(({ member, ...rest }) => ({ ...rest, ...member }))}
              selectType="member"
              userId=""
              setPageNumber={setPageNumber}
              isLoading={loadMore}
              pageNumber={pageNumber}
              isMemberList
              setSearchKeyWithPagination={setSearchKey}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              bottom: 0,
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
              boxShadow:
                '0 0 1px 0 rgba(0, 0, 0, 0.04), 0 -4px 8px 0 rgba(0, 0, 0, 0.04), 0 -16px 24px 0 rgba(0, 0, 0, 0.04), 0 -24px 32px 0 rgba(0, 0, 0, 0.04)',
              background: colors.white,
            }}
          >
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button size={btnSize.SMALL} onClick={() => generateAppointmentLink(appointmentValues)}>
              Generate link
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export default GenerateLinkDrawer;
