import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  chart: {
    '& .apexcharts-legend': {
      gap: 20,
    },
    '& .apexcharts-yaxis-label': {
      x: '0 !important',
    },
    '& .apexcharts-legend-marker': {
      borderRadius: '50% !important',
      top: '1px !important',
      marginRight: '8px !important',
    },
    '& .apexcharts-legend-text': {
      fontSize: '14px !important',
    },
    '& .chart-tooltip': {
      padding: 16,
      width: 121,
      borderRadius: '8px !important',
      '& .date': {
        fontSize: '14px !important',
        lineHeight: '20px',
        color: colors.neutral600,
      },
      '& .level': {
        color: colors.neutral900,
        fontWeight: 700,
      },
    },
  },
});
