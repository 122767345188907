import { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import {
  Heading,
  Text,
  fontWeight,
  headingLevel,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
// import ScheduleLink from 'pages/provider/dashboard/components/schedule-link/ScheduleLink';
import { getAuth } from 'redux/modules/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { profileActionCreators } from 'redux/modules/profile';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { getProfile, selectMemberInsurance } from 'redux/modules/profile/selectors';
import { IDemographicDetails } from 'redux/modules/profile/types';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { BaseLayout } from 'layouts/base';
import { ReferralPartnerType } from 'services/member/member.service';
import { appointmentActionCreators } from 'redux/modules/appointment';

import history from 'utils/history';
import { useStyles } from './index.styles';
import Appointments from './Appointments';
import Card from './Card';
import Diagnoses from './Diagonoses';
import Medications from './Medications';
import Notes from './Notes';

const Dashboard: FC = () => {
  const classes = useStyles();
  const { memberId, referralPartnerId } = useParams();
  const { isAdmin } = useSelector(getAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { demographicDetails = {} as IDemographicDetails, referralPartners } = useSelector(getProfile);
  const [currentReferralPartner, setCurrentReferralPartner] = useState({} as ReferralPartnerType);
  const getFormatQueryParams = () => {
    return {
      pageNumber: 0,
      pageSize: 1000,
      search: '',
    };
  };
  const fetchReferralPartnersCall = () => {
    dispatch(profileActionCreators.fetchReferralPartners(getFormatQueryParams()));
  };
  const { insurance } = useSelector(selectMemberInsurance);
  useEffect(() => {
    const appointmentsQueryParams = {
      orderBy: 'asc',
      page: 0,
      size: 3,
      sortBy: 'startTime',
      statuses: '',
      type: 'CURRENT',
      states: null,
      searchQuery: '',
    };
    dispatch(profileActionCreators.fetchDemographicMetadata());
    dispatch(profileActionCreators.fetchDemographicDetails(memberId));
    dispatch(profileActionCreators.fetchDomainTypes(memberId));
    dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
    dispatch(
      appointmentActionCreators.fetchAppointments({
        queryParams: appointmentsQueryParams,
      }),
    );
    fetchReferralPartnersCall();
  }, []);
  useEffect(() => {
    const foundReferralPartner = referralPartners?.partners?.find(
      item => item?.refPartnerId === referralPartnerId,
    );
    if (foundReferralPartner) setCurrentReferralPartner(foundReferralPartner);
  }, [referralPartners]);
  useEffect(() => {
    dispatch(
      profileActionCreators.selectedPatient({
        member: {
          id: memberId,
          firstName: demographicDetails?.firstName || '',
          lastName: demographicDetails?.lastName || '',
          fullName: demographicDetails?.fullName || '',
          nickName: demographicDetails?.nickName || '',
          photo: null,
        },
        email: demographicDetails?.email || '',
        dob: demographicDetails?.dateOfBirth || '',
        phone: demographicDetails?.mobilePhone || '',
        fundsInWallet: 0,
        engagement: {
          Status: demographicDetails?.levelOfEngagementStatus || '',
          SubStatus: demographicDetails?.levelOfEngagementStatus || '',
        },
      }),
    );
  }, [demographicDetails]);

  const renderLabelValue = (label: string, value: { text: string; isActive?: string }) => {
    return (
      <Box display="flex" flexDirection="column" gap="8px">
        <Text size={textLevel.M} weight={fontWeight.MEDIUM} color={colors.neutral600}>
          {label}
        </Text>
        <Box display="flex" flexDirection="row" gap="8px" alignItems="center">
          <Text size={textLevel.M} weight={fontWeight.MEDIUM} color={colors.neutral900}>
            {value.text}
          </Text>
          {value.isActive && (
            <Badge
              variant={badgeType.OUTLINED}
              size={badgeSize.SMALL}
              style={value.isActive === 'Active' ? badgeStyle.GREEN : badgeStyle.UNRELATED}
              // className={classes.badge}
            >
              {value.isActive}
            </Badge>
          )}
        </Box>
      </Box>
    );
  };
  const renderMemberInfo = () => (
    <Card
      label="Member information"
      actions={[
        {
          label: 'Read more',
          onClick: () =>
            navigate(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=general-information`),
        },
      ]}
    >
      <Box className={classes.spaceBetweenRow}>
        <ProfileInfo
          type="member"
          nickName={demographicDetails?.nickName || ''}
          fullName={`${
            demographicDetails?.fullName ??
            `${demographicDetails?.firstName || ''} ${demographicDetails?.lastName || ''}`
          } `}
          memberId={memberId}
        />
        {renderLabelValue('Insurance carrier', {
          text: insurance?.currentInsurance?.name || '-',
          isActive: insurance?.currentInsurance?.isActive ? 'Active' : 'Inactive',
        })}
        {renderLabelValue('Member ID', {
          text: insurance?.patientInsuranceData?.memberId || '-',
        })}
        {renderLabelValue('Type', {
          text: insurance?.insuranceType || '-',
        })}
        {renderLabelValue('Level of engagement', {
          text:
            demographicDetails?.levelOfEngagementStatus !== null
              ? demographicDetails?.levelOfEngagementStatus
              : '-',
        })}
      </Box>
    </Card>
  );

  // const renderMyNotes = () => (
  //   <Card label="My notes">
  //     <Box className={classes.noteWrapper}>
  //       <Input value="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint." />
  //     </Box>
  //   </Card>
  // );

  // const renderStory = () => (
  //   <Grid container spacing={3}>
  //     <Grid item xs={12} md={12} lg={6}>
  //       <Card
  //         label="Personal story"
  //         actions={[
  //           {
  //             label: 'Read more',
  //             onClick: () =>
  //               history.push(
  //                 `/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=general-information`,
  //               ),
  //           },
  //         ]}
  //       >
  //         <Box sx={{ p: 4 }}>
  //           <Box className={classes.verticalEllipsis}>
  //             Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
  //             consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim
  //             mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim
  //             velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
  //             ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
  //             Exercitation veniam consequat sunt nostrud amet.
  //           </Box>
  //         </Box>
  //       </Card>
  //     </Grid>
  //     <Grid item xs={12} md={12} lg={6}>
  //       <Card
  //         label="Treatment journey"
  //         actions={[
  //           {
  //             label: 'Read more',
  //             onClick: () =>
  //               history.push(
  //                 `/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=general-information`,
  //               ),
  //           },
  //         ]}
  //       >
  //         <Box sx={{ p: 4 }}>
  //           <Box className={classes.verticalEllipsis}>
  //             Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
  //             consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim
  //             mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim
  //             velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
  //             ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
  //             Exercitation veniam consequat sunt nostrud amet.
  //           </Box>
  //         </Box>
  //       </Card>
  //     </Grid>
  //   </Grid>
  // );

  const renderAppointments = () => (
    // <Card
    //   label="Appointments"
    //   actions={[
    //     // {
    //     //   label: 'Download PDF',
    //     //   icon: 'download',
    //     //   onClick: () => null
    //     // },
    //     {
    //       label: 'View all',
    //       onClick: () =>
    //         navigate(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=appointments-current`),
    //     },
    //   ]}
    // >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
        borderRadius: 2,
        background: colors.white,
      }}
    >
      <Box className={classes.flexColumn}>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box className={classes.flexColumn} sx={{ background: colors.secondary50, p: 2, gap: 1 }}>
                <Box className={classes.cardLabel}>Referred</Box>
                <Box className={classes.cardDescription}>
                  {' '}
                  {demographicDetails?.appointmentStats?.referred
                    ? dayjs(demographicDetails?.appointmentStats?.referred).format('MMMM DD, YYYY')
                    : '-'}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.flexColumn} sx={{ background: colors.warning50, p: 2, gap: 1 }}>
                <Box className={classes.cardLabel}>Started care</Box>
                <Box className={classes.cardDescription}>
                  {demographicDetails?.appointmentStats?.startedCare
                    ? dayjs(demographicDetails?.appointmentStats?.startedCare).format('MMMM DD, YYYY')
                    : '-'}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.flexColumn} sx={{ background: colors.success50, p: 2, gap: 1 }}>
                <Box className={classes.cardLabel}>total appointments</Box>
                <Box className={classes.cardDescription}>
                  {demographicDetails?.appointmentStats?.totalAppointments || '-'}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.flexColumn} sx={{ background: colors.primary50, p: 2, gap: 1 }}>
                <Box className={classes.cardLabel}>Appointments this month</Box>
                <Box className={classes.cardDescription}>
                  {demographicDetails?.appointmentStats?.appointmentsThisMonth || '-'}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
    // </Card>
  );

  // const renderPersonality = () => (
  //   <Grid container spacing={3}>
  //     <Grid item xs={12} md={12} lg={6}>
  //       <Card
  //         label="Anxiety"
  //         badge="12 completed"
  //         actions={[{ label: 'All completions', onClick: () => null }]}
  //       >
  //         <Box sx={{ p: 3 }}>
  //           <Grid container spacing={4}>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.neutral50, borderRadius: 2 }}
  //               >
  //                 <Box className={classes.cardLabel}>first</Box>
  //                 <Box className={classes.cardDescription}>15</Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.neutral50, borderRadius: 2 }}
  //               >
  //                 <Box className={classes.cardLabel}>current</Box>
  //                 <Box className={classes.cardDescription}>60</Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.destructive50, borderRadius: 2 }}
  //               >
  //                 <Box className={classes.cardLabel} sx={{ color: `${colors.destructive500} !important` }}>
  //                   change
  //                 </Box>
  //                 <Box
  //                   className={classes.cardDescription}
  //                   sx={{ color: `${colors.destructive500} !important` }}
  //                 >
  //                   45
  //                 </Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //           </Grid>
  //         </Box>
  //       </Card>
  //     </Grid>
  //     <Grid item xs={12} md={12} lg={6}>
  //       <Card
  //         label="Depression"
  //         badge="12 completed"
  //         actions={[{ label: 'All completions', onClick: () => null }]}
  //       >
  //         <Box sx={{ p: 3 }}>
  //           <Grid container spacing={4}>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.neutral50 }}
  //               >
  //                 <Box className={classes.cardLabel}>first</Box>
  //                 <Box className={classes.cardDescription}>15</Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.neutral50 }}
  //               >
  //                 <Box className={classes.cardLabel}>current</Box>
  //                 <Box className={classes.cardDescription}>60</Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //             <Grid item xs={12} md={4}>
  //               <Box
  //                 className={classes.flexColumn}
  //                 sx={{ flex: 1, gap: 1, p: 2, background: colors.success50 }}
  //               >
  //                 <Box className={classes.cardLabel} sx={{ color: `${colors.success500} !important` }}>
  //                   change
  //                 </Box>
  //                 <Box className={classes.cardDescription} sx={{ color: `${colors.success500} !important` }}>
  //                   45
  //                 </Box>
  //                 <Box className={classes.cardSubLabel}>5/6/2023</Box>
  //               </Box>
  //             </Grid>
  //           </Grid>
  //         </Box>
  //       </Card>
  //     </Grid>
  //   </Grid>
  // );

  const BaseComponent = history.location.pathname.includes('admin/members/dashboard') ? BaseLayout : Box;

  return (
    <BaseComponent>
      <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
        Member dashboard
      </Heading>
      <Box className={classes.flexColumn} sx={{ gap: 3, mt: 5 }}>
        {renderMemberInfo()}
        {/* {renderMyNotes()} */}
        {/* {renderStory()} */}
        {currentReferralPartner?.patientDetailAppointment && renderAppointments()}
        {/* <ScheduleLink /> */}
        {/* {renderPersonality()} */}

        {currentReferralPartner?.patientDetailAppointment && <Appointments isAdmin={isAdmin} />}
        {currentReferralPartner?.patientDetailDiagnosis && <Diagnoses isAdmin={isAdmin} />}
        {/* <Evaluations isAdmin={isAdmin} /> */}
        {/* <Chatbots isAdmin={isAdmin} /> */}
        {currentReferralPartner?.patientDetailMedication && <Medications isAdmin={isAdmin} />}
        <Notes isAdmin={isAdmin} />
      </Box>
    </BaseComponent>
  );
};

export default Dashboard;
