import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 24 },
  backBtn: {
    width: 40,
    height: 40,
    border: `1px solid ${colors.primary300}`,
    color: colors.primary500,
    borderRadius: 4,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  gap24: {
    gap: 24,
  },
  gap12: {
    gap: 12,
  },
  gap4: {
    gap: 4,
  },
  flexColumn: {
    flexDirection: 'column',
  },
  font22: {
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 600,
  },
  font16: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  font18: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  font12: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  font14: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  badge: {
    height: 18,
    borderRadius: 100,
    padding: '0px 8px',
  },
  btn: {
    '&.Mui-disabled': {
      color: colors.primary300,
      backgroundColor: colors.primary50,
    },
  },
  activeBtn: {
    background: colors.white,
    border: `1px solid ${colors.primary300}`,
    color: colors.primary600,
    '&:hover': {
      color: colors.white,
      opacity: 1,
    },
  },
  tabContainer: {
    padding: 16,
    borderBottom: `1px solid ${colors.neutral200}`,
  },
  evaluationTabs: {
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
      margin: 0,
      minHeight: 44,
      padding: '12px 16px',
      height: 44,
      textTransform: 'capitalize',
      color: colors.neutral700,
      '&.Mui-selected': {
        color: colors.neutral900,
        border: `1px solid ${colors.neutral200}`,
        borderRadius: 8,
        '& div': {
          background: colors.neutral700,
          color: colors.white,
          border: 'none',
        },
      },
    },
  },
  tabBadge: {
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 100,
    color: colors.neutral700,
    background: colors.white,
    height: 20,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  outerCol: {
    borderRight: `1px solid ${colors.neutral100}`,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  requiredBadge: {
    background: colors.white,
    border: `1px solid ${colors.warning300}`,
    color: colors.warning600,
  },
  collapsable: {
    '& .collapsableHeader': {
      justifyContent: 'flex-start',
    },
    '& .collapsableIcon': {
      marginLeft: 0,
      '& path': {
        fill: colors.neutral900,
      },
    },
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    position: 'relative',
    width: 480,
  },
  content: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '40px',
  },
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  modalTitle: {
    fontSize: '28px',
    color: colors.neutral900,
    fontWeight: 700,
    lineHeight: '36px',
    margin: '0px 0px 8px 0px',
  },
  modalButtons: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 16,
    alignSelf: 'stretch',
    marginTop: 40,
  },
  modalCloseButton: {
    flex: 1,
    padding: '12px 20px',
    color: colors.neutral800,
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral300}`,
    height: 'unset',
    '&:hover': {
      backgroundColor: colors.neutral100,
    },
  },
  modalButton: {
    flex: 1,
    padding: '12px 20px',
    height: 'unset',
  },
});
