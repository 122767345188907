import { FC } from 'react';
import { Box } from '@mui/material';

import { Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { useLocation, useNavigate } from 'react-router-dom';
import { CONVERSATION_TYPES } from 'constants/CommonConstants';
import { path2type } from 'pages/admin/conversation/ConversationDetail.mock';
import { useStyles } from './InfoCard.styles';

type Props = {
  title: string;
  data: {
    conversationName: string;
    conversationId: string;
    version?: string;
    assignments?: number;
    completed?: number;
    inProgress?: number;
    notStarted?: number;
  }[];
  version: number;
};

const InfoCard: FC<Props> = ({ title, data }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  const goToConversationReport = (conversationId: string, conversationVersion: string) => {
    navigate(
      `/admin/${
        path2type[path].type === CONVERSATION_TYPES.EVALUATION_DCT
          ? 'collection-evaluations'
          : 'collection-conversations'
      }/${conversationId}/${conversationVersion}`,
    );
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>{title}</Heading>
      </Box>
      {data.map((item, idx) => (
        <Box
          className={classes.data}
          key={idx}
          onClick={() => goToConversationReport(item.conversationId, item.version)}
        >
          <Box sx={{ width: 288 }}>
            <Text className={classes.text}>{item.conversationName}</Text>
          </Box>
          <IconButton icon="chevron-right" className={classes.icon} />
        </Box>
      ))}
    </Box>
  );
};

export { InfoCard };
