import { FC } from 'react';

// components
import { Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import CardTop from '../completions/card-top';

import { DonutChartSeriesProps, getChartOpts } from './ReportChartCard.utils';
// styles
import { useStyles } from './ReportChartCard.styles';

type Props = {
  title: string;
  subtitle: string;
  badge: string;
  total: number;
  chartData: DonutChartSeriesProps;
  onViewClick?: () => void;
};

const ReportChartCard: FC<Props> = ({ title, subtitle, badge, total, chartData, onViewClick }) => {
  const classes = useStyles();
  const series = chartData.map(({ data }) => data[0]);

  return (
    <Box className={classes.card}>
      <CardTop title={title} subtitle={subtitle} badge={badge} onClick={onViewClick} />
      <Box className={classes.chartWrap}>
        <ReactApexChart
          options={getChartOpts(chartData, { title: 'Total', total })}
          series={series}
          type="donut"
          height={250}
        />
      </Box>
    </Box>
  );
};

export { ReportChartCard };
