// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
  },
  header: {
    backgroundColor: colors.white,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    padding: '8px 24px',
    boxShadow: '0px -1px 0px 0px #EDF1F5 inset',
    minHeight: '64px',
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  backBtn: {
    justifyContent: 'end',
    height: '48px',
    '& svg': {
      height: 20,
    },
  },
  body: {
    height: 'calc(100vh - 150px)',
    overflowY: 'auto',
    backgroundColor: colors.neutral50,
    padding: 24,
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '0px', // Set the width of the scrollbar
    },
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    position: 'relative',
    width: 480,
  },
  content: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '40px',
  },
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  modalTitle: {
    fontSize: '28px',
    color: colors.neutral900,
    fontWeight: 700,
    lineHeight: '36px',
    margin: '0px 0px 8px 0px',
  },
  modalButtons: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 16,
    alignSelf: 'stretch',
    marginTop: 40,
  },
  modalCloseButton: {
    flex: 1,
    padding: '12px 20px',
    color: colors.neutral800,
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral300}`,
    height: 'unset',
    '&:hover': {
      backgroundColor: colors.neutral100,
    },
  },
  modalButton: {
    flex: 1,
    padding: '12px 20px',
    height: 'unset',
  },
});
