import * as yup from 'yup';

const DctScoringSchema = yup.object({
  minScore: yup.number().required('Min Score is required'),
  maxScore: yup.number().required('Max Score is required'),
  positivityDirection: yup.string().required('Positivity Direction must be either INCREASE or DECREASE'),
  description: yup.string().required('Scoring Description is required'),
  scoreElementKey: yup.string().required('Scoring Profile Element is required'),
  outcomeElementKey: yup.string().required('Outcome Range Profile Element required'),
  veryNegativeRange: yup.object({
    minScore: yup.number().required('This field is required'),
    maxScore: yup
      .number()
      .required('This field is required')
      .moreThan(yup.ref('minScore'), 'Max Score must be greater than Min Score'),
    colorCode: yup.string(),
  }),
  negativeRange: yup.object({
    minScore: yup.number().required('This field is required'),
    maxScore: yup
      .number()
      .required('This field is required')
      .moreThan(yup.ref('minScore'), 'Max Score must be greater than Min Score'),
    colorCode: yup.string(),
  }),
  neutralRange: yup.object({
    minScore: yup.number().required('This field is required'),
    maxScore: yup
      .number()
      .required('This field is required')
      .moreThan(yup.ref('minScore'), 'Max Score must be greater than Min Score'),
    colorCode: yup.string(),
  }),
  positiveRange: yup.object({
    minScore: yup.number().required('This field is required'),
    maxScore: yup
      .number()
      .required('This field is required')
      .moreThan(yup.ref('minScore'), 'Max Score must be greater than Min Score'),
    colorCode: yup.string(),
  }),
});

export { DctScoringSchema };
