import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: { borderRadius: 12 },
  information: {
    padding: 32,
    display: 'flex',
    gap: 32,
    background: colors.success50,
    border: `1px solid ${colors.success200}`,
    borderRadius: 8,
  },
  lockImg: {
    width: 42,
    height: 54,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: 286,
  },
  input: {
    height: 48,
    paddingLeft: 16,
    '& .MuiInput-root > input': {
      fontSize: 14,
      lineHeight: '20px',
    },
    '& input::placeholder': {
      color: colors.neutral500,
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  eye: {
    cursor: 'pointer',
    '& path': {
      fill: colors.primary500,
    },
  },
  info: {
    '& path': {
      fill: colors.destructive500,
    },
  },
  btn: {
    '& button': {
      width: '100%',
      '&.Mui-disabled': {
        color: colors.primary300,
        background: colors.primary50,
      },
    },
    width: 286,
  },
  outlineBtn: {
    color: colors.primary600,
    borderColor: colors.primary300,
  },
  error: { borderColor: colors.destructive300 },
});
