import { CONTACT_NOTES_FLAGS } from '@confidant-health/lib/constants/CommonConstants';
import * as yup from 'yup';

const AddNoteSchema = (type: string) => {
  const basicSchemaObject = {
    title: yup.string().required('Title is required'),
    status: yup.string().required('Status is required'),
  };
  switch (type) {
    case CONTACT_NOTES_FLAGS.GENERAL:
    case CONTACT_NOTES_FLAGS.CONTACT:
    case CONTACT_NOTES_FLAGS.CAUTION:
    case CONTACT_NOTES_FLAGS.PROHIBITIVE:
    case CONTACT_NOTES_FLAGS.LAB_REQUEST:
      return yup.object(basicSchemaObject);
    case CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION:
      return yup.object({
        ...basicSchemaObject,
        typeOfInformationForRelease: yup
          .array()
          .of(yup.string())
          .min(1, 'Type of information for release is required')
          .required('Type of information for release is required'),
      });
    case CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE:
      return yup.object({
        ...basicSchemaObject,
        typesOfHelpNeeded: yup
          .array()
          .of(yup.string())
          .min(1, 'Types of help needed is required')
          .required('Types of help needed is required'),
      });
    case CONTACT_NOTES_FLAGS.REFERRAL_OUT:
      return yup.object({
        ...basicSchemaObject,
        typesOfReferralNeeded: yup
          .array()
          .of(yup.string())
          .min(1, 'Types of referral needed is required')
          .required('Types of referral needed is required'),
        typesOfReferral: yup
          .array()
          .of(yup.string())
          .min(1, 'Types of referral is required')
          .required('Types of referral is required'),
      });
    default:
      return yup.object(basicSchemaObject);
  }
};

export { AddNoteSchema };
