import { REPORT_FILTERS } from 'constants/CommonConstants';
import { ReportFilterRule } from 'redux/modules/reporting/types';

export const filterLogicSelectOptions = Object.entries(REPORT_FILTERS).map(filter => {
  return { label: filter[0], value: filter[1] };
});

export const automationProfileSelect = [{ label: 'PHQ-9 Score', value: 'PHQ-9 Score' }];

export const automationRuleSelectNumeric = [
  { label: 'Equals', value: ReportFilterRule.EQUALS },
  { label: 'Greater than', value: ReportFilterRule.GREATER_THAN },
  { label: 'Less than', value: ReportFilterRule.LESS_THAN },
];

export const automationRuleSelectText = [
  { label: 'Contains', value: ReportFilterRule.CONTAINS },
  { label: 'Does not contain', value: ReportFilterRule.NOT_CONTAIN },
];

export const automationRuleSelectOption = [
  { label: 'Equals', value: ReportFilterRule.EQUALS },
  { label: 'Not equals', value: ReportFilterRule.NOT_EQUALS },
];

export const automationRuleSelectDate = [
  { label: 'Before this Date', value: ReportFilterRule.BEFORE_DATE },
  { label: 'After this Date', value: ReportFilterRule.AFTER_DATE },
];
