import { FC } from 'react';
import { Box } from '@mui/material';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { ISource } from '../share-screen-selection/ShareScreenSelection';
import { useStyles } from './BottomView.styles';

type Props = {
  publisher: ReactJSXElement;
  screen?: ReactJSXElement;
  shareScreen: ISource;
  isVideoCall: boolean;
  onFullScreen: () => void;
};

const BottomView: FC<Props> = ({ publisher, shareScreen, isVideoCall, onFullScreen, screen }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Box display="flex" gap="30px">
          <div className={classes.thumbnail}>{publisher}</div>
          {screen && <div className={classes.thumbnail}>{screen}</div>}
        </Box>
        <Box display="flex" alignItems="center" gap="12px">
          {shareScreen && (
            <Box className={classes.imgWrap}>
              <img src={shareScreen.thumbnail} alt="" />
              <Text className={classes.shareText}>Sharing {shareScreen.name}</Text>
            </Box>
          )}
        </Box>
        <Box className={classes.actions}>
          {isVideoCall && (
            <IconButton
              variant={iconBtnType.PRIMARY}
              className={classes.iconBtn}
              onClick={onFullScreen}
              icon="full-screen"
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export { BottomView };
