import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: colors.neutral50,
    borderRadius: 8,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
  },
  topClickable: {
    cursor: 'pointer',
    border: `1px solid ${colors.neutral100}`,
    borderBottom: 'none',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  title: {
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  subtitle: {
    color: colors.neutral700,
  },
  badge: {
    height: 24,
  },
  editBtn: {
    fontSize: 14,
    lineHeight: '20px',
    '& svg': {
      width: 14,
      marginLeft: 12,
    },
  },
  content: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  description: {
    fontSize: 18,
    lineHeight: '28px',
  },
  detail: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral100}`,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  childItem: {
    padding: 16,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  arrowBtn: {
    minWidth: 'auto',
    '& svg': {
      width: 16,
      height: 16,
      '& path': {
        fill: colors.neutral500,
      },
    },
  },
  arrowBtnRotate: {
    transform: 'rotate(-90deg)',
  },
  linkBtn: {
    padding: 0,
    minWidth: 'auto',
  },
});
