/* eslint-disable max-len */
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';

export const tableColumns: TableGrid.TableColumn[] = [
  { id: 'no', label: 'Sr.', sortable: false },
  { id: 'name', label: 'Name', sortable: false },
  { id: 'description', label: 'Description', sortable: false },
  { id: 'serviceByTypeCount', label: 'Service By Type Count', sortable: false },
  { id: 'actions', label: '', sortable: false },
];
