import dayjs from 'dayjs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { INewSchedulePayload } from 'pages/admin/appointments/add-schedule/AddSchedule.types';
import { IAction } from 'redux/store/types';
import * as AppointmentService from 'services/appointment/appointment.service';
import * as ProxyService from 'services/proxy/proxy.service';
import getConfig from 'config';
import { profileActionCreators } from '../profile';
import { showSnackbar } from '../snackbar/actions';
import * as actionType from './actions';
import { IPatchAppointmentRequest, PatchAction } from './types';

function* appointmentFetcher(action: IAction) {
  try {
    yield put(profileActionCreators.fetchProviders());

    const { data } = yield call(AppointmentService.getAppointments, action.payload.queryParams);
    const appointments = data?.results;

    yield put({
      type: actionType.FETCH_APPOINTMENTS_SUCCESSFUL,
      payload: {
        appointments,
        currentPage: data?.page,
        totalPages: Math.ceil(data?.total / data?.size),
        total: data?.total,
        loadingMore: action.payload.loadMore,
      },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: actionType.FETCH_APPOINTMENTS_FAILED,
      payload: { message },
    });
  }
}

function* appointmentFetcherPublic(action: IAction) {
  const { userId, queryParams } = action.payload || {};
  try {
    const { data } = yield call(ProxyService.bypassProxy, {
      method: 'GET',
      url: `${getConfig.api.baseUrl}/v2/scheduling/appointment/search`,
      memberId: userId,
      params: queryParams,
    });

    const appointments = data?.results;

    yield put({
      type: actionType.FETCH_APPOINTMENTS_SUCCESSFUL,
      payload: {
        appointments,
        currentPage: data?.page,
        totalPages: Math.ceil(data?.total / data?.size),
        total: data?.total,
        loadingMore: action.payload.loadMore,
      },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';

    yield put({
      type: actionType.FETCH_APPOINTMENTS_FAILED,
      payload: { message },
    });
  }
}

function* appointmentDetailsFetcher(action: IAction) {
  try {
    const { data } = yield call(AppointmentService.getPastAppointmentDetails, {
      appointmentId: action.payload,
    });

    yield put({
      type: actionType.FETCH_APPOINTMENT_DETAILS_SUCCESSFUL,
      payload: {
        details: data,
      },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: actionType.FETCH_APPOINTMENT_DETAILS_FAILED,
      payload: { message },
    });
  }
}

function* providerServicesFetcher(action: IAction) {
  try {
    const { data } = yield call(AppointmentService.getProviderServices, { providerId: action.payload });
    yield put({
      type: actionType.FETCH_PROVIDER_SERVICES_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: actionType.FETCH_PROVIDER_SERVICES_FAILED,
      payload: { message },
    });
  }
}

interface AppointmentAction extends IAction {
  payload: {
    appointmentId: string;
    appointmentCancelParams?: {
      reason: string;
    };
    callback: (err?: string) => void;
  };
}
function* cancelAppointment(action: AppointmentAction) {
  const { appointmentId, appointmentCancelParams, callback } = action.payload;
  try {
    const body: IPatchAppointmentRequest = {
      action: appointmentCancelParams.reason === 'NO_SHOW' ? PatchAction.NO_SHOW : PatchAction.REJECT,
      cancelAppointmentReason: appointmentCancelParams.reason,
    };
    yield call(AppointmentService.patchAppointment, body, { appointmentId });
    yield put({
      type: actionType.CANCEL_APPOINTMENT_SUCCESSFUL,
      payload: { appointmentId },
    });
    callback();
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage:
          appointmentCancelParams.reason === 'NO_SHOW'
            ? 'Successfully marked as no show'
            : 'Appointment cancelled successfully',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    callback(message);
    yield put({
      type: actionType.CANCEL_APPOINTMENT_FAILED,
      payload: { message },
    });
  }
}

function* getAppointmentById(action) {
  const { appointmentId2 } = action.payload;

  try {
    const { data } = yield call(AppointmentService.getAppointmentById, { appointmentId: appointmentId2 });
    const data1 = {
      appointmentId: data?.appointmentId,
      cost: data?.serviceCost,
      duration: data?.serviceDuration,
      endTime: data?.endTime,
      startTime: data.startTime,
      participants: [
        {
          participantId: data?.patientId,
          dateOfBirth: data?.patientDOB,
          firstName: data?.patientFirstName,
          lastName: data?.patientLastName,
          name: data?.participantName,
          profilePicture: data?.participantImage,
          isPractitioner: false,
        },
        {
          participantId: data?.practitionerId,
          dateOfBirth: '',
          firstName: data?.practitionerFirstName,
          lastName: data?.practitionerLastName,
          name: data?.practitionerName,
          profilePicture: data?.practitionerImage,
          isPractitioner: true,
        },
      ],
      serviceName: data?.serviceName,
    };
    yield put({
      type: actionType.FETCH_APPOINTMENT_BY_ID_SUCCESSFUL,
      payload: { data1 },
    });
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    console.log(message);
  }
}

function* acceptAppointment(action: IAction) {
  const { appointmentId, callback } = action.payload;
  try {
    yield call(
      AppointmentService.patchAppointment,
      { action: PatchAction.ACCEPT, confirmAppointmentData: { primaryConcern: null } },
      { appointmentId },
    );
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Appointment request accepted',
      }),
    );
    callback(true);
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    callback(false);
  }
}

interface IUpdateAppointmentAction extends IAction {
  payload: {
    appointmentId: string;
    bodyRequest: IPatchAppointmentRequest;
    callback: (err?: string) => void;
  };
}
function* updateAppointment(action: IUpdateAppointmentAction) {
  const { appointmentId, bodyRequest, callback } = action.payload;
  try {
    yield call(AppointmentService.patchAppointment, bodyRequest, { appointmentId });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Appointment has been updated',
      }),
    );
    callback();
  } catch (e) {
    console.warn(e);
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    callback(message);
    yield put({
      type: actionType.UPDATE_APPOINTMENT_FAILED,
      payload: { message },
    });
  }
}

function* createAppointment(action: {
  type: string;
  payload: {
    data: INewSchedulePayload;
    callback: (
      isSucceed: boolean,
      isInstantSession: boolean,
      appointmentId: string,
      isRecurringAppointment?: boolean,
      recurringAppointmentSlots?: any[],
    ) => void;
  };
}) {
  const { data, callback } = action.payload;
  const body = data.isRecurring
    ? ({
        instantAppointment: false,
        isRecurring: true,
        recurringType: data.recurringType,
        memberId: data.member.id,
        providerId: data.provider.id,
        serviceId: data.serviceId,
        startTime: data.time.start,
        endTime: data.time.end,
      } as AppointmentService.INewRecurringAppointmentRequest)
    : ({
        memberId: data.member.id,
        providerId: data.provider.id,
        serviceId: data.serviceId,
        startTime: data.time.start,
        instantAppointment: data.instantAppointment ? data.instantAppointment : '',
      } as AppointmentService.INewAppointmentRequest);
  try {
    const response = yield data.isRecurring
      ? call(
          AppointmentService.createNewRecurringAppointment,
          body as AppointmentService.INewRecurringAppointmentRequest,
        )
      : call(AppointmentService.createNewAppointment, body);
    yield put({ type: actionType.CREATE_APPOINTMENT_SUCCESSFUL });
    yield callback(
      true,
      data.instantAppointment,
      response.data.appointment?._id,
      data.isRecurring,
      response.data,
    );
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Your appointment has been created',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: actionType.CREATE_APPOINTMENT_FAILED,
      payload: { message },
    });
    yield callback(false, false, '');
  }
}

function* fetchMasterScheduleHandler(action: IAction) {
  const startDatee = new Date();
  const startDate = new Date();
  const endDate = new Date(startDate.setDate(startDate.getDate() + Number(action.payload.timeSpan)));
  const startTimeValue = `${action.payload.startTime.slice(0, 2)}:${action.payload.startTime.slice(2, 4)}`;
  const endTimeValue = `${action.payload.endTime.slice(0, 2)}:${action.payload.endTime.slice(2, 4)}`;
  const paramquery = {
    participantIds: action.payload?.provderId !== undefined ? action.payload?.provderId : '',
    daysOfWeek: action.payload.selectedDays.toString(),
    duration: action.payload.duration,
    startDate:
      action.payload?.view === 'calendar'
        ? dayjs(endDate).startOf('month').format('DD-MM-YYYY')
        : dayjs(startDatee).format('DD-MM-YYYY'),
    endDate:
      action.payload?.view === 'calendar'
        ? dayjs(endDate).endOf('month').format('DD-MM-YYYY')
        : dayjs(endDate).format('DD-MM-YYYY'),
    startTime: startTimeValue,
    endTime: endTimeValue,
    timezone: action.payload.timeZone,
    year: action.payload.year,
    providerRoles: action.payload.providerRoles.toString(),
    month: Number(action.payload.month),
    viewProviderDetails: true,
    viewInsurances: true,
    size: 100,
  };
  try {
    const { data } = yield call(AppointmentService.searchSchedule, paramquery);

    yield put({
      type: actionType.FETCH_MASTER_SCHEDULE_REQUEST_SUCCESSFUL,
      payload: {
        masterScheduleItems: data.results,
      },
    });
  } catch (e) {
    yield put({
      type: actionType.FETCH_MASTER_SCHEDULE_REQUEST_FAILED,
      payload: { message: e.data?.errorDetails?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchProviderRolesHandler() {
  try {
    const { data } = yield call(AppointmentService.listProviders);

    yield put({
      type: actionType.FETCH_PROVIDER_ROLES_SUCCESSFUL,
      payload: {
        providerRoles: data,
      },
    });
  } catch (e) {
    yield put({
      type: actionType.FETCH_PROVIDER_ROLES_FAILED,
      payload: { message: e?.data?.errorDetails?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* appointmentCountsFetcher() {
  try {
    const queryParams = {
      page: 0,
      size: 1,
      type: 'PENDING',
    };
    const response = yield call(AppointmentService.getAppointments, queryParams);
    queryParams.type = 'CURRENT';
    const response2 = yield call(AppointmentService.getAppointments, queryParams);
    const data2 = {
      current: response2?.data?.total,
      pending: response?.data?.total,
      past: 0,
    };
    yield put({
      type: actionType.FETCH_ALL_APPOINTMENT_COUNTS_SUCCESSFUL,
      payload: {
        allAppointmentCounts: data2,
      },
    });
  } catch (e) {
    yield put({
      type: actionType.FETCH_ALL_APPOINTMENT_COUNTS_FAILED,
      payload: { message: e?.data?.errorDetails?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchAllProviderServices(action) {
  try {
    const { initial, state, memberId } = action.payload;
    const params: any = {
      initial: !!initial,
      memberId,
    };
    if (state) {
      params.state = state;
    }
    /* if (providerId && providerId.trim() !== '') {
      params.providerId = providerId;
      if (memberId) {
        params.initial = false;
      }
    } */
    const { data } = yield call(AppointmentService.getAllProviderServices, params);
    yield put({
      type: actionType.FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL,
      payload: {
        schedule: data,
      },
    });
  } catch (e: any) {
    console.warn(e);
    yield put({
      type: actionType.FETCH_ALL_PROVIDER_SERVICES_FAILED,
      payload: {
        error: e?.message,
      },
    });
  }
}

function* fetchMasterSchedule2(action) {
  try {
    const { data } = yield call(AppointmentService.getAvailableSlots, action?.payload);
    yield put({
      type: actionType.FETCH_MASTER_SCHEDULE_SUCCESSFUL,
      payload: {
        data: data?.results,
      },
    });
  } catch (e) {
    console.warn(e);
  }
}

export default function* appointmentSaga(): IterableIterator<any> {
  yield takeLatest(actionType.FETCH_APPOINTMENTS, appointmentFetcher);
  yield takeLatest(actionType.FETCH_APPOINTMENTS_PUBLIC, appointmentFetcherPublic);
  yield takeLatest(actionType.FETCH_PROVIDER_SERVICES, providerServicesFetcher);
  yield takeLatest(actionType.CANCEL_APPOINTMENT, cancelAppointment);
  yield takeLatest(actionType.ACCEPT_APPOINTMENT, acceptAppointment);
  yield takeLatest(actionType.UPDATE_APPOINTMENT, updateAppointment);
  yield takeLatest(actionType.CREATE_APPOINTMENT, createAppointment);
  yield takeLatest(actionType.FETCH_MASTER_SCHEDULE_REQUEST, fetchMasterScheduleHandler);
  yield takeLatest(actionType.FETCH_MASTER_SCHEDULE, fetchMasterSchedule2);
  yield takeLatest(actionType.FETCH_PROVIDER_ROLES, fetchProviderRolesHandler);
  yield takeLatest(actionType.FETCH_APPOINTMENT_DETAILS, appointmentDetailsFetcher);
  yield takeLatest(actionType.FETCH_ALL_APPOINTMENT_COUNTS, appointmentCountsFetcher);
  yield takeLatest(actionType.FETCH_ALL_PROVIDER_SERVICES, fetchAllProviderServices);
  yield takeLatest(actionType.FETCH_APPOINTMENT_BY_ID, getAppointmentById);
}
