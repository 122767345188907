import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';

// components
import { fontWeight, Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { ChecklistMock } from 'pages/provider/member-appointment-notes/components/complete-note/CompleteNote.constants';
import ChecklistCard from 'pages/provider/member-appointment-notes/components/checklist-card';

// types
import { IEvaluationItem } from 'pages/provider/sessions/evaluations-v2/Evaluations.types';
import { IEvaluationSectionItem } from 'pages/provider/sessions/evaluations/evaluation-section-collapse/EvaluationSectionCollapse';

// services
import { profileActionCreators } from 'redux/modules/profile';
import { getAppointmentEvaluationProgress } from 'services/conversation/conversation.service';
import { reportServiceActionCreators } from 'redux/modules/report-service';
import { getReportService } from 'redux/modules/report-service/selectors';
import { useDomainType } from 'hooks/useDomainType';
import { DomainTypesEnum } from 'constants/CommonConstants';
import history from 'utils/history';

import { getAppointmentCptCodes } from 'services/appointment/appointment.service';
import { stateActionCreators } from 'redux/modules/state';

import { AddProcedureCodes } from '../add-procedure-codes';
import { ReviewDiagnoses } from '../review-diagnoses';
import { MedicationReconciliation } from '../medication-reconciliation';
import { DiagnosesCard } from './diagnoses-card/DiagnosesCard';
import { MedicationCard } from './medication-card/MedicationCard';
import ProcedureCodeCard from './procedure-code-card';

// styles
import { useStyles } from './Checklist.styles';
import EvaluationDrawer from './evaluation-drawer';

type Props = {
  appointment: IAppointment;
  className?: string;
  onSelectedEvaluation: (evaluation: IEvaluationItem) => void;
  onSelectedEvaluationSection: (section: IEvaluationSectionItem) => void;
  showEvaluation: () => void;
  showSchedule: () => void;
  setShowSignOff: () => void;
  isSession?: boolean;
  isEndedSession?: boolean;
  getEvaluationProgressListt?: () => void;
};

const Checklist: FC<Props> = ({
  appointment,
  className = '',
  onSelectedEvaluation,
  onSelectedEvaluationSection,
  showEvaluation,
  showSchedule,
  setShowSignOff,
  isSession = false,
  isEndedSession = false,
  getEvaluationProgressListt,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDiagnosesDrawer, setOpenDiagnosesDrawer] = useState<boolean>(false);
  const [openMedicationsDrawer, setOpenMedicationsDrawer] = useState<boolean>(false);
  const [openProcedureDrawer, setOpenProcedureDrawer] = useState<boolean>(false);
  const [evaluationsProgressList, setEvaluationsProgressList] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [cpts, setCpts] = useState<any[]>([]);
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);

  // const { appointmentCptCode} = useSelector(getReportService);
  const [diagnosisList, setDiagnosisList] = useState<any[]>([]);

  const { appointmentCptCode } = useSelector(getReportService);

  const onSubmitSignOffClick = () => {
    history.push(`/provider/appointments/past/${appointment.appointmentId}/session-signoff/v2`);
  };

  const [isLoadingDiagnoses, diagnoses, domainElementsList] = useDomainType({
    type: DomainTypesEnum.DIAGNOSES,
    patientId: appointment?.participantId,
  });
  useEffect(() => {
    let tempDiagnosisList = [];
    if (domainElementsList) {
      domainElementsList.forEach((item: any) => {
        if (item.relatedElements?.length) {
          const filteredDiagnises = item.relatedElements?.filter(
            (element: any) => element.metaData?.metaDataValues?.icd10Codes,
          );
          tempDiagnosisList = [...filteredDiagnises, ...tempDiagnosisList];
        }
      });
    }
    setDiagnosisList(tempDiagnosisList);
  }, [diagnoses, domainElementsList]);
  const [, medications, domainOptions] = useDomainType({
    type: DomainTypesEnum.MEDICATIONS,
    patientId: appointment?.participantId,
  });

  const [, symptoms] = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
    patientId: appointment?.participantId,
  });

  // useEffect(() => {
  //   // getPatientDataDomainElement({ patientId: appointment.participantId })
  //   //   .then(res => console.log({ res }))
  //   //   .catch(err => console.log({ err }));
  //   getDomainTypes({ patientId: appointment.participantId })
  //     .then(res => console.log({ res }))
  //     .catch(err => console.log({ err }));
  // }, []);
  useEffect(() => {
    if (diagnoses && medications && domainOptions) {
      setLoader(false);
    }
  }, [diagnoses, medications, domainOptions]);
  /**
   * @Name getEvaluationProgressList
   * @description This method is used to get Evaluation Progress List
   */
  const getEvaluationProgressList = () => {
    setLoader(true);
    getAppointmentEvaluationProgress({ appointmentId: appointment?.appointmentId })
      .then(evaluationProgressDetailList => {
        // setLoader(false);
        if (evaluationProgressDetailList?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList = evaluationProgressDetailList?.data || [];
          setEvaluationsProgressList(responseList);
          setLoader(false);
        }
      })
      .catch(error => {
        // setLoader(false);
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  };

  /**
   * @Name onClickEvaluation
   * @description This method is used to set evaluation and evaluation section when we select evaluation from the list
   */
  const onClickEvaluation = (evaluation: IEvaluationItem) => {
    if (!isEndedSession) {
      showEvaluation();
      onSelectedEvaluation(evaluation);
      const section = evaluation?.sections?.find(sec => sec.items?.some(item => !item.isDone));
      const item = section?.items.find(it => !it.isDone);
      onSelectedEvaluationSection(item || section?.items[0]);
    } else {
      setSelectedEvaluation(evaluation);
    }
  };

  const getProcedureCodeOptionLists = async () => {
    const cptCodesList = await getAppointmentCptCodes(appointment?.appointmentId);
    setCpts(cptCodesList?.data?.cptCodes);
    dispatch(stateActionCreators.fetchICD10());
  };

  const UpdateOnCompletion = () => {
    getEvaluationProgressList();
    if (getEvaluationProgressListt) getEvaluationProgressListt();
  };

  useEffect(() => {
    getEvaluationProgressList();
    // Fetch provider details for "Review diagnoses" and "Review medications"
    dispatch(profileActionCreators.fetchDomainTypes(appointment?.participantId));
    dispatch(reportServiceActionCreators.fetchAppointmentCptCodeById(appointment?.appointmentId));
    getProcedureCodeOptionLists()
      .then()
      .catch(err => {
        console.log('option list fetch failed', err);
      });
  }, []);

  return (
    <>
      {openDiagnosesDrawer && (
        <ReviewDiagnoses
          appointment={appointment}
          relatedElements={diagnoses?.relatedElements}
          onClose={() => setOpenDiagnosesDrawer(false)}
        />
      )}
      {openMedicationsDrawer && (
        <MedicationReconciliation
          appointment={appointment}
          relatedElements={medications?.relatedElements}
          domainOptions={domainOptions}
          onClose={() => setOpenMedicationsDrawer(false)}
          symptoms={symptoms}
        />
      )}
      {openProcedureDrawer && (
        <AddProcedureCodes
          appointment={appointment}
          appointmentCptCode={appointmentCptCode}
          domainElements={diagnoses?.relatedElements}
          diagnosisList={diagnosisList}
          cpts={cpts}
          onClose={() => setOpenProcedureDrawer(false)}
          isLoadingCPT={isLoadingDiagnoses}
        />
      )}
      {!openDiagnosesDrawer && !openMedicationsDrawer && !openProcedureDrawer ? (
        <Box className={clsx(classes.container, className)} style={{ padding: isSession ? 40 : 64 }}>
          <Box className={classes.top}>
            <Heading weight={fontWeight.BOLD} className={classes.title}>
              Checklist
            </Heading>
            <Text className={classes.subtitle}>Here is what you supposed to do during this session</Text>
          </Box>

          <Box display="flex" flexDirection="column" gap="8px">
            {!loader ? (
              evaluationsProgressList?.map(item => (
                <ChecklistCard
                  key={item.id}
                  title={item.name}
                  subtitle={item.description}
                  progress={item.progress}
                  status={item.status}
                  icon="evaluation"
                  onClick={() => onClickEvaluation(item)}
                />
              ))
            ) : (
              <div className={classes.loader}>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                Loading...
              </div>
            )}
            <DiagnosesCard
              key="Review diagnoses"
              domainElements={diagnoses?.relatedElements}
              status="NOT_STARTED"
              onClick={() => setOpenDiagnosesDrawer(true)}
              isLoading={isLoadingDiagnoses}
            />
            <MedicationCard
              key="Medication reconciliation"
              domainElements={medications?.relatedElements}
              status="NOT_STARTED"
              onClick={() => setOpenMedicationsDrawer(true)}
            />
            <ProcedureCodeCard
              key="add-prcedure-codes-card"
              codesCount={appointmentCptCode?.cptList?.length}
              status="NOT_STARTED"
              onClick={() => setOpenProcedureDrawer(true)}
              appointmentCptCode={appointmentCptCode}
            />
            <ChecklistCard
              key={ChecklistMock[0].title}
              {...ChecklistMock[0]}
              icon="calendar-today-outlined"
              onClick={showSchedule}
            />
            <ChecklistCard
              key={ChecklistMock[1].title}
              {...ChecklistMock[1]}
              icon="checkbox-outlined"
              onClick={() => {
                setShowSignOff();
                onSubmitSignOffClick();
              }}
            />
          </Box>
        </Box>
      ) : null}
      {selectedEvaluation && (
        <EvaluationDrawer
          evaluations={selectedEvaluation}
          open={!!selectedEvaluation}
          onClose={() => setSelectedEvaluation(null)}
          UpdateOnCompletion={UpdateOnCompletion}
        />
      )}
    </>
  );
};

export { Checklist };
