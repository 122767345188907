import { FC, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  fontWeight,
  Heading,
  Text,
  textLevel,
  headingLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { getProfile } from 'redux/modules/profile/selectors';
import { conversationActionCreators } from 'redux/modules/conversation';
import { uuid4 } from 'lib/sendbird';
import { formatTimeMessage } from 'utils/CommonUtils';

import { MessageInput } from '../../MessageInput';
import { useStyles } from './Chats.styles';

type Props = {
  onClose: () => void;
  messages: any[];
  channelUrl: string;
};

function ChatItem({ message, time, name, avatar, fileMeta }) {
  const classes = useStyles();
  return (
    <Box display="flex" marginBottom="24px">
      <Avatar variant={avatarType.CIRCLE} size={40} name={name} src={avatar} />
      <Box paddingLeft="16px" flex="1">
        <Box display="flex">
          <Heading weight={fontWeight.BOLD} level={headingLevel.S} className={classes.groupName}>
            {name}
          </Heading>
          <Text level={textLevel.S} className={classes.time}>
            {time}
          </Text>
        </Box>
        <Text level={textLevel.S} className={classes.messageText}>
          {message}
        </Text>
        {fileMeta && (
          <>
            {fileMeta?.type.split('/')[0] === 'image' ? (
              <img
                src={fileMeta.url}
                alt="confidant health"
                height={96}
                width={160}
                style={{ borderRadius: 4, marginTop: 10 }}
              />
            ) : (
              <video
                controls
                src={fileMeta.url}
                height={96}
                width={160}
                style={{ borderRadius: 4, marginTop: 10 }}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

const Chats: FC<Props> = ({ onClose, messages, channelUrl }) => {
  const classes = useStyles();
  const { demographicDetails, profile, providerDetails } = useSelector(getProfile);
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  /**
   * @Name handleAddMessage
   * @param message
   * @description This method is used to handle add message functionality through redux call .
   */
  const handleAddMessage = (msg: Record<string, any>) => {
    const newMessage = !msg.hasFile
      ? {
          _id: uuid4(),
          message: {
            text: msg,
          },
          user: {
            userId: profile?.providerId,
            name: profile?.fullName,
            avatar: profile?.profileImage,
          },
          nickName: profile.fullName ?? 'no name',
          createdAt: new Date().getTime(),
          type: 'user',
          system: false,
        }
      : msg;
    dispatch(
      conversationActionCreators.sendMessage({
        payload: {
          message: newMessage,
        },
      }),
    );
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        behavior: 'smooth',
        left: 0,
      });
    }
  }, [containerRef, messages.length]);

  return (
    <Box className={classes.container} ref={containerRef}>
      <Box className={classes.header}>
        <Box className={classes.headerText}>
          <Heading weight={fontWeight.BOLD} className={classes.title}>
            Chats
          </Heading>
          <Box display="flex" alignItems="center" marginTop="16px">
            <Avatar
              src={demographicDetails?.profilePicture || providerDetails.profileImage}
              name={`${demographicDetails?.firstName ?? providerDetails?.firstName ?? ''} ${
                demographicDetails?.lastName ?? providerDetails?.lastName ?? ''
              }`.trim()}
              size={56}
              variant={avatarType.CIRCLE}
            />
            <Box marginLeft="12px">
              <Heading weight={fontWeight.BOLD} level={headingLevel.S} className={classes.groupName}>
                {`${demographicDetails?.firstName ?? providerDetails?.firstName ?? ''} ${
                  demographicDetails?.lastName ?? providerDetails?.lastName ?? ''
                }`.trim()}
              </Heading>
              <Text level={textLevel.S}>Private chat</Text>
            </Box>
          </Box>
        </Box>
        <IconButton icon="close" onClick={onClose} className={classes.closeBtn} />
      </Box>
      <Box padding="20px 16px" marginBottom="60px">
        {messages.map(({ message, user, _id, createdAt, fileMeta }) => (
          <ChatItem
            key={_id}
            name={user.name}
            time={formatTimeMessage(createdAt)}
            message={message.text}
            avatar={user.avatar}
            fileMeta={fileMeta}
          />
        ))}
      </Box>
      <Box
        paddingX="16px"
        paddingY="10px"
        width="440px"
        position="fixed"
        bottom="0"
        sx={{ backgroundColor: 'white' }}
      >
        <MessageInput onSubmit={handleAddMessage} />
      </Box>
    </Box>
  );
};

export { Chats };
