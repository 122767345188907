import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  closeBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    minHeight: 'calc(100vh - 160px)',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  dot: {
    width: 24,
    height: 24,
    marginRight: 8,
    borderRadius: 100,
  },
});
