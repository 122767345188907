import { FC } from 'react';
import { Box, Stack } from '@mui/material';

import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';

// styles
import { useStyles } from './AutomationEventItem.styles';
// constants

export type IAutomationEventItem = {
  startLabel: string;
  startValue: string | number;
  endLabel: string;
  endValue: string | number;
  isDeleteable?: boolean;
};

type Props = {
  onEdit: (item: IAutomationEventItem) => void;
  onDelete?: (item: IAutomationEventItem) => void;
  event: IAutomationEventItem;
};

const AutomationEventItem: FC<Props> = ({ event, onEdit, onDelete }) => {
  const classes = useStyles();

  const onEditClick = () => {
    onEdit(event);
  };

  const onDeleteClick = () => {
    onDelete(event);
  };

  const renderInfo = (label: string | number, value: string | number) => (
    <Box>
      <Text className={classes.infoLabel} weight={fontWeight.EXTRA_BOLD}>
        {label}
      </Text>
      <Text className={classes.infoValue} weight={fontWeight.SEMI_BOLD}>
        {value}
      </Text>
    </Box>
  );

  return (
    <Box className={classes.event}>
      <Stack direction="row" gap={4} alignItems="center">
        {renderInfo(event.startLabel, event.startValue)}
        <Icons glyph="arrow-right" color={colors.neutral500} />
        {renderInfo(event.endLabel, event.endValue)}
      </Stack>
      <Stack direction="row" gap={1.5} alignItems="center">
        <IconButton onClick={onEditClick} className={classes.editBtn}>
          Edit
        </IconButton>
        {event.isDeleteable && (
          <IconButton onClick={onDeleteClick} className={classes.deleteBtn}>
            Delete
          </IconButton>
        )}
      </Stack>
    </Box>
  );
};

export { AutomationEventItem };
