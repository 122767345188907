import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useDebounce from 'hooks/useDebounce';
import clsx from 'clsx';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { btnSize, btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { getConversation } from 'redux/modules/conversation/selectors';
import { getContentBlockInLibrary } from 'services/conversation/conversation.service';

import { ContentBlockDrawer } from 'pages/admin/content-block-library/components/ContentBlockDrawer';
import { conversationActionCreators } from 'redux/modules/conversation';

import { useStyles } from './FlowAdd.styles';

type Props = {
  isOpen: boolean;
  // dctId: string;
  onClose: () => void;
  onSave: (dctIds: string[]) => void;
};

const SelectCBDrawer: FC<Props> = ({ isOpen, onClose, onSave }) => {
  const classes = useStyles();
  // const [searchKey, setSearchKey] = useState<string>('');
  const [selectedCBs, setSelectedCBs] = useState<string[]>([]);
  const dispatch = useDispatch();
  const {
    profileElements: { profileElementList },
  } = useSelector(getConversation);
  const [searchKey, setSearchKey] = useState<string>('');
  const [openCbDrawer, setOpenCbDrawer] = useState(false);
  const [filteredCB, setFilteredCB] = useState([]);
  const [selectedCBData, setSelectedCBData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [addCbButtonPressed, setAddCbButtonPressed] = useState(false);
  const searchName = useDebounce(searchKey, 1000);

  const handleSearchKeyChange = (keyword: string) => {
    setSearchKey(keyword);
    setFilteredCB([]);
    setPage(0);
    setTotalPages(0);
  };

  const toggleDCTSelection = cb => {
    if (selectedCBs.includes(cb.cbId)) {
      setSelectedCBs(selectedCBs.filter((cbId: string) => cb.cbId !== cbId));
      setSelectedCBData(selectedCBData.filter(item => cb.cbId !== item.cbId));
    } else {
      setSelectedCBs([...selectedCBs, cb.cbId]);
      setSelectedCBData([...selectedCBData, cb]);
    }
  };

  const handleAddDct = () => {
    onSave(selectedCBData);
    onClose();
    setSearchKey('');
    setFilteredCB([]);
    setPage(0);
    setTotalPages(0);
    setSelectedCBs([]);
    setSelectedCBData([]);
    void getCB();
  };

  const closeCbDrawer = () => {
    setOpenCbDrawer(false);
    setAddCbButtonPressed(false);
  };

  const addContentBlock = data => {
    setOpenCbDrawer(false);
    data.status = 'ACTIVE';
    dispatch(conversationActionCreators.addContentBlock(data));
    void getCB();
  };

  const getCB = async () => {
    setLoading(true);
    try {
      const { data } = await getContentBlockInLibrary({
        orderBy: 'desc',
        pageNumber: page,
        pageSize: 20,
        status: 'ACTIVE',
        sortBy: '',
        types: '',
        searchQuery: searchKey,
      });
      setPage(page + 1);
      setFilteredCB([...filteredCB, ...data?.results]);
      setTotalPages(data?.totalPages);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    void getCB();
  }, [searchName]);

  const renderCB = CB => {
    const { cbId } = CB;
    const { text, type } = CB?.basicInfo;
    const isSelected = selectedCBs.includes(cbId);
    return (
      <Box
        key={cbId}
        className={clsx(classes.dct, { [classes.selected]: isSelected })}
        onClick={() => toggleDCTSelection(CB)}
      >
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {text}
          </Text>
          <Text level={textLevel.S} weight={fontWeight.MEDIUM} style={{ color: '#4C5965' }}>
            {type}
          </Text>
        </Box>
        {isSelected && <Icons glyph="checkmark" color={colors.primary500} />}
      </Box>
    );
  };

  return (
    <>
      <Drawer
        open={isOpen}
        variant={drawerType.FORM}
        className={classes.drawer2}
        onClose={() => {
          onClose();
          setSearchKey('');
          setFilteredCB([]);
          setPage(0);
          setTotalPages(0);
          setSelectedCBs([]);
          setSelectedCBData([]);
          void getCB();
        }}
        header={
          <div className={classes.header2}>
            <IconButton
              icon="chevron-left"
              variant={btnType.OUTLINE}
              onClick={() => {
                onClose();
                setSearchKey('');
                setFilteredCB([]);
                setPage(0);
                setTotalPages(0);
                setSelectedCBs([]);
                setSelectedCBData([]);
                void getCB();
              }}
              className={classes.backBtn}
            />
            <Heading level={headingLevel.M} className={classes.headerTitle} weight={fontWeight.BOLD}>
              Add Content Block
            </Heading>
          </div>
        }
        footer={
          <Box className={classes.footer}>
            <Button
              variant={btnType.TEXT}
              onClick={() => {
                onClose();
                setSearchKey('');
                setFilteredCB([]);
                setPage(0);
                setTotalPages(0);
                setSelectedCBs([]);
                setSelectedCBData([]);
                void getCB();
              }}
            >
              Cancel
            </Button>
            <Button disabled={selectedCBs.length === 0} onClick={handleAddDct}>
              {selectedCBs.length === 1 ? 'Add Content Block' : 'Add Content Blocks'}
            </Button>
          </Box>
        }
      >
        <Box>
          <Box className={classes.searchBoxCB}>
            <Input
              fullWidth
              className={classes.searchInput}
              variant={inputType.SEARCH}
              placeholder="Search"
              value={searchKey}
              onChange={handleSearchKeyChange}
            />
            <IconButton
              size={btnSize.SMALL}
              icon="plus"
              className={classes.iconBtn}
              onClick={() => {
                setAddCbButtonPressed(true);
                setOpenCbDrawer(true);
              }}
            >
              New content block
            </IconButton>
          </Box>
          <Box className="list-item">
            {loading && (
              <div className={classes.loader}>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                Loading...
              </div>
            )}
            {filteredCB.length > 0 && !loading && (
              <>
                {!loading && filteredCB?.map(renderCB)}
                {totalPages !== 0 && page < totalPages && !loading && (
                  <Box className={classes.btnContainer}>
                    <Button
                      variant={btnType.TEXT}
                      className={classes.loadMoreBtn}
                      onClick={() => void getCB()}
                    >
                      Load more
                    </Button>
                  </Box>
                )}
              </>
            )}
            {filteredCB.length === 0 && !loading && (
              <Box style={{ padding: '16px 24px' }}>
                <Text level={textLevel.M} weight={fontWeight.MEDIUM}>
                  No content blocks available
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>
      {openCbDrawer && (
        <ContentBlockDrawer
          titleLabel=""
          isOpen={openCbDrawer}
          profileElements={profileElementList}
          onClose={() => {
            closeCbDrawer();
            setSearchKey('');
            setFilteredCB([]);
            setPage(0);
            setTotalPages(0);
            void getCB();
          }}
          onDelete={cbId => {
            console.log(cbId);
          }}
          onSave={addContentBlock}
          addCbButtonPressed={addCbButtonPressed}
          getCBs={getCB}
        />
      )}
    </>
  );
};

export { SelectCBDrawer };
