import { FC } from 'react';
import { Box } from '@mui/material';

import { Heading, headingLevel, fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { ProgressBar } from '@confidant-health/lib/ui/atoms/ProgressBar';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IGlyphs, Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import {
  evaluationStatusStyle,
  EVALUATION_STATUS,
  EVALUATION_STATUS_TYPES,
} from 'pages/provider/sessions/evaluations-v2/Evaluations.constants';
import clsx from 'clsx';

import { useStyles } from './CheckListCard.styles';

type Props = {
  title: string;
  subtitle: string;
  status?: string;
  progress?: number;
  icon?: IGlyphs['glyph'];
  onClick: () => void;
};

const CheckListCard: FC<Props> = ({ title, subtitle, status, progress, icon, onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.cardContent} onClick={onClick}>
        <Box className={classes.iconWrapper}>
          <Icons color={colors.primary500} glyph={icon} />
        </Box>
        <Box
          className={status === EVALUATION_STATUS_TYPES.IN_PROGRESS ? classes.cardText : classes.cardText2}
        >
          <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.title}>
            {title}
          </Heading>
          <Text className={classes.subtitle}>{subtitle}</Text>
        </Box>
        {status && (
          <Box className={classes.statusBadge}>
            {status === EVALUATION_STATUS_TYPES.IN_PROGRESS ? (
              <>
                <ProgressBar value={progress} className={classes.progress} />
                <Badge
                  className={classes.badge}
                  variant={badgeType.OUTLINED}
                  style={evaluationStatusStyle[status]}
                >
                  {progress}%
                </Badge>
              </>
            ) : (
              <Badge
                icon={status === 'COMPLETED' || status === 'INCOMPLETE' ? 'check-circle' : null}
                className={clsx({
                  [classes.badgeCompleted]: status === 'COMPLETED',
                  [classes.badgeIncomplete]: status === 'INCOMPLETE',
                  [classes.badge]: status !== 'COMPLETED' && status !== 'INCOMPLETE',
                })}
                variant={
                  status === 'COMPLETED' || status === 'INCOMPLETE' ? badgeType.ICON : badgeType.OUTLINED
                }
                style={evaluationStatusStyle[status]}
              >
                {EVALUATION_STATUS[status]}
              </Badge>
            )}
          </Box>
        )}
      </Box>

      {/*   <Menu
        icon="more"
        className={classes.menu}
        // itemsWrapperClassName={classes.menuItemsWrapper}
        // items={items}
      /> */}
    </Box>
  );
};

export { CheckListCard };
