import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    width: '100%',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headRight: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  title: {
    color: colors.neutral900,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 8,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  menu: {
    padding: 0,
  },
  menuLabel: {
    color: colors.neutral700,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    '& ul li': {
      color: colors.neutral700,
    },
  },
  chart: {
    padding: 24,
    '& .apexcharts-legend': {
      gap: 20,
    },
    '& .apexcharts-legend-marker': {
      borderRadius: '50% !important',
      top: '1px !important',
      marginRight: '8px !important',
    },
    '& .apexcharts-legend-text': {
      fontSize: '14px !important',
    },
    '& .chart-tooltip': {
      padding: 16,
      width: 350,
      borderRadius: '8px !important',
      '& .date': {
        fontSize: '14px !important',
        lineHeight: '20px',
        color: colors.neutral600,
      },
      '& .appointments': {
        color: colors.neutral900,
        fontWeight: 700,
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 12,
      },
      '& .row-left': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .dot': {
        width: 12,
        height: 12,
        borderRadius: '50%',
        marginRight: 8,
        '&.dot-completed': {
          backgroundColor: colors.green600,
        },
        '&.dot-canceled': {
          backgroundColor: colors.neutral600,
        },
        '&.dot-noshow': {
          backgroundColor: colors.destructive600,
        },
        '&.dot-booked': {
          backgroundColor: colors.cyan500,
        },
        '&.dot-inactive': {
          backgroundColor: colors.indigo500,
        },
        '&.dot-proposed': {
          backgroundColor: colors.orange500,
        },
      },
      '& .title': {
        fontSize: '14px !important',
        color: colors.neutral600,
      },
      '& .row .value': {
        fontSize: 12,
        fontWeight: 700,
        borderRadius: '50%',
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${colors.neutral200}`,
      },
    },
  },
});
