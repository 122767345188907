import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import Box from '@mui/material/Box';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { ShareNoteBox } from '@confidant-health/lib/ui/templates/share-note-box/ShareNoteBox';
import { getAuth } from 'redux/modules/auth/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { profileActionCreators } from 'redux/modules/profile';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';
import { showSnackbar } from 'redux/modules/snackbar';
import { uuid4 } from 'lib/sendbird';
import { useStyles } from './EvaluationShareDrawer.styles';

type Props = {
  // data: any;
  // currentUserId: string;
  openDrawer: boolean;
  onClose: () => void;
  // onClose2: () => void;
};

const EvaluationShareDrawer = ({ openDrawer, onClose }: Props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { memberId, appointmentId, evaluationId } = useParams();
  const { meta } = useSelector(getAuth);
  const { providers, adminValidationTodos } = useSelector(getProfile);
  const targetContentIdentifier = `${appointmentId}|${evaluationId}`;
  const todosRecipients =
    adminValidationTodos?.length !== 0
      ? adminValidationTodos.filter(obj => obj.contentIdentifier === targetContentIdentifier)
      : [];
  const transformedObjects =
    todosRecipients.length !== 0
      ? todosRecipients?.map(obj => {
          return {
            key: uuid4(),
            dueDate: obj.dueDate ? dayjs(obj.dueDate, GLOBAL_DATE_FORMAT).toISOString() : undefined,
            message: obj.message || '',
            notifyViaSms: false,
            providerId: obj.assignedTo,
          };
        })
      : [];
  const [recipients, setRecipients] = useState(transformedObjects);
  const [dirty] = useState(false);

  useEffect(() => {
    console.log('updating');
  }, [recipients, adminValidationTodos]);

  const handleSubmit = () => {
    if (recipients?.length !== 0) {
      const isRecipientEmpty = recipients.filter(item => item.providerId === '');
      if (isRecipientEmpty?.length === 0) {
        const dateFormatRecipiants = recipients.map(recipient => {
          if (recipient.dueDate) {
            recipient.dueDate = dayjs(recipient.dueDate).format(GLOBAL_DATE_FORMAT);
          }
          delete recipient?.key;
          return recipient;
        });
        const bodyRequest = {
          appointmentId,
          memberId,
          assignerId: meta.userId,
          evaluationId,
          recipients: dateFormatRecipiants,
        };
        dispatch(profileActionCreators.shareAlfieValidation({ bodyRequest }));
        dispatch(profileActionCreators.fetchAdminValidationTodos());
        // setRecipients([]);
        onClose();
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Please add recipient to share validation.',
          }),
        );
      }
    } else {
      setRecipients([]);
      const bodyRequest = {
        appointmentId,
        memberId,
        assignerId: meta.userId,
        evaluationId,
        recipients: [],
      };
      dispatch(profileActionCreators.shareAlfieValidation({ bodyRequest }));
      dispatch(profileActionCreators.fetchAdminValidationTodos());
      onClose();
    }
  };

  const onAddRecipient = () => {
    setRecipients([
      ...recipients,
      {
        key: uuid4(),
        providerId: '',
        dueDate: undefined,
        message: '',
        notifyViaSms: false,
      },
    ]);
  };

  const renderSharingOptions = () => {
    const sharedProviderIds = recipients.map(recipient => recipient.providerId);
    return (
      <>
        <Box className={classes.block}>
          <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
            Share validation
          </Heading>
          <Box gap="24px" display="flex" flexDirection="column">
            {recipients.map((recipient, index) => {
              return (
                <ShareNoteBox
                  key={recipient.key}
                  sequenceNumber={Number(index) + 1}
                  providerId={recipient.providerId}
                  providers={providers
                    .filter(provider => {
                      if (provider.id === recipient.providerId) {
                        return true;
                      }
                      return !sharedProviderIds.includes(provider.id);
                    })
                    .map(provider => {
                      return {
                        label: provider.fullName,
                        value: provider.id,
                        avatar: provider.profileImage,
                      };
                    })}
                  dueDate={recipient.dueDate}
                  message={recipient.message}
                  notifyViaSms={recipient.notifyViaSms}
                  onSelectProvider={providerId => {
                    recipients[index].providerId = providerId;
                    setRecipients([...recipients]);
                  }}
                  onSelectDueDate={date => {
                    recipients[index].dueDate = date;
                    setRecipients([...recipients]);
                  }}
                  onChangeMessage={value => {
                    recipients[index].message = value;
                    setRecipients([...recipients]);
                  }}
                  onChangeNotifyToggle={checked => {
                    recipients[index].notifyViaSms = checked;
                    setRecipients([...recipients]);
                  }}
                  onDelete={() => {
                    const resultArray = [...recipients];
                    resultArray.splice(index, 1);
                    setRecipients(resultArray);
                  }}
                  dirty={dirty}
                />
              );
            })}
          </Box>
          <IconButton icon="forward" className={classes.addRecipientLink} onClick={onAddRecipient}>
            Add recipient
          </IconButton>
        </Box>
      </>
    );
  };

  return (
    <>
      <Drawer open={openDrawer} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Box>
              <Text weight={fontWeight.BOLD} className={classes.headTitle}>
                Share validation
              </Text>
            </Box>
            <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
          </Box>
          <Box className={classes.inputsContainer}>{renderSharingOptions()}</Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Continue</Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export { EvaluationShareDrawer };
