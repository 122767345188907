import { TableGrid } from '@confidant-health/lib/ui/organisms/table';

export const providerColumns: TableGrid.TableColumn[] = [
  { id: 'provider', label: 'Provider', sortable: false },
  { id: 'npiNumber', label: 'NPI number', sortable: false },
  { id: 'payers', label: 'Payers', sortable: false },
  { id: 'members', label: 'Members', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [
  { label: 'Active in chat', value: 'active_chat' },
  { label: 'Inactive in chat', value: 'inactive_chat' },
  { label: 'At Least 2 Sessions', value: '2_sessions' },
  { label: 'No Sessions', value: 'no_sessions' },
];
