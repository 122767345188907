import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import Box from '@mui/material/Box';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { profileActionCreators } from 'redux/modules/profile';
import { useStyles } from '../../../MemberDetail.styles';
import { UpdateFrequencyInfo } from '../index.types';

type Props = {
  data: any;
  currentUserId: string;
  openDrawer: boolean;
  onClose: () => void;
  onUpdated?: () => void;
};

const UpdateVisitFrequency = ({ openDrawer, onClose, onUpdated, data, currentUserId }: Props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [frequencySimpleList] = useState([
    { label: 'None', value: 'None' },
    { label: 'Daily', value: 'Daily' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Biweekly', value: 'Biweekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'As Needed', value: 'As needed' },
    { label: 'No Longer in Care', value: 'No longer in care' },
  ]);
  const [formData, setFormData] = useState<UpdateFrequencyInfo>({
    userId: data.userId,
    name: data.name,
    visitFrequency: data.visitFrequency,
  });
  const onChangeField = (field: string) => (e: any) => {
    setFormData(old => ({
      ...old,
      [field]: e.target?.value,
    }));
  };
  const handleSubmit = () => {
    const data1 = [
      {
        userId: formData.userId,
        name: formData.name,
        visitFrequency: formData.visitFrequency,
      },
    ];
    dispatch(profileActionCreators.updateCareTeam({ members: data1, userId: currentUserId }));
    /* try {
      //await setCareTeamMemberData({ members: data1 }, { userId: currentUserId });
    } catch (error) {
      console.log(error);
    } */
    onUpdated && onUpdated();
    onClose();
  };
  return (
    <>
      <Drawer
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        open={openDrawer}
        onClose={onClose}
        title="Update Visit Frequency"
        submitBtnTitle="Update"
        onSubmit={handleSubmit}
      >
        <Box className={classes.inputsContainer}>
          {/* <Box className={classes.inputWrapper}>
              <label htmlFor="userId">
                <Text level={textLevel.S} weight={fontWeight.BOLD}>
                  User ID
                </Text>
              </label>
              <Input
                name="userId"
                value={formData.userId || ''}
                onChange={onChangeField('userId')}
                size={inputSize.M}
                // placeholder="Nil"
                disabled
              />
            </Box> */}
          <Box className={classes.inputWrapper}>
            <label htmlFor="name">
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                Provider
              </Text>
            </label>
            <Input
              name="name"
              value={formData.name || ''}
              onChange={onChangeField('name')}
              size={inputSize.M}
              disabled
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              <span>Visit Frequency</span>
            </Text>
            <Select
              variant={selectType.SECONDARY}
              value={formData?.visitFrequency}
              onChange={onChangeField('visitFrequency')}
              options={frequencySimpleList}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default UpdateVisitFrequency;
