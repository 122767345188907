import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApexChart from 'react-apexcharts';
import clsx from 'clsx';
import { Box, ButtonGroup, Grid, Stack, useMediaQuery } from '@mui/material';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { theme } from '@confidant-health/lib/theme';
import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';
import { BaseLayout } from 'layouts/base';
import Heading from 'components/v2/Heading';
import BoxFrame from 'components/v2/BoxFrame';
import Typography from 'components/v2/Typography';
import { HeadingFont, HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import { typography } from 'components/v2/Typography/index.constant';
import { getAuth } from 'redux/modules/auth/selectors';
import { viewGroups } from './Todos.mock';
import { TODO_STATES, getChartOpts } from './Todos.constants';
import { useStyles } from './Todos.styles';
import MultiColorProgressBar from '../../admin/notes/multi-color-progress-bar/MultiColorProgressBar';
import { ActiveTodos } from './components/ActiveTodos';
import { CompletedTodos } from './components/CompletedTodos';

const Todos: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery('(max-width: 450px)');

  const { isAdmin, meta } = useSelector(getAuth);
  const { profile, todosStats } = useSelector(getProfile);
  const [viewBy, setViewBy] = useState(TODO_STATES.ACTIVE);

  useEffect(() => {
    const statsPayload = {
      assignedBy: '',
      assignedTo: profile.providerId,
    };
    dispatch(profileActionCreators.fetchTodoStats(statsPayload));
  }, []);

  const viewData = useMemo(
    () => (viewBy === TODO_STATES.ACTIVE ? todosStats.activeTodoStats : todosStats.completedTodoStats),
    [viewBy, todosStats],
  );

  const countsByType = useMemo(
    () => [
      {
        name: 'No due date',
        color: colors.indigo500,
        value: viewData?.withoutDueDateCount ?? 0,
      },
      {
        name: 'With due date',
        color: colors.cyan500,
        value: viewData?.withDueDateCount ?? 0,
      },
      {
        name: 'Past due',
        color: colors.destructive500,
        value: viewData?.pastDueDateCount ?? 0,
      },
    ],
    [viewData],
  );

  const chartWidth = useMemo(() => {
    if (isMobile) return 450;
    return 500;
  }, [isMobile]);

  const chartStyle = useMemo(() => {
    if (isMobile) return { top: '20px !important', right: '60px !important' };
    return { top: '42px !important', right: '-55px !important' };
  }, [isMobile]);

  const legendSeriesStyle = useMemo(() => {
    if (isMobile) return '0 0 7px !important';
    return '0 0 20px !important';
  }, [isMobile]);

  const onChangeView = type => () => {
    setViewBy(type);
  };

  const legendSeries = [
    {
      name: `No due Date`,
      color: colors.neutral600,
      data: [viewData.withoutDueDateCount],
    },
    {
      name: `Past due`,
      color: colors.destructive500,
      data: [viewData.withDueDateCount],
    },
    {
      name: `With due date`,
      color: colors.primary500,
      data: [viewData.withoutDueDateCount],
    },
  ];

  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            flexDirection: {
              md: 'row',
              xs: 'column',
            },
            gap: {
              md: 0,
              xs: 3,
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            sx={{
              justifyContent: {
                md: 'inherit',
                xs: 'space-between',
              },
            }}
          >
            <Heading
              variant={HeadingLevel.H1}
              fontFamily={HeadingFont.SECONDARY}
              fontWeight={HeadingWeight.BOLD}
            >
              To-dos
            </Heading>
            <Badge
              className={classes.totalCountBadge}
              variant={badgeType.OUTLINED}
              style={badgeStyle.UNRELATED}
            >
              {viewData.total} {viewBy === TODO_STATES.ACTIVE ? 'active' : 'completed'}
            </Badge>
          </Stack>
          <Stack sx={{ margin: '0px !important' }}>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              sx={{
                display: {
                  md: 'inherit',
                  xs: 'flex',
                },
                '& button': {
                  flex: {
                    md: 'none',
                    xs: 0.5,
                  },
                },
              }}
            >
              {viewGroups?.map(({ type, label }) => (
                <Button
                  key={type}
                  className={clsx(classes.btnGroupIcon, {
                    [classes.btnGroupIconActive]: type === viewBy,
                  })}
                  onClick={onChangeView(type)}
                >
                  {label}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
        </Stack>
        <Box display="flex" flexDirection="column" gap={4}>
          {viewData?.total > 0 && viewBy === TODO_STATES.ACTIVE && (
            <Box>
              {isTablet ? (
                <Box
                  className={classes.chartWrap}
                  sx={{
                    '& .apexcharts-legend-series': {
                      margin: legendSeriesStyle,
                    },
                    '& .apexcharts-legend.apexcharts-align-center.apx-legend-position-right': {
                      top: chartStyle.top,
                      right: chartStyle.right,
                    },
                    '& span.apexcharts-legend-marker': {
                      top: '0px !important',
                      width: '32px !important',
                      height: '32px !important',
                    },
                    '& span.apexcharts-legend-marker[rel="1"]::before': {
                      content: `'${viewData.withoutDueDateCount ?? 0}'`,
                      color: 'white !important',
                      fontSize: 14,
                      fontWeight: 500,
                      width: 32,
                      height: 32,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                    '& span.apexcharts-legend-marker[rel="2"]::before': {
                      content: `'${viewData.pastDueDateCount ?? 0}'`,
                      color: 'white !important',
                      fontSize: 14,
                      fontWeight: 500,
                      width: 32,
                      height: 32,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                    '& span.apexcharts-legend-marker[rel="3"]::before': {
                      content: `'${viewData.withDueDateCount ?? 0}'`,
                      color: 'white !important',
                      fontSize: 14,
                      fontWeight: 500,
                      width: 32,
                      height: 32,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                    '& .apexcharts-inner.apexcharts-graphical': {
                      transform: isMobile && 'translate(-8px, 0px)',
                    },
                  }}
                >
                  <ApexChart
                    width={chartWidth}
                    options={getChartOpts(
                      legendSeries,
                      {
                        total: viewData.total,
                        title: 'Total',
                        size: '80%',
                      },
                      true,
                      `${viewData.total} total`,
                    )}
                    series={[
                      viewData.withoutDueDateCount,
                      viewData.pastDueDateCount,
                      viewData.withDueDateCount,
                    ]}
                    type="donut"
                    height={340}
                  />
                </Box>
              ) : (
                <BoxFrame>
                  <Box display="flex" flexDirection="column" gap={3} p={3}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" gap={4} alignItems="center">
                        {countsByType?.map((item, index) => (
                          <Box key={index} display="flex" gap={1.5} alignItems="center">
                            <Box className={classes.badge} sx={{ background: item.color, px: 1.5, py: 0.5 }}>
                              <Typography {...typography.body.xs.bold} color={colors.white}>
                                {item.value}
                              </Typography>
                            </Box>
                            <Typography {...typography.body.s.medium} color={colors.neutral700}>
                              {item.name}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                      <Typography {...typography.body.s.medium}>{viewData?.total} total</Typography>
                    </Box>
                    <Box sx={{ '&>div': { margin: 0 } }}>
                      <MultiColorProgressBar readings={countsByType} totalValue={viewData?.total} />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    p={3}
                    sx={{ borderTop: `1px solid ${colors.neutral100}` }}
                  >
                    <Grid container spacing={3}>
                      {!isAdmin && (
                        <>
                          <Grid item xs={12} md={3}>
                            <Box display="flex" gap={2} alignItems="center">
                              <Box className={classes.box}>
                                <Heading variant={HeadingLevel.H5} fontWeight={HeadingWeight.BOLD}>
                                  {viewData?.signOffCount}
                                </Heading>
                              </Box>
                              <Box>
                                <Typography {...typography.body.m.semibold}>Sign off to-dos</Typography>
                                <Typography {...typography.body.s.medium}>
                                  {viewData?.avgCompletionTimeSignOff} avg completion time
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Box display="flex" gap={2} alignItems="center">
                              <Box className={classes.box}>
                                <Heading variant={HeadingLevel.H5} fontWeight={HeadingWeight.BOLD}>
                                  {viewData?.evaluationsCount}
                                </Heading>
                              </Box>
                              <Box>
                                <Typography {...typography.body.m.semibold}>
                                  Evaluation mention to-dos
                                </Typography>
                                <Typography {...typography.body.s.medium}>
                                  {viewData?.avgCompletionTimeEvaluations} avg completion time
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      )}
                      {(!isAdmin || (isAdmin && meta.authority === 'CARE_NAVIGATOR')) && (
                        <Grid item xs={12} md={3}>
                          <Box display="flex" gap={2} alignItems="center">
                            <Box className={classes.box}>
                              <Heading variant={HeadingLevel.H5} fontWeight={HeadingWeight.BOLD}>
                                {viewData?.chatCount}
                              </Heading>
                            </Box>
                            <Box>
                              <Typography {...typography.body.m.semibold}>Chat mention to-dos</Typography>
                              <Typography {...typography.body.s.medium}>
                                {viewData?.avgCompletionTimeChat} avg completion time
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12} md={3}>
                        <Box display="flex" gap={2} alignItems="center">
                          <Box className={classes.box}>
                            <Heading variant={HeadingLevel.H5} fontWeight={HeadingWeight.BOLD}>
                              {viewData?.notesCount}
                            </Heading>
                          </Box>
                          <Box>
                            <Typography {...typography.body.m.semibold}>Case note to-dos</Typography>
                            <Typography {...typography.body.s.medium}>
                              {viewData?.avgCompletionTimeNotes} avg completion time
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </BoxFrame>
              )}
            </Box>
          )}
          {viewBy === TODO_STATES.ACTIVE ? <ActiveTodos /> : <CompletedTodos />}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { Todos };
