import { FC } from 'react';
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IGlyphs, Icons } from '@confidant-health/lib/icons';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';

import { useStyles } from './AppointmentDetail.styles';

type Props = {
  icon: IGlyphs['glyph'];
  open: boolean;
  title: string;
  content: string;
  lbtnLabel?: string;
  rbtnLabel?: string;
  isLoading: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit: () => void;
};

const ConfirmModal: FC<Props> = ({
  icon,
  open,
  title,
  content,
  lbtnLabel,
  rbtnLabel,
  onClose,
  isLoading,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          {icon && (
            <Box className={classes.editIconWrap}>
              <Icons glyph={icon} className={classes.editIcon} />
            </Box>
          )}
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.modalTitle}>{title}</Heading>
              <Text className={classes.subtitle}>{content}</Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button
                className={clsx(classes.modalBtn, classes.cancelBtn)}
                disabled={isLoading}
                onClick={onCancel || onClose}
                variant={btnType.OUTLINE}
              >
                {lbtnLabel ?? 'Cancel'}
              </Button>
              <Button
                className={clsx(classes.modalBtn, classes.okBtn)}
                disabled={isLoading}
                onClick={onSubmit}
              >
                {rbtnLabel ?? 'Ok'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
