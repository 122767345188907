/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';

interface Bucket1 {
  key_as_string: string;
  key: string;
  doc_count: number;
}
interface Bucket2 {
  key: string;
  doc_count: number;
}
interface MemberId {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: Bucket2[];
}
interface Bucket3 {
  key: string;
  doc_count: number;
  member_id: MemberId;
}
interface Total {
  value: number;
  relation: string;
}
interface Count {
  buckets: Bucket1[];
}
interface Assignment_Last_30_Days {
  count: Count;
}
interface Last_30_Days_Total_Assignments {
  meta: any;
  doc_count: number;
}
interface Breakdown_By_Method {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: Bucket2[];
}
interface Breakdown_By_Progress {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: Bucket3[];
}
interface Aggregations {
  assignments_per_day_last_60_days: Assignment_Last_30_Days;
  assignments_per_day_7_days: Assignment_Last_30_Days;
  assignments_per_day_last_3_months: Assignment_Last_30_Days;
  assignments_per_day_last_6_months: Assignment_Last_30_Days;
  assignments_per_day_last_12_months: Assignment_Last_30_Days;
  last_30_days_total_assignments: Last_30_Days_Total_Assignments;
  breakdown_by_method: Breakdown_By_Method;
  assignments_per_day_last_30_days: Assignment_Last_30_Days;
  breakdown_by_progress: Breakdown_By_Progress;
  total_assignments: Total;
}
export interface IPlanItemDetail {
  hits: any;
  aggregations: Aggregations;
}

export const typeSelects = [
  { label: 'Topic', value: 'topic' },
  { label: 'Education', value: 'education' },
];

export const navigatesToSelects = [
  { label: 'Navigates to topic screen', value: 'topic_screen' },
  { label: 'Navigates to education screen', value: 'education_screen' },
];

export const progressStateSelects = [
  { label: 'View selected topic', value: 'progress_topic' },
  { label: 'View selected education', value: 'progress_education' },
];

export const completedStateSelects = [
  { label: 'View each education within a selected topic', value: 'complete_topic' },
  { label: 'View each education within a selected education', value: 'complete_education' },
];

export const removeFromPlanSelects = [
  { label: 'Removed by member', value: 'by_member' },
  { label: 'Removed by provider', value: 'by_provider' },
];

export const planItemDetailElkQuery = () => {
  return {
    size: 0,
    aggs: {
      last_30_days_total_assignments: {
        filter: {
          range: {
            assignedAt: {
              gte: 'now-30d/d',
            },
          },
        },
      },
      total_assignments: {
        value_count: {
          field: 'assignedAt',
        },
      },
      assignments_per_day_7_days: {
        filter: {
          range: {
            assignedAt: {
              gte: 'now-7d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'assignedAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_30_days: {
        filter: {
          range: {
            assignedAt: {
              gte: 'now-30d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'assignedAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_3_months: {
        filter: {
          range: {
            assignedAt: {
              gte: 'now-90d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'assignedAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_6_months: {
        filter: {
          range: {
            assignedAt: {
              gte: 'now-180d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'assignedAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_12_months: {
        filter: {
          range: {
            assignedAt: {
              gte: 'now-365d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'assignedAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      breakdown_by_method: {
        terms: {
          field: 'assignedByAuthority.keyword',
        },
      },
      breakdown_by_progress: {
        terms: {
          field: 'status.keyword',
          size: 10,
        },
        aggs: {
          member_id: {
            terms: {
              field: 'member_id.keyword',
              size: 10,
            },
          },
        },
      },
    },
  };
};

export const planItemRecentAssignmentElkQuery = (
  planItemId: string,
  searchKeyword: string,
  pageNumber = 0,
  pageSize = 10,
) => {
  if (!planItemId) {
    return null;
  }
  return {
    _source: [
      'member_fullname',
      'assignedByAuthority',
      'status',
      'assignedAt',
      'member_first_name',
      'member_last_name',
      'member_id',
      'revampPlanItemsId',
      'revampPlanItemName',
      'revampPlanITemNavigateTo',
    ],
    size: pageSize,
    from: (pageNumber - 1) * pageSize,
    query: {
      bool: searchKeyword
        ? {
            must: [{ match: { 'revampPlanItemsId.keyword': planItemId } }],
            should: [
              {
                regexp: {
                  'member_fullname.keyword': {
                    value: `.*${searchKeyword}.*`,
                    case_insensitive: true,
                  },
                },
              },
              {
                regexp: {
                  'member_first_name.keyword': {
                    value: `.*${searchKeyword}.*`,
                    case_insensitive: true,
                  },
                },
              },
              {
                regexp: {
                  'member_last_name.keyword': {
                    value: `.*${searchKeyword}.*`,
                    case_insensitive: true,
                  },
                },
              },
            ],
            minimum_should_match: 1,
          }
        : {
            must: [{ match: { 'revampPlanItemsId.keyword': planItemId } }],
          },
    },
    sort: [{ assignedAt: 'asc' }],
  };
};

export const getFormatedRecentAssignedData = (recentAssignedData: any) => {
  if (recentAssignedData?.hits?.length <= 0) {
    return null;
  }
  // return recentAssignedData?.hits?.map(hit => hit._source);
  return recentAssignedData?.hits?.map(hit => ({
    member: {
      name: hit?._source?.member_fullname || '',
      fullname: `${hit?._source?.member_first_name || ''} ${hit?._source?.member_last_name || ''}`,
      memberId: hit?._source?.member_id,
      // thumbnail: 'https://i.pravatar.cc/300',
    },
    assignedBy: hit?._source?.assignedByAuthority,
    status: hit?._source?.status?.toLowerCase(),
    date: dayjs(hit?._source?.assignedAt).format(GLOBAL_DATE_FORMAT),
  }));
};
interface DateCount {
  date: string;
  count: number;
}
interface KeyCount {
  key: string;
  count: number;
}
interface KeyCountMembers {
  key: string;
  count: number;
  members: KeyCount[];
}
interface Assignments {
  current30Days: DateCount[];
  last30Days: DateCount[];
  last3Months: DateCount[];
  last6Months: DateCount[];
  last12Months: DateCount[];
  last7Days: DateCount[];
}
export interface IFormatedPlanItemDetail {
  assignmentLast30Days: number;
  totalAssignments: number;
  assignments: Assignments;
  breakdownByMethod: KeyCount[];
  breakdownByProgress: KeyCountMembers[];
  totalRecords: number;
}
export const getFormatedPlanItemDetail = (planItemDetail: IPlanItemDetail) => {
  if (!planItemDetail) {
    return null;
  }
  return {
    assignmentLast30Days: planItemDetail?.aggregations?.last_30_days_total_assignments?.doc_count || 0,
    totalAssignments: planItemDetail?.aggregations?.total_assignments?.value || 0,
    assignments: {
      last7Days: planItemDetail?.aggregations?.assignments_per_day_7_days?.count?.buckets?.map(bucket => ({
        date: bucket.key_as_string,
        count: bucket.doc_count,
      })),
      current30Days: planItemDetail?.aggregations?.assignments_per_day_last_30_days?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last3Months: planItemDetail?.aggregations?.assignments_per_day_last_3_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last6Months: planItemDetail?.aggregations?.assignments_per_day_last_6_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last12Months: planItemDetail?.aggregations?.assignments_per_day_last_12_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
    },
    breakdownByMethod: planItemDetail?.aggregations?.breakdown_by_method?.buckets?.map(bucket => ({
      key: bucket.key,
      count: bucket.doc_count,
    })),
    breakdownByProgress: planItemDetail?.aggregations?.breakdown_by_progress?.buckets?.map(bucket => ({
      key: bucket.key,
      count: bucket.doc_count,
      members: bucket.member_id?.buckets?.map(bkt => ({ key: bkt.key, count: bkt.doc_count })),
    })),
    totalRecords: planItemDetail?.hits?.total?.value,
  } as IFormatedPlanItemDetail;
};

export const planItemRecentAssignemtColumns: TableGrid.TableColumn[] = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'assignedBy', label: 'Assigned by', sortable: false },
  { id: 'status', label: 'Progress status', sortable: false, className: 'color-secondary-500' },
  { id: 'date', label: 'Date', sortable: false },
];
