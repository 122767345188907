import Appointments from './appointments';
import Availability from './availability';
import Connections from './connections';
import GeneralInfo from './general-info';
import Groups from './groups';
import Services from './services';

type ComponentT = React.FC<{
  selectedScreen: string;
}>;

const SelectedComponent: ComponentT = ({ selectedScreen }) => {
  switch (selectedScreen) {
    case 'general-information':
      return <GeneralInfo />;
    case 'connections':
      return <Connections />;
    case 'appointments-current':
    case 'appointments-past':
    case 'appointments-pending':
      return <Appointments />;
    case 'availability':
      return <Availability />;
    case 'groups':
      return <Groups />;
    case 'services':
      return <Services />;
    default:
      return <div>In Progress...</div>;
  }
};

export default SelectedComponent;
