/* eslint-disable max-len */
import { ProfileSectionIds } from 'pages/provider/profile/Profile.constants';

export const profileSections: { id: string; title: string }[] = [
  {
    id: ProfileSectionIds.general,
    title: 'General information',
  },
  {
    id: ProfileSectionIds.approach,
    title: 'Approach',
  },
  {
    id: ProfileSectionIds.availableStates,
    title: 'Available states',
  },
  {
    id: ProfileSectionIds.certifications,
    title: 'Certifications',
  },
  {
    id: ProfileSectionIds.credentials,
    title: 'Credentials',
  },
  {
    id: ProfileSectionIds.clinicAffiliation,
    title: 'Clinic affiliation',
  },
  {
    id: ProfileSectionIds.pastEmployment,
    title: 'Past employment',
  },
  {
    id: ProfileSectionIds.education,
    title: 'Education',
  },
  {
    id: ProfileSectionIds.resetPassword,
    title: 'Reset password',
  },
];

export const certificationsMock = [
  { label: 'PMHNP-BC', value: 'PMHNP-BC' },
  { label: 'ABC', value: 'ABC' },
  { label: 'APRN', value: 'APRN' },
  { label: 'BCD', value: 'BCD' },
  { label: 'DEF', value: 'DEF' },
  { label: 'GHK', value: 'GHK' },
  { label: 'LMN', value: 'LMN' },
  { label: 'OPR', value: 'OPR' },
  { label: 'STU', value: 'STU' },
  { label: 'VWX', value: 'VWX' },
];

export const availableStatesMock = [
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'PMHNP-BC', value: 'PMHNP-BC' },
];

export const credentialsMock = [
  { label: 'ABC', value: 'ABC' },
  { label: 'BCD', value: 'BCD' },
  { label: 'DEF', value: 'DEF' },
  { label: 'GHK', value: 'GHK' },
  { label: 'LMN', value: 'LMN' },
  { label: 'OPR', value: 'OPR' },
  { label: 'STU', value: 'STU' },
  { label: 'VWX', value: 'VWX' },
];
