import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  noItem: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    color: colors.neutral600,
  },
  errorText: {
    color: colors.destructive500,
  },
});
