/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading, headingLevel, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
// import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
// import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { HistoryLookup } from 'redux/modules/profile/types';
import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';
import { getDomainElements } from 'services/member/member.service';

// import SelectUnit from '../../select-unit';
import SelectLevel from '../../select-level';

// styles
import { useStyles } from './MedicationForm.styles';
import { IMedication } from '../medication-card/MedicationCard.types';

import { ReportItem } from '../../AddNewReport/AddNewReport.types';

// import { units } from '../../select-unit/SelectUnit.constants';

type Props = {
  lookupData?: HistoryLookup;
  form: any;
  error?: string;
  options?: ReportItem[];
  medication?: IMedication;
  symptomsList?: string[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MedicationForm: FC<Props> = ({ form, error, symptomsList, lookupData, options, medication }) => {
  const [importanceLevels, setImportanceLevels] = useState([]);
  const { providers } = useSelector(getProfile);
  const providerListOptions = providers.map(provider => ({
    label: provider.fullName,
    value: provider.providerId,
  }));

  const frequencies = [
    'Daily',
    'Twice a Day',
    'Three times a Day',
    'Every other Day',
    'As needed',
    'Other',
  ].map(frequency => ({ label: frequency, value: frequency }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  }, []);
  //   useEffect(() => {
  //   if (options.length > 0) {
  //     const selectedDiagnosis = options[0].relatedElements?.filter(
  //       option => option?.Id === item?.domainElementId,
  //     );

  //     // const selectedRelatedElement = selectedDiagnosis[0]?.relatedElements?.filter(
  //     //   element => element.Id === item.item.value,
  //     // );
  //     // console.log('selected diagnosis', selectedRelatedElement);
  //     const tempImportanceLevels = [];
  //     Object.keys(selectedDiagnosis[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
  //       if (selectedDiagnosis[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
  //         tempImportanceLevels.push({ value: key, label: key });
  //       }
  //     });

  //     // setImportanceLevels(tempImportanceLevels);
  //     // console.log(newValue);
  //   }
  // }, [options]);

  useEffect(() => {
    if (medication) {
      if (medication?.domainElementId) {
        const tempImportanceLevels = [];
        getDomainElements({ domainTypeId: medication?.domainElementId })
          .then(res => {
            if (res.status === 200) {
              Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                  tempImportanceLevels.push({ value: key, label: key });
                }
              });
              setImportanceLevels(tempImportanceLevels);
            }
          })
          .catch(err => {
            console.log({ err });
          });
      } else {
        const selectedDiagnosis = options?.filter(option => option?.groupId === medication?.groupId);
        const selectedRelatedElement = selectedDiagnosis[0]?.relatedElements?.filter(
          element => element.Id === medication.item.value,
        );

        // console.log('selected related element', selectedRelatedElement);

        const tempImportanceLevels = [];
        // const tempIcd10List = [];
        Object.keys(selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
          if (selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
            tempImportanceLevels.push({ value: key, label: key });
          }
        });
        // if (selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes) {
        //   selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes.forEach(val => {
        //     tempIcd10List.push({ value: val, label: val });
        //   });

        // setIcd10(selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes);
        // }
        setImportanceLevels(tempImportanceLevels);
      }
    }

    // setIcd10(tempIcd10List);
  }, []);
  const classes = useStyles();
  const { values, touched, errors, setFieldValue, handleChange, ...rest } = form;

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (str: string, className = '') => (
    <Heading level={headingLevel.S} className={clsx(classes.label, className)} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  useEffect(() => {
    if (values?.status === '') {
      setFieldValue('status', 'INACTIVE');
    }
  }, [values]);

  return (
    <Box className={classes.formContent}>
      {error && (
        <Box display="flex" justifyContent="center">
          <TextError errorMsg={error} />
        </Box>
      )}
      <Box className={classes.section}>
        {renderLabel('Importance level')}
        <SelectLevel
          name="status"
          value={values.status}
          isLifeEvent
          options={importanceLevels}
          onChange={handleChange}
        />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Dosage')}
        <Input
          fullWidth
          name="dosage"
          value={values.dosage}
          size={inputSize.M}
          placeholder="Enter dosage"
          onChange={handleChange}
          onBlur={setTouched('dosage')}
        />
        <TextError errorMsg={touched.dosage ? errors.dosage?.toString() : null} />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Frequency')}
        <Select
          name="frequency"
          value={values.frequency}
          variant={selectType.SECONDARY}
          options={frequencies?.sort((a, b) => a.label.localeCompare(b.label))}
          emptyText="Select item"
          displayEmpty
          onChange={handleChange}
        />
        <TextError errorMsg={touched.frequency ? errors.frequency?.toString() : null} />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Duration')}
        <Input
          fullWidth
          name="medDuration"
          value={values.medDuration}
          size={inputSize.M}
          placeholder="Enter duration"
          onChange={handleChange}
          onBlur={setTouched('medDuration')}
        />
        <TextError errorMsg={touched.medDuration ? errors.medDuration?.toString() : null} />
      </Box>
      {symptomsList?.length > 0 && (
        <Box className={classes.section}>
          {renderLabel('Symptoms being treated')}
          <Input
            // freeSolo
            fullWidth
            name="symptoms"
            value={values.symptoms}
            options={symptomsList}
            variant={inputType.TAGS}
            size={inputSize.M}
            getOptionLabel={(option: string) => option}
            onBlur={setTouched('symptoms')}
            onChange={async (_: SyntheticEvent, newValue: string) => {
              await setFieldValue('symptoms', newValue);
            }}
          />
          <TextError errorMsg={touched.symptoms ? errors.symptoms?.toString() : null} />
        </Box>
      )}
      <Box className={classes.section}>
        {renderLabel('Prescribing Provider')}
        <Select
          name="provider"
          value={values.provider}
          variant={selectType.SECONDARY}
          options={[
            { label: 'Confidant Provider', value: 'Confidant Provider' },
            { label: 'External Provider', value: 'External Provider' },
          ]}
          onChange={handleChange}
        />
        <TextError errorMsg={touched.provider ? errors.provider?.toString() : null} />
      </Box>
      {values?.provider === 'Confidant Provider' ? (
        <Box className={classes.section}>
          {renderLabel('Confidant Provider')}
          <Select
            name="confidantProvider"
            value={values.confidantProvider}
            variant={selectType.SECONDARY}
            options={providerListOptions?.sort((a, b) => a.label.localeCompare(b.label)) || []}
            emptyText="Select item"
            displayEmpty
            onChange={handleChange}
            // testST1234!@#$
          />
          <TextError errorMsg={touched.confidantProvider ? errors.confidantProvider?.toString() : null} />
        </Box>
      ) : (
        <Box className={classes.section}>
          {renderLabel('Provider Name')}
          <Input
            fullWidth
            name="providerName"
            value={values.providerName}
            size={inputSize.M}
            placeholder="Enter provider name"
            onChange={handleChange}
            onBlur={setTouched('providerName')}
          />
          <TextError errorMsg={touched.providerName ? errors.providerName?.toString() : null} />
        </Box>
      )}
      {/* <Box display="flex" justifyContent="space-between" gap="16px">
        <Box className={classes.section}>
          {renderLabel('Dose')}
          <SelectUnit
            value={values.dose.amount as any}
            unit={values.dose.unit}
            options={lookupData?.doseUnit.map(item => ({ label: item.value, value: item.name }))}
            onChange={onChangeSelectUnit('dose')}
          />
          <TextError errorMsg={errors.dose?.amount} />
        </Box>
        <Box className={classes.section}>
          {renderLabel('Frequency')}
          <SelectUnit
            value={values.frequency.amount as any}
            unit={values.frequency.unit}
            options={units.frequency}
            disabledUnit
            onChange={onChangeSelectUnit('frequency')}
          />
          <TextError errorMsg={errors.frequency?.amount} />
        </Box>
        <Box className={classes.section}>
          {renderLabel('Duration')}
          <SelectUnit
            value={values?.duration?.amount as any}
            unit={values.duration.unit}
            options={lookupData?.supplyUnit.map(item => ({ label: item.value, value: item.name }))}
            onChange={onChangeSelectUnit('duration')}
          />
          <TextError errorMsg={errors.duration?.amount} />
        </Box>
      </Box>
      <Box className={classes.section}>
        <FormControlLabel
          control={<Checkbox />}
          checked={values.refillable}
          name="refillable"
          onChange={() => {
            void setFieldValue('refillable', !values.refillable);
          }}
          label="Medication is refillable"
        />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Refillable', classes.disableLabel)}
        <SelectUnit
          value={values.refillTimes.amount as any}
          unit={values.refillTimes.unit}
          // disabled={!values.refillable}
          disabled
          onChange={onChangeSelectUnit('refillTimes')}
        />
        <TextError errorMsg={errors.refillTimes?.amount} />
      </Box>
             */}
      <Box className={classes.section}>
        {renderLabel('Report notes')}
        <Textarea
          value={values.notes}
          name="notes"
          onChange={handleChange}
          onBlur={setTouched('notes')}
          minRows={5}
          placeholder="Enter your notes"
        />
        <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
      </Box>
    </Box>
  );
};

export { MedicationForm };
