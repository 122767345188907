/* eslint-disable react/destructuring-assignment */
import { FC, useState, useEffect, useMemo, ReactNode } from 'react';
import clsx from 'clsx';
import { useDownloadExcel } from 'table-to-excel-react';

import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box, Grid, Stack } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { boxType, DashboardBox } from '@confidant-health/lib/ui/templates/dashboard-box';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';

import { IReport } from 'redux/modules/reporting/types';
import { getElkChatbotReport, getElkReportsCount } from 'services/reporting/reporting.service';
import RecentAssignmentsTable from 'pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import HeadCard from '../head-card';
import ChatbotDetail from './chatbot-detail';
import { IChatbotReport } from './Chatbots.types';
import AssignmentsChart from '../assignments-chart';
import { ViewAllDrawer } from '../data-collection-templates/ViewAllDrawer';
import ChartSeries from './chart-series';

import {
  memberColumns,
  attempColumns,
  getChatbotReportQuery,
  getBoxData,
  getLast30DaysAssigned,
  getLast30DaysCompleted,
  getByNumberOfMembersData,
  getByCompletionAttemptsData,
  getChartSeries,
} from './Chatbots.constants';
import { useStyles } from '../profile-elements/ProfileElements.styles';
import { getReportsCountQuery, REPORT_COUNT_QUERY_KEYWORDS } from '../../ReportDetail.constants';

type Props = {
  report: IReport;
  showNewReport: boolean;
  componentRef: any;
};

const Chatbots: FC<Props> = ({ report, showNewReport, componentRef }) => {
  const classes = useStyles();
  const [showViewAllType, setShowViewAllType] = useState(null);
  const [selectedItem, setSelectedItemDetail] = useState(null);
  const [chatbotReport, setChatbotReport] = useState<IChatbotReport>(null);
  const { onDownload } = useDownloadExcel({
    fileName: 'chatbots_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const boxData = useMemo(() => getBoxData(chatbotReport, report), [chatbotReport, report]);
  const last30DaysAssigned = useMemo(() => getLast30DaysAssigned(chatbotReport), [chatbotReport]);
  const last30DaysCompleted = useMemo(() => getLast30DaysCompleted(chatbotReport), [chatbotReport]);
  const byNumberOfMembersData = useMemo(() => getByNumberOfMembersData(chatbotReport), [chatbotReport]);
  const byCompletionAttemptsData = useMemo(() => getByCompletionAttemptsData(chatbotReport), [chatbotReport]);
  const chartSeries = useMemo(() => getChartSeries(chatbotReport), [chatbotReport]);
  const chatbotSection = useMemo(() => report?.data?.chatbotSection, [report]);

  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await getElkChatbotReport(getChatbotReportQuery(report), null);
        setChatbotReport(resp.data);
        const countResponse = await getElkReportsCount(
          getReportsCountQuery(REPORT_COUNT_QUERY_KEYWORDS.DEFAULT),
          null,
        );
        setChatbotReport({ ...resp.data, total_chatbots_count: countResponse?.data?.count });
      })();
  }, [showNewReport]);

  const onCloseViewDetail = () => {
    setSelectedItemDetail(null);
  };

  const onClickAll = (type: string) => () => {
    setShowViewAllType(type || showViewAllType);
  };

  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };

  const onRowClick = item => {
    setSelectedItemDetail(item);
  };

  // eslint-disable-next-line react/display-name
  const renderMenu = () => () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };

  const getContentsInViewAll = (): {
    title: string;
    subtitle: string;
    tableColumns: TableGrid.TableColumn[];
    data: unknown[];
    renderMenuAction: (payload: any) => ReactNode;
    onRowClick: (item: any) => void;
  } => {
    if (showViewAllType === 'members') {
      return {
        title: 'By number of members',
        subtitle: `${byNumberOfMembersData.length} total`,
        tableColumns: memberColumns,
        data: byNumberOfMembersData,
        onRowClick,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === 'attemps') {
      return {
        title: 'By completion attempts',
        subtitle: `${byCompletionAttemptsData.length} total`,
        tableColumns: attempColumns,
        data: byCompletionAttemptsData,
        onRowClick,
        renderMenuAction: renderMenu(),
      };
    }
    return null;
  };

  const renderTable = (title, columns, data, onClickViewAll, renderMenuAction, className = '') => (
    <Box className={clsx(classes.tableWrap, className)} flex={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
          {title}
        </Text>
        <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
          View top ({data.length})
        </IconButton>
      </Stack>
      <RecentAssignmentsTable
        className={classes.tableBordered}
        isShowAll={false}
        tableColumns={columns}
        onRowClick={onRowClick}
        data={data}
        renderMenuAction={renderMenuAction}
      />
    </Box>
  );

  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {...[
          {
            title: 'By Number of Members',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: memberColumns,
            data: byNumberOfMembersData as any,
          },
          {
            title: 'By Completion Attempts',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: attempColumns,
            data: byCompletionAttemptsData as any,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };
  return (
    <>
      {renderAllTables()}
      <ChatbotDetail open={!!selectedItem} onClose={onCloseViewDetail} item={selectedItem} />
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <Box className={classes.card}>
        <HeadCard title="Chatbots" componentRef={componentRef} onDownloadClick={onDownload} />
        <Box className={classes.card}>
          <Box className={classes.reports}>
            <Grid container spacing={2}>
              {boxData.map(box => (
                <Grid item xs={4} key={box.label}>
                  <DashboardBox className={classes.reportItem} {...box} type={boxType.TOTAL} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {chatbotSection.chatbotsInLast30Days?.isChecked && (
            <Box className={classes.chartWrap}>
              <AssignmentsChart
                title="Assigned VS Completed in the last 30 days"
                className={classes.chart}
                chart={{
                  series: [
                    { name: 'Assigned', color: colors.primary500, data: last30DaysAssigned },
                    { name: 'Completed', color: colors.success500, data: last30DaysCompleted },
                  ],
                  tooltipConfig: {
                    isShowTotal: false,
                    title: 'Assigned',
                  },
                }}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {chatbotSection.mostMembers?.isChecked &&
              renderTable(
                'By number of members',
                memberColumns,
                byNumberOfMembersData,
                onClickAll('members'),
                renderMenu(),
                !chatbotSection.mostCompleted?.isChecked ? classes.halfTable : '',
              )}
            {chatbotSection.mostCompleted?.isChecked &&
              renderTable(
                'By completion attempts',
                attempColumns,
                byCompletionAttemptsData,
                onClickAll('attemps'),
                renderMenu(),
                !chatbotSection.mostMembers?.isChecked ? classes.halfTable : '',
              )}
          </Box>
          {chatbotSection.chatbotsList?.isChecked && (
            <ChartSeries data={chartSeries} pagination onHeadingClick={onRowClick} />
          )}
        </Box>
      </Box>
    </>
  );
};

export { Chatbots };
