export enum ProfileSectionIds {
  general = 'general-information',
  approach = 'approach',
  availableStates = 'available-states',
  certifications = 'certifications',
  credentials = 'credentials',
  clinicAffiliation = 'clinic-affiliation',
  pastEmployment = 'past-employment',
  education = 'education',
  services = 'services',
  generalAvailability = 'general-availability',
  resetPassword = 'reset-password',
}

export const profileSections: { id: string; title: string }[] = [
  {
    id: ProfileSectionIds.general,
    title: 'General information',
  },
  {
    id: ProfileSectionIds.approach,
    title: 'Approach',
  },
  {
    id: ProfileSectionIds.availableStates,
    title: 'Available states',
  },
  {
    id: ProfileSectionIds.certifications,
    title: 'Certifications',
  },
  {
    id: ProfileSectionIds.credentials,
    title: 'Credentials',
  },
  {
    id: ProfileSectionIds.generalAvailability,
    title: 'General availability',
  },
  {
    id: ProfileSectionIds.services,
    title: 'Services',
  },
];

export const rightSidebarNavItems = [
  {
    id: 'general-information',
    value: 'General information',
  },
  {
    id: 'approach',
    value: 'Approach',
  },
  {
    id: 'available-states',
    value: 'Available states',
  },
  {
    id: 'certifications',
    value: 'Certifications',
  },
  {
    id: 'credentials',
    value: 'Credentials',
  },
  // {
  //   id: 'clinic-affiliation',
  //   value: 'Clinic affiliation',
  // },
  // {
  //   id: 'past-employment',
  //   value: 'Past employment',
  // },
  // {
  //   id: 'education',
  //   value: 'Education',
  // },
  {
    id: 'general-availability',
    value: 'General availability',
  },
  {
    id: 'services',
    value: 'Services',
  },
];

export const certificationsMock = [
  { label: 'PMHNP-B', value: 'PMHNP-B' },
  { label: 'ABC', value: 'ABC' },
  { label: 'APRN', value: 'APRN' },
  { label: 'BCD', value: 'BCD' },
  { label: 'DEF', value: 'DEF' },
  { label: 'GHK', value: 'GHK' },
  { label: 'LMN', value: 'LMN' },
  { label: 'OPR', value: 'OPR' },
  { label: 'STU', value: 'STU' },
  { label: 'VWX', value: 'VWX' },
];

export const credentialsMock = [
  { label: 'DN', value: 'DN' },
  { label: 'APR', value: 'APR' },
  { label: 'DEF', value: 'DEF' },
  { label: 'GHK', value: 'GHK' },
  { label: 'LMN', value: 'LMN' },
  { label: 'OPR', value: 'OPR' },
  { label: 'STU', value: 'STU' },
  { label: 'VWX', value: 'VWX' },
];

export const US_TIMEZONES = [
  {
    label: 'America/New_York',
    value: 'America/New_York',
    states: [
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Kentucky',
      'Maine',
      'Maryland',
      'Massachusetts',
      'New Hampshire',
      'New Jersey',
      'New York',
      'North Carolina',
      'Ohio',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
    ],
  },
  {
    label: 'America/Chicago',
    value: 'America/Chicago',
    states: [
      'Alabama',
      'Arkansas',
      'Illinois',
      'Iowa',
      'Kansas',
      'Louisiana',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Nebraska',
      'North Dakota',
      'Oklahoma',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Wisconsin',
    ],
  },
  {
    label: 'US/Alaska',
    value: 'US/Alaska',
    states: ['Alaska'],
  },
  {
    label: 'America/Phoenix',
    value: 'America/Phoenix',
    states: ['Arizona'],
  },
  {
    label: 'America/Los_Angeles',
    value: 'America/Los_Angeles',
    states: ['California', 'Nevada', 'Oregon'],
  },
  {
    label: 'America/Denver',
    value: 'America/Denver',
    states: ['Colorado', 'Montana', 'New Mexico', 'Utah', 'Wyoming'],
  },
  {
    label: 'US/Hawaii',
    value: 'US/Hawaii',
    states: ['Hawaii'],
  },
  {
    label: 'America/Boise',
    value: 'America/Boise',
    states: ['Idaho'],
  },
  {
    label: 'America/Indianapolis',
    value: 'America/Indianapolis',
    states: ['Indiana'],
  },
  {
    label: 'America/Detroit',
    value: 'America/Detroit',
    states: ['Michigan'],
  },
];

function generateTimeArray() {
  const timeArray = [];
  const timeFormatOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = new Date(0, 0, 0, hour, minute);
      const label = time.toLocaleTimeString('en-US', timeFormatOptions as any);
      const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

      timeArray.push({ label, value });
    }
  }

  return timeArray;
}

export const TIME_ARRAY = generateTimeArray();

export const helperTabs = [
  { label: 'Related information', tabKey: 'information' },
  // { label: 'General information', tabKey: 'general-information' },
  // { label: 'Ask AI', tabKey: 'ask_ai' },
  // { label: 'Contact us', tabKey: 'contact_us' },
];
