import { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { colors } from '@confidant-health/lib/colors';
import { btnSize, btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { IClaimProvider } from 'redux/modules/reporting/types';
import { useStyles } from '../ClaimDetail.styles';

type Props = {
  onSubmit?: (formData: Partial<IClaimProvider>, type: string) => void;
  data?: IClaimProvider;
  type?: boolean;
};

type DrawerProps = {
  data: IClaimProvider;
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<IClaimProvider>, type: string) => void;
};

const defaultData = {
  uPin: '',
  employerId: '',
  firstName: '',
  fullName: '',
  lastName: '',
  npi: '',
  profileImage: '',
  stateLicense: '',
  userAcccountId: '',
};

const labels = {
  fullName: 'Full name',
  firstName: 'First name',
  lastName: 'Last name',
  npi: 'NPI',
  // employerId: 'Employer ID',
  // stateLicense: 'State license',
  // uPin: 'UPIN',
};

const ProviderDrawer = ({ data, open, onClose, onSubmit }: DrawerProps) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...data });
  const [errors, setErrors] = useState(defaultData);

  const onChangeField = (field: string) => (e: any) => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: null }));
    }
    setFormData(old => ({
      ...old,
      [field]: e.target?.value,
    }));
  };

  const onChangeBillProvider = () => {
    onSubmit(formData, 'billingProvider');
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title="Edit Provider"
      submitBtnTitle="Update"
      onSubmit={onChangeBillProvider}
    >
      {Object.keys(labels).map((d: keyof IClaimProvider) => (
        <Box className={classes.inputWrapper} key={d}>
          <label htmlFor={d}>
            <Text level={textLevel.S} weight={fontWeight.BOLD}>
              {labels[d]}
            </Text>
          </label>
          <Input
            name={d}
            value={formData[d] || ''}
            onChange={onChangeField(d)}
            size={inputSize.M}
            placeholder={`Enter your ${labels[d]}`}
          />
          {errors[d] && <TextError errorMsg={errors[d]} />}
        </Box>
      ))}
    </Drawer>
  );
};

const Provider = ({ onSubmit, data = defaultData, type }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={185}>
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
        {value || 'N/A'}
      </Text>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          {type ? 'Billing provider' : 'Rendering provider'}
        </Heading>
        {onSubmit && (
          <Button
            className={classes.btn}
            variant={btnType.TEXT}
            onClick={() => setOpen(true)}
            size={btnSize.SMALL}
          >
            Change billing provider
          </Button>
        )}
      </Box>
      <Divider sx={{ marginX: -3 }} />

      <Box display="flex" mt={4}>
        <Box display="flex" flexDirection="column" minWidth={185} mr={4}>
          <ProfileInfo
            type="provider"
            photo={data?.profileImage}
            nickName={data?.fullName || ''}
            fullName={`${data?.firstName ? `${data?.firstName} ` : ''}${data?.lastName || ''}`}
            memberId={data?.userAccountId}
          />
        </Box>
        <Col label="NPI" value={data?.npi} />
        {/* <Col label="Employer ID" value={data?.employerId} />
        <Col label="State licence" value={data?.stateLicense} />
        <Col label="UPIN" value={data?.uPin} /> */}
      </Box>
      {open && <ProviderDrawer data={data} open={open} onClose={() => setOpen(false)} onSubmit={onSubmit} />}
    </Box>
  );
};

export default Provider;
