import { FC } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-scroll';

import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';
import { Badge } from '@confidant-health/lib/ui/atoms/badge';

import { useStyles } from './RightSideBar.styles';
import { IRightSideBarProps } from './RightSideBar.types';

const RightSideBar: FC<IRightSideBarProps> = ({ title, navItems }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box padding="40px 32px">
        <span className={classes.headerSideProfile}>{title}</span>
      </Box>
      <Box>
        {navItems.map((item, idx) => {
          return item.children ? (
            <Box className={classes.wrapperButton}>
              <Collapsable
                key={idx}
                open
                label={item.value}
                iconPosition={IiconPosition.RIGHT}
                className={classes.drawerCollapsableAccordion}
              >
                {item.children.map((subItem, i) => {
                  return (
                    <Link
                      key={i}
                      to={subItem.id}
                      spy
                      smooth
                      offset={-20}
                      duration={500}
                      className={classes.buttonContentWithBadge}
                    >
                      <span className={classes.navText}>{subItem.value}</span>
                      {subItem.badge > 0 && <Badge className={classes.childBadge}>{subItem.badge}</Badge>}
                    </Link>
                  );
                })}
              </Collapsable>
            </Box>
          ) : (
            <Box key={item.id} className={classes.wrapperButton}>
              <Link to={item.id} spy smooth offset={0} duration={500} className={classes.buttonContent}>
                {item.value}
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export { RightSideBar };
