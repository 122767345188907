/* eslint-disable max-len */
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { PrioritiesCard } from 'pages/provider/sessions/priority-list/PriorityList.constants';
import SymptomEditing from 'pages/provider/member-detail/components/Symptoms/symptom-editing';
import DiagnosisEditing from 'pages/provider/member-detail/components/Diagnoses/diagnoses-editing';
import SideEffectEditing from 'pages/provider/member-detail/components/SideEffects/components/side-effect-editing';
import MedicationEditing from 'pages/provider/member-detail/components/Medications/medication-editing';
import SubstanceUseEditing from 'pages/provider/member-detail/components/SubstanceUse/substance-use-editing';
import { createNewAssociatedTag } from 'services/member/member.service';
import { getProfile } from 'redux/modules/profile/selectors';
import { MedicationEditPayload } from 'pages/provider/member-detail/components/Medications/medication-editing/MedicationEditing.types';
import { SubstanceEditValue } from 'pages/provider/member-detail/components/SubstanceUse/SubstanceUse.constants';
import { EditSideEffectPayload } from 'pages/provider/member-detail/components/SideEffects/components/side-effect-editing/SideEffectEditing.types';
import { HistoryLookup, IDomainElement } from 'redux/modules/profile/types';
import { getAuth } from 'redux/modules/auth/selectors';
import { DomainTypesEnum } from 'constants/CommonConstants';

type Props = {
  sectionType: string;
  patientId: string;
  card: any;
  lookupData?: HistoryLookup;
  isNoDrawer?: boolean;
  onCloseCard: (submitted?: boolean) => void;
};

const UpdatePriorityCard: FC<Props> = ({
  sectionType,
  card,
  patientId,
  onCloseCard,
  isNoDrawer,
  lookupData,
}) => {
  // const [, lookupData as lookUpDataSymptos] = useDomainType({
  //   type: DomainTypesEnum.SYMPTOMS,
  // });

  const medicalConditions = lookupData?.medicalCondition;
  const { meta } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const onUpdateSideEffect = async (data: EditSideEffectPayload) => {
    setIsSaving(true);
    setError(null);
    try {
      await createNewAssociatedTag({
        patientId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: card.domainElementId,
            metaData: {
              notes: data.notes,
              interferenceInLife: data.isInterference,
              selectedImportance: data.status,
            },
          },
        ],
      });
      setIsSaving(false);
      onCloseCard(true);
    } catch (err) {
      setError(err.data.message || err.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const onUpdateSymptom = async data => {
    const { status: importanceLevel, notes, isInterference, ...remaining } = data;
    setIsSaving(true);
    setError(null);
    try {
      await createNewAssociatedTag({
        patientId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: card.domainElementId,
            metaData: {
              notes,
              interferenceInLife: isInterference,
              selectedImportance: importanceLevel,
              specification: {
                relatedToMedicalCondition: remaining.medicalConditions,
              },
            },
          },
        ],
      });
      setIsSaving(false);
      onCloseCard(true);
    } catch (err) {
      setError(err.data.message || err.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const onUpdateMedication = async (data: MedicationEditPayload) => {
    const { status: importanceLevel, notes } = data;
    setIsSaving(true);
    setError(null);
    try {
      await createNewAssociatedTag({
        patientId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: card.domainElementId,
            metaData: {
              notes,
              // interferenceInLife: isInterference,
              selectedImportance: importanceLevel,
              rxDrugInfo: {
                dose: 0,
                doseUnit: '',
                doseFrequency: 0,
                duration: data?.medDuration,
                // refillable: true,
                // refill: item.tutorial.refillTimes.amount,
                supply: 0,
                supplyUnit: '',
                confidantProviderId: data?.confidantProvider,
                confidantProviderName: data?.providerName,
                dosage: data?.dosage,
                prescribingProvider: data?.provider === 'Confidant Provider' ? 'Internal' : 'External',
                providerName: data?.providerName,
                symptomsBeingTreated: data?.symptoms,
                frequency: data?.frequency,
              },
            },
          },
        ],
      });
      setIsSaving(false);
      onCloseCard(true);
    } catch (err) {
      setError(err.data.message || err.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const onUpdateSubstanceUse = async (data: SubstanceEditValue) => {
    setIsSaving(true);
    setError(null);
    try {
      await createNewAssociatedTag({
        patientId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: card.domainElementId,
            metaData: {
              interferenceInLife: data.isInterference,
              notes: data.notes,
              selectedImportance: data.status,
              substanceUse: {
                currentFrequencyOfUse: data.frequency,
                howLongUsingThisLevel: data.duration,
                lastUse: data.lastUse,
                methodOfUse: data.method,
                unitOfUse: data.dose.unit,
                dose: data.dose.amount,
                refillable: data.refillable,
              },
            },
          },
        ],
      });
      setIsSaving(false);
      onCloseCard(true);
    } catch (err) {
      setError(err.data.message || err.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const onUpdateDiagnosis = async (data: IDomainElement) => {
    const { status: importanceLevel, notes, selectedIct10code } = data;
    setIsSaving(true);
    setError(null);
    try {
      await createNewAssociatedTag({
        patientId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: card.domainElementId,
            selectedIct10code,
            metaData: {
              notes,
              selectedImportance: importanceLevel,
            },
          },
        ],
      });
      setIsSaving(false);
      onCloseCard(true);
    } catch (err) {
      setError(err.data.message || err.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const getCard = () => {
    switch (sectionType) {
      case PrioritiesCard.SocialDeterminants:
      case PrioritiesCard.SideEffects:
      case PrioritiesCard.CognitiveIssues:
        return (
          <SideEffectEditing
            item={card}
            title={
              sectionType === PrioritiesCard.SocialDeterminants
                ? 'Update Social Determinant'
                : sectionType === PrioritiesCard.SideEffects
                ? 'Update Side Effect'
                : 'Update Cognitive Issue'
            }
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onSubmit={onUpdateSideEffect}
            isOpen
            error={error}
            isSaving={isSaving}
          />
        );
      case PrioritiesCard.Symptoms:
        return (
          <SymptomEditing
            title="Update symptom"
            symptom={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onSave={onUpdateSymptom}
            isSaving={isSaving}
            error={error}
            type={DomainTypesEnum.SYMPTOMS}
            medicalConditions={medicalConditions}
            isOpen
          />
        );
      case PrioritiesCard.LifeEvents:
        return (
          <DiagnosisEditing
            item={card}
            title={sectionType === PrioritiesCard.LifeEvents ? 'Update life event' : 'Update diagnosis'}
            onClose={onCloseCard}
            isLifeEvent={sectionType === PrioritiesCard.LifeEvents}
            isNoDrawer={isNoDrawer}
            onSubmit={onUpdateDiagnosis}
            error={error}
            isSaving={isSaving}
            isOpen
            type={DomainTypesEnum.LIFE_EVENTS}
          />
        );
      case PrioritiesCard.Diagnoses:
        return (
          <DiagnosisEditing
            item={card}
            title={sectionType === PrioritiesCard.LifeEvents ? 'Update life event' : 'Update diagnosis'}
            onClose={onCloseCard}
            isLifeEvent={sectionType === PrioritiesCard.LifeEvents}
            isNoDrawer={isNoDrawer}
            onSubmit={onUpdateDiagnosis}
            error={error}
            isSaving={isSaving}
            isOpen
          />
        );
      case PrioritiesCard.Medications:
        return (
          <MedicationEditing
            isNoDrawer={isNoDrawer}
            isOpen
            medication={card}
            onClose={onCloseCard}
            error={error}
            isSaving={isSaving}
            lookupData={lookupData}
            onSubmit={onUpdateMedication}
          />
        );
      case PrioritiesCard.SubstanceUse:
        return (
          <SubstanceUseEditing
            isNoDrawer={isNoDrawer}
            isOpen
            item={card}
            error={error}
            lookupData={lookupData}
            isSaving={isSaving}
            onClose={onCloseCard}
            onSubmit={onUpdateSubstanceUse}
          />
        );
      default:
        return null;
    }
  };

  return <>{getCard()}</>;
};

export { UpdatePriorityCard };
