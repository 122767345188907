import * as yup from 'yup';

const ConversationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  // type: yup.string().required('Type is required'),
  // tags: yup.array().required('Tags are required').min(1, 'At least one tag is required'),
  // benefits: yup
  //   .array()
  //   .of(yup.object())
  //   .required('Benefits are required')
  //   .min(1, 'At least one benefit is required'),
  // unitCost: yup.number().required('Unit cost is required'),
  // avatar: yup.mixed().required('File is required'),
});

export { ConversationSchema };
