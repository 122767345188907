import { AutomationFilterRule } from './AutomationFilterForm.types';

export enum AUTOMATION_FILTERS {
  PROFILE_ELEMENT = 'profileElement',
  STATE = 'state',
  LEVEL_OF_ENGAGEMENT = 'levelOfEngagement',
  INSURANCE = 'insurance',
  PROVIDER = 'provider',
}
export const automationLogicSelect = [
  { label: 'If Profile Element', value: 'P' },
  // This is commented out because we are not using Avatar Tags in the automation filters for now
  // { label: 'If Avatar Tags', value: 'T' },
  { label: 'States', value: 'STATE' },
  { label: 'Level of Engagement', value: 'LEVEL_OF_ENGAGEMENT' },
  { label: 'Provider', value: 'PROVIDER' },
  { label: 'Insurance', value: 'INSURANCE' },
];

export const automationProfileSelect = [{ label: 'PHQ-9 Score', value: 'PHQ-9 Score' }];

export const automationRuleSelect = [
  { label: 'Equals', value: 'equals' },
  { label: 'Not equals', value: 'not-equals' },
  { label: 'Greater than', value: 'Greater than' },
  { label: 'Less than', value: 'Less than' },
];

export const ruleAggregatorSelect = [
  { label: 'AND', value: 'and' },
  { label: 'OR', value: 'or' },
];

export const ProfileElementDefType = {
  SCORE_BASED: 'Score Based',
  TEXT_INPUT: 'Text Input',
  RATING_SCALE: 'Rating Scale',
  DATE: 'Date',
  DATE_TIME: 'Date Time',
  YES_NO: 'Yes/No',
  NUMERIC: 'Numeric',
  USER_DEFINED_VALUES: 'User Defined Values',
};

export const FilterTypeMap = {
  P: 'Profile Element',
  T: 'Avatar Tags',
  R: 'Conversation Variable',
  STATE: 'States',
  LEVEL_OF_ENGAGEMENT: 'Level of Engagement',
  PROVIDER: 'Provider',
  INSURANCE: 'Insurance',
};

export const BUILDER_CONSTANTS = {
  ContentBlockTypes: {
    PROVIDER_MESSAGE: 'provider-message',
    PROVIDER_PROMPT: 'provider-prompt',
    SINGLE_SELECT: 'single-select',
    MULTI_SELECT: 'multi-select',
    MULTI_DROPDOWN: 'multi-dropdown',
    TEXT_INPUT: 'text-input',
    RATING_SCALE: 'rating-scale',
    EDUCATION_CONTENT: 'education',
    FILTERED_PROVIDERS: 'filtered-providers',
    TELEHEALTH_SERVICES: 'telehealth-services',
  },
  BlockTypeNames: {
    'provider-message': 'Provider Message',
    'provider-prompt': 'Provider Prompt',
    'single-select': 'Single Select',
    'multi-select': 'Multi Select',
    'text-input': 'Text Input',
    'rating-scale': 'Rating Scale',
    education: 'Education',
    'filtered-providers': 'Filtered Providers',
    'telehealth-services': 'Telehealth Services',
  },
  BlockTypeNamesInEvaluation: {
    'single-select': 'Single Select',
    'multi-select': 'Multi Select',
    'text-input': 'Text Input',
    'rating-scale': 'Rating Scale',
    education: 'Education',
  },
  DefaultOperators: [
    { value: 'equals', displayName: 'Equals' },
    { value: 'not-equals', displayName: 'Not Equals' },
    { value: 'contains', displayName: 'Contains' },
    { value: 'does-not-contains', displayName: 'Does not contains' },
  ],
  RoutingOptions: {
    SPECIFIC: 'Specific List of Content Blocks',
    NEXT: 'Next Block',
    END: 'End of Conversation/DCT',
  },
  StaticChatBotMessages: {
    'telehealth-services': 'Learn more about our clinical services',
    'filtered-providers': 'Get introduced to our Clinical Team',
    'provider-prompt': 'Get introduced to our Clinical Team',
  },
  AUTOMATION_RULES: {
    ACTIONS: {
      ASSIGN_CONVERSATION: 'ASSIGN CONVERSATION',
      ASSIGN_EDUCATION: 'ASSIGN EDUCATION',
      ASSIGN_PLAN_ITEM: 'ASSIGN PLAN ITEM',
    },
    FILTERS: {
      RESPONSE_BASED_FILTER: 'RESPONSE_BASED_FILTER',
      USER_WHITE_LIST_FILTER: 'USER_WHITE_LIST_FILTER',
    },
    TIME_INTERVAL: {
      MIN: 'MIN',
      HOUR: 'HOUR',
      DAY: 'DAY',
    },
    EVENTS: {
      Names: {
        APPOINTMENT_REQUESTED: 'Appointment Requested',
        APPOINTMENT_SCHEDULED: 'Appointment Scheduled',
        APPOINTMENT_COMPLETED: 'Appointment Completed',
        APPOINTMENT_CANCELLED: 'Appointment Cancelled',
        APPOINTMENT_NO_SHOW: 'Appointment No Show',
        CONVERSATION_COMPLETED: 'CONVERSATION COMPLETED',
        CONVERSATION_ASSIGNED: 'Conversation Assigned',
        DCT_COMPLETED: 'DCT COMPLETED',
        PROFILE_ELEMENT_UPDATED: 'PROFILE ELEMENT UPDATED',
        GROUP_JOINED: 'Group Joined',
        GROUP_LEFT: 'Group Left',
        GROUP_SESSION_JOINED: 'Group Session Joined',
        GROUP_CONTRIBUTION_MADE: 'Group Contribution Made',
        PAYMENT_TYPE_SELECTED: 'Payment Type Selected',
        PAYMENT_MADE: 'Payment Made',
        CONTRIBUTION_SET: 'Contribution Set',
        CONTRIBUTION_CANCELLED: 'Contribution Cancelled',
        EDUCATION_MARKED_READ: 'Education Marked as Read',
        EDUCATION_VIEWED: 'Education Viewed',
        EDUCATION_SHARED: 'Education Shared',
        EDUCATION_ADDED_TO_FAVOURITES: 'Education Added To Favourites',
      },
      Values: {
        APPOINTMENT_REQUESTED: 'APPOINTMENT_REQUESTED',
        APPOINTMENT_SCHEDULED: 'APPOINTMENT_SCHEDULED',
        APPOINTMENT_COMPLETED: 'APPOINTMENT_COMPLETED',
        APPOINTMENT_CANCELLED: 'APPOINTMENT_CANCELLED',
        APPOINTMENT_NO_SHOW: 'APPOINTMENT_NO_SHOW',
        CONVERSATION_COMPLETED: 'CONVERSATION_COMPLETED',
        CONVERSATION_ASSIGNED: 'CONVERSATION_ASSIGNED',
        DCT_COMPLETED: 'DCT_COMPLETED',
        PROFILE_ELEMENT_UPDATED: 'PROFILE_ELEMENT_UPDATED',
        GROUP_JOINED: 'GROUP_JOINED',
        GROUP_LEFT: 'GROUP_LEFT',
        GROUP_SESSION_JOINED: 'GROUP_SESSION_JOINED',
        GROUP_CONTRIBUTION_MADE: 'GROUP_CONTRIBUTION_MADE',
        PAYMENT_TYPE_SELECTED: 'PAYMENT_TYPE_SELECTED',
        PAYMENT_MADE: 'PAYMENT_MADE',
        CONTRIBUTION_SET: 'CONTRIBUTION_SET',
        CONTRIBUTION_CANCELLED: 'CONTRIBUTION_CANCELLED',
        EDUCATION_MARKED_READ: 'EDUCATION_MARKED_READ',
        EDUCATION_VIEWED: 'EDUCATION_VIEWED',
        EDUCATION_SHARED: 'EDUCATION_SHARED',
        EDUCATION_ADDED_TO_FAVOURITES: 'EDUCATION_ADDED_TO_FAVOURITES',
      },
    },
    AUTOMATION_ACCORDIAN_DEF: {
      EVENT: 'EVENT',
      FILTER: 'FILTER',
      ACTION: 'ACTION',
    },
  },
  TAGS: {
    TAGS_PRIORITY_LEVELS: {
      CRITICAL: 'Critical',
      NORMAL: 'Normal',
      HIGH: 'High',
      LOW: 'Low',
      NONE: 'None',
    },
    TAGS_COLOR: {
      RED: 'red',
      YELLOW: 'yellow',
      GREEN: 'green',
      PURPLE: 'purple',
    },
    TAGS_TYPES: {
      ALERTS: 'Alerts',
      LIFE_EVENTS: 'Life Events',
      SYMPTOMS: 'Symptoms',
      SIDE_EFFECTS: 'Side Effects',
      DIAGNOSIS: 'Diagnosis',
      PRESCRIPTIONS: 'Prescriptions',
      IMPROVEMENTS: 'Improvements',
      SUBSTANCE_USE: 'Substance Use',
      GENERAL: 'General',
    },
    TAGS_ASSIGNMENT_METHOD: {
      BASED_ON_PROFILE_ELEMENT: 'Profile element',
      BASED_ON_DCT: 'DCT',
    },
    BASED_ON_TAGS_ASSIGNMENT_METHOD: {
      BASED_ON_PROFILE_ELEMENT: 'BASED_ON_PROFILE_ELEMENT',
      BASED_ON_DCT: 'BASED_ON_DCT',
    },
  },
  SORT_DIRECTIONS: {
    asc: 'ASC',
    desc: 'DESC',
  },
  GENDER: {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
  },
};

export const automationRuleSelectNumeric = [
  { label: 'Equals', value: AutomationFilterRule.EQUALS },
  { label: 'Greater than', value: AutomationFilterRule.GREATER_THAN },
  { label: 'Less than', value: AutomationFilterRule.LESS_THAN },
];

export const automationRuleSelectText = [
  { label: 'Contains', value: AutomationFilterRule.CONTAINS },
  { label: 'Does not contain', value: AutomationFilterRule.NOT_CONTAIN },
];

export const automationRuleSelectOption = [
  { label: 'Equals', value: AutomationFilterRule.EQUALS },
  { label: 'Not equals', value: AutomationFilterRule.NOT_EQUALS },
];

export const automationRuleSelectDate = [
  { label: 'Before this Date', value: AutomationFilterRule.BEFORE_DATE },
  { label: 'After this Date', value: AutomationFilterRule.AFTER_DATE },
];
