import { INavItemType } from '@confidant-health/lib/ui/templates/side-navigation';

export const rightSideNavList: INavItemType[] = [
  {
    text: 'Appointments',
    query: { type: 'name', value: 'appointments' },
    section: 'appointmentsSection',
  },
  {
    text: 'Demographics',
    query: { type: 'name', value: 'demographics' },
    section: 'demoGraphicsSection',
  },
  // TODO: Uncomment when logins are implemented
  // Commented out because it is not used for now
  // {
  //   text: 'Logins',
  //   query: { type: 'name', value: 'logins' },
  //   section: 'loginSection',
  // },
  {
    text: 'Profile elements',
    query: { type: 'name', value: 'profile-elements' },
    section: 'profileElementsSection',
  },
  {
    text: 'Chatbots',
    query: { type: 'name', value: 'chatbots' },
    section: 'chatbotSection',
  },
  {
    text: 'Chatbot DCTs',
    query: { type: 'name', value: 'chatbot-dcts' },
    section: 'chatbotDctSection',
  },
  {
    text: 'Evaluation DCTs',
    query: { type: 'name', value: 'evaluation-dcts' },
    section: 'evaluationDctSection',
  },
  {
    text: 'Evaluations',
    query: { type: 'name', value: 'evaluations' },
    section: 'evaluationsSection',
  },
  {
    text: 'Data domain elements',
    children: [
      {
        text: 'Symptoms',
        query: { type: 'name', value: 'symptoms' },
      },
      {
        text: 'Diagnoses',
        query: { type: 'name', value: 'diagnoses' },
      },
      {
        text: 'Medications',
        query: { type: 'name', value: 'medications' },
      },
      {
        text: 'Side effects',
        query: { type: 'name', value: 'side-effects' },
      },
      {
        text: 'Substance use',
        query: { type: 'name', value: 'substance-use' },
      },
      {
        text: 'Life events',
        query: { type: 'name', value: 'life-events' },
      },
      {
        text: 'Social determinants',
        query: { type: 'name', value: 'social-determinants' },
      },
    ],
    section: 'dataDomainElementSection',
  },
];
