import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  tableBordered: {
    border: `1px solid ${colors.neutral100}`,
    borderBottom: 'none',
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: 24,
    '& td.color-secondary-500': {
      color: colors.secondary500,
    },
  },
  drawerContent: {
    flex: 1,
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '720px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  headerDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  headerSubtitle: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    margin: 0,
    color: colors.neutral600,
  },
  closeIconBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  drawerTable: {
    flex: 1,
    overflowY: 'auto',
    height: 'calc(100vh - 160px)',
    '& td.color-secondary-500': {
      color: colors.secondary500,
    },
  },
  drawerFooter: {
    display: 'flex',
    height: 80,
    borderTop: `1px solid ${colors.neutral100}`,
    justifyContent: 'flex-end',
    padding: 16,
    '& button': {
      height: 48,
    },
  },
});
