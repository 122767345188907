enum SocketNotificationTypes {
  appointmentRequested = 'APPOINTMENT_REQUESTED',
  appointmentConfirmed = 'APPOINTMENT_CONFIRMED',
  chatMessageReceived = 'CHAT_MESSAGE_RECEIVED',
  groupMessageReceived = 'GROUP_MESSAGE_RECEIVED',
  appointmentNeedsAction = 'APPOINTMENT_NEEDS_ACTION',
  appointmentCancelled = 'APPOINTMENT_CANCELLED',
}

export default SocketNotificationTypes;
