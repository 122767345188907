import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Stepper } from '@confidant-health/lib/ui/molecules/stepper';

import { DomainPriorities } from 'redux/modules/profile/types';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';

// components
import StepOne from './step-one/StepOne';
import StepTwo from './step-two/StepTwo';
import StepThree from './step-three/StepThree';

// types
import { IAddNewReportProps, ISymptomPayload, ReportItemProgress } from './AddNewReport.types';
// styles
import { useStyles } from './AddNewReport.styles';

const AddNewReport: React.FC<IAddNewReportProps> = ({
  isOpen,
  onClose,
  title,
  isLifeEvent,
  isNoDrawer,
  type,
  searchLabel,
  onSubmit,
  stepTitles,
  showInterference,
  options,
  optionsLoading = false,
}) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedSymptomForms, setSelectedSymptomForms] = React.useState<ISymptomPayload[]>([]);

  const onCloseClick = () => {
    if (currentStep === 0) {
      onClose();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onRemoveSymptom = (index: number) => {
    const newSymptom = selectedItems.filter((_, i) => i !== index);
    if (newSymptom.length === 0) {
      setCurrentStep(0);
    }
    setSelectedItems(newSymptom);
  };

  const onClickContinue = () => {
    if (currentStep === 0) {
      const newSymps: ISymptomPayload[] = selectedItems.map(id => {
        let groupId = null;
        let item = null;
        options.forEach(opt => {
          const result = opt.items.find(it => it.value === id);
          if (result) {
            groupId = opt.groupId;
            item = result;
          }
        });
        return {
          groupId,
          item,
          status: isLifeEvent ? DomainPriorities.POSITIVE : DomainPriorities.CRITICAL,
          notes: '',
          isInterference: false,
          progress: ReportItemProgress.Incomplete,
        };
      });
      setSelectedSymptomForms(newSymps);
    }
    if (currentStep === 2) {
      onSubmit(selectedSymptomForms);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0);
      setSelectedItems([]);
    }
  }, [isOpen]);

  const renderStep = step => {
    switch (step) {
      case 0:
        return (
          <StepOne
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            options={options}
            searchLabel={searchLabel}
          />
        );
      case 1:
        return (
          <StepTwo
            selectedSymptoms={selectedSymptomForms}
            updateSymptoms={setSelectedSymptomForms}
            isLifeEvent={isLifeEvent}
            type={type}
            onRemoveSymptom={onRemoveSymptom}
            showInterference={showInterference}
            options={options}
          />
        );
      case 2:
        return <StepThree selectedSymptoms={selectedSymptomForms} />;
      default:
        return null;
    }
  };

  const checkDisabled = () => {
    if (currentStep === 0) {
      return selectedItems.length === 0;
    }
    if (currentStep === 1) {
      return selectedSymptomForms.some(item => item.notes.trim().length === 0);
    }
    return false;
  };

  const renderContent = () => {
    return (
      <Box className={classes.wrapper}>
        <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
          <Box>
            <Text weight={fontWeight.BOLD} className={classes.headTitle}>
              {title}
            </Text>
            <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
              {stepTitles[currentStep]}
            </Text>
          </Box>
          <Box className={classes.stepWrap}>
            <Stepper steps={['Select', 'Detail', 'Review']} activeStep={currentStep} />
          </Box>
          <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
        </Box>
        <Box
          className={clsx(classes.content, {
            [classes.contentNoDrawer]: isNoDrawer,
            [classes.contentStep2]: currentStep > 0,
          })}
        >
          {optionsLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" gap={4}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </Box>
          ) : (
            renderStep(currentStep)
          )}
        </Box>

        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onCloseClick}>
            Cancel
          </Button>
          <Button onClick={onClickContinue} disabled={checkDisabled()}>
            {currentStep === 2 ? 'Send report' : 'Continue'}
          </Button>
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer open={isOpen} onClose={onCloseClick} variant={drawerType.NORMAL} className={classes.drawer}>
      {renderContent()}
    </Drawer>
  );
};

export { AddNewReport };
