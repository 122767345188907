import { FC } from 'react';
// import { Interweave } from 'interweave';
import { makeStyles } from '@mui/styles';
import Markdown from 'markdown-to-jsx';

type Props = {
  selectedSection?: any;
};

const useStyles = makeStyles({
  markdown: {
    '& a': {
      lineBreak: 'anywhere',
    },
  },
});

const EvaluationExercise: FC<Props> = ({ selectedSection }) => {
  const classes = useStyles();

  if (!selectedSection) {
    return null;
  }
  return (
    <>
      {/* <Interweave content={selectedSection} /> */}
      <Markdown className={classes.markdown}>{selectedSection}</Markdown>
    </>
  );
};

export { EvaluationExercise };
