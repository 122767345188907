import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import CellMeasurer, { CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import { useStyles } from './ApointmentEvaluationSubtitles.styles';
import { TranscriptPart } from './TranscriptPart';

type Props = {
  audioTimestamp: number;
  scrollLocked: boolean;
  providerSpeaker: string;
  parts: any[];
  member;
  provider;
  onChangeTranscriptSpeaker?: (index: number, isProvider: boolean) => void;
  editMode: boolean;
  onInvertSpeakers: () => void;
  timedCB?: {
    id: string;
    from: number;
    to: number;
  };
  analysisInProgress: boolean;
  onCancelChanges: () => void;
  onSaveChanges: () => void;
};

const ApointmentEvaluationSubtitles: FC<Props> = ({
  audioTimestamp,
  scrollLocked,
  providerSpeaker,
  parts,
  member,
  provider,
  onChangeTranscriptSpeaker,
  editMode,
  onInvertSpeakers,
  timedCB,
  analysisInProgress,
  onCancelChanges,
  onSaveChanges,
}) => {
  const classes = useStyles();
  const cache = new CellMeasurerCache({ defaultHeight: 85, fixedWidth: true });
  let mostRecentHeight = 0;
  const [resizeAllFlag, setResizeAllFlag] = useState(false);
  const listRef = useRef(null);

  const resizeAll = () => {
    setResizeAllFlag(false);
    cache.clearAll();
    if (listRef.current) {
      listRef.current.recomputeRowHeights();
    }
  };

  useEffect(() => {
    if (resizeAllFlag) {
      resizeAll();
    }
  }, [resizeAllFlag]);

  useEffect(() => {
    if (scrollLocked) {
      const partIndex = parts.findIndex(part => {
        return Math.floor(part.timestamp) === Math.floor(audioTimestamp);
      });
      if (partIndex > -1 && listRef.current) {
        listRef.current.scrollToRow(partIndex);
      }
    }
  }, [audioTimestamp, scrollLocked, listRef]);

  useEffect(() => {
    if (!scrollLocked && timedCB) {
      const partIndex = parts.findIndex(part => {
        const cbStartSec = timedCB.from - 1;
        const cbEndSec = timedCB.to + 1;
        return part.timestamp >= cbStartSec && part.timestamp <= cbEndSec;
      });
      if (partIndex > -1 && listRef.current) {
        listRef.current.scrollToRow(partIndex);
      }
    }
  }, [timedCB, scrollLocked, listRef]);

  const rowRenderer = ({ index: idx, key, parent, style }) => {
    if (!parts || parts.length === 0) {
      return null;
    }
    const part = parts[idx];
    if (!part) {
      return null;
    }
    const showMeta =
      (editMode && !analysisInProgress) || idx === 0 || parts[idx - 1].speaker !== part.speaker;
    const name = part.speaker === providerSpeaker ? provider?.name : member?.name;
    const isProviderMessage = part.speaker === providerSpeaker;
    const userType = isProviderMessage ? 'Provider' : 'Member';
    const imageSrc = part.speaker === providerSpeaker ? provider?.profilePicture : member?.profilePicture;
    const switched = part.switched ?? false;
    const cbStartSec = timedCB && timedCB.from - 1;
    const cbEndSec = timedCB && timedCB.to + 1;
    const shouldHighlight = scrollLocked
      ? Math.floor(audioTimestamp) === Math.floor(part.timestamp) ||
        Math.ceil(audioTimestamp) === Math.ceil(part.timestamp) ||
        (audioTimestamp >= part.timestamp && audioTimestamp <= Number(part.timestamp) + 2)
      : timedCB && cbStartSec <= part.timestamp && cbEndSec >= part.timestamp;
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={idx}>
        {({ registerChild }) => (
          <div style={style} ref={registerChild}>
            <TranscriptPart
              editMode={editMode}
              analysisInProgress={analysisInProgress}
              idx={idx}
              showMeta={showMeta}
              imageSrc={imageSrc}
              name={name}
              timestamp={part.timestamp}
              switched={switched}
              isProviderMessage={isProviderMessage}
              userType={userType}
              shouldHighlight={shouldHighlight}
              speaker={part.speaker}
              text={part.text}
              providerSpeaker={providerSpeaker}
              onChangeTranscriptSpeaker={onChangeTranscriptSpeaker}
              parts={parts}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      {editMode && !analysisInProgress && (
        <Box style={{ position: 'relative' }}>
          <Box className={classes.speakerAdjustmentBox}>
            <Button variant={btnType.TEXT} onClick={onInvertSpeakers} className={classes.invertAllBtn}>
              <Icons color={colors.primary} glyph="switch-alternate" className={classes.invertAllIcon} />
              <Text weight={fontWeight.BOLD} className={classes.invertAllText}>
                Invert Speakers
              </Text>
            </Button>
            <Box display="flex" gap={2}>
              <Button
                variant={btnType.OUTLINE}
                className={classes.speakerActionBtn}
                onClick={onCancelChanges}
              >
                Cancel
              </Button>
              <Button className={classes.speakerActionBtn} onClick={onSaveChanges}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.colSubtitlesScroll}>
        <AutoSizer>
          {({ height, width }) => {
            if (mostRecentHeight && mostRecentHeight !== height) {
              setResizeAllFlag(true);
            }
            mostRecentHeight = height;
            return (
              <List
                deferredMeasurementCache={cache}
                height={height}
                overscanRowCount={10}
                ref={listRef}
                rowCount={parts.length}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                scrollToAlignment="center"
                width={width}
              />
            );
          }}
        </AutoSizer>
      </Box>
    </>
  );
};

export { ApointmentEvaluationSubtitles };
