import { FC, useEffect } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { IProfileElementInfo } from 'redux/modules/conversation/types';
import { PROFILE_ELEMENT_DEF_TYPE } from 'constants/CommonConstants';

// schema
import { ProfileElementSchema } from './AddProfileElement.schema';

// styles
import { useStyles } from './AddProfileElement.styles';

// constants
import { profileElementDefMethods } from '../../ProfileElementList.constants';
import { IProfileElementInfoProps } from './AddProfileElement.types';

const AddProfileElement: FC<IProfileElementInfoProps> = ({ isOpen, profileElement, onSubmit, onClose }) => {
  const defaultValues = {
    key: profileElement?.profileElementInfo?.key || '',
    type: profileElement?.profileElementInfo?.type || '',
    values: profileElement?.profileElementInfo?.values || [],
    method: profileElement?.profileElementInfo?.method || '',
  };

  const isUserDefinedValuesSelected = (type: string) =>
    PROFILE_ELEMENT_DEF_TYPE[type] === PROFILE_ELEMENT_DEF_TYPE.USER_DEFINED_VALUES ||
    PROFILE_ELEMENT_DEF_TYPE[type] === PROFILE_ELEMENT_DEF_TYPE.RATING_SCALE ||
    PROFILE_ELEMENT_DEF_TYPE[type] === PROFILE_ELEMENT_DEF_TYPE.NUMERIC;

  const classes = useStyles();
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema: ProfileElementSchema,
    onSubmit: (payload: IProfileElementInfo, actions: FormikHelpers<IProfileElementInfo>) => {
      if (isUserDefinedValuesSelected(values.type) && !values.values.length) {
        actions.setFieldError('values', 'At least one value is required');
      }
      onSubmit(payload);
    },
  });

  const profileElementTypes = Object.keys(PROFILE_ELEMENT_DEF_TYPE).map(key => ({
    label: PROFILE_ELEMENT_DEF_TYPE[key],
    value: key,
  }));
  const profilElementValues = values.values.map(value => ({ title: value, value }));
  const profileElementMethods = Object.values(profileElementDefMethods).map(method => ({
    label: method,
    value: method,
  }));
  const isNumericTypeSelected = PROFILE_ELEMENT_DEF_TYPE[values.type] === PROFILE_ELEMENT_DEF_TYPE.NUMERIC;

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeValues = async (_, newValue) => {
    const newValues: string[] = [];
    newValue.forEach(item => {
      if (typeof item === 'object') {
        newValues.push(item.value);
      } else if (!isNumericTypeSelected || (isNumericTypeSelected && !Number.isNaN(Number(item)))) {
        newValues.push(item);
      }
    });
    await setFieldValue('values', newValues);
  };

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{profileElement ? 'Edit' : 'Add'} profile element</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Profile element name')}
              <Input
                fullWidth
                name="key"
                value={values.key}
                placeholder="Enter a profile element name"
                size={inputSize.M}
                onBlur={setTouched('key')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.key ? errors.key?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Profile type')}
              <Select
                fullWidth
                displayEmpty
                name="type"
                value={values.type}
                options={profileElementTypes}
                emptyText="Select type"
                variant={selectType.SECONDARY}
                onBlur={setTouched('type')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.type ? errors.type?.toString() : null} />
            </Box>
            {isUserDefinedValuesSelected(values.type) && (
              <Box className={classes.section}>
                {renderLabel('User defined values')}
                <Input
                  freeSolo
                  fullWidth
                  name="values"
                  type={isNumericTypeSelected ? 'number' : 'text'}
                  value={profilElementValues}
                  options={profilElementValues}
                  placeholder="Select values"
                  variant={inputType.TAGS}
                  size={inputSize.M}
                  onBlur={setTouched('values')}
                  onChange={onChangeValues}
                />
                <TextError errorMsg={touched.values ? errors.values?.toString() : null} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Storage method')}
              <Select
                fullWidth
                displayEmpty
                name="method"
                value={values.method}
                options={profileElementMethods}
                variant={selectType.SECONDARY}
                emptyText="Select storage method"
                onBlur={setTouched('method')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.method ? errors.method?.toString() : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>{profileElement ? 'Update' : 'Add'} profile element</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddProfileElement };
