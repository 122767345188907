import { Heading } from '@confidant-health/lib/ui/atoms/typography';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import ApexChart from 'react-apexcharts';

import { ChartConfig, getChartOptions } from './StackChart.config';
import { useStyles } from './StackChart.styles';

type Props = {
  title: string;
  className?: string;
  chart: ChartConfig;
};

const StackChart: FC<Props> = ({ title, className, chart }) => {
  const classes = useStyles();
  const { series } = chart;

  return (
    <Box className={clsx(classes.card, className)}>
      <Box className={clsx(classes.head, 'chart-head')}>
        <Heading className={classes.title}>{title}</Heading>
        {/* <Box className={classes.headRight}>
          {renderMenu(selectedTime.label, times)}
          {renderMenu(selectedStatus.label, statuses)}
        </Box> */}
      </Box>
      <div className={classes.chart}>
        <ApexChart options={getChartOptions(chart)} series={series} type="bar" height={440} />
      </div>
    </Box>
  );
};

export { StackChart };
