import { FC } from 'react';
import { Box } from '@mui/material';
import {
  Heading,
  headingLevel,
  fontWeight,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import {
  evaluationStatusStyle,
  EVALUATION_STATUS,
} from 'pages/provider/sessions/evaluations-v2/Evaluations.constants';
import { IAppointmentCptCode } from 'redux/modules/report-service/types';
import { useStyles } from './ProcedureCodeCard.styles';

type Props = {
  codesCount: number;
  status: string;
  onClick: () => void;
  appointmentCptCode?: IAppointmentCptCode;
};

const ProcedureCodeCard: FC<Props> = ({ codesCount, status, appointmentCptCode, onClick }) => {
  const classes = useStyles();

  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={185}>
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      {label && (
        <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
          {value || 'N/A'}
        </Text>
      )}
    </Box>
  );
  return (
    <Box className={classes.card}>
      <Box className={classes.cardContent} onClick={onClick}>
        <Box className={classes.iconWrapper}>
          <Icons color={colors.primary500} glyph="procedure" />
        </Box>
        <Box>
          <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.title}>
            Add procedure codes
          </Heading>
          <Text className={classes.subtitle}>
            {codesCount > 0 ? codesCount : 'No'} code{codesCount > 1 ? 's' : ''} added
          </Text>
          {appointmentCptCode?.cptList?.map((c, index: number) => (
            <Box key={`${index}-${c.cptCode}`} display="flex" mx={-3} py={2} px={3}>
              <Col label="CPT Code" value={c?.cptCode} />
              <Col label="ICD10 Code" value={c?.icd10Code} />
            </Box>
          ))}
        </Box>
        {!codesCount && (
          <Box className={classes.statusBadge}>
            <Badge
              className={classes.badge}
              variant={badgeType.OUTLINED}
              style={evaluationStatusStyle[status]}
            >
              {EVALUATION_STATUS[status]}
            </Badge>
          </Box>
        )}
      </Box>
      {/* <Menu
        icon="more"
        className={classes.menu}
        // itemsWrapperClassName={classes.menuItemsWrapper}
        // items={items}
      /> */}
    </Box>
  );
};

export { ProcedureCodeCard };
