import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import clsx from 'clsx';
import { Box, Modal, Stack } from '@mui/material';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { Button, btnType, btnSize } from '@confidant-health/lib/ui/atoms/button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { positionType, Toggle } from '@confidant-health/lib/ui/atoms/toggle';
// selectors
import { appointmentActionCreators } from 'redux/modules/appointment';
import { selectProviderRoles } from 'redux/modules/appointment/selectors';
import { getAllChoiceTemplates, saveChoiceTemplate } from 'services/conversation/conversation.service';
import { ContentfulClient } from 'config/contentful-config';
import { showSnackbar } from 'redux/modules/snackbar';

// constants
import {
  BUILDER_CONSTANTS,
  CONTENT_BLOCK_TYPES,
  CONTENT_BLOCK_TYPES_EVALUATION,
  PROFILE_ELEMENT_DEF_TYPE,
  RATING_SCALE_OPTIONS,
} from 'constants/CommonConstants';

// types
import { ISelectOption } from './FlowEditing.types';

// components
import { FlowChoice } from './FlowChoice';
import { flowLogicOptions } from './ContentBlock.constant';
import { useStyles } from './ContentBlockDrawer.styles';
import { JoditUIEditor } from './JoditUIEditor';

type Props = {
  isOpen: boolean;
  addCbButtonPressed: boolean;
  blockToUpdate?: any;
  profileElements: any[];
  onClose: () => void;
  onDelete: (cbId: string) => void;
  onSave: (data: any) => void;
  getCBs: () => void;
  titleLabel?: string;
};

const defaultCB = {
  basicInfo: {
    activityData: {
      itemsNeeded: '',
      timeNeeded: '',
      title: '',
    },
    choices: [
      {
        choice: '',
        score: 0,
        value: '',
        riskFactor: false,
      },
    ],
    dataType: '',
    dropdowns: [
      {
        label: '',
        values: [],
      },
    ],
    educationContentSlug: '',
    educationSearchKeyword: '',
    exerciseContent: '',
    name: '',
    placeholder: '',
    popupText: '',
    ratingScale: {
      assignmentRanges: [
        {
          elementValue: '',
          maxValue: 0,
          minValue: 0,
        },
      ],
      highLabel: '',
      lowLabel: '',
      values: [],
    },
    referenceId: '',
    text: '',
    type: '',
    variableMapping: {
      name: '',
      scope: '',
    },
  },
  cbId: '',
  displayLogics: [
    {
      key: '',
      metaText: '',
      rule: '',
      type: '',
      value: '',
    },
  ],
  helperText: '',
  prompt: '',
  rawDisplayLogic: '',
  relatedProfileElements: [],
  required: true,
  routing: [],
  routingOption: '',
  ruleAggregator: '',
  subtitle: '',
};

const ContentBlockDrawer: FC<Props> = ({
  isOpen,
  blockToUpdate,
  profileElements,
  onClose,
  onSave,
  onDelete,
  addCbButtonPressed,
  getCBs,
  titleLabel = '',
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roles = useSelector(selectProviderRoles);
  const defaultSelectOption = { label: '', value: '' };
  const [updateComponent, setUpdateComponent] = useState(false);
  const [selectedProviderRole, setSelectedProviderRole] = useState<ISelectOption>(defaultSelectOption);
  const [educationalContents, setEducationalContents] = useState<ISelectOption[]>([]);
  const [selectedEducationalContent, setSelectedEducationalContent] =
    useState<ISelectOption>(defaultSelectOption);
  const [choiceTemplates, setChoiceTemplates] = useState([]);
  const [selectedChoiceTemplate, setSelectedChoiceTemplate] = useState('');
  const [openChoiceTemplateSaveModal, setOpenChoiceTemplateSaveModal] = useState(false);
  const [choiceTemplateName, setChoiceTemplateName] = useState('');
  const [contentBlock, setContentBlock] = useState(null);
  const [titleRequired, setTitleRequired] = useState(false);
  const [typeRequired, setTypeRequired] = useState(false);

  useEffect(() => {
    if (blockToUpdate) {
      setContentBlock(blockToUpdate);
    } else {
      setContentBlock(defaultCB);
    }
  }, [blockToUpdate]);

  const providerRoleOptions = useMemo(
    () => roles.map(role => ({ label: role.designation, value: role.designation })),
    [roles],
  );

  const clearData = () => {
    setContentBlock({
      basicInfo: {
        activityData: {
          itemsNeeded: '',
          timeNeeded: '',
          title: '',
        },
        choices: [
          {
            choice: '',
            score: 0,
            value: '',
            riskFactor: false,
          },
        ],
        dataType: '',
        dropdowns: [
          {
            label: '',
            values: [],
          },
        ],
        educationContentSlug: '',
        educationSearchKeyword: '',
        name: '',
        placeholder: '',
        popupText: '',
        ratingScale: {
          assignmentRanges: [
            {
              elementValue: '',
              maxValue: 0,
              minValue: 0,
            },
          ],
          highLabel: '',
          lowLabel: '',
          values: [],
        },
        referenceId: '',
        text: '',
        type: '',
        variableMapping: {
          name: '',
          scope: '',
        },
      },
      cbId: '',
      displayLogics: [
        {
          key: '',
          metaText: '',
          rule: '',
          type: '',
          value: '',
        },
      ],
      helperText: '',
      prompt: '',
      rawDisplayLogic: '',
      relatedProfileElements: [],
      required: true,
      routing: [],
      routingOption: '',
      ruleAggregator: '',
      subtitle: '',
    });
  };

  const onCloseDrawer = () => {
    setTypeRequired(false);
    setTitleRequired(false);
    if (addCbButtonPressed) {
      clearData();
    }
    onClose();
  };

  const profileElementOptions = useMemo(
    () =>
      profileElements
        .map(element => ({
          label: element.profileElementInfo.key,
          value: element.profileElementInfo.key,
        }))
        .sort((a, b) => a.label.trim().localeCompare(b?.label?.trim())),
    [profileElements],
  );
  const defaultProfileElement = profileElementOptions.find(
    el => el.value === contentBlock?.basicInfo?.variableMapping?.name,
  );
  const [currentProfileElement, setCurrentProfileElement] = useState<ISelectOption>(
    defaultProfileElement || defaultSelectOption,
  );
  const [showProfileElement, setShowProfileElement] = useState<boolean>(!!defaultProfileElement);

  const cbType = contentBlock?.basicInfo?.type;
  const shouldProvideText =
    cbType !== CONTENT_BLOCK_TYPES.FILTERED_PROVIDERS &&
    cbType !== CONTENT_BLOCK_TYPES.TELEHEALTH_SERVICES &&
    cbType !== CONTENT_BLOCK_TYPES.PROVIDER_PROMPT &&
    cbType?.toLowerCase() !== 'dct';
  const showProfilieElementMapping =
    cbType !== CONTENT_BLOCK_TYPES.PROVIDER_MESSAGE &&
    cbType !== CONTENT_BLOCK_TYPES.PROVIDER_PROMPT &&
    cbType !== CONTENT_BLOCK_TYPES.EDUCATION_CONTENT &&
    cbType !== CONTENT_BLOCK_TYPES.FILTERED_PROVIDERS &&
    cbType !== CONTENT_BLOCK_TYPES.TELEHEALTH_SERVICES &&
    cbType !== CONTENT_BLOCK_TYPES.EXERCISE_CONTENT &&
    cbType?.toLowerCase() !== 'dct';

  const onSaveClick = () => {
    let error = false;
    if (contentBlock.basicInfo.text === '' || contentBlock.basicInfo.text.trim() === '') {
      setTitleRequired(true);
      error = true;
    }
    if (contentBlock.basicInfo.type === '') {
      setTypeRequired(true);
      error = true;
    }
    if (error) {
      return;
    }
    if (contentBlock.basicInfo.type === 'single-select' || contentBlock.basicInfo.type === 'multi-select') {
      if (contentBlock.basicInfo.choices.length !== 0) {
        const choicesEmpty = contentBlock.basicInfo.choices.filter(item => item.choice === '');
        if (choicesEmpty.length !== 0) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Choices cant be empty.',
            }),
          );
        }
        if (choicesEmpty.length === 0) {
          onSave(contentBlock);
          setUpdateComponent(!updateComponent);
          clearData();
          getCBs();
        }
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Choices cant be empty.',
          }),
        );
      }
    } else if (contentBlock.basicInfo.type === 'education') {
      if (contentBlock.basicInfo.educationContentSlug === '') {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Education article cant be empty.',
          }),
        );
      } else {
        onSave(contentBlock);
        setUpdateComponent(!updateComponent);
        clearData();
        getCBs();
      }
    } else if (contentBlock.basicInfo.type === 'exercise') {
      if (contentBlock.basicInfo.exerciseContent.trim() === '') {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Exercise content cant be empty.',
          }),
        );
      } else {
        onSave(contentBlock);
        setUpdateComponent(!updateComponent);
        clearData();
        getCBs();
      }
    } else if (contentBlock.basicInfo.type === 'rating-scale') {
      if (
        contentBlock.basicInfo.ratingScale.highLabel.trim() === '' ||
        contentBlock.basicInfo.ratingScale.lowLabel.trim() === ''
      ) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Rating Labels cant be empty.',
          }),
        );
      } else {
        onSave(contentBlock);
        setUpdateComponent(!updateComponent);
        clearData();
        getCBs();
      }
    } else {
      onSave(contentBlock);
      setUpdateComponent(!updateComponent);
      clearData();
      getCBs();
    }
  };
  const onDeleteClick = () => {
    onDelete(contentBlock.cbId);
    setUpdateComponent(!updateComponent);
  };

  // fetch education contents
  const getEducationalContents = () => {
    const params = { content_type: 'educationalContent', limit: 250 };
    ContentfulClient.getEntries(params)
      .then(response => {
        setEducationalContents(
          response.items.map(item => ({ value: item.sys.id, label: item.fields.title })),
        );
      })
      .catch(err => {
        console.log('Fetch educational contents error', err);
      });
  };

  const getAllChoiceTemplatesCall = async () => {
    try {
      const allChoiceTemplates = await getAllChoiceTemplates();
      if (allChoiceTemplates.status !== 200) {
        // AlertUtil.showError(choiceTemplates.errors[0].endUserMessage);
        console.log('Error getting all choice templates');
      } else {
        setChoiceTemplates(allChoiceTemplates.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (addCbButtonPressed) {
      clearData();
    }
  }, [addCbButtonPressed]);

  useEffect(() => {
    setUpdateComponent(!updateComponent);
  }, [contentBlock?.displayLogics?.key]);

  /**
   * @function useEffect
   * @description This method used to call functions to load data for the first time .
   */
  useEffect(() => {
    dispatch(appointmentActionCreators.fetchProviderRoles());
    getAllChoiceTemplatesCall()
      .then(res => console.log(res))
      .catch(err => console.log(err));
    getEducationalContents();
  }, []);

  const allLogicsComplete = () => {
    return (
      contentBlock?.displayLogics &&
      contentBlock?.displayLogics.length > 0 &&
      contentBlock?.displayLogics.length ===
        contentBlock?.displayLogics.filter(logic => logic.type && logic.key && logic.value && logic.rule)
          .length
    );
  };

  const initializeRatingScale = cb => {
    if (!cb.basicInfo.ratingScale) {
      cb.basicInfo.ratingScale = {
        values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      };
    } else if (!cb.basicInfo.ratingScale.values || cb.basicInfo.ratingScale.values.length === 0) {
      cb.basicInfo.ratingScale.values = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    }
  };

  const changeHandlers = {
    basicInfo: {
      onTypeChanged: type => {
        setTypeRequired(type === '');
        contentBlock.basicInfo.type = type;
        if (type === CONTENT_BLOCK_TYPES.RATING_SCALE) {
          initializeRatingScale(contentBlock);
        }
      },
      onTextChanged: text => {
        contentBlock.basicInfo.text = text;
        setTitleRequired(text === '');
      },
      onSubtitleChanged: text => {
        contentBlock.subtitle = text;
      },
      onUpdateRequired: checked => {
        contentBlock.required = checked;
      },
      onPromptChanged: text => {
        contentBlock.prompt = text;
      },
      onHelperTextChanged: text => {
        contentBlock.helperText = text;
      },
      onChoicesChanged: choices => {
        contentBlock.basicInfo.choices = choices;
      },
      variableMappingChanged: variableMapping => {
        contentBlock.basicInfo.variableMapping = variableMapping;
      },
      educationItemChanged: (entryId: string) => {
        contentBlock.basicInfo.educationContentSlug = entryId;
      },
      exerciseItemChanged: (htmlData: string) => {
        contentBlock.basicInfo.exerciseContent = htmlData;
      },
      popupTextChanged: popupText => {
        contentBlock.basicInfo.popupText = popupText;
      },
      ratingScaleChanged: (values: string[]) => {
        if (contentBlock.basicInfo.ratingScale) {
          contentBlock.basicInfo.ratingScale.values = values;
        } else {
          contentBlock.basicInfo.ratingScale = { values };
        }
      },
      ratingLowLabelChanged: lowEndLabel => {
        if (contentBlock.basicInfo.ratingScale) {
          contentBlock.basicInfo.ratingScale.lowLabel = lowEndLabel;
        } else {
          contentBlock.basicInfo.ratingScale = {
            lowLabel: lowEndLabel,
          };
        }
      },
      ratingHighLabelChanged: highLabel => {
        if (contentBlock.basicInfo.ratingScale) {
          contentBlock.basicInfo.ratingScale.highLabel = highLabel;
        } else {
          contentBlock.basicInfo.ratingScale = {
            highLabel,
          };
        }
      },
    },
    displayLogic: {
      mainTypeChanged: type => {
        contentBlock.mainLogicType = type;
        if (type === 'always' || type === 'RAW') {
          contentBlock.displayLogics = [];
          contentBlock.ruleAggregator = null;
        } else {
          contentBlock.displayLogics = [{ type }];
        }
      },
      logicsUpdated: logics => {
        contentBlock.displayLogics = logics.map(logic => {
          if (logic.rule === 'before-today' || logic.rule === 'after-today') {
            logic.value = 'today';
          }
          return logic;
        });
      },
      ruleAggregatorChanged: ruleAggregator => {
        contentBlock.ruleAggregator = ruleAggregator;
      },
    },
  };

  /**
   * @function getFilteredProfileElementsBasedOnType
   * @description This method used to get profile elements based on selected type.
   */
  const getFilteredProfileElementsBasedOnType = () => {
    const type = contentBlock?.basicInfo?.type || '';
    let filteredProfileElements = profileElements;
    if (type === CONTENT_BLOCK_TYPES.SINGLE_SELECT || type === CONTENT_BLOCK_TYPES.MULTI_SELECT) {
      filteredProfileElements = profileElements?.filter(
        profileElement =>
          profileElement?.profileElementInfo?.type === 'USER_DEFINED_VALUES' ||
          profileElement?.profileElementInfo?.type === 'YES_NO',
      );
    } else if (type === CONTENT_BLOCK_TYPES.RATING_SCALE) {
      filteredProfileElements = profileElements?.filter(
        profileElement =>
          profileElement.profileElementInfo.type === 'USER_DEFINED_VALUES' ||
          profileElement.profileElementInfo.type === 'RATING_SCALE',
      );
    } else if (type === CONTENT_BLOCK_TYPES.TEXT_INPUT) {
      filteredProfileElements = profileElements?.filter(
        profileElement => profileElement?.profileElementInfo?.type === 'TEXT_INPUT',
      );
    }
    filteredProfileElements = filteredProfileElements?.sort((a, b) =>
      a?.profileElementInfo?.key?.trim().localeCompare(b?.profileElementInfo?.key?.trim()),
    );
    return filteredProfileElements;
  };

  const getSelectedElementType = logic => {
    const selectedProfileElement = profileElements.filter(
      element => element.profileElementInfo.key === logic.key,
    )[0];
    return PROFILE_ELEMENT_DEF_TYPE[selectedProfileElement?.profileElementInfo?.type];
  };

  const getOperatorValues = logic => {
    let operators = [];
    if (logic.type && logic.key) {
      if (logic.type === 'P') {
        const selectedElementType = getSelectedElementType(logic);
        switch (selectedElementType) {
          case PROFILE_ELEMENT_DEF_TYPE.SCORE_BASED:
          case PROFILE_ELEMENT_DEF_TYPE.RATING_SCALE:
          case PROFILE_ELEMENT_DEF_TYPE.NUMERIC: {
            operators = [
              { value: 'equals', displayName: 'Equals' },
              { value: 'less-than', displayName: 'Less than' },
              { value: 'greater-than', displayName: 'Greater than' },
            ];
            break;
          }
          case PROFILE_ELEMENT_DEF_TYPE.DATE:
          case PROFILE_ELEMENT_DEF_TYPE.DATE_TIME: {
            operators = [
              { value: 'before-today', displayName: 'Before today’s date' },
              { value: 'after-today', displayName: 'After today’s date' },
            ];
            break;
          }
          default: {
            operators = BUILDER_CONSTANTS.DefaultOperators;
            break;
          }
        }
      }
    }
    return operators;
  };

  const getValidOperatorValue = (logic, index) => {
    if (!logic.rule) {
      // Return null or undefined whatever rule was set
      return logic.rule;
    }
    const operators = getOperatorValues(logic);
    if (operators.map(op => op.value).includes(logic.rule)) {
      return logic.rule;
    }
    let logics = contentBlock?.displayLogics;

    logics = logics.map((item, indexInner) => {
      if (indexInner === index) {
        item.rule = null;
      }
      return item;
    });
    changeHandlers.displayLogic.logicsUpdated(logics);
    return null;
  };

  const renderOperatorValues = logic => {
    const operators = getOperatorValues(logic);
    return operators.length === 0 ? [] : operators.map(op => ({ label: op.displayName, value: op.value }));
  };

  const getMainLogicTypeValue = () => {
    if (contentBlock?.mainLogicType) {
      return contentBlock?.mainLogicType as string;
    }
    if (contentBlock?.displayLogics && contentBlock?.displayLogics.length > 0) {
      return contentBlock.displayLogics[0].type as string;
    }
    if (contentBlock?.rawDisplayLogic) {
      return 'RAW';
    }
    return 'always';
  };

  const renderProfileElementValues = logic => {
    const filteredElements = profileElements.filter(element => element.profileElementInfo.key === logic.key);
    if (
      filteredElements.length > 0 &&
      filteredElements[0].profileElementInfo &&
      filteredElements[0].profileElementInfo.values
    ) {
      return filteredElements[0].profileElementInfo.values.map(val => {
        return { label: val, value: val };
      });
    }
    return null;
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
      {(label === 'Title' ||
        label === 'Content block type' ||
        label === 'Low end label' ||
        label === 'High end label' ||
        label === 'Select education article' ||
        label === 'Exercise Content') && <span className={classes.asterick}>*</span>}
    </Heading>
  );

  const renderValues = (logic, index) => {
    if (
      !logic.rule ||
      !logic.key ||
      getMainLogicTypeValue() === 'RAW' ||
      getMainLogicTypeValue() === 'always'
    ) {
      return null;
    }
    if (logic.type === 'P') {
      const profileElementType = getSelectedElementType(logic);
      if (profileElementType === PROFILE_ELEMENT_DEF_TYPE.SCORE_BASED) {
        return (
          <Box className={classes.section}>
            {renderLabel('Enter value')}
            <Input
              value={logic.value || ''}
              name={`raw-display-logic-${index}`}
              placeholder="123"
              onChange={e => {
                let logics = contentBlock?.displayLogics;
                logics = logics.map((item, indexInner) => {
                  if (indexInner === index) {
                    item.value = e.target.value;
                  }
                  return item;
                });
                changeHandlers.displayLogic.logicsUpdated(logics);
                setUpdateComponent(!updateComponent);
              }}
              size={inputSize.M}
              fullWidth
            />
          </Box>
        );
      }
      if (
        profileElementType === PROFILE_ELEMENT_DEF_TYPE.DATE ||
        profileElementType === PROFILE_ELEMENT_DEF_TYPE.DATE_TIME
      ) {
        return null;
      }
      if (profileElementType === PROFILE_ELEMENT_DEF_TYPE.TEXT_INPUT) {
        return (
          <Box className={classes.section}>
            {renderLabel('Enter value')}
            <Input
              value={logic.value || ''}
              name={`raw-display-logic-${index}`}
              placeholder="123"
              onChange={e => {
                let logics = contentBlock?.displayLogics;
                logics = logics.map((item, indexInner) => {
                  if (indexInner === index) {
                    item.value = e.target.value;
                  }
                  return item;
                });
                changeHandlers.displayLogic.logicsUpdated(logics);
                setUpdateComponent(!updateComponent);
              }}
              size={inputSize.M}
              fullWidth
            />
          </Box>
        );
      }
    }
    return (
      <div className="field-wrapper">
        <Box className={classes.section}>
          {renderLabel('Select value')}
          <Select
            value={logic.value || ''}
            variant={selectType.SECONDARY}
            options={renderProfileElementValues(logic) || []}
            emptyText="Select item"
            displayEmpty
            onChange={e => {
              let logics = contentBlock?.displayLogics;

              logics = logics.map((item, indexInner) => {
                if (indexInner === index) {
                  item.value = e.target.value;
                }
                return item;
              });
              changeHandlers.displayLogic.logicsUpdated(logics);
              setUpdateComponent(!updateComponent);
            }}
          />
        </Box>
      </div>
    );
  };

  const uniqueChoicesName = choices => {
    const uniqueChoices = choices?.map(choice => choice.text);
    return uniqueChoices.length === choices.length;
  };

  const openChoiceTemplateModal = () => {
    setOpenChoiceTemplateSaveModal(true);
  };

  const closeChoiceTemplateModal = () => {
    setOpenChoiceTemplateSaveModal(false);
    clearData();
  };

  /**
   * @function saveChoiceTemplate
   * @description This method is used to save choice template in db.
   * @params payload ( Name , Choices ).
   */
  const onSaveChoiceTemplate = (name: string) => {
    if (name === '') {
      console.log('Please enter template name');
      return;
    }
    closeChoiceTemplateModal();
    let { choices } = contentBlock?.basicInfo;
    choices = choices
      .filter(choice => choice.choice)
      .map(singleChoice => {
        const { choice, score } = singleChoice;
        return { text: choice, score };
      });
    if (choices && choices.length > 0 && uniqueChoicesName(choices)) {
      const choiceRequest = {
        name,
        choices,
      };
      try {
        saveChoiceTemplate(choiceRequest).then(response => {
          if (response.status !== 200) {
            // AlertUtil.showError(response.errors[0].endUserMessage);
            console.log('Error Saving Choice Template');
            // this.setState({ isLoading: false });
          } else {
            // AlertUtil.showSuccess('Choice template saved successfully');
            console.log('Choice template saved successfully');
            getAllChoiceTemplatesCall()
              .then(() => console.log('-'))
              .catch(() => console.log('-'));
            setSelectedChoiceTemplate(name);
            // this.setState({ selectedChoiceTemplate: name, isLoading: false });
          }
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('Invalid Choices');
    }
  };

  const getSelectedChoiceDetail = selectedName => {
    const selectedItem = choiceTemplates.find(template => template.name === selectedName);
    const { name, choices } = selectedItem;
    return { name, choices: choices?.map(choice => ({ choice: choice.text, score: choice.score })) };
  };

  const renderSectionPerType = () => {
    switch (contentBlock?.basicInfo?.type) {
      case CONTENT_BLOCK_TYPES.EDUCATION_CONTENT:
        return (
          <Box className={classes.section}>
            {renderLabel('Select education article')}
            <Input
              fullWidth
              name="educationArticle"
              size={inputSize.M}
              variant={inputType.AUTOCOMPLETE}
              value={selectedEducationalContent}
              getOptionLabel={(option: ISelectOption) => option.label}
              options={educationalContents}
              onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
                changeHandlers.basicInfo.educationItemChanged(newValue.value);
                setSelectedEducationalContent(newValue);
              }}
            />
          </Box>
        );
      case CONTENT_BLOCK_TYPES.EXERCISE_CONTENT:
        return (
          <Box className={classes.section}>
            {renderLabel('Exercise Content')}
            <JoditUIEditor
              htmlValue={contentBlock?.basicInfo?.exerciseContent}
              handleChange={changeHandlers.basicInfo.exerciseItemChanged}
            />
          </Box>
        );
      case CONTENT_BLOCK_TYPES.FILTERED_PROVIDERS:
        return (
          <Box className={classes.section}>
            {renderLabel('Designation / Provider role')}
            <Input
              fullWidth
              name="providersRole"
              size={inputSize.M}
              variant={inputType.AUTOCOMPLETE}
              value={selectedProviderRole}
              getOptionLabel={(option: ISelectOption) => option.label}
              options={providerRoleOptions}
              onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
                changeHandlers.basicInfo.popupTextChanged(newValue.value);
                setSelectedProviderRole(newValue);
              }}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  const renderChoicesBlock = () => {
    switch (contentBlock?.basicInfo?.type) {
      case CONTENT_BLOCK_TYPES.RATING_SCALE:
        return (
          <Box className={classes.formBlock}>
            <Box className={classes.blockHeader}>
              <Text className={classes.title}>Manage scale</Text>
            </Box>
            <Box display="flex" alignItems="center" gap={3}>
              <Box className={classes.section} flex={1}>
                {renderLabel('Low end label')}
                <Input
                  fullWidth
                  id="ratingLowEndLabel"
                  name="ratingLowEndLabel"
                  value={contentBlock.basicInfo?.ratingScale?.lowLabel || ''}
                  placeholder="Enter Low End Label"
                  size={inputSize.M}
                  onChange={e => {
                    changeHandlers.basicInfo.ratingLowLabelChanged(e.target.value);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
              <Box className={classes.section} flex={1}>
                {renderLabel('High end label')}
                <Input
                  fullWidth
                  id="ratingHighEndLabel"
                  name="ratingHighEndLabel"
                  value={contentBlock.basicInfo?.ratingScale?.highLabel || ''}
                  placeholder="Enter High End Label"
                  size={inputSize.M}
                  onChange={e => {
                    changeHandlers.basicInfo.ratingHighLabelChanged(e.target.value);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gap={3}>
              <Box className={classes.section} flex={1}>
                {renderLabel('Options in scale')}
                <Select
                  value={contentBlock.basicInfo?.ratingScale?.values?.length || 10}
                  variant={selectType.SECONDARY}
                  options={RATING_SCALE_OPTIONS.map((value: number) => ({
                    label: `${value}`,
                    value: `${value}`,
                  }))}
                  displayEmpty
                  emptyText="Select item"
                  onChange={e => {
                    let start = 1;
                    if (
                      contentBlock.basicInfo?.ratingScale?.values &&
                      contentBlock.basicInfo?.ratingScale?.values.length > 0
                    ) {
                      start = parseInt(contentBlock.basicInfo?.ratingScale.values[0], 10);
                    }
                    let end = e.target.value;
                    if (start === 0) {
                      end -= 1;
                    }
                    const values = [];
                    for (let v = start; v <= end; v++) {
                      values.push(`${v}`);
                    }
                    changeHandlers.basicInfo.ratingScaleChanged(values);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
              <Box className={classes.section} flex={1}>
                {renderLabel('Scale starts at')}
                <Select
                  value={
                    contentBlock.basicInfo?.ratingScale?.values &&
                    contentBlock.basicInfo?.ratingScale?.values.length > 0
                      ? contentBlock.basicInfo?.ratingScale.values[0]
                      : '1'
                  }
                  variant={selectType.SECONDARY}
                  displayEmpty
                  emptyText="Select item"
                  options={[
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                  ]}
                  onChange={e => {
                    let totalValues = 10;
                    if (
                      contentBlock.basicInfo?.ratingScale?.values &&
                      contentBlock.basicInfo?.ratingScale?.values.length > 0
                    ) {
                      totalValues = contentBlock.basicInfo?.ratingScale.values.length;
                    }
                    if (e.target.value === 1) {
                      totalValues += 1;
                    }
                    const values = [];
                    for (let v = e.target.value; v < totalValues; v++) {
                      values.push(`${v}`);
                    }
                    changeHandlers.basicInfo.ratingScaleChanged(values);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
            </Box>
          </Box>
        );
      case CONTENT_BLOCK_TYPES.TEXT_INPUT:
        return (
          <Box className={classes.formBlock}>
            <Box className={classes.blockHeader}>
              <Text className={classes.title}>Manage input</Text>
            </Box>
            <Box className={clsx(classes.section, classes.noMb)}>
              {renderLabel('Helper Text')}
              <Input
                fullWidth
                value={contentBlock.helperText}
                name="helperText"
                placeholder="Enter Helper Text"
                onChange={e => {
                  changeHandlers.basicInfo.onHelperTextChanged(e.target.value);
                  setUpdateComponent(!updateComponent);
                }}
                size={inputSize.M}
              />
            </Box>
          </Box>
        );
      case CONTENT_BLOCK_TYPES.SINGLE_SELECT:
      case CONTENT_BLOCK_TYPES.MULTI_SELECT:
        return (
          <Box className={classes.formBlock}>
            <Box className={classes.blockHeader}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                <Text className={classes.title}>
                  Manage choices
                  <span className={classes.asterick}>*</span>
                </Text>
                <Stack direction="row" alignItems="center" gap={1}>
                  <IconButton
                    icon="save-outlined"
                    className={classes.saveTempBtn}
                    onClick={() => {
                      openChoiceTemplateModal();
                    }}
                  >
                    Save as template
                  </IconButton>
                  <Select
                    name="choiceTemplate"
                    variant={selectType.PRIMARY}
                    displayEmpty
                    emptyText="Use template"
                    value={selectedChoiceTemplate}
                    options={choiceTemplates?.map(choiceTemplate => ({
                      value: choiceTemplate.name,
                      label: choiceTemplate.name,
                    }))}
                    onChange={e => {
                      const selectedItemDetail = getSelectedChoiceDetail(e.target.value);
                      if (selectedItemDetail) {
                        const { choices } = selectedItemDetail;
                        setSelectedChoiceTemplate(e.target.value);
                        changeHandlers.basicInfo.onChoicesChanged(choices);
                        setUpdateComponent(!updateComponent);
                      }
                    }}
                  />
                </Stack>
              </Stack>
            </Box>

            <Box display="flex" flexDirection="column" gap={2}>
              {contentBlock?.basicInfo?.choices?.map((choice, index) => (
                <FlowChoice
                  key={index}
                  item={choice}
                  isCurrentElementExisting={showProfileElement}
                  options={profileElements
                    .find(
                      element =>
                        element.profileElementInfo.key === contentBlock.basicInfo?.variableMapping?.name,
                    )
                    ?.profileElementInfo?.values?.map(val => ({ label: val, value: val }))}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>, fieldName) => {
                    const newChoices = [...contentBlock.basicInfo.choices];
                    if (fieldName === 'choice') {
                      newChoices[index].choice = e.target.value;
                    } else if (fieldName === 'score') {
                      newChoices[index].score = e.target.value;
                    } else if (fieldName === 'value') {
                      newChoices[index].value = e.target.value;
                    } else if (fieldName === 'riskFactor') {
                      newChoices[index].riskFactor = e.target.checked;
                    }
                    setUpdateComponent(!updateComponent);
                  }}
                  handleChangeSelect={(value: string) => {
                    changeHandlers.basicInfo.onChoicesChanged(
                      contentBlock.basicInfo?.choices?.map((item, indexInner) => {
                        if (indexInner === index) {
                          item.value = value;
                        }
                        return item;
                      }),
                    );
                    setUpdateComponent(!updateComponent);
                  }}
                  onDuplicateChoice={() => {
                    changeHandlers.basicInfo.onChoicesChanged([
                      ...contentBlock?.basicInfo?.choices,
                      { ...choice },
                    ]);
                    setUpdateComponent(!updateComponent);
                  }}
                  onDeleteChoice={() => {
                    const newChoices = [...contentBlock?.basicInfo?.choices];
                    newChoices.splice(index, 1);
                    changeHandlers.basicInfo.onChoicesChanged(newChoices);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              ))}
              <Box>
                <IconButton
                  icon="plus"
                  className={classes.addIconBtn}
                  onClick={() => {
                    if (contentBlock?.basicInfo?.choices) {
                      changeHandlers.basicInfo.onChoicesChanged([
                        ...contentBlock?.basicInfo?.choices,
                        { choice: '', score: 0, value: '', riskFactor: false },
                      ]);
                    } else {
                      changeHandlers.basicInfo.onChoicesChanged([
                        { choice: '', score: 0, value: '', riskFactor: false },
                      ]);
                    }
                    setUpdateComponent(!updateComponent);
                  }}
                >
                  Add Choice
                </IconButton>
              </Box>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const renderGeneralBlock = () => (
    <Box className={clsx(classes.formBlock, classes.formBlockNoBorder)}>
      <Box className={classes.blockHeader}>
        <Text className={classes.title}>General</Text>
      </Box>
      {shouldProvideText && (
        <Box className={classes.section}>
          {renderLabel('Title')}
          <Textarea
            value={contentBlock?.basicInfo?.text}
            name="message"
            type="text"
            placeholder="Enter Title"
            onChange={e => {
              changeHandlers.basicInfo.onTextChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
            minRows={1}
            maxRows={5}
            className={classes.textarea}
          />
          {titleRequired && <TextError errorMsg="Title is required" />}
        </Box>
      )}
      <Box className={classes.section}>
        {renderLabel('Subtitle')}
        <Textarea
          value={contentBlock?.subtitle}
          name="subtitle"
          type="text"
          placeholder="Enter Subtitle"
          onChange={e => {
            changeHandlers.basicInfo.onSubtitleChanged(e.target.value);
            setUpdateComponent(!updateComponent);
          }}
          minRows={2}
          maxRows={5}
          className={classes.textarea}
        />
      </Box>
      <Box className={clsx(classes.section)} gap="32px">
        <Toggle
          checked={contentBlock?.required}
          labelPosition={positionType.RIGHT}
          onChange={checked => {
            changeHandlers.basicInfo.onUpdateRequired(checked);
          }}
        >
          Required
        </Toggle>
      </Box>
      <Box className={clsx(classes.section)}>
        {renderLabel('Prompt Text')}
        <Input
          fullWidth
          value={contentBlock?.prompt}
          name="prompt"
          placeholder="Enter Prompt Text"
          onChange={e => {
            changeHandlers.basicInfo.onPromptChanged(e.target.value);
            setUpdateComponent(!updateComponent);
          }}
          size={inputSize.M}
        />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Content block type')}
        <Select
          name="contentBlockType"
          value={contentBlock?.basicInfo?.type}
          variant={selectType.SECONDARY}
          options={Object.values(CONTENT_BLOCK_TYPES_EVALUATION).map((value: string) => ({
            label: capitalize(value).replaceAll('-', ' '),
            value,
          }))}
          emptyText="Select item"
          displayEmpty
          onChange={e => {
            changeHandlers.basicInfo.onTypeChanged(e.target.value);
            setUpdateComponent(!updateComponent);
          }}
        />
        {typeRequired && <TextError errorMsg="Type is required" />}
      </Box>
      {showProfilieElementMapping && (
        <FormControlLabel
          control={<Checkbox />}
          checked={showProfileElement}
          name="showProfileElement"
          label="Maps to profile element"
          onChange={() => setShowProfileElement(!showProfileElement)}
        />
      )}
      {showProfilieElementMapping && showProfileElement && (
        <Box className={classes.section}>
          {renderLabel('Profile element')}
          <Input
            fullWidth
            name="profileElement"
            size={inputSize.M}
            variant={inputType.AUTOCOMPLETE}
            value={currentProfileElement}
            getOptionLabel={(option: ISelectOption) => option.label}
            options={profileElementOptions}
            onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
              if (newValue) {
                changeHandlers.basicInfo?.variableMappingChanged({
                  scope: 'profile', // contentBlock.basicInfo?.variableMapping.scope
                  name: newValue.value,
                });
                setCurrentProfileElement(newValue);
              } else {
                changeHandlers.basicInfo?.variableMappingChanged(null);
                setCurrentProfileElement(null);
              }
            }}
          />
        </Box>
      )}
      {renderSectionPerType()}
    </Box>
  );

  const renderDisplayBlock = () => {
    const selectOptions = getFilteredProfileElementsBasedOnType().map(profileElement => ({
      label: `${profileElement.profileElementInfo.key}`,
      value: profileElement.profileElementInfo.key,
    }));
    return (
      <Box className={classes.formBlock}>
        <Box className={classes.blockHeader}>
          <Text className={classes.title}>Display logic</Text>
        </Box>
        <Box className={classes.section}>
          {renderLabel('Select logic')}
          <Select
            value={getMainLogicTypeValue()}
            name="logic"
            variant={selectType.SECONDARY}
            options={flowLogicOptions}
            emptyText="Select item"
            displayEmpty
            onChange={e => {
              changeHandlers.displayLogic.mainTypeChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
          />
        </Box>
        {contentBlock?.displayLogics?.map((logic, index) => (
          <Box key={index} display="flex" flexDirection="column" gap={3}>
            {index > 0 && (
              <Box className={classes.section} flex={1}>
                {renderLabel('Select display logic')}
                <Box display="flex" alignItems="center" gap={3}>
                  <Select
                    value={logic.type || ''}
                    variant={selectType.SECONDARY}
                    emptyText="Select item"
                    displayEmpty
                    style={{ flex: 1 }}
                    options={[{ label: 'If Profile Element', value: 'P' }]}
                    onChange={e => {
                      let logics = contentBlock?.displayLogics;
                      logics = logics.map((item, ind) => {
                        if (ind === index && item.type !== e.target.value) {
                          item.type = e.target.value;
                          item.key = null;
                          item.value = null;
                        }
                        return item;
                      });
                      changeHandlers.displayLogic.logicsUpdated(logics);
                      setUpdateComponent(!updateComponent);
                    }}
                  />
                  <IconButton
                    icon="delete-outlined-2"
                    className={classes.removeIconBtn}
                    onClick={() => {
                      const logics = contentBlock?.displayLogics;
                      logics.splice(index, 1);
                      changeHandlers.displayLogic.logicsUpdated(logics);
                      setUpdateComponent(!updateComponent);
                      getCBs();
                    }}
                  />
                </Box>
              </Box>
            )}
            {logic.type === 'P' && (
              <Box className={classes.section}>
                {renderLabel('Select profile element')}
                <Input
                  fullWidth
                  name="profileElement"
                  size={inputSize.M}
                  variant={inputType.AUTOCOMPLETE}
                  value={selectOptions.find(el => el.value === logic.key) || defaultSelectOption}
                  getOptionLabel={(option: ISelectOption) => option.label}
                  options={selectOptions}
                  onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
                    let logics = contentBlock?.displayLogics;
                    logics = logics.map((item, indexInner) => {
                      if (indexInner === index && item.key !== newValue.value) {
                        item.key = newValue.value;
                        item.rule = null;
                        item.value = null;
                      }
                      return item;
                    });
                    changeHandlers.displayLogic.logicsUpdated(logics);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
            )}
            {logic.key && (
              <Box className={classes.section}>
                {renderLabel('Select rule')}
                <Select
                  value={getValidOperatorValue(logic, index) || ''}
                  variant={selectType.SECONDARY}
                  options={renderOperatorValues(logic)}
                  emptyText="Select item"
                  displayEmpty
                  onChange={e => {
                    let logics = contentBlock?.displayLogics;
                    logics = logics?.map((item, indexInner) => {
                      if (indexInner === index) {
                        item.rule = e.target.value;
                      }
                      return item;
                    });
                    changeHandlers.displayLogic.logicsUpdated(logics);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
            )}
            {renderValues(logic, index)}
            {index === 0 && contentBlock?.displayLogics && contentBlock.displayLogics.length > 1 && (
              <Box className={classes.section}>
                {renderLabel('Rule aggregator')}
                <Select
                  value={contentBlock?.ruleAggregator || ''}
                  variant={selectType.SECONDARY}
                  options={[
                    { label: 'AND', value: 'and' },
                    { label: 'OR', value: 'or' },
                  ]}
                  emptyText="Select item"
                  displayEmpty
                  onChange={e => {
                    changeHandlers.displayLogic.ruleAggregatorChanged(e.target.value);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
            )}
          </Box>
        ))}
        {getMainLogicTypeValue() !== 'RAW' &&
          contentBlock?.displayLogics &&
          contentBlock?.displayLogics.length > 0 &&
          allLogicsComplete() && (
            <IconButton
              icon="plus"
              className={classes.addIconBtn}
              onClick={() => {
                changeHandlers.displayLogic.logicsUpdated([
                  ...contentBlock?.displayLogics,
                  { rule: 'equals' },
                ]);
                setUpdateComponent(!updateComponent);
              }}
            >
              Add Another
            </IconButton>
          )}
      </Box>
    );
  };
  const renderRelatedInformationBlock = () => {
    return (
      <Box className={classes.formBlock}>
        <Box className={classes.blockHeader}>
          <Text className={classes.title}>Related information</Text>
        </Box>
        <Box className={classes.section}>
          {renderLabel('Select profile element')}
          <Input
            fullWidth
            name="relatedProfileElements"
            variant={inputType.TAGS}
            size={inputSize.M}
            value={contentBlock?.relatedProfileElements || []}
            getOptionLabel={(option: string) => option}
            options={profileElements.map(element => element.profileElementInfo.key)}
            onChange={(_: SyntheticEvent, newValue: string) => {
              contentBlock.relatedProfileElements = newValue;
              setUpdateComponent(!updateComponent);
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderDangerZone = () => (
    <Box className={classes.formBlock} alignItems="flex-start">
      <Text className={classes.title}>Danger zone</Text>
      <IconButton icon="delete-outlined-2" className={classes.removeContentBtn} onClick={onDeleteClick}>
        Remove content block
      </IconButton>
    </Box>
  );

  return (
    <>
      <Modal open={openChoiceTemplateSaveModal} onClose={closeChoiceTemplateModal}>
        <Box className={classes.modal}>
          <Box className={classes.modalContent}>
            <Box className={classes.content}>
              <Box className={classes.section}>
                {renderLabel('Choice template name')}
                <Input
                  fullWidth
                  size={inputSize.M}
                  placeholder="Choice Template Name"
                  value={choiceTemplateName}
                  onChange={e => {
                    setChoiceTemplateName(e.target.value);
                  }}
                />
              </Box>
              <Box display="flex" alignItems="center" gap={3} mt={3}>
                <Button size={btnSize.SMALL} onClick={() => onSaveChoiceTemplate(choiceTemplateName)}>
                  Save
                </Button>
                <Button size={btnSize.SMALL} onClick={closeChoiceTemplateModal}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Drawer
        open={isOpen}
        onClose={onCloseDrawer}
        variant={drawerType.FORM}
        title={
          titleLabel !== '' ? titleLabel : addCbButtonPressed ? 'Add content block' : 'Edit content block'
        }
        className={classes.drawer}
        footer={
          <Box className={classes.footer}>
            <Button
              variant={btnType.TEXT}
              onClick={() => {
                getCBs();
                if (addCbButtonPressed) {
                  clearData();
                }
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button onClick={onSaveClick}>Save</Button>
          </Box>
        }
      >
        <Box>
          <Box className={classes.formContent}>
            {renderGeneralBlock()}
            {renderChoicesBlock()}
            {renderDisplayBlock()}
            {renderRelatedInformationBlock()}
            {!addCbButtonPressed && renderDangerZone()}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export { ContentBlockDrawer };
