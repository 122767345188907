import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topWrap: {
    display: 'flex',
    gap: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 40,
  },
  topWrap2: {
    paddingBottom: 0,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  addFilterBtn: {
    height: 40,
    fontSize: 14,
    borderColor: colors.primary300,
    color: colors.primary600,
  },
  contentWrap: {
    flex: 1,
  },
  noFilter: {
    color: colors.neutral600,
    fontSize: 14,
    textAlign: 'center',
  },
  forms: {
    flex: 1,
    display: 'flex',
    paddingLeft: 42,
    paddingRight: 42,
    overflowY: 'auto',
    flexDirection: 'column',
    gap: 24,
    height: 'calc(100% - 80px)',
    paddingBottom: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
    flex: 1,
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
    },
  },
});
