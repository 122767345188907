import { FILTER_COLLAPSABLE_SECTION_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';
import { BUILDER_CONSTANTS } from '../../../constants/CommonConstants';

export const CbMultiSelectFilterOptionsMock = [
  {
    sectionTitle: 'Type',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: Object.keys(BUILDER_CONSTANTS.BlockTypeNamesInEvaluation).map(key => {
      return {
        label: BUILDER_CONSTANTS.BlockTypeNamesInEvaluation[key],
        value: BUILDER_CONSTANTS.BlockTypeNamesInEvaluation[key],
      };
    }),
  },
];
