import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerNoDrawer: {
    height: 80,
    padding: 16,
    borderBottom: 'none',
    '& $headTitle': {
      fontSize: 18,
      lineHeight: '24px',
    },
    '& $headSubtitle': {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  headWrap: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  headSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'block',
    margin: 0,
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    '& svg': {
      width: 30,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  form: {
    flex: 1,
  },
  formNoDrawer: {
    '& $formContent': {
      height: 'calc(100vh - 423px)',
      overflowY: 'auto',
      minHeight: 'unset',
    },
  },
  formContent: {
    flex: 1,
    display: 'flex',
    minHeight: 'calc(100vh - 254px)',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
});
