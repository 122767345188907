/* eslint-disable react/destructuring-assignment */
import { FC, useEffect, useMemo, useState } from 'react';

import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box, Stack } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Icons } from '@confidant-health/lib/icons';

import { getElkChatbotReport } from 'services/reporting/reporting.service';
import BreakdownChart from 'pages/admin/plan-items/components/plan-item-detail/breakdown-chart';
import DctTable from '../../data-collection-templates/dct-table';
import AssignmentsChart from '../../assignments-chart';

import {
  tableColumns,
  getChartInfo,
  getLast30DaysData,
  getPast30DaysData,
  getRecentCompletionsData,
  getChatbotReportDetailQuery,
} from './ChatbotDetail.constants';
import { IChatbotReportDetail } from '../Chatbots.types';
import { useStyles } from './ChatbotDetail.styles';

type Props = {
  open: boolean;
  item: any;
  onClose: () => void;
};

const ChatbotDetail: FC<Props> = ({ open, onClose, item }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [chatbotReportDetail, setChatbotReportDetail] = useState<IChatbotReportDetail>(null);

  const chartInfo = useMemo(() => getChartInfo(chatbotReportDetail), [chatbotReportDetail]);
  const last30DaysData = useMemo(() => getLast30DaysData(chatbotReportDetail), [chatbotReportDetail]);
  const past30DaysData = useMemo(() => getPast30DaysData(chatbotReportDetail), [chatbotReportDetail]);
  const recentCompletionsData = useMemo(
    () => getRecentCompletionsData(chatbotReportDetail),
    [chatbotReportDetail],
  );

  useEffect(() => {
    if (!item || !item.name) {
      return;
    }

    void (async () => {
      setIsLoading(true);
      const resp = await getElkChatbotReport(getChatbotReportDetailQuery(item.name), null);
      setChatbotReportDetail(resp.data);
      setIsLoading(false);
    })();
  }, [item]);

  const renderMenu = () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };

  const formatLegend = (name: string, opts: any): string => {
    return `${opts.w.globals.series[opts.seriesIndex]} ${name}`;
  };

  return (
    <Drawer open={open} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {open && !isLoading && (
        <Box className={classes.drawerContent}>
          <Box className={classes.headerDetail}>
            <Stack direction="column">
              <Text className={classes.headerTitle} weight={fontWeight.BOLD}>
                {item.name}
              </Text>
              <Text className={classes.headerSubtitle}>{item.total} assignments</Text>
            </Stack>
            <IconButton icon="close" onClick={onClose} className={classes.closeIconBtn} />
          </Box>
          <Box className={classes.content}>
            <Box className={classes.donut}>
              {chartInfo && (
                <BreakdownChart
                  className={classes.breakdownChart}
                  title={chartInfo.title}
                  subtitle={chartInfo.subtitle}
                  chartData={chartInfo.chartData}
                  chartHeight={200}
                  chartWidth={640}
                  donutOpts={{
                    total: chartInfo.donutOptTotal,
                    title: chartInfo.donutOptTitle,
                    size: '80%',
                    legend: { formatter: formatLegend },
                  }}
                />
              )}
            </Box>

            <Box className={classes.chartWrap}>
              <AssignmentsChart
                title="Last 30 days"
                className={classes.chart}
                chart={{
                  series: [
                    { name: 'Current 30 days', color: colors.primary500, data: last30DaysData },
                    { name: 'Past 30 days', color: colors.neutral600, data: past30DaysData },
                  ],
                  tooltipConfig: {
                    isShowTotal: false,
                    title: 'Total',
                  },
                }}
              />
            </Box>
            <Box className={classes.tableWrap}>
              <Text className={classes.recentText}>Recent completions</Text>
              <Input
                variant={inputType.SEARCH}
                placeholder="Search"
                value={search}
                className={classes.inputSearch}
                onChange={setSearch}
                size={inputSize.M}
                fullWidth
              />
              <DctTable
                className={classes.tableBordered}
                isShowAll
                searchText={search}
                tableColumns={tableColumns}
                data={recentCompletionsData}
                renderMenuAction={renderMenu}
              />
            </Box>
          </Box>
          <Box className={classes.drawerFooter}>
            <Button variant={btnType.PRIMARY} onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      )}
      {isLoading && (
        <div className={classes.loader}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          Loading...
        </div>
      )}
    </Drawer>
  );
};

export { ChatbotDetail };
