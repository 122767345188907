import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  h1: {
    fontSize: 40,
    lineHeight: '48px',
  },
  h2: { fontSize: 36, lineHeight: '44px' },
  h3: {
    fontSize: 32,
    lineHeight: '40px',
  },
  h4: {
    fontSize: 28,
    lineHeight: '36px',
  },
  h5: {
    fontSize: 24,
    lineHeight: '32px',
  },
  h6: {
    fontSize: 22,
    lineHeight: '28px',
  },
  sub: {
    fontSize: 18,
    lineHeight: '24px',
  },
});
