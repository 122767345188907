import { createAction } from 'redux-actions';

export const FETCH_NOTIFICATIONS = 'notifications/fetch';
export const FETCH_NOTIFICATIONS_SUCCESSFUL = 'notifications/fetchSuccessful';
export const FETCH_NOTIFICATIONS_FAILED = 'notifications/fetchFailed';

export const FETCH_ADMIN_NOTIFICATIONS = 'AdminNotifications/fetch';
export const FETCH_ADMIN_NOTIFICATIONS_SUCCESSFUL = 'AdminNotifications/fetchSuccessful';
export const FETCH_ADMIN_NOTIFICATIONS_FAILED = 'AdminNotifications/fetchFailed';

export const REMOVE_ADMIN_NOTIFICATION = 'AdminNotifications/remove';
export const REMOVE_ADMIN_NOTIFICATION_SUCCESSFUL = 'AdminNotifications/removeSuccessful';
export const REMOVE_ADMIN_NOTIFICATION_FAILED = 'AdminNotifications/removeFailed';

export const notificationActionCreators = {
  fetchNotifications: createAction(FETCH_NOTIFICATIONS),
  fetchAdminNotifications: createAction(FETCH_ADMIN_NOTIFICATIONS),
  removeAdminNotification: createAction(REMOVE_ADMIN_NOTIFICATION),
};
