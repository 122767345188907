import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import dayjs from 'dayjs';

import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';
import { IDemographicDetails } from 'redux/modules/profile/types';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';

import { useStyles } from './SessionSignOffV2.styles';

type Props = {
  memberId: string;
  openDrawer: boolean;
  onClose: () => void;
  onUpdated: () => void;
};

const statusList = [
  { label: 'New Member', value: 'New member' },
  { label: 'Referral', value: 'Referral' },
  { label: 'Active', value: 'Active' },
  { label: 'Successful Discharge', value: 'Successful Discharge' },
  { label: 'Self Discharge', value: 'Self Discharge' },
  { label: 'Discharged to HLOC', value: 'Discharged to HLOC' },
  { label: 'Lost to Care', value: 'Lost to Care' },
  { label: 'Not Admitted to Care', value: 'Not admitted to care' },
  { label: 'DIY', value: 'DIY' },
  { label: 'Ghost', value: 'Ghost' },
  { label: 'Referral Active', value: 'Referral Active' },
];

const UpdateLevelOfEngagement = ({ openDrawer, onClose, memberId, onUpdated }: Props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { demographicDetails = {} as IDemographicDetails } = useSelector(getProfile);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    genderIdentity: '',
    genderPronoun: '',
    gender: '',
    levelOfEngagementStatus: '',
    levelOfEngagementSubStatus: '',
    primaryInterests: [],
    isActiveChatGroup: false,
    referralSource: '',
  });

  useEffect(() => {
    dispatch(profileActionCreators.fetchDemographicMetadata());
  }, []);

  useEffect(() => {
    setFormData({
      firstName: demographicDetails.firstName || '',
      lastName: demographicDetails.lastName || '',
      dateOfBirth: dayjs(demographicDetails.dateOfBirth).format(GLOBAL_DATE_FORMAT) || '',
      genderIdentity: demographicDetails.genderIdentity || 'Male',
      gender: demographicDetails.sex || 'Male',
      genderPronoun: demographicDetails.genderPronoun || '',
      levelOfEngagementStatus: demographicDetails?.levelOfEngagementStatus || '',
      levelOfEngagementSubStatus: '',
      isActiveChatGroup: demographicDetails.isActiveChat || false,
      primaryInterests: demographicDetails.onboardingGoals?.map(goal => ({ title: goal, value: goal })) || [],
      referralSource: demographicDetails.referralSource || '',
    });
  }, [demographicDetails]);

  const onChangeLevelOfEngagementStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(old => ({
      ...old,
      levelOfEngagementStatus: e.target.value,
    }));
  };

  const onSubmit = () => {
    const dob = new Date(formData.dateOfBirth);
    const currentDate = new Date();
    dispatch(
      profileActionCreators.updateDemographicDetails({
        demographicDetails: {
          ...formData,
          dateOfBirth:
            formData.dateOfBirth !== 'Invalid Date' && dob <= currentDate
              ? dayjs(formData.dateOfBirth).format('YYYY-MM-DD')
              : '',
          gender: formData.genderIdentity,
          primaryInterests: formData.primaryInterests.map(({ value }) => value),
        },
        memberId,
      }),
    );
    onClose();
    onUpdated();
  };

  return (
    <>
      <Drawer
        variant={drawerType.FORM}
        position={drawerPosition.RIGHT}
        open={openDrawer}
        onClose={onClose}
        title="Update Level of Engagement"
        submitBtnTitle="Update"
        onSubmit={onSubmit}
      >
        <Box className={classes.inputsContainer}>
          <div className={classes.inputWrapper}>
            <Text className="label" level={textLevel.S} weight={fontWeight.BOLD}>
              <span>Level of Engagement</span>
            </Text>
            <Select
              variant={selectType.SECONDARY}
              options={statusList}
              onChange={onChangeLevelOfEngagementStatus}
              value={formData.levelOfEngagementStatus}
            />
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default UpdateLevelOfEngagement;
