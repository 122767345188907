import { colors } from '@confidant-health/lib/colors';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Box } from '@mui/material';
import { Avatar, AvatarType, AvatarSize } from 'components/avatar';

type Props = {
  fullName: string;
  userRole: string;
  src?: string;
  hideNotes?: boolean;
};

const ProfileInfo = ({ fullName, userRole, src = '', hideNotes = true }: Props) => {
  const isInitial = fullName?.split(' ')?.length > 1;
  return (
    <Box sx={{ display: 'flex', gap: 1.5 }}>
      {isInitial ? (
        <Avatar type={AvatarType.IMAGE} src={src} name={`${fullName} `} size={AvatarSize.XSMALL} />
      ) : (
        <Avatar type={AvatarType.IMAGE} src={src} name={`${fullName} `} size={AvatarSize.XSMALL}>
          {fullName?.split('')?.[0]}
        </Avatar>
      )}
      {hideNotes && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            {fullName}
          </Text>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: colors.indigo50,
              borderRadius: 6,
              paddingX: 1,
              width: 'fit-content',
              paddingY: 0.5,
              textWrap: 'nowrap',
            }}
          >
            <Text level={textLevel.XS} weight={fontWeight.SEMI_BOLD} color={colors.indigo600}>
              {userRole?.charAt(0).toUpperCase() + userRole?.slice(1)}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfileInfo;
