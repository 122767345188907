import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import cx from 'clsx';
import history from 'utils/history';
// components
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Collapsable } from '@confidant-health/lib/ui/atoms/collapsable';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { ProgressBar } from '@confidant-health/lib/ui/atoms/ProgressBar';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Link, linkType } from '@confidant-health/lib/ui/atoms/link';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IEvaluationProgress } from 'redux/modules/appointment/types';
import EvaluationDetails from 'pages/provider/priorities/appointments/evaluation-detail/EvaluationDetails';
import {
  EVALUATION_STATUS,
  EVALUATION_STATUS_TYPES,
} from '../../../sessions/evaluations-v2/Evaluations.constants';
import dayjs from '../../../../../utils/dayjs';
// styles
import { useStyles } from './EvaluationCard.styles';

type props = {
  evaluation: IEvaluationProgress;
  provider: any;
  expandable?: boolean;
  appointmentIdProp?: string;
};

const EvaluationCard: FC<props> = ({ evaluation, provider, expandable = false, appointmentIdProp }) => {
  const classes = useStyles({});
  const { memberId, appointmentId } = useParams();
  const {
    name: title,
    status,
    description,
    id: evaluationId,
    progress: evalProgress,
    completedAt,
    assignedAt,
    evaluationStats,
  } = evaluation;
  const location = useLocation();
  const userRole = location?.pathname.split('/')[1];
  const dateToShow = status === 'COMPLETED' ? completedAt : assignedAt;

  const { totalCb, totalExercises, totalRequiredCb, forProviderCount, unanswered, riskFactorCount } =
    evaluationStats;

  const [expandEvaluation, setExpandEvaluation] = useState(false);

  const handleViewEvaluation = e => {
    if (expandable) {
      e.preventDefault();
      setExpandEvaluation(!expandEvaluation);
    } else {
      e.preventDefault();
      history.push(
        `/${userRole}/members/${memberId}/appointments/${
          appointmentIdProp ?? appointmentId
        }/evaluation?evaluationId=${evaluationId}`,
      );
    }
  };

  const evaluationStatusStyle = {
    REJECTED: badgeStyle.CRITICAL,
    COMPLETED: badgeStyle.ACTIVE,
    NOT_STARTED: badgeStyle.INACTIVE,
    IN_PROGRESS: badgeStyle.PRIMARY,
    INCOMPLETE: badgeStyle.PRIMARY,
  };

  // const dynamicHref = expandable
  //   ? '#'
  //   : `/${userRole}/members/${memberId}/appointments/${
  //       appointmentIdProp ?? appointmentId
  //     }/evaluation?evaluationId=${evaluationId}`;

  // console.log('dynamicHref dynamicHref', dynamicHref);

  return (
    <Box id={evaluationId} className={classes.card}>
      <Box className={classes.card}>
        <Box className={classes.cardContent}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Box display="flex" gap={1.5} alignItems="center">
              <Badge
                variant={badgeType.OUTLINED}
                style={evaluationStatusStyle[status]}
                className={classes.badge}
              >
                {EVALUATION_STATUS[status]}
              </Badge>
              {status === EVALUATION_STATUS_TYPES.IN_PROGRESS ? (
                <Box className={classes.dflex}>
                  <ProgressBar value={evalProgress} className={classes.progressBar} />
                  <Text weight={fontWeight.MEDIUM} className={classes.progress}>
                    {evalProgress}%
                  </Text>
                </Box>
              ) : (
                <Box className={classes.text14} color={colors.neutral500}>
                  {dayjs(dateToShow).format('MM/DD/YYYY')}
                </Box>
              )}
            </Box>
            {status !== 'REJECTED' && (
              <Link
                variant={linkType.CALLBACK}
                className={classes.cardLink}
                onClick={handleViewEvaluation}
                /* eslint-disable-next-line max-len */
                // href="#"
              >
                View evaluation
                <Icons glyph="arrow-right" color={colors.primary600} />
              </Link>
            )}
          </Box>
        </Box>
        <Box className={classes.cardHead}>
          <Heading className={classes.cardTitle} weight={fontWeight.SEMI_BOLD} level={headingLevel.M}>
            {title}
          </Heading>
          <Text weight={fontWeight.MEDIUM} level={textLevel.S} color={colors.neutral600}>
            {description}
          </Text>
        </Box>
        <Box className={classes.cardFooter}>
          {provider && (
            <ProfileInfo
              type="provider"
              photo={provider?.profilePicture}
              role={provider?.role}
              fullName={provider?.name}
            />
          )}
          {status !== EVALUATION_STATUS_TYPES.REJECTED && (
            <Box className={cx(classes.footerItems, !provider && classes.flexStartItems)}>
              {totalCb > 0 && (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Box className={classes.text14}>All</Box>
                  <Box>
                    <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                      {totalCb}
                    </Badge>
                  </Box>
                </Box>
              )}
              {riskFactorCount > 0 && status === 'COMPLETED' && (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Box color={colors.destructive600} className={classes.text14}>
                    Risk factors
                  </Box>
                  <Box>
                    <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.CRITICAL}>
                      {riskFactorCount}
                    </Badge>
                  </Box>
                </Box>
              )}
              {totalRequiredCb > 0 && (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Box className={classes.text14}>Required</Box>
                  <Box>
                    <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                      {totalRequiredCb}
                    </Badge>
                  </Box>
                </Box>
              )}
              {unanswered > 0 && (status === 'COMPLETED' || status === 'IN_PROGRESS') && (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Box className={classes.text14}>Unanswered</Box>
                  <Box>
                    <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                      {unanswered}
                    </Badge>
                  </Box>
                </Box>
              )}
              {forProviderCount > 0 && (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Box className={classes.text14}>For provider</Box>
                  <Box>
                    <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                      {forProviderCount}
                    </Badge>
                  </Box>
                </Box>
              )}
              {totalExercises > 0 && (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Box className={classes.text14}>Exercises</Box>
                  <Box>
                    <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                      {totalExercises}
                    </Badge>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {expandable && expandEvaluation && (
          <Collapsable iconHidden id={evaluationId} open={expandEvaluation}>
            <EvaluationDetails
              hideHeader
              fullScreen
              evaluation={evaluation}
              appointmentId={appointmentId}
              memberId={memberId}
              className={classes.evaluationCardDetails}
            />
          </Collapsable>
        )}
      </Box>
    </Box>
  );
};

export { EvaluationCard };
