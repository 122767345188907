import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  table: {
    backgroundColor: colors.white,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  badge: {
    width: 'fit-content',
    borderRadius: 20,
    fontSize: 14,
    lineHeight: '20px',
    border: 'none',
    color: colors.white,
    '&.CRITICAL': {
      backgroundColor: colors.rose500,
    },
    '&.NORMAL': {
      backgroundColor: colors.green200,
    },
    '&.HIGH': {
      backgroundColor: colors.rose50,
    },
    '&.LOW': {
      backgroundColor: colors.cyan500,
    },
    '&.NONE': {
      backgroundColor: colors.destructive500,
    },
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
});
