import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
    fontSize: 32,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    color: colors.neutral600,
  },
  searchWrap: {
    width: '100%',
    '& > div': {
      padding: 0,
      '& > div': {
        padding: 0,
        '& > div': {
          backgroundColor: colors.white,
        },
      },
    },
  },
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    borderRadius: 8,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  tabs: {
    '& button': {
      color: colors.neutral600,
      fontWeight: 700,
      textTransform: 'none',
      '&.Mui-selected': {
        color: `${colors.neutral900} !important`,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.neutral900,
    },
  },
  drawerTitle: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginBottom: 40,
    display: 'block',
  },
});
