import { FC, useCallback } from 'react';
import ApexChart from 'react-apexcharts';
import { IDomainElement } from 'redux/modules/profile/types';

import { getChartConfig } from './SymptomChart.utils';
import { useStyles } from './SymptomChart.styles';

type Props = {
  name: string;
  items: IDomainElement[];
};

const SymptomChart: FC<Props> = ({ name, items }) => {
  const classes = useStyles();

  const chartConfig = useCallback((opt: Props) => {
    return getChartConfig(opt);
  }, []);

  return (
    <div className={classes.chart}>
      <ApexChart {...chartConfig({ name, items })} type="line" height={330} />
    </div>
  );
};

export { SymptomChart };
