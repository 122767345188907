// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
  },
  whiteBg: {
    backgroundColor: colors.white,
  },
  header: {
    backgroundColor: colors.neutral25,
    borderBottom: `1px solid ${colors.neutral100}`,
    width: '100%',
    height: 80,
    gap: 24,
    display: 'flex',
    alignItems: 'center',
    padding: '20px 16px',
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  backBtn: {
    width: 40,
    minWidth: 40,
    height: 40,
    border: `1px solid ${colors.primary300}`,
    borderRadius: 6,
    '& svg': {
      height: 13,
    },
  },
  body: {
    height: 'calc(100vh - 240px)',
    overflowY: 'auto',
    position: 'relative',
  },
  bodyInPerson: {
    height: 'calc(100vh - 300px)',
    overflowY: 'auto',
    position: 'relative',
  },
  evaluations: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: 24,
  },
  evaluationsNoXPadding: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  searchInputWrap: {
    padding: 16,
    width: '100%',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  searchInputWrapNoPadding: {
    backgroundColor: 'white',
    width: '100%',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  checkbox: {
    '& .MuiTypography-body1': {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  searchInput: {
    height: 48,
    width: '100%',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 80,
    padding: '16px 6px 16px 24px',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  addSelectedBtn: {
    backgroundColor: colors.primary500,
    color: colors.white,
  },
});
