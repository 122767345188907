import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { useStyles } from './index.styles';

type Props = {
  children: ReactNode;
  [prop: string]: any;
};

const BoxFrame = ({ children, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  );
};

export default BoxFrame;
