import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ArrayHelpers, FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import { Box } from '@mui/material';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { reportServiceActionCreators } from 'redux/modules/report-service';
import { ICPTCode } from 'redux/modules/state/types';
import { IDomainElement } from 'redux/modules/profile/types';
import { IAppointmentCptCode } from 'redux/modules/report-service/types';
import { AddProcedureCodesSchema } from './AddProcedureCodes.schema';
import { useStyles } from './AddProcedureCodes.styles';

interface Props {
  appointment: IAppointment;
  appointmentCptCode: IAppointmentCptCode;
  domainElements: IDomainElement[];
  diagnosisList?: any[];
  cpts: ICPTCode[];
  onClose: () => void;
  isLoadingCPT: boolean;
}

const AddProcedureCodes: FC<Props> = ({
  appointment,
  appointmentCptCode,
  domainElements,
  diagnosisList,
  cpts,
  onClose,
  isLoadingCPT,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [, setIcd10Options] = useState<any[]>([]);
  const hasCptCodes = Object.keys(appointmentCptCode).length > 0;
  const cptCodeOptions = useMemo(
    () =>
      cpts?.map((cpt: ICPTCode) => ({ label: `${cpt?.code} - ${cpt?.description}`, value: cpt?.code })) || [],
    [cpts],
  );

  const diagnosisOptions = useMemo(
    () =>
      domainElements?.map((element: IDomainElement) => ({
        label: element?.name,
        value: element?.name,
      })),
    [domainElements],
  );
  useEffect(() => {
    if (appointmentCptCode?.cptList) {
      const icd10OptionsList = diagnosisList
        ?.find(
          (diagnose: any) =>
            diagnose?.Id === appointmentCptCode?.cptList[0].diagnosis ||
            diagnose?.name === appointmentCptCode?.cptList[0].diagnosis,
        )
        ?.metaData?.metaDataValues?.icd10Codes?.map((code: any) => {
          return { label: `${code}`, value: code };
        });
      // icd10Code = icd10OptionsList?.[0]?.value;
      setIcd10Options(icd10OptionsList);
    }
  }, []);
  // const icd10Options = useMemo(
  //   () =>
  //     icd10?.map((code: IICD10Code) => ({ label: `${code.name} - ${code.description}`, value: code.code })),
  //   [icd10],
  // );

  const defaultValues = {
    cptList: appointmentCptCode?.cptList || [{ cptCode: '', diagnosis: '', icd10Code: '', notes: '' }],
  };

  const formik = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: AddProcedureCodesSchema,
    onSubmit: (payload: typeof defaultValues) => {
      if (hasCptCodes) {
        // update
        dispatch(
          reportServiceActionCreators.updateAppointmentCptCode({
            bodyRequest: { appointmentId: appointment.appointmentId, ...payload },
            pathParams: { id: appointmentCptCode._id },
            callback: () => onClose(),
          }),
        );
      } else {
        // add
        dispatch(
          reportServiceActionCreators.addAppointmentCptCode({
            bodyRequest: { appointmentId: appointment.appointmentId, ...payload },
            callback: () => {
              dispatch(reportServiceActionCreators.fetchAppointmentCptCodeById(appointment.appointmentId));
              onClose();
            },
          }),
        );
      }
    },
  });
  const { errors, values, handleChange, handleSubmit, setFieldValue, touched, ...rest } = formik;

  const getDescription = (code: string) => {
    const selectedCode = cpts.find((cpt: ICPTCode) => cpt.code === code);
    return selectedCode?.description || '';
  };

  useEffect(() => {
    rest.setErrors({});
  }, []);

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Box>
          <Text className={classes.headerTitle}>Procedure codes</Text>
          <Text className={classes.subtitle}>
            To make your life easier, we add the typical CPT codes for this appointment type.
          </Text>
        </Box>
        <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
      </Box>
      <FormikProvider value={formik}>
        <form
          className={location.pathname.includes('session-signoff') ? classes.form2 : classes.form}
          onSubmit={handleSubmit}
        >
          {!isLoadingCPT ? (
            <Box className={classes.formContent}>
              <FieldArray
                name="cptList"
                render={(arrayHelpers: ArrayHelpers) => (
                  <>
                    {values.cptList?.map((_, index: number) => (
                      <Box
                        key={`cpt-list-${index}`}
                        display="flex"
                        flexDirection="column"
                        gap={3}
                        marginBottom={values.cptList?.length > 1 ? 4 : 0}
                      >
                        <Box className={classes.section}>
                          {renderLabel(`${index + 1} - Procedure code`)}

                          <Box display="flex" gap={2} width="100%">
                            <Input
                              fullWidth
                              name={`cptList.${index}.cptCode`}
                              placeholder="Select item"
                              size={inputSize.M}
                              variant={inputType.AUTOCOMPLETE}
                              value={cptCodeOptions.find(option => option.value === _?.cptCode) || ''}
                              options={cptCodeOptions}
                              // emptyText="Select item"
                              // displayEmpty
                              getOptionLabel={option => option?.label || ''}
                              onChange={(__: SyntheticEvent, newValue) => {
                                void setFieldValue(`cptList.${index}.cptCode`, newValue.value);
                              }}
                            />
                            {/* )} */}
                            {values.cptList?.length > 1 && (
                              <IconButton
                                icon="delete-outlined-2"
                                className={classes.removeIconBtn}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}
                          </Box>
                          <TextError
                            errorMsg={
                              touched.cptList?.length > index &&
                              touched.cptList[index]?.cptCode &&
                              errors.cptList?.length > index
                                ? getIn(errors.cptList[index], 'cptCode')
                                : null
                            }
                          />
                          <Text className={classes.description}>
                            {getDescription(values.cptList[index]?.cptCode)}
                          </Text>
                        </Box>
                        {/* <Box className={clsx(classes.section, classes.noGap)}>
                      <FormControlLabel
                        control={<Checkbox />}
                        // checked={values.cptList[index]?.useDefaultTime}
                        // name={`cptList.${index}.useDefaultTime`}
                        onChange={() => {
                          // void setFieldValue(
                          //   `cptList.${index}.useDefaultTime`,
                          //   !values.cptList[index]?.useDefaultTime,
                          // );
                          console.log('onclick checkbox');
                        }}
                        label="Use default time"
                        className={classes.checkbox}
                      />
                      <Text className={classes.checkboxSubtitle}>45 minutes</Text>
                      <TextError
                        errorMsg={
                          touched.cptList?.length > index &&
                          touched.cptList[index]?.useDefaultTime &&
                          errors.cptList?.length > index
                            ? getIn(errors.cptList[index], 'useDefaultTime')
                            : null
                        }
                      />
                    </Box> */}
                        <Box className={classes.section}>
                          {renderLabel('Related Diagnosis')}
                          <Select
                            sx={{
                              '& .MuiInputBase-input': {
                                marginLeft: '0px !important',
                              },
                            }}
                            name={`cptList.${index}.diagnosis`}
                            value={values.cptList[index].diagnosis}
                            variant={selectType.SECONDARY}
                            options={diagnosisOptions}
                            emptyText="Select item"
                            displayEmpty
                            truncate
                            truncateAt={64}
                            onChange={e => {
                              const diagnoseId = e?.target?.value;
                              handleChange(e);
                              if (diagnoseId) {
                                console.log('Diagnoses Id Selected');
                              }
                            }}
                          />
                          <TextError
                            errorMsg={
                              touched.cptList?.length > index &&
                              touched.cptList[index]?.diagnosis &&
                              errors.cptList?.length > index
                                ? getIn(errors.cptList[index], 'diagnosis')
                                : null
                            }
                          />
                        </Box>
                        <Box className={classes.section}>
                          {renderLabel('Specific ICD-10 Code')}
                          <Select
                            name={`cptList.${index}.icd10Code`}
                            value={values.cptList[index].icd10Code}
                            variant={selectType.SECONDARY}
                            options={
                              diagnosisList
                                ?.find(
                                  (diagnose: any) =>
                                    diagnose?.Id === values.cptList[index].diagnosis ||
                                    diagnose?.name === values.cptList[index].diagnosis,
                                )
                                ?.metaData?.metaDataValues?.icd10Codes?.map((code: any) => {
                                  return { label: `${code}`, value: code };
                                })
                                ?.sort((a, b) => a.label.localeCompare(b.label)) || []
                            }
                            emptyText="Select item"
                            displayEmpty
                            onChange={handleChange}
                          />
                          {/* <Input
                        fullWidth
                        name={`cptList.${index}.icd10Code`}
                        placeholder="Select item"
                        size={inputSize.M}
                        variant={inputType.AUTOCOMPLETE}
                        value={
                          icd10Options.find(option => option.value === values.cptList[index]?.icd10Code) ||
                          icd10Options[0]
                        }
                        value={values.cptList[index]?.icd10Code || (icd10Options?.[0]?.value as string) || ''}
                        options={icd10Options}
                        getOptionLabel={option => option?.label || ''}
                        onChange={(__: SyntheticEvent, newValue) => {
                          void setFieldValue(`cptList.${index}.icd10Code`, newValue.value);
                        }}
                      /> */}
                          <TextError
                            errorMsg={
                              touched.cptList?.length > index &&
                              touched.cptList[index]?.icd10Code &&
                              errors.cptList?.length > index
                                ? getIn(errors.cptList[index], 'icd10Code')
                                : null
                            }
                          />
                        </Box>
                      </Box>
                    ))}
                    <IconButton
                      icon="plus"
                      onClick={() =>
                        arrayHelpers.push({
                          cptCode: '',
                          diagnosis: '',
                          icd10Code: '',
                          notes: '',
                        })
                      }
                      className={classes.addBtn}
                    >
                      Add another
                    </IconButton>
                  </>
                )}
              />
            </Box>
          ) : (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          )}
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={isLoadingCPT}>
              {hasCptCodes ? 'Update' : 'Add'} codes
            </Button>
          </Box>
        </form>
      </FormikProvider>
    </Box>
  );
};

export { AddProcedureCodes };
