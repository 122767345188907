import { createAction } from 'redux-actions';

export const FETCH_APPOINTMENTS = 'appointments/fetch';
export const FETCH_APPOINTMENTS_PUBLIC = 'appointments/public/fetch';
export const FETCH_APPOINTMENTS_SUCCESSFUL = 'appointments/fetchSuccessful';
export const FETCH_APPOINTMENTS_FAILED = 'appointments/fetchFailed';

export const FETCH_APPOINTMENT_BY_ID = 'appointments/fetchAppointmentById';
export const FETCH_APPOINTMENT_BY_ID_SUCCESSFUL = 'appointments/fetchAppointmentByIdSuccessful';

export const CANCEL_APPOINTMENT = 'appointments/cancel';
export const CANCEL_APPOINTMENT_SUCCESSFUL = 'appointments/cancelSuccessful';
export const CANCEL_APPOINTMENT_FAILED = 'appointments/cancelFailed';

export const ACCEPT_APPOINTMENT = 'appointments/accept';
export const ACCEPT_APPOINTMENT_SUCCESSFUL = 'appointments/acceptSuccessful';
export const ACCEPT_APPOINTMENT_FAILED = 'appointments/acceptFailed';

export const UPDATE_APPOINTMENT = 'appointments/update';
export const UPDATE_APPOINTMENT_SUCCESSFUL = 'appointments/updateSuccessful';
export const UPDATE_APPOINTMENT_FAILED = 'appointments/updateFailed';

export const CREATE_APPOINTMENT = 'appointments/create';
export const CREATE_APPOINTMENT_SUCCESSFUL = 'appointments/createSuccessful';
export const CREATE_APPOINTMENT_FAILED = 'appointments/createFailed';

export const FETCH_PROVIDER_SERVICES = 'appointments/providerServices';
export const FETCH_PROVIDER_SERVICES_SUCCESSFUL = 'appointments/providerServicesSuccessful';
export const FETCH_PROVIDER_SERVICES_FAILED = 'appointments/providerServicesFailed';
export const RESET_ERROR_MSG = 'appointments/resetErrorMsg';

export const FETCH_MASTER_SCHEDULE_REQUEST = 'appointments/masterSchedule';
export const FETCH_MASTER_SCHEDULE_REQUEST_SUCCESSFUL = 'appointments/masterScheduleSuccessful';
export const FETCH_MASTER_SCHEDULE_REQUEST_FAILED = 'appointments/masterScheduleFailed';

export const FETCH_PROVIDER_ROLES = 'appointments/providerRoles';
export const FETCH_PROVIDER_ROLES_SUCCESSFUL = 'appointments/providerRolesSuccessful';
export const FETCH_PROVIDER_ROLES_FAILED = 'appointments/providerRolesFailed';

export const FETCH_APPOINTMENT_DETAILS = 'appointments/fetchDetails';
export const FETCH_APPOINTMENT_DETAILS_SUCCESSFUL = 'appointments/fetchDetailsSuccessful';
export const FETCH_APPOINTMENT_DETAILS_FAILED = 'appointments/fetchDetailsFailed';

export const FETCH_ALL_APPOINTMENT_COUNTS = 'appointments/fetchCounts';
export const FETCH_ALL_APPOINTMENT_COUNTS_SUCCESSFUL = 'appointments/fetchCountsSuccessful';
export const FETCH_ALL_APPOINTMENT_COUNTS_FAILED = 'appointments/fetchCountsFailed';

export const FETCH_ALL_PROVIDER_SERVICES = 'appointments/fetchAllProviderServices';
export const FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL = 'appointments/fetchAllProviderServicesSuccessful';
export const FETCH_ALL_PROVIDER_SERVICES_FAILED = 'appointments/fetchAllProviderServicesFailure';

export const FETCH_MASTER_SCHEDULE = 'appointments/fetchMasterSchedule2';
export const FETCH_MASTER_SCHEDULE_SUCCESSFUL = 'appointments/fetchMasterScheduleSuccessful2';

export const appointmentActionCreators = {
  createAppointment: createAction(CREATE_APPOINTMENT),
  fetchAppointments: createAction(FETCH_APPOINTMENTS),
  fetchAppointmentsPublic: createAction(FETCH_APPOINTMENTS_PUBLIC),
  cancelAppointment: createAction(CANCEL_APPOINTMENT),
  acceptAppointment: createAction(ACCEPT_APPOINTMENT),
  updateAppointment: createAction(UPDATE_APPOINTMENT),
  fetchAllProviderServices: createAction(FETCH_ALL_PROVIDER_SERVICES),
  fetchProviderServices: createAction(FETCH_PROVIDER_SERVICES),
  fetchMasterSchedule2: createAction(FETCH_MASTER_SCHEDULE),
  resetError: createAction(RESET_ERROR_MSG),
  fetchMasterSchedule: createAction(FETCH_MASTER_SCHEDULE_REQUEST),
  fetchProviderRoles: createAction(FETCH_PROVIDER_ROLES),
  fetchAppointmentDetails: createAction(FETCH_APPOINTMENT_DETAILS),
  fetchAllAppointmentCounts: createAction(FETCH_ALL_APPOINTMENT_COUNTS),
  fetchAppointmentById: createAction(FETCH_APPOINTMENT_BY_ID),
};
