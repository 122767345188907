/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { DomainPriorities } from 'redux/modules/profile/types';

import { SubstanceUseBottomCard } from './SubstanceUseBottomCard';
import { ISubstanceUseCardProps } from './SubstanceUseCard.types';
// styles
import { useStyles } from './SubstanceUseCard.styles';

const SubstanceUseCard: React.FC<ISubstanceUseCardProps> = ({
  item,
  onClick,
  showNotes = true,
  isOnlyView,
  lookupData,
}) => {
  const classes = useStyles();

  const onClickTitle = () => {
    onClick(item, false);
  };

  const onClickEdit = () => {
    onClick(item, true);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.rowCenter} sx={{ p: 3, minHeight: 96 }}>
        <Box className={classes.topLeft}>
          <Box className={classes.topLeftHead}>
            <Text className={classes.title} onClick={onClickTitle}>
              {item.name}
            </Text>
            <Badge
              variant={badgeType.FILLED}
              className={clsx(classes.badge, {
                [classes.badgeMisreported]: item.priority.name === DomainPriorities.MISREPORTED,
              })}
              style={badgeStyle[item.priority.name] || badgeStyle.UNRELATED}
            >
              {item.priority.name}
            </Badge>
          </Box>
          <Box>
            <Text className={classes.title2}>{item.description}</Text>
          </Box>
        </Box>
        <Box className={classes.topRight}>
          {/* <IconButton
            icon="clock-rotate"
            variant={iconBtnType.TEXT}
            className={classes.btn}
            onClick={onClickTitle}
          >
            See All Reports ({(item.history ?? []).length + 1})
          </IconButton> */}
          {!isOnlyView && (
            <IconButton variant={iconBtnType.SECONDARY} className={classes.btn} onClick={onClickEdit}>
              Update
            </IconButton>
          )}
        </Box>
      </Box>
      <SubstanceUseBottomCard item={item} showNotes={showNotes} lookupData={lookupData} />
    </Box>
  );
};

export { SubstanceUseCard };
