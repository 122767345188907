import React, { useState, useContext, createContext } from 'react';
import { EvaluationHelper } from '../utils/EvaluationHelper';

const EvaluationHeadContext = createContext(null);

const linkedListHelper = new EvaluationHelper();
function useEvaluationHead() {
  const [head, setHead] = useState(null);

  return {
    head,
    createFilteredList: list => {
      linkedListHelper.createLinkedListFrom(list);
      setHead(linkedListHelper.getHead());
    },
    processEvaluationData: data => {
      linkedListHelper.transformEvaluation(data);
      setHead(linkedListHelper.getHead());
    },
    updateValues: payload => {
      linkedListHelper.updateValues(payload, head);
      setHead({ ...linkedListHelper.getHead() });
    },
  };
}

export const ProvideEvaluationHead = ({ children }) => {
  const value = useEvaluationHead();
  return <EvaluationHeadContext.Provider value={value}>{children}</EvaluationHeadContext.Provider>;
};

export default function useEvaluation() {
  return useContext(EvaluationHeadContext);
}
