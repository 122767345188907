import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDownloadExcel } from 'table-to-excel-react';

import { Box, Stack } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { getElkPatientReport } from 'services/reporting/reporting.service';
import { IReport } from 'redux/modules/reporting/types';
import BreakdownChart from 'pages/admin/plan-items/components/plan-item-detail/breakdown-chart';
import RecentAssignmentsTable from 'pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import HeadCard from '../head-card';
import ColumnChart from './column-chart';
import {
  getCityList,
  getStateList,
  getGenderSeries,
  getHousingSeries,
  getMartialStatusSeries,
  getRaceSeries,
  cityColumns,
  stateColumns,
  getProfileReportQuery,
  DEMOGRAPHICS_TABLES,
} from './Demographics.constants';
import { IDemographicsReport } from './Demographics.types';
import { useStyles } from './Demographics.styles';
import { ViewAllDrawer } from '../data-collection-templates/ViewAllDrawer';
import DemographicsDetail from './demographics-detail';

type Props = {
  report: IReport;
  showNewReport: boolean;
  componentRef: any;
};

const Demographics: FC<Props> = ({ report, showNewReport, componentRef }) => {
  const [demographicsReport, setDemographicsReport] = useState<IDemographicsReport>(null);

  const { onDownload } = useDownloadExcel({
    fileName: 'demographics_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const [selectedDemographicElement, setSelectedDemographicElement] = useState(null);
  const [showViewAllType, setShowViewAllType] = useState('');
  const genderSeries = useMemo(() => getGenderSeries(demographicsReport), [demographicsReport]);
  const raceSeries = useMemo(() => getRaceSeries(demographicsReport), [demographicsReport]);
  const martialStatusSeries = useMemo(() => getMartialStatusSeries(demographicsReport), [demographicsReport]);
  const housingSeries = useMemo(() => getHousingSeries(demographicsReport), [demographicsReport]);
  const ageCatgories = useMemo(
    () =>
      demographicsReport ? Object.keys(demographicsReport.aggregations.age_range.buckets).reverse() : [],
    [demographicsReport],
  );
  const ageValues = useMemo(
    () =>
      demographicsReport
        ? Object.values(demographicsReport.aggregations.age_range.buckets)
            .reverse()
            .map(bucket => bucket.doc_count)
        : [],
    [demographicsReport],
  );
  const educationCategories = useMemo(
    () =>
      demographicsReport
        ? demographicsReport.aggregations.demographics.education.by_education.buckets.map(
            bucket => bucket.key,
          )
        : [],
    [demographicsReport],
  );
  const educationValues = useMemo(
    () =>
      demographicsReport
        ? demographicsReport.aggregations.demographics.education.by_education.buckets.map(
            bucket => bucket.doc_count,
          )
        : [],
    [demographicsReport],
  );
  const cityListData = useMemo(() => getCityList(demographicsReport), [demographicsReport]);
  const stateListData = useMemo(() => getStateList(demographicsReport), [demographicsReport]);
  const demoGraphicsSection = useMemo(() => report?.data?.demoGraphicsSection, [report]);
  const classes = useStyles();

  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await getElkPatientReport(getProfileReportQuery(report), null);
        setDemographicsReport(resp.data);
      })();
  }, [showNewReport]);

  const formatLegend = (name: string, opts: any): string => {
    return `${opts.w.globals.series[opts.seriesIndex]} ${name}`;
  };
  // const onRowClick = item => {
  //   setSelectedItem(item);
  // };

  const onRowClick = item => {
    setSelectedDemographicElement({ name: item.city ?? item.state, members: item.members, type: item.type });
  };
  const renderTable = (title, columns, data, onClickViewAll, renderMenuAction, className = '', type) => (
    <Box className={clsx(classes.tableWrap, className)} flex={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
          {title}
        </Text>
        <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
          View top ({data?.length})
        </IconButton>
      </Stack>
      <RecentAssignmentsTable
        className={classes.tableBordered}
        isShowAll={false}
        tableColumns={columns}
        onRowClick={(item: any) => onRowClick({ ...item, type })}
        data={data}
        renderMenuAction={renderMenuAction}
      />
    </Box>
  );
  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {...[
          {
            title: 'Cities',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: cityColumns,
            data: cityListData as any,
          },
          {
            title: 'States',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: stateColumns,
            data: stateListData as any,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };
  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };
  const onClickAll = (type: string) => () => {
    setShowViewAllType(type);
  };
  const getContentsInViewAll = (): {
    title: string;
    subtitle: string;
    tableColumns: TableGrid.TableColumn[];
    data: unknown[];
    renderMenuAction: (payload: any) => ReactNode;
    onRowClick: (item: any) => void;
  } => {
    if (showViewAllType === DEMOGRAPHICS_TABLES.cities) {
      return {
        title: 'Top cities',
        subtitle: `${cityListData.length} total`,
        tableColumns: cityColumns,
        onRowClick,
        data: cityListData.map(city => {
          return {
            ...city,
            name: city.city,
            total: city.members,
            type: showViewAllType,
          };
        }),
        renderMenuAction: () => <></>,
      };
    }
    if (showViewAllType === DEMOGRAPHICS_TABLES.states) {
      return {
        title: 'Top states',
        subtitle: `${stateListData.length} total`,
        tableColumns: stateColumns,
        onRowClick,
        data: stateListData.map(state => {
          return {
            ...state,
            name: state.state,
            total: state.members,
            type: showViewAllType,
          };
        }),
        renderMenuAction: () => <></>,
      };
    }
    return null;
  };
  const onCloseViewDetail = () => {
    setSelectedDemographicElement(null);
  };
  return (
    <Box className={classes.card}>
      {renderAllTables()}
      <DemographicsDetail
        open={!!selectedDemographicElement}
        onClose={onCloseViewDetail}
        demographicsDetail={selectedDemographicElement}
      />
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <HeadCard title="Demographics" onDownloadClick={onDownload} componentRef={componentRef} />
      <Box className={classes.card}>
        <Box className={classes.donutCharts}>
          {demoGraphicsSection.genderBreakDown?.isChecked && (
            <Box className={classes.donut}>
              <BreakdownChart
                className={classes.breakdownChart}
                title="Gender Identity"
                chartHeight={250}
                donutOpts={{
                  legend: {
                    formatter: formatLegend,
                  },
                }}
                chartData={genderSeries}
              />
            </Box>
          )}
          {demoGraphicsSection.raceBreakDown?.isChecked && (
            <Box className={classes.donut1}>
              <BreakdownChart
                title="Race"
                className={classes.breakdownChart}
                chartData={raceSeries}
                chartHeight={250}
                donutOpts={{
                  legend: { formatter: formatLegend },
                }}
              />
            </Box>
          )}
        </Box>
        {demoGraphicsSection.ageBreakDown?.isChecked && (
          <Box className={classes.chartWrap}>
            <ColumnChart
              title="Age"
              className={classes.chart}
              chart={{
                series: [{ name: 'Age', data: ageValues }],
                color: colors.primary500,
                categories: ageCatgories,
              }}
            />
          </Box>
        )}
        {demoGraphicsSection.educationBreakDown?.isChecked && (
          <Box className={classes.chartWrap}>
            <ColumnChart
              title="Education"
              className={classes.chart}
              chart={{
                series: [{ name: 'Education', data: educationValues }],
                color: colors.green500,
                categories: educationCategories,
              }}
            />
          </Box>
        )}
        <Box className={classes.donutCharts}>
          {demoGraphicsSection.maritalStatus?.isChecked && (
            <Box className={classes.donut}>
              <BreakdownChart
                className={classes.breakdownChart}
                title="Marital status"
                chartHeight={250}
                donutOpts={{
                  legend: {
                    formatter: formatLegend,
                  },
                }}
                chartData={martialStatusSeries}
              />
            </Box>
          )}
          {demoGraphicsSection.housingStatus?.isChecked && (
            <Box className={classes.donut1}>
              <BreakdownChart
                title="Housing status"
                className={classes.breakdownChart}
                chartData={housingSeries}
                chartHeight={250}
                donutOpts={{
                  legend: { formatter: formatLegend },
                }}
              />
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" gap={6}>
          {demoGraphicsSection.topFiveCities?.isChecked &&
            renderTable(
              'Top 5 cities',
              cityColumns,
              cityListData,
              onClickAll(DEMOGRAPHICS_TABLES.cities),
              '',
              classes.fullTable,
              DEMOGRAPHICS_TABLES.cities,
            )}
          {demoGraphicsSection.topFiveStates?.isChecked &&
            renderTable(
              'Top 5 states',
              stateColumns,
              stateListData,
              onClickAll(DEMOGRAPHICS_TABLES.states),
              '',
              classes.fullTable,
              DEMOGRAPHICS_TABLES.states,
            )}
        </Box>
      </Box>
    </Box>
  );
};
export { Demographics };
