import { FC, useEffect, useState } from 'react';
import cx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Box, Grid } from '@mui/material';
// components
import { Input } from '@confidant-health/lib/ui/atoms/input';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { getAuth } from 'redux/modules/auth/selectors';
import { authActionCreators } from 'redux/modules/auth/actions';
import { LoginType } from 'constants/CommonConstants';
import { resendOtp } from 'services/auth/auth.service';
import Heading from 'components/v2/Heading';
import { HeadingFont, HeadingLevel } from 'components/v2/Heading/index.types';
import { typography } from 'components/v2/Typography/index.constant';
import Typography from 'components/v2/Typography';
import VSlider from 'components/v2/VSlider';
import { useStyles } from './Login.styles';
import './login.css';

const TwoFactorAuthentication: FC = () => {
  const { isLoading, isAuthenticated, errorMsg } = useSelector(getAuth);
  const [counter, setCounter] = useState(60);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const codeType = 'ONE_TIME_PASSWORD';
  const { state } = useLocation() as any;
  const { mobileNumber, userEmail } = JSON.parse(localStorage.getItem('meta'));

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setButtonDisable(true);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      userType: LoginType.PROVIDER,
      code: '',
      codeType,
      emailOrPhone: mobileNumber || userEmail,
    },

    onSubmit: values => {
      dispatch(authActionCreators.verifyOTP(values));
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  const onBlurInput = async e => {
    await formik.setFieldTouched(e.target.name);
  };

  const callResendOtp = () => {
    setIsResendLoading(true);
    resendOtp({ codeType, emailOrPhone: state.emailOrPhone })
      .then(() => {
        setButtonDisable(false);
        setCounter(60);
      })
      .catch(e => console.log(e))
      .finally(() => {
        setIsResendLoading(false);
      });
  };

  const hidePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber?.trim()) {
      const lastFourDigits = phoneNumber?.slice(-4);
      const asterisks = '*'.repeat(phoneNumber?.length - 4);
      return `${asterisks}${lastFourDigits}`;
    }
    return phoneNumber;
  };

  const hideEmail = (email: string) => {
    if (email?.trim()) {
      const atIndex = email?.indexOf('@');
      const username = email?.slice(0, atIndex);
      const hiddenUsername = '*'.repeat(username?.length - 4) + username?.slice(-4);
      const domain = email?.slice(atIndex);
      return hiddenUsername + domain;
    }
    return email;
  };

  return (
    <div className={cx({ [classes.root]: true })}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Box width={448} display="flex" alignItems="center">
              <Box className={classes.flex} gap={7} flex={1}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box className={classes.between}>
                    <Heading
                      variant={HeadingLevel.H2}
                      fontFamily={HeadingFont.SECONDARY}
                      letterSpacing="-0.7px"
                    >
                      Verify that its you
                    </Heading>
                  </Box>
                  <Typography {...typography.body.m.regular}>
                    {mobileNumber?.trim()
                      ? `We sent a verification code to your phone ending on ${
                          hidePhoneNumber(mobileNumber) || ''
                        }`
                      : `We sent a verification code to your provided email ${hideEmail(userEmail)}`}
                    .
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="column" gap={2}>
                    {errorMsg && (
                      <Box className={classes.errorMsg}>
                        <Icons glyph="info-outlined" color={colors.destructive500} />
                        <Typography {...typography.body.s.medium}>
                          {errorMsg.data.errors[0].endUserMessage}
                        </Typography>
                      </Box>
                    )}
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                      <div className={classes.inputContainer}>
                        <Typography {...typography.body.normal.medium.semibold}>Verification code</Typography>
                        <Input
                          className={classes.input}
                          placeholder="Enter verification code"
                          name="code"
                          onChange={formik.handleChange}
                          onBlur={onBlurInput}
                        />
                        {formik.touched.code ? (
                          <Typography {...typography.body.normal.small.medium} color={colors.destructive600}>
                            {formik.errors.code?.toString()}
                          </Typography>
                        ) : null}
                      </div>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Button className={classes.buttonContainer} onClick={formik.handleSubmit}>
                          {isLoading ? (
                            <Icons
                              className="rotate linear infinite"
                              glyph="in-progress"
                              color={colors.white}
                            />
                          ) : (
                            'Verify and sign in'
                          )}
                        </Button>
                        <Button
                          className={classes.buttonContainer}
                          onClick={callResendOtp}
                          disabled={!buttonDisable}
                        >
                          {isResendLoading ? (
                            <Icons
                              className="rotate linear infinite"
                              glyph="in-progress"
                              color={colors.white}
                            />
                          ) : (
                            'Send again'
                          )}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                  <Box display="flex" justifyContent="center" color={colors.neutral500} mt={3}>
                    <Typography {...typography.body.s.medium}>
                      {counter ? `Wait ${counter} second before requesting a new code` : ''}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.support}>
              <IconButton icon="help">
                <Typography {...typography.body.m.bold}>Contact support</Typography>
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.rightContainer} sx={{ px: { xs: 5, sm: 5, md: 10 } }}>
            <Box className={classes.startBackground} />
            <VSlider />
            <Box className={classes.endBackground} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export { TwoFactorAuthentication };
