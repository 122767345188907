/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { LifeEventBottomCard } from './LifeEventBottomCard';
import { ILifeEventCardProps } from './LifeEventCard.types';
// styles
import { useStyles } from './LifeEventCard.styles';

const LifeEventCard: React.FC<ILifeEventCardProps> = ({ item, onClick, showNotes = true, isOnlyView }) => {
  const classes = useStyles();

  const onClickTitle = () => {
    onClick && onClick(item, false);
  };

  const onClickEdit = () => {
    onClick && onClick(item, true);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.rowCenter} sx={{ p: 3, height: 96 }}>
        <Box className={classes.topLeft}>
          <Box className={classes.topLeftHead}>
            <Text className={clsx(classes.title, { [classes.clickable]: !!onClick })} onClick={onClickTitle}>
              {item.name}
            </Text>
            <Badge
              variant={badgeType.FILLED}
              className={clsx(classes.badge, classes[`badge${item.priority.name}`])}
              style={badgeStyle[item.priority.name] || badgeStyle.UNRELATED}
            >
              {item.priority.name}
            </Badge>
          </Box>
          <Box>
            <Text className={classes.title2}>{item.description}</Text>
          </Box>
        </Box>
        <Box className={classes.topRight}>
          <IconButton
            icon="clock-rotate"
            variant={iconBtnType.TEXT}
            className={classes.btn}
            onClick={onClickTitle}
          >
            See All Reports ({(item.history ?? []).length + 1})
          </IconButton>
          {!isOnlyView && (
            <IconButton variant={iconBtnType.SECONDARY} className={classes.btn} onClick={onClickEdit}>
              Update
            </IconButton>
          )}
        </Box>
      </Box>
      <LifeEventBottomCard item={item} showNotes={showNotes} />
    </Box>
  );
};

export { LifeEventCard };
