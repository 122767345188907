import { FC, useMemo } from 'react';
import { Box, Divider, Paper } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

import { DashboardBox, IDashboardTopBoxProps } from '@confidant-health/lib/ui/templates/dashboard-box';
import { colors } from '@confidant-health/lib/colors';
import { AppointmentStatus } from '@confidant-health/lib/constants/CommonConstants';
import { getChartOpts } from 'pages/provider/todos/todo-chart.config';
import { DashboardHeading } from '../dashboard-heading';
import { useStyles } from './ReportMonth.styles';
import { getTopSectionCardText } from './ReportMonth.constants';

type Props = {
  items: IDashboardTopBoxProps[];
  onClickViewAllItems?: () => void;
};

const ReportMonth: FC<Props> = ({ items = [], onClickViewAllItems }) => {
  const classes = useStyles();

  const booked = useMemo(() => items.find(item => item?.status === AppointmentStatus.BOOKED), [items]);
  const pending = useMemo(() => items.find(item => item?.status === AppointmentStatus.PENDING), [items]);
  const proposed = useMemo(() => items.find(item => item?.status === AppointmentStatus.PROPOSED), [items]);
  const fulfilled = useMemo(() => items.find(item => item?.status === AppointmentStatus.FULFILLED), [items]);
  const inactive = useMemo(() => items.find(item => item?.status === 'INACTIVE'), [items]);
  const cancelled = useMemo(() => items.find(item => item?.status === AppointmentStatus.CANCELLED), [items]);
  const total = useMemo(
    () =>
      items.reduce((acc, curr) => {
        return acc + Number(curr?.count ?? 0);
      }, 0),
    [items],
  );

  const legendSeries = [
    {
      name: 'booked',
      color: colors.primary500,
      data: [Number(booked?.count) || 0],
    },
    {
      name: 'pending',
      color: colors.warning600,
      data: [Number(pending?.count) || 0],
    },
    {
      name: 'proposed',
      color: colors.secondary100,
      data: [Number(proposed?.count) || 0],
    },
    {
      name: 'fulfilled',
      color: colors.success600,
      data: [Number(fulfilled?.count) || 0],
    },
    {
      name: 'inactive',
      color: colors.cyan100,
      data: [Number(inactive?.count) || 0],
    },
    {
      name: 'cancelled',
      color: colors.neutral100,
      data: [Number(cancelled?.count) || 0],
    },
  ];

  return (
    <Paper>
      <DashboardHeading title="Appointments this month" onClick={onClickViewAllItems} />
      <Divider />
      <div className={classes.items}>
        {items
          ?.sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((box, index) => (
            <DashboardBox
              key={index}
              className={classes.item}
              label={getTopSectionCardText(box?.status) || ''}
              value={box?.count}
              type={box?.status}
              badge={box?.trendPercentage}
              isIncrease={box?.trendUp}
            />
          ))}
      </div>
      <Box
        className={classes.chart}
        sx={{
          '& span.apexcharts-legend-marker': {
            top: '0px !important',
            width: '32px !important',
            height: '32px !important',
          },
          '& span.apexcharts-legend-marker[rel="1"]::before': {
            content: `'${booked?.count ?? 0}'`,
            color: 'white !important',
            fontSize: 16,
            fontWeight: 700,
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '& span.apexcharts-legend-marker[rel="2"]::before': {
            content: `'${pending?.count ?? 0}'`,
            color: 'white !important',
            fontSize: 16,
            fontWeight: 700,
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '& span.apexcharts-legend-marker[rel="3"]::before': {
            content: `'${proposed?.count ?? 0}'`,
            color: '#93024D !important',
            fontSize: 16,
            fontWeight: 700,
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '& span.apexcharts-legend-marker[rel="4"]::before': {
            content: `'${fulfilled?.count ?? 0}'`,
            color: 'white !important',
            fontSize: 16,
            fontWeight: 700,
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '& span.apexcharts-legend-marker[rel="5"]::before': {
            content: `'${inactive?.count ?? 0}'`,
            color: '#0E6F90 !important',
            fontSize: 16,
            fontWeight: 700,
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '& span.apexcharts-legend-marker[rel="6"]::before': {
            content: `'${cancelled?.count ?? 0}'`,
            color: '#0D0F11 !important',
            fontSize: 16,
            fontWeight: 700,
            width: 32,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <ReactApexChart
          width={600}
          options={getChartOpts(
            legendSeries,
            {
              total,
              title: 'Total',
              size: '80%',
            },
            true,
            `${total} total`,
          )}
          series={[
            Number(booked?.count ?? 0),
            Number(pending?.count ?? 0),
            Number(proposed?.count ?? 0),
            Number(fulfilled?.count ?? 0),
            Number(inactive?.count ?? 0),
            Number(cancelled?.count ?? 0),
          ]}
          type="donut"
          height={160}
        />
      </Box>
    </Paper>
  );
};

export { ReportMonth };
