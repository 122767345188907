import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

interface StyleProps {
  collapsed?: boolean;
}

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  chevronIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    objectFit: 'contain',
    transition: '0.4s',
    marginLeft: '12px',
    marginRight: '12px',
    transform: ({ collapsed }: StyleProps) => `rotate(${collapsed ? '180deg' : 0})`,
    '& path': {
      fill: colors.neutral900,
    },
  },
  selects: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: '10px 46px',
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
  },
  label2: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    color: colors.neutral500,
  },
  selectItem: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
});
