import { EntityState } from '@reduxjs/toolkit';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { IProcedureCodes } from '../report-service/types';

export interface AppointmentState {
  masterSchedule2: any;
  allProviderServices: any;
  isLoading: boolean;
  isLoadingMore: boolean;
  isRequesting: boolean;
  errorMsg: string;
  appointments: IAppointment[];
  currentPage: number;
  totalPages: number;
  total: number;
  providerServices: {
    isLoading: boolean;
    errorMsg: string;
    services: IService[];
  };
  masterSchedule: IMasterScheduleState;
  providerRoles: IProviderRolesState;
  appointmentDetails: EntityState<IAppointmentDetails> & {
    isLoading: boolean;
    errorMsg: string;
  };
  appointmentDetailById2?: any;
  allAppointmentCounts?: {
    isLoading: boolean;
    errorMsg: string;
    past: number;
    current: number;
    pending: number;
  };
}

export type ITimeSlot = {
  startTime: string;
  endTime: string;
};

export interface ISlot {
  end: string;
  start: string;
}
export enum PatchAction {
  REQUEST_CHANGES = 'REQUEST_CHANGES',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  NO_SHOW = 'NO_SHOW',
  ADD_METAINFO = 'ADD_METAINFO',
  COMPLETE_APPOINTMENT = 'COMPLETE_APPOINTMENT',
  ARRIVE_FOR_APPOINTMENT = 'ARRIVE_FOR_APPOINTMENT',
  ADD_SUPERVISOR_NOTES = 'ADD_SUPERVISOR_NOTES',
  ADD_PROVIDER_FEEDBACK = 'ADD_PROVIDER_FEEDBACK',
  PROVIDER_SIGN_OFF = 'PROVIDER_SIGN_OFF',
  START_IN_PERSON = 'START_IN_PERSON',
}

export interface IPatchAppointmentRequest {
  action: PatchAction;
  confirmAppointmentData?: { primaryConcern?: string };
  supervisorNotesData?: { notes?: string; status?: string };
  appointmentMetaInfoRequestDto?: { scratchpad: string };
  appointmentRequestDto?: {
    providerId?: string;
    memberId: string;
    serviceId: string;
    comment?: string;
    startTime: string;
    primaryConcern?: string;
  };
  cancelAppointmentReason?: string;
  sessionQualityDetails?: {
    rating: number;
    connectionIssues: boolean;
    reminderIssues: boolean;
    communicationIssues: boolean;
    qualityFeedback: string;
  };
  skipProviderSignOff?: boolean;
}

export interface IProvider {
  approach: string;
  combinedRating: number;
  designation: string;
  matchmaker: boolean;
  name: string;
  operatingStates: string[];
  profilePicture: string;
  specialities: string[];
  speciality: string;
  stateLimited: boolean;
  totalReviews: number;
  userId: string;
}

export interface IService {
  bufferTime: number;
  cost: number;
  description: string;
  duration: number;
  marketCost: number;
  name: string;
  operatingStates: string[];
  privateService: boolean;
  initialService: boolean;
  enableNotification: boolean;
  recommendedCost: number;
  requireSupervisorSignOff?: boolean;
  roles?: string[];
  serviceAvailable?: boolean;
  providerId?: string;
  active?: boolean;
  serviceTypes: string[];
  stateLimited?: boolean;
  stateUsageInAppointment?: boolean;
  // for updating or create new
  systemService?: boolean;
  id?: string;
  providers?: IProvider[];
  serviceId?: string;
  buffer?: number;
  isDefault?: boolean;
  slidingScaleProviderFee?: number;
  slidingScaleRate?: number;
  providerFee?: number;
  cashRate?: number;
  acceptsSlidingScale?: boolean;
  cptCodes?: string[];
  primaryCptCodes?: string[];
  secondaryCptCodes?: string[];
}

export interface IMasterScheduleState {
  isLoading: boolean;
  errorMsg: string;
  masterScheduleItems: IMasterScheduleItem[];
}

export interface IProviderSchedule {
  availableServices: Array<{
    availableSession: 'string';
    availableSlots: Array<ISlot>;
  }>;
  scheduleDate: 'string';
}

export interface IMasterScheduleItem {
  firstAvailability: 'string';
  providerName: 'string';
  providerSchedule: Array<IProviderSchedule>;
  providerDetailsDto: ProviderDetails;
  bookedAppointments?: Array<any>;
}

export interface IFilterMasterSchedule {
  duration: number;
  endTime: string;
  month: string;
  providerRoles: string[];
  selectedDays: string[];
  startTime: string;
  timeSpan: number;
  timeZone: string;
  year: number;
}

export interface IProviderRolesState {
  isLoading: boolean;
  errorMsg: string;
  roles: IProviderRole[];
}

export interface IProviderRole {
  approach: string;
  combinedRating: number;
  designation: string;
  matchmaker: boolean;
  name: string;
  operatingStates: Array<string>;
  profilePicture: string;
  specialities: Array<string>;
  speciality: string;
  stateLimited: boolean;
  totalReviews: number;
  userId: string;
}

export interface IAppointmentDetails {
  appointmentId: string;
  cost: number;
  duration: number;
  evaluationProgress: Array<IEvaluationProgress>;
  participants: Array<IParticipant>;
  providerSignOffRole: string;
  requireSupervisorSignOff: boolean;
  serviceName: string;
  signOffNotes: {
    assessment: string;
    objective: string;
    plan: string;
    subjective: string;
    supervisorNote: string;
    signOffAt?: string;
    supervisorSignOffAt?: string;
  };
  signOffStatus: string;
  status: string;
  userSignOffRole: string;
  startTime?: string;
  endTime?: string;
}

export interface IEvaluationProgress {
  id: string;
  name: string;
  dctProgress: Array<{
    id: string;
    name: string;
    progress: number;
    score: number;
    isScorable?: boolean;
  }>;
  description: string;
  evaluationAssignmentMethod: string;
  progress: number;
  rejectionTemplate: Array<string>;
  status: string;
  assignedAt: string;
  completedAt: string;
  evaluationStats: {
    forProviderCount: number;
    totalCb: number;
    totalExercises: number;
    totalRequiredCb: number;
    unanswered: number;
    riskFactorCount: number;
  };
}

export interface IParticipant {
  appointmentFeedback: unknown;
  isPractitioner: boolean;
  name: string;
  participantId: string;
  profilePicture: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  rating: number;
  uuid?: string;
  designation?: string;
}

export interface IFeedbackCpt {
  appointmentCptCodes: IProcedureCodes[];
}

export interface IFilterMasterSchedule2 {
  daysOfWeek: string;
  duration: number;
  endTime: string;
  month: number;
  providerRoles: string;
  startTime: string;
  timezone: string;
  year: number;
}

/* export interface IMasterScheduleItem2 {
  actor: string;
  providerName: string;
  bookedAppointments: any;
  providerDetailsDto: ProviderDetails;
  slots: Array<ISlot2>;
}
export interface ISlot2 {
  end: string;
  start: string;
} */

export interface ProviderDetails {
  bio: string;
  experience: string;
  id: string;
  insuranceDetails: string[];
  name: string;
  providerImage: string;
  providerServices: Array<PServices>;
  rating: string;
  specialities: null;
  states: null;
  timeZone: string;
}

interface PServices {
  id: string;
  name: string;
  cost: string;
  duration: number;
}
