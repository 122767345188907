import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  filterText: {
    padding: '5px',
  },
  profile: {
    width: 400,
    '& #fullName': {
      display: 'inline-block',
      width: 140,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  insuranceText: {
    display: 'inline-block',
    fontSize: 16,
    width: 120,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  insuranceText2: {
    fontSize: 16,
  },
  insuranceIcon: {
    width: 16,
    height: 16,
  },
  noInsuranceIcon: {
    width: 20,
    height: 20,
  },
  nextAppointmentIcon: {
    width: 24,
    height: 24,
  },
  providerProfile: {
    width: '48%',
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: '24px 24px 0px 24px',
  },
  searchInput: {
    height: 48,
  },
  listItemContainer: {
    width: 300,
  },
  listItemStateContainer: {
    width: 330,
  },
  listItemMemberIdContainer: {
    width: 140,
  },
  userinfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '24px 20px',
    gap: 24,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
    '&:hover': {
      backgroundColor: colors.primary25,
      borderRadius: 12,
      border: `1px solid #B8DDFE !important`,
    },
  },
  selected: {
    backgroundColor: colors.primary100,
    borderRadius: 12,
    border: `1px solid #B8DDFE !important`,
  },
  error: {
    textAlign: 'center',
    color: `${colors.destructive500} !important`,
  },
  emptyList: {
    textAlign: 'center',
    color: colors.neutral400,
    display: 'inline-block',
    width: '100%',
    margin: '32px 0',
  },
  badge: {
    fontSize: 12,
    height: 18,
  },
  providerInfo: {
    borderRadius: 8,
    border: '1px solid',
    borderColor: colors.neutral200,
    padding: 24,
    display: 'flex',
    gap: 80,
    cursor: 'pointer',
  },
  nickName: {
    fontSize: 14,
    color: colors.neutral900,
    lineHeight: '20px',
  },
  fullName: {
    fontSize: 14,
    color: colors.neutral600,
    display: 'block',
  },
  loadMoreBtn: {
    fontSize: 14,
  },
  loadingWrap: {
    '& svg': {
      marginRight: 12,
    },
  },
  heading: {
    color: colors.neutral900,
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '-0.22px',
  },
  bookNewProviderBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '24px',
    border: `1px solid ${colors.neutral200}`,
    borderRadius: '8px',
    cursor: 'pointer',
  },
  bookNewProviderIcon: {
    width: 40,
    height: 40,
  },
  bookNewProviderText: {
    fontSize: 16,
    color: colors.primary600,
    fontWeight: 600,
    lineHeight: '24px',
    cursor: 'pointer',
  },
  providerList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  profileInfo: {
    width: 10,
  },
});

export const useStyles2 = makeStyles({
  filterText: {
    padding: '5px',
  },
  profile: {
    width: '30%',
  },
  insuranceBox: {
    width: '30%',
  },
  stateBox: {
    width: '13%',
  },
  dobBox: {
    width: '23%',
  },
  ticBox: {
    width: '4%',
  },

  insuranceText: {
    fontSize: 16,
  },
  insuranceIcon: {
    width: 16,
    height: 16,
  },
  noInsuranceIcon: {
    width: 20,
    height: 20,
  },
  providerProfile: {
    width: '48%',
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    padding: 24,
  },
  searchInput: {
    height: 48,
  },
  userinfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 20,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
    '&:hover': {
      backgroundColor: colors.primary25,
      borderRadius: 12,
      border: `1px solid #B8DDFE !important`,
    },
  },
  selected: {
    backgroundColor: colors.primary100,
    borderRadius: 12,
    border: `1px solid #B8DDFE !important`,
  },
  error: {
    textAlign: 'center',
    color: `${colors.destructive500} !important`,
  },
  emptyList: {
    textAlign: 'center',
    color: colors.neutral400,
    display: 'inline-block',
    width: '100%',
    margin: '32px 0',
  },
  badge: {
    fontSize: 12,
    height: 18,
  },
  nickName: {
    fontSize: 14,
    color: colors.neutral900,
    lineHeight: '20px',
  },
  fullName: {
    fontSize: 14,
    color: colors.neutral600,
    display: 'block',
  },
  loadMoreBtn: {
    fontSize: 14,
  },
  loadingWrap: {
    '& svg': {
      marginRight: 12,
    },
  },
});
