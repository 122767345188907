import { useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { colors } from '@confidant-health/lib/colors';
import { btnSize, btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { IClaimStatusDetail } from 'redux/modules/reporting/types';
import { sentenceCase } from 'sentence-case';
import { CLAIM_STATUSES } from 'pages/admin/claims/Claims.constants';
import DatePickerInput from './DatePicker';
import { useStyles } from '../ClaimDetail.styles';

type Props = {
  onSubmit: (formData: Partial<IClaimStatusDetail>, type: string) => void;
  data?: Partial<
    IClaimStatusDetail & {
      profileImage: string;
      fullName: string;
      firstName: string;
      lastName: string;
      memberId: string;
      uuid?: string;
    }
  >;
};

type DrawerProps = {
  data: Partial<IClaimStatusDetail>;
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<IClaimStatusDetail>, type: string) => void;
};

const defaultData = {
  claimStatus: '',
  datePaid: '',
  amountPaid: 0,
  amountDenied: 0,
  notes: '',
  trackingNumber: '',
  initialBillingDate: '',
};

const labels = {
  claimStatus: 'Claim Status',
  amountPaid: 'Amount Paid',
  datePaid: 'Date Paid',
  amountDenied: 'Amount Denied',
  notes: 'Notes',
  trackingNumber: 'Tracking Number',
  initialBillingDate: 'Initial Billing Date',
};

export const StatusDetailDrawer = ({ data, open, onClose, onSubmit }: DrawerProps) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...data });
  const [errors, setErrors] = useState(defaultData);

  const onChangeField = (field: string) => (e: any) => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: '' }));
    }
    setFormData(old => ({
      ...old,
      [field]: e.target?.value,
    }));
  };

  const onChangeStatus = () => {
    const { amountPaid, claimStatus, datePaid, amountDenied, notes, trackingNumber, initialBillingDate } =
      formData;
    onSubmit(
      { amountPaid, claimStatus, datePaid, amountDenied, notes, trackingNumber, initialBillingDate },
      'statusDetail',
    );
  };

  const onChangeBillingDate = (date: any) => {
    if (date?.isValid() && date?.isBefore(dayjs())) {
      setFormData({ ...formData, initialBillingDate: date.toISOString() });
    } else {
      setFormData({ ...formData, initialBillingDate: '' });
    }
  };

  const onChangePaidDate = (date: any) => {
    if (date?.isValid() && date?.isBefore(dayjs())) {
      setFormData({ ...formData, datePaid: date.toISOString() });
    } else {
      setFormData({ ...formData, datePaid: '' });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title="Edit Status"
      submitBtnTitle="Update"
      onSubmit={onChangeStatus}
    >
      <Box className={classes.inputWrapper}>
        <label htmlFor="claimStatus">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.claimStatus}
          </Text>
        </label>
        <Select
          value={formData.claimStatus}
          name="claimStatus"
          variant={selectType.SECONDARY}
          emptyText=""
          displayEmpty
          options={CLAIM_STATUSES}
          onChange={onChangeField('claimStatus')}
        />
      </Box>
      <Box className={classes.inputWrapper}>
        <label htmlFor="initialBillingDate">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.initialBillingDate}
          </Text>
        </label>
        <DatePickerInput date={dayjs(formData?.initialBillingDate)} onChange={onChangeBillingDate} />
      </Box>
      <Box className={classes.inputWrapper}>
        <label htmlFor="amountPaid">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.amountPaid}
          </Text>
        </label>
        <Input
          name="amountPaid"
          value={formData.amountPaid || ''}
          onChange={onChangeField('amountPaid')}
          size={inputSize.M}
          placeholder="Enter your amount"
          type="number"
        />
      </Box>
      <Box className={classes.inputWrapper}>
        <label htmlFor="datePaid">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.datePaid}
          </Text>
        </label>
        <DatePickerInput date={dayjs(formData.datePaid)} onChange={onChangePaidDate} />
      </Box>

      <Box className={classes.inputWrapper}>
        <label htmlFor="amountPaid">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.amountDenied}
          </Text>
        </label>
        <Input
          name="amountDenied"
          value={formData.amountDenied || ''}
          onChange={onChangeField('amountDenied')}
          size={inputSize.M}
          placeholder="Enter your amount"
          type="number"
        />
      </Box>
      <Box className={classes.inputWrapper}>
        <label htmlFor="notes">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.notes}
          </Text>
        </label>
        <Input
          name="notes"
          value={formData.notes || ''}
          onChange={onChangeField('notes')}
          size={inputSize.M}
          placeholder="Enter your notes"
        />
      </Box>
      <Box className={classes.inputWrapper}>
        <label htmlFor="notes">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {labels.trackingNumber}
          </Text>
        </label>
        <Input
          name="trackingNumber"
          value={formData.trackingNumber || ''}
          onChange={onChangeField('trackingNumber')}
          size={inputSize.M}
          placeholder="Enter your tracking number"
        />
      </Box>
    </Drawer>
  );
};

const StatusDetail = ({ onSubmit, data = defaultData }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const Col = ({ label, value, colType = false }) => (
    <Box display="flex" flexDirection="column">
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <>
        {colType ? (
          <Badge
            variant={badgeType.OUTLINED}
            style={
              value === 'PAID' ||
              value === 'PARTIALLY_PAID' ||
              value === 'OVERPAID' ||
              value === 'UNDERPAID' ||
              value === 'APPEALED'
                ? badgeStyle.RESOLVED
                : value === 'CREATED' || value === 'COMPLETED'
                ? badgeStyle.PRIMARY
                : value === 'DENIED' || value === 'REJECTED'
                ? badgeStyle.HIGH
                : badgeStyle.MEDIUM
            }
            className={clsx(
              classes.badge,
              { [classes.unPaid]: value === 'DENIED' || value === 'REJECTED' },
              {
                [classes.submittedStatus]: value === 'CREATED' || value === 'COMPLETED',
              },
              {
                [classes.paid]:
                  value === 'PAID' ||
                  value === 'PARTIALLY_PAID' ||
                  value === 'OVERPAID' ||
                  value === 'UNDERPAID' ||
                  value === 'APPEALED',
              },
              classes.marginTop8,
            )}
          >
            {sentenceCase(value || '')}
          </Badge>
        ) : (
          <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
            {value || 'N/A'}
          </Text>
        )}
      </>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          Status
        </Heading>
        <Button
          className={classes.btn}
          variant={btnType.TEXT}
          onClick={() => setOpen(true)}
          size={btnSize.SMALL}
        >
          Edit info
        </Button>
      </Box>
      <Divider sx={{ marginX: -3 }} />

      <Box display="flex" mt={4} justifyContent="space-between">
        <Box display="flex" flexDirection="column" minWidth={185}>
          <ProfileInfo
            type="member"
            photo={data?.profileImage}
            nickName={
              data?.firstName && data?.lastName
                ? `${data?.firstName || ''} ${data?.lastName || ''}`
                : data?.fullName ?? 'No Name'
            }
            fullName={data?.uuid}
            memberId={data?.memberId}
          />
        </Box>
        <Col label="Claim status" value={data?.claimStatus} colType />
        <Col
          label="Initial billing date"
          value={data?.initialBillingDate ? format(new Date(data?.initialBillingDate), 'MM.dd.yyyy') : ''}
        />
        <Col label="Amount paid" value={data?.amountPaid} />
        <Col label="Amount denied" value={data?.amountDenied} />
        <Col label="Date paid" value={data?.datePaid ? format(new Date(data?.datePaid), 'MM.dd.yyyy') : ''} />
        <Col label="Notes" value={data?.notes} />
        <Col label="Tracking no" value={data?.trackingNumber} />
      </Box>
      {open && (
        <StatusDetailDrawer data={data} open={open} onClose={() => setOpen(false)} onSubmit={onSubmit} />
      )}
    </Box>
  );
};

export default StatusDetail;
