import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { format } from 'date-fns';
import { colors } from '@confidant-health/lib/colors';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { IClaimPatient } from 'redux/modules/reporting/types';
import { useStyles } from '../ClaimDetail.styles';

type Props = {
  data?: IClaimPatient;
};

const defaultData = {
  dob: '',
  firstName: '',
  fullName: '',
  gender: '',
  lastName: '',
  phoneNumber: '',
  profileImage: '',
  ssn: '',
};

const Patient = ({ data = defaultData }: Props) => {
  const classes = useStyles();

  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={185}>
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
        {value || 'N/A'}
      </Text>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          Patient
        </Heading>
      </Box>
      <Divider sx={{ marginX: -3 }} />

      <Box display="flex" mt={4}>
        <Box display="flex" flexDirection="column" minWidth={185}>
          <ProfileInfo
            type="member"
            photo={data?.profileImage}
            nickName={
              data?.firstName && data?.lastName
                ? `${data?.firstName || ''} ${data?.lastName || ''}`
                : data?.fullName ?? 'No Name'
            }
            fullName={data?.uuid}
            memberId={data?.userAccountId}
          />
        </Box>
        <Col label="Date of birth" value={data?.dob ? format(new Date(data?.dob), 'MM.dd.yyyy') : ''} />
        <Col label="Gender" value={data?.gender} />
        <Col label="SSN" value={data?.ssn} />
        <Col label="Phone" value={data?.phoneNumber} />
      </Box>
    </Box>
  );
};

export default Patient;
