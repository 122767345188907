import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  select: {
    position: 'relative',
    '& > .MuiInputBase-input': {
      paddingLeft: '32px !important',
    },
    '&:after': {
      position: 'absolute',
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: colors.neutral600,
      left: 16,
      top: 20,
      zIndex: 1,
      content: '""',
    },
  },
  selectRESOLVED: {
    '&:after': {
      backgroundColor: colors.textResolved,
    },
  },
  selectPOSITIVE: {
    '&:after': {
      backgroundColor: colors.textResolved,
    },
  },
  selectNEGATIVE: {
    '&:after': {
      backgroundColor: colors.textHigh,
    },
  },
  selectMISREPORTED: {
    '&:after': {
      backgroundColor: colors.destructive600,
    },
  },
  selectHIGH: {
    '&:after': {
      backgroundColor: colors.textHigh,
    },
  },
  selectLOW: {
    '&:after': {
      backgroundColor: colors.cyan600,
    },
  },
  selectMEDIUM: {
    '&:after': {
      backgroundColor: colors.orange600,
    },
  },
  selectUNRELATED: {
    '&:after': {
      backgroundColor: colors.neutral600,
    },
  },
  selectCRITICAL: {
    '&:after': {
      backgroundColor: colors.bgCritical,
    },
  },
});
