import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Input, inputType, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import SideEffectCard from 'pages/provider/member-detail/components/SideEffects/components/side-effect-card';
import { DrawerProps } from '../drawer.types';
import { useStyles } from '../history-drawer/HistoryDrawer.styles';
import EmptyState from '../empty-state';

const SymptomaticFactorDrawer: FC<DrawerProps> = ({
  open,
  isNoDrawer,
  isOnlyView,
  onClose,
  onClickNewReport,
  onClickCard,
  items = [],
  appointment,
  loading,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [list, setList] = useState(items);

  useEffect(() => {
    const filteredList = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
    setList(filteredList);
  }, [search, items]);

  const renderContent = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.searchWrap}>
          <Input
            variant={inputType.SEARCH}
            className={classes.searchInput}
            placeholder="Search"
            size={inputSize.M}
            value={search}
            onChange={setSearch}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={classes.reportNewBtn}
            onClick={onClickNewReport}
            // disable
          >
            Report new
          </IconButton>
        </Box>
        <Box className={classes.items}>
          {loading ? (
            <Box display="flex" justifyContent="center" paddingY="10px">
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            </Box>
          ) : list.length > 0 ? (
            list.map((item, index: number) => (
              <SideEffectCard key={index} item={item} onClick={onClickCard} isOnlyView={isOnlyView} />
            ))
          ) : (
            <EmptyState
              title={`No Symptomatic Factors reported for ${
                appointment?.participantName || 'this member'
              } yet.`}
              subtitle="If they've told you about some symptomatic factors they're having, please record it here."
              glyph="face-talking-confused"
            />
          )}
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="Important Symptomatic Factors"
      subTitle={loading ? '' : `${list.length} total`}
      onClose={onClose}
      variant={drawerType.FORM}
      className={clsx(classes.drawer, classes.drawerWithSubtitle)}
      footer={<span />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { SymptomaticFactorDrawer };
