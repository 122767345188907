import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';

import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { paginationParamsType } from '@confidant-health/lib/ui/organisms/table/pagination';
// import { Filter } from '@confidant-health/lib/ui/organisms/table';
import { searchParamsType } from '@confidant-health/lib/ui/organisms/table/search';

import { BaseLayout } from 'layouts/base';
import { selectAutomationById } from 'redux/modules/conversation/selectors';
import { getElkAutomationDetail } from 'services/reporting/reporting.service';

import AssignmentsChart from './assignments-chart';
import BreakdownChart from './breakdown-chart';
import RecentAssignmentTable from './recent-assignments-table';
import InfoCard from './info-card';

// styles
import { useStyles } from './AutomationDetailPage.styles';
import {
  IFormatedAutomationDetail,
  automationDetailElkQuery,
  automationRecentAssignemtColumns,
  automationRecentAssignmentElkQuery,
  // filterOptionsMock,
  getFormatedAutomationDetail,
  getFormatedRecentAssignedData,
} from './AutomationDetailPage.constants';

const BREAKDOWN_BY_METHOD_PROGRESS_LABELS = {
  COMPLETED: 'Completed',
  DELAYED: 'Delayed',
};

const AutomationDetailPage: FC = () => {
  const classes = useStyles();
  const [searchProps, setSearchProps] = useState({ searchKey: '' } as searchParamsType);
  const [automationDetail, setAutomationDetail] = useState({} as IFormatedAutomationDetail);
  const [automationRecentAssignemt, setAutomationRecentAssignemt] = useState([]);
  const [automationTotalRecentAssignemt, setAutomationTotalRecentAssignemt] = useState(0);
  const [pagination, setPagination] = useState({ currentPage: 1, rowsPerPage: 10 } as paginationParamsType);
  const [isLoading, setIsLoading] = useState(false);

  const { automationId, active } = useParams();
  const automationRule = useSelector(selectAutomationById(automationId));

  const getRecentAssignmentDataCall = () => {
    setIsLoading(true);
    getElkAutomationDetail(
      automationRecentAssignmentElkQuery(
        automationId,
        searchProps.searchKey,
        pagination.currentPage,
        pagination.rowsPerPage,
      ),
      null,
    )
      .then(automationRecentAssignmentResponse => {
        const formatedData = getFormatedRecentAssignedData(automationRecentAssignmentResponse?.data?.hits);
        setAutomationRecentAssignemt(formatedData);
        setAutomationTotalRecentAssignemt(automationRecentAssignmentResponse?.data?.hits?.total?.value || 0);
        setIsLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    getElkAutomationDetail(automationDetailElkQuery(automationId), null)
      .then(automationDetailResponse => {
        setAutomationDetail(getFormatedAutomationDetail(automationDetailResponse.data));
        setIsLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setTimeout(getRecentAssignmentDataCall, 500);
  }, [searchProps, pagination]);

  const getLabelText = (value: string) => {
    return BREAKDOWN_BY_METHOD_PROGRESS_LABELS[value];
  };
  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Breadcrumbs
            links={[{ href: '/admin/automations', text: 'Automations' }, { text: automationRule?.ruleName }]}
          />
        </Box>
      </Box>
      <Box>
        <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }} marginBottom={8}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            {automationRule?.ruleName}
          </Heading>
          <Badge
            variant={badgeType.FILLED}
            style={active ? badgeStyle.RESOLVED : badgeStyle.HIGH}
            className={clsx(classes.badge, { [classes.inActive]: !active })}
          >
            {active ? 'Active' : 'Inactive' || 'N/A'}
          </Badge>
        </Stack>
        <Box className={classes.content}>
          <Stack direction="row" gap={2} className={classes.infoWrap}>
            <InfoCard title="executions - last 30 days" value={automationDetail?.assignmentLast30Days || 0} />
            <InfoCard title="executions - total" value={automationDetail?.totalAssignments || 0} />
          </Stack>
          <Box className={classes.chartWrap}>
            <AssignmentsChart
              title="Executions"
              className={classes.chart}
              data={automationDetail?.assignments}
              isLoading={isLoading}
              chart={{
                series: [
                  {
                    name: 'Current 30 days',
                    color: colors.primary500,
                    data: automationDetail?.assignments?.current30Days?.map(item => item.count),
                  },
                ],
                tooltipConfig: {
                  isShowTotal: false,
                  title: 'Total',
                },
                showLegend: false,
              }}
            />
          </Box>
          <Box className={classes.chartWrap} display="flex" gap={1} paddingY="8px">
            <BreakdownChart
              title="Automation status breakdown"
              className={classes.breakdownChart}
              chartWidth={386}
              chartHeight={160}
              donutOpts={{
                title: 'Members',
                total: automationDetail?.totalRecords,
                size: '80',
              }}
              legendRight
              chartData={
                automationDetail?.breakdownByProgress?.map(item => ({
                  name: ` ${item.count} ${getLabelText(item.key)} `,
                  color: item.key === 'COMPLETED' ? colors.success500 : colors.primary500,
                  data: [item.count],
                })) || []
              }
            />
          </Box>
          <Box>
            <Box className={classes.headerDetail}>
              <Stack direction="column">
                <Text className={classes.recentTitle} weight={fontWeight.BOLD}>
                  Automation members
                </Text>
              </Stack>
            </Box>
            <RecentAssignmentTable
              isShowAll
              data={automationRecentAssignemt || []}
              tableColumns={automationRecentAssignemtColumns}
              isLoading={isLoading}
              paginationProps={{
                currentRows: 10,
                totalCount: automationTotalRecentAssignemt,
                value: pagination,
                showRowsPerPage: false,
                onChange: value => setPagination(value),
              }}
              searchProps={{
                value: searchProps,
                placeholder: 'Search',
                onChange: value => {
                  setPagination({ currentPage: 1, rowsPerPage: 10 });
                  setSearchProps(value);
                },
                // filterProps: {
                //   variant: Filter.tableFilterType.MULTIPLE,
                //   options: filterOptionsMock,
                //   allOptionLabel: 'All profile',
                // },
              }}
              className={classes.memberTable}
            />
          </Box>
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { AutomationDetailPage };
