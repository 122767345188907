import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  multicolorBar: {
    margin: '0 16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '8px',
    overflow: 'hidden',
    // backgroundColor: colors.rose500,
    '& bars bar': {
      height: '10px',
    },
  },
  bar: {
    position: 'relative',
    height: '10px',
    '&:hover': {
      borderWidth: '2px',
    },
  },
  bars: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    height: '10px',
  },
});
