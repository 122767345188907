import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '1000px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  loader: {
    height: 'calc(100vh - 158px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    position: 'relative',
    padding: '16px 8px 24px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerNoDrawer: {
    padding: 16,
    '& $headTitle': {
      fontSize: 18,
      lineHeight: '24px',
    },
    '& $headSubtitle': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      color: colors.neutral600,
    },
  },
  headWrap: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  headSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'block',
    margin: 0,
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    position: 'absolute',
    right: 24,
    top: 24,
    '& svg': {
      width: 30,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  content: {
    height: 'calc(100vh - 158px)',
    overflowY: 'auto',
    transition: 'height 300ms linear',
  },
  showFooter: {
    height: 'calc(100vh - 238px)',
  },
  sessionContent: {
    height: 'calc(100vh - 220px)',
    overflowY: 'auto',
    transition: 'height 300ms linear',
  },
  sessionShowFooter: {
    height: 'calc(100vh - 239px)',
  },
  stepWrap: {
    marginTop: 20,
    '& .Mui-completed': {
      '& #stepCircle': {
        background: '#000000',
        border: '2px solid #ffffff',
      },
    },
  },
  availabilitySlotContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  availabilitySlot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  availabilitySlotActionContainer: {},
  dateTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  insuranceText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
  insuranceText2: {
    fontSize: 16,
  },
  insuranceIcon: {
    width: 16,
    height: 16,
  },
  noInsuranceIcon: {
    width: 20,
    height: 20,
  },
  dashedLine: {
    color: colors.neutral300,
    borderBottom: '2px dashed',
    width: 670,
    height: '10px',
  },
  dashedLineSmall: {
    color: colors.neutral300,
    borderBottom: '2px dashed',
    width: 130,
    height: '10px',
  },
  card: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  cardTopSection: {
    height: 72,
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    borderBottom: '1px solid',
    color: colors.neutral100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContentSection: {
    padding: 32,
    display: 'flex',
    justifyContent: 'space-between',
  },
  nickName: {
    fontSize: 14,
    color: colors.neutral900,
    lineHeight: '20px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  btn: {
    maxWidth: '120px',
    width: '100%',
    color: colors.white,
  },
  btnReschedule: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary600,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
    paddingBottom: 16,
  },
  text14_2: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
  },
  text16: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },
  badge: {
    height: 'auto !important',
    fontSize: 12,
    padding: '0 8px',
  },
});
