import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: colors.white,
    borderRadius: 8,
    width: '100%',
    padding: 16,
    flex: 1,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  bordered: {
    border: `2px solid ${colors.neutral700}`,
  },
  title: {
    margin: 0,
    textTransform: 'uppercase',
    lineHeight: '20px',
    fontSize: 12,
    color: colors.neutral600,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '36px',
    margin: 0,
    color: colors.neutral900,
  },
  badge: {
    borderRadius: 20,
    height: 20,
    fontSize: 12,
    lineHeight: '16px',
    width: 'fit-content',
    padding: '3px 6px',
  },
});
