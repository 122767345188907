import { FC, useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import cx from 'clsx';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { useStyles } from './TopBar.styles';

export type ISource = {
  id: string;
  name: string;
  thumbnail: string;
};

type Props = {
  shareScreen?: ISource;
  onEndSession?: () => void;
  toggleCamera?: (isOn: boolean) => void;
  toggleMic?: (isOn: boolean) => void;
  onChangeShareScreen?: () => void;
  onShareScreenClick?: () => void;
  onStopSharingClick?: () => void;
  toggleFullScreen: () => void;
  publisher?: ReactJSXElement;
  screen?: ReactJSXElement;
};

const TopBar: FC<Props> = ({
  shareScreen,
  onEndSession,
  toggleCamera = isOn => setVideo(isOn),
  toggleMic = isOn => setAudio(isOn),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeShareScreen,
  onShareScreenClick,
  onStopSharingClick,
  publisher,
  toggleFullScreen,
  screen,
}) => {
  const classes = useStyles();
  const [showDropDownSharing, setShowDropDownSharing] = useState(false);
  const [isVideoCall, setVideo] = useState(true);
  const [isAudio, setAudio] = useState(true);

  const onShareClick = () => {
    if (screen) {
      setShowDropDownSharing(true);
    } else {
      onShareScreenClick();
    }
  };

  const onStopClick = () => {
    onStopSharingClick();
    setShowDropDownSharing(false);
  };

  // const onChageScreenClick = () => {
  //   setShowDropDownSharing(false);
  //   onChangeShareScreen();
  // };

  const toggleCameraHandler = () => {
    setVideo(!isVideoCall);
    toggleCamera(!isVideoCall);
  };

  const toggleMicHandler = () => {
    setAudio(!isAudio);
    toggleMic(!isAudio);
  };

  return (
    <>
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" gap="12px">
          <>
            <Box className={classes.imgWrap}>
              {publisher || <img src="https://randomuser.me/api/portraits/men/20.jpg" alt="" />}
            </Box>
            {screen && <Box className={classes.imgWrap}>{screen}</Box>}
          </>
        </Box>
        <Box className={classes.actions}>
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, { [classes.shareActive]: screen })}
            onClick={onShareClick}
            icon="share-screen"
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, { [classes.videoActive]: isVideoCall })}
            icon={isVideoCall ? 'camera-filled' : 'camera-off'}
            onClick={toggleCameraHandler}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, { [classes.AudioActive]: isAudio })}
            onClick={toggleMicHandler}
            icon={isAudio ? 'mic-on' : 'mic-off'}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn)}
            icon="full-screen"
            onClick={toggleFullScreen}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, classes.iconBtnActive)}
            icon="end-call-filled"
            onClick={onEndSession}
          />
          {showDropDownSharing && (
            <ClickAwayListener onClickAway={() => setShowDropDownSharing(false)}>
              <Box className={classes.dropdown}>
                <Box className={classes.wrapTitle}>
                  <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.title}>
                    Sharing your screen - {shareScreen?.name}
                  </Heading>
                </Box>
                <Box className={classes.wrapContent}>
                  <IconButton
                    className={cx(classes.btn, { [classes.stopShareBtn]: screen })}
                    onClick={onStopClick}
                  >
                    Stop sharing screen
                  </IconButton>
                  {/* <IconButton
                    className={cx(classes.btn, { [classes.changeShareBtn]: screen })}
                    onClick={onChageScreenClick}
                  >
                    Change screen
                  </IconButton> */}
                </Box>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      </Box>
    </>
  );
};

export { TopBar };
