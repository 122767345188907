import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal } from '@mui/material';
import dayjs from 'dayjs';
import cx from 'clsx';
import { Calendar } from '@confidant-health/lib/ui/templates/Calendar';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnSize, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { AppointmentCard, IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import {
  AppointmentStatus,
  AppointmentType,
  SignOffStatus,
} from '@confidant-health/lib/constants/CommonConstants';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
// import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { useReactToPrint } from 'react-to-print';
import * as memberService from 'services/member/member.service';

import history from 'utils/history';
import { profileActionCreators } from 'redux/modules/profile';
import { scheduleActionCreators } from 'redux/modules/schedule/actions';
import { appointmentActionCreators } from 'redux/modules/appointment/actions';
import {
  INewRecurringAppointmentRequest,
  ISearchAppointmentParams,
  appointmentsDownload,
  getAppointments,
} from 'services/appointment/appointment.service';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';

import CalendarGroupButton from 'pages/provider/appointments/components/CalendarGroupButton';
import AddSchedule, { INewSchedulePayload } from 'pages/admin/appointments/add-schedule';
import AppointmentDetail from 'pages/provider/appointments/components/appointment-detail';
import AppointmentScheduling from 'pages/provider/appointments/components/appointment-scheduling';
import AppointmentCancel from 'pages/provider/appointments/components/appointment-cancel';
import AppointmentsDrawer from 'pages/provider/appointments/components/appointments-drawer';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';
import logo from 'assets/images/ch-icon.png';
import { TableSearch, searchParamsType } from '@confidant-health/lib/ui/organisms/table/search';
import { tableFilterType } from '@confidant-health/lib/ui/organisms/table/filter';
import { getUserTimeZone } from 'utils/dayjs';
import { conversationActionCreators } from 'redux/modules/conversation';
import { getAuth } from 'redux/modules/auth/selectors';
import { selectEvaluation } from 'redux/modules/conversation/selectors';
import { getProfile } from 'redux/modules/profile/selectors';

import PriorityEmpty from '../PriorityEmpty';

import {
  AppointmentTitles,
  currentAppointmentsFilterOption,
  pastAppointmentsFilterOption,
  defaultAppointmentFilterOptions,
} from './Appointments.constants';
import { useStyles } from '../../MemberDetail.styles';

import { IDownloadAppointment, IDownloadDiagnosis, IDownloadMedication } from '../../MemberDetail.types';
import { downloadDateRange } from '../../MemberDetail.constants';
import AppointmentDetailDrawer from '../../../../admin/appointments/appointment-detail-drawer';

interface IAppointmentsProps {
  selectedScreen: string;
}

const Appointments: React.FC<IAppointmentsProps> = ({ selectedScreen }) => {
  const classes = useStyles({});
  const { isAdmin } = useSelector(getAuth);
  const { pathname } = useLocation();
  const { profile } = useSelector(getProfile);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const [appointmentsCount] = useState(0);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedAppointmentList, setSelectedAppointmentList] = useState<IAppointment[]>([]);
  const [showCancel, setShowCancel] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const providerSchedule = useSelector(selectProviderSchedule);
  const [isShowCalendar, setIsShowCalendar] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentSize, setCurrentSize] = useState(50);
  const [formatedAppointmentForDownload, setFormatedAppointmentForDownload] = useState(null);
  const [openTableToPrintModal, setOpenTableToPrintModal] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const evaluations = useSelector(selectEvaluation);

  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(false);
  const [screenChangeLoading, setScreenChangeLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({} as searchParamsType);

  const openTableToPrint = () => {
    setOpenTableToPrintModal(true);
  };

  const closeTableToPrint = () => {
    setOpenTableToPrintModal(false);
  };
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => {
      const currentComponent = componentRef.current as any;
      currentComponent.style.overflow = 'visible';
      return currentComponent;
    },
  });
  const onViewClick = (appointment: IAppointment) => {
    if (appointment.status === AppointmentStatus.FULFILLED) {
      dispatch(profileActionCreators.updateAppointmentNote(appointment));
      history.push(`${pathname}/appointments/${appointment.appointmentId}`);
    } else {
      dispatch(
        conversationActionCreators.fetchAssignedEvaluations({ appointmentId: appointment.appointmentId }),
      );
      setSelectedAppointment(appointment);
    }
  };
  const formatAppointmentsForDownload = (
    downloadAppointmentList: IDownloadAppointment[],
    medications: IDownloadMedication[],
    diagnosis: IDownloadDiagnosis[],
    notes: any[],
  ) => {
    if (!downloadAppointmentList || downloadAppointmentList.length === 0) {
      setIsExportDisabled(true);
      return;
    }
    if (downloadAppointmentList) {
      memberService
        .getAdminProfile({ userId: memberId || '' })
        .then(patientResponse => {
          setFormatedAppointmentForDownload(
            <div ref={componentRef} className={classes.documentToPrint}>
              <div className={classes.coverPage}>
                <div className={classes.coverDownlad}>
                  <div className={classes.topBox}>
                    <p className={classes.c4}>FOR CLINICAL USE ONLY</p>
                  </div>
                  <div className={classes.middleBox}>
                    <p className={classes.c4}>
                      <span className={classes.imageBox}>
                        <img alt="" src={logo} className={classes.imageTag} title="" />
                      </span>
                    </p>
                    <p className={classes.c2}>Patient Clinical Notes Export</p>
                  </div>
                  <div className={classes.bottomBox}>
                    <p className={classes.c5}>Confidential:</p>
                    <div className={classes.bottomInnerBox}>
                      <p className={classes.c1}>
                        These clinical notes contain sensitive patient information. If received in error,
                        please notify us immediately and destroy all copies. Unauthorized access, disclosure,
                        or use is prohibited by law and subject to penalties.
                      </p>
                      <p className={classes.c1}>
                        Protect patient privacy, it&#39;s our shared responsibility.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Patient information */}
              <div className={classes.sectionBox}>
                <p className={classes.title}>FOR CLINICAL USE ONLY</p>
                <div className={classes.exportDate}>
                  <p className={classes.textRight}>Date of Export: {dayjs().format(GLOBAL_DATE_FORMAT)}</p>
                </div>

                <p className={classes.question}>
                  <b>
                    Patient:{' '}
                    <span className={classes.answer}>
                      {patientResponse.data?.firstName || patientResponse.data?.lastName
                        ? `${patientResponse.data?.firstName || ''} ${patientResponse.data?.lastName || ''}`
                        : patientResponse.data?.fullName ?? ''}{' '}
                    </span>
                  </b>
                </p>
                <p className={`${classes.question} ${classes.cptList}`}>
                  <b>
                    Date of birth:{' '}
                    {patientResponse.data?.dob
                      ? dayjs(patientResponse.data?.dob).format(GLOBAL_DATE_FORMAT)
                      : ''}
                  </b>
                </p>
              </div>

              {/* Diagnosis */}
              {diagnosis?.length > 0 && (
                <div className={classes.sectionBoxTop}>
                  <p className={classes.sectionTitleLeft}>Diagnoses:</p>
                  <ul>
                    {diagnosis?.map((diag, index) => (
                      <li key={`diag-${index}`} className={classes.appointmentBox}>
                        <p className={classes.sectionSubTitleLeft}>{diag.elementName}</p>
                        <ul>
                          <li className={classes.question}>Importance level: {diag.importanceLevel?.name}</li>
                          <li className={classes.question}>ICD10 code: {diag.selectedIct10code}</li>
                          <li className={classes.question}>Report notes: {diag.notes}</li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Medications */}
              {medications?.length > 0 && (
                <div className={cx(classes.sectionBoxPageBreakBefore, classes.paddingTop10)}>
                  <p className={classes.sectionTitleLeft}>Medications:</p>
                  <ul>
                    {medications?.map((medic, index) => (
                      <li key={`medic-${index}`} className={classes.appointmentBox}>
                        <p className={classes.sectionSubTitleLeft}>{medic.elementName}</p>
                        <ul>
                          <li className={classes.question}>
                            Importance level: {medic.importanceLevel?.name}
                          </li>
                          <li className={classes.question}>Dosage: {medic.dosage}</li>
                          <li className={classes.question}>Frequency: {medic.frequency}</li>
                          <li className={classes.question}>
                            Prescribing Provider:{' '}
                            {medic.provider?.providerName ?? medic.provider?.confidantProviderName}
                          </li>
                          <li className={classes.question}>Report notes: {medic.notes}</li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Appointments */}
              {downloadAppointmentList?.length > 0 && (
                <div className={classes.sectionBox}>
                  <p className={classes.sectionTitleCenter}>Appointments</p>
                  {downloadAppointmentList?.map((appointmnt, index) => (
                    <div
                      key={`appointment-${index}`}
                      className={cx({
                        [classes.sectionBoxPageBreakAfter]: true,
                        [classes.paddingTop10]: index >= 1,
                      })}
                    >
                      <p className={classes.sectionSubTitleLeft}>{appointmnt.serviceName}</p>
                      <div>
                        <p className={classes.question}>Appointment Start Date and Time:</p>
                        <p className={classes.cptList}>
                          {dayjs(appointmnt.appointmentTime).format(`${GLOBAL_DATE_FORMAT} hh:mmA`)}
                        </p>
                        <p className={classes.question}>Clinician: {appointmnt.clinician}</p>
                        <p className={classes.question}>Signoff Status: {appointmnt.signOffStatus}</p>
                        {appointmnt?.signOffAt && (
                          <p className={classes.question}>
                            Signoff Date and Time:{' '}
                            {dayjs(appointmnt.signOffAt).format(`${GLOBAL_DATE_FORMAT} hh:mmA`)}
                          </p>
                        )}
                        <div className={classes.question}>
                          Codes billed:{' '}
                          {appointmnt.cptCodes?.cptList?.map((cpt, indx) => (
                            <div key={`cpt-${indx}`}>
                              <p className={classes.answer}>{cpt.cptCode}</p>
                              <div className={classes.codes}>
                                <ul>
                                  <li className={`${classes.question} ${classes.cptList}`}>
                                    Description: {cpt.diagnosis}
                                  </li>
                                  <li className={classes.question}>Related diagnosis: {cpt.icd10Code}</li>
                                </ul>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        {appointmnt.evaluations?.map((eva, ind) => (
                          <div key={`evaluation-${ind}`} className={classes.evaluationBox}>
                            <p className={classes.sectionSubTitleLeft}>{eva.name}</p>
                            <ul>
                              {eva.dctList?.map((dct, indx) => (
                                <li key={`dct-${indx}`} className={classes.dctBox}>
                                  <p className={classes.question}>
                                    {dct.dctName} {dct.dctScore ? `- Score: ${dct.dctScore}` : ''}
                                  </p>
                                  <div className={classes.qnaBox}>
                                    <ul>
                                      {dct.dctQuestionAnswer?.map((qna, i) => (
                                        <li key={`qna-${i}`}>
                                          <p className={classes.question}>
                                            {qna.question}{' '}
                                            <ul>
                                              {qna?.answer?.map((ans, indexNumber) => (
                                                <li key={`ans-${indexNumber}`} className={classes.answer}>
                                                  {ans}
                                                  {qna.providerNarrative && (
                                                    <ul>
                                                      <li className={classes.question}>
                                                        Provider Narative:
                                                        <ul>
                                                          <li className={classes.answer}>
                                                            {qna.providerNarrative}
                                                          </li>
                                                        </ul>
                                                      </li>
                                                    </ul>
                                                  )}
                                                </li>
                                              ))}
                                            </ul>
                                          </p>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* Additional Notes */}
              {notes?.length > 0 && (
                <div className={classes.sectionBox}>
                  <p className={classes.sectionTitleCenter}>Additional Notes</p>
                  {notes?.map((note, index) => (
                    <div
                      key={`note-${index}`}
                      className={cx({
                        [classes.sectionBoxPageBreakAfter]: true,
                        [classes.paddingTop10]: index >= 1,
                      })}
                    >
                      <p className={classes.sectionSubTitleLeft}>{note.name}</p>
                      <div>
                        <p className={classes.cptList}>Type: {note.type}</p>
                        <p className={classes.cptList}>Status: {note.status}</p>
                        <p className={classes.question}>Auther: {note.createdBy?.name ?? ''}</p>
                        <p className={classes.cptList}>
                          Updated on: {dayjs(note.lastModified).format(`${GLOBAL_DATE_FORMAT} hh:mmA`)}
                        </p>
                        <p className={classes.question}>Additional notes:</p>
                        <p className={classes.cptList}>{note.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>,
          );
          setIsExportDisabled(false);
        })
        .catch(err => {
          console.log({ err });
          setIsExportDisabled(true);
        });
    }
  };
  const onStartSession = (appointment: IAppointment) => {
    if (appointment.status === AppointmentStatus.BOOKED && !isAdmin) {
      history.push(`/provider/appointments/current/${appointment.appointmentId}/session`);
    }
    if (appointment.status === AppointmentStatus.NEEDS_ACTION) {
      dispatch(
        appointmentActionCreators.acceptAppointment({
          appointmentId: appointment.appointmentId,
          callback: (isSucceed: boolean) => {
            if (isSucceed) {
              // forceRefresh: true
              // fetch the latest record from the database
              // instead of querying an internal map
              fetchAppointments();
            }
          },
        }),
      );
      onCloseAppointmentList();
      onCloseDrawer();
    }
  };

  const multiSelectFilterOptions = useMemo(() => {
    if (searchParams?.multiSelectFilter && Object.keys(searchParams?.multiSelectFilter)?.length > 0) {
      setResetMultiSelectFilter(true);
    }
    setScreenChangeLoading(true);
    setTimeout(() => {
      setScreenChangeLoading(false);
    }, 100);
    switch (selectedScreen?.split('-')[1]) {
      case AppointmentType.CURRENT:
        return currentAppointmentsFilterOption;
      case AppointmentType.PAST:
        return pastAppointmentsFilterOption;
      default:
        return defaultAppointmentFilterOptions;
    }
  }, [selectedScreen]);

  const generateMultiselectParams = () => {
    const statuses = selectedScreen?.split('-')[1] === AppointmentType.CURRENT ? 'BOOKED' : '';
    const queryParams: ISearchAppointmentParams = {
      participantIds: memberId,
      type:
        selectedScreen?.split('-')[1] === AppointmentType.PAST
          ? 'PAST'
          : selectedScreen?.split('-')[1] === AppointmentType.CURRENT
          ? 'CURRENT'
          : 'PENDING',
      page: 0,
      size: 1000,
      orderBy: 'desc',
      sortBy: 'startTime',
      statuses,
      states: null,
      searchQuery: searchParams.searchKey || '',
    };

    if (selectedScreen?.split('-')[1] === AppointmentType.PAST) {
      queryParams.orderBy = 'desc';
    }
    const multiselectFilters = searchParams?.multiSelectFilter;
    if (multiselectFilters && Object.keys(multiselectFilters)?.length > 0) {
      if (multiselectFilters.State?.length > 0) {
        queryParams.states = multiselectFilters.State?.toString() ?? '';
      }
      if (multiselectFilters.Role?.length > 0) {
        queryParams.practitionerDesignations = multiselectFilters.Role?.toString() ?? '';
      }
      if (
        selectedScreen?.split('-')[1] === AppointmentType.CURRENT &&
        multiselectFilters.Status?.length > 0
      ) {
        if (multiselectFilters.Status[0] === 'Scheduled') {
          queryParams.dateStatus = 'NOT_TODAY';
        } else if (multiselectFilters.Status[0] === 'Today') {
          queryParams.dateStatus = 'TODAY';
        }
        queryParams.timeZone = getUserTimeZone();
      } else if (
        selectedScreen?.split('-')[1] === AppointmentType.PAST &&
        multiselectFilters.Status?.length > 0
      ) {
        queryParams.statuses = multiselectFilters.Status?.toString() ?? '';
      }
    }
    if (!queryParams.states) {
      delete queryParams.states;
    }

    return { queryParams };
  };

  const fetchAppointments = () => {
    const { queryParams } = generateMultiselectParams();
    setIsLoading(true);
    if (!providerSchedule && !isAdmin) {
      dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: memberId }));
    }
    getAppointments(queryParams)
      .then(res => {
        setAppointments(res.data.results.map(item => ({ ...item, participantName: item.participantName })));
        setIsLoading(false);
      })
      .catch(err => console.log({ err }));
  };

  const toggleAddSchedule = () => {
    if (selectedDateTime) {
      setSelectedDateTime(null);
    } else {
      setShowAddSchedule(!showAddSchedule);
    }
  };

  const fetchAppointments2 = (providerId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  // Please remove unused code, this function is called only in AppointmentList.tsx
  const onSubmitNewSchedule = (payload: INewSchedulePayload | INewRecurringAppointmentRequest) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: payload,
        callback: (isSucceed: boolean, isInstantSession: boolean, appointmentId: string) => {
          if (isSucceed) {
            toggleAddSchedule();
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  const onDateClick = (datetime: string) => {
    const format = 'YYYY-MM-DD';
    const selectedDate = dayjs(datetime).format(format);
    const now = dayjs().format(format);

    if (dayjs(selectedDate, format).unix() >= dayjs(now, format).unix()) {
      setSelectedDateTime(datetime);
    }
  };

  const onCloseDrawer = () => {
    setSelectedAppointment(null);
  };

  const toggleCancelDrawer = () => {
    setShowCancel(!showCancel);
  };

  const toggleRescheduleDrawer = () => {
    setShowReschedule(!showReschedule);
  };

  const onCloseAppointmentList = () => {
    setSelectedAppointmentList([]);
  };

  const onClickAppointmentOnCalendar = (items: IAppointment[]) => {
    if (items.length === 1) {
      setSelectedAppointment(items[0]);
    } else {
      setSelectedAppointmentList(items);
    }
  };

  const updateAppointment = (appointment: IAppointment) => {
    setSelectedAppointment(appointment);
    setAppointments(s =>
      s.map(item => (item.appointmentId === appointment.appointmentId ? appointment : item)),
    );
  };

  const onLoadMoreClick = () => {
    setIsLoadingMore(true);
    setPageNumber(pageNumber + 1);
    void fetchAppointments();
    setCurrentSize(currentSize + 50);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    dispatch(conversationActionCreators.fetchEvaluations());
    void fetchAppointments();

    setIsExportDisabled(true);
    appointmentsDownload(memberId, downloadDateRange.startDate, downloadDateRange.endDate)
      .then(res => {
        const downloadAppiontmentList = res.data?.data?.appointmentDetails?.map(appt => {
          return {
            ...appt,
            evaluations: appt.evaluations?.map(eva => {
              return {
                ...eva,
                dctList: [
                  ...eva.detail
                    ?.filter(item => item.question && item.answer)
                    ?.map(eveQnA => {
                      return {
                        ...eveQnA,
                        dctId: '',
                        dctName: '',
                        dctQuestionAnswer: [
                          {
                            ...eveQnA,
                            answer: eveQnA.answer?.userResponseList,
                          },
                        ],
                      };
                    }),
                  ...eva.detail
                    ?.filter(item => item.dctName)
                    ?.map(dctItem => {
                      return {
                        ...dctItem,
                        dctId: '',
                        dctQuestionAnswer: dctItem.questionAnswer
                          ?.filter(item => item.answer)
                          ?.map(item => {
                            return {
                              ...item,
                              providerNarrative: item.answer?.providerNarrative,
                              answer: item.answer?.userResponseList,
                            };
                          }),
                      };
                    }),
                ],
              };
            }),
          };
        });

        const downloadMedications = res.data?.data?.associatedTags?.medication?.map(med => {
          return {
            ...med,
            dose: med.tagMetaData?.rxDrugInfo?.dose,
            dosage: med.tagMetaData?.rxDrugInfo?.dosage,
            frequency: med.tagMetaData?.rxDrugInfo?.frequency,
            provider: {
              confidantProviderName: med.tagMetaData?.rxDrugInfo?.confidantProviderName,
              providerName: med.tagMetaData?.rxDrugInfo?.providerName,
              prescribingProvider: med.tagMetaData?.rxDrugInfo?.prescribingProvider,
            },
          };
        });
        const downloadDiagnosis = res.data?.data?.associatedTags?.diagnosis;
        memberService
          .getMemberNotes(
            { userId: memberId },
            { startDate: downloadDateRange.startDate, endDate: downloadDateRange.endDate },
          )
          .then(resp => {
            const formatedNotes =
              resp.data?.patientContactNotes?.length > 0 ? resp.data?.patientContactNotes : [];
            formatAppointmentsForDownload(
              downloadAppiontmentList,
              downloadMedications,
              downloadDiagnosis,
              formatedNotes,
            );
          })
          .catch(err => {
            formatAppointmentsForDownload(
              downloadAppiontmentList,
              downloadMedications,
              downloadDiagnosis,
              null,
            );
            console.warn(err);
          });
      })
      .catch(() => {
        setIsExportDisabled(true);
      });
  }, [selectedScreen, searchParams]);
  const resetOverflow = () => {
    const currentComponent = componentRef.current as any;
    currentComponent.style.overflow = 'scroll';
    setOpenTableToPrintModal(false);
  };
  return (
    <>
      <Modal open={openTableToPrintModal} onClose={closeTableToPrint}>
        <Box className={classes.modal}>
          <Box className={classes.modalContent}>
            <Box className={classes.content}>
              <Box className={classes.sectionAutoSize}>{formatedAppointmentForDownload}</Box>
              <Box display="flex" alignItems="center" gap={3} mt={3}>
                <Button
                  size={btnSize.SMALL}
                  onClick={e => {
                    handlePrint(e);
                    resetOverflow();
                  }}
                >
                  Export/Print
                </Button>
                <Button size={btnSize.SMALL} onClick={closeTableToPrint}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {(showAddSchedule || !!selectedDateTime) && (
        <AddSchedule
          isOpen={showAddSchedule || !!selectedDateTime}
          onClose={toggleAddSchedule}
          selectedDateTime={selectedDateTime}
          onSubmit={onSubmitNewSchedule}
          isSaving={isLoading}
          memberId={memberId}
          onClickReschedule={toggleAddSchedule}
        />
      )}
      {!isAdmin && (
        <AppointmentDetail
          open={!!selectedAppointment}
          onClose={onCloseDrawer}
          onCancelClick={toggleCancelDrawer}
          onRescheduleClick={toggleRescheduleDrawer}
          onAcceptAppointment={onStartSession}
          appointment={selectedAppointment}
          evaluations={evaluations}
        />
      )}
      {isAdmin && (
        <AppointmentDetailDrawer
          open={!!selectedAppointment}
          onClose={() => setSelectedAppointment(null)}
          appointment={selectedAppointment}
          evaluations={evaluations}
          fetchAppointments={() => fetchAppointments()}
        />
      )}
      <AppointmentScheduling
        open={showReschedule}
        onClose={toggleRescheduleDrawer}
        appointment={selectedAppointment}
        updateAppointment={setSelectedAppointment}
      />
      <AppointmentCancel
        open={showCancel}
        onClose={() => {
          toggleCancelDrawer();
          onCloseDrawer();
          void fetchAppointments();
        }}
        appointment={selectedAppointment}
        updateAppointment={updateAppointment}
      />
      <AppointmentsDrawer
        open={selectedAppointmentList?.length > 0}
        onClose={onCloseAppointmentList}
        appointments={selectedAppointmentList}
        onSelectAppointment={setSelectedAppointment}
      />
      <div className={classes.headingWithBtnWrapper}>
        <div className={classes.noteHeaderLeftSide}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            {AppointmentTitles[selectedScreen]}
          </Heading>
          <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.totalBadge}>
            {appointmentsCount || appointments?.length || 0} total
          </Badge>
        </div>
        <div className={classes.noteHeaderRightSide}>
          <CalendarGroupButton showCalendar={isShowCalendar} toggleCalendar={setIsShowCalendar} />
          {isAdmin && selectedScreen === 'appointments-past' && (
            <IconButton
              icon="export"
              variant={btnType.PRIMARY}
              className={classes.addBtn}
              onClick={openTableToPrint}
              disabled={isExportDisabled}
            >
              Export
            </IconButton>
          )}
        </div>
      </div>
      <div className={classes.noteCardWrapper} style={{ gap: 16 }}>
        {isLoading && (
          <Box display="flex" justifyContent="center" paddingY={2} gap={1} alignItems="center">
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} /> Loading...
          </Box>
        )}
        {isShowCalendar && providerSchedule ? (
          <Box width="100%">
            <Calendar
              appointments={appointments}
              providerSchedule={providerSchedule}
              onDateClick={onDateClick}
              onClickAppointment={onClickAppointmentOnCalendar}
              className={classes.calendarWrap}
            />
          </Box>
        ) : (
          <>
            {screenChangeLoading ? (
              <div className={classes.loader}>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                Loading...
              </div>
            ) : (
              <Box className={classes.searchHeader}>
                <TableSearch
                  placeholder="Search appointment by service name and provider"
                  resetMultiSelectFilter={resetMultiSelectFilter}
                  filterProps={{
                    variant: tableFilterType.MULTIPLE,
                    multiSelectOptions: multiSelectFilterOptions,
                  }}
                  value={searchParams}
                  onChange={setSearchParams}
                />
              </Box>
            )}
            {appointments?.length ? (
              appointments?.slice(0, currentSize).map((item, idx) => (
                <AppointmentCard
                  isAdmin={isAdmin}
                  key={idx}
                  appointment={{
                    ...item,
                    startTime: item?.startTime,
                  }}
                  isRecurring={item.recurringType}
                  onViewClick={
                    isAdmin
                      ? item?.signOffStatus === SignOffStatus.DRAFTED ||
                        item?.status === AppointmentStatus.CANCELLED
                        ? null
                        : onViewClick
                      : onViewClick
                  }
                  onStartSessionClick={onStartSession}
                  onGotoSignOff={appointment => {
                    if (appointment?.appointmentId)
                      history.push(
                        `/provider/appointments/current/${appointment.appointmentId}/session-signoff`,
                      );
                  }}
                  appointmentSection={
                    selectedScreen === 'appointments-past'
                      ? 'past'
                      : selectedScreen === 'appointments-pending'
                      ? 'pending'
                      : 'current'
                  }
                  showProvider
                />
              ))
            ) : (
              <Box width="100%" justifyContent="center" display="flex">
                <PriorityEmpty
                  title="There are no appointments."
                  description=""
                  glyph="lottie-current-and-past"
                  glyphOrientation="portrait"
                  className={classes.heightUnset}
                  isEmptyWithLottie
                  lottieSize={128}
                />
              </Box>
            )}
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.loadingWrap}>
              {isLoadingMore ? (
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              ) : (
                <>
                  {(appointments?.length > currentSize || appointmentsCount > currentSize) && (
                    <Button variant={btnType.TEXT} className={classes.loadMoreBtn} onClick={onLoadMoreClick}>
                      Load more
                    </Button>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </div>
    </>
  );
};

export default Appointments;
