import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { inputSize } from '@confidant-health/lib/ui/atoms/input';
import { MessageInputField } from '@confidant-health/lib/ui/templates/message-input-field';
import { ChangeEvent, FC, RefObject, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { BoundedChat } from 'redux/store/types';
import { markRecommendationUsed } from 'services/report-service/reportService.service';
import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { CONNECTIONS_TYPES } from 'constants/CommonConstants';

import { useStyles } from './MessageInput.styles';

type Props = {
  username?: string;
  onSubmit: (message: Record<string, any>, usedRecommendation?: string | null) => void;
  boundedChat?: BoundedChat;
  recommendationLoading?: boolean;
  showRecommendation?: boolean;
  currentUserId?: string;
  onRegenerateRecommendation?: () => void;
  openScheduleAppointmentDialog?: (params: any) => void;
  selectedContact?: any;
  selectedUser?: any;
  channelMembers?: any;
  setSelectedUser?: (user: any) => void;
  inputRef2?: RefObject<any>;
  recommendationError?: boolean;
};

const MessageInput: FC<Props> = ({
  username,
  onSubmit,
  boundedChat,
  showRecommendation,
  recommendationLoading,
  onRegenerateRecommendation,
  openScheduleAppointmentDialog,
  channelMembers,
  currentUserId,
  selectedContact,
  selectedUser,
  setSelectedUser,
  inputRef2,
  recommendationError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const fileRef = useRef<HTMLInputElement>(null);
  const [msg, setMsg] = useState('');
  const [acceptFile, setAcceptFile] = useState('');
  const [fileWithData, setFileWithData] = useState({ hasFile: false, file: File });
  const [usedRecommendation, setUsedRecommendation] = useState(null);
  const { providers } = useSelector(getProfile);
  const [userList, setUserList] = useState(providers);
  const [userListCopy, setUserListCopy] = useState(providers);
  const [showUserList, setShowUserList] = useState(false);
  const [userTagClicked, setUserTagClicked] = useState(false);
  const [cursorWordIndex, setCursorWordIndex] = useState(0);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const handleSubmit = async () => {
    if (msg === usedRecommendation) {
      const { id } = boundedChat;
      await markRecommendationUsed({ id });
    }
    onSubmit(!fileWithData.hasFile ? msg : (fileWithData as any), usedRecommendation);
    setFileWithData({ hasFile: false, file: null });
    setMsg('');
    setCursorWordIndex(0);
  };
  const fetchProviderDetail = async () => {
    // const searchword = mentionedUser?.replace('@', '');
    const queryParams = {
      searchQuery: '',
      pageNumber: 1,
      pageSize: 300,
      orderBy: '',
      sortBy: '',
      careNavigator: true,
    };
    await dispatch(profileActionCreators.fetchProviders(queryParams));
  };

  const findWordIndex = (sentence, cursorIndex) => {
    const words = sentence.split(' ');
    let charCount = 0;
    for (let i = 0; i < words.length; i++) {
      charCount += Number(words[i].length) + 1;
      if (charCount > cursorIndex) {
        return i;
      }
    }
    return words.length;
  };

  const onChangeMsg = (e: ChangeEvent<HTMLInputElement>) => {
    if (location.pathname === '/provider/chats') {
      const text = e.target.value;
      const cursorIndex = e.target.selectionStart;
      const wordIndex = findWordIndex(text, cursorIndex);
      const lastWord = text.split(' ');
      const usernames = text
        .split(' ')
        .filter(str => str.startsWith('@'))
        .map(str => str.replace('@', '').replaceAll('_', ' '));

      const newSelectedUser = selectedUser.filter(item => usernames.includes(item.fullName));
      setSelectedUser(newSelectedUser);
      setMsg(e.target.value);
      setCursorWordIndex(wordIndex);

      if (selectedContact?.type !== 'PATIENT') {
        if (lastWord[wordIndex].startsWith('@')) {
          setShowUserList(true);
          const searchTerm = lastWord[wordIndex].substring(1).toLowerCase();

          const searchedUserList = userListCopy.filter(
            user =>
              !selectedUser.some(taggedUser => taggedUser.id === user.id) &&
              user.fullName.toLowerCase().includes(searchTerm),
          );

          setUserList(searchedUserList);
        } else {
          setShowUserList(false);
          setUserList(userListCopy);
        }
      }
      if (msg.length < 2) {
        setSelectedUser([]);
      }
    } else {
      setMsg(e.target.value);
    }
  };

  const handleKeyDown = e => {
    if (userList.length === 0) return;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : userList.length - 1));
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prevIndex => (prevIndex < userList.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const newText = msg.split(' ');
      if (newText[cursorWordIndex].startsWith('@')) {
        const userSelected = userList[focusedIndex];
        const selectedPIDs = selectedUser?.map(provider => provider.providerId);
        if (userSelected === undefined) {
          void handleSubmit();
          setShowUserList(false);
          setFocusedIndex(-1);
        } else if (!selectedPIDs.includes(userSelected?.providerId)) {
          if (!userTagClicked) {
            newText[cursorWordIndex] = `@${userSelected?.fullName.replaceAll(' ', '_')} `;
            const modifiedText = newText.join(' ');
            setMsg(modifiedText);
            setSelectedUser([...selectedUser, userSelected]);
          } else {
            void handleSubmit();
            setShowUserList(false);
            setFocusedIndex(-1);
          }
          setUserTagClicked(false);
          setShowUserList(false);
          setFocusedIndex(-1);
          inputRef2.current.focus();
        } else if (e.key === 'Enter' && !e.shiftKey) {
          void handleSubmit();
          setShowUserList(false);
          setFocusedIndex(-1);
        }
      } else if (e.key === 'Enter' && !e.shiftKey) {
        void handleSubmit();
        setShowUserList(false);
        setFocusedIndex(-1);
      }
    }
  };
  const onOpenFile = (fileType: string) => () => {
    setAcceptFile(fileType);
    setTimeout(() => {
      fileRef.current?.click();
    }, 100);
  };

  const populateCurrentSuggestion = suggestion => {
    setMsg(suggestion);
    setUsedRecommendation(suggestion);
  };

  useEffect(() => {
    if (fileWithData.hasFile) setMsg(`FILE SELECTED: "${fileWithData.file.name}"`);
  }, [fileWithData]);

  useEffect(() => {
    const { recommendations } = boundedChat || {};
    if (recommendations?.length > 0 && recommendations[0] !== usedRecommendation) {
      setUsedRecommendation(null);
    }
  }, [boundedChat?.recommendations, usedRecommendation]);

  const onChangeFile = (e: ChangeEvent<any>) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setFileWithData({ hasFile: true, file });
    // reset the value
    e.target.value = '';
  };
  const onAddMentionClick = () => {
    const newText = msg.split(' ');
    if (newText[cursorWordIndex] === '') {
      newText[cursorWordIndex] = '@';
      const modifiedText = newText.join(' ');
      setMsg(modifiedText);
      setShowUserList(true);
    } else if (newText[cursorWordIndex] !== '') {
      newText[cursorWordIndex] = `${newText[cursorWordIndex]} @`;
      const modifiedText = newText.join(' ');
      setCursorWordIndex(cursorWordIndex + 1);
      setMsg(modifiedText);
      setShowUserList(true);
    }
    // inputRef2.current.focus();
  };

  const renderMenuItem = (lable, icon, fileType) => (
    <div className={classes.dropdownItem} onClick={onOpenFile(fileType)}>
      <Icons color={colors.primary500} glyph={icon} />
      {lable}
    </div>
  );
  const renderMenuItem2 = (lable, icon) => (
    <div className={classes.dropdownItem} onClick={onAddMentionClick}>
      <Icons color={colors.primary500} glyph={icon} />
      {lable}
    </div>
  );
  const handleUserSelect = user => {
    const newText = msg.split(' ');
    // const filteredProvider = userList.filter(u => u.id !== user.providerId);
    // setUserList(filteredProvider);
    const selectedPIDs = selectedUser?.map(provider => provider.providerId);
    if (!selectedPIDs.includes(user.providerId)) {
      newText[cursorWordIndex] = `@${user.fullName.replaceAll(' ', '_')} `;
      const modifiedText = newText.join(' ');
      setMsg(modifiedText);
      /* const usernames = modifiedText
        .split(' ')
        .filter(str => str.startsWith('@'))
        .map(str => str.replace('@', '').replaceAll('_', ' '));
      const newSelectedUser = [...selectedUser, user].filter(item => usernames.includes(item.fullName)); */
      setSelectedUser([...selectedUser, user]);
      setUserTagClicked(true);
      setShowUserList(false);
      inputRef2.current.focus();
    }
  };

  const freshRecommendationActive = boundedChat?.recommendations?.length > 0 && !usedRecommendation;

  useEffect(() => {
    if (username) {
      onRegenerateRecommendation();
    }
  }, []);

  useEffect(() => {
    void fetchProviderDetail();
  }, []);

  useEffect(() => {
    if (channelMembers?.length !== 0) {
      const uniqueProviders = channelMembers?.filter(item => item.userId !== currentUserId);
      const filteredProviders = providers.filter(member2 => {
        return uniqueProviders?.some(member1 => member1.userId === member2.providerId);
      });
      setUserList(filteredProviders);
      setUserListCopy(filteredProviders);

      // setUserList(uniqueProviders);
      // setUserListCopy(uniqueProviders);
    }
  }, [channelMembers, providers]);

  useEffect(() => {
    if (userListCopy && !showUserList) {
      const selectedUserIds = selectedUser?.map(item => item?.providerId);
      const filteredProviders = userListCopy?.filter(item => !selectedUserIds?.includes(item.id));
      setUserList(filteredProviders);
    }
  }, [selectedUser, userListCopy, showUserList]);

  return (
    <>
      <input
        style={{ display: 'none' }}
        ref={fileRef}
        type="file"
        accept={acceptFile}
        onChange={onChangeFile}
      />
      {showRecommendation && (
        <div className={recommendationLoading ? classes.alfieChat2 : classes.alfieChat}>
          {recommendationLoading ? (
            <>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </>
          ) : (
            <Box className={classes.avatar}>
              <Box display="flex" gap={1.5}>
                <Icons className={classes.logo} glyph="story-icon-logo" />
                <Box className={classes.chatbotContainer}>
                  <Text weight={fontWeight.BOLD} className={classes.chatbotContainerHeading}>
                    {recommendationError
                      ? 'Chat recommendation is booting back up… Give us a few minutes'
                      : freshRecommendationActive
                      ? 'Alfie Proposed'
                      : 'How can I help next?'}
                  </Text>
                  {freshRecommendationActive && (
                    <Text className={classes.chatbotContainerText}>{boundedChat?.recommendations[0]}</Text>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {!recommendationLoading && (
            <Box className={classes.btnContainer}>
              {(freshRecommendationActive || recommendationError) && (
                <Button
                  variant={btnType.OUTLINE}
                  className={classes.outline}
                  onClick={onRegenerateRecommendation}
                >
                  Regenerate
                </Button>
              )}
              {(boundedChat?.recommendations.length === 0 || !!usedRecommendation) && (
                <Button
                  variant={btnType.TEXT}
                  onClick={onRegenerateRecommendation}
                  className={classes.linkBtn}
                >
                  <Icons glyph="plus" color="currentColor" className={classes.linkIcon} />
                  Generate Another
                </Button>
              )}
              {boundedChat?.action &&
                boundedChat?.action.type === 'REQUEST_APPOINTMENT' &&
                usedRecommendation && (
                  <Button
                    variant={btnType.TEXT}
                    onClick={() => {
                      openScheduleAppointmentDialog(boundedChat.action);
                    }}
                    className={classes.linkBtn}
                  >
                    <Icons glyph="schedule" color="currentColor" className={classes.linkIcon} />
                    Schedule Appointment
                  </Button>
                )}
              {freshRecommendationActive && (
                <Button
                  variant={btnType.PRIMARY}
                  className={classes.primary}
                  onClick={() => populateCurrentSuggestion(boundedChat?.recommendations[0])}
                >
                  Use response
                </Button>
              )}
            </Box>
          )}
        </div>
      )}
      {showUserList && (
        <>
          {userList.length > 0 && (
            <>
              <ul
                className={clsx(
                  classes.mentionUserList,
                  userList.length === 1
                    ? classes.oneUser
                    : userList.length === 2
                    ? classes.twoUser
                    : userList.length === 3
                    ? classes.threeUser
                    : classes.allUser,
                )}
              >
                {userList.map((user, index) => (
                  <li
                    key={user.id}
                    className={clsx(
                      classes.mentionUserListData,
                      index === focusedIndex ? classes.focused : '',
                    )}
                    onMouseEnter={() => setFocusedIndex(index)}
                    onMouseLeave={() => setFocusedIndex(-1)}
                  >
                    <a onClick={() => handleUserSelect(user)}>
                      <div className={classes.member}>
                        <Avatar
                          size={40}
                          variant={avatarType.CIRCLE}
                          src={user.profileImage}
                          name={user.fullName}
                        />
                        <div className={classes.info}>
                          <div className={classes.userName}>{user.fullName}</div>
                          <Badge
                            className={clsx(classes.badge, user.designation?.split(' ').join(''))}
                            variant={badgeType.FILLED}
                          >
                            {user.designation ?? 'Care Navigator'}
                          </Badge>
                        </div>
                        {/* <ProfileInfo
                          fullName={user.fullName}
                          src={user.profileImage}
                          role={user.designation as ProfileInfoRole}
                        /> */}
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      )}
      <MessageInputField
        dropdownItems={
          selectedContact?.type !== CONNECTIONS_TYPES.PATIENT &&
          selectedContact?.type !== CONNECTIONS_TYPES.CARE_NAVIGATOR &&
          location.pathname === '/provider/chats'
            ? [
                // { label: renderMenuItem('Add File', 'add-file-outlined', '*/*') },
                { label: renderMenuItem('Add Image', 'add-image-outlined', 'image/*') },
                { label: renderMenuItem('Add Video', 'play-outlined', 'video/*') },
                { label: renderMenuItem2('Add mention', 'addMention') },
              ]
            : [
                // { label: renderMenuItem('Add File', 'add-file-outlined', '*/*') },
                { label: renderMenuItem('Add Image', 'add-image-outlined', 'image/*') },
                { label: renderMenuItem('Add Video', 'play-outlined', 'video/*') },
              ]
        }
        size={inputSize.M}
        placeholder={
          location.pathname === '/provider/chats' ||
          location.pathname.includes('/provider/chats/chats-with-members/') ||
          location.pathname.includes('/provider/chats/chats-with-providers/') ||
          location.pathname.includes('/provider/chats/chats-with-groups/')
            ? `Message ${username}`
            : 'Type your message here...'
        }
        username={username}
        onSubmit={handleSubmit}
        value={msg}
        onChange={onChangeMsg}
        onKeyDown={handleKeyDown}
        inputRef={inputRef2}
      />
    </>
  );
};

export { MessageInput };
