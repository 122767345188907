import { FC, useEffect, useState } from 'react';
import { Stack, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { BaseLayout } from 'layouts/base';
import { getProfile } from 'redux/modules/profile/selectors';
import { selectProviderServicesState } from 'redux/modules/appointment/selectors';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { scheduleActionCreators } from 'redux/modules/schedule/actions';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { stateActionCreators } from 'redux/modules/state';

import GeneralAvailability from './components/general-availability';
import EditServices from './components/services';
import ProfileCard from './components/profile-card';
import RightSidebar from './components/right-sidebar';

import { profileSections, ProfileSectionIds, rightSidebarNavItems } from './Profile.constants';
import { useStyles } from './Profile.styles';
import { QuestionsDrawer } from './components/questions-drawer/QuestionsDrawer';

const Profile: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { services } = useSelector(selectProviderServicesState);
  const providerSchedule = useSelector(selectProviderSchedule);
  const { profile } = useSelector(getProfile);
  const [editSection, setEditSection] = useState<ProfileSectionIds | null>(null);
  const [sections, setSections] = useState([]);

  const onEdit = (sectionId: ProfileSectionIds) =>
    sectionId === ProfileSectionIds.generalAvailability || sectionId === ProfileSectionIds.services
      ? () => {
          setEditSection(sectionId);
        }
      : null;

  const onCloseEdit = () => {
    setEditSection(null);
  };

  const getContent = (id: string) => {
    switch (id) {
      case ProfileSectionIds.general: {
        return {
          description: profile?.bio,
          profile: {
            avatar: profile?.profileImage,
            name: profile?.fullName,
            role: profile?.designation,
            activeInApp: profile?.active,
            specialities: profile?.speciality,
            npi: profile?.npi,
          },
        };
      }
      case ProfileSectionIds.approach: {
        return {
          description: profile?.approach || 'N/A',
        };
      }
      case ProfileSectionIds.availableStates: {
        return {
          badges: profile?.operatingStates || [],
        };
      }
      case ProfileSectionIds.certifications: {
        return {
          badges: profile.providerProfile?.certifications || [],
        };
      }
      case ProfileSectionIds.credentials: {
        return {
          badges: profile.providerProfile?.credentials || [],
        };
      }
      case ProfileSectionIds.clinicAffiliation: {
        return {
          affiliation: {
            avatar: profile.providerProfile?.affiliationImage,
            title: profile.providerProfile?.affiliationName?.[0],
            description: profile.providerProfile?.affiliationPlace?.[0],
            startDate: undefined,
            endDate: undefined,
          },
        };
      }
      case ProfileSectionIds.pastEmployment: {
        return {
          affiliation: {
            avatar: profile?.providerProfile?.employmentImage,
            title: profile?.providerProfile?.employmentName?.[0],
            description: profile?.providerProfile?.employmentPlace?.[0],
            startDate: profile?.providerProfile?.employmentStartDate,
            endDate: profile?.providerProfile?.employmentEndDate,
          },
        };
      }
      case ProfileSectionIds.education: {
        return {
          affiliation: {
            avatar: profile?.providerProfile?.educationImage,
            title: profile?.providerProfile?.educationName?.[0],
            description: profile?.providerProfile?.educationDescription,
            startDate: profile?.providerProfile?.educationStartDate,
            endDate: profile?.providerProfile?.educationEndDate,
          },
        };
      }
      case ProfileSectionIds.generalAvailability: {
        const filteredSchedule = providerSchedule?.availability.filter(item => item.active === true);
        return {
          workingDays: filteredSchedule?.reduce((prev, item) => {
            return {
              ...prev,
              [item.day.toLowerCase()]: prev[item.day.toLowerCase()]
                ? [...prev[item.day.toLowerCase()], ...item.slots]
                : item.slots,
            };
          }, {}),
        };
      }
      default:
        // services
        return { services };
    }
  };

  useEffect(() => {
    const newSections = profileSections?.map(item => ({
      ...item,
      content: getContent(item.id),
    }));
    setSections(newSections);
  }, [profile, services, providerSchedule]);

  useEffect(() => {
    dispatch(appointmentActionCreators.fetchProviderServices(profile.providerId));
    dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: profile?.providerId }));
    dispatch(stateActionCreators.fetchCPTs());
  }, []);

  const editContent = getContent(editSection);

  return (
    <BaseLayout
      customRightSideBar={<RightSidebar title="My profile" navItems={rightSidebarNavItems} />}
      rightSideBarWidth="280px"
    >
      <div className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              My profile
            </Heading>
          </Stack>
        </Stack>

        <Box display="flex" flexDirection="column" gap="16px">
          {sections.map((section, idx) => (
            <ProfileCard
              {...section}
              key={idx}
              onEdit={section.id !== ProfileSectionIds.services ? onEdit(section.id) : null}
            />
          ))}
        </Box>
        <QuestionsDrawer />
      </div>
      <GeneralAvailability
        isOpen={editSection === ProfileSectionIds.generalAvailability}
        providerId={profile.providerId}
        onClose={onCloseEdit}
      />
      <EditServices
        isOpen={editSection === ProfileSectionIds.services}
        onClose={onCloseEdit}
        providerId={profile.providerId}
        services={editContent.services || []}
      />
    </BaseLayout>
  );
};

export { Profile };
