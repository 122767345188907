import { ChangeEvent, FC, Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';

import { fontWeight, Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';

import { useStyles } from './Notes.styles';

type Props = {
  onClose: () => void;
  notes: string;
  setNotes: Dispatch<SetStateAction<string>>;
};
const Notes: FC<Props> = ({ onClose, notes, setNotes }) => {
  const classes = useStyles();

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.headerText}>
          <Heading weight={fontWeight.BOLD} className={classes.title}>
            Scratchpad
          </Heading>
          <Text className={classes.subtitle} weight={fontWeight.MEDIUM}>
            Notes here are only for you and only for the current session.
          </Text>
        </Box>
        <IconButton icon="close" onClick={onClose} className={classes.closeBtn} />
      </Box>

      <Box className={classes.body}>
        <Textarea
          value={notes}
          onChange={onChangeText}
          placeholder="Enter your notes"
          className={classes.textarea}
        />
      </Box>
      <Box className={classes.footer}>
        <Box />
        <IconButton onClick={onClose} variant={iconBtnType.PRIMARY} className={classes.doneBtn}>
          Done
        </IconButton>
      </Box>
    </Box>
  );
};

export { Notes };
