import { createStore, applyMiddleware, compose, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { compact } from 'lodash';
import { persistStore, Persistor } from 'redux-persist';

import rootReducer from './reducers';
import sagas from './sagas';

// define type of window
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
  }
}

type IStore = {
  persistor: Persistor;
  store: Store;
};

export default function initializeStore(): IStore {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = compact([
    sagaMiddleware,
    createLogger({
      collapsed: (getState, action, logEntry) => !logEntry.error,
    }),
  ]);

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(sagas, store);

  const persistor = persistStore(store, null, () => {
    store.getState();
  });

  return { store, persistor };
}
