// colors
import { colors } from '@confidant-health/lib/colors';
import { theme } from '@confidant-health/lib/theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px !important',
    minHeight: '80px !important',
    background: colors.white,
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
  },
  backBtn: {
    position: 'absolute',
    left: 32,
    border: `1px solid ${colors.primary300}`,
    color: colors.primary300,
    width: 48,
    height: 48,
    padding: 0,
    minWidth: 0,
    marginRight: 16,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '64px',
    flexGrow: 1,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 40,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  reviewEvaluationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '64px',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 40,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  answersBox: {
    flex: 1,
    padding: '16px 0px',
    '& .MuiFormGroup-root': {
      gap: 'unset',
      '& label': {
        padding: '8px 0px',
      },
    },
  },
  choicesBox: {
    padding: '16px 0px',
  },
  reviewEvaluationBox: {
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
    border: '1px solid #E5EBF0',
    borderRadius: '12px',
    width: '800px',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    marginBottom: '64px',
  },
  reviewEvaluationTitle: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-0.56px',
  },
  reviewQuestionBox: {
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    padding: '24px 0px',
  },
  reviewQuestionBoxWithReason: {
    transition: 'all 0.3s ease',
    paddingBottom: '16px',
  },
  reviewQuestion: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  reviewAnswer: {
    color: colors.neutral700,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
  },
  toggleReasonIcon: {
    cursor: 'pointer',
    width: '20px !important',
    height: '20px !important',
    minWidth: '20px !important',
    minHeight: '20px !important',
    marginLeft: '4px',
    verticalAlign: 'middle',
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: colors.neutral200,
  },
  reasonIcon: {
    width: '24px !important',
    height: '24px !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
  },
  editAnswerBtn: {
    border: `1px solid ${colors.primary300}`,
    borderRadius: '4px',
    width: '27px !important',
    height: '24px !important',
    color: colors.primary600,
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 700,
    padding: '8px 16px',
    minWidth: '27px !important',
    boxSizing: 'content-box',
  },
  headTitle: {
    margin: 0,
    lineHeight: '32px',
    color: colors.neutral900,
    fontSize: '18px',
  },
  headSubTitle: {
    marginTop: 4,
    lineHeight: '20px',
    fontSize: '14px',
    color: colors.neutral600,
    display: 'inline-block',
  },
  helperText: {
    fontFamily: 'Manrope',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: '20px',
  },
  editDrawer: {
    '& .drawerHeader svg': {
      minWidth: '24px !important',
      minHeight: '24px !important',
    },
    '& .drawerHeader h3>div': {
      fontSize: '18px',
      paddingRight: '8px',
    },
    '& .drawerBody': {
      padding: '0px 24px !important',
    },
  },
  reasonBox: {
    marginBottom: '24px',
    borderRadius: '8px',
    border: '1px solid #FCD34D',
    backgroundColor: colors.warning50,
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
  },
  multiSelectOptionRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
    gap: '12px',
    cursor: 'pointer',
  },
  formLabel: {
    '& .MuiTypography-body1': {
      fontSize: 16,
    },
  },
  optionText: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  contentWrapper: {
    width: 800,
  },
  contentHeader: { display: 'flex', gap: 19, alignItems: 'center' },
  contentHeaderTitle: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-0.01em',
    color: colors.neutral900,
  },
  addProcedureCodeBtn: {
    alignSelf: 'flex-start',
    '& svg': {
      marginRight: '8px',
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  content: {
    padding: '40px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 40,
    gap: 24,
    background: colors.white,
    border: '1px solid #E5EBF0',
    borderRadius: 8,
    '& .title': {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '24px',
      color: colors.neutral900,
    },
    '& .content': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.neutral600,
    },
  },
  evaluation: {
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    border: '1px solid #E5EBF0',
    borderRadius: 8,
    padding: '16px 16px 16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  evaluationContent: {
    display: 'flex',
    flexDirection: 'column',
    '& .title': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
    },
    '& .subTitle': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  progressBar: {
    width: 174,
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.secondary500,
    },
  },
  badge: {
    height: 'auto',
    padding: '0px 8px',
    textWrap: 'nowrap',
    '& .rotate': {
      padding: '2px',
    },
  },
  badgeColor: {
    color: colors.secondary500,
    borderColor: colors.secondary300,
  },
  badgeLight: {
    fontWeight: 500,
    minWidth: '107px',
  },
  reviewBadge: {
    height: '24px !important',
    padding: '2px 10px',
  },
  badgeComplete: {
    padding: '4px 8px',
    backgroundColor: colors.success500,
    border: `1px solid ${colors.success500}`,
  },
  menu: {
    height: 'auto',
    padding: 0,
    '& svg': {
      width: 18,
    },
  },
  menuItemsWrapper: {
    '& ul li': {
      color: colors.primary600,
      '& svg': {
        margin: 8,
      },
      '& svg path': {
        fill: colors.destructive600,
      },
    },
  },
  question: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '28px',
    marginBottom: 16,
  },
  description: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
  },
  appointmentCard: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #E5EBF0',
    filter:
      'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))',
    borderRadius: 8,
  },
  appointmentCardHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    gap: 4,
    background: colors.white,
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
    borderRadius: '8px 8px 0px 0px',
    '& .title': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.neutral900,
    },
    '& .subTitle': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: colors.neutral600,
    },
  },
  appointmentDetails: {
    display: 'flex',
    gap: 32,
    alignItems: 'center',
    background: colors.white,
    borderRadius: '0px 0px 8px 8px',
    padding: 16,
    '& .wrapper': {
      display: 'flex',
      gap: 8,
      '& svg': { width: 13.5 },
      '& .text': { fontWeight: 600, fontSize: 14, lineHeight: '20px', color: colors.neutral700 },
    },
  },
  avatar: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    '& .name': { fontWeight: 600, fontSize: 14, lineHeight: '20px', color: colors.neutral700 },
  },
  stepper: {
    '& .MuiStepIcon-root.Mui-active': {
      color: colors.white,
      border: `2px solid ${colors.secondary500}`,
      borderRadius: '100px',
      boxShadow: '0px 0px 1px 4px rgba(255, 227, 241, 1)',
      '& .MuiStepIcon-text': {
        color: colors.secondary500,
      },
    },
    '& .MuiStepIcon-root': {
      color: '#E5EBF0',
    },
    '& .MuiStepIcon-text': {
      color: colors.neutral600,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      fill: 'unset',
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: colors.secondary500,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  activeStep: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.secondary500,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
  },
  headerText: {
    color: colors.neutral900,
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '24px',
  },
  footer: {
    padding: 24,
    background: colors.white,
    borderTop: '1px solid #E5EBF0',
    boxShadow:
      '0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  nextBtn: {
    backgroundColor: colors.primary500,
    color: colors.white,
    '& svg': {
      width: 6,
    },
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    padding: '12px 20px',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: colors.primary500,
    },
    '&:disabled': {
      backgroundColor: colors.primary50,
      color: colors.primary300,
    },
  },
  previousBtn: {
    '& svg': {
      width: 6,
      marginRight: 16,
    },
  },
  single: {
    flexDirection: 'row-reverse',
  },
  drawer: {
    '& .drawerBody': {
      backgroundColor: colors.neutral50,
    },
  },
  bodyWhite: {
    '& .drawerBody': {
      backgroundColor: colors.white,
      height: 'calc(100vh - 81px)',
    },
  },
  signOffCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    gap: 87,
    background: colors.white,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    cursor: 'pointer',
  },
  signOffContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    '& .icon': {
      width: 48,
      height: 48,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: colors.primary50,
      borderRadius: 6,
    },
    '& .title': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      color: colors.neutral900,
    },
    '& .added': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: colors.neutral600,
    },
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& > label': {
      lineHeight: '20px',
      height: 20,
    },
    '& .label': {
      lineHeight: '20px',
      height: 20,
    },
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 16px',
    gap: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    margin: 0,
  },
  modalBackBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    padding: '18px 12px',
    borderColor: colors.primary300,
    '& svg': {
      color: colors.primary500,
      width: 18,
      height: 15,
    },
  },
  reviewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
    flex: 1,
    maxHeight: 79,
    // marginLeft: '78px',
  },
  reviewHeaderMargin: {
    marginLeft: '78px',
  },
  reviewHeaderBottom: {
    borderBottom: '1px solid #D4DDE5',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  gap24: {
    gap: 24,
  },
  gap12: {
    gap: 12,
  },
  gap4: {
    gap: 4,
  },
  flexColumn: {
    flexDirection: 'column',
  },
  font22: {
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 600,
  },
  font16: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  font18: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  font12: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  font14: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  badge2: {
    height: 18,
    borderRadius: 100,
    padding: '0px 8px',
  },
  btn: {
    '&.Mui-disabled': {
      color: colors.primary300,
      backgroundColor: colors.primary50,
    },
  },
  activeBtn: {
    background: colors.white,
    border: `1px solid ${colors.primary300}`,
    color: colors.primary600,
    '&:hover': {
      color: colors.white,
      opacity: 1,
    },
  },

  tabContainer: {
    padding: 16,
    borderBottom: `1px solid ${colors.neutral200}`,
  },
  evaluationTabs: {
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
      margin: 0,
      minHeight: 44,
      padding: '12px 16px',
      height: 44,
      textTransform: 'capitalize',
      color: colors.neutral700,
      '&.Mui-selected': {
        color: colors.neutral900,
        border: `1px solid ${colors.neutral200}`,
        borderRadius: 8,
        '& div': {
          background: colors.neutral700,
          color: colors.white,
          border: 'none',
        },
      },
    },
  },
  tabBadge: {
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 100,
    color: colors.neutral700,
    background: colors.white,
    height: 20,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  outerCol: {
    borderRight: `1px solid ${colors.neutral100}`,
  },
  requiredBadge: {
    background: colors.white,
    border: `1px solid ${colors.warning300}`,
    color: colors.warning600,
  },
  collapsable: {
    '& .collapsableHeader': {
      justifyContent: 'flex-start',
    },
    '& .collapsableIcon': {
      marginLeft: 0,
      '& path': {
        fill: colors.neutral900,
      },
    },
  },
  collapsableWrapper: {
    padding: 12,
    position: 'relative',
    '& .collapsableInfoCards': {
      padding: '24px 28px',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    '& .collapsableCardScoreText': {
      lineHeight: '20px',
      position: 'absolute',
      right: 24,
      top: 12,
      fontWeight: 700,
      fontSize: 14,
    },
    '& .collapsableInfoCardWrapper': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.white,
      padding: 24,
      border: '1px solid #e5ebf0',
      borderRadius: 8,
    },
    '& .collapsableInfoCardTitle': {
      color: colors.neutral900,
      lineHeight: '24px',
    },
    '& .collapsableInfoCardSubTitle': {
      color: colors.neutral700,
      lineHeight: '24px',
    },
  },
  backBtnHeader: {
    width: 40,
    minWidth: 40,
    height: 40,
    border: `1px solid ${colors.primary300}`,
    color: colors.primary500,
    borderRadius: 4,
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    position: 'relative',
    width: 480,
  },
  modalContent: {
    background: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    '&:hover $cardLink': {
      display: 'flex',
    },
    padding: '40px',
  },
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  modalTitle: {
    fontSize: '28px',
    color: colors.neutral900,
    fontWeight: 700,
    lineHeight: '36px',
    margin: '0px 0px 8px 0px',
  },
  modalButtons: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 16,
    alignSelf: 'stretch',
    marginTop: 40,
  },
  modalCloseButton: {
    flex: 1,
    padding: '12px 20px',
    color: colors.neutral800,
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral300}`,
    height: 'unset',
    '&:hover': {
      backgroundColor: colors.neutral100,
    },
  },
  modalButton: {
    flex: 1,
    padding: '12px 20px',
    height: 'unset',
  },
  toggle: {
    fontWeight: 500,
    fontFamily: 'Manrope',
    fontSize: 14,
    lineHeight: '20px',
  },
  noteCard: {
    cursor: 'auto',
  },
});
