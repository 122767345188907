import clsx from 'clsx';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { colors } from '@confidant-health/lib/colors';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { ICandidClaim } from 'redux/modules/reporting/types';
import { sentenceCase } from 'sentence-case';
import { useStyles } from '../ClaimDetail.styles';

type Props = {
  data?: ICandidClaim;
  sectionNumber?: number;
};

const defaultData = {
  candidId: '-',
  claimId: '-',
  claimStatus: '-',
  amountAllowed: 0,
  amountPaid: 0,
  toBePaidByPatient: 0,
  procedureModifiers: '-',
  carcs: null,
  rarcs: null,
  denialReasons: null,
  rejectionReason: null,
  encounterExternalId: '-',
  chequeDate: '-',
  chequeAmountCents: 0,
  chequeNumber: '-',
  chequePaidDollarAmount: 0,
  chequePatientDollars: 0,
  chequePayerClaimNumber: '-',
  amountReceivedToConfidant: false,
  postingDate: '-',
};

const CandidDetail = ({ data = defaultData, sectionNumber = 1 }: Props) => {
  const classes = useStyles();

  const Col = ({ label, value, colType = false }) => (
    <Box display="flex" flexDirection="column">
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <>
        {colType ? (
          <Badge
            variant={badgeType.OUTLINED}
            style={
              value === 'PAID' ||
              value === 'PARTIALLY_PAID' ||
              value === 'OVERPAID' ||
              value === 'UNDERPAID' ||
              value === 'APPEALED'
                ? badgeStyle.RESOLVED
                : value === 'CREATED' || value === 'COMPLETED'
                ? badgeStyle.PRIMARY
                : value === 'DENIED' || value === 'REJECTED'
                ? badgeStyle.HIGH
                : badgeStyle.MEDIUM
            }
            className={clsx(
              classes.badge,
              { [classes.unPaid]: value === 'DENIED' || value === 'REJECTED' },
              {
                [classes.submittedStatus]: value === 'CREATED' || value === 'COMPLETED',
              },
              {
                [classes.paid]:
                  value === 'PAID' ||
                  value === 'PARTIALLY_PAID' ||
                  value === 'OVERPAID' ||
                  value === 'UNDERPAID' ||
                  value === 'APPEALED',
              },
              classes.marginTop8,
            )}
          >
            {sentenceCase(value || '')}
          </Badge>
        ) : (
          <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
            {value || 'N/A'}
          </Text>
        )}
      </>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          Candid claim{' '}
          {sectionNumber === 1 ? 'summary' : sectionNumber === 2 ? 'cheque details' : 'amount details'}
        </Heading>
        {/* <Button
          className={classes.btn}
          variant={btnType.TEXT}
          onClick={() => setOpen(true)}
          size={btnSize.SMALL}
        >
          Edit info
        </Button> */}
      </Box>
      <Divider sx={{ marginX: -3 }} />

      <Box display="flex" mt={4} gap={4} justifyContent="space-between" flexDirection="column">
        {sectionNumber === 1 && (
          <Box>
            <Box display="flex" justifyContent="space-between" flexDirection="row">
              <Col label="Candid status" value={data?.claimStatus} colType />
              <Col label="Encounter id" value={data?.candidId ?? '-'} />
              <Col label="Claim id" value={data?.claimId ?? '-'} />
            </Box>
            <Box display="flex" justifyContent="flex-start" flexDirection="row" marginTop={1} gap="20%">
              <Col label="Latest payer claim number" value={data?.latestPayerClaimNumber ?? '-'} />
              {data?.deniedClaimDenialReason && (
                <Col label="Denial reason (denied claim)" value={data?.deniedClaimDenialReason ?? '-'} />
              )}
            </Box>
          </Box>
        )}
        {sectionNumber === 2 && (
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <Col label="Cheque amount - cents" value={`${data?.chequeAmountCents ?? '-'}`} />
            <Col
              label="Cheque date"
              value={
                data?.chequeDate && data?.chequeDate !== '-'
                  ? format(new Date(data?.chequeDate), 'MM.dd.yyyy')
                  : '-'
              }
            />
            <Col label="Cheque number" value={data?.chequeNumber} />
            <Col label="Cheque paid amount" value={`$${data?.chequePaidDollarAmount?.toFixed(2) ?? '-'}`} />
            <Col label="Patient amount" value={`$${data?.chequePatientDollars?.toFixed(2) ?? '-'}`} />
            <Col label="Cheque payer claim" value={data?.chequePayerClaimNumber ?? '-'} />
            <Col
              label="Posting date"
              value={
                data?.postingDate && data?.postingDate !== '-'
                  ? format(new Date(data?.postingDate), 'MM.dd.yyyy')
                  : '-'
              }
            />
          </Box>
        )}
        {sectionNumber === 3 && (
          <Box display="flex" justifyContent="space-between" gap={4} flexDirection="row">
            <Col label="Amount allowed - cents" value={`${data?.amountAllowed ?? '-'}`} />
            <Col label="Amount paid - cents" value={`${data?.amountPaid ?? '-'}`} />
            <Col label="Patient paid - cents" value={`${data?.toBePaidByPatient ?? '-'}`} />
            <Col label="Procedure modifiers" value={data?.procedureModifiers ?? '-'} />
            <Col label="CARCS" value={data?.carcs ?? '-'} />
            <Col label="RARCS" value={data?.rarcs ?? '-'} />
            <Col label="Denial reasons" value={data?.denialReasons ?? '-'} />
            <Col label="Rejection reason" value={data?.rejectionReason ?? '-'} />
            <Col label="Received to confidant" value={data?.amountReceivedToConfidant ? 'YES' : 'NO'} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CandidDetail;
