export const tableColumns = [
  { id: 'patient', label: 'Patient', sortable: false },
  { id: 'dob', label: 'DOB', sortable: false },
  { id: 'startDate', label: 'Referred Date', sortable: false },
  { id: 'appointmentsThisMonth', label: 'Appointments this month', sortable: false },
  { id: 'status', label: 'Status', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const referralPartnersDummy = Array.from({ length: 6 }, () => ({
  name: 'Partner name',
  dob: 'May 12, 1998',
  startDate: '1 Feb, 2020',
  patients: 35,
  status: 'Active',
}));

export const reportsTableColumns = [
  { id: 'reportName', label: 'Report name', sortable: false },
  { id: 'createdBy', label: 'Created by', sortable: false },
  { id: 'createdAt', label: 'Date created', sortable: false },
  { id: 'updatedAt', label: 'Last updated', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const reportsDummy = Array.from({ length: 6 }, () => ({
  name: 'Report name',
  createdBy: 'Eva Bond',
  createdAt: 'May 12, 1998',
  updatedAt: '1 Feb, 2020',
}));
