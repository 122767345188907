import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { ButtonGroup, Stack } from '@mui/material';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { Link } from '@confidant-health/lib/ui/atoms/link';
// services
import { addDct, updateDCT, updateDCTScoring } from 'services/conversation/conversation.service';
import { conversationActionCreators } from 'redux/modules/conversation';
import { selectDctState } from 'redux/modules/conversation/selectors';
import { showSnackbar } from 'redux/modules/snackbar';

// constants
import { CONVERSATION_TYPES } from 'constants/CommonConstants';

// components
import history from 'utils/history';

import { BaseLayout } from 'layouts/base';
import AddCollectionTemplate from './components/add-collection-template';
import DctScoring from './components/dct-scoring';

// mock
import {
  templatesColumns,
  filterOptionsMock,
  viewGroups,
  DCTMultiSelectFilterOptionsMock,
} from './DataCollectionTemplates.mock';

// styles
import { useStyles } from './DataCollectionTemplates.styles';

const DataCollectionTemplates: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });

  const { isLoading, dcts, totalRecords } = useSelector(selectDctState);
  const [viewBy, setViewBy] = useState(viewGroups[0].type);
  const [openDctDrawer, setOpenDctDrawer] = useState(false);
  const [openDctScoringDrawer, setOpenDctScoringDrawer] = useState<boolean>(false);
  const [selectedDct, setSelectedDct] = useState({ dctId: '' });
  const [dctList, setDctList] = useState([]);

  /**
   * @function onChangeView
   * @params type
   * @description This method is used to handle toggle between ACTIVE and DRAFT DCT Lists.
   */
  const onChangeView = type => () => {
    setViewBy(type);
    setTableParams({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: { direction: 'desc', column: '' },
    });
  };

  const onAddClick = () => {
    setSelectedDct(null);
    setOpenDctDrawer(true);
  };

  /**
   * @function onEditClick
   * @params dctId
   * @description This method is used to handle Edit menu option click.
   */
  const onEditClick = (dctId: string) => () => {
    setSelectedDct(dcts.find(template => template.dctId === dctId));
    setOpenDctDrawer(true);
  };

  const onEditScoringClick = (dctId: string) => () => {
    setSelectedDct(dcts.find(template => template.dctId === dctId));
    setOpenDctScoringDrawer(true);
  };

  /**
   * @function onRowClick
   * @params rowItem
   * @description This method is used to redirect to DctDetails page with Selected DCT's Id.
   */
  const onRowClick = rowItem => {
    history.push(
      `/admin/collection-templates/${rowItem.dctId}/${rowItem.version}?name=assignments&isDraft=${
        viewBy === viewGroups[1].type
      }`,
      {
        from: CONVERSATION_TYPES.DCT,
      },
    );
  };

  const renderColumns = templatesColumns?.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: ({ templateId, version, name }) => (
          <Link
            className={classes.link}
            to={`/admin/collection-templates/${templateId}/${version}?isDraft=${
              viewBy === viewGroups[1].type
            }`}
          >
            <Text className={classes.name} weight={fontWeight.MEDIUM}>
              {name}
            </Text>
          </Link>
        ),
      };
    }
    if (column.id === 'scorable') {
      return {
        ...column,
        renderCell: scorable => (
          <Badge
            className={clsx(classes.badge, { [classes.badgeActive]: scorable })}
            variant={badgeType.FILLED}
          >
            {scorable ? 'Scorable' : 'Not Scorable'}
          </Badge>
        ),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id: dctId }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              { label: 'Edit', onClick: onEditClick(dctId) },
              { label: 'Edit Scoring', onClick: onEditScoringClick(dctId) },
            ]}
          />
        ),
      };
    }
    return column;
  });

  useEffect(() => {
    const formatDcts = dcts.map(
      ({ contentBlocksCount, lastUpdated, usedInConversations, assignmentCount, name, ...item }) => ({
        ...item,
        name: { templateId: item.dctId, name, version: item.version },
        cbs: `${contentBlocksCount} CB's`,
        lastUpdated: dayjs(lastUpdated).format('MM/DD/YYYY'),
        usedIn: `${usedInConversations?.length || 0} ${
          usedInConversations?.length > 1 ? 'Conversations' : 'Conversation'
        }`,
        assignments: `${assignmentCount} ${assignmentCount > 1 ? 'Assignments' : 'Assignment'}`,
        actions: { id: item?.dctId },
      }),
    );
    setDctList(formatDcts);
  }, [dcts]);

  const getDCTs = () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const { pagination, search, sorter } = tableParams;
    const queryParams = {
      orderBy: sorter.direction,
      pageNumber: pagination.currentPage - 1,
      pageSize: pagination.rowsPerPage,
      searchQuery: search.searchKey,
      sortBy: [sorter.column],
      status: viewBy,
      isScorable: multiselectFilters?.Scorable?.length
        ? multiselectFilters?.Scorable[0] === 'Scorable'
        : null,
    };
    dispatch(conversationActionCreators.fetchDCTs(queryParams));
  };

  /**
   * @function saveUpdateDct
   * @params dctRequestPayload
   * @description This method is used to save or update DCT.
   */
  const saveUpdateDct = dctRequestPayload => {
    if (selectedDct) {
      updateDCT(selectedDct.dctId, dctRequestPayload)
        .then(() => {
          setOpenDctDrawer(false);
          getDCTs();
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Dct successfully updated',
            }),
          );
        })
        .catch(err => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
            }),
          );
        });
    } else {
      addDct(dctRequestPayload)
        .then(() => {
          setOpenDctDrawer(false);
          getDCTs();
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Dct successfully added',
            }),
          );
        })
        .catch(err => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
            }),
          );
        });
    }
  };

  const updateDctScoring = scoring => {
    setOpenDctScoringDrawer(false);
    updateDCTScoring(scoring, selectedDct.dctId)
      .then(() => {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Dct scoring successfully updated',
          }),
        );
      })
      .catch(err => {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
          }),
        );
      });
  };

  useEffect(() => {
    getDCTs();
  }, [viewBy, tableParams]);

  return (
    <>
      <AddCollectionTemplate
        dct={selectedDct}
        isOpen={openDctDrawer}
        onClose={() => setOpenDctDrawer(false)}
        onSubmit={saveUpdateDct}
      />
      <DctScoring
        dctId={selectedDct?.dctId}
        isOpen={openDctScoringDrawer}
        onClose={() => setOpenDctScoringDrawer(false)}
        onSubmit={updateDctScoring}
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Chatbot DCTs
              </Heading>
              {!!totalRecords && (
                <Badge
                  className={classes.totalMemberBadge}
                  variant={badgeType.OUTLINED}
                  style={badgeStyle.UNRELATED}
                >
                  {totalRecords} total
                </Badge>
              )}
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onAddClick}
            >
              Add new
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search chatbot DCT by name',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                options: filterOptionsMock,
                multiSelectOptions: DCTMultiSelectFilterOptionsMock,
                allOptionLabel: 'All template',
              },
              leftSearchComponent: (
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {viewGroups.map(({ type, label }) => (
                    <Button
                      key={type}
                      className={clsx(classes.btnGroupIcon, {
                        [classes.btnGroupIconActive]: type === viewBy,
                      })}
                      onClick={onChangeView(type)}
                    >
                      {label}
                    </Button>
                  ))}
                </ButtonGroup>
              ),
            }}
            gridProps={{
              columns: renderColumns,
              data: dctList,
              isLoading,
              onRowClick,
            }}
            paginationProps={{
              currentRows: tableParams.pagination.rowsPerPage,
              totalCount: totalRecords,
              showRowsPerPage: true,
            }}
            value={tableParams}
            onChange={setTableParams}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { DataCollectionTemplates };
