import { FC, useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { Heading, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { IDashboardTopBoxProps } from '@confidant-health/lib/ui/templates/dashboard-box';

import { AppointmentsFilterDates, AppointmentsReportsFilterStatus } from 'constants/CommonConstants';
import { AppointmentsChart } from '../chart';
import { useStyles } from './Appointments.styles';

type Props = {
  reportMonth?: IDashboardTopBoxProps[];
  chartSeries?: any[];
  chartDays: string[];
  getFilteredAppointmentsMetricsOnScreen?: (filterDetails: any, applyFilter: boolean) => void;
};

const Appointments: FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  reportMonth,
  chartSeries,
  getFilteredAppointmentsMetricsOnScreen,
  chartDays,
}) => {
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState(AppointmentsFilterDates[2]);
  const [selectedStatus, setSelectedStatus] = useState(AppointmentsReportsFilterStatus[0]);

  // console.log({ chartSeries });
  // const found = chartSeries?.findIndex((item: any) => item.name === 'No shows');
  // if (found !== -1) {
  //   chartSeries[found].data = chartSeries[found].data?.map(d => 2);
  // }
  // console.log({ chartSeries });
  // const found = chartSeries?.findIndex((item: any) => item.name === 'No shows');
  // if (found !== -1) {
  //   chartSeries[found].data = chartSeries[found].data?.map(d => 2);
  // }
  const getFilteredData = () => {
    getFilteredAppointmentsMetricsOnScreen(
      {
        selectedNumberOfDays: selectedTime?.value,
        status: selectedStatus?.value,
      },
      true,
    );
  };

  const times = AppointmentsFilterDates.map(time => ({
    ...time,
    onClick: () => setSelectedTime(time),
  }));

  const statuses = AppointmentsReportsFilterStatus.map(status => ({
    ...status,
    onClick: () => setSelectedStatus(status),
  }));

  useEffect(() => {
    getFilteredData();
  }, [selectedTime, selectedStatus]);

  return (
    <Paper className={classes.root}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Appointments By Status</Heading>
        <Box className={classes.headRight}>
          <Menu icon="chevron-down" items={times} itemsWrapperClassName={classes.menuLabel}>
            <Text className={classes.menuLabel} level={textLevel.S}>
              {selectedTime.label}
            </Text>
          </Menu>
          <Menu icon="chevron-down" items={statuses} itemsWrapperClassName={classes.menuLabel}>
            <Text className={classes.menuLabel} level={textLevel.S}>
              {selectedStatus.label}
            </Text>
          </Menu>
        </Box>
      </Box>
      {/* <Box className={classes.reportMonthWrap}>
        {reportMonth.map((box, index) => (
          <DashboardBox
            key={index}
            className={classes.reportMonthItem}
            label={box?.status}
            value={box?.count}
            type={box?.status}
            badge={box?.trendPercentage}
            isIncrease={box?.trendUp}
          />
        ))}
      </Box> */}
      {chartSeries && (
        <Box className={classes.chartWrap}>
          <AppointmentsChart chartSeriesData={chartSeries} chartDays={chartDays} />
        </Box>
      )}
    </Paper>
  );
};

export { Appointments };
