import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// components
import { Box, Stack } from '@mui/material';
import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { BaseLayout } from 'layouts/base';

import {
  getProfileElementDetailByMonth,
  getProfileElementDataUsage,
  getProfileElements,
} from 'services/conversation/conversation.service';
import { conversationActionCreators } from 'redux/modules/conversation/actions';
import { IProfileElement, IProfileElementInfo } from 'redux/modules/conversation/types';
import AddProfileElement from 'pages/admin/profile-element-list/components/add-profile-element';
import { PROFILE_ELEMENT_DEF_TYPE } from 'constants/CommonConstants';
import { sentenceCase } from 'sentence-case';
import { getElkProfileElementReport2 } from 'services/reporting/reporting.service';

import ReportChartCard from './components/report-chart-card';
import ValuesListCard from './components/values-list-card';
import AssignmentsChartCard from './components/assignments-chart-card';

import { IProfileElementDistribution, IProfileElementDetailByMonth } from './ProfileElementDetail.types';

// styles
import { useStyles } from './ProfileElementDetail.styles';
import ProfileAssignmentTableCard from './components/profileElements-assignment-table-card';
import { ProfileDctConvoTableCard } from './components/profileElements-DCT-Convo-table-card/ProfileDctConvoTableCard';
import { PriorityDataDomainTableCard } from './components/priority-data-domain-table-card/PriorityDataDomainTableCard';
import {
  formatDistributionData,
  getProfileElementDistributionDetailQuery,
} from './ProfileElementDetail.constants';

const ProfileElementDetail: FC = () => {
  const { profileElementId } = useParams();
  const location = useLocation();
  const state = location.state as { profileElement: IProfileElement };
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState(null);
  const [showAddProfile, setShowAddProfile] = useState(false);
  const [profileElement, setProfileElement] = useState<IProfileElement>(state?.profileElement);
  const [profileElementDistribution, setProfileElementDistribution] = useState<IProfileElementDistribution>();
  const [profileElementDetailByMonth, setProfileElementDetailByMonth] =
    useState<IProfileElementDetailByMonth[]>();
  const [profileElementDataUsage, setProfileElementDataUsage] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * @function fetchPercentileDistributionOfProfileElementsByValues
   * @description This method is used to get Percentile Distribution of Profile Elements by Values.
   * @required ProfileElementId
   */
  const fetchPercentileDistributionOfProfileElementsByValues = async () => {
    try {
      const { data } = await getElkProfileElementReport2(
        getProfileElementDistributionDetailQuery(profileElement.profileElementInfo.key),
        null,
      );

      setProfileElementDistribution(formatDistributionData(data));
    } catch (err) {
      setErrorMsg(err?.data?.errors[0]?.endUserMessage || 'Something went wrong!');
    }
  };

  /**
   * @function fetchProfileElementDetailByMonth
   * @description This method is used to get Aggregate Counts of assignment for current month
   * @required ProfileElementId
   */
  const fetchProfileElementDetailByMonth = async () => {
    try {
      setIsLoading(true);
      const { data } = await getProfileElementDetailByMonth(profileElementId);
      setProfileElementDetailByMonth(data);
      setIsLoading(false);
    } catch (err) {
      setErrorMsg(err?.data?.errors[0]?.endUserMessage || 'Something went wrong!');
    }
  };

  /**
   * @function fetchProfileElementDataUsage
   * @description This method is used to get list of DCT Usage Objects for Profile Element .
   * @required ProfileElementId
   */
  const fetchProfileElementDataUsage = async () => {
    try {
      const response = await getProfileElementDataUsage(profileElementId);
      setProfileElementDataUsage(response);
    } catch (err) {
      setErrorMsg(err?.data?.errors[0]?.endUserMessage || 'Something went wrong!');
    }
  };

  const onSubmitProfileElement = (payload: IProfileElementInfo) => {
    const updatedProfileElement = {
      ...profileElement,
      profileElementInfo: { ...payload, id: profileElement?.profileElementInfo.id },
    };
    dispatch(
      conversationActionCreators.updateProfileElement({
        payload,
        profileElementId: profileElement?.profileElementInfo.id,
        callback: () => {
          setShowAddProfile(false);
          setProfileElement(updatedProfileElement);
        },
      }),
    );
  };

  // following is the temporary fix for app crash when user tries to access this page from URL directly. Causes slow loading only when loading from URL directly.
  // Remove when proper solution from backend is implemented i.e. to return profileElement object in response of getProfileElement API
  useEffect(() => {
    const fetchProfileElements = async () => {
      const profileElements = await getProfileElements({});
      const allProfileElements = await getProfileElements({ pageSize: profileElements?.data?.totalRecords });
      const currentProfileElement = allProfileElements?.data?.profileElementList?.find(
        element => element?.profileElementInfo?.id === profileElementId,
      );
      const formattedProfileElement = {
        ...currentProfileElement,
        name: currentProfileElement?.profileElementInfo?.key,
        type: PROFILE_ELEMENT_DEF_TYPE[currentProfileElement?.profileElementInfo?.type],
        values: currentProfileElement?.profileElementInfo?.values,
        method: sentenceCase(currentProfileElement?.profileElementInfo?.method || ''),
        appearIn: `${currentProfileElement?.usage?.dctIds?.length || '0'} DCT / ${
          currentProfileElement?.usage?.conversationIds?.length || '0'
        } Convo`,
        actions: { ...currentProfileElement },
      };
      setProfileElement(formattedProfileElement);
    };

    // only in case page is accessed directly from URL
    if (!location.state) {
      fetchProfileElements().catch(console.error);
    }
  }, []);

  useEffect(() => {
    void fetchProfileElementDetailByMonth();
    void fetchProfileElementDataUsage();
  }, []);
  useEffect(() => {
    void fetchPercentileDistributionOfProfileElementsByValues();
  }, [profileElement]);

  return (
    <>
      {errorMsg && (
        <Box display="flex" justifyContent="center" flex={1} padding={2}>
          <TextError errorMsg={errorMsg} />
        </Box>
      )}
      <AddProfileElement
        isOpen={showAddProfile}
        profileElement={profileElement}
        onClose={() => setShowAddProfile(false)}
        onSubmit={onSubmitProfileElement}
      />
      <BaseLayout>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Breadcrumbs
              links={[
                { href: '/admin/data-management-elements', text: 'Profile elements' },
                { text: profileElement?.profileElementInfo.key },
              ]}
            />
          </Box>
          <Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack direction="column" gap={1}>
                <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                  {profileElement?.profileElementInfo.key}
                </Heading>
                <Text className={classes.subtitle}>{profileElement?.profileElementInfo.method}</Text>
              </Stack>
              <IconButton
                className={classes.editBtn}
                variant={iconBtnType.PRIMARY}
                onClick={() => setShowAddProfile(true)}
              >
                Edit profile element
              </IconButton>
            </Stack>
            {profileElementDistribution && profileElementDetailByMonth && profileElementDataUsage ? (
              <Box display="flex" flexDirection="column" gap={4} sx={{ marginTop: 7 }}>
                <ReportChartCard
                  profileElementType={PROFILE_ELEMENT_DEF_TYPE[profileElement?.profileElementInfo.type]}
                  profileElementDistribution={profileElementDistribution}
                />
                <ValuesListCard profileElementValues={profileElementDistribution.values} />
                <AssignmentsChartCard
                  profileElementDetailByMonth={profileElementDetailByMonth}
                  isLoading={isLoading}
                />
                <ProfileAssignmentTableCard profile={profileElement} disableExport />
                <ProfileDctConvoTableCard profile={profileElement} />
                <PriorityDataDomainTableCard profile={profileElement} />
                {/* <AssignmentsTableCard />
                <InfoCard
                  title="Connected DCTs and Conversational Variables"
                  data={{
                    title: 'person-of-concern',
                    version: 'v1.0',
                    assignments: 12,
                  }}
                />
                <InfoCard
                  title="Connected to Priorities and Data Domains"
                  data={{
                    title: 'person-of-concern',
                    version: 'v1.0',
                    assignments: 15,
                  }}
                /> */}
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              </Box>
            )}
          </Box>
        </Box>
      </BaseLayout>
    </>
  );
};

export { ProfileElementDetail };
