import { colors } from '@confidant-health/lib/colors';

export type DonutChartSeriesProps = {
  name: string;
  color: string;
  bg?: string;
  data: number[];
}[];

export type DonutOpts = {
  legend?: {
    [key: string]: any;
  };
  total?: number;
  title?: string;
  size?: string;
};

export const getChartOpts = (
  series: DonutChartSeriesProps,
  donutOpts?: DonutOpts,
  legendRight?: boolean,
  chartId?: string,
): any => {
  const { legend = {} } = donutOpts || { legend: {} };
  const labels = series?.map(({ name }) => name);
  const seriColors = series?.map(({ color }) => color);
  return {
    chart: {
      height: '100%',
      redrawOnParentResize: true,
      id: chartId,
    },
    title: {
      text: '',
      show: false,
    },
    labels,

    plotOptions: {
      pie: {
        size: 60,
        offsetY: 6,
        offsetX: -45,
        donut: {
          size: '75%',
          labels: {
            show: !!donutOpts?.title,
            total: {
              showAlways: true,
              show: true,
              label: donutOpts?.title,
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              color: colors.neutral600,
              formatter: () => String(donutOpts?.total),
            },
            name: {
              show: true,
              offsetY: 20,
            },
            value: {
              show: true,
              fontSize: '18px',
              label: 12,
              fontFamily: 'Manrope',
              fontWeight: 700,
              color: colors.neutral900,
              offsetY: -20,
            },
          },
        },
      },
    },
    tooltip: {
      style: {
        fontFamily: 'Manrope',
      },
      custom: ({ seriesIndex, w }: Record<string, any>): string => {
        return `
          <div class="chartWrap-tooltip">
            <div class="date">${labels[seriesIndex]}</div>
            <div class="level">${w.config.series[seriesIndex]}</div>
          </div>
        `;
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: seriColors,
    stroke: {
      width: 3,
    },
    legend: {
      markers: {
        offsetY: 7,
        fillColors: new Array(series.length).fill('transparent'),
      },
      position: legendRight ? 'right' : 'left',
      fontSize: '16px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      offsetX: -80,
      width: 250,
      // offsetY: 5,
      height: 200,
      itemMargin: {
        horizontal: 0,
        vertical: 15,
      },
      ...legend,
    },
  };
};
