import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import dayjs from 'dayjs';
import history from 'utils/history';
import { Box, ButtonGroup } from '@mui/material';
import { inputType, Input } from '@confidant-health/lib/ui/atoms/input';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Drawer } from '@confidant-health/lib/ui/organisms/drawer';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';

// components
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';

import { BaseLayout } from 'layouts/base';
import { appointmentActionCreators } from 'redux/modules/appointment';
import {
  selectMasterScheduleItems,
  selectMasterScheduleItemsLoading,
  selectProviderRoles,
  selectProviderRolesLoading,
} from 'redux/modules/appointment/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import { AppState } from 'redux/store/types';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { getUserTimeZone } from 'utils/dayjs';

import MasterScheduleItem from './components/master-schedule-item';
import Calendar from './components/calendar';
import AddSchedule, { INewSchedulePayload } from '../appointments/add-schedule';
import AvailableProviders from './components/AvailableProviders';

// mock
import { durationOptions, timeDurations, daysOfWeek, timesOfDay } from './MasterSchedule.mock';

// styles
import { useStyles } from './MasterSchedule.styles';

const MasterSchedule: FC = () => {
  const masterScheduleItems = useSelector((state: AppState) => selectMasterScheduleItems(state));
  const { profile } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);
  const masterScheduleItemsLoading = useSelector((state: AppState) =>
    selectMasterScheduleItemsLoading(state),
  );
  const roles = useSelector(selectProviderRoles);
  const rolesLoading = useSelector(selectProviderRolesLoading);
  const providerSchedule = useSelector(selectProviderSchedule);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentProvider, setCurrentProvider] = useState({} as any);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [view, setView] = useState<'calendar' | 'list'>('list');
  const [filters, setFilters] = useState({
    duration: 60,
    endTime: '2359',
    month: dayjs().format('M'),
    providerRoles: ['Coach', 'matchmaker'],
    selectedDays: daysOfWeek,
    startTime: '0000',
    timeSpan: durationOptions[0].value,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: dayjs().format('YYYY'),
  });
  const [open, setOpen] = useState(false);
  const [drawerItems, setDrawerItems] = useState({
    date: '',
    items: [],
  });
  const [searchKey, setSearchKey] = useState('');
  const isListView = view === 'list';
  const isCalendarView = view === 'calendar';

  useEffect(() => {
    dispatch(appointmentActionCreators.fetchProviderRoles());
  }, []);

  useEffect(() => {
    if (view === 'list') {
      dispatch(appointmentActionCreators.fetchMasterSchedule(filters));
    } else {
      dispatch(
        appointmentActionCreators.fetchMasterSchedule({
          ...filters,
          view: 'calendar',
        }),
      );
    }
  }, [filters, view]);

  const uniqueRoles = useMemo(
    () => Array.from(new Set(roles.map(({ designation }) => designation.toLocaleLowerCase()))),
    [roles],
  );

  useEffect(() => {
    setFilters({
      ...filters,
      providerRoles: uniqueRoles,
    });
  }, [uniqueRoles]);

  const getMasterSchedules = schedules => {
    if (!schedules || schedules.length === 0) return [];
    return schedules.map(schedule => {
      const reduced = schedule?.slots?.reduce((prev: any, slot) => {
        const dayString = dayjs(slot?.start).format('YYYY-MM-DD');
        if (prev[dayString]) {
          prev[dayString].push(slot);
        } else {
          prev[dayString] = [slot];
        }
        return prev;
      }, {});

      return { ...schedule, slots: reduced };
    });
  };

  const transformedSchedules = useMemo(() => {
    if (searchKey === '') {
      return getMasterSchedules(masterScheduleItems);
    }
    const filteredValue = masterScheduleItems.filter(v => {
      return v.providerDetailsDto.name.toLowerCase().includes(searchKey.toLowerCase());
    });

    if (filteredValue.length === 0) {
      return getMasterSchedules(masterScheduleItems);
    }

    return getMasterSchedules(filteredValue);
  }, [searchKey, masterScheduleItems]);

  const masterSchedules = useMemo(() => {
    if (searchKey === '') {
      return masterScheduleItems;
    }
    const filteredValue = masterScheduleItems.filter(v => {
      return v.providerDetailsDto.name.toLowerCase().includes(searchKey.toLowerCase());
    });

    if (filteredValue.length === 0) {
      return masterScheduleItems;
    }

    return filteredValue;
  }, [searchKey, masterScheduleItems]);

  const onFilterChange = (key: string, newValue: any) => {
    if (key === 'providerRoles' || key === 'selectedDays') {
      if (filters[key].indexOf(newValue) !== -1) {
        setFilters({
          ...filters,
          [key]: filters[key].filter(v => v !== newValue),
        });
      } else {
        setFilters({
          ...filters,
          [key]: [...filters[key], newValue],
        });
      }
    } else if (key === 'timesOfDay') {
      const { startTime, endTime } = newValue;
      setFilters({
        ...filters,
        startTime,
        endTime,
      });
    } else {
      setFilters({
        ...filters,
        [key]: newValue,
      });
    }
  };

  const isSelected = (key: string, newValue: any) => {
    if (key === 'providerRoles' || key === 'selectedDays') {
      return Boolean(filters[key].find(v => v === newValue));
    }
    if (key === 'timesOfDay') {
      const { startTime, endTime } = newValue;
      return filters.startTime === startTime && filters.endTime === endTime;
    }
    return filters[key] === newValue;
  };

  const onDateClick = (date: string, items: any) => {
    setOpen(true);
    /*     const filteredItem = items?.filter((item, index) => {
      const itemIndex = items?.findIndex(i => {
        return i?.providerName === item?.providerName;
      });
      return index === itemIndex;
    }); */
    setDrawerItems({
      date,
      items,
    });
  };
  const fetchAppointments2 = (providerId, timeZone) => {
    const filters2 = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters: filters2,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const onSubmitNewSchedule = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed: boolean, isInstantSession: boolean, appointmentId: string) => {
          if (isSucceed) {
            setShowAddSchedule(false);
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  return (
    <>
      <BaseLayout
        rightSideBarWidth="320px"
        customRightSideBar={
          <Box padding="32px">
            <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
              Filter schedule
            </Heading>
            <Box display="flex" gap="32px" flexDirection="column">
              <Box display="flex" flexDirection="column">
                <Input
                  variant={inputType.SEARCH}
                  placeholder="Search"
                  onChange={value => {
                    setSearchKey(value);
                  }}
                />
              </Box>
              <Box>
                <Collapsable open label="Provider types" iconPosition={IiconPosition.RIGHT}>
                  <Box marginTop="26px">
                    {uniqueRoles.map(role => (
                      <Box key={role} display="flex" alignItems="center" gap={1.5} mb={2}>
                        <Checkbox
                          checked={isSelected('providerRoles', role)}
                          onChange={() => onFilterChange('providerRoles', role)}
                        />
                        <Text level={textLevel.M}>{`${role[0]?.toUpperCase()}${role?.slice(1)}`}</Text>
                      </Box>
                    ))}
                  </Box>
                </Collapsable>
              </Box>
              <Box>
                <Collapsable open label="Day Duration" iconPosition={IiconPosition.RIGHT}>
                  <Box marginTop="26px">
                    {durationOptions.map(({ label, value: key }) => (
                      <Box key={key} display="flex" alignItems="center" gap={1.5} mb={2}>
                        <Checkbox
                          checked={isSelected('timeSpan', key)}
                          onChange={() => onFilterChange('timeSpan', key)}
                        />
                        <Text level={textLevel.M}>{label}</Text>
                      </Box>
                    ))}
                  </Box>
                </Collapsable>
              </Box>
              <Box>
                <Collapsable open label="Time Duration" iconPosition={IiconPosition.RIGHT}>
                  <Box marginTop="26px">
                    {timeDurations.map(({ label, value: key }) => (
                      <Box key={key} display="flex" alignItems="center" gap={1.5} mb={2}>
                        <Checkbox
                          checked={isSelected('duration', key)}
                          onChange={() => onFilterChange('duration', key)}
                        />
                        <Text level={textLevel.M}>{label}</Text>
                      </Box>
                    ))}
                  </Box>
                </Collapsable>
              </Box>
              <Box>
                <Collapsable open label="Days of Weeks" iconPosition={IiconPosition.RIGHT}>
                  <Box marginTop="26px">
                    {daysOfWeek.map(day => (
                      <Box
                        key={day}
                        sx={{ '& span': { textTransform: 'capitalize' } }}
                        display="flex"
                        alignItems="center"
                        gap={1.5}
                        mb={2}
                      >
                        <Checkbox
                          checked={isSelected('selectedDays', day)}
                          onChange={() => onFilterChange('selectedDays', day)}
                        />
                        <Text level={textLevel.M}>{day}</Text>
                      </Box>
                    ))}
                  </Box>
                </Collapsable>
              </Box>
              <Box>
                <Collapsable open label="Times Of Day" iconPosition={IiconPosition.RIGHT}>
                  <Box marginTop="26px">
                    {timesOfDay.map(({ label, startTime, endTime }, index) => (
                      <Box key={index} display="flex" alignItems="center" gap={1.5} mb={2}>
                        <Checkbox
                          checked={isSelected('timesOfDay', { startTime, endTime })}
                          onChange={() => onFilterChange('timesOfDay', { startTime, endTime })}
                        />
                        <Text level={textLevel.M}>{label}</Text>
                      </Box>
                    ))}
                  </Box>
                </Collapsable>
              </Box>
              <Box>
                <Collapsable open label="Specific Providers" iconPosition={IiconPosition.RIGHT}>
                  <Box display="flex" flexDirection="column" gap="16px" marginTop="26px">
                    {roles.map(({ name, userId, designation }) => (
                      <Box key={userId} display="flex" alignItems="center" gap={1.5} mb={2}>
                        <Checkbox
                          checked={isSelected('provderId', userId)}
                          onChange={() => onFilterChange('provderId', userId)}
                        />
                        <ProfileInfo type="provider" role={designation} fullName={name} />
                      </Box>
                    ))}
                  </Box>
                </Collapsable>
              </Box>
            </Box>
          </Box>
        }
      >
        <div className={classes.root}>
          <Box display="flex" justifyContent="space-between">
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Master schedule
            </Heading>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <IconButton
                className={clsx(classes.btnGroupIcon, { [classes.btnGroupIconActive]: isListView })}
                icon="list"
                onClick={() => setView('list')}
              />
              <IconButton
                className={clsx(classes.btnGroupIcon, { [classes.btnGroupIconActive]: isCalendarView })}
                icon="calendar-primary-outlined"
                onClick={() => setView('calendar')}
              />
            </ButtonGroup>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            {rolesLoading || masterScheduleItemsLoading ? (
              <Box
                sx={{
                  position: rolesLoading || masterScheduleItemsLoading ? 'relative' : 'absolute',
                  top: rolesLoading || masterScheduleItemsLoading ? -125 : 0,
                  left: rolesLoading || masterScheduleItemsLoading ? 0 : 70,
                  width: '100%',
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: rolesLoading || masterScheduleItemsLoading ? '' : colors.white,
                  opacity: 0.8,
                  zIndex: 1000,
                }}
              >
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              </Box>
            ) : (
              <>
                {isListView &&
                  transformedSchedules.map((data, index) => (
                    <MasterScheduleItem
                      key={index}
                      data={data}
                      onClickSchedule={() => {
                        setShowAddSchedule(true);
                        const foundProvider = roles.find(role => role.name === data.providerName);
                        setCurrentProvider(foundProvider);
                      }}
                    />
                  ))}
                {isCalendarView && <Calendar data={masterSchedules} onDateClick={onDateClick} />}
              </>
            )}
          </Box>
        </div>
      </BaseLayout>
      <Drawer open={open} onClose={() => setOpen(false)} footer={<div />} className={classes.drawer}>
        <Box display="flex" flexDirection="column" gap="16px">
          <AvailableProviders data={drawerItems} onClose={() => setOpen(false)} />
        </Box>
      </Drawer>
      <AddSchedule
        isOpen={showAddSchedule}
        onClose={() => setShowAddSchedule(false)}
        onSubmit={onSubmitNewSchedule}
        providerId={currentProvider?.userId}
      />
    </>
  );
};

export { MasterSchedule };
