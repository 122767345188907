import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  table: {
    backgroundColor: colors.white,
    '& tr > td': {
      flex: 1,
      maxWidth: 150,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&.removedByMember': {
        textAlign: 'center',
        padding: 0,
      },
      '&.completeXTimes': {
        textAlign: 'center',
        padding: 0,
      },
    },
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addProfileBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
});
