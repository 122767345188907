import { FC } from 'react';

import { PrioritiesCard } from 'pages/provider/sessions/priority-list/PriorityList.constants';
import SymptomDetail from 'pages/provider/member-detail/components/Symptoms/symptom-detail';
import DiagnosesDetail from 'pages/provider/member-detail/components/Diagnoses/diagnoses-detail';
import SideEffectDetail from 'pages/provider/member-detail/components/SideEffects/components/side-effect-detail';
import MedicationDetail from 'pages/provider/member-detail/components/Medications/medication-detail';
import SubstanceUseDetail from 'pages/provider/member-detail/components/SubstanceUse/substance-use-detail';
import { HistoryLookup } from 'redux/modules/profile/types';

type Props = {
  sectionType: string;
  card: any;
  isNoDrawer?: boolean;
  lookupData?: HistoryLookup;
  onCloseCard: () => void;
  onClickNewReport: () => void;
};

const ViewPriorityCard: FC<Props> = ({
  sectionType,
  card,
  isNoDrawer,
  lookupData,
  onCloseCard,
  onClickNewReport,
}) => {
  const getCard = () => {
    switch (sectionType) {
      case PrioritiesCard.SocialDeterminants:
      case PrioritiesCard.SideEffects:
        return (
          <SideEffectDetail
            open
            item={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={sectionType}
          />
        );
      case PrioritiesCard.Symptoms:
        return (
          <SymptomDetail
            open
            symptom={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={PrioritiesCard.Symptoms}
          />
        );
      case PrioritiesCard.Diagnoses:
      case PrioritiesCard.LifeEvents:
        return (
          <DiagnosesDetail
            open
            diagnoses={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={sectionType}
          />
        );
      case PrioritiesCard.Medications:
      case PrioritiesCard.CognitiveIssues:
        return (
          <MedicationDetail
            open
            medication={card}
            onClose={onCloseCard}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={sectionType}
          />
        );
      case PrioritiesCard.SubstanceUse:
        return (
          <SubstanceUseDetail
            open
            item={card}
            onClose={onCloseCard}
            lookupData={lookupData}
            isNoDrawer={isNoDrawer}
            onClickNewReport={onClickNewReport}
            priorityType={PrioritiesCard.SubstanceUse}
          />
        );
      default:
        return null;
    }
  };

  return <>{getCard()}</>;
};

export { ViewPriorityCard };
