// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 32,
    zIndex: 2,
  },
  thumbnail: {
    width: 206,
    height: 120,
    borderRadius: 8,
    border: `3px solid ${colors.white}`,
  },
  imgWrap: {
    width: 200,
    height: 114,
    position: 'relative',
    borderRadius: 8,
    border: `3px solid ${colors.white}`,
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  shareText: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 700,
    margin: 0,
    color: colors.white,
  },
  actions: {
    position: 'relative',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  },
  iconBtn: {
    width: 56,
    height: 56,
    minWidth: 56,
    borderRadius: '50%',
    backgroundColor: colors.white,
    border: `2px solid ${colors.neutral200}`,
    '& svg path': {
      color: colors.neutral900,
    },
    '&:hover': {
      border: `2px solid ${colors.neutral900}`,
      '& svg path': {
        color: colors.white,
      },
    },
  },
});
