// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  boxRight: {
    width: 332,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  block: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    padding: 24,
  },
  nextTitle: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    display: 'block',
  },
  nextSubtitle: {
    fontWeight: 600,
    color: colors.neutral700,
  },
  nextTime: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
    color: colors.neutral500,
    display: 'block',
  },
  topTitle: {
    fontSize: 18,
    lineHeight: '24px',
    marginTop: 0,
  },
  label: {
    fontSize: 12,
    lineHeight: '20px',
    color: colors.neutral500,
    display: 'block',
    fontWeight: 800,
    margin: 0,
    textTransform: 'uppercase',
  },
  text: {
    margin: 0,
    fontSize: 16,
    lineHeight: '24px',
    display: 'block',
    fontWeight: 500,
  },
  infoIconBtn: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderRadius: 4,
    padding: 10,
    '& svg': {
      width: '20px !important',
      height: '20px !important',
    },
    '& svg path': {
      fill: colors.primary500,
    },
  },
  actBtn: {
    height: 48,
    borderRadius: 6,
    width: '100%',
    color: colors.white,
  },
  actBtn2: {
    // height: 48,
    borderRadius: 6,
    width: '100%',
    color: colors.white,
  },
  cancelBtn: {
    backgroundColor: colors.destructive500,
    '&:hover': {
      backgroundColor: colors.destructive400,
    },
  },
  noShowBtn: {
    border: `1px solid ${colors.destructive300}`,
    color: colors.destructive500,
  },
  completedBtn: {
    border: `1px solid ${colors.neutral300}`,
    color: colors.neutral800,
  },
});
