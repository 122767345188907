export const StepTitles = {
  SELECT_MEMBER: 'Step 1 - Select Member',
  SELECT_PROVIDER: 'Step 2 - Select Provider',
  SELECT_SERVICE: 'Step 3 - Select Service',
  SELECT_TYPE: 'Step 4 - Select Type',
  CHECK_AVAILABILITY: 'Step 5 - Availability',
  SELECT_REVIEW: 'Step 6 - Review',
};

export const ScheduleSteps = {
  SELECT_MEMBER: 'SELECT_MEMBER',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SELECT_SERVICE: 'SELECT_SERVICE',
  SELECT_TYPE: 'SELECT_TYPE',
  CHECK_AVAILABILITY: 'CHECK_AVAILABILITY',
  SELECT_REVIEW: 'SELECT_REVIEW',
};

export const appointmentType = {
  SCHEDULING_LINK: 'SCHEDULING_LINK',
  RECURRING: 'RECURRING',
  INSTANT_SESSION: 'INSTANT_SESSION',
  SPECIFIC_DATE: 'SPECIFIC_DATE',
};

export const StepsNumber = {
  SELECT_MEMBER: 0,
  SELECT_PROVIDER: 1,
  SELECT_SERVICE: 2,
  SELECT_TYPE: 3,
  CHECK_AVAILABILITY: 4,
  SELECT_REVIEW: 5,
};

export const getSchedulingSteps = (apptType: string) => {
  switch (apptType) {
    case appointmentType.SPECIFIC_DATE:
      return ['Member', 'Provider', 'Service', 'Type', 'Review'];
    case appointmentType.RECURRING:
      return ['Member', 'Provider', 'Service', 'Type', 'Availability', 'Review'];

    default:
      return ['Member', 'Provider', 'Service', 'Type'];
  }
};
export const appointmentRepetition = [{ label: 'Do Not Repeat', value: 'do_not_repeat' }];

export const RECURRING_APPOINTMENT_FREQUENCIED = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  BI_WEEKLY: 'BI-WEEKLY',
  MONTHLY: 'MONTHLY',
};
