import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  table: {
    backgroundColor: colors.white,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    display: 'block',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  badge: {
    borderRadius: 20,
    height: 18,
    padding: '2px 8px',
    fontSize: 12,
    width: 'fit-content',
    border: 'none',
    marginTop: 2,
    '&.Provider': {
      backgroundColor: colors.indigo50,
      color: colors.indigo600,
    },
    '&.Coach': {
      backgroundColor: colors.warning50,
      color: colors.warning600,
    },
  },
  statusBadge: {
    padding: '4px 12px',
    height: 'auto',
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: 14,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addProfileBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
});
