import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import dayjs from 'dayjs';

import { getProfile } from 'redux/modules/profile/selectors';
import history from 'utils/history';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { AppointmentStatusBagde } from '@confidant-health/lib/constants/CommonConstants';
import { AppointmentDetail } from 'pages/provider/appointments/components/appointment-detail/AppointmentDetail';
import { AppointmentScheduling } from 'pages/provider/appointments/components/appointment-scheduling/AppointmentScheduling';
import { AppointmentCancel } from 'pages/provider/appointments/components/appointment-cancel/AppointmentCancel';
import { conversationActionCreators } from 'redux/modules/conversation';
import { selectEvaluation } from 'redux/modules/conversation/selectors';
import { getAppointment } from 'redux/modules/appointment/selectors';
import { IDemographicDetails } from 'redux/modules/profile/types';

import Card from './Card';

import { useStyles } from './index.styles';

const Appointments = ({ isAdmin, showStats = false }) => {
  const classes = useStyles();
  const { memberId } = useParams();
  const { selectedPatient, demographicDetails = {} as IDemographicDetails } = useSelector(getProfile);
  const { isLoading, appointments: appointmentsState = [] } = useSelector(getAppointment);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDetailDrawer, setShowDetailDrawer] = useState(false);
  const evaluations = useSelector(selectEvaluation);

  const dispatch = useDispatch();
  const toggleDetailDrawer = () => {
    setShowDetailDrawer(!showDetailDrawer);
  };
  const toggleCancelDrawer = () => {
    setShowCancel(!showCancel);
    // fetchAppointments();
  };

  const toggleRescheduleDrawer = () => {
    setShowReschedule(!showReschedule);
  };

  const onClickAppointment = appointment => {
    setSelectedItem(appointment);
    toggleDetailDrawer();
  };

  useEffect(() => {
    if (selectedItem?.appointmentId) {
      dispatch(
        conversationActionCreators.fetchAssignedEvaluations({ appointmentId: selectedItem?.appointmentId }),
      );
    }
  }, [selectedItem]);

  const renderAppointmentStats = () => (
    // <Card
    //   label="Appointments"
    //   actions={[
    //     // {
    //     //   label: 'Download PDF',
    //     //   icon: 'download',
    //     //   onClick: () => null
    //     // },
    //     {
    //       label: 'View all',
    //       onClick: () =>
    //         navigate(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=appointments-current`),
    //     },
    //   ]}
    // >
    <Box className={classes.flexColumn}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={6}>
          <Box className={classes.flexColumn} sx={{ background: colors.secondary50, p: 2, gap: 1 }}>
            <Box className={classes.cardLabel}>Referred</Box>
            <Box className={classes.cardDescription}>
              {' '}
              {demographicDetails?.appointmentStats?.referred
                ? dayjs(demographicDetails?.appointmentStats?.referred).format('MMMM DD, YYYY')
                : '-'}
            </Box>
          </Box>
        </Grid> */}
          <Grid item xs={12} md={4}>
            <Box className={classes.flexColumn} sx={{ background: colors.warning50, p: 2, gap: 1 }}>
              <Box className={classes.cardLabel}>Started care</Box>
              <Box className={classes.cardDescription}>
                {demographicDetails?.appointmentStats?.startedCare
                  ? dayjs(demographicDetails?.appointmentStats?.startedCare).format('MMMM DD, YYYY')
                  : '-'}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.flexColumn} sx={{ background: colors.success50, p: 2, gap: 1 }}>
              <Box className={classes.cardLabel}>total appointments</Box>
              <Box className={classes.cardDescription}>
                {demographicDetails?.appointmentStats?.totalAppointments || '-'}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.flexColumn} sx={{ background: colors.primary50, p: 2, gap: 1 }}>
              <Box className={classes.cardLabel}>Appointments this month</Box>
              <Box className={classes.cardDescription}>
                {demographicDetails?.appointmentStats?.appointmentsThisMonth || '-'}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
    // </Card>
  );
  return (
    <>
      <AppointmentDetail
        open={showDetailDrawer}
        onClose={toggleDetailDrawer}
        onCancelClick={toggleCancelDrawer}
        onRescheduleClick={toggleRescheduleDrawer}
        // onAcceptAppointment={onStartAppointmentClick}
        appointment={selectedItem}
        evaluations={evaluations}
      />
      <AppointmentScheduling
        open={showReschedule}
        onClose={toggleRescheduleDrawer}
        updateAppointment={setSelectedItem}
        appointment={selectedItem}
      />
      <AppointmentCancel
        open={showCancel}
        onClose={toggleCancelDrawer}
        appointment={selectedItem}
        updateAppointment={setSelectedItem}
      />
      <Card
        label="Coming appointments"
        actions={[
          {
            label: 'View all',
            onClick: () =>
              history.push(
                `/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=appointments-current`,
              ),
          },
        ]}
      >
        {showStats && renderAppointmentStats()}
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </Box>
        ) : appointmentsState?.length > 0 ? (
          <>
            {appointmentsState.slice(0, 3).map((item, idx) => (
              <Grid
                container
                key={idx}
                alignItems="center"
                sx={{ borderBottom: `1px solid ${colors.neutral100}`, cursor: 'pointer' }}
                onClick={() => onClickAppointment(item)}
              >
                <Grid item xs={3}>
                  <Box sx={{ p: 2, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                    <Avatar
                      src={item?.practitionerImage}
                      size={40}
                      variant={avatarType.CIRCLE}
                      name={item?.practitionerName}
                    />
                    <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
                      <Text weight={fontWeight.MEDIUM} level={textLevel.M}>
                        {item?.practitionerName ||
                          `${item?.practitionerFirstName || ''} ${item?.practitionerLastName || ''}`}
                      </Text>
                      <Badge size={badgeSize.SMALL} style={badgeStyle.MEDIUM} variant={badgeType.OUTLINED}>
                        {item?.practitionerDesignation}
                      </Badge>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={{ p: 2 }}>
                    <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
                      <Text weight={fontWeight.MEDIUM} level={textLevel.M}>
                        {`${dayjs(item?.startTime).format('MMMM DD, YYYY')}`}
                      </Text>
                      <Text weight={fontWeight.LIGHT} level={textLevel.M}>
                        {`${dayjs(item?.startTime).format('hh:mmA')} - ${dayjs(item?.endTime).format(
                          'hh:mmA',
                        )}`}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={{ p: 2 }}>
                    <Text weight={fontWeight.MEDIUM} level={textLevel.M}>
                      {item?.serviceName}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ p: 2 }}>
                    <Badge
                      variant={badgeType.FILLED}
                      style={(AppointmentStatusBagde[item?.status] || badgeStyle.UNRELATED) as badgeStyle}
                      className={classes.status}
                    >
                      {item?.status}
                    </Badge>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box sx={{ p: 2 }} display="flex" alignItems="center">
                    <IconButton
                      icon="chevron-right"
                      className={classes.menu}
                      onClick={() => onClickAppointment(item)}
                    />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
            <Text weight={fontWeight.BOLD} level={textLevel.S} color={colors.neutral700}>
              No appointment{' '}
              {`${selectedPatient?.member?.nickName ? `for ${selectedPatient?.member?.nickName}` : ''}`} yet.
            </Text>
          </Box>
        )}
      </Card>
    </>
  );
};

export default Appointments;
