import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './AppointmentEvaluationDctQuestion.styles';
import { IEvaluationContext } from '../../../../redux/modules/conversation/types';
import EvaluationSectionCollapse from '../../../provider/sessions/evaluations/evaluation-section-collapse';

type Props = {
  evaluation: any;
  selectedSection: any;
  setSelectedSection: (section: any) => void;
  evaluationContext: IEvaluationContext;
  allSectionsOpen: boolean;
};

const AppointmentEvaluationDctQuestion: FC<Props> = ({
  evaluation,
  setSelectedSection,
  selectedSection,
  evaluationContext,
  allSectionsOpen,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.body}>
        {evaluation.sections?.map((section, idx) => {
          return (
            <EvaluationSectionCollapse
              key={idx}
              index={idx}
              section={section}
              evaluationCompleted
              selectedSection={selectedSection}
              onSelectSection={setSelectedSection}
              evaluation={evaluation}
              setMultiSelect={false}
              onSelectMultiSelect={item => {
                console.log(item);
              }}
              skippedCBTs={[]}
              alfieValidation
              evaluationContext={evaluationContext}
              externallyCollapsed={allSectionsOpen}
            />
          );
        })}
      </Box>
    </>
  );
};

export { AppointmentEvaluationDctQuestion };
