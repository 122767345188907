import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// components
import { Box } from '@mui/material';
import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';
import { Table } from '@confidant-health/lib/ui/organisms/table';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';

import { stateSelector } from 'redux/modules/state/selectors';
import { stateActionCreators } from 'redux/modules/state/actions';
import { IAddPlanPayload, ICPTCode, IPlan, IPayerProvider } from 'redux/modules/state/types';

import { BaseLayout } from 'layouts/base';

import { cptColumns, providerColumns, FeeTypeLabels } from './PayerDetail.constants';
import { AddPlanDrawer } from './AddPlanDrawer';
import { AddCPTDrawer } from './AddCPTDrawer';

// styles
import { useStyles } from '../../../StateDetail.styles';

const PayerDetail: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { stateId, payerId } = useParams();
  const { selectedPayer, selectedState } = useSelector(stateSelector);

  const [selectedPlan, setSelectedPlan] = useState<IPlan>();
  const [selectedCPT, setSelectedCPT] = useState<ICPTCode>();
  const [openAddPlanDrawer, setOpenAddPlanDrawer] = useState<boolean>(false);
  const [openAddCPTDrawer, setOpenAddCPTDrawer] = useState<boolean>(false);

  const onClickAddPlan = () => {
    setSelectedPlan(null);
    setOpenAddPlanDrawer(true);
  };

  const onClickEditPlan = (e: SyntheticEvent, plan: IPlan) => {
    e.stopPropagation();
    setSelectedPlan(plan);
    setOpenAddPlanDrawer(true);
  };

  const onSubmitPayerPlan = (payload: IAddPlanPayload) => {
    if (selectedPlan) {
      dispatch(
        stateActionCreators.updatePayerPlan({
          bodyRequest: payload,
          pathParams: { payerId, planId: selectedPlan._id },
          callback: () => {
            setOpenAddPlanDrawer(false);
          },
        }),
      );
    } else {
      dispatch(
        stateActionCreators.addPayerPlan({
          bodyRequest: payload,
          pathParams: { payerId },
          callback: () => {
            setOpenAddPlanDrawer(false);
          },
        }),
      );
    }
  };

  const onSubmitCPT = (payload: ICPTCode) => {
    if (selectedCPT) {
      dispatch(
        stateActionCreators.updateCPT({
          bodyRequest: payload,
          pathParams: { planId: selectedPlan._id, cptId: selectedCPT._id },
          callback: () => {
            setOpenAddCPTDrawer(false);
          },
        }),
      );
    } else {
      dispatch(
        stateActionCreators.addPayerPlanCPT({
          bodyRequest: payload,
          pathParams: { planId: selectedPlan._id },
          callback: () => {
            setOpenAddCPTDrawer(false);
          },
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(stateActionCreators.fetchPayerDetail(payerId));
  }, []);

  const renderCPTColumns = useCallback(() => {
    return cptColumns.map(column => {
      if (column.id === 'feeType') {
        return {
          ...column,
          renderCell: (feeType: string) => FeeTypeLabels[feeType],
        };
      }
      if (column.id === 'fee') {
        return {
          ...column,
          renderCell: (fee: number | string) => `$${fee}`,
        };
      }
      if (column.id === 'requiredTimeLimits') {
        return {
          ...column,
          renderCell: requiredTimeLimits =>
            requiredTimeLimits?.min && requiredTimeLimits?.max
              ? `${requiredTimeLimits?.min} min - ${requiredTimeLimits?.max} min`
              : '',
        };
      }
      if (column.id === 'requiredCPTCodes') {
        return {
          ...column,
          renderCell: requiredCPTCodes =>
            requiredCPTCodes?.length > 0 ? requiredCPTCodes?.join(', ') : 'N/A',
        };
      }
      if (column.id === 'requiredDiagnosis') {
        return {
          ...column,
          renderCell: requiredDiagnosis =>
            requiredDiagnosis?.length > 0 ? requiredDiagnosis?.join(', ') : 'N/A',
        };
      }
      if (column.id === 'requiredCPTCodes') {
        return {
          ...column,
          renderCell: ({ min, max }) => (min && max ? `${min} min - ${max} min` : ''),
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: ({ cpt, plan }) => (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={[
                {
                  label: 'Edit CPT code',
                  onClick: () => {
                    setSelectedPlan(plan);
                    setSelectedCPT(cpt);
                    setOpenAddCPTDrawer(true);
                  },
                },
              ]}
            />
          ),
        };
      }
      return column;
    });
  }, []);

  const renderProviderColumns = useCallback(() => {
    return providerColumns.map(column => {
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ imageUrl, firstName, lastName, role, id }: any) => (
            <ProfileInfo
              type="provider"
              photo={imageUrl}
              role={role}
              fullName={`${firstName || ''} ${lastName || ''}`}
              memberId={id}
            />
          ),
        };
      }
      if (column.id === 'npi') {
        return {
          ...column,
          renderCell: (npi: string) => npi || 'N/A',
        };
      }
      if (column.id === 'isBillingProvider') {
        return {
          ...column,
          renderCell: (isBillingProvider: boolean) =>
            isBillingProvider ? (
              <Badge
                variant={badgeType.FILLED}
                style={badgeStyle.RESOLVED}
                icon="checkmark"
                className={classes.iconColorGreen}
              >
                Yes
              </Badge>
            ) : (
              <Badge
                variant={badgeType.FILLED}
                style={badgeStyle.CRITICAL}
                icon="close"
                className={classes.iconColorRed}
              >
                No
              </Badge>
            ),
        };
      }
      // if (column.id === 'actions') {
      //   return {
      //     ...column,
      //     renderCell: ({ provider, plan }) => (
      //       <Menu
      //         icon="more"
      //         className={classes.menu}
      //         itemsWrapperClassName={classes.menuItemsWrapper}
      //         items={[
      //           {
      //             label: 'Edit provider',
      //             onClick: () => {
      //               setSelectedPlan(plan);
      //               setSelectedProvider(provider);
      //               setOpenAddProviderDrawer(true);
      //             },
      //           },
      //         ]}
      //       />
      //     ),
      //   };
      // }
      return column;
    });
  }, []);

  const renderCPTCodes = (plan: IPlan) => {
    return (
      <Box mt={4} className={classes.card}>
        <Box className={classes.cardTopSection}>
          <Heading level={headingLevel.S} weight={fontWeight.BOLD} className={classes.headingText}>
            CPT codes & fee schedule
          </Heading>
          <IconButton
            icon="plus"
            variant={iconBtnType.OUTLINE}
            className={classes.addBtnWhite}
            onClick={() => {
              setSelectedPlan(plan);
              setSelectedCPT(null);
              setOpenAddCPTDrawer(true);
            }}
          >
            Add CPT
          </IconButton>
        </Box>
        <Table
          gridProps={{
            columns: renderCPTColumns(),
            data: plan?.cptCodes?.map((cpt: ICPTCode) => ({ ...cpt, actions: { cpt, plan } })),
          }}
        />
      </Box>
    );
  };

  const renderProviders = (plan: IPlan) => {
    return (
      <Box mt={2} className={classes.card}>
        <Box className={classes.cardTopSection}>
          <Heading level={headingLevel.S} weight={fontWeight.BOLD} className={classes.headingText}>
            Providers
          </Heading>
          {/* <IconButton
            icon="plus"
            variant={iconBtnType.OUTLINE}
            className={classes.addBtnWhite}
            onClick={() => {
              setSelectedPlan(plan);
              setSelectedProvider(null);
              setOpenAddProviderDrawer(true);
            }}
          >
            Add provider
          </IconButton> */}
        </Box>
        <Table
          gridProps={{
            columns: renderProviderColumns(),
            data: plan.providersDetails.map((p: IPayerProvider) => ({
              ...p,
              provider: {
                firstName: p?.firstName,
                lastName: p?.lastName,
                role: p?.role,
                imageUrl: p?.imageUrl,
                id: p?.userAccountId,
              },
              // actions: { provider: p, plan },
            })),
          }}
        />
      </Box>
    );
  };

  return (
    <BaseLayout>
      <AddPlanDrawer
        isOpen={openAddPlanDrawer}
        selectedPlan={selectedPlan}
        onSubmit={onSubmitPayerPlan}
        onClose={() => setOpenAddPlanDrawer(false)}
      />
      <AddCPTDrawer
        isOpen={openAddCPTDrawer}
        planName={selectedPayer?.name}
        payerName={selectedPayer?.name}
        selectedCPT={selectedCPT}
        onClose={() => setOpenAddCPTDrawer(false)}
        onSubmit={onSubmitCPT}
      />
      {/* <AddProviderDrawer
        isOpen={openAddProviderDrawer}
        selectedProvider={selectedProvider}
        onClose={() => setOpenAddProviderDrawer(false)}
        onSubmit={onSubmitProvider}
      /> */}
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Breadcrumbs
            links={[
              { href: '/admin/states', text: 'States' },
              { href: `/admin/states/${stateId}?name=general-information`, text: selectedState?.name },
              { text: selectedPayer?.name },
            ]}
          />
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={2}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              {selectedPayer?.name}
            </Heading>
            <Badge
              variant={badgeType.FILLED}
              style={selectedState?.isActive ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
            >
              {selectedPayer?.isActive ? 'Active' : 'Inactive'}
            </Badge>
          </Box>
          <IconButton
            icon="plus"
            variant={iconBtnType.PRIMARY}
            className={classes.addBtnPrimary}
            onClick={onClickAddPlan}
          >
            Add plan type
          </IconButton>
        </Box>

        {selectedPayer?.plans?.map((plan: IPlan) => {
          return (
            <Collapsable
              key={plan._id}
              open
              labelExtend={
                <Box display="flex" alignItems="center" gap={2}>
                  <Text level={textLevel.XL} weight={fontWeight.SEMI_BOLD}>
                    {plan.name}
                  </Text>
                  <Badge
                    variant={badgeType.OUTLINED}
                    style={plan.isActive ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
                    className={classes.badge}
                  >
                    <span>{plan.isActive ? 'Active' : 'Inactive'}</span>
                  </Badge>
                  <IconButton
                    icon="edit-underline"
                    onClick={(e: SyntheticEvent) => onClickEditPlan(e, plan)}
                    iconPosition={iconBtnPosition.LEFT}
                    className={classes.textBtn}
                  >
                    Edit
                  </IconButton>
                </Box>
              }
              iconPosition={IiconPosition.RIGHT}
            >
              {renderCPTCodes(plan)}
              {renderProviders(plan)}
            </Collapsable>
          );
        })}
      </Box>
    </BaseLayout>
  );
};

export { PayerDetail };
