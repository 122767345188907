/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  header: {
    height: 80,
    padding: '16px 16px 16px 24px',
    backgroundColor: colors.neutral25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
  },
  body: {
    height: 'calc(100vh - 240px)',
    padding: 32,
    backgroundColor: colors.white,
    overflowY: 'auto',
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    '& svg': {
      width: 24,
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  addTextBtn: {
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primary600,
      color: colors.white,
    },
  },
});
