import { GeneralInfo } from './components';

const SelectedComponent = () => {
  // switch (selectedScreen) {
  //   case 'general':
  //     return <GeneralInfo />;
  //   case 'psychiatric-evaluation':
  //   case 'mat-alcohol-evaluation':
  //   case 'next-steps':
  //   case 'diagnosis-assistant':
  //   case 'medications-assistant':
  //   default:
  //     return <div>In Progress...</div>;
  // }
  return <GeneralInfo />;
};

export default SelectedComponent;
