import { TableGrid } from '@confidant-health/lib/ui/organisms/table';

export const tableColumns: TableGrid.TableColumn[] = [
  { id: 'provider', label: 'Provider', sortable: false },
  { id: 'role', label: 'Role', sortable: false },
  { id: 'firstAvailability', label: 'First available', sortable: false },
  { id: 'daysAvailable', label: 'Days available', sortable: false },
  { id: 'timeDurations', label: 'Time durations', sortable: false },
  { id: 'startEndTime', label: 'Start & End time', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const durationOptions = [
  { value: '7', label: '7 days' },
  { value: '14', label: '14 days' },
  { value: '30', label: '30 days' },
];

export const providerTypes = [
  {
    label: 'All Types',
    value: 'all',
  },
  {
    label: 'Providers',
    value: 'providers',
  },
  {
    label: 'Prescribers',
    value: 'prescribers',
  },
  {
    label: 'Matchmakers',
    value: 'matchmakers',
  },
  {
    label: 'Social workers',
    value: 'social-workers',
  },
  {
    label: 'Coaches',
    value: 'coaches',
  },
];

export const timeDurations = [
  {
    label: '15 Minutes',
    value: 15,
  },
  {
    label: '30 Minutes',
    value: 30,
  },
  {
    label: '45 Minutes',
    value: 45,
  },
  {
    label: '1 Hour',
    value: 60,
  },
  {
    label: '1 Hour 15 Minutes',
    value: 60 + 15,
  },
  {
    label: '1 Hour 30 Minutes',
    value: 60 + 30,
  },
  {
    label: '1 Hour 45 Minutes',
    value: 60 + 45,
  },
  {
    label: '2 Hours',
    value: 120,
  },
];

export const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const timesOfDay = [
  {
    label: 'All times of day',
    startTime: '0000',
    endTime: '2359',
  },
  {
    label: 'Morning (6am - 9am)',
    startTime: '0600',
    endTime: '0900',
  },
  {
    label: 'Early morning (9am - 12pm)',
    startTime: '0900',
    endTime: '1200',
  },
  {
    label: 'Afternoon (12pm - 3pm)',
    startTime: '1200',
    endTime: '1500',
  },
  {
    label: 'Early evening (3am - 6pm)',
    startTime: '1500',
    endTime: '1800',
  },
  {
    label: 'Evening (6am - 9pm)',
    startTime: '1800',
    endTime: '2100',
  },
];
