export const levelSelects = [
  { title: 'Active', value: 'Active' },
  { title: 'ActiveExternalRX', value: 'Active_external_rx' },
  { title: 'Critical', value: 'Critical' },
  { title: 'High', value: 'High' },
  { title: 'InActive', value: 'Inactive' },
  { title: 'InactiveExternalRX', value: 'Inactive_external_rx' },
  { title: 'Low', value: 'Low' },
  { title: 'Medium', value: 'Medium' },
  { title: 'Misreported', value: 'Misreported' },
  { title: 'Negative', value: 'Negative' },
  { title: 'Neutral', value: 'Neutral' },
  { title: 'Possible', value: 'Possible' },
  { title: 'Positive', value: 'Positive' },
  { title: 'Resolved', value: 'Resolved' },
  { title: 'Unrelated', value: 'Unrelated' },
];

export const requireList = [
  { label: 'Require Diagnosis info', name: 'requireDiagnosisInfo' },
  { label: 'Require Interference with life info', name: 'requireInterferenceWithLifeInfo' },
  { label: 'Require Notes info', name: 'requireNotes' },
  { label: 'Require RX info', name: 'requireRxInfo' },
  { label: 'Require Related To info', name: 'requireRelatedToInfo' },
  { label: 'Require Substance Use info', name: 'requireSubstanceUse' },
  { label: 'Require Icd10 codes', name: 'requireIcd10Codes' },
];

export const TAXONOMY_TYPES = {
  CONCEPT: 'CONCEPT',
  PATIENT_DATA_DOMAIN: 'PATIENT_DATA_DOMAIN',
  PATIENT_DATA_ELEMENT: 'PATIENT_DATA_ELEMENT',
};
