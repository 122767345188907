import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuth } from 'redux/modules/auth/selectors';
import './AppointmentEvaluationAudioPlayer.css';
import H5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Box, Button, Grid } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';

type Props = {
  src: string;
  onTimeUpdated: (time: number) => void;
  scrollLocked: boolean;
  onLockChanged: (locked: boolean) => void;
  onEditClicked: () => void;
  editMode: boolean;
  timedCB: {
    id?: string;
    from: number;
    to: number;
  };
  onSessionDurationCaptured: (duration: number) => void;
  analysisInProgress: boolean;
};
const AppointmentEvaluationAudioPlayer: FC<Props> = ({
  src,
  onTimeUpdated,
  scrollLocked,
  onLockChanged,
  onEditClicked,
  editMode,
  timedCB,
  onSessionDurationCaptured,
  analysisInProgress,
}) => {
  const { isAdmin } = useSelector(getAuth);
  const playerRef = useRef<H5AudioPlayer>();
  const [durationInSeconds, setDuration] = useState(0);

  useEffect(() => {
    if (!scrollLocked && timedCB) {
      const player = playerRef.current;
      if (player) {
        player.audio.current.currentTime = timedCB.from;
        const pb = document.querySelector('.rhap_progress-bar');
        if (pb) {
          const pbWidth = pb.clientWidth;
          const cbSpanSeconds = timedCB.to - timedCB.from;
          const highlightWidth = pbWidth * (cbSpanSeconds / durationInSeconds);
          const el = document.querySelector('.rhap_progress-bar');
          const existingHighlights = document.querySelectorAll('.progress-bar-overlap');
          existingHighlights.forEach(eh => eh.remove());
          if (el) {
            const overlapNode = document.createElement('div');
            overlapNode.setAttribute('class', 'progress-bar-overlap');
            const offsetPercent = 100 * (timedCB.from / durationInSeconds);
            const leftPercentage = offsetPercent * (pbWidth / 100);
            overlapNode.setAttribute('style', `left: ${leftPercentage}px; width: ${highlightWidth}px;`);
            el.appendChild(overlapNode);
          }
        }
      }
    }
  }, [timedCB, scrollLocked]);
  return (
    <Grid container>
      <Box flex={1}>
        <H5AudioPlayer
          src={src}
          customAdditionalControls={[]}
          showJumpControls={false}
          customVolumeControls={[]}
          autoPlayAfterSrcChange={false}
          autoPlay={false}
          layout="horizontal-reverse"
          ref={playerRef}
          listenInterval={200}
          onListen={(e: any) => {
            if (e.type === 'timeupdate') {
              onTimeUpdated(e.target.currentTime);
            }
          }}
          onLoadedMetaData={(e: any) => {
            setDuration(e.target.duration);
            onSessionDurationCaptured(e.target.duration);
          }}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          className={scrollLocked ? 'action-btn' : 'action-btn-selected'}
          onClick={() => onLockChanged(!scrollLocked)}
        >
          {scrollLocked ? (
            <Icons color="#111c24" glyph="lock" className="btn-icon" />
          ) : (
            <Icons color="#111c24" glyph="unlock-white" className="btn-icon" />
          )}
        </Button>
        {!analysisInProgress && isAdmin && (
          <Button
            variant="contained"
            className={editMode ? 'action-btn-selected' : 'action-btn'}
            onClick={onEditClicked}
          >
            {editMode ? (
              <Icons color="#111c24" glyph="switch-alternate-white" className="btn-icon" />
            ) : (
              <Icons color="#111c24" glyph="switch-alternate-outline" className="btn-icon" />
            )}
          </Button>
        )}
      </Box>
    </Grid>
  );
};

export { AppointmentEvaluationAudioPlayer };
