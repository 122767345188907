import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import clsx from 'clsx';

import {
  Text,
  fontWeight,
  Heading,
  headingLevel,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { useSelector } from 'react-redux';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { Rating } from '@confidant-health/lib/ui/atoms/rating';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { getAppointment } from 'redux/modules/appointment/selectors';
import { getAuth } from 'redux/modules/auth/selectors';

import { patchAppointment } from 'services/appointment/appointment.service';
import { FEEDBACK_ISSUES_LIST } from 'constants/CommonConstants';

import { FeedbackAppointmentSchema } from './FeedbackAppointment.schema';
import { useStyles } from './FeedbackAppointment.styles';
import { PatchAction } from '../../../../../redux/modules/appointment/types';

type Props = {
  open?: boolean;
  onClose?: (isSubmitted?: boolean) => void;
  appointmentId?: string;
};
const FeedbackAppointment: FC<Props> = ({ open, onClose, appointmentId }) => {
  const classes = useStyles();
  const { appointments: appointmentsState = [] } = useSelector(getAppointment);
  const { isAdmin } = useSelector(getAuth);
  const [appointment, setAppointment] = useState<IAppointment | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { rating: 0, notes: '', issues: [] },
    validationSchema: FeedbackAppointmentSchema,
    onSubmit: async obj => {
      try {
        setIsSaving(true);
        setError(null);
        const payload = {
          action: PatchAction.ADD_PROVIDER_FEEDBACK,
          sessionQualityDetails: {
            rating: obj?.rating,
            qualityFeedback: obj?.notes,
            connectionIssues: obj?.issues?.includes(FEEDBACK_ISSUES_LIST[0]),
            reminderIssues: obj?.issues?.includes(FEEDBACK_ISSUES_LIST[1]),
            communicationIssues: obj?.issues?.includes(FEEDBACK_ISSUES_LIST[2]),
          },
        };

        await patchAppointment(payload, { appointmentId });
        onClose(true);
      } catch (e) {
        setError(e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!');
      }
      setIsSaving(false);
    },
  });

  useEffect(() => {
    const item = appointmentsState.find(app => app.appointmentId === appointmentId);
    if (item) {
      setAppointment(item);
    }
  }, [appointmentId, appointmentsState]);

  const onCloseDrawer = () => onClose(false);

  const renderLabel = (str: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  const onChangeIssue = item => () => {
    const newIssues = values.issues.includes(item)
      ? values.issues.filter(i => i !== item)
      : [...values.issues, item];
    void setFieldValue('issues', newIssues);
  };

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeRating = (e: ChangeEvent<HTMLInputElement>) => {
    void setFieldValue('rating', +e.target.value);
  };

  const renderContent = () => (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            Rate your session
          </Text>
          <Text weight={fontWeight.MEDIUM} className={classes.headSubtitle}>
            {appointment?.serviceName}
          </Text>
        </Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onCloseDrawer} />
      </Box>
      <Box className={classes.content}>
        <Box className={classes.top}>
          <ProfileInfo
            type="member"
            photo={appointment?.participantImage}
            nickName={appointment?.participantName}
            fullName={`${appointment?.patientFirstName || ''} ${appointment?.patientLastName || ''}`}
            memberId={appointment?.member?.userAccountId}
            isProvider={!isAdmin}
          />
          <Text weight={fontWeight.MEDIUM} className={clsx(classes.username, classes.minutesText)}>
            {`${appointment?.serviceDuration} minutes session`}
          </Text>
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Overall call quality')}
              <Box className={classes.ratingWrap}>
                <Rating
                  value={values.rating}
                  readOnly={false}
                  className={classes.rating}
                  showValue={false}
                  defaultValue={0}
                  onChange={onChangeRating}
                  name="rating"
                />
                <Text className={classes.ratingValue} weight={fontWeight.SEMI_BOLD}>
                  {values.rating || 'Not rated yet'}
                </Text>
              </Box>
              <TextError errorMsg={touched.rating ? errors.rating?.toString() : null} />
            </Box>
            <Box className={classes.section} style={{ gap: 16 }}>
              {renderLabel('Have you experienced any issues?')}
              {FEEDBACK_ISSUES_LIST.map(item => (
                <FormControlLabel
                  key={item}
                  control={<Checkbox />}
                  checked={values.issues.includes(item)}
                  onChange={onChangeIssue(item)}
                  className={classes.checkboxLabel}
                  label={item}
                />
              ))}
            </Box>
            <Box className={classes.section}>
              {renderLabel('Additional feedback and thoughts')}
              <Textarea
                value={values.notes}
                name="notes"
                onChange={handleChange}
                onBlur={setTouched('notes')}
                minRows={5}
                placeholder="Enter your notes"
              />
              <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
            </Box>
          </Box>
        </form>
      </Box>
      <Box className={classes.footer}>
        <Box>
          <TextError errorMsg={error} />
        </Box>
        <IconButton
          onClick={handleSubmit}
          disabled={isSaving || values.rating === 0}
          className={classes.newReportBtn}
          variant={iconBtnType.PRIMARY}
        >
          {isSaving ? 'Saving' : 'Save feedback'}
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Drawer open={open} onClose={onCloseDrawer} variant={drawerType.NORMAL} className={classes.drawer}>
      {renderContent()}
    </Drawer>
  );
};

export { FeedbackAppointment };
