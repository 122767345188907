import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    display: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  totalBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addBtn: {
    color: '#fff',
    fontSize: 16,
    whiteSpace: 'nowrap',
    '& svg': {
      width: 20,
      marginRight: 4,
      '& path': {
        fill: '#fff',
      },
    },
  },
  appointmentList: {
    backgroundColor: colors.white,
  },
  arrowIconBtn: {
    '& svg': {
      width: 16,
      height: 16,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  roleBadge: {
    borderRadius: 20,
    whiteSpace: 'nowrap',
    height: 18,
    padding: '2px 8px',
    fontSize: 12,
    width: 'fit-content',
    border: 'none',
    marginTop: 2,
    '&.Provider': {
      backgroundColor: colors.indigo50,
      color: colors.indigo600,
    },
    '&.Coach': {
      backgroundColor: colors.warning50,
      color: colors.warning600,
    },
    '&.Prescriber': {
      backgroundColor: colors.cyan50,
      color: colors.cyan600,
    },
    '&.Matchmaker': {
      backgroundColor: '#FEF6EE',
      color: '#E04F16',
    },
    '&.Socialworker': {
      backgroundColor: colors.purple50,
      color: colors.purple600,
    },
  },
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    width: 70,
    fontSize: 14,
    color: '#fff',
    backgroundColor: colors.success500,
  },
  arrowIcon: {
    width: 24,
    height: 20,
    padding: 0,
    backgroundColor: colors.green50,
    borderColor: colors.green50,
    '& svg': {
      width: 14,
      transform: 'rotate(-90deg)',
      '& path': {
        fill: colors.green600,
      },
    },
  },
  decreaseBadge: {
    borderColor: '#FFF1F3',
    backgroundColor: '#FFF1F3',
    '& svg path': {
      fill: '#E31B53',
    },
  },
  noChangeBadge: {
    borderColor: colors.primary25,
    backgroundColor: colors.primary25,
    '& svg': {
      transform: 'rotate(180deg)',
    },
    '& svg path': {
      fill: colors.primary500,
    },
  },
  memberColumn: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  id: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: colors.neutral900,
  },
  name: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  timeWrap: {
    fontSize: 14,
    lineHeight: '20px',
    height: 44,
  },
  date: {
    color: colors.neutral900,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  time: {
    whiteSpace: 'nowrap',
    color: colors.neutral600,
    fontWeight: 500,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      '&:last-child': {
        color: colors.destructive600,
        '& svg path': {
          fill: colors.destructive600,
        },
      },
    },
  },
  statusLabel: {
    borderRadius: 100,
    minHeight: 24,
    minWidth: 'auto',
    height: 'auto',
    fontSize: 12,
    color: colors.success600,
  },
  statusFee: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '36px',
    color: colors.neutral900,
    marginTop: 8,
  },
  unpaidStatus: {
    color: colors.destructive600,
  },
  submittedStatus: {
    color: colors.primary600,
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '1px',
    color: colors.neutral600,
  },
});
