import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  name: {
    color: colors.neutral900,
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    display: 'block',
  },
  fullname: {
    color: colors.neutral600,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  userWrap: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    width: 150,
  },
});
