import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import debounce from 'debounce';

import { fontWeight, Text, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { TextField } from '@confidant-health/lib/ui/atoms/text-field';

import { getFullImagePath } from 'utils/CommonUtils';
// import { useDispatch } from 'react-redux';
// import { appointmentActionCreators } from 'redux/modules/appointment';
// import { profileActionCreators } from 'redux/modules/profile';
import { useStyles } from './StepOne.styles';
import dayjs from '../../../../../utils/dayjs';

type Props = {
  options: any[];
  selectedItem: any;
  selectedPatient?: any;
  careTeam?: any;
  allProviders?: any;
  selectType: 'member' | 'provider';
  error?: string;
  className?: string;
  userId?: string;
  fetchMembers?(searchKey?: string, pageNumber?: number): void;
  fetchProviders?(searchKey?: string, pageNumber?: number): void;
  onChange: (item: any) => void;
  onChangeNewProviderSelected?: (value: boolean) => void;
  isMemberList?: boolean;
  isLoading?: boolean;
  setPageNumber?(item: number): void;
  pageNumber?: number;
  setSearchKeyWithPagination?: React.Dispatch<React.SetStateAction<string>>;
  showSimpleSearch?: boolean;
  setSimpleSearchKey?: React.Dispatch<React.SetStateAction<string>>;
  currentStepToService?: () => void;
  onChangePreSelected?: () => void;
};

const StepOneNew: FC<Props> = ({
  selectedItem,
  selectedPatient,
  selectType,
  onChange,
  onChangeNewProviderSelected,
  options,
  error,
  careTeam,
  className = '',
  userId = null,
  fetchMembers,
  fetchProviders,
  isMemberList,
  isLoading,
  setPageNumber,
  pageNumber,
  setSearchKeyWithPagination,
  showSimpleSearch = false,
  setSimpleSearchKey,
  currentStepToService,
  allProviders,
  onChangePreSelected,
}) => {
  const listInnerRef = useRef();
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [membersFiltered, setMembersFiltered] = useState([]);
  const [, setProviderFiltered] = useState([]);
  const [checkMemberList, setCheckMemberList] = useState(false);
  const [firstCheck, setFirstCheck] = useState(false);
  const [careTeamMember, setCareTeamMember] = useState(careTeam);
  const [, setInsuranceChecked] = useState(false);
  const [, setStateChecked] = useState(false);

  useEffect(() => {
    selectedPatient?.state ? setStateChecked(true) : setStateChecked(false);
    selectedPatient?.insurance ? setInsuranceChecked(true) : setInsuranceChecked(false);
  }, [selectedPatient]);

  useEffect(() => {
    if (options.length > 0 && selectType === 'member') {
      setMembersFiltered(options);
    } else {
      setProviderFiltered(options);
    }
  }, [options]);

  useEffect(() => {
    if (userId) {
      if (!checkMemberList) {
        if (!firstCheck) {
          setFirstCheck(true);
          const preSelected = options.find(option => option.id === userId);
          if (preSelected) {
            options.sort((x, y) => {
              return x.id === preSelected.id ? -1 : y.id === preSelected.id ? 1 : 0;
            });
            onChange(preSelected);
            if (selectType === 'member') {
              onChangePreSelected();
            }
          }
        }
      }
    }
  }, [userId, options]);

  useEffect(() => {
    if (selectType === 'provider') {
      const filteredCareTeam = careTeam.filter(item => item.name.toLowerCase().includes(searchKey));
      if (searchKey !== '' && filteredCareTeam.length !== 0) {
        setCareTeamMember(filteredCareTeam);
      } else if (searchKey !== '' && filteredCareTeam.length === 0) {
        setCareTeamMember([]);
        onChange(null);
      } else if (searchKey === '') {
        setCareTeamMember(careTeam);
      }
    }
    if (selectType === 'member') {
      if (searchKey === '') {
        fetchMembers();
      }
    }
  }, [careTeam, searchKey]);

  const fetchMembersCallback = useCallback(
    debounce((key, page, step) => {
      if (step === 'member' && fetchMembers) {
        fetchMembers(key, page);
      } else if (step === 'provider' && fetchProviders) {
        // fetchProviders(key, page);
      } else {
        const newItems = options.filter(item =>
          `${item.firstName} ${item.lastName}`.toLowerCase().includes(key),
        );
        setMembersFiltered(newItems);
      }
    }, 300),
    [options],
  );

  const onClickItem = item => () => {
    onChangeNewProviderSelected(false);
    if (isMemberList) {
      setCheckMemberList(true);
      /* const payload: any = {
        initial: true,
        memberId: item?.id,
      }; */
      // dispatch(profileActionCreators.fetchCareTeam({ userId: item.id }));
      // dispatch(appointmentActionCreators.fetchAllProviderServices(payload));
    }
    onChange(item);
  };

  const onChangeSearchKey = (val: string) => {
    fetchMembersCallback(val.toLowerCase(), 0, selectType);
    setPageNumber(0);
    setSearchKey(val);
  };

  useEffect(() => {
    setMembersFiltered(options);
  }, [options]);

  useEffect(() => {
    setSearchKey('');
  }, [selectType]);

  const onLoadMoreClick = () => {
    fetchMembersCallback(searchKey.toLocaleLowerCase() || '', 1 + pageNumber, selectType);
    setPageNumber(pageNumber + 1);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (parseFloat(scrollTop) + parseFloat(clientHeight) === scrollHeight) {
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };

  const renderProvider = item => {
    const { id, nickName, fullName, nextAppointment, profilePicture } = item;
    const start = item?.schedule?.slots ? item?.schedule?.slots?.[0]?.start : null;
    return (
      <Box
        className={clsx(classes.providerInfo, { [classes.selected]: selectedItem?.id === id })}
        key={id}
        onClick={onClickItem(item)}
      >
        <Box className={classes.providerProfile}>
          <ProfileInfo
            type={selectType}
            photo={getFullImagePath(profilePicture)}
            nickName={nickName}
            fullName={fullName !== ' ' ? fullName : nickName}
            role={item.designation}
          />
        </Box>
        {start ? (
          <Box alignItems="center" gap="12px" width="48%">
            <TextField label="Next available" text={dayjs(start).format('dddd, MM/DD/YYYY - h:mm A')} />
          </Box>
        ) : (
          ''
        )}
        <Box display="flex" flexDirection="column" gap="4px" width="40%">
          <TextField label="Next appointment" text="" />
          {nextAppointment ? (
            <Box display="flex" alignItems="center" gap="4px">
              <Icons
                glyph="checked-circle-outlined"
                color={colors.green600}
                className={classes.nextAppointmentIcon}
              />
              <Text weight={fontWeight.MEDIUM} className={classes.insuranceText2}>
                {dayjs(nextAppointment.startTime).format('MM/DD/YYYY - h:mm A')}
              </Text>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" gap="4px">
              <Icons
                glyph="x-circle-outlined"
                color={colors.neutral500}
                className={classes.noInsuranceIcon}
              />
              <Text weight={fontWeight.MEDIUM} className={classes.insuranceText2}>
                Not scheduled
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  /* const showNextSlot = tomorrowSlot => {
    const currentDateTimestamp = dayjs().format('DD/MM/YYYY');
    const tomorrowDateTimestamp = dayjs(tomorrowSlot).add(1, 'day').format('DD/MM/YYYY');
    const todayDateTimestamp = dayjs(tomorrowSlot).format('DD/MM/YYYY');
    if (currentDateTimestamp === todayDateTimestamp) {
      return `Today -  ${dayjs(tomorrowSlot).format('hh:mm A')}`;
    }
    if (currentDateTimestamp === tomorrowDateTimestamp) {
      return `Tomorrow -  ${dayjs(tomorrowSlot).format('hh:mm A')}`;
    }
    return dayjs(tomorrowSlot).format('dddd, MMMM D, YYYY h:mm A');
  }; */

  const renderMember = item => {
    const { id, nickName, state, dob, insurance, firstName, lastName, uuid, photo } = item;
    // const patientFullName = fullName?.trim();
    // const patientNickName = nickName?.trim();
    return (
      <Box
        className={clsx(classes.userinfo, { [classes.selected]: selectedItem?.id === id })}
        key={id}
        onClick={onClickItem(item)}
        display="flex"
        gap="4px"
      >
        <Box className={classes.profile}>
          <ProfileInfo
            type="member"
            nickName={firstName && lastName ? `${firstName || ''} ${lastName || ''}` : nickName || 'No Name'}
            fullName={uuid}
            role={item.designation}
            photo={photo}
          />
        </Box>
        <Box alignItems="center" gap="12px" className={classes.listItemContainer}>
          <Box display="flex" flexDirection="column" gap="4px">
            <TextField label="Insurance" text="" />
            {insurance ? (
              <Box display="flex" alignItems="center" justifyContent="stretch" gap="6px">
                <Box>
                  <Icons glyph="check-circle" color={colors.green600} className={classes.insuranceIcon} />
                </Box>
                <Box>
                  <Text weight={fontWeight.MEDIUM} className={classes.insuranceText}>
                    {insurance}
                  </Text>
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap="6px">
                <Icons
                  glyph="clear-circle"
                  color={colors.destructive500}
                  className={classes.noInsuranceIcon}
                />
                <Text weight={fontWeight.MEDIUM} className={classes.insuranceText}>
                  None
                </Text>
              </Box>
            )}
          </Box>
        </Box>
        <Box alignItems="center" className={classes.listItemStateContainer}>
          <TextField label="State" text={state || '-'} />
        </Box>
        <Box alignItems="center" className={classes.listItemContainer}>
          <TextField label="DOB" text={dob || '-'} />
        </Box>
        <Box alignItems="center" className={classes.listItemMemberIdContainer}>
          <TextField label="Member ID" text={uuid} />
        </Box>
      </Box>
    );
  };
  if (selectedItem) {
    options.sort((a, b) => {
      if (a.id === selectedItem.id) return -1;
      if (b.id === selectedItem.id) return 1;
      return 0;
    });
  }

  const renderProviderList = () => {
    let careTeamProvider = careTeamMember?.map(connection => {
      const matchingProvider = allProviders?.find(provider => connection?.connectionId === provider?.id);
      if (matchingProvider) {
        return { ...connection, ...matchingProvider };
      }
      return connection;
    });
    careTeamProvider = careTeamProvider?.filter(
      provider =>
        provider?.schedule !== undefined &&
        provider?.schedule !== null &&
        provider?.schedule?.slots?.length !== 0,
    );

    return <>{careTeamProvider?.map(renderProvider)}</>;
  };

  /* const renderProviderListHeadings = (text, state, insurance) => {
    return (
      <Box padding="19px">
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} className={classes.filterText}>
          {text}
        </Text>
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} color={colors.secondary500}>
          {insurance}
        </Text>
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} className={classes.filterText}>
          In {state}
        </Text>
      </Box>
    );
  }; */

  const onChangeSimpleSearch = (val: string) => {
    if (selectType === 'member') {
      setSimpleSearchKey(val?.toLocaleLowerCase() || '');
    }
  };
  return (
    <Box className={className}>
      <Box className={classes.searchBox}>
        {selectType === 'provider' && (
          <Box
            className={classes.bookNewProviderBox}
            onClick={() => {
              onChangeNewProviderSelected(true);
              currentStepToService();
              onChange(null);
            }}
          >
            <Icons
              glyph="plus-circle-filled"
              color={colors.primary}
              className={classes.bookNewProviderIcon}
            />
            <Box className={classes.bookNewProviderText}>Book with new provider</Box>
          </Box>
        )}
        {selectType === 'provider' && <Box className={classes.heading}>Book with care team</Box>}
        <Input
          className={classes.searchInput}
          variant={inputType.SEARCH}
          placeholder="Search"
          value={searchKey}
          onChange={onChangeSearchKey}
        />
        {error && <TextError errorMsg={error} className={clsx(classes.nickName, classes.error)} />}
      </Box>
      {showSimpleSearch && (
        <Box className={classes.searchBox}>
          <Input
            className={classes.searchInput}
            variant={inputType.SEARCH}
            placeholder="Search"
            value={searchKey}
            onChange={onChangeSimpleSearch}
          />
          {error && <TextError errorMsg={error} className={clsx(classes.nickName, classes.error)} />}
        </Box>
      )}
      <Box
        className={`list-item ${selectType === 'member' ? '' : classes.providerList}`}
        onScroll={onScroll}
        ref={listInnerRef}
        sx={{ padding: 3 }}
      >
        {options?.length > 0 ? (
          selectType === 'member' ? (
            membersFiltered?.map(renderMember)
          ) : (
            renderProviderList()
          )
        ) : (
          <Text weight={fontWeight.MEDIUM} className={clsx(classes.nickName, classes.emptyList)}>
            There is no item
          </Text>
        )}
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.loadingWrap}>
          {isLoading ? (
            <>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </>
          ) : setSearchKeyWithPagination ? (
            <Button variant={btnType.TEXT} className={classes.loadMoreBtn} onClick={onLoadMoreClick}>
              Load more
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default StepOneNew;
