import { FC, ReactNode, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// components
import { Box } from '@mui/material';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { path2type } from 'pages/admin/conversation/ConversationDetail.mock';
import AddCollectionTemplate from 'pages/admin/data-collection-templates/components/add-collection-template';
// services
import { getDCTDetails, updateDCT, updateEvaluationDCT } from 'services/conversation/conversation.service';
import { showSnackbar } from 'redux/modules/snackbar';
import { CONVERSATION_TYPES } from 'constants/CommonConstants';
// types
import { IDCTDetail, IDCTScoring } from 'redux/modules/conversation/types';
// styles
import { useStyles } from './ConversationDetail.styles';

const ConversationDetail: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { templateId } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dct, setDct] = useState<IDCTDetail>();
  const [, setScoring] = useState<IDCTScoring>();
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const fetchDctDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await getDCTDetails(templateId);
      setDct({ id: templateId, ...data.dct });
      setScoring(data.scoring);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const updateDct = dctRequestPayload => {
    if (path2type[path].type === CONVERSATION_TYPES.EVALUATION_DCT) {
      updateEvaluationDCT(dct.id, dctRequestPayload)
        .then(() => {
          setShowEdit(false);
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Dct successfully updated',
            }),
          );
          void fetchDctDetails();
        })
        .catch(err => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
            }),
          );
        });
      return;
    }

    updateDCT(dct.id, dctRequestPayload)
      .then(() => {
        setShowEdit(false);
        void fetchDctDetails();
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Dct successfully updated',
          }),
        );
      })
      .catch(err => {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
          }),
        );
      });
  };
  useEffect(() => {
    void fetchDctDetails();
  }, []);

  const renderLabel = (label: string) => (
    <Text className={classes.label} weight={fontWeight.SEMI_BOLD}>
      {label}
    </Text>
  );

  const renderInfoText = (label: string, value: string | number) => (
    <Box className={classes.info}>
      {renderLabel(label)}
      <Text className={classes.value} weight={fontWeight.MEDIUM}>
        {value}
      </Text>
    </Box>
  );

  const renderInfoIcon = (label: string, icon: ReactNode, value = '') => (
    <Box className={classes.info} style={{ alignItems: 'center' }}>
      {renderLabel(label)}
      <Box display="flex" gap={1} flex={0.6} alignItems="center">
        {icon}
        <Text className={classes.value} weight={fontWeight.MEDIUM}>
          {value}
        </Text>
      </Box>
    </Box>
  );

  return (
    <>
      {!isLoading && dct && (
        <AddCollectionTemplate
          isOpen={showEdit}
          onClose={() => setShowEdit(false)}
          dct={dct}
          onSubmit={updateDct}
        />
      )}
      <Box className={classes.root}>
        <Box className={classes.top}>
          <Heading className={classes.heading} level={headingLevel.S} weight={fontWeight.BOLD}>
            Conversation details
          </Heading>

          <IconButton
            icon="edit-underline"
            iconPosition={iconBtnPosition.RIGHT}
            className={classes.exportBtn}
            onClick={() => setShowEdit(true)}
          >
            Edit
          </IconButton>
        </Box>

        {!isLoading && dct && (
          <Box className={classes.content}>
            {renderInfoText('Name', dct?.name)}
            {renderInfoText('Version', dct?.version)}
            {renderInfoText('Last updated', dct?.lastUpdated)}
            {renderInfoIcon(
              'Scorable',
              <Icons glyph="checkmark" color={dct?.scorable ? colors.success500 : colors.neutral400} />,
              dct?.scorable ? 'Yes' : 'No',
            )}
            {path2type[path].type === CONVERSATION_TYPES.EVALUATION_DCT &&
              renderInfoIcon(
                'Use Previous Responses',
                <Icons
                  glyph="checkmark"
                  color={dct?.usePreviousResponses ? colors.success500 : colors.neutral400}
                />,
                dct?.usePreviousResponses ? 'Yes' : 'No',
              )}
            {renderInfoIcon(
              'Full Page',
              <Icons glyph="checkmark" color={dct?.fullPage ? colors.success500 : colors.neutral400} />,
              dct?.fullPage ? 'Yes' : 'No',
            )}
            {renderInfoText('Question for', dct?.responderType ?? 'MEMBER')}
            {renderInfoText('Prompt', dct?.prompt)}
            {renderInfoText('Description', dct?.description)}

            {/* These fields are commented as response to Client's feedback for now */}
            {/* {renderInfoText('Scoring description', scoring?.description)}
            {renderInfoText('Minimum score', scoring?.minScore)}
            {renderInfoText('Maximum score', scoring?.maxScore)}
            {renderInfoText('Direction of positive change', scoring?.positivityDirection)}
            {renderInfoText(
              'Very negative score range',
              `${scoring?.veryNegativeRange?.minScore} - ${scoring?.veryNegativeRange?.maxScore}`,
            )}
            {renderInfoText(
              'Negative score range',
              `${scoring?.negativeRange?.minScore} - ${scoring?.negativeRange?.maxScore}`,
            )}
            {renderInfoText(
              'Neutral score range',
              `${scoring?.neutralRange?.minScore} - ${scoring?.neutralRange?.maxScore}`,
            )}
            {renderInfoText(
              'Positive score range',
              `${scoring?.positiveRange?.minScore} - ${scoring?.positiveRange?.maxScore}`,
            )} */}
          </Box>
        )}
      </Box>
    </>
  );
};

export { ConversationDetail };
