import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { IProfileElementAssignmentQueryParams } from '../../ProfileElementDetail.types';

export const tableColumns: TableGrid.TableColumn[] = [
  { id: 'domainType', label: 'Data Domain Type', sortable: false },
  { id: 'domainElement', label: 'Data Domain Element', sortable: false },
  { id: 'importanceValue', label: 'Value', sortable: false },
  { id: 'importanceLevel', label: 'Importance Level', sortable: false },
  { id: 'assignment', label: 'Assignment', sortable: false },
  // { id: 'actions', label: '', sortable: false },
];
export const getProfileElementPriorityAndDataDomainListQuery = (
  params: IProfileElementAssignmentQueryParams,
) => {
  return {
    size: 0,
    query: {
      bool: {
        must_not: [
          {
            term: {
              'dde_importance_level_value.keyword': 'NOT_AVAILABLE',
            },
          },
        ],
        must: [
          {
            nested: {
              path: 'dataElementDetails',
              query: {
                bool: {
                  must: [
                    {
                      term: {
                        'dataElementDetails.dataElementKey.keyword': {
                          value: params.profileKey,
                        },
                      },
                    },
                  ],
                },
              },
              inner_hits: {},
            },
          },
        ],
      },
    },
    aggs: {
      by_assignments: {
        terms: {
          field: 'dde_name.keyword',
          size: 100,
          order: {
            _count: 'desc',
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          by_last_assigned_date: {
            top_hits: {
              _source: [
                'dde_id',
                'dde_type',
                'dde_name',
                'dde_importance_level_value',
                'dde_importance',
                'lastModifiedDate',
              ],
              sort: [
                {
                  lastModifiedDate: {
                    order: 'desc',
                  },
                },
              ],
              size: 1,
            },
          },
        },
      },
    },
  };
};

export const mapResponseToTableObject = (data: any) => {
  const list = { ...data.aggregations.by_assignments };
  if (list.buckets.length > 0) {
    const mappedList = list.buckets.map(hit => {
      const { value: assignment } = hit.uniqueMembers;
      const {
        dde_type: domainType,
        dde_name: domainElement,
        dde_importance_level_value: importanceValue,
        dde_importance: importanceLevel,
      } = hit.by_last_assigned_date.hits.hits[0]._source;
      return {
        domainType,
        domainElement,
        importanceValue,
        importanceLevel,
        assignment,
      };
    });
    return mappedList;
  }
  return [];
};
