import { useEffect, useState, FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Stepper } from '@confidant-health/lib/ui/molecules/stepper';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { getProfile } from 'redux/modules/profile/selectors';
import { profileActionCreators } from 'redux/modules/profile/actions';
import { scheduleActionCreators } from 'redux/modules/schedule/actions';
import { appointmentActionCreators } from 'redux/modules/appointment';
import * as memberService from 'services/member/member.service';
import { getAppointment, selectProviderServicesState } from 'redux/modules/appointment/selectors';
import { validateRecurringAppointment } from 'services/appointment/appointment.service';
import { showSnackbar } from 'redux/modules/snackbar';

import StepOneNew from './step-one/StepOneNew';
import StepThreeNew from './step-three/StepThreeNew';
import { IAddNewScheduleProps, IRecurringSlot } from './AddSchedule.types';
import {
  StepTitles,
  ScheduleSteps,
  StepsNumber,
  appointmentType,
  getSchedulingSteps,
  RECURRING_APPOINTMENT_FREQUENCIED,
} from './AddSchedule.constants';
import { useStyles } from './AddSchedule.styles';
import StepSixNew from './step-six/StepSixNew';
import StepFourNew from './step-four/StepFourNew';
import StepFiveNew from './step-five/StepFiveNew';

const AddSchedule: FC<IAddNewScheduleProps> = ({
  isOpen,
  isSaving = false,
  onClose,
  onSubmit,
  selectedDateTime,
  providerId,
  memberId,
  appointment,
  isVideoSession,
  memberDetails2,
  onClickReschedule,
  // isMemberList,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [currentStep, setCurrentStep] = useState(ScheduleSteps.SELECT_MEMBER);
  const [errorMsg, setErrorMsg] = useState(null);
  const {
    payload: { patientsList },
    providers,
    isLoading,
    careTeamList2,
    profile,
  } = useSelector(getProfile);
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const { services } = useSelector(selectProviderServicesState);
  const { allProviderServices } = useSelector(getAppointment);
  const [memberProfile, setMemberProfile] = useState({} as any);
  const [memberList, setMemberList] = useState(patientsList);
  const [withSearchLoadMoreCount, setWithSearchLoadMoreCount] = useState(0);
  const [withoutSearchLoadMoreCount, setWithoutSearchLoadMoreCount] = useState(0);
  const [previousSearchKey, setPreviousSearchKey] = useState('');
  const [loader, setLoader] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstCheck, setFirstCheck] = useState(false);
  const [preSelected, setPreSelected] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [recurringSlots, setRecurringSlots] = useState([] as IRecurringSlot[]);
  const [isLoadingRecurringSlots, setIsLoadingRecurringSlots] = useState(false);
  const [formData, setFormData] = useState({
    member: null,
    provider: null,
    time: null,
    serviceId: null,
    selectedService: null,
  });
  // incase no provider selected at step 2
  const [isNewProviderSelected, setIsNewProviderSelected] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(appointmentType.SPECIFIC_DATE);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(selectedDateTime);
  const [selectedDateRange, setSelectedDateRange] = useState([dayjs(), dayjs()]);
  const [recurringType, setRecurringType] = useState(RECURRING_APPOINTMENT_FREQUENCIED.WEEKLY);
  const filteredProvider = providers?.find(item => item?.providerId === selectedProvider?.providerId);

  const onCloseClick = () => {
    if (currentStep === ScheduleSteps.SELECT_MEMBER) {
      onClose();
      setSelectedAppointmentType(appointmentType.SPECIFIC_DATE);
      setIsNewProviderSelected(false);
    } else {
      let prevStep;
      if (currentStep === ScheduleSteps.SELECT_REVIEW) {
        prevStep = ScheduleSteps.SELECT_TYPE;
        if (isNewProviderSelected) {
          setFormData({ ...formData, time: null, provider: null });
          setSelectedProvider(null);
        } else {
          setFormData({ ...formData, time: null });
        }
        setSelectedAppointmentType(appointmentType.SPECIFIC_DATE);
      } else if (currentStep === ScheduleSteps.SELECT_TYPE) {
        setFormData({ ...formData, selectedService: null });
        prevStep = ScheduleSteps.SELECT_SERVICE;
      } else if (currentStep === ScheduleSteps.SELECT_SERVICE) {
        setFormData({ ...formData, selectedService: null });
        prevStep = ScheduleSteps.SELECT_PROVIDER;
      } else {
        // setFormData({ ...formData, member: null });
        prevStep = ScheduleSteps.SELECT_MEMBER;
      }
      /*    const prevStep =
        currentStep === ScheduleSteps.SELECT_SERVICE
          ? ScheduleSteps.SELECT_PROVIDER
          : ScheduleSteps.SELECT_MEMBER;
      setCurrentStep(prevStep); */
      setCurrentStep(prevStep);
      if (prevStep === ScheduleSteps.SELECT_PROVIDER || prevStep === ScheduleSteps.SELECT_MEMBER) {
        setSelectedAppointmentType(appointmentType.SPECIFIC_DATE);
        setIsNewProviderSelected(false);
      }
    }
  };

  const onChangeFormData = key => payload => {
    errorMsg && setErrorMsg(null);
    setFormData({ ...formData, [key]: payload });
  };

  const fetchPatients = async (search?: string, page?: number) => {
    setLoadMore(true);
    const response = await memberService.getPatientsList(
      {
        searchQuery: search ?? searchKey,
        pageNumber: pageNumber < 0 || page < 0 ? 0 : page ?? pageNumber,
        pageSize: 10,
        orderBy: 'asc',
        sortBy: '',
      },
      null,
    );
    if (response.status === 200) {
      const { data } = response;

      const updatedPatientsList = data?.patientsList?.map(member => {
        return {
          member: {
            id: member.userId,
            nickName: member.fullName,
            fullName: `${member.firstName || ''} ${member.lastName || ''}`,
            firstName: member.firstName,
            lastName: member.lastName,
            insurance: member?.insurance,
            photo: member?.photo,
          },
          id: member.userId,
          email: member.email,
          phone: member.phoneNumber,
          insurance: member.insurance,
          state: member.state,
          activeChat: member.activeChat || false,
          completed: member.totalCompletedSessions,
          fundsInWallet: member.fundsInWallet,
          dob: member.dob ? dayjs(member.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
          uuid: member.uuid,
          actions: {
            id: member.userId,
          },
        };
      });

      if (search) {
        setPreviousSearchKey(search);
        setWithoutSearchLoadMoreCount(0);
        withSearchLoadMoreCount === 0 || search !== previousSearchKey
          ? (setWithSearchLoadMoreCount(withSearchLoadMoreCount + 1), setMemberList(updatedPatientsList))
          : setMemberList([
              ...memberList?.filter(
                i => !updatedPatientsList?.find(item => item?.member?.id === i?.member?.id),
              ),
              ...updatedPatientsList,
            ]);
      } else {
        let newMemberList =
          withoutSearchLoadMoreCount === 0
            ? [...updatedPatientsList]
            : [
                ...memberList?.filter(
                  i => !updatedPatientsList?.find(item => item?.member?.id === i?.member?.id),
                ),
                ...updatedPatientsList,
              ];
        let filteredData = [];
        if (Object.keys(memberProfile).length !== 0) {
          newMemberList = [{ ...memberProfile }, ...newMemberList];
          const uniqueIds = {};
          if (newMemberList?.length !== 0) {
            newMemberList?.forEach(item => {
              const { id } = item;
              if (!uniqueIds[id]) {
                uniqueIds[id] = true;
                filteredData.push(item);
              }
            });
          }
        } else {
          filteredData = [...newMemberList];
        }
        setWithSearchLoadMoreCount(0);
        withoutSearchLoadMoreCount === 0
          ? (setWithoutSearchLoadMoreCount(withoutSearchLoadMoreCount + 1), setMemberList(filteredData))
          : setMemberList(filteredData);
      }
    }
    setLoadMore(false);
  };

  const fetchPatient2 = async data => {
    const searchWord = pathname?.includes('/session')
      ? data?.participantName?.toLowerCase() ||
        `${data?.patientFirstName?.toLowerCase() || ''} ${data?.patientLastName?.toLowerCase() || ''}`
      : data?.fullName?.toLowerCase() || data?.name?.toLowerCase();
    const response = await memberService.getPatientsList(
      {
        searchQuery: searchWord,
        pageNumber: 0,
        pageSize: 300,
        orderBy: 'asc',
        sortBy: '',
      },
      null,
    );
    const updatedPatientsList2 = response?.data?.patientsList?.map(member => {
      return {
        ...member,
        member: {
          id: member.userId,
          nickName: member.fullName,
          fullName: `${member.firstName || ''} ${member.lastName || ''}`,
          firstName: member.firstName,
          lastName: member.lastName,
          insurance: member?.insurance,
          photo: '',
        },
        id: member.userId,
        email: member.email,
        phone: member.phoneNumber,
        insurance: member.insurance,
        nickName: member.fullName,
        state: member.state,
        activeChat: member.activeChat || false,
        completed: member.totalCompletedSessions,
        fundsInWallet: member.fundsInWallet,
        dob: member.dob ? dayjs(member.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
        uuid: member.uuid,
        actions: {
          id: member.userId,
        },
      };
    });
    const foundPatient2 = updatedPatientsList2.find(item => item?.member?.id === memberId);
    if (foundPatient2 !== undefined && foundPatient2) {
      setFirstCheck(true);
      setCurrentStep(ScheduleSteps.SELECT_PROVIDER);
      setFormData({ ...formData, member: foundPatient2 });
      setMemberProfile(foundPatient2);
      // setMemberList([...foundPatient2, ...memberList]);
    }
    return foundPatient2;
  };

  const fetchMembers = (searchQuery = '', page) => {
    fetchPatients(searchQuery, page).catch(error => console.log({ error }));

    const foundPatient = patientsList?.find(item => item?.member?.id === memberId);
    if (foundPatient !== undefined && (foundPatient || !(foundPatient && searchQuery))) {
      if ((pathname?.includes('/session') || pathname?.includes('/chats')) && !firstCheck) {
        fetchPatient2(pathname?.includes('/session') ? appointment : memberDetails2)
          .then(res => setMemberList([...res, ...memberList]))
          .catch(e => console.warn(e));
      } else {
        setMemberProfile(foundPatient);
        setLoader(false);
      }
      // setMemberList([{foundPatient}, ...memberList]);
      return;
    }

    if (memberId && !searchQuery && !foundPatient) {
      if ((pathname?.includes('/session') || pathname?.includes('/chats')) && !firstCheck) {
        fetchPatient2(pathname?.includes('/session') ? appointment : memberDetails2)
          .then(res => setMemberList([...res, ...memberList]))
          .catch(e => console.warn(e));
      } else {
        memberService
          .getAdminProfile({ userId: memberId || '' })
          .then(res => {
            setMemberProfile(res.data);
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      }
    } else {
      setLoader(false);
    }
  };
  const fetchProviders = (searchQuery = '') => {
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery,
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  };
  useEffect(() => {
    if (memberProfile) {
      if (pathname?.includes('/session') || pathname?.includes('/chats')) {
        setMemberList([{ ...memberProfile }, ...patientsList?.filter(item => item.id !== memberId)]);
      } else {
        setMemberList([
          {
            ...memberProfile,
            id: memberId,
            nickName: memberProfile?.firstName || '',
            phone: memberProfile?.phoneNumber || '',
            email: memberProfile?.emailAddress || '',
            photo: memberProfile?.profileImage || '',
            fullName: `${memberProfile?.fullName || ''} ${memberProfile?.lastName || ''}`,
          },
          ...patientsList?.filter(item => item.id !== memberId),
        ]);
      }
    } else {
      setMemberList(patientsList);
    }
    if (!pathname?.includes('/session') && !pathname?.includes('/chats')) setMemberList(patientsList);
  }, [memberProfile, patientsList]);

  /* const clearingStates = () => {
    setFormData({
      member: null,
      provider: null,
      time: null,
      serviceId: null,
      selectedService: null,
    });
    setIsNewProviderSelected(false);
    setSelectedProvider(null);
    setSelectedAppointmentType(appointmentType.SPECIFIC_DATE);
    setSelectedServiceId(null);
  }; */

  const onClickContinue = () => {
    if (currentStep === ScheduleSteps.SELECT_MEMBER) {
      if (!formData?.member) {
        setErrorMsg('Please select a member!');
        return;
      }
    }
    if (currentStep === ScheduleSteps.SELECT_PROVIDER && !formData?.provider && !isNewProviderSelected) {
      setErrorMsg('Please select a provider!');
      return;
    }
    /* if (currentStep === ScheduleSteps.SELECT_MEMBER && formData?.member) {
      dispatch(profileActionCreators.fetchCareTeam({ userId: formData?.member.id }));
    } */
    if (currentStep === ScheduleSteps.SELECT_PROVIDER) {
      dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: formData?.provider.id }));
      //  setFormData({ ...formData, provider:  });
    }
    if (currentStep === ScheduleSteps.SELECT_SERVICE && !formData?.selectedService) {
      setErrorMsg('Please select a service!');
      return;
    }
    // via care team flow
    if (
      currentStep === ScheduleSteps.SELECT_TYPE &&
      !isNewProviderSelected &&
      selectedAppointmentType === appointmentType.SPECIFIC_DATE &&
      !formData?.time
    ) {
      setErrorMsg('Please select a Time!');
      return;
    }
    if (
      currentStep === ScheduleSteps.SELECT_TYPE &&
      !isNewProviderSelected &&
      selectedAppointmentType === appointmentType.SPECIFIC_DATE &&
      !formData?.time
    ) {
      if (!formData?.time) {
        setErrorMsg('Please select a Time!');
        return;
      }
      if (!formData?.time) {
        setErrorMsg('Please select a Time!');
        return;
      }
      if (selectedServiceId) {
        setErrorMsg('Please select a Provider!');
        return;
      }
    }

    if (
      currentStep === ScheduleSteps.SELECT_TYPE &&
      selectedAppointmentType === appointmentType.INSTANT_SESSION
    ) {
      const formData2 = {
        member: formData?.member,
        provider: { ...profile, id: profile?.providerId },
        serviceId: selectedServiceId,
        time: {
          start: dayjs().add(5, 'minute').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          end: dayjs().add(5, 'minute').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        },
        instantAppointment: true,
      };
      onSubmit(formData2);
      // clearingStates();
    }
    if (
      currentStep === ScheduleSteps.SELECT_TYPE &&
      selectedAppointmentType === appointmentType.SCHEDULING_LINK
    ) {
      onClose();
      // clearingStates();
    }
    if (
      (currentStep === ScheduleSteps.SELECT_TYPE &&
        !!formData?.time &&
        !isNewProviderSelected &&
        recurringType &&
        selectedAppointmentType === appointmentType.RECURRING) ||
      (currentStep === ScheduleSteps.SELECT_TYPE &&
        !!formData?.time &&
        selectedAppointmentType === appointmentType.RECURRING &&
        isNewProviderSelected &&
        selectedProvider &&
        recurringType &&
        selectedServiceId) ||
      currentStep === ScheduleSteps.CHECK_AVAILABILITY
    ) {
      nextStep(currentStep);
      return;
    }
    if (currentStep !== ScheduleSteps.SELECT_REVIEW) {
      if (selectedAppointmentType === appointmentType.SPECIFIC_DATE) {
        nextStep(currentStep);
      } else {
        nextStep2(currentStep);
      }
    } else {
      const formData2 =
        selectedAppointmentType === appointmentType.RECURRING
          ? {
              member: formData?.member,
              provider: !isNewProviderSelected
                ? formData?.provider
                : { ...selectedProvider, id: selectedProvider?.providerId },
              serviceId: selectedServiceId,
              time: formData?.time,
              instantAppointment: false,
              isRecurring: true,
              recurringType,
            }
          : {
              member: formData?.member,
              provider: !isNewProviderSelected
                ? formData?.provider
                : { ...selectedProvider, id: selectedProvider?.providerId },
              serviceId: selectedServiceId,
              time: formData?.time,
              instantAppointment: false,
            };
      onSubmit(formData2);
      // clearingStates();
    }
  };
  const nextStep = value => {
    switch (value) {
      case ScheduleSteps.SELECT_MEMBER:
        return setCurrentStep(ScheduleSteps.SELECT_PROVIDER);
      case ScheduleSteps.SELECT_PROVIDER:
        return setCurrentStep(ScheduleSteps.SELECT_SERVICE);
      case ScheduleSteps.SELECT_SERVICE:
        return setCurrentStep(ScheduleSteps.SELECT_TYPE);
      case ScheduleSteps.SELECT_TYPE:
        return selectedAppointmentType === appointmentType.RECURRING
          ? setCurrentStep(ScheduleSteps.CHECK_AVAILABILITY)
          : setCurrentStep(ScheduleSteps.SELECT_REVIEW);
      default:
        return setCurrentStep(ScheduleSteps.SELECT_REVIEW);
    }
  };

  const nextStep2 = value => {
    switch (value) {
      case ScheduleSteps.SELECT_MEMBER:
        return setCurrentStep(ScheduleSteps.SELECT_PROVIDER);
      case ScheduleSteps.SELECT_PROVIDER:
        return setCurrentStep(ScheduleSteps.SELECT_SERVICE);
      default:
        return setCurrentStep(ScheduleSteps.SELECT_TYPE);
    }
  };
  useEffect(() => {
    fetchMembers('', pageNumber);
    fetchProviders();
    if (providerId) dispatch(profileActionCreators.fetchProviderProfile(providerId));
  }, []);

  useEffect(() => {
    if (formData?.member) {
      const payload: any = {
        initial: true,
        memberId: formData?.member?.id,
      };
      if (formData?.member.state) {
        payload.state = formData?.member.state;
      }
      dispatch(
        profileActionCreators.fetchCareTeam({ userId: formData?.member?.id, scheduleAppointment: true }),
      );
      dispatch(appointmentActionCreators.fetchAllProviderServices(payload));
      if (preSelected) {
        setCurrentStep(ScheduleSteps.SELECT_PROVIDER);
        setPreSelected(false);
      }
    }
  }, [formData?.member, preSelected]);

  useEffect(() => {
    if (
      contentRef?.current &&
      (currentStep === ScheduleSteps.SELECT_TYPE || currentStep === ScheduleSteps.SELECT_MEMBER)
    ) {
      contentRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  /* useEffect(() => {
    setFormData(s => ({
      ...s,
      provider:
        providers.find(p => p.id === providerId) ||
        providers.find(p => p.id === profile?.providerId) ||
        providers[0],
    }));
  }, [providers]); */

  useEffect(() => {
    if (currentStep === ScheduleSteps.SELECT_PROVIDER && formData?.provider === null) {
      let careTeamProvider = careTeamList2?.map(connection => {
        const matchingProvider = providers?.find(provider => connection?.connectionId === provider?.id);
        if (matchingProvider) {
          return { ...connection, ...matchingProvider };
        }
        return connection;
      });
      careTeamProvider = careTeamProvider?.filter(
        provider => provider?.schedule !== undefined && provider?.schedule !== null,
      );
      const isCareTeamMember = careTeamProvider?.filter(
        item => item?.connectionId === providerId || item?.connectionId === profile?.providerId,
      );
      if (isCareTeamMember?.length === 0) {
        setFormData({ ...formData, provider: null });
      } else {
        isCareTeamMember[0].id = isCareTeamMember[0].connectionId;
        setFormData({ ...formData, provider: isCareTeamMember[0] });
      }
    }
  }, [currentStep]);

  useEffect(() => {
    setFormData({
      ...formData,
      provider: formData?.provider ?? selectedProvider,
      serviceId: formData?.serviceId ?? selectedServiceId,
    });
    if (
      currentStep === ScheduleSteps.SELECT_TYPE &&
      formData?.time?.start &&
      formData?.time?.start !== '' &&
      recurringType &&
      selectedAppointmentType === appointmentType.RECURRING
    ) {
      setIsLoadingRecurringSlots(true);
      const rangeUnit =
        recurringType === RECURRING_APPOINTMENT_FREQUENCIED.WEEKLY ||
        recurringType === RECURRING_APPOINTMENT_FREQUENCIED.BI_WEEKLY
          ? 'week'
          : 'month';
      const rangeAmount =
        recurringType === RECURRING_APPOINTMENT_FREQUENCIED.WEEKLY ||
        recurringType === RECURRING_APPOINTMENT_FREQUENCIED.MONTHLY
          ? 3
          : 6;

      const payload = {
        comment: '',
        endTime: dayjs(formData?.time?.start)?.add(rangeAmount, rangeUnit)?.toISOString(),
        instantAppointment: false,
        isRecurring: true,
        memberId: formData?.member?.id,
        providerId:
          formData?.provider?.providerId ?? formData?.provider?.connectionId ?? selectedProvider?.providerId,
        recurringType,
        serviceId: formData?.serviceId ?? formData?.selectedService?.serviceId ?? selectedServiceId,
        startTime: formData?.time?.start,
      };
      validateRecurringAppointment(payload)
        .then(res => {
          setRecurringSlots(res.data?.recurringAppointmentSlots);
          setIsLoadingRecurringSlots(false);
        })
        .catch(err => {
          setIsLoadingRecurringSlots(false);
          if (err?.status === 400) return;
          dispatch(
            showSnackbar({
              snackMessage: err.data?.errors?.[0]?.endUserMessage || 'Unable to validate slots',
              snackType: 'error',
            }),
          );
        });
    }
  }, [formData?.time, recurringType, selectedProvider]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(ScheduleSteps.SELECT_MEMBER);
      setFormData({
        member: null,
        provider: null,
        time: null,
        serviceId: null,
        selectedService: null,
      });
    }
  }, [isOpen]);

  const handleOnChangeProviderClick = provider => {
    setSelectedProvider(provider);
    setTimeout(() => {
      contentRef.current?.scrollTo({
        top: contentRef.current.scrollHeight, // Scroll to the bottom
        behavior: 'smooth', // Smooth scrolling
      });
    }, 100);
  };

  const renderStep = step => {
    switch (step) {
      case ScheduleSteps.SELECT_MEMBER:
        return (
          <StepOneNew
            error={errorMsg}
            selectedItem={formData?.member}
            fetchMembers={fetchMembers}
            onChange={onChangeFormData('member')}
            options={memberList?.map(({ member, ...rest }) => ({ ...rest, ...member }))}
            selectType="member"
            userId={memberId}
            setPageNumber={setPageNumber}
            isLoading={loadMore}
            pageNumber={pageNumber}
            isMemberList
            setSearchKeyWithPagination={setSearchKey}
            onChangeNewProviderSelected={setIsNewProviderSelected}
            onChangePreSelected={() => setPreSelected(true)}
          />
        );
      case ScheduleSteps.SELECT_PROVIDER:
        return (
          <StepOneNew
            error={errorMsg}
            selectedPatient={formData?.member}
            selectedItem={formData?.provider}
            fetchProviders={fetchProviders}
            onChange={onChangeFormData('provider')}
            allProviders={providers}
            careTeam={careTeamList2}
            options={providers?.filter(
              providr => providr?.active && providr.schedule && providr.schedule.slots?.length > 0,
            )}
            selectType="provider"
            userId={providerId}
            setPageNumber={setPageNumber}
            onChangeNewProviderSelected={setIsNewProviderSelected}
            currentStepToService={() => {
              setCurrentStep(ScheduleSteps.SELECT_SERVICE);
            }}
          />
        );
      case ScheduleSteps.SELECT_SERVICE:
        return (
          <StepThreeNew
            error={errorMsg}
            formData={formData}
            selectedDateTime={selectedDateTime}
            services={isNewProviderSelected ? allProviderServices : services}
            isNewProviderSelected={isNewProviderSelected}
            onChange={onChangeFormData('time')}
            onChangeService={setSelectedServiceId}
            onChangeSelectedService={onChangeFormData('selectedService')}
            serviceId={formData?.selectedService?.id || formData?.selectedService?.serviceId}
          />
        );
      case ScheduleSteps.SELECT_TYPE:
        return (
          <StepFourNew
            contentRef={contentRef}
            error={errorMsg}
            formData={formData}
            isNewProviderSelected={isNewProviderSelected}
            selectedDateTime={selectedDate}
            updateSelectedDateTime={setSelectedDate}
            onChangeSelectedTimeSlot={onChangeFormData('time')}
            onChangeProvider={handleOnChangeProviderClick}
            selectedProvider={selectedProvider}
            setAppointmentType={setSelectedAppointmentType}
            setSelectedServiceId={setSelectedServiceId}
            selectedAppointmentType={selectedAppointmentType}
            providerr={filteredProvider}
            isVideoSession={isVideoSession}
            setRecurringType={setRecurringType}
            recurringType={recurringType}
            recurringSlots={recurringSlots}
            setRecurringSlots={setRecurringSlots}
            isLoadingRecurringSlots={isLoadingRecurringSlots}
            setSelectedDateRange={setSelectedDateRange}
            selectedDateRange={selectedDateRange}
          />
        );
      case ScheduleSteps.CHECK_AVAILABILITY:
        return (
          <StepFiveNew
            error={errorMsg}
            formData={formData}
            selectedDateTime={selectedDateTime}
            selectedAppointmentType={selectedAppointmentType}
            provider={selectedProvider ?? filteredProvider}
            selectedServiceId={selectedServiceId}
            isNewProviderSelected={isNewProviderSelected}
            onChange={onChangeFormData('time')}
            onChangeService={onChangeFormData('serviceId')}
            onClickReschedule={onClickReschedule}
            recurringSlots={recurringSlots}
          />
        );
      default:
        return (
          <StepSixNew
            error={errorMsg}
            formData={formData}
            selectedDateTime={selectedDateTime}
            selectedAppointmentType={selectedAppointmentType}
            provider={filteredProvider}
            selectedServiceId={selectedServiceId}
            isNewProviderSelected={isNewProviderSelected}
            onChange={onChangeFormData('time')}
            onChangeService={onChangeFormData('serviceId')}
            recurringSlots={recurringSlots}
            recurringType={recurringType}
          />
        );
    }
  };

  const saveText = isSaving ? 'Saving...' : 'Schedule';
  const saveText2 = isSaving ? 'Saving...' : 'Start instant session';
  const showFooter =
    (currentStep === ScheduleSteps.SELECT_MEMBER && !!formData?.member) ||
    (currentStep === ScheduleSteps.SELECT_PROVIDER && !!formData?.provider) ||
    (currentStep === ScheduleSteps.SELECT_SERVICE && !!formData?.selectedService) ||
    (currentStep === ScheduleSteps.SELECT_TYPE &&
      !!formData?.time &&
      !isNewProviderSelected &&
      selectedAppointmentType === appointmentType.SPECIFIC_DATE) ||
    (currentStep === ScheduleSteps.SELECT_TYPE &&
      !!formData?.time &&
      selectedAppointmentType === appointmentType.SPECIFIC_DATE &&
      isNewProviderSelected &&
      selectedProvider &&
      selectedServiceId) ||
    (currentStep === ScheduleSteps.SELECT_TYPE &&
      !!formData?.time?.start &&
      !!formData?.time?.end &&
      !isNewProviderSelected &&
      recurringType &&
      selectedAppointmentType === appointmentType.RECURRING) ||
    (currentStep === ScheduleSteps.SELECT_TYPE &&
      !!formData?.time?.start &&
      !!formData?.time?.end &&
      selectedAppointmentType === appointmentType.RECURRING &&
      isNewProviderSelected &&
      selectedProvider &&
      recurringType &&
      selectedServiceId) ||
    (currentStep === ScheduleSteps.SELECT_TYPE &&
      (selectedAppointmentType === appointmentType.INSTANT_SESSION ||
        selectedAppointmentType === appointmentType.SCHEDULING_LINK) &&
      selectedServiceId) ||
    currentStep === ScheduleSteps.CHECK_AVAILABILITY ||
    currentStep === ScheduleSteps.SELECT_REVIEW;

  return (
    <Drawer open={isOpen} onClose={onCloseClick} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Box>
            <Text weight={fontWeight.BOLD} className={classes.headTitle}>
              Schedule new appointment
            </Text>
            <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
              {StepTitles[currentStep]}
            </Text>
          </Box>
          <Box className={classes.stepWrap}>
            <Stepper
              steps={getSchedulingSteps(selectedAppointmentType)}
              activeStep={StepsNumber[currentStep]}
              minusConnector
            />
          </Box>
          <IconButton
            icon="close"
            className={classes.closeBtn}
            onClick={() => {
              setFormData({
                member: null,
                provider: null,
                time: null,
                serviceId: null,
                selectedService: null,
              });
              onClose();
            }}
          />
        </Box>
        <Box className={clsx(classes.content, { [classes.showFooter]: showFooter })} ref={contentRef}>
          {loader && isLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            renderStep(currentStep)
          )}
        </Box>
        {showFooter && (
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onCloseClick}>
              Cancel
            </Button>
            <Button onClick={onClickContinue} disabled={isSaving}>
              {currentStep === ScheduleSteps.SELECT_REVIEW &&
              selectedAppointmentType === appointmentType.SPECIFIC_DATE
                ? saveText
                : selectedAppointmentType === appointmentType.INSTANT_SESSION
                ? saveText2
                : 'Continue'}
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export { AddSchedule };
