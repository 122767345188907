import dayjs from 'dayjs';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';

/* eslint-disable camelcase */
interface Bucket1 {
  key_as_string: string;
  key: string;
  doc_count: number;
}
interface Bucket2 {
  key: string;
  doc_count: number;
}
interface MemberId {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: Bucket2[];
}
interface Bucket3 {
  key: string;
  doc_count: number;
  member_id: MemberId;
}
interface Total {
  value: number;
  relation: string;
}
interface Count {
  buckets: Bucket1[];
}
interface Assignment_Last_30_Days {
  count: Count;
}
interface Last_30_Days_Total_Assignments {
  meta: any;
  doc_count: number;
}
interface Breakdown_By_Progress {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: Bucket3[];
}
interface Aggregations {
  assignments_per_day_last_60_days: Assignment_Last_30_Days;
  assignments_per_day_7_days: Assignment_Last_30_Days;
  assignments_per_day_last_3_months: Assignment_Last_30_Days;
  assignments_per_day_last_6_months: Assignment_Last_30_Days;
  assignments_per_day_last_12_months: Assignment_Last_30_Days;
  last_30_days_total_assignments: Last_30_Days_Total_Assignments;
  assignments_per_day_last_30_days: Assignment_Last_30_Days;
  breakdown_by_progress: Breakdown_By_Progress;
  total_assignments: Total;
}
export interface IAutomationDetail {
  hits: any;
  aggregations: Aggregations;
}

export const automationDetailElkQuery = (automationId: string) => {
  return {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              'ruleId.keyword': automationId,
            },
          },
        ],
      },
    },
    aggs: {
      last_30_days_total_assignments: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-30d/d',
            },
          },
        },
      },
      total_assignments: {
        value_count: {
          field: 'createdAt',
        },
      },
      assignments_per_day_7_days: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-7d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_30_days: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-30d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_3_months: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-90d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_6_months: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-180d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      assignments_per_day_last_12_months: {
        filter: {
          range: {
            createdAt: {
              gte: 'now-365d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'createdAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      breakdown_by_progress: {
        terms: {
          field: 'status.keyword',
          size: 10,
        },
        aggs: {
          member_id: {
            terms: {
              field: 'patientId.keyword',
              size: 10,
            },
          },
        },
      },
    },
  };
};

export const automationRecentAssignmentElkQuery = (
  automationId: string,
  searchKeyword: string,
  pageNumber = 0,
  pageSize = 10,
) => {
  if (!automationId) {
    return null;
  }
  return {
    from: (pageNumber - 1) * pageSize,
    size: pageSize,
    query: {
      bool: {
        must: [
          {
            term: {
              'ruleId.keyword': automationId,
            },
          },
        ],
        should: [
          {
            regexp: {
              'member_fullname.keyword': {
                value: `.*${searchKeyword}.*`,
                case_insensitive: true,
              },
            },
          },
          {
            regexp: {
              'member_first_name.keyword': {
                value: `.*${searchKeyword}.*`,
                case_insensitive: true,
              },
            },
          },
          {
            regexp: {
              'member_last_name.keyword': {
                value: `.*${searchKeyword}.*`,
                case_insensitive: true,
              },
            },
          },
        ],
        minimum_should_match: 1,
      },
    },
  };
};

export const getFormatedRecentAssignedData = (recentAssignedData: any) => {
  if (recentAssignedData?.hits?.length <= 0) {
    return null;
  }
  /* eslint no-underscore-dangle: "warn" */
  // return recentAssignedData?.hits?.map(hit => hit._source);
  return recentAssignedData?.hits?.map(hit => ({
    member: {
      name: hit?._source?.member_fullname || '', // eslint-disable-line no-underscore-dangle
      fullname: `${hit?._source?.member_first_name || ''} ${hit?._source?.member_last_name || ''}`, // eslint-disable-line no-underscore-dangle
      patientId: hit?._source?.patientId, // eslint-disable-line no-underscore-dangle
      // thumbnail: 'https://i.pravatar.cc/300',
    },
    status: hit?._source?.status?.toLowerCase(), // eslint-disable-line no-underscore-dangle
    date: dayjs(hit?._source?.assignedAt).format(GLOBAL_DATE_FORMAT), // eslint-disable-line no-underscore-dangle
  }));
};
interface DateCount {
  date: string;
  count: number;
}
interface KeyCount {
  key: string;
  count: number;
}
interface KeyCountMembers {
  key: string;
  count: number;
  members: KeyCount[];
}
interface Assignments {
  current30Days: DateCount[];
  last30Days: DateCount[];
  last3Months: DateCount[];
  last6Months: DateCount[];
  last12Months: DateCount[];
  last7Days: DateCount[];
}
export interface IFormatedAutomationDetail {
  assignmentLast30Days: number;
  totalAssignments: number;
  assignments: Assignments;
  breakdownByProgress: KeyCountMembers[];
  totalRecords: number;
}
export const getFormatedAutomationDetail = (automationDetail: IAutomationDetail) => {
  if (!automationDetail) {
    return null;
  }
  return {
    assignmentLast30Days: automationDetail?.aggregations?.last_30_days_total_assignments?.doc_count || 0,
    totalAssignments: automationDetail?.aggregations?.total_assignments?.value || 0,
    assignments: {
      last7Days: automationDetail?.aggregations?.assignments_per_day_7_days?.count?.buckets?.map(bucket => ({
        date: bucket.key_as_string,
        count: bucket.doc_count,
      })),
      current30Days: automationDetail?.aggregations?.assignments_per_day_last_30_days?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last3Months: automationDetail?.aggregations?.assignments_per_day_last_3_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last6Months: automationDetail?.aggregations?.assignments_per_day_last_6_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
      last12Months: automationDetail?.aggregations?.assignments_per_day_last_12_months?.count?.buckets?.map(
        bucket => ({
          date: bucket.key_as_string,
          count: bucket.doc_count,
        }),
      ),
    },
    breakdownByProgress: automationDetail?.aggregations?.breakdown_by_progress?.buckets?.map(bucket => ({
      key: bucket.key,
      count: bucket.doc_count,
      members: bucket.member_id?.buckets?.map(bkt => ({ key: bkt.key, count: bkt.doc_count })),
    })),
    totalRecords: automationDetail?.hits?.total?.value,
  } as IFormatedAutomationDetail;
};

export const automationRecentAssignemtColumns: TableGrid.TableColumn[] = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'status', label: 'Plan item status', sortable: false, className: 'color-secondary-500' },
  { id: 'date', label: 'Date', sortable: false },
];

// export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];
