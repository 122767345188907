import { FC } from 'react';
import { Box } from '@mui/material';

import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { useStyles } from './EvaluationRejected.styles';
import { IEvaluationItem } from '../../../../evaluations-v2/Evaluations.types';

type Props = {
  selectedEvaluation?: IEvaluationItem;
  onClickView: () => void;
};
const EvaluationRejected: FC<Props> = ({ onClickView, selectedEvaluation }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapIcon}>
        <Icons glyph="clear-circle" className={classes.icon} />
      </Box>
      <Heading level={headingLevel.S} className={classes.title} weight={fontWeight.BOLD}>
        {selectedEvaluation?.name} rejected!
      </Heading>
      <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
        {selectedEvaluation?.description}
      </Text>
      <IconButton onClick={onClickView} variant={iconBtnType.OUTLINE} className={classes.viewBtn}>
        View evaluation
      </IconButton>
    </Box>
  );
};

export { EvaluationRejected };
