import { Box } from '@mui/material';
import {
  Heading,
  Text,
  TextError,
  fontWeight,
  headingLevel,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import { getDomainElements } from 'services/member/member.service';
import { SelectLevel } from 'pages/provider/member-detail/components/select-level/SelectLevel';
import { EditSideEffectPayload } from 'pages/provider/member-detail/components/SideEffects/components/side-effect-editing/SideEffectEditing.types';
import { SocialDeterminantsSchema } from './schema';

import { useStyles } from '../AlfieAnalysisPlayground.styles';

type Props = {
  onClose: () => void;
  selectedElement: any;
  filteredPriorities: any;
};

const defaultValues = {
  status: '',
  notes: '',
  isInterference: false,
};

const AddPriorities = ({ onClose, selectedElement, filteredPriorities }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { profile } = useSelector(getProfile);
  const { meta } = useSelector(getAuth);

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: SocialDeterminantsSchema,
    onSubmit: (payload: EditSideEffectPayload) => {
      const data = {
        patientId: memberId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: selectedElement?.domainElementId,
            metaData: {
              interferenceInLife: payload.isInterference,
              notes: payload.notes,
              selectedImportance: payload.status,
            },
          },
        ],
      };
      dispatch(
        profileActionCreators.createNewAssociatedTag({
          data,
          callback: (errorMsg?: string) => {
            if (!errorMsg) {
              onClose();
            }
          },
        }),
      );
    },
  });

  const [importanceLevels, setImportanceLevels] = useState([]);

  useEffect(() => {
    if (selectedElement) {
      if (selectedElement?.domainElementId) {
        const tempImportanceLevels = [];
        getDomainElements({ domainTypeId: selectedElement?.domainElementId })
          .then(res => {
            if (res.status === 200) {
              Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                  tempImportanceLevels.push({ value: key, label: key });
                }
              });
              setImportanceLevels(tempImportanceLevels);
            }
          })
          .catch(err => {
            console.log({ err });
          });
      }
    }
  }, [selectedElement]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Box className={classes.column1_1_1} sx={{ gap: 3 }}>
        <Button variant={btnType.OUTLINE} className={classes.backBtn} onClick={onClose}>
          <Icons color={colors.primary500} glyph="chevron-left" />
        </Button>
        <Text level={textLevel.L} weight={fontWeight.BOLD}>
          Add {selectedElement?.type}
        </Text>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, py: 3, px: 3 }}>
          <Text
            level={selectedElement ? textLevel.S : textLevel.M}
            weight={selectedElement ? fontWeight.SEMI_BOLD : fontWeight.BOLD}
            className={selectedElement && classes.subtitle}
          >
            {selectedElement?.type}
          </Text>
          <Text
            level={selectedElement ? textLevel.M : textLevel.S}
            weight={selectedElement ? fontWeight.BOLD : fontWeight.SEMI_BOLD}
            className={!selectedElement && classes.subtitle}
          >
            {selectedElement ? selectedElement.name : `${filteredPriorities.length} found in conversation`}
          </Text>
          <Text level={textLevel.M} className={classes.mLabel}>
            {selectedElement ? selectedElement.reason : 'Select a priority to view details'}
          </Text>

          <Box className={classes.section}>
            {renderLabel('Importance level')}
            <SelectLevel
              value={values.status}
              isLifeEvent
              options={importanceLevels}
              onChange={handleChange}
            />
            <TextError errorMsg={touched.status ? errors.status?.toString() : null} />
          </Box>
          {selectedElement?.type === 'Social Determinants' && (
            <Box className={classes.section}>
              {renderLabel('Interference with life')}
              <FormControlLabel
                control={<Checkbox />}
                checked={values.isInterference}
                name="isInterference"
                onChange={() => {
                  void setFieldValue('isInterference', !values.isInterference);
                }}
                className={classes.checkboxLabel}
                label="This symptom has interference with patient’s life"
              />
            </Box>
          )}
          <Box className={classes.section}>
            {renderLabel('Report notes')}
            <Textarea
              value={values.notes}
              name="notes"
              onChange={handleChange}
              onBlur={setTouched('notes')}
              minRows={5}
              placeholder="Enter your notes"
            />
            <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: '1px solid #EDF1F5',
            px: 3,
            py: 2.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={false}>
            {!selectedElement ? 'Saving...' : `Add ${selectedElement?.type?.toLowerCase().slice(0, -1)}`}
          </Button>
        </Box>
      </form>
      {/* </Box> */}
    </Box>
  );
};

export default AddPriorities;
