import { FC } from 'react';
import { Box } from '@mui/material';

import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';

import { payersMock, membersMock } from './ProviderDetail.mock';

import { useStyles } from './ProviderDetail.styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  provider: any;
}

const ProviderDetail: FC<IProps> = ({ open, onClose, provider }) => {
  const classes = useStyles();

  const renderPayer = payer => (
    <Box key={payer.name} className={classes.payer}>
      <Box display="flex" justifyContent="space-between">
        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M} style={{ fontSize: 18 }}>
          {payer.name}
        </Text>
        <Badge
          variant={badgeType.FILLED}
          style={payer.active ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
          size={badgeSize.SMALL}
          className={classes.badge}
        >
          {payer.active ? 'Active' : 'Inactive'}
        </Badge>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Text weight={fontWeight.MEDIUM} level={textLevel.M} style={{ color: '#333C43' }}>
          Plan Type
        </Text>
        <Badge
          variant={badgeType.OUTLINED}
          style={payer.planType ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
          size={badgeSize.SMALL}
          className={classes.badge}
        >
          {payer.planType ? 'Active' : 'Inactive'}
        </Badge>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Text weight={fontWeight.MEDIUM} level={textLevel.M} style={{ color: '#333C43' }}>
          HMO
        </Text>
        <Badge
          variant={badgeType.OUTLINED}
          style={payer.hmo ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
          size={badgeSize.SMALL}
          className={classes.badge}
        >
          {payer.hmo ? 'Active' : 'Inactive'}
        </Badge>
      </Box>
    </Box>
  );

  const renderMember = member => (
    <Box key={member.fullName} className={classes.member}>
      <ProfileInfo
        type="member"
        photo={member.photo || ''}
        nickName={member.nickName}
        fullName={member.fullName}
      />
      <Text weight={fontWeight.MEDIUM} level={textLevel.S} className={classes.memberDate}>
        {member.date}
      </Text>
    </Box>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={`${provider?.firstName || ''} ${provider?.lastName || ''}`}
      subTitle={provider?.designation}
      variant={drawerType.FORM}
      footer={<div />}
      className={classes.drawer}
    >
      {!!provider && (
        <>
          <Box>
            <Text weight={fontWeight.BOLD} level={textLevel.L}>
              Payers
            </Text>
            {payersMock.map(payer => renderPayer(payer))}
          </Box>
          <Box>
            <Text weight={fontWeight.BOLD} level={textLevel.L}>
              Members
            </Text>
            {membersMock.map(member => renderMember(member))}
          </Box>
        </>
      )}
    </Drawer>
  );
};

export { ProviderDetail };
