import * as yup from 'yup';

const LoginSchema = yup.object({
  type: yup.string(),
  email: yup
    .string()
    .email(`That format doesn't look right. Make sure there aren't any typos.`)
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

export { LoginSchema };
