import { TableGrid } from '@confidant-health/lib/ui/organisms/table';

export const tableColumns: TableGrid.TableColumn[] = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'importanceLevels', label: 'Importance levels', sortable: false },
  { id: 'requireNotes', label: 'Require notes', sortable: false, textAlign: 'center' },
  { id: 'requireRxInfo', label: 'RX info', sortable: false, textAlign: 'center' },
  { id: 'requireRelatedToInfo', label: 'Related to', sortable: false, textAlign: 'center' },
  {
    id: 'requireInterferenceWithLifeInfo',
    label: 'Interference with life',
    sortable: false,
    textAlign: 'center',
  },
  { id: 'relatedGroups', label: 'Related groups', sortable: false },
  { id: 'relatedElements', label: 'Related elements', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];
