import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { DomainPriorities } from 'redux/modules/profile/types';

import { ISubstanceUseCardProps } from './SubstanceUseCard.types';
import { useStyles } from './SubstanceUseCard.styles';

const SubstanceUseBottomCard: React.FC<ISubstanceUseCardProps> = ({
  item,
  showBadge,
  showNotes = true,
  lookupData,
}) => {
  const classes = useStyles();
  const {
    tagMetaData: { substanceUse },
  } = item;

  const renderBlock = (label: string, value: string) => {
    return (
      <Box>
        <Text className={classes.label}>{label}</Text>
        <Text className={clsx(classes.label, classes.value)}>{value}</Text>
      </Box>
    );
  };

  const getValue = (key: string, opts: { name: string; value: string }[] = []): string => {
    const opt = opts.find(dx => dx.name === key);
    return opt?.value || key;
  };

  const renderContent = () => {
    if (!lookupData) {
      return null;
    }

    return (
      <Box flex={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {renderBlock('Method', getValue(substanceUse.methodOfUse, lookupData.methodsOfSubstanceUse))}
          {renderBlock('Amount', getValue(substanceUse.unitOfUse, lookupData.unitsOfSubstanceUse))}
          {renderBlock(
            'Frequency',
            getValue(substanceUse.currentFrequencyOfUse, lookupData.currentFrequencyOfSubstanceUse),
          )}
          {renderBlock(
            'Duration',
            getValue(substanceUse.howLongUsingThisLevel, lookupData.continuousLevelOfSubstanceUse),
          )}
          {renderBlock('Last use', getValue(substanceUse.lastUse, lookupData.lastSubstanceUse))}
        </Box>
        {showNotes && <Text className={clsx(classes.time, classes.notes)}>{item.notes}</Text>}
      </Box>
    );
  };

  const priority = item.priority || item.importanceLevel;

  return (
    <Box className={classes.borderTop}>
      <Box className={clsx(classes.rowCenter, classes.flexStart)}>
        <Box display="flex" gap="12px" alignItems="center" flex={showBadge ? 1 : 0.3}>
          <Avatar src={item.avatar} size={40} variant={avatarType.CIRCLE} name={item.assignedBy} />
          <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
            <Text className={classes.username}>By {item.assignedBy}</Text>
            <Text className={classes.time}>{dayjs(item.assignedAt).format('MMMM DD, YYYY')}</Text>
          </Box>
        </Box>
        {showBadge ? (
          <Badge
            variant={badgeType.FILLED}
            className={clsx(classes.badge, {
              [classes.badgeMisreported]: priority.name === DomainPriorities.MISREPORTED,
            })}
            style={badgeStyle[priority.name] || badgeStyle.UNRELATED}
          >
            {priority.name}
          </Badge>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            className={clsx(classes.interference, {
              [classes.interferenceResolved]: item.status === 'Resolved',
            })}
            flex="0.7"
          >
            {renderContent()}
          </Box>
        )}
      </Box>
      {showBadge && <Box className={classes.bottomBox}>{renderContent()}</Box>}
    </Box>
  );
};

export { SubstanceUseBottomCard };
