import cx from 'clsx';
import { FC } from 'react';
// components
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
// styles
import { formatTimeMessage } from 'utils/CommonUtils';
import { useStyles } from './MessageItem.styles';
import { IMessageItemProps } from './MessageItem.types';

const MessageItem: FC<IMessageItemProps> = ({
  className = '',
  thumbnail,
  time,
  text,
  name,
  onClick,
  firstName,
  lastName,
}) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.messageItem, className)} onClick={onClick}>
      <ProfileInfo
        fullName={`${firstName || ''} ${lastName || ''}`}
        nickName={name}
        photo={thumbnail}
        isProvider
      />
      <Text className={classes.text} level={textLevel.S} weight={fontWeight.MEDIUM}>
        {text}
      </Text>
      <Text className={classes.time} level={textLevel.S}>
        {formatTimeMessage(time)}
      </Text>
    </div>
  );
};
export { MessageItem };
