import { TableGrid } from '@confidant-health/lib/ui/organisms/table';

export const tableColumns: TableGrid.TableColumn[] = [
  { id: 'reportName', label: 'Report name', sortable: true },
  { id: 'createdBy', label: 'Created by', sortable: false },
  { id: 'createdAt', label: 'Created at', sortable: false },
  { id: 'updatedAt', label: 'Last updated', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];
