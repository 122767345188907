import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { sentenceCase } from 'sentence-case';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { CheckboxGroup } from '@confidant-health/lib/ui/molecules/checkbox-group';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { AppointmentStatus, AppointmentStatusBagde } from '@confidant-health/lib/constants/CommonConstants';
import { getTimeZoneText } from 'constants/CommonConstants';
import { IAssignedEvaluation, IEvaluation } from 'redux/modules/conversation/types';
import { useStyles } from './AppointmentDetail.styles';

type IAddEvaluationProps = {
  isOpen: boolean;
  onClose: () => void;
  evaluations: Partial<IEvaluation>[];
  assignedEvaluations: IAssignedEvaluation[];
  appointment: IAppointment;
  onAddEvaluation: (val: string[]) => void;
  onRemoveEvaluation: (evaluationId: string) => void;
};

const AddEvaluation: React.FC<IAddEvaluationProps> = ({
  isOpen,
  onClose,
  onAddEvaluation,
  appointment,
  evaluations,
  assignedEvaluations,
  onRemoveEvaluation,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState(
    evaluations?.map(ev => ({ label: ev.name, value: ev.evaluationId })),
  );
  const [checkedValues, setCheckedValues] = useState(
    evaluations
      ?.filter(ev => assignedEvaluations?.some(ae => ev.evaluationId === ae.id))
      ?.map(ev => ev.evaluationId),
  );

  useEffect(() => {
    if (searchText.trim() === '') {
      setOptions(evaluations?.map(ev => ({ label: ev.name, value: ev.evaluationId })));
    } else {
      setOptions(
        evaluations
          ?.filter(ev => ev?.name?.toLowerCase()?.includes(searchText?.toLowerCase()))
          ?.map(ev => ({ label: ev.name, value: ev.evaluationId })),
      );
    }
  }, [searchText]);

  const onChangeCheck = val => {
    // Determine which evaluations were unchecked
    const uncheckedEvaluations = checkedValues.filter(id => !val.includes(id));
    // Remove the unchecked evaluations
    uncheckedEvaluations.forEach(evaluationId => {
      onRemoveEvaluation(evaluationId);
    });
    setCheckedValues([...val]);
  };

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtn} />
          <Text className={classes.headerTitle}>Add evaluation</Text>
        </Box>
      </Box>
      <Box className={classes.evaluation}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text weight={fontWeight.BOLD} className={classes.title}>
              {appointment.serviceName}
            </Text>
            <Text weight={fontWeight.MEDIUM} className={clsx(classes.text14, classes.duration)}>
              {appointment.serviceDuration} minutes session
            </Text>
          </Box>
          <Badge
            variant={badgeType.FILLED}
            style={AppointmentStatusBagde[appointment.status] || badgeStyle.UNRELATED}
            className={classes.badge}
          >
            {appointment.status === AppointmentStatus.BOOKED
              ? 'Scheduled'
              : sentenceCase(appointment.status || '')}
          </Badge>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
            {dayjs(appointment.startTime).format('MMMM DD, YYYY - hh:mma')}
            &nbsp;-&nbsp;
            {dayjs(appointment.endTime).format('hh:mma')}&nbsp;-&nbsp;
            {getTimeZoneText(appointment.providerTimezone)}
          </Text>
        </Box>
      </Box>
      <Box className={classes.searchWrap}>
        <Input
          variant={inputType.SEARCH}
          placeholder="Search"
          className={classes.searchInput}
          value={searchText}
          onChange={setSearchText}
        />
        <Box mt={3.5}>
          <CheckboxGroup options={options} onChange={onChangeCheck} value={checkedValues} />
        </Box>
      </Box>
      <Box className={classes.evaluationFooter}>
        <Button variant={btnType.TEXT} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onAddEvaluation(checkedValues)} disabled={checkedValues?.length === 0}>
          Add selected
        </Button>
      </Box>
    </Drawer>
  );
};

export default AddEvaluation;
