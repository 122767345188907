import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Box } from '@mui/system';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { TabPanel, Tabs, tabIconPosition } from '@confidant-health/lib/ui/atoms/tabs';
import {
  IAssignedEvaluation,
  ICBResponse,
  IDctContentBlock,
  IEvaluationContext,
} from 'redux/modules/conversation/types';
import { Badge } from '@confidant-health/lib/ui/atoms/badge';
import { IEvaluationProgress } from 'redux/modules/appointment/types';
import { ICounters, ITabItem } from './EvaluationDetail.types';
import { getEvaluationContext } from '../../../../../services/conversation/conversation.service';
import { QuestionItem } from './QuestionItem';
import EvaluationItem from './EvaluationItem';
import { useStyles } from './index.styles';
import { PreviewExercisesDrawer } from './PreviewExercisesDrawer';

type Props = {
  evaluation: IAssignedEvaluation | IEvaluationProgress;
  appointmentId: string;
  memberId: string;
  fullScreen?: boolean;
  hideHeader?: boolean;
  className?: string;
};

const EvaluationDetails: FC<Props> = ({
  evaluation,
  appointmentId,
  memberId,
  fullScreen,
  className = '',
  hideHeader = false,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [tabType, setTabType] = useState('all');
  const [previewDrawerOpen, setPreviewDrawerOpen] = useState(false);
  const [evaluationContext, setEvaluationContext] = useState<IEvaluationContext>(null);
  const [evaluationTabs, setEvaluationTabs] = useState<ITabItem[]>([]);
  const [selectedExercise, setSelectedExercise] = useState<string | null>(null);
  const [questionToExpand, setQuestionToExpand] = useState<string | null>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contentIdentifier = searchParams.get('contentIdentifier');

  useEffect(() => {
    if (evaluation?.id && appointmentId) {
      void fetchEvaluationContext();
    }
  }, [appointmentId, evaluation?.id]);

  useEffect(() => {
    if (!hideHeader) {
      const containsRiskFactor = evaluationTabs.find(tab => tab.tabKey === 'riskFactor');
      if (containsRiskFactor && !contentIdentifier) setTabType('riskFactor');
    }
  }, [evaluationTabs]);

  useEffect(() => {
    if (evaluationContext) {
      const counts = countBlocks();
      const evalTabs = Object.keys(counts)
        .map(key => {
          return {
            tabKey: key,
            count: counts[key].count,
            label: counts[key].label,
            filter: counts[key].filter,
            contentLabel: counts[key].contentLabel,
          };
        })
        .filter(tab => tab.count > 0);
      setEvaluationTabs(evalTabs);
    }
  }, [evaluationContext]);

  useEffect(() => {
    if (contentIdentifier) {
      setQuestionToExpand(contentIdentifier);
    }
  }, [contentIdentifier, evaluationContext]);

  useEffect(() => {
    if (questionToExpand) {
      document.getElementById(questionToExpand)?.scrollIntoView({ behavior: 'smooth' });
      setQuestionToExpand(null);
    }
  }, [questionToExpand]);

  const allContentBlocks = useMemo((): Array<ICBResponse & IDctContentBlock> => {
    if (!evaluationContext) {
      return [];
    }
    const blocks = evaluationContext.cbResponseList
      .map(cb => {
        if (cb.cbType === 'dct' && cb.dctContentBlockList) {
          return cb.dctContentBlockList.map(innerCb => {
            return {
              ...innerCb,
              responderType: cb.responderType,
            };
          });
        }
        return cb;
      })
      .flat();
    return blocks as Array<ICBResponse & IDctContentBlock>;
  }, [evaluationContext]);

  const countBlocks = (): ICounters => {
    const initialCounters: ICounters = {
      riskFactor: {
        count: 0,
        filter: (cb: ICBResponse & IDctContentBlock) =>
          cb.userResponse?.userResponseList?.some(userResponseItem =>
            cb.choices?.some(choice => choice.choice === userResponseItem && choice.riskFactor),
          ),
        label: 'Risk factors',
        contentLabel: 'Risk factors',
      },
      all: {
        count: 0,
        filter: null,
        label: 'All',
        contentLabel: 'All questions',
      },
      required: {
        count: 0,
        filter: (cb: ICBResponse & IDctContentBlock) => cb.required,
        label: 'Required',
        contentLabel: 'Required questions',
      },
      provider: {
        count: 0,
        filter: (cb: ICBResponse & IDctContentBlock) => cb.responderType === 'PROVIDER',
        label: 'For provider',
        contentLabel: 'Questions for provider',
      },
      exercise: {
        count: 0,
        filter: (cb: ICBResponse & IDctContentBlock) => cb.cbType === 'exercise',
        label: 'Exercises',
        contentLabel: 'Exercises',
      },
      unanswered: {
        count: 0,
        filter: (cb: ICBResponse & IDctContentBlock) =>
          !cb.userResponse ||
          !cb.userResponse.userResponseList ||
          cb.userResponse.userResponseList.length === 0 ||
          cb.userResponse.userResponseList.every(
            res =>
              res.trim().toLowerCase() === 'not applicable' || res.trim().toLowerCase() === 'not discussed',
          ),
        label: 'Unanswered',
        contentLabel: 'Unanswered questions',
      },
    };
    return allContentBlocks.reduce((acc, cb) => {
      acc.all.count++;
      if (cb.required) {
        acc.required.count++;
      }
      if (
        evaluation.status === 'COMPLETED' &&
        cb.userResponse?.userResponseList?.length !== 0 &&
        cb.choices?.length !== 0 &&
        cb.userResponse?.userResponseList?.some(userResponseItem =>
          cb.choices?.some(choice => choice.choice === userResponseItem && choice.riskFactor),
        )
      ) {
        acc.riskFactor.count++;
      }
      if (cb.responderType === 'PROVIDER') {
        acc.provider.count++;
      }
      if (cb.cbType === 'exercise') {
        acc.exercise.count++;
      }
      if (
        (!cb.userResponse ||
          !cb.userResponse.userResponseList ||
          cb.userResponse.userResponseList.length === 0 ||
          cb.userResponse.userResponseList.every(
            res =>
              res.trim().toLowerCase() === 'not applicable' || res.trim().toLowerCase() === 'not discussed',
          )) &&
        cb.cbType !== 'education' &&
        cb.cbType !== 'exercise' &&
        (evaluation.status === 'COMPLETED' || evaluation.status === 'IN_PROGRESS')
      ) {
        acc.unanswered.count++;
      }
      return acc;
    }, initialCounters);
  };

  const fetchEvaluationContext = async () => {
    setLoading(true);
    try {
      const { data } = await getEvaluationContext({ appointmentId, evaluationId: evaluation.id });
      setEvaluationContext(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const onPreviewExercise = cbId => {
    setSelectedExercise(cbId);
    setPreviewDrawerOpen(true);
  };

  const closeExercisesPreview = () => {
    setPreviewDrawerOpen(false);
    setSelectedExercise(null);
  };

  const renderTab = tab => {
    return {
      tabKey: tab.tabKey,
      label: tab.label || tab.tabKey,
      icon: <Badge className={classes.countBadge}>{tab?.count || 0}</Badge>,
      iconPosition: 'end' as tabIconPosition,
    };
  };

  const renderTabPanel = (tabConfig: ITabItem) => {
    let blocks = evaluationContext.cbResponseList;
    if (tabConfig.filter) {
      blocks = allContentBlocks.filter(tabConfig.filter);
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {!hideHeader && (
          <Box className={classes.evaluationTitle}>
            <span className={classes.capitalize}>{tabConfig.contentLabel}</span>
          </Box>
        )}
        <Box>
          {blocks.map((cb, index: number) => {
            const showBottomBorder = index !== blocks.length - 1 && blocks[index + 1]?.cbType !== 'dct';
            const showEvaluationBottomBorder = index !== blocks.length - 1;
            return cb.cbType === 'dct' && !tabConfig.filter ? (
              <EvaluationItem
                id={cb.cbId}
                key={index}
                dct={cb as ICBResponse}
                open
                memberId={memberId}
                onPreviewExercise={onPreviewExercise}
                contextId={evaluationContext?.contextId}
                evaluationStatus={evaluationContext.status}
                selectedTab={tabType}
                showBottomBorder={showEvaluationBottomBorder}
                waitingForAI={evaluationContext?.aiCompletionStatus === 'IN_PROGRESS'}
                appointmentId={appointmentId}
                evaluationId={evaluation.id}
                contentIdentifier={contentIdentifier}
              />
            ) : (
              <QuestionItem
                id={cb.cbId}
                contentBlock={cb as IDctContentBlock}
                memberId={memberId}
                contextId={evaluationContext?.contextId}
                onPreviewExercise={onPreviewExercise}
                key={index}
                evaluationStatus={evaluationContext.status}
                showBottomBorder={showBottomBorder}
                selectedTab={tabType}
                waitingForAI={evaluationContext?.aiCompletionStatus === 'IN_PROGRESS'}
                appointmentId={appointmentId}
                evaluationId={evaluation.id}
                contentIdentifier={contentIdentifier}
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
        <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
      </Box>
    );

  return (
    <>
      <Box className={clsx(fullScreen && classes.centeredSection, { [className || '']: className })}>
        {!hideHeader && (
          <Box className={classes.tabContainer}>
            <Tabs
              value={tabType}
              onChange={setTabType}
              className={classes.evaluationTabs}
              options={evaluationTabs.map(renderTab)}
            />
          </Box>
        )}
        <Box
          className={clsx(
            classes.tabContent,
            hideHeader && classes.headlessTabContent,
            !fullScreen && classes.drawerTabContent,
          )}
        >
          {evaluationTabs.map((tabConfig: ITabItem, index) => (
            <TabPanel value={tabType} tabKey={tabConfig.tabKey} key={index}>
              {renderTabPanel(tabConfig)}
            </TabPanel>
          ))}
        </Box>
      </Box>
      <PreviewExercisesDrawer
        open={previewDrawerOpen}
        onClose={closeExercisesPreview}
        exercises={allContentBlocks.filter(cb => cb.cbType === 'exercise')}
        evaluationName={evaluation.name}
        selectedExerciseCbId={selectedExercise}
      />
    </>
  );
};

export default EvaluationDetails;
