import React, { FC, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { RadioGroup, radioGroupType } from '@confidant-health/lib/ui/molecules/radio-group';
import { useStyles } from './single-select.styles';
import { EvaluationItemNode } from '../../../../redux/modules/conversation/types';

type props = {
  options: any[];
  onChange: (selectedValue: string) => void;
  currentBlock: EvaluationItemNode;
  selectedIndex: number;
  scrollRef?: React.RefObject<HTMLDivElement>;
};
export const SingleSelectOptions: FC<props> = ({
  options,
  currentBlock,
  onChange,
  selectedIndex,
  scrollRef,
}) => {
  const classes = useStyles({ currentIndex: selectedIndex, choices: options });
  const ref = useRef<HTMLDivElement>(null);

  let value = null;
  if (currentBlock && currentBlock.userResponse && currentBlock.userResponse.userResponseList.length > 0) {
    const response = currentBlock.userResponse.userResponseList[0];
    value = options.find(op => op.value === response);
  }

  useEffect(() => {
    if (ref?.current && scrollRef?.current && selectedIndex > -1) {
      scrollRef.current.scrollTop =
        ref.current.offsetTop + selectedIndex * 60 - scrollRef.current.offsetTop - 60;
    }
  }, [scrollRef, selectedIndex, ref]);

  return (
    <Box className={classes.radioGroupRoot} ref={ref}>
      <RadioGroup
        variant={radioGroupType.VERTICAL}
        options={options}
        value={value?.value || null}
        onChange={(e, v) => {
          onChange(v);
        }}
      />
    </Box>
  );
};
