import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { Input } from '@confidant-health/lib/ui/atoms/input';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';

import { useStyles } from './LLMAccessConfirmModal.styles';

type Props = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit: (password: string) => void;
};

const LLMAccessConfirmModal: FC<Props> = ({ open, onClose, isLoading, onCancel, onSubmit }) => {
  const classes = useStyles();
  const [accessPassword, setAccessPassword] = useState('');
  const [error, setError] = useState('');

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccessPassword(e.target.value);
    setError('');
  };

  const onSubmitClick = () => {
    if (accessPassword.trim().length === 0) {
      setError('Please enter access password');
      return;
    }
    onSubmit(accessPassword);
  };

  const closeModal = () => {
    setAccessPassword('');
    setError('');
    onClose();
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.editIconWrap}>
            <Icons glyph="pencil" className={classes.editIcon} />
          </Box>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>For Clinical Use Only</Heading>
              <Text className={classes.subtitle}>
                Access to this area is controlled and monitored. This area is specifically designed for
                clinical leadership to assess clinical decision-making and clinical data collection. Access to
                this section is for clinical purposes only. Please confirm your access password.
              </Text>
              <Icons
                glyph="close"
                onClick={closeModal}
                className={classes.closeBtn}
                color={colors.neutral400}
              />
            </Box>
            <Box>
              <Text className={classes.label}>Access Password</Text>
              <Input
                placeholder="Access Password"
                type="password"
                value={accessPassword}
                onChange={onChangeName}
                className={classes.input}
                fullWidth
              />
              {error && <TextError errorMsg="Not allow empty" />}
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button
                className={clsx(classes.btn, classes.cancelBtn)}
                disabled={isLoading}
                onClick={onCancel || closeModal}
              >
                Cancel
              </Button>
              <Button
                className={clsx(classes.btn, classes.okBtn)}
                disabled={isLoading || accessPassword.trim().length === 0}
                onClick={onSubmitClick}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { LLMAccessConfirmModal };
