import * as yup from 'yup';

const ServiceSchema = yup.object({
  serviceTypes: yup.array().min(1, 'Service type is required'),
  name: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  duration: yup.string().required('Time is required'),
  operatingStates: yup.array().when('stateUsageInAppointment', {
    is: exists => !!exists,
    then: yup.array().min(1, 'State is required'),
    otherwise: yup.array(),
  }),
  slidingScaleRate: yup.number().when('acceptsSlidingScale', {
    is: exists => !!exists,
    then: yup.number().min(0, 'Sliding scale rate should be 0 or greater'),
    otherwise: yup.number(),
  }),
  slidingScaleProviderFee: yup.number().when('acceptsSlidingScale', {
    is: exists => !!exists,
    then: yup.number().min(0, 'Sliding scale provider fee should be 0 or greater'),
    otherwise: yup.number(),
  }),
  cashRate: yup.number().min(0, 'Cash rate should be 0 or greater'),
  providerFee: yup.number().min(0, 'Provider fee should be 0 or greater'),
  cost: yup
    .number()
    .test('is-decimal', 'Invalid decimal number. Must have two decimal places.', value => {
      if (typeof value === 'number') {
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length === 2;
      }
      return false;
    })
    .required('Cost is required'),
  recommendedCost: yup

    .number()
    .test('is-decimal', 'Invalid decimal number. Must have two decimal places.', value => {
      if (typeof value === 'number') {
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length === 2;
      }
      return false;
    })
    .min(yup.ref('cost'), 'Recommended cost and market cost must be greater than service cost'),
  marketCost: yup
    .number()
    .test('is-decimal', 'Invalid decimal number. Must have two decimal places.', value => {
      if (typeof value === 'number') {
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length === 2;
      }
      return false;
    })
    .min(yup.ref('recommendedCost'), 'Market cost must be greater than recommended cost'),
});

export { ServiceSchema };
