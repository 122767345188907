import { FC, useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import BreakdownChart from 'pages/admin/plan-items/components/plan-item-detail/breakdown-chart';
import { IChartInfo } from './ChartSeries.types';
import { useStyles } from './ChartSeries.styles';

type Props = {
  data: Array<IChartInfo>;
  pagination?: boolean;
  pageSize?: number;
  onHeadingClick?: (item: any) => void;
};

const ChartSeries: FC<Props> = ({ data, pagination = false, pageSize = 6, onHeadingClick }: Props) => {
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const chartSeries = useMemo(() => {
    if (pagination) {
      return _.chunk(data.slice(0, page * pageSize), 2);
    }
    return _.chunk(data, 2);
  }, [data, pagination, page, pageSize]);

  const handleLoadMore = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const renderDonutChart = (chartInfo: IChartInfo) => (
    <Box className={classes.donut}>
      <BreakdownChart
        className={classes.breakdownChart}
        title={chartInfo.title}
        subtitle={chartInfo.subtitle}
        chartData={chartInfo.chartData}
        chartHeight={200}
        onHeadingClick={onHeadingClick}
        donutOpts={{
          total: chartInfo.donutOptTotal,
          title: chartInfo.donutOptTitle,
          size: '80%',
        }}
      />
    </Box>
  );

  return (
    <Box>
      {chartSeries.map((item, index) => (
        <Box key={index} className={classes.donutCharts}>
          {renderDonutChart(item[0])}
          {item[1] && renderDonutChart(item[1])}
        </Box>
      ))}
      {pagination && (
        <div className={classes.buttonContainer}>
          <Button variant={btnType.TEXT} onClick={handleLoadMore}>
            Load More
          </Button>
        </div>
      )}
    </Box>
  );
};

export { ChartSeries };
