import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  btnGroupIcon: {
    color: colors.neutral700,
    backgroundColor: 'transparent',
    padding: '14px 18px',
    transition: '0s',
    fontSize: 14,
    height: 64,
    border: `1px solid ${colors.neutral200}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.neutral900,
      border: `1px solid ${colors.neutral900}`,
    },
  },
  btnGroupIconActive: {
    color: colors.white,
    border: `1px solid ${colors.neutral900}`,
    backgroundColor: colors.neutral900,
  },
  addInvoicesBtn: {
    height: 64,
    color: '#FFF',
    padding: '20px 24px',
    borderRadius: 8,
    whiteSpace: 'nowrap',
  },
  status: {
    borderRadius: 100,
    minWidth: 'auto',
    height: 28,
    fontSize: 14,
    color: colors.success600,
  },
  pendingStatus: {
    color: colors.orange300,
  },
  holdStatus: {
    color: colors.destructive600,
  },
  subTitle: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  actionBtn: {
    height: 30,
    color: colors.neutral800,
    padding: 0,
    '& svg': {
      height: 16,
    },
  },
  evaluationName: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  rating: {
    '& path': {
      fill: colors.warning500,
    },
  },
});
