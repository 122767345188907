import * as yup from 'yup';

const EvaluationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  // rejectionTemplate: yup
  //   .array()
  //   .of(yup.object())
  //   .required('Rejection reason are required')
  //   .min(1, 'At least one rejection reason is required'),
});

export { EvaluationSchema };
