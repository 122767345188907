import { IGlyphs } from '@confidant-health/lib/icons';
import { TodoItemProps } from '../TodoItem/TodoItem.types';

export interface TodoListProps {
  open: boolean;
  heading: string;
  count: number;
  items: TodoItemProps[];
  setValue?: any;
  value?: any;
  isLoading?: boolean;
  emptyTodosText: string;
  emptyTodosHeading: string;
  emptyTodosIcon: IGlyphs['glyph'];
  preventEmptyView?: boolean;
  onItemClicked?: (id: string) => void;
  onItemChecked: (id: string) => void;
  onItemUnchecked: (id: string) => void;
  isCompleted?: boolean;
  variant?: TodoVariant;
  todoType?: TodoType;
}

export enum TodoVariant {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum TodoType {
  CHAT = 'CHAT',
  NOTES = 'NOTES',
  APPOINTMENT = 'APPOINTMENT',
}
