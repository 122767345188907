// components
import { IDCTAssignment } from 'redux/modules/conversation/types';
import AssignmentDetail from './components/assignments';
import CompletionDetail from './components/completions';
import ConversationDetail from './components/detail';

type ComponentT = React.FC<{ selectedScreen: string; version: number; dct: IDCTAssignment }>;

const SelectedComponent: ComponentT = ({ selectedScreen, version, dct }) => {
  switch (selectedScreen) {
    case 'assignments':
      return <AssignmentDetail version={version} dct={dct} />;
    case 'responses-completion':
      return <CompletionDetail />;
    default:
      return <ConversationDetail />;
  }
};

export default SelectedComponent;
