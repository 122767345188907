import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MuiInputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

// components
import { Input } from '@confidant-health/lib/ui/atoms/input';
import { fontWeight, Text, TextError, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { Button } from '@confidant-health/lib/ui/atoms/button';

import { LoginType } from 'constants/CommonConstants';
import { updatePassword } from 'services/auth/auth.service';
import { showSnackbar } from 'redux/modules/snackbar';

import { NewPasswordSchema } from './NewPasswordSchema';
// styles
import { useStyles } from './NewPassword.styles';

type Props = {
  code: string;
  email: string;
};

const NewPassword: FC<Props> = ({ code, email }) => {
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: NewPasswordSchema,
    onSubmit: async values => {
      const params = {
        email,
        newPassword: values.newPassword,
        confirmPassword: values.confirmNewPassword,
        code,
        userType: LoginType.PROVIDER,
      };
      setIsLoading(true);
      const response = await updatePassword(params);

      response?.data?.message ? setErrorMsg(response?.data?.message) : setPasswordUpdated(true);

      setIsLoading(false);
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (passwordUpdated) {
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'Password Updated Successfully',
        }),
      );

      navigate('/');
    }
  }, [passwordUpdated]);

  const onNewPasswordClickEye = () => {
    setShowNewPassword(!showNewPassword);
  };

  const onConfirmPasswordClickEye = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const onBlurInput = async e => {
    await formik.setFieldTouched(e.target.name);
  };

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <div className={classes.inputContainer}>
        <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
          New Password
        </Text>
        <Input
          className={classes.input}
          name="newPassword"
          placeholder="Enter your password"
          type={showNewPassword ? 'password' : 'text'}
          onChange={formik.handleChange}
          onBlur={onBlurInput}
          InputProps={{
            startAdornment: (
              <MuiInputAdornment position="start">
                <Icons glyph="password-lock" color={colors.neutral500} />
              </MuiInputAdornment>
            ),
            endAdornment: (
              <MuiInputAdornment position="end">
                <Icons
                  glyph="eye-outlined"
                  className={classes.eye}
                  color={colors.neutral500}
                  onClick={onNewPasswordClickEye}
                />
              </MuiInputAdornment>
            ),
          }}
        />
        <TextError errorMsg={formik.touched.newPassword ? formik.errors.newPassword?.toString() : null} />
      </div>
      <div className={classes.inputContainer}>
        <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
          Confirm New Password
        </Text>
        <Input
          className={classes.input}
          name="confirmNewPassword"
          placeholder="Enter your password"
          type={showConfirmNewPassword ? 'password' : 'text'}
          onChange={formik.handleChange}
          onBlur={onBlurInput}
          InputProps={{
            startAdornment: (
              <MuiInputAdornment position="start">
                <Icons glyph="password-lock" color={colors.neutral500} />
              </MuiInputAdornment>
            ),
            endAdornment: (
              <MuiInputAdornment position="end">
                <Icons
                  glyph="eye-outlined"
                  className={classes.eye}
                  color={colors.neutral500}
                  onClick={onConfirmPasswordClickEye}
                />
              </MuiInputAdornment>
            ),
          }}
        />
        <TextError
          errorMsg={formik.touched.confirmNewPassword ? formik.errors.confirmNewPassword?.toString() : null}
        />
      </div>
      {errorMsg && (
        <div className={classes.inputContainer}>
          <Text className={classes.errorText} level={textLevel.S}>
            {errorMsg}
          </Text>
        </div>
      )}

      <div className={classes.inputContainer}>
        <Button className={classes.buttonContainer} onClick={formik.handleSubmit}>
          {isLoading ? (
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.white} />
          ) : (
            'Submit'
          )}
        </Button>
      </div>
    </form>
  );
};

export { NewPassword };
