import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { DomainTypesEnum } from 'constants/CommonConstants';
import { getDomainElements } from 'services/member/member.service';

import { IDomainElement } from 'redux/modules/profile/types';
// components
import SelectLevel from '../../select-level';
import { SymptomBottomCard } from '../symptom-card/SymptomBottomCard';
// schema
import { SymptomEditSchema } from './SymptomEditing.schema';
// styles
import { useStyles } from './SymptomEditing.styles';
// types
import { ISymptomEditProps } from './SymptomEditing.types';

const defaultValues = {
  status: '',
  notes: '',
  isInterference: false,
  medicalConditions: [],
};

const SymptomEditing: React.FC<ISymptomEditProps> = ({
  isOpen,
  onClose,
  title,
  symptom,
  isSaving,
  error,
  onSave,
  isNoDrawer,
  type,
  medicalConditions,
}) => {
  const classes = useStyles();
  const [importanceLevels, setImportanceLevels] = useState([]);
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: SymptomEditSchema,
    onSubmit: (payload: IDomainElement | any) => {
      onSave && onSave(payload);
    },
  });

  const medicalConditionList = medicalConditions?.map(condition => condition?.value);
  useEffect(() => {
    if (symptom) {
      if (symptom?.domainElementId) {
        const tempImportanceLevels = [];
        getDomainElements({ domainTypeId: symptom?.domainElementId })
          .then(res => {
            if (res.status === 200) {
              Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                  tempImportanceLevels.push({ value: key, label: key });
                }
              });
              setImportanceLevels(tempImportanceLevels);
            }
          })
          .catch(err => {
            console.log({ err });
          });
      }
    }
  }, [symptom]);

  useEffect(() => {
    if (symptom) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(symptom).forEach(async key => {
        if (key === 'tagMetaData') {
          await setFieldValue('isInterference', symptom[key].interferenceInLife);
          if (symptom[key]?.specification?.relatedToMedicalCondition) {
            await setFieldValue('medicalConditions', symptom[key]?.specification?.relatedToMedicalCondition);
          }
        } else if (key === 'priority') {
          await setFieldValue('status', symptom[key].name);
        } else {
          await setFieldValue(key, symptom[key]);
        }
      });
    }
  }, [symptom]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderContent = () => {
    return (
      <Box className={classes.wrapper}>
        <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
          <Box>
            <Text weight={fontWeight.BOLD} className={classes.headTitle}>
              {title} - {symptom.name}
            </Text>
            <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
              {symptom.description}
            </Text>
          </Box>
          <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
        </Box>
        <Box className={classes.headWrap}>
          <SymptomBottomCard symptom={symptom} showBadge />
        </Box>
        <form className={clsx(classes.form, { [classes.formNoDrawer]: isNoDrawer })} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center">
                <TextError errorMsg={error} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Importance level')}
              <SelectLevel
                value={values.status}
                isLifeEvent
                options={importanceLevels}
                onChange={handleChange}
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Interference with life')}
              <FormControlLabel
                control={<Checkbox />}
                checked={values.isInterference}
                name="isInterference"
                onChange={() => {
                  void setFieldValue('isInterference', !values.isInterference);
                }}
                label="This symptom has interference with patient’s life"
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Report notes')}
              <Textarea
                value={values.notes}
                name="notes"
                onChange={handleChange}
                onBlur={setTouched('notes')}
                minRows={5}
                placeholder="Enter your notes"
              />
              <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
            </Box>
            {renderLabel('Related info')}
            {medicalConditionList?.length > 0 && type === DomainTypesEnum.SYMPTOMS && (
              <Box className={classes.section}>
                {renderLabel('Related to medical conditions')}
                <Input
                  // freeSolo
                  fullWidth
                  name="medical conditions"
                  value={values.medicalConditions}
                  options={medicalConditionList}
                  variant={inputType.TAGS}
                  size={inputSize.M}
                  getOptionLabel={(option: string) => option}
                  onBlur={setTouched('medicalConditions')}
                  onChange={async (_: SyntheticEvent, newValue: string) => {
                    await setFieldValue('medicalConditions', newValue);
                  }}
                />
                {/* <TextError errorMsg={touched.symptoms ? errors.symptoms?.toString() : null} /> */}
              </Box>
            )}
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Update'}
            </Button>
          </Box>
        </form>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {symptom && renderContent()}
    </Drawer>
  );
};

export { SymptomEditing };
