import { createAction } from 'redux-actions';

export const FETCH_PROVIDER_SCHEDULE = 'schedule/fetchProviderSchedule';
export const FETCH_PROVIDER_SCHEDULE_SUCCESSFUL = 'schedule/fetchProviderScheduleSuccessful';
export const FETCH_PROVIDER_SCHEDULE_FAILED = 'schedule/fetchProviderScheduleFailed';
export const FETCH_DAILY_SCHEDULE = 'schedule/fetchDailySchedule';
export const FETCH_DAILY_SCHEDULE_SUCCESSFUL = 'schedule/fetchDailyScheduleSuccessful';
export const FETCH_DAILY_SCHEDULE_FAILED = 'schedule/fetchDailyScheduleFailed';

export const FETCH_PROVIDER_SCHEDULE_PUBLIC = 'schedule/public/fetchProviderSchedule';
export const FETCH_PROVIDER_SCHEDULE_SUCCESSFUL_PUBLIC = 'schedule/public/fetchProviderScheduleSuccessful';
export const FETCH_PROVIDER_SCHEDULE_FAILED_PUBLIC = 'schedule/public/fetchProviderScheduleFailed';

export const UPDATE_PROVIDER_SCHEDULE = 'schedule/updateProviderSchedule';
export const UPDATE_PROVIDER_SCHEDULE_SUCCESSFUL = 'schedule/updateProviderScheduleSuccessful';
export const UPDATE_PROVIDER_SCHEDULE_FAILED = 'schedule/updateProviderScheduleFailed';

export const FETCH_PROVIDER_SERVICES1 = 'schedule/fetchProviderServices';
export const FETCH_PROVIDER_SERVICES1_SUCCESSFUL = 'schedule/fetchProviderServicesSuccessful';
export const FETCH_PROVIDER_SERVICES1_FAILED = 'schedule/fetchProviderServicesFailed';

export const FETCH_SYSTEM_SERVICES = 'schedule/fetchSystemServices';
export const FETCH_SYSTEM_SERVICES_SUCCESSFUL = 'schedule/fetchSystemServicesSuccessful';
export const FETCH_SYSTEM_SERVICES_FAILED = 'schedule/fetchSystemServicesFailed';

export const FETCH_APPOINTMENT_REPORT = 'schedule/fetchAppointmentReport';
export const FETCH_APPOINTMENT_REPORT_SUCCESSFUL = 'schedule/fetchAppointmentReportSuccessful';
export const FETCH_APPOINTMENT_REPORT_FAILED = 'schedule/fetchAppointmentReportFailed';

export const FETCH_LEVEL_OF_ENGAGEMENTS = 'schedule/fetchLevelOfEngagements';
export const FETCH_LEVEL_OF_ENGAGEMENTS_SUCCESSFUL = 'schedule/fetchLevelOfEngagementsSuccessful';
export const FETCH_LEVEL_OF_ENGAGEMENTS_FAILED = 'schedule/fetchLevelOfEngagementsFailed';
export const FETCH_LOE_DETAILS = 'schedule/fetchLoeDetails';
export const FETCH_LOE_DETAILS_SUCCESSFUL = 'schedule/fetchLoeDetailsSuccessful';
export const FETCH_LOE_DETAILS_FAILED = 'schedule/fetchLoeDetailsFailed';
export const FETCH_LOE_STATUS_DETAILS = 'schedule/fetchLoeStatusDetails';
export const FETCH_LOE_STATUS_DETAILS_SUCCESSFUL = 'schedule/fetchLoeStatusDetailsSuccessful';
export const FETCH_LOE_STATUS_DETAILS_FAILED = 'schedule/fetchLoeStatusDetailsFailed';

export const scheduleActionCreators = {
  fetchProviderSchedule: createAction(FETCH_PROVIDER_SCHEDULE),
  fetchDailySchedule: createAction(FETCH_DAILY_SCHEDULE),
  fetchProviderSchedulePublic: createAction(FETCH_PROVIDER_SCHEDULE_PUBLIC),
  updateProviderSchedule: createAction(UPDATE_PROVIDER_SCHEDULE),
  fetchProviderServices: createAction(FETCH_PROVIDER_SERVICES1),
  fetchSystemServices: createAction(FETCH_SYSTEM_SERVICES),
  fetchAppointmentReport: createAction(FETCH_APPOINTMENT_REPORT),
  fetchLevelOfEngagements: createAction(FETCH_LEVEL_OF_ENGAGEMENTS),
  fetchLoeDetails: createAction(FETCH_LOE_DETAILS),
  fetchLoeStatusDetails: createAction(FETCH_LOE_STATUS_DETAILS),
};
