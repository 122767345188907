// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
  },
  header: {
    backgroundColor: colors.neutral25,
    borderTop: `1px solid ${colors.neutral100}`,
    borderBottom: `1px solid ${colors.neutral100}`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 16px',
  },
  headerText: {
    lineHeight: '20px',
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: '500',
  },
  closeBtn: {
    marginTop: -36,
    width: 40,
    minWidth: 40,
    height: 40,
    '& svg': {
      height: 20,
    },
  },
  body: {
    height: 'calc(100vh - 160px)',
    overflowY: 'hidden',
    backgroundColor: colors.white,
    position: 'relative',
  },
  footer: {
    display: 'none',
    backgroundColor: colors.neutral25,
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    padding: '16px 6px 16px 24px',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  doneBtn: {
    color: colors.white,
  },
  textarea: {
    border: 'none',
    height: '100% !important',
    position: 'absolute',
    overflow: 'auto !important',
  },
});
