import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import ProfileCard from 'pages/provider/profile/components/profile-card';
import EditServices from 'pages/provider/profile/components/services';
import { selectProviderServicesState1 } from 'redux/modules/schedule/selectors';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { stateActionCreators } from 'redux/modules/state';

const Services: FC = () => {
  const { services, isLoading } = useSelector(selectProviderServicesState1);
  const dispatch = useDispatch();
  const [showEditServices, setShowEditServices] = useState(false);
  const { providerId } = useParams();

  const toggleEditService = () => {
    setShowEditServices(s => !s);
  };

  useEffect(() => {
    dispatch(scheduleActionCreators.fetchProviderServices(providerId));
    dispatch(stateActionCreators.fetchCPTs());
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 1 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <ProfileCard title="Services" content={{ services }} onEdit={toggleEditService} />
      )}
      <EditServices
        isOpen={showEditServices}
        onClose={toggleEditService}
        services={services}
        providerId={providerId}
      />
    </Box>
  );
};

export { Services };
