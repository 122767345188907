import { INavItemType } from '@confidant-health/lib/ui/templates/side-navigation';

export const versionsMock = [
  { label: 'v1.0', value: 'v1.0' },
  { label: 'v1.1', value: 'v1.1' },
  { label: 'v2.0', value: 'v2.0' },
];

export const rightSideNavList: INavItemType[] = [
  { text: 'Assignments', link: '?name=assignments', query: { type: 'name', value: 'assignments' } },
  { text: 'Details', link: '?name=details', query: { type: 'name', value: 'details' } },
];
