import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { UploadFile } from '@confidant-health/lib/ui/templates/upload-file';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';

import UploadFileProgress from '../upload-flie-progress';
// schema
import { ClinicAffiliationSchema } from './ClinicAffiliation.schema';
// styles
import { useStyles } from './ClinicAffiliation.styles';
// constants
import { IClinicAffiliationProps, IClinicAffiliation } from './ClinicAffiliation.types';

const defaultValues = {
  title: '',
  description: '',
  avatar: null,
  file: null,
};

const EditClinicAffiliation: React.FC<IClinicAffiliationProps> = ({
  isOpen,
  onClose,
  clinicAffiliation,
  error,
  onUpdate,
}) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: ClinicAffiliationSchema,
    onSubmit: (payload: IClinicAffiliation) => {
      const data = {
        affiliationName: [payload.title],
        affiliationPlace: [payload.description],
        affiliationImage: payload.avatar,
      };
      onUpdate && onUpdate(data);
    },
  });

  const [savingButton, setSavingButton] = useState(false);

  const onUpdateButtonClick = () => {
    setSavingButton(!savingButton);
  };

  useEffect(() => {
    if (!isOpen) {
      setSavingButton(false);
    }
  }, [isOpen]);

  // Re enable update button when there are errors
  useEffect(() => {
    if (Object.keys(errors).length >= 1) {
      setSavingButton(false);
    }
  }, [errors, savingButton]);

  useEffect(() => {
    if (clinicAffiliation) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(clinicAffiliation).forEach(async key => {
        await setFieldValue(key, clinicAffiliation[key] || '');
      });
    }
  }, [clinicAffiliation]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onAcceptedFiles = (files: File[]) => {
    const file = files[0];
    void setFieldValue('avatar', file);
  };

  const onRemoveFile = () => {
    void setFieldValue('avatar', '');
  };

  const onFileUploaded = (val, file) => {
    void setFieldValue('avatar', val);
    setSelectedFile(file);
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  // eslint-disable-next-line no-nested-ternary
  const fileName = selectedFile?.name
    ? selectedFile?.name
    : values.avatar?.split
    ? values.avatar.split('/').pop()
    : 'unknown';

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>Clinic affiliation</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center" paddingY={1}>
                <TextError errorMsg={error} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Clinic Name')}
              <Input
                value={values.title}
                name="title"
                placeholder="Enter name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('title')}
                fullWidth
              />
              <TextError errorMsg={touched.title ? errors.title?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Place')}
              <Input
                value={values.description}
                name="description"
                placeholder="Enter place"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('description')}
                fullWidth
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Logo')}
              {values.avatar ? (
                <UploadFileProgress
                  file={values.avatar}
                  fileName={fileName}
                  fileSize={selectedFile?.size || 1024}
                  onUploaded={onFileUploaded}
                  onRemove={onRemoveFile}
                />
              ) : (
                <UploadFile
                  onAcceptedFiles={onAcceptedFiles}
                  description="PNG, JPG, GIF up to 5MB"
                  fileType="image/*"
                />
              )}
              <TextError errorMsg={touched.avatar ? errors.avatar?.toString() : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onUpdateButtonClick();
                handleSubmit();
              }}
              disabled={savingButton}
            >
              {savingButton ? 'Saving...' : 'Update'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { EditClinicAffiliation };
