export type IAddCollectionTemplateProps = {
  dct?: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (dctRequest: any) => void;
  isEvaluation?: boolean;
};

export const ResponderTypes = [
  { value: 'MEMBER', name: 'Member' },
  { value: 'PROVIDER', name: 'Provider' },
];
