import React, { FC, useEffect, useState } from 'react';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Box } from '@mui/material';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { fontWeight, Heading, headingLevel, TextError } from '@confidant-health/lib/ui/atoms/typography';
import cx from 'clsx';
import { useFormik } from 'formik';
import { RadioGroup, radioGroupType } from '@confidant-health/lib/ui/molecules/radio-group';
import { Select, selectSize, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { UploadFile } from '@confidant-health/lib/ui/templates/upload-file';
import { useDispatch, useSelector } from 'react-redux';
import MuiInputAdornment from '@mui/material/InputAdornment';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { stateActionCreators } from 'redux/modules/state';
import { useStyles } from './AddProviderDrawer.styles';
import { IProps } from './types';
import { AddProviderSchema } from './AddProvider.schema';
import UploadFileProgress from '../../conversations/components/upload-flie-progress';
import { addProvider } from '../../../../services/profile/profile.service';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { specialities } from '../../../provider/profile/components/general-info/GeneralInfo.constants';
import { stateSelector } from '../../../../redux/modules/state/selectors';
import { MediaManager } from '../../../../services/mediaManager/mediaManager';

const radioOptions = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
];

const signOffRole = [
  { label: 'Default', value: 'Default' },
  { label: 'Supervisor', value: 'supervisor' },
  { label: 'Associate', value: 'associate' },
];

const designation = [
  { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
  { label: 'Therapist', value: 'Therapist' },
  { label: 'Counselor', value: 'Counselor' },
  { label: 'Coach', value: 'Coach' },
  { label: 'Care Manager', value: 'Care Manager' },
  { label: 'Physician', value: 'Physician' },
];

const defaultValues = {
  name: '',
  providerCode: '',
  gender: 'MALE',
  email: '',
  password: '',
  address: '',
  phone: '',
  signOffRole: 'Default',
  designation: '',
  operatingStates: [],
  speciality: [],
  approach: '',
  about: '',
};
const AddProviderDrawer: FC<IProps> = ({ open, onClose, onSuccess }) => {
  const classes = useStyles();
  const [imageFile, setImageFile] = useState<File>();
  const [showPassword, setShowPassword] = useState(true);
  const dispatch = useDispatch();

  const { states: availableStates } = useSelector(stateSelector);
  const [statesList, setStatesList] = useState([]);
  const [imageURL, setImageURL] = useState('');
  let payload;
  const processFile = async () => {
    const filesRead = await MediaManager.uploadDisplayPicture(imageFile, e => {
      console.log(e.percent);
    });
    return filesRead.response.key;
  };
  const addNewProvider = async values => {
    let imageUrl = null;
    if (imageURL) {
      imageUrl = imageURL;
    }
    if (imageFile && !imageURL) {
      imageUrl = await processFile();
      setImageURL(imageUrl);
    }
    payload = {
      ...values,
      operatingStates: values.operatingStates.map(v => v.value),
      speciality: values.speciality.map(v => v.value),
      profilePhoto: imageUrl,
      matchmaker: false,
      twoFAEnabled: false,
      requireSupervisorSignOff: false,
    };
    try {
      const { data } = await addProvider(payload);
      if (data.errors) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: data.errors,
          }),
        );
      } else {
        onSuccess();
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'New provider added successfully',
          }),
        );
      }
    } catch (e) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: e.data.errors[0].endUserMessage,
        }),
      );
      console.warn(e.data?.errors);
    }
  };

  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema: AddProviderSchema,
    onSubmit: () => {
      void addNewProvider(values);
    },
  });

  useEffect(() => {
    dispatch(stateActionCreators.fetchStates());
  }, []);

  useEffect(() => {
    if (availableStates) {
      setStatesList(
        availableStates.map(state => {
          return {
            title: state.state.name,
            value: state.state.name,
          };
        }),
      );
    }
  }, [availableStates]);

  const onAcceptedFiles = (files: File[]) => {
    const file = files[0];
    setImageFile(file);
  };

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };
  const onChangeTags = (name: string) => async (_, val) => {
    await rest.setFieldValue(name, val);
  };

  const handlePhoneChange = val => {
    void rest.setFieldValue('phone', val);
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );
  const resetDrawer = () => {
    void rest.resetForm();
    setImageFile(null);
  };

  useEffect(() => {
    if (!open) {
      resetDrawer();
    }
  }, [open]);

  const onClickEye = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Add Provider"
      variant={drawerType.FORM}
      className={classes.drawer}
      submitBtnTitle="Add provider"
      onSubmit={handleSubmit}
      narrowHeader
    >
      <Box className={classes.drawerBodyContainer}>
        <form>
          <Box className={classes.rowContainer}>
            <Box className={classes.name}>
              {renderLabel('Name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter name"
                size={inputSize.M}
                fullWidth
                onChange={handleChange}
                onBlur={setTouched('name')}
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.providerCode}>
              {renderLabel('Provider Code')}
              <Input
                value={values.providerCode}
                name="providerCode"
                placeholder="Enter provider code"
                onChange={handleChange}
                size={inputSize.M}
                fullWidth
              />
            </Box>
          </Box>
          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
          <Box className={classes.radioContainer}>
            <RadioGroup
              value={values.gender}
              variant={radioGroupType.HORIZONTAL}
              options={radioOptions}
              defaultValue={radioOptions[0].value}
              onChange={handleChange}
              name="gender"
              className={classes.radio}
            />
          </Box>
          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
          <Box className={classes.emailPassContainer}>
            <Box className={classes.name}>
              {renderLabel('Email')}
              <Input
                value={values.email}
                name="email"
                placeholder="Enter email"
                size={inputSize.M}
                fullWidth
                onChange={handleChange}
                onBlur={setTouched('email')}
              />
            </Box>
            <Box className={classes.providerCode}>
              {renderLabel('Password')}
              <Input
                value={values.password}
                name="password"
                type={showPassword ? 'password' : 'text'}
                placeholder="Enter password"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('password')}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <MuiInputAdornment position="end">
                      <Icons
                        glyph="eye-outlined"
                        className={classes.eye}
                        color={colors.neutral500}
                        onClick={onClickEye}
                      />
                    </MuiInputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <Box className={classes.errorContainer}>
            <Box className={classes.emailError}>
              <TextError errorMsg={touched.email ? errors.email?.toString() : null} />
            </Box>
            <Box className={classes.passwordError}>
              <TextError errorMsg={touched.password ? errors.password?.toString() : null} />
            </Box>
          </Box>
          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
          <Box>
            {renderLabel('Phone')}
            <Box className={classes.phoneContainer}>
              <Input
                value={values.phone}
                name="phone"
                placeholder="Enter phone number"
                onChange={handlePhoneChange}
                size={inputSize.M}
                onBlur={setTouched('phone')}
                variant={inputType.PHONE}
                fullWidth
              />
            </Box>
            <TextError errorMsg={touched.phone ? errors.phone?.toString() : null} />
          </Box>

          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          >
            {renderLabel('Sign off role')}
            <Box className={classes.signoffRole}>
              <Select
                value={values.signOffRole}
                name="signOffRole"
                variant={selectType.SECONDARY}
                options={signOffRole}
                size={selectSize.S}
                className={classes.select}
                onChange={handleChange}
              />
            </Box>
          </Box>

          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
          <Box>
            {renderLabel('Designation')}
            <Box className={classes.signoffRole}>
              <Select
                value={values.designation}
                name="designation"
                variant={selectType.SECONDARY}
                options={designation}
                size={selectSize.S}
                className={classes.select}
                onChange={handleChange}
              />
            </Box>
            <TextError errorMsg={touched.designation ? errors.designation?.toString() : null} />
          </Box>

          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          >
            {renderLabel('State')}
            <Box className={classes.signoffRole}>
              <Input
                value={values.operatingStates}
                name="operatingStates"
                onChange={onChangeTags('operatingStates')}
                options={statesList}
                size={inputSize.M}
                variant={inputType.TAGS}
                getOptionLabel={option => `${option.title}`}
                fullWidth
              />
            </Box>
          </Box>

          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
          <Box>
            {renderLabel('Specialities')}
            <Box className={classes.fullWidthRowContainer}>
              <Input
                value={values.speciality}
                name="speciality"
                variant={inputType.TAGS}
                // placeholder="Enter an speciality"
                onChange={onChangeTags('speciality')}
                options={specialities.filter(item => {
                  const foundItem = values.speciality?.find(itm => itm.value === item.value);
                  if (foundItem) {
                    return false;
                  }
                  return item;
                })}
                size={inputSize.M}
                onBlur={setTouched('speciality')}
                fullWidth
              />
            </Box>
          </Box>
          <TextError errorMsg={touched.speciality ? errors.speciality?.toString() : null} />

          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
          <Box>
            {renderLabel('Approach')}
            <Box className={classes.fullWidthRowContainer}>
              <Textarea
                value={values.approach}
                name="approach"
                type="text"
                onChange={handleChange}
                onBlur={setTouched('designation')}
                placeholder="Enter approaches"
                minRows={5}
              />
            </Box>
            <TextError errorMsg={touched.approach ? errors.approach?.toString() : null} />
          </Box>

          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
          <Box>
            {renderLabel('About')}
            <Box className={classes.fullWidthRowContainer}>
              <Textarea
                value={values.about}
                name="about"
                type="text"
                onChange={handleChange}
                onBlur={setTouched('about')}
                placeholder="Enter about provider"
                minRows={5}
              />
            </Box>
          </Box>

          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
          {renderLabel('Profile photo')}
          <Box>
            {imageFile ? (
              <UploadFileProgress
                file={imageFile}
                onUploaded={() => {
                  console.log('uploaded');
                }}
                onRemove={() => {
                  setImageFile(null);
                }}
              />
            ) : (
              <UploadFile
                description="File size up to 5MB"
                onAcceptedFiles={onAcceptedFiles}
                fileType="image/*"
                className={classes.dashedBorder}
              />
            )}
          </Box>
          <Box
            className={cx({
              [classes.marginClass]: true,
            })}
          />
        </form>
      </Box>
    </Drawer>
  );
};

export { AddProviderDrawer };
