import dayjs from 'dayjs';
import { IUserGroup } from 'redux/modules/profile/types';

export const groupsMock: IUserGroup[] = [
  {
    attendanceCount: 20,
    channelUrl: 'url',
    groupImage: 'https://i.pravatar.cc/100?img=2',
    joinedAt: dayjs().subtract(1, 'd').toISOString(),
    name: 'Healthier habits',
    meetings: new Array(20).fill(1),
  },
  {
    attendanceCount: 11,
    channelUrl: 'url',
    groupImage: 'https://i.pravatar.cc/100?img=2',
    joinedAt: dayjs().subtract(1, 'd').toISOString(),
    name: 'Healthier habits',
    meetings: new Array(20).fill(1),
  },
  {
    attendanceCount: 12,
    channelUrl: 'url',
    groupImage: 'https://i.pravatar.cc/100?img=2',
    joinedAt: dayjs().subtract(1, 'd').toISOString(),
    name: 'Healthier habits',
    meetings: new Array(20).fill(1),
  },
  {
    attendanceCount: 13,
    channelUrl: 'url',
    groupImage: 'https://i.pravatar.cc/100?img=2',
    joinedAt: dayjs().subtract(1, 'd').toISOString(),
    name: 'Healthier habits',
    meetings: new Array(20).fill(1),
  },
];
