import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as ReportingService from 'services/reporting/reporting.service';
import * as ProviderFeeService from 'services/provider-fee/providerFee.service';
import {
  FETCH_CONTENT_BLOCK_HISTORY,
  FETCH_CONTENT_BLOCK_HISTORY_FAILED,
  FETCH_CONTENT_BLOCK_HISTORY_SUCCESSFUL,
  FETCH_REPORTS_LIST,
  FETCH_REPORTS_LIST_FAILED,
  FETCH_REPORTS_LIST_SUCCESSFUL,
  CREATE_REPORT,
  CREATE_REPORT_SUCCESSFUL,
  CREATE_REPORT_FAILED,
  UPDATE_REPORT,
  UPDATE_REPORT_SUCCESSFUL,
  UPDATE_REPORT_FAILED,
  DELETE_REPORT,
  DELETE_REPORT_SUCCESSFUL,
  DELETE_REPORT_FAILED,
  FETCH_CLAIMS_SUCCESSFUL,
  FETCH_CLAIMS_FAILED,
  FETCH_CLAIMS,
  FETCH_INVOICES,
  FETCH_INVOICES_SUCCESSFUL,
  FETCH_INVOICES_FAILED,
  FETCH_CLAIM_BY_ID,
  FETCH_CLAIM_BY_ID_SUCCESSFUL,
  FETCH_CLAIM_BY_ID_FAILED,
  PUT_CLAIM_DETAIL_SUCCESSFUL,
  PUT_CLAIM_DETAIL_FAILED,
  PUT_CLAIM_DETAIL,
  PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_FAILED,
  PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_SUCCESSFUL,
  PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID,
  FFETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_SUCCESSFUL,
  FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_FAILED,
  FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID,
  FETCH_INVOICE_BY_ID_SUCCESSFUL,
  FETCH_INVOICE_BY_ID_FAILED,
  FETCH_INVOICE_BY_ID,
  UPDATE_INVOICE_BY_ID,
  UPDATE_INVOICE_BY_ID_FAILED,
  UPDATE_INVOICE_STATUS_BY_ID,
  UPDATE_INVOICE_STATUS_BY_ID_FAILED,
} from './actions';
import { selectReport } from './selectors';
import { showSnackbar } from '../snackbar';

function* fetchContentBlockHistory(action: IAction) {
  const { contentBlockId, userId } = action.payload;
  try {
    const { data } = yield call(ReportingService.getContentBlockHistory, { contentBlockId, userId });

    yield put({
      type: FETCH_CONTENT_BLOCK_HISTORY_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_CONTENT_BLOCK_HISTORY_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* reportListHandler(action: IAction) {
  const { pageNumber, pageSize, searchQuery, orderBy } = action.payload || {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
  };

  try {
    const response = yield call(ReportingService.getReportList, {
      pageNumber,
      pageSize,
      searchQuery,
      orderBy,
      sortBy: 'Date Created',
    });

    if (response.status === 200) {
      const { data } = response;

      yield put({
        type: FETCH_REPORTS_LIST_SUCCESSFUL,
        payload: {
          currentPage: data.paginator.pageNumber,
          pageSize: data.paginator.pageSize,
          totalPages: data.paginator.totalPages,
          totalRecords: data.paginator.itemCount,
          reportList: data.itemsList,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_REPORTS_LIST_FAILED,
      payload: {
        message: e,
      },
    });
  }
}

function* createReportHandler(action: IAction) {
  try {
    yield call(ReportingService.addReport, action.payload.data);

    yield put({
      type: CREATE_REPORT_SUCCESSFUL,
      payload: {
        success: true,
      },
    });
  } catch (e) {
    yield put({
      type: CREATE_REPORT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updateReportHandler(action: IAction) {
  try {
    const resp = yield call(ReportingService.updateReport, action.payload.id, action.payload.data);

    const newReport = resp.data.data[0];

    yield put({
      type: UPDATE_REPORT_SUCCESSFUL,
      payload: {
        report: newReport,
      },
    });
  } catch (e) {
    yield put({
      type: UPDATE_REPORT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* deleteReportHandler(action: IAction) {
  try {
    yield call(ReportingService.deleteReport, action.payload);

    yield put({
      type: DELETE_REPORT_SUCCESSFUL,
      payload: {
        success: true,
      },
    });
    const { pageSize, currentPage } = yield select(selectReport);
    yield put({
      type: FETCH_REPORTS_LIST,
      payload: {
        pageNumber: currentPage,
        pageSize,
        searchQuery: '',
        orderBy: 'desc',
        sortBy: 'Date Created',
      },
    });
  } catch (e) {
    yield put({
      type: DELETE_REPORT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchClaims(action: IAction) {
  const {
    pageSize = 10,
    pageNumber = 1,
    sortBy = 'createdAt',
    sortOrder = 'desc',
    body = {},
  } = action.payload;
  try {
    const { data } = yield call(ReportingService.getClaims, body, {
      pageSize,
      pageNumber,
      sortBy,
      sortOrder,
    });

    yield put({
      type: FETCH_CLAIMS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_CLAIMS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchClaimById(action: IAction) {
  const { id } = action.payload;
  try {
    const { data } = yield call(ReportingService.getClaimById, { id });

    yield put({
      type: FETCH_CLAIM_BY_ID_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_CLAIM_BY_ID_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updateClaimDetail(action: IAction) {
  try {
    const { type, formData, claimId } = action.payload;

    yield call(ReportingService.updateClaimById, formData, { claimId, type });

    const { data } = yield call(ReportingService.getClaimById, { id: claimId });
    yield put({
      type: PUT_CLAIM_DETAIL_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    console.log({ e });
    const message = e?.data?.error || 'Something went wrong';
    yield put({
      type: PUT_CLAIM_DETAIL_FAILED,
      payload: { message },
    });
  }
}

function* fetchInvoices(action: IAction) {
  const {
    pageSize = 10,
    pageNumber = 1,
    sortBy = 'createdAt',
    sortOrder = 'desc',
    body = {},
  } = action.payload || {};
  try {
    const { data } = yield call(ReportingService.getInvoices, body, {
      pageSize,
      pageNumber,
      sortBy,
      sortOrder,
    });

    yield put({
      type: FETCH_INVOICES_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_INVOICES_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchInvoiceById(action: IAction) {
  const { id } = action.payload;
  try {
    const { data } = yield call(ReportingService.getInvoiceById, { id });
    yield put({
      type: FETCH_INVOICE_BY_ID_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    console.log({ e });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: e?.data?.error || 'Something went wrong!',
      }),
    );
    yield put({
      type: FETCH_INVOICE_BY_ID_FAILED,
      payload: { message: e.data?.error || 'Something went wrong!' },
    });
  }
}

function* updateInvoiceDetail(action: IAction) {
  try {
    const { id, payload } = action.payload;
    yield call(ReportingService.updateInvoiceById, payload, { id });
    const { data } = yield call(
      ReportingService.getInvoices,
      {},
      {
        pageSize: 10,
        pageNumber: 1,
        sortBy: 'createdAt',
        sortOrder: 'desc',
      },
    );

    yield put({
      type: FETCH_INVOICES_SUCCESSFUL,
      payload: data,
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Invoice updated successfully !',
      }),
    );
  } catch (e) {
    console.log({ e });
    const message = e?.data?.error || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: e?.data?.error || 'Something went wrong!',
      }),
    );
    yield put({
      type: UPDATE_INVOICE_BY_ID_FAILED,
      payload: { message },
    });
  }
}

function* updateInvoiceStatusById(action: IAction) {
  try {
    const { requestBody, id } = action.payload;
    const filters = action.payload?.filters || null;
    yield call(ReportingService.updateInvoiceStatus, requestBody, { id });
    const { data } = yield call(ReportingService.getInvoices, filters?.body, {
      pageSize: 10,
      pageNumber: 1,
      sortBy: 'createdAt',
      sortOrder: 'desc',
    });

    yield put({
      type: FETCH_INVOICES_SUCCESSFUL,
      payload: data,
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Invoice status updated successfully !',
      }),
    );
  } catch (e) {
    console.log({ e });
    const message = e?.data?.error || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: e?.data?.error || 'Something went wrong!',
      }),
    );
    yield put({
      type: UPDATE_INVOICE_STATUS_BY_ID_FAILED,
      payload: { message },
    });
  }
}

function* fetchProviderFeeByAppointmentId(action: IAction) {
  const { id } = action.payload;
  try {
    const response = yield call(ProviderFeeService.getProviderFeeByAppointmentId, { id });
    if (response?.status === 200) {
      yield put({
        type: FFETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_SUCCESSFUL,
        payload: response?.data,
      });
    } else {
      yield put({
        type: FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_FAILED,
        payload: { message: response?.data?.message || 'Something went wrong!' },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updateProviderFeeByProviderId(action: IAction) {
  try {
    const { formData, providerId } = action.payload;

    const { data } = yield call(ReportingService.updateProviderFeeByProviderId, formData, { providerId });

    yield put({
      type: PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e?.data?.message || 'Something went wrong';

    yield put({
      type: PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_FAILED,
      payload: { message },
    });
  }
}

function* reportingSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_CONTENT_BLOCK_HISTORY, fetchContentBlockHistory);
  yield takeLatest(FETCH_REPORTS_LIST, reportListHandler);
  yield takeLatest(CREATE_REPORT, createReportHandler);
  yield takeLatest(UPDATE_REPORT, updateReportHandler);
  yield takeLatest(DELETE_REPORT, deleteReportHandler);
  yield takeLatest(FETCH_CLAIMS, fetchClaims);
  yield takeLatest(FETCH_CLAIM_BY_ID, fetchClaimById);
  yield takeLatest(PUT_CLAIM_DETAIL, updateClaimDetail);
  yield takeLatest(FETCH_INVOICES, fetchInvoices);
  yield takeLatest(FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID, fetchProviderFeeByAppointmentId);
  yield takeLatest(PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID, updateProviderFeeByProviderId);
  yield takeLatest(FETCH_INVOICE_BY_ID, fetchInvoiceById);
  yield takeLatest(UPDATE_INVOICE_BY_ID, updateInvoiceDetail);
  yield takeLatest(UPDATE_INVOICE_STATUS_BY_ID, updateInvoiceStatusById);
}

export default reportingSaga;
