import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import TableCard from '../components/table-card';
import { getAssignedPrioritiesV2 } from '../../../../services/priority/priority.service';
import { mapToLegacy } from '../../../../utils/CommonUtils';

type Props = {
  memberId: string;
  hideNotes?: boolean;
  onViewAll?: (type) => void;
  title?: string;
  reset: boolean;
  onClick: (item: any, type: string) => void;
};
const Diagnoses: FC<Props> = ({ memberId, onViewAll, hideNotes, title = '', reset, onClick }) => {
  const typeName = 'diagnoses';
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const { lookupData } = useSelector(getProfile);

  useEffect(() => {
    setLoading(true);
    getAssignedPrioritiesV2({
      patientId: memberId,
      dataDomainType: typeName,
      pageNumber: 0,
      pageSize: 3,
    })
      .then(({ data }) => {
        setRecords(data.records.map(record => mapToLegacy(record, lookupData)));
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        setRecords([]);
      });
  }, [memberId, reset]);

  const columns = [
    {
      id: 'name',
      label: 'Diagnosis name',
    },
    {
      id: 'provider',
      label: 'Diagnosing provider',
    },
    {
      id: 'icd',
      label: 'ICD-10 Code',
    },
    {
      id: 'createdAt',
      label: 'Date added',
    },
    {
      id: 'status',
      label: 'Status',
    },
  ];

  if (!loading && records.length === 0) {
    return null;
  }
  return (
    <>
      <TableCard
        columns={columns}
        data={records}
        loading={loading}
        title={title === '' ? 'Diagnoses' : title}
        hideNotes={hideNotes}
        onRowClick={item => onClick(item, typeName)}
        action={{ onClick: () => onViewAll(typeName), label: 'View all' }}
      />
    </>
  );
};

export default Diagnoses;
