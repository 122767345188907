import dayjs from 'dayjs';
import { DomainPriorities, IDomainElement } from 'redux/modules/profile/types';

/* eslint-disable max-len */
export const sideEffectList: IDomainElement[] = [
  {
    id: '1',
    name: 'Dry mouth',
    description: 'Wellbutrin',
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
    assignedBy: 'Patient, self-report',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: '',
    priority: {
      name: DomainPriorities.CRITICAL,
      color: 'red',
    },
    history: [],
  },
];

export const sideEffectItems = [
  {
    label: 'Wellbutrin',
    groupId: 'Wellbutrin',
    items: [
      { label: 'Blurred vision', value: 'Blurred vision' },
      { label: 'Low blood pressure', value: 'Low blood pressure' },
      { label: 'Dry mouth', value: 'Dry mouth' },
    ],
  },
  {
    label: 'Naloxone',
    groupId: 'Naloxone',
    items: [
      { label: 'Naloxone Agitated', value: 'Naloxone Agitated' },
      { label: 'Naloxone Anxious', value: 'Naloxone Anxious' },
      { label: 'Naloxone Aggressive', value: 'Naloxone Aggressive' },
      { label: 'Naloxone Angry', value: 'Naloxone Angry' },
    ],
  },
  {
    label: 'Naltrexone',
    groupId: 'Naltrexone',
    items: [
      { label: 'Naltrexone Agitated', value: 'Naltrexone Agitated' },
      { label: 'Naltrexone Anxious', value: 'Naltrexone Anxious' },
      { label: 'Naltrexone Aggressive', value: 'Naltrexone Aggressive' },
      { label: 'Naltrexone Angry', value: 'Naltrexone Angry' },
    ],
  },
];

export const stepTitles = {
  0: 'Step 1 - select side effect',
  1: 'Step 2 - side effect details',
  2: 'Step 3 - review side effects',
};
