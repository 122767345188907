import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { fontWeight, Heading } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import PriorityEmpty from 'pages/provider/member-detail/components/PriorityEmpty';
import { useStyles } from './AddEvaluation.styles';

type Props = {
  evaluationsList: any[];
  onAddEvaluationClick: (checkedEvaluationIds: any[]) => void;
  onClose: () => void;
  inPersonSession?: boolean;
};
const AddEvaluation: FC<Props> = ({ evaluationsList, onAddEvaluationClick, onClose, inPersonSession }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [selectedEvaluationsIDs, setSelectedEvaluationsIDs] = useState([]);
  const [evaluationList, setEvaluationList] = useState(evaluationsList || []);

  const onAdd = () => {
    onAddEvaluationClick(selectedEvaluationsIDs);
  };

  /**
   * @Name updateEvaluationCheckBoxStatus
   * @description This method is used to update checkbox status
   */
  const updateEvaluationCheckBoxStatus = (evaluationId: any) => () => {
    const updatedselectedEvaluationsIDList = selectedEvaluationsIDs.includes(evaluationId)
      ? selectedEvaluationsIDs.filter(i => i !== evaluationId)
      : [...selectedEvaluationsIDs, evaluationId];
    setSelectedEvaluationsIDs(updatedselectedEvaluationsIDList);
  };

  useEffect(() => {
    setEvaluationList(evaluationsList);
  }, [evaluationsList]);

  /**
   * @Name useEffect
   * @description This method is used to get filtered Evaluation List based on search text value
   */
  useEffect(() => {
    const filteredEvaluationList = evaluationsList?.filter(evaluation =>
      evaluation?.name.toLowerCase().includes(searchText?.toLowerCase()),
    );
    setEvaluationList(filteredEvaluationList);
  }, [searchText]);

  return (
    <Box
      className={clsx(classes.container, {
        [classes.whiteBg]: !inPersonSession,
      })}
    >
      {!inPersonSession && (
        <Box className={classes.header}>
          <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtn} />
          <Heading weight={fontWeight.BOLD} className={classes.title}>
            Add evaluation
          </Heading>
        </Box>
      )}
      <Box
        className={clsx({
          [classes.body]: !inPersonSession,
          [classes.bodyInPerson]: inPersonSession,
        })}
      >
        <Box
          className={clsx({
            [classes.searchInputWrap]: !inPersonSession,
            [classes.searchInputWrapNoPadding]: inPersonSession,
          })}
        >
          <Input
            variant={inputType.SEARCH}
            value={searchText}
            placeholder="Search"
            onChange={setSearchText}
            className={classes.searchInput}
          />
        </Box>
        {evaluationList?.length > 0 ? (
          <Box
            className={clsx({
              [classes.evaluationsNoXPadding]: inPersonSession,
              [classes.evaluations]: !inPersonSession,
            })}
          >
            {evaluationList?.map(({ evaluationId, name }) => (
              <FormControlLabel
                key={evaluationId}
                control={<Checkbox />}
                label={name}
                onChange={updateEvaluationCheckBoxStatus(evaluationId)}
                className={classes.checkbox}
              />
            ))}
          </Box>
        ) : (
          <PriorityEmpty
            title="No evaluations"
            description="Right now we have no evaluation found in the system."
            className="session-evaluations"
          />
        )}
      </Box>
      <Box className={classes.footer}>
        <IconButton
          onClick={onAdd}
          disabled={selectedEvaluationsIDs?.length === 0}
          variant={iconBtnType.PRIMARY}
          className={classes.addSelectedBtn}
        >
          Add selected
        </IconButton>
      </Box>
    </Box>
  );
};

export { AddEvaluation };
