import { IGlyphs } from '@confidant-health/lib/icons';

export interface InfoType {
  title: string;
  description: string;
}
export interface chatBotDetail {
  score?: number;
  label: string;
  infoList?: InfoType[];
}
export interface ChatbotT {
  title: string;
  subTitle: string;
  progress: number;
  flourishingScale?: chatBotDetail;
  medicalHistory?: chatBotDetail;
  psychiatricScreener?: chatBotDetail;
  exclusionCriteria?: chatBotDetail;
  PHQ9?: chatBotDetail;
  GAD7?: chatBotDetail;
}

export interface ActivityDataT {
  title: string;
  subTitle: string;
  date: string;
  color?: string;
  isLastItem?: boolean;
  icon: IGlyphs['glyph'];
}

export interface ICareTeam {
  connectionId: string;
  designation: string;
  name: string;
  lastSessions: number;
  scheduledSessions: number;
  nextAppointment: {
    appointmentId: string;
    serviceId: string;
    serviceName: string;
    startTime: string;
  };
  profilePicture: string;
  type: string;
  visitFrequency: string;
}

export interface IMatchmaker {
  active: boolean;
  address: string;
  firstName: string;
  fullName: string;
  lastName: string;
  profileImage: string;
  userId: string;
}

export enum ReportItemProgress {
  Completed = 'Completed',
  InProgress = 'In progress',
  Incomplete = 'Incomplete',
}
export interface IDownloadQnA {
  question: string;
  providerNarrative?: string;
  answer: string[];
}
export interface IDownloadDCT {
  dctName: string;
  dctId: string;
  dctScore: number;
  dctQuestionAnswer: IDownloadQnA[];
}
export interface IDownloadEvaluation {
  name: string;
  _id: string;
  dctList: IDownloadDCT[];
}
export interface IDownloadCPTList {
  cptCode: string;
  diagnosis: string;
  icd10Code: string;
}
export interface IDownloadCPTCodes {
  cptList: IDownloadCPTList[];
}
export interface IDownloadAppointment {
  appointmentType: string[];
  appointmentTime: string;
  serviceName: string;
  appointmentDuration: string;
  cptCodes: IDownloadCPTCodes;
  clinician: string;
  evaluations?: IDownloadEvaluation[];
  signOffStatus: string;
  signOffAt: string;
}

export interface IDownloadMedication {
  importanceLevel: {
    name: string;
    color: string;
  };
  elementName: string;
  notes: string;
  assignedAt: string;
  dose: string;
  dosage: string;
  frequency: string;
  provider: {
    prescribingProvider: string;
    confidantProviderName?: string;
    providerName?: string;
  };
  tagMetaData: {
    rxDrugInfo: {
      dose: number;
      supply: number;
      doseFrequency: number;
      dosage: string;
      frequency: string;
      prescribingProvider: string;
      confidantProviderName?: string;
      providerName?: string;
    };
    substanceUse: any;
  };
}

export interface IDownloadDiagnosis {
  importanceLevel: {
    name: string;
    color: string;
  };
  elementName: string;
  notes: string;
  assignedAt: string;
  selectedIct10code: string;
  dataElementIcd10CodeList: string[];
  tagMetaData: {
    rxDrugInfo: any;
    substanceUse: any;
  };
}
