import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { AppointmentStatus } from '@confidant-health/lib/constants/CommonConstants';
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';

export enum AppointmentTitles {
  pending = 'Pending appointments',
  past = 'Past appointments',
  current = 'Current appointments',
}

export const appointmentColumns: TableGrid.TableColumn[] = [
  { id: 'member', label: 'Member', sortable: true },
  { id: 'sessionType', label: 'Session type', sortable: false },
  { id: 'appointmentStatus', label: 'Status', sortable: true },
  { id: 'appointmentTime', label: 'Date & time', sortable: true },
  { id: 'actions', label: '', sortable: false },
];

export const pastAppointmentColumns: TableGrid.TableColumn[] = [
  { id: 'provider', label: 'Provider', sortable: true },
  { id: 'sessionType', label: 'Session type', sortable: false },
  { id: 'appointmentStatus', label: 'Status', sortable: true },
  { id: 'appointmentTime', label: 'Date & time', sortable: true },
  { id: 'rating', label: 'Rating', sortable: false },
  { id: 'action', label: '', sortable: false },
];

export const appointmentFilterStatusOptions = [
  { label: 'Status is Completed', value: AppointmentStatus.FULFILLED },
  { label: 'Status is Confirmed', value: AppointmentStatus.BOOKED },
  { label: 'Status is Requested', value: AppointmentStatus.NEEDS_ACTION },
  { label: 'Status is Cancelled', value: AppointmentStatus.CANCELLED },
  { label: 'Status is No Show', value: AppointmentStatus.NO_SHOW },
];

export const appointmentSchedulingTabs = [
  { label: 'Next available', tabKey: 'next_available' },
  { label: 'Specific week', tabKey: 'specific_week' },
];

export const appointmentSorterMap = {
  member: 'participantName',
  appointmentStatus: 'status',
  appointmentTime: 'startTime',
};

export const defaultAppointmentFilterOptions = [
  {
    sectionTitle: 'State',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Texas', value: 'Texas' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'New Hampshire', value: 'New Hampshire' },
    ],
  },
  {
    sectionTitle: 'VBC',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [{ label: 'VBC flag', value: 'VBC flag' }],
  },
];
export const currentAppointmentsFilterOption = [
  {
    sectionTitle: 'Status',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'Today', value: 'Today' },
      { label: 'Scheduled', value: 'Scheduled' },
    ],
    sectionTooltip: 'This filter will precede date range filter.',
  },
  ...defaultAppointmentFilterOptions,
];

export const pastAppointmentsFilterOption = [
  {
    sectionTitle: 'Status',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Cancelled', value: AppointmentStatus.CANCELLED },
      { label: 'Completed', value: AppointmentStatus.FULFILLED },
      { label: 'No Show', value: 'NO SHOW' },
    ],
  },
  ...defaultAppointmentFilterOptions,
];
