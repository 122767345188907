import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {},
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    backgroundColor: colors.neutral25,
    border: `1px solid ${colors.neutral100}`,
  },
  divider: {
    backgroundColor: colors.neutral100,
  },
  icon: {
    marginRight: 18,
    '& path': {
      fill: colors.primary400,
    },
  },
  input: {
    backgroundColor: colors.white,
  },
  title: {
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
  },
  subtitle: {
    color: '#4C5965',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    position: 'relative',
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  copyBtn: {
    fontSize: 14,
    color: colors.primary600,
    '& svg': {
      height: 20,
      '& path': {
        fill: colors.primary500,
      },
    },
  },
  checkbox: {
    marginRight: '12px',
  },
  deleteBtn: {
    fontSize: 14,
    color: colors.destructive600,
    '& svg': {
      height: 20,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
  JoditEditorRoot: {
    '& .jodit-status-bar': {
      display: 'none',
    },
  },
});
