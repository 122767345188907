import { TableGrid } from '@confidant-health/lib/ui/organisms/table';

export const tagColumns: TableGrid.TableColumn[] = [
  { id: 'name', label: 'Name', sortable: false },
  { id: 'type', label: 'Tag Type', sortable: false },
  { id: 'assignmentMethod', label: 'Assignment Method', sortable: false },
  { id: 'associationCount', label: 'Connected To', sortable: false },
  { id: 'priority', label: 'Tag Priority', sortable: false },
  { id: 'actions', label: 'Actions', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];

export const tagListMock = [
  {
    id: '1',
    name: 'Test tag',
    type: 'Life Events',
    method: 'Profile element',
    connectedTo: 0,
    priority: 'Critical',
  },
  {
    id: '2',
    name: 'Tag name',
    type: 'Life Events',
    method: 'Profile element',
    connectedTo: 0,
    priority: 'Critical',
  },
  {
    id: '3',
    name: 'Tag name',
    type: 'Life Events',
    method: 'Profile element',
    connectedTo: 0,
    priority: 'Critical',
  },
  {
    id: '4',
    name: 'Tag name',
    type: 'Life Events',
    method: 'Profile element',
    connectedTo: 0,
    priority: 'Critical',
  },
  {
    id: '5',
    name: 'Tag name',
    type: 'Life Events',
    method: 'Profile element',
    connectedTo: 0,
    priority: 'Critical',
  },
  {
    id: '6',
    name: 'Tag name',
    type: 'Life Events',
    method: 'Profile element',
    connectedTo: 0,
    priority: 'Critical',
  },
];
