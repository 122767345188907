import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  table: {
    backgroundColor: colors.white,
    overflowX: 'auto',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  btnGroupIcon: {
    color: colors.neutral700,
    backgroundColor: 'transparent',
    padding: '14px 18px',
    transition: '0s',
    fontSize: 14,
    height: 48,
    border: `1px solid ${colors.neutral200}`,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.neutral900,
      border: `1px solid ${colors.neutral900}`,
    },
  },
  btnGroupIconActive: {
    color: colors.white,
    border: `1px solid ${colors.neutral900}`,
    backgroundColor: colors.neutral900,
  },
  menu: {
    float: 'left',
    right: 10,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  totalMemberBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addProfileBtn: {
    height: 64,
    color: '#FFF',
    whiteSpace: 'nowrap',
    padding: '20px 24px',
    borderRadius: 8,
  },
});
