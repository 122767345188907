import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  exContainer: {
    paddingLeft: 136,
    paddingRight: 136,
  },
  container: {
    padding: '32px',
  },
  container2: {
    margin: 'auto',
    // height: 'calc(95vh - 450px)',
    overflowY: 'auto',
    textAlign: 'left',
    paddingRight: 10,
    '&:focus': {
      outline: 'none',
    },
    '&:focus-visible': {
      outline: 'none',
    },
    '& h1': {
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      fontSize: 40,
      lineHeight: '48px',
      color: colors.neutral900,
      paddingBottom: 24,
    },
    '& h2': {
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      fontSize: 36,
      lineHeight: '44px',
      color: colors.neutral900,
      paddingBottom: 24,
    },
    '& h3': {
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '40px',
      color: colors.neutral900,
      paddingBottom: 24,
    },
    '& p': {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '28px',
      color: colors.neutral700,
    },
    '& ul': {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '28px',
      color: colors.neutral700,
    },
    '& a': {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '28px',
      color: '#0001EE',
    },
    '& img': {
      borderRadius: 16,
    },
  },
  headerContainer: {
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  togglerIcon: {
    width: 24,
    height: 24,
  },
  sideBarToggler: {
    height: 40,
    minWidth: 40,
    maxWidth: 40,
    borderRadius: 99,
    padding: '0px !important',
    border: `1px solid ${colors.neutral300}`,
    color: colors.neutral700,
  },
  evaluationContainer: {
    width: '320px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: '8px',
    border: '1px solid #E5EBF0',
    background: colors.neutral25,
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04)',
  },
  flexibleEvaluationContainer: {
    width: 'unset !important',
    maxWidth: '520px',
  },
  questionsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  evaluationHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    justifyContent: 'space-between',
  },
  headerLeftSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
  },
  evaluationsContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    height: 'calc(100vh - 220px)',
    overflowY: 'auto',
  },
  allEvalsBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  heading: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  titleContainer: {
    width: '176px',
  },
  menu: {
    height: 'auto',
    padding: 0,
    '& svg': {
      width: 18,
    },
    '& path': {
      fill: `${colors.primary500} !important`,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      boxShadow:
        '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04)',
      border: `1px solid ${colors.neutral100}`,
      borderRadius: '8px',
    },
    '& ul li': {
      '&:first-child': {
        color: colors.primary600,
        '& svg path': {
          fill: colors.primary600,
        },
      },
      color: colors.destructive600,
      padding: '16px',
      '& svg path': {
        fill: colors.destructive600,
      },
    },
  },
  icon: {
    marginRight: 8,
  },
  pauseIcon: {
    width: 18,
  },
  rightSectionTitleContainer: {
    // width: '176px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '16px',
    flex: 1,
  },
  iconPlus: {
    padding: '12px',
    display: 'flex',
    cursor: 'pointer',
  },
  initialEvaluation: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  inPersonMainContainer: {
    backgroundColor: colors.white,
    display: 'flex',
  },
  inPersonCenterBox: {
    width: '800px',
    marginTop: '48px',
  },
  questionType: {
    fontWeight: '800',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.neutral500,
  },
  questionTypeBox: {
    textAlign: 'center',
  },
  question: {
    fontSize: '40px',
    fontWeight: '300',
    lineHeight: '52px',
    color: colors.neutral900,
  },
  question2: {
    fontSize: '40px',
    fontWeight: '300',
    lineHeight: '52px',
    color: colors.neutral900,
  },
  nxtBtnAndTxtBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '80px',
  },
  spaceBarBtn: {
    width: '95px',
    color: colors.neutral900,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    borderRadius: '6px',
    border: '1px solid #BEC9D3',
    boxShadow: '0px 2px 0px 0px #BEC9D3',
    background: colors.white,
    height: '40px',
    alignSelf: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    '&:hover': {
      backgroundColor: colors.neutral50,
    },
  },
  BtnLabel: {
    color: colors.neutral500,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
  },
  shortcutsLink: {
    color: colors.textPrimary,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    margin: '0px !important',
    padding: '0px !important',
  },
  shortcutContainer: {
    display: 'flex',
  },
  shortcutContainer2: {
    display: 'flex',
    paddingTop: 0,
    paddingLeft: 80,
    paddingRight: 80,
    marginBottom: 40,
  },
  nxtBtnAndTxtBox2: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
  shortcutBox: {
    textAlign: 'center',
  },
  inPersonRootBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'space-between',
    flex: 1,
    paddingLeft: '20px',
    paddingRight: '20px',
    width: '74%',
  },
  shortcutBtnBox: {
    justifyContent: 'flex-end',
    marginBottom: '38px',
  },
  parentQuestionBox: {
    marginTop: '80px',
    '&:focus': {
      outline: 'none',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  isExercise: {
    height: 'calc(100vh - 280px)',
    overflowY: 'auto',
  },
  rootBox: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  evaluationRootBox: {
    paddingLeft: '32px',
    paddingTop: '32px',
    paddingBottom: '32px',
    display: 'flex',
  },
  rightSectionRootBox: {
    paddingRight: '32px',
    paddingTop: '32px',
    paddingBottom: '32px',
    display: 'flex',
  },
  questionBoxRoot: {
    width: '320px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: '8px',
    border: '1px solid #E5EBF0',
    background: colors.white,
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04)',
  },
  questionBoxRootInactive: {
    width: '72px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: '8px',
    border: '1px solid #E5EBF0',
    background: colors.white,
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04)',
  },
  questionBox: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  questionBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    gap: '16px',
  },
  rightSectionHeaderSubRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
  },
  rightSectionHeaderRoot: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  collapsable: {
    '& .MuiCollapse-wrapper': {
      width: 'auto',
      // paddingLeft: '50px',
      alignItems: 'center',
      // height: '40px',
    },
    '& .collapsableHeader': {
      justifyContent: 'unset',
      height: '40px',
    },
    '& .MuiCollapse-root': {
      minHeight: 'unset !important',
      height: 'auto !important',
    },
  },
  circleIcon: {
    width: '24px',
    height: '24px',
  },
  marginRightCheckbox: {
    marginRight: '16px',
    marginLeft: '16px',
  },
  wh24: {
    maxWidth: '24px',
    maxHeight: '24px',
    '&.MuiCheckbox-root': {
      padding: '0px !important',
    },
  },
  selectedQuestion: {
    backgroundColor: colors.neutral50,
  },
  nonSelectedQuestion: {
    backgroundColor: colors.white,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  questionAndIconWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  marginLeftQuestion: {
    marginLeft: '50px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  hiddenContainer: {
    display: 'none',
  },
  togglerIconRight: {
    width: '24px',
    height: '24px',
    transformOrigin: 'center',
    transform: 'rotate(180deg)',
  },
  toggleIconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    textAlign: 'center',
  },
  dctItem: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    color: colors.neutral900,
    marginLeft: '12px',
  },
  margin35: { marginLeft: '35px' },
  collapseHeaderBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px',
    cursor: 'pointer',
  },
  noPointer: {
    justifyContent: 'space-between',
    cursor: 'default',
  },
  invertIcon: {
    transformOrigin: 'center',
    transform: 'rotate(180deg)',
  },
  questionBoxFooter: {
    borderTop: `1px solid ${colors.neutral200}`,
    background: colors.neutral25,
    height: '48px',
    alignItems: 'center',
    display: 'flex',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  answerBoxFooter: {
    borderTop: `1px solid ${colors.neutral200}`,
    background: colors.neutral25,
    height: '48px',
    alignItems: 'center',
    display: 'flex',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  footerText: {
    marginLeft: '16px',
    color: colors.neutral500,
    marginRight: '131px',
  },
  footerBtn: {
    height: '24px',
    width: '32px',
    minWidth: 'unset',
    borderRadius: '6px',
    border: `1px solid ${colors.neutral300}`,
    background: colors.white,
    boxShadow: `0px 1px 0px 0px ${colors.neutral300}`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '0px !important',
  },
  footerText2: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    color: colors.neutral700,
    marginLeft: '12px',
    marginRight: '12px',
  },
  collapaseItemBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '8px',
  },
  onlyCheckbox: {
    '& .MuiCheckbox-root': {
      marginBottom: '16px',
    },
  },
  scroll: {
    overflowY: 'auto',
    height: 'calc(100vh - 220px)',
  },
  inActiveBoxScroll: {
    overflowY: 'auto',
    height: 'calc(100vh - 268px)',
  },
  questionBoxFooterContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'end',
  },
  bottomOpacity: {
    height: '76px',
    width: '318px',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    position: 'absolute',
    bottom: 33,
    borderRadius: '8px',
  },
  bottomOpacity2: {
    width: '98%',
    // paddingRight: 10,
    height: '76px',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 80%)',
    position: 'relative',
    marginTop: '-3%',
    borderRadius: '8px',
  },
  bottomOpacityCollapsed: {
    height: '76px',
    width: '70px',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    position: 'absolute',
    bottom: 33,
    borderRadius: '8px',
  },
  rightSectionHeaderTextRight: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: colors.neutral600,
    paddingRight: '16px',
  },
  rightSectionBodyRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  answerContainer: {
    height: 'calc(85vh - 290px)',
    overflow: 'auto',
  },
  answerOptionBox: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '24px',
    minHeight: '56px',
    cursor: 'pointer',
    alignItems: 'center',
    '& .MuiCheckbox-root': {
      padding: '0px',
      // paddingTop: '5px',
    },
    '& .MuiBox-root': {
      marginTop: '0px !important',
    },
    '&:hover': {
      backgroundColor: colors.neutral50,
    },
  },
  adjustedAnswerOptionBox: {
    minHeight: '40px !important',
    paddingLeft: '0px !important',
    alignItems: 'center',
  },
  optionContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '12px',
  },
  answerTitle: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    color: colors.neutral900,
  },
  adjustedAnswerTitle: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
  },
  answerSubTitle: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: colors.neutral600,
  },
  rightSectionCheckIcon: {
    marginRight: '0px',
  },
  adjustedRightSectionCheckIcon: {
    marginRight: '0px',
    height: '24px',
    width: '24px',
  },
  countOptions: {
    alignSelf: 'center',
    display: 'flex',
    flex: 1,
    transform: 'rotate(180deg)',
    writingMode: 'vertical-lr',
    justifyContent: 'center',
  },
  inActiveRightSectionFooterIcon: {
    paddingLeft: '24px',
    paddingTop: '20px',
    paddingBottom: '16px',
  },
  activeBoxFlagIcon: {
    paddingLeft: '20px',
    paddingBottom: '16px',
  },
  collapsedFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  completedRoot: {
    overflowY: 'auto',
    height: 'calc(100vh - 80px)',
  },
  completedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '800px',
    margin: 'auto',
  },
  headerText: {
    fontSize: '40px',
    fontFamily: 'Manrope',
    fontWeight: '300',
    lineHeight: '52px',
    textAlign: 'center',
    marginTop: '56px',
  },
  remainingEvalsContainer: {
    marginBottom: '56px',
    marginTop: '56px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },
  remainingEvalItem: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    borderRadius: '8px',
    border: `1px solid ${colors.neutral100}`,
    boxShadow: `0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04)`,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  remainingEvalDescBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    flex: 1,
  },
  remainingEvalTitle: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    color: colors.neutral900,
    fontFamily: 'Manrope',
  },
  remainingEvalText: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: colors.neutral600,
    fontFamily: 'Manrope',
  },
  startEvalBtn: {
    border: `1px solid ${colors.primary300}`,
    padding: '8px 16px !important',
    height: '40px',
    color: colors.primary600,
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
  },
  alfieBox: {
    marginTop: '80px',
  },
  alfie: {
    width: '200px',
    height: '200px !important',
  },
  addEvalBtn: {
    marginBottom: '107px',
  },
  textAreaBox: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  helperText: {
    fontFamily: 'Manrope',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '20px',
  },
  ratingScaleBox: {
    paddingLeft: '24px',
  },
  optionWithNoDescription: {
    marginTop: '4px',
  },
  focusedChoice: {
    backgroundColor: colors.neutral50,
  },
  verticalText: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    color: colors.neutral700,
  },
  secondHeading: {
    marginTop: 16,
    fontSize: 40,
  },
  titleText: {
    marginTop: 56,
    fontSize: 40,
  },
  description: {
    marginTop: 24,
    fontSize: 20,
  },
  divider: {
    width: '98%',
    height: 1,
    color: '#D4DDE5',
    margin: 'auto',
    marginTop: 56,
    marginBottom: 56,
  },
  thisContent: {
    marginTop: 24,
  },
  heading1: {
    fontFamily: 'Source Serif Pro',
    fontWeight: 600,
    fontSize: 40,
    lineHeight: '48px',
    color: colors.neutral900,
    paddingBottom: 24,
  },
  heading2: {
    fontFamily: 'Source Serif Pro',
    fontWeight: 600,
    fontSize: 36,
    lineHeight: '44px',
    color: colors.neutral900,
    paddingBottom: 24,
  },
  heading3: {
    fontFamily: 'Source Serif Pro',
    fontWeight: 600,
    fontSize: 32,
    lineHeight: '40px',
    color: colors.neutral900,
    paddingBottom: 24,
  },
  paragraph: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '28px',
    color: colors.neutral700,
  },
  ulList: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    color: colors.neutral700,
  },
  hyperlinK1: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '28px',
    color: '#0001EE',
  },
  hyperlink2: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
    color: '#0001EE',
  },
  btnContainer: {
    display: 'flex',
    width: '50%',
  },
});
