import { FC } from 'react';
import { Box } from '@mui/material';

import clsx from 'clsx';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';

import { IRecurringSlot, ISlot } from '../AddSchedule.types';
import { useStyles } from './StepFive.styles';
import { IPatient, IProvider } from '../../../../../redux/modules/profile/types';
import SlotComponent from '../SlotsView';

type Props = {
  formData: {
    member: IPatient;
    provider: IProvider;
    time?: any;
    serviceId?: string;
    selectedService?: any;
  };
  selectedDateTime?: string;
  error: string;
  onChange: (item: ISlot) => void;
  onChangeService(id: string): void;
  serviceId?: string;
  isNewProviderSelected?: boolean;
  selectedAppointmentType?: string;
  selectedServiceId?: string;
  provider?: any;
  onClickReschedule?: () => void;
  recurringSlots?: IRecurringSlot[];
};

const StepFiveNew: FC<Props> = ({ onClickReschedule, recurringSlots }) => {
  const classes = useStyles();
  const slotsExceptFirst = recurringSlots.slice(1, recurringSlots.length);
  const renderLabel = (label: string) => (
    <Text className={clsx(classes.text18, classes.label)} weight={fontWeight.BOLD}>
      {label}
    </Text>
  );
  const renderSubLabel = (label: string) => (
    <Text
      className={clsx(classes.text12, classes.label)}
      weight={fontWeight.EXTRA_BOLD}
      color={colors.neutral500}
    >
      {label}
    </Text>
  );

  return (
    <Box className={classes.reviewWrap}>
      <Box className={classes.reviewSection}>
        {renderLabel('Check availability')}
        {renderSubLabel('SELECTED DAY AND TIME')}
        <SlotComponent slot={recurringSlots?.[0]} onClickReschedule={onClickReschedule} />
        {recurringSlots?.length > 1 && (
          <>
            {renderSubLabel(
              `NEXT ${recurringSlots?.length - 1} UPCOMING DATE${recurringSlots?.length - 1 > 1 ? 'S' : ''}`,
            )}
            {slotsExceptFirst?.map((item, index) => (
              <SlotComponent key={`${index}-slot`} slot={item} onClickReschedule={onClickReschedule} />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default StepFiveNew;
