import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Toggle, positionType } from '@confidant-health/lib/ui/atoms/toggle';
import { useQuery } from '@confidant-health/lib/hooks';

import { conversationActionCreators } from 'redux/modules/conversation/actions';
import { selectProviderRoles } from 'redux/modules/appointment/selectors';
import { AutomationRuleState } from 'redux/modules/conversation/types';
import { BUILDER_CONSTANTS } from 'constants/CommonConstants';
import { sentenceCase } from 'sentence-case';
import { toSentenceCase } from 'utils/CommonUtils';

import DuplicateAutomation from '../duplicate-automation';
import AutomationDetail from '../automation-detail';
// mock
import {
  tableColumns,
  filterOptionsMock,
  defaultTableParams,
  automationMultiSelectFilterOptionsMock,
} from './Chatbots.constants';
import { automationTypes } from '../Automations.constants';
// styles
import { useStyles } from './Chatbots.styles';

type Props = {
  automations: AutomationRuleState;
  onDelete: (id: string) => void;
  onEditItem: (id: string) => any;
  getAutomations: (params: any) => any;
  educations?: any[];
  loading?: boolean;
};

type SearchParamType = tableParamsType & { category?: string; multiSelectFilters?: any };

const Chatbots: FC<Props> = ({
  automations = {
    automationRules: [],
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
  },
  onDelete,
  onEditItem,
  loading,
  getAutomations,
  educations,
}) => {
  const query = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();

  const providerRoles = useSelector(selectProviderRoles);

  const [selectedItem, setSelectedItem] = useState(null);
  const [showViewType, setShowViewType] = useState(null);
  const [value, setValue] = useState<tableParamsType>(defaultTableParams);
  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(true);

  const navigate = useNavigate();

  const modifyParamArray = (inputArray: string[]) => {
    if (!inputArray) {
      return [];
    }
    return inputArray.map(item => {
      return item.toUpperCase().replace(/ /g, '_');
    });
  };

  const onSearchParamsFormat = ({
    search,
    pagination,
    sorter,
    category,
    multiSelectFilters,
  }: SearchParamType) => {
    const params = {
      ruleName: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction,
      category,
      eventType: modifyParamArray(multiSelectFilters?.EventType),
      actionType: getPlanType(multiSelectFilters?.AssignmentType),
      planType: modifyParamArray(multiSelectFilters?.PlanItemType),
    };

    return params;
  };

  const getPlanType = multiSelectFilter => {
    const planType = [];
    if (multiSelectFilter?.includes('Evaluation')) {
      planType.push('ASSIGN_EVALUATION');
    }
    if (multiSelectFilter?.includes('Plan Item')) {
      planType.push('ASSIGN_PLAN_ITEM');
    }
    return planType;
  };

  useEffect(() => {
    setResetMultiSelectFilter(false);
  }, [value]);

  useEffect(() => {
    if (value.search?.multiSelectFilter && Object.keys(value.search?.multiSelectFilter)?.length > 0) {
      setResetMultiSelectFilter(true);
    }
    const getQuery = query.get('name');
    const multiSelectFilters = {};
    if (!getQuery) {
      getAutomations(
        onSearchParamsFormat({
          ...defaultTableParams,
          category: '',
          multiSelectFilters,
        }),
      );
    } else {
      getAutomations(
        onSearchParamsFormat({
          ...defaultTableParams,
          category: automationTypes[getQuery]?.category,
          multiSelectFilters,
        }),
      );
    }
    setValue(defaultTableParams);
  }, [query]);

  useEffect(() => {
    const multiSelectFilters = value?.search?.multiSelectFilter;
    const getQuery = query.get('name');
    if (!getQuery) {
      getAutomations(
        onSearchParamsFormat({
          ...value,
          category: '',
          multiSelectFilters: value?.search?.multiSelectFilter,
        }),
      );
    } else {
      getAutomations(
        onSearchParamsFormat({
          ...value,
          category: automationTypes[getQuery]?.category,
          multiSelectFilters,
        }),
      );
    }
  }, [value]);
  const getLogicDescriptionType = (type: string, key: string) => {
    switch (type) {
      case 'R':
        return `Response of ${key}`;
      case 'P':
        return `Profile Element ${key}`;
      case 'STATE':
        return `States ${key}`;
      case 'LEVEL_OF_ENGAGEMENT':
        return `Levels of Engagement ${key}`;
      case 'PROVIDER':
        return `Providers ${key}`;
      case 'INSURANCE':
        return `Insurances for ${key}`;
      default:
        return 'Profile Element';
    }
  };
  const describeLogic = logic => {
    let description = `${getLogicDescriptionType(logic.type, logic.key)}`;
    if (logic.rule === 'before-today') {
      description = `${description} assigned before today's date`;
    } else if (logic.rule === 'after-today') {
      description = `${description} assigned after today's date`;
    } else {
      description = `${description} ${logic.rule} ${logic.metaText}`;
      if (logic.type === 'INSURANCE') {
        description = `${description} ${logic.rule} of ${logic.metaText} payers`;
      }
    }
    return description;
  };

  const renderFilterDescription = rule => {
    if (rule.filter.structuredCondition) {
      if (
        rule.filter.structuredCondition.automationLogics &&
        rule.filter.structuredCondition.automationLogics.length > 0
      ) {
        let description = '';
        rule.filter.structuredCondition.automationLogics.forEach((logic, index) => {
          if (index > 0) {
            description = `${description} ${rule.filter.structuredCondition.ruleAggregator} `;
          }
          description = `${description}${describeLogic(logic)}`;
        });
        return description;
      }
      return null;
    }
    if (rule.filter.condition) {
      return `Raw Expression: ${rule.filter.condition} evaluates to true`;
    }
    return null;
  };

  const onCloseViewDetail = () => {
    setSelectedItem(null);
    setShowViewType(null);
  };

  const onChangeToggle = id => () => {
    const selectedAutomation = automations?.automationRules?.find(item => item.id === id);
    if (!selectedAutomation) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _id, protocolId: _protocolId, active, ...request } = selectedAutomation;
    const automationRuleRequest = {
      ...request,
      active: !active,
      category: automationTypes[query.get('name')]?.category,
    };
    dispatch(conversationActionCreators.updateAutomationRule({ ...automationRuleRequest, id }));
  };

  // const onDuplicateItem = id => () => {
  //   setSelectedItem(automations?.automationRules.find(item => item.id === id));
  //   setShowViewType('duplicate');
  // };

  const onDeleteItem = (id: string) => () => {
    onDelete(id);
  };

  const onClickAutomation = (automation: any) => {
    navigate(`/admin/automation-detail/${automation.id}/${automation.active}`);
  };

  const getLabelFromEvent = event => {
    const { eventMetaData, name, reference } = event || {};

    const { serviceName, providerName, chatBotName, dctName, groupName, profileElementName } =
      eventMetaData || {};
    const placeholder = name?.replaceAll('_', ' ') || '';
    const paymentReference = providerRoles?.find(r => r.userId === reference);
    const educationReference = educations?.find(e => e?.sys.id === reference);

    switch (name) {
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_REQUESTED:
        return serviceName ? `${serviceName} requested with ${providerName}` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_SCHEDULED:
        return serviceName ? `${serviceName} scheduled with ${providerName}` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_COMPLETED:
        return serviceName ? `${serviceName} completed with ${providerName}` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_CANCELLED:
        return serviceName ? `${serviceName} cancelled with ${providerName}` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_NO_SHOW:
        return serviceName ? `${serviceName} no show with ${providerName}` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_COMPLETED:
        return chatBotName ? `${chatBotName} completed` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_ASSIGNED:
        return chatBotName ? `${chatBotName} assigned` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.DCT_COMPLETED:
        return dctName ? `${dctName} completed` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PROFILE_ELEMENT_UPDATED:
        return profileElementName ? `${profileElementName} updated` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.GROUP_JOINED:
        return groupName ? `${groupName} joined` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.GROUP_LEFT:
        return groupName ? `${groupName} left` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.GROUP_SESSION_JOINED:
        return groupName ? `${groupName} session joined` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.GROUP_CONTRIBUTION_MADE:
        return groupName ? `${groupName} contribution made` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PAYMENT_TYPE_SELECTED:
        return paymentReference ? `Payment type selected for ${paymentReference?.name}` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PAYMENT_MADE:
        return paymentReference ? `Payment made for ${paymentReference?.name}` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONTRIBUTION_SET:
        return paymentReference ? `${paymentReference?.name} set contribution` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONTRIBUTION_CANCELLED:
        return paymentReference ? `${paymentReference?.name} cancelled contribution` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EDUCATION_MARKED_READ:
        return educationReference ? `${educationReference?.fields.title} marked as read` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EDUCATION_VIEWED:
        return educationReference ? `${educationReference?.fields.title} viewed` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EDUCATION_SHARED:
        return educationReference ? `${educationReference?.fields.title} shared` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EDUCATION_ADDED_TO_FAVOURITES:
        return educationReference ? `${educationReference?.fields.title} added to favourites` : placeholder;
      case BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.TRANSCRIPT_READY:
        return BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Names.TRANSCRIPT_READY;

      default:
        return '';
    }
  };

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'ruleName') {
      return {
        ...column,
        renderCell: ruleName => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {ruleName}
          </Text>
        ),
      };
    }
    if (column.id === 'event') {
      return {
        ...column,
        renderCell: event => {
          const label = getLabelFromEvent(event);
          return (
            <Text className={classes.name} weight={fontWeight.MEDIUM}>
              {toSentenceCase(label)}
            </Text>
          );
        },
      };
    }
    if (column.id === 'isActive') {
      return {
        ...column,
        renderCell: (val: boolean) => (
          <Icons
            glyph={val ? 'checkmark' : 'close-circle-outlined'}
            color={val ? colors.success500 : colors.destructive500}
          />
        ),
      };
    }
    if (column.id === 'delay') {
      return {
        ...column,
        renderCell: (val: string | null) => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {val || 'None'}
          </Text>
        ),
      };
    }
    if (column.id === 'repeat') {
      return {
        ...column,
        renderCell: (val: string | null) => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {val || 'Never'}
          </Text>
        ),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id, isActive }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              {
                customeElement: (
                  <Toggle checked={isActive} onChange={onChangeToggle(id)} labelPosition={positionType.LEFT}>
                    Automation is active
                  </Toggle>
                ),
              },
              {
                icon: <Icons glyph="edit-underline" className={classes.icon} />,
                label: 'Edit automation',
                onClick: onEditItem(id),
              },
              // {
              //   icon: <Icons glyph="copy-outlined" className={classes.icon} />,
              //   label: 'Duplicate automation',
              //   onClick: onDuplicateItem(id),
              // },
              {
                icon: <Icons glyph="delete-outlined-2" className={classes.icon} />,
                label: 'Delete automation',
                onClick: onDeleteItem(id),
              },
            ]}
          />
        ),
      };
    }
    return column;
  });

  const formatList = list =>
    list.map(({ action, event, ...item }) => ({
      ...item,
      delay: `${action?.delay?.value ?? ''} ${action?.delay?.interval ?? ''}`.trim(),
      repeat: `${action?.repeat?.value ?? ''} ${action?.repeat?.interval ?? ''}`.trim(),
      event,
      filters: renderFilterDescription({
        ...item,
        action,
        event,
      }),
      planItemName: action?.referenceName || '',
      planItemType: sentenceCase(BUILDER_CONSTANTS.AUTOMATION_RULES.ACTIONS[action.type] || ''),
      actions: {
        id: item.id,
        isActive: item?.active || false,
      },
    }));

  const dataSource = useMemo(() => {
    return formatList(automations?.automationRules || []);
  }, [automations]);

  return (
    <>
      <DuplicateAutomation
        isOpen={showViewType === 'duplicate'}
        onClose={onCloseViewDetail}
        item={selectedItem || {}}
      />
      <AutomationDetail
        isOpen={showViewType === 'detail'}
        onClose={onCloseViewDetail}
        item={selectedItem || {}}
      />
      <Table
        searchProps={{
          resetMultiSelectFilter,
          placeholder: 'Search chatbot by name',
          filterProps: {
            variant: Filter.tableFilterType.MULTIPLE,
            options: filterOptionsMock,
            multiSelectOptions: automationMultiSelectFilterOptionsMock,
            allOptionLabel: 'All',
          },
        }}
        gridProps={{
          columns: renderColumns,
          data: dataSource,
          isLoading: loading,
          onRowClick: automation => onClickAutomation(automation),
        }}
        paginationProps={{
          currentRows: dataSource.length,
          totalCount: automations?.totalRecords,
        }}
        value={value}
        onChange={setValue}
        className={classes.table}
      />
    </>
  );
};

export { Chatbots };
