import { Box } from '@mui/material';

import { colors } from '@confidant-health/lib/colors';
import Heading from 'components/v2/Heading';
import { HeadingLevel } from 'components/v2/Heading/index.types';

import InsuranceCard from './InsuranceCard';
import Typography from '../../../../components/v2/Typography';
import { typography } from '../../../../components/v2/Typography/index.constant';
import SubmissionStatusDrawer from './SubmissionStatusDrawer';
import BoxShadow from '../../../../components/v2/BoxShadow';

type Props = {
  state: any;
  getAvailableStates: () => void;
};

const AvailableStateSection = ({ state, getAvailableStates }: Props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Heading variant={HeadingLevel.H5}>{state.state}</Heading>
      {state?.insurances?.length > 0 ? (
        state?.insurances.map((insurance, index) => (
          <InsuranceCard
            key={index}
            insurance={insurance}
            state={state.state}
            getAvailableStates={getAvailableStates}
          />
        ))
      ) : (
        <BoxShadow>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography {...typography.body.l.semibold} color={colors.neutral500}>
              You are not accepting insurances in this state
            </Typography>
          </Box>
        </BoxShadow>
      )}
    </Box>
  );
};

export default AvailableStateSection;
