import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';

import { ISessionType, ICreateSessionTypeDTO, IUpdateSessionTypeDTO } from 'redux/modules/conversation/types';
import { SessionSchema } from './AddSession.schema';
import { useStyles } from './AddSession.styles';

type IServicesDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  isEdit?: boolean;
  session?: ISessionType;
  onDeleteSession?: (id: string) => void;
  onUpdateSession?: (session: IUpdateSessionTypeDTO) => void;
  onAddSession?: (session: ICreateSessionTypeDTO) => void;
};

const defaultValues: FormikValues = {
  name: '',
  description: '',
};

const AddSession: React.FC<IServicesDrawerProps> = ({
  isOpen,
  onClose,
  isEdit,
  session,
  onDeleteSession,
  onUpdateSession,
  onAddSession,
}) => {
  const classes = useStyles();
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema: SessionSchema,
    onSubmit: (payload: FormikValues) => {
      if (isEdit) {
        onUpdateSession && onUpdateSession({ ...payload, id: session.id });
      } else {
        onAddSession && onAddSession(payload);
      }
    },
  });

  useEffect(() => {
    if (session && isOpen) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(session).forEach(async key => {
        await setFieldValue(key, session[key]);
      });
    }
  }, [session, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{`${isEdit ? 'Edit' : 'Add'} session type`}</Text>
          <IconButton icon="close" onClick={onClose} className={classes.closeBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter session type name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Description')}
              <Textarea
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={setTouched('description')}
                minRows={5}
                placeholder="Enter description"
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Box>
              {isEdit && (
                <IconButton
                  icon="delete-outlined-2"
                  onClick={() => onDeleteSession(session.id)}
                  className={classes.removeBtn}
                >
                  Delete session
                </IconButton>
              )}
            </Box>
            <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add session'}</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddSession };
