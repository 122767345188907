import { TableGrid } from '@confidant-health/lib/ui/organisms/table';

export const memberColumns: TableGrid.TableColumn[] = [
  { id: 'member', label: 'Member', sortable: false },
  { id: 'appointments', label: 'Appointments', sortable: false },
  { id: 'paymentMethod', label: 'Payment Method', sortable: false },
  { id: 'payer', label: 'Payer', sortable: false },
  { id: 'plan', label: 'Plan', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [
  { label: 'Active in chat', value: 'active_chat' },
  { label: 'Inactive in chat', value: 'inactive_chat' },
  { label: 'At Least 2 Sessions', value: '2_sessions' },
  { label: 'No Sessions', value: 'no_sessions' },
];
