import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Icons, IGlyphs } from '@confidant-health/lib/icons';

import { useStyles } from './EmptyState.styles';

type Props = {
  title: string;
  subtitle: ReactNode | string;
  btnLabel?: string;
  btnVariant?: btnType;
  btnClick?: () => void;
  glyph?: IGlyphs['glyph'];
  glyphOrientation?: string;
};

const EmptyState: React.FC<Props> = ({
  title,
  subtitle,
  btnClick,
  btnLabel,
  btnVariant = btnType.SECONDARY,
  glyph,
  glyphOrientation = 'square',
}) => {
  const classes = useStyles();
  const isLandscape = glyphOrientation === 'landscape';
  const isPortrait = glyphOrientation === 'portrait';
  const isSquare = glyphOrientation === 'square';
  return (
    <Box className={classes.card}>
      {glyph && (
        <Icons
          glyph={glyph}
          className={clsx({
            [classes.alifie_landscape]: isLandscape,
            [classes.alfie_portrait]: isPortrait,
            [classes.alfie]: isSquare,
          })}
        />
      )}
      <Heading className={classes.title} weight={fontWeight.BOLD}>
        {title}
      </Heading>
      <Text className={classes.subtitle} weight={fontWeight.MEDIUM}>
        {subtitle}
      </Text>
      {btnLabel && (
        <Button onClick={btnClick} variant={btnVariant} className={classes.btn}>
          {btnLabel}
        </Button>
      )}
    </Box>
  );
};

export { EmptyState };
