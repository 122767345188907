// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '800px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: 108,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerNoDrawer: {
    height: 80,
    padding: 16,
    '& $headTitle': {
      fontSize: 18,
      lineHeight: '24px',
    },
    '& $headSubtitle': {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  headTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  headSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'block',
    margin: 0,
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    '& svg': {
      width: 30,
    },
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
  contentNoDrawer: {
    height: 'calc(100vh - 256px)',
    flex: 'unset',
  },
  footer: {
    height: 96,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.neutral100}`,
    boxShadow:
      // eslint-disable-next-line max-len
      '0px -24px 32px rgba(0, 0, 0, 0.04), 0px -16px 24px rgba(0, 0, 0, 0.04), 0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  newReportBtn: {
    color: colors.white,
    '& svg path': {
      fill: colors.white,
    },
  },
  contentFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  contentTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 700,
  },
  question: {
    marginLeft: 8,
    position: 'relative',
    top: 4,
    '& path': {
      fill: colors.neutral600,
    },
  },
  menuLabel: {
    color: colors.neutral700,
    textDecorationLine: 'line-through',
    '& ul li': {
      color: colors.neutral700,
    },
  },
  contentChart: {
    padding: 24,
  },
  contentDescription: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    padding: '0px 24px 24px',
    display: 'block',
  },
});
