import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading } from '@confidant-health/lib/ui/atoms/typography';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';

import { useStyles } from './Chats.styles';
import { IChatListItem } from './Chats.types';
import { SUPPORTED_AUTHORITIES } from '../../../../../../constants/CommonConstants';

type Props = {
  chatListItem: IChatListItem;
};

const ChatListItem: FC<Props> = ({ chatListItem }) => {
  const classes = useStyles();

  return (
    <Box className={classes.chatListItem}>
      <Box className={classes.chatListItemFlex} gap="12px">
        <Avatar
          src={chatListItem?.avatar}
          name={chatListItem?.name}
          size={40}
          variant={avatarType.CIRCLE}
          color={chatListItem?.colorCode}
        />
        <Box>
          <Heading weight={fontWeight.SEMI_BOLD} className={classes.chatUsername}>
            {chatListItem?.name}
          </Heading>
          <Badge variant={badgeType.FILLED} className={clsx(classes.chatBadge, chatListItem?.role)}>
            {chatListItem?.role === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR
              ? 'Care Navigator'
              : chatListItem?.role}
          </Badge>
        </Box>
      </Box>
    </Box>
  );
};

export { ChatListItem };
