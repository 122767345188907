import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  addBtn: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    marginTop: 16,
    alignSelf: 'self-start',
    '& svg': {
      width: 18,
      marginRight: 6,
    },
  },
  removeIconBtn: {
    width: 40,
    height: 40,
    minWidth: 'unset',
    border: `1px solid ${colors.destructive300}`,
    borderRadius: 4,

    '& svg': {
      width: 20,
      height: 20,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
  formContent: {
    flex: 1,
    display: 'flex',
    height: 'calc(100vh - 240px)',
    flexDirection: 'column',
    gap: 24,
    padding: 10,
    overflowY: 'auto',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 80,
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  subHeading: {
    fontFamily: 'Manrope',
    marginTop: 8,
    marginBottom: 8,
    color: colors.neutral600,
  },
  badge: {
    color: colors.textMedium,
    height: 24,
  },
  submittedStatus: {
    color: colors.primary600,
  },
  paid: {
    color: colors.success600,
  },
  unPaid: {
    color: colors.destructive600,
  },

  btn: {
    fontSize: 14,
    lineHeight: '20px',
  },
  colLabel: {
    color: colors.neutral500,
  },
  marginTop8: {
    marginTop: 8,
  },
  headingFont: {
    fontFamily: 'Source Serif Pro',
  },
  cardBadge: {
    padding: '2px 10px',
    height: 24,
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginBottom: 10,
    '& > label': {
      lineHeight: '20px',
      height: 20,
    },
    '& .label': {
      lineHeight: '20px',
      height: 20,
    },
  },
  headerLabel: {
    fontWeight: 800,
    fontSize: 12,
    lineHeight: '20px',
    color: colors.neutral600,
  },
});
