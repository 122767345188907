/* eslint-disable max-len */
import dayjs from 'dayjs';
import { DomainPriorities, IDomainElement } from 'redux/modules/profile/types';

export const lifeEventList: IDomainElement[] = [
  {
    id: '1',
    name: 'Vacation',
    description: '',

    assignedBy: 'Eva Bond',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: null,
    priority: {
      name: DomainPriorities.HIGH,
      color: '',
    },
    history: [],
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
  },
];

export const lifeEventItems = [
  {
    label: 'Activity Change',
    groupId: 'Activity Change',
    items: [
      { label: 'New social activities', value: 'New social activities' },
      { label: 'Social isolation', value: 'Social isolation' },
      { label: 'Vacation', value: 'Vacation' },
    ],
  },
  {
    label: 'Children',
    groupId: 'Children',
    items: [
      { label: 'Children - 1', value: 'Children - 1' },
      { label: 'Children - 2', value: 'Children - 2' },
      { label: 'Children - 3', value: 'Children - 3' },
      { label: 'Children - 4', value: 'Children - 4' },
    ],
  },
  {
    label: 'Education',
    groupId: 'Education',
    items: [
      { label: 'Education - 1', value: 'Education - 1' },
      { label: 'Education - 2', value: 'Education - 2' },
      { label: 'Education - 3', value: 'Education - 3' },
      { label: 'Education - 4', value: 'Education - 4' },
    ],
  },
  {
    label: 'Family',
    groupId: 'Family',
    items: [
      { label: 'Family - 1', value: 'Family - 1' },
      { label: 'Family - 2', value: 'Family - 2' },
      { label: 'Family - 3', value: 'Family - 3' },
      { label: 'Family - 4', value: 'Family - 4' },
    ],
  },
  {
    label: 'Financial',
    groupId: 'Financial',
    items: [
      { label: 'Financial - 1', value: 'Financial - 1' },
      { label: 'Financial - 2', value: 'Financial - 2' },
      { label: 'Financial - 3', value: 'Financial - 3' },
      { label: 'Financial - 4', value: 'Financial - 4' },
    ],
  },
  {
    label: 'Housing',
    groupId: 'Housing',
    items: [
      { label: 'Housing - 1', value: 'Housing - 1' },
      { label: 'Housing - 2', value: 'Housing - 2' },
      { label: 'Housing - 3', value: 'Housing - 3' },
      { label: 'Housing - 4', value: 'Housing - 4' },
    ],
  },
  {
    label: 'Transportation',
    groupId: 'Transportation',
    items: [
      { label: 'Transportation - 1', value: 'Transportation - 1' },
      { label: 'Transportation - 2', value: 'Transportation - 2' },
      { label: 'Transportation - 3', value: 'Transportation - 3' },
      { label: 'Transportation - 4', value: 'Transportation - 4' },
    ],
  },
];

export const stepTitles = {
  0: 'Step 1 - select life events',
  1: 'Step 2 - life event details',
  2: 'Step 3 - review life events',
};

export const lifeEventDefaultValues = {
  status: '',
  notes: '',
  isInterference: false,
};
