import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Input, inputType, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { useHistoryPatient } from 'hooks/useHistoryPatient';

import { DrawerProps } from '../drawer.types';
import { useStyles } from './HistoryDrawer.styles';
import EmptyState from '../empty-state';

const HistoryDrawer: FC<DrawerProps> = ({ open, isNoDrawer, onClose, appointment }) => {
  const classes = useStyles();
  const { isLoading, error, items } = useHistoryPatient({ patientId: appointment?.participantId });
  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);

  useEffect(() => {
    const filteredList = items.filter(
      item =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.value.toLowerCase().includes(search.toLowerCase()),
    );
    setList(filteredList);
  }, [search, items.length]);

  const renderBlock = (item: { name: string; value: string; child?: string[] }) => (
    <Box display="flex" gap="16px" className={classes.block} key={item.name}>
      <Text className={classes.blockTitle}>{item.name}</Text>
      <Text className={classes.blockSubtitle}>{item.value}</Text>
      <Box flex={0.3}>
        {item.child && (
          <>
            <ul className={classes.ul}>
              {item.child.map((el, id) => (
                <li key={id} className={classes.time}>
                  {el}
                </li>
              ))}
            </ul>
          </>
        )}
      </Box>
    </Box>
  );

  const renderContent = () => {
    if (isLoading || error) {
      return (
        <Box display="flex" gap="16px" className={`${classes.block} item-center`} minHeight={200}>
          {isLoading ? (
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          ) : (
            <Text className={classes.blockSubtitle}>{error}</Text>
          )}
        </Box>
      );
    }

    return (
      <Box className={classes.container}>
        <Box className={classes.searchWrap}>
          <Input
            variant={inputType.SEARCH}
            className={classes.searchInput}
            placeholder="Search"
            value={search}
            size={inputSize.S}
            onChange={setSearch}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="8px" className={classes.items}>
          {list.length > 0 ? (
            list.map(renderBlock)
          ) : (
            <EmptyState
              title={`We don't have ${appointment.participantName || 'this member'}'s Medical History.`}
              subtitle="This should appear after their first evaluation..."
              glyph="face-talking-amazed"
            />
          )}
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="History"
      onClose={onClose}
      variant={drawerType.FORM}
      className={classes.drawer}
      footer={<div />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { HistoryDrawer };
