// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  card: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',

    '&:hover $linkBtn': {
      display: 'flex',
    },
    '&:hover $action': {
      display: 'flex',
    },
  },
  cardTopSection: {
    height: 72,
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    borderBottom: '1px solid',
    color: colors.neutral100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContentSection: {
    padding: '16px 32px 32px 32px',
  },
  headingText: {
    fontWeight: 600,
    margin: '18px 0',
  },
  addBtnPrimary: {
    height: 64,
    color: colors.white,
    padding: '20px 24px',
    whiteSpace: 'nowrap',
    borderRadius: 8,
  },
  addBtnWhite: {
    height: 40,
    fontSize: 14,
    borderColor: colors.primary300,
    color: colors.primary600,
  },
  badge: {
    height: 28,
    padding: '0 12px',
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginRight: 8,
    display: 'inline-block',
    backgroundColor: colors.neutral600,
  },
  dotActive: {
    backgroundColor: colors.success500,
  },
  payersContentSection: {
    padding: 0,
  },
  payerSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderBottom: '1px solid',
    color: colors.neutral100,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  iconColorGreen: {
    '& path': { fill: colors.success600 },
  },
  iconColorRed: {
    '& path': { fill: colors.destructive600 },
  },
  linkBtn: {
    display: 'none',
    fontSize: 14,
    '& svg': {
      marginRight: 8,
      width: 16,
    },
  },
  textBtn: {
    padding: 0,
    fontSize: 14,
    minWidth: 'unset',
    color: colors.primary600,
    '& svg': {
      marginRight: 8,
      width: 16,
    },
  },
  iconButton: {
    color: colors.white,
    padding: 16,
    whiteSpace: 'nowrap',
  },
  action: {
    display: 'none',
    gap: 20,
  },
  loader: {
    height: 'calc(100vh - 158px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
