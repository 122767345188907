import { AxiosResponse } from 'axios';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { baseRequest } from 'utils/HttpClient';

export const getUserActivity = (
  pathParams: {
    userId: string;
  },
  queryParams: {
    pageNumber: number;
    pageSize: number;
  },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_USER_ACTIVITY, null, pathParams, queryParams);
};
