export const ProfileDurationMock = {
  id: 'general-availability',
  title: 'General availability',
  content: {
    workingDays: {
      sunday: null,
      monday: [
        {
          startTime: '09:00',
          endTime: '11:00',
        },
        {
          startTime: '13:00',
          endTime: '15:00',
        },
        {
          startTime: '17:00',
          endTime: '18:00',
        },
      ],
      tuesday: [
        {
          startTime: '09:00',
          endTime: '11:00',
        },
        {
          startTime: '13:00',
          endTime: '15:00',
        },
      ],
      wednesday: [
        {
          startTime: '09:00',
          endTime: '11:00',
        },
        {
          startTime: '13:00',
          endTime: '15:00',
        },
        {
          startTime: '16:00',
          endTime: '17:00',
        },
        {
          startTime: '17:00',
          endTime: '18:00',
        },
      ],
      thursday: [
        {
          startTime: '09:00',
          endTime: '11:00',
        },
        {
          startTime: '13:00',
          endTime: '15:00',
        },
        {
          startTime: '16:00',
          endTime: '17:00',
        },
      ],
      friday: [
        {
          startTime: '09:00',
          endTime: '11:00',
        },
      ],
      saturday: null,
    },
  },
};
