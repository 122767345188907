import { IAction } from 'redux/store/types';
import { SOCKET_IN_APP_NOTIFICATION, SOCKET_NAVIGATE_TO_SCREEN, SOCKET_NAVIGATION_COMPLETE } from './actions';
import { SocketState } from './types';

const DEFAULT: SocketState = {
  connected: false,
  connecting: false,
  navigate: false,
  navigateData: null,
  inAppNotification: {
    open: false,
    data: null,
  },
};

export default function socketReducer(state = DEFAULT, action: IAction): SocketState {
  const { type, payload } = action;

  switch (type) {
    case SOCKET_NAVIGATE_TO_SCREEN: {
      return {
        ...state,
        navigate: true,
        navigateData: payload,
        inAppNotification: {
          open: false,
          data: null,
        },
      };
    }
    case SOCKET_NAVIGATION_COMPLETE: {
      return {
        ...state,
        navigate: false,
        navigateData: null,
        inAppNotification: {
          open: false,
          data: null,
        },
      };
    }
    case SOCKET_IN_APP_NOTIFICATION: {
      return {
        ...state,
        inAppNotification: {
          open: payload?.open || false,
          data: payload?.data || null,
        },
      };
    }
    default: {
      return state;
    }
  }
}
