import clsx from 'clsx';
import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { formatTimeMessage } from 'utils/CommonUtils';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { useStyles } from './Chats.styles';

type Props = {
  messageThread?: {
    messageId?: number;
    message?: string;
    nickName?: string;
    colorCode?: string;
    userId?: string;
    name?: string;
    avatar?: string;
    thumbnail?: string;
    createdDate?: string;
    fileMeta?: {
      type?: string;
      url?: string;
    };
  };
  recommendation?: boolean;
  onClose?: () => void;
};

const MessageThread: FC<Props> = ({ messageThread, recommendation, onClose }) => {
  const classes = useStyles({});
  return (
    <Box
      className={clsx({
        [classes.replyMessageDisplay2]: messageThread.fileMeta,
        [classes.replyMessageDisplay]: !messageThread.fileMeta,
        [classes.bottomSpace]: recommendation,
      })}
    >
      <div className={classes.replyMessageDisplayTitle}>
        <div className={classes.replyMessageDisplayTitleHeading}>
          <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD}>
            Add reply
          </Text>
        </div>
        <div className={classes.replyMessageDisplayTitleBtn}>
          <Button variant={btnType.TEXT} className={classes.replyCloseBtn} onClick={() => onClose()}>
            <Icons glyph="close" color={colors.primary600} />
          </Button>
        </div>
      </div>
      <div className={classes.userChatCard}>
        <div>
          <Avatar
            variant={avatarType.CIRCLE}
            size={40}
            src={messageThread?.thumbnail}
            name={messageThread?.nickName}
            className={classes.avt}
            color={messageThread?.colorCode}
          />
        </div>
        <div className="userChatContentWrapper2">
          <div className="userChatMessageWrapper2">
            <div className="userChatNameWrapper">
              {!!messageThread.nickName && (
                <Text className="nickName" weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                  {messageThread.nickName === 'Super Admin' || messageThread.nickName === 'Confidant Admin'
                    ? 'Confidant Admin'
                    : messageThread.nickName}
                </Text>
              )}
              <Text className="time" weight={fontWeight.SEMI_BOLD} level={textLevel.XS}>
                {formatTimeMessage(messageThread.createdDate)}
              </Text>
            </div>
            <div className={messageThread.fileMeta ? 'replyMsgContainer' : 'replyMsgContainer2'}>
              <Text className={classes.messageText} weight={fontWeight.MEDIUM} level={textLevel.S}>
                {messageThread.message}
              </Text>
              {messageThread.fileMeta && (
                <>
                  {messageThread.fileMeta && (
                    <>
                      {messageThread.fileMeta?.type.split('/')[0] === 'image' ? (
                        <img
                          src={messageThread.fileMeta.url}
                          alt="confidant health"
                          height={96}
                          width={160}
                          style={{ borderRadius: 4 }}
                        />
                      ) : (
                        <video
                          controls
                          src={messageThread.fileMeta.url}
                          height={96}
                          width={160}
                          style={{ borderRadius: 4 }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export { MessageThread };
