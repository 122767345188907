import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { btnType } from '@confidant-health/lib/ui/atoms/button';

import Header from 'components/v2/Header';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from '../../../../redux/modules/auth/selectors';

import AvailableStateSection from './AvailableStateSection';

import { useStyles } from './index.styles';
import { fetchAvailableStates } from '../../../../services/datalab/datalab.service';
import AvailableState from '../../profile/components/available-states';
import { stateActionCreators } from '../../../../redux/modules/state';

const AvailableStates = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { providerDetails } = useSelector(getProfile);
  const { meta } = useSelector(getAuth);
  const [availableStates, setAvailableStates] = useState([]);
  const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);

  const getAvailableStates = async () => {
    try {
      const response = await fetchAvailableStates({ userAccountId: meta?.userId });
      setAvailableStates(response.data.data);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    dispatch(stateActionCreators.fetchPayerGroups());
    void getAvailableStates();
  }, []);

  const handleCloseDrawer = () => {
    setShowAddNewDrawer(false);
    void getAvailableStates();
  };

  return (
    <>
      <Header
        label="Available States"
        actions={[
          {
            variant: btnType.PRIMARY,
            icon: 'plus',
            className: classes.primaryBtn,
            children: <Typography {...typography.body.normal.medium.bold}>Add new</Typography>,
            onClick: () => setShowAddNewDrawer(true),
          },
        ]}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        {availableStates?.map((state, index) => (
          <AvailableStateSection key={index} state={state} getAvailableStates={getAvailableStates} />
        ))}
      </Box>
      <AvailableState states={[]} isOpen={showAddNewDrawer} onClose={handleCloseDrawer} />
    </>
  );
};

export default AvailableStates;
