// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '560px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  header: {
    height: 108,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  headSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'block',
    margin: 0,
  },
  top: {
    display: 'flex',
    padding: 32,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  username: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    display: 'block',
  },
  fullname: {
    color: colors.neutral600,
  },
  minutesText: {
    color: colors.neutral700,
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    '& svg': {
      width: 30,
    },
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 32,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  ratingWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  rating: {
    '& path': {
      fill: colors.warning500,
    },
  },
  ratingValue: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral500,
    margin: 0,
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  checkboxLabel: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  footer: {
    height: 96,
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  newReportBtn: {
    color: colors.white,
    '& svg path': {
      fill: colors.white,
    },
  },
  contentFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  contentTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 700,
  },
  question: {
    marginLeft: 8,
    position: 'relative',
    top: 4,
    '& path': {
      fill: colors.neutral600,
    },
  },
  menuLabel: {
    color: colors.neutral700,
    '& ul li': {
      color: colors.neutral700,
    },
  },
  contentChart: {
    padding: 24,
  },
  contentDescription: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    padding: '0px 24px 24px',
    display: 'block',
  },
});
