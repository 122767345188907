import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  dropdownItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    fontWeight: 700,
    width: '100%',
    fontSize: 14,
  },
  focus2: {
    boxShadow: '-5px 0 5px #e3f1ff, 5px 0 5px #e3f1ff, 0 -5px 5px #e3f1ff',
  },
  isChat: {
    fontFamily: 'manrope',
    lineHeight: 20,
    fontWeight: '500',
    // height: 56,
    borderRadius: '0px 0px 6px 6px',
    padding: '13px 16px !important',
    '& .MuiInput-input': {
      fontSize: 14,
    },
  },
  alfieChat: {
    fontWeight: '500',
    // height: 108,
    borderRadius: '8px 8px 0px 0px',
    border: `1px solid #D4DDE5`,
    borderBottom: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 24px 10px 19px!important',
    gap: 8,
    // padding: '13px 16px !important',
  },
  alfieChat2: {
    fontWeight: '500',
    // height: 108,
    borderRadius: '8px 8px 0px 0px',
    border: `1px solid #D4DDE5`,
    borderBottom: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 24px 10px 19px!important',
    // padding: '13px 16px !important',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 40,
    minWidth: 40,
    height: 40,
    minHeight: 40,
  },
  chatbotContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
  },
  chatbotContainerHeading: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },
  chatbotContainerText: {
    fontSize: 16,
    lineHeight: '24px',
    paddingRight: '20px',
    maxHeight: 70,
    overflow: 'auto !important',
  },
  primary: {
    height: 40,
    padding: '8px 16px !important',
    fontSize: 14,
    lineHeight: '20px',
  },
  outline: {
    height: 40,
    color: '#0365C1',
    borderColor: '#8EC8FD',
    padding: '8px 16px !important',
    fontSize: 14,
    lineHeight: '20px',
  },
  btnContainer: {
    display: 'flex',
    gap: 16,
    justifyContent: 'flex-end',
    minWidth: '255px',
  },
  linkBtn: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19px',
    color: colors.primary600,
    transition: '0.4s',
    height: 'unset !important',
    '&:hover': {
      opacity: 0.8,
    },
    padding: '0px !important',
  },
  linkIcon: {
    marginRight: '8px',
  },
  btnText: {
    gap: 13,
  },
  mentionUserList: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 6,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 10px 20px 0px #0000000A',
    background: 'white',
    position: 'fixed',
    width: 261,
    overflowY: 'auto',
    listStyleType: 'none',
    padding: 0,
  },
  oneUser: {
    height: 72,
    marginTop: -82,
  },
  twoUser: {
    height: 135,
    marginTop: -145,
  },
  threeUser: {
    height: 200,
    marginTop: -210,
  },
  allUser: {
    height: 280,
    marginTop: -290,
  },
  mentionUserListData: {
    height: 62,
    padding: '8px 16px 8px 16px',
    cursor: 'pointer',
  },
  focused: {
    backgroundColor: colors.primary100,
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  badge: {
    borderRadius: 20,
    height: 18,
    padding: '2px 8px',
    fontSize: 12,
    width: 'fit-content',
    border: 'none',
    marginTop: 2,
    maxWidth: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  userName: {
    width: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
  },
});
