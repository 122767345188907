import { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { colors } from '@confidant-health/lib/colors';
import { btnSize, btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';

import { IClaimSubmitter } from 'redux/modules/reporting/types';
import { useStyles } from '../ClaimDetail.styles';

type Props = {
  onSubmit: (formData, type) => void;
  data: IClaimSubmitter;
};

type DrawerProps = {
  data: IClaimSubmitter;
  open: boolean;
  onClose: () => void;
  onSubmit: (formData, type) => void;
};

const defaultData = { name: '', taxId: '', phone: '', fax: '', email: '' };

const labels = {
  name: 'Submitter',
  taxId: 'Tax ID',
  phone: 'Phone',
  fax: 'Fax',
  email: 'Email',
};

const SubmitterDrawer = ({ data, open, onClose, onSubmit }: DrawerProps) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...data });
  const [errors, setErrors] = useState(defaultData);

  const onChangeField = (field: string) => (e: any) => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: '' }));
    }
    setFormData(old => ({
      ...old,
      [field]: e.target?.value,
    }));
  };

  const onChangeSubmitter = () => {
    onSubmit(formData, 'submitter');
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title="Edit Submitter"
      submitBtnTitle="Update"
      onSubmit={onChangeSubmitter}
    >
      {Object.keys(data)
        .filter(d => !['state', 'email'].includes(d))
        .map((d: keyof IClaimSubmitter, index: number) => (
          <Box className={classes.inputWrapper} key={`${d}-${index}`}>
            <label htmlFor={d}>
              <Text level={textLevel.S} weight={fontWeight.BOLD}>
                {labels[d]}
              </Text>
            </label>
            <Input
              name={d}
              value={formData[d] || ''}
              onChange={onChangeField(d)}
              size={inputSize.M}
              placeholder={`Enter your ${labels[d]}`}
            />
            {errors[d] && <TextError errorMsg={errors[d]} />}
          </Box>
        ))}
    </Drawer>
  );
};

const Submitter = ({ onSubmit, data = defaultData }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={175} mr={4}>
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
        {value || 'N/A'}
      </Text>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          Submitter
        </Heading>
        <Button
          className={classes.btn}
          variant={btnType.TEXT}
          onClick={() => setOpen(true)}
          size={btnSize.SMALL}
        >
          Change submitter
        </Button>
      </Box>
      <Divider sx={{ marginX: -3 }} />

      <Box display="flex" mt={4}>
        <Col label="Submitter" value={data?.name} />
        <Col label="Tax id" value={data?.taxId} />
        <Col label="Phone" value={data?.phone} />
        <Col label="Fax" value={data?.fax} />
        {/* <Col label="Email" value={data?.email} /> */}
      </Box>
      {open && <SubmitterDrawer data={data} open={open} onClose={() => setOpen(false)} onSubmit={onSubmit} />}
    </Box>
  );
};

export default Submitter;
