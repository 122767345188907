import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { IIconButtonProps, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { theme } from '@confidant-health/lib/theme';
import { Box, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';
import cx from 'clsx';
import BoxFrame from 'components/v2/BoxFrame';
import { useStyles } from '../index.styles';

type Props = {
  img: string | ReactNode;
  action: IIconButtonProps;
  extended?: ReactNode;
};

const Card = ({ img, action, extended }: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <BoxFrame>
      <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexDirection: {
              md: 'row',
              xs: 'column',
            },
          }}
        >
          {!isMobile && (
            <>
              {typeof img === 'string' ? (
                <img src={img} alt="no appointments" className={classes.img64} />
              ) : (
                img
              )}
            </>
          )}
          <IconButton
            variant={btnType.OUTLINE}
            className={cx(classes.outlineBtn, classes.xsmallBtn)}
            {...action}
          />
        </Box>
        {extended}
      </Box>
    </BoxFrame>
  );
};

export default Card;
