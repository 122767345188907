import { AxiosResponse } from 'axios';
import { baseRequest } from 'utils/HttpClient';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { INewTypePayload } from 'pages/admin/priority-types/components/add-edit-type/AddEditPriorityType.types';
import { INewGroupPayload } from 'pages/admin/priority-groupings/add-edit-grouping/AddEditPriorityGrouping.types';
import { INewElementPayload } from 'pages/admin/priority-elements/add-edit-elements/AddEditPriorityElement.types';

export const getPriorityDomainTypes = (payload: {
  searchQuery: string;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: any[];
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PRIORITY_DOMAIN_TYPES, null, null, payload);
};

export const getPriorityDomainElements = (payload: {
  searchQuery: string;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: any[];
  typeIds: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PRIORITY_DOMAIN_ELEMENTS, null, null, payload);
};

export const getDomainElementsByTypeId = (typeId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_ELEMENTS_BY_TYPE_ID, null, { typeId });
};

export const getPriorityDomainProfileElements = (payload: {
  searchQuery: string;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: any[];
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROFILE_ELEMENTS, null, null, payload);
};

export const getPriorityDomainGroups = (payload: {
  searchQuery: string;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: any[];
  typeIds: string;
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PRIORITY_DOMAIN_GROUPS, null, null, payload);
};

export const createNewDomainType = (payload: INewTypePayload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_DOMAIN_TYPE, payload);
};

export const updateDomainType = (payload: INewTypePayload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_DOMAIN_TYPE, payload, { domainTypeId: payload.Id });
};

export const deleteDomainType = (domainTypeId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.DELETE_DOMAIN_TYPE, null, { domainTypeId });
};

export const deleteDomainElement = (domainElementId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.DELETE_DOMAIN_ELEMENT, null, { domainElementId });
};

export const deleteDomainGroup = (domainGroupId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.DELETE_DOMAIN_GROUP, null, { domainGroupId });
};

export const createNewDomainGroup = (payload: INewGroupPayload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_DOMAIN_GROUP, payload);
};

export const updateDomainGroup = (payload: INewGroupPayload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_DOMAIN_GROUP, payload, { domainGroupId: payload.Id });
};

export const getDomainLookups = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_DOMAIN_LOOKUP, null, null);
};

export const createNewElementType = (payload: INewElementPayload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_DOMAIN_ELEMENT, payload);
};

export const updateDomainElement = (payload: INewElementPayload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_DOMAIN_ELEMENT, payload, { domainElementId: payload.Id });
};

export const getAssignedPrioritiesV2 = (params: {
  patientId: string;
  pageNumber: number;
  pageSize: number;
  dataDomainType: string;
  dataDomainTypes?: string;
  importanceLevels?: string;
  searchQuery?: string;
}): Promise<AxiosResponse> => {
  if (!params.importanceLevels || params.importanceLevels === '') {
    delete params.importanceLevels;
  }
  return baseRequest(ApiEndpoints.GET_ASSIGNED_PRIORITIES, null, null, params);
};
