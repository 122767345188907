import { IAction } from 'redux/store/types';
import { FETCH_USER_ACTIVITY, FETCH_USER_ACTIVITY_FAILED, FETCH_USER_ACTIVITY_SUCCESSFUL } from './actions';
import { AuditState, IActivity } from './types';

export const DEFAULT = {
  isLoading: false,
  errorMsg: '',
  activity: {} as IActivity,
};

export default function auditReducer(state = DEFAULT, action: IAction): AuditState {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_ACTIVITY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_USER_ACTIVITY_FAILED: {
      return {
        ...state,
        isLoading: false,
        activity: {} as IActivity,
      };
    }
    case FETCH_USER_ACTIVITY_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        activity: payload,
      };
    }
    default: {
      return state;
    }
  }
}
