import { colors } from '@confidant-health/lib/colors';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { IProfileElementDistribution } from './ProfileElementDetail.types';

export const profileColumns: TableGrid.TableColumn[] = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'type', label: 'Type', sortable: true },
  { id: 'values', label: 'Values', sortable: false },
  { id: 'method', label: 'Method', sortable: true },
  { id: 'appearIn', label: 'Appears In', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];

export const DEFAULT_DONUT_CHART_VALUES = 9;

export const GRAPH_COLORS = [
  colors.success500,
  colors.orange500,
  '#58BDFF',
  '#FF4560',
  '#00E396',
  '#FEB019',
  '#a738fe',
  '#cf9f0e',
  '#a832a6',
];

export const profileListMock = [
  {
    id: '1',
    name: 'ReVAMP Physically uncomfortable or ill negativ...',
    type: 'Type 1',
    values: 'Yes',
    method: 'Most Recent Response',
    dct: 1,
    convo: 1,
  },
  {
    id: '2',
    name: 'ReVAMP Physically uncomfortable or ill negativ...',
    type: 'Type 1',
    values: 'Yes',
    method: 'Most Recent Response',
    dct: 1,
    convo: 1,
  },
  {
    id: '3',
    name: 'ReVAMP Physically uncomfortable or ill negativ...',
    type: 'Type 1',
    values: 'Yes',
    method: 'Most Recent Response',
    dct: 1,
    convo: 1,
  },
  {
    id: '4',
    name: 'ReVAMP Physically uncomfortable or ill negativ...',
    type: 'Type 1',
    values: 'Yes',
    method: 'Most Recent Response',
    dct: 1,
    convo: 1,
  },
  {
    id: '5',
    name: 'ReVAMP Physically uncomfortable or ill negativ...',
    type: 'Type 1',
    values: 'Yes',
    method: 'All Responses with Date/Time stamps',
    dct: 1,
    convo: 1,
  },
  {
    id: '6',
    name: 'ReVAMP Physically uncomfortable or ill negativ...',
    type: 'Type 1',
    values: 'Yes',
    method: 'All Responses with Date/Time stamps',
    dct: 1,
    convo: 1,
  },
];

export const getProfileElementDistributionDetailQuery = (profilElementKey: string) => {
  return {
    size: 0,
    query: {
      term: {
        'dataElementKey.keyword': {
          value: profilElementKey,
        },
      },
    },
    aggs: {
      unique_member_count: {
        cardinality: {
          field: 'userAccountId.keyword',
        },
      },

      field_distribution: {
        terms: {
          field: 'dataElementValue.keyword',
          size: 100,
        },
      },
    },
  };
};

/* eslint-disable camelcase */
interface UniqueMemberCount {
  value: number;
}
interface Bucket {
  key: string;
  doc_count: number;
}
interface FieldDistribution {
  buckets: Bucket[];
}
interface Aggregations {
  unique_member_count: UniqueMemberCount;
  field_distribution: FieldDistribution;
}
export interface IProfileElementDistributionData {
  aggregations: Aggregations;
}
export const formatDistributionData = (data: IProfileElementDistributionData) => {
  return {
    totalPatients: data?.aggregations?.unique_member_count?.value || 0,
    values: data?.aggregations?.field_distribution?.buckets?.map(bucket => {
      return {
        assignedCount: bucket?.doc_count || 0,
        name: bucket?.key || '',
        percentage: (bucket?.doc_count / data?.aggregations?.unique_member_count?.value) * 100 || 0,
      };
    }),
  } as IProfileElementDistribution;
};
