import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import {
  NoteCategoryCard,
  INoteCategoryCardProps,
} from '@confidant-health/lib/ui/templates/note-category-card';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { INote } from '@confidant-health/lib/ui/templates/note-card';

import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from 'redux/modules/auth/selectors';

import { addMemberNotes, updateMemberNotes } from 'services/member/member.service';
import { NoteCategoriesMock } from 'pages/provider/member-detail/MemberDetail.mock';
import { CONTACT_NOTES } from 'constants/CommonConstants';
import { showSnackbar } from 'redux/modules/snackbar';
import AddNoteForm from './AddNoteForm';
import { AddNotePayload } from './AddNoteForm.types';
import { useStyles } from './AddNoteDrawer.styles';
import { MediaManager } from '../../../../../../services/mediaManager/mediaManager';

type AddNoteDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  note?: INote;
  fetchNotes: () => void;
  memberId: string;
};

const AddNoteDrawer: React.FC<AddNoteDrawerProps> = ({ isOpen, note, onClose, fetchNotes, memberId }) => {
  const classes = useStyles();
  const [selectedNote, setNoteCategoryType] = useState<INoteCategoryCardProps | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const { meta } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (!isSaving) {
      if (selectedNote) {
        setNoteCategoryType(null);
      } else {
        onClose();
      }
    }
  };

  const onSelectNoteCategory = (item: INoteCategoryCardProps) => () => {
    setNoteCategoryType(item);
  };

  const onBackCategories = () => {
    if (note) {
      onClose();
      return;
    }
    setNoteCategoryType(null);
  };

  const renderHeader = () => {
    return (
      <div className={classes.header}>
        <IconButton
          icon="chevron-left"
          variant={btnType.OUTLINE}
          onClick={onBackCategories}
          className={classes.backBtn}
        />
        <Heading level={headingLevel.M} className={classes.headerTitle} weight={fontWeight.BOLD}>
          {note ? 'Update note' : `Add ${selectedNote?.title.toLocaleLowerCase()}`}
        </Heading>
      </div>
    );
  };

  const onSubmit = async (payload: AddNotePayload) => {
    const flag = selectedNote?.flag || note?.flag;
    const body = {
      ...payload,
      flag,
      attachments: payload.attachments ?? [],
      recipients: payload.recipients,
      // description: payload.description,
      name: payload.title,
      notes: payload.description,
      patientId: memberId,
      userId: meta?.userId,
      providerId: meta?.userId,

      // relationship: payload.relationship,
      type: flag,
      createdBy: profile.fullName || meta.nickName,
    };
    setIsSaving(true);
    try {
      if (payload.attachments.length > 0) {
        const attachments = [];
        const allPromises = [];
        const withoutOriginalFileAttachemnt = [];
        const originalFileAttachemnt = [];
        const newUplaodedAttachemnt = [];
        payload.attachments.forEach(({ originFile }, index) => {
          if (originFile) {
            originalFileAttachemnt.push(payload.attachments[index]);
            allPromises.push(
              MediaManager.uploadContactNotesMedia(originFile, `${CONTACT_NOTES}/${memberId}/`, e =>
                console.log(e),
              ),
            );
          }
          if (originFile === undefined) {
            withoutOriginalFileAttachemnt.push(payload?.attachments[index]);
          }
        });
        const allPromisesResponded = await Promise.all(allPromises);
        allPromisesResponded.forEach(item => {
          if (item.success) {
            attachments.push(item.response.location);
          }
        });
        if (attachments.length > 0) {
          originalFileAttachemnt.forEach((attachment, index) => {
            newUplaodedAttachemnt.push({
              ...attachment,
              attachmentUrl: attachments[index],
              attachmentSize: attachment?.attachmentSize?.replace('B', ''),
            });
          });
          body.attachments = newUplaodedAttachemnt.concat(withoutOriginalFileAttachemnt);
        }
      }
      if (note) {
        try {
          const newPayload = { ...note, ...body };
          const response = await updateMemberNotes(newPayload);
          if (response.status === 200) {
            dispatch(
              showSnackbar({
                snackType: 'success',
                snackMessage: response?.data?.message,
              }),
            );
          }
        } catch (error) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: error?.data?.errors[0].endUserMessage,
            }),
          );
        }
      } else {
        try {
          const response = await addMemberNotes(body);
          if (response.status === 200) {
            dispatch(
              showSnackbar({
                snackType: 'success',
                snackMessage: response?.data?.message,
              }),
            );
          }
        } catch (error) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: error?.data?.errors[0].endUserMessage,
            }),
          );
        }
      }
      fetchNotes();
      setNoteCategoryType(null);
      onClose();
      // eslint-disable-next-line no-empty
    } catch (err1) {
      const message = err1?.data?.errors[0].endUserMessage || 'Something went wrong';
      dispatch(showSnackbar({ snackType: 'error', snackMessage: message }));
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      title={note ? 'Update note' : 'Add note'}
      open={isOpen}
      onClose={handleClose}
      variant={drawerType.FORM}
      className={clsx(classes.drawer, { [classes.bodyWhite]: !!selectedNote || !!note })}
      footer={<div />}
      submitBtnTitle={note ? 'Save note' : 'Add note'}
      header={selectedNote || note ? renderHeader() : null}
    >
      {selectedNote || note ? (
        <AddNoteForm
          onSubmit={onSubmit}
          onCancel={onBackCategories}
          noteCategory={selectedNote}
          note={note}
          isSaving={isSaving}
        />
      ) : (
        <div className={classes.categories}>
          {NoteCategoriesMock.map((item, index) => (
            <NoteCategoryCard key={index} {...item} onClick={onSelectNoteCategory(item)} />
          ))}
        </div>
      )}
    </Drawer>
  );
};

export default AddNoteDrawer;
