import { IAutomationEvent } from './automation-event/AutomationEvent';

export const listEvent: IAutomationEvent[] = [
  {
    icon: 'schedule',
    title: 'Event',
    events: [
      {
        startLabel: 'Event',
        startValue: 'DCT completed',
        endLabel: 'Reference',
        endValue: 'Heroin use',
      },
    ],
  },
  {
    icon: 'filter',
    title: 'Filters',
    events: [
      {
        startLabel: 'Profile element',
        startValue: 'PHQ-9 Score',
        endLabel: 'Equals',
        endValue: '7',
        isDeleteable: true,
      },
      {
        startLabel: 'Profile element',
        startValue: 'POC Primary Interest',
        endLabel: 'Equals',
        endValue: 'Mental Health',
        isDeleteable: true,
      },
    ],
  },
  {
    icon: 'pointer',
    title: 'Action',
    events: [
      {
        startLabel: 'Action type',
        startValue: 'Assign education',
        endLabel: 'Education article',
        endValue: 'What characterizes addiction?',
      },
    ],
  },
];
