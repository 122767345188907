// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  divider: {
    marginLeft: '0px !important',
    marginTop: '0px !important',
  },
  divider2: {
    marginLeft: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  btn: {
    color: '#000000',
    fontWeight: 600,
  },
  body: {
    backgroundColor: colors.white,
    maxHeight: 'calc(100vh - 286px)',
    overflowY: 'scroll',
  },
  cList: {
    display: 'inline-flex',
    width: '100%',
    flexGrow: 1,
    overflow: 'auto',
    '& .full-match': {
      color: '#29aa31',
    },
    '& .partial-match': {
      color: '#ef681a',
    },
    '& .no-match': {
      color: '#ef4242',
    },
  },
});
