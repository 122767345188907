import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { RadioGroup } from '@confidant-health/lib/ui/molecules/radio-group';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { TOAST_TYPES } from 'constants/CommonConstants';

import { useStyles } from './RejectEvaluationModal.styles';
import { IEvaluationItem, IEvaluationUpdateRequest } from '../Evaluations.types';
import { EVALUATION_STATUS_TYPES } from '../Evaluations.constants';

type Props = {
  open: boolean;
  isEndEvaluation?: boolean;
  evaluation: IEvaluationItem;
  updateEvaluationStatus?: (evaluationUpdateRequest: IEvaluationUpdateRequest) => void;
  onClose: () => void;
  setToastProperties: Dispatch<
    SetStateAction<{
      message: string;
      type: string;
      status: boolean;
    }>
  >;
};

const RejectEvaluationModal: FC<Props> = ({
  open,
  isEndEvaluation,
  evaluation,
  onClose,
  updateEvaluationStatus,
  setToastProperties,
}) => {
  const classes = useStyles();
  const [selectedReason, setSelectedReason] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const [otherReasonMessage, setOtherReasonMessage] = useState('');
  const [rejectionReasonsList, setRejectionReasonsList] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const updatedRejectionReasonsList = evaluation?.rejectionTemplate?.map(rejectionReason => {
      return {
        label: rejectionReason,
        value: rejectionReason,
      };
    });
    updatedRejectionReasonsList?.length > 0
      ? setRejectionReasonsList([...updatedRejectionReasonsList, { label: 'Other', value: 'other' }])
      : setRejectionReasonsList([{ label: 'Other', value: 'other' }]);
  }, []);

  /**
   * @Name onChangeOtherReasonMessage
   * @description This method is used to change other reason messsage
   */
  const onChangeOtherReasonMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReasonMessage(e.target.value);
    setError('');
  };

  /**
   * @Name onChangeReasonDropDownValue
   * @description This method is used to change reason value from drop down
   */
  const onChangeReasonDropDownValue = (e: { target: { value: SetStateAction<string> } }) => {
    setSelectedReason(e.target.value);
  };

  /**
   * @Name onSubmitClick
   * @description This method is used to submit end/reject evaluation
   */
  const onSubmitClick = () => {
    if (!isEndEvaluation && selectedReason.length === 0) {
      setToastProperties({
        message: 'Reason is Required!',
        type: TOAST_TYPES.ERROR,
        status: true,
      });
      return;
    }
    if (otherReasonMessage.trim().length === 0 && selectedReason === 'other') {
      setError('Please enter reason');
      return;
    }
    const evaluationUpdateRequest = {
      evaluationId: evaluation?.id,
      evaluationUpdatedStatus: isEndEvaluation
        ? EVALUATION_STATUS_TYPES.COMPLETED
        : EVALUATION_STATUS_TYPES.REJECTED,
      // eslint-disable-next-line no-nested-ternary
      remarks: isEndEvaluation
        ? 'Evaluation Ended'
        : otherReasonMessage.length > 0
        ? otherReasonMessage
        : 'no remarks',
    };
    updateEvaluationStatus(evaluationUpdateRequest);
    setIsRequesting(true);
    setTimeout(() => {
      setIsRequesting(false);
      onClose();
    }, 3000);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.editIconWrap}>
            <Icons glyph="clear-circle" className={classes.closeIcon} onClick={onClose} />
          </Box>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>
                {isEndEvaluation ? 'End' : 'Reject'} {evaluation?.name}?
              </Heading>
              <Text className={classes.subtitle}>All the progress will be lost - this cannot be undone.</Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            {!isEndEvaluation && (
              <Box>
                <Text className={classes.label}>Why do you want to reject this evaluation?</Text>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  value={selectedReason}
                  name="radio-buttons-group"
                  onChange={onChangeReasonDropDownValue}
                  className={classes.radioGroup}
                  options={rejectionReasonsList}
                />
                {selectedReason === 'other' && (
                  <Box>
                    <Text className={classes.label}>Enter reason</Text>
                    <Textarea
                      placeholder="Enter reason"
                      value={otherReasonMessage}
                      onChange={onChangeOtherReasonMessage}
                      className={classes.input}
                      minRows={3}
                    />
                    <TextError errorMsg={error ? 'Not allow empty!' : null} />
                  </Box>
                )}
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button className={clsx(classes.btn, classes.cancelBtn)} onClick={onClose}>
                {isEndEvaluation ? 'Continue evaluation' : 'Keep evaluation'}
              </Button>
              <Button className={clsx(classes.btn, classes.okBtn)} onClick={onSubmitClick}>
                {isRequesting && (
                  <Icons
                    glyph="in-progress"
                    color={colors.white}
                    className={clsx('rotate linear infinite', classes.loading)}
                  />
                )}
                {isEndEvaluation ? 'End evaluation' : 'Reject evaluation'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { RejectEvaluationModal };
