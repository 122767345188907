import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import { sentenceCase } from 'sentence-case';

import { Box } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';

import { conversationActionCreators } from 'redux/modules/conversation/actions';
import {
  selectAssignedPlanItemsById,
  selectAssignedPlanItemsLoading,
} from 'redux/modules/conversation/selectors';

import PlanItemCard from './PlanItemCard';
import PlanItemDrawer from './PlanItemDrawer';
import { useStyles } from './PlanItems.styles';

type Props = {
  memberID?: string;
};

const PlanItems: React.FC<Props> = ({ memberID }) => {
  const classes = useStyles({});
  const { memberId = memberID } = useParams();
  const [showAddNew, setShowAddNew] = useState(false);
  const isLoading = useSelector(selectAssignedPlanItemsLoading);
  const planItemList = useSelector(selectAssignedPlanItemsById(memberId));
  const dispatch = useDispatch();

  const planItemGroupList = useMemo(() => groupBy(planItemList, 'status'), [planItemList]);

  const onClickAddNew = () => {
    setShowAddNew(true);
  };

  const onCloseAddNew = () => {
    setShowAddNew(false);
  };

  const fetchAssignedPlanItems = useCallback(() => {
    dispatch(conversationActionCreators.fetchAssignedPlanItems({ memberId }));
  }, [memberId, dispatch]);

  useEffect(() => {
    void fetchAssignedPlanItems();
  }, [fetchAssignedPlanItems]);

  return (
    <>
      <div className={classes.headingWithBtnWrapper}>
        <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
          Plan items
        </Heading>
        <div className={classes.noteHeaderRightSide}>
          <IconButton
            className={classes.addBtn}
            icon="plus"
            variant={iconBtnType.PRIMARY}
            onClick={onClickAddNew}
          >
            Add plan Items
          </IconButton>
        </div>
      </div>
      <div className={classes.cardsWrapper}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        ) : (
          <>
            {planItemList?.length > 0 ? (
              Object.keys(planItemGroupList).map(status => (
                <Collapsable
                  key={status}
                  className={classes.collapsableAccordion}
                  iconPosition={IiconPosition.RIGHT}
                  label={sentenceCase(status || '')}
                  labelExtend={
                    <Badge
                      variant={badgeType.OUTLINED}
                      style={badgeStyle.UNRELATED}
                      className={classes.badge}
                    >
                      {`${planItemGroupList[status].length} item${
                        planItemGroupList[status].length > 1 ? 's' : ''
                      }`}
                    </Badge>
                  }
                  open
                >
                  {planItemGroupList[status].map((item, index) => (
                    <PlanItemCard item={item} key={index} />
                  ))}
                </Collapsable>
              ))
            ) : (
              <Text className={classes.emptyNote}>No plan items yet</Text>
            )}
          </>
        )}
      </div>
      <PlanItemDrawer isOpen={showAddNew} memberId={memberId} onClose={onCloseAddNew} />
    </>
  );
};

export { PlanItems };
