// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
  },
  header: {
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.neutral100}`,
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    padding: '20px 16px',
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  backBtn: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderRadius: 8,
    border: `1px solid ${colors.primary300}`,
    '& svg': {
      height: 13,
    },
  },
  body: {
    height: 'calc(100vh - 160px)',
    // padding: 32,
    overflowY: 'auto',
    backgroundColor: colors.neutral50,
  },
  body2: {
    height: 'calc(100vh - 160px)',
  },
  footer: {
    backgroundColor: colors.white,
  },
  viewCard: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    zIndex: 2,
    left: 0,
    backgroundColor: colors.white,
  },
});
