import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';

import { Heading, Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { colors } from '@confidant-health/lib/colors';

import { IConversationAssignments } from 'pages/admin/conversations/Conversations.types';
import { AppointmentsFilterDates, AppointmentsFilterStatus } from 'constants/CommonConstants';

import {
  getConversationAssignmentReport,
  getDctAssignmentReport,
  getEvaluationAssignmentReport,
} from 'services/conversation/conversation.service';

import { chartOptions } from './AssignmentsCard.utils';
import { useStyles } from './AssignmentsCard.styles';

type Props = {
  conversation?: IConversationAssignments;
  isEvaluation: boolean;
  version: number;
  evaluationId?: string;
};

const AssignmentsCard: FC<Props> = ({ conversation, isEvaluation, version, evaluationId }) => {
  const classes = useStyles();
  const { templateId } = useParams();
  const [days, setDays] = useState<number>(AppointmentsFilterDates[2].value);
  const [status] = useState<string>(AppointmentsFilterStatus[0].value);
  const [chartDates, setChartDates] = useState<string[]>([]);
  const [chartSeries, setChartSeries] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  const fetchConversationAssignmentReports = () => {
    if (isEvaluation) {
      setIsLoading(true);
      getEvaluationAssignmentReport(evaluationId, days, version)
        .then(res => {
          const dates: string[] = [];
          const series: number[] = [];
          if (res.data?.length <= 0) {
            setNoData(true);
          } else {
            setNoData(false);
          }
          res.data.forEach(item => {
            dates.push(item.assignmentDate);
            series.push(item.assignmentCount);
          });

          setChartSeries(series);
          setChartDates(dates);
          setIsLoading(false);
        })
        .catch(err => {
          console.log('err ', err);
          setIsLoading(false);
        });
      return;
    }
    setIsLoading(true);

    getConversationAssignmentReport(days, { conversationId: conversation?.conversationId }, version)
      .then(res => {
        const dates: string[] = [];
        const series: number[] = [];
        if (res.data?.length <= 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        res.data.forEach(item => {
          dates.push(item.assignmentDate);
          series.push(item.assignmentCount);
        });

        setChartSeries(series);
        setChartDates(dates);
        setIsLoading(false);
      })
      .catch(err => {
        console.log('err ', err);
        setIsLoading(false);
      });
  };

  const fetchDctAssignmentReports = () => {
    setIsLoading(true);
    getDctAssignmentReport(templateId, days)
      .then(res => {
        const dates: string[] = [];
        const series: number[] = [];
        if (res.data?.length <= 0) {
          setNoData(true);
        }
        res.data.forEach(item => {
          dates.push(item.assignmentDate);
          series.push(item.assignmentCount);
        });

        setChartSeries(series);
        setChartDates(dates);
        setIsLoading(false);
      })
      .catch(err => {
        console.log('err ', err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (conversation) {
      fetchConversationAssignmentReports();
    } else {
      fetchDctAssignmentReports();
    }
  }, [days, status]);

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Assignments chart</Heading>
        <Box className={classes.headRight}>
          <Select
            value={days}
            name="days"
            variant={selectType.PRIMARY}
            options={AppointmentsFilterDates}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setDays(Number(e.target.value))}
          />
          {/* <Select
            value={status}
            name="status"
            variant={selectType.PRIMARY}
            options={AppointmentsFilterStatus}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setStatus(e.target.value)}
          /> */}
        </Box>
      </Box>
      <Box className={classes.chart}>
        {!isLoading ? (
          !noData ? (
            <ApexChart
              options={chartOptions(chartDates)}
              series={[{ data: chartSeries }]}
              type="line"
              height={330}
            />
          ) : (
            <Box display="flex" justifyContent="center">
              <Text weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                No Record Found
              </Text>
            </Box>
          )
        ) : (
          <div className={classes.loader}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </div>
        )}
      </Box>
    </Box>
  );
};

export { AssignmentsCard };
