import * as yup from 'yup';

const EducationSchema = yup.object({
  title: yup.string().required('Name is required'),
  startDate: yup.date().required('Start date is required'),
  endDate: yup.date().required('End date is required'),
  avatar: yup.mixed().required('File is required'),
});

export { EducationSchema };
