import React from 'react';
import { Box } from '@mui/material';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { IconText } from '@confidant-health/lib/ui/molecules/icon-text';
import { Icons } from '@confidant-health/lib/icons';
import { useStyles } from './LoeAssignmentsDrawer.styles';

export const MemberInfo = ({ profileImage, nickName, fullName, goToChat, type }) => {
  const classes = useStyles();
  return (
    <Box className={classes.memberInfoContainer}>
      <Box className={classes.profileContainer}>
        <ProfileInfo type="member" photo={profileImage} nickName={nickName} fullName={fullName} />
      </Box>
      {type !== 'Signup incomplete' && (
        <Box className={classes.iconTextContainer} onClick={goToChat}>
          <Icons glyph="chat-icon" className={classes.icon} />
          <IconText>Go to chat</IconText>
        </Box>
      )}
    </Box>
  );
};
