import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    borderRadius: 8,
    border: `1px solid ${colors.primary300}`,
    '& svg': {
      width: 8,
    },
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    minHeight: 'calc(100vh - 160px)',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  removeBtn: {
    color: colors.destructive600,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    '& svg': {
      marginRight: 8,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  startAdorment: {
    marginRight: '28px !important',
    '&::after': {
      content: '"" !important',
      position: 'absolute',
      backgroundColor: colors.neutral25,
      borderRight: `1px solid ${colors.neutral100}`,
      width: 40,
      height: 52,
      left: -16,
      top: -16,
      bottom: -32,
      zIndex: '-1 !important',
    },
  },
  input: {
    height: 48,
  },
  timeSelect: {
    paddingLeft: 26,
  },
  clockIcon: {
    position: 'absolute',
    top: 42,
    left: 12,
  },
  toggle: {
    '& span': {
      fontWeight: 'bold',
    },
  },
  valuesInput: {
    padding: 0,
    '& > div': {
      '& input': {
        fontSize: '14px !important',
      },
    },
    '& .MuiAutocomplete-tag': {
      fontSize: 14,
    },
  },
});
