import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  between: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    marginTop: 16,
    height: 64,
    '&.Mui-disabled': {
      background: colors.primary50,
      color: colors.primary300,
    },
  },
  errorText: {
    color: colors.textError,
    marginBottom: 0,
  },
  buttonTextContainer: {
    width: '100%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 40,
    display: 'flex',
  },
  card: {
    background: colors.primary100,
    minHeight: 148,
    marginBottom: 24,
    padding: 24,
    display: 'flex !important',
    gap: 24,
    borderRadius: 12,
  },
});
