import { useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons/Icons';
import { colors } from '@confidant-health/lib/colors';

import { useDomainType } from 'hooks/useDomainType';
import { getProfile } from 'redux/modules/profile/selectors';
import { profileActionCreators } from 'redux/modules/profile';
import { createNewAssociatedTag } from 'services/member/member.service';
import { getAuth } from 'redux/modules/auth/selectors';
import { DomainTypesEnum } from 'constants/CommonConstants';

import PriorityEmpty from '../PriorityEmpty';
import AddNewReport from './substance-use-new';
import SubstanceUseDetail from './substance-use-detail';
import SubstanceUseEditing from './substance-use-editing';
import SubstanceUseCard from './substance-use-card';

import { useStyles } from '../../MemberDetail.styles';
import { ISubstanceUse } from './substance-use-card/SubstanceUseCard.types';
import { SubstanceEditValue } from './SubstanceUse.constants';

const SubstanceUse: FC = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { meta } = useSelector(getAuth);
  const { profile, selectedPatient } = useSelector(getProfile);
  const [isLoading, domainData, domainOptions, lookupData] = useDomainType({
    type: DomainTypesEnum.SUBSTANCE_USE,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [errorApi, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);

  const onClickItem = (item, isEdit = false) => {
    setSelectedItem(item);
    setIsEditing(isEdit);
  };

  const onCloseDrawer = () => {
    setSelectedItem(null);
    setIsEditing(false);
    setShowAddItem(false);
  };

  const onClickNewReport = () => {
    setShowAddItem(true);
  };

  const onAddNewSubstanceUse = () => {
    setShowAddItem(true);
  };

  const onUpdateItem = async (data: SubstanceEditValue) => {
    setIsSaving(true);
    setError(null);
    try {
      await createNewAssociatedTag({
        patientId: memberId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: selectedItem.domainElementId,
            metaData: {
              interferenceInLife: data.isInterference,
              notes: data.notes,
              selectedImportance: data.status,
              substanceUse: {
                currentFrequencyOfUse: data.frequency,
                howLongUsingThisLevel: data.duration,
                lastUse: data.lastUse,
                methodOfUse: data.method,
                unitOfUse: data.dose.unit,
                dose: data.dose.amount,
                refillable: data.refillable,
              },
            },
          },
        ],
      });
      setIsSaving(false);
      dispatch(profileActionCreators.fetchDomainTypes(memberId));
      onCloseDrawer();
    } catch (error) {
      setError(error.data.message || error.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const onSubmitNewReport = (payload: ISubstanceUse[]) => {
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        metaData: {
          interferenceInLife: item.isInterference,
          notes: item.notes,
          selectedImportance: item.status,
          substanceUse: {
            currentFrequencyOfUse: item.tutorial.frequency,
            howLongUsingThisLevel: item.tutorial.duration,
            lastUse: item.tutorial.lastUse,
            methodOfUse: item.tutorial.method,
            unitOfUse: item.tutorial.dose.unit,
            dose: item.tutorial.dose.amount,
            refillable: item.tutorial.refillable,
            refillTimes: item.tutorial.refillTimes.amount,
          },
        },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: (errorMsg?: string) => {
          if (!errorMsg) {
            onCloseDrawer();
          }
        },
      }),
    );
  };

  return (
    <>
      <SubstanceUseDetail
        open={!!selectedItem && !isEditing}
        onClose={onCloseDrawer}
        onClickNewReport={onClickNewReport}
        item={selectedItem}
        lookupData={lookupData}
      />
      <SubstanceUseEditing
        isOpen={!!selectedItem && isEditing}
        onClose={onCloseDrawer}
        item={selectedItem}
        error={errorApi}
        isSaving={isSaving}
        lookupData={lookupData}
        onSubmit={onUpdateItem}
      />
      <AddNewReport
        isOpen={showAddItem}
        onClose={onCloseDrawer}
        options={domainOptions}
        lookupData={lookupData}
        onSubmit={onSubmitNewReport}
      />
      <div className={classes.headingWithBtnWrapper}>
        <div className={classes.noteHeaderLeftSide}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Substance use
          </Heading>
          <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.totalBadge}>
            {domainData?.relatedElements.length || 0} total
          </Badge>
        </div>
        {domainData?.relatedElements.length > 0 && (
          <IconButton
            className={classes.addBtn}
            icon="plus"
            variant={iconBtnType.PRIMARY}
            onClick={onAddNewSubstanceUse}
          >
            Add substance
          </IconButton>
        )}
      </div>
      <div className={clsx(classes.noteCardWrapper)}>
        {isLoading ? (
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        ) : (
          <>
            {domainData?.relatedElements.length > 0 ? (
              <>
                {domainData.relatedElements.map((item, idx) => (
                  <SubstanceUseCard
                    key={idx}
                    item={item}
                    onClick={onClickItem}
                    showNotes={false}
                    lookupData={lookupData}
                  />
                ))}
              </>
            ) : (
              <PriorityEmpty
                title={`No Substance Use reported for ${selectedPatient?.member.nickName} yet.`}
                // eslint-disable-next-line max-len
                description="If you've spoken with them about their substance use, make sure you save that information here."
                addText="Add substance"
                onAdd={onAddNewSubstanceUse}
                glyph="wine"
                glyphOrientation="portrait"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export { SubstanceUse };
