import { IInfoCardProps } from '../info-card/InfoCard.types';

export const PrioritiesCard = {
  History: 'history',
  PastAppointment: 'pastAppointments',
  Chatbots: 'chatbots',
  Groups: 'groups',
  SocialDeterminants: 'socialDeterminants',
  LifeEvents: 'lifeEvents',
  Symptoms: 'symptoms',
  Diagnoses: 'diagnoses',
  SideEffects: 'sideEffects',
  Medications: 'medications',
  SubstanceUse: 'substanceUse',
  Notes: 'notes',
  PlanItems: 'planItems',
  EnvironmentalFactors: 'environmental-factor',
  SymptomaticFactors: 'symptomatic-factor',
  CognitiveIssues: 'cognitive-issues',
};

export const priorityListCard: IInfoCardProps[] = [
  {
    title: 'History',
    type: PrioritiesCard.History,
    isCompleted: true,
    icon: 'history-outlined',
  },
  {
    title: 'Past appointments',
    type: PrioritiesCard.PastAppointment,
    isCompleted: false,
    icon: 'event-outlined',
  },
  {
    title: 'Chatbots',
    type: PrioritiesCard.Chatbots,
    isCompleted: false,
    icon: 'chat-2',
  },
  {
    title: 'Groups',
    type: PrioritiesCard.Groups,
    isCompleted: false,
    icon: 'members-outlined',
  },
  {
    title: 'Notes',
    type: PrioritiesCard.Notes,
    isCompleted: false,
    icon: 'document-filled',
  },
  {
    title: 'Plan Items',
    type: PrioritiesCard.PlanItems,
    isCompleted: false,
    icon: 'list2',
  },
  {
    title: 'Social determinants',
    type: PrioritiesCard.SocialDeterminants,
    isCompleted: false,
    icon: 'stack',
  },
  {
    title: 'Life events',
    type: PrioritiesCard.LifeEvents,
    isCompleted: false,
    icon: 'user-star',
  },
  {
    title: 'Symptoms',
    type: PrioritiesCard.Symptoms,
    isCompleted: false,
    icon: 'symptom',
  },
  {
    title: 'Diagnoses',
    type: PrioritiesCard.Diagnoses,
    isCompleted: false,
    icon: 'diagnoses',
  },
  {
    title: 'Medications',
    type: PrioritiesCard.Medications,
    isCompleted: false,
    icon: 'medication-2',
  },
  {
    title: 'Side effects',
    type: PrioritiesCard.SideEffects,
    isCompleted: false,
    icon: 'question-outlined',
  },
  {
    title: 'Substance use',
    type: PrioritiesCard.SubstanceUse,
    isCompleted: false,
    icon: 'pill-outlined',
  },
];

export const priorityList = [
  'Symptoms',
  'Substance Use',
  'Diagnoses',
  'Medications',
  'Side Effects',
  'Social Determinants',
  'Life Events',
];

export const disableSectionsInDetailView = [
  PrioritiesCard.PastAppointment,
  PrioritiesCard.Chatbots,
  PrioritiesCard.Groups,
];
