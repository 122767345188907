import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';

import { ReportCollapseItems } from '../report-items-collapse';
import { ReportItem } from '../AddNewReport.types';

import { useStyles } from './StepOne.styles';

type Props = {
  options: ReportItem[];
  selectedItems: string[];
  searchLabel: string;
  setSelectedItems: (symptom: string[]) => void;
};

const StepOne: FC<Props> = ({ selectedItems, setSelectedItems, options, searchLabel }) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState('');
  const [itemsFiltered, setItemsFiltered] = useState(options);
  const total = options.length * 4;
  useEffect(() => {
    const newItems = options.reduce((arr, currentItem) => {
      const items = currentItem.items.filter(i => i.label.toLowerCase().includes(searchKey.toLowerCase()));
      if (items.length > 0) {
        return [...arr, { ...currentItem, items }];
      }
      return arr;
    }, []);
    setItemsFiltered(newItems);
  }, [searchKey, options]);

  return (
    <Box className={classes.container}>
      <Box className={classes.searchBox}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text className={classes.label}>{searchLabel}</Text>
          <Text className={classes.count}>{`${selectedItems.length} of ${total} selected`}</Text>
        </Box>
        <Input
          className={classes.searchInput}
          variant={inputType.SEARCH}
          placeholder="Search"
          value={searchKey}
          onChange={setSearchKey}
        />
      </Box>
      <Box className={classes.sections}>
        {itemsFiltered.length > 0 ? (
          itemsFiltered.map((section, idx) => (
            <ReportCollapseItems
              items={section.items}
              selectedItems={selectedItems}
              open={idx === 0}
              onChange={setSelectedItems}
              key={section.label}
              label={section.label}
            />
          ))
        ) : (
          <Box display="flex" justifyContent="center">
            <Text className={classes.noData}>No data available</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StepOne;
