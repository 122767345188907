import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  headerSubtitle: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
    margin: 0,
    color: colors.neutral600,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  duplicateBtn: {
    backgroundColor: colors.primary50,
    color: colors.primary500,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  content: {
    flex: 1,
    padding: 40,
    display: 'flex',
    height: 'calc(100vh - 160px)',
    overflowY: 'auto',
    backgroundColor: colors.neutral25,
    flexDirection: 'column',
    gap: 40,
  },
});
