import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    padding: 250,
  },
  alfieImage: {
    height: 100,
    width: 100,
  },
  errorText: {
    fontWeight: 700,
  },
});
