import { AxiosResponse } from 'axios';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { baseRequest } from 'utils/HttpClient';

export const fetchCaqhCredentials = (pathParams: { userAccountId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.FETCH_CAQH_CREDENTIALS, null, pathParams, null);
};

export const postCaqhCredentials = (bodyRequest): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.POST_CAQH_CREDENTIALS, bodyRequest, null, null);
};

export const caqhDataSummary = (pathParams: { type: string; providerId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.CAQH_DATA_SUMMARY, null, pathParams, null);
};

export const extractCredentialingData = (pathParams: { userAccountId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.EXTRACT_CREDENTIALING_DATA, {}, pathParams, null);
};

export const fetchNavItemStatus = (pathParams: { providerId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_NAV_ITEM_STATUS, {}, pathParams, null);
};

export const putProviderData = (sectionType, bodyRequest): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.PUT_PROVIDER_DATA, bodyRequest, { sectionType }, null);
};

export const uploadDocumentData = (bodyRequest): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPLOAD_DOCUMENT_DATA, bodyRequest, null, null);
};

export const deleteCredentialingDocument = (bodyRequest): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.DELETE_CREDENTIALING_DOCUMENT, bodyRequest, null, null);
};

export const fetchAvailableStates = (pathParams: { userAccountId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_AVAILABLE_STATES, null, pathParams, null);
};

export const requestPayerSubmission = (bodyRequest, userAccountId): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.REQUEST_PAYER_SUBMISSION, bodyRequest, { userAccountId }, null);
};
