import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.neutral50,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  formContent: {
    flex: 1,
    display: 'flex',
    padding: 32,
    overflowY: 'auto',
    flexDirection: 'column',
    gap: 24,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    backgroundColor: colors.white,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
    },
  },
});
