import { ApiEndpoints } from 'constants/ApiEndpoints';
import { baseRequest } from 'utils/HttpClient';

export const noteTimeStampForSessionEvaluation = (
  pathParams: {
    trembitSessionId: string;
    evaluationId: string;
    event: string;
  },
  queryParams: {
    capturedAt: number;
  },
) => {
  return baseRequest(ApiEndpoints.TREMBIT_SESSION_EVALUATION_TIMESTAMP, null, pathParams, queryParams);
};

export const noteTimeStampForEvaluationContentBlock = (
  pathParams: {
    trembitSessionId: string;
    evaluationId: string;
    cbId: string;
    event: string;
  },
  queryParams: {
    capturedAt: number;
  },
) => {
  return baseRequest(ApiEndpoints.TREMBIT_SESSION_CONTENTBLOCK_TIMESTAMP, null, pathParams, queryParams);
};
