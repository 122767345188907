import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  errorText: {
    color: colors.destructive500,
  },
  memberColumn: {
    textDecoration: 'none',
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  table: {
    backgroundColor: colors.white,
    td: {
      overflow: 'hidden',
      maxWidth: 200,
    },
  },
  id: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral900,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  name: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  totalBadge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addBtn: {
    color: '#fff',
    fontSize: 16,
    whiteSpace: 'nowrap',
    '& svg': {
      width: 20,
      marginRight: 4,
      '& path': {
        fill: '#fff',
      },
    },
  },
});
