import { makeStyles } from '@mui/styles';
// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  notification: {
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 32,
    fontWeight: 500,
    height: 72,
    fontSize: 14,
    backgroundColor: colors.white,
    borderRadius: 8,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  notificationRead: {
    border: `1px solid ${colors.neutral100}`,
    backgroundColor: colors.neutral25,
    boxShadow: 'none',
  },
  iconWrap: {
    display: 'flex',
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: 21,
      height: 21,
    },
  },
  avtGroup: {
    '& > div': {
      zIndex: 1000,
      backgroundColor: colors.neutral100,
      color: colors.neutral500,
      fontSize: 14,
      marginLeft: '0 !important',
    },
  },
  avtItem: {
    marginRight: -8,
    border: `1px solid ${colors.white}`,
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: colors.white,
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    gap: 16,
  },
  text: {
    flex: 1,
    color: colors.neutral900,
    lineHeight: '20px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    lineClamp: 2,
    textOverflow: 'ellipsis',
    width: '100%',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 32,
  },
  time: {
    display: 'block',
    lineHeight: '20px',
    color: colors.neutral600,
  },
  badge: {
    backgroundColor: colors.secondary500,
    color: colors.white,
    fontWeight: 600,
    borderRadius: 20,
  },
});
