import { FC, useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuth } from 'redux/modules/auth/selectors';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SocketListener from 'utils/SocketListener';
import SocketNotificationTypes from 'utils/SocketNotificationTypes';
import { useStyles } from './InAppNotification.styles';

export interface State extends SnackbarOrigin {
  open: boolean;
  notificationData: any;
}

const InAppNotification: FC = () => {
  const classes = useStyles();
  const [state, setState] = useState<State>({
    open: true,
    vertical: 'top',
    horizontal: 'right',
    notificationData: {},
  });

  const { vertical, horizontal } = state;

  const { inAppNotification } = useSelector((storeState: any) => storeState.socket);
  const { isAuthenticated } = useSelector(getAuth);
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="primary" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const handleClick = () => {
    let clickListener: any;
    switch (state.notificationData.notificationType) {
      case SocketNotificationTypes.chatMessageReceived:
      case SocketNotificationTypes.groupMessageReceived:
        clickListener = SocketListener.createMessageNoitificationClickListener(state.notificationData);
        if (clickListener !== null) {
          clickListener();
        }
        break;
      case SocketNotificationTypes.appointmentRequested:
        clickListener = SocketListener.createAppointmentRequestedNotificationListener(state.notificationData);
        clickListener();
        break;
      case SocketNotificationTypes.appointmentNeedsAction:
        clickListener = SocketListener.createAppointmentNeedsActionNotificationListener(
          state.notificationData,
        );
        clickListener();
        break;
      case SocketNotificationTypes.appointmentConfirmed:
        clickListener = SocketListener.createAppointmentConfirmedNotificationListener(state.notificationData);
        clickListener();
        break;
      case SocketNotificationTypes.appointmentCancelled:
        clickListener = SocketListener.createAppointmentCancelledNotificationListener(state.notificationData);
        clickListener();
        break;
      default:
        break;
    }
    handleClose();
  };

  useEffect(() => {
    if (inAppNotification) {
      setState({ ...state, notificationData: inAppNotification.data, open: inAppNotification.open });
    }
  }, [inAppNotification]);

  function truncate(str, n) {
    return str.length > n ? `${str.slice(0, n - 1)}...` : str;
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isAuthenticated && state.open}
        key={vertical + horizontal}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <div className={classes.root}>
          <div className={classes.message} onClick={handleClick}>
            <div className={classes.title}>
              {state.notificationData?.title ? truncate(state.notificationData.title, 30) : ''}
            </div>
            <div className={classes.subtitle}>
              {state.notificationData?.subtitle ? truncate(state.notificationData.subtitle, 30) : ''}
            </div>
          </div>
          {action}
        </div>
      </Snackbar>
    </div>
  );
};

export { InAppNotification };
