import React, { FC, useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import cx from 'clsx';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Question } from './Questions';
import { EvaluationItemNode } from '../../../../../redux/modules/conversation/types';
import { EvaluationHelper } from '../../../../../utils/EvaluationHelper';
import { useStyles } from './EvaluationSectionCollapse.styles';

type Props = {
  item: EvaluationItemNode;
  currentBlock: EvaluationItemNode;
  listHelper: EvaluationHelper;
  handleChange: (number: number) => void;
  setCurrentBlock: (block: EvaluationItemNode) => void;
  dctProgress: number;
  scrollRef: React.RefObject<HTMLDivElement>;
};

const EvaluationSectionCollapse: FC<Props> = ({
  setCurrentBlock,
  currentBlock,
  item,
  dctProgress,
  scrollRef,
}) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setCurrentBlock(item);
  };

  useEffect(() => {
    if (currentBlock) {
      const found = item?.children.find(node => node.cbId === currentBlock.cbId);
      if (found) setCollapsed(!!found);
    }
  }, [currentBlock]);

  return (
    <Box
      className={cx({
        [classes.selectedQuestion]: currentBlock?.cbId === item.cbId,
      })}
    >
      <Box
        onClick={item?.isAttemptable ? toggleCollapsed : null}
        className={cx(
          classes.collapseHeaderBox,
          item.fullPage && dctProgress !== 100 && item.isAttemptable && classes.ml2,
          !item?.isAttemptable && classes.noPointer,
        )}
      >
        <Box className={classes.collapseTitle}>
          {item?.isAttemptable && !item.fullPage ? (
            <Icons
              glyph="chevron-down"
              color={colors.neutral900}
              className={collapsed ? classes.invertIcon : classes.baseIconClass}
            />
          ) : item.fullPage && dctProgress === 100 ? (
            <Icons className={classes.dctCheckIcon} glyph="checkmark" color={colors.success500} />
          ) : (
            !item.isAttemptable && (
              <Box
                style={{
                  display: 'flex',
                  minWidth: item.fullPage ? '24px' : '20px',
                  justifyContent: 'center',
                }}
              >
                <Icons className={classes.minusIcon} glyph="minus" color={colors.destructive600} />
              </Box>
            )
          )}
          <Text className={classes.dctItem}>{item.text}</Text>
        </Box>
        {item?.isAttemptable && (
          <Text
            weight={fontWeight.BOLD}
            level={textLevel.S}
            color={collapsed ? colors.primary600 : colors.neutral500}
          >
            {dctProgress}%
          </Text>
        )}
      </Box>
      {item.cbType === 'dct' &&
        !item.fullPage &&
        item.children.map((childItem, childIndex) => (
          <Collapse in={!item?.isAttemptable ? false : collapsed} key={childIndex}>
            <Question
              item={childItem}
              currentBlock={currentBlock}
              setCurrentBlock={setCurrentBlock}
              scrollRef={scrollRef}
            />
          </Collapse>
        ))}
    </Box>
  );
};

export { EvaluationSectionCollapse };
