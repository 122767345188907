import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    borderRadius: 8,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  title: {
    color: colors.neutral900,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 8,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  data: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 16px',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
    '&:hover': {
      backgroundColor: colors.primary25,
    },
  },
  text: {
    color: colors.neutral900,
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    display: 'block',
  },
  icon: {
    minWidth: 'auto',
    '& svg': {
      width: 14,
      height: 14,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
});
