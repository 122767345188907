import React, { FC } from 'react';

// components
import { Box, Divider } from '@mui/material';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';

import { IFlowChoice } from '../flow-card/FlowCard.types';
import { useStyles } from './FlowChoice.styles';

type Props = {
  item: IFlowChoice;
  options: any;
  isCurrentElementExisting: boolean;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>, filedName: string) => void;
  handleChangeSelect: (value: string) => void;
  onDuplicateChoice: () => void;
  onDeleteChoice: () => void;
};

const FlowChoice: FC<Props> = ({
  item,
  options,
  isCurrentElementExisting,
  handleChange,
  handleChangeSelect,
  onDuplicateChoice,
  onDeleteChoice,
}) => {
  const classes = useStyles();

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Box className={classes.card}>
      <Box display="flex" padding={2}>
        <Icons glyph="drag-arrows" className={classes.icon} />
        <Box display="flex" alignItems="center" gap={3} flex={1}>
          <Box className={classes.section} flex={3}>
            {renderLabel('Choice')}
            <Input
              fullWidth
              name="choice"
              size={inputSize.M}
              value={item?.choice}
              placeholder="Enter name"
              onChange={e => handleChange(e, 'choice')}
              className={classes.input}
            />
          </Box>
          <Box className={classes.section} flex={1}>
            {renderLabel('Score')}
            <Input
              fullWidth
              name="score"
              type="number"
              size={inputSize.M}
              value={item.score}
              placeholder="Enter score"
              onChange={e => handleChange(e, 'score')}
              className={classes.input}
            />
          </Box>
        </Box>
      </Box>
      <Divider variant="fullWidth" className={classes.divider} />
      <Box ml="38px" padding={2}>
        {isCurrentElementExisting && (
          <Box className={classes.section}>
            {renderLabel('Profile element value')}
            <Select
              name="mapsToProfileElementValue"
              value={item.value}
              variant={selectType.SECONDARY}
              options={options}
              displayEmpty
              emptyText="Select item"
              // className={classes.input}
              onChange={e => handleChangeSelect(e.target.value)}
            />
          </Box>
        )}
        <Box display="flex" gap={3} mt={3}>
          <Box>
            <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.MEDIUM}>
              <Checkbox
                checked={item.riskFactor}
                onChange={e => handleChange(e, 'riskFactor')}
                name="riskFactor"
                className={classes.checkbox}
              />
              Risk Factor
            </Heading>
          </Box>
          <IconButton icon="copy-outlined" className={classes.copyBtn} onClick={onDuplicateChoice}>
            <Box ml={1.3}>Duplicate</Box>
          </IconButton>
          <IconButton icon="delete-outlined-2" className={classes.deleteBtn} onClick={onDeleteChoice}>
            <Box ml={1.3}>Remove</Box>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export { FlowChoice };
