import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { colors } from '@confidant-health/lib/colors';
import { DROPDOWN_FILTER_COLLAPSABLE_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';
import dayjs from 'utils/dayjs';
import { sentenceCase } from 'sentence-case';

import { reportingActionCreators } from 'redux/modules/reporting';
import { selectInvoice, selectInvoiceList } from 'redux/modules/reporting/selectors';
import { IInvoiceRecord } from 'redux/modules/reporting/types';
import { AppState } from 'redux/store/types';

import { BaseLayout } from 'layouts/base';
import { profileActionCreators } from 'redux/modules/profile';
import { Icons } from '@confidant-health/lib/icons';

import InvoiceDetailDrawer from './invoices-detail-drawer';
import { tableColumns, multiSelectFilterOptionsMock } from './Invoices.constants';
import { useStyles } from './Invoices.styles';
import EditInvoice from '../claim-detail/components/EditInvoice';

const invoiceStatuses = ['PAID', 'ON_HOLD', 'CANCELLED', 'PENDING', 'WRITE_OFF', 'PAYMENT_FAILED'];

interface Item {
  label: string;
  onClick: () => void;
}

const InvoiceList: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: {
      searchKey: '',
      filter: [],
      dateFilter: {
        startDate: '',
        endDate: '',
      },
    },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });
  const [filterChangeLoading, setFilterChangeLoading] = useState(false);
  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(false);
  const [filterOptionsMock, setFilterOptionsMock] = useState([]);
  const [selectedFilter] = useState<string>('All');
  const [selectedItem, setSelectedItem] = useState(null);
  const [invoiceList, setInvoiceList] = useState([]);
  const providersList = useSelector((state: AppState) => state.profile.providers);
  const { isLoading, invoicesList = [], additionalMetaData, totalRecords } = useSelector(selectInvoiceList);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openInvoiceDetailDrawer, setOpenInvoiceDetailDrawer] = useState(false);
  const currentInvoiceReduxState = useSelector(selectInvoice);
  const onExportClick = () => {
    console.log('onExportClick');
  };

  const getMultiSelectFilterForStatus = () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter || {};
    const statuses = [];
    if (multiselectFilters.Status) {
      multiselectFilters.Status.forEach(filter => {
        switch (filter) {
          case 'Paid':
            statuses.push('PAID');
            break;
          case 'On Hold':
            statuses.push('ON_HOLD');
            break;
          case 'Canceled':
            statuses.push('CANCELED');
            break;
          case 'Pending':
            statuses.push('PENDING');
            break;
          case 'Write Off':
            statuses.push('WRITE_OFF');
            break;
          case 'Payment Failed':
            statuses.push('PAYMENT_FAILED');
            break;
          default:
            statuses.push(filter.toUpperCase().replaceAll(' ', '_'));
            break;
        }
      });
    }

    return statuses;
  };

  const handleFetchInvoicesFormat = (tableParam: tableParamsType) => {
    const multiselectFilters = tableParam.search?.multiSelectFilter;
    const statuses = getMultiSelectFilterForStatus();
    const states = multiselectFilters?.State ?? [];
    const roles = multiselectFilters?.Role ?? [];
    const selectedProviders = multiselectFilters?.[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.map(
      provider => {
        return provider.id;
      },
    );

    return {
      pageSize: tableParam?.pagination.rowsPerPage,
      pageNumber: tableParam?.pagination.currentPage,
      sortBy: 'createdAt',
      sortOrder: tableParam?.sorter.direction,
      body: {
        searchQuery: tableParam?.search.searchKey,
        startDate: tableParam?.search.dateFilter?.startDate
          ? dayjs(tableParam?.search.dateFilter?.startDate)?.toISOString()
          : null,
        endDate: tableParam?.search.dateFilter?.endDate
          ? dayjs(tableParam?.search.dateFilter?.endDate)?.toISOString()
          : null,
        statuses,
        states,
        roles,
        providerIds: selectedProviders,

        // states: getFilterForState(),
      },
    };
  };

  const onRowClick = (data: IInvoiceRecord) => {
    setSelectedItem(data);
    setOpenInvoiceDetailDrawer(true);
  };
  useEffect(() => {
    dispatch(reportingActionCreators.fetchInvoices(handleFetchInvoicesFormat(tableParams)));
  }, [tableParams, selectedFilter]);

  useEffect(() => {
    const formatInvoices = invoicesList?.map((invoice: IInvoiceRecord) => ({
      ...invoice,
      totalAmount: Number.isInteger(invoice?.totalAmount)
        ? invoice?.totalAmount
        : `${invoice?.totalAmount?.toFixed(2) || 0}`,
      actions: {
        ...invoice,
        totalAmount: Number.isInteger(invoice?.totalAmount)
          ? invoice?.totalAmount
          : `${invoice?.totalAmount?.toFixed(2) || 0}`,
      },
    }));

    setInvoiceList(formatInvoices);
  }, [invoicesList]);

  const updateInvoiceStatus = (data: IInvoiceRecord, updatedStatus: string) => {
    dispatch(
      reportingActionCreators.updateInvoiceStatusById({
        requestBody: { status: updatedStatus },
        id: data?._id,
        filters: handleFetchInvoicesFormat(tableParams),
      }),
    );
  };

  /**
   * Generates a list of items for updating an invoice status, based on the current status of the invoice.
   * @param data The invoice record for which to generate the status options
   * @returns An array of items, each representing an available status option
   */
  const generateStatusOptions = (data: IInvoiceRecord) => {
    const items: Item[] = invoiceStatuses
      .map(status => {
        if (data?.status === status || data?.status === 'PAID') {
          return null;
        }
        return {
          label: `Mark as ${status?.replaceAll('_', ' ')?.toLocaleLowerCase()}`,
          onClick: () => updateInvoiceStatus(data, status),
        };
      })
      .filter(Boolean) as Item[];

    return items;
  };

  const renderColumns = () => {
    return tableColumns.map(column => {
      if (column.id === 'invoiceDate') {
        return {
          ...column,
          renderCell: (invoiceDate: string) => (
            <div className={classes.timeWrap}>
              <div className={classes.date}>{dayjs(invoiceDate).format('MM/DD/YYYY')}</div>
              <div className={classes.time}>{dayjs(invoiceDate).format('h:mm A')}</div>
            </div>
          ),
        };
      }
      if (column.id === 'member') {
        return {
          ...column,
          renderCell: ({ firstName, lastName, fullName, profileImage, id, uuid }) => (
            <ProfileInfo
              type="member"
              photo={profileImage}
              nickName={
                firstName && lastName ? `${firstName || ''} ${lastName || ''}` : fullName ?? 'No Name'
              }
              fullName={uuid}
              memberId={id}
            />
          ),
        };
      }
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ firstName, lastName, profileImage, designation, id }) => (
            <ProfileInfo
              type="provider"
              photo={profileImage}
              role={designation}
              fullName={`${firstName} ${lastName}`}
              memberId={id}
            />
          ),
        };
      }
      /* TO DO
        Change totalAmount based on amount rendering: styleguide is done.
      */
      // if (column.id === 'amount') {
      //   return {
      //     ...column,
      //     renderCell: ({ amountPaid, increased, changed }) => (
      //       <Box display="flex">
      //         {changed ? (
      //           <Box display="flex" justifyContent="center" alignItems="center">
      //             <Badge
      //               variant={badgeType.OUTLINED}
      //               style={increased ? badgeStyle.RESOLVED : badgeStyle.HIGH}
      //               className={clsx(classes.badge, { [classes.negativeBadge]: !increased })}
      //             >
      //               <Icons
      //                 glyph="arrow-left"
      //                 className={clsx(increased ? classes.increment : classes.incrementNegative)}
      //               />
      //             </Badge>
      //             <Box marginLeft={1}>${amountPaid}</Box>
      //           </Box>
      //         ) : (
      //           <span>${amountPaid}</span>
      //         )}
      //       </Box>
      //     ),
      //   };
      // }
      if (column.id === 'totalAmount') {
        return {
          ...column,
          renderCell: (val: any) => {
            return <Box>${val}</Box>;
          },
        };
      }
      if (column.id === 'state') {
        return {
          ...column,
          renderCell: (state: any) => {
            return <Box>{state?.name || 'N/A'}</Box>;
          },
        };
      }
      if (column.id === 'status') {
        return {
          ...column,
          renderCell: (val: any) => {
            return (
              <Badge
                variant={badgeType.OUTLINED}
                style={
                  val === 'PAID'
                    ? badgeStyle.RESOLVED
                    : val === 'PENDING' || val === 'COMPLETED'
                    ? badgeStyle.PRIMARY
                    : badgeStyle.HIGH
                }
                className={clsx(
                  classes.status,
                  { [classes.unpaidStatus]: val !== 'PAID' },
                  { [classes.submittedStatus]: val === 'PENDING' || val === 'COMPLETED' },
                )}
              >
                {sentenceCase(val || '')}
              </Badge>
            );
          },
        };
      }
      if (column.id === 'dueDate') {
        return {
          ...column,
          renderCell: (dueDate: string) => (
            <div className={classes.timeWrap}>
              <div className={classes.date}>{dayjs(dueDate).format('MM/DD/YYYY')}</div>
              <div className={classes.time}>{dayjs(dueDate).format('h:mm A')}</div>
            </div>
          ),
        };
      }
      if (column.id === 'sessionDate') {
        return {
          ...column,
          renderCell: (sessionDate: string) => (
            <div className={classes.timeWrap}>
              <div className={classes.date}>{dayjs(sessionDate).format('MM/DD/YYYY')}</div>
              <div className={classes.time}>{dayjs(sessionDate).format('h:mm A')}</div>
            </div>
          ),
        };
      }
      // TODO: Will implement daysPastDue in future ticket

      // if (column.id === 'daysPastDue') {
      //   return {
      //     ...column,
      //     renderCell: (dueDate: string) => (
      //       <div className={classes.timeWrap}>
      //         <div className={classes.date}>{dayjs(dueDate).format('MMMM D, YYYY')}</div>
      //         <div className={classes.time}>{dayjs(dueDate).format('h:mm A')}</div>
      //       </div>
      //     ),
      //   };
      // }
      // if (column.id === 'sessionType') {
      //   return {
      //     ...column,
      //     renderCell: (sessionType: string) => (
      //       <div className={classes.timeWrap}>
      //         <div className={classes.date}>{sessionType || 'N/A'}</div>
      //       </div>
      //     ),
      //   };
      // }
      if (column.id === 'reason') {
        return {
          ...column,
          renderCell: (reason: string) => (
            <div className={classes.timeWrap}>
              <div className={classes.reasonText}>{reason || 'N/A'}</div>
            </div>
          ),
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: data => (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={[
                {
                  label: 'View Details',
                  onClick: () => {
                    setSelectedItem(data);
                    setOpenInvoiceDetailDrawer(true);
                  },
                },
                ...generateStatusOptions(data),
                {
                  label: 'Request payment',
                  onClick: () => {
                    updateInvoiceStatus(data, 'PENDING');
                  },
                },
                data?.status !== 'PAID' && {
                  label: 'Edit Invoice',
                  onClick: () => {
                    setOpenInvoiceDetailDrawer(false);
                    dispatch(reportingActionCreators.fetchInvoiceById({ id: data?._id }));
                    setTimeout(() => {
                      setSelectedItem(data);
                      setOpenInvoice(true);
                    }, 1000);
                  },
                },
              ].filter(Boolean)}
            />
          ),
        };
      }
      return column;
    });
  };

  const onCloseDrawer = () => {
    setSelectedItem(null);
    setOpenInvoiceDetailDrawer(false);
  };
  const renderStatusTotal = (status: string, value: number, badge: string) => (
    <Box
      padding={2}
      sx={{
        background: colors.white,
        flex: '1 1 0',
        boxShadow:
          '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '8px',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Text className={classes.label}>{status.toUpperCase()}</Text>
        <Badge
          className={clsx(
            classes.statusLabel,
            { [classes.unpaidStatus]: badge === 'Write off' },
            { [classes.submittedStatus]: badge === 'Pending' },
          )}
          variant={badgeType.OUTLINED}
          style={
            badge === 'Paid'
              ? badgeStyle.RESOLVED
              : badge === 'Pending'
              ? badgeStyle.PRIMARY
              : badgeStyle.HIGH
          }
        >
          {badge}
        </Badge>
      </Box>
      <Text className={classes.statusFee}>${Number.isInteger(value) ? value : value.toFixed(2)}</Text>
    </Box>
  );

  useEffect(() => {
    setFilterOptionsMock(multiSelectFilterOptionsMock(providersList));
    setResetMultiSelectFilter(true);
  }, [providersList]);

  useEffect(() => {
    setResetMultiSelectFilter(false);
  }, [tableParams]);

  useEffect(() => {
    setFilterChangeLoading(true);
    setTimeout(() => {
      setFilterChangeLoading(false);
    }, 100);
  }, [filterOptionsMock]);

  useEffect(() => {
    if (providersList?.length <= 0) dispatch(profileActionCreators.fetchAllProviders());
  }, []);

  return (
    <BaseLayout>
      {openInvoiceDetailDrawer && (
        <InvoiceDetailDrawer
          open={openInvoiceDetailDrawer}
          onClose={onCloseDrawer}
          invoice={selectedItem}
          updateInvoiceStatus={(data, status) => updateInvoiceStatus(data, status)}
        />
      )}
      <Box className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Invoices
            </Heading>
            <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
              {totalRecords || '0'} total
            </Badge>
          </Stack>
        </Stack>
        {openInvoice && !currentInvoiceReduxState?.errorMsg && currentInvoiceReduxState?.data && (
          <EditInvoice
            open={openInvoice}
            onClose={() => setOpenInvoice(false)}
            data={currentInvoiceReduxState?.data || selectedItem}
          />
        )}
        <Box display="flex" flexDirection="column" mb={4} gap={0}>
          <Box display="flex" mb={2} gap={2}>
            {renderStatusTotal('Total Pending', additionalMetaData?.totalPending || 0, 'Pending')}
            {renderStatusTotal('Total Paid', additionalMetaData?.totalPaid || 0, 'Paid')}
            {renderStatusTotal('Total Write off', additionalMetaData?.totalWriteOff || 0, 'Write Off')}
          </Box>
        </Box>
        <div className={classes.invoiceList}>
          {filterChangeLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <Table
              searchProps={{
                placeholder: 'Search invoices by member and provider',
                filterProps: {
                  variant: Filter.tableFilterType.MULTIPLE,
                  multiSelectOptions: multiSelectFilterOptionsMock(providersList),
                  dateFilter: {
                    startDate: tableParams?.search?.dateFilter?.startDate,
                    endDate: tableParams?.search?.dateFilter?.endDate,
                  },
                },
                resetMultiSelectFilter,
                exportProps: {
                  btnTitle: 'Export',
                  isLoadingExport: false,
                },
              }}
              onClickExport={onExportClick}
              gridProps={{
                columns: renderColumns(),
                data: invoiceList || [],
                isLoading,
                onRowClick,
              }}
              paginationProps={{
                currentRows: invoiceList?.length || 0,
                totalCount: totalRecords || 0,
                showRowsPerPage: true,
              }}
              value={tableParams}
              onChange={setTableParams}
            />
          )}
        </div>
      </Box>
    </BaseLayout>
  );
};

export { InvoiceList };
