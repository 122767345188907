import React, { FC, useState } from 'react';
import { Box, ButtonGroup } from '@mui/material';
import { Drawer } from '@confidant-health/lib/ui/organisms/drawer';
import cx from 'clsx';

import { iconBtnStyle, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Heading, Text, headingLevel, fontWeight } from '@confidant-health/lib/ui/atoms/typography';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';

import { useStyles } from './CompleteNote.styles';
import SignOffModal from '../signoff-modal';
import { ChecklistMock, FilterSessions, FilterSessionsMock } from './CompleteNote.constants';
import ChecklistCard from '../checklist-card';

type Props = {
  open: boolean;
  onClose: () => void;
};

const CompleteNote: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const [showSignOff, setShowSignOff] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [selectedSession, setSelectedSession] = useState(FilterSessions[0].type);
  const [selectedSessionIndex, setSelectedSessionIndex] = useState(FilterSessionsMock[0].type);

  const onSignOffClick = () => {
    setShowSignOff(true);
  };

  const onCloseSignOffClick = () => {
    setShowSignOff(false);
  };

  const onSubmitSignOffClick = () => {
    if (!isRequesting) {
      setIsRequesting(true);
      setTimeout(() => {
        setIsRequesting(false);
        setShowSignOff(false);
      }, 3000);
    }
  };

  const onChangeSession = (session: string) => () => {
    setSelectedSession(session);
  };

  const onChangeSessionIndex = (session: string) => () => {
    setSelectedSessionIndex(session);
  };

  return (
    <>
      <SignOffModal
        open={showSignOff}
        onClose={onCloseSignOffClick}
        isLoading={isRequesting}
        onSubmit={onSubmitSignOffClick}
      />
      <Drawer open={open} onClose={onClose} className={classes.drawer} paperClassName={classes.paperDrawer}>
        <Box className={classes.drawHead}>
          <Box display="flex" alignItems="center" gap="24px">
            <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtn} />
            <Box>
              <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.headTitle}>
                Session name
              </Heading>
              <Text className={classes.headSubtitle} weight={fontWeight.SEMI_BOLD}>
                Session ended on March 21, 2:36 pm
              </Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap="40px">
            <Box display="flex" alignItems="center" gap="12px">
              <Box className={classes.avtWrap}>
                <Avatar src="" name="Olivia" size={40} variant={avatarType.CIRCLE} />
                <Box
                  className={cx(classes.avtStatus, {
                    [classes.avtStatusActive]: true,
                  })}
                />
              </Box>
              <Box>
                <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.username}>
                  Olivia
                </Heading>
                <Text className={classes.fullname}>Olivia Rhye</Text>
              </Box>
            </Box>
            <IconButton
              icon="pencil"
              onClick={onSignOffClick}
              style={iconBtnStyle.PRIMARY}
              className={classes.signoffBtn}
            >
              Sign off
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.filters}>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {FilterSessions.map(({ type, label }) => (
              <Button
                key={type}
                className={cx(classes.btnGroupIcon, {
                  [classes.btnGroupIconActive]: type === selectedSession,
                })}
                onClick={onChangeSession(type)}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {FilterSessionsMock.map(({ type, label, total, amount }) => (
              <Button
                key={type}
                className={cx(classes.btnGroupIcon, {
                  [classes.btnGroupIconActive]: type === selectedSessionIndex,
                })}
                onClick={onChangeSessionIndex(type)}
              >
                {label}
                <Badge
                  variant={badgeType.OUTLINED}
                  style={badgeStyle.MEDIUM}
                  className={cx(classes.badge, {
                    [classes.badgeActive]: type === selectedSessionIndex,
                  })}
                >
                  {`${amount}/${total}`}
                </Badge>
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box className={classes.drawBody}>
          <Box className={classes.checklist}>
            <Box sx={{ mb: 3 }}>
              <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.title}>
                Checklist
              </Heading>
              <Text className={classes.subtitle} weight={fontWeight.MEDIUM}>
                Here is what you supposed to do during this session
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" gap="8px">
              {ChecklistMock.map(item => (
                <ChecklistCard {...item} key={item.title} onClick={() => console.log('onClickCardItem')} />
              ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export { CompleteNote };
