import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Icons, IGlyphs } from '@confidant-health/lib/icons';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { colors } from '@confidant-health/lib/colors';

import { useStyles } from './ReportTop.styles';

type Props = {
  label: string;
  icon: IGlyphs['glyph'];
  isChecked: boolean;
  onChange(): void;
  checkList: Array<{ title: string; type: string }>;
  listChecked: string[];
  setListChecked: Dispatch<SetStateAction<string[]>>;
};

const ReportTop: FC<Props> = ({
  icon,
  label,
  isChecked,
  onChange,
  checkList,
  listChecked,
  setListChecked,
}) => {
  const classes = useStyles();
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    if (listChecked.length === checkList.length) {
      setSelectAll(true);
      return;
    }
    setSelectAll(false);
  }, [listChecked]);

  return (
    <Box className={classes.cardTop}>
      <Stack direction="row" gap={2} alignItems="center">
        <Icons glyph={icon} color={colors.neutral400} />
        <Text className={classes.cardTopTitle} weight={fontWeight.SEMI_BOLD}>
          {label}
        </Text>
      </Stack>
      <FormControlLabel
        control={<Checkbox />}
        checked={isChecked}
        className={classes.checkbox}
        onChange={onChange}
        label="Include in report"
      />
      {isChecked && (
        <FormControlLabel
          control={<Checkbox />}
          checked={selectAll}
          className={classes.checkbox}
          onChange={() => {
            if (!selectAll) {
              setListChecked(checkList.map(({ type }) => type));
            } else setListChecked([]);
            setSelectAll(!selectAll);
          }}
          label="Select all"
        />
      )}
    </Box>
  );
};

export { ReportTop };
