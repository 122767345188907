import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
      padding: '0px 0px',
      marginTop: '0px',
    },
    '& .drawerHeader': {
      height: '64px',
    },
    '& .headTitle': {
      fontSize: '18px',
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    marginBottom: 6,
    marginTop: '0px',
    letterSpacing: '0.14px',
  },

  eye: {
    cursor: 'pointer',
  },
  drawerBodyContainer: {
    padding: '0px 40px',
  },
  rowContainer: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  emailPassContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: '76px',
  },
  fullWidthRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .react-tel-input': {
      width: 'auto !important',
      flex: 1,
    },
  },
  name: {
    flex: 1,
    height: 'auto',
    marginRight: '24px',
  },
  providerCode: {
    height: 'auto',
    flex: 1,
  },
  marginClass: {
    marginTop: '24px',
  },
  radioContainer: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  radio: {
    gap: '24px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: colors.neutral900,
      lineHeight: '20px',
      letterSpacing: '0.14px',
    },
  },
  phoneContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .react-tel-input': {
      width: 'auto !important',
      flex: 1,
    },
  },
  signoffRole: {
    display: 'flex',
    flex: 1,
  },
  select: {
    width: '100%',
  },
  dashedBorder: {
    border: `1px dashed ${colors.neutral400}`,
    borderRadius: '8px',
  },
  chip: {
    marginTop: '10px',
  },
  boxMarginLeft: {
    marginLeft: '30%',
  },
  errorContainer: {
    display: 'flex',
  },
  emailError: {
    flex: 1,
  },
  passwordError: {
    flex: 1,
    marginLeft: '27px',
  },
});
