import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import dayjs from 'dayjs';
import { Alert, Box, Stack } from '@mui/material';

// components
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import history from 'utils/history';

import { showSnackbar } from 'redux/modules/snackbar';
import { getConversation, selectDctState } from 'redux/modules/conversation/selectors';
import { conversationActionCreators } from 'redux/modules/conversation/actions';

// constants
import {
  CONTENT_BLOCK_TYPES,
  CONTENT_BLOCK_CARD_TITLES,
  CONVERSATION_TYPES,
  CONVERSATION_STATUS,
  ROUTING_OPTIONS,
} from 'constants/CommonConstants';

// services
import {
  addNewContentBlock,
  getContentBlocksByConsversationId,
  getDCTDetails,
  getContentblocksByDct,
  deleteContentBlock,
  upgradeConversationVersion,
  publishConversation,
  getConversationRippleCounts,
  getDCTRipplesCount,
  // updateConversation,
  upgradeDctVersion,
  publishDCT,
  updateEvaluation,
  updateDCT,
  updateConversation,
  integrateContentBlock,
} from 'services/conversation/conversation.service';

import { IContentBlock, IConversationAssignments } from 'pages/admin/conversations/Conversations.types';
import { useStyles } from './ConversationFlow.styles';

import FlowCard from './flow-card';
import FlowAdd from './flow-add';
import FlowEditing from './flow-edting';
import FlowChangeOrder from './flow-change-order';
import UpgradeVersion from './upgrade-version';
import PublishConversationModal from './publish-conversation';
import { IFlowCard } from './flow-card/FlowCard.types';
import { initializeAIConfig } from '../../../../../utils';

type Props = {
  conversation?: IConversationAssignments;
  dct?: any;
  onClose: () => void;
  onSave: (contentBlockId: string, updatedContentBlock: any) => Promise<void>;
  conversationType: string;
};

const ConversationFlow: FC<Props> = ({ conversation, dct, onClose, onSave, conversationType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const { dcts } = useSelector(selectDctState);
  const {
    profileElements: { profileElementList },
  } = useSelector(getConversation);

  const [isLoading, setIsLoading] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [addFlow, setAddFlow] = useState(false);
  const [reloadCBList, setReloadCBList] = useState(false);
  const [editLocked, setEditLocked] = useState(false);
  const [nextDraftId, setNextDraftId] = useState(null);
  const [nextDraftVersion, setNextDraftVersion] = useState(null);
  const [editFlowItem, setEditFlowItem] = useState<any>(null);
  const [flowList, setFlowList] = useState<IFlowCard[]>();
  const [showChangeOrder, setShowChangeOrder] = useState(false);
  const [contentBlocks, setContentBlocks] = useState([]);
  const [refreshContentBlocks, setRefreshContentBlocks] = useState(false);
  const [isVersionUpgraded, setIsVersionUpgraded] = useState(false);
  const [showPublishModel, setPublishModel] = useState(false);
  const conversationId = conversation?.conversationId;
  const [rippleCount, setRippleCount] = useState(null);

  const isDraft =
    conversationType === CONVERSATION_TYPES.CONVERSATION
      ? conversation?.conversation?.status === CONVERSATION_STATUS.DRAFT
      : conversationType === CONVERSATION_TYPES.EVALUATION
      ? conversation?.evaluation?.status === CONVERSATION_STATUS.DRAFT
      : dct?.dct?.status === CONVERSATION_STATUS.DRAFT;
  const formatContentBlockCards = (type: string, contentBlock: any, contentBlockList: any[]) => {
    const contentBlockCards = [];
    switch (type) {
      case CONVERSATION_TYPES.DCT:
        // variable card
        if (contentBlock.basicInfo?.referenceId) {
          contentBlockCards.push({
            type: CONTENT_BLOCK_CARD_TITLES.VARIABLE,
            icon: 'arrow-left-right',
            name: CONTENT_BLOCK_CARD_TITLES.VARIABLE,
            title: contentBlock.basicInfo?.referenceId,
          });
        }
        // type card
        if (contentBlock.basicInfo?.type) {
          contentBlockCards.push({
            type: CONTENT_BLOCK_CARD_TITLES.TYPE,
            icon: 'input-method',
            name: CONTENT_BLOCK_CARD_TITLES.TYPE,
            title: contentBlock.basicInfo?.type,
          });
        }
        // choices card
        if (contentBlock.basicInfo?.choices?.length > 0) {
          contentBlockCards.push({
            type: CONTENT_BLOCK_CARD_TITLES.CHOICES,
            icon: 'list2',
            name: CONTENT_BLOCK_CARD_TITLES.CHOICES,
            title: contentBlock.basicInfo?.text,
            choices: contentBlock.basicInfo?.choices?.map(choice => {
              return {
                ...choice,
                name: choice.choice,
              };
            }),
          });
        }
        // display logic card
        if (contentBlock.displayLogics?.length > 0 || contentBlock.rawDisplayLogic) {
          contentBlockCards.push({
            type: CONTENT_BLOCK_CARD_TITLES.DISPLAY_LOGIC,
            icon: 'eye-outlined',
            name: CONTENT_BLOCK_CARD_TITLES.DISPLAY_LOGIC,
            // eslint-disable-next-line no-nested-ternary
            title: contentBlock.rawDisplayLogic
              ? 'Raw Logic Expression'
              : contentBlock.displayLogics[0]?.type === 'R'
              ? 'If Conversation Variable'
              : 'If Profile Element',
            // eslint-disable-next-line no-nested-ternary
            description:
              contentBlock.displayLogics?.length > 0
                ? `${
                    contentBlockList?.find(cb => cb.cbId === contentBlock.displayLogics[0]?.key)?.basicInfo
                      ?.referenceId
                  } ${contentBlock.displayLogics[0]?.rule} ${contentBlock.displayLogics[0]?.value}`
                : contentBlock.rawDisplayLogic,
          });
        }
        // routing card
        if (contentBlock.routingOption) {
          let title = contentBlock.routingOption;
          let description = '';
          if (contentBlock.routingOption === ROUTING_OPTIONS.SPECIFIC && contentBlock.routing?.length > 0) {
            const foundContentBlock = contentBlockList?.find(cb => cb.cbId === contentBlock.routing[0]);
            if (foundContentBlock) {
              title = foundContentBlock.basicInfo?.referenceId;
              description = foundContentBlock.basicInfo?.text;
            }
          }
          contentBlockCards.push({
            type: CONTENT_BLOCK_CARD_TITLES.ROUTING,
            icon: 'routing',
            name: CONTENT_BLOCK_CARD_TITLES.ROUTING,
            title,
            description,
          });
        }
        // variable mapping card
        if (contentBlock.basicInfo?.variableMapping) {
          contentBlockCards.push({
            type: CONTENT_BLOCK_CARD_TITLES.VARIABLE_MAPPING,
            icon: 'connect',
            name: CONTENT_BLOCK_CARD_TITLES.VARIABLE_MAPPING,
            title: contentBlock.basicInfo?.variableMapping?.scope,
            description: contentBlock.basicInfo?.variableMapping?.name,
          });
        }
        // // related Information card
        // if (contentBlock.displayLogics?.length > 0) {
        // }
        return contentBlockCards;

      default:
        return contentBlockCards;
    }
  };

  const generateFlowList = (cbs: IContentBlock[]) =>
    cbs.map((cb: IContentBlock) => ({
      id: cb.cbId,
      variableName: cb.basicInfo?.referenceId,
      type: cb.basicInfo?.type,
      message: cb.dctId ? cb.basicInfo?.name || cb.basicInfo?.text : cb.basicInfo?.text,
      items: formatContentBlockCards(CONVERSATION_TYPES.DCT, cb, cbs),
    }));

  /**
   * @function getContentBlocks
   * @description This method is used to get content block by dctID.
   */
  const getContentBlocks = async () => {
    if (conversationId) {
      try {
        const { data } = await getContentBlocksByConsversationId(conversationId);
        setEditLocked(data.locked || false);
        setNextDraftId(data?.conversation?.nextDraftId);
        setNextDraftVersion(data?.conversation?.nextDraftVersion);
        setContentBlocks(data.contentBlocks);
        setFlowList(generateFlowList(data.contentBlocks));
      } catch (err) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
          }),
        );
      }
    }

    if (dct?.id) {
      try {
        const response = await getDCTDetails(dct?.id);
        setNextDraftId(response.data.dct.nextDraftId);

        const { data } = await getContentblocksByDct(dct?.id);
        setEditLocked(data.locked || false);
        setContentBlocks(data.contentBlocks);
        setFlowList(generateFlowList(data.contentBlocks));
      } catch (err) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
          }),
        );
      }
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    // fetch DCTs list
    if (conversationId) {
      dispatch(
        conversationActionCreators.fetchDCTs({
          status: CONVERSATION_STATUS.ACTIVE,
          type: conversationType === CONVERSATION_TYPES.EVALUATION ? CONVERSATION_TYPES.EVALUATION : '',
        }),
      );
    }

    // fetch profiile elements list
    const queryParams = {
      searchQuery: '',
      type: '',
      method: '',
      pageNumber: 0,
      pageSize: 10000,
      orderBy: '',
      sortBy: [],
    };
    dispatch(conversationActionCreators.fetchProfileElements(queryParams));

    await getContentBlocks();

    setIsLoading(false);
  };

  const onBackClick = () => {
    onClose();
  };

  const onOpenAddCB = () => {
    setAddFlow(true);
  };

  const onEditClick = (flowItem: IFlowCard) => {
    const foundContentBlock = contentBlocks.find(contentBlock => contentBlock.cbId === flowItem.id);
    if (foundContentBlock) {
      const extendedFlowItem = { ...flowItem, ...foundContentBlock };
      extendedFlowItem.logic = 'always';
      if (foundContentBlock.displayLogics && foundContentBlock.displayLogics.length > 0) {
        extendedFlowItem.logic = foundContentBlock.displayLogics[0].type as string;
      }
      if (foundContentBlock.rawDisplayLogic) {
        extendedFlowItem.logic = 'RAW';
      }
      if (
        extendedFlowItem.basicInfo.type !== CONTENT_BLOCK_TYPES.EDUCATION_CONTENT &&
        extendedFlowItem.basicInfo.type !== CONTENT_BLOCK_TYPES.EXERCISE_CONTENT
      ) {
        initializeAIConfig(extendedFlowItem);
      }
      setEditFlowItem(extendedFlowItem);
    }
  };

  const onCloseEditFlowItem = () => {
    setEditFlowItem(null);
  };

  const onCloseAddFlow = () => {
    setAddFlow(false);
  };

  const getNextCBNumber = blocks => {
    const cbNumbers = blocks
      .map(cb => {
        const regex = /\d+/g;
        if (cb.basicInfo.referenceId && cb.basicInfo.referenceId.match(regex) !== null) {
          return parseInt(cb.basicInfo.referenceId.match(regex)[0], 10);
        }
        return null;
      })
      .filter(num => num !== null);
    const max = Math.max(...cbNumbers);
    if (max === -Infinity) {
      return 1;
    }
    return max + 1;
  };

  const sanitizeContentBlock = contentBlock => {
    const { basicInfo } = contentBlock;
    const { type } = basicInfo;
    if (type !== CONTENT_BLOCK_TYPES.EDUCATION_CONTENT) {
      basicInfo.educationContentSlug = null;
    }
    if (type !== CONTENT_BLOCK_TYPES.RATING_SCALE) {
      basicInfo.ratingScale = null;
    }
    if (type !== CONTENT_BLOCK_TYPES.FILTERED_PROVIDERS) {
      basicInfo.popupText = null;
    }
    if (type !== CONTENT_BLOCK_TYPES.SINGLE_SELECT && type !== CONTENT_BLOCK_TYPES.MULTI_SELECT) {
      basicInfo.choices = null;
    }
    if (
      type === CONTENT_BLOCK_TYPES.PROVIDER_MESSAGE ||
      type === CONTENT_BLOCK_TYPES.PROVIDER_PROMPT ||
      type === CONTENT_BLOCK_TYPES.TELEHEALTH_SERVICES
    ) {
      basicInfo.variableMapping = null;
    }
    if (type === CONTENT_BLOCK_TYPES.SINGLE_SELECT || type === CONTENT_BLOCK_TYPES.MULTI_SELECT) {
      if (!basicInfo.variableMapping || basicInfo.variableMapping?.scope !== 'profile') {
        basicInfo.choices =
          basicInfo.choices &&
          basicInfo.choices.map(choice => {
            choice.value = choice.choice;
            return choice;
          });
      }
    }
    if (type === CONTENT_BLOCK_TYPES.RATING_SCALE && basicInfo.ratingScale) {
      basicInfo.ratingScale.lowLabel = basicInfo.ratingScale.lowLabel.trim();
      basicInfo.ratingScale.highLabel = basicInfo.ratingScale.highLabel.trim();
    }
  };

  const checkVariableName = () => {
    const variableNamesList = contentBlocks?.map(item => item?.basicInfo?.referenceId?.trim());
    return variableNamesList.some((item, index) => variableNamesList.indexOf(item) !== index);
  };

  const allLogicsComplete = () => {
    return (
      editFlowItem?.displayLogics &&
      editFlowItem?.displayLogics?.length > 0 &&
      editFlowItem?.displayLogics?.length ===
        editFlowItem?.displayLogics.filter(logic => logic.type && logic.key && logic.value && logic.rule)
          .length
    );
  };

  const displayLogic = () => {
    if (editFlowItem?.mainLogicType) {
      if (editFlowItem?.mainLogicType === 'P' || editFlowItem?.mainLogicType === 'R') {
        if (editFlowItem?.displayLogics && !allLogicsComplete()) {
          return false;
        }
      }
    } else if (
      editFlowItem?.displayLogics &&
      editFlowItem?.displayLogics?.length > 0 &&
      !allLogicsComplete()
    ) {
      return false;
    } else if (editFlowItem?.displayLogics?.length > 1 && editFlowItem?.ruleAggregator === null) {
      return false;
    }
    return true;
  };

  const checkRoutingIsNull = routing => {
    return routing.filter(routingKey => routingKey === '').length === 0;
  };

  const isFormValid = () => {
    const { routing } = editFlowItem;
    const { type, variableMapping, ratingScale } = editFlowItem?.basicInfo;

    if (checkVariableName()) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Duplicates variable name are not allowed',
        }),
      );
      return false;
    }
    if (!displayLogic()) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Invalid display logic',
        }),
      );
      return false;
    }
    if (editFlowItem?.routingOption === 'SPECIFIC' && !checkRoutingIsNull(routing)) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Invalid routing',
        }),
      );
      return false;
    }
    if (
      type === CONTENT_BLOCK_TYPES.SINGLE_SELECT ||
      type === CONTENT_BLOCK_TYPES.MULTI_SELECT ||
      type === CONTENT_BLOCK_TYPES.TEXT_INPUT ||
      type === CONTENT_BLOCK_TYPES.RATING_SCALE
    ) {
      if (variableMapping?.scope === 'profile' || variableMapping?.scope === 'local') {
        if (!variableMapping.name) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Variable mapping is required',
            }),
          );
          return false;
        }
        if (variableMapping.name.trim() === '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Variable mapping is required',
            }),
          );

          return false;
        }
      }
      if (type === CONTENT_BLOCK_TYPES.RATING_SCALE) {
        if (!ratingScale.lowLabel || ratingScale.lowLabel.trim() === '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Low Label for Rating Scale is required',
            }),
          );
          return false;
        }
        if (!ratingScale.highLabel || ratingScale.highLabel.trim() === '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'High Label for Rating Scale is required',
            }),
          );
          return false;
        }
      }
    }
    return true;
  };

  const onSaveFlowItem = async () => {
    const { cbId, ...contentBlock } = editFlowItem;
    // if (
    //   contentBlock.basicInfo?.variableMapping?.scope === 'profile' &&
    //   contentBlock.basicInfo?.variableMapping?.name !== null
    // ) {
    //   contentBlock.relatedProfileElements = contentBlock.relatedProfileElements?.filter(
    //     element => element !== contentBlock.basicInfo.variableMapping.name,
    //   );
    // }
    if (isFormValid()) {
      if (contentBlock.basicInfo && contentBlock.basicInfo?.referenceId) {
        contentBlock.basicInfo.referenceId = contentBlock.basicInfo.referenceId.trim();
      }
      sanitizeContentBlock(contentBlock);
      await onSave(cbId, contentBlock);
      void fetchData();
    }
    setEditFlowItem(null);
  };

  const toggleShowUpgradeModal = () => {
    setShowUpgradeModal(!showUpgradeModal);
  };

  const toggleShowPublishModal = () => {
    setPublishModel(!showPublishModel);
  };

  const onUpgradeVersion = () => {
    if (
      conversationType === CONVERSATION_TYPES.DCT ||
      conversationType === CONVERSATION_TYPES.EVALUATION_DCT
    ) {
      upgradeDctVersion(dct?.id)
        .then(upgradeVersionResponse => {
          setNextDraftId(upgradeVersionResponse.data?.dct?.nextDraftId);
          setNextDraftVersion(upgradeVersionResponse.data?.dct?.nextDraftVersion);
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'DCT version upgraded Successfully',
            }),
          );
          history.push(
            `/admin/${path}/${upgradeVersionResponse.data?.id}/${upgradeVersionResponse.data?.version}?name=assignments`,
            {
              openFlow: true,
            },
          );
          setIsVersionUpgraded(true);
          toggleShowUpgradeModal();
        })
        .catch(error => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: error?.data?.errors[0]?.endUserMessage ?? 'Unable to update!',
            }),
          );
        });
      toggleShowUpgradeModal();
    } else {
      upgradeConversationVersion(conversationId)
        .then(upgradeVersionResponse => {
          setNextDraftId(upgradeVersionResponse.data?.conversation?.nextDraftId);
          setNextDraftVersion(upgradeVersionResponse.data?.conversation?.nextDraftVersion);
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'New Draft ready for publishing',
            }),
          );
          history.push(
            `/admin/${path}/${upgradeVersionResponse.data?.id}/${upgradeVersionResponse.data?.version}?name=assignments`,
            {
              openFlow: true,
            },
          );
          setIsVersionUpgraded(true);
          toggleShowUpgradeModal();
        })
        .catch(error => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: error?.data?.errors[0]?.endUserMessage ?? 'Unable to update!',
            }),
          );
          toggleShowUpgradeModal();
        });
    }
  };

  const onChangeOrderClick = () => {
    setShowChangeOrder(true);
  };

  const onCloseChangeOrder = () => {
    setShowChangeOrder(false);
  };

  const onEditNextVersion = () => {
    history.push(`/admin/${path}/${nextDraftId}/${nextDraftVersion}?name=assignments`, { openFlow: true });
  };

  const onPublish = () => {
    if (
      conversationType === CONVERSATION_TYPES.DCT ||
      conversationType === CONVERSATION_TYPES.EVALUATION_DCT
    ) {
      publishDCT(dct?.id)
        .then(() => {
          setReloadCBList(!reloadCBList);
          setIsVersionUpgraded(false);

          if (conversationType === CONVERSATION_TYPES.DCT) {
            history.push('/admin/collection-templates');
          } else {
            history.push('/admin/collection-evaluation-dcts');
          }
        })
        .catch(err => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
            }),
          );
        });
    } else {
      publishConversation(conversationId)
        .then(() => {
          setReloadCBList(!reloadCBList);
          setIsVersionUpgraded(false);

          if (conversationType === CONVERSATION_TYPES.CONVERSATION) {
            history.push('/admin/collection-conversations');
          } else {
            history.push('/admin/collection-evaluations');
          }
        })
        .catch(err => {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
            }),
          );
        });
    }

    setPublishModel(false);
  };

  const checkRipplesForDraft = async () => {
    if (
      conversationType === CONVERSATION_TYPES.DCT ||
      conversationType === CONVERSATION_TYPES.EVALUATION_DCT
    ) {
      const response = await getDCTRipplesCount(dct?.id);
      if (response.status === 200) {
        setRippleCount({
          automationRippleCount: response.data.automationsCount,
          dctRippleCount: response.data.dctCount,
        });
        setPublishModel(true);
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: response?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
          }),
        );
      }
    } else {
      const response = await getConversationRippleCounts(conversationId);
      if (response.status === 200) {
        setRippleCount({
          automationRippleCount: response.data.automationsCount,
          dctRippleCount: response.data.dctCount,
        });
        setPublishModel(true);
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: response?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
          }),
        );
      }
    }
  };

  const onDeleteFlow = () => {
    const { cbId } = editFlowItem;
    deleteContentBlock(cbId)
      .then(response => {
        if (response.status !== 200) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Error Deleting Content Block',
            }),
          );
        } else {
          setRefreshContentBlocks(!refreshContentBlocks);
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Content Block Deleted from DCT',
            }),
          );
        }
      })
      .catch(err => {
        console.log('Error Deleting Content Block', err);
      });
    setEditFlowItem(null);
  };

  const addCB = async payload => {
    let successCount = 0;
    let highestNumber: number = getNextCBNumber([...contentBlocks]);
    for (let i = 0; i < payload.length; i++) {
      /* eslint-disable no-await-in-loop */
      let request: any = {
        conversationId,
        cbId: payload[i]?.cbId,
      };
      if (dct?.id) {
        request = {
          ...request,
          dctId: dct.id,
          referenceId: `CB-${highestNumber}`,
        };
        highestNumber = Number(highestNumber) + 1;
      } else {
        request = {
          ...request,
          referenceId: `CB-${highestNumber}`,
        };
        highestNumber = Number(highestNumber) + 1;
      }
      try {
        const response = await integrateContentBlock(request);
        if (response.status === 200) {
          successCount++;
        }
      } catch (err) {
        const msg = err.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: msg,
          }),
        );
      }
    }
    if (successCount > 0) {
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: `Content ${payload.length === 1 ? 'block' : 'blocks'} added successfully`,
        }),
      );
    }
    setReloadCBList(!reloadCBList);
  };

  const addDcts = async (selectedDcts: string[]) => {
    const blocks = [...contentBlocks];
    const requests = selectedDcts.map((dctId: string) => {
      const cb = {
        type: 'dct',
        conversationId,
        dctId,
        referenceId: `DCT-CB-${getNextCBNumber(blocks)}`,
      };
      blocks.push({ basicInfo: { referenceId: cb.referenceId } });
      return cb;
    });

    let successCount = 0;
    // Backend error: Promise.all() doesn't work
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < requests.length; i++) {
      const { data } = await addNewContentBlock(requests[i]);
      if (data.errors) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: data.errors,
          }),
        );
      } else {
        successCount++;
      }
    }
    if (successCount > 0) {
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'DCTs added successfully',
        }),
      );
    }

    onCloseAddFlow();
    setReloadCBList(!reloadCBList);
  };

  const onChangeOrder = (orderedCbs: IContentBlock[]) => {
    setContentBlocks(orderedCbs);
    setFlowList(generateFlowList(orderedCbs));
    const orderedCbIds = orderedCbs.map(cb => cb.cbId);

    let conversationRequest;
    if (conversationType === CONVERSATION_TYPES.CONVERSATION) {
      conversationRequest = {
        ...conversation?.conversation,
        contentBlocks: orderedCbIds,
        selfContained: true,
        defaultPriority: 'LOW',
        tags: ['Assessment'],
        whoCanBenefit: ['Clients seeing providers'],
        description: 'Confidant chatbot',
        avatar: 'https://i.imgur.com/Tgbdv8K.png',
        reorder: true,
      };
      updateConversation(conversationRequest, { conversationId: dct?.id })
        .then(res => {
          dispatch(showSnackbar({ snackType: 'success', snackMessage: res.data.message }));
        })
        .catch(() => {
          dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Something went wrong!' }));
        });
    } else if (conversationType === CONVERSATION_TYPES.EVALUATION) {
      conversationRequest = {
        ...conversation?.evaluation,
        contentBlocks: orderedCbIds,
        selfContained: true,
        defaultPriority: 'LOW',
        tags: ['Assessment'],
        whoCanBenefit: ['Clients seeing providers'],
        description: 'Confidant chatbot',
        avatar: 'https://i.imgur.com/Tgbdv8K.png',
        reorder: true,
      };
      updateEvaluation(conversationRequest, { evaluationId: conversation.evaluation.evaluationId })
        .then(res => {
          dispatch(showSnackbar({ snackType: 'success', snackMessage: res.data.message }));
        })
        .catch(() => {
          dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Something went wrong!' }));
        });
    } else {
      conversationRequest = {
        ...dct,
        contentBlocks: orderedCbIds,
        selfContained: true,
        defaultPriority: 'LOW',
        tags: ['Assessment'],
        whoCanBenefit: ['Clients seeing providers'],
        description: 'Confidant chatbot',
        avatar: 'https://i.imgur.com/Tgbdv8K.png',
        reorder: true,
      };
      updateDCT(dct?.id, conversationRequest)
        .then(res => {
          dispatch(showSnackbar({ snackType: 'success', snackMessage: res.data.message }));
        })
        .catch(() => {
          dispatch(showSnackbar({ snackType: 'error', snackMessage: 'Something went wrong!' }));
        });
    }
  };

  useEffect(() => {
    void fetchData();
  }, [reloadCBList, refreshContentBlocks, conversationId, dct?.id]);

  const renderBanner = () => {
    if (editLocked && !nextDraftId) {
      return (
        <Alert severity="error">
          This conversation is locked for editing because it is actively being used by members. Please upgrade
          its version to make changes.
        </Alert>
      );
    }
    if (editLocked && nextDraftId) {
      return (
        <Alert severity="error">
          This conversation is locked and Draft for the next version is already available. Click on Edit Next
          Version Draft to make changes.
        </Alert>
      );
    }
    if (isDraft) {
      return (
        <Alert severity="info">
          This conversation is in draft state. Click on Publish to make it active for members.
        </Alert>
      );
    }
    return null;
  };

  return (
    <>
      <FlowChangeOrder
        isOpen={showChangeOrder}
        contentBlocks={contentBlocks}
        onClose={onCloseChangeOrder}
        onChangeOrder={onChangeOrder}
      />
      <UpgradeVersion
        open={showUpgradeModal}
        onClose={toggleShowUpgradeModal}
        onUpgradeVersion={onUpgradeVersion}
      />
      <PublishConversationModal
        open={showPublishModel}
        onClose={toggleShowPublishModal}
        onPublishConvesation={onPublish}
        dctCount={rippleCount?.dctRippleCount}
        automationsCount={rippleCount?.automationRippleCount}
        type="conversation"
      />
      {!!editFlowItem && (
        <FlowEditing
          isLoading={isLoading}
          isOpen={!!editFlowItem}
          flowItem={editFlowItem || {}}
          editLocked={editLocked}
          contentBlocks={contentBlocks}
          dcts={dcts}
          profileElements={profileElementList}
          onClose={onCloseEditFlowItem}
          onSave={onSaveFlowItem}
          onDelete={onDeleteFlow}
          conversationType={conversationType}
        />
      )}
      <FlowAdd
        isOpen={addFlow}
        conversationType={conversationType}
        conversationId={conversationId}
        dctId={dct?.id}
        nextCbNumber={getNextCBNumber([...contentBlocks])}
        onClose={onCloseAddFlow}
        addCB={addCB}
        addDcts={addDcts}
      />

      <Box className={classes.root}>
        <Box className={classes.header}>
          <Stack direction="row" gap={1.5} alignItems="center">
            <IconButton className={classes.backBtn} icon="arrow-left" onClick={onBackClick} />
            <Heading className={classes.headerTitle} level={headingLevel.S} weight={fontWeight.BOLD}>
              {dct?.id ? 'DCT Flow' : 'Conversation Flow'}
            </Heading>
          </Stack>
          {editLocked && !nextDraftId && (
            <IconButton
              className={classes.upgradeBtn}
              variant={iconBtnType.PRIMARY}
              onClick={toggleShowUpgradeModal}
            >
              Upgrade version
            </IconButton>
          )}
          {editLocked && nextDraftId && (
            <IconButton
              className={classes.upgradeBtn}
              variant={iconBtnType.PRIMARY}
              onClick={onEditNextVersion}
            >
              Edit Next Version Draft
            </IconButton>
          )}
          {isDraft && (
            <IconButton
              className={classes.upgradeBtn}
              variant={iconBtnType.PRIMARY}
              onClick={checkRipplesForDraft}
            >
              Publish
            </IconButton>
          )}
        </Box>

        <Box className={classes.content}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" sx={{ paddingY: 15 }}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            </Box>
          ) : (
            <Box className={classes.contentLeft}>
              {renderBanner()}
              <Box px={10} py={5}>
                <Box display="flex" justifyContent="space-between">
                  <Stack direction="column" gap={1}>
                    <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                      {conversationType === CONVERSATION_TYPES.CONVERSATION
                        ? conversation?.conversationName
                        : conversationType === CONVERSATION_TYPES.EVALUATION
                        ? conversation?.evaluation.name
                        : dct?.dct?.name}
                    </Heading>
                    <Text className={classes.subtitle}>
                      Updated on{' '}
                      {dayjs(
                        conversationType === CONVERSATION_TYPES.CONVERSATION
                          ? conversation?.conversation.lastUpdated
                          : conversationType === CONVERSATION_TYPES.EVALUATION
                          ? conversation?.evaluation.lastUpdated
                          : dct?.dct?.lastUpdated,
                      ).format('MMMM DD, YYYY')}
                    </Text>
                  </Stack>
                  {!contentBlocks.length && (
                    <Button disabled={editLocked} onClick={onOpenAddCB}>
                      Add New Content Block
                    </Button>
                  )}
                </Box>
                <Box display="flex" flexDirection="column">
                  {flowList?.map((item: IFlowCard) => (
                    <Box key={item.id}>
                      <Box className={classes.dividerWrap}>
                        <Box className={classes.divider} />
                        <IconButton
                          icon="plus-circle-filled"
                          disabled={editLocked}
                          className={classes.addCBIcon}
                          onClick={onOpenAddCB}
                        />
                        <Box className={classes.divider} />
                      </Box>
                      <FlowCard
                        item={item}
                        onEditClick={onEditClick}
                        isEditable={isVersionUpgraded || !editLocked}
                      />
                    </Box>
                  ))}
                  {flowList?.length > 0 && (
                    <Box className={classes.dividerWrap}>
                      <Box className={classes.divider} />
                      <IconButton
                        icon="plus-circle-filled"
                        disabled={editLocked}
                        className={classes.addCBIcon}
                        onClick={onOpenAddCB}
                      />
                      <Box className={classes.divider} />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          <Box className={classes.contentRight}>
            <Box>
              {contentBlocks?.map(cb => (
                <Box key={cb.cbId}>
                  <Link
                    to={cb.cbId}
                    spy
                    smooth
                    offset={0}
                    duration={500}
                    className={classes.buttonContent}
                    onClick={() => console.log(cb.cbId)}
                  >
                    {cb.dctId ? cb.basicInfo?.name || cb.basicInfo?.text : cb.basicInfo?.text}
                  </Link>
                </Box>
              ))}
            </Box>
            <IconButton
              className={classes.changeOrderBtn}
              variant={iconBtnType.OUTLINE}
              onClick={onChangeOrderClick}
            >
              Change order
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { ConversationFlow };
