import React, { FC, useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { isUndefined } from 'lodash';
import { RadioGroup } from '@confidant-health/lib/ui/molecules/radio-group';
import { useStyles } from './EvaluationPrevHospitalization.styles';

type Props = {
  selectedSection: any; // IEvaluationSectionItem;
  forceRender: () => void;
  onChange?: (value: string) => void;
  removeExtraHeight?: boolean;
  selectedIndex?: number;
  options?: any[];
  adminFlow?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
  isSelectedBlock?: boolean;
  fullPageMode?: boolean;
};
const EvaluationPrevHospitalization: FC<Props> = ({
  selectedSection,
  forceRender,
  onChange,
  removeExtraHeight,
  selectedIndex,
  options,
  adminFlow,
  scrollRef,
  isSelectedBlock,
  fullPageMode,
}) => {
  const classes = useStyles({
    currentIndex: fullPageMode && isSelectedBlock ? selectedIndex : fullPageMode ? -1 : selectedIndex,
    choices: options,
  });
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current && scrollRef?.current && selectedIndex > -1) {
      if (isSelectedBlock || !fullPageMode)
        scrollRef.current.scrollTo({
          top: ref.current.offsetTop + selectedIndex * 40 - scrollRef.current.offsetTop - 206,
          behavior: 'smooth',
        });
    }
  }, [scrollRef, selectedIndex, ref]);

  useEffect(() => {
    if (isUndefined(selectedSection.evaluationResponses)) {
      setSelectedItem(null);
      if (selectedSection?.userResponse?.userResponseList.length > 0) {
        selectedSection.evaluationResponses = selectedSection.userResponse.userResponseList;
      }
    } else if (selectedSection.evaluationResponses[0] === selectedItem) {
      setSelectedItem(null);
    }
  }, [selectedSection.evaluationResponses, selectedSection]);

  const onChangeCheckboxes = e => {
    selectedSection.evaluationResponses = [e.target.value];
    setSelectedItem(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
    forceRender();
  };

  const checkAnswer = (() => {
    if (selectedSection.evaluationResponses?.length > 0) return selectedSection.evaluationResponses;
    if (selectedSection.userResponse?.userResponseList?.length > 0) {
      return selectedSection.userResponse.userResponseList;
    }
    return null;
  })();

  return (
    <>
      {!removeExtraHeight && <Box style={{ height: 32 }} />}
      <Box className={classes.radioGroupRoot} ref={ref}>
        <RadioGroup
          value={checkAnswer}
          onChange={(e, v) => {
            if (adminFlow) {
              onChangeCheckboxes(e);
            } else {
              onChange(v);
            }
          }}
          options={options}
          className={classes.radioGroup}
        />
      </Box>
    </>
  );
};

export { EvaluationPrevHospitalization };
