import { AxiosResponse } from 'axios';
import { baseRequest } from 'utils/HttpClient';
import { ApiEndpoints } from 'constants/ApiEndpoints';

export const getProviderFeeList = (
  queryParams: {
    providerId?: string[];
    page: number;
    limit: number;
  },
  body: {
    searchQuery: string;
    startDate: string;
    endDate: string;
    states: string[];
  },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_LIST, body, null, queryParams);
};
export const downloadProviderFeeList = (payload: any, requestBody: any): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_LIST, requestBody || {}, null, payload, true, true, true);
};
export const getProviderFeeById = (pathParams: { id: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_BY_ID, null, pathParams);
};

export const markProviderFeePaid = (pathParams: { id: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.MARK_PAID_PROVIDER_FEE, null, pathParams);
};

export const markAllSelectedProviderFeePaid = (requestBody: { ids: string[] }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ALL_SELECTED_MARK_PAID_PROVIDER_FEE, requestBody, null);
};
export const markAllSelectedProviderFeeRejected = (requestBody: {
  ids: string[];
}): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ALL_SELECTED_MARK_REJECTED_PROVIDER_FEE, requestBody, null);
};

export const markProviderFeeRejected = (pathParams: { id: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.MARK_REJECT_PROVIDER_FEE, null, pathParams);
};

export const getProviderFeeByClaimId = (pathParams: { id: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_BY_CLAIM_ID, null, pathParams);
};
export const getProviderFeeByAppointmentId = (pathParams: { id: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_FEE_BY_APPOINTMENT_ID, null, pathParams);
};
