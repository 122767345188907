import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      flexDirection: 'column',
      display: 'flex',
      padding: '0px 0px',
      marginTop: '0px',
    },
    '& .drawerHeader': {
      height: '64px',
    },
    '& .headTitle': {
      fontSize: '18px',
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    marginBottom: '8px',
    marginTop: '0px',
    letterSpacing: '0.14px',
  },
  bodyContainer: {
    padding: '40px 40px 40px 40px',
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabs: {
    borderBottom: `1px solid ${colors.neutral100}`,
    height: '72px',
    '& button': {
      color: colors.neutral600,
      fontWeight: 700,
      height: '72px',
      minWidth: '144px',
      textTransform: 'none',
      '&.Mui-selected': {
        color: `${colors.neutral900} !important`,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.neutral900,
    },
  },
  marginClass: {
    marginTop: '24px',
  },
  radioContainer: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  radio: {
    gap: '24px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: colors.neutral900,
      lineHeight: '20px',
      letterSpacing: '0.14px',
    },
  },
  emailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkBoxContainer: {
    marginTop: '38px',
  },
  checkbox: {
    marginRight: '12px',
    paddingLeft: '16px',
  },
  phoneContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .react-tel-input': {
      width: 'auto !important',
      flex: 1,
    },
  },
  dobContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      flex: 1,
    },
  },
  firstName: {
    flex: 1,
    marginRight: '24px',
  },
  lastName: {
    flex: 1,
  },
  inputClassName: {
    flex: 1,
  },
  insuarance: {
    flex: 1,
    marginRight: '24px',
  },
  state: {
    flex: 1,
    marginRight: '24px',
  },
  address: {
    flex: 1,
  },
  referralSources: {
    flex: 1,
  },
  memberId: {
    flex: 1,
  },
  city: {
    flex: 1,
  },
  zipCode: {
    flex: 1,
  },
  select: {
    width: '100%',
  },
  idAndInsuaranceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dashedBorder: {
    border: `1px dashed ${colors.neutral400}`,
    borderRadius: '8px',
    padding: '32px 32px 32px 32px',
  },
  marginBottomClass: {
    marginBottom: '8px',
  },
  labelExtraMargin: {
    marginBottom: '16px',
  },
  csvContainer: {
    marginTop: '40px',
  },
  errorContainer: {
    display: 'flex',
  },
  firstNameError: {
    flex: 1,
  },
  lastNameError: {
    flex: 1,
    marginLeft: '27px',
  },
  cityAndZipCodeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
