import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  closeBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    minHeight: 'calc(100vh - 160px)',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  removeBtn: {
    color: colors.destructive600,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    '& svg': {
      marginRight: 8,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.neutral50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginBackground: {
    width: '75%',
    height: '60%',
    objectFit: 'contain',
  },
  loginHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 60,
  },
  headerText: {
    fontFamily: 'Source Serif Pro',
  },
  rightContainer: {
    flex: 1,
  },
  // form: { width: '100%' },
  // loginContainer: {
  //   padding: '40px 100px',
  //   flex: 1,
  //   height: '100%',
  //   width: '100%',
  // },
  loginContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    paddingTop: '10%',
  },
  outlineBtn: {
    height: 'unset',
    padding: '10px 16px',
    fontSize: 14,
  },
  inputContainer: {
    width: '100%',
    marginTop: 16,
  },
  input: {
    width: '100%',
    paddingLeft: 18,
    marginTop: 8,
  },
  eye: {
    cursor: 'pointer',
  },
  buttonContainer: {
    width: '100%',
    marginTop: 24,
    height: 64,
  },
  errorText: {
    color: colors.textError,
    marginBottom: 0,
  },
  buttonTextContainer: {
    width: '100%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 40,
    display: 'flex',
  },
});
