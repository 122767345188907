import { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { IProfileElement, ITagType } from 'redux/modules/conversation/types';

import AutomationFilterForm from './automation-filter-form';
import { IAutomationFilterLogic, IAutomationEvent } from '../AddAutomation.types';
import { useStyles } from './AutomationFilters.styles';
import { ruleAggregatorSelect } from './automation-filter-form/AutomationFilterForm.constants';

type Props = {
  onClose: () => void;
  onContinue: (payload: any) => void;
  filters: IAutomationFilterLogic;
  profileElementList: IProfileElement[];
  tagList: ITagType[];
  event?: IAutomationEvent;
  isEditMode?: boolean;
  payers?: any[];
  states?: any[];
  levelOfEngagements?: any[];
  providers?: any[];
  isLoadingPayers?: boolean;
};

export const DEFAULT_STRUCTURED_VALUES = {
  key: '',
  rule: '',
  type: '',
  value: [],
};

const AutomationFilters: FC<Props> = ({
  onClose,
  onContinue,
  filters,
  profileElementList,
  tagList,
  event,
  isEditMode,
  payers,
  states,
  levelOfEngagements,
  providers,
  isLoadingPayers,
}) => {
  const classes = useStyles();
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [filterRuleAggregatorValue, setFilterRuleAggregatorValue] = useState(
    filters?.structuredCondition?.ruleAggregator || 'and',
  );

  const onAddFilterClick = () => {
    setCurrentFilters({
      ...currentFilters,
      openForm: true,
      structuredCondition: {
        ...currentFilters.structuredCondition,
        automationLogics: [
          ...currentFilters.structuredCondition?.automationLogics,
          DEFAULT_STRUCTURED_VALUES,
        ],
        ruleAggregator: null,
      },
    });
  };

  const onDeleteFilter = (filterIndex: number) => () => {
    currentFilters.structuredCondition.automationLogics =
      currentFilters.structuredCondition?.automationLogics?.filter((_, index) => index !== filterIndex);
    setCurrentFilters({ ...currentFilters });
  };

  const onChangeFilter = (filterIndex: number) => payload => {
    if (currentFilters.structuredCondition?.automationLogics.length > filterIndex) {
      currentFilters.structuredCondition.automationLogics[filterIndex] = payload;
    } else {
      currentFilters.structuredCondition?.automationLogics?.push(payload);
    }
    setCurrentFilters({ ...currentFilters });
  };

  const onContinueClick = () => {
    currentFilters.structuredCondition.ruleAggregator = filterRuleAggregatorValue;
    onContinue(currentFilters);
  };

  const renderLabel = (str: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  const changeRuleAggregator = (ruleValueEvent: any) => {
    setFilterRuleAggregatorValue(ruleValueEvent?.target?.value);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.topWrap}>
        <Stack direction="row" gap={1.2} alignItems="center">
          <Icons glyph="filter" />
          <Text className={classes.title}>Filters</Text>
        </Stack>
        <IconButton
          icon="plus"
          variant={iconBtnType.OUTLINE}
          className={classes.addFilterBtn}
          onClick={onAddFilterClick}
        >
          Add filter
        </IconButton>
      </Box>
      <Box className={classes.contentWrap}>
        <Box className={classes.forms}>
          {currentFilters?.structuredCondition?.automationLogics?.length > 1 && (
            <Box className={classes.section}>
              {renderLabel('Rule Aggregator')}
              <Select
                value={filterRuleAggregatorValue}
                name="filterRuleAggregatorValue"
                variant={selectType?.SECONDARY}
                emptyText=""
                displayEmpty
                options={ruleAggregatorSelect}
                onChange={changeRuleAggregator}
              />
            </Box>
          )}
          {currentFilters.structuredCondition &&
          currentFilters?.structuredCondition?.automationLogics?.length > 0 ? (
            currentFilters?.structuredCondition?.automationLogics.map((filter, index) => (
              <AutomationFilterForm
                filterLength={currentFilters.structuredCondition.automationLogics.length}
                key={index}
                filter={filter}
                open={
                  currentFilters.structuredCondition.automationLogics.length > 0
                    ? currentFilters.openForm
                    : true
                }
                onDelete={onDeleteFilter(index)}
                onChange={onChangeFilter(index)}
                profileElementList={profileElementList}
                tagList={tagList}
                event={event}
                isEditMode={isEditMode}
                payersList={payers}
                states={states}
                levelOfEngagements={levelOfEngagements}
                providers={providers}
                isLoadingPayers={isLoadingPayers}
              />
            ))
          ) : (
            <Text className={classes.noFilter}>Click to add more filter</Text>
          )}
        </Box>
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onContinueClick}>Continue</Button>
        </Box>
      </Box>
    </Box>
  );
};

export { AutomationFilters };
