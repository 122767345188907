import { IPlan } from '../state/types';

export interface ContentBlockResponse {
  completedAt: string;
  conversationContextId: string;
  responses: string;
}

export interface ContentBlockHistory {
  contentBlockId: string;
  question: string;
  responseHistory: ContentBlockResponse[];
}

export interface ReportingState {
  isLoading: boolean;
  errorMsg: string;
  contentBlockHistory: ContentBlockHistory;
  report: {
    isLoading: boolean;
    isSaving: boolean;
    errorMsg: string;
    currentPage?: number;
    pageSize?: number;
    totalPages?: number;
    totalRecords?: number;
    reportList: Array<IReport>;
  };
  reportDetailTotalMembers: number;
  claims: {
    isLoading: boolean;
    isSaving: boolean;
    errorMsg: string;
    nextPage?: number;
    currentPage?: number;
    pageSize?: number;
    totalPages?: number;
    totalRecords?: number;
    claimsList: Array<IClaimRecord>;
    metaData?: any;
  };
  claim: {
    isLoading: boolean;
    isSaving: boolean;
    errorMsg: string;
    data: IClaim;
  };
  invoices: {
    isLoading: boolean;
    isSaving: boolean;
    errorMsg: string;
    nextPage?: number;
    currentPage?: number;
    pageSize?: number;
    totalPages?: number;
    totalRecords?: number;
    invoicesList: Array<IInvoiceRecord>;
    additionalMetaData?: any;
  };
  providerFee: {
    isLoading: boolean;
    isSaving: boolean;
    errorMsg: string;
    providerFeeData: IInvoiceRecord;
  };
  invoice: {
    isLoading: boolean;
    isSaving: boolean;
    errorMsg: string;
    data: IInvoiceRecord;
  };
}

export interface IReport {
  createdBy: {
    designation: string;
    firstName: string;
    lastName: string;
    fullName: string;
    matchmaker: boolean;
    profileImage: string;
    userAccountId: string;
  };
  createdAt: string;
  data: IReportData;
  reportFilters: Array<IReportFilter>;
  reportName: string;
  rolesHavingAccess: Array<string>;
  totalMembers: number;
  referralPartnerId?: string;
  updatedAt: string;
  _id: string;
}

export interface IReportDTO {
  data: IReportData;
  reportFilters: Array<IReportFilter>;
  // reportId: string;
  referralPartnerId: string;
  reportName: string;
  rolesHavingAccess: string[];
  totalMembers: number;
}

export type IReportData = {
  appointmentsSection: {
    totalAppointments?: IReportItem;
    totalMembers?: IReportItem;
    totalRating?: IReportItem;
    completedAppointments?: IReportItem;
    canceledAppointments?: IReportItem;
    noShows?: IReportItem;
    appointmentsInLast30Days?: IReportItem;
    mostAppointments?: IReportItem;
    mostNoShows?: IReportItem;
    mostRecentAppointments?: IReportItem;
    serviceTypeIds: {
      serviceTypeIds: string[];
    };
    includeInReport: boolean;
  };
  chatbotSection: {
    totalChatbots?: IReportItem;
    averageAssignments?: IReportItem;
    lastAssigned?: IReportItem;
    chatbotsInLast30Days?: IReportItem;
    mostMembers?: IReportItem;
    mostCompleted?: IReportItem;
    chatbotsList?: IReportItem;
    chatbotIds: {
      conversationIds: string[];
    };
    includeInReport: boolean;
  };
  chatbotDctSection: {
    mostCompleted?: IReportItem;
    leastCompleted?: IReportItem;
    mostAttempted?: IReportItem;
    leastAttempted?: IReportItem;
    completedLastThirtyDays?: IReportItem;
    totalDCTs?: IReportItem;
    totalCompleted?: IReportItem;
    totalAttempts?: IReportItem;
    averageInitialScore?: IReportItem;
    averageCurrentScore?: IReportItem;
    greatestChangeInScore?: IReportItem;
    averageNumberOfCompletions?: IReportItem;
    dataCollectionTemplateIds: {
      dctTemplateIds: string[];
    };
    includeInReport: boolean;
  };
  evaluationDctSection: {
    mostCompleted?: IReportItem;
    leastCompleted?: IReportItem;
    mostAttempted?: IReportItem;
    leastAttempted?: IReportItem;
    completedLastThirtyDays?: IReportItem;
    totalDCTs?: IReportItem;
    totalCompleted?: IReportItem;
    totalAttempts?: IReportItem;
    averageInitialScore?: IReportItem;
    averageCurrentScore?: IReportItem;
    greatestChangeInScore?: IReportItem;
    averageNumberOfCompletions?: IReportItem;
    dataCollectionTemplateIds: {
      dctTemplateIds: string[];
    };
    includeInReport: boolean;
  };
  dataDomainElementSection: {
    top10tByMembers?: IReportItem;
    mostAssigned?: IReportItem;
    leastAssigned?: IReportItem;
    mostCountByImportance?: IReportItem;
    mostChangedFromStart?: IReportItem;
    dataDomainElementIds: {
      domainElementIds: string[];
    };
    includeInReport: boolean;
  };
  demoGraphicsSection: {
    ageBreakDown?: IReportItem;
    educationBreakDown?: IReportItem;
    genderBreakDown?: IReportItem;
    housingStatus?: IReportItem;
    maritalStatus?: IReportItem;
    raceBreakDown?: IReportItem;
    topFiveCities?: IReportItem;
    topFiveStates?: IReportItem;
    includeInReport: boolean;
  };
  loginSection: {
    totalLogins?: IReportItem;
    averageLogins?: IReportItem;
    lastLogin?: IReportItem;
    updatedInLast30Days?: IReportItem;
    mostLogins?: IReportItem;
    fewestLogins?: IReportItem;
    mostRecentLogins?: IReportItem;
    mostSinceLastLogin?: IReportItem;
    includeInReport: boolean;
  };
  profileElementsSection: {
    totalProfileElements?: IReportItem;
    averageAssignmentsCount?: IReportItem;
    lastAssigned?: IReportItem;
    updatedInLast30Days?: IReportItem;
    mostAssigned?: IReportItem;
    leastAssigned?: IReportItem;
    top10ProfileElements?: IReportItem;
    topProfileElements?: IReportItem;
    profileElementIds: {
      profileElementIds: string[];
    };
    includeInReport: boolean;
  };
  evaluationsSection: {
    totalEvaluations?: IReportItem;
    totalAssignments?: IReportItem;
    lastAssigned?: IReportItem;
    updatedInLast30Days?: IReportItem;
    mostCompleted?: IReportItem;
    mostMembers?: IReportItem;
    evaluationList?: IReportItem;
    evaluationIds: {
      evaluationIds: string[];
    };
    includeInReport: boolean;
  };
};

export enum DctType {
  CHATBOT = 'DEFAULT',
  EVALUTATION = 'EVALUATION',
}

export enum ReportFilterRule {
  EQUALS = 'equals',
  NOT_EQUALS = 'not-equals',
  GREATER_THAN = 'greater-than',
  LESS_THAN = 'less-than',
  CONTAINS = 'contains',
  NOT_CONTAIN = 'not-contain',
  BEFORE_DATE = 'before-date',
  AFTER_DATE = 'after-date',
}

export type IReportFilter = {
  type: string;
  profileElement: string;
  states?: string[];
  levelOfEngagements: string[];
  providers: string[];
  insurance: { state: string; carriers: string[] };
  rule: ReportFilterRule;
  value: number | string;
  _id?: string;
};

export interface IReportItem {
  isChecked: boolean;
  title: string;
  viewType: ReportViewType;
}

export enum ReportViewType {
  PIE_CHART = 'PIE_CHART',
  TABLE = 'TABLE',
  BAR_GRAPH = 'BAR_GRAPH',
  COUNT = 'COUNT',
  LINE_CHART = 'LINE_CHART',
}

export enum IClaimStatus {
  CREATED = 'CREATED',
  INFO_REQUESTED = 'INFO_REQUESTED',
  REJECTED = 'REJECTED',
  PAID = 'PAID',
  COMPLETED = 'COMPLETED',
}

export interface IClaimClaim {
  totalClaim: number;
  appointmentId?: string;
  totalCost: number;
  totalProviderFee: number;
  patientResponsibility: number;
  cptList: ICpt[];
}

export interface IClaimStatusDetail {
  claimStatus: string;
  datePaid: string;
  amountPaid: number;
  amountDenied?: number;
  notes?: string;
  trackingNumber?: string;
  initialBillingDate?: string;
}

export interface IClaimRecord {
  appointmentEndTime?: string;
  appointmentId?: string;
  appointmentStartTime?: string;
  appointmentTotalTimeInMins?: number;
  payer: {
    name: string;
    taxId: string;
    plan: IPlan[];
  };
  patient: {
    fullName: string;
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    ssn: string;
    phoneNumber: string;
    profileImage?: string;
  };
  claim: IClaimClaim;
  renderingProvider: IClaimProvider;
  statusDetail: IClaimStatusDetail;
  status: IClaimStatus;
  candidStatus?: string;
  candidClaimMetadata?: any;
  state?: string;
  stateId?: string;
  stateName?: string;
  createdAt: string;
  updatedAt: string;
  paidAt?: string;
  _id: string;
}

export interface IClaimProvider {
  fullName: string;
  firstName: string;
  lastName: string;
  npi: string;
  employerId: string;
  stateLicense: string;
  uPin?: string;
  profileImage?: string;
  designation?: string;
  role?: string;
  userAccountId?: string;
}

export interface IClaimMember {
  fullName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  profileImage: string;
  uuid?: string;
  memberId: string;
  dob: string;
  gender: string;
  ssn: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  groupName: string;
  groupNumber: string;
  insuranceCode: string;
  userAccountId?: string;
}

export interface ICpt {
  _id?: string;
  cptCode: string;
  providerFee: number;
  fee?: number;
  relatedDiagnosis?: string;
  diagnosis?: string;
  icd10Code: string;
  notes: string;
  cost?: number;
}

export interface IClaimSubmitter {
  name: string;
  taxId: string;
  phone: string;
  fax: string;
  email: string;
}

export interface IClaimPayer {
  name: string;
  taxId: string;
  stateName?: string;
  isActive?: boolean;
}

export type IClaimPatient = Pick<
  IClaimMember,
  'fullName' | 'firstName' | 'lastName' | 'profileImage' | 'dob' | 'gender' | 'ssn' | 'userAccountId' | 'uuid'
> & { phoneNumber: string };

export interface ICandidClaim {
  candidId: string;
  claimId: string;
  claimStatus: string;
  amountAllowed: number;
  amountPaid: number;
  procedureModifiers: string;
  carcs: null;
  rarcs: null;
  denialReasons: null;
  rejectionReason: null;
  encounterExternalId: string;
  toBePaidByPatient: number;
  chequeDate: string;
  chequeAmountCents: number;
  chequeNumber: string;
  chequePaidDollarAmount: number;
  chequePatientDollars: number;
  chequePayerClaimNumber: string;
  amountReceivedToConfidant: boolean;
  postingDate: string;
  latestPayerClaimNumber?: string;
  deniedClaimDenialReason?: string;
}
export interface IClaim {
  name: string;
  submitter: IClaimSubmitter;
  payer: IClaimPayer;
  planName: string;
  subscriber: IClaimMember;
  dependent: IClaimMember;
  patient: IClaimPatient;
  claim: IClaimClaim;
  statusDetail: {
    claimStatus: string;
    datePaid: string;
    amountPaid: number;
    amountDenied: number;
    notes: string;
    trackingNumber: string;
    initialBillingDate: string;
  };
  _id: string;
  submitterRef: string;
  payerRef: string;
  patientRef: string;
  renderingProvider: IClaimProvider;
  renderingProviderRef: string;
  billingProvider: IClaimProvider;
  billingProviderRef: string;
  appointmentId: string;
  appointmentStartTime?: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  stateId: string;
  stateName: string;
  candidClaimMetadata?: ICandidClaim;
}

export interface IInvoiceRecord {
  member: {
    id?: string;
    fullName: string;
    firstName: string;
    lastName: string;
    profileImage: string;
    uuid?: string;
  };
  provider: {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
    profileImage: string;
  };
  insuranceDetails: {
    payer: string;
    plan: string;
    isActive: boolean;
  };
  isNoShow: boolean;
  status: string;
  _id: string;
  amount: number;
  totalAmount: number;
  memberAmount: number;
  insuranceAmount: number;
  ammountPaid: number;
  ammountDue: number;
  insurancePaid: true;
  memberPaid: false;
  memberRef: string;
  providerRef: string;
  sessoinType?: string;
  sessionType: string;
  renderingProviderRef?: string;
  cptList: ICpt[];
  createdAt?: string;
  updatedAt?: string;
  dueDate?: string;
  insuranceDetail?: {
    payer: string;
    plan: string;
    isActive: boolean;
  };
  invoiceDate?: string;
  name?: string;
  sessionDate?: string;
  appointmentId?: string;
  reason: string;
}
