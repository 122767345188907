import { FC, useState } from 'react';
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Heading, Text, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { Input } from '@confidant-health/lib/ui/atoms/input';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';

import { useStyles } from './SignOffModal.styles';

type Props = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit: () => void;
};

const SignOffModal: FC<Props> = ({ open, onClose, isLoading, onCancel, onSubmit }) => {
  const classes = useStyles();
  const [userName, setUserName] = useState('');
  const [error, setError] = useState('');

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
    setError('');
  };

  const onSubmitClick = () => {
    if (userName.trim().length === 0) {
      setError('Please enter a name');
      return;
    }
    onSubmit();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.editIconWrap}>
            <Icons glyph="pencil" className={classes.editIcon} />
          </Box>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.title}>Sign off</Heading>
              <Text className={classes.subtitle}>
                By signing off on this note you confirm all the recorded information to be correct. After you
                sign off, you will not be able to edit the information inside the note.
              </Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box>
              <Text className={classes.label}>Type your name</Text>
              <Input
                placeholder="Name"
                value={userName}
                onChange={onChangeName}
                className={classes.input}
                fullWidth
              />
              {error && <TextError errorMsg="Not allow empty" />}
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button
                className={clsx(classes.btn, classes.cancelBtn)}
                disabled={isLoading}
                onClick={onCancel || onClose}
              >
                Cancel
              </Button>
              <Button
                className={clsx(classes.btn, classes.okBtn)}
                disabled={isLoading}
                onClick={onSubmitClick}
              >
                Sign off
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { SignOffModal };
