import { AlertColor } from '@mui/material';
import { connect } from 'react-redux';

import ToastMessage from '@confidant-health/lib/ui/templates/toast-message';
import { clearSnackbar } from 'redux/modules/snackbar';
import { AppState } from 'redux/store/types';

type Props = {
  show: boolean;
  snackType: AlertColor;
  snackMessage: string;
  clearSnackbarAction: () => void;
};

function SnackContainer(props: Props) {
  return <ToastMessage {...props} />;
}

const mapStateToProps = (state: AppState) => state.snackbar;
const mapDispatchToProps = dispatch => ({
  clearSnackbarAction: () => dispatch(clearSnackbar()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(SnackContainer);
