import { ReactNode } from 'react';
import { useStyles } from './index.styles';

type Props = {
  children: ReactNode;
};

const BoxShadow = ({ children }: Props) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default BoxShadow;
