/* eslint-disable no-underscore-dangle */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// components
import { Box, Stack } from '@mui/material';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { useQuery } from '@confidant-health/lib/hooks';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { BaseLayout } from 'layouts/base';
import { selectReportList, selectReportDetailTotalMembers } from 'redux/modules/reporting/selectors';
import { DctType, IReport, IReportFilter } from 'redux/modules/reporting/types';
import { reportingActionCreators } from 'redux/modules/reporting';
import { GLOBAL_DATE_FORMAT, REPORT_FILTERS } from 'constants/CommonConstants';
import { getAllPayers } from 'services/state/state.service';
import { getProfile } from 'redux/modules/profile/selectors';
import { getElkPatientReport, getReportById } from 'services/reporting/reporting.service';
import { selectLoeReport } from 'redux/modules/schedule/selectors';

import Appointments from './components/appointments';
import Demographics from './components/demographics';
import Logins from './components/logins';
import InfoCard from './components/info-card';
import ProfileElements from './components/profile-elements';
import Chatbots from './components/chatbots';
import DataCollectionTemplates from './components/data-collection-templates';
import Evaluations from './components/evaluations';
import Symptoms from './components/symptoms';
import Diagnoses from './components/diagnoses';
import Medications from './components/medications';
import SideEffects from './components/side-effects';
import SubstanceUse from './components/substance-use';
import LifeEvents from './components/life-events';
import SocialDeterminants from './components/social-determinants';

import AddReport from '../reports/add-report';
import { rightSideNavList } from './ReportDetail.mock';
import { useStyles } from './ReportDetail.styles';
import { DeleteReport } from '../reports/delete-report/DeleteReport';
import { getProfileReportQuery } from './ReportDetail.constants';

const ReportDetail: FC = () => {
  const classes = useStyles();
  const { reportId } = useParams();
  const query = useQuery();
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [showNewReport, setShowAddReport] = useState(false);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IReport>(null);
  const reportList = useSelector(selectReportList);
  const reportDetailTotalMembers = useSelector(selectReportDetailTotalMembers);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [report, setReport] = useState(reportList.find(t => t._id === reportId) || null);
  const [payers, setpayers] = useState([]);
  const {
    // demographicMetadata: { levelOfEngagements },
    providers,
  } = useSelector(getProfile);
  const { levelOfEngagementStats } = useSelector(selectLoeReport);
  const toggleDeleteReport = () => {
    setShowDeleteReport(s => !s);
  };
  const handleDeleteReport = useCallback(() => {
    dispatch(reportingActionCreators.deleteReport(reportId));
    toggleDeleteReport();
    navigate('/admin/reports');
  }, [dispatch, navigate, reportId]);
  const getReportCall = () => {
    getReportById(reportId)
      .then(res => {
        setReport(res.data[0]);
        getElkPatientReport(getProfileReportQuery(res.data[0]), null)
          .then(resp => {
            dispatch(
              reportingActionCreators.setReportDetailTotalMembers(resp.data.aggregations.count.value || 0),
            );
            setShowAddReport(false);
            setIsLoading(false);
          })
          .catch(() => {
            setShowAddReport(false);
            setIsLoading(false);
          });
      })
      .catch(() => {
        setShowAddReport(false);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (!reportId) {
      navigate('/admin/reports');
    }
    setIsLoading(true);
    getReportCall();

    getAllPayers()
      .then(allPayers => {
        const pyrs = allPayers?.data?.data?.length ? allPayers?.data?.data : null;
        if (pyrs) {
          setpayers(pyrs);
        }
      })
      .catch(err => {
        console.log({ err });
      });
  }, []);

  const filteredSideNavList = useMemo(
    () => rightSideNavList.filter(navItem => report?.data[navItem.section]?.includeInReport),
    [rightSideNavList, report],
  );

  // const appointmentTypesCount = useMemo(() => {
  //   if (!report) {
  //     return 0;
  //   }
  //   let viewTypes = [];
  //   Object.keys(report.data).forEach(sectionKey => {
  //     const section = report.data[sectionKey];
  //     Object.keys(section).forEach(key => {
  //       if (typeof section[key] === 'object' && section[key].viewType) {
  //         const { viewType } = section[key];
  //         if (!viewTypes.includes(viewType)) {
  //           viewTypes = [...viewTypes, viewType];
  //         }
  //       }
  //     });
  //   });
  //   return viewTypes.length;
  // }, [report]);

  useEffect(() => {
    const getQuery = query.get('name');
    if (!getQuery) {
      query.set(
        'name',
        filteredSideNavList[0]?.query?.value ||
          filteredSideNavList[0]?.children[0]?.query?.value ||
          'appointments',
      );
      window.location.search = query.toString();
    } else {
      setSelectedScreen(getQuery);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [query]);

  const handleEditReport = useCallback(() => {
    setShowAddReport(true);
  }, []);

  const getFilterTitle = (filter: IReportFilter) => {
    switch (filter.type) {
      case REPORT_FILTERS.PROFILE_ELEMENT:
        return `Profile Element: ${filter?.profileElement}` || 'Profile Element';
      case REPORT_FILTERS.STATE:
        return 'States:';
      case REPORT_FILTERS.LEVEL_OF_ENGAGEMENT:
        return 'Level of Engagement:';
      case REPORT_FILTERS.PROVIDER:
        return 'Providers:';
      case REPORT_FILTERS.INSURANCE:
        return `Insurance: ${filter?.insurance?.state}` || 'Insurance';
      default:
        return 'Filter';
    }
  };
  const getFilterValue = (filter: IReportFilter) => {
    switch (filter.type) {
      case REPORT_FILTERS.PROFILE_ELEMENT:
        return filter?.rule && filter?.value ? `${filter?.rule} - ${filter.value}` : '';
      case REPORT_FILTERS.STATE:
        return filter?.states?.toString() || '';
      case REPORT_FILTERS.LEVEL_OF_ENGAGEMENT:
        return (
          filter?.levelOfEngagements
            ?.map(lvl => {
              const level = levelOfEngagementStats?.levelOfEngagementStatsCount?.find(
                l => l.levelOfEngagementStatus === lvl,
              );
              return level?.levelOfEngagementStatus;
            })
            ?.toString() || ''
        );
      case REPORT_FILTERS.PROVIDER:
        return (
          filter?.providers
            ?.map(prvdr => {
              const provider = providers.find(p => p.providerId === prvdr);
              return provider?.fullName;
            })
            ?.toString() || ''
        );
      case REPORT_FILTERS.INSURANCE:
        return (
          filter?.insurance?.carriers
            ?.map(crer => {
              const payer = payers.find(p => p._id === crer);
              return payer?.name;
            })
            ?.toString() || ''
        );
      default:
        return '';
    }
  };

  return (
    <>
      <DeleteReport
        onClose={toggleDeleteReport}
        onDeleteReport={() => handleDeleteReport()}
        open={showDeleteReport}
        selectedReport={selectedItem}
      />
      <AddReport
        report={report}
        isOpen={showNewReport}
        onClose={getReportCall}
        refreshReport={getReportCall}
        totalMembers={reportDetailTotalMembers || 0}
      />
      <BaseLayout
        rightSideNavList={filteredSideNavList}
        rightSideBarWidth="320px"
        rightSidebarHeadCustom={
          <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
            Report
          </Heading>
        }
      >
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Breadcrumbs
              links={[{ href: '/admin/reports', text: 'Reports' }, { text: report?.reportName }]}
            />
          </Box>
          <Box ref={componentRef}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack direction="column" gap={1}>
                <Stack direction="row" gap={2} alignItems="center">
                  <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                    {report?.reportName}
                  </Heading>
                  {!isLoading ? (
                    <Badge className={classes.topBadge} variant={badgeType.OUTLINED}>
                      {reportDetailTotalMembers} members in this report
                    </Badge>
                  ) : (
                    <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                  )}
                </Stack>
                <Text className={classes.subtitle}>
                  Created by <span className={classes.username}>{report?.createdBy.fullName}</span> •
                  {dayjs(report?.createdAt).format(`${GLOBAL_DATE_FORMAT} [at] hh:mmA`)}
                </Text>
              </Stack>
              <Menu
                className={classes.menu}
                icon="more"
                items={[
                  { label: 'Edit Report', onClick: handleEditReport },
                  {
                    label: 'Delete Report',
                    onClick: () => {
                      setSelectedItem(reportList.find(item => item._id === reportId));
                      toggleDeleteReport();
                    },
                  },
                ]}
              />
            </Stack>
            <Box display="flex" flexDirection="column" gap={4} sx={{ marginTop: 4 }}>
              <Box display="flex" flexDirection="row" gap={2} sx={{ marginBottom: 2 }}>
                {report?.reportFilters?.map(item => (
                  <InfoCard key={item._id} title={getFilterTitle(item)} value={getFilterValue(item)} />
                ))}
                {/* <InfoCard
                  key="appointmentTypes"
                  title="Appointment Types"
                  value={
                    <>
                      {appointmentTypesCount} - <span className={classes.username}>View types</span>
                    </>
                  }
                /> */}
              </Box>
              {selectedScreen === 'appointments' && report?.data?.appointmentsSection?.includeInReport && (
                <Appointments report={report} showNewReport={showNewReport} componentRef={componentRef} />
              )}
              {selectedScreen === 'demographics' && report?.data?.demoGraphicsSection?.includeInReport && (
                <Demographics report={report} showNewReport={showNewReport} componentRef={componentRef} />
              )}
              {selectedScreen === 'logins' && report?.data?.loginSection?.includeInReport && (
                <Logins report={report} showNewReport={showNewReport} />
              )}
              {selectedScreen === 'profile-elements' &&
                report?.data?.profileElementsSection?.includeInReport && (
                  <ProfileElements
                    report={report}
                    showNewReport={showNewReport}
                    componentRef={componentRef}
                  />
                )}
              {selectedScreen === 'chatbots' && report?.data?.chatbotSection?.includeInReport && (
                <Chatbots report={report} showNewReport={showNewReport} componentRef={componentRef} />
              )}
              {selectedScreen === 'chatbot-dcts' && report?.data?.chatbotDctSection?.includeInReport && (
                <DataCollectionTemplates
                  dctType={DctType.CHATBOT}
                  report={report}
                  showNewReport={showNewReport}
                  componentRef={componentRef}
                />
              )}
              {selectedScreen === 'evaluation-dcts' &&
                report?.data?.evaluationDctSection?.includeInReport && (
                  <DataCollectionTemplates
                    dctType={DctType.EVALUTATION}
                    report={report}
                    showNewReport={showNewReport}
                    componentRef={componentRef}
                  />
                )}
              {selectedScreen === 'evaluations' && report?.data?.evaluationsSection?.includeInReport && (
                <Evaluations report={report} showNewReport={showNewReport} componentRef={componentRef} />
              )}
              {selectedScreen === 'symptoms' && <Symptoms report={report} showNewReport={showNewReport} />}
              {selectedScreen === 'diagnoses' && <Diagnoses report={report} showNewReport={showNewReport} />}
              {selectedScreen === 'medications' && (
                <Medications report={report} showNewReport={showNewReport} componentRef={componentRef} />
              )}
              {selectedScreen === 'side-effects' && (
                <SideEffects report={report} showNewReport={showNewReport} componentRef={componentRef} />
              )}
              {selectedScreen === 'substance-use' && (
                <SubstanceUse report={report} showNewReport={showNewReport} componentRef={componentRef} />
              )}
              {selectedScreen === 'life-events' && (
                <LifeEvents report={report} showNewReport={showNewReport} componentRef={componentRef} />
              )}
              {selectedScreen === 'social-determinants' && (
                <SocialDeterminants
                  report={report}
                  showNewReport={showNewReport}
                  componentRef={componentRef}
                />
              )}
            </Box>
          </Box>
        </Box>
      </BaseLayout>
    </>
  );
};

export { ReportDetail };
