import { colors } from '@confidant-health/lib/colors';
import dayjs from 'utils/dayjs';
import { shortNumber } from 'utils/CommonUtils';

export const chartOptions = (dates: string[]) => ({
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  title: {
    text: '',
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  colors: [colors.primary500],
  stroke: {
    width: 2,
    colors: [colors.primary500],
    curve: 'smooth' as any,
  },
  xaxis: {
    tickAmount: 30,
    categories: dates,
    labels: {
      formatter: (val: string): string => {
        return dayjs(val, 'DD-MM-YYYY').format('MMM D');
      },
      style: {
        fontFamily: 'Manrope',
        fontWeight: 600,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    title: {
      text: '',
    },
  },
  tooltip: {
    style: {
      fontFamily: 'Manrope',
    },
    custom: ({ series, dataPointIndex }: Record<string, any>): string => {
      const time = dayjs(dates[dataPointIndex], 'DD-MM-YYYY').format('MMM DD, YYYY');
      // const num = +series[0][dataPointIndex];
      return `
          <div class="chart-tooltip">
            <div class="date">${time}</div>
            <div class="row">
              <div class="row-left">
                <div class="dot dot-provider"></div>
                <div class="title">Assignments</div>
              </div>
              <div class="value">${series[0][dataPointIndex]}</div>
            </div>
          </div>
        `;
    },
    y: {
      formatter: shortNumber,
      show: false,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    show: true,
    offsetY: 10,
    horizontalAlign: 'left' as any,
  },
});
