import cx from 'clsx';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Box, Checkbox } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import React, { FC, useEffect, useRef } from 'react';
import { CbStatus, EvaluationItemNode } from '../../../../redux/modules/conversation/types';
import { useStyles } from './InPersonSession.styles';

type props = {
  parentExpanded: boolean;
  item: EvaluationItemNode;
  currentBlock: EvaluationItemNode;
  setCurrentBlock: (block: EvaluationItemNode) => void;
  scrollRef: React.RefObject<HTMLDivElement>;
};
export const Question: FC<props> = ({ currentBlock, item, setCurrentBlock, parentExpanded, scrollRef }) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current && scrollRef?.current) {
      if (currentBlock?.cbId === item.cbId) {
        scrollRef.current.scrollTop = ref.current.offsetTop + 36 - 180;
      }
    }
  }, [scrollRef, currentBlock, item, ref]);
  return (
    <Box
      ref={ref}
      className={cx(classes.collapaseItemBox, {
        [classes.selectedQuestion]: currentBlock?.cbId === item.cbId && parentExpanded,
        [classes.nonSelectedQuestion]: currentBlock?.cbId !== item.cbId && parentExpanded,
        [classes.onlyCheckbox]: !parentExpanded,
      })}
      onClick={() => {
        if (item.isAttemptable) {
          setCurrentBlock(item);
        }
      }}
    >
      {parentExpanded && (
        <Text level={textLevel.S} weight={fontWeight.MEDIUM} className={classes.marginLeftQuestion}>
          {item.text}
        </Text>
      )}
      <Checkbox
        checked={item.status === CbStatus.ANSWERED}
        className={cx(classes.wh24, {
          [classes.marginRightCheckbox]: parentExpanded,
        })}
        checkedIcon={
          item.isAttemptable ? (
            <Icons glyph="check-circle" color={colors.success500} className={classes.circleIcon} />
          ) : (
            <Icons glyph="horizontal-line" color={colors.destructive600} className={classes.circleIcon} />
          )
        }
        icon={
          currentBlock?.cbId === item.cbId ? (
            <Icons glyph="black-circle-filled" color={colors.neutral900} className={classes.circleIcon} />
          ) : item.isAttemptable ? (
            <Icons glyph="circle-outlined" color={colors.neutral400} className={classes.circleIcon} />
          ) : (
            <Icons glyph="horizontal-line" color={colors.destructive600} className={classes.circleIcon} />
          )
        }
        // onChange={() => handleCheckboxChange(index)}
      />
    </Box>
  );
};
