/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
import { FC, useEffect, useMemo, useState, ReactNode, useRef } from 'react';
import clsx from 'clsx';
import { useDownloadExcel } from 'table-to-excel-react';

import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box, Stack } from '@mui/material';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';

import { toDateString } from 'utils/CommonUtils';
import { getElkDataDomainElementReport } from 'services/reporting/reporting.service';
import RecentAssignmentsTable from 'pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import { IReport } from 'redux/modules/reporting/types';
import { DDE_REPORT_TYPES, getMemberListdata, MemberListColumns } from '../../ReportDetail.constants';
import { ViewAllDrawer as ViewAllMembersDrawer } from '../appointments/ViewAllDrawer';
import HeadCard from '../head-card';

import {
  assignedColumns,
  mostImportantColumns,
  initImportantColumns,
  getSymptomQuery,
  getChartSeries,
} from './Symptoms.constants';
import { useStyles } from './Symptoms.styles';
import { ISymptomReport } from './Symptoms.types';
import { ViewAllDrawer } from '../data-collection-templates/ViewAllDrawer';
import StackChart from '../stack-chart';
import { DemographicsDetail } from '../demographics/demographics-detail/DemographicsDetail';

type Props = {
  report: IReport;
  showNewReport: boolean;
};

const Symptoms: FC<Props> = ({ report, showNewReport }) => {
  const [ddeReport, setDdeReport] = useState<ISymptomReport>(null);
  const [showViewAllType, setShowViewAllType] = useState(null);
  const classes = useStyles();
  const componentRef = useRef();
  const [selectedItem, setSelectedItemDetail] = useState(null);
  const { onDownload } = useDownloadExcel({
    fileName: 'symptoms_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const [showViewAllMembersType, setShowViewAllMembersType] = useState(null);
  const chartSeries = useMemo(() => getChartSeries(ddeReport), [ddeReport]);
  const memberListData = useMemo(() => {
    return getMemberListdata(ddeReport);
  }, [ddeReport]);
  const chartCategories = useMemo(
    () => ddeReport?.aggregations.top_ten_by_members.buckets.map(bucket => bucket.key) ?? [],
    [ddeReport],
  );
  const mostAssginedData = useMemo(
    () =>
      ddeReport?.aggregations.most_assigned.buckets.map(bucket => ({
        name: bucket.key,
        members: bucket.uniqueMembers.value,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.assignedAt),
      })) ?? [],
    [ddeReport],
  );
  const mostImportantData = useMemo(
    () =>
      ddeReport?.aggregations.most_count_by_importance.buckets.map(bucket => {
        return {
          name: bucket.key,
          level: bucket.importance_level.hits.hits[0]._source.dde_importance,
          members: bucket.uniqueMembers.value,
          lastUpdated: toDateString(bucket.last_modified_date.hits.hits[0]._source.lastModified),
        };
      }) ?? [],
    [ddeReport],
  );
  const mostChangedData = useMemo(() => {
    const buckets = [];
    ddeReport?.aggregations.most_changed_from_start.buckets.forEach(bucket => {
      bucket.by_importance?.buckets.forEach(b => {
        const foundInitial = b.by_initial_importance?.buckets?.filter(bi => bi.key !== b.key);
        if (foundInitial) {
          foundInitial?.forEach(fi => {
            buckets.push({
              name: bucket.key,
              members: fi.doc_count,
              level: b.key,
              initLevel: fi.key,
              lastUpdated: toDateString(fi.last_modified_date.hits.hits[0]._source.lastModified),
            });
          });
        }
      });
    });
    return buckets;
  }, [ddeReport]);
  const dataDomainElementSection = useMemo(() => report?.data?.dataDomainElementSection, [report]);

  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await getElkDataDomainElementReport(getSymptomQuery(report), null);
        setDdeReport(resp.data);
      })();
  }, [showNewReport]);

  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };

  const onClickAll = (type: string) => () => {
    setShowViewAllType(type || showViewAllType);
  };
  const onRowClick = item => {
    setSelectedItemDetail({ ...item, type: DDE_REPORT_TYPES.DEFAULT });
  };
  // eslint-disable-next-line react/display-name
  const renderMenu = () => () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };

  const onCloseViewAllMembers = () => {
    setShowViewAllMembersType(null);
  };

  const onClickAllMembers = (type: string) => () => {
    setShowViewAllMembersType(type || 'memberList');
  };
  const getNewColumns = columns =>
    columns.map(col => {
      if (col.id === 'level' || col.id === 'initLevel') {
        return {
          ...col,
          renderCell: (val: string) => {
            const key = val?.toUpperCase();
            return (
              <Badge style={badgeStyle[key]} variant={badgeType.FILLED} className={classes.badge}>
                {val}
              </Badge>
            );
          },
        };
      }
      return col;
    });

  const getContentsInViewAllMembers = (): {
    title: string;
    subtitle: string;
    tableColumns: TableGrid.TableColumn[];
    data: unknown[];
    renderMenuAction: (payload: any) => ReactNode;
  } => {
    if (showViewAllMembersType === 'memberList') {
      return {
        title: 'Member list',
        subtitle: `${memberListData.length} total`,
        tableColumns: MemberListColumns,
        data: memberListData.slice().reverse(),
        renderMenuAction: renderMenu(),
      };
    }
    return null;
  };

  const getContentsInViewAll = (): {
    title: string;
    subtitle: string;
    tableColumns: TableGrid.TableColumn[];
    data: unknown[];
    renderMenuAction: (payload: any) => ReactNode;
    onRowClick: (item: any) => void;
  } => {
    let contents = null;
    if (showViewAllType === 'mostAssigned') {
      contents = {
        title: 'Most assigned',
        subtitle: `${mostAssginedData.length} total`,
        tableColumns: assignedColumns,
        data: mostAssginedData,
        renderMenuAction: renderMenu(),
        onRowClick,
      };
    } else if (showViewAllType === 'leastAssigned') {
      contents = {
        title: 'Least assigned',
        subtitle: `${mostAssginedData.length} total`,
        tableColumns: assignedColumns,
        data: mostAssginedData.slice().reverse(),
        renderMenuAction: renderMenu(),
        onRowClick,
      };
    } else if (showViewAllType === 'mostImportantByCount') {
      contents = {
        title: 'Most important by count',
        subtitle: `${mostImportantData.length} total`,
        tableColumns: mostImportantColumns,
        data: mostImportantData,
        renderMenuAction: renderMenu(),
        onRowClick,
      };
    } else if (showViewAllType === 'mostChangedFromStart') {
      contents = {
        title: 'Most changed from start',
        subtitle: `${mostChangedData.length} total`,
        tableColumns: initImportantColumns,
        data: mostChangedData,
        renderMenuAction: renderMenu(),
        onRowClick,
      };
    }

    if (contents) {
      contents.tableColumns = getNewColumns(contents.tableColumns);
    }

    return contents;
  };

  const renderTable = (
    title,
    data,
    columns,
    onClickViewAll,
    renderMenuAction,
    className = '',
    disableOnRowClick = false,
  ) => {
    const newColoumns = getNewColumns(columns);
    return (
      <Box className={clsx(classes.tableWrap, className)} flex={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
            {title}
          </Text>
          <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
            View top ({data.length})
          </IconButton>
        </Stack>
        <RecentAssignmentsTable
          className={classes.tableBordered}
          isShowAll={false}
          tableColumns={newColoumns}
          data={data}
          renderMenuAction={renderMenuAction}
          onRowClick={disableOnRowClick ? null : onRowClick}
        />
      </Box>
    );
  };

  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {...[
          {
            title: 'Most Assigned Symptoms',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: assignedColumns,
            data: mostAssginedData as any,
          },
          {
            title: 'Most Importment By Count',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: mostImportantColumns,
            data: mostImportantData as any,
          },
          {
            title: 'Most changed from start',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: initImportantColumns,
            data: mostChangedData as any,
          },
          {
            title: 'Member List',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: MemberListColumns,
            data: memberListData,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };
  const onCloseViewDetail = () => {
    setSelectedItemDetail(null);
  };
  return (
    <>
      {renderAllTables()}
      <DemographicsDetail
        open={!!selectedItem}
        onClose={onCloseViewDetail}
        demographicsDetail={selectedItem}
      />
      <ViewAllMembersDrawer
        isOpen={!!showViewAllMembersType}
        onClose={onCloseViewAllMembers}
        {...getContentsInViewAllMembers()}
      />
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <Box className={classes.card}>
        <HeadCard title="Symptoms" componentRef={componentRef} onDownloadClick={onDownload} />
        <Box className={classes.card} ref={componentRef}>
          {dataDomainElementSection.top10tByMembers?.isChecked && (
            <Box className={classes.chartWrap}>
              <StackChart
                title="Top 10 symptoms by member"
                className={classes.chart}
                chart={{
                  series: chartSeries,
                  isDatetime: false,
                  categories: chartCategories,
                }}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {dataDomainElementSection.mostAssigned?.isChecked &&
              renderTable(
                'Most assigned symptoms',
                mostAssginedData,
                assignedColumns,
                onClickAll('mostAssigned'),
                renderMenu(),
                !dataDomainElementSection.leastAssigned?.isChecked ? classes.halfTable : '',
              )}
            {dataDomainElementSection.leastAssigned?.isChecked &&
              renderTable(
                'Least assigned symptoms',
                mostAssginedData.slice().reverse(),
                assignedColumns,
                onClickAll('leastAssigned'),
                renderMenu(),
                !dataDomainElementSection.mostAssigned?.isChecked ? classes.halfTable : '',
              )}
          </Box>
          {dataDomainElementSection.mostCountByImportance?.isChecked && (
            <Box className={classes.fullTable}>
              {renderTable(
                'Most important by count',
                mostImportantData,
                mostImportantColumns,
                onClickAll('mostImportantByCount'),
                renderMenu(),
              )}
            </Box>
          )}
          {dataDomainElementSection.mostChangedFromStart?.isChecked && (
            <Box className={classes.fullTable}>
              {renderTable(
                'Most changed from start',
                mostChangedData,
                initImportantColumns,
                onClickAll('mostChangedFromStart'),
                renderMenu(),
              )}
            </Box>
          )}
          <Box display="flex" justifyContent="space-around" className={classes.fullTable2}>
            {renderTable(
              'Member list',
              memberListData.slice().reverse(),
              MemberListColumns,
              onClickAllMembers('memberList'),
              '',
              classes.fullTable,
              true,
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { Symptoms };
