import { remove } from 'lodash';
import { specialItemKeys, SectionNames } from '../caqh-information/index.constants';
import { caqhDataSummary, uploadDocumentData } from '../../../../services/datalab/datalab.service';
import { flattenObject, getDataList } from './common.utils';

export const requiredKeys = {
  BoardCertification: ['boardName', 'specialty', 'certificateNumber', 'initialCertificateDate', 'expiryDate'],
  ProfessionalLicense: ['type', 'licenseNo', 'state', 'issueDate', 'expiryDate'],
  DeaCdsRegistration: ['licenseNo', 'state', 'issueDate', 'expiryDate', 'dataType'],
  Education: ['degree', 'major', 'address.state', 'schoolStartDate', 'schoolEndDate'],
  InsuranceInformation: [
    'carrierInsuredName',
    'policyNumber',
    'address.state',
    'currentEffectiveDate',
    'currentExpirationDate',
  ],
};

export const sectionDataType = {
  ProfessionalLicense: 'professional-licenses',
  DeaCdsRegistration: 'dea-cds-registration',
  Education: 'education',
  BoardCertification: 'board-certification',
  InsuranceInformation: 'insurance-information',
};

const camelCaseToText = (camelCase: string) => {
  return camelCase
    ?.replace(/([A-Z])/g, ' $1')
    ?.replace(/_/g, ' ')
    ?.replace(/.*\./, '')
    ?.trim();
};

const handleArrayValue = (arr, dataKeys, sectionKey, key) => {
  arr.forEach((item, index: number) => {
    const arrItem = getDataList(item, false, key, `${index + 1}`);
    const section = {
      section: !!sectionKey,
      label: `${camelCaseToText(sectionKey)?.toUpperCase()} ${index + 1}`,
    };

    const tempDataKeys = { filled: [], missing: [], list: [] };
    getFilledAndMissingKeys(arrItem, tempDataKeys);
    if (tempDataKeys.filled.length > 0) {
      dataKeys.filled?.push(section, ...tempDataKeys.filled);
    }
    if (tempDataKeys.missing.length > 0) {
      dataKeys.missing?.push(section, ...tempDataKeys.missing);
    }
    dataKeys.list?.push(section, ...tempDataKeys.list);
  });
};

const getFilledAndMissingKeys = (data, dataKeys) => {
  data.forEach(item => {
    const { value, label, key, badge } = item;
    if (Array.isArray(value)) {
      if (value?.length > 0) {
        handleArrayValue(value, dataKeys, label, key);
      }
    } else {
      item.key = key.replaceAll('.', '-');
      if (value || badge?.label) {
        dataKeys.filled?.push(item);
        dataKeys.list?.push(item);
      } else {
        dataKeys.missing?.push(item);
        dataKeys.list?.push(item);
      }
    }
  });
};

export const transformData = (data, type = '') => {
  const transformedData = flattenObject(data, '');
  const list = getDataList(transformedData);
  const filteredList = type === '' ? list : list.filter(item => requiredKeys[type]?.includes(item.key));
  const specialItems = remove(filteredList, item => Object.keys(specialItemKeys).includes(item.key));
  specialItems.forEach(item => {
    const { labelKey, hidden } = specialItemKeys[item.key];
    if (!hidden) {
      item.value.forEach(subItem => {
        const dataKeys = {
          filled: [],
          missing: [],
          list: [],
          title: subItem[labelKey],
        };
        getFilledAndMissingKeys([{ key: `${item.key}`, value: [subItem] }], dataKeys);
        filteredList.push({ key: labelKey, label: subItem[labelKey], value: dataKeys, type: 'nested' });
      });
    }
  });
  const dataKeys = {
    filled: [],
    missing: [],
    list: [],
  };
  getFilledAndMissingKeys(filteredList, dataKeys);
  return {
    list: dataKeys.list,
    filled: dataKeys.filled,
    missing: dataKeys.missing,
  };
};

export const getCaqhData = async ({ providerId, type }) => {
  try {
    const response = await caqhDataSummary({
      type,
      providerId,
    });
    if (response?.status === 200) {
      return response?.data?.data;
    }
  } catch (err) {
    const msg = err.response?.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    console.log(msg);
  }
  return null;
};
