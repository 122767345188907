// components
// import ScoredOutcomes from 'pages/admin/member-detail/ScoredOutcomes';
import Diagnoses from 'pages/provider-v2/member-detail/diagnoses';
import Medications from 'pages/provider-v2/member-detail/medications';
import SymptomaticFactors from 'pages/provider-v2/member-detail/symptomatic-factors';
import EnvironmentalFactors from 'pages/provider-v2/member-detail/environmental-factors';
import CognitiveIssues from 'pages/provider-v2/member-detail/cognitive-issues';

import { GeneralInformation, CareTeam, Chatbots, Activity, Groups, Notes } from './components';
import ProviderAppointments from './components/Appointments/ProviderAppointments';
// import Diagnoses from './components/Diagnoses';
import History from './components/History';
import LifeEvents from './components/LifeEvents';
// import Medications from './components/Medications';
// import PDMP from './components/PDMP';
import SideEffects from './components/SideEffects';
import SocialDeterminants from './components/SocialDeterminants';
import SubstanceUse from './components/SubstanceUse';
import Symptoms from './components/Symptoms';
import PlanItems from './components/PlanItems';
import ProviderReported from './components/ScoredOutcomes/provider-reported';
import MemberReported from './components/ScoredOutcomes/member-reported';
import Evaluations from './components/Evaluations';
import InsuranceInformation from './components/insurance-information';
import Invoices from './components/invoices';
import Dashboard from './components/Dashboard/MemberDashboard';

type ComponentT = React.FC<{
  selectedScreen: string;
  toggle?: (screen: string) => void;
}>;

const SelectedComponent: ComponentT = ({ selectedScreen, toggle }) => {
  switch (selectedScreen) {
    case 'general-information':
      return <GeneralInformation />;
    case 'care-team':
      return <CareTeam />;
    case 'chatbots':
      return <Chatbots />;
    // case 'scored-outcomes':
    //   return <ScoredOutcomes />;
    case 'activity':
      return <Activity />;
    case 'groups':
      return <Groups />;
    case 'plan-items':
      return <PlanItems />;
    case 'notes':
      return <Notes />;
    // case 'pdmp-checks':
    //   return <PDMP />;
    case 'appointments-current':
    case 'appointments-past':
    case 'appointments-pending':
      return <ProviderAppointments selectedScreen={selectedScreen} toggle={toggle} />;
    case 'history':
      return <History />;
    case 'symptoms':
      return <Symptoms />;
    case 'diagnoses':
      return <Diagnoses />;
    // return <Diagnoses />;
    case 'medications':
      return <Medications />;
    case 'side-effects':
      return <SideEffects />;
    case 'substance-use':
      return <SubstanceUse />;
    case 'life-events':
      return <LifeEvents />;
    case 'social-determinants':
      return <SocialDeterminants />;
    case 'insurance-information':
      return <InsuranceInformation />;
    case 'provider-reported':
      return <ProviderReported />;
    case 'member-reported':
      return <MemberReported />;
    case 'evaluations':
      return <Evaluations />;
    case 'invoices':
      return <Invoices />;
    case 'dashboard':
      return <Dashboard />;
    case 'symptomatic-factor':
      return <SymptomaticFactors />;
    case 'environmental-factor':
      return <EnvironmentalFactors />;
    case 'cognitive-issues':
      return <CognitiveIssues />;
    default:
      return <div>In Progress...</div>;
  }
};

export default SelectedComponent;
