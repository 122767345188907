import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// components
import { Box } from '@mui/material';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { iconBtnType, IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import history from 'utils/history';
import { stateSelector } from 'redux/modules/state/selectors';
import { stateActionCreators } from 'redux/modules/state/actions';
import { IPayer, IPlan } from 'redux/modules/state/types';
import { uploadPayerImage } from 'services/state/state.service';

import { AddPayerDrawer } from './add-payer';

// styles
import { useStyles } from '../../StateDetail.styles';

const Payers: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { stateId } = useParams();
  const { payers, selectedState, isLoading } = useSelector(stateSelector);

  const [openPayerDrawer, setOpenPayerDrawer] = useState<boolean>(false);
  const [selectedPayer, setSelectedPayer] = useState<IPayer>();

  const viewPayerDetail = (payerId: string) => {
    history.push(`/admin/states/${stateId}/${payerId}`);
  };

  const onClickAdd = () => {
    setSelectedPayer(null);
    setOpenPayerDrawer(true);
  };

  const onClickUpdate = (payer: IPayer) => {
    setSelectedPayer(payer);
    setOpenPayerDrawer(true);
  };

  const onSubmit = (payload: IPayer, isImageUpdated: boolean) => {
    if (selectedPayer) {
      if (isImageUpdated) {
        const newPayload = {
          name: payload.name,
          image: payload.image,
        };

        // API call for upload image
        uploadPayerImage(newPayload)
          .then(() => {
            console.log('image uploaded successfully');
          })
          .catch(error => console.log({ error }));
      }
      delete payload.image;
      dispatch(
        stateActionCreators.updatePayer({
          bodyRequest: payload,
          pathParams: { stateId: selectedState._id, payerId: selectedPayer._id },
          callback: () => {
            dispatch(stateActionCreators.fetchPayers(stateId));
            setOpenPayerDrawer(false);
          },
        }),
      );
    } else {
      dispatch(
        stateActionCreators.addPayer({
          bodyRequest: payload,
          pathParams: { stateId: selectedState._id },
          callback: () => {
            dispatch(stateActionCreators.fetchPayers(stateId));
            setOpenPayerDrawer(false);
          },
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(stateActionCreators.fetchPayers(stateId));
  }, []);

  const renderPlan = (plan: IPlan) => {
    return (
      <Box className={classes.payerSection}>
        <Box display="flex" alignItems="center" gap={2}>
          <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD}>
            {plan.name}
          </Text>
          <Badge
            variant={badgeType.OUTLINED}
            style={plan.isActive ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
            className={classes.badge}
          >
            <span>{plan.isActive ? 'Active' : 'Inactive'}</span>
          </Badge>
        </Box>
        <Box display="flex" gap={5}>
          <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
            {plan.providers?.length} provider{plan?.providers?.length > 1 ? 's' : ''}
          </Text>
          <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
            {plan.cptCodes?.length} CPT code{plan?.cptCodes?.length > 1 ? 's' : ''}
          </Text>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <AddPayerDrawer
        isOpen={openPayerDrawer}
        selectedPayer={selectedPayer}
        onSubmit={onSubmit}
        onClose={() => setOpenPayerDrawer(false)}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={2}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            {selectedState?.name}
          </Heading>
          <Badge
            variant={badgeType.FILLED}
            style={selectedState?.isActive ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
          >
            {selectedState?.isActive ? 'Active' : 'Inactive'}
          </Badge>
        </Box>

        <IconButton
          icon="plus"
          variant={iconBtnType.PRIMARY}
          className={classes.iconButton}
          iconPosition={iconBtnPosition.LEFT}
          onClick={onClickAdd}
        >
          Add Payer
        </IconButton>
      </Box>

      {isLoading ? (
        <div className={classes.loader}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          Loading...
        </div>
      ) : (
        payers?.map((payer: IPayer) => (
          <Box key={payer.name} className={classes.card}>
            <Box className={classes.cardTopSection}>
              <Box display="flex" alignItems="center" gap={2}>
                <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.headingText}>
                  {payer?.name}
                </Heading>
                <Badge
                  variant={badgeType.FILLED}
                  style={payer?.isActive ? badgeStyle.ACTIVE : badgeStyle.INACTIVE}
                  size={badgeSize.SMALL}
                >
                  <span>{payer?.isActive ? 'Active' : 'Inactive'}</span>
                </Badge>
              </Box>

              <Box className={classes.action}>
                <IconButton
                  icon="arrow-right"
                  variant={iconBtnType.TEXT}
                  className={classes.linkBtn}
                  onClick={() => viewPayerDetail(payer?._id)}
                >
                  See Details
                </IconButton>
                <IconButton
                  variant={iconBtnType.SECONDARY}
                  className={classes.linkBtn}
                  onClick={() => onClickUpdate(payer)}
                >
                  Update
                </IconButton>
              </Box>
            </Box>

            {payer?.plans?.map((plan: IPlan) => (
              <Box key={plan?._id} className={classes.payersContentSection}>
                {renderPlan(plan)}
              </Box>
            ))}
          </Box>
        ))
      )}
    </>
  );
};

export { Payers };
