import { FC } from 'react';
import MuiAvatar from '@mui/material/Avatar';
import { S3_BUCKET_URL } from '@confidant-health/lib/constants/CommonConstants';
import cx from 'clsx';
import { stringAvatar } from 'utils';
import { bgColors } from './Avatar.constants';
import { IAvatarProps, AvatarColor, AvatarSize, AvatarShape } from './Avatar.types';
import { useStyles } from './Avatar.styles';

const Avatar: FC<IAvatarProps> = ({
  src = '',
  name = '',
  shape = AvatarShape.CIRCULAR,
  size = AvatarSize.XSMALL,
  color = AvatarColor.PRIMARY,
  className = '',
  children,
  ...rest
}) => {
  const classes = useStyles({ isRounded: shape === AvatarShape.ROUNDED });

  const url = src ? (src.includes('http') ? src : `${S3_BUCKET_URL}${src}`) : '';
  const customStyles = url
    ? { sx: { bgcolor: bgColors[color] } }
    : { ...stringAvatar(name || rest.alt), sx: { bgcolor: bgColors[color] } };

  if (children) {
    return (
      <MuiAvatar
        {...customStyles}
        className={cx({
          [classes.root]: true,
          [classes.xsmall]: size === AvatarSize.XSMALL,
          [classes.small]: size === AvatarSize.SMALL,
          [className]: className,
        })}
        src={url}
        variant={shape}
        {...rest}
      >
        {children}
      </MuiAvatar>
    );
  }

  return (
    <MuiAvatar
      {...customStyles}
      className={cx({
        [classes.root]: true,
        [classes.xsmall]: size === AvatarSize.XSMALL,
        [classes.small]: size === AvatarSize.SMALL,
        [className]: className,
      })}
      src={url}
      variant={shape}
      {...rest}
    />
  );
};

export { Avatar };
