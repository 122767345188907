import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Heading, Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { TextField, textFieldType } from '@confidant-health/lib/ui/atoms/text-field';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { ISlot } from 'redux/modules/appointment/types';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import { Tooltip } from '@confidant-health/lib/ui/atoms/tooltip';

import { IProfileContent } from '../ProfileCard.types';
import { useStyles } from './ProfileContentCard.styles';
import ServiceItem from '../../services/service-item';

type Props = {
  content?: IProfileContent;
};

const ProfileContentCard: FC<Props> = ({ content }) => {
  const classes = useStyles();
  const providerSchedule = useSelector(selectProviderSchedule);
  const { meta, isAdmin } = useSelector(getAuth);
  const { description, profile, badges, affiliation, workingDays, services, insurances } = content;
  const [availableStates, setAvailableStates] = useState<any>([]);

  useEffect(() => {
    if (insurances) {
      const statesAndPayers = insurances.map(itm => {
        const state = [];
        state.push(itm.state);
        itm.insurances.map(insurance => {
          return state.push(insurance.name);
        });
        return state;
      });
      setAvailableStates(statesAndPayers);
    }
  }, [insurances]);
  const renderContentDuration = (title: string, durations: ISlot[], className = '') => {
    return (
      <Box display="flex" flexDirection="column" gap="16px">
        <Box className={classes.titleDuration}>{title}</Box>
        <Box display="flex" flexWrap="wrap" gap="8px">
          {!durations ? (
            <Box className={clsx(classes.descriptionAffiliation, className)}>Day is set as unavailable</Box>
          ) : (
            durations.map((item, index) => {
              const timeStart = dayjs(item.start).tz(providerSchedule.timezone).format('hh:mm a');
              const timeEnd = dayjs(item.end).tz(providerSchedule.timezone).format('hh:mm a');

              return (
                <Box key={index} className={classes.contentDuration}>
                  <span>{`${timeStart} - ${timeEnd}`}</span>
                </Box>
              );
            })
          )}
        </Box>
      </Box>
    );
  };

  const renderInfo = (label: string, text: string) => {
    return (
      <Grid item md={1} xs={3}>
        <Box className={classes.cutTextWrapper}>
          <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            {!label.includes('##') ? label : label.replace('##', '')}
          </Text>

          <Text
            className={clsx(classes.textDark, classes.cutText)}
            level={textLevel.M}
            weight={fontWeight.MEDIUM}
          >
            <Tooltip title={text} placement="top-start">
              <span>{text}</span>
            </Tooltip>
          </Text>
        </Box>
      </Grid>
    );
  };

  return (
    <Box className={classes.cardContent}>
      {profile && (
        <Box>
          <Box className={classes.wrapperUserProfile}>
            <ProfileInfo
              type="provider"
              photo={profile.avatar}
              fullName={
                profile?.name
                  ? profile?.name
                  : profile?.firstName && profile?.lastName
                  ? `${profile?.firstName} ${profile?.lastName}`
                  : meta.nickName
              }
              role={profile?.designation}
              isProvider
            />
            <Box display="flex" alignItems="center">
              {providerSchedule?.timezone && (
                <span className={classes.profileTimeZone}>
                  {providerSchedule.timezone} -{' '}
                  {dayjs(new Date()).tz(providerSchedule.timezone).format('hh:mm a')}
                </span>
              )}
              <Badge
                className={classes.badge}
                variant={badgeType.OUTLINED}
                style={profile.activeInApp ? badgeStyle.RESOLVED : badgeStyle.UNRELATED}
              >
                <span className={clsx(classes.dot, { [classes.activeDot]: profile.activeInApp })} />{' '}
                {profile.activeInApp ? 'Active' : 'Inactive'}
              </Badge>
            </Box>
          </Box>
          <Box>
            <Grid container columns={4} rowSpacing="32px" paddingTop={2}>
              {renderInfo('First name', profile.firstName ?? '-')}
              {renderInfo('Last name', profile.lastName ?? '-')}
              {profile.emailAddress && renderInfo('Email', profile.emailAddress)}
              {profile.phoneNumber && renderInfo('Phone', profile.phoneNumber)}
            </Grid>
          </Box>
          <Box>
            <Grid container columns={4} rowSpacing="32px" paddingTop={2}>
              {renderInfo('Address', profile.practitionerAddress?.address1 ?? '-')}
              {renderInfo('City', profile.practitionerAddress?.city ?? '-')}
              {renderInfo('State', profile.practitionerAddress?.state ?? '-')}
              {renderInfo('Zip', profile.practitionerAddress?.zipCode ?? '-')}
            </Grid>
          </Box>
          {(profile.emailAddress || profile.phoneNumber) && (
            <Grid container columns={4} rowSpacing="32px" paddingTop={2}>
              {renderInfo('Accepting adolescents', profile.acceptingAdolescents ? 'Yes' : 'No')}
              {renderInfo('Tax ID', profile.taxId ?? '-')}
              {isAdmin && renderInfo('Billing Provider', profile.billingProvider ? 'Yes' : 'No')}
              {profile.npi && renderInfo('NPI', profile.npi)}
            </Grid>
          )}
          {isAdmin && (
            <Box>
              <Grid container columns={4} rowSpacing="32px" paddingTop={2}>
                {renderInfo('Zip +4 code', profile.practitionerAddress?.zipPlusFourCode ?? '-')}
                {profile?.providerAvailabilityCalendar &&
                  renderInfo('External calendar', profile?.providerAvailabilityCalendar ?? '-')}
              </Grid>
            </Box>
          )}
          {description && (
            <Box className={classes.descriptionBox}>
              <Text className={classes.textLight} level={textLevel.XS} weight={fontWeight.SEMI_BOLD}>
                About
              </Text>

              <Text
                className={clsx(classes.textDark, classes.about)}
                level={textLevel.S}
                weight={fontWeight.MEDIUM}
              >
                <Tooltip title={description} placement="top-start">
                  <span>{description}</span>
                </Tooltip>
              </Text>
            </Box>
          )}
        </Box>
      )}
      {description && !profile && (
        <Box>
          <Text className={classes.textDescription}>{description}</Text>
        </Box>
      )}
      {insurances && (
        <>
          {insurances?.length > 0 ? (
            <Box display="flex" flexWrap="wrap" gap="5px" flexDirection="column">
              {availableStates.map((item, index) => (
                <Box key={index} display="flex" flexWrap="wrap" gap="5px" flexDirection="row" mb="10px">
                  {item.map((itm, idx) =>
                    idx === 0 ? (
                      <Box key={idx} className={classes.stateContent}>
                        {`${itm}  `}{' '}
                      </Box>
                    ) : (
                      <Box key={idx} className={classes.badgeContent}>
                        {itm}
                      </Box>
                    ),
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <Text className={classes.descriptionAffiliation}>None</Text>
          )}
        </>
      )}
      {badges && (
        <>
          {badges?.length > 0 ? (
            <Box display="flex" flexWrap="wrap" gap="5px">
              {badges.map((item, index) => (
                <Box key={index} className={classes.badgeContent}>
                  {item}
                </Box>
              ))}
            </Box>
          ) : (
            <Text className={classes.descriptionAffiliation}>None</Text>
          )}
        </>
      )}
      {affiliation && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {affiliation.title ? (
            <>
              <Box display="flex" gap="24px" alignItems="center">
                <Avatar size={64} variant={avatarType.SQUARE} src={affiliation.avatar} />
                <Box>
                  <Heading className={classes.titleAffiliation}>{affiliation.title}</Heading>
                  <Text className={classes.descriptionAffiliation}>{affiliation.description}</Text>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Box className={classes.textDateAffiliation}>
                  {dayjs(affiliation.startDate).format('MMMM DD, YYYY -')}
                </Box>
                <Box className={classes.textDateAffiliation}>
                  {dayjs(affiliation.endDate).format('MMMM DD, YYYY')}
                </Box>
              </Box>
            </>
          ) : (
            <Text className={classes.descriptionAffiliation}>None</Text>
          )}
        </Box>
      )}
      {workingDays && (
        <Box display="flex" flexDirection="column" gap="32px">
          {renderContentDuration('Sunday', workingDays.sunday, classes.widthAuto)}
          {renderContentDuration('Monday', workingDays.monday, classes.widthAuto)}
          {renderContentDuration('Tuesday', workingDays.tuesday, classes.widthAuto)}
          {renderContentDuration('Wednesday', workingDays.wednesday, classes.widthAuto)}
          {renderContentDuration('Thursday', workingDays.thursday, classes.widthAuto)}
          {renderContentDuration('Friday', workingDays.friday, classes.widthAuto)}
          {renderContentDuration('Saturday', workingDays.saturday, classes.widthAuto)}
        </Box>
      )}
      {services && (
        <>
          {services.length > 0 ? (
            <Box display="flex" flexDirection="column" gap="32px">
              {services.map(item => (
                <ServiceItem service={item} key={item.id} />
              ))}
            </Box>
          ) : (
            <Text className={classes.descriptionAffiliation}>None</Text>
          )}
        </>
      )}
    </Box>
  );
};

export { ProfileContentCard };
