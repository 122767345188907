import { FC, memo } from 'react';
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { getProfile } from 'redux/modules/profile/selectors';

import { useHistoryPatient } from 'hooks/useHistoryPatient';

import { useStyles } from '../../MemberDetail.styles';
import { useStyles as useHistoryStyles } from './History.styles';
import PriorityEmpty from '../PriorityEmpty';

const History: FC = memo(() => {
  const classes = useStyles({});
  const historyCls = useHistoryStyles();
  const { memberId } = useParams();
  const { items, isLoading, error } = useHistoryPatient({ patientId: memberId });
  const { selectedPatient } = useSelector(getProfile);

  const renderCheckIcon = isChecked => (
    <Box>
      <Icons
        glyph="checkmark"
        color={isChecked ? colors.success500 : colors.destructive500}
        className={historyCls.icon}
      />
    </Box>
  );

  const renderContent = (item: { name: string; value: string; child?: string[]; checked?: boolean }) => (
    <TableRow className={historyCls.row}>
      <TableCell>
        <Text className={historyCls.label}>{item.name}</Text>
      </TableCell>
      <TableCell>
        <Box className={historyCls.rowCell}>
          {item.checked !== undefined && renderCheckIcon(item.checked)}
          <Text className={historyCls.value}>{item.value}</Text>
        </Box>
      </TableCell>
      <TableCell>
        {item.child?.length > 1 ? (
          <ul className={historyCls.liItem}>
            {item.child.map((note, index) => (
              <li key={index}>
                <Text className={historyCls.label}>{note.split('_').join(' ')}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Text className={historyCls.label}>{item.child?.[0]}</Text>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <div className={classes.headingWithBtnWrapper}>
        <div className={classes.noteHeaderLeftSide}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            History
          </Heading>
          {false && (
            <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.totalBadge}>
              {21} total
            </Badge>
          )}
        </div>
      </div>
      <div className={clsx(classes.noteCardWrapper, historyCls.historyContainer)}>
        <Table>
          <TableBody>
            {isLoading || error ? (
              <TableRow className={historyCls.row}>
                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                  {error ? (
                    <Text className={historyCls.value}>{error}</Text>
                  ) : (
                    <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                  )}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {items?.length > 0 ? (
                  items.map(renderContent)
                ) : (
                  <PriorityEmpty
                    title={`We don't have ${selectedPatient.member.nickName}'s Medical History yet.`}
                    description="This should appear after their first evaluation..."
                    glyph="face-talking-amazed"
                  />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
});

History.displayName = 'History';

export { History };
