import { FC, useState } from 'react';

// components
import { Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

import { getChartOpts } from './ReportChartCard.utils';

// styles
import { useStyles } from './ReportChartCard.styles';

type Props = {
  inProgress?: number;
  completed?: number;
  notStarted?: number;
};

const ReportChartCard: FC<Props> = ({ inProgress, completed, notStarted }) => {
  const classes = useStyles();
  const [series] = useState([completed || 0, inProgress || 0, notStarted || 0]);

  return (
    <Box className={classes.card}>
      <ReactApexChart
        options={getChartOpts(
          'Assignments completed',
          `${completed}/${inProgress + notStarted + completed}`,
          series,
        )}
        series={series}
        type="donut"
        width={750}
        height={262}
      />
    </Box>
  );
};

export { ReportChartCard };
