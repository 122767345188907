import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text, TextError, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input } from '@confidant-health/lib/ui/atoms/input';
import MuiInputAdornment from '@mui/material/InputAdornment';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { LoginType } from 'constants/CommonConstants';
import { updatePassword } from 'services/auth/auth.service';
import { showSnackbar } from 'redux/modules/snackbar';

// schema
import { ResetPasswordSchema } from './ResetPasswordSchema';
// styles
import { useStyles } from './ResetPassword.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  error?: string;
  email?: string;
};

const ResetPassword: React.FC<Props> = ({ isOpen, onClose, email, error }) => {
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(true);
  const [, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [savingButton, setSavingButton] = useState(false);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async values => {
      const params = {
        code: '1234',
        email,
        newPassword: values.newPassword,
        confirmPassword: values.confirmNewPassword,
        userType: LoginType.PROVIDER,
      };
      setIsLoading(true);

      try {
        await updatePassword(params);
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Password Updated Successfully',
          }),
        );
        onClose();
      } catch (err) {
        console.warn('error', err);
        setErrorMsg(err?.data?.message);
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Password Update Failed',
          }),
        );
      }
      setSavingButton(false);
      setIsLoading(false);
    },
  });

  const classes = useStyles();

  const onBlurInput = async e => {
    await formik.setFieldTouched(e.target.name);
  };

  const onNewPasswordClickEye = () => {
    setShowNewPassword(!showNewPassword);
  };

  const onConfirmPasswordClickEye = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const onUpdateButtonClick = () => {
    setSavingButton(true);
  };

  // Re enable update button when there are errors
  useEffect(() => {
    if (Object.keys(formik.errors).length >= 1) {
      setSavingButton(false);
    }
  }, [formik.errors, savingButton]);

  useEffect(() => {
    if (!isOpen) {
      setSavingButton(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      formik.setErrors({});
    }
  }, [isOpen]);

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>Reset Password</Text>
          <IconButton icon="close" onClick={onClose} className={classes.closeBtn} />
        </Box>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center" paddingY={2}>
                <TextError errorMsg={error} />
              </Box>
            )}

            <div className={classes.inputContainer}>
              <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                New Password
              </Text>
              <Input
                className={classes.input}
                name="newPassword"
                placeholder="Enter your password"
                type={showNewPassword ? 'password' : 'text'}
                onChange={formik.handleChange}
                onBlur={onBlurInput}
                InputProps={{
                  startAdornment: (
                    <MuiInputAdornment position="start">
                      <Icons glyph="password-lock" color={colors.neutral500} />
                    </MuiInputAdornment>
                  ),
                  endAdornment: (
                    <MuiInputAdornment position="end">
                      <Icons
                        glyph="eye-outlined"
                        className={classes.eye}
                        color={colors.neutral500}
                        onClick={onNewPasswordClickEye}
                      />
                    </MuiInputAdornment>
                  ),
                }}
              />
              <TextError
                errorMsg={formik.touched.newPassword ? formik.errors.newPassword?.toString() : null}
              />
            </div>
            <div className={classes.inputContainer}>
              <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                Confirm New Password
              </Text>
              <Input
                className={classes.input}
                name="confirmNewPassword"
                placeholder="Enter your password"
                type={showConfirmNewPassword ? 'password' : 'text'}
                onChange={formik.handleChange}
                onBlur={onBlurInput}
                InputProps={{
                  startAdornment: (
                    <MuiInputAdornment position="start">
                      <Icons glyph="password-lock" color={colors.neutral500} />
                    </MuiInputAdornment>
                  ),
                  endAdornment: (
                    <MuiInputAdornment position="end">
                      <Icons
                        glyph="eye-outlined"
                        className={classes.eye}
                        color={colors.neutral500}
                        onClick={onConfirmPasswordClickEye}
                      />
                    </MuiInputAdornment>
                  ),
                }}
              />
              <TextError
                errorMsg={
                  formik.touched.confirmNewPassword ? formik.errors.confirmNewPassword?.toString() : null
                }
              />
            </div>
            {errorMsg && (
              <div className={classes.inputContainer}>
                <Text className={classes.errorText} level={textLevel.S}>
                  {errorMsg}
                </Text>
              </div>
            )}
          </Box>

          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onUpdateButtonClick();
                formik.handleSubmit();
              }}
              disabled={savingButton}
            >
              {savingButton ? 'Saving...' : 'Update'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { ResetPassword };
