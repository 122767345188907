import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';

// components
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { useQuery } from '@confidant-health/lib/hooks';

import {
  AppointmentStatus,
  AppointmentStatusText,
  AppointmentStatusBagde,
  AppointmentType,
} from '@confidant-health/lib/constants/CommonConstants';

import { getTimeZoneText } from 'constants/CommonConstants';
import history from 'utils/history';
import dayjs, { getUserTimeZone } from 'utils/dayjs';
import { isAppointmentDone } from 'utils/CommonUtils';

import { appointmentActionCreators } from 'redux/modules/appointment';
import { reportServiceActionCreators } from 'redux/modules/report-service';
import { profileActionCreators } from 'redux/modules/profile';
import { getReportService } from 'redux/modules/report-service/selectors';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import AppointmentDetailDrawer from 'pages/admin/appointments/appointment-detail-drawer';
import AddSchedule, { INewSchedulePayload } from 'pages/admin/appointments/add-schedule';

import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { DROPDOWN_FILTER_COLLAPSABLE_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';
import { getProfile } from 'redux/modules/profile/selectors';
import { conversationActionCreators } from 'redux/modules/conversation';
import { selectEvaluation } from '../../../../redux/modules/conversation/selectors';

import {
  appointmentColumns,
  appointmentFilterStatusOptions,
  AppointmentTitles,
  appointmentSorterMap,
  currentAppointmentsFilterOption,
  pastAppointmentsFilterOption,
  pendingAppointmentsFilterOption,
} from './AppointmentList.constants';

// styles
import { useStyles } from './AppointmentList.styles';
import { ISearchAppointmentParams } from '../../../../services/appointment/appointment.service';

const AppointmentList: FC = () => {
  const classes = useStyles();
  const { providerId } = useParams();
  const { isAdmin } = useSelector(getAuth);
  const query = useQuery();
  const dispatch = useDispatch();
  const [appointmentType, setAppointmentType] = useState('current');
  const { profile } = useSelector(getProfile);
  const evaluations = useSelector(selectEvaluation);

  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(false);
  const [screenChangeLoading, setScreenChangeLoading] = useState(false);

  const filterOptions = appointmentFilterStatusOptions[appointmentType];
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });
  const [title, setTitle] = useState(AppointmentTitles.current);
  const [appointments, setAppointments] = useState([]);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<IAppointment | null>(null);

  const providerSchedule = useSelector(selectProviderSchedule);
  const { isLoading, appointments: appointmentsState = [], totalRecords } = useSelector(getReportService);

  useEffect(() => {
    const name = query.get('name');
    if (name) {
      setAppointmentType(name?.split('-')[1]);
    }
  }, [query]);

  const multiSelectFilterOptions = useMemo(() => {
    setTableParams({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: {
        direction:
          appointmentType === AppointmentType.PAST || appointmentType === AppointmentType.CURRENT
            ? 'desc'
            : 'asc',
        column: '',
      },
    });
    if (
      tableParams.search?.multiSelectFilter &&
      Object.keys(tableParams.search?.multiSelectFilter)?.length > 0
    ) {
      setResetMultiSelectFilter(true);
    }
    setScreenChangeLoading(true);
    setTimeout(() => {
      setScreenChangeLoading(false);
    }, 100);
    switch (appointmentType) {
      case AppointmentType.CURRENT:
        return currentAppointmentsFilterOption;
      case AppointmentType.PAST:
        return pastAppointmentsFilterOption;
      default:
        return pendingAppointmentsFilterOption;
    }
  }, [appointmentType]);

  const onClickAppointment = (appointment: IAppointment) => {
    if (appointmentType === 'past' && appointment.status === AppointmentStatus.FULFILLED) {
      dispatch(profileActionCreators.updateAppointmentNote(appointment));
      history.push(`/admin/members/${appointment.participantId}/appointments/${appointment.appointmentId}`);
      return;
    }
    dispatch(
      conversationActionCreators.fetchAssignedEvaluations({ appointmentId: appointment.appointmentId }),
    );
    setSelectedAppointment(appointment);
  };

  const generateMultiselectParams = () => {
    const { pagination, sorter } = tableParams;
    const sortBy = appointmentSorterMap[sorter.column] || 'startTime';
    const page = pagination.currentPage - 1;
    const size = pagination.rowsPerPage;
    const orderBy = sorter.direction;
    const statuses = appointmentType === AppointmentType.CURRENT ? 'BOOKED' : '';
    const queryParams: ISearchAppointmentParams = {
      orderBy,
      page,
      size,
      sortBy,
      statuses,
      type: appointmentType.toUpperCase(),
      states: null,
      searchQuery: tableParams.search.searchKey,
    };

    if (appointmentType === AppointmentType.PAST) {
      queryParams.orderBy = 'desc';
    }
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    if (multiselectFilters && Object.keys(multiselectFilters)?.length > 0) {
      if (multiselectFilters.State?.length > 0) {
        queryParams.states = multiselectFilters.State?.toString() ?? '';
      }
      if (multiselectFilters.Role?.length > 0) {
        queryParams.practitionerDesignations = multiselectFilters.Role?.toString() ?? '';
      }
      if (multiselectFilters[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.length > 0) {
        queryParams.participantIds =
          multiselectFilters[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]
            ?.map(provider => {
              return provider.id;
            })
            ?.toString() ?? '';
      }
      if (appointmentType === AppointmentType.CURRENT && multiselectFilters.Status?.length > 0) {
        if (multiselectFilters.Status[0] === 'Scheduled') {
          queryParams.dateStatus = 'NOT_TODAY';
        } else if (multiselectFilters.Status[0] === 'Today') {
          queryParams.dateStatus = 'TODAY';
        }
        queryParams.timeZone = getUserTimeZone();
      } else if (appointmentType === AppointmentType.PAST && multiselectFilters.Status?.length > 0) {
        queryParams.statuses =
          multiselectFilters.Status.map(item => {
            if (item === 'NO SHOW') {
              return AppointmentStatus.NO_SHOW;
            }
            return item;
          })?.toString() ?? '';
      }
    }
    if (!queryParams.states) {
      delete queryParams.states;
    }

    return { queryParams };
  };

  const fetchAppointments2 = (pId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId: pId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const fetchAppointments = () => {
    const { queryParams } = generateMultiselectParams();
    dispatch(
      reportServiceActionCreators.fetchAppointments({
        queryParams: { ...queryParams, participantIds: providerId },
      }),
    );
  };

  const onSubmitNewSchedule = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed: boolean, isInstantSession: boolean, appointmentId: string) => {
          if (isSucceed) {
            setShowAddSchedule(false);
            fetchAppointments();
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  useEffect(() => {
    setTitle(AppointmentTitles[appointmentType]);
    fetchAppointments();
  }, [appointmentType, tableParams]);

  useEffect(() => {
    const formatAppointments = appointmentsState.map(item => ({
      ...item,
      appointmentStatus: {
        status: item.status,
        time: item.startTime,
        signOffStatus: item.signOffStatus,
        patientStatus: item.patientStatus,
        practitionerStatus: item.practitionerStatus,
      },
      appointmentTime: {
        timezone: item.providerTimezone,
        startTime: item.startTime,
        endTime: item.endTime,
        isRecurring: !!item.recurringType,
      },
      actions: {
        appointmentId: item.appointmentId,
        disabled: isAppointmentDone(item.status),
      },
      provider: {
        practitionerName: item.practitionerName,
        practitionerImage: item.practitionerImage,
        practitionerDesignation: item.practitionerDesignation,
      },
      member: {
        participantImage: item.participantImage,
        patientFirstName: item.patientFirstName,
        patientLastName: item.patientLastName,
        participantName: item.participantName,
      },
    }));

    setAppointments(formatAppointments);
  }, [appointmentsState]);

  const renderColumns = useCallback(() => {
    return appointmentColumns.map(column => {
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ practitionerName, practitionerImage, practitionerDesignation }: any) => (
            <ProfileInfo
              type="provider"
              photo={practitionerImage}
              role={practitionerDesignation}
              fullName={practitionerName}
            />
          ),
        };
      }
      if (column.id === 'member') {
        return {
          ...column,
          renderCell: ({ participantImage, patientFirstName, patientLastName, participantName }: any) => (
            <ProfileInfo
              type="member"
              photo={participantImage}
              nickName={participantName}
              fullName={`${patientFirstName || ''} ${patientLastName || ''}`}
            />
          ),
        };
      }
      if (column.id === 'appointmentStatus') {
        return {
          ...column,
          renderCell: ({
            status,
            time,
            signOffStatus,
            practitionerStatus,
            patientStatus,
            statusChangingAuthority,
          }) => {
            const isToday = dayjs(time).isSame(dayjs(), 'day');
            let statusText = AppointmentStatusText[status];
            if (patientStatus === AppointmentStatus.NEEDS_ACTION) {
              statusText = 'Waiting on Member';
            } else if (practitionerStatus === AppointmentStatus.NEEDS_ACTION) {
              statusText = 'Waiting on Provider';
            } else if (status === AppointmentStatus.CANCELLED) {
              if (patientStatus === AppointmentStatus.DECLINED) {
                statusText = 'Cancelled - M';
              } else if (practitionerStatus === AppointmentStatus.DECLINED) {
                statusText = 'Cancelled - P';
              }
            }
            if (status === AppointmentStatus.NO_SHOW) {
              if (statusChangingAuthority === 'PATIENT') {
                statusText = 'No Show - M';
              } else if (statusChangingAuthority === 'PRACTITIONER') {
                statusText = 'No Show - P';
              }
            }
            if (status === AppointmentStatus.BOOKED) {
              statusText = isToday ? 'Today' : 'Scheduled';
            }
            if (signOffStatus === 'DRAFTED') {
              statusText = 'Waiting for Sign Off';
            }
            return (
              <Badge
                variant={badgeType.FILLED}
                style={
                  isToday
                    ? badgeStyle.MISREPORTED
                    : signOffStatus === 'DRAFTED'
                    ? (AppointmentStatusBagde[signOffStatus] as badgeStyle)
                    : ((AppointmentStatusBagde[status] || badgeStyle.UNRELATED) as badgeStyle)
                }
                className={classes.status}
              >
                {statusText?.toLowerCase()?.includes('cancel') ? (
                  <span className={classes.cancelledStatusText}>{statusText}</span>
                ) : (
                  statusText
                )}
              </Badge>
            );
          },
        };
      }
      if (column.id === 'appointmentTime') {
        return {
          ...column,
          renderCell: ({ startTime, endTime, timezone, isRecurring }: any) => (
            <div className={classes.timeWrap}>
              <div className={clsx(classes.date, classes.row)}>
                {dayjs(startTime).format('MMMM DD, YYYY')}
                {isRecurring && <Icons glyph="recurring" color={colors.secondary500} />}
              </div>
              <div className={classes.date}>{getTimeZoneText(timezone)}</div>
              <div className={classes.time}>
                {dayjs(startTime).format('h:mma')} - {dayjs(endTime).format('h:mma')}
              </div>
            </div>
          ),
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: (appointment: IAppointment) => {
            return (
              <IconButton
                icon="chevron-right"
                className={classes.arrowIconBtn}
                onClick={() => onClickAppointment(appointment)}
              />
            );
          },
        };
      }
      return column;
    });
  }, []);

  return (
    <>
      <AppointmentDetailDrawer
        open={!!selectedAppointment}
        onClose={() => setSelectedAppointment(null)}
        appointment={selectedAppointment}
        evaluations={evaluations}
        fetchAppointments={() => fetchAppointments()}
      />
      <AddSchedule
        isOpen={showAddSchedule}
        // isSaving={isRequesting}
        onClose={() => setShowAddSchedule(false)}
        onSubmit={onSubmitNewSchedule}
        providerId={providerId}
      />
      <Box className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              {title}
            </Heading>
            {!isLoading && (
              <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
                {totalRecords} total
              </Badge>
            )}
          </Stack>
          <IconButton
            icon="appointment"
            variant={btnType.PRIMARY}
            className={classes.addBtn}
            onClick={() => setShowAddSchedule(true)}
          >
            Schedule new
          </IconButton>
        </Stack>
        <Box className={classes.appointmentList}>
          {screenChangeLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <Table
              searchProps={{
                placeholder: 'Search',
                resetMultiSelectFilter,
                filterProps: {
                  variant: Filter.tableFilterType.MULTIPLE,
                  options: filterOptions,
                  multiSelectOptions: multiSelectFilterOptions,
                },
              }}
              gridProps={{
                columns: renderColumns(),
                data: appointments,
                isLoading,
                onRowClick: onClickAppointment,
              }}
              paginationProps={{
                currentRows: tableParams.pagination.rowsPerPage,
                totalCount: totalRecords,
                showRowsPerPage: true,
              }}
              value={tableParams}
              onChange={setTableParams}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export { AppointmentList };
