import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';

import { IAddPlanPayload } from 'redux/modules/state/types';

// schema
import { AddPlanSchema } from './PayerDetail.schema';

// styles
import { useStyles } from './PayerDetail.styles';

interface IAddPlanProps {
  isOpen: boolean;
  selectedPlan: IAddPlanPayload;
  onSubmit: (payload: IAddPlanPayload) => void;
  onClose: () => void;
}
const AddPlanDrawer: FC<IAddPlanProps> = ({ isOpen, selectedPlan, onSubmit, onClose }) => {
  const classes = useStyles();

  const defaultValues = {
    name: selectedPlan?.name || '',
    code: selectedPlan?.code || '',
    isActive: selectedPlan?.isActive || false,
  };

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    enableReinitialize: true,
    validationSchema: AddPlanSchema,
    onSubmit: (payload: IAddPlanPayload) => {
      onSubmit(payload);
    },
  });

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{selectedPlan ? 'Edit' : 'Add'} Plan</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Plan Name')}
              <Input
                fullWidth
                name="name"
                value={values.name}
                placeholder="Enter a plan name"
                size={inputSize.M}
                onBlur={setTouched('name')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.name ? errors.name : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Plan Code')}
              <Input
                fullWidth
                name="code"
                value={values.code}
                placeholder="Enter a plan code"
                size={inputSize.M}
                onBlur={setTouched('code')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.code ? errors.code : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Status')}
              <FormControlLabel
                control={<Checkbox />}
                checked={values?.isActive}
                name="isActive"
                onChange={() => {
                  void setFieldValue('isActive', !values?.isActive);
                }}
                label="Active"
              />
              <TextError errorMsg={touched.isActive ? errors.isActive : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddPlanDrawer };
