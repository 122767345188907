import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import {
  Heading,
  Text,
  fontWeight,
  headingLevel,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import cx from 'clsx';
import { colors } from '@confidant-health/lib/colors';
import { Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Icons } from '@confidant-health/lib/icons';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Box, Grid, Stack } from '@mui/material';

import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProfile } from 'redux/modules/profile/selectors';
import { BaseLayout } from 'layouts/base';
import { insertIfObj } from 'utils/InsertIf';
import {
  getAllReferredPatientsByPartnerId,
  getRefferedPartnerMemberStats,
} from 'services/member/member.service';
import { getReportList } from 'services/reporting/reporting.service';
import dayjs from 'dayjs';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';

import Card from './Card';

import { useStyles } from './index.styles';
import { referralPartnersDummy, reportsTableColumns, tableColumns } from './index.constant';
import { AddReport } from '../reports/add-report/AddReport';

const ReferralPartner = () => {
  const classes = useStyles();
  const { partnerId } = useParams();
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalReports, setTotalReports] = useState(0);
  const [isLoadingPatients, setIsLoadingPatients] = useState(false);
  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const [reports, setReports] = useState([]);
  const [referralPatients, setReferralPatients] = useState([]);
  const [stats, setStats] = useState({} as any);
  const [isAddReportBtnDisabled] = useState(false);
  const [showAddReportDrawer, setShowAddReportDrawer] = useState(false);
  const { referralPartners } = useSelector(getProfile);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [reportsTableParams, setReportsTableParams] = useState({
    search: {
      searchKey: '',
    },
    pagination: {
      rowsPerPage: 10,
      currentPage: 1,
    },
    sorter: {
      direction: 'asc',
      column: 'createdBy',
    },
  } as tableParamsType);
  const [patientsTableParams, setPatientsTableParams] = useState({
    search: {
      searchKey: '',
    },
    pagination: {
      rowsPerPage: 10,
      currentPage: 0,
    },
  } as tableParamsType);

  const toggleAddReportDrawer = () => {
    setShowAddReportDrawer(!showAddReportDrawer);
  };
  const columns = useMemo(
    () =>
      tableColumns.map(column => ({
        ...column,
        renderCell: text => (
          <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
            {text}
          </Text>
        ),
        ...insertIfObj(column.id === 'patient', {
          renderCell: ({ firstName, lastName, fullName, profileImage, userAccountId }) => (
            <ProfileInfo
              type="member"
              photo={profileImage}
              fullName={`${firstName} ${lastName}`}
              nickName={fullName}
              memberId={selectedPartner?.patientDetail ? userAccountId : null}
            />
          ),
        }),
        ...insertIfObj(column.id === 'appointmentsThisMonth', {
          renderCell: (appointmentsThisMonth: number) =>
            appointmentsThisMonth > 0 ? (
              <Badge variant={badgeType.OUTLINED} size={badgeSize.SMALL} style={badgeStyle.UNRELATED}>
                {appointmentsThisMonth} appointments
              </Badge>
            ) : (
              <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
                N-A
              </Text>
            ),
        }),
        ...insertIfObj(column.id === 'dob', {
          renderCell: dob => (
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
              {dob && dayjs(dob).format(GLOBAL_DATE_FORMAT)}
            </Text>
          ),
        }),
        ...insertIfObj(column.id === 'startDate', {
          renderCell: startDate => (
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
              {startDate && dayjs(startDate).format(GLOBAL_DATE_FORMAT)}
            </Text>
          ),
        }),
        ...insertIfObj(column.id === 'status', {
          renderCell: status => (
            <Badge variant={badgeType.OUTLINED} size={badgeSize.SMALL} style={badgeStyle.ACTIVE}>
              {status}
            </Badge>
          ),
        }),
        ...insertIfObj(column.id === 'actions', {
          renderCell: ({ userAccountId }) => (
            <IconButton
              icon="chevron-right"
              className={classes.menu}
              onClick={() => {
                if (selectedPartner?.patientDetail)
                  navigate(`/admin/members/dashboard/${userAccountId}/${partnerId}`);
              }}
            />
            // <Menu
            //   icon="chevron-right"
            //   className={classes.menu}
            //   itemsWrapperClassName={classes.menuItemsWrapper}
            //   items={
            //     [
            //       // {
            //       //   icon: <Icons glyph="edit-underline" className={classes.icon} />,
            //       //   label: 'Edit partner',
            //       //   onClick: () => null,
            //       // },
            //       // {
            //       //   icon: <Icons glyph="copy-outlined" className={classes.icon} />,
            //       //   label: 'Delete partner',
            //       //   onClick: () => null,
            //       // },
            //     ]
            //   }
            // />
          ),
        }),
      })),
    [tableColumns, referralPartnersDummy, selectedPartner],
  );

  const reportsColumns = useMemo(
    () =>
      reportsTableColumns.map(column => ({
        ...column,
        renderCell: reportName => (
          <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
            {reportName}
          </Text>
        ),
        ...insertIfObj(column.id === 'createdBy', {
          renderCell: ({ firstName, lastName, fullName, userAccountId }) => (
            <ProfileInfo
              type="provider"
              photo=""
              fullName={fullName ?? `${firstName} ${lastName}`}
              memberId={userAccountId}
            />
          ),
        }),
        ...insertIfObj(column.id === 'createdAt', {
          renderCell: createdAt => (
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
              {createdAt && dayjs(createdAt).format(GLOBAL_DATE_FORMAT)}
            </Text>
          ),
        }),
        ...insertIfObj(column.id === 'updatedAt', {
          renderCell: updatedAt => (
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral900}>
              {updatedAt && dayjs(updatedAt).format(GLOBAL_DATE_FORMAT)}
            </Text>
          ),
        }),
        ...insertIfObj(column.id === 'actions', {
          renderCell: ({ _id }) => (
            <IconButton
              icon="chevron-right"
              className={classes.menu}
              onClick={() => navigate(`/admin/reports/${_id}`)}
            />
            // <Menu
            //   icon="chevron-right"
            //   className={classes.menu}
            //   itemsWrapperClassName={classes.menuItemsWrapper}
            //   items={
            //     [
            //       // {
            //       //   icon: <Icons glyph="edit-underline" className={classes.icon} />,
            //       //   label: 'Edit partner',
            //       //   onClick: () => null,
            //       // },
            //       // {
            //       //   icon: <Icons glyph="copy-outlined" className={classes.icon} />,
            //       //   label: 'Delete partner',
            //       //   onClick: () => null,
            //       // },
            //     ]
            //   }
            // />
          ),
        }),
      })),
    [tableColumns, referralPartnersDummy],
  );

  const handleFetchReportsParamsFormat = ({ search, pagination }: tableParamsType) => {
    const params = {
      searchQuery: search.searchKey || '',
      pageNumber: pagination.currentPage || 1,
      pageSize: pagination.rowsPerPage || 10,
      referralPartnerId: partnerId,
    };

    return params;
  };
  const formatReferralpatients = patients => {
    return patients?.map(patient => {
      return {
        ...patient,
        patient,
        appointmentsThisMonth: patient?.appointmentsCount || 0,
        actions: patient,
      };
    });
  };
  const fetchPatients = () => {
    setIsLoadingPatients(true);
    getAllReferredPatientsByPartnerId(
      {
        pageNumber: patientsTableParams?.pagination?.currentPage || 0,
        pageSize: patientsTableParams?.pagination?.rowsPerPage || 10,
      },
      { refPartnerId: partnerId },
    )
      .then(res => {
        setTotalPatients(res.data?.totalRecords || 0);
        setReferralPatients(formatReferralpatients(res.data?.referredPatientList || []));
        setIsLoadingPatients(false);
      })
      .catch(() => {
        setIsLoadingPatients(false);
      });
  };
  const fetchReports = () => {
    setIsLoadingReports(true);
    getReportList(handleFetchReportsParamsFormat(reportsTableParams))
      .then(res => {
        setTotalReports(res.data?.paginator?.itemCount || 0);
        setReports(
          res.data?.itemsList?.map(item => {
            return {
              ...item,
              actions: item,
            };
          }) || [],
        );
        setIsLoadingReports(false);
      })
      .catch(() => {
        setIsLoadingReports(false);
      });
  };
  const filterSelectedReferralPartner = () => {
    if (referralPartners) {
      const foundPartner = referralPartners?.partners?.find(item => item?.refPartnerId === partnerId);
      if (foundPartner) {
        setSelectedPartner(foundPartner);
      }
    }
  };
  useEffect(() => {
    filterSelectedReferralPartner();
  }, [referralPartners]);

  useEffect(() => {
    fetchPatients();
    fetchReports();
    getRefferedPartnerMemberStats({ referralPartnerId: partnerId })
      .then(res => {
        setStats(res.data?.data || {});
      })
      .catch(err => console.log({ err }));
    filterSelectedReferralPartner();
  }, []);

  useEffect(() => {
    if (!showAddReportDrawer) fetchReports();
  }, [reportsTableParams, showAddReportDrawer]);

  useEffect(() => {
    fetchPatients();
  }, [patientsTableParams]);
  return (
    <BaseLayout>
      <AddReport isOpen={showAddReportDrawer} onClose={toggleAddReportDrawer} />
      <div className={classes.root}>
        <div className={classes.header}>
          <Breadcrumbs
            links={[
              { href: '/admin/referral-partners', text: 'Referral partners' },
              { text: selectedPartner?.name || (state as any)?.partnerName || '' },
            ]}
          />
        </div>
      </div>
      <Stack direction="column" gap={5}>
        <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
          Referral partner - {selectedPartner?.name || (state as any)?.partnerName || ''}
        </Heading>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Card
                label="Total patients referred"
                icon="group-outlined"
                count={stats.patientsReferred || 0}
                color={colors.neutral50}
                iconColor={colors.neutral500}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Card
                label="total patients accepted"
                icon="checkmark"
                count={stats.patientAccepted || 0}
                color={colors.success50}
                iconColor={colors.success500}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Card
                label="total patients active"
                icon="profile-arrow-left-outlined"
                count={stats.patientsActive || 0}
                color={colors.primary50}
                iconColor={colors.primary500}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Card
                label="Referred this month"
                icon="group-outlined"
                count={stats.referredThisMonth || 0}
                color={colors.orange50}
                iconColor={colors.orange500}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Card
                label="average claim"
                icon="dollar-square"
                count={`$${stats.averageClaim || 0}`}
                color={colors.purple50}
                iconColor={colors.purple500}
              />
            </Grid>
          </Grid>
        </Box>
        {selectedPartner?.patientList && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Text level={textLevel.L} weight={fontWeight.BOLD}>
              Patients referred
            </Text>
            <Table
              className={classes.table}
              gridProps={{
                columns,
                onRowClick: item =>
                  selectedPartner?.patientDetail &&
                  navigate(`/admin/members/dashboard/${item?.userAccountId}/${partnerId}`),
                data: referralPatients || [],
                isLoading: isLoadingPatients,
              }}
              paginationProps={{
                currentRows: referralPatients?.length || 0,
                totalCount: totalPatients || 0,
              }}
              value={patientsTableParams}
              onChange={setPatientsTableParams}
            />
          </Box>
        )}
        {selectedPartner?.reportList && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text level={textLevel.L} weight={fontWeight.BOLD}>
                Reports
              </Text>
              <IconButton
                variant={iconBtnType.SECONDARY}
                onClick={toggleAddReportDrawer}
                disabled={isAddReportBtnDisabled}
                className={cx(classes.addReportBtn, {
                  [classes.addReportBtnDisabled]: isAddReportBtnDisabled,
                })}
              >
                New report
                <Icons glyph="plus" color="currentColor" />
              </IconButton>
            </Box>
            <Table
              className={classes.table}
              gridProps={{
                columns: reportsColumns,
                onRowClick: item => navigate(`/admin/reports/${item._id}`),
                data: reports,
                isLoading: isLoadingReports,
              }}
              paginationProps={{
                currentRows: reports?.length || 0,
                totalCount: totalReports || 0,
              }}
              value={reportsTableParams}
              onChange={setReportsTableParams}
            />
          </Box>
        )}
      </Stack>
    </BaseLayout>
  );
};

export default ReferralPartner;
