/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

const commonText = {
  fontFamily: 'Roboto-Regular',
  color: '#30344D',
};
export const desText = {
  fontFamily: 'Roboto-Regular',
  color: '#646c73',
  fontSize: 20,
  fontWeight: '100',
};
export const useStyles = makeStyles({
  badge: {
    fontSize: 12,
    height: 18,
    lineHeight: '16px',
  },
  container: {
    height: '100%',
    flex: 1,
    position: 'relative',
    zIndex: 1,
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    flexDirection: 'column',
  },
  alfieContainer: {
    boxShadow: 'none',
    borderLeft: `1px solid ${colors.neutral100}`,
  },
  feedbackHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
    background: colors.neutral25,
    borderBottom: `1px solid ${colors.neutral100}`,
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
    height: 80,
  },
  cancelFeedbackBtn: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary300,
    width: 40,
    height: 40,
    padding: 0,
    minWidth: 0,
    marginRight: 16,
  },
  header: {
    height: 'auto',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  info: {
    borderRadius: 6,
    backgroundColor: colors.neutral50,
    padding: 16,
    marginTop: 10,
    marginBottom: 10,
  },
  infoTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral700,
    margin: 0,
    marginBottom: 8,
  },
  infoKey: {
    fontSize: 14,
    lineHeight: '20px',
    display: 'block',
    margin: 0,
  },
  infoValue: {
    fontWeight: 500,
    color: colors.neutral700,
  },
  infoValueNoData: {
    fontWeight: 400,
    color: colors.neutral700,
  },
  title: {
    fontSize: 18,
    lineHeight: '30px',
    margin: 0,
  },
  additionalNotesTitle: {
    fontSize: 14,
    lineHeight: '20px',
    marginTop: 20,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    color: colors.neutral600,
  },
  responderType: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.textSecondary,
    margin: 0,
  },
  body: {
    padding: 24,
    backgroundColor: colors.white,
    overflowY: 'auto',
    flexGrow: 1,
    height: 'calc(100vh - 370px)',
  },
  exBody: {
    '& h1': {
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      fontSize: 40,
      lineHeight: '48px',
      color: colors.neutral900,
      paddingBottom: 24,
    },
    '& h2': {
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      fontSize: 36,
      lineHeight: '44px',
      color: colors.neutral900,
      paddingBottom: 24,
    },
    '& h3': {
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '40px',
      color: colors.neutral900,
      paddingBottom: 24,
    },
    '& p': {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '28px',
      color: colors.neutral700,
    },
    '& ul': {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '28px',
      color: colors.neutral700,
    },
    '& a': {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '28px',
      color: '#0001EE',
    },
    '& img': {
      borderRadius: 16,
    },
  },
  questionsBox: {},
  questionsList: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  questionsHeading: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    color: colors.neutral900,
  },
  questionsText: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: colors.neutral900,
  },
  choicesText: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    color: colors.neutral700,
  },
  pointer: {
    cursor: 'pointer',
  },
  addPersonalNote: {
    paddingTop: 64,
  },
  footer: {
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    boxShadow:
      '0px -24px 32px rgba(0, 0, 0, 0.04), 0px -16px 24px rgba(0, 0, 0, 0.04), 0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  nextBtn: {
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primary600,
      color: colors.white,
    },
    height: 48,
  },
  previousBtn: {
    gap: 12,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
  previousResponseBox: {
    padding: '16px',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  aiFeedbackBox: {
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  aiBtn: {
    height: 40,
    color: colors.neutral800,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  prevText: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.neutral600,
  },
  prevResponseItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  prevResponseValue: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    lineHeight: '20px',
  },
  noMatchIcon: {
    color: '#dd2121',
    fontSize: 24,
    borderRadius: 25,
    boxShadow: '0px 0px 1px 7px #fee3e3',
    marginRight: '15px',
  },
  fullMatchIcon: {
    color: '#218d27',
    fontSize: 24,
    borderRadius: 25,
    boxShadow: '0px 0px 1px 7px #e9f9ea',
    backgroundColor: '#e9f9ea',
    marginRight: '15px',
  },
  resolvedIcon: {
    color: '#0374DD',
    fontSize: 24,
    borderRadius: 25,
    boxShadow: '0px 0px 1px 7px #E3F1FF',
    backgroundColor: '#E3F1FF',
    marginRight: '15px',
  },
  partialMatchIcon: {
    color: '#de8618',
    fontSize: 24,
    borderRadius: 25,
    boxShadow: '0px 0px 1px 7px #fef3c8',
    marginRight: '15px',
  },
  useText: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  heading1: {
    ...commonText,
    fontFamily: 'Roboto-Bold',
    fontSize: 32,
    fontWeight: '700',
  },
  heading2: {
    ...commonText,
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    fontWeight: '700',
  },
  heading3: {
    ...commonText,
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    fontWeight: '700',
  },
  heading4: {
    ...commonText,
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
  },
  heading5: {
    ...commonText,
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
  },
  heading6: {
    ...commonText,
    fontSize: 11,
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
  },
  linkText: {
    ...desText,
    textDecorationLine: 'underline',
    fontStyle: 'italic',
    color: '#4FACFE',
  },
});
