import { FC } from 'react';

import { IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';

import { useStyles } from './ProfileHeaderCard.styles';

interface Props {
  title: string;
  onEdit?: () => void;
}

const ProfileHeaderCard: FC<Props> = ({ title, onEdit }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardHeader}>
      <div className={classes.wrapperTitleHeader}>
        <span>{title}</span>
      </div>

      {onEdit && (
        <IconButton
          icon="edit-underline"
          onClick={onEdit}
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.btnEdit}
        >
          Edit
        </IconButton>
      )}
    </div>
  );
};

export { ProfileHeaderCard };
