import { FC } from 'react';

// components
import { Box, Grid } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';

// styles
import { useStyles } from './ReportChartCard.styles';

// types
import { IProfileElementDistribution } from '../../ProfileElementDetail.types';

// constants
import { DEFAULT_DONUT_CHART_VALUES, GRAPH_COLORS } from '../../ProfileElementDetail.constants';

interface Props {
  profileElementType: string;
  profileElementDistribution: IProfileElementDistribution;
}

const ReportChartCard: FC<Props> = ({ profileElementType, profileElementDistribution }) => {
  const classes = useStyles();
  const chartSeries = [];
  const chartLabels = [];
  let chartColors = [];

  const sortedValues = profileElementDistribution.values.sort((a, b) => b.percentage - a.percentage);
  sortedValues.slice(0, DEFAULT_DONUT_CHART_VALUES).forEach(value => {
    chartSeries.push(Math.round(value.percentage));
    chartLabels.push(value.name);
  });
  chartColors = GRAPH_COLORS.slice(0, sortedValues.length);

  const othersPercentage = Math.round(
    sortedValues
      .slice(DEFAULT_DONUT_CHART_VALUES)
      .map(value => value.percentage)
      .reduce((a, b) => Number(a) + Number(b), 0),
  );
  if (othersPercentage > 0) {
    chartSeries.push(othersPercentage);
    chartLabels.push('Others');
    chartColors.push(colors.neutral500);
  }

  const options = {
    labels: chartLabels,
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
        },
      },
    },
  };

  return (
    <Box className={classes.card}>
      <ReactApexChart options={options} series={chartSeries} type="donut" width={240} height={270} />
      <Box className={classes.stats}>
        <Text weight={fontWeight.BOLD} className={classes.statsTotal}>
          {profileElementDistribution.totalPatients}
        </Text>
        <Text weight={fontWeight.BOLD} className={classes.statsLabel}>
          Patients with any value for PE
        </Text>
      </Box>
      <Box className={classes.legend}>
        <Box className={classes.legendTitle}>{profileElementType}</Box>
        <Grid container spacing={2} mt={2}>
          {chartSeries.map((item, index) => (
            <Grid item key={index} xs={12} md={6} className={classes.legendItem}>
              <Box className={classes.legendMarker} bgcolor={chartColors[index]} />
              <Box>{chartLabels[index]}</Box>
              <Box className={classes.legendBadge}>{item}%</Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { ReportChartCard };
