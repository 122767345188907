import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Stepper } from '@confidant-health/lib/ui/molecules/stepper';
import { useQuery } from '@confidant-health/lib/hooks';

import { conversationActionCreators } from 'redux/modules/conversation/actions';
import {
  selectConversations,
  selectDctState,
  selectProfileElementList,
  selectTags,
  selectPlanItems,
  selectEvaluation,
} from 'redux/modules/conversation/selectors';
import { selectProviderRoles } from 'redux/modules/appointment/selectors';
import { AppState } from 'redux/store/types';
import { profileActionCreators } from 'redux/modules/profile';
import { stateActionCreators } from 'redux/modules/state';
import { stateSelector } from 'redux/modules/state/selectors';
import { getProfile } from 'redux/modules/profile/selectors';

// components
import AutomationEvent from './automation-event';
import AutomationFilters from './automation-filters';
import AutomationAction from './automation-action';

// types
import {
  IAddAutomationProps,
  IAutoACTION,
  IAutomationEvent,
  IAutomationFilterLogic,
} from './AddAutomation.types';
// styles
import { useStyles } from './AddAutomation.styles';
import { stepTitles } from './AddAutomation.constants';
import { automationTypes } from '../Automations.constants';
import { automationLogicSelect } from './automation-filters/automation-filter-form/AutomationFilterForm.constants';

export const DEFAULT_FILTER = {
  structuredCondition: {
    automationLogics: [],
    ruleAggregator: null,
  },
};

const defaultEvent = {
  name: '',
  reference: '',
  ruleName: '',
};

const DEFAULT_ACTION = {
  reference: '',
  type: '',
  repeat: {
    interval: 'none',
    value: 0,
  },
  delay: {
    interval: 'none',
    value: 0,
  },
};

const AddAutomation: React.FC<IAddAutomationProps> = ({
  isOpen,
  onClose,
  educationList,
  editList,
  isEditMode,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();

  const conversations = useSelector(selectConversations);
  const { dcts } = useSelector(selectDctState);
  const groups = useSelector((state: AppState) => state.profile.group.groups);
  const profileElementList = useSelector(selectProfileElementList);
  const providers = useSelector((state: AppState) => state.profile.providers);
  const providerRoles = useSelector(selectProviderRoles);
  const tags = useSelector(selectTags);
  const evaluations = useSelector(selectEvaluation);
  const planItems = useSelector(selectPlanItems);

  const conversationOptions = useMemo(
    () =>
      conversations.map(cv => ({
        label: cv.version ? `${cv.name}_V${cv.version}` : cv.name,
        value: cv.conversationId,
      })),
    [conversations],
  );
  const dctOptions = useMemo(
    () =>
      dcts.map(dct => ({ label: dct.version ? `${dct.name}_V${dct.version}` : dct.name, value: dct.dctId })),
    [dcts],
  );
  const profileElementOptions = useMemo(
    () =>
      profileElementList.map(pe => ({
        label: pe.profileElementInfo.key,
        value: pe.profileElementInfo.key,
      })),
    [profileElementList],
  );
  const groupOptions = useMemo(
    () => groups?.map(group => ({ label: group.name, value: group.id })),
    [groups],
  );
  const educationOptions = useMemo(
    () => educationList.map(ed => ({ label: ed.fields.title, value: ed.sys.id })),
    [educationList],
  );
  const providerRoleOptions = useMemo(
    () => providerRoles.map(pr => ({ label: pr.name, value: pr.userId })),
    [providerRoles],
  );
  const evaluationsOptions = useMemo(
    () => evaluations.map(ev => ({ label: ev.name, value: ev.evaluationId })),
    [evaluations],
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [event, setEvent] = useState<IAutomationEvent>({});
  const [filter, setFilters] = useState<IAutomationFilterLogic>(DEFAULT_FILTER);
  const [action, setAction] = useState<IAutoACTION>(DEFAULT_ACTION);
  const { states } = useSelector(stateSelector);
  const {
    demographicMetadata: { levelOfEngagements },
  } = useSelector(getProfile);
  const { payers, isLoading } = useSelector(stateSelector);
  const formatValue = (valueData: string[], type: string) => {
    return valueData?.map((value: string) => {
      let foundItem = {} as any;
      switch (type) {
        case automationLogicSelect[2].value:
          foundItem = levelOfEngagements.find(lvl => lvl.id === value);
          if (foundItem)
            return {
              label: foundItem.name,
              value,
            };
          return { label: value, value };
        case automationLogicSelect[3].value:
          foundItem = providers.find(prvdr => prvdr.providerId === value);
          if (foundItem) {
            return {
              label: foundItem.fullName,
              value,
            };
          }
          return { label: value, value };

        case automationLogicSelect[4].value:
          foundItem = payers?.find(pyr => pyr._id === value);
          if (foundItem) {
            return {
              label: foundItem.name,
              value,
            };
          }
          return { label: value, value };
        default:
          return { label: value, value };
      }
    });
  };
  const getValueForEdit = (flters: any) => {
    return {
      ...flters,
      structuredCondition: {
        ...flters?.structuredCondition,
        automationLogics: flters?.structuredCondition?.automationLogics?.map(fltr => {
          if (fltr.type === automationLogicSelect[0].value) return fltr;
          if (fltr.type === automationLogicSelect[4].value) {
            const foundState = states.find(state => state?.state?.name === fltr.key);
            if (foundState) {
              dispatch(stateActionCreators.fetchPayers(foundState._id));
            }
          }
          const vals = fltr?.value?.split(',');
          return { ...fltr, value: formatValue(vals, fltr.type) };
        }),
      },
    };
  };
  const getEventValuesForEdit = (edtList: any) => {
    if (
      edtList?.event?.reference &&
      (edtList?.event?.referenceParent || edtList?.event?.providerFilter?.length > 0)
    ) {
      return {
        ...editList?.event,
        ruleName: editList?.ruleName,
        appointmentEventSubType: 'PROVIDER_SERVICE',
        providers: [edtList?.event?.referenceParent],
        providerFilter: [edtList?.event?.referenceParent],
        generalServiceFilter: [
          { label: edtList?.event?.eventMetaData?.serviceName, value: edtList?.event?.reference },
        ],
      };
    }
    return { ...editList?.event, ruleName: editList?.ruleName };
  };
  useEffect(() => {
    setEvent(editList ? getEventValuesForEdit(editList) : defaultEvent);

    setFilters(editList ? getValueForEdit(editList?.filter) : DEFAULT_FILTER);
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 10000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
    dispatch(stateActionCreators.fetchStates());
    dispatch(profileActionCreators.fetchDemographicMetadata());
    if (editList?.filter?.structuredCondition?.automationLogics?.length > 0) {
      const insuranceFilter = editList?.filter?.structuredCondition?.automationLogics?.find(
        (logic: any) => logic.type === 'INSURANCE',
      );
      if (insuranceFilter) {
        const foundState = states.find(state => state?.state?.name === insuranceFilter.key);
        if (foundState) {
          dispatch(stateActionCreators.fetchPayers(foundState._id));
        }
      }
    }
  }, []);

  const onCloseClick = () => {
    onClose();
  };

  const saveAutomationRule = (active = true) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ruleName, eventMetaData, ...restEvent } = event || {};
    const automationRuleRequest = {
      ruleName,
      active,
      event: {
        ...restEvent,
        references: restEvent?.references?.map(item => item?.value) || [],
      },
      action,
      filter: {
        ...filter,
        structuredCondition: {
          ...filter.structuredCondition,
          automationLogics: filter?.structuredCondition?.automationLogics?.map(logic => {
            if (logic.type !== 'P') {
              return {
                ...logic,
                value: logic.value?.toString(),
              };
            }
            return logic;
          }),
        },
      },
      category: automationTypes[query.get('name')]?.category,
    };
    if (isEditMode) {
      const { id } = editList;
      dispatch(conversationActionCreators.updateAutomationRule({ ...automationRuleRequest, id }));
    } else {
      dispatch(conversationActionCreators.createAutomationRule(automationRuleRequest));
    }
  };
  const formatFiltersPayload = (payload: any) => {
    const {
      structuredCondition: { automationLogics },
    } = payload;
    return {
      ...payload,
      structuredCondition: {
        ...payload.structuredCondition,
        automationLogics: automationLogics.map(logic => {
          switch (logic.type) {
            case automationLogicSelect[1].value:
            case automationLogicSelect[2].value:
            case automationLogicSelect[3].value:
            case automationLogicSelect[4].value:
              return {
                ...logic,
                value: logic?.value?.map(val => val?.value),
              };

            default:
              return logic;
          }
        }),
      },
    };
  };
  const onClickContinue = (payload: any) => {
    switch (currentStep) {
      case 0:
        setEvent(payload);
        break;
      case 1:
        setFilters({
          ...formatFiltersPayload(payload),
          condition: '',
          type: 'RESPONSE_BASED_FILTER',
          users: [],
        });
        break;
      case 2:
        setAction(payload);
        onClose();
        break;
      default:
        break;
    }
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (action.reference !== '') {
      saveAutomationRule();
    }
  }, [action]);

  const renderStep = step => {
    switch (step) {
      case 0:
        return (
          <AutomationEvent
            isEditMode={isEditMode}
            eventdata={event}
            onClose={onCloseClick}
            onContinue={onClickContinue}
            conversations={conversationOptions}
            dcts={dctOptions}
            evaluations={evaluationsOptions}
            groups={groupOptions}
            educations={educationOptions}
            profileElements={profileElementOptions}
            providers={providers}
            providerRoles={providerRoleOptions}
            planItems={planItems}
            actiondata={editList}
          />
        );
      case 1:
        return (
          <AutomationFilters
            isEditMode={isEditMode}
            filters={filter}
            event={event}
            onClose={onCloseClick}
            onContinue={onClickContinue}
            profileElementList={profileElementList}
            tagList={tags}
            isLoadingPayers={isLoading}
            payers={payers}
            states={states}
            levelOfEngagements={levelOfEngagements}
            providers={providers}
          />
        );
      case 2:
        return (
          <AutomationAction
            actiondata={editList}
            eventdata={event}
            isEditMode={isEditMode}
            onClose={onCloseClick}
            onContinue={onClickContinue}
            evaluations={evaluationsOptions}
            planItems={planItems}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Drawer open={isOpen} onClose={onCloseClick} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text weight={fontWeight.BOLD} className={classes.headTitle}>
              {isEditMode ? 'Edit automation' : 'Add automation'}
            </Text>
            <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
          </Box>
          <Stepper steps={Object.values(stepTitles)} activeStep={currentStep} />
        </Box>
        <Box className={classes.content}>{renderStep(currentStep)}</Box>
      </Box>
    </Drawer>
  );
};

export { AddAutomation };
