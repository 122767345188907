// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
  },
  btnBack: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary300,
    width: 48,
    height: 48,
    padding: 0,
    minWidth: 0,
    marginRight: 16,
  },
  info: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 16,
  },
  title: {
    margin: 0,
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    color: colors.neutral600,
  },
  btnOpenChat: {
    marginRight: 0,
    marginLeft: 'auto',
  },
});
