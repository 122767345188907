import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    flex: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  icon: {},
  title: {
    fontSize: 18,
    lineHeight: '28px',
    margin: 0,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  event: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    padding: 24,
    backgroundColor: colors.white,
  },
  info: {},
  infoLabel: {
    margin: 0,
    fontSize: 12,
    lineHeight: '20px',
    marginBottom: 4,
    display: 'block',
    textTransform: 'uppercase',
    color: colors.neutral500,
  },
  nofilter: {
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral700,
  },
  infoValue: {
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
  },
  arrowIcon: {},
  deleteBtn: {
    fontSize: 14,
    color: colors.destructive500,
  },
  btn: {
    fontSize: 14,
  },
  addBtn: {
    height: 40,
    fontSize: 14,
    color: colors.white,
  },
});
