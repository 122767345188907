import { Box } from '@mui/material';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { Filter, Table, TableGrid, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { colors } from '@confidant-health/lib/colors';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAuth } from 'redux/modules/auth/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { profileActionCreators } from 'redux/modules/profile';
import { DomainTypesEnum } from 'constants/CommonConstants';

import Header from 'components/v2/Header';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

import { PrioritiesCard } from 'pages/provider/sessions/priority-list/PriorityList.constants';
import { getAssignedPrioritiesV2 } from 'services/priority/priority.service';
import { getDomainGroup } from 'services/member/member.service';
import { PriorityDetailDrawer } from 'pages/provider/priorities/PriorityDetailDrawer';
import PriorityEmpty from 'pages/provider/member-detail/components/PriorityEmpty';

import ChartBoard from '../ChartBoard';
import AddNewReportMedications from '../add-new-report/AddNewReportMedications';
import { IMedication } from '../add-new-report/AddNewReport.types';
import { FactorType } from '../add-factor-drawer/FactorItem';

import { useStyles } from './index.styles';
import {
  DOMAIN_ELEMENT_VARIENT,
  formatDomainData,
  getFilters,
  getMultiSelectFilterOptions,
} from '../member-detail.constents';
import { ProfileInfoRole } from '../../../../components/v2/ProfileInfo';

const columns: TableGrid.TableColumn[] = [
  { id: 'factor', label: 'Factor', sortable: false },
  { id: 'dose', label: 'Dose', sortable: false },
  { id: 'frequency', label: 'Frequency', sortable: false },
  { id: 'supply', label: 'Supply', sortable: false },
  { id: 'refillable', label: 'Refillable', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

const Medications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { meta, isAdmin } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);
  const typeName = PrioritiesCard.Medications;
  const [domainData, setDomainData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [domainOptions, setDomainOptions] = useState([] as any[]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  const [value, setValue] = useState<tableParamsType>({
    search: {
      searchKey: '',
    },
    pagination: {
      currentPage: 1,
      rowsPerPage: 10,
    },
    sorter: {
      direction: 'asc',
      column: '',
    },
  });
  const [selectedType, setSelectedType] = useState<FactorType | null>(null);
  const [selectedItem, setSelectedItem] = useState({} as any);
  const [reset, setReset] = useState(false);
  const { lookupData, domainTypes } = useSelector(getProfile);

  useEffect(() => {
    setIsLoading(true);
    getAssignedPrioritiesV2({
      patientId: memberId,
      dataDomainType: typeName,
      pageNumber: value.pagination.currentPage - 1,
      pageSize: value.pagination.rowsPerPage,
      importanceLevels: getFilters(value)?.importanceLevels,
      searchQuery: value.search.searchKey ?? '',
    })
      .then(({ data }) => {
        setDomainData(data);
        setIsLoading(false);
      })
      .catch(e => {
        console.error(e);
        setIsLoading(false);
      });
  }, [value, reset]);

  const fetchDomainForm = (typeId: string) => {
    getDomainGroup({ domainTypeId: typeId })
      .then(({ data }) => {
        const opts = data.map(item => ({
          label: item.name,
          groupId: item.Id,
          items: item.relatedElements.map(element => ({ label: element.name, value: element.Id })),
          relatedElements: item.relatedElements,
        }));
        setDomainOptions(opts);
        setOptionsLoading(false);
      })
      .catch(() => {
        setDomainOptions([]);
        setOptionsLoading(false);
      });
  };

  useEffect(() => {
    const domain = domainTypes.find(item => item.typeName === DomainTypesEnum.MEDICATIONS);
    if (domain) {
      setOptionsLoading(true);
      fetchDomainForm(domain.typeId);
    }
  }, [selectedType, domainTypes]);

  const domainTypeData = useMemo(() => {
    if (domainData?.records?.length > 0) {
      return formatDomainData(domainData, lookupData, DOMAIN_ELEMENT_VARIENT.MEDICATION);
    }
    return { domainElementData: [], domainElementStats: [] };
  }, [domainData, value]);

  const elementsStats = useMemo(() => {
    if (domainData?.records?.length > 0) {
      return formatDomainData(domainData, lookupData, DOMAIN_ELEMENT_VARIENT.MEDICATION)?.domainElementStats;
    }
    return [];
  }, [domainData]);

  const renderColumns = columns.map(column => {
    if (column.id === 'factor') {
      return {
        ...column,
        renderCell: ({ name, count }) => (
          <Box display="flex" flexDirection="column" paddingLeft={2} gap={0.5}>
            <Typography {...typography.body.normal.small.semibold}>{name}</Typography>
            <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
              {count} updates
            </Typography>
          </Box>
        ),
      };
    }

    if (column.id === 'currentLevel' || column.id === 'firstLevel') {
      return {
        ...column,
        renderCell: ({ level, color, date }) => (
          <Box display="flex" flexDirection="column" paddingLeft={2} gap={0.5}>
            <Typography
              {...typography.body.normal.small.semibold}
              color={
                level === 'High'
                  ? colors.orange600
                  : level === 'Medium'
                  ? colors.warning600
                  : level === 'Critical'
                  ? colors.destructive600
                  : color ?? colors.cyan600
              }
            >
              {level}
            </Typography>
            <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
              {date}
            </Typography>
          </Box>
        ),
      };
    }

    if (column.id === 'context') {
      return {
        ...column,
        renderCell: context => (
          <Box paddingLeft={2}>
            <Typography {...typography.body.normal.small.medium}>{context}</Typography>
          </Box>
        ),
      };
    }

    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: item => (
          <IconButton
            icon="chevron-right"
            className={classes.actions}
            onClick={() => setSelectedItem(item)}
          />
        ),
      };
    }

    return {
      ...column,
      renderCell: context => (
        <Box paddingLeft={2}>
          <Typography {...typography.body.normal.small.medium}>{context}</Typography>
        </Box>
      ),
    };
  });

  const onCloseDrawer = () => {
    setSelectedType(null);
    setReset(!reset);
  };

  const onSubmitNewReport = (payload: IMedication[]) => {
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        metaData: {
          notes: item?.notes,
          selectedImportance: item?.status,
          rxDrugInfo: {
            dose: item.tutorial.dose ?? 0,
            doseUnit: item.tutorial.doseUnit ?? '',
            doseFrequency: item.tutorial.doseFrequency ?? 0,
            supply: item.tutorial.supply ?? 0,
            supplyUnit: item.tutorial.supplyUnit ?? '',
            confidantProviderId: item?.tutorial?.confidantProviderId,
            confidantProviderName: item?.tutorial?.confidantProviderName,
            dosage: item?.tutorial?.dosage,
            prescribingProvider:
              item?.tutorial?.prescribingProvider === 'Confidant Provider' ? 'Internal' : 'External',
            providerName: item?.tutorial?.providerName,
            symptomsBeingTreated: item?.tutorial?.symptomsBeingTreated,
            frequency: item?.tutorial?.frequency,
          },
        },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: (errorMsg?: string) => {
          if (!errorMsg) {
            onCloseDrawer();
          }
        },
      }),
    );
  };

  // if (isLoading) {
  //   return (
  //     <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={4}>
  //       <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
  //       Loading...
  //     </Box>
  //   );
  // }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Header
          label="Medications"
          actions={
            (profile.designation === ProfileInfoRole.NURSE_PRACTITIONER ||
              profile.designation === ProfileInfoRole.PHYSICIAN ||
              isAdmin) && [
              {
                children: <Typography {...typography.body.normal.medium.bold}>New medication</Typography>,
                variant: btnType.PRIMARY,
                className: classes.primaryBtn,
                icon: 'plus',
                onClick: () => setSelectedType(FactorType.MEDICATIONS),
              },
            ]
          }
        />
        <Box display="flex" flexDirection="column" gap={3}>
          {elementsStats?.length > 0 && <ChartBoard legendSeries={elementsStats} total={12} />}
          <Table
            searchProps={{
              placeholder: 'Search',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                multiSelectOptions: getMultiSelectFilterOptions(DOMAIN_ELEMENT_VARIENT.MEDICATION),
              },
            }}
            gridProps={{
              columns: renderColumns,
              data: domainTypeData?.domainElementData,
              onRowClick: item => {
                setSelectedItem(item);
              },
              isLoading,
              emptyState: (
                <PriorityEmpty
                  title="No medications have been prescribed yet."
                  description=""
                  glyph="lottie-medications"
                  glyphOrientation="portrait"
                  isEmptyWithLottie
                  lottieSize={128}
                />
              ),
            }}
            paginationProps={{
              currentRows: 10,
              totalCount: domainData?.totalRecords,
              noOfRows: 10,
              showRowsPerPage: false,
            }}
            value={value}
            onChange={setValue}
            className={classes.table}
          />
        </Box>
      </Box>

      <AddNewReportMedications
        isOpen={selectedType === FactorType.MEDICATIONS}
        onClose={onCloseDrawer}
        options={domainOptions}
        lookupData={lookupData}
        onSubmit={onSubmitNewReport}
        optionsLoading={optionsLoading}
      />
      {selectedItem?.taxonomyName && (
        <PriorityDetailDrawer
          memberId={memberId}
          selectedItem={selectedItem}
          type={DomainTypesEnum.MEDICATIONS}
          onClose={() => {
            setSelectedItem(null);
            setSelectedType(null);
          }}
          open={selectedItem?.taxonomyName}
          onElementsUpdated={() => setReset(!reset)}
        />
      )}
    </>
  );
};

export default Medications;
