export const flowTypeSelect = [
  { label: 'Multi select', value: 'Multi select' },
  { label: 'Single select', value: 'Single select' },
  { label: 'Rating scale', value: 'Rating scale' },
];

export const flowProfileElementSelect = [{ label: 'Recent issue', value: 'Recent issue' }];

export const flowLogicOptions = [
  { label: 'Always Show', value: 'always' },
  { label: 'If Profile Element', value: 'P' },
];

export const flowRoutingSelect = [{ label: 'Next content block', value: 'Next content block' }];

export const flowMenuItems = [{ value: 'use-template', label: 'Use template' }];

export const formatTypeSelectOptionsFromArray = (options: any[]) => {
  return options.map(option => {
    return { label: option, value: option };
  });
};
export const formatTypeSelectOptions = (options: any) => {
  // const selectOptions = [];
  return Object.keys(options).map(optionKey => {
    return { label: options[optionKey], value: options[optionKey] };
  });
};
