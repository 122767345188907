import React, { FC, useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import { useStyles } from './FlowChoice.styles';
import { MediaManager } from '../../../../services/mediaManager/mediaManager';

type Props = {
  htmlValue?: string;
  handleChange?: (data: any) => void;
};

const JoditUIEditor: FC<Props> = ({ htmlValue, handleChange }) => {
  const editorRef = useRef(null);
  const classes = useStyles();
  const [editorState] = useState(htmlValue);

  const toolbarOptions = {
    readonly: false,
    buttons: [
      'bold',
      {
        icon: 'image',
        exec: joditInstance => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.click();
          input.onchange = () => {
            const file = input.files[0];
            const formData = new FormData();
            formData.append('image', file);
          };
          input.onchange = async () => {
            const file = input.files[0];
            const { response } = await MediaManager.uploadExercisesMedia(file, e => {
              console.log(e);
            });
            joditInstance.selection.insertImage(response?.location);
          };
        },
      },
    ],
  };

  return (
    <>
      <JoditEditor
        ref={editorRef}
        value={editorState}
        config={toolbarOptions}
        onBlur={val => handleChange(val)}
        className={classes.JoditEditorRoot}
      />
    </>
  );
};

export { JoditUIEditor };
