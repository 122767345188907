import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    paddingLeft: 8,
    '& label .MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 8,
    },
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    paddingLeft: 8,
    marginTop: 8,
    '& .MuiTypography-body1': {
      fontSize: 14,
      marginLeft: 12,
      fontWeight: 500,
    },
    '& div': {
      padding: 0,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    color: colors.primary,
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    height: 'calc(100vh - 160px)',
    overflowY: 'auto',
    flexDirection: 'column',
    gap: 24,
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  cancel: {
    color: colors.primary600,
    fontSize: '14px',
  },
  submit: {
    height: '48px',
  },
  valuesInput: {
    padding: 0,
    '& > div': {
      '& input': {
        fontSize: '14px !important',
      },
    },
    '& .MuiAutocomplete-tag': {
      fontSize: 14,
    },
  },
});
