import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
    '& .list-item': {
      height: 'calc(100vh - 260px)',
      overflowY: 'auto',
      borderTop: `1px solid ${colors.neutral100}`,
    },
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    marginBottom: 30,
    padding: '24px 24px 0',
  },
  searchInput: {
    height: 48,
  },
  chatbot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 24,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
    '&:hover': {
      backgroundColor: colors.primary25,
    },
  },
  selected: {
    backgroundColor: colors.primary25,
  },
  name: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
    display: 'block',
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    color: colors.neutral600,
  },
  error: {
    textAlign: 'center',
  },
  emptyList: {
    textAlign: 'center',
    color: colors.neutral400,
    display: 'inline-block',
    width: '100%',
    margin: '32px 0',
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
  },
});
