// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  mainContainer: {
    width: '100%',
    height: '100vh',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 1,
  },
  hiddenContainer: {
    display: 'none',
  },
  content: {
    backgroundColor: colors.neutral50,
    flex: 1,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 80px)',
  },
  mainContent: {
    backgroundColor: colors.neutral50,
    flex: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'calc(100vh - 80px)',
  },
  hiddenMainContent: {
    display: 'none',
  },
  publisherVideo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  subscriberVideo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  waitingScreen: {
    padding: '64px 148px',
    flex: 1,
    width: '100%',
    gap: 132,
    '@media (max-width: 1440px)': {
      padding: '32px',
      gap: 32,
    },
  },
  boxLeft: {
    flex: 1,
  },
  videoContainer: {
    position: 'relative',
    flex: 1,
    zIndex: 1,
    transition: 'all 0.3s ease-in-out',
  },
  rightPanel: {
    width: 0,
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
    backgroundColor: colors.white,
  },
  showRightPanel: {
    borderLeft: `1px solid ${colors.neutral100}`,
    width: 400,
  },
  sharePanel: {
    width: 321,
  },
  priorityPanel: {
    width: 752,
  },
  chatPanel: {
    width: 440,
    overflowY: 'auto',
  },
  schedulePanel: {
    width: 630,
  },
  notePanel: {
    width: 336,
  },
  checklistPanel: {
    width: 807,
    margin: '0 auto',
  },
  evaluationPanel: {
    width: 360,
  },
  evaluationRightPanel: {
    width: 440,
  },
  evaluationRightPanelNoVideo: {
    width: 'calc(100vw - 360px)',
  },
  video: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.neutral900,
    padding: 32,
  },
  hiddenVideo: {
    width: '0%',
    height: '0%',
    backgroundColor: colors.neutral900,
  },
  noVideo: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: colors.neutral50,
    zIndex: 1,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
  },
  username: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginTop: 24,
  },
  fullname: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  fullWidth: {
    width: '100%',
  },
  chatDrawer: {
    '& .MuiPaper-root': {
      maxWidth: '440px !important',
    },
  },
  scheduleDrawer: {
    '& .MuiPaper-root': {
      maxWidth: '560px !important',
    },
  },
  pin: {
    display: 'none',
    position: 'absolute',
    minWidth: 'unset',
    padding: '5px',
    bottom: '20px',
    right: '20px',
    zIndex: 2,
    '&:disabled': {
      opacity: 0.5,
    },
  },
});
