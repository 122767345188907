import { FC } from 'react';
import { Box } from '@mui/material';

import { fontWeight, Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { useStyles } from './EvaluationEducation.styles';

type Props = {
  selectedSection: any;
  onClickDetail: () => void;
  setIsRead?: (value: boolean) => void;
};
const EvaluationEducation: FC<Props> = ({ selectedSection, onClickDetail, setIsRead }) => {
  const classes = useStyles();
  const onClickDetailHandler = () => {
    selectedSection.evaluationResponses = [true];
    setIsRead(true);
    onClickDetail();
  };

  return (
    <>
      <Box className={classes.info} onClick={onClickDetailHandler}>
        <Icons glyph="book-side-filled-outlined" color={colors.primary500} />
        <Box>
          <Heading weight={fontWeight.BOLD} className={classes.infoTitle}>
            {selectedSection?.title}
          </Heading>
          {selectedSection?.duration && (
            <Text weight={fontWeight.MEDIUM} className={classes.infoSubtitle}>
              {selectedSection?.duration} read
            </Text>
          )}
        </Box>
      </Box>
    </>
  );
};

export { EvaluationEducation };
