import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    marginTop: 40,
    backgroundColor: colors.white,
    borderRadius: 8,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  name: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    display: 'block',
  },
  heading: {
    margin: 0,
    fontSize: 18,
    lineHeight: '24px',
  },
  badge: {
    width: 'fit-content',
    height: 24,
    borderRadius: 20,
    border: 'none',
    whiteSpace: 'nowrap',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    padding: '4px 8px',
  },
  actBtn: {
    minWidth: 'auto',
    position: 'relative',
    left: 10,
    '@media (max-width: 1440px)': {
      left: 0,
    },
    '& svg': {
      width: 12,
      height: 12,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  exportBtn: {
    '& svg': {
      width: 14,
      marginLeft: 12,
    },
  },
});
