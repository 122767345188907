import { HistoryLookup, IDomainElement } from 'redux/modules/profile/types';
import { ReportItem } from '../../AddNewReport/AddNewReport.types';

export const defaultValues = {
  // const defaultValues = {
  status: '',
  notes: '',
  dosage: '',
  frequency: '',
  medDuration: '',
  symptoms: [],
  provider: 'Confidant Provider',
  confidantProvider: '',
  providerName: '',
  additionalNotes: '',
  isInterference: false,
  // dose: {
  //   amount: 1,
  //   unit: 'MG',
  // },
  // frequency: {
  //   amount: 1,
  //   unit: 'DAYS',
  // },
  // duration: {
  //   amount: 1,
  //   unit: 'DAYS',
  // },
  // refillable: true,
  // refillTimes: {
  //   amount: 1,
  //   unit: 'TIMES',
  // },
  // isInterference: false,
};

// };

export type MedicationEditPayload = typeof defaultValues;

export type IMedicationEditProps = {
  isOpen: boolean;
  onClose: () => void;
  isNoDrawer?: boolean;
  medication: IDomainElement;
  isSaving?: boolean;
  lookupData?: HistoryLookup;
  error?: string;
  onSubmit?: (data: MedicationEditPayload) => void;
  options?: ReportItem[];
  memberId?: string;
};
