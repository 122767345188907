// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  colLabel: {
    color: colors.neutral500,
    paddingBottom: '10px',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  card: {
    width: '50%',
    backgroundColor: colors.white,
    borderRadius: 8,
    border: `1px solid ${colors.neutral100}`,
    '&:hover $btnLink': {
      display: 'flex',
    },
  },
  cptCard: {
    width: '50%',
    height: '250px',
    backgroundColor: colors.white,
    borderRadius: 8,
    border: `1px solid ${colors.neutral100}`,
    '&:hover $btnLink': {
      display: 'flex',
    },
  },
  cardTop: {
    padding: 24,
  },
  cardTopTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  btnLink: {
    gap: 10,
    display: 'none',
    '& div': {
      fontSize: 14,
      lineHeight: '20px',
      color: colors.primary500,
      fontWeight: 700,
    },
  },
  cardBody: {
    padding: 24,
  },
  cptCardBody: {
    padding: 24,
  },
  cptCardScroll: {
    maxHeight: 'calc(100% - 100px)',
    overflowY: 'scroll',
  },
  username: {
    fontWeight: 600,
    lineHeight: '20px',
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '10rem',
    whiteSpace: 'nowrap',
  },
  usernameFull: {
    color: colors.neutral600,
    fontWeight: 500,
  },
  cardBodyText: {
    marginTop: 24,
    display: 'block',
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral700,
  },
  cptCardBodyText: {
    display: 'block',
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral700,
  },
  badge: {
    marginTop: 4,
    borderRadius: 99,
    height: 22,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '0 8px',
    color: colors.indigo600,
    background: colors.indigo50,
    borderColor: colors.indigo50,
  },
  rating: {
    '& > span span': {
      fontSize: 19,
    },
    '& span': {
      color: colors.neutral900,
      fontSize: 14,
    },
    '& svg': {
      color: colors.warning500,
    },
  },
});
