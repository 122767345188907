/* eslint-disable max-len */
import dayjs from 'dayjs';
import { DomainPriorities, IDomainElement } from 'redux/modules/profile/types';

export const substanceUseList: IDomainElement[] = [
  {
    id: '1',
    name: 'Heroin',
    description: 'Injecting once a month for 3 months.',
    assignedBy: 'Eva Bond',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: null,
    priority: { name: DomainPriorities.HIGH, color: '' },
    history: [],
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
    tagMetaData: {
      rxDrugInfo: {
        method: 'Injecting',
        dose: 5,
        doseUnit: 'MG',
        doseFrequency: 3,
        supply: 60,
        supplyUnit: 'DAYS',
        refillable: true,
        lastUse: 'Last week',
      },
      substanceUse: {
        currentFrequencyOfUse: 'FEW_TIMES_A_YEAR',
        howLongUsingThisLevel: 'YEARS',
        lastUse: 'LAST_WEEK',
        methodOfUse: 'SMOKE',
        unitOfUse: 'GRAMS',
      },
    },
  },
];

export const medicationItems = [
  {
    label: 'Category name',
    groupId: 'Category name',
    items: [
      { label: 'Benzodiazepine', value: 'Benzodiazepine' },
      { label: 'Alcohol', value: 'Alcohol' },
      { label: 'Fluvoxamine', value: 'Fluvoxamine' },
      { label: 'Cannabis', value: 'Cannabis' },
    ],
  },
  {
    label: 'Category name 1',
    groupId: 'Category name 1',
    items: [
      { label: 'Category name 1 - 1', value: 'Category name 1 - 1' },
      { label: 'Category name 1 - 2', value: 'Category name 1 - 2' },
      { label: 'Category name 1 - 3', value: 'Category name 1 - 3' },
      { label: 'Category name 1 - 4', value: 'Category name 1 - 4' },
    ],
  },
  {
    label: 'Category name 2',
    groupId: 'Category name 2',
    items: [
      { label: 'Category name 2 - 1', value: 'Category name 2 - 1' },
      { label: 'Category name 2 - 2', value: 'Category name 2 - 2' },
      { label: 'Category name 2 - 3', value: 'Category name 2 - 3' },
      { label: 'Category name 2 - 4', value: 'Category name 2 - 4' },
    ],
  },
];

export const stepTitles = {
  0: 'Step 1 - select substances',
  1: 'Step 2 - substance details',
  2: 'Step 3 - review substances',
};

export const substanceDefaultValues = {
  status: '',
  notes: '',
  method: 'DRINKING',
  lastUse: 'TODAY',
  dose: {
    amount: 1,
    unit: 'MG',
  },
  frequency: 'MULTIPLE_TIMES_DAILY',
  duration: 'DAYS',
  refillable: true,
  refillTimes: {
    amount: 1,
    unit: 'TIMES',
  },
  isInterference: false,
};

export type SubstanceEditValue = typeof substanceDefaultValues & { id?: string };
