import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    borderRadius: 8,
    padding: '26px 24px',
    boxShadow: `0px 0px 6px ${colors.neutral100}`,
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral100}`,
    '&:hover $topRight': {
      display: 'flex',
    },
    alignItems: 'center',
  },
  button: {
    borderColor: colors.primary300,
    padding: '15px 22px',
  },
});
