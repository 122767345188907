/* eslint-disable no-nested-ternary */
import { FC, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';

import { HistoryLookup } from 'redux/modules/profile/types';
import ReportFormCollapseMedications from '../report-form-collapse/ReportFormCollapseMedications';

import { IMedication, ReportItem, ReportItemProgress } from '../AddNewReport.types';
import { useStyles } from './StepTwo.styles';

type Props = {
  showInterference?: boolean;
  selectedItems: IMedication[];
  lookupData?: HistoryLookup;
  onRemoveItem: (index: number) => void;
  updateItems: (items: IMedication[]) => void;
  memberId?: string;
  options?: ReportItem[];
};

const StepTwoMedications: FC<Props> = ({
  showInterference,
  selectedItems,
  onRemoveItem,
  updateItems,
  lookupData,
  memberId,
  options,
}) => {
  const classes = useStyles();

  useEffect(() => {
    updateItems(
      selectedItems.map((symp, index) => ({
        ...symp,
        progress: !index ? ReportItemProgress.InProgress : ReportItemProgress.Incomplete,
      })),
    );
  }, []);

  const onUpdateItem = useCallback(
    (medication: IMedication) => {
      const nextIndex = selectedItems.findIndex(
        item =>
          item.item.value !== medication.item.value &&
          item.progress !== ReportItemProgress.Completed &&
          item.notes.trim().length === 0,
      );
      updateItems(
        selectedItems.map((selectedItem, index) =>
          selectedItem.item.value === medication.item.value
            ? { ...medication, progress: ReportItemProgress.Completed }
            : index === nextIndex
            ? { ...selectedItem, progress: ReportItemProgress.InProgress }
            : selectedItem,
        ),
      );
    },
    [selectedItems, updateItems],
  );

  const onOpenCollapse = (medication: IMedication) => (open: boolean) => {
    let newProgress = medication.notes.trim().length
      ? ReportItemProgress.Completed
      : ReportItemProgress.Incomplete;
    if (open) {
      newProgress = ReportItemProgress.InProgress;
    }

    updateItems(
      selectedItems.map(selectedItem =>
        selectedItem.item.value === medication.item.value
          ? { ...selectedItem, progress: newProgress }
          : selectedItem,
      ),
    );
  };

  const onRemoveClick = (index: number) => () => {
    const updatedSymptoms = selectedItems.filter((_, i) => i !== index);
    onRemoveItem(index);
    updateItems([...updatedSymptoms]);
  };

  return (
    <Box className={classes.container}>
      {selectedItems.map((medication, index) => (
        <ReportFormCollapseMedications
          key={index}
          lookupData={lookupData}
          medication={medication}
          showInterference={showInterference}
          onChange={onUpdateItem}
          onRemoveClick={onRemoveClick(index)}
          onOpenCollapse={onOpenCollapse(medication)}
          memberId={memberId}
          options={options}
        />
      ))}
    </Box>
  );
};

export default StepTwoMedications;
