import { AxiosResponse } from 'axios';
import { baseRequest } from 'utils/HttpClient';
import { ApiEndpoints } from 'constants/ApiEndpoints';

import {
  IAddPlanPayload,
  IAddStatePayload,
  ICPTCode,
  IEntity,
  IPayer,
  IPayerProvider,
} from 'redux/modules/state/types';

export const getStates = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_STATES, null, null, null);
};

export const updateState = (
  bodyRequest: IAddStatePayload,
  pathParams: { stateId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_STATE, bodyRequest, pathParams);
};

export const getStateById = (stateId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_STATE_BY_ID, null, { stateId });
};

export const addRegisteredEntity = (bodyRequest: IEntity): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_REGISTERED_ENTITY, bodyRequest);
};

export const updateRegisteredEntity = (
  bodyRequest: IEntity,
  pathParams: { entityId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_REGISTERED_ENTITY, bodyRequest, pathParams);
};

export const getPayers = (stateId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PAYERS, null, { stateId });
};

export const getAllPayers = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_ALL_PAYERS, null, null);
};

export const getPayerById = (payerId: string): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PAYER_BY_ID, null, { payerId });
};

export const addPayer = (bodyRequest: IPayer, pathParams: { stateId: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_PAYER, bodyRequest, pathParams);
};

export const updatePayer = (
  bodyRequest: IPayer,
  pathParams: { stateId: string; payerId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_PAYER, bodyRequest, pathParams);
};

export const addPayerPlan = (
  bodyRequest: IAddPlanPayload,
  pathParams: { payerId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.ADD_PAYER_PLAN, bodyRequest, pathParams, null, true);
};

export const updatePayerPlan = (
  bodyRequest: IAddPlanPayload,
  pathParams: { payerId: string; planId: string },
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_PAYER_PLAN, bodyRequest, pathParams, null, true);
};

export const addPayerProvider = (
  bodyRequest: IPayerProvider,
  pathParams: { planId: string },
): Promise<AxiosResponse> => {
  return baseRequest(
    ApiEndpoints.ADD_PAYER_PROVIDER,
    bodyRequest,
    pathParams,
    { getFullPayerInResponse: true },
    true,
  );
};

export const updatePayerProvider = (
  bodyRequest: IPayerProvider,
  pathParams: { planId: string; providerId: string },
): Promise<AxiosResponse> => {
  return baseRequest(
    ApiEndpoints.UPDATE_PAYER_PROVIDER,
    bodyRequest,
    pathParams,
    { getFullPayerInResponse: true },
    true,
  );
};

export const getPayerGroups = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PAYER_GROUPED, null, null, null);
};
export const getUniquePayers = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_UNIQUE_PAYERS, null, null, null);
};
export const getCPTs = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_CPTS, null, null, null);
};

export const getIcd10codes = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_ICD10_CODES, null, null, null);
};

export const addPayerPlanCPT = (
  bodyRequest: ICPTCode,
  pathParams: { planId: string },
): Promise<AxiosResponse> => {
  return baseRequest(
    ApiEndpoints.ADD_PAYER_PLAN_CPT,
    bodyRequest,
    pathParams,
    { getFullPayerInResponse: true },
    true,
  );
};

export const updatePayerPlanCPT = (
  bodyRequest: ICPTCode,
  pathParams: { planId: string; cptId: string },
): Promise<AxiosResponse> => {
  return baseRequest(
    ApiEndpoints.UPDATE_PAYER_PLAN_CPT,
    bodyRequest,
    pathParams,
    { getFullPayerInResponse: true },
    true,
  );
};

export const getStatesSimple = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_STATES_SIMPLE, null, null, null);
};

export const uploadPayerImage = (bodyRequest: { name: string; image: string }): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.PAYER_UPLOAD_IMAGE, bodyRequest, null, null, true);
};
