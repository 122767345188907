/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { IReport, ReportFilterRule } from 'redux/modules/reporting/types';
import { REPORT_FILTERS } from 'constants/CommonConstants';
import { ISymptomReport } from './Symptoms.types';
import {
  getReportAllFiltersSubQuery,
  getReportAllFiltersSubQueryReferralPartner,
  getReportFilterSubQuery,
  getReportInternalFiltersSubQuery,
  REPORT_CONSTANTS,
  REPORT_SECTION_NAMES,
} from '../../ReportDetail.constants';

export const assignedColumns: TableGrid.TableColumn[] = [
  {
    id: 'name',
    label: 'Symptom',
    sortable: false,
  },
  {
    id: 'members',
    label: 'Members',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const mostImportantColumns: TableGrid.TableColumn[] = [
  {
    id: 'name',
    label: 'Symptom',
    sortable: false,
  },
  {
    id: 'level',
    label: 'Importance level',
    sortable: false,
    textAlign: 'center',
  },
  {
    id: 'members',
    label: 'Members',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const initImportantColumns: TableGrid.TableColumn[] = [
  {
    id: 'name',
    label: 'Symptom',
    sortable: false,
  },
  {
    id: 'level',
    label: 'Importance level',
    sortable: false,
    textAlign: 'center',
  },
  {
    id: 'initLevel',
    label: 'Initial importance level',
    sortable: false,
    textAlign: 'center',
  },
  {
    id: 'members',
    label: 'Members',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const getChartSeries = (symptomReport: ISymptomReport) => {
  const getValueFromStatus = (status: string) =>
    symptomReport?.aggregations.top_ten_by_members.buckets.map(
      bucket => bucket.by_importance.buckets.find(b => b.key === status.toUpperCase())?.doc_count ?? 0,
    ) ?? [];

  return [
    {
      name: 'Resolved',
      color: colors.success500,
      data: getValueFromStatus('Resolved'),
    },
    {
      name: 'Low',
      color: colors.cyan500,
      data: getValueFromStatus('Low'),
    },
    {
      name: 'Medium',
      color: colors.warning500,
      data: getValueFromStatus('Medium'),
    },
    {
      name: 'High',
      color: colors.orange600,
      data: getValueFromStatus('High'),
    },
    {
      name: 'Critical',
      color: colors.destructive500,
      data: getValueFromStatus('Critical'),
    },
    {
      name: 'Unrelated',
      color: colors.neutral400,
      data: getValueFromStatus('Unrelated'),
    },
    {
      name: 'Misreported',
      color: colors.secondary500,
      data: getValueFromStatus('Misreported'),
    },

    {
      name: 'Active',
      color: colors.green200,
      data: getValueFromStatus('Active'),
    },
    {
      name: 'ActiveExternalRX',
      color: colors.indigo200,
      data: getValueFromStatus('ActiveExternalRX'),
    },
    {
      name: 'InActive',
      color: colors.neutral900,
      data: getValueFromStatus('InActive'),
    },
    {
      name: 'InactiveExternalRX',
      color: colors.neutral600,
      data: getValueFromStatus('InactiveExternalRX'),
    },
    {
      name: 'Negative',
      color: colors.orange300,
      data: getValueFromStatus('Negative'),
    },
    {
      name: 'Neutral',
      color: colors.purple300,
      data: getValueFromStatus('Neutral'),
    },
    {
      name: 'Possible',
      color: colors.rose500,
      data: getValueFromStatus('Possible'),
    },
    {
      name: 'Positive',
      color: colors.purple600,
      data: getValueFromStatus('Positive'),
    },
  ];
};

export const getSymptomQuery = (report: IReport) => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    return {
      size: 0,
      query: {
        bool: {
          filter: [
            {
              term: {
                'dde_type.keyword': 'Symptoms',
              },
            },
            ...(report?.data?.dataDomainElementSection?.dataDomainElementIds?.domainElementIds?.length
              ? [
                  {
                    terms: {
                      'taxonomy_id.keyword':
                        report.data.dataDomainElementSection.dataDomainElementIds.domainElementIds,
                    },
                  },
                ]
              : []),
          ],
          must: [
            ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
            ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
            ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
            ...getReportInternalFiltersSubQuery(report, REPORT_SECTION_NAMES.dataDomainElementSection),
          ]?.filter(obj => Object.keys(obj)?.length > 0),
        },
      },
      aggs: {
        total_members: {
          cardinality: {
            field: 'member_id.keyword',
          },
        },
        top_ten_by_members: {
          terms: {
            field: 'dde_name.keyword',
            size: 10,
            order: {
              uniqueMembers: 'desc',
            },
          },
          aggs: {
            uniqueMembers: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            by_importance: {
              terms: {
                field: 'dde_importance.keyword',
                size: 100,
                order: {
                  uniqueMembers: 'desc',
                },
              },
              aggs: {
                uniqueMembers: {
                  cardinality: {
                    field: 'member_id.keyword',
                  },
                },
              },
            },
          },
        },
        most_assigned: {
          terms: {
            field: 'dde_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            order: {
              uniqueMembers: 'desc',
            },
          },
          aggs: {
            uniqueMembers: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'assignedAt',
                sort: [{ assignedAt: { order: 'desc' } }],
                size: 1,
              },
            },
          },
        },
        most_count_by_importance: {
          terms: {
            field: 'dde_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            order: {
              uniqueMembers: 'desc',
            },
          },
          aggs: {
            uniqueMembers: {
              cardinality: {
                field: 'member_id.keyword',
              },
            },
            by_importance: {
              terms: {
                field: 'dde_importance.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
            },
            last_modified_date: {
              top_hits: {
                _source: 'lastModified',
                sort: [{ 'lastModified.keyword': { order: 'desc' } }],
                size: 1,
              },
            },
            importance_level: {
              top_hits: {
                _source: 'dde_importance',
                sort: [{ 'dde_importance.keyword': { order: 'desc' } }],
                size: 1,
              },
            },
          },
        },

        most_changed_from_start: {
          terms: {
            field: 'dde_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            by_importance: {
              terms: {
                field: 'dde_importance.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                order: {
                  uniqueMembers: 'desc',
                },
              },

              aggs: {
                by_initial_importance: {
                  terms: {
                    field: 'initial_importance.keyword',
                    size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                  },
                  aggs: {
                    last_modified_date: {
                      top_hits: {
                        _source: 'lastModified',
                        sort: [
                          {
                            'lastModified.keyword': {
                              order: 'desc',
                            },
                          },
                        ],
                        size: 1,
                      },
                    },
                  },
                },
                uniqueMembers: {
                  cardinality: {
                    field: 'member_id.keyword',
                  },
                },
              },
            },
          },
        },

        member_list: {
          top_hits: {
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            sort: [{ assignedAt: { order: 'desc' } }],
            _source: [
              'first_name',
              'last_name',
              'member_name',
              'dde_name',
              'initial_importance',
              'dde_importance',
              'initial_assignedAt',
              'assignedAt',
              'lastModified',
              'member_id',
            ],
          },
        },
      },
    };
  }
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            term: {
              'dde_type.keyword': 'Symptoms',
            },
          },
          ...(report?.data?.dataDomainElementSection?.dataDomainElementIds?.domainElementIds?.length
            ? [
                {
                  terms: {
                    'taxonomy_id.keyword':
                      report.data.dataDomainElementSection.dataDomainElementIds.domainElementIds,
                  },
                },
              ]
            : []),
        ],
        must: [],
      },
    },
    aggs: {
      total_members: {
        cardinality: {
          field: 'member_id.keyword',
        },
      },
      top_ten_by_members: {
        terms: {
          field: 'dde_name.keyword',
          size: 10,
          order: {
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          by_importance: {
            terms: {
              field: 'dde_importance.keyword',
              size: 100,
              order: {
                uniqueMembers: 'desc',
              },
            },
            aggs: {
              uniqueMembers: {
                cardinality: {
                  field: 'member_id.keyword',
                },
              },
            },
          },
        },
      },
      most_assigned: {
        terms: {
          field: 'dde_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          order: {
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'assignedAt',
              sort: [{ assignedAt: { order: 'desc' } }],
              size: 1,
            },
          },
        },
      },
      most_count_by_importance: {
        terms: {
          field: 'dde_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          order: {
            uniqueMembers: 'desc',
          },
        },
        aggs: {
          uniqueMembers: {
            cardinality: {
              field: 'member_id.keyword',
            },
          },
          by_importance: {
            terms: {
              field: 'dde_importance.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
          },
          last_modified_date: {
            top_hits: {
              _source: 'lastModified',
              sort: [{ 'lastModified.keyword': { order: 'desc' } }],
              size: 1,
            },
          },
          importance_level: {
            top_hits: {
              _source: 'dde_importance',
              sort: [{ 'dde_importance.keyword': { order: 'desc' } }],
              size: 1,
            },
          },
        },
      },

      most_changed_from_start: {
        terms: {
          field: 'dde_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          by_importance: {
            terms: {
              field: 'dde_importance.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              order: {
                uniqueMembers: 'desc',
              },
            },

            aggs: {
              by_initial_importance: {
                terms: {
                  field: 'initial_importance.keyword',
                  size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
                },
                aggs: {
                  last_modified_date: {
                    top_hits: {
                      _source: 'lastModified',
                      sort: [
                        {
                          'lastModified.keyword': {
                            order: 'desc',
                          },
                        },
                      ],
                      size: 1,
                    },
                  },
                },
              },
              uniqueMembers: {
                cardinality: {
                  field: 'member_id.keyword',
                },
              },
            },
          },
        },
      },

      member_list: {
        top_hits: {
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          sort: [{ assignedAt: { order: 'desc' } }],
          _source: [
            'first_name',
            'last_name',
            'member_name',
            'dde_name',
            'initial_importance',
            'dde_importance',
            'initial_assignedAt',
            'assignedAt',
            'lastModified',
            'member_id',
          ],
        },
      },
    },
  };
};
