import { colors } from '@confidant-health/lib/colors';
import { tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';
import dayjs from 'dayjs';
import { mapToLegacy, toSentenceCase } from 'utils/CommonUtils';

export const IMPORTANCE_LEVEL_COLOR_MAPPING = {
  CRITICAL: [colors.destructive700, colors.destructive50],
  MEDIUM: [colors.warning700, colors.warning100],
  RESOLVED: [colors.success700, colors.success100],
  UNRELATED: [colors.neutral700, colors.neutral100],
  HIGH: [colors.orange600, colors.orange100],
  LOW: [colors.cyan700, colors.cyan100],
  MISREPORTED: [colors.secondary600, colors.secondary100],
  NEUTRAL: [colors.warning700, colors.warning100],
  POSITIVE: [colors.indigo700, colors.indigo100],
  NEGATIVE: [colors.secondary600, colors.secondary100],
  POSSIBLE: [colors.success700, colors.success100],
  ACTIVE: [colors.primary600, colors.primary100],
  INACTIVE: [colors.neutral700, colors.neutral100],
  ACTIVE_EXTERNAL_RX: [colors.success700, colors.success100],
  INACTIVE_EXTERNAL_RX: [colors.rose700, colors.rose100],
};

export enum DOMAIN_ELEMENT_VARIENT {
  DIAGNOSES = 'diagnoses',
  MEDICATION = 'medication',
  COGNITIVE_ISSUES = 'cognitive',
  SYMPTOMATIC_FACTORS = 'symptomatic',
  ENVIRONMENTAL_FACTORS = 'environmental',
}

const customSort = arr => {
  const priorityOrder = [
    'critical',
    'medium',
    'resolved',
    'unrelated',
    'high',
    'low',
    'misreported',
    'active',
    'inactive',
    'possible',
    'positive',
    'negative',
    'neutral',
    'active_external_rx',
    'inactive_external_rx',
  ];

  const getOrderIndex = value => {
    const index = priorityOrder.indexOf(value?.toLowerCase());
    return index === -1 ? Infinity : index;
  };

  return arr.sort((a, b) => {
    const indexA = getOrderIndex(a._id);
    const indexB = getOrderIndex(b._id);
    return indexA - indexB;
  });
};

export const formatDomainData = (domainElements: any, lookupData: any, varient?: DOMAIN_ELEMENT_VARIENT) => {
  let customColorsMaping = {} as any;
  let hasStats = false;
  const domainElementStats = customSort(domainElements?.stats)?.map(item => {
    customColorsMaping = {
      ...customColorsMaping,
      [item._id?.toUpperCase()]: IMPORTANCE_LEVEL_COLOR_MAPPING[item._id?.toUpperCase()]?.[0],
    };
    if (item.count > 0) {
      hasStats = true;
    }
    return {
      name:
        item._id?.length > 20
          ? `${item._id?.replaceAll('_', ' ')?.substring(0, 17)}...`
          : item._id?.replaceAll('_', ' '),
      color: IMPORTANCE_LEVEL_COLOR_MAPPING[item._id?.toUpperCase()]?.[0],
      bg: IMPORTANCE_LEVEL_COLOR_MAPPING[item._id?.toUpperCase()]?.[1],
      data: item.count,
    };
  });
  let domainElementData = [];
  switch (varient) {
    case DOMAIN_ELEMENT_VARIENT.MEDICATION:
      domainElementData = domainElements?.records?.map(element => {
        const newElement = {
          ...mapToLegacy(element, lookupData),
          element: mapToLegacy(element, lookupData),
          id: element.id,
          factor: { name: element.taxonomyName, count: element.history?.length ?? 0 },
          dose: element.tagMetaData?.rxDrugInfo?.dosage ?? '-',
          frequency: element.tagMetaData?.rxDrugInfo?.frequency ?? '-',
          supply: element.tagMetaData?.rxDrugInfo?.supply
            ? `${element.tagMetaData?.rxDrugInfo?.supply} ${
                element.tagMetaData?.rxDrugInfo?.supplyUnit ?? ''
              }`
            : '-',
          refillable: element.tagMetaData?.rxDrugInfo?.refillable ?? '-',
          currentLevel: {
            level: element.currentImportanceLevel,
            color: customColorsMaping[element.currentImportanceLevel],
            date: element.currentImportanceLevelDate
              ? dayjs(element.currentImportanceLevelDate).format('MM/DD/YYYY')
              : '',
          },
          firstLevel: {
            level: element.firstImportanceLevel,
            color: customColorsMaping[element.firstImportanceLevel],
            date: element.firstImportanceLevelDate
              ? dayjs(element.firstImportanceLevelDate).format('MM/DD/YYYY')
              : '',
          },
          context: element.note,
          actions: '',
        };
        newElement.actions = newElement;
        return newElement;
      });
      break;

    default:
      domainElementData = domainElements?.records?.map(element => {
        const newElement = {
          ...mapToLegacy(element, lookupData),
          element: mapToLegacy(element, lookupData),
          id: element.id,
          factor: { name: element.taxonomyName, count: element.history?.length ?? 0 },
          currentLevel: {
            level: element.currentImportanceLevel,
            color: customColorsMaping[element.currentImportanceLevel],
            date: element.currentImportanceLevelDate
              ? dayjs(element.currentImportanceLevelDate).format('MM/DD/YYYY')
              : '',
          },
          firstLevel: {
            level: element.firstImportanceLevel,
            color: customColorsMaping[element.firstImportanceLevel],
            date: element.firstImportanceLevelDate
              ? dayjs(element.firstImportanceLevelDate).format('MM/DD/YYYY')
              : '',
          },
          context: element.note,
          actions: '',
        };
        newElement.actions = newElement;
        return newElement;
      });
  }
  return {
    domainElementData,
    domainElementStats: hasStats
      ? domainElementStats
          ?.map(item => ({
            ...item,
            data: [item.data],
            name: toSentenceCase(item.name),
          }))
          .filter(item => item.data?.[0])
      : [],
  };
};

export const FACTOR_DOMAIN_TYPE_MAP = {
  SYMPTOM: 'Symptoms',
  SUBSTANCE_USE: 'Substance Use',
  SIDE_EFFECT: 'Side Effects',
  SOCIAL_DETERMINANT: 'Social Determinants',
  LIFE_EVENT: 'Life Events',
  COGNITIVE_ISSUES: 'Cognitive Issues',
  DIAGONOSES: 'Diagnoses',
  MEDICATIONS: 'Medications',
  symptoms: 'Symptoms',
  substanceUse: 'Substance Use',
  sideEffects: 'Side Effects',
  socialDeterminants: 'Social Determinants',
  lifeEvents: 'Life Events',
  'cognitive-issues': 'Cognitive Issues',
  diagnoses: 'Diagnoses',
  medications: 'Medications',
};

export const getMultiSelectFilterOptions = (priorityType: DOMAIN_ELEMENT_VARIENT) => {
  switch (priorityType) {
    case DOMAIN_ELEMENT_VARIENT.SYMPTOMATIC_FACTORS:
      return [
        {
          sectionTitle: 'Priority type',
          sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
          sectionOptions: [
            { label: 'Symptoms', value: 'Symptoms' },
            { label: 'Substance use', value: 'Substance use' },
            { label: 'Side effects', value: 'Side effects' },
          ],
        },
        {
          sectionTitle: 'Importance levels',
          sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
          sectionOptions: [
            { label: 'Unrelated', value: 'Unrelated' },
            { label: 'Misreported', value: 'Misreported' },
            { label: 'Low', value: 'Low' },
            { label: 'Resolved', value: 'Resolved' },
            { label: 'Medium', value: 'Medium' },
            { label: 'High', value: 'High' },
            { label: 'Critical', value: 'Critical' },
          ],
        },
      ];

    case DOMAIN_ELEMENT_VARIENT.ENVIRONMENTAL_FACTORS:
      return [
        {
          sectionTitle: 'Priority type',
          sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
          sectionOptions: [
            { label: 'Social determinants', value: 'Social determinants' },
            { label: 'Life events', value: 'Life events' },
          ],
        },
        {
          sectionTitle: 'Importance levels',
          sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
          sectionOptions: [
            { label: 'Unrelated', value: 'Unrelated' },
            { label: 'Misreported', value: 'Misreported' },
            { label: 'Low', value: 'Low' },
            { label: 'Resolved', value: 'Resolved' },
            { label: 'Medium', value: 'Medium' },
            { label: 'High', value: 'High' },
            { label: 'Critical', value: 'Critical' },
            { label: 'Negative', value: 'Negative' },
            { label: 'Neutral', value: 'Neutral' },
            { label: 'Positive', value: 'Positive' },
          ],
        },
      ];
    case DOMAIN_ELEMENT_VARIENT.DIAGNOSES:
      return [
        {
          sectionTitle: 'Importance levels',
          sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
          sectionOptions: [
            { label: 'Misreported', value: 'Misreported' },
            { label: 'Inactive', value: 'Inactive' },
            { label: 'Resolved', value: 'Resolved' },
            { label: 'Possible', value: 'Possible' },
            { label: 'Active', value: 'Active' },
          ],
        },
      ];
    case DOMAIN_ELEMENT_VARIENT.MEDICATION:
      return [
        {
          sectionTitle: 'Importance levels',
          sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
          sectionOptions: [
            { label: 'Inactive', value: 'Inactive' },
            { label: 'Inactive external rx', value: 'Inactive external rx' },
            { label: 'Active', value: 'Active' },
            { label: 'Active external rx', value: 'Active external rx' },
          ],
        },
      ];
    case DOMAIN_ELEMENT_VARIENT.COGNITIVE_ISSUES:
      return [
        {
          sectionTitle: 'Importance levels',
          sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
          sectionOptions: [
            { label: 'Misreported', value: 'Misreported' },
            { label: 'Low', value: 'Low' },
            { label: 'Medium', value: 'Medium' },
            { label: 'High', value: 'High' },
            { label: 'Critical', value: 'Critical' },
          ],
        },
      ];

    default:
      return [];
  }
};
export const FilterTextMapping = {
  Symptoms: 'symptoms',
  'Substance use': 'substance-use',
  'Side effects': 'side-effects',
  'Social determinants': 'social-determinants',
  'Life events': 'life-events',
  Misreported: 'MISREPORTED',
  Low: 'LOW',
  Medium: 'MEDIUM',
  High: 'HIGH',
  Critical: 'CRITICAL',
  Inactive: 'INACTIVE',
  Active: 'ACTIVE',
  'Inactive external rx': 'INACTIVE_EXTERNAL_RX',
  'Active external rx': 'ACTIVE_EXTERNAL_RX',
  Resolved: 'RESOLVED',
  Possible: 'POSSIBLE',
  Positive: 'POSITIVE',
  Neutral: 'NEUTRAL',
  Negative: 'NEGATIVE',
  Unrelated: 'UNRELATED',
};
export const getFilters = (filters: tableParamsType) => {
  const importanceLevels = filters.search?.multiSelectFilter?.['Importance levels']?.map(
    item => FilterTextMapping[item],
  );
  const priorityType = filters.search?.multiSelectFilter?.['Priority type']?.map(
    item => FilterTextMapping[item],
  );
  return {
    importanceLevels: importanceLevels?.length > 0 ? importanceLevels?.toString() : '',
    priorityType: priorityType?.length > 0 ? priorityType?.toString() : '',
  };
};
