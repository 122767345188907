import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { ProgressBar } from '@confidant-health/lib/ui/atoms/ProgressBar';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Link } from '@confidant-health/lib/ui/atoms/link';
import { badgeStyle, badgeType, Badge } from '@confidant-health/lib/ui/atoms/badge';
import { ApexOptions } from 'apexcharts';
import cx from 'clsx';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';
import { IChatbotAttemptHistory } from 'redux/modules/conversation/types';
import { getMemberCompletionHistory } from 'services/reporting/reporting.service';
import ResponseDrawer from '../responses-drawer';
import { useStyles } from '../../../Evaluations/evaluations.styles';

type CompletionDrawerProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  params: {
    userId: string;
    dctId: string;
  };
};

const options: ApexOptions = {
  chart: {
    toolbar: { show: false },
    selection: { enabled: false },
    zoom: { enabled: false },
    offsetX: 100,
    offsetY: 100,
  },
  dataLabels: { enabled: false },
  colors: [
    ({ value }) => {
      if (value > 1) {
        return '#2da834';
      }
      if (value === 1) {
        return '#7edc84';
      }
      return '#d4dde5';
    },
  ],
  grid: { show: false },
  xaxis: {
    labels: { show: false },
    axisTicks: { show: false },
    axisBorder: { show: false },
  },
  yaxis: { show: false },
  tooltip: { enabled: false },
  legend: { show: false },
  plotOptions: {
    heatmap: {
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: '#d4dde5',
            foreColor: '#d4dde5',
          },
          {
            from: 1,
            to: 1,
            color: '#7edc84',
            foreColor: '#7edc84',
          },
          {
            from: 10000,
            to: 2,
            color: '#2da834',
            foreColor: '#2da834',
          },
        ],
      },
    },
  },
};

const CompletionDrawer: React.FC<CompletionDrawerProps> = ({ open, onClose, params, title }) => {
  const [loading, setLoading] = useState(false);
  const [attempts, setAttempts] = useState(null);
  const [selectedCompletionId, setSelectedCompletionId] = useState(null);
  // const [last30Days, setlast30Days] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    void (async () => {
      setLoading(true);
      try {
        const { data } = await getMemberCompletionHistory(params);
        setAttempts(data);
        // setlast30Days(data.last30Days);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const renderCard = (attempt: IChatbotAttemptHistory, index: number) => {
    let attemptTime = '';
    let status = '';
    let progress = 0;
    let contextId = '';
    if (attempt.attemptDetails.length) {
      attemptTime = dayjs(attempt.attemptDetails[0].attemptTime).format('MMMM D, YYYY - hh:mm a');
      progress = attempt.attemptDetails[0].progress;
      status = progress > 99 ? 'Completed' : 'In progress';
      contextId = attempt.attemptDetails[0].contextId;
    } else {
      attemptTime = dayjs(attempt.date).format('MMMM D, YYYY');
      status = 'Not started';
      progress = 0;
      contextId = null;
      return null;
    }

    return (
      <Box key={index} className={classes.chatBotCard}>
        <Box className={classes.chatBotCardHeaderWrapper}>
          <Box className={classes.chatBotCardHeaderCompletions}>
            <Box className={classes.chatBotCardHeaderContent}>
              <Box>{attemptTime}</Box>
              <Badge
                className={classes.badgeOutlined}
                style={status === 'Completed' ? badgeStyle.SUCCESS : badgeStyle.CRITICAL}
                variant={badgeType.OUTLINED}
              >
                {status}
              </Badge>
            </Box>
          </Box>

          {contextId && (
            <Box className={classes.actions}>
              <Link
                className={cx({ [classes.careTeamValueWrapper]: true, [classes.link]: true })}
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  setSelectedCompletionId(contextId);
                }}
              >
                View
                <Icons glyph="arrow-right" color={colors.primary600} />
              </Link>
            </Box>
          )}
        </Box>
        {progress < 100 && (
          <Box className={classes.progressWrapper}>
            <Text className={classes.chatBotProgressText} level={textLevel.XS} weight={fontWeight.MEDIUM}>
              {progress}% completed
            </Text>
            <ProgressBar value={progress} />
          </Box>
        )}
        {attempt.attemptDetails[0]?.providerName && attempt.attemptDetails[0]?.providerDesignation && (
          <>
            <Divider sx={{ maringY: 1 }} />
            <Box
              className={classes.profile}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderTopLeftRadius: '0px !important',
                borderTopRightRadius: '0px !important',
                padding: '24px',
              }}
            >
              <Box display="flex" alignItems="center" gap="12px">
                <Avatar
                  name={attempt.attemptDetails[0]?.providerName}
                  size="40px"
                  variant={avatarType.CIRCLE}
                  src={attempt.attemptDetails[0]?.providerProfileImage}
                />
                <Box>
                  <Text level={textLevel.S}>{attempt.attemptDetails[0]?.providerName}</Text>
                  <Badge style={badgeStyle.PURPLE}>{attempt.attemptDetails[0]?.providerDesignation}</Badge>
                </Box>
              </Box>
              <Text level={textLevel.S}>Opiod use evaluation</Text>
            </Box>
          </>
        )}
      </Box>
    );
  };

  return (
    <Drawer
      title="Previous completions"
      subTitle={title}
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      footer={<div />}
      className={classes.drawer}
    >
      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <Box padding={3}>
          {attempts != null && attempts?.data?.last30Days?.length > 0 ? (
            <>
              <Text level={textLevel.M}>Completions map within last 30 days</Text>
              <Box className={classes.heatmapChart} marginTop="24px">
                <ReactApexChart
                  options={options}
                  series={[
                    { data: attempts?.data?.last30Days?.map(attempt => attempt.attemptDetails.length) },
                  ]}
                  type="heatmap"
                  height={70}
                />
              </Box>
              <Heading weight={fontWeight.SEMI_BOLD} level={headingLevel.S}>
                All completions
              </Heading>
              <Box display="flex" flexDirection="column" gap="8px" marginTop="24px">
                {attempts?.data.completeHistory.map((attempt: IChatbotAttemptHistory, index: number) =>
                  renderCard(attempt, index),
                )}
              </Box>
            </>
          ) : (
            <Text weight={fontWeight.SEMI_BOLD} level={textLevel.M}>
              No completions yet
            </Text>
          )}
        </Box>
      )}
      {!!selectedCompletionId && (
        <ResponseDrawer
          open={!!selectedCompletionId}
          onClose={() => setSelectedCompletionId(null)}
          title={title}
          params={{
            contextId: selectedCompletionId,
            dctId: params?.dctId,
          }}
        />
      )}
    </Drawer>
  );
};

export default CompletionDrawer;
