/* eslint-disable @typescript-eslint/no-floating-promises */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components
import { Stack } from '@mui/material';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { BaseLayout } from 'layouts/base';
import { conversationActionCreators } from 'redux/modules/conversation/actions';
import { scheduleActionCreators } from 'redux/modules/schedule/actions';
import { profileActionCreators } from 'redux/modules/profile/actions';
import { selectPlanItemState } from 'redux/modules/conversation/selectors';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { ContentfulClient } from 'config/contentful-config';
import { CONTENTFUL_CONTENT_TYPES } from 'constants/CommonConstants';

import AddPlanItem from './components/add-plan-item';
// mock
import { filterOptionsMock, planItemFilterOptionsMock, tableColumns } from './PlanItems.mock';
// styles
import { useStyles } from './PlanItems.styles';

const PlanItems: FC = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [selectedPlanItem, setSelectedPlanItem] = useState(null);
  const [educationalContent, setEducationalContent] = useState([]);
  const [topics, setTopics] = useState([]);
  const { isLoading, totalRecords, planItems } = useSelector(selectPlanItemState);
  const [value, setValue] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toUserReadableFormat = inputString => {
    const words = inputString?.split('_') || [];
    return words
      .map(word => {
        return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
      })
      .join(' ');
  };

  const tableData = useMemo(
    () =>
      planItems
        .map(item => ({
          ...item,
          name: {
            planId: item.id,
            name: item.name,
          },
          completedState: toUserReadableFormat(item.completedState),
          progressState: toUserReadableFormat(item.progressState),
          type: toUserReadableFormat(item.type),
          navigatesTo: toUserReadableFormat(item.navigatesTo),
          actions: { id: item.id },
          removedByMember: item.RevampPlanRemove?.reference === 'REMOVED_BY_MEMBER',
          completeXTimes: item.RevampPlanRemove?.completeXTimes ?? 0,
        }))
        ?.sort((a, b) => (a > b ? 1 : -1)),
    [planItems],
  );

  const getPlanTypeFilters = () => {
    const planType = [];
    const multiselectFilters = value.search?.multiSelectFilter;
    if (multiselectFilters?.PlanType) {
      multiselectFilters.PlanType.forEach(filter => {
        switch (filter) {
          case 'Provider Type':
            planType.push('PROVIDER_TYPE');
            break;
          default:
            planType.push(filter.toUpperCase().replace(/ /g, '_'));
            break;
        }
      });
    }

    return planType?.toString() || '';
  };

  const handleFetchPlanItemsParamsFormat = ({ search, pagination, sorter }: tableParamsType) => {
    const sortBy = sorter.column === 'member' ? 'name' : sorter.column || '';
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction,
      sortBy,
      type: getPlanTypeFilters(),
    };
    return params;
  };

  const getEducationalContent = async () => {
    const params = {
      content_type: CONTENTFUL_CONTENT_TYPES.EDUCATIONAL_CONTENT,
      limit: 250,
    };
    try {
      const response = await ContentfulClient.getEntries(params);
      const content = response.items.map(item => {
        return {
          id: item.sys.id,
          title: item.fields.title,
        };
      });
      setEducationalContent(content);
    } catch (error) {
      console.log(error);
    }
  };

  const getTopics = async () => {
    const params = {
      content_type: CONTENTFUL_CONTENT_TYPES.TOPICS,
      limit: 30,
    };
    try {
      const response = await ContentfulClient.getEntries(params);
      const data = response.items.map(topic => {
        return {
          id: topic.sys.id,
          title: topic.fields.name,
        };
      });
      setTopics(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(conversationActionCreators.fetchConversations());
    dispatch(profileActionCreators.fetchGroups());
    dispatch(profileActionCreators.fetchProviders());
    dispatch(conversationActionCreators.fetchRevampTypesList());
    dispatch(scheduleActionCreators.fetchSystemServices());
    dispatch(appointmentActionCreators.fetchProviderRoles());
    (async () => {
      await getEducationalContent();
      await getTopics();
    })();
  }, []);

  useEffect(() => {
    dispatch(conversationActionCreators.fetchPlanItems(handleFetchPlanItemsParamsFormat(value)));
  }, [value]);

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const onSelectPlanItem = planItem => {
    navigate(`/admin/data-management-plan-items/${planItem.id}`);
  };

  const onAddPlanItem = useCallback(() => {
    setSelectedPlanItem(null);
    toggleAddNew();
  }, [setSelectedPlanItem, toggleAddNew]);

  const onEditPlanItem = (planId: string) => {
    setSelectedPlanItem(planItems.find(item => item.id === planId));
    toggleAddNew();
  };

  const onDeletePlanItem = (planId: string) => {
    dispatch(conversationActionCreators.deletePlanItem(planId));
  };

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: ({ name }) => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {name}
          </Text>
        ),
      };
    }
    if (column.id === 'removedByMember') {
      return {
        ...column,
        renderCell: (val: boolean) => (
          <Icons glyph={val ? 'checkmark' : 'close-circle-outlined'} color={colors.neutral700} />
        ),
        className: 'removedByMember',
      };
    }
    if (column.id === 'completeXTimes') {
      return {
        ...column,
        renderCell: (val: string) => val,
        className: 'completeXTimes',
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              { label: 'Edit Plan Item', onClick: () => onEditPlanItem(id) },
              { label: 'Delete Plan Item', onClick: () => onDeletePlanItem(id) },
            ]}
          />
        ),
      };
    }
    return column;
  });

  return (
    <>
      <AddPlanItem
        isOpen={showAddNew}
        planItem={selectedPlanItem}
        educationalContent={educationalContent}
        topics={topics}
        onClose={toggleAddNew}
        filters={getPlanTypeFilters()}
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Plan Items
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {totalRecords} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onAddPlanItem}
            >
              Add plan item
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search name',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                options: filterOptionsMock,
                multiSelectOptions: planItemFilterOptionsMock,
                allOptionLabel: 'All profile',
              },
            }}
            gridProps={{
              columns: renderColumns,
              data: tableData,
              onRowClick: item => onSelectPlanItem(item),
              isLoading,
            }}
            paginationProps={{
              currentRows: tableData.length,
              totalCount: totalRecords,
            }}
            value={value}
            onChange={setValue}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { PlanItems };
