/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { INoteCategoryCardProps } from '@confidant-health/lib/ui/templates/note-category-card/NoteCategoryCard.types';
import { CONTACT_NOTES_FLAGS } from '@confidant-health/lib/constants/CommonConstants';
import { Icons } from '@confidant-health/lib/icons';
import { CONTACT_NOTES_TYPES, INote, INoteAttachment } from '@confidant-health/lib/ui/templates/note-card';
import { Chip } from '@confidant-health/lib/ui/atoms/chip';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { ShareNoteBox } from '@confidant-health/lib/ui/templates/share-note-box/ShareNoteBox';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from 'redux/modules/snackbar';
import { getProfile } from 'redux/modules/profile/selectors';
import { conversationActionCreators } from 'redux/modules/conversation';
import { NOTES_STATUS_CATEGORIES } from 'pages/admin/notes/NotesList.constants';
// styles
import { useStyles } from './AddNoteForm.styles';
import { AddNoteSchema } from './AddNoteSchema';
import { AttachmentCard } from '../AttachmentCard';
import { AddNotePayload } from './AddNoteForm.types';
import dayjs from '../../../../../../utils/dayjs';
import { GLOBAL_DATE_FORMAT } from '../../../../../../constants/CommonConstants';
import { uuid4 } from '../../../../../../lib/sendbird';
import { profileActionCreators } from '../../../../../../redux/modules/profile';
import { convertByteToString } from '../../../../../../utils';

const STATUS_TEXTS = {
  REPORTED: 'REPORTED',
  REVIEWED_BY_MANAGEMENT: 'REVIEWED_BY_MANAGEMENT',
  RESOLVED: 'RESOLVED',
  RECEIVED_BY_CARE_MANAGEMENT: 'RECEIVED_BY_CARE_MANAGEMENT',
  SENT_TO_MEMBER: 'SENT_TO_MEMBER',
  SENT_TO_REQUESTED_CONTACT: 'SENT_TO_REQUESTED_CONTACT',
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  REQUESTED: 'REQUESTED',
  SENT_TO_LAB: 'SENT_TO_LAB',
  NO_RESPONSE: 'NO_RESPONSE',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  REMOVED: 'REMOVED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  RESCINDED: 'RESCINDED',
};
export const getSpecialStatus = (status: string) => {
  switch (status) {
    case STATUS_TEXTS.REPORTED:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.REVIEWED_BY_MANAGEMENT:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.RESOLVED:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.RECEIVED_BY_CARE_MANAGEMENT:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.SENT_TO_MEMBER:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.SENT_TO_REQUESTED_CONTACT:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.COMPLETED:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.REQUESTED:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.SENT_TO_LAB:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.NO_RESPONSE:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.REJECTED:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.SUBMITTED:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.PENDING:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.UNSUCCESSFUL:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.REMOVED:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    case STATUS_TEXTS.ACTIVE:
      return NOTES_STATUS_CATEGORIES.NEEDS_ACTION;
    case STATUS_TEXTS.INACTIVE:
      return NOTES_STATUS_CATEGORIES.NO_ACTION_NEEDED;
    default:
      return '';
  }
};
enum RELEASE_INFORMATION_TYPE {
  OBTAIN_INFORMATION_FORM = 'obtainInformationFrom',
  RELEASE_INFORMATION_TO = 'releaseInformationTo',
}
type AddNoteFormProps = {
  isSaving: boolean;
  note?: INote;
  noteCategory: INoteCategoryCardProps;
  onCancel: () => void;
  onSubmit: (note: AddNotePayload) => void;
};
const getInitialValues = (category: CONTACT_NOTES_FLAGS) => {
  const generalFields = {
    type: '',
    title: '',
    description: '',
    attachments: [],
    recipients: [],
    status: '',
  };
  const informationFields = {
    nameOfReleaseTarget: '',
    relationship: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
  };
  const standingOrderFields = {
    frequency: '',
    isStandingOrder: false,
  };
  switch (category) {
    case CONTACT_NOTES_FLAGS.PROHIBITIVE:
    case CONTACT_NOTES_FLAGS.CAUTION:
      return {
        ...generalFields,
        internalType: '',
      };
    case CONTACT_NOTES_FLAGS.CONTACT:
      return {
        ...generalFields,
        methodOfContact: '',
      };
    case CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION:
      return {
        ...generalFields,
        releaseInformationToCheck: false,
        obtainInfromationFromCheck: false,
        releaseInformationTo: informationFields,
        obtainInformationFrom: informationFields,
        typeOfInformationForRelease: [],
        additionalInformationForRelease: '',
        rationaleReasonForRelease: '',
        purposeOfDisclosure: '',
        signableDocumentLink: '',
      };
    case CONTACT_NOTES_FLAGS.LAB_REQUEST:
      return {
        ...generalFields,
        selectedTests: [],
        selectedTestsDescription: '',
        standingOrder: standingOrderFields,
        // frequencyOfStabdingOrder: ,
        lab: '',
      };
    case CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE:
    case CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION:
      return {
        ...generalFields,
        typesOfHelpNeeded: [],
      };
    case CONTACT_NOTES_FLAGS.REFERRAL_OUT:
      return {
        ...generalFields,
        typesOfReferralNeeded: [],
        typesOfReferral: [],
      };
    default:
      return generalFields;
  }
};
const AddNoteForm: FC<AddNoteFormProps> = ({ note, isSaving, noteCategory, onSubmit, onCancel }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [labOptions] = useState([
    {
      label: 'Quest',
      value: 'Quest',
    },
    {
      label: 'Labcorp',
      value: 'Labcorp',
    },
    {
      label: 'Other',
      value: 'Other',
    },
    {
      label: 'Home Kit',
      value: 'Home Kit',
    },
    {
      label: 'Unsure - Need Care Management Support',
      value: 'Unsure - Need Care Management Support',
    },
  ]);
  const [subFieldErrors, setSubFieldErrors] = useState([]);
  const [dirty, setDirty] = useState(false);
  // const {
  //   profileElements: { profileElementList },
  // } = useSelector(getConversation);

  const { providers } = useSelector(getProfile);
  const dispatch = useDispatch();
  // const localLabProfileElement = useMemo(
  //   () =>
  //     profileElementList.find(
  //       profileElement => profileElement.profileElementInfo.key === LOCAL_LAB_PROFILE_ELEMENT,
  //     ) || null,
  //   [profileElementList],
  // );
  // const labOptions = useMemo(() => labs.map(lab => ({ label: lab.name, value: lab.name })) || [], [labs]);
  const flag = noteCategory?.flag || note?.flag;
  const customValidation = vals => {
    const releaseChecks = subFieldErrors;
    ['nameOfReleaseTarget', 'city', 'state', 'zip', 'phone'].forEach(key => {
      if (vals.releaseInformationToCheck && vals.releaseInformationTo?.[key] === '') {
        if (!releaseChecks.includes(`releaseInformationTo.${key}`)) {
          releaseChecks.push(`releaseInformationTo.${key}`);
        }
      } else if (releaseChecks.includes(`releaseInformationTo.${key}`)) {
        const index = releaseChecks.indexOf(`releaseInformationTo.${key}`);
        releaseChecks.splice(index, 1);
      }
      if (vals.obtainInfromationFromCheck && vals.obtainInformationFrom?.[key] === '') {
        if (!releaseChecks.includes(`obtainInformationFrom.${key}`)) {
          releaseChecks.push(`obtainInformationFrom.${key}`);
        }
      } else if (releaseChecks.includes(`obtainInformationFrom.${key}`)) {
        const index = releaseChecks.indexOf(`obtainInformationFrom.${key}`);
        releaseChecks.splice(index, 1);
      }
    });
    if (releaseChecks.length) {
      setSubFieldErrors([]);
      setSubFieldErrors(releaseChecks);
      return false;
    }
    if (recipients.length > 0) {
      const someEmptyRecipients = recipients.some(
        recipient => recipient.message === '' || recipient.providerId === '',
      );
      if (someEmptyRecipients) {
        return false;
      }
    }
    return true;
  };
  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: getInitialValues(noteCategory?.flag || note?.flag),
    validationSchema: AddNoteSchema(flag),
    validateOnChange: true,
    validate(payload) {
      customValidation(payload);
    },
    onSubmit: (payload: any) => {
      setDirty(true);
      if (customValidation(payload)) {
        payload.recipients = recipients.map(recipient => {
          if (recipient.dueDate) {
            recipient.dueDate = dayjs(recipient.dueDate).format(GLOBAL_DATE_FORMAT);
          }
          delete recipient.key;
          return recipient;
        });
        onSubmit(payload);
      }
    },
  });
  const initiallizeFields = () => {
    Object.keys(note)?.forEach(key => {
      void rest.setFieldValue(`${key}`, note?.[key]);
    });
  };
  useEffect(() => {
    if (note) {
      initiallizeFields();
      setRecipients(
        note?.recipients?.map(rec => {
          return {
            ...rec,
            dueDate: rec.dueDate ? dayjs(rec.dueDate, GLOBAL_DATE_FORMAT).toISOString() : undefined,
            key: uuid4(),
          };
        }) || [],
      );
      void rest.setFieldValue('type', note.type || flag);
      void rest.setFieldValue('title', note.name);
      switch (note.flag) {
        case CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION:
          void rest.setFieldValue(
            'releaseInformationToCheck',
            note.releaseInformationTo?.nameOfReleaseTarget && true,
          );
          void rest.setFieldValue(
            'obtainInfromationFromCheck',
            note.obtainInformationFrom?.nameOfReleaseTarget && true,
          );
          break;
        default:
          break;
      }
    }
  }, [note]);

  useEffect(() => {
    if (
      noteCategory?.flag === CONTACT_NOTES_FLAGS.LAB_REQUEST ||
      note?.flag === CONTACT_NOTES_FLAGS.LAB_REQUEST
    ) {
      const queryParams = {
        searchQuery: '',
        type: '',
        method: '',
        pageNumber: 0,
        pageSize: 10000,
        orderBy: '',
        sortBy: [],
      };
      dispatch(conversationActionCreators.fetchProfileElements(queryParams));
    }
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 1000,
        orderBy: '',
        sortBy: [],
        careNavigator: true,
        requestBody: {},
      }),
    );
  }, []);
  // useEffect(() => {
  //   if (localLabProfileElement?.profileElementInfo?.id)
  //     getPercentileDistributionOfProfileElementsByValues(localLabProfileElement.profileElementInfo.id)
  //       .then(res => {
  //         setLabs(res.data?.values);
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  // }, [localLabProfileElement]);

  const replaceSpace = (name: string, character = '_'): string => {
    return name.replace(/ /g, character);
  };

  const analyzeFile = (file: File): Promise<any> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({
          attachmentName: replaceSpace(file.name),
          attachmentSize: convertByteToString(file.size),
          attachmentType: file.type,
          attachmentUrl: reader.result,
          originFile: file,
        });
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleUploadFile = async files => {
    const filesRead = files.map(f => analyzeFile(f));
    const res = await Promise.all(filesRead);
    values.attachments?.push(...(res as INoteAttachment[]));
    handleChange({
      target: {
        name: 'attachments',
        value: values.attachments,
      },
    });
  };

  const onAddRecipient = () => {
    setRecipients([
      ...recipients,
      {
        providerId: '',
        dueDate: undefined,
        message: '',
        notifyViaSms: false,
        key: uuid4(),
      },
    ]);
  };

  const handleUploadFileRejection = () => {
    dispatch(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'File size exceeds the maximum limit',
      }),
    );
  };
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleUploadFile,
    onDropRejected: handleUploadFileRejection,
    noClick: true,
    accept: '',
    multiple: true,
    maxSize: 200 * 1024 * 1024,
  });

  /*   const onAcceptedFiles = async (files: File[]) => {
    const filesRead = files.map(f => S3Service.analyzeFile(f));
    const res = await Promise.all(filesRead);

    values.attachments?.push(...(res as INoteAttachment[]));
    handleChange({
      target: {
        name: 'attachments',
        value: values.attachments,
      },
    });
  };
 */
  const onRemoveAttachment = (index: number) => () => {
    values.attachments.splice(index, 1);
    handleChange({
      target: {
        name: 'attachments',
        value: values.attachments,
      },
    });
  };

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (label: string, required?: boolean) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
      {required && <span className={classes.asterick}>*</span>}
    </Heading>
  );
  const onHandleDelete = (name: string, index: number) => {
    values[name].splice(index, 1);
    void rest.setFieldValue(name, values[name]);
  };
  const handleMultiselectChange = (name: string) => (event: any) => {
    if (!event.target.value) {
      return;
    }
    if (values?.[name]?.length > 0) void rest.setFieldValue(name, [...values?.[name], event.target.value]);
    else void rest.setFieldValue(name, [event.target.value]);
  };
  const renderMultiSelect = (
    name: string,
    label: string,
    selectOptions: { label: string; value: string }[],
    required?: boolean,
  ) => {
    return (
      <>
        <Box className={classes.block}>
          {renderLabel(label, required)}
          <Select
            options={selectOptions.filter(option => !values[name]?.includes(option.value))}
            value=""
            emptyText="Select multiple"
            displayEmpty
            name={name}
            onChange={handleMultiselectChange(name)}
            variant={selectType.SECONDARY}
            fullWidth
          />
        </Box>
        {values?.[name]?.length > 0 && (
          <div className={classes.chips}>
            {values?.[name]?.map((data, index) => {
              return (
                <Chip
                  key={index}
                  label={data}
                  onDelete={() => {
                    onHandleDelete(name, index);
                  }}
                  className="chip"
                />
              );
            })}
          </div>
        )}
        <TextError errorMsg={touched?.[name] ? errors?.[name]?.toString() : null} />
      </>
    );
  };
  const rednerSingleSelect = (
    name: string,
    label: string,
    selectOptions: { label: string; value: string }[],
    emptyText: string,
    required?: boolean,
  ) => {
    return (
      <Box className={classes.block}>
        {renderLabel(label, required)}
        <Select
          options={selectOptions}
          value={values[name]}
          emptyText={emptyText}
          displayEmpty
          name={name}
          onChange={handleChange}
          variant={selectType.SECONDARY}
          fullWidth
        />
        <TextError errorMsg={touched?.[name] ? errors?.[name]?.toString() : null} />
      </Box>
    );
  };
  useEffect(() => {
    if (CONTACT_NOTES_TYPES[flag]) {
      const newOpts = CONTACT_NOTES_TYPES[flag].map(item => ({
        label: item,
        value: item,
      }));
      handleChange({
        target: {
          name: 'type',
          value: newOpts[0].value,
        },
      });
      setOptions(newOpts);
    }
  }, [noteCategory]);
  const renderTitleField = () => {
    return (
      <Box className={classes.block}>
        {renderLabel('Title', true)}
        <Input
          value={values.title}
          name="title"
          placeholder="Enter title"
          onChange={handleChange}
          size={inputSize.M}
          onBlur={setTouched('title')}
          fullWidth
        />
        <TextError errorMsg={touched.title ? errors.title?.toString() : null} />
      </Box>
    );
  };
  const renderDescriptionAndAttachmentFields = () => {
    return (
      <>
        <Box className={classes.block}>
          {renderLabel('Description')}
          <Textarea
            value={values.description}
            name="description"
            onChange={handleChange}
            onBlur={setTouched('description')}
            minRows={5}
            placeholder="Enter description"
          />
          <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Attachments')}
          <div className={clsx(classes.root2, { [classes.dragging]: isDragActive })} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Text className={classes.filesize} level={textLevel.S}>
                Drop files here to upload
              </Text>
            ) : (
              <div className={classes.content2}>
                <Icons glyph="add-image-outlined" className={classes.icon} />
                <Heading level={headingLevel.M} className={classes.uploadText} weight={fontWeight.MEDIUM}>
                  <span onClick={open}>Upload a file</span> or drag and drop
                </Heading>
                <Text className={classes.filesize} level={textLevel.S}>
                  File size up to 200MB
                </Text>
              </div>
            )}
          </div>
          {/* <UploadFile onAcceptedFiles={onAcceptedFiles} multiple /> */}
          {values.attachments?.length > 0 && (
            <Box className={classes.attachmentWrap}>
              {values.attachments.map((file: any, index: number) => (
                <AttachmentCard
                  key={index}
                  file={file}
                  onRemove={onRemoveAttachment(index)}
                  className={classes.attachment}
                />
              ))}
            </Box>
          )}
        </Box>
      </>
    );
  };

  const renderSharingOptions = () => {
    const sharedProviderIds = recipients.map(recipient => recipient.providerId);
    const sortedProviders = providers?.sort((a, b) => {
      return a?.fullName?.localeCompare(b?.fullName);
    });
    return (
      <>
        <Box className={classes.block}>
          <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
            Share Note
          </Heading>
          <Box gap="24px" display="flex" flexDirection="column">
            {recipients.map((recipient, index) => {
              return (
                <ShareNoteBox
                  key={recipient.key}
                  sequenceNumber={index + 1}
                  providerId={recipient.providerId}
                  providers={sortedProviders
                    .filter(provider => {
                      if (provider.id === recipient.providerId) {
                        return true;
                      }
                      return !sharedProviderIds.includes(provider.id);
                    })
                    .map(provider => {
                      return {
                        label: provider.fullName,
                        value: provider.id,
                        avatar: provider.profileImage,
                      };
                    })}
                  dueDate={recipient.dueDate}
                  message={recipient.message}
                  notifyViaSms={recipient.notifyViaSms}
                  onSelectProvider={providerId => {
                    recipients[index].providerId = providerId;
                    setRecipients([...recipients]);
                  }}
                  onSelectDueDate={date => {
                    recipients[index].dueDate = date;
                    setRecipients([...recipients]);
                  }}
                  onChangeMessage={value => {
                    recipients[index].message = value;
                    setRecipients([...recipients]);
                  }}
                  onChangeNotifyToggle={checked => {
                    recipients[index].notifyViaSms = checked;
                    setRecipients([...recipients]);
                  }}
                  onDelete={() => {
                    const resultArray = [...recipients];
                    resultArray.splice(index, 1);
                    setRecipients(resultArray);
                  }}
                  dirty={dirty}
                />
              );
            })}
          </Box>
          <IconButton icon="forward" className={classes.addRecipientLink} onClick={onAddRecipient}>
            Add recipient
          </IconButton>
        </Box>
      </>
    );
  };

  const getStatusOptions = (type: CONTACT_NOTES_FLAGS) => {
    switch (type) {
      case CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION:
        return [
          {
            label: 'Received by Care Management',
            value: STATUS_TEXTS.RECEIVED_BY_CARE_MANAGEMENT,
          },
          {
            label: 'Sent to Member/Client/Guest',
            value: STATUS_TEXTS.SENT_TO_MEMBER,
          },
          {
            label: 'Sent to Requested Contact/Department/Company',
            value: STATUS_TEXTS.SENT_TO_REQUESTED_CONTACT,
          },
          {
            label: 'Completed',
            value: STATUS_TEXTS.COMPLETED,
          },
          {
            label: 'Incomplete',
            value: STATUS_TEXTS.INCOMPLETE,
          },
          {
            label: 'Rescinded',
            value: STATUS_TEXTS.RESCINDED,
          },
        ];
      case CONTACT_NOTES_FLAGS.LAB_REQUEST:
        return [
          {
            label: 'Requested',
            value: STATUS_TEXTS.REQUESTED,
          },
          {
            label: 'Sent to Lab',
            value: STATUS_TEXTS.SENT_TO_LAB,
          },
          {
            label: 'No Response - Client inaction',
            value: STATUS_TEXTS.NO_RESPONSE,
          },
          {
            label: 'Completed - Results',
            value: STATUS_TEXTS.COMPLETED,
          },
          {
            label: 'Rejected',
            value: STATUS_TEXTS.REJECTED,
          },
        ];
      case CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE:
      case CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION:
      case CONTACT_NOTES_FLAGS.REFERRAL_OUT:
        return [
          {
            label: 'Submitted',
            value: STATUS_TEXTS.SUBMITTED,
          },
          {
            label: 'Pending',
            value: STATUS_TEXTS.PENDING,
          },
          {
            label: 'Resolved',
            value: STATUS_TEXTS.RESOLVED,
          },
          {
            label: 'No Response',
            value: STATUS_TEXTS.NO_RESPONSE,
          },
          {
            label: 'Unsuccessful',
            value: STATUS_TEXTS.UNSUCCESSFUL,
          },
        ];

      default:
        return [
          {
            label: 'Reported',
            value: STATUS_TEXTS.REPORTED,
          },
          {
            label: 'Reviewed by Management',
            value: STATUS_TEXTS.REVIEWED_BY_MANAGEMENT,
          },
          {
            label: 'Resolved',
            value: STATUS_TEXTS.RESOLVED,
          },
        ];
    }
  };

  const renderStatusField = (type: CONTACT_NOTES_FLAGS) => {
    return (
      <Box className={classes.block}>
        {renderLabel('Status', true)}
        <Select
          options={getStatusOptions(type)}
          value={values.status}
          emptyText="Select a status"
          displayEmpty
          name="status"
          onChange={handleChange}
          variant={selectType.SECONDARY}
          fullWidth
        />
        <TextError errorMsg={touched.status ? errors.status?.toString() : null} />
      </Box>
    );
  };
  const renderReleaseInformationToSubFields = () => {
    const fieldNames = {
      nameOfReleaseTarget: 'releaseInformationTo.nameOfReleaseTarget',
      relationship: 'releaseInformationTo.relationship',
      address1: 'releaseInformationTo.address1',
      address2: 'releaseInformationTo.address2',
      city: 'releaseInformationTo.city',
      state: 'releaseInformationTo.state',
      zip: 'releaseInformationTo.zip',
      phone: 'releaseInformationTo.phone',
      fax: 'releaseInformationTo.fax',
    };

    return (
      <Box className={classes.innerBlock}>
        <Box className={classes.block}>
          {renderLabel('Name of Target', true)}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.nameOfReleaseTarget}
            name={fieldNames.nameOfReleaseTarget}
            placeholder="Enter name of target"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.nameOfReleaseTarget)}
            fullWidth
          />
          <TextError
            errorMsg={
              subFieldErrors.includes('releaseInformationTo.nameOfReleaseTarget')
                ? 'Name of target required'
                : null
            }
          />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Relationship')}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.relationship}
            name={fieldNames.relationship}
            placeholder="Enter relationship"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.relationship)}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Address 1')}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.address1}
            name={fieldNames.address1}
            placeholder="Enter address 1"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.address1)}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Address 2')}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.address2}
            name={fieldNames.address2}
            placeholder="Enter address 2"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.address2)}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          <Box className={classes.block}>
            {renderLabel('City', true)}
            <Input
              value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.city}
              name={fieldNames.city}
              placeholder="Enter city"
              onChange={handleChange}
              size={inputSize.M}
              onBlur={setTouched(fieldNames.city)}
              fullWidth
            />
            <TextError
              errorMsg={subFieldErrors.includes('releaseInformationTo.city') ? 'City is required' : null}
            />
          </Box>
          <Box className={classes.horizontalBlock}>
            <Box className={classes.block}>
              {renderLabel('State', true)}
              <Input
                value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.state}
                name={fieldNames.state}
                placeholder="Enter state"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched(fieldNames.state)}
                fullWidth
              />
              <TextError
                errorMsg={subFieldErrors.includes('releaseInformationTo.state') ? 'State is required' : null}
              />
            </Box>
            <Box className={classes.block}>
              {renderLabel('Zip', true)}
              <Input
                value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.zip}
                name={fieldNames.zip}
                placeholder="Enter zip"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched(fieldNames.zip)}
                fullWidth
              />
              <TextError
                errorMsg={subFieldErrors.includes('releaseInformationTo.zip') ? 'Zip is required' : null}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.block}>
          {renderLabel('Phone', true)}
          <Input
            variant={inputType.PHONE}
            value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.phone}
            name={fieldNames.phone}
            placeholder="Enter phone"
            onChange={e => {
              void rest.setFieldValue(fieldNames.phone, e);
            }}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.phone)}
            fullWidth
          />
          <TextError
            errorMsg={
              subFieldErrors.includes('releaseInformationTo.phone') ? 'Phone number is required' : null
            }
          />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Fax')}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.RELEASE_INFORMATION_TO]?.fax}
            name={fieldNames.fax}
            placeholder="Enter fax"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.fax)}
            fullWidth
          />
        </Box>
      </Box>
    );
  };
  const renderObtainInformationFromSubFields = () => {
    const fieldNames = {
      nameOfReleaseTarget: 'obtainInformationFrom.nameOfReleaseTarget',
      relationship: 'obtainInformationFrom.relationship',
      address1: 'obtainInformationFrom.address1',
      address2: 'obtainInformationFrom.address2',
      city: 'obtainInformationFrom.city',
      state: 'obtainInformationFrom.state',
      zip: 'obtainInformationFrom.zip',
      phone: 'obtainInformationFrom.phone',
      fax: 'obtainInformationFrom.fax',
    };

    return (
      <Box className={classes.innerBlock}>
        <Box className={classes.block}>
          {renderLabel('Name of Target', true)}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.nameOfReleaseTarget}
            name={fieldNames.nameOfReleaseTarget}
            placeholder="Enter name of target"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.nameOfReleaseTarget)}
            fullWidth
          />
          <TextError
            errorMsg={
              subFieldErrors.includes('obtainInformationFrom.nameOfReleaseTarget')
                ? 'Name of target required'
                : null
            }
          />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Relationship')}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.relationship}
            name={fieldNames.relationship}
            placeholder="Enter relationship"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.relationship)}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Address 1')}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.address1}
            name={fieldNames.address1}
            placeholder="Enter address 1"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.address1)}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Address 2')}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.address2}
            name={fieldNames.address2}
            placeholder="Enter address 2"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.address2)}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          <Box className={classes.block}>
            {renderLabel('City', true)}
            <Input
              value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.city}
              name={fieldNames.city}
              placeholder="Enter city"
              onChange={handleChange}
              size={inputSize.M}
              onBlur={setTouched(fieldNames.city)}
              fullWidth
            />
            <TextError
              errorMsg={
                subFieldErrors.includes('releaseInformationTo.city') ||
                subFieldErrors.includes('obtainInformationFrom.city')
                  ? 'City is required'
                  : null
              }
            />
          </Box>
          <Box className={classes.horizontalBlock}>
            <Box className={classes.block}>
              {renderLabel('State', true)}
              <Input
                value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.state}
                name={fieldNames.state}
                placeholder="Enter state"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched(fieldNames.state)}
                fullWidth
              />
              <TextError
                errorMsg={subFieldErrors.includes('obtainInformationFrom.state') ? 'State is required' : null}
              />
            </Box>
            <Box className={classes.block}>
              {renderLabel('Zip', true)}
              <Input
                value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.zip}
                name={fieldNames.zip}
                placeholder="Enter zip"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched(fieldNames.zip)}
                fullWidth
              />
              <TextError
                errorMsg={subFieldErrors.includes('obtainInformationFrom.zip') ? 'Zip is required' : null}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.block}>
          {renderLabel('Phone', true)}
          <Input
            variant={inputType.PHONE}
            value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.phone}
            name={fieldNames.phone}
            placeholder="Enter phone"
            onChange={e => {
              void rest.setFieldValue(fieldNames.phone, e);
            }}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.phone)}
            fullWidth
          />
          <TextError
            errorMsg={
              subFieldErrors.includes('obtainInformationFrom.phone') ? 'Phone number is required' : null
            }
          />
        </Box>
        <Box className={classes.block}>
          {renderLabel('Fax')}
          <Input
            value={values[RELEASE_INFORMATION_TYPE.OBTAIN_INFORMATION_FORM]?.fax}
            name={fieldNames.fax}
            placeholder="Enter fax"
            onChange={handleChange}
            size={inputSize.M}
            onBlur={setTouched(fieldNames.fax)}
            fullWidth
          />
        </Box>
      </Box>
    );
  };
  const renderAdditionalFields = () => {
    const methodOfContactOptions = [
      {
        label: 'Phone Call',
        value: 'Phone Call',
      },
      {
        label: 'Email',
        value: 'Email',
      },
    ];
    const selectedTestsOptions = [
      {
        label: 'CBC with Differential',
        value: 'CBC with Differential',
      },
      {
        label: 'Complete Metabolic Panel',
        value: 'Complete Metabolic Panel',
      },
      {
        label: 'Hepatic Panel',
        value: 'Hepatic Panel',
      },
      {
        label: 'Hepatitis Panel (includes Hep A, B and C)',
        value: 'Hepatitis Panel (includes Hep A, B and C)',
      },
      {
        label: 'Fibro Test/FibroSure/Liver Fibrosis Screening',
        value: 'Fibro Test/FibroSure/Liver Fibrosis Screening',
      },
      {
        label: 'Hepatitis C Screening',
        value: 'Hepatitis C Screening',
      },
      {
        label: 'Hepatitis B Screening',
        value: 'Hepatitis B Screening',
      },
      {
        label: 'HIV Panel',
        value: 'HIV Panel',
      },
      {
        label: 'Lithium Levels',
        value: 'Lithium Levels',
      },
      {
        label: 'Depakote Levels',
        value: 'Depakote Levels',
      },
      {
        label: 'Tegretol Levels',
        value: 'Tegretol Levels',
      },
      {
        label: 'TSH',
        value: 'TSH',
      },
      {
        label: 'Urine Drug Screen (Labcorp)',
        value: 'Urine Drug Screen (Labcorp)',
      },
      {
        label: 'Urine Drug Screen (Quest)',
        value: 'Urine Drug Screen (Quest)',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ];
    const frequencyOfStandingOrderOptions = [
      {
        label: 'Weekly',
        value: 'Weekly',
      },
      {
        label: 'Monthly',
        value: 'Monthly',
      },
    ];
    const typeOfInformationForReleaseOptions = [
      {
        label: 'Psychiatric',
        value: 'Psychiatric',
      },
      {
        label: 'Substance Use',
        value: 'Substance Use',
      },
      {
        label: 'STDs',
        value: 'STDs',
      },
      {
        label: 'HIV/AIDS',
        value: 'HIV/AIDS',
      },
    ];
    const typeOfHelpNeededOptions = [
      {
        label: 'Pharmacist requesting additional information',
        value: 'Pharmacist requesting additional information',
      },
      {
        label: 'Pharmacy declining to fill prescription',
        value: 'Pharmacy declining to fill prescription',
      },
      {
        label: 'Pharmacist requesting alternative medication',
        value: 'Pharmacist requesting alternative medication',
      },
      {
        label: 'Medication not covered by insurance',
        value: 'Medication not covered by insurance',
      },
      {
        label: 'Pharmacy not accepting new clients',
        value: 'Pharmacy not accepting new clients',
      },
      {
        label: 'Prior authorization needed',
        value: 'Prior authorization needed',
      },
      {
        label: 'Medication not in stock',
        value: 'Medication not in stock',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ];
    const typeOfReferralNeededOptions = [
      {
        label: 'Detox / Inpatient Substance Use Care',
        value: 'Detox / Inpatient Substance Use Care',
      },
      {
        label: 'Residential Facility',
        value: 'Residential Facility',
      },
      {
        label: 'Primary Care Physician',
        value: 'Primary Care Physician',
      },
      {
        label: 'Inpatient Psychiatric Care',
        value: 'Inpatient Psychiatric Care',
      },
      {
        label: 'Housing Assistance',
        value: 'Housing Assistance',
      },
      {
        label: 'Food Assistance',
        value: 'Food Assistance',
      },
      {
        label: 'Pain Management Provider',
        value: 'Pain Management Provider',
      },
      {
        label: 'Insurance Coverage Support',
        value: 'Insurance Coverage Support',
      },
      {
        label: 'Local Transportation Assistance',
        value: 'Local Transportation Assistance',
      },
      {
        label: 'Domestic Violence services',
        value: 'Domestic Violence services',
      },
      {
        label: 'Intensive Outpatient Program (IOP)',
        value: 'Intensive Outpatient Program (IOP)',
      },
      {
        label: 'Partial Hospitalization Program (PHP)',
        value: 'Partial Hospitalization Program (PHP)',
      },
      {
        label: 'In-person treatment for mental health',
        value: 'In-person treatment for mental health',
      },
      {
        label: 'In-person treatment for addiction',
        value: 'In-person treatment for addiction',
      },
      {
        label: 'In-person treatment for mental health and addiction',
        value: 'In-person treatment for mental health and addiction',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ];
    const typeOfReferralOptions = [
      {
        label: 'Discharge',
        value: 'Discharge',
      },
      {
        label: 'Additional Care',
        value: 'Additional Care',
      },
    ];
    switch (flag) {
      case CONTACT_NOTES_FLAGS.CONTACT:
        return (
          <Box className={classes.block}>
            {renderLabel('Method of Contact')}
            <Select
              options={methodOfContactOptions}
              value={values.methodOfContact}
              emptyText="Select a Method of Contact"
              displayEmpty
              name="methodOfContact"
              onChange={handleChange}
              variant={selectType.SECONDARY}
              fullWidth
            />
          </Box>
        );
      case CONTACT_NOTES_FLAGS.CAUTION:
        return (
          <Box className={classes.block}>
            {renderLabel('Type')}
            <Select
              options={options}
              value={values.internalType}
              emptyText="Select a type"
              displayEmpty
              name="internalType"
              onChange={handleChange}
              variant={selectType.SECONDARY}
              fullWidth
            />
          </Box>
        );
      case CONTACT_NOTES_FLAGS.PROHIBITIVE:
        return (
          <Box className={classes.block}>
            {renderLabel('Type')}
            <Select
              options={options}
              value={values.internalType}
              emptyText="Select a type"
              displayEmpty
              name="internalType"
              onChange={handleChange}
              variant={selectType.SECONDARY}
              fullWidth
            />
          </Box>
        );
      case CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION:
        return (
          <>
            <Box className={classes.block}>
              <Heading
                level={headingLevel.S}
                className={classes.label}
                style={{ display: 'flex', gap: 8 }}
                weight={fontWeight.BOLD}
              >
                <Checkbox
                  checked={values.releaseInformationToCheck}
                  name="releaseInformationToCheck"
                  onChange={e => {
                    void rest.setFieldValue('releaseInformationToCheck', e.target.checked);
                  }}
                  label="Release of Information"
                />
                Release information to
              </Heading>
            </Box>
            {values.releaseInformationToCheck && renderReleaseInformationToSubFields()}
            <Box className={classes.block}>
              <Heading
                level={headingLevel.S}
                className={classes.label}
                style={{ display: 'flex', gap: 8 }}
                weight={fontWeight.BOLD}
              >
                <Checkbox
                  checked={values.obtainInfromationFromCheck}
                  name="obtainInfromationFromCheck"
                  onChange={e => {
                    void rest.setFieldValue('obtainInfromationFromCheck', e.target.checked);
                  }}
                  label="Obtain Information From"
                />
                Obtain Information From
              </Heading>
            </Box>
            {values.obtainInfromationFromCheck && renderObtainInformationFromSubFields()}
            {renderMultiSelect(
              'typeOfInformationForRelease',
              'Type of information for release',
              typeOfInformationForReleaseOptions,
              true,
            )}
            <Box className={classes.block}>
              {renderLabel('Additional Information for Release')}
              <Input
                value={values.additionalInformationForRelease}
                name="additionalInformationForRelease"
                placeholder="Enter additional information for release"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('additionalInformationForRelease')}
                fullWidth
              />

              <TextError
                errorMsg={
                  touched.additionalInformationForRelease
                    ? errors.additionalInformationForRelease?.toString()
                    : null
                }
              />
            </Box>
            {/* reason, purposeOfDisclosure */}
            <Box className={classes.block}>
              {renderLabel('Rationale/reason for release')}
              <Input
                value={values.rationaleReasonForRelease}
                name="rationaleReasonForRelease"
                placeholder="Enter rationale reason for release"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('rationaleReasonForRelease')}
                fullWidth
              />
              <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.MEDIUM}>
                For example, Medical Records Requests, Collaboration with Providers, etc.
              </Heading>
              <TextError
                errorMsg={
                  touched.rationaleReasonForRelease ? errors.rationaleReasonForRelease?.toString() : null
                }
              />
            </Box>

            {values.rationaleReasonForRelease && (
              <Box className={classes.block}>
                {renderLabel('Purpose of disclosure/use of PHI')}
                <Input
                  value={values.purposeOfDisclosure}
                  name="purposeOfDisclosure"
                  placeholder="Enter purpose of disclosure/use of PHI"
                  onChange={handleChange}
                  size={inputSize.M}
                  onBlur={setTouched('purposeOfDisclosure')}
                  fullWidth
                />
                <TextError
                  errorMsg={touched.purposeOfDisclosure ? errors.purposeOfDisclosure?.toString() : null}
                />
              </Box>
            )}
            <Box className={classes.block}>
              {renderLabel('Signable Document Link')}
              <Input
                value={values.signableDocumentLink}
                name="signableDocumentLink"
                placeholder="Enter signable document link"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('signableDocumentLink')}
                fullWidth
              />
              <TextError
                errorMsg={touched.signableDocumentLink ? errors.signableDocumentLink?.toString() : null}
              />
            </Box>
          </>
        );
      case CONTACT_NOTES_FLAGS.LAB_REQUEST:
        return (
          <>
            {renderMultiSelect('selectedTests', 'Selected tests', selectedTestsOptions)}
            {/* <Box className={classes.block}>
              {renderLabel('Selected tests')}
              <Select
                options={options}
                value={values.selectedTests}
                emptyText="Select tests"
                displayEmpty
                name="selectedTests"
                onChange={handleChange}
                variant={selectType.SECONDARY}
                fullWidth
              />
            </Box> */}
            {values.selectedTests?.includes('Other') && (
              <Box className={classes.block}>
                {renderLabel('Test description')}
                <Input
                  value={values.selectedTestsDescription}
                  name="selectedTestsDescription"
                  placeholder="Enter description"
                  onChange={handleChange}
                  size={inputSize.M}
                  onBlur={setTouched('selectedTestsDescription')}
                  fullWidth
                />
                <TextError
                  errorMsg={
                    touched.selectedTestsDescription ? errors.selectedTestsDescription?.toString() : null
                  }
                />
              </Box>
            )}
            <Heading
              level={headingLevel.S}
              className={classes.label}
              style={{ display: 'flex', gap: 8 }}
              weight={fontWeight.BOLD}
            >
              <Checkbox
                checked={values.standingOrder?.isStandingOrder}
                name="standingOrder.isStandingOrder"
                onChange={() => {
                  void rest.setFieldValue(
                    'standingOrder.isStandingOrder',
                    !values.standingOrder?.isStandingOrder,
                  );
                }}
                label="Select a standing order"
              />
              Select a standing order
            </Heading>
            {values.standingOrder?.isStandingOrder &&
              rednerSingleSelect(
                'standingOrder.frequency',
                'Frequency of standing order',
                frequencyOfStandingOrderOptions,
                'Select a frequency of standing order',
              )}
            {rednerSingleSelect('lab', 'Lab', labOptions, 'Select a lab')}
          </>
        );
      case CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE:
      case CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION:
        return (
          <>{renderMultiSelect('typesOfHelpNeeded', 'Type of help needed', typeOfHelpNeededOptions, true)}</>
        );
      case CONTACT_NOTES_FLAGS.REFERRAL_OUT:
        return (
          <>
            {renderMultiSelect('typesOfReferral', 'Type of referral', typeOfReferralOptions, true)}
            {renderMultiSelect(
              'typesOfReferralNeeded',
              'Type of referral needed',
              typeOfReferralNeededOptions,
              true,
            )}
            {values.typesOfHelpNeeded?.includes('Other') && (
              <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.MEDIUM}>
                Please defined in description
              </Heading>
            )}
          </>
        );
      default:
        return <></>;
    }
  };
  const renderFields = () => {
    return (
      <>
        {renderTitleField()}
        {renderAdditionalFields()}
        {renderStatusField(flag)}
        {renderDescriptionAndAttachmentFields()}
        {renderSharingOptions()}
      </>
    );
  };
  return (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit} noValidate className={classes.root}>
        <Box className={classes.form}>
          <Box className={classes.content}>{renderFields()}</Box>
          <Box className={classes.footer}>
            <Button onClick={onCancel} variant={btnType.TEXT} className={classes.submit}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant={btnType.PRIMARY}
              className={classes.submit}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : note ? 'Save note' : 'Add note'}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddNoteForm;
