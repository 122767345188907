import { Box, Grid } from '@mui/material';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from 'redux/modules/profile/selectors';
import { IDemographicDetails } from 'redux/modules/profile/types';

import BoxFrame from 'components/v2/BoxFrame';
import Header from 'components/v2/Header';
import Typography from 'components/v2/Typography';
import { HeadingFont, HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import { typography } from 'components/v2/Typography/index.constant';
import { FilterSessions } from 'pages/provider/sessions/session-filters/SessionFilters.constants';
import { getAuth } from 'redux/modules/auth/selectors';
import history from 'utils/history';
import { useState } from 'react';
import CreateConnectionModal from 'layouts/base/CreateConnectionModal';
import { showSnackbar } from 'redux/modules/snackbar';
import { createMemberConnection } from 'services/conversation/conversation.service';
import { useStyles } from './index.styles';

type Props = {
  isAction?: boolean;
  onChangeView?: (view: string) => void;
  isSession?: boolean;
  memberId?: string;
  isInPersonSessionCall?: boolean;
};

const IdentificationCard = ({
  isAction = true,
  onChangeView,
  isSession = true,
  memberId,
  isInPersonSessionCall = false,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { demographicDetails = {} as IDemographicDetails } = useSelector(getProfile);
  const { idCardFront, idCardBack } = demographicDetails;
  const { isAdmin, meta } = useSelector(getAuth);
  const [isCreateConnection, setIsCreateConnection] = useState(false);

  // const title = useMemo(
  //   () =>
  //     !idCardFront && !idCardBack
  //       ? "Driver's license or photo ID not uploaded yet"
  //       : "Driver's license or photo ID card",
  //   [idCardFront, idCardBack],
  // );

  const addConnectionWithMember = async () => {
    try {
      const bodyRequest = {
        suggestedFirstUser: meta.userId,
        suggestedSecondUser: memberId,
      };
      const response = await createMemberConnection(bodyRequest);
      setIsCreateConnection(false);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Connection with member created successfully.',
          }),
        );
        history.push(`/admin/chats?userId=${memberId}&type=members`);
      }
    } catch (error) {
      setIsCreateConnection(false);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error?.data?.errors[0].endUserMessage,
        }),
      );
    }
  };

  const goToChat = id => {
    if (isAdmin) {
      if (meta?.authority === 'BILLING_SUPPORT' || meta?.authority === 'ADMIN') {
        if (demographicDetails?.isConnected === false) {
          setIsCreateConnection(true);
        } else {
          history.push(`/admin/chats?userId=${id}&type=members`);
        }
      } else {
        history.push(`/admin/chats?userId=${id}&type=members`);
      }
    } else {
      history.push(`/provider/chats?userId=${id}&type=care_team`);
    }
  };

  return (
    <BoxFrame>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Box py={2.5} px={3}>
            <Header
              label="Driver's license or photo ID card"
              fontFamily={HeadingFont.PRIMARY}
              fontVariant={HeadingLevel.SUB}
              fontWeight={HeadingWeight.SEMI_BOLD}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} display="flex" justifyContent="flex-end" alignItems="center">
          {isAction && !isInPersonSessionCall && (
            <Box
              sx={{
                '& button': { gap: 1 },
              }}
              pr={2}
            >
              <IconButton
                onClick={() => (isSession ? onChangeView(FilterSessions[2].type) : goToChat(memberId))}
              >
                <Typography {...typography.body.s.bold}>Go to chat</Typography>
              </IconButton>
            </Box>
          )}
        </Grid>
        <Grid item container sx={{ borderTop: `1px solid ${colors.neutral100}` }}>
          {!idCardFront && !idCardBack ? (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
                gap: '16px',
                padding: 40,
              }}
            >
              <Icons glyph="lottie-driver-license" className={classes.lottieSize} />
              <Typography {...typography.body.m.medium} color={colors.neutral900}>
                Driver&apos;s license or photo ID card not uploaded yet
              </Typography>
            </Box>
          ) : (
            <Box display="flex" gap={3} p={3}>
              {idCardFront ? (
                <img src={idCardFront} alt="id card front" className={classes.idCard} />
              ) : (
                <Box
                  className={classes.idCard}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid ${colors.neutral100}`}
                  textAlign="center"
                >
                  Front of ID - Not Uploaded
                </Box>
              )}
              {idCardBack ? (
                <img src={idCardBack} alt="id card back" className={classes.idCard} />
              ) : (
                <Box
                  className={classes.idCard}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid ${colors.neutral100}`}
                  textAlign="center"
                >
                  Back of ID - Not Uploaded
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      {isCreateConnection && isAdmin && (
        <CreateConnectionModal
          icon="conversations-filled"
          open={isCreateConnection}
          title="Add Connection"
          content="Do you want to create connection with this member?"
          lbtnLabel="Cancel"
          rbtnLabel="Confirm"
          onClose={() => setIsCreateConnection(false)}
          onCancel={() => setIsCreateConnection(false)}
          onSubmit={addConnectionWithMember}
          isLoading={false}
          isDelete={false}
        />
      )}
    </BoxFrame>
  );
};

export default IdentificationCard;
