import { call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';

import { IAction } from 'redux/store/types';
import * as notificationServices from 'services/notification/notification.service';
import { getNotifications } from './selectors';
import {
  FETCH_ADMIN_NOTIFICATIONS,
  FETCH_ADMIN_NOTIFICATIONS_FAILED,
  FETCH_ADMIN_NOTIFICATIONS_SUCCESSFUL,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_FAILED,
  FETCH_NOTIFICATIONS_SUCCESSFUL,
  REMOVE_ADMIN_NOTIFICATION,
  REMOVE_ADMIN_NOTIFICATION_FAILED,
  REMOVE_ADMIN_NOTIFICATION_SUCCESSFUL,
} from './actions';
import { SOCKET_IN_APP_NOTIFICATION } from '../socket/actions';
import { LOGIN_SUCCESSFUL } from '../auth/actions';

function* fetchNotificationHandler(action: IAction) {
  const { pageNumber, pageSize, isLoadMore } = action.payload || {
    pageNumber: 1,
    pageSize: 10,
    isLoadMore: false,
  };

  try {
    const { notifications } = yield select(getNotifications);
    const { data } = yield call(notificationServices.getNotifications, {
      pageNumber: pageNumber - 1,
      pageSize,
    });

    const list = isLoadMore
      ? [...notifications, ...data.realtimeActivityFeedList]
      : data.realtimeActivityFeedList;

    yield put({
      type: FETCH_NOTIFICATIONS_SUCCESSFUL,
      payload: {
        currentPage: pageNumber,
        totalPages: data.totalPages,
        totalRecords: data.totalRecords,
        notifications: list.sort((a, b) => dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix()),
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_NOTIFICATIONS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchAdminNotificationHandler() {
  const { isAdmin } = yield select(state => state.auth.meta);
  if (isAdmin) {
    try {
      const { data } = yield call(notificationServices.getAdminNotifications);
      yield put({
        type: FETCH_ADMIN_NOTIFICATIONS_SUCCESSFUL,
        payload: { notifications: data.notifications },
      });
    } catch (e) {
      yield put({
        type: FETCH_ADMIN_NOTIFICATIONS_FAILED,
        payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
      });
    }
  }
}

function* removeAdminNotificationHandler(action: IAction) {
  try {
    const { notificationId } = action.payload;
    yield call(notificationServices.removeAdminNotification, notificationId);
    yield put({
      type: REMOVE_ADMIN_NOTIFICATION_SUCCESSFUL,
      payload: { notificationId },
    });
  } catch (e) {
    yield put({
      type: REMOVE_ADMIN_NOTIFICATION_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

export default function* notificationSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_NOTIFICATIONS, fetchNotificationHandler);
  yield takeLatest([FETCH_ADMIN_NOTIFICATIONS, LOGIN_SUCCESSFUL], fetchAdminNotificationHandler);
  yield takeLatest(REMOVE_ADMIN_NOTIFICATION, removeAdminNotificationHandler);
  yield takeLatest(SOCKET_IN_APP_NOTIFICATION, fetchAdminNotificationHandler);
}
