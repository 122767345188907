import { createAction } from 'redux-actions';

export const SOCKET_NAVIGATE_TO_SCREEN = 'socket/navigateToScreen';
export const SOCKET_NAVIGATION_COMPLETE = 'socket/navigationComplete';
export const SOCKET_IN_APP_NOTIFICATION = 'socket/inAppNotification';

export const socketActionCreators = {
  navigateToScreen: createAction(SOCKET_NAVIGATE_TO_SCREEN),
  navigationComplete: createAction(SOCKET_NAVIGATION_COMPLETE),
  inAppNotification: createAction(SOCKET_IN_APP_NOTIFICATION),
};
