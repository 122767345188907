import { IAction } from 'redux/store/types';
import { USER_LOGOUT } from '../auth/actions';
import {
  FETCH_ADMIN_NOTIFICATIONS,
  FETCH_ADMIN_NOTIFICATIONS_FAILED,
  FETCH_ADMIN_NOTIFICATIONS_SUCCESSFUL,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_FAILED,
  FETCH_NOTIFICATIONS_SUCCESSFUL,
  REMOVE_ADMIN_NOTIFICATION_SUCCESSFUL,
} from './actions';
import { NotificationState } from './types';

export const DEFAULT: NotificationState = {
  isLoading: false,
  errorMsg: '',
  currentPage: 1,
  totalPages: 0,
  totalRecords: 0,
  notifications: [],
  adminNotifications: [],
};

export default function notificationReducer(state = DEFAULT, action: IAction): NotificationState {
  const { type, payload } = action;

  switch (type) {
    case FETCH_NOTIFICATIONS: {
      return {
        ...state,
        isLoading: true,
        errorMsg: null,
      };
    }
    case FETCH_ADMIN_NOTIFICATIONS: {
      return {
        ...state,
        isLoading: true,
        adminNotifications: [],
      };
    }
    case FETCH_ADMIN_NOTIFICATIONS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        adminNotifications: payload.notifications,
      };
    }
    case FETCH_ADMIN_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        adminNotifications: [],
      };
    }
    case REMOVE_ADMIN_NOTIFICATION_SUCCESSFUL: {
      const updatedAdminNotifications = state.adminNotifications.filter(
        notification => notification.id !== payload.notificationId,
      );
      return {
        ...state,
        adminNotifications: updatedAdminNotifications,
      };
    }
    case FETCH_NOTIFICATIONS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: null,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        totalRecords: payload.totalRecords,
        notifications: payload.notifications,
      };
    }
    case USER_LOGOUT:
    case FETCH_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message || null,
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
        notifications: [],
      };
    }
    default: {
      return state;
    }
  }
}
