import { EVALUATION_STATUS_TYPES } from 'pages/provider/sessions/evaluations-v2/Evaluations.constants';

export const FilterSessions = [
  { type: 'all', label: 'Default' },
  { type: 'chat', label: 'Chat' },
  { type: 'scratchpad', label: 'Scratchpad' },
  { type: 'priorities', label: 'Priorities' },
  { type: 'schedule', label: 'Schedule' },
];

export const FilterSessionsMock = [
  { type: 'checklist', label: 'Checklist', total: 7, amount: 3 },
  { type: 'evaluation', label: 'Evaluation', total: 3, amount: 1 },
  { type: 'documentation', label: 'Documentation', total: 3, amount: 0 },
];

export const ChecklistMock = [
  {
    title: 'Schedule next appointment',
    subtitle: 'Request an appointment for you or another care team member',
  },
  {
    title: 'Sign off on documentation',
    subtitle: 'Sign off on this note to complete this appointment',
    status: EVALUATION_STATUS_TYPES.NOT_STARTED,
  },
];
