import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { fontWeight, Heading, headingLevel, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAuth } from 'redux/modules/auth/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { updateMemberIdCard } from 'services/member/member.service';
import { showSnackbar } from 'redux/modules/snackbar';
import { profileActionCreators } from 'redux/modules/profile';

import PersonalInformation from './PersonalInformation';
import ContactInformation from './ContactInformation';
import EmergencyContact from './EmergencyContact';
import Wallet from './Wallet';
import PrivacyAndConcents from './PrivacyAndConsents';
import { useStyles } from '../../MemberDetail.styles';
import IdCard from './IdCard';
import CardTransactions from './CardTransactions';
import IdCardUploadDrawer from '../insurance-information/upload-drawer';

const GeneralInformation: FC = () => {
  const classes = useStyles({});
  const { memberId } = useParams();
  const { isAdmin } = useSelector(getAuth);
  const { isLoading, errorMsg } = useSelector(getProfile);
  const dispatch = useDispatch();
  const [showEditIdCardDrawer, setShowEditIdCardDrawer] = useState(false);

  const submitIDCard = urls => {
    if (!!urls?.front && !!urls?.back) {
      const idCardFront = urls.front || null;
      const idCardBack = urls.back || null;
      if (idCardFront && idCardBack) {
        updateMemberIdCard({ idCardBack, idCardFront, memberId })
          .then(() => {
            dispatch(
              showSnackbar({
                snackType: 'success',
                snackMessage: 'ID card updated successfully',
              }),
            );
            dispatch(profileActionCreators.fetchDemographicDetails(memberId));
            setShowEditIdCardDrawer(false);
          })
          .catch(() => {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Error occured while uploading ID card images',
              }),
            );
          });
      }
    }
  };

  return (
    <>
      <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
        Patient Information
      </Heading>
      {(errorMsg || isLoading) && (
        <Box display="flex" alignItems="center" paddingY={1} flex={1} width="100%" justifyContent="center">
          {errorMsg && <TextError errorMsg={errorMsg} />}
          {isLoading && (
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          )}
        </Box>
      )}
      <div className={classes.cardsWrapper}>
        <PersonalInformation memberId={memberId} />
        <ContactInformation memberId={memberId} />
        <IdCard btnLabel="Edit" onClickBtn={() => setShowEditIdCardDrawer(true)} />
        <EmergencyContact memberId={memberId} />
        {/* <PreferredPharmacies /> */}
        {isAdmin && <Wallet memberId={memberId} />}
        {isAdmin && <CardTransactions memberId={memberId} />}
        <PrivacyAndConcents />
      </div>
      {showEditIdCardDrawer && (
        <IdCardUploadDrawer
          openDrawer={showEditIdCardDrawer}
          onClose={() => setShowEditIdCardDrawer(false)}
          onSubmit={submitIDCard}
          isInsurance={false}
        />
      )}
    </>
  );
};

export default GeneralInformation;
