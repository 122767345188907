import { colors } from '@confidant-health/lib/colors';
import { btnSize } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box } from '@mui/material';
import Heading from 'components/v2/Heading';
import { HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import { useStyles } from '../index.styles';

type Props = {
  title: string;
  subTitle?: string;
  onClose?: () => void;
};

const DrawerHead = ({ title, subTitle, onClose }: Props) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={subTitle ? 'baseline' : 'center'}
      sx={{
        px: 3,
        py: subTitle ? 2 : 3,
        borderBottom: `1px solid ${colors.neutral100}`,
      }}
    >
      <Box display="flex" sx={{ flexDirection: subTitle ? 'column' : 'row', gap: subTitle ? 0.5 : 0 }}>
        <Heading variant={HeadingLevel.H5} fontWeight={HeadingWeight.BOLD} className={classes.drawerHeading}>
          {title}
        </Heading>
        <Typography {...typography.body.m.medium} color={colors.neutral600}>
          {subTitle}
        </Typography>
      </Box>
      <Box sx={{ '& button': { minWidth: 24 } }}>
        <IconButton icon="close" size={btnSize.SMALL} onClick={onClose} />
      </Box>
    </Box>
  );
};

export default DrawerHead;
