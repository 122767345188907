import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Stack } from '@mui/material';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { ConfirmationModal } from '@confidant-health/lib/ui/templates/confirmation-modal';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { BaseLayout } from 'layouts/base';

// actions
import { priorityActionCreators } from 'redux/modules/priority';
import { getPriority } from 'redux/modules/priority/selectors';

import AddEditPriorityGrouping from './add-edit-grouping';
import { IDomainType } from './add-edit-grouping/AddEditPriorityGrouping.types';

import { tableColumns, filterOptionsMock, multiSelectFilterOptionsMock } from './PriorityGroupings.constants';
import { useStyles } from './PriorityGroupings.styles';

const PriorityGroupings: FC = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isLoading,
    groupsPayload: { totalRecords, domainGroups },
    typesPayload: { domainTypes },
  } = useSelector(getPriority);

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  const closeConfirmation = () => {
    setSelectedRecord(null);
    setShowConfirmModal(false);
  };

  const handleFetchGroupsParamsFormat = ({ search, pagination, sorter }: tableParamsType) => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const sortBy = sorter.column || '';
    const typeIds = [];
    if (multiselectFilters?.['Domain Type']) {
      multiselectFilters['Domain Type'].forEach(filter => {
        switch (filter) {
          case 'Diagnoses':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Diagnoses')?.Id);
            break;
          case 'Symptoms':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Symptoms')?.Id);
            break;
          case 'Social Determinants':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Social Determinants')?.Id);
            break;
          case 'Substance Use':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Substance Use')?.Id);
            break;
          case 'Medications':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Medications')?.Id);
            break;
          case 'Side Effects':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Side Effects')?.Id);
            break;
          case 'Life Events':
            typeIds.push(domainTypes.find(domainType => domainType.name === 'Life Events')?.Id);
            break;
          default:
            break;
        }
      });
    }

    const typeIdsStringFormat = typeIds.join(',');
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction,
      typeIds: typeIdsStringFormat,
      sortBy,
    };
    return params;
  };

  const fetchDomainGroups = () => {
    dispatch(priorityActionCreators.fetchPriorityDomainTypes());
    dispatch(priorityActionCreators.fetchPriorityDomainElements());
    dispatch(priorityActionCreators.fetchPriorityDomainGroups(handleFetchGroupsParamsFormat(tableParams)));
  };

  useEffect(() => {
    fetchDomainGroups();
  }, [tableParams]);

  const onDeleteGroup = () => {
    dispatch(
      priorityActionCreators.deletePriorityDomainGroup({
        data: selectedRecord?.Id,
        callback: (isSucceed: boolean) => {
          if (isSucceed) {
            fetchDomainGroups();
          }
          closeConfirmation();
        },
      }),
    );
  };

  const renderElement = (elements: number) => {
    if (elements === 0) {
      return (
        <Text className={classes.name} weight={fontWeight.MEDIUM}>
          0 Elements
        </Text>
      );
    }
    return (
      <Text className={classes.name} weight={fontWeight.MEDIUM}>
        {elements
          .toString()
          .concat(' Element')
          .concat(elements > 1 ? 's' : '')}
      </Text>
    );
  };

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'relatedElements') {
      return {
        ...column,
        renderCell: renderElement,
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: data => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              {
                label: 'Edit',
                onClick: () => {
                  setSelectedRecord(data);
                  setShowAddNew(true);
                },
              },
              {
                label: 'Delete',
                onClick: () => {
                  setSelectedRecord(data);
                  setShowConfirmModal(true);
                },
              },
            ]}
          />
        ),
      };
    }
    return column;
  });

  return (
    <>
      {showAddNew && (
        <AddEditPriorityGrouping
          isOpen={showAddNew}
          onClose={() => {
            setSelectedRecord(null);
            toggleAddNew();
          }}
          fetchDomainGroups={fetchDomainGroups}
          editData={selectedRecord}
          domainTypes={domainTypes as IDomainType[]}
        />
      )}
      <ConfirmationModal
        open={showConfirmModal}
        onClose={closeConfirmation}
        onSave={onDeleteGroup}
        title="Delete Domain Group"
        message="Are you sure?"
      />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Data domain groupings
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {totalRecords} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={toggleAddNew}
            >
              Add grouping
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search name',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                options: filterOptionsMock,
                allOptionLabel: 'All Groups',
                multiSelectOptions: multiSelectFilterOptionsMock,
              },
            }}
            gridProps={{
              columns: renderColumns,
              data: domainGroups,
              isLoading,
            }}
            paginationProps={{
              currentRows: domainGroups?.length,
              totalCount: totalRecords,
              showRowsPerPage: true,
            }}
            value={tableParams}
            onChange={setTableParams}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { PriorityGroupings };
