export const defaultScoringValues = {
  minScore: '',
  maxScore: '',
  description: '',
  positivityDirection: '',
  scoreRule: 'AGGREGATE',
  scoreElementKey: '',
  outcomeElementKey: '',
  veryNegativeRange: {
    minScore: '',
    maxScore: '',
    colorCode: '#ff0000',
  },
  negativeRange: {
    minScore: '',
    maxScore: '',
    colorCode: '#ffff00',
  },
  neutralRange: {
    minScore: '',
    maxScore: '',
    colorCode: '#0000ff',
  },
  positiveRange: {
    minScore: '',
    maxScore: '',
    colorCode: '#00ff00',
  },
};
