/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import cx from 'clsx';
import { Collapse, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { iconBtnStyle, iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { useDomainType } from 'hooks/useDomainType';
import { DomainTypesEnum } from 'constants/CommonConstants';

import MedicationForm from '../medication-form';
// types
import { IReportMedicationsFormCollapsableProps } from './ReportFormCollapse.types';
import { IMedication, ReportItemProgress } from '../AddNewReport.types';
import { MedicationEditSchema } from '../index.schema';
// styles
import { useStyles } from './ReportFormCollapse.styles';
import { showSnackbar } from '../../../../../redux/modules/snackbar';

const defaultValues = {
  status: '',
  notes: '',
  dosage: '',
  frequency: '',
  symptoms: [],
  provider: 'Confidant Provider',
  confidantProvider: '',
  providerName: '',
  additionalNotes: '',
};

const ReportFormCollapseMedications: FC<IReportMedicationsFormCollapsableProps> = ({
  open = false,
  medication,
  onChange,
  lookupData,
  onRemoveClick,
  onOpenCollapse,
  memberId,
  options,
}) => {
  const dispatch = useDispatch();
  const [, symptoms] = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
    patientId: memberId,
  });

  const symtomsList = symptoms?.relatedElements?.map(item => item.name) || [];
  const [collapsed, setCollapsed] = useState(open || false);
  const [state, setState] = useState(defaultValues);
  const classes = useStyles();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    onOpenCollapse && onOpenCollapse(!collapsed);
  };

  const form = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: MedicationEditSchema,
    onSubmit: (payload: any) => {
      console.log({ payload });
    },
  });

  const buttonStatus = () => {
    return (
      state.dosage.length !== 0 &&
      state.frequency.length !== 0 &&
      state.confidantProvider.length !== 0 &&
      state.notes.length !== 0 &&
      state.status.length !== 0 &&
      state.provider.length !== 0
    );
  };

  const showError = () => {
    const errorMessage =
      state.status.length === 0
        ? 'Importance level'
        : state.dosage.length === 0
        ? 'Dosage'
        : state.frequency.length === 0
        ? 'Frequency'
        : state.provider.length === 0
        ? 'Prescribing provider'
        : state.confidantProvider.length === 0
        ? 'Confidant provider'
        : 'Report notes';

    dispatch(
      showSnackbar({
        snackType: 'error',
        snackMessage: `${errorMessage} is required`,
      }),
    );
  };

  const handleSubmit = () => {
    const buttonEnabled = buttonStatus();

    if (buttonEnabled) {
      const { ...remaining } = form.values;
      onChange({
        ...remaining,
        tutorial: {
          dosage: state.dosage,
          frequency: state.frequency,
          confidantProviderId: state.confidantProvider,
          confidantProviderName: state.providerName,
          symptomsBeingTreated: state.symptoms,
          prescribingProvider: state.provider,
          providerName: state.providerName,
        },
        ...state,
      } as IMedication);
    } else {
      showError();
    }
  };
  useEffect(() => {
    if (medication) {
      const { /* tutorial, */ ...remaining } = medication;
      const newObj = {
        ...remaining,
        // ...tutorial,
      };
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(newObj).forEach(async key => {
        await form.setFieldValue(key, newObj[key]);
      });
      if (medication.progress) {
        setCollapsed(medication.progress === ReportItemProgress.InProgress);
      }
    }
  }, [medication]);

  useEffect(() => {
    setCollapsed(open || false);
  }, [open]);

  const onChangeHandler = (key: string, value: any) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  return (
    <Box className={classes.root}>
      <Box className={cx(classes.header, { [classes.headerBorder]: collapsed })} onClick={toggleCollapsed}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Text className={classes.headTitle}>{medication.item.label}</Text>
          <Badge
            variant={badgeType.OUTLINED}
            className={classes.badge}
            style={
              medication.progress === ReportItemProgress.Completed
                ? badgeStyle.SUCCESS
                : medication.progress === ReportItemProgress.Incomplete
                ? badgeStyle.MISREPORTED
                : badgeStyle.CRITICAL
            }
          >
            {medication.progress}
          </Badge>
        </Box>
      </Box>
      <Collapse in={collapsed}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <MedicationForm
            form={form}
            medication={medication}
            lookupData={lookupData}
            symptomsList={symtomsList}
            options={options}
            onChangeHandler={onChangeHandler}
            state={state}
          />
          <Box className={classes.footer}>
            <IconButton
              icon="delete-outlined-2"
              className={classes.removeBtn}
              variant={iconBtnType.TEXT}
              style={iconBtnStyle.SECONDARY}
              onClick={onRemoveClick}
            >
              Remove this medication
            </IconButton>
            <Button onClick={handleSubmit}>Done</Button>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

export default ReportFormCollapseMedications;
