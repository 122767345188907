import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { getDomainElements } from 'services/member/member.service';

// components
import SelectLevel from '../../../select-level';
import { SideEffectBottomCard } from '../side-effect-card/SideEffectBottomCard';
// schema
import { SymptomEditSchema } from '../../../Symptoms/symptom-editing/SymptomEditing.schema';
// types
import { EditSideEffectPayload, ISideEffectEditProps } from './SideEffectEditing.types';
// styles
import { useStyles } from './SideEffectEditing.styles';

const defaultValues = {
  status: '',
  notes: '',
  isInterference: false,
};

const SideEffectEdit: React.FC<ISideEffectEditProps> = ({
  title,
  isOpen,
  onClose,
  item,
  isSaving,
  error,
  isNoDrawer,
  onSubmit,
}) => {
  // console.log({ item });
  const [importanceLevels, setImportanceLevels] = useState([]);
  const classes = useStyles();
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: SymptomEditSchema,
    onSubmit: (payload: EditSideEffectPayload) => {
      onSubmit(payload);
    },
  });

  useEffect(() => {
    if (item) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(item).forEach(async key => {
        if (key === 'priority') {
          await setFieldValue('status', item[key].name);
        } else {
          await setFieldValue(key, item[key]);
        }
      });
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      if (item?.domainElementId) {
        const tempImportanceLevels = [];
        getDomainElements({ domainTypeId: item?.domainElementId })
          .then(res => {
            if (res.status === 200) {
              Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                  tempImportanceLevels.push({ value: key, label: key });
                }
              });
              setImportanceLevels(tempImportanceLevels);
            }
          })
          .catch(err => {
            console.log({ err });
          });
      }
      //  else {
      // const selectedDiagnosis = options?.filter(option => option?.groupId === medication?.groupId);
      // const selectedRelatedElement = selectedDiagnosis[0].relatedElements?.filter(
      //   element => element.Id === medication.item.value,
      // );
      // // console.log('selected related element', selectedRelatedElement);
      // const tempImportanceLevels = [];
      // // const tempIcd10List = [];
      // Object.keys(selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
      //   if (selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
      //     tempImportanceLevels.push({ value: key, label: key });
      //   }
      // });
      // // if (selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes) {
      // //   selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes.forEach(val => {
      // //     tempIcd10List.push({ value: val, label: val });
      // //   });
      // // setIcd10(selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes);
      // // }
      // setImportanceLevels(tempImportanceLevels);
      // }
    }
    // setIcd10(tempIcd10List);
  }, [item]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderContent = () => (
    <Box className={classes.wrapper}>
      <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            {title} - {item.name}
          </Text>
          <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
            {item.description}
          </Text>
        </Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
      </Box>
      <Box className={classes.headWrap}>
        <SideEffectBottomCard item={item} showBadge isEditing />
      </Box>
      <form className={clsx(classes.form, { [classes.formNoDrawer]: isNoDrawer })} onSubmit={handleSubmit}>
        <Box className={classes.formContent}>
          {error && (
            <Box display="flex" justifyContent="center">
              <TextError errorMsg={error} />
            </Box>
          )}
          <Box className={classes.section}>
            {renderLabel('Importance level')}
            <SelectLevel
              value={values.status}
              isLifeEvent
              options={importanceLevels}
              onChange={handleChange}
            />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Report notes')}
            <Textarea
              value={values.notes}
              name="notes"
              onChange={handleChange}
              onBlur={setTouched('notes')}
              minRows={5}
              placeholder="Enter your notes"
            />
            <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Update'}
          </Button>
        </Box>
      </form>
    </Box>
  );

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {item && renderContent()}
    </Drawer>
  );
};

export { SideEffectEdit };
