import * as yup from 'yup';

const PlanItemSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),
  navigatesTo: yup.string().required('Navigates To is required'),
  referenceId: yup.string().required('Reference is required'),
  progressState: yup.string().required('Progress State is required'),
  completedState: yup.string().required('Completed State is required'),
  removeReference: yup.string().required('Remove from Plan is required'),
  removeCompleteXTimes: yup.number(),
  planToken: yup.number().required('Plan token is required'),
});

export { PlanItemSchema };
