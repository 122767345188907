import dayjs from 'dayjs';

export const listMock = [
  {
    id: '1',
    member: {
      name: 'john_doe',
      fullname: 'John Doe',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'Excellent',
    numberic: 100,
    createdDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '2',
    member: {
      name: 'Eliah',
      fullname: 'Eliah Johnson',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'Excellent',
    numberic: 100,
    createdDate: dayjs().subtract(2, 'd').toISOString(),
  },
  {
    id: '3',
    member: {
      name: 'Alisa',
      fullname: 'Alisa Hester',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'Fine',
    numberic: 100,
    createdDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '4',
    member: {
      name: 'Lana',
      fullname: 'Lana Stevens',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'Fine',
    numberic: 100,
    createdDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '5',
    member: {
      name: 'Natali',
      fullname: 'Natali Craig',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'Terrible',
    numberic: 100,
    createdDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '6',
    member: {
      name: 'Zahir',
      fullname: 'Zahir Mays',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'Terrible',
    numberic: 100,
    createdDate: dayjs().subtract(1, 'd').toISOString(),
  },
  {
    id: '7',
    member: {
      name: 'Lucky Loki',
      fullname: 'Loki Bright',
      thumbnail: 'https://i.pravatar.cc/300',
    },
    status: 'Terrible',
    numberic: 100,
    createdDate: dayjs().subtract(1, 'd').toISOString(),
  },
];
