import * as yup from 'yup';

export const RegisteredEntitySchema = yup.object({
  name: yup.string().required('State name is required'),
  address1: yup.string().required('State name is required'),
  address2: yup.string(),
  city: yup.string().required('State name is required'),
  state: yup.string().required('State name is required'),
  zip: yup.string().required('State name is required'),
  employerId: yup.string().required('State name is required'),
  taxId: yup.string(),
  phone: yup.string().required('State name is required'),
  fax: yup.string(),
  isActive: yup.boolean(),
});
