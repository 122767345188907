import { makeStyles } from '@mui/styles';
// colors
import { theme } from '@confidant-health/lib/theme';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  wrapper: {
    gap: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.neutral50,
    padding: 16,
    borderRadius: 8,
  },
  error: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.error.main,
  },
  time: {
    backgroundColor: colors.white,
  },
  removeBtn: {
    minWidth: 48,
    height: 48,
    '& svg': {
      width: 20,
      '& path': {
        fill: colors.destructive500,
      },
    },
  },
  timeSelectionMenu: {
    width: '100%',
    height: '30',
  },
});
