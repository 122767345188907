import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as AuditService from 'services/audit/audit.service';
import { FETCH_USER_ACTIVITY, FETCH_USER_ACTIVITY_SUCCESSFUL, FETCH_USER_ACTIVITY_FAILED } from './actions';

function* activityFetcher(action: IAction) {
  try {
    const { userId, pageNumber = 0, pageSize = 10 } = action.payload;
    const { data } = yield call(AuditService.getUserActivity, { userId }, { pageNumber, pageSize });

    yield put({
      type: FETCH_USER_ACTIVITY_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_USER_ACTIVITY_FAILED,
      payload: { message },
    });
  }
}

export default function* auditSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_USER_ACTIVITY, activityFetcher);
}
