import { useState } from 'react';
import { colors } from '@confidant-health/lib/colors';
import { Button, btnSize, btnType } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Drawer } from '@confidant-health/lib/ui/organisms/drawer';
import { Box } from '@mui/material';
import Heading from 'components/v2/Heading';
import { HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import { PrioritiesCard } from 'pages/provider/sessions/priority-list/PriorityList.constants';

import FactorItem, { FactorType } from './FactorItem';
import { useStyles } from '../index.styles';

export enum DrawerType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  variant?: DrawerType;
  selectedType: string | null;
  onChangeSelect: (t: string) => void;
  increasedWidth?: boolean;
};

const AddFactorDrawer = ({
  open,
  onClose,
  title,
  variant = DrawerType.PRIMARY,
  selectedType = null,
  onChangeSelect,
  increasedWidth = false,
}: Props) => {
  const classes = useStyles({});
  const [selected, setSelected] = useState(selectedType);

  return (
    <Drawer open={open} onClose={onClose} title={title} className={classes.factorDrawer}>
      <Box
        p={3}
        pr={0}
        display="flex"
        justifyContent="space-between"
        sx={{ borderBottom: `1px solid ${colors.neutral100}` }}
      >
        <Heading variant={HeadingLevel.H5} fontWeight={HeadingWeight.BOLD}>
          {title}
        </Heading>
        <IconButton icon="close" size={btnSize.SMALL} variant={btnType.TEXT} onClick={onClose} />
      </Box>
      <Box sx={{ display: 'flex', flex: 1, background: colors.neutral50, p: 5 }}>
        {variant === DrawerType.PRIMARY ? (
          <Box display="flex" flexDirection="column" gap={2}>
            <FactorItem
              title="Symptom"
              icon="intelligent"
              description="A manifestation of disease apparent to the patient himself."
              type={FactorType.SYMPTOM}
              onClick={() => setSelected(PrioritiesCard.Symptoms)}
              selected={selected === FactorType.SYMPTOM || selected === PrioritiesCard.Symptoms}
            />
            <FactorItem
              title="Substance Use"
              icon="pharmacy"
              description="Substances, such as alcohol, tobacco, drugs, inhalants, and others, consumed or absorbed into the body."
              type={FactorType.SUBSTANCE_USE}
              onClick={() => setSelected(PrioritiesCard.SubstanceUse)}
              selected={selected === FactorType.SUBSTANCE_USE || selected === PrioritiesCard.SubstanceUse}
            />
            <FactorItem
              title="Side Effect"
              icon="info-outlined"
              description="An unwanted symptom caused by medical treatment"
              type={FactorType.SIDE_EFFECT}
              onClick={() => setSelected(PrioritiesCard.SideEffects)}
              selected={selected === FactorType.SIDE_EFFECT || selected === PrioritiesCard.SideEffects}
            />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={2}>
            <FactorItem
              title="Social determinant"
              icon="determinant"
              description="The conditions in environments that affect health, functioning, and quality of life."
              type={FactorType.SOCIAL_DETERMINANT}
              onClick={() => setSelected(PrioritiesCard.SocialDeterminants)}
              selected={
                selected === FactorType.SOCIAL_DETERMINANT || selected === PrioritiesCard.SocialDeterminants
              }
            />
            <FactorItem
              title="Life event"
              icon="flag-filled"
              description="Social experience or transformative change impacting individuals psychologically."
              type={FactorType.LIFE_EVENT}
              onClick={() => setSelected(PrioritiesCard.LifeEvents)}
              selected={selected === FactorType.LIFE_EVENT || selected === PrioritiesCard.LifeEvents}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          borderTop: `1px solid ${colors.neutral100}`,
        }}
      >
        <IconButton size={btnSize.SMALL} variant={btnType.TEXT} onClick={onClose}>
          Cancel
        </IconButton>
        <Button
          size={btnSize.SMALL}
          variant={btnType.PRIMARY}
          disabled={!selected}
          onClick={() => {
            onChangeSelect(selected);
          }}
        >
          Continue
        </Button>
      </Box>
    </Drawer>
  );
};

export default AddFactorDrawer;
