import { createAction } from 'redux-actions';

export const FETCH_CONTENT_BLOCK_HISTORY = 'reporting/contentBlock/fetch';
export const FETCH_CONTENT_BLOCK_HISTORY_FAILED = 'reporting/contentBlock/fetchFailed';
export const FETCH_CONTENT_BLOCK_HISTORY_SUCCESSFUL = 'reporting/contentBlock/fetchSuccessful';

export const FETCH_REPORTS_LIST = 'reporting/reports/fetch';
export const FETCH_REPORTS_LIST_FAILED = 'reporting/reports/fetchFailed';
export const FETCH_REPORTS_LIST_SUCCESSFUL = 'reporting/reports/fetchSuccessful';

export const CREATE_REPORT = 'reporting/reports/create';
export const CREATE_REPORT_FAILED = 'reporting/reports/createFailed';
export const CREATE_REPORT_SUCCESSFUL = 'reporting/reports/createSuccessful';

export const FETCH_CLAIMS = 'reporting/claims/fetch';
export const FETCH_CLAIMS_FAILED = 'reporting/claims/fetchFailed';
export const FETCH_CLAIMS_SUCCESSFUL = 'reporting/claims/fetchSuccessful';

export const FETCH_CLAIM_BY_ID = 'reporting/claims/fetchById';
export const FETCH_CLAIM_BY_ID_FAILED = 'reporting/claims/fetchByIdFailed';
export const FETCH_CLAIM_BY_ID_SUCCESSFUL = 'reporting/claims/fetchByIdSuccessful';

export const FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID = 'reporting/providerFee/fetchByAppId';
export const FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_FAILED = 'reporting/providerFee/fetchByAppIdFailed';
export const FFETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_SUCCESSFUL =
  'reporting/providerFee/fetchByAppIdSuccessful';

export const PUT_CLAIM_DETAIL = 'claim/put';
export const PUT_CLAIM_DETAIL_FAILED = 'claim/putFailed';
export const PUT_CLAIM_DETAIL_SUCCESSFUL = 'claim/putSuccessful';

export const FETCH_INVOICES = 'reporting/invoices/fetch';
export const FETCH_INVOICES_FAILED = 'reporting/invoices/fetchFailed';
export const FETCH_INVOICES_SUCCESSFUL = 'reporting/invoices/fetchSuccessful';

export const FETCH_INVOICE_BY_ID = 'reporting/invoices/fetchInvoiceById';
export const FETCH_INVOICE_BY_ID_FAILED = 'reporting/invoices/fetchInvoiceByIdFailed';
export const FETCH_INVOICE_BY_ID_SUCCESSFUL = 'reporting/invoices/fetchInvoiceByIdSuccessful';

export const UPDATE_INVOICE_BY_ID = 'reporting/invoices/updateInvoiceById';
export const UPDATE_INVOICE_BY_ID_FAILED = 'reporting/invoices/updateInvoiceByIdFailed';
export const UPDATE_INVOICE_BY_ID_SUCCESSFUL = 'reporting/invoices/updateInvoiceByIdSuccessful';

export const UPDATE_INVOICE_STATUS_BY_ID = 'reporting/invoices/updateInvoiceStatusById';
export const UPDATE_INVOICE_STATUS_BY_ID_FAILED = 'reporting/invoices/updateInvoiceStatusByIdFailed';
export const UPDATE_INVOICE_STATUS_BY_ID_SUCCESSFUL = 'reporting/invoices/updateInvoiceStatusByIdSuccessful';

export const UPDATE_REPORT = 'reporting/reports/update';
export const UPDATE_REPORT_FAILED = 'reporting/reports/updateFailed';
export const UPDATE_REPORT_SUCCESSFUL = 'reporting/reports/updateSuccessful';
export const DELETE_REPORT = 'reporting/reports/delete';
export const DELETE_REPORT_FAILED = 'reporting/reports/deleteFailed';
export const DELETE_REPORT_SUCCESSFUL = 'reporting/reports/deleteSuccessful';

export const PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID = 'providerFee/put';
export const PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_FAILED = 'providerFee/putFailed';
export const PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_SUCCESSFUL = 'providerFee/putSuccessful';

export const SET_REPORT_DETAIL_TOTAL_MEMBERS = 'reporting/reportDetail/setTotalMembers';

export const reportingActionCreators = {
  fetchContentBlockHistory: createAction(FETCH_CONTENT_BLOCK_HISTORY),
  fetchReportList: createAction(FETCH_REPORTS_LIST),
  createReport: createAction(CREATE_REPORT),
  updateReport: createAction(UPDATE_REPORT),
  deleteReport: createAction(DELETE_REPORT),
  fetchClaims: createAction(FETCH_CLAIMS),
  fetchClaimById: createAction(FETCH_CLAIM_BY_ID),
  fetchProviderFeeByAppointmentId: createAction(FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID),
  updateClaimDetail: createAction(PUT_CLAIM_DETAIL),
  fetchInvoices: createAction(FETCH_INVOICES),
  setReportDetailTotalMembers: createAction(SET_REPORT_DETAIL_TOTAL_MEMBERS),
  updateProviderFeeByProviderId: createAction(PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID),
  fetchInvoiceById: createAction(FETCH_INVOICE_BY_ID),
  updateInvoiceById: createAction(UPDATE_INVOICE_BY_ID),
  updateInvoiceStatusById: createAction(UPDATE_INVOICE_STATUS_BY_ID),
};
