import { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { IContentBlock } from 'pages/admin/conversations/Conversations.types';

import { OrderItem } from './OrderItem';
import { useStyles } from './FlowChangeOrder.styles';

type Props = {
  isOpen: boolean;
  contentBlocks: IContentBlock[];
  onClose: () => void;
  onChangeOrder: (payload: IContentBlock[]) => void;
};

const FlowChangeOrder: FC<Props> = ({ isOpen, contentBlocks, onClose, onChangeOrder }) => {
  const classes = useStyles();
  const [orderedCbs, setOrderedCbs] = useState<IContentBlock[]>(contentBlocks);

  const handleSubmit = () => {
    onChangeOrder && onChangeOrder(orderedCbs);
    onClose();
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setOrderedCbs((prevItems: IContentBlock[]) =>
      update(prevItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevItems[dragIndex]],
        ],
      }),
    );
  }, []);

  useEffect(() => {
    setOrderedCbs(contentBlocks);
  }, [contentBlocks]);

  const renderCard = useCallback(
    (item: IContentBlock, index: number) => (
      <OrderItem
        key={item.cbId}
        index={index}
        id={item.cbId}
        text={item.dctId ? item.basicInfo?.name || item.basicInfo?.text : item.basicInfo?.text}
        moveCard={moveCard}
      />
    ),
    [],
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.header}>
        <Heading className={classes.headerTitle} level={headingLevel.S} weight={fontWeight.BOLD}>
          Change order of content blocks
        </Heading>
        <IconButton className={classes.closeBtn} icon="close" onClick={onClose} />
      </Box>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.content}>
          <Box className={classes.items}>{orderedCbs.map(renderCard)}</Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save</Button>
          </Box>
        </Box>
      </DndProvider>
    </Drawer>
  );
};

export { FlowChangeOrder };
