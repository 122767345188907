import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import dayjs from 'dayjs';

import { UploadFile } from '@confidant-health/lib/ui/templates/upload-file';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import DateSelect from '@confidant-health/lib/ui/templates/date-select';

import UploadFileProgress from '../upload-flie-progress';
// schema
import { EducationSchema } from './Education.schema';
// styles
import { useStyles } from './Education.styles';
// constants
import { IEducationProps, IEducation } from './Education.types';

const defaultValues = {
  title: '',
  startDate: '',
  endDate: '',
  avatar: null,
  file: null,
};

const EditEducation: React.FC<IEducationProps> = ({
  isOpen,
  onClose,
  isEducation,
  data,
  onUpdate,
  error,
}) => {
  const classes = useStyles();

  const saveInfo = (payload: IEducation) => {
    const newBody = isEducation
      ? {
          educationName: [payload.title],
          educationStartDate: dayjs(payload.startDate).format('YYYY-MM-DD'),
          educationEndDate: dayjs(payload.endDate).format('YYYY-MM-DD'),
          educationImage: payload.avatar,
        }
      : {
          employmentName: [payload.title],
          employmentStartDate: dayjs(payload.startDate).format('YYYY-MM-DD'),
          employmentEndDate: dayjs(payload.endDate).format('YYYY-MM-DD'),
          employmentImage: payload.avatar,
        };
    onUpdate && onUpdate(newBody);
  };

  const [savingButton, setSavingButton] = useState(false);

  const onUpdateButtonClick = () => {
    setSavingButton(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setSavingButton(false);
    }
  }, [isOpen]);

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: EducationSchema,
    onSubmit: saveInfo,
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // Re enable update button when there are errors
  useEffect(() => {
    if (Object.keys(errors).length >= 1) {
      setSavingButton(false);
    }
  }, [errors, savingButton]);

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(data).forEach(async key => {
        await setFieldValue(key, data[key] || '');
      });
    }
  }, [data]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onAcceptedFiles = (files: File[]) => {
    const file = files[0];
    void setFieldValue('avatar', file);
  };

  const onChangeDate = (name: string) => (date: Date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD').toString();
    void setFieldValue(name, formattedDate);
  };
  const onRemoveFile = () => {
    void setFieldValue('avatar', '');
  };

  const onFileUploaded = (val, file) => {
    void setFieldValue('avatar', val);
    setSelectedFile(file);
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  // eslint-disable-next-line no-nested-ternary
  const fileName = selectedFile?.name
    ? selectedFile?.name
    : values.avatar?.split
    ? values.avatar.split('/').pop()
    : 'unknown';

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{isEducation ? 'Education' : 'Past employment'}</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center" paddingY={1}>
                <TextError errorMsg={error} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel(isEducation ? 'Education name' : 'Employment name')}
              <Input
                value={values.title}
                name="title"
                placeholder="Enter name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('title')}
                fullWidth
              />
              <TextError errorMsg={touched.title ? errors.title?.toString() : null} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Box className={classes.section} flex="1">
                {renderLabel(`${isEducation ? 'Education' : 'Employment'} start date`)}
                <DateSelect
                  value={values.startDate}
                  placeholder="Enter start date"
                  onChange={onChangeDate('startDate')}
                  maxDate={new Date()}
                />
                <TextError errorMsg={touched.startDate ? errors.startDate?.toString() : null} />
              </Box>
              <Box className={classes.section} flex="1">
                {renderLabel(`${isEducation ? 'Education' : 'Employment'} end date`)}
                <DateSelect
                  value={values.endDate}
                  placeholder="Enter end date"
                  onChange={onChangeDate('endDate')}
                  maxDate={new Date()}
                />
                <TextError errorMsg={touched.endDate ? errors.endDate?.toString() : null} />
              </Box>
            </Box>

            <Box className={classes.section}>
              {renderLabel(`${isEducation ? 'Education' : 'Employer'} logo`)}
              {values.avatar ? (
                <UploadFileProgress
                  file={values.avatar}
                  fileName={fileName}
                  fileSize={selectedFile?.size || 1024}
                  onUploaded={onFileUploaded}
                  onRemove={onRemoveFile}
                  s3Folder="profileImages/"
                />
              ) : (
                <UploadFile
                  onAcceptedFiles={onAcceptedFiles}
                  description="PNG, JPG, GIF up to 5MB"
                  fileType="image/*"
                />
              )}
              <TextError errorMsg={touched.avatar ? errors.avatar?.toString() : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onUpdateButtonClick();
                handleSubmit();
              }}
              disabled={savingButton}
            >
              {savingButton ? 'Saving...' : 'Update'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { EditEducation };
