export const ApiEndpoints = {
  ALLOW_ACCESS_GOOGLE_CALENDAR: {
    path: '/report-service-node/calendar/v1/allow-access-google-calendar',
    method: 'POST',
  },
  SYNC_CONFIDANT_TO_GOOGLE_CALENDAR: {
    path: '/report-service-node/calendar/v1/sync/confidant-to-google',
    method: 'POST',
  },
  SYNC_GOOGLE_TO_CONFIDANT: {
    path: '/report-service-node/calendar/v1/sync/google-to-confidant',
    method: 'POST',
  },
  GET_ALL_GOOGLE_CALENDAR_EVENTS: {
    path: '/report-service-node/calendar/v1/all',
    method: 'POST',
  },
  DELETE_CALENDAR_EVENTS: {
    path: '/report-service-node/calendar/v1/remove/calendar',
    method: 'POST',
  },
  GET_GOOGLE_CALENDER_SETTINGS: {
    path: '/profile/settings/google-calender',
    method: 'GET',
  },
  ADD_GOOGLE_CALENDER_SETTINGS: {
    path: '/profile/settings/google-calender',
    method: 'POST',
  },
  UPDATE_GOOGLE_CALENDER_SETTINGS: {
    path: '/profile/settings/google-calender',
    method: 'PUT',
  },
  DELETE_GOOGLE_CALENDER_SETTINGS: {
    path: '/profile/settings/google-calender',
    method: 'DELETE',
  },
  GET_ALL_CALENDARS_WITH_ACCESS_TOKEN: {
    path: '/report-service-node/calendar/v1/get-all-calendars',
    method: 'POST',
  },
  GET_PROFILE: {
    path: '/profile/profile',
    method: 'GET',
  },
  LIST_TODOS: {
    path: '/profile/todo',
    method: 'GET',
  },
  COMPLETE_TODOS: {
    path: '/profile/todo',
    method: 'PUT',
  },
  SEND_SUPPORT_EMAIL: {
    path: '/profile/support/connect',
    method: 'POST',
  },
  LOGIN: {
    path: '/auth/login',
    method: 'POST',
  },
  VERIFY_AUTH: {
    path: '/auth/verificationCode',
    method: 'POST',
  },
  RESEND_OTP: {
    path: '/auth/resend/verificationCode',
    method: 'POST',
  },
  VERIFY_OTP: {
    path: '/auth/login/patient/otp',
    method: 'POST',
  },
  USER_ACCOUNT_RECOVERY: {
    path: '/auth/recoverAccount',
    method: 'POST',
  },
  UPDATE_PASSWORD: {
    path: '/auth/recoverAccount/setup/newPassword',
    method: 'POST',
  },
  RESEND_VERIFICATION_CODE: {
    path: '/auth/resend/verificationCode',
    method: 'POST',
  },
  VERIFY_CONFIRMATION_CODE: {
    path: '/auth/verificationCode',
    method: 'POST',
  },
  GET_SENDBIRD_TOKEN: {
    path: '/auth/sendbird/session',
    method: 'GET',
  },
  PROVIDER_PROFILE: {
    path: '/profile/provider',
    method: 'GET',
  },
  PROVIDER_PROFILE_BY_ID: {
    path: '/profile/provider/{providerId}',
    method: 'GET',
  },
  GET_PROVIDER_ORGANIZATIONS: {
    path: '/profile/provider/organizations',
    method: 'GET',
  },
  SET_DEFAULT_ORGANIZATION: {
    path: '/profile/provider/organization/setDefault',
    method: 'POST',
  },
  GET_CONVERSATION_BY_ORG_ID: {
    path: '/conversation/conversation/list/{organizationId}',
    method: 'GET',
  },
  GET_CONVERSATIONS: {
    path: '/conversation/conversation/list',
    method: 'GET',
  },
  ASSIGN_CONVERSATION: {
    path: '/conversation/conversation/assign',
    method: 'POST',
  },
  GET_PLAN_ITEMS: {
    path: '/conversation/revamp/revampPlanItems',
    method: 'GET',
  },
  GET_ASSIGNED_PLAN_ITEMS: {
    path: '/conversation/revamp/assignedPlan',
    method: 'GET',
  },
  ADD_ASSIGNED_PLAN_ITEMS: {
    path: '/conversation/revamp/assignedPlan',
    method: 'POST',
  },
  REGISTER_PLAYERID: {
    path: '/auth/player/{playerId}',
    method: 'POST',
  },
  REMOVE_PLAYERID: {
    path: '/auth/removePlayer/{playerId}',
    method: 'DELETE',
  },
  REFRESH_AUTH_TOKEN: {
    path: '/auth/token/refresh',
    method: 'GET',
  },

  GET_PROFILE_DCT_BY_USER_ID: {
    path: '/profile/profile/dct/{userId}',
    method: 'GET',
  },
  INITIATE_VIDEOCHAT: {
    path: '/conversation/initiateVideoChat/{userID}',
    method: 'POST',
  },
  BOUNDED_CHAT_PROCESS_LATEST_MESSAGE: {
    path: '/report-service-node/chat/boundedChat/processLatestChatMessage',
    method: 'POST',
  },
  MARK_RECOMMENDATION_USED: {
    path: '/report-service-node/chat/boundedChat/recommendationUsed',
    method: 'POST',
  },
  AVATAR_BY_USER_ID: {
    path: '/profile/profile/avatar/{userId}',
    method: 'GET',
  },
  GET_CONNECTIONS: {
    path: '/profile/connections',
    method: 'GET',
  },
  GET_PAGINATED_CONNECTIONS: {
    path: '/profile/connections/v2',
    method: 'GET',
  },
  GET_CONNECTIONS_BY_STATUS: {
    path: '/profile/connections/getConnectionsByTypeAndStatus',
    method: 'GET',
  },
  SET_CARE_TEAM_MEMBER_DATA: {
    path: '/profile/careteam/{userId}',
    method: 'POST',
  },
  GET_CARE_TEAM_MEMBER: {
    path: '/profile/connections/careTeam/{userId}',
    method: 'GET',
  },
  GET_MATCHMAKERS: {
    path: '/profile/matchmakers',
    method: 'GET',
  },
  UPDATE_CONTACT_INFO: {
    path: '/profile/member/{id}/contact',
    method: 'PUT',
  },
  UPDATE_EMERGENCY_INFO: {
    path: '/profile/member/{id}/emergency',
    method: 'PUT',
  },
  UPDATE_LEVEL_ENGAGEMENT_USER: {
    path: '/profile/connections/updateLevelOfEngagement/{connectionId}/{engagementLevelId}',
    method: 'PUT',
  },
  UPDATE_CHAT_STATUS_USER: {
    path: '/profile/connections/updateChatStatus/{connectionId}',
    method: 'PUT',
  },
  GET_DEMOGRAPHIC_DETAIL: {
    path: '/profile/demographics/{connectionId}',
    method: 'GET',
  },
  GET_MEMBER_NOTES: {
    path: '/profile/profile/{userId}/contactNotes',
    method: 'GET',
  },
  GET_NOTES: {
    path: '/profile/profile/get/contactNotes',
    method: 'POST',
  },
  CREATE_MEMBER_CONNECTION: {
    path: '/profile/connections/suggestConnection',
    method: 'POST',
  },
  DELETE_MEMBER_CONNECTION: {
    path: '/profile/connections/{userId}/disconnect',
    method: 'POST',
  },
  ADD_MEMBER_NOTES: {
    path: '/profile/profile/contactNotes',
    method: 'POST',
  },
  UPDATE_MEMBER_NOTES: {
    path: '/profile/profile/contactNotes',
    method: 'PUT',
  },
  ADD_NOTE_APPOINTMENT: {
    path: '/scheduling/appointment/addNote/{appointmentId}',
    method: 'POST',
  },
  GET_CONTACT_NOTES_COUNT: {
    path: '/profile/profile/get/contactNotes/count',
    method: 'GET',
  },
  GET_CONTACT_NOTES_COUNT_BY_PATIENT: {
    path: '/profile/profile/get/contactNotes/count/{patientId}',
    method: 'GET',
  },
  GET_REFERRAL_PARTNERS: {
    path: '/profile/profile/referralPartners',
    method: 'GET',
  },
  CREATE_REFERRAL_PARTNER: {
    path: '/profile/profile/referralPartners',
    method: 'POST',
  },
  UPDATE_REFERRAL_PARTNER: {
    path: '/profile/profile/referralPartners/{referralPartnerId}',
    method: 'PUT',
  },
  GET_ALL_REFERRED_PATIENTS_BY_PARTNER: {
    path: '/profile/profile/get/referred/patients/{refPartnerId}',
    method: 'GET',
  },
  UPDATE_MEMBER_ID_CARD: {
    path: '/profile/profile/idCard',
    method: 'PATCH',
  },
  GET_REFERRED_PARTNER_MEMBER_STATS: {
    path: '/report-service-node/profile/referral-partner/memberstats/{referralPartnerId}',
    method: 'GET',
  },
  UPDATE_DEMOGRAPHIC_DETAIL: {
    path: '/profile/demographics/{connectionId}/v2',
    method: 'PUT',
  },
  GET_OUTCOMES_DETAIL: {
    path: '/conversation/cheatsheet/outcomes/{userId}',
    method: 'GET',
  },

  GET_PROFILE_ELEMENTS: {
    path: '/conversation/builder/profileElementsList',
    method: 'GET',
  },

  ADD_NEW_PROFILE_ELEMENT: {
    path: '/conversation/builder/profileElement',
    method: 'POST',
  },

  UPDATE_PROFILE_ELEMENT: {
    path: '/conversation/builder/profileElement',
    method: 'PUT',
  },

  GET_DCT_LIST: {
    path: '/conversation/dct',
    method: 'GET',
  },

  GET_TAGS_LIST: {
    path: '/conversation/builder/tags',
    method: 'GET',
  },

  GET_PATIENTS_LIST: {
    path: '/profile/users/patients',
    method: 'GET',
  },
  GET_PATIENTS_BY_USER: {
    path: '/profile/users/patientsByUser',
    method: 'POST',
  },
  DOWNLOAD_PATIENTS_BY_USER: {
    path: '/profile/users/patientsByUser/download',
    method: 'POST',
  },
  GET_WALLET: {
    path: '/billing/wallet/{connectionId}',
    method: 'GET',
  },

  UPDATE_WALLET_BALANCE: {
    path: '/billing/wallet/cheatsheet/topup',
    method: 'POST',
  },
  GET_NOTIFICATIONS: {
    path: '/audit/activityFeed/realtimeActivityFeed',
    method: 'GET',
  },
  GET_USER_PROFILES_BY_SEARCH: {
    path: '/profile/users/search',
    method: 'GET',
  },

  GET_PROVIDER_PUBLIC_DETAILS: {
    path: '/profile/provider/{providerId}',
    method: 'GET',
  },

  ADD_NEW_PROVIDER: {
    path: '/profile/provider/add',
    method: 'POST',
  },
  GET_PROVIDER_PROFILE: {
    path: '/profile/provider',
    method: 'GET',
  },

  GET_CONNECTIONS_BY_USER_ID: {
    path: '/profile/connections/activeConnectionList/{userId}',
    method: 'GET',
  },

  SUGGEST_CONNECTION_REQUEST: {
    path: '/profile/connections/suggestConnection',
    method: 'POST',
  },
  UPLOAD_IMAGE: {
    path: '/profile/provider/uploadImage',
    method: 'POST',
  },
  UPDATE_PROVIDER_PROFILE: {
    path: '/profile/provider/{providerId}/updateProfile',
    method: 'POST',
  },
  UPDATE_OPERATING_STATES: {
    path: '/profile/provider/operatingStates',
    method: 'PUT',
  },
  FETCH_ALL_PROVIDERS: {
    path: '/profile/provider/all',
    method: 'POST',
  },
  REORDER_PROVIDERS: {
    path: '/profile/provider/reorder',
    method: 'POST',
  },
  FETCH_SERVICES_ON_SERVICE_TYPE: {
    path: '/scheduling/schedule/system/serviceType',
    method: 'POST',
  },
  FETCH_SERVICES_ON_SERVICES_NAME: {
    path: '/scheduling/schedule/system/distinctServicesName',
    method: 'POST',
  },
  FETCH_PROVIDER_SERVICES: {
    path: '/scheduling/schedule/providerService',
    method: 'GET',
  },
  DELETE_SERVICE: {
    path: '/v2/scheduling/service/{serviceId}',
    method: 'DELETE',
  },
  ADD_NEW_SERVICE: {
    path: '/v2/scheduling/service',
    method: 'POST',
  },
  UPDATE_SERVICE: {
    path: '/v2/scheduling/service/{id}',
    method: 'PUT',
  },

  GET_LEDGER: {
    path: '/billing/payments/ledger/aggregate',
    method: 'GET',
  },
  GET_GROUP_LEDGER: {
    path: '/billing/payments/ledger/donations',
    method: 'GET',
  },
  GET_LEDGER_DETAILS: {
    path: '/billing/payments/ledger/provider/{providerId}',
    method: 'GET',
  },
  GET_PATIENT_CARD_TRANSACTIONS: {
    path: '/billing/payments/patient/ledgerHistory/{memberId}',
    method: 'GET',
  },

  GET_PROVIDER_SCHEDULE: {
    path: '/v2/scheduling/schedule/{actor}',
    method: 'GET',
  },

  UPDATE_PROVIDER_SCHEDULE: {
    path: '/v2/scheduling/schedule',
    method: 'PUT',
  },

  GET_APPOINTMENT_REPORT: {
    path: '/report-service-node/appointment/report/cashpayments',
    method: 'POST',
  },

  PAY_PROVIDER_OUTSTANDING_AMOUNT: {
    path: '/billing/payments/ledger/providerPayment/{providerId}',
    method: 'POST',
  },

  GET_CONVERSATIONS_DETAIL: {
    path: '/conversation/builder/conversations',
    method: 'GET',
  },
  GET_ALL_AI_JOBS: {
    path: '/v2/scheduling/aijob',
    method: 'GET',
  },

  GET_AUTOMATION_RULES_DETAIL: {
    path: '/conversation/builder/automationRulesList',
    method: 'GET',
  },

  GET_ELK_AUTOMATION_DETAILS: {
    path: 'automation_rule/_search',
    method: 'POST',
  },

  GET_AUTOMATION_COUNT: {
    path: '/report-service-node/automation/count',
    method: 'GET',
  },

  RE_PROCESS_ALFIE_VALIDATION: {
    path: '/conversation/evaluations/{appointmentId}/{evaluationId}/reprocess',
    method: 'POST',
  },

  ADD_DCT: {
    path: '/conversation/builder/dct',
    method: 'POST',
  },

  GET_CONTENT_BLOCKS_BY_DCT: {
    path: '/conversation/builder/contentblocks/{dctId}',
    method: 'GET',
  },
  GET_CONTENT_BLOCKS_BY_CONVERSATION: {
    path: '/conversation/builder/contentblocksByConversationId/{conversationId}',
    method: 'GET',
  },
  ADD_NEW_CONTENT_BLOCK: {
    path: '/conversation/builder/contentblock',
    method: 'POST',
  },
  SAVE_CONTENT_BLOCK: {
    path: '/conversation/builder/contentblock/{cbId}',
    method: 'PUT',
  },
  UPDATE_DCT: {
    path: '/conversation/builder/dct/{dctId}',
    method: 'PUT',
  },
  ADD_EVALUATION_DCT: {
    path: '/conversation/dct',
    method: 'POST',
  },
  UPDATE_EVALUATION_DCT: {
    path: '/conversation/builder/evaluationDct/{dctId}',
    method: 'PUT',
  },
  UPDATE_CONVERSATION: {
    path: '/conversation/builder/conversation/{conversationId}',
    method: 'PUT',
  },
  ADD_CONVERSATION: {
    path: '/conversation/builder/conversation',
    method: 'POST',
  },
  COPY_CONTENT_BLOCK: {
    path: '/conversation/builder/contentblock/{cbId}/copy',
    method: 'POST',
  },
  DELETE_CONTENT_BLOCK: {
    path: '/conversation/builder/contentblock/{cbId}',
    method: 'DELETE',
  },

  SAVE_CHOICE_TEMPLATE: {
    path: '/conversation/builder/saveChoiceTemplate',
    method: 'POST',
  },

  GET_ALL_CHOICE_TEMPLATES: {
    path: '/conversation/builder/getAllChoiceTemplates',
    method: 'GET',
  },
  GET_PERCENTILE_DISTRIBUTION_PROFILE_ELEMENTS_BY_VALUES: {
    path: '/conversation/builder/profileElement/distribution/{profileElementId}',
    method: 'GET',
  },

  GET_PROFILE_ELEMENT_DETAIL_BY_MONTH: {
    path: '/conversation/builder/profileElement/assignedByMonth/{profileElementId}',
    method: 'GET',
  },

  GET_PROFILE_ELEMENT_DATA_USAGE: {
    path: '/conversation/builder/profileElement/dctUsage/{profileElementId}',
    method: 'GET',
  },

  GET_CONVERSATION_ASSIGNMENT_REPORT: {
    path: '/conversation/builder/conversation/{conversationId}/graph',
    method: 'GET',
  },

  GET_CONVERSATION_ASSIGNMENT_DETAIL: {
    path: '/conversation/builder/conversation/{conversationId}/patients',
    method: 'GET',
  },

  GET_DCT_ASSIGNMENT_REPORT: {
    path: '/conversation/builder/dct/{dctId}/graph',
    method: 'GET',
  },

  GET_DCT_ASSIGNMENT_DETAIL: {
    path: '/conversation/builder/dct/{dctId}/patients',
    method: 'GET',
  },

  GET_EVALUATION_DETAIL: {
    path: '/conversation/builder/evaluation/details/{evaluationId}/patients',
    method: 'GET',
  },

  GET_EVALUATION_ASSIGNMENT_DETAIL_GRAPH: {
    path: '/conversation/builder/evaluation/{evaluationId}/graph',
    method: 'GET',
  },

  GET_EVALUATION_DCT_ASSIGNMENT_REPORT: {
    path: '/conversation/builder/evaluation/dct/{dctId}/graph',
    method: 'GET',
  },

  GET_EVALUATION_DCT_ASSIGNMENT_DETAIL: {
    path: '/conversation/builder/evaluation/dct/{dctId}/patients',
    method: 'GET',
  },

  GET_DOMAIN_TYPES: {
    path: '/conversation/builder/postapp/associatedTags',
    method: 'GET',
  },
  GET_DOMAIN_ELEMENT: {
    path: '/conversation/builder/postapp/associatedTag',
    method: 'GET',
  },
  GET_PATIENT_DOMAIN_ELEMENT: {
    path: '/conversation/builder/postapp/associatedTag/{patientId}',
    method: 'GET',
  },
  UPDATE_ASSOCIATED_TAG: {
    path: '/conversation/builder/postapp/associatedTag',
    method: 'PUT',
  },
  CREATE_ASSOCIATED_TAG: {
    path: '/conversation/builder/postapp/associatedTag',
    method: 'POST',
  },
  GET_PATIENT_HISTORY: {
    path: '/conversation/builder/postapp/associatedTag/history',
    method: 'GET',
  },
  ADD_DOMAIN_TYPE: {
    path: '/conversation/builder/domainType',
    method: 'POST',
  },

  CREATE_TODO: {
    path: '/profile/todo',
    method: 'POST',
  },

  UPDATE_TODO: {
    path: '/profile/todo',
    method: 'PUT',
  },

  UPDATE_DOMAIN_TYPE: {
    path: '/conversation/builder/domainType/{domainTypeId}',
    method: 'PUT',
  },

  DELETE_DOMAIN_TYPE: {
    path: '/conversation/builder/domainType/{domainTypeId}',
    method: 'DELETE',
  },
  GET_LEVEL_ENGAGEMENTS: {
    path: '/conversation/builder/levelOfEngagements',
    method: 'GET',
  },
  ADD_LEVEL_ENGAGEMENT: {
    path: '/conversation/builder/levelOfEngagement',
    method: 'POST',
  },
  UPDATE_LEVEL_ENGAGEMENT: {
    path: '/conversation/builder/levelOfEngagement/{id}/',
    method: 'PUT',
  },
  DELETE_LEVEL_ENGAGEMENT: {
    path: '/conversation/builder/levelOfEngagement/{id}/',
    method: 'DELETE',
  },
  UPGRADE_CONVERSATION_VERSION: {
    path: '/conversation/builder/conversation/{conversationId}/upgradeVersion',
    method: 'POST',
  },
  PUBLISH_CONVERSATION_DRAFT: {
    path: '/conversation/builder/conversation/{conversationId}/publish',
    method: 'POST',
  },
  CHECK_CONVERSATION_DRAFT_RIPPLES: {
    path: '/conversation/builder/conversation/{conversationId}/publish',
    method: 'GET',
  },
  PAYER_UPLOAD_IMAGE: {
    path: '/report-service-node/payer/uploadPicture',
    method: 'PUT',
  },
  UPGRADE_DCT_VERSION: {
    path: '/conversation/builder/dct/{dctId}/upgradeVersion',
    method: 'POST',
  },
  PUBLISH_DCT_DRAFT: {
    path: '/conversation/builder/dct/{dctId}/publish',
    method: 'POST',
  },
  CHECK_DCT_DRAFT_RIPPLES: {
    path: '/conversation/builder/dct/{dctId}/publish',
    method: 'GET',
  },
  LIST_APPOINTMENT_ELIGIBLE_PROVIDERS: {
    path: '/v2/scheduling/provider',
    method: 'GET',
  },

  GET_DCT_DETAIL: {
    path: '/conversation/builder/dct/{dctId}',
    method: 'GET',
  },
  UPDATE_DCT_SCORING: {
    path: '/conversation/builder/dct/scoring/{dctId}',
    method: 'PUT',
  },

  ADD_AUTOMATION_RULE: {
    path: '/conversation/builder/automationRule',
    method: 'POST',
  },

  UPDATE_AUTOMATION_RULE: {
    path: '/conversation/builder/automationRule/{automationRuleId}',
    method: 'PUT',
  },

  DELETE_AUTOMATION_RULE: {
    path: '/conversation/builder/automationRule/{automationRuleId}',
    method: 'DELETE',
  },

  ADD_DOMAIN_ELEMENT: {
    path: '/conversation/builder/domainElement',
    method: 'POST',
  },

  UPDATE_DOMAIN_ELEMENT: {
    path: '/conversation/builder/domainElement/{domainElementId}',
    method: 'PUT',
  },
  GET_ASSIGNED_PRIORITIES: {
    path: '/conversation/priorities/assigned',
    method: 'GET',
  },
  DELETE_DOMAIN_ELEMENT: {
    path: '/conversation/builder/domainElement/{domainElementId}',
    method: 'DELETE',
  },
  // GET_DOMAIN_ELEMENT: {
  //   path: '/conversation/builder/domainElement/{domainElementId}',
  //   method: 'GET',
  // },

  GET_DOMAIN_GROUPS: {
    path: '/conversation/builder/domainGroups/name/{domainTypeId}',
    method: 'GET',
  },

  ADD_DOMAIN_GROUP: {
    path: '/conversation/builder/domainGroup',
    method: 'POST',
  },

  UPDATE_DOMAIN_GROUP: {
    path: '/conversation/builder/domainGroup/{domainGroupId}',
    method: 'PUT',
  },

  DELETE_DOMAIN_GROUP: {
    path: '/conversation/builder/domainGroup/{domainGroupId}',
    method: 'DELETE',
  },

  GET_DOMAIN_ELEMENTS: {
    path: '/conversation/builder/domainElement/{domainTypeId}',
    method: 'GET',
  },
  GET_TAG_REPORT: {
    path: '/conversation/builder/tags/{tagId}/graph',
    method: 'GET',
  },
  GET_TAG_ASSIGNMENT_DETAILS: {
    path: '/conversation/builder/tags/{tagId}/patients',
    method: 'GET',
  },
  GET_TAG_DETAIL: {
    path: '/conversation/builder/tag/{tagId}',
    method: 'GET',
  },
  GET_DOMAIN_LOOKUP: {
    path: '/conversation/builder/domain/lookup',
    method: 'GET',
  },
  GET_REVAMP_TYPES: {
    path: '/conversation/revamp/revampTypes',
    method: 'GET',
  },
  ADD_REVAMP_TYPE: {
    path: '/conversation/revamp/revampType',
    method: 'POST',
  },
  UPDATE_REVAMP_TYPE: {
    path: '/conversation/revamp/revampType',
    method: 'PUT',
  },
  DELETE_REVAMP_TYPE: {
    path: '/conversation/revamp/revampType',
    method: 'DELETE',
  },
  GET_SYSTEM_SERVICES: {
    path: '/v2/scheduling/service/systemServices',
    method: 'GET',
  },
  GET_ALL_GROUPS: {
    path: '/profile/groups/allGroups',
    method: 'GET',
  },
  ADD_NEW_PLAN_ITEM: {
    path: '/conversation/revamp/revampPlanItem',
    method: 'POST',
  },
  UPDATE_PLAN_ITEM: {
    path: '/conversation/revamp/revampPlanItem',
    method: 'PUT',
  },
  DELETE_PLAN_ITEM: {
    path: '/conversation/revamp/revampPlanItem',
    method: 'DELETE',
  },
  UPDATE_PROVIDER_OPERATING_STATES: {
    path: '/profile/provider/operatingStates/{providerId}',
    method: 'PUT',
  },
  CHATBOT_DETAILS: {
    path: '/conversation/chatbots/{userId}',
    method: 'GET',
  },
  GET_CHATBOT_CONTACTS: {
    path: '/conversation/chatbots/responses/{conversationContextId}',
    method: 'GET',
  },
  GET_CHATBOT_ATTEMPT_HISTORY: {
    path: '/conversation/chatbots/patient/completionHistory',
    method: 'POST',
  },
  APPPINTMENT_DOWNLOAD: {
    path: '/report-service-node/appointment/member/export/{memberId}',
    method: 'GET',
  },
  ALL_APPOINNTMENT_COUNTS: {
    path: '/report-service-node/appointment/counts/all',
    method: 'POST',
  },
  CREATE_APPOINTMENT: {
    path: '/v2/scheduling/appointment',
    method: 'POST',
  },
  CREATE_RECURRING_APPOINTMENT: {
    path: '/v2/scheduling/appointment/recurring/request',
    method: 'POST',
  },
  VALIDATE_RECURRING_APPOINTMENT: {
    path: '/v2/scheduling/appointment/recurring/validate',
    method: 'POST',
  },
  FETCH_NEXT_APPOINTMENT: {
    path: '/v2/scheduling/appointment/next-appointment',
    method: 'GET',
  },
  FETCH_PREVIOUS_COMPLETED_APPOINTMENT: {
    path: '/v2/scheduling/appointment/previous-completed',
    method: 'GET',
  },
  GET_APPOINTMENTS: {
    path: '/v2/scheduling/appointment/search',
    method: 'GET',
  },
  GET_APPOINTMENT_COUNTS: {
    path: '/v2/scheduling/appointment/counts',
    method: 'GET',
  },
  EXPORT_APPOINTMENTS: {
    path: '/v2/scheduling/appointment/export',
    method: 'GET',
  },
  GET_APPOINTMENTS_PAST: {
    path: '/scheduling/appointment/past/{userId}/v2',
    method: 'GET',
  },
  GET_PAST_APPOINTMENT_DETAILS: {
    path: '/scheduling/appointment/pastAppointmentDetails/{appointmentId}',
    method: 'GET',
  },
  SEARCH_SCHEDULE: {
    path: '/v2/scheduling/schedule/masterSchedule',
    method: 'GET',
  },
  GET_ALL_UNIQUE_SERVICES: {
    path: '/v2/scheduling/service/unique',
    method: 'GET',
  },
  PATCH_APPOINTMENT: {
    path: '/v2/scheduling/appointment/{appointmentId}',
    method: 'PATCH',
  },
  GET_APPOINTMENT_BY_ID: {
    path: '/v2/scheduling/appointment/{appointmentId}',
    method: 'GET',
  },
  GET_APPOINTMENT_CPT_CODE: {
    path: '/scheduling/appointment/cptCodes/{appointmentId}',
    method: 'GET',
  },
  GET_APPOINTMENT_CPT_CODE_BY_ID: {
    path: '/report-service-node/appointment-cpt-code/by-appointment/{id}',
    method: 'GET',
  },
  ADD_APPOINTMENT_CPT_CODE: {
    path: '/report-service-node/appointment-cpt-code',
    method: 'POST',
  },
  UPDATE_APPOINTMENT_CPT_CODE: {
    path: '/report-service-node/appointment-cpt-code/{id}',
    method: 'PUT',
  },
  GET_PROVIDER_SERVICES_BY_ID: {
    path: '/v2/scheduling/service/providerServices',
    method: 'GET',
  },
  GET_SESSION_TYPES: {
    path: '/v2/scheduling/service/type',
    method: 'GET',
  },
  CREATE_SESSION_TYPE: {
    path: '/v2/scheduling/service/service-type',
    method: 'POST',
  },
  UPDATE_SESSION_TYPE: {
    path: '/v2/scheduling/service/service-type/{name}',
    method: 'PUT',
  },
  DELETE_SESSION_TYPE: {
    path: '/v2/scheduling/service/service-type/{name}',
    method: 'DELETE',
  },
  GET_USER_ACTIVITY: {
    path: '/audit/getUserActivity/{userId}',
    method: 'GET',
  },
  USER_GROUPS: {
    path: '/profile/groups/joined/{userId}',
    method: 'GET',
  },
  GET_CHANNEL_URL: {
    path: '/conversation/liveChat/{connectionId}/channelUrl',
    method: 'GET',
  },
  GET_EVALUATIONS: {
    path: '/conversation/builder/evaluations',
    method: 'GET',
  },
  GET_APPOINTMENT_EVALUATION_PROGRESS: {
    path: '/conversation/evaluations/appointment/progress/{appointmentId}',
    method: 'GET',
  },
  PROVIDE_AI_EVALUATION_FEEDBACK: {
    path: '/conversation/evaluations/{appointmentId}/feedback',
    method: 'POST',
  },
  UPDATE_APPOINTMENT_EVALUATION_STATUS: {
    path: '/conversation/evaluations/appointment/updateEvaluationStatus/{appointmentId}',
    method: 'PUT',
  },
  UPDATE_APPOINTMENT_ADD_MULTIPLE_EVALUATIONS: {
    path: '/conversation/evaluations/appointmentEvaluations/{appointmentId}',
    method: 'PUT',
  },
  GET_EVALUATION_CONTEXT: {
    path: '/conversation/evaluations/context/{appointmentId}/{evaluationId}',
    method: 'GET',
  },
  GET_CONTENT_BLOCK_COMMENTS: {
    path: '/conversation/evaluations/context/{contextId}/cbId/{cbId}/comment',
    method: 'GET',
  },
  ADD_CONTENT_BLOCK_COMMENT: {
    path: '/conversation/evaluations/context/{contextId}/cbId/{cbId}/comment',
    method: 'POST',
  },
  UPDATE_SESSION_TRANSCRIPT: {
    path: '/conversation/evaluations/{appointmentId}/transcript',
    method: 'PUT',
  },
  UPDATE_EVALUATION_CONTEXT: {
    path: '/conversation/evaluations/context/{appointmentId}/{evaluationId}',
    method: 'PUT',
  },
  UPDATE_EVALUATION_CONTEXT_FOR_FULL_PAGE_DCT: {
    path: '/conversation/evaluations/context/processFullPageDct',
    method: 'PUT',
  },
  ADD_NEW_CONVERSATION: {
    path: '/conversation/builder/conversation',
    method: 'POST',
  },
  ADD_NEW_EVALUATION: {
    path: '/conversation/builder/evaluations',
    method: 'POST',
  },
  UPDATE_EVALUATION: {
    path: '/conversation/builder/evaluations/{evaluationId}',
    method: 'PUT',
  },
  GET_PRIORITY_DOMAIN_TYPES: {
    path: '/conversation/builder/domainTypes',
    method: 'GET',
  },
  GET_PRIORITY_DOMAIN_ELEMENTS: {
    path: '/conversation/builder/domainElements',
    method: 'GET',
  },
  GET_DOMAIN_ELEMENTS_BY_TYPE_ID: {
    path: '/conversation/builder/domainElements/byType/{typeId}',
    method: 'GET',
  },
  GET_PRIORITY_DOMAIN_GROUPS: {
    path: '/conversation/builder/domainGroups',
    method: 'GET',
  },
  GET_TAGS: {
    path: '/conversation/builder/tags',
    method: 'GET',
  },
  ADD_NEW_TAG: {
    path: '/conversation/builder/tag',
    method: 'POST',
  },
  UPDATE_TAG: {
    path: '/conversation/builder/tag/{tagId}',
    method: 'PUT',
  },
  GET_DEMOGRAPHIC_METADATA: {
    path: '/profile/demographics/metadata',
    method: 'GET',
  },
  GET_APPOINTMENTS_METRICS: {
    path: '/elasticsearch/appointments/metrics',
    method: 'GET',
  },
  GET_FILTERED_APPOINTMENTS_METRICS: {
    path: '/elasticsearch/appointments/metrics/filtered',
    method: 'POST',
  },
  GET_CONTENT_BLOCK_HISTORY: {
    path: '/reporting/content-block/{contentBlockId}/history/patient/{userId}',
    method: 'GET',
  },
  START_OR_JOIN_CALL: {
    path: '/profile/group/{channelUrl}/startOrJoinCall',
    method: 'POST',
  },
  LEAVE_CALL: {
    path: '/profile/group/{channelUrl}/{userId}/leave',
    method: 'POST',
  },
  GET_REPORTS_LIST: {
    path: '/report-service-node/reports',
    method: 'GET',
  },
  GET_REPORT_BY_ID: {
    path: '/report-service-node/report/{id}',
    method: 'GET',
  },
  GET_KPI_OUTCOME_FOR_MEMBER: {
    path: '/report-service-node/dashboard/profileElement/outcomeAverage/{kpi}',
    method: 'GET',
  },
  GET_PROFILE_ELEMENT_30_DAY_ASSIGNMENT: {
    path: '/report-service-node/dashboard/profileElement/assignment30Days/{userAccountId}/{profileElement}',
    method: 'GET',
  },
  ADD_NEW_REPORT: {
    path: '/report-service-node/report',
    method: 'POST',
  },
  UPDATE_REPORT: {
    path: '/report-service-node/report/{id}',
    method: 'PUT',
  },
  DELETE_REPORT: {
    path: '/report-service-node/report/{id}',
    method: 'DELETE',
  },
  GET_ELK_PROFILE_ELEMENT_ASSIGNMENT_CHATBOT: {
    path: 'chatbot/_search',
    method: 'POST',
  },
  GET_ELK_PROFILE_ELEMENT_DCT_CONVO: {
    path: 'dct_conversation/_search',
    method: 'POST',
  },
  GET_ELK_PROFILE_ELEMENT_DCT_EVALUATION: {
    path: 'dct_evaluation/_search',
    method: 'POST',
  },
  GET_ELK_PROFILE_ELEMENT_PRIORITY_DATA_DOMAIN: {
    path: 'data_domain_element_report/_search',
    method: 'POST',
  },
  GET_ELK_PROFILE_ELEMENT_ASSIGNMENT_EVALUATION: {
    path: 'chatbot_evaluation/_search',
    method: 'POST',
  },
  GET_ELK_PATIENT_REPORT: {
    path: 'patient/_search',
    method: 'POST',
  },
  GET_ELK_APPOINTMENT_REPORT: {
    path: 'appointment/_search',
    method: 'POST',
  },
  GET_ELK_APPOINTMENT_COUNT: {
    path: 'appointment/_count',
    method: 'POST',
  },
  GET_ELK_APPOINTMENT_FEEDBACK: {
    path: 'feedback/_search',
    method: 'POST',
  },
  GET_ELK_PROFILE_ELEMENT_REPORT: {
    path: 'profile_element_data/_search',
    method: 'POST',
  },
  GET_ELK_PROFILE_ELEMENT_REPORT_2: {
    path: 'profile_element_report/_search',
    method: 'POST',
  },
  GET_ELK_PLAN_ITEM_DETAILS: {
    path: 'planitems/_search',
    method: 'POST',
  },
  GET_ELK_PROFILE_ELEMENT_COUNT: {
    path: 'profile_element/_count',
    method: 'POST',
  },
  GET_ELK_CHATBOT_DCT_REPORT: {
    path: 'dct_conversation/_search',
    method: 'POST',
  },
  GET_ELK_CHATBOT_DCT_COUNT: {
    path: 'dct_conversation/_count',
    method: 'POST',
  },
  GET_ELK_EVALUATION_DCT_REPORT: {
    path: 'dct_evaluation/_search',
    method: 'POST',
  },
  GET_ELK_EVALUATION_DCT_COUNT: {
    path: 'dct_evaluation/_count',
    method: 'POST',
  },
  GET_ELK_CHATBOT_REPORT: {
    path: 'chatbot/_search',
    method: 'POST',
  },
  GET_ELK_CHATBOT_COUNT: {
    path: 'chatbot/_count',
    method: 'POST',
  },
  GET_ELK_EVALUATION_REPORT: {
    path: 'chatbot_evaluation/_search',
    method: 'POST',
  },
  GET_ELK_EVALUATION_COUNT: {
    path: 'chatbot_evaluation/_count',
    method: 'POST',
  },
  GET_ELK_DATA_DOMAIN_ELEMENT_REPORT: {
    path: 'data_domain_element_report/_search',
    method: 'POST',
  },
  GET_ELK_DATA_DOMAIN_ELEMENT_COUNT: {
    path: 'data_domain_element_report/_count',
    method: 'POST',
  },
  GET_ELK_PATIENTS_COUNT: {
    path: 'patient/_count',
    method: 'POST',
  },
  GET_ELK_REPORTS_COUNT: {
    path: 'conversation/_count',
    method: 'POST',
  },
  GET_ELK_LOGIN_REPORT: {
    path: 'user_activity_log_lite/_search',
    method: 'POST',
  },
  GET_ELK_LOGIN_COUNT: {
    path: 'user_activity_log_lite/_count',
    method: 'POST',
  },
  GET_APPOINTMENTS_V4: {
    path: '/report-service-node/appointment/v4',
    method: 'POST',
  },
  GET_EVALUATIONS_DCT_OUTCOME_FOR_USER: {
    path: 'report-service-node/evaluation/patient/{userId}/listOfDcts',
    method: 'GET',
  },
  GET_PATIENT_EVALUATIONS: {
    path: '/report-service-node/evaluation/patientEvaluations/{userId}',
    method: 'GET',
  },
  GET_COMPLETION_HISTORY: {
    path: '/report-service-node/evaluation/patient/completionHistory',
    method: 'POST',
  },
  GET_PROVIDER_COMPLETION_HISTORY: {
    path: '/report-service-node/evaluation/dct/patient/completionHistory',
    method: 'POST',
  },
  GET_MEMBER_COMPLETION_HISTORY: {
    path: '/report-service-node/conversation/dct/patient/completionHistory',
    method: 'POST',
  },
  GET_CONTEXT_RESPONSES: {
    path: '/report-service-node/evaluation/contextResponse/{contextId}',
    method: 'GET',
  },
  GET_MEMBER_CONTEXT_RESPONSES: {
    path: '/conversation/chatbots/responses/{contextId}',
    method: 'GET',
  },
  GET_STATES: {
    path: '/report-service-node/states/v2',
    method: 'GET',
  },
  UPDATE_STATE: {
    path: '/report-service-node/state/{stateId}',
    method: 'PUT',
  },
  GET_STATE_BY_ID: {
    path: '/report-service-node/state/{stateId}',
    method: 'GET',
  },
  ADD_REGISTERED_ENTITY: {
    path: '/report-service-node/state/registeredEntity',
    method: 'POST',
  },
  UPDATE_REGISTERED_ENTITY: {
    path: '/report-service-node/state/registeredEntity/{stateId}',
    method: 'PUT',
  },
  GET_PAYER_GROUPED: {
    path: '/report-service-node/payers/grouped',
    method: 'GET',
  },
  GET_PAYERS: {
    path: '/report-service-node/payers/{stateId}',
    method: 'GET',
  },
  GET_ALL_PAYERS: {
    path: '/report-service-node/payers',
    method: 'GET',
  },
  GET_UNIQUE_PAYERS: {
    path: '/report-service-node/payers/unique',
    method: 'GET',
  },
  GET_PAYER_BY_ID: {
    path: '/report-service-node/payer/{payerId}',
    method: 'GET',
  },
  ADD_PAYER: {
    path: '/report-service-node/payer/{stateId}',
    method: 'POST',
  },
  UPDATE_PAYER: {
    path: '/report-service-node/payer/{stateId}/{payerId}',
    method: 'PUT',
  },
  ADD_PAYER_PLAN: {
    path: '/report-service-node/payer/plan/{payerId}',
    method: 'POST',
  },
  UPDATE_PAYER_PLAN: {
    path: '/report-service-node/payer/plan/{payerId}/{planId}',
    method: 'PUT',
  },
  ADD_PAYER_PROVIDER: {
    path: '/report-service-node/payer/plan/provider/{planId}',
    method: 'POST',
  },
  UPDATE_PAYER_PROVIDER: {
    path: '/report-service-node/payer/plan/provider/{planId}/{providerId}',
    method: 'PUT',
  },
  GET_CPTS: {
    path: '/report-service-node/cpts',
    method: 'GET',
  },
  ADD_PAYER_PLAN_CPT: {
    path: '/report-service-node/payer/plan/cpt/{planId}',
    method: 'POST',
  },
  UPDATE_PAYER_PLAN_CPT: {
    path: '/report-service-node/payer/plan/cpt/{planId}/{cptId}',
    method: 'PUT',
  },
  GET_ICD10_CODES: {
    path: '/report-service-node/icd10s',
    method: 'GET',
  },
  GET_REPORT_DCT_FOR_PATIENT: {
    path: '/reporting/dct/patient/{userId}/listOfDcts',
    method: 'GET',
  },
  GET_DCT_RESPONSES: {
    path: '/report-service-node/evaluation/patient/{userId}/contentBlock/{cbId}/history',
    method: 'GET',
  },

  VALIDATE_INSURANCE_INFO: {
    path: '/report-service-node/soharhealth/entry/{userAccountId}',
    method: 'POST',
  },

  GET_INSURANCE_INFO: {
    path: 'report-service-node/insurances/userprofile/insurance-info/{userId}',
    method: 'GET',
  },
  UPDATE_INSURANCE_INFO: {
    path: 'report-service-node/insurances/userprofile/insurance-info/{userId}',
    method: 'PUT',
  },
  GET_CLAIMS: {
    path: 'report-service-node/claims',
    method: 'POST',
  },
  GET_CLAIM_BY_ID: {
    path: 'report-service-node/claims/{id}',
    method: 'GET',
  },
  UPDATE_CLAIM_BY_ID: {
    path: 'report-service-node/claims/{claimId}/{type}',
    method: 'PUT',
  },
  UPLOAD_CANDID_PART_ONE: {
    path: 'report-service-node/claims/candid/part1',
    method: 'POST',
  },
  UPLOAD_CANDID_PART_TWO: {
    path: 'report-service-node/claims/candid/part2',
    method: 'POST',
  },
  UPLOAD_CANDID_PART_THREE: {
    path: 'report-service-node/claims/candid/part3',
    method: 'POST',
  },
  UPLOAD_CANDID_PART_FOUR: {
    path: 'report-service-node/claims/candid/part4',
    method: 'POST',
  },
  DOWNLOAD_EXCEL_REPORT: {
    path: 'report-service-node/elastic-proxy/download',
    method: 'POST',
  },
  ADD_INVOICE: {
    path: 'report-service-node/invoice',
    method: 'POST',
  },
  GET_INVOICES: {
    path: 'report-service-node/invoices',
    method: 'POST',
  },
  GET_PROVIDER_FEE_LIST: {
    path: 'report-service-node/providerfees',
    method: 'POST',
  },
  GET_PROVIDER_FEE_BY_ID: {
    path: 'report-service-node/providerfee/{id}',
    method: 'GET',
  },
  GET_PROVIDER_FEE_BY_CLAIM_ID: {
    path: 'report-service-node/providerfee/by-claim/{id}',
    method: 'GET',
  },
  MARK_PAID_PROVIDER_FEE: {
    path: 'report-service-node/providerfee/mark/paid/{id}',
    method: 'GET',
  },
  GET_TOP_PROVIDERS: {
    path: '/scheduling/appointment/list/top/providers',
    method: 'GET',
  },
  ALL_SELECTED_MARK_REJECTED_PROVIDER_FEE: {
    path: 'report-service-node/providerfee/mark/rejected',
    method: 'POST',
  },
  ALL_SELECTED_MARK_PAID_PROVIDER_FEE: {
    path: 'report-service-node/providerfee/mark/paid',
    method: 'POST',
  },
  MARK_REJECT_PROVIDER_FEE: {
    path: 'report-service-node/providerfee/mark/rejected/{id}',
    method: 'GET',
  },
  UPDATE_PROVIDER_FEE_BY_PROVIDER_ID: {
    path: 'report-service-node/providerfee/{providerId}',
    method: 'PUT',
  },
  GET_PROVIDER_FEE_BY_APPOINTMENT_ID: {
    path: 'report-service-node/providerfee/by-appointment/{id}',
    method: 'GET',
  },
  GET_STATES_SIMPLE: {
    path: 'report-service-node/states/simple',
    method: 'GET',
  },
  ADD_INSURANCE_INFO: {
    path: 'report-service-node/insurances/userprofile',
    method: 'POST',
  },
  TREMBIT_SESSION_STATUS: {
    path: '/report-service-node/trembitsession/{trembitSessionId}/{event}',
    method: 'GET',
  },
  TREMBIT_SESSION_EVALUATION_TIMESTAMP: {
    path: '/report-service-node/trembitsession/{trembitSessionId}/{evaluationId}/{event}',
    method: 'GET',
  },
  TREMBIT_SESSION_CONTENTBLOCK_TIMESTAMP: {
    path: '/report-service-node/trembitsession/{trembitSessionId}/{evaluationId}/{cbId}/{event}',
    method: 'GET',
  },
  GET_TREMBIT_SESSION_EVALUATION_TIMESTAMP: {
    path: '/report-service-node/trembitsession/{trembitSessionId}/{evaluationId}/contentBlocks/{event}',
    method: 'GET',
  },
  // TREMBIT_SESSION_EVALUATION_TIMESTAMP: {
  //   path: '/report-service-node/trembitsession/{appointmentId}/{evaluationId}/{cbId}/{event}',
  //   method: 'GET',
  // },
  GET_INVOICE_BY_ID: {
    path: 'report-service-node/invoice/{id}',
    method: 'GET',
  },
  UPDATE_INVOICE_BY_ID: {
    path: 'report-service-node/invoice/{id}',
    method: 'PUT',
  },
  UPDATE_INVOICE_STATUS: {
    path: 'report-service-node/invoices/status/{id}',
    method: 'PUT',
  },
  ADD_PROVIDER_NEW: {
    path: '/profile/provider/add',
    method: 'POST',
  },
  INVITE_MEMBER: {
    path: 'profile/member/{memberId}/invite',
    method: 'POST',
  },
  TODO_STATS: {
    path: 'profile/todo/stats',
    method: 'GET',
  },
  SHARE_ALFIE_VALIDATION: {
    path: 'conversation/evaluations/share',
    method: 'POST',
  },
  GET_ADMIN_NOTIFICATIONS: {
    path: '/profile/notifications/admin',
    method: 'GET',
  },
  REMOVE_ADMIN_NOTIFICATION: {
    path: '/profile/notifications/{notificationId}/complete',
    method: 'PUT',
  },
  GET_LEVEL_OF_ENGAGEMENTS: {
    path: '/v2/scheduling/loe/stats',
    method: 'GET',
  },
  GET_LOE_DETAILS: {
    path: '/v2/scheduling/loe/graph',
    method: 'GET',
  },
  GET_LOE_MEMBERS_LIST: {
    path: '/v2/scheduling/loe/members',
    method: 'GET',
  },
  ADD_CONTENT_BLOCK: {
    path: '/v2/scheduling/library/contentblock',
    method: 'POST',
  },
  USE_CONTENT_BLOCK: {
    path: 'conversation/library/useContentBlock',
    method: 'POST',
  },
  FETCH_LIBRARY_CONTENT_BLOCKS: {
    path: '/v2/scheduling/library/contentblock',
    method: 'GET',
  },
  UPDATE_LIBRARY_CONTENT_BLOCKS: {
    path: '/v2/scheduling/library/contentblock/{contentBlockId}',
    method: 'PATCH',
  },
  DELETE_LIBRARY_CONTENT_BLOCKS: {
    path: '/v2/scheduling/library/contentblock/{contentBlockId}',
    method: 'DELETE',
  },
  DELETE_EVALUATION: {
    path: '/conversation/evaluations/appointmentEvaluations/{appointmentId}',
    method: 'DELETE',
  },
  BYPASS_PROXY: {
    path: '/report-service-node/bypass-proxy',
    method: 'POST',
  },
  GET_FEEDBACK_SUMMARY: {
    path: '/v2/scheduling/provider/{providerId}',
    method: 'GET',
  },
  GET_PROVIDER_REVIEWS: {
    path: '/v2/scheduling/provider/feedback',
    method: 'GET',
  },
  GET_PHQ9_GAD7_AVERAGE_SCORE_SUMMARY: {
    path: 'report-service-node/dashboard/profileElement/outcomeAverage/{type}/summary',
    method: 'GET',
  },
  GET_ALL_PHQ9_GAD7_AVERAGE_SCORE: {
    path: 'report-service-node/dashboard/profileElement/outcomeAverage/{type}',
    method: 'GET',
  },
  USERS_SUMMARY: {
    path: '/report-service-node/user/usersSummary',
    method: 'POST',
  },
  NOTIFY_AI_COMPLETION: {
    path: '/conversation/evaluations/context/notifyForAICompletion',
    method: 'POST',
  },
  FETCH_DCT_PREVIOUS_RESPONSES: {
    path: 'conversation/evaluations/getAllPreviousAnswers',
    method: 'GET',
  },
  USE_DCT_PREVIOUS_RESPONSES: {
    path: 'conversation/evaluations/useAllPreviousAnswers',
    method: 'PATCH',
  },
  ADD_UPDATE_DEFAUTL_CARE_NAVIGATOR: {
    path: '/profile/settings/chat/notifications',
    method: 'PUT',
  },
  GET_DEFAUTL_CARE_NAVIGATOR: {
    path: '/profile/settings/chat/notifications',
    method: 'GET',
  },
  GET_CARE_NAVIGATOR_ALIAS_ACCOUNTS: {
    path: '/profile/profile/care/navigator/alias/emails',
    method: 'GET',
  },
  FETCH_CAQH_CREDENTIALS: {
    path: '/report-service-node/credentialing/fetchCAQHCredentials/{userAccountId}',
    method: 'GET',
  },
  POST_CAQH_CREDENTIALS: {
    path: '/report-service-node/credentialing/validateCAQHCredentials',
    method: 'POST',
  },
  EXTRACT_CREDENTIALING_DATA: {
    path: '/report-service-node/credentialing/importCAQHData/{userAccountId}',
    method: 'POST',
  },
  CAQH_DATA_SUMMARY: {
    path: '/report-service-node/credentialing/dataSummary/{type}/{providerId}',
    method: 'GET',
  },
  GET_NAV_ITEM_STATUS: {
    path: '/report-service-node/credentialing/{providerId}/statusOverview',
    method: 'GET',
  },
  PUT_PROVIDER_DATA: {
    path: '/report-service-node/credentialing/dataSummary/{sectionType}',
    method: 'PUT',
  },
  UPLOAD_DOCUMENT_DATA: {
    path: '/report-service-node/credentialing/dataSummaryValidation',
    method: 'PUT',
  },
  DELETE_CREDENTIALING_DOCUMENT: {
    path: '/report-service-node/credentialing/delete',
    method: 'DELETE',
  },
  GET_AVAILABLE_STATES: {
    path: '/report-service-node/credentialing/fetchAvailableStates/{userAccountId}',
    method: 'GET',
  },
  REQUEST_PAYER_SUBMISSION: {
    path: '/report-service-node/credentialing/payerSubmissionRequest/{userAccountId}',
    method: 'POST',
  },
  UPLOAD_MEDIA: {
    path: '/profile/s3Uploads/uploadFile',
    method: 'POST',
  },
  AWS_SIGNEDURL: {
    path: '/report-service-node/aws/signedurl',
    method: 'POST',
  },
  FETCH_OLD_MESSAGES: {
    path: '/report-service-node/sendbird/oldMessagesHistory',
    method: 'GET',
  },
};
