/* eslint-disable no-nested-ternary */
import { FC, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';

import { DomainTypesEnum } from 'constants/CommonConstants';
import { useDomainType } from 'hooks/useDomainType';
import { ReportItem } from '../AddNewReport.types';
import { ISymptomPayload } from '../../Symptoms/symptom-card/SymptomCard.types';
import { ReportItemProgress } from '../../../MemberDetail.types';
import { ReportFormCollapse } from '../report-form-collapse';
import { useStyles } from './StepTwo.styles';

type Props = {
  type?: DomainTypesEnum;
  isLifeEvent?: boolean;
  showInterference?: boolean;
  selectedSymptoms: ISymptomPayload[];
  onRemoveSymptom: (index: number) => void;
  updateSymptoms: (symptoms: ISymptomPayload[]) => void;
  options?: ReportItem[];
};

const StepTwo: FC<Props> = ({
  showInterference,
  selectedSymptoms,
  isLifeEvent,
  type,
  onRemoveSymptom,
  updateSymptoms,
  options,
}) => {
  const classes = useStyles();
  const [, , , lookupData] = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
  });

  const medicalConditions = lookupData?.medicalCondition;
  useEffect(() => {
    updateSymptoms(
      selectedSymptoms.map((symp, index) => ({
        ...symp,
        progress: !index ? ReportItemProgress.InProgress : ReportItemProgress.Incomplete,
      })),
    );
  }, []);

  const onUpdateSymptom = useCallback(
    (symptom: ISymptomPayload) => {
      const nextIndex = selectedSymptoms.findIndex(
        item => item.item.value !== symptom.item.value && item.notes.trim().length === 0,
      );
      updateSymptoms(
        selectedSymptoms.map((symp, index) =>
          symp.item.value === symptom.item.value
            ? { ...symptom, progress: ReportItemProgress.Completed }
            : index === nextIndex
            ? { ...symp, progress: ReportItemProgress.InProgress }
            : symp,
        ),
      );
    },
    [selectedSymptoms, updateSymptoms],
  );

  const onRemoveClick = (index: number) => () => {
    const updatedSymptoms = selectedSymptoms.filter((_, i) => i !== index);
    onRemoveSymptom(index);
    updateSymptoms([...updatedSymptoms]);
  };

  const onOpenCollapse = (symptom: ISymptomPayload) => (open: boolean) => {
    let newProgress = symptom.notes.trim().length
      ? ReportItemProgress.Completed
      : ReportItemProgress.Incomplete;
    if (open) {
      newProgress = ReportItemProgress.InProgress;
    }

    updateSymptoms(
      selectedSymptoms.map(symp =>
        symp.item.value === symptom.item.value ? { ...symp, progress: newProgress } : symp,
      ),
    );
  };

  return (
    <Box className={classes.container}>
      {selectedSymptoms.map((symptom, index) => (
        <ReportFormCollapse
          key={index}
          symptom={symptom}
          type={type}
          isLifeEvent={isLifeEvent}
          showInterference={showInterference}
          onChange={onUpdateSymptom}
          onRemoveClick={onRemoveClick(index)}
          onOpenCollapse={onOpenCollapse(symptom)}
          options={options}
          medicalConditions={medicalConditions}
        />
      ))}
    </Box>
  );
};

export default StepTwo;
