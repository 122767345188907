import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import {
  DROPDOWN_FILTER_COLLAPSABLE_TYPE,
  FILTER_COLLAPSABLE_SECTION_TYPE,
} from '@confidant-health/lib/ui/organisms/table/filter';
// import { getProviderFilterOptions } from '../provider-fee/ProviderFee.constants';

export const tableColumns: TableGrid.TableColumn[] = [
  { id: 'sessionDate', label: 'Session Date & Time', sortable: false },
  { id: 'provider', label: 'Provider', sortable: false },
  { id: 'member', label: 'Member', sortable: false },
  { id: 'sessionType', label: 'Service', sortable: false },
  { id: 'totalAmount', label: 'Amount', sortable: false },
  // TODO: will implement daysPastDue in another ticket
  // { id: 'daysPastDue', label: 'Days past due', sortable: false },
  { id: 'dueDate', label: 'Due Date & Time', sortable: false },
  { id: 'reason', label: 'Reason', sortable: false },
  { id: 'status', label: 'Status', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const INVOICES_STATUSES = [
  { label: 'Paid', value: 'PAID' },
  { label: 'On Hold', value: 'ON_HOLD' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Write off', value: 'WRITE_OFF' },
  { label: 'Payment Failed', value: 'PAYMENT_FAILED' },
];

const getProviderFilterOptions = (providers: any[]) => {
  return {
    sectionTitle: DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS,
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DROPDOWN,
    sectionOptions: providers.map(provider => {
      return {
        label: provider.fullName,
        value: {
          name: provider.fullName,
          id: provider.providerId,
          designation: provider.designation,
          image: provider.profile,
        },
      };
    }),
  };
};

export const multiSelectFilterOptionsMock = (providers: any[]) => {
  return [
    {
      sectionTitle: 'Status',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Paid', value: 'Paid' },
        { label: 'On Hold', value: 'On Hold' },
        { label: 'Canceled', value: 'Canceled' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Write Off', value: 'Write Off' },
        { label: 'Payment Failed', value: 'Payment Failed' },
      ],
    },
    {
      sectionTitle: 'State',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Texas', value: 'Texas' },
        { label: 'Florida', value: 'Florida' },
        { label: 'Connecticut', value: 'Connecticut' },
        { label: 'Virginia', value: 'Virginia' },
        { label: 'New Hampshire', value: 'New Hampshire' },
      ],
    },
    {
      sectionTitle: 'Role',
      sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
      sectionOptions: [
        { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
        { label: 'Therapist', value: 'Therapist' },
        { label: 'Counselor', value: 'Counselor' },
        { label: 'Coach', value: 'Coach' },
        { label: 'Care Manager', value: 'Care Manager' },
        { label: 'Physician', value: 'Physician' },
      ],
    },
    getProviderFilterOptions(providers),
  ];
};
