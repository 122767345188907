import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';

import { IEntity } from 'redux/modules/state/types';

// schema
import { RegisteredEntitySchema } from './AddRegisteredEntity.schema';

// styles
import { useStyles } from './AddRegisteredEntity.styles';

interface IAddStateProps {
  isOpen: boolean;
  selectedEntity: IEntity;
  onSubmit: (payload: IEntity) => void;
  onClose: () => void;
}

const AddRegisteredEntity: FC<IAddStateProps> = ({ isOpen, selectedEntity, onSubmit, onClose }) => {
  const classes = useStyles();

  const defaultValues = {
    name: selectedEntity?.name || '',
    address1: selectedEntity?.address1 || '',
    address2: selectedEntity?.address2 || '',
    city: selectedEntity?.city || '',
    state: selectedEntity?.state || '',
    zip: selectedEntity?.zip || '',
    employerId: selectedEntity?.employerId || '',
    taxId: selectedEntity?.taxId || '',
    phone: selectedEntity?.phone || '',
    fax: selectedEntity?.fax || '',
    isActive: selectedEntity?.isActive || false,
    npi: selectedEntity?.npi || '',
    zipPlusFourCode: selectedEntity?.zipPlusFourCode || '',
  };

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: RegisteredEntitySchema,
    onSubmit: (payload: IEntity) => {
      onSubmit(payload);
    },
  });

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{selectedEntity ? 'Edit' : 'Add'} Entity</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Name')}
              <Input
                fullWidth
                name="name"
                value={values.name}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('name')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.name ? errors.name : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Address 1')}
              <Input
                fullWidth
                name="address1"
                value={values.address1}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('address1')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.address1 ? errors.address1 : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Address 2')}
              <Input
                fullWidth
                name="address2"
                value={values.address2}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('address2')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.address2 ? errors.address2 : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('City')}
              <Input
                fullWidth
                name="city"
                value={values.city}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('city')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.city ? errors.city : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('State')}
              <Input
                fullWidth
                name="state"
                value={values.state}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('state')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.state ? errors.state : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Zip')}
              <Input
                fullWidth
                name="zip"
                value={values.zip}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('zip')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.zip ? errors.zip : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Employer ID')}
              <Input
                fullWidth
                name="employerId"
                value={values.employerId}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('employerId')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.employerId ? errors.employerId : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Tax ID')}
              <Input
                fullWidth
                name="taxId"
                value={values.taxId}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('taxId')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.taxId ? errors.taxId : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Phone')}
              <Input
                fullWidth
                name="phone"
                value={values.phone}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('phone')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.phone ? errors.phone : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Fax')}
              <Input
                fullWidth
                name="fax"
                value={values.fax}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('fax')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.fax ? errors.fax : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('NPI')}
              <Input
                fullWidth
                name="npi"
                value={values.npi}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('npi')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.npi ? errors.npi : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Zip +4 code')}
              <Input
                fullWidth
                name="zipPlusFourCode"
                value={values.zipPlusFourCode}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('zipPlusFourCode')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.zipPlusFourCode ? errors.zipPlusFourCode : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Status')}
              <FormControlLabel
                control={<Checkbox />}
                checked={values.isActive}
                name="isActive"
                onChange={() => {
                  void setFieldValue('isActive', !values.isActive);
                }}
                label="Active"
              />
              <TextError errorMsg={touched.isActive ? errors.isActive : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddRegisteredEntity };
