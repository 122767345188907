import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    width: '100%',
  },
  title: {
    margin: 0,
  },
  titleClickable: {
    margin: 0,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: colors.primary600,
    },
  },
  subtitle: {
    fontSize: 12,
    lineHeight: '20px',
    color: colors.neutral500,
  },
  chartWrap: {
    position: 'relative',
    height: 'auto',
    // padding: 32,
    '& .legend-badge-value': {
      display: 'inline-block',
      marginLeft: '8px',
      fontSize: 12,
      height: 18,
      lineHeight: '16px',
      fontFamily: 'Manrope',
      fontWeight: '600',
      color: colors.neutral700,
      borderRadius: '20px',
      border: `1px solid ${colors.neutral200}`,
      padding: '1px 8px 0',
    },
    '& .apexcharts-legend.apx-legend-position-right': {
      overflow: 'hidden !important',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
    },
    '& .apexcharts-legend-marker': {
      marginRight: 16,
    },
    '& .apexcharts-legend-series': {
      margin: '0 0 8px !important',
    },
    '& .legend-wrap': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 32,
      marginLeft: 16,
      '&-left': {
        width: 200,
      },
    },
    '& .chartWrap-tooltip': {
      padding: 16,
      backgroundColor: colors.white,
      width: 'fit-content',
      '& .date': {
        fontSize: '14px !important',
        lineHeight: '20px',
        color: colors.neutral600,
      },
      '& .level': {
        color: colors.neutral900,
        fontWeight: 700,
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        whiteSpace: 'wrap',
        marginTop: 12,
        '& .value': {
          borderRadius: '50%',
          width: 22,
          height: 22,
          border: `1px solid ${colors.neutral200}`,
          fontSize: 12,
          lineHeight: '16px',
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: colors.neutral700,
          textAlign: 'center',
        },
        '& .row-left': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .dot': {
          width: 12,
          height: 12,
          borderRadius: '50%',
          marginRight: 8,
          '&.dot-0': {
            backgroundColor: colors.primary500,
          },
          '&.dot-1': {
            backgroundColor: colors.secondary500,
          },
          '&.dot-2': {
            backgroundColor: colors.neutral600,
          },
        },
      },
    },
  },
});
