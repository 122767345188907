import { FC, useEffect, useState } from 'react';
import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { Heading, Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { chartOptions } from 'pages/admin/data-collection-template-detail/components/assignments-chart-card/AssignmentsCard.utils';
import { AppointmentsFilterDates } from 'constants/CommonConstants';
// import { generateChartOptions } from './AssignmentsChartCard.utils';
import { useStyles } from './AssignmentsChartCard.styles';

// types
import { IProfileElementDetailByMonth } from '../../ProfileElementDetail.types';

interface Props {
  profileElementDetailByMonth: IProfileElementDetailByMonth[];
  isLoading?: boolean;
}

const AssignmentsChartCard: FC<Props> = ({ profileElementDetailByMonth, isLoading }) => {
  const classes = useStyles();
  const [chartDates, setChartDates] = useState<string[]>([]);
  const [chartSeriess, setChartSeriess] = useState<number[]>([]);
  const [selectedTime, setSelectedTime] = useState(AppointmentsFilterDates[2]);
  // const [selectedStatus, setSelectedStatus] = useState(AppointmentsFilterStatus[0]);
  const chartSeries: number[] = [];
  const chartDays: string[] = [];

  let filtered = [];
  const currentDate = dayjs();

  switch (selectedTime.value) {
    case 7:
      filtered = profileElementDetailByMonth.filter(item => {
        const sevenDaysAgo = currentDate.subtract(7, 'day');
        return dayjs(item.date, 'DD-MM-YYYY').isAfter(sevenDaysAgo);
      });

      break;
    case 30:
      filtered = profileElementDetailByMonth.filter(item => {
        const thirtyDaysAgo = currentDate.subtract(30, 'day');
        return dayjs(item.date, 'DD-MM-YYYY').isAfter(thirtyDaysAgo);
      });

      break;
    case 90:
      filtered = profileElementDetailByMonth.filter(item => {
        const ninetyDaysAgo = currentDate.subtract(90, 'day');
        return dayjs(item.date, 'DD-MM-YYYY').isAfter(ninetyDaysAgo);
      });

      break;
    case 180:
      filtered = profileElementDetailByMonth.filter(item => {
        const oneEightyDaysAgo = currentDate.subtract(180, 'day');
        return dayjs(item.date, 'DD-MM-YYYY').isAfter(oneEightyDaysAgo);
      });

      break;
    case 360:
      filtered = profileElementDetailByMonth.filter(item => {
        const threeSixtyDaysAgo = currentDate.subtract(360, 'day');
        return dayjs(item.date, 'DD-MM-YYYY').isAfter(threeSixtyDaysAgo);
      });

      break;
    default:
      filtered = profileElementDetailByMonth.filter(item => {
        const thirtyDaysAgo = currentDate.subtract(30, 'day');
        return dayjs(item.date, 'DD-MM-YYYY').isAfter(thirtyDaysAgo);
      });
  }

  const compareDates = (obj1, obj2) => {
    const date1 = dayjs(obj1.date, 'DD-MM-YYYY');
    const date2 = dayjs(obj2.date, 'DD-MM-YYYY');
    return date1.diff(date2);
  };

  // Sort the array of date strings
  const sortedDates = filtered.sort(compareDates);

  sortedDates.forEach(item => {
    chartSeries.push(item.count);
    chartDays.push(item.date);
  });

  const times = AppointmentsFilterDates.map(time => ({
    ...time,
    onClick: () => setSelectedTime(time),
  }));

  const hasAssignments = () => {
    if (profileElementDetailByMonth?.length > 0) {
      return true;
    }
    return false;
  };

  const hasDataInRange = () => {
    if (filtered?.length <= 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setChartSeriess(chartSeries);
    setChartDates(chartDays);
  }, [selectedTime]);

  // const statuses = [AppointmentsFilterStatus[0]].map(status => ({
  //   ...status,
  //   onClick: () => setSelectedStatus(status),
  // }));

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Assignments chart</Heading>
        <Box className={classes.headRight}>
          <Menu
            icon="chevron-down"
            items={times}
            itemsWrapperClassName={classes.menuLabel}
            className={classes.menu}
          >
            <Text className={classes.menuLabel} level={textLevel.S}>
              {selectedTime.label}
            </Text>
          </Menu>
          {/* <Menu
            icon="chevron-down"
            items={statuses}
            itemsWrapperClassName={classes.menuLabel}
            className={classes.menu}
          >
            <Text className={classes.menuLabel} level={textLevel.S}>
              {selectedStatus.label}
            </Text>
          </Menu> */}
        </Box>
      </Box>
      {isLoading ? (
        <div className={classes.loader}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </div>
      ) : hasAssignments() && hasDataInRange() ? (
        <Box className={classes.chart}>
          <ApexChart
            options={chartOptions(chartDates, 'Assignments')}
            series={[{ data: chartSeriess }]}
            type="line"
            height={330}
          />
          <Box className={classes.chartLegend}>
            <Box className={classes.circle} />
            <Text className={classes.legendText}>With any Value for Profile Element</Text>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" paddingY="90px">
          <Text weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
            {!hasAssignments() ? 'No Record Found' : 'No Record Found In This Range'}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export { AssignmentsChartCard };
