import * as yup from 'yup';

// const types = yup.object().shape({
//   amount: yup.number().min(1, 'Minimal amount is 1'),
//   unit: yup.string().required('Unit is required'),
// });

const MedicationEditSchema = yup.object({
  status: yup.string().required('Name is required'),
  notes: yup.string().required('Notes is required'),
  // dose: types,
  // frequency: types,
  // duration: types,
  // refillTimes: types,
});

export { MedicationEditSchema };
