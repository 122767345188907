// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 1,
  },
  hiddenContainer: {
    display: 'none !important',
  },
  mainContent: {
    backgroundColor: colors.neutral50,
    flex: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'calc(100vh - 80px)',
  },
  hiddenMainContent: {
    display: 'none',
  },
  publisherVideo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  subscriberVideo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  waitingScreen: {
    padding: '64px',
    flex: 1,
    width: '100%',
    gap: 64,
  },
  boxLeft: {
    flex: 1,
  },
  videoContainer: {
    position: 'relative',
    flex: 1,
    zIndex: 1,
    transition: 'all 0.3s ease-in-out',
  },
  rightPanel: {
    width: 0,
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
    backgroundColor: colors.white,
  },
  showRightPanel: {
    borderLeft: `1px solid ${colors.neutral100}`,
    width: 400,
  },
  sharePanel: {
    width: 321,
  },
  priorityPanel: {
    width: 752,
  },
  chatPanel: {
    width: 440,
  },
  inPersonRightPanel: {
    width: '100% !important',
  },
  schedulePanel: {
    width: 560,
  },
  notePanel: {
    width: 336,
  },
  checklistPanelNoVideo: {
    width: '100%',
    margin: '0 auto',
  },
  checklistPanel: {
    width: '50%',
    margin: '0 auto',
  },
  initialEvaluationPanel: {
    minWidth: 520,
    display: 'flex',
  },
  inProgressEvaluationPanel: {
    minWidth: 360,
    display: 'flex',
  },
  inProgressEvaluationPanelWidth: {
    minWidth: '50%',
    display: 'flex',
  },
  evaluationRightPanel: {
    width: 'calc(100vw - 1060px)',
  },
  evaluationRightPanelNoVideo: {
    width: '50%',
  },
  rightPanelNoVideo: {
    width: 'calc(100vw - 360px)',
  },
  video: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.neutral900,
  },
  hiddenVideo: {
    width: '0%',
    height: '0%',
    backgroundColor: colors.neutral900,
  },
  noVideo: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: colors.neutral50,
    zIndex: 1,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
  },
  username: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginTop: 24,
  },
  fullname: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  fullWidth: {
    width: '100%',
  },
  chatDrawer: {
    '& .MuiPaper-root': {
      maxWidth: '440px !important',
    },
  },
  scheduleDrawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
  },
  inPersonContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  dashboardScroll: {
    overflowY: 'auto',
  },
  flexContainer: {
    display: 'flex',
  },
  priorities: {
    minWidth: 720,
    order: 0,
    overflowY: 'auto',
  },
});
