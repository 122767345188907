import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, FieldArray } from 'formik';
import { Box } from '@mui/material';
import { Text, fontWeight, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { showSnackbar } from 'redux/modules/snackbar';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { getAuth } from 'redux/modules/auth/selectors';
import { updateProviderSchedule } from 'services/schedule/schedule.service';
import { US_TIMEZONES } from '../profile/Profile.constants';
import { TimeHeading } from '../profile/components/general-availability/time-heading/TimeHeading';
import { TimeSlot } from '../profile/components/general-availability/time-slot/TimeSlot';
import { useStyles } from '../profile/components/general-availability/GeneralAvailability.styles';

type DayAvailabilityProps = {
  currentDate: dayjs.Dayjs;
  isOpen: boolean;
  onClose: () => void;
  providerId: string;
  schedules: any;
};

const DayAvailabilityDrawer = ({
  currentDate,
  isOpen,
  onClose,
  providerId,
  schedules: defaultSchedules = [],
}: DayAvailabilityProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const authInfo = useSelector(getAuth);
  const providerSchedule = useSelector(selectProviderSchedule);

  const [selectedTimeZone, setSelectedTimeZone] = useState(
    providerSchedule?.timezone || US_TIMEZONES[0].value,
  );
  const [isLoading, setIsLoading] = useState(false);

  const onChangeTimezone = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeZone(e.target.value);
  }, []);

  // const workingDays = useMemo(
  //   () =>
  //     defaultSchedules?.reduce((prev, item) => {
  //       if (dayjs(currentDate).format('dddd') === item.day) {
  //         const convertedSlots = item.slots.map(v => ({
  //           start: dayjs(v.start).tz(selectedTimeZone).format('HH:mm'),
  //           end: dayjs(v.end).tz(selectedTimeZone).format('HH:mm'),
  //           ...insertIfObj(v?.removed, {
  //             removed: v?.removed,
  //           }),
  //         }));
  //         return {
  //           ...prev,
  //           day: prev.day ? [...prev.day, ...convertedSlots] : convertedSlots,
  //         };
  //       }
  //       return prev;
  //     }, {}),
  //   [defaultSchedules, currentDate, selectedTimeZone],
  // );

  const dailySlots = useMemo(
    () =>
      defaultSchedules
        ?.map(av => {
          return av.slots.map(slot => {
            return {
              start: dayjs(slot.start).tz(selectedTimeZone).format('HH:mm'),
              end: dayjs(slot.end).tz(selectedTimeZone).format('HH:mm'),
            };
          });
        })
        .flat(),
    [defaultSchedules, currentDate, selectedTimeZone],
  );

  useEffect(() => {
    const endOfDay = currentDate.endOf('day');
    const startOfDay = currentDate.startOf('day');
    dispatch(
      scheduleActionCreators.fetchDailySchedule({
        userId: authInfo?.meta.userId,
        queryParams: {
          startTime: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          endTime: endOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        },
      }),
    );
  }, [currentDate]);

  const onSubmit = useCallback(async values => {
    const availability = [
      {
        day: null,
        startTimeOfDay: currentDate.tz(selectedTimeZone).startOf('day'),
        slots: values.day
          ? values.day.map(slot => {
              const [startHour, startMin] = slot.start?.split(':');
              const [endHour, endMin] = slot.end?.split(':');
              return {
                start: currentDate
                  .set('hour', startHour)
                  .set('minute', startMin)
                  .second(0)
                  .millisecond(0)
                  .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                end: currentDate
                  .set('hour', endHour)
                  .set('minute', endMin)
                  .set('second', 0)
                  .second(0)
                  .millisecond(0)
                  .tz()
                  .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              };
            })
          : [],
        active: true,
      },
    ];

    try {
      setIsLoading(true);
      await updateProviderSchedule(providerId, {
        availability,
        timezone: selectedTimeZone,
      });
      const endOfDay = currentDate.endOf('day');
      const startOfDay = currentDate.startOf('day');
      dispatch(
        scheduleActionCreators.fetchDailySchedule({
          userId: authInfo?.meta.userId,
          queryParams: {
            startTime: startOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endTime: endOfDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
        }),
      );
      onClose();
    } catch (e) {
      const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!';
      dispatch(showSnackbar({ snackType: 'error', snackMessage: message }));
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        day: dailySlots,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, values, handleSubmit, errors }) => {
        return (
          <Drawer
            title={currentDate.format('dddd, MMM DD, YYYY')}
            open={isOpen}
            onClose={onClose}
            variant={drawerType.FORM}
            submitBtnTitle="Save"
            onSubmit={isValid ? handleSubmit : null}
            disableSubmit={isLoading}
          >
            <Box className={classes.drawerContent}>
              <Box>
                <Text className={classes.label} weight={fontWeight.BOLD}>
                  Select timezone
                </Text>
                <Select
                  variant={selectType.SECONDARY}
                  value={selectedTimeZone}
                  displayEmpty
                  /* emptyText="Select timezone" */
                  className={classes.select}
                  onChange={onChangeTimezone}
                  options={US_TIMEZONES}
                />
              </Box>
              <Box className={classes.daySlots}>
                <FieldArray
                  name="day"
                  render={arrayHelpers => (
                    <>
                      <TimeHeading
                        label="Availability"
                        onAddTimeSlot={() => {
                          const lastTimeSlot = values.day
                            ? values.day[values.day.length - 1]
                            : { start: '00:00', end: '00:00' };
                          arrayHelpers.insert(
                            values.day?.length || 0,
                            lastTimeSlot
                              ? {
                                  start: lastTimeSlot.end,
                                  end: lastTimeSlot.end,
                                }
                              : {
                                  start: '00:00',
                                  end: '00:00',
                                },
                          );
                        }}
                      />
                      <TextError errorMsg={typeof errors?.day === 'string' ? String(errors.day) : ''} />

                      {values.day?.map((_, index) => (
                        <Field key={`slot-${index}`}>
                          {({ field, form, meta }) => (
                            <TimeSlot
                              fieldName={`day.${index}`}
                              onRemove={() => {
                                arrayHelpers.remove(index);
                              }}
                              field={field}
                              form={form}
                              meta={meta}
                            />
                          )}
                        </Field>
                      ))}
                    </>
                  )}
                />
              </Box>
            </Box>
          </Drawer>
        );
      }}
    </Formik>
  );
};

export { DayAvailabilityDrawer };
