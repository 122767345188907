export enum SectionNames {
  NAME = 'name',
  GENERAL_INFORMATION = 'general-information',
  CONTACT_INFORMATION = 'contact-information',
  PRACTICE_LOCATIONS = 'practice-locations',
  HOSPITAL_AFFILIATIONS = 'hospital-affiliations',
  WORK_HISTORY_INFORMATION = 'work-history-information',
  REFERENCES_INFORMATION = 'references-information',
  LICENSES = 'ProfessionalLicense',
  DEA_CDS_REGISTRATION = 'DeaCdsRegistration',
  EDUCATION = 'Education',
  BOARD_CERTIFICATION = 'BoardCertification',
  INSURANCE = 'InsuranceInformation',
}

export const sectionTitles = {
  name: 'Name',
  'general-information': 'General Information',
  'contact-information': 'Contact Information',
  'practice-locations': 'Practice Locations',
  'hospital-affiliations': 'Hospital Affiliations',
  'work-history-information': 'Work History Information',
  'references-information': 'References Information',
  ProfessionalLicense: 'Professional License',
  DeaCdsRegistration: 'DEA/CDS Registration number',
  Education: 'Degree',
  BoardCertification: 'Board Certification',
  InsuranceInformation: 'Insurance Information',
};

export const sectionDescriptions = {
  name: 'Your legal name.',
  'general-information': 'Information determined at birth.',
  'contact-information': 'Your personal contact information.',
  'practice-locations': 'Practice location information.',
  'hospital-affiliations': "Hospitals that you're affiliated with or have admitting privileges to.",
  'work-history-information': "Where you're working now and have worked in the past.",
  'references-information': 'References of providers you have worked with.',
  ProfessionalLicense: 'Professional License',
  DeaCdsRegistration: 'DEA/CDS Registration number',
  Education: 'Degree',
  BoardCertification: 'Board Certification',
  InsuranceInformation: 'Insurance Information',
};

export const emptyName = {
  firstName: '',
  lastName: '',
  middleName: '',
  suffix: '',
  hasOtherNames: '',
  otherProviderName: [
    {
      firstName: '',
      lastName: '',
      middleName: '',
      startDate: '',
      endDate: '',
    },
  ],
};

export const emptyGeneralInformation = {
  gender: '',
  dateOfBirth: '',
  cityOfBirth: '',
  stateOfBirth: '',
  countryOfBirth: '',
  ssn: '',
  hasFNIN: '',
  fnin: '',
  fninCountryOfIssue: '',
};

export const emptyContactInformation = {
  homeAddress: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    country: '',
    province: '',
    county: '',
    zipCode: '',
  },
  email: '',
  cell: '',
  fax: '',
};

export const emptyReference = {
  dataSource: '',
  references: [
    {
      providerType: '',
      firstName: '',
      lastName: '',
      address: {
        city: '',
        state: '',
        country: '',
        province: '',
        zipCode: '',
      },
      email: '',
      phone: '',
      fax: '',
    },
  ],
};

export const emptyWorkHistory = {
  dataSource: '',
  gapRecord: [
    {
      startDate: '',
      endDate: '',
      gapExplanation: '',
    },
  ],
  workHistory: [
    {
      employerName: '',
      department: '',
      phone: '',
      extension: '',
      fax: '',
      hasCurrentEmployer: '',
      startDate: '',
      endDate: '',
      departureReason: '',
    },
  ],
  hasActiveMilitaryDuty: '',
  hasReserveOrNationalGuard: '',
};

export const emptyHospitalAffiliation = {
  admittingPrivileges: {
    hasAdmittingPrivileges: '',
    state: '',
    country: '',
    hospitalName: '',
    hasPrimaryHospital: '',
    status: '',
    startDate: '',
    endDate: '',
    reason: '',
    privilegeType: '',
  },
  admittingArrangements: {
    hasAdmittingArrangements: '',
    state: '',
    country: '',
    hospitalName: '',
    status: '',
    startDate: '',
    endDate: '',
    admittedForYou: '',
    firstName: '',
    lastName: '',
    hasSameSpecialty: '',
    phone: '',
    email: '',
    NPIType1: '',
    describeAdmittingArrangements: '',
  },
  nonAdmittingAffiliations: {
    hasNonAdmittingAffiliations: '',
    state: '',
    country: '',
    hospitalName: '',
    status: '',
    startDate: '',
    endDate: '',
    reason: '',
    describeNonAdmittingAffiliations: '',
  },
};

export const emptyPracticeLocation = {
  confirmedDate: undefined,
  officeType: '',
  providerStartDate: undefined,
  practiceAtThisLocation: undefined,
  explain: undefined,
  providerDirectoryClassification: undefined,
  specialty: undefined,
  subSpecialty: undefined,
  hasContinuePracticeInCurrentLocation: undefined,
  servicesTypes: undefined,
  narrativeDescription: undefined,
  practiceName: undefined,
  address: {
    street1: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    zipCode: undefined,
  },
  email: undefined,
  hasGeneralCorrespondence: undefined,
  practiceLocationWebsite: undefined,
  appointmentSchedulingWebsite: undefined,
  hasSameMailingAddress: undefined,
  mailingAddress: {
    street1: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    zipCode: undefined,
  },
  typesOfPractice: undefined,
  organizationNPIType2: {
    hasDifferentGroupName: undefined,
    groupName: undefined,
    npiNumber: undefined,
  },
  groupMedicaidNumber: undefined,
  groupMedicareNumber: undefined,
  phoneNumber: {
    appointmentPhone: undefined,
    extension: undefined,
    fax: undefined,
    backOfficePhone: undefined,
  },
  phoneCoverage: {
    hasFullDayPhoneCoverage: undefined,
    coverageType: undefined,
  },
  taxInformation: {
    taxId: undefined,
    taxType: undefined,
    hasPrimaryTaxId: undefined,
    groupName: undefined,
  },
  hasPlanClosePractice: undefined,
  officeHours: [
    {
      dayOfWeek: undefined,
      startTime: undefined,
      endTime: undefined,
    },
  ],
  patients: {
    hasAcceptNewPatient: undefined,
    hasAcceptExistingPatient: undefined,
    hasAcceptAllNewPatient: undefined,
    hasAcceptNewMedicarePatient: undefined,
    hasAcceptNewMedicaidPatient: undefined,
    hasAcceptNewPatientByReferrals: undefined,
    determineAppropriatenessOfReferrals: undefined,
    hasInformationVaryByPlan: undefined,
  },
  hasPartners: undefined,
  coveringColleagues: undefined,
  hasMidLevelPractitioner: undefined,
  businessStaffContact: {
    firstName: undefined,
    lastName: undefined,
    middleName: undefined,
    suffix: undefined,
    phone: undefined,
    fax: undefined,
    email: undefined,
    hasOfficeManagerContact: undefined,
  },
  billingContact: {
    hasSameContact: undefined,
    firstName: undefined,
    lastName: undefined,
    middleName: undefined,
    address: {
      street1: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      zipCode: undefined,
    },
    phone: undefined,
    fax: undefined,
    email: undefined,
  },
  paymentRemittance: {
    departmentName: undefined,
    payableTo: undefined,
    hasElectronicBilling: undefined,
    hasSameContact: undefined,
    address: {
      street1: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      zipCode: undefined,
    },
    phone: undefined,
    fax: undefined,
    email: undefined,
  },
  accessibility: {
    hasADA: undefined,
    hasHandicapped: undefined,
  },
  handicappedAccessibilityRequirement: {
    hasExteriorBuilding: undefined,
    hasInteriorBuilding: undefined,
    hasWheelChairAccess: undefined,
    hasExamTable: undefined,
    hasStretchers: undefined,
    hasPortableLifts: undefined,
    hasRadiologicEquipment: undefined,
    hasSignage: undefined,
    hasParking: undefined,
    hasRestroom: undefined,
    hasOtherHandicappedAccess: undefined,
    hasOtherServices: undefined,
  },
  otherServices: {
    hasTextTelephony: undefined,
    americanSignLanguage: undefined,
    impairmentServices: undefined,
    hasPublicTransportation: undefined,
  },
  publicTransportation: {
    hasBus: undefined,
    hasSubway: undefined,
    hasRegionalTrain: undefined,
    hasOtherTransportation: undefined,
    hasChildCareServices: undefined,
    hasSafetySanitationRequirements: undefined,
    hasTTD: undefined,
    hasWorkersCompensation: undefined,
    hasStaffTrained: undefined,
    hasActivelyEvaluated: undefined,
    hasUrgentWalkIns: undefined,
  },
  telehealth: {
    hasTelehealth: undefined,
    hasHIPAACompliant: undefined,
    serviceType: undefined,
    hasAudio: undefined,
    hasAudioAndVideo: undefined,
    hasSecureTextMessaging: undefined,
    hasRemoteMonitoring: undefined,
    hasStoreAndForward: undefined,
    hasSupportFamilyGiver: undefined,
  },
  services: {
    hasLaboratory: undefined,
    accreditingProgram: undefined,
    hasRadiology: undefined,
    hasXray: undefined,
    xrayCertificationType: undefined,
    hasEKG: undefined,
    hasCareOfMinorsLacerations: undefined,
    hasPulmonaryFunctionTesting: undefined,
    hasAllergyInjections: undefined,
    hasAllergySkinTesting: undefined,
    hasOfficeGynecology: undefined,
    hasDrawingBlood: undefined,
    hasAsthmaTreatment: undefined,
    hasAgeAppropriateImmunizations: undefined,
    hasFlexibleSigmoidoscopy: undefined,
    hasTympanometryScreening: undefined,
    hasOsteopathicManipulation: undefined,
    hasIVhydrationTreatment: undefined,
    hasCardiacStressTest: undefined,
    hasPhysicalTherapy: undefined,
    hasTreadmill: undefined,
    hasAnesthesiaAdministered: undefined,
    classOfAnesthesia: undefined,
    anesthesiaAdministeredBy: undefined,
    anesthesiaAdministeredByFirstName: undefined,
    anesthesiaAdministeredByLastName: undefined,
    otherServices: undefined,
    practitionerSpecialSkills: [{ skills: '' }],
    staffSpecialSkills: [{ skills: '' }],
    staffNonEnglishLanguage: [{ lang: '' }],
    employeeType: undefined,
    hasInterpreters: undefined,
  },
};

export const emptyArrays = {
  otherProviderName: emptyName.otherProviderName,
  gapRecord: emptyWorkHistory.gapRecord,
  workHistory: emptyWorkHistory.workHistory,
  'activeLocation.services.practitionerSpecialSkills':
    emptyPracticeLocation.services.practitionerSpecialSkills,
  'activeLocation.services.staffSpecialSkills': emptyPracticeLocation.services.staffSpecialSkills,
  'activeLocation.services.staffNonEnglishLanguage': emptyPracticeLocation.services.staffNonEnglishLanguage,
};

export const specialItemKeys = {
  activeLocation: {
    labelKey: 'practiceName',
    hidden: false,
  },
  archivedLocation: {
    labelKey: 'practiceName',
    hidden: true,
  },
};

export const namePriorities = {
  firstName: 1,
  lastName: 2,
  middleName: 3,
  suffix: 4,
  hasOtherNames: 5,
  otherProviderName: 6,
};

export const contactPriorities = {
  email: 1,
  cell: 2,
  fax: 3,
  'homeAddress.street1': 4,
  'homeAddress.street2': 5,
  'homeAddress.city': 6,
  'homeAddress.state': 7,
  'homeAddress.country': 8,
  'homeAddress.province': 9,
  'homeAddress.county': 10,
  'homeAddress.zipCode': 11,
};

export const generalInformationPriorities = {
  gender: 1,
  dateOfBirth: 2,
  cityOfBirth: 3,
  stateOfBirth: 4,
  countryOfBirth: 5,
  ssn: 6,
  hasFNIN: 7,
  fnin: 8,
  fninCountryOfIssue: 9,
};

export const practiceLocationPriorities = {
  'activeLocation.confirmedDate': 1,
  'activeLocation.practiceName': 2,
  'activeLocation.address.street1': 3,
  'activeLocation.address.city': 4,
  'activeLocation.address.state': 5,
  'activeLocation.address.country': 6,
  'activeLocation.address.zipCode': 7,
  'activeLocation.email': 8,
  'activeLocation.hasGeneralCorrespondence': 9,
  'activeLocation.practiceLocationWebsite': 10,
  'activeLocation.appointmentSchedulingWebsite': 11,
  'activeLocation.officeHours': 12,
  'activeLocation.officeType': 15,
  'activeLocation.providerStartDate': 16,
  'activeLocation.practiceAtThisLocation': 17,
  'activeLocation.explain': 18,
  'activeLocation.specialty': 19,
  'activeLocation.subSpecialty': 20,
  'activeLocation.hasContinuePracticeInCurrentLocation': 21,
  'activeLocation.servicesTypes': 22,
  'activeLocation.narrativeDescription': 23,
  'activeLocation.hasSameMailingAddress': 24,
  'activeLocation.mailingAddress.street1': 25,
  'activeLocation.mailingAddress.city': 26,
  'activeLocation.mailingAddress.state': 27,
  'activeLocation.mailingAddress.country': 28,
  'activeLocation.mailingAddress.zipCode': 29,
  'activeLocation.billingContact.hasSameContact': 30,
  'activeLocation.billingContact.firstName': 31,
  'activeLocation.billingContact.lastName': 32,
  'activeLocation.billingContact.middleName': 33,
  'activeLocation.billingContact.address.street1': 34,
  'activeLocation.billingContact.address.city': 35,
  'activeLocation.billingContact.address.state': 36,
  'activeLocation.billingContact.address.country': 37,
  'activeLocation.billingContact.address.zipCode': 38,
  'activeLocation.billingContact.phone': 39,
  'activeLocation.billingContact.fax': 40,
  'activeLocation.billingContact.email': 41,
  'activeLocation.businessStaffContact.hasOfficeManagerContact': 42,
  'activeLocation.businessStaffContact.firstName': 43,
  'activeLocation.businessStaffContact.lastName': 44,
  'activeLocation.businessStaffContact.middleName': 45,
  'activeLocation.businessStaffContact.suffix': 46,
  'activeLocation.businessStaffContact.phone': 47,
  'activeLocation.businessStaffContact.fax': 48,
  'activeLocation.businessStaffContact.email': 49,
  'activeLocation.taxInformation.hasPrimaryTaxId': 50,
  'activeLocation.taxInformation.taxId': 51,
  'activeLocation.taxInformation.taxType': 52,
  'activeLocation.taxInformation.groupName': 53,
  'activeLocation.paymentRemittance.departmentName': 54,
  'activeLocation.paymentRemittance.hasElectronicBilling': 55,
  'activeLocation.paymentRemittance.payableTo': 56,
  'activeLocation.paymentRemittance.hasSameContact': 57,
  'activeLocation.paymentRemittance.address.street1': 58,
  'activeLocation.paymentRemittance.address.city': 59,
  'activeLocation.paymentRemittance.address.state': 60,
  'activeLocation.paymentRemittance.address.country': 61,
  'activeLocation.paymentRemittance.address.zipCode': 62,
  'activeLocation.paymentRemittance.phone': 63,
  'activeLocation.paymentRemittance.fax': 64,
  'activeLocation.paymentRemittance.email': 65,
  'activeLocation.organizationNPIType2.hasDifferentGroupName': 66,
  'activeLocation.organizationNPIType2.groupName': 67,
  'activeLocation.organizationNPIType2.npiNumber': 68,
  'activeLocation.telehealth.hasTelehealth': 69,
  'activeLocation.telehealth.serviceType': 70,
  'activeLocation.telehealth.hasHIPAACompliant': 71,
  'activeLocation.telehealth.hasAudio': 72,
  'activeLocation.telehealth.hasAudioAndVideo': 73,
  'activeLocation.telehealth.hasSecureTextMessaging': 74,
  'activeLocation.telehealth.hasRemoteMonitoring': 75,
  'activeLocation.telehealth.hasStoreAndForward': 76,
  'activeLocation.telehealth.hasSupportFamilyGiver': 77,
  'activeLocation.services.hasLaboratory': 78,
  'activeLocation.services.accreditingProgram': 79,
  'activeLocation.services.hasXray': 80,
  'activeLocation.services.xrayCertificationType': 81,
  'activeLocation.services.hasAnesthesiaAdministered': 82,
  'activeLocation.services.classOfAnesthesia': 83,
  'activeLocation.services.anesthesiaAdministeredBy': 84,
  'activeLocation.services.anesthesiaAdministeredByFirstName': 85,
  'activeLocation.services.anesthesiaAdministeredByLastName': 86,
  'activeLocation.otherServices.hasAmericanSignLanguage': 87,
  'activeLocation.otherServices.hasImpairmentServices': 88,
  'activeLocation.providerDirectoryClassification': 89,
  'activeLocation.typesOfPractice': 90,
  'activeLocation.groupMedicaidNumber': 91,
  'activeLocation.groupMedicareNumber': 92,
  'activeLocation.phoneNumber.appointmentPhone': 93,
  'activeLocation.phoneNumber.extension': 94,
  'activeLocation.phoneNumber.fax': 95,
  'activeLocation.phoneNumber.backOfficePhone': 96,
  'activeLocation.phoneCoverage.hasFullDayPhoneCoverage': 97,
  'activeLocation.phoneCoverage.coverageType': 98,
  'activeLocation.hasPlanClosePractice': 99,
  'activeLocation.patients.hasAcceptNewPatient': 100,
  'activeLocation.patients.hasAcceptExistingPatient': 101,
  'activeLocation.patients.hasAcceptAllNewPatient': 102,
  'activeLocation.patients.hasAcceptNewMedicarePatient': 103,
  'activeLocation.patients.hasAcceptNewMedicaidPatient': 104,
  'activeLocation.patients.hasAcceptNewPatientByReferrals': 105,
  'activeLocation.patients.determineAppropriatenessOfReferrals': 106,
  'activeLocation.patients.hasInformationVaryByPlan': 107,
  'activeLocation.hasPartners': 108,
  'activeLocation.coveringColleagues': 109,
  'activeLocation.hasMidLevelPractitioner': 110,
  'activeLocation.limitations.hasPracticeLimitations': 111,
  'activeLocation.limitations.hasGenderLimitation': 112,
  'activeLocation.limitations.hasAgeLimitation': 113,
  'activeLocation.limitations.otherLimitation': 114,
  'activeLocation.accessibility.hasADA': 115,
  'activeLocation.accessibility.hasHandicapped': 116,
  'activeLocation.handicappedAccessibilityRequirement.hasExteriorBuilding': 117,
  'activeLocation.handicappedAccessibilityRequirement.hasInteriorBuilding': 118,
  'activeLocation.handicappedAccessibilityRequirement.hasWheelChairAccess': 119,
  'activeLocation.handicappedAccessibilityRequirement.hasExamTable': 120,
  'activeLocation.handicappedAccessibilityRequirement.hasStretchers': 121,
  'activeLocation.handicappedAccessibilityRequirement.hasPortableLifts': 122,
  'activeLocation.handicappedAccessibilityRequirement.hasRadiologicEquipment': 123,
  'activeLocation.handicappedAccessibilityRequirement.hasSignage': 124,
  'activeLocation.handicappedAccessibilityRequirement.hasParking': 125,
  'activeLocation.handicappedAccessibilityRequirement.hasRestroom': 126,
  'activeLocation.handicappedAccessibilityRequirement.hasOtherHandicappedAccess': 127,
  'activeLocation.handicappedAccessibilityRequirement.hasOtherServices': 128,
  'activeLocation.otherServices.hasTextTelephony': 129,
  'activeLocation.otherServices.americanSignLanguage': 130,
  'activeLocation.otherServices.impairmentServices': 131,
  'activeLocation.otherServices.hasPublicTransportation': 132,
  'activeLocation.publicTransportation.hasBus': 133,
  'activeLocation.publicTransportation.hasSubway': 134,
  'activeLocation.publicTransportation.hasRegionalTrain': 135,
  'activeLocation.publicTransportation.hasOtherTransportation': 136,
  'activeLocation.publicTransportation.hasChildCareServices': 137,
  'activeLocation.publicTransportation.hasSafetySanitationRequirements': 138,
  'activeLocation.publicTransportation.hasTTD': 139,
  'activeLocation.publicTransportation.hasWorkersCompensation': 140,
  'activeLocation.publicTransportation.hasStaffTrained': 141,
  'activeLocation.publicTransportation.hasActivelyEvaluated': 142,
  'activeLocation.publicTransportation.hasUrgentWalkIns': 143,
  'activeLocation.services.hasRadiology': 144,
  'activeLocation.services.hasEKG': 145,
  'activeLocation.services.hasCareOfMinorsLacerations': 146,
  'activeLocation.services.hasPulmonaryFunctionTesting': 147,
  'activeLocation.services.hasAllergyInjections': 148,
  'activeLocation.services.hasAllergySkinTesting': 149,
  'activeLocation.services.hasOfficeGynecology': 150,
  'activeLocation.services.hasDrawingBlood': 151,
  'activeLocation.services.hasAsthmaTreatment': 152,
  'activeLocation.services.hasAgeAppropriateImmunizations': 153,
  'activeLocation.services.hasFlexibleSigmoidoscopy': 154,
  'activeLocation.services.hasTympanometryScreening': 155,
  'activeLocation.services.hasOsteopathicManipulation': 156,
  'activeLocation.services.hasIVhydrationTreatment': 157,
  'activeLocation.services.hasCardiacStressTest': 158,
  'activeLocation.services.hasPhysicalTherapy': 159,
  'activeLocation.services.hasTreadmill': 160,
  'activeLocation.services.otherServices': 161,
  'activeLocation.services.employeeType': 162,
  'activeLocation.services.hasInterpreters': 163,
  'activeLocation.services.practitionerSpecialSkills': 164,
  'activeLocation.services.staffSpecialSkills': 165,
  'activeLocation.services.staffNonEnglishLanguage': 166,
};

export const hospitalAffiliationPriorities = {
  'admittingPrivileges.hasAdmittingPrivileges': 1,
  'admittingPrivileges.state': 2,
  'admittingPrivileges.country': 3,
  'admittingPrivileges.hospitalName': 4,
  'admittingPrivileges.hasPrimaryHospital': 5,
  'admittingPrivileges.status': 6,
  'admittingPrivileges.startDate': 7,
  'admittingPrivileges.endDate': 8,
  'admittingPrivileges.reason': 9,
  'admittingPrivileges.privilegeType': 10,
  'admittingArrangements.hasAdmittingArrangements': 11,
  'admittingArrangements.state': 12,
  'admittingArrangements.country': 13,
  'admittingArrangements.hospitalName': 14,
  'admittingArrangements.status': 15,
  'admittingArrangements.startDate': 16,
  'admittingArrangements.endDate': 17,
  'admittingArrangements.admittedForYou': 18,
  'admittingArrangements.firstName': 19,
  'admittingArrangements.lastName': 20,
  'admittingArrangements.hasSameSpecialty': 21,
  'admittingArrangements.phone': 22,
  'admittingArrangements.email': 23,
  'admittingArrangements.NPIType1': 24,
  'admittingArrangements.describeAdmittingArrangements': 25,
  'nonAdmittingAffiliations.hasNonAdmittingAffiliations': 26,
  'nonAdmittingAffiliations.state': 27,
  'nonAdmittingAffiliations.country': 28,
  'nonAdmittingAffiliations.hospitalName': 29,
  'nonAdmittingAffiliations.status': 30,
  'nonAdmittingAffiliations.startDate': 31,
  'nonAdmittingAffiliations.endDate': 32,
  'nonAdmittingAffiliations.reason': 33,
  'nonAdmittingAffiliations.describeNonAdmittingAffiliations': 34,
};

export const workHistoryPriorities = {
  hasActiveMilitaryDuty: 1,
  hasReserveOrNationalGuard: 2,
  gapRecord: 3,
  workHistory: 4,
};

export const referencesPriorities = {
  references: 1,
};

export const priorities = {
  name: namePriorities,
  'general-information': generalInformationPriorities,
  'contact-information': contactPriorities,
  'practice-locations': practiceLocationPriorities,
  'hospital-affiliations': hospitalAffiliationPriorities,
  'work-history-information': workHistoryPriorities,
  'references-information': referencesPriorities,
};

export const dataSectionLabels = {
  otherProviderName: 'OTHER NAME',
  gapRecord: 'GAP RECORD',
  workHistory: 'WORK HISTORY',
  references: 'REFERENCE',
  'activeLocation.services.practitionerSpecialSkills': 'PRACTITIONER SPECIAL SKILL',
  'activeLocation.services.staffSpecialSkills': 'STAFF SPECIAL SKILL',
  'activeLocation.services.staffNonEnglishLanguage': 'STAFF NON ENGLISH LANGUAGE',
  'activeLocation.officeHours': 'OFFICE HOURS',
};

export const dataLabels = {
  // name labels
  hasOtherNames: 'Has other names',
  firstName: 'First name',
  lastName: 'Last name',
  middleName: 'Middle name',
  suffix: 'Suffix',
  'otherProviderName.firstName': 'Other first name',
  'otherProviderName.lastName': 'Other last name',
  'otherProviderName.middleName': 'Other middle name',
  'otherProviderName.startDate': 'Date started using other name',
  'otherProviderName.endDate': 'Date stopped using other name',

  // contact information labels

  email: 'Email',
  cell: 'Phone',
  fax: 'Fax',
  'homeAddress.street1': 'Street address',
  'homeAddress.street2': 'Street address line 2',
  'homeAddress.city': 'City',
  'homeAddress.state': 'State',
  'homeAddress.country': 'Country',
  'homeAddress.province': 'Province',
  'homeAddress.county': 'County',
  'homeAddress.zipCode': 'ZIP code',

  // general information label

  gender: 'Gender',
  dateOfBirth: 'Date of birth',
  cityOfBirth: 'City of birth',
  stateOfBirth: 'State of birth',
  countryOfBirth: 'Country of birth',
  ssn: 'SSN',
  hasFNIN: 'Has FNIN',
  fnin: 'Foreign national identification number (FNIN)',
  fninCountryOfIssue: 'FNIN country of issue',

  // practice location labels

  'activeLocation.otherServices.hasAmericanSignLanguage': 'Has American Sign Language',
  'activeLocation.otherServices.hasImpairmentServices': 'Has Impairment Services',
  'activeLocation.confirmedDate': 'Confirmed date',
  'activeLocation.officeType': 'Office type',
  'activeLocation.providerStartDate': 'Provider start date',
  'activeLocation.practiceAtThisLocation': 'Practice at this location',
  'activeLocation.explain': 'Explanation',
  'activeLocation.providerDirectoryClassification': 'Provider directory classification',
  'activeLocation.specialty': 'Specialty',
  'activeLocation.subSpecialty': 'Sub-specialty',
  'activeLocation.hasContinuePracticeInCurrentLocation': 'Continue practice in current location',
  'activeLocation.servicesTypes': 'Services types',
  'activeLocation.narrativeDescription': 'Narrative description',
  'activeLocation.practiceName': 'Practice name',
  'activeLocation.address.street1': 'Street address',
  'activeLocation.address.city': 'City',
  'activeLocation.address.state': 'State',
  'activeLocation.address.country': 'Country',
  'activeLocation.address.zipCode': 'ZIP code',
  'activeLocation.email': 'Email',
  'activeLocation.hasGeneralCorrespondence': 'General correspondence',
  'activeLocation.practiceLocationWebsite': 'Practice location website',
  'activeLocation.appointmentSchedulingWebsite': 'Appointment scheduling website',
  'activeLocation.hasSameMailingAddress': 'Mailing address same as home',
  'activeLocation.mailingAddress.street1': 'Mailing street address',
  'activeLocation.mailingAddress.city': 'Mailing city',
  'activeLocation.mailingAddress.state': 'Mailing state',
  'activeLocation.mailingAddress.country': 'Mailing country',
  'activeLocation.mailingAddress.zipCode': 'Mailing zip code',
  'activeLocation.typesOfPractice': 'Types of practice',
  'activeLocation.organizationNPIType2.hasDifferentGroupName': 'Has different group name',
  'activeLocation.organizationNPIType2.groupName': 'Group name',
  'activeLocation.organizationNPIType2.npiNumber': 'NPI number',
  'activeLocation.groupMedicaidNumber': 'Medicaid number',
  'activeLocation.groupMedicareNumber': 'Medicare number',
  'activeLocation.phoneNumber.appointmentPhone': 'Appointment phone number',
  'activeLocation.phoneNumber.extension': 'Phone extension',
  'activeLocation.phoneNumber.fax': 'Fax number',
  'activeLocation.phoneNumber.backOfficePhone': 'Back office phone number',
  'activeLocation.phoneCoverage.hasFullDayPhoneCoverage': 'Full day phone coverage',
  'activeLocation.phoneCoverage.coverageType': 'Phone coverage type',
  'activeLocation.taxInformation.taxId': 'Tax id',
  'activeLocation.taxInformation.taxType': 'Tax type',
  'activeLocation.taxInformation.hasPrimaryTaxId': 'Has primary tax id',
  'activeLocation.taxInformation.groupName': 'Tax information group name',
  'activeLocation.hasPlanClosePractice': 'Plan to close practice',
  'activeLocation.patients.hasAcceptNewPatient': 'Accept new patients',
  'activeLocation.patients.hasAcceptExistingPatient': 'Accept existing patients',
  'activeLocation.patients.hasAcceptAllNewPatient': 'Accept all new patients',
  'activeLocation.patients.hasAcceptNewMedicarePatient': 'Accept new medicare patients',
  'activeLocation.patients.hasAcceptNewMedicaidPatient': 'Accept new medicaid patients',
  'activeLocation.patients.hasAcceptNewPatientByReferrals': 'Accept new patients by referrals',
  'activeLocation.patients.determineAppropriatenessOfReferrals':
    'What questions should we ask a patient, to help determine the appropriateness of the referral?',
  'activeLocation.patients.hasInformationVaryByPlan': 'Information varies by plan',
  'activeLocation.hasPartners': 'Has partners',
  'activeLocation.coveringColleagues': 'Covering colleagues',
  'activeLocation.hasMidLevelPractitioner': 'Has mid-level practitioner',
  'activeLocation.businessStaffContact.firstName': 'Business staff first name',
  'activeLocation.businessStaffContact.lastName': 'Business staff last name',
  'activeLocation.businessStaffContact.middleName': 'Business staff middle name',
  'activeLocation.businessStaffContact.suffix': 'Business staff suffix',
  'activeLocation.businessStaffContact.phone': 'Business staff phone number',
  'activeLocation.businessStaffContact.fax': 'Business staff fax number',
  'activeLocation.businessStaffContact.email': 'Business staff email',
  'activeLocation.businessStaffContact.hasOfficeManagerContact': 'Business staff has office manager contact',
  'activeLocation.billingContact.hasSameContact': 'Billing contact has same contact',
  'activeLocation.billingContact.firstName': 'Billing contact first name',
  'activeLocation.billingContact.lastName': 'Billing contact last name',
  'activeLocation.billingContact.middleName': 'Billing contact middle name',
  'activeLocation.billingContact.address.street1': 'Billing contact street address',
  'activeLocation.billingContact.address.city': 'Billing contact city',
  'activeLocation.billingContact.address.state': 'Billing contact state',
  'activeLocation.billingContact.address.country': 'Billing contact country',
  'activeLocation.billingContact.address.zipCode': 'Billing contact ZIP code',
  'activeLocation.billingContact.phone': 'Billing contact phone number',
  'activeLocation.billingContact.fax': 'Billing contact fax number',
  'activeLocation.billingContact.email': 'Billing contact email',
  'activeLocation.paymentRemittance.departmentName': 'Payment remittance department name',
  'activeLocation.paymentRemittance.payableTo': 'Payment remittance payable to',
  'activeLocation.paymentRemittance.hasElectronicBilling': 'Payment remittance has electronic billing',
  'activeLocation.paymentRemittance.hasSameContact': 'Payment remittance has same contact',
  'activeLocation.paymentRemittance.address.street1': 'Payment remittance street address',
  'activeLocation.paymentRemittance.address.city': 'Payment remittance city',
  'activeLocation.paymentRemittance.address.state': 'Payment remittance state',
  'activeLocation.paymentRemittance.address.country': 'Payment remittance country',
  'activeLocation.paymentRemittance.address.zipCode': 'Payment remittance ZIP code',
  'activeLocation.paymentRemittance.phone': 'Payment remittance phone number',
  'activeLocation.paymentRemittance.fax': 'Payment remittance fax number',
  'activeLocation.paymentRemittance.email': 'Payment remittance email',
  'activeLocation.limitations.hasPracticeLimitations': 'Has practice limitations',
  'activeLocation.limitations.hasGenderLimitation': 'Has gender limitation',
  'activeLocation.limitations.hasAgeLimitation': 'Has age limitation',
  'activeLocation.limitations.otherLimitation': 'Other limitations',
  'activeLocation.accessibility.hasADA': 'Has ADA accessibility',
  'activeLocation.accessibility.hasHandicapped': 'Has handicapped accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasExteriorBuilding':
    'Has exterior building accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasInteriorBuilding':
    'Has interior building accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasWheelChairAccess': 'Has wheelchair access',
  'activeLocation.handicappedAccessibilityRequirement.hasExamTable': 'Has exam table accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasStretchers': 'Has stretchers accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasPortableLifts': 'Has portable lifts accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasRadiologicEquipment':
    'Has radiologic equipment accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasSignage': 'Has signage accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasParking': 'Has parking accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasRestroom': 'Has restroom accessibility',
  'activeLocation.handicappedAccessibilityRequirement.hasOtherHandicappedAccess':
    'Has other handicapped access',
  'activeLocation.handicappedAccessibilityRequirement.hasOtherServices': 'Has other handicapped services',
  'activeLocation.otherServices.hasTextTelephony': 'Has text telephony',
  'activeLocation.otherServices.americanSignLanguage': 'American sign language services',
  'activeLocation.otherServices.impairmentServices': 'Impairment services',
  'activeLocation.otherServices.hasPublicTransportation': 'Has public transportation',
  'activeLocation.publicTransportation.hasBus': 'Has bus transportation',
  'activeLocation.publicTransportation.hasSubway': 'Has subway transportation',
  'activeLocation.publicTransportation.hasRegionalTrain': 'Has regional train transportation',
  'activeLocation.publicTransportation.hasOtherTransportation': 'Has other transportation',
  'activeLocation.publicTransportation.hasChildCareServices': 'Public transport has child care services',
  'activeLocation.publicTransportation.hasSafetySanitationRequirements':
    'Public transport has safety and sanitation requirements',
  'activeLocation.publicTransportation.hasTTD': 'Public transport has TTD',
  'activeLocation.publicTransportation.hasWorkersCompensation': 'Public transport has workers compensation',
  'activeLocation.publicTransportation.hasStaffTrained': 'Has staff trained for public transportation',
  'activeLocation.publicTransportation.hasActivelyEvaluated': 'Has actively evaluated public transportation',
  'activeLocation.publicTransportation.hasUrgentWalkIns': 'Public transport for urgent walk-ins',
  'activeLocation.telehealth.hasTelehealth': 'Has telehealth services',
  'activeLocation.telehealth.hasHIPAACompliant': 'Telehealth services HIPAA compliant',
  'activeLocation.telehealth.serviceType': 'Telehealth service type',
  'activeLocation.telehealth.hasAudio': 'Telehealth services include audio',
  'activeLocation.telehealth.hasAudioAndVideo': 'Telehealth services include audio and video',
  'activeLocation.telehealth.hasSecureTextMessaging': 'Telehealth services include secure text messaging',
  'activeLocation.telehealth.hasRemoteMonitoring': 'Telehealth services include remote monitoring',
  'activeLocation.telehealth.hasStoreAndForward': 'Telehealth services include store and forward',
  'activeLocation.telehealth.hasSupportFamilyGiver': 'Telehealth services support family givers',
  'activeLocation.services.hasLaboratory': 'Has laboratory services',
  'activeLocation.services.accreditingProgram': 'Laboratory services accrediting program',
  'activeLocation.services.hasRadiology': 'Has radiology services',
  'activeLocation.services.hasXray': 'Has x-ray services',
  'activeLocation.services.xrayCertificationType': 'X-ray certification type',
  'activeLocation.services.hasEKG': 'Has EKG services',
  'activeLocation.services.hasCareOfMinorsLacerations': 'Has care of minors lacerations services',
  'activeLocation.services.hasPulmonaryFunctionTesting': 'Has pulmonary function testing services',
  'activeLocation.services.hasAllergyInjections': 'Has allergy injections services',
  'activeLocation.services.hasAllergySkinTesting': 'Has allergy skin testing services',
  'activeLocation.services.hasOfficeGynecology': 'Has office gynecology services',
  'activeLocation.services.hasDrawingBlood': 'Has drawing blood services',
  'activeLocation.services.hasAsthmaTreatment': 'Has asthma treatment services',
  'activeLocation.services.hasAgeAppropriateImmunizations': 'Has age appropriate immunizations services',
  'activeLocation.services.hasFlexibleSigmoidoscopy': 'Has flexible sigmoidoscopy services',
  'activeLocation.services.hasTympanometryScreening': 'Has tympanometry screening services',
  'activeLocation.services.hasOsteopathicManipulation': 'Has osteopathic manipulation services',
  'activeLocation.services.hasIVhydrationTreatment': 'Has IV hydration treatment services',
  'activeLocation.services.hasCardiacStressTest': 'Has cardiac stress test services',
  'activeLocation.services.hasPhysicalTherapy': 'Has physical therapy services',
  'activeLocation.services.hasTreadmill': 'Has treadmill services',
  'activeLocation.services.hasAnesthesiaAdministered': 'Has anesthesia administered services',
  'activeLocation.services.classOfAnesthesia': 'Class of anesthesia',
  'activeLocation.services.anesthesiaAdministeredBy': 'Anesthesia administered by',
  'activeLocation.services.anesthesiaAdministeredByFirstName': 'Anesthesia administered by first name',
  'activeLocation.services.anesthesiaAdministeredByLastName': 'Anesthesia administered by last name',
  'activeLocation.services.otherServices': 'Other services',
  'activeLocation.services.employeeType': 'Employee type',
  'activeLocation.services.hasInterpreters': 'Has interpreters services',
  'activeLocation.services.practitionerSpecialSkills.skills': 'Skill',
  'activeLocation.services.staffSpecialSkills.skills': 'Skill',
  'activeLocation.services.staffNonEnglishLanguage.lang': 'Language',
  'activeLocation.officeHours.dayOfWeek': 'Day of week',
  'activeLocation.officeHours.startTime': 'Start time',
  'activeLocation.officeHours.endTime': 'End time',

  // hospitalAffiliationsLabels
  'admittingPrivileges.hasAdmittingPrivileges': 'Has admitting privileges',
  'admittingPrivileges.state': 'Admitting privileges state',
  'admittingPrivileges.country': 'Admitting privileges country',
  'admittingPrivileges.hospitalName': 'Admitting privileges hospital name',
  'admittingPrivileges.hasPrimaryHospital': 'Has primary hospital privileges',
  'admittingPrivileges.status': 'Admitting privileges status',
  'admittingPrivileges.startDate': 'Admitting privileges start date',
  'admittingPrivileges.endDate': 'Admitting privileges end date',
  'admittingPrivileges.reason': 'Reason for admitting privileges',
  'admittingPrivileges.privilegeType': 'Admitting privilege type',
  'admittingArrangements.hasAdmittingArrangements': 'Has admitting arrangements',
  'admittingArrangements.state': 'Admitting arrangements state',
  'admittingArrangements.country': 'Admitting arrangements country',
  'admittingArrangements.hospitalName': 'Admitting arrangements hospital name',
  'admittingArrangements.status': 'Admitting arrangements status',
  'admittingArrangements.startDate': 'Admitting arrangements start date',
  'admittingArrangements.endDate': 'Admitting arrangements end date',
  'admittingArrangements.admittedForYou': 'Admitted for you',
  'admittingArrangements.firstName': 'Admitting arrangements first name',
  'admittingArrangements.lastName': 'Admitting arrangements last name',
  'admittingArrangements.hasSameSpecialty': 'Has same specialty',
  'admittingArrangements.phone': 'Admitting arrangements phone',
  'admittingArrangements.email': 'Admitting arrangements email',
  'admittingArrangements.NPIType1': 'Admitting arrangements NPI type',
  'admittingArrangements.describeAdmittingArrangements': 'Describe admitting arrangements',
  'nonAdmittingAffiliations.hasNonAdmittingAffiliations': 'Has non-admitting affiliations',
  'nonAdmittingAffiliations.state': 'Non-admitting affiliations state',
  'nonAdmittingAffiliations.country': 'Non-admitting affiliations country',
  'nonAdmittingAffiliations.hospitalName': 'Non-admitting affiliations hospital name',
  'nonAdmittingAffiliations.status': 'Non-admitting affiliations status',
  'nonAdmittingAffiliations.startDate': 'Non-admitting affiliations start date',
  'nonAdmittingAffiliations.endDate': 'Non-admitting affiliations end date',
  'nonAdmittingAffiliations.reason': 'Reason for non-admitting affiliations',
  'nonAdmittingAffiliations.describeNonAdmittingAffiliations': 'Describe non-admitting affiliations',

  // work history labels
  hasActiveMilitaryDuty: 'Has Active Military Duty',
  hasReserveOrNationalGuard: 'Has Reserve Or National Guard',
  'gapRecord.startDate': 'Start date',
  'gapRecord.endDate': 'End date',
  'gapRecord.gapExplanation': 'Gap explanation',
  'workHistory.employerName': 'Employer name',
  'workHistory.department': 'Department',
  'workHistory.address.street1': 'Street address',
  'workHistory.address.city': 'Address city',
  'workHistory.address.state': 'Address state',
  'workHistory.address.zipCode': 'Address ZIP code',
  'workHistory.address.country': 'Address country',
  'workHistory.phone': 'Phone',
  'workHistory.extension': 'Extension',
  'workHistory.fax': 'Fax',
  'workHistory.hasCurrentEmployer': 'Has current employer',
  'workHistory.startDate': 'Start date',
  'workHistory.endDate': 'End date',
  'workHistory.departureReason': 'Departure reason',

  // reference labels
  'references.providerType': 'Provider type',
  'references.firstName': 'First name',
  'references.lastName': 'Last name',
  'references.address.street1': 'Street address',
  'references.address.city': 'City',
  'references.address.state': 'State',
  'references.address.zipCode': 'ZIP code',
  'references.address.country': 'Country',
  'references.address.province': 'Province',
  'references.email': 'Email',
  'references.phone': 'Phone',
  'references.fax': 'Fax',

  // board certificate labels
  boardName: 'Board Name',
  specialty: 'Specialty',
  certificateNumber: 'Certificate number',
  'address.state': 'State',
  expiryDate: 'Expiry date',
  initialCertificateDate: 'Issue date',

  // licenses labels
  state: 'State',
  licenseNo: 'License number',
  type: 'Name',
  issueDate: 'Issue Date',

  // degree labels
  degree: 'Degree',
  schoolStartDate: 'Start date',
  schoolEndDate: 'End date',
  major: 'Major',

  // malpractice coverage labels
  policyNumber: 'Number',
  carrierInsuredName: 'Insurance name',
  currentEffectiveDate: 'Issue date',
  currentExpirationDate: 'Expiry date',

  // dea/cds labels
  dataType: 'Data type',
};

export const renderDatePicker = [
  'otherProviderName.startDate',
  'otherProviderName.endDate',
  'dateOfBirth',
  'demographics.dob',
  'activeLocation.confirmedDate',
  'activeLocation.providerStartDate',
  'admittingPrivileges.startDate',
  'admittingPrivileges.endDate',
  'admittingArrangements.startDate',
  'admittingArrangements.endDate',
  'nonAdmittingAffiliations.startDate',
  'nonAdmittingAffiliations.endDate',
  'gapRecord.startDate',
  'gapRecord.endDate',
  'workHistory.startDate',
  'workHistory.endDate',
  'expiryDate',
  'initialCertificateDate',
  'issueDate',
  'schoolStartDate',
  'schoolEndDate',
  'currentEffectiveDate',
  'currentExpirationDate',
];

export const allowedFutureDateKeys = ['expiryDate', 'schoolEndDate', 'currentExpirationDate'];

export const setToPresentDateKeys = [
  'admittingPrivileges.endDate',
  'admittingArrangements.endDate',
  'nonAdmittingAffiliations.endDate',
  'gapRecord.endDate',
  'workHistory.endDate',
  'trainings.endDate',
];

export const excludeKeys = ['providerId', '__v', '_id', 'createdAt', 'updatedAt', 'dataSource'];

export const defaultToYesBooleanKeys = [
  'activeLocation.patients.hasAcceptNewPatient',
  'activeLocation.patients.hasAcceptExistingPatient',
  'activeLocation.patients.hasAcceptNewPatientByReferrals',
  'activeLocation.paymentRemittance.hasElectronicBilling',
  'activeLocation.telehealth.hasTelehealth',
  'activeLocation.telehealth.hasHIPAACompliant',
  'activeLocation.telehealth.hasAudio',
  'activeLocation.telehealth.hasAudioAndVideo',
  'activeLocation.telehealth.hasSecureTextMessaging',
  'activeLocation.telehealth.hasRemoteMonitoring',
  'activeLocation.telehealth.hasSupportFamilyGiver',
];

export const fieldVisibilityControl = {
  'otherProviderName.add': 'hasOtherNames',
  otherProviderName: 'hasOtherNames',
  'otherProviderName.firstName': 'hasOtherNames',
  'otherProviderName.lastName': 'hasOtherNames',
  'otherProviderName.middleName': 'hasOtherNames',
  'otherProviderName.startDate': 'hasOtherNames',
  'otherProviderName.endDate': 'hasOtherNames',

  fnin: 'hasFNIN',
  fninCountryOfIssue: 'hasFNIN',

  'activeLocation.mailingAddress.street1': 'activeLocation.hasSameMailingAddress',
  'activeLocation.mailingAddress.city': 'activeLocation.hasSameMailingAddress',
  'activeLocation.mailingAddress.state': 'activeLocation.hasSameMailingAddress',
  'activeLocation.mailingAddress.country': 'activeLocation.hasSameMailingAddress',
  'activeLocation.mailingAddress.zipCode': 'activeLocation.hasSameMailingAddress',

  'activeLocation.billingContact.firstName': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.lastName': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.middleName': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.address.street1': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.address.city': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.address.state': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.address.country': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.address.zipCode': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.phone': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.fax': 'activeLocation.billingContact.hasSameContact',
  'activeLocation.billingContact.email': 'activeLocation.billingContact.hasSameContact',

  'activeLocation.businessStaffContact.firstName':
    'activeLocation.businessStaffContact.hasOfficeManagerContact',
  'activeLocation.businessStaffContact.lastName':
    'activeLocation.businessStaffContact.hasOfficeManagerContact',
  'activeLocation.businessStaffContact.middleName':
    'activeLocation.businessStaffContact.hasOfficeManagerContact',
  'activeLocation.businessStaffContact.suffix': 'activeLocation.businessStaffContact.hasOfficeManagerContact',
  'activeLocation.businessStaffContact.phone': 'activeLocation.businessStaffContact.hasOfficeManagerContact',
  'activeLocation.businessStaffContact.fax': 'activeLocation.businessStaffContact.hasOfficeManagerContact',
  'activeLocation.businessStaffContact.email': 'activeLocation.businessStaffContact.hasOfficeManagerContact',

  'activeLocation.paymentRemittance.address.street1': 'activeLocation.paymentRemittance.hasSameContact',
  'activeLocation.paymentRemittance.address.city': 'activeLocation.paymentRemittance.hasSameContact',
  'activeLocation.paymentRemittance.address.state': 'activeLocation.paymentRemittance.hasSameContact',
  'activeLocation.paymentRemittance.address.country': 'activeLocation.paymentRemittance.hasSameContact',
  'activeLocation.paymentRemittance.address.zipCode': 'activeLocation.paymentRemittance.hasSameContact',
  'activeLocation.paymentRemittance.phone': 'activeLocation.paymentRemittance.hasSameContact',
  'activeLocation.paymentRemittance.fax': 'activeLocation.paymentRemittance.hasSameContact',
  'activeLocation.paymentRemittance.email': 'activeLocation.paymentRemittance.hasSameContact',

  'activeLocation.taxInformation.taxId': 'activeLocation.taxInformation.hasPrimaryTaxId',
  'activeLocation.taxInformation.taxType': 'activeLocation.taxInformation.hasPrimaryTaxId',

  'activeLocation.services.xrayCertificationType': 'activeLocation.services.hasXray',

  'activeLocation.organizationNPIType2.groupName':
    'activeLocation.organizationNPIType2.hasDifferentGroupName',

  'activeLocation.services.accreditingProgram': 'activeLocation.services.hasLaboratory',

  'activeLocation.services.classOfAnesthesia': 'activeLocation.services.hasAnesthesiaAdministered',
  'activeLocation.services.anesthesiaAdministeredBy': 'activeLocation.services.hasAnesthesiaAdministered',
  'activeLocation.services.anesthesiaAdministeredByFirstName':
    'activeLocation.services.hasAnesthesiaAdministered',
  'activeLocation.services.anesthesiaAdministeredByLastName':
    'activeLocation.services.hasAnesthesiaAdministered',

  'admittingPrivileges.state': 'admittingPrivileges.hasAdmittingPrivileges',
  'admittingPrivileges.country': 'admittingPrivileges.hasAdmittingPrivileges',
  'admittingPrivileges.hospitalName': 'admittingPrivileges.hasAdmittingPrivileges',
  'admittingPrivileges.hasPrimaryHospital': 'admittingPrivileges.hasAdmittingPrivileges',
  'admittingPrivileges.status': 'admittingPrivileges.hasAdmittingPrivileges',
  'admittingPrivileges.startDate': 'admittingPrivileges.hasAdmittingPrivileges',
  'admittingPrivileges.endDate': 'admittingPrivileges.hasAdmittingPrivileges',
  'admittingPrivileges.reason': 'admittingPrivileges.hasAdmittingPrivileges',
  'admittingPrivileges.privilegeType': 'admittingPrivileges.hasAdmittingPrivileges',

  'admittingArrangements.state': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.country': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.hospitalName': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.status': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.startDate': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.endDate': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.admittedForYou': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.firstName': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.lastName': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.hasSameSpecialty': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.phone': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.email': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.NPIType1': 'admittingArrangements.hasAdmittingArrangements',
  'admittingArrangements.describeAdmittingArrangements': 'admittingArrangements.hasAdmittingArrangements',

  'nonAdmittingAffiliations.state': 'nonAdmittingAffiliations.hasNonAdmittingAffiliations',
  'nonAdmittingAffiliations.country': 'nonAdmittingAffiliations.hasNonAdmittingAffiliations',
  'nonAdmittingAffiliations.hospitalName': 'nonAdmittingAffiliations.hasNonAdmittingAffiliations',
  'nonAdmittingAffiliations.status': 'nonAdmittingAffiliations.hasNonAdmittingAffiliations',
  'nonAdmittingAffiliations.startDate': 'nonAdmittingAffiliations.hasNonAdmittingAffiliations',
  'nonAdmittingAffiliations.endDate': 'nonAdmittingAffiliations.hasNonAdmittingAffiliations',
  'nonAdmittingAffiliations.reason': 'nonAdmittingAffiliations.hasNonAdmittingAffiliations',
  'nonAdmittingAffiliations.describeNonAdmittingAffiliations':
    'nonAdmittingAffiliations.hasNonAdmittingAffiliations',
};

export const showFieldsOnNoValueKeys = [
  'activeLocation.hasSameMailingAddress',
  'activeLocation.billingContact.hasSameContact',
  'activeLocation.paymentRemittance.hasSameContact',
];

export enum TAB_VIEW {
  INFORMATION = 'information',
  FILE = 'files',
}
