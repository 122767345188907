import React, { useEffect } from 'react';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Box, Divider } from '@mui/material';
import { ProgressBar } from '@confidant-health/lib/ui/atoms/ProgressBar';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerHeader } from './DrawerHeader';
import { useStyles } from './LoeAssignmentsDrawer.styles';
import AssignmentsCard from '../assignments-chart-card';
import { scheduleActionCreators } from '../../../../../redux/modules/schedule';
import { getLoeStatusDetails } from '../../../../../redux/modules/schedule/selectors';
import { MemberInfo } from './MemberInfo';
import history from '../../../../../utils/history';

const LoeAssignmentsDrawer = ({ open, onClose, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberDetails, outReachNeededStats, isLoading } = useSelector(getLoeStatusDetails);

  const goToChat = id => {
    history.push(`/admin/chats?userId=${id}&type=members`);
  };

  const fetchDetails = days => {
    dispatch(scheduleActionCreators.fetchLoeDetails({ days, loeStatus: data.name }));
  };

  useEffect(() => {
    if (open) {
      fetchDetails(30);
      dispatch(scheduleActionCreators.fetchLoeStatusDetails({ loe: data.name }));
    }
  }, [open]);

  const excludedNames = [
    'Successful Discharge',
    'Self Discharge',
    'Discharged to HLOC',
    'Lost to Care',
    'Not admitted to care',
    'DIY',
    'Ghost',
    'No Status',
    'Referral Active',
  ];
  const shouldRenderBox = !excludedNames.includes(data.name);

  const noAssignmentChart = 'No Status';
  const shouldRenderChart = !noAssignmentChart.includes(data.name);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      header={<DrawerHeader title={data.name} membersCount={data.members} onClose={onClose} />}
      className={classes.drawer}
    >
      {shouldRenderChart && <AssignmentsCard updateGraph={fetchDetails} />}
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <Box>
          {shouldRenderBox && (
            <Box className={classes.outreachNeededBox}>
              <Heading class={classes.heading2} level={headingLevel.S} weight={fontWeight.REGULAR}>
                Outreach needed
              </Heading>
              <ProgressBar
                value={outReachNeededStats.percentageOutreachNeeded}
                variant="determinate"
                className={classes.progressBar}
              />
            </Box>
          )}
          <Box>
            {memberDetails.map((member, index) => (
              <React.Fragment key={index}>
                <MemberInfo
                  nickName={member.firstName || member.fullName}
                  fullName={member.uuid}
                  profileImage={member.profileImage}
                  type={data.name}
                  goToChat={() => {
                    goToChat(member.userAccountId);
                  }}
                />
                {index < memberDetails.length - 1 && <Divider className={classes.bodyDivider} />}
              </React.Fragment>
            ))}
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export { LoeAssignmentsDrawer };
