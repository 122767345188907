import { Box } from '@mui/material';
import { IconButton, iconBtnPosition, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { IGlyphs } from '@confidant-health/lib/icons';

import { useStyles } from './index.styles';

type CardHeaderProps = {
  title: string;
  total?: number;
  action?: {
    onClick: () => void;
    icon?: IGlyphs['glyph'];
    label: string;
  };
};

const CardHeader = ({ title, total, action }: CardHeaderProps) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        py: 2.5,
        px: 3,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box className={classes.title}>{title}</Box>
        {total && <Box className={classes.badge}>{total} total</Box>}
      </Box>
      {action && (
        <Box sx={{ svg: { marginLeft: 1 }, '& button': { fontSize: 14 } }}>
          <IconButton
            onClick={action.onClick}
            icon={action.icon || 'arrow-right'}
            iconPosition={iconBtnPosition.RIGHT}
            variant={iconBtnType.TEXT}
          >
            {action.label}
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CardHeader;
