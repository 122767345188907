export const TAGS = {
  TAGS_PRIORITY_LEVELS: {
    CRITICAL: 'Critical',
    NORMAL: 'Normal',
    HIGH: 'High',
    LOW: 'Low',
    NONE: 'None',
  },
  TAGS_COLOR: {
    RED: 'red',
    YELLOW: 'yellow',
    GREEN: 'green',
    PURPLE: 'purple',
  },
  TAGS_TYPES: {
    ALERTS: 'Alerts',
    LIFE_EVENTS: 'Life Events',
    SYMPTOMS: 'Symptoms',
    SIDE_EFFECTS: 'Side Effects',
    DIAGNOSIS: 'Diagnosis',
    PRESCRIPTIONS: 'Prescriptions',
    IMPROVEMENTS: 'Improvements',
    SUBSTANCE_USE: 'Substance Use',
    GENERAL: 'General',
  },
  TAGS_ASSIGNMENT_METHOD: {
    BASED_ON_PROFILE_ELEMENT: 'Profile element',
    BASED_ON_DCT: 'DCT',
  },
  BASED_ON_TAGS_ASSIGNMENT_METHOD: {
    BASED_ON_PROFILE_ELEMENT: 'BASED_ON_PROFILE_ELEMENT',
    BASED_ON_DCT: 'BASED_ON_DCT',
  },
};
