import { makeStyles } from '@mui/styles';
// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  messageItem: {
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 32,
    fontWeight: 500,
    fontSize: 14,
  },
  text: {
    flex: 1,
    color: colors.neutral900,
    lineHeight: '20px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    lineClamp: 2,
    textOverflow: 'ellipsis',
    width: '100%',
  },
  time: {
    display: 'block',
    lineHeight: '20px',
    color: colors.neutral600,
  },
});
