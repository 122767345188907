import { FC } from 'react';
import { Box } from '@mui/material';

import { useStyles } from './ValuesListCard.styles';

// types
import { IProfileElementValue } from '../../ProfileElementDetail.types';

interface Props {
  profileElementValues: IProfileElementValue[];
}

const ValuesListCard: FC<Props> = ({ profileElementValues }) => {
  const classes = useStyles();

  const Chip = ({ name, percentage }) => {
    return (
      <Box className={classes.chip}>
        {name}
        <span className={classes.badge}>{Math.round(percentage)}%</span>
      </Box>
    );
  };

  return (
    <Box className={classes.card}>
      {profileElementValues.map((value: IProfileElementValue, index: number) => (
        <Chip key={index} name={value.name} percentage={value.percentage} />
      ))}
    </Box>
  );
};

export { ValuesListCard };
