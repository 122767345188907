import Box from '@mui/material/Box';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IconButton, iconBtnPosition, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { RadioGroup, radioGroupType } from '@confidant-health/lib/ui/molecules/radio-group';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';

import { profileActionCreators } from 'redux/modules/profile';
import { getPriority } from 'redux/modules/priority/selectors';
import { IDomainElement } from 'redux/modules/profile/types';

import { useDomainType } from 'hooks/useDomainType';
import { DomainTypesEnum } from 'constants/CommonConstants';

import { AddMedication } from '../video-call-screen/components/add-medication';

import { radioOptions } from './SessionSignOffV2.constant';
import { useStyles } from './SessionSignOffV2.styles';

interface Props {
  onBack: () => void;
  onNext: (status: any) => void;
  appointment: IAppointment;
  preFill: { selectedOption?: string };
  template: string;
}

const Medications = ({ onBack, onNext, appointment, preFill, template }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loadingMedication, medications, domainOptions] = useDomainType({
    type: DomainTypesEnum.MEDICATIONS,
    patientId: appointment?.participantId,
  });

  const [loadingSymptom, symptoms] = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
    patientId: appointment?.participantId,
  });

  const {
    elementsPayload: { domainElements },
  } = useSelector(getPriority);

  const [selectedOption, setSelectedOption] = useState(preFill?.selectedOption ?? 'no');
  const [openAddMedication, setOpenAddMedication] = useState(false);
  const [selectedDomainElement, setSelectedDomainElement] = useState<IDomainElement>();

  const isDisabled = useMemo(() => {
    return (!medications || medications?.relatedElements?.length === 0) && selectedOption === 'no';
  }, [medications, selectedOption]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter' && !isDisabled) {
        onNextClick();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (template === 'yes' && !preFill?.selectedOption) {
      setSelectedOption('yes');
    }
  }, [template]);

  const onNextClick = () => {
    onNext({
      medications: {
        icon: 'medication-2',
        title: 'Medications',
        description: 'prescribed',
        number: medications?.relatedElements.length ?? 0,
        selectedOption,
      },
    });
  };

  const onSubmit = payload => {
    const data = {
      patientId: appointment.participantId,
      tagItems: [
        {
          assignedBy: appointment?.participantName,
          domainElementId: payload?.medication,
          metaData: {
            notes: payload?.additionalNotes,
            selectedImportance: payload?.status,
            rxDrugInfo: {
              confidantProviderId: payload?.confidantProvider,
              confidantProviderName: payload?.confidantProviderName,
              dosage: payload?.dosage,
              frequency: payload?.frequency,
              dose: 0,
              doseFrequency: 0,
              doseUnit: '',
              prescribingProvider: payload?.provider === 'Confidant Provider' ? 'Internal' : 'External',
              providerName: payload?.providerName,
              refillable: true,
              supply: 0,
              supplyUnit: '',
              symptomsBeingTreated: payload?.symptoms,
            },
          },
        },
      ],
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: (errorMsg?: string) => {
          if (!errorMsg) {
            setOpenAddMedication(false);
          }
        },
      }),
    );
  };

  const onSelect = (e: { target: { value } }) => {
    setSelectedOption(e.target.value);
  };

  const onAdd = () => {
    setSelectedDomainElement(null);
    setOpenAddMedication(true);
  };

  const onUpdate = (item: IDomainElement) => {
    setSelectedDomainElement(item);
    setOpenAddMedication(true);
  };

  if (loadingMedication || loadingSymptom) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Box display="flex" flexDirection="column" gap={10} sx={{ height: 'calc(100vh - 257px)' }}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.head}>
              <Box className={classes.contentHeader}>
                <Icons glyph="medication-2" color={colors.neutral700} />
                <Box className={classes.contentHeaderTitle}>Medications</Box>
              </Box>
              <IconButton icon="plus" onClick={onAdd}>
                Add new
              </IconButton>
            </Box>
            <Box className={classes.content}>
              {medications?.relatedElements?.length > 0 ? (
                medications?.relatedElements.map((medication, index) => (
                  <Box className={classes.evaluation} key={index} onClick={() => onUpdate(medication)}>
                    <Box className={classes.evaluationContent}>
                      <Box className="title">{medication.name}</Box>
                      {medication.tagMetaData?.rxDrugInfo?.dosage && (
                        <Box className="subTitle">
                          {medication.tagMetaData?.rxDrugInfo?.dosage} -{' '}
                          {medication.tagMetaData?.rxDrugInfo?.frequency}
                        </Box>
                      )}
                    </Box>
                    <Box display="flex" gap={3} alignItems="center">
                      <Badge
                        style={badgeStyle.HIGH}
                        variant={badgeType.FILLED}
                        size={28}
                        className={classes.badge}
                      >
                        {medication.priority.name}
                      </Badge>
                      <Menu
                        icon="more"
                        className={classes.menu}
                        itemsWrapperClassName={classes.menuItemsWrapper}
                        items={[
                          {
                            label: 'Update Medication',
                            onClick: () => onUpdate(medication),
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                ))
              ) : (
                <Box className={classes.empty}>
                  <Box className="title">No Medications Listed</Box>
                  <Box className="content">
                    Update the medication reconciliation, if appropriate. It is critical to have an accurate
                    record of medications at all times.
                  </Box>
                  <IconButton icon="plus" variant={iconBtnType.OUTLINE} onClick={onAdd}>
                    New medication
                  </IconButton>
                </Box>
              )}
            </Box>
            {(!medications || medications?.relatedElements?.length === 0) && (
              <>
                <Box className={classes.question}>Do you want to continue without adding medications?</Box>
                <RadioGroup
                  name="radio-buttons-group"
                  options={radioOptions}
                  value={selectedOption}
                  onChange={onSelect}
                  variant={radioGroupType.HORIZONTAL}
                />
              </>
            )}
          </Box>
          <Drawer
            open={openAddMedication}
            onClose={() => setOpenAddMedication(false)}
            variant={drawerType.NORMAL}
          >
            <AddMedication
              selectedDomainElement={selectedDomainElement}
              domainElements={domainElements}
              domainOptions={domainOptions}
              onSubmit={onSubmit}
              symptoms={symptoms}
              onClose={() => setOpenAddMedication(false)}
            />
          </Drawer>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <IconButton
          icon="chevron-left"
          iconPosition={iconBtnPosition.LEFT}
          className={classes.previousBtn}
          onClick={onBack}
          variant={iconBtnType.TEXT}
        >
          Previous
        </IconButton>
        <IconButton
          icon="chevron-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.nextBtn}
          onClick={onNextClick}
          disabled={isDisabled}
        >
          Next
        </IconButton>
      </Box>
    </>
  );
};

export default Medications;
