/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    height: '100%',
    zIndex: 1,
    backgroundColor: colors.white,
    display: 'flex',
    padding: 32,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapIcon: {
    backgroundColor: '#C4EFC7',
    width: 80,
    height: 80,
    display: 'flex',
    borderRadius: '50%',
    marginBottom: 32,
  },
  icon: {
    width: 68,
    height: 68,
    borderRadius: '50%',
    margin: 'auto',
    backgroundColor: colors.white,
    '& path': {
      fill: colors.success500,
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    textAlign: 'center',
    marginBottom: 8,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    display: 'block',
    marginBottom: 32,
  },
  viewBtn: {
    borderColor: colors.primary300,
    color: colors.primary600,
  },
});
