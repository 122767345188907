import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { colors } from '@confidant-health/lib/colors';
import { btnSize, btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { IClaimMember } from 'redux/modules/reporting/types';
import DatePickerInput from './DatePicker';
import { useStyles } from '../ClaimDetail.styles';

type Props = {
  onSubmit: (formData: Partial<IClaimMember>, type: string) => void;
  data?: Partial<IClaimMember>;
  type?: boolean;
};

type DrawerProps = {
  data: Partial<IClaimMember>;
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<IClaimMember>, type: string) => void;
  type?: boolean;
};

const defaultData = {
  address1: '',
  address2: '',
  city: '',
  dob: '',
  firstName: '',
  fullName: '',
  gender: '',
  groupName: '',
  groupNumber: '',
  insuranceCode: '',
  lastName: '',
  memberId: '',
  middleName: '',
  postalCode: '',
  profileImage: '',
  state: '',
  userAccountId: '',
};

const labels = {
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  dob: 'Date of Birth',
  firstName: 'First Name',
  fullName: 'Full Name',
  gender: 'Gender',
  groupName: 'Group Name',
  groupNumber: 'Group Number',
  insuranceCode: 'Insurance Code',
  lastName: 'Last Name',
  memberId: 'Member ID',
  middleName: 'Middle Name',
  postalCode: 'Postal Code',
  ssn: 'SSN',
};

const defaultDetailKeys = [
  ['fullName', 'firstName', 'lastName', 'memberId'],
  ['ssn', 'address1', 'address2', 'groupName', 'groupNumber', 'insuranceCode'],
];

const SubscriberDrawer = ({ data, open, onClose, onSubmit, type }: DrawerProps) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...data });
  const [errors, setErrors] = useState(defaultData);

  const onChangeField = (field: string) => (e: any) => {
    if (errors[field]) {
      setErrors(s => ({ ...s, [field]: '' }));
    }
    setFormData(old => ({
      ...old,
      [field]: e.target?.value,
    }));
  };

  const onEditInfo = () => {
    onSubmit(formData, type ? 'dependent' : 'subscriber');
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title={type ? 'Edit Dependent' : 'Edit Subscriber'}
      submitBtnTitle="Update"
      onSubmit={onEditInfo}
    >
      {defaultDetailKeys[0].map((d: keyof IClaimMember, index: number) => (
        <Box className={classes.inputWrapper} key={`${index}-${d}`}>
          <label htmlFor={d}>
            <Text level={textLevel.S} weight={fontWeight.BOLD}>
              {labels[d]}
            </Text>
          </label>
          <Input
            name={d}
            value={formData[d] || ''}
            onChange={onChangeField(d)}
            size={inputSize.M}
            placeholder={`Enter your ${d}`}
          />
        </Box>
      ))}
      <Box className={classes.inputWrapper}>
        <label htmlFor="dob">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            Date of birth
          </Text>
        </label>
        <DatePickerInput
          date={dayjs(formData.dob)}
          onChange={date => {
            setFormData({ ...formData, dob: date.toISOString() });
          }}
        />
      </Box>
      <Box className={classes.inputWrapper}>
        <label htmlFor="gender">
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            Gender
          </Text>
        </label>
        <Select
          value={formData.gender}
          name="gender"
          variant={selectType.SECONDARY}
          emptyText=""
          displayEmpty
          options={[
            { label: 'Male', value: 'Male' },
            { label: 'Female', value: 'Female' },
            { label: 'Other', value: 'Other' },
          ]}
          onChange={onChangeField('gender')}
        />
      </Box>
      {defaultDetailKeys[1].map((d: keyof IClaimMember, index: number) => (
        <Box className={classes.inputWrapper} key={`${index}-${d}`}>
          <label htmlFor={d}>
            <Text level={textLevel.S} weight={fontWeight.BOLD}>
              {labels[d]}
            </Text>
          </label>
          <Input
            name={d}
            value={formData[d] || ''}
            onChange={onChangeField(d)}
            size={inputSize.M}
            placeholder={`Enter your ${d}`}
          />
        </Box>
      ))}
    </Drawer>
  );
};

const Subscriber = ({ onSubmit, data = defaultData, type }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const address = useMemo(() => {
    return `${data?.address1 ? `${data?.address1}, ` : ''}${data?.address2 ? `${data?.address2}, ` : ''}${
      data?.city ? `${data?.city}, ` : ''
    }${data?.postalCode ? `${data?.postalCode}, ` : ''}`;
  }, []);

  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={185}>
      <Text className={classes.colLabel} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      <Text className={classes.marginTop8} level={textLevel.M} weight={fontWeight.MEDIUM}>
        {value || 'N/A'}
      </Text>
    </Box>
  );

  return (
    <Box
      sx={{
        background: colors.white,
        paddingX: 3,
        paddingBottom: 4,
        marginBottom: 2,
        boxShadow:
          '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading level={headingLevel.S} weight={fontWeight.SEMI_BOLD}>
          {type ? 'Dependent' : 'Subscriber'}
        </Heading>
        <Button
          className={classes.btn}
          variant={btnType.TEXT}
          onClick={() => setOpen(true)}
          size={btnSize.SMALL}
        >
          Edit info
        </Button>
      </Box>
      <Divider sx={{ marginX: -3 }} />

      <Box display="flex" mt={4} alignItems="center">
        <Box display="flex" flexDirection="column" minWidth={185}>
          <ProfileInfo
            type="member"
            photo={data?.profileImage}
            nickName={data?.fullName || ''}
            fullName={`${data?.firstName ? `${data?.firstName} ` : ''}${data?.lastName || ''}`}
            memberId={data?.userAccountId}
          />
        </Box>
        <Col label="Member id" value={data?.memberId} />
        <Col label="Date of birth" value={data?.dob ? format(new Date(data?.dob), 'MM.dd.yyyy') : ''} />
      </Box>
      <Box display="flex" mt={4}>
        <Col label="Gender" value={data?.gender} />
        <Col label="SSN" value={data?.ssn} />
        <Col label="Address" value={address || 'N/A'} />
      </Box>
      <Box display="flex" mt={4}>
        <Col label="Group name" value={data?.groupNumber} />
        <Col label="Group number" value={data?.groupNumber} />
        <Col label="Insurance code" value={data?.insuranceCode} />
      </Box>
      {open && (
        <SubscriberDrawer
          data={data}
          open={open}
          onClose={() => setOpen(false)}
          type={type}
          onSubmit={onSubmit}
        />
      )}
    </Box>
  );
};

export default Subscriber;
