import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import debounce from 'debounce';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import MyCheckbox from '@confidant-health/lib/ui/molecules/my-checkbox';

import { conversationActionCreators } from 'redux/modules/conversation/actions';
import {
  selectPlanItems,
  selectPlanItemsLoading,
  selectAssignedPlanItemsSaving,
  selectAssignedPlanItemsById,
} from 'redux/modules/conversation/selectors';

import { useStyles } from './PlanItemDrawer.styles';

type PlanItemDrawerProps = {
  isOpen: boolean;
  memberId: string;
  onClose: () => void;
};

const PlanItemDrawer: React.FC<PlanItemDrawerProps> = ({ isOpen, memberId, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const planItems = useSelector(selectPlanItems);
  const isLoading = useSelector(selectPlanItemsLoading);
  const assignedPlanItems = useSelector(selectAssignedPlanItemsById(memberId));
  const possiblePlanItems = useMemo(
    () =>
      planItems.filter(
        planItem =>
          !assignedPlanItems?.map(assignedPlanItem => assignedPlanItem.planItem.id).includes(planItem.id),
      ),
    [planItems, assignedPlanItems],
  );
  const [planItemsFiltered, setPlanItemsFiltered] = useState([]);
  const [selectedPlanItemIds, setSelectedPlanItemIds] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const isSaving = useSelector(selectAssignedPlanItemsSaving);

  useEffect(() => {
    setPlanItemsFiltered(possiblePlanItems);
  }, [possiblePlanItems]);

  const handleSubmit = useCallback(() => {
    dispatch(
      conversationActionCreators.addAssignedPlanItems({
        memberId,
        planItemIds: selectedPlanItemIds,
      }),
    );
  }, [memberId, selectedPlanItemIds, conversationActionCreators, dispatch]);

  const fetchPlanItems = useCallback(() => {
    dispatch(
      conversationActionCreators.fetchPlanItems({
        pageNumber: 1,
        pageSize: 10000,
      }),
    );
  }, [dispatch]);

  const fetchAssignedPlanItems = useCallback(() => {
    dispatch(conversationActionCreators.fetchAssignedPlanItems({ memberId }));
  }, [memberId, dispatch]);

  const filterPlanItemsCallback = useCallback(
    debounce(keyword => {
      const newPlanItems = possiblePlanItems.filter(item => `${item.name}`.toLowerCase().includes(keyword));
      setPlanItemsFiltered(newPlanItems);
    }, 300),
    [possiblePlanItems],
  );

  const onChangeSearchKey = (val: string) => {
    setSearchKey(val);
    filterPlanItemsCallback(val.toLowerCase());
  };

  const handleClose = useCallback(() => {
    onClose();
    setSelectedPlanItemIds([]);
    onChangeSearchKey('');
  }, [onClose, setSelectedPlanItemIds]);

  useEffect(() => {
    void fetchPlanItems();
  }, [fetchPlanItems]);

  useEffect(() => {
    if (isOpen && !isSaving) {
      handleClose();
      fetchAssignedPlanItems();
    }
  }, [isSaving]);

  const updateEvaluationCheckBoxStatus = (evaluationId: any) => () => {
    const updatedselectedPlanItemIdList = selectedPlanItemIds.includes(evaluationId)
      ? selectedPlanItemIds.filter(i => i !== evaluationId)
      : [...selectedPlanItemIds, evaluationId];
    setSelectedPlanItemIds(updatedselectedPlanItemIdList);
  };

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      variant={drawerType.FORM}
      title="Add plan items"
      submitBtnTitle={isSaving ? 'Saving...' : 'Add plan items'}
      onSubmit={handleSubmit}
      className={classes.drawer}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <>
          <Box className={classes.searchBox}>
            <Input
              className={classes.searchInput}
              variant={inputType.SEARCH}
              placeholder="Search"
              value={searchKey}
              onChange={onChangeSearchKey}
            />
          </Box>
          <Box className={classes.listItem}>
            {planItemsFiltered.map((planItem, index) => (
              <Box key={index} className={classes.section}>
                <MyCheckbox
                  label={planItem.name}
                  sublabel={planItem.type}
                  isChecked={selectedPlanItemIds.includes(planItem.id)}
                  onChange={updateEvaluationCheckBoxStatus(planItem.id)}
                />
              </Box>
            ))}
          </Box>
        </>
      )}
    </Drawer>
  );
};

export { PlanItemDrawer };
