import { FC, useEffect } from 'react';
import { isUndefined } from 'lodash';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';

import { Schema } from './EvaluationTextInput.schema';
import { useStyles } from './EvaluationTextInput.styles';

type Props = {
  id?: string;
  selectedSection: any;
  forceRender: () => void;
  onChange?: (value: string) => void;
  removeExtraHeight?: boolean;
  setInputFocused?: (focused: boolean) => void;
};

const EvaluationTextInput: FC<Props> = ({
  id,
  selectedSection,
  forceRender,
  onChange,
  removeExtraHeight,
  setInputFocused,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (isUndefined(selectedSection?.evaluationResponses)) {
      if (selectedSection?.userResponse?.userResponseList > 0) {
        selectedSection.evaluationResponses = selectedSection.userResponse.userResponseList;
      }
    }
  }, []);

  const checkAnswer = () => {
    if (selectedSection.evaluationResponses?.length > 0) return selectedSection.evaluationResponses[0];
    if (selectedSection.userResponse?.userResponseList?.length > 0) {
      return selectedSection.userResponse.userResponseList[0];
    }
    return '';
  };

  const defaultValues = {
    text: selectedSection?.evaluationResponses ? selectedSection?.evaluationResponses[0] : '',
  };

  const { handleChange, handleSubmit } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: (payload: typeof defaultValues) => {
      console.log('onSubmit', payload);
    },
  });

  const onChangeHandler = event => {
    const trimmedValue = event.target.value.trimStart();
    selectedSection.evaluationResponses = [trimmedValue];
    handleChange({
      ...event,
      target: {
        ...event.target,
        value: trimmedValue,
        name: event.target.name,
      },
    });
    if (onChange) {
      onChange(trimmedValue);
    }
    forceRender();
  };

  return (
    <>
      {!removeExtraHeight && <Box style={{ height: 32 }} />}
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box key={id} className={classes.formContent}>
          <Box className={classes.section}>
            <Textarea
              id={id}
              value={checkAnswer()}
              name="text"
              onChange={onChangeHandler}
              onBlur={() => setInputFocused(false)}
              onFocus={() => setInputFocused(true)}
              minRows={1}
              placeholder="Enter your text"
            />
            {!!selectedSection?.helperText && selectedSection?.helperText?.length > 0 && (
              <Box>
                <Text className={classes.helperText}>{selectedSection?.helperText}</Text>
              </Box>
            )}
          </Box>
        </Box>
      </form>
    </>
  );
};

export { EvaluationTextInput };
