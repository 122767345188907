import * as yup from 'yup';
import { FeeTypes } from './PayerDetail.constants';

export const AddPlanSchema = yup.object({
  name: yup.string().required('Plan name is required'),
  code: yup.string(),
  isActive: yup.boolean(),
});

export const AddProviderSchema = yup.object({
  provider: yup.object().required('Provider is required'),
  isBillingProvider: yup.boolean(),
  // isRenderingProvider: yup.boolean(),
  // npi: yup.string().required('NPI is required'),
  // isActive: yup.boolean(),
  // notAllowedCPTCodes: yup.array().required('NPI is required'),
});

export const AddCPTSchema = yup.object({
  code: yup.object().required('CPT code is required'),
  description: yup.string().required('Description is required'),
  feeType: yup.string().oneOf([FeeTypes.SINGLE, FeeTypes.TIME]),
  durationWisePrice: yup.array().when('feeType', {
    is: FeeTypes.TIME,
    then: yup.array().of(
      yup.object().shape({
        duration: yup.number().required('This field is required'),
        fee: yup.number().required('This field is required'),
        providerFee: yup.number().required('This field is required'),
      }),
    ),
  }),
  fee: yup.number().when('feeType', {
    is: FeeTypes.SINGLE,
    then: yup.number().required('Total fee is required'),
  }),
  providerFee: yup.number().when('feeType', {
    is: FeeTypes.SINGLE,
    then: yup.number().required('Provider fee is required'),
  }),
  isTimeRequired: yup.boolean(),
  requiredTimeLimits: yup.object().when('isTimeRequired', {
    is: true,
    then: yup.object({
      min: yup.number().required('This field is required'),
      max: yup.number().required('This field is required'),
    }),
  }),
  requireAnotherCPTCode: yup.boolean(),
  requiredCPTCodes: yup.array().when('requireAnotherCPTCode', {
    is: true,
    then: yup
      .array()
      .of(
        yup.object({
          title: yup.string(),
          value: yup.string(),
        }),
      )
      .min(1, 'Select at least one code'),
  }),
  requireDiagnosisToBill: yup.boolean(),
  requiredDiagnosis: yup.array().when('requireDiagnosisToBill', {
    is: true,
    then: yup
      .array()
      .of(
        yup.object({
          title: yup.string(),
          value: yup.string(),
        }),
      )
      .min(1, 'Select at least one code'),
  }),
  isActive: yup.boolean(),
});
