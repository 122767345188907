import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  table: {
    overflowX: 'auto',
    backgroundColor: 'white',
    '& .MuiTableCell-body': {
      padding: '24px 16px 24px 24px !important',
    },
    '& .MuiTableCell-head': {
      color: colors.neutral700,
      boxShadow: '0px -1px 0px 0px #EDF1F5 inset',
      background: colors.neutral25,
    },
  },
  status: {
    height: 24,
    padding: '2px 10px',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
});
