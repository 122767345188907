import { Box, Grid } from '@mui/material';
import { IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import { Rating } from '@confidant-health/lib/ui/atoms/rating';
import { colors } from '@confidant-health/lib/colors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'redux/modules/auth/selectors';
import Heading from 'components/v2/Heading';
import Typography from 'components/v2/Typography';
import * as scheduleServices from 'services/schedule/schedule.service';
import { typography } from 'components/v2/Typography/index.constant';
import { HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import { getDateLabels } from 'utils/CommonUtils';
import { showSnackbar } from 'redux/modules/snackbar';
import ReviewCard from './components/Card';
import AllReviewsDrawer from './components/AllReviewsDrawer';

import { useStyles } from './index.styles';

const Reviews = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const [reviews, setReviews] = useState([]);
  const [reviewsData, setReviewsData] = useState({
    totalReviews: '0',
    averageRating: '0',
    recentRating: '0',
  });
  const [openReviewDrawer, setOpenReviewDrawer] = useState(false);

  const fetchProviderReviews = async () => {
    try {
      const { data } = await scheduleServices.getProviderReviews({
        actor: meta.userId,
        pageNumber: 0,
        pageSize: 3,
      });
      if (data?.totalReviews > 0) {
        setReviews(data?.feedbackList);
        setReviewsData({
          totalReviews: String(data?.totalReviews),
          averageRating: String(data?.averageRating),
          recentRating: String(data?.feedbackList[0].rating),
        });
      }
    } catch (e) {
      const message = e?.data?.message || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: message,
        }),
      );
      console.warn(e);
    }
  };

  useEffect(() => {
    void fetchProviderReviews();
  }, []);

  return (
    <>
      <Box className={classes.scorebox}>
        <Box className={classes.sbflex} px={3} py={2.5}>
          <Heading variant={HeadingLevel.SUB} fontWeight={HeadingWeight.SEMI_BOLD}>
            My reviews
          </Heading>
          {Number(reviewsData.totalReviews) > 0 && (
            <Box sx={{ '& button': { gap: 1 } }}>
              <IconButton
                icon="arrow-right"
                iconPosition={iconBtnPosition.RIGHT}
                onClick={() => setOpenReviewDrawer(true)}
              >
                <Typography {...typography.body.s.bold}>View all</Typography>
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className={classes.cardContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <ReviewCard label="total reviews" count={reviewsData?.totalReviews} />
            </Grid>
            <Grid item xs={12} md={4}>
              <ReviewCard label="average rating" count={reviewsData?.averageRating} isRating />
            </Grid>
            <Grid item xs={12} md={4}>
              <ReviewCard label="last review" count={reviewsData?.recentRating} isRating />
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          {reviews.map((review, index) => (
            <Grid
              item
              container
              display="flex"
              alignItems="center"
              borderBottom={`1px solid ${colors.neutral100}`}
              key={index}
            >
              <Grid item xs={12} md={2} padding={2}>
                <Rating
                  value={Number(review?.rating)}
                  readOnly
                  className={classes.rating}
                  name="rating"
                  showValue={false}
                />
              </Grid>
              <Grid item xs={12} md={8} padding={2}>
                <Typography {...typography.body.normal.small.medium}>
                  {review?.publicComment?.trim() === '' ? '-' : review?.publicComment}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                padding={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography {...typography.body.normal.small.medium}>
                  {getDateLabels(review?.lastModified, 'MMMM DD, h:mm a')}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <AllReviewsDrawer open={openReviewDrawer} onClose={() => setOpenReviewDrawer(false)} />
    </>
  );
};

export default Reviews;
