import { Box } from '@mui/material';
import { btnSize, btnType } from '@confidant-health/lib/ui/atoms/button';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from 'components/v2/Header';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

import SubmitDrawer from '../caqh-information/components/drawers/SubmitDrawer';
import ImportDrawer from '../caqh-information/components/drawers/ImportDrawer';

import { useStyles } from './index.styles';
import PriorityEmpty from '../../member-detail/components/PriorityEmpty';
import { SectionNames } from '../caqh-information/index.constants';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { getCaqhData, transformData } from '../common/utils.constants';
import { CardStatus, CardType, ProfileSectionCard } from '../common/card/profile-section-card';
import { InfoCard } from '../common/card/info-card';
import { putProviderData } from '../../../../services/datalab/datalab.service';
import { showSnackbar } from '../../../../redux/modules/snackbar';

type deacdsProps = React.FC<{
  refresh?: () => void;
}>;

const DEACDSNumbers: deacdsProps = ({ refresh }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const [showNewUpload, setShowNewUpload] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitList, setSubmitList] = useState({ filled: [], missing: [], list: [], title: undefined });
  const [deaCds, setDeaCds] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecordType, setSelectedRecordType] = useState('');

  const fetchCaqhData = async () => {
    const caqhData = await getCaqhData({ providerId: meta?.userId, type: 'dea-cds-registration' });
    if (caqhData) {
      setDeaCds(caqhData);
    }
  };

  useEffect(() => {
    void fetchCaqhData();
  }, []);

  const onApprove = async (id: string) => {
    const type = 'dea-cds-registration';
    const requestBody = {
      providerId: meta.userId,
      data: {
        id,
        documentStatus: 'APPROVED',
      },
    };
    try {
      const response = await putProviderData(type, requestBody);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'DEA/CDS information approved',
          }),
        );
        void fetchCaqhData();
      }
    } catch (err) {
      const msg = err.response?.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    }
  };

  const onUpdate = (id: string) => {
    const data = deaCds?.find(i => i._id === id);
    setSelectedRecordType(data?.mainData?.dataType);
    setSelectedRecord(data);
    const deaCdsData = { ...data?.mainData };
    delete deaCdsData?.dataType;
    const list = transformData(deaCdsData, SectionNames.DEA_CDS_REGISTRATION);
    setShowSubmitModal(true);
    setSubmitList({
      filled: list?.filled || [],
      missing: list?.missing || [],
      list: list?.list || [],
      title: 'DEA/CDS Numbers',
    });
    setEditMode(true);
    setShowSubmitModal(true);
  };

  const onFormSubmit = async (data: any, edit: boolean) => {
    const type = 'dea-cds-registration';
    let fileData = {};
    if (data.fileData) {
      fileData = data.fileData;
    }
    const requestBody = {
      providerId: meta.userId,
      data: {
        id: selectedRecord._id,
        dataType: selectedRecordType,
        licenseNo: data.licenseNo,
        state: data.state,
        issueDate: data.issueDate,
        expiryDate: data.expiryDate,
        ...fileData,
      },
    };
    try {
      const { data: response } = await putProviderData(type, requestBody);
      if (response.success) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'DEA/CDS data updated',
          }),
        );
      } else {
        setSubmitList({ filled: [], missing: [], list: [], title: undefined });
        void fetchCaqhData();
      }
    } catch (e) {
      console.warn(e);
      if (e.data) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e.data.error,
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e.message || 'Something went wrong',
          }),
        );
      }
    }
    onCloseModal();
  };

  const resetInformation = () => {
    setSubmitList({ filled: [], missing: [], list: [], title: undefined });
  };

  const onCloseModal = () => {
    setSelectedRecord(null);
    setSelectedRecordType('');
    setShowNewUpload(false);
    setShowSubmitModal(false);
    refresh();
    void fetchCaqhData();
    resetInformation();
  };

  return (
    <>
      <Header
        label="DEA / CDS Numbers"
        actions={[
          {
            children: <Typography {...typography.body.normal.medium.bold}>Add new</Typography>,
            icon: 'plus',
            variant: btnType.PRIMARY,
            size: btnSize.SMALL,
            onClick: () => setShowNewUpload(true),
            className: classes.primaryBtn,
          },
        ]}
      />
      <InfoCard records={deaCds} />
      {deaCds?.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {deaCds?.map((number, index) => {
            const cardData =
              number?.documentStatus === CardStatus.DISCREPANCY ? number?.filesData : number?.mainData;
            const discrepancies = [];
            if (number?.documentStatus === CardStatus.DISCREPANCY) {
              number?.issues?.includes('licenseNo') && discrepancies.push('name');
              number?.issues?.includes('licenseNo') && discrepancies.push('number');
              number?.issues?.includes('state') && discrepancies.push('states');
              number?.issues?.includes('issueDate') && discrepancies.push('issueDate');
              number?.issues?.includes('expiryDate') && discrepancies.push('expiryDate');
            }
            return (
              <ProfileSectionCard
                key={index}
                data={number}
                status={number?.documentStatus || 'INCOMPLETE'}
                name={cardData?.licenseNo || ''}
                number={cardData?.licenseNo || ''}
                states={[cardData?.state || '']}
                issueDate={cardData?.issueDate || ''}
                expiryDate={cardData?.expiryDate || ''}
                file={number?.files?.[0] || 'Missing'}
                type={cardData?.dataType || CardType.DEA_CDS_REGISTRATION}
                onUpdate={() => onUpdate(number?._id)}
                onApprove={() => onApprove(number?._id)}
                discrepancies={discrepancies}
              />
            );
          })}
        </Box>
      ) : (
        <PriorityEmpty
          title="DEA / CDS Numbers will appear here once added."
          description=""
          glyph="empty-dea-cds"
          glyphOrientation="portrait"
          isEmptyWithLottie
          lottieSize={128}
        />
      )}
      {showNewUpload && (
        <ImportDrawer open={showNewUpload} onClose={onCloseModal} type={SectionNames.DEA_CDS_REGISTRATION} />
      )}
      {showSubmitModal && (
        <SubmitDrawer
          missedKeys={submitList.missing || []}
          filledKeys={submitList.filled}
          open={showSubmitModal}
          title={`${selectedRecord?.mainData?.dataType || 'DEA/CDS'} Number`}
          subTitle={`${selectedRecord?.mainData?.dataType || 'DEA/CDS'} Number description`}
          list={submitList.list}
          onClose={onCloseModal}
          onEdit={() => {
            setEditMode(true);
          }}
          isTabView
          editMode={editMode}
          onFormSubmit={onFormSubmit}
          selectedRecord={selectedRecord}
          fileType={SectionNames.DEA_CDS_REGISTRATION}
        />
      )}
    </>
  );
};

export default DEACDSNumbers;
