// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tabContainer: {
    padding: 24,
    display: 'flex',
    gap: 24,
    borderBottom: '1px solid #E5EBF0',
  },
  loader: {
    height: 'calc(50vh - 78px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabs: {
    padding: '16px 20px 16px 16px',
    borderRadius: 8,
    border: '1px solid',
    borderColor: colors.neutral200,
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    cursor: 'pointer',
  },
  tabLabel: {
    color: colors.primary600,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  activetab: {
    borderColor: '#B8DDFE !important',
    background: colors.primary50,
  },
  infoContainer: {
    padding: 24,
  },
  schedulingLinkBox: {
    background: colors.neutral50,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    gap: 16,
  },
  schedulingLinkBoxLabel: {
    color: colors.neutral900,
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 18,
  },
  schedulingLinkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  schedulingLinkContainer: {
    padding: '12px 12px 12px 16px',
    display: 'flex',
    flexDirection: 'row',
    background: colors.white,
    width: '80%',
    gap: 12,
    borderRadius: 8,
  },
  linkText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.neutral900,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  linkText2: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    padding: '12px 24px',
    gap: 8,
    border: '1px solid',
    borderColor: colors.primary300,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer',
  },
  userInfoBox: {
    padding: '0px 40px',
    // display: 'flex',
  },
  userInfoContainer: {
    display: 'flex',
    padding: '24px 0px',
    gap: 80,
    alignItems: 'center',
  },
  userInfoLabel: {
    color: colors.neutral900,
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 18,
    width: 120,
  },
  text16_2: {
    fontSize: 16,
    color: colors.neutral900,
    lineHeight: '24px',
    fontWeight: 500,
  },
  text16: {
    fontSize: 16,
    color: colors.neutral500,
    lineHeight: '24px',
    fontWeight: 600,
  },
  colorDark: {
    color: '#0D0F11',
  },
  text16Selected: {
    fontSize: 16,
    color: colors.primary600,
    lineHeight: '24px',
    fontWeight: 600,
  },
  text16_1_1: {
    fontSize: 16,
    color: colors.neutral600,
    lineHeight: '24px',
    fontWeight: 500,
  },
  text16_1: {
    fontSize: 16,
    color: colors.neutral900,
    lineHeight: '24px',
    fontWeight: 500,
  },
  pb: {
    paddingBottom: 4,
  },
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
  },
  instantTab: {
    padding: 32,
  },
  underlineBottom: {
    borderBottom: '1px solid #E5EBF0',
  },
  valueSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  dateTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  informationContainer: {
    display: 'flex',
    padding: '16px 0px 0px 14px',
    gap: 32,
  },
  filterContainer: {
    display: 'flex',
  },
  slotsdateTimeContainer: {
    display: 'flex',
  },
  specificDateContainer: {
    padding: 0,
    display: 'flex',
    // gap: 40,
    alignItems: 'flex-start',
    width: '100%',
  },
  specificDateContainer2: {
    padding: 0,
    display: 'flex',
    width: '100%',
  },
  specificDateandTimeContainer: {
    padding: '32px 0px 0px 24px',
    display: 'flex',
    flexDirection: 'column',
  },
  infoLabels: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },
  toggleFilter: {
    display: 'flex',
    gap: 24,
    flexDirection: 'column',
  },
  filterBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    paddingLeft: 14,
  },
  filterHeading: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '16px',
  },
  filterToggle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  slotsssContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 32,
    width: '100%',
    marginTop: '1.5rem',
  },
  slotsssContainer2: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    justifyContent: 'center',
  },
  slotsssContainerRecuring: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '360px',
    height: '100%',
  },
  availablelabel: {
    color: colors.success500,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  notAvailableLabel: {
    color: colors.neutral500,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  timezoneLabel: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 500,
    lineHeight: '20px',
    fontSize: 14,
    gap: 12,
    color: '#4C5965 !important',
    cursor: 'pointer',
  },
  providerTitle: {
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 18,
  },
  providerListContainer: {
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
  },
  plOneTime: {
    marginTop: '1.5em',
    padding: '0px 5rem 2rem 0.875rem',
  },
  providerAppDetailContainer: {
    display: 'flex',
    gap: 40,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  providerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 40px 24px 24px',
    borderRadius: 8,
    border: '1px solid',
    borderColor: colors.neutral200,
    cursor: 'pointer',
  },
  slotHeading: {
    fontWeight: 500,
    // fontFamily: 'inter',
    fontSize: 14,
    lineHeight: '16px',
  },
  slotContainer2: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    maxHeight: '124vh',
    overflowY: 'auto',
    maxWidth: 'fit-content',
    paddingRight: '10px',
    marginTop: '12px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#b4b4b4',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f5f5f5',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,0.4)',
    },
  },
  slotContainerRecurring: {
    display: 'flex',
    flexWrap: 'wrap',

    gap: 12,
    maxHeight: 588,
    width: '100%',
    overflowY: 'auto',
    justifyContent: 'center',
  },
  slotIndicator: {
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  timeSlot: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    borderRadius: 6,
    border: '1px solid #D0D6DD',
    justifyContent: 'center',
    cursor: 'pointer',
    height: 56,
    width: '20rem',
  },
  timeSlotRecurring: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    borderRadius: 6,
    border: '1px solid #D0D6DD',
    justifyContent: 'center',
    cursor: 'pointer',
    height: 56,
    width: '20rem',
  },
  slotTextBox: {
    height: 24,
    width: 70,
    display: 'flex',
    justifyContent: 'center',
  },
  select: {
    width: '21rem',
    '& .MuiInputBase-input': {
      padding: '0px !important',
      margin: '15px',
    },
  },
  editIcon: {
    width: 18,
    height: 18,
  },
  timezoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
    paddingBottom: 16,
  },
  availabilitySlotContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  availabilitySlot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  availabilitySlotActionContainer: {},
  dateTimeContainer2: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
  insuranceText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
  insuranceText2: {
    fontSize: 16,
  },
  insuranceIcon: {
    width: 16,
    height: 16,
  },
  noInsuranceIcon: {
    width: 20,
    height: 20,
  },
  dashedLine: {
    color: colors.neutral300,
    borderBottom: '2px dashed',
    width: 670,
    height: '10px',
  },
  text14_2: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
  },
  fetchedSlotsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 100,
    width: '400px',
  },
  justifyFlexSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noSlot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
  noSlotOneTime: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '144px 0px 0px 120px',
    alignItems: 'center',
    height: '300px',
  },
  pr: {
    paddingRight: 40,
  },
});

export const useStyles2 = makeStyles({
  reviewWrap: {
    padding: '0px 45px',
  },
  reviewSection: {
    padding: '25px 0px',
  },
  section: {
    position: 'relative',
    padding: '16px 24px',
  },
  timeSelect: {
    paddingLeft: 26,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    width: '25%',
  },
  valueSection: {
    width: '75%',
  },
  valueSection2: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  valueSection3: {
    width: '75%',
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  text18: {
    fontSize: 18,
    lineHeight: '24px',
  },
  text16: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },
  text16_2: {
    fontSize: 16,
    color: colors.neutral600,
    lineHeight: '24px',
    fontWeight: 500,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
    paddingBottom: 16,
  },
  select: {
    width: '100%',
  },
  label: {
    display: 'block',
  },
  text16_3: {
    fontSize: 16,
    color: colors.neutral500,
    lineHeight: '24px',
    fontWeight: 600,
  },
});
