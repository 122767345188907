import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Stepper } from '@confidant-health/lib/ui/molecules/stepper';

import { conversationActionCreators } from 'redux/modules/conversation/actions';
import { IReportDTO, IReportData, IReportFilter, ReportFilterRule } from 'redux/modules/reporting/types';
import { reportingActionCreators } from 'redux/modules/reporting';
import { priorityActionCreators } from 'redux/modules/priority';
import { selectReportSaving } from 'redux/modules/reporting/selectors';
import { showSnackbar } from 'redux/modules/snackbar';
import { getReportAllFiltersQuery } from 'pages/admin/report-detail/ReportDetail.constants';
import { getElkPatientCount } from 'services/reporting/reporting.service';
import { profileActionCreators } from 'redux/modules/profile';

import { IAutomationFilter } from '../../automations/add-automation/AddAutomation.types';
import { IAddAutomationProps } from './AddReport.types';

// components
import ReportFilters from './report-name-filters';
import ReportDataIncluded from './report-data-included';

import { stepTitles } from './AddReport.constants';
import { useStyles } from './AddReport.styles';

const AddReport: React.FC<IAddAutomationProps> = ({ isOpen, report, onClose, refreshReport = null }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [reportName, setReportName] = useState('');
  const [referralPartnerId, setReferralPartnerId] = useState('');
  const [filters, setFilters] = useState<IAutomationFilter[]>([]);
  const [dataIncluded, setDataIncluded] = useState<IReportData>(null);
  const isSaving = useSelector(selectReportSaving);
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = {
      searchQuery: '',
      type: '',
      method: '',
      pageNumber: 0,
      pageSize: 2000,
    };
    const conversationQueryParams = {
      pageNumber: 1,
      pageSize: 10000,
      status: 'ACTIVE',
      reportingView: true,
    };
    dispatch(profileActionCreators.fetchDemographicMetadata());
    dispatch(conversationActionCreators.fetchProfileElements(queryParams));
    dispatch(
      conversationActionCreators.fetchDCTs({
        ...conversationQueryParams,
        pageNumber: 0,
        assignmentCount: false,
        type: '',
      }),
    );
    dispatch(conversationActionCreators.fetchSessionTypes());
    dispatch(priorityActionCreators.fetchPriorityDomainElements());
    dispatch(conversationActionCreators.fetchConversations(conversationQueryParams));
    dispatch(
      conversationActionCreators.fetchEvaluations({
        ...conversationQueryParams,
        searchQuery: '',
        orderBy: '',
        sortBy: '',
      }),
    );
  }, []);

  useEffect(() => {
    if (report) {
      setFilters(
        report.reportFilters?.map(item => ({
          type: item.type,
          profileElement: item?.profileElement || '',
          automationRule: item.rule,
          automationValue: item.value,
          states: item?.states || [],
          levelOfEngagements: item?.levelOfEngagements || [],
          memberState: item?.insurance?.state || '',
          insuranceCarriers: item?.insurance?.carriers || [],
          providers: item?.providers || [],
        })),
      );
      setDataIncluded(report.data);
      setReportName(report.reportName);
    } else {
      setFilters([]);
      setDataIncluded(null);
      setReportName('');
    }
  }, [report, isOpen]);

  useEffect(() => {
    if (isOpen && !isSaving) {
      onClose();
    }
    refreshReport && refreshReport();
  }, [isSaving]);
  const handleAddReport = (payload: IReportData) => {
    const reportFilters: Array<IReportFilter> = filters?.map(filter => ({
      // logic: filter.type,
      type: filter.type,
      states: filter.states?.map(state => state?.value ?? state),
      levelOfEngagements: filter.levelOfEngagements?.map(level => level?.value ?? level),
      insurance: {
        state: filter.memberState.value ?? filter.memberState,
        carriers: filter.insuranceCarriers?.map(carrier => carrier?.value ?? carrier),
      },
      providers: filter.providers?.map(provider => provider?.value ?? provider),
      profileElement: filter.profileElement?.id ?? filter.profileElement,
      rule: filter.automationRule as ReportFilterRule,
      value: String(filter.automationValue),
    }));

    const reportDTOWithoutTotalMembers = {
      reportFilters: reportFilters?.filter(item => item.type),
      data: payload,
      reportName,
      rolesHavingAccess: ['PRACTITIONER'],
    };
    const createUpdateReport = (reportDTO: IReportDTO) => {
      if (!report) {
        dispatch(reportingActionCreators.createReport({ data: reportDTO }));
      } else {
        dispatch(reportingActionCreators.updateReport({ id: report._id, data: reportDTO }));
      }
    };
    getElkPatientCount(
      getReportAllFiltersQuery({
        ...reportDTOWithoutTotalMembers,
        createdAt: '',
        createdBy: {
          designation: '',
          firstName: '',
          lastName: '',
          fullName: '',
          matchmaker: false,
          profileImage: '',
          userAccountId: '',
        },
        _id: '',
        updatedAt: '',
        totalMembers: 0,
      }),
    )
      .then(res => {
        if (res?.data?.count) {
          const reportDTO: IReportDTO = {
            reportFilters: reportFilters?.filter(item => item.type),
            data: payload,
            reportName,
            referralPartnerId,
            rolesHavingAccess: ['PRACTITIONER'],
            totalMembers: res?.data?.count ?? 0,
          };
          const hasData = Object.keys(reportDTO.data)?.filter(key => reportDTO.data[key]?.includeInReport);
          if (!hasData?.length) {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Please select at least one item to include in report',
              }),
            );
            return;
          }
          createUpdateReport(reportDTO);
          return;
        }

        const reportDTO: IReportDTO = {
          reportFilters: reportFilters?.filter(item => item.type),
          data: payload,
          reportName,
          referralPartnerId,
          rolesHavingAccess: ['PRACTITIONER'],
          totalMembers: 0,
        };
        const hasData = Object.keys(reportDTO.data)?.filter(key => reportDTO.data[key]?.includeInReport);
        if (!hasData?.length) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Please select at least one item to include in report',
            }),
          );
          return;
        }
        createUpdateReport(reportDTO);
      })
      .catch(err => {
        console.warn(err);
        const reportDTO: IReportDTO = {
          reportFilters: reportFilters?.filter(item => item.type),
          data: payload,
          reportName,
          referralPartnerId,
          rolesHavingAccess: ['PRACTITIONER'],
          totalMembers: 0,
        };
        const hasData = Object.keys(reportDTO.data)?.filter(key => reportDTO.data[key]?.includeInReport);
        if (!hasData?.length) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Please select at least one item to include in report',
            }),
          );
          return;
        }
        createUpdateReport(reportDTO);
      });
  };

  const onCloseClick = () => {
    if (currentStep === 0) {
      setFilters([]);
      onClose();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onClickContinue = (payload: any) => {
    switch (currentStep) {
      case 0:
        setFilters(payload.filters);
        setReportName(payload.reportName);
        setReferralPartnerId(payload.referralPartner || '');
        break;
      default: {
        handleAddReport(payload);
        break;
      }
    }
    if (currentStep < 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0);
    }
  }, [isOpen]);

  const renderStep = step => {
    switch (step) {
      case 0:
        return (
          <ReportFilters
            name={reportName}
            filters={filters}
            referralPartnerId={report?.referralPartnerId}
            onClose={onCloseClick}
            onContinue={onClickContinue}
          />
        );
      default:
        return <ReportDataIncluded onClose={onCloseClick} data={dataIncluded} onContinue={onClickContinue} />;
    }
  };

  return (
    <Drawer open={isOpen} onClose={onCloseClick} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text weight={fontWeight.BOLD} className={classes.headTitle}>
              {report ? 'Edit' : 'Add'} report
            </Text>
            <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
          </Box>
          <Stepper steps={Object.values(stepTitles)} activeStep={currentStep} />
        </Box>
        <Box className={classes.content}>{renderStep(currentStep)}</Box>
      </Box>
    </Drawer>
  );
};

export { AddReport };
