import { DomainTypesEnum } from 'constants/CommonConstants';
import { HistoryLookup, IDomainElement } from 'redux/modules/profile/types';

export interface ISymptomPayload {
  groupId: string;
  item: {
    value: string;
    label: string;
  };
  description?: string;
  username?: string;
  time?: string;
  thumbnail?: string;
  status: string;
  isInterference?: boolean;
  reportCount?: number;
  notes?: string;
  icd10?: string;
  progress?: ReportItemProgress;
  medicalConditions?: any[];
}

export enum ReportItemProgress {
  Completed = 'Completed',
  InProgress = 'In progress',
  Incomplete = 'Incomplete',
}

export type ReportItem = {
  groupId: string;
  label: string;
  items: {
    label: string;
    value: string;
  }[];
  relatedElements?: any; // TODO: update this type
};

export type IAddNewReportProps = {
  isOpen: boolean;
  isNoDrawer?: boolean;
  title: string;
  type?: DomainTypesEnum;
  isLifeEvent?: boolean;
  searchLabel: string;
  showInterference?: boolean;
  onSubmit: (payload: ISymptomPayload[]) => void;
  stepTitles: {
    [key: number]: string;
  };
  options?: ReportItem[];
  onClose: () => void;
  optionsLoading?: boolean;
};

export type IUnit = {
  amount: number;
  unit: string;
};

export interface ISubstanceUse {
  groupId: string;
  item: {
    value: string;
    label: string;
  };
  description?: string;
  username?: string;
  time?: string;
  thumbnail?: string;
  status: string;
  isInterference?: boolean;
  notes?: string;
  reportCount?: number;
  tutorial: {
    lastUse: string;
    method: string;
    dose: IUnit;
    frequency: string;
    duration: string;
    refillable: boolean;
    refillTimes: IUnit;
  };
  progress?: ReportItemProgress;
  domainElementId?: string;
}

export type ISubstanceUseCardProps = {
  item: IDomainElement;
  lookupData?: HistoryLookup;
  showBadge?: boolean;
  isOnlyView?: boolean;
  showNotes?: boolean;
  onClick?(item: IDomainElement, isEdit?: boolean): void;
};

export type IAddNewReportSubstanceProps = {
  isOpen: boolean;
  isNoDrawer?: boolean;
  onClose: () => void;
  options?: ReportItem[];
  lookupData?: HistoryLookup;
  onSubmit?: (data: ISubstanceUse[]) => void;
  optionsLoading?: boolean;
};

export type IAddNewReportMedicationsProps = {
  isOpen: boolean;
  onClose: () => void;
  isNoDrawer?: boolean;
  options: ReportItem[];
  lookupData?: HistoryLookup;
  onSubmit: (data) => void;
  memberId?: string;
  optionsLoading?: boolean;
};

export interface IMedication {
  id?: string;
  groupId?: string;
  item?: {
    value: string;
    label: string;
  };
  description?: string;
  username?: string;
  time?: string;
  thumbnail?: string;
  status?: string;
  isInterference?: boolean;
  notes?: string;
  domainElementId?: string;
  tutorial?: {
    dose?: number;
    frequency?: string;
    duration?: IUnit;
    refillable?: boolean;
    refillTimes?: IUnit;
    confidantProviderId?: string;
    confidantProviderName?: string;
    doseFrequency?: number;
    doseUnit?: string;
    prescribingProvider?: string;
    providerName?: string;
    supply?: number;
    supplyUnit?: string;
    symptomsBeingTreated?: string[];
    dosage?: string;
    // refillable?: boolean;
    // /   confidantProviderId: payload?.confidantProvider,
    //   confidantProviderName: payload?.confidantProviderName,
    //   dosage: payload?.dosage,
    //   dose: 0,
    //   doseFrequency: 0,
    //   doseUnit: '',
    //   frequency: payload?.frequency,
    //   prescribingProvider: payload?.provider === 'Confidant Provider' ? 'Internal' : 'External',
    //   providerName: payload?.providerName,
    //   refillable: true,
    //   supply: 0,
    //   supplyUnit: '',
    //   symptomsBeingTreated: payload?.symptoms,
  };
  progress?: ReportItemProgress;
}

export type IMedicationCardProps = {
  medication: IDomainElement;
  showBadge?: boolean;
  isOnlyView?: boolean;
  onClick?(medication: IDomainElement, isEdit?: boolean): void;
};
