import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  items: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    padding: 24,
    flexWrap: 'wrap',
  },
  item: {
    flex: 1,
    '@media (max-width: 996px)': {
      flex: 'unset',
      width: '48%',
    },
    '@media (max-width: 640px)': {
      flex: 'unset',
      width: '100%',
    },
  },
  chart: {
    position: 'relative',
    height: 'auto',
    paddingBottom: 20,
    '& .legend-badge-value': {
      display: 'inline-block',
      marginLeft: '8px',
      fontSize: 12,
      height: 18,
      lineHeight: '16px',
      fontFamily: 'Manrope',
      fontWeight: '600',
      color: colors.neutral700,
      borderRadius: '20px',
      border: `1px solid ${colors.neutral200}`,
      padding: '1px 8px 0',
    },
    '& .apexcharts-legend.apx-legend-position-right': {
      overflow: 'hidden !important',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
    },
    '& .apexcharts-legend-marker': {
      marginRight: 16,
    },
    '& .apexcharts-legend-series': {
      margin: '0 0 20px !important',
    },
    '& .legend-wrap': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 32,
      marginLeft: 16,
      '&-left': {
        width: 200,
      },
    },
    '& .chartWrap-tooltip': {
      padding: 16,
      backgroundColor: colors.white,
      width: 'fit-content',
      '& .date': {
        fontSize: '14px !important',
        lineHeight: '20px',
        color: colors.neutral600,
      },
      '& .level': {
        color: colors.neutral900,
        fontWeight: 700,
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        whiteSpace: 'wrap',
        marginTop: 12,
        '& .value': {
          borderRadius: '50%',
          width: 22,
          height: 22,
          border: `1px solid ${colors.neutral200}`,
          fontSize: 12,
          lineHeight: '16px',
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: colors.neutral700,
          textAlign: 'center',
        },
        '& .row-left': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .dot': {
          width: 12,
          height: 12,
          borderRadius: '50%',
          marginRight: 8,
          '&.dot-0': {
            backgroundColor: colors.primary500,
          },
          '&.dot-1': {
            backgroundColor: colors.secondary500,
          },
          '&.dot-2': {
            backgroundColor: colors.neutral600,
          },
        },
      },
    },
    '& .apexcharts-canvas svg': {
      width: 'calc(100vw - 400px)',
      '& foreignObject': {
        width: '100%',
      },
    },
    '& .apexcharts-legend.apexcharts-align-center.apx-legend-position-right': {
      width: '400px !important',
      height: 'auto !important',
      left: '255px !important',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
});
