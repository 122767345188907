import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { Text } from '@confidant-health/lib/ui/atoms/typography';

import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';
import GroupCard from 'pages/provider/member-detail/components/Groups/GroupCard';

import { groupsMock } from './Groups.constants';
import { useStyles } from './Groups.styles';

const Groups: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { providerId } = useParams();
  const { userGroups = groupsMock, isLoading, errorMsg } = useSelector(getProfile);

  useEffect(() => {
    if (providerId) {
      dispatch(profileActionCreators.fetchUserGroups(providerId));
    }
  }, [providerId]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <>
          {userGroups.length > 0 ? (
            userGroups.map((value, index) => <GroupCard key={index} {...value} />)
          ) : (
            <Text className={clsx(classes.noItem, { [classes.errorText]: !!errorMsg })}>
              {errorMsg || 'No group'}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};

export { Groups };
