// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    backgroundColor: '#fff',
    borderRadius: 8,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '&:hover $viewBtn': {
      display: 'block',
    },
    marginBottom: 16,
  },
  dlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 24,
    minHeight: 96,
    '&:first-child': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  badgeResult: {
    height: 28,
    fontSize: 14,
    color: '#DD0374',
  },
  title: {
    fontSize: 18,
    lineHeight: '32px',
    fontWeight: '600',
    color: '#0D0F11',
    margin: 0,
  },
  typeText: {
    fontWeight: '500',
    fontSize: 14,
    color: '#4C5965',
  },
});
