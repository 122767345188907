import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';

import { TabPanel, Tabs } from '@confidant-health/lib/ui/atoms/tabs';
import { fontWeight, Text, TextError, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import MuiDatePicker from '@confidant-health/lib/ui/templates/date-select';
import { selectProviderServicesState } from 'redux/modules/appointment/selectors';
import { ISearchScheduleParams, searchSchedule } from 'services/appointment/appointment.service';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { appointmentActionCreators } from 'redux/modules/appointment';
import dayjs, { getUserTimeZone } from 'utils/dayjs';
import { tz } from 'moment-timezone';

import { appointmentSchedulingTabs } from '../../AppointmentList.constants';
import { INewSchedulePayload, ISearchScheduleResponse, ISlot } from '../AddSchedule.types';
import { useStyles } from './StepThree.styles';
import { US_TIMEZONES } from '../../../../provider/profile/Profile.constants';

type Props = {
  formData: INewSchedulePayload;
  selectedDateTime?: string;
  error: string;
  onChange: (item: ISlot) => void;
  onChangeService(id: string): void;
  serviceId?: string;
};

const StepThree: FC<Props> = ({
  formData,
  onChange,
  error,
  selectedDateTime,
  onChangeService,
  serviceId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { services } = useSelector(selectProviderServicesState);
  const [selectedServiceId, setSelectedServiceId] = useState(serviceId || '');
  const [currentTab, setCurrentTab] = useState(appointmentSchedulingTabs[selectedDateTime ? 1 : 0].tabKey);
  const [timeSlots, setTimeSlots] = useState<{ date: string; slots: ISlot[] }[]>([]);
  const [filteredTimeSlots, setFilteredTimeSlots] = useState<{ date: string; slots: ISlot[] }[]>([]);
  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorApi, setErrorApi] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<ISlot | null>(formData?.time);
  const [selectedDate, setSelectedDate] = useState(
    selectedDateTime ? new Date(selectedDateTime) : new Date(),
  );

  const [selectedTimeZone, setSelectedTimeZone] = useState(
    US_TIMEZONES.find(tzone => tzone.value === tz.guess())?.value || null,
  );
  const providerSchedule = useSelector(selectProviderSchedule);

  const onChangeDate = (date: Date) => {
    setSelectedDate(date);
    setSelectedTimeSlot(null);
  };

  const getAvailableSlotsCb = useCallback(
    async (date: Dayjs) => {
      if (!selectedServiceId) {
        return null;
      }
      try {
        setErrorApi(null);
        const params: ISearchScheduleParams = {
          participantIds: formData?.provider?.id,
          memberId: formData?.member?.id,
          serviceId: selectedServiceId,
          timezone: providerSchedule?.timezone || getUserTimeZone(),
          viewProviderDetails: false,
        };
        if (date) {
          const startDate = date.format('DD-MM-YYYY');
          const endDate = dayjs(date).add(7, 'd').format('DD-MM-YYYY');
          params.startDate = startDate;
          params.endDate = endDate;
        }

        const { data }: { data: ISearchScheduleResponse } = await searchSchedule(params);

        const slots = [];
        if (data.results.length > 0) {
          const reduced = data.results[0].slots.reduce((prev: any, slot) => {
            const dayString = dayjs(slot.start).format('DD-MM-YYYY');
            if (prev[dayString]) {
              prev[dayString].push(slot);
            } else {
              prev[dayString] = [slot];
            }
            return prev;
          }, {});
          Object.keys(reduced).forEach(dayString => {
            slots.push({
              date: dayjs(dayString, 'DD-MM-YYYY').toISOString(),
              slots: reduced[dayString],
            });
          });
        }

        return slots.sort((a, b) => dayjs(a.date, 'DD-MM-YYYY').unix() - dayjs(b.date, 'DD-MM-YYYY').unix());
      } catch (err) {
        setErrorApi(err?.data?.errors[0]?.endUserMessage || 'Something went wrong!');
        return null;
      }
    },
    [selectedServiceId, services],
  );

  const getSlotsByDate = async (date: Dayjs) => {
    if (!isLoading) {
      setIsLoading(true);
      const slots = (await getAvailableSlotsCb(date)) || [];
      setTimeSlots(slots);
      setFilteredTimeSlots(slots);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedServiceId) {
      if (currentTab === appointmentSchedulingTabs[1].tabKey && selectedDate) {
        void getSlotsByDate(dayjs(selectedDate));
      }
      if ([appointmentSchedulingTabs[0].tabKey, appointmentSchedulingTabs[2].tabKey].includes(currentTab)) {
        void getSlotsByDate(null);
      }
    }
  }, [currentTab, selectedDate, selectedServiceId]);

  useEffect(() => {
    dispatch(appointmentActionCreators.fetchProviderServices(formData?.provider?.id));
  }, [dispatch]);

  useEffect(() => {
    if (serviceId) {
      onChangeService(serviceId);
    }
  }, [serviceId]);

  const onChangeSessionType = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedServiceId(target.value);
    onChangeService(target.value);
  };

  const onChangeTimezone = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedTz = e.target.value && e.target.value.length > 0 ? e.target.value : null;
    setSelectedTimeZone(updatedTz);
  };

  const onTimeSlotClick = (timeSlot: ISlot) => () => {
    onChange(timeSlot);
    setSelectedTimeSlot(timeSlot);
  };

  const onChangeDateFilter = (date: string, isStartDate: boolean) => {
    const providedStartDate = isStartDate ? new Date(date) : dateRange.startDate;
    const providedEndDate = isStartDate ? dateRange.endDate : new Date(date);
    setSelectedTimeSlot(null);
    setDateRange(prev => ({ ...prev, [isStartDate ? 'startDate' : 'endDate']: date }));
    const filteredDates = timeSlots.filter(item => {
      if (providedStartDate && providedEndDate) {
        return new Date(item.date) >= providedStartDate && new Date(item.date) <= providedEndDate;
      }
      if (providedStartDate) {
        return new Date(item.date) >= providedStartDate;
      }

      if (providedEndDate) {
        return new Date(item.date) <= providedEndDate;
      }
      return null;
    });

    setDateRange(prev => ({ ...prev, [isStartDate ? 'startDate' : 'endDate']: new Date(date) }));
    setFilteredTimeSlots(filteredDates);
  };
  const renderLabel = (label: string) => (
    <Text className={clsx(classes.text14, classes.label)} level={textLevel.S} weight={fontWeight.BOLD}>
      {label}
    </Text>
  );
  const slotSliceFactor = currentTab === appointmentSchedulingTabs[0].tabKey ? 1 : filteredTimeSlots.length;
  return (
    <Box>
      <Tabs
        options={appointmentSchedulingTabs}
        value={currentTab}
        onChange={setCurrentTab}
        className={classes.tabs}
      />
      <Box className={classes.selectWrap}>
        {renderLabel('Session type')}
        <Select
          variant={selectType.SECONDARY}
          value={selectedServiceId}
          displayEmpty
          emptyText="Select session type"
          className={classes.select}
          onChange={onChangeSessionType}
          options={services?.map(s => ({ label: s.name, value: s.id }))}
        />
      </Box>
      <Box className={classes.selectWrap}>
        {renderLabel('Timezone')}
        <Select
          variant={selectType.SECONDARY}
          value={selectedTimeZone}
          displayEmpty
          emptyText="Select timezone"
          className={classes.select}
          onChange={onChangeTimezone}
          options={US_TIMEZONES}
        />
      </Box>
      <TabPanel value={currentTab} tabKey={appointmentSchedulingTabs[1].tabKey}>
        <Box className={classes.tabPanel}>
          <Box className={classes.selectWrap}>
            {renderLabel('Date')}
            <MuiDatePicker
              inputClassName={classes.select}
              value={selectedDate.toISOString()}
              minDate={new Date()}
              onChange={onChangeDate}
            />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={currentTab} tabKey={appointmentSchedulingTabs[2].tabKey}>
        <Box className={classes.tabPanel}>
          <Box className={classes.selectWrap}>
            {renderLabel('Date Range')}
            <Input
              drawerWidth
              variant={inputType.CALENDARFILTER}
              onChange={onChangeDateFilter}
              value={dateRange}
              iconcolor={colors.neutral400}
            />
          </Box>
        </Box>
      </TabPanel>
      {(errorApi || error) && (
        <Box display="flex" justifyContent="center" flex={1} padding={2}>
          <TextError errorMsg={errorApi || error} />
        </Box>
      )}
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <Box className={classes.timeSlots}>
          {filteredTimeSlots.slice(0, slotSliceFactor).map((item, idx) => {
            const slots: ISlot[] =
              currentTab === appointmentSchedulingTabs[0].tabKey ? item.slots.slice(0, 2) : item.slots;
            return (
              <Box key={idx}>
                <Text className={classes.day}>{dayjs(item.date).format('MMMM DD, YYYY')}</Text>
                <Box className={classes.timeSlotItems}>
                  {slots.map((slot, index) => (
                    <Box
                      key={index}
                      className={clsx(classes.timeSlot, {
                        timeSlotActive: selectedTimeSlot?.start === slot.start,
                      })}
                      onClick={onTimeSlotClick(slot)}
                    >
                      {`${dayjs(slot.start)
                        .tz(selectedTimeZone ?? tz.guess())
                        .format('h:mm a')} — ${dayjs(slot.end)
                        .tz(selectedTimeZone ?? tz.guess())
                        .format('h:mm a')}`}
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          })}
          {!isLoading && filteredTimeSlots.length === 0 && (
            <Text className={clsx(classes.day, classes.textCenter)}>No time slot available</Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StepThree;
