import { FC } from 'react';
import dayjs from 'dayjs';
// components
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';

import { useSelector } from 'react-redux';
import { getProfile } from 'redux/modules/profile/selectors';
import { IDemographicDetails } from 'redux/modules/profile/types';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';

// styles
import { useStyles } from '../../MemberDetail.styles';

const PrivacyAndConcents: FC = () => {
  const classes = useStyles({});
  const { demographicDetails = {} as IDemographicDetails } = useSelector(getProfile);

  const handleDateFormat = (signedDate: string) => {
    return signedDate ? dayjs(signedDate).format(`[Signed on] ${GLOBAL_DATE_FORMAT} [at] h:mm a`) : '';
  };

  const privacyPolDt = handleDateFormat(demographicDetails.privacyPolicy);
  const exclusionCritDt = handleDateFormat(demographicDetails.pcpRelease);
  const hipaaDt = handleDateFormat(demographicDetails.hipaa);

  return (
    <div className={classes.card}>
      <div className={classes.cardTopSection}>
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          Privacy & consents
        </Heading>
      </div>
      <div>
        <div className={classes.item}>
          <Text className={classes.textDark} level={textLevel.M} weight={fontWeight.MEDIUM}>
            Privacy Policy
          </Text>
          <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            {privacyPolDt}
          </Text>
        </div>
        <div className={classes.item}>
          <Text className={classes.textDark} level={textLevel.M} weight={fontWeight.MEDIUM}>
            Exclusion Criteria
          </Text>
          <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            {exclusionCritDt}
          </Text>
        </div>
        <div className={classes.item}>
          <Text className={classes.textDark} level={textLevel.M} weight={fontWeight.MEDIUM}>
            HIPAA Consents
          </Text>
          <Text className={classes.textLight} level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
            {hipaaDt}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndConcents;
