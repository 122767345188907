import { FC } from 'react';
import { Box } from '@mui/system';
import { Modal } from '@mui/material';
import { fontWeight, Heading, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { useStyles } from './index.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};
const ShowConfirmModal: FC<Props> = ({ open, onClose, onConfirm }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.content}>
            <Box>
              <Heading className={classes.modalTitle}>
                Required questions need to be answered. Complete without answering?
              </Heading>
              <Text level={textLevel.M} weight={fontWeight.MEDIUM} color={colors.neutral600}>
                Answers are typically required because they have significant clinical importance.
              </Text>
              <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
            </Box>
            <Box className={classes.modalButtons}>
              <Button onClick={onClose} className={classes.modalCloseButton}>
                Cancel
              </Button>
              <Button variant={btnType.PRIMARY} onClick={onConfirm} className={classes.modalButton}>
                Leave unanswered
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export { ShowConfirmModal };
