/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    color: colors.neutral600,
  },
  radioGroup: {
    gap: 16,
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  helperText: {
    fontFamily: 'Manrope',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: '20px',
  },
  form: {
    flex: 1,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  input: {
    height: 64,
  },
  select: {
    height: 64,
  },
  checkboxLabel: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
});
