/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { IReport, ReportFilterRule } from 'redux/modules/reporting/types';
import { REPORT_FILTERS } from 'constants/CommonConstants';
import { getTimeDifference } from 'utils/dayjs';
import { ILoginReport } from './Logins.types';
import {
  getReportAllFiltersSubQuery,
  getReportAllFiltersSubQueryReferralPartner,
  getReportFilterSubQuery,
  REPORT_CONSTANTS,
} from '../../ReportDetail.constants';

const boxDataMap = {
  'total logins': 'totalLogins',
  'average logins': 'averageLogins',
  'Most time since last login': 'lastLogin',
};

export const getBoxData = (loginReport: ILoginReport, report: IReport) => {
  const boxData = [
    {
      label: 'total logins',
      value: loginReport?.aggregations?.total_login?.buckets[0]?.doc_count?.toString() ?? '0',
    },
    {
      label: 'average logins',
      value: Math.round((loginReport?.aggregations?.total_login_last30days?.doc_count ?? 0) / 30).toString(),
    },
    {
      label: 'Most time since last login',
      value: loginReport
        ? getTimeDifference(
            new Date(loginReport?.aggregations?.last_login?.hits?.hits[0]?._source?.activityDateTime),
            new Date(),
          )
        : '',
    },
  ];
  return boxData.filter(item => report?.data?.loginSection[boxDataMap[item.label]]?.isChecked) ?? [];
};

export const getCurrent30DaysCounts = (report: ILoginReport) =>
  report
    ? report?.aggregations?.last_30_days?.count?.buckets?.map(bucket => bucket?.doc_count).slice(0, 30)
    : [];

export const getPast30DaysCounts = (report: ILoginReport) =>
  report
    ? report?.aggregations?.past_30_days?.counts?.buckets?.map(bucket => bucket?.doc_count).slice(0, 30)
    : [];

export const getMostLoginsData = (report: ILoginReport) =>
  report
    ? report?.aggregations?.most_logins?.buckets?.map(bucket => ({
        member: { name: bucket?.key },
        total: bucket?.doc_count,
        lastUpdated: new Date(
          bucket?.last_login_date?.hits?.hits[0]?._source?.activityDateTime,
        )?.toLocaleDateString(),
      }))
    : [];

export const getFewestLoginsData = (report: ILoginReport) =>
  report
    ? report?.aggregations?.fewest_logins?.buckets?.map(bucket => ({
        member: { name: bucket?.key },
        total: bucket?.doc_count,
        lastUpdated: new Date(
          bucket?.last_login_date?.hits?.hits[0]?._source?.activityDateTime,
        )?.toLocaleDateString(),
      }))
    : [];

export const getMostRecentLoginsData = (report: ILoginReport) =>
  report
    ? report?.aggregations?.most_recent_logins?.buckets?.map(bucket => ({
        member: { name: bucket?.username?.buckets[0]?.key || '' },
        total: bucket?.doc_count,
        lastUpdated: new Date(bucket?.key_as_string)?.toLocaleDateString(),
      }))
    : [];

export const getMostSinceLastLoginData = (report: ILoginReport) =>
  report
    ? report?.aggregations?.most_since_last_login?.buckets?.map(bucket => ({
        member: { name: bucket?.key || '' },
        total: bucket?.doc_count,
        lastUpdated: new Date(
          bucket?.last_login_date?.hits?.hits[0]?._source?.activityDateTime,
        )?.toLocaleDateString(),
        daysSince: bucket?.no_days?.value,
      }))
    : [];

export const loginColumns: TableGrid.TableColumn[] = [
  {
    id: 'member',
    label: 'Member',
    sortable: false,
  },
  {
    id: 'total',
    label: 'Total',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];
export const lastLoginColumns: TableGrid.TableColumn[] = [
  {
    id: 'member',
    label: 'Member',
    sortable: false,
  },
  {
    id: 'daysSince',
    label: 'Days since',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const getLoginsQuery = (report: IReport) => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    return {
      size: 0,
      query: {
        bool: {
          must: [
            {
              term: {
                'activityActionType.keyword': {
                  value: 'LOGIN',
                },
              },
            },
            ...[
              ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
              ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
              ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
            ]?.filter(obj => Object.keys(obj)?.length > 0),
          ],
        },
      },
      aggs: {
        total_members: {
          cardinality: {
            field: 'user_activity_id.keyword',
          },
        },
        total_login: {
          terms: {
            field: 'activityActionType.keyword',
            size: 10,
          },
        },
        last_login: {
          top_hits: {
            _source: 'activityDateTime',
            size: 1,
            sort: [
              {
                activityDateTime: { order: 'desc' },
              },
            ],
          },
        },
        total_login_last30days: {
          filter: {
            range: {
              activityDateTime: {
                gte: 'now-30d/d',
              },
            },
          },
        },
        last_30_days: {
          filter: {
            range: {
              activityDateTime: {
                gte: 'now-30d/d',
                lte: 'now/d',
              },
            },
          },
          aggs: {
            count: {
              date_histogram: {
                field: 'activityDateTime',
                calendar_interval: '1d',
              },
            },
          },
        },
        past_30_days: {
          filter: {
            range: {
              activityDateTime: {
                gte: 'now-60d/d',
                lt: 'now-30d/d',
              },
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'activityDateTime',
                calendar_interval: '1d',
              },
            },
          },
        },
        most_logins: {
          terms: {
            field: 'userName.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            order: {
              _count: 'desc',
            },
          },
          aggs: {
            last_login_date: {
              top_hits: {
                _source: 'activityDateTime',
                sort: [{ activityDateTime: { order: 'desc' } }],
                size: 1,
              },
            },
          },
        },
        fewest_logins: {
          terms: {
            field: 'userName.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            order: {
              _count: 'asc',
            },
          },
          aggs: {
            last_login_date: {
              top_hits: {
                _source: 'activityDateTime',
                sort: [{ activityDateTime: { order: 'desc' } }],
                size: 1,
              },
            },
          },
        },
        most_recent_logins: {
          terms: {
            field: 'activityDateTime',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            order: {
              _key: 'desc',
            },
          },
          aggs: {
            username: {
              terms: {
                field: 'userName.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
            },
          },
        },
        most_since_last_login: {
          terms: {
            field: 'userName.keyword',
            order: {
              _key: 'desc',
            },
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            last_login_date: {
              top_hits: {
                _source: 'activityDateTime',
                sort: [{ activityDateTime: { order: 'desc' } }],
                size: 1,
              },
            },
            no_days: {
              max: {
                script: "(new Date().getTime() - doc['activityDateTime'].value.getMillis())/1000/86400 ",
              },
            },
            sorf: {
              bucket_sort: {
                sort: [{ no_days: { order: 'desc' } }],
              },
            },
          },
        },
      },
    };
  }
  return {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              'activityActionType.keyword': {
                value: 'LOGIN',
              },
            },
          },
        ],
      },
    },
    aggs: {
      total_members: {
        cardinality: {
          field: 'user_activity_id.keyword',
        },
      },
      total_login: {
        terms: {
          field: 'activityActionType.keyword',
          size: 10,
        },
      },
      last_login: {
        top_hits: {
          _source: 'activityDateTime',
          size: 1,
          sort: [
            {
              activityDateTime: { order: 'desc' },
            },
          ],
        },
      },
      total_login_last30days: {
        filter: {
          range: {
            activityDateTime: {
              gte: 'now-30d/d',
            },
          },
        },
      },
      last_30_days: {
        filter: {
          range: {
            activityDateTime: {
              gte: 'now-30d/d',
              lte: 'now/d',
            },
          },
        },
        aggs: {
          count: {
            date_histogram: {
              field: 'activityDateTime',
              calendar_interval: '1d',
            },
          },
        },
      },
      past_30_days: {
        filter: {
          range: {
            activityDateTime: {
              gte: 'now-60d/d',
              lt: 'now-30d/d',
            },
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'activityDateTime',
              calendar_interval: '1d',
            },
          },
        },
      },
      most_logins: {
        terms: {
          field: 'userName.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          order: {
            _count: 'desc',
          },
        },
        aggs: {
          last_login_date: {
            top_hits: {
              _source: 'activityDateTime',
              sort: [{ activityDateTime: { order: 'desc' } }],
              size: 1,
            },
          },
        },
      },
      fewest_logins: {
        terms: {
          field: 'userName.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          order: {
            _count: 'asc',
          },
        },
        aggs: {
          last_login_date: {
            top_hits: {
              _source: 'activityDateTime',
              sort: [{ activityDateTime: { order: 'desc' } }],
              size: 1,
            },
          },
        },
      },
      most_recent_logins: {
        terms: {
          field: 'activityDateTime',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          order: {
            _key: 'desc',
          },
        },
        aggs: {
          username: {
            terms: {
              field: 'userName.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
          },
        },
      },
      most_since_last_login: {
        terms: {
          field: 'userName.keyword',
          order: {
            _key: 'desc',
          },
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          last_login_date: {
            top_hits: {
              _source: 'activityDateTime',
              sort: [{ activityDateTime: { order: 'desc' } }],
              size: 1,
            },
          },
          no_days: {
            max: {
              script: "(new Date().getTime() - doc['activityDateTime'].value.getMillis())/1000/86400 ",
            },
          },
          sorf: {
            bucket_sort: {
              sort: [{ no_days: { order: 'desc' } }],
            },
          },
        },
      },
    },
  };
};
