import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { priorityActionCreators } from 'redux/modules/priority';
import { PriorityGroupingSchema } from './AddEditPriorityGrouping.schema';
import { IAddNewProps } from './AddEditPriorityGrouping.types';
import { useStyles } from './AddEditPriorityGrouping.styles';

const AddEditPriorityGrouping: React.FC<IAddNewProps> = ({
  isOpen,
  onClose,
  editData,
  fetchDomainGroups,
  domainTypes,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const domainTypesOptions =
    domainTypes?.map(item => {
      return { label: item.name, value: item.Id };
    }) || [];
  const relatedElementsArray = editData?.relatedElements?.length
    ? editData?.relatedElements?.map(item => {
        return { title: item.name, value: item.Id };
      })
    : [];
  const defaultValues = {
    name: editData?.name ?? '',
    type: editData?.type?.Id ?? '',
    relatedElements: relatedElementsArray,
  };
  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: PriorityGroupingSchema,
    onSubmit: () => {
      const payload = {
        name: values.name,
        type: { Id: values.type },
        relatedElements: values.relatedElements.map(item => {
          return {
            Id: item.value,
          };
        }),
      };
      if (editData) {
        dispatch(
          priorityActionCreators.updatePriorityDomainGroup({
            data: { ...payload, Id: editData.Id },
            callback: (isSucceed: boolean) => {
              if (isSucceed) {
                fetchDomainGroups();
                onClose();
              }
            },
          }),
        );
      } else {
        dispatch(
          priorityActionCreators.createPriorityDomainGroup({
            data: payload,
            callback: (isSucceed: boolean) => {
              if (isSucceed) {
                fetchDomainGroups();
                onClose();
              }
            },
          }),
        );
      }
    },
  });

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeTags = async (_, val) => {
    await rest.setFieldValue('relatedElements', val);
  };

  const onChangeType = async e => {
    await rest.setFieldValue('relatedElements', []);
    await rest.setFieldValue('type', e.target.value);
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderElementsForSelection = () => {
    const selectedDomainType = domainTypes?.find(item => item.Id === values.type)?.actions;
    const relatedElements = [];
    let elementsOptions = [];
    if (selectedDomainType) {
      if (selectedDomainType?.children?.length === 0) {
        elementsOptions = [];
      } else if (selectedDomainType?.children?.length === relatedElements.length) {
        elementsOptions = [];
      } else if (selectedDomainType?.children?.length > 0) {
        elementsOptions = selectedDomainType?.children
          .filter(element => !relatedElements.includes(element.id))
          .map(item => {
            return { title: item.name, value: item.id };
          });
      }
    }
    return (
      <Box className={classes.section}>
        {renderLabel(`Select ${selectedDomainType?.name} Type Elements`)}
        <Input
          value={values.relatedElements}
          name="relatedElements"
          isOptionEqualToValue={(option, value) => option.value === value.value}
          className={classes.valuesInput}
          options={elementsOptions}
          onChange={onChangeTags}
          variant={inputType.TAGS}
          size={inputSize.M}
          fullWidth
        />
        <TextError errorMsg={touched.relatedElements ? errors.relatedElements?.toString() : null} />
      </Box>
    );
  };

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{`${editData ? 'Edit' : 'Add'} data domain grouping`}</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter a group name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Domain type')}
              <Select
                options={domainTypesOptions}
                value={values.type}
                displayEmpty
                emptyText="Select type"
                name="type"
                onChange={onChangeType}
                variant={selectType.SECONDARY}
                fullWidth
              />
              <TextError errorMsg={touched.type ? errors.type?.toString() : null} />
            </Box>
            {renderElementsForSelection()}
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>{`${editData ? 'Update' : 'Add'} data domain grouping`}</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddEditPriorityGrouping };
