import { createSelector } from 'reselect';
import { AppState } from 'redux/store/types';
import { ScheduleState } from './types';

export const selectScheduleState = (state: AppState): ScheduleState => state.schedule;

export const selectLoeReport = createSelector(selectScheduleState, scheduleState => scheduleState.loeReport);

export const selectProviderSchedule = createSelector(
  selectScheduleState,
  scheduleState => scheduleState.providerSchedule.schedule,
);
export const selectDailySchedule = createSelector(
  selectScheduleState,
  scheduleState => scheduleState.dailySchedule.schedule,
);

export const selectProviderScheduleLoading = createSelector(
  selectScheduleState,
  scheduleState => scheduleState.providerSchedule.isLoading,
);

export const selectProviderScheduleSaving = createSelector(
  selectScheduleState,
  scheduleState => scheduleState.providerSchedule.isSaving,
);

export const selectSystemServices = createSelector(
  selectScheduleState,
  scheduleState => scheduleState.systemServices.services,
);

export const selectSystemServicesLoading = createSelector(
  selectScheduleState,
  scheduleState => scheduleState.systemServices.isLoading,
);

export const selectAppointmentReport = createSelector(
  selectScheduleState,
  scheduleState => scheduleState.appointmentReport,
);

export const selectProviderServicesState1 = createSelector(
  selectScheduleState,
  scheduleState => scheduleState.providerServices,
);

export const getGraphDetails = createSelector(
  selectScheduleState,
  graphDetailsState => graphDetailsState.loeGraphDetails,
);

export const getLoeStatusDetails = createSelector(
  selectScheduleState,
  loeStatusDetails => loeStatusDetails.loeStatusDetails,
);
