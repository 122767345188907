import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { AppState } from 'redux/store/types';
import {
  selectConversations,
  selectPlanItemSaving,
  selectRevampTypes,
} from 'redux/modules/conversation/selectors';
import { selectSystemServices } from 'redux/modules/schedule/selectors';
import { selectProviderRoles } from 'redux/modules/appointment/selectors';
import { conversationActionCreators } from 'redux/modules/conversation/actions';
import { IUpdatePlanItemDTO, ICreatePlanItemDTO } from 'redux/modules/conversation/types';

// schema
import { PlanItemSchema } from './AddPlanItem.schema';
import {
  typeSelects,
  removeFromPlanSelects,
  PLAN_ITEMS,
  REVAMP_TYPES,
  getNavigatesToSelects,
  getProgressStateSelects,
  getCompleteStateSelects,
} from './AddPlanItem.constants';
// styles
import { useStyles } from './AddPlanItem.styles';
// constants
import { IAddNewProps } from './AddPlanItem.types';

const defaultValues = {
  name: '',
  type: '',
  referenceId: '',
  navigatesTo: '',
  progressState: '',
  completedState: '',
  removeCompleteXTimes: 0,
  removeReference: '',
  planToken: 0,
};

const AddPlanItem: React.FC<IAddNewProps> = ({
  isOpen,
  planItem,
  educationalContent,
  topics,
  onClose,
  filters,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const groups = useSelector((state: AppState) => state.profile.group.groups);
  const conversations = useSelector(selectConversations);
  const revampTypes = useSelector(selectRevampTypes);
  const services = useSelector(selectSystemServices);
  const providers = useSelector((state: AppState) => state.profile.providers);
  const providerRoles = useSelector(selectProviderRoles);
  const isSaving = useSelector(selectPlanItemSaving);
  const [activityOptions, setActivityOptions] = useState([]);
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [selectReference, setSelectReference] = useState({
    label: '',
    value: '',
  });

  const groupOptions = useMemo(() => groups.map(group => ({ label: group.name, value: group.id })), [groups]);
  const conversationOptions = useMemo(
    () =>
      conversations.map(conversation => ({ label: conversation.name, value: conversation.conversationId })),
    [conversations],
  );
  const providerOptions = useMemo(
    () => providers.map(provider => ({ label: provider.fullName, value: provider.id })),
    [providers],
  );
  const educationalOptions = useMemo(
    () => educationalContent.map(item => ({ label: item.title, value: item.id })),
    [educationalContent],
  );
  const topicOptions = useMemo(
    () => topics.map(topic => ({ label: topic.title, value: topic.id })),
    [topics],
  );
  const serviceOptions = useMemo(
    () => services?.map(service => ({ label: service.name, value: service.id })),
    [services],
  );
  const providerRoleOptions = useMemo(
    () =>
      Array.from(new Set(providerRoles.map(item => item.designation))).map(item => ({
        label: item,
        value: item,
      })),
    [providerRoles],
  );

  const { errors, values, handleChange, handleSubmit, setValues, setFieldValue, touched, ...rest } =
    useFormik({
      initialValues: { ...defaultValues },
      validationSchema: PlanItemSchema,
      onSubmit: ({ removeReference, removeCompleteXTimes, ...payload }: typeof defaultValues) => {
        if (planItem) {
          const data: IUpdatePlanItemDTO = {
            ...payload,
            filters,
            id: planItem.id,
            RevampPlanRemove: {
              reference: removeReference,
              completeXTimes: removeCompleteXTimes || 0,
            },
          };
          dispatch(conversationActionCreators.updatePlanItem(data));
        } else {
          const data: ICreatePlanItemDTO = {
            ...payload,
            filters,
            RevampPlanRemove: {
              reference: removeReference,
              completeXTimes: removeCompleteXTimes || 0,
            },
          };
          dispatch(conversationActionCreators.createPlanItem(data));
        }
      },
    });

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !isSaving) {
      onClose();
    }
  }, [isSaving]);

  useEffect(() => {
    if (isOpen && planItem) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setValues({
        name: planItem.name,
        type: planItem.type,
        navigatesTo: planItem.navigatesTo,
        referenceId: planItem.referenceId,
        completedState: planItem.completedState,
        progressState: planItem.progressState,
        removeCompleteXTimes: planItem.RevampPlanRemove.completeXTimes,
        removeReference: planItem.RevampPlanRemove.reference,
        planToken: planItem.planToken,
      });
    }
  }, [isOpen, planItem]);

  useEffect(() => {
    if (values.referenceId) {
      setSelectReference(referenceOptions.find(r => r.value === values.referenceId));
    }
  }, [values, referenceOptions]);

  useEffect(() => {
    let newActivities = [];
    const revampTypeActivity = revampTypes.find(record => record.name === REVAMP_TYPES.Activities);
    if (revampTypeActivity?.children?.length > 0) {
      revampTypeActivity.children.forEach(child => {
        if (child.revampMetaData?.valuesGroups?.length > 0) {
          child.revampMetaData.valuesGroups.forEach(valueGroup => {
            newActivities = [...newActivities, ...valueGroup.values];
          });
        }
      });
    }
    setActivityOptions(
      Array.from(new Set(newActivities.map(item => item.name))).map(item => ({
        label: item,
        value: item,
      })),
    );
  }, [revampTypes]);

  const getListDetailByType = (type: string) => {
    switch (type) {
      case PLAN_ITEMS.PLAN_ITEM_TYPE.EDUCATION:
        return educationalOptions;
      case PLAN_ITEMS.PLAN_ITEM_TYPE.TOPIC:
        return topicOptions;
      case PLAN_ITEMS.PLAN_ITEM_TYPE.ACTIVITY:
        return activityOptions;
      case PLAN_ITEMS.PLAN_ITEM_TYPE.GROUP:
        return groupOptions;
      case PLAN_ITEMS.PLAN_ITEM_TYPE.CONVERSATION:
        return conversationOptions;
      case PLAN_ITEMS.PLAN_ITEM_TYPE.PROVIDER:
        return providerOptions;
      case PLAN_ITEMS.PLAN_ITEM_TYPE.SERVICE:
        return serviceOptions;
      case PLAN_ITEMS.PLAN_ITEM_TYPE.PROVIDER_TYPE:
      case 'PROVIDER_TYPE':
        return providerRoleOptions;
      default: {
        return [];
      }
    }
  };

  useEffect(() => {
    if (values.type) {
      setReferenceOptions(getListDetailByType(values.type));
    } else {
      setReferenceOptions([]);
    }
  }, [values.type]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderSelect = (label, name, options) => (
    <Box className={classes.section}>
      {renderLabel(label)}
      <Select
        options={options}
        value={values[name]}
        displayEmpty
        emptyText="Select item"
        name={name}
        onChange={handleChange}
        variant={selectType.SECONDARY}
        fullWidth
      />
      <TextError errorMsg={touched[name] ? errors[name]?.toString() : null} />
    </Box>
  );

  const renderAutoComplete = (label, name, options) => (
    <Box className={classes.section}>
      {renderLabel(label)}
      <Input
        placeholder={`Select a ${name}`}
        variant={inputType.AUTOCOMPLETE}
        fullWidth
        name={name}
        size={inputSize.M}
        options={options}
        value={selectReference}
        getOptionLabel={option => option.label}
        onBlur={setTouched(name)}
        onChange={(_: SyntheticEvent, newValue) => {
          void setFieldValue(name, newValue.value);
          setSelectReference(newValue);
        }}
      />
      <TextError errorMsg={touched[name] ? errors[name]?.toString() : null} />
    </Box>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{`${planItem ? 'Edit' : 'Add'} plan item`}</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Plan item name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter a plan item name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            {values.name ? renderSelect('Plan type', 'type', typeSelects) : null}
            {values.type
              ? renderSelect('Navigates to', 'navigatesTo', getNavigatesToSelects(values.type))
              : null}
            {values.navigatesTo
              ? renderAutoComplete(`Select ${values.type}`, 'referenceId', referenceOptions)
              : null}
            {values.referenceId
              ? renderSelect('Progress state', 'progressState', getProgressStateSelects(values.type))
              : null}
            {values.progressState
              ? renderSelect('Completed state', 'completedState', getCompleteStateSelects(values.type))
              : null}
            {values.completedState
              ? renderSelect('Remove from plan', 'removeReference', removeFromPlanSelects)
              : null}
            {values.removeReference === 'COMPLETED_X_TIMES' ? (
              <Box className={classes.section}>
                {renderLabel('Value')}
                <Input
                  value={values.removeCompleteXTimes}
                  name="removeCompleteXTimes"
                  placeholder="Enter Completed time Value"
                  onChange={handleChange}
                  size={inputSize.M}
                  onBlur={setTouched('removeCompleteXTimes')}
                  fullWidth
                />
                <TextError
                  errorMsg={touched.removeCompleteXTimes ? errors.removeCompleteXTimes?.toString() : null}
                />
              </Box>
            ) : null}
            {values.removeReference ? (
              <Box className={classes.section}>
                {renderLabel('Plan token')}
                <Input
                  value={values.planToken}
                  name="planToken"
                  type="number"
                  placeholder="Enter plan token"
                  onChange={handleChange}
                  size={inputSize.M}
                  onBlur={setTouched('planToken')}
                  fullWidth
                />
                <TextError errorMsg={touched.planToken ? errors.planToken?.toString() : null} />
              </Box>
            ) : null}
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>
              {isSaving ? 'Saving...' : `${planItem ? 'Update' : 'Add'} Plan Item`}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddPlanItem };
