import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { useDomainType } from 'hooks/useDomainType';
import { DomainTypesEnum } from 'constants/CommonConstants';

// components
import MedicationForm from '../medication-form';
import { MedicationBottomCard } from '../medication-card/MedicationBottomCard';
// schema
import { MedicationEditSchema } from './MedicationEditing.schema';
// styles
import { useStyles } from './MedicationEditing.styles';
// types
import { defaultValues, IMedicationEditProps } from './MedicationEditing.types';

const MedicationEditing: React.FC<IMedicationEditProps> = ({
  isOpen,
  onClose,
  isNoDrawer,
  medication,
  error,
  isSaving,
  onSubmit,
  lookupData,
  memberId,
}) => {
  const [, , domainOptions] = useDomainType({
    type: DomainTypesEnum.MEDICATIONS,
  });

  const [, symptoms] = useDomainType({
    type: DomainTypesEnum.SYMPTOMS,
    patientId: memberId,
  });

  const symtomsList = symptoms?.relatedElements?.map(item => item.name) || [];

  const defaultValuesForEditing = {
    // const defaultValues = {
    status: '',
    notes: '',
    dosage: '',
    frequency: '',
    medDuration: '',
    symptoms: [],
    provider: 'Confidant Provider',
    confidantProvider: '',
    providerName: '',
    additionalNotes: '',
    isInterference: false,
    // dose: {
    //   amount: 1,
    //   unit: 'MG',
    // },
    // frequency: {
    //   amount: 1,
    //   unit: 'DAYS',
    // },
    // duration: {
    //   amount: 1,
    //   unit: 'DAYS',
    // },
    // refillable: true,
    // refillTimes: {
    //   amount: 1,
    //   unit: 'TIMES',
    // },
    // isInterference: false,
  };
  const classes = useStyles();
  const form = useFormik({
    initialValues: { ...defaultValuesForEditing },
    validationSchema: MedicationEditSchema,
    onSubmit: payload => {
      onSubmit && onSubmit(payload);
    },
  });

  useEffect(() => {
    if (medication) {
      const {
        // tagMetaData: { rxDrugInfo },
        priority,
        ...others
      } = medication;
      const newObj = {
        ...others,
        status: priority?.name,
        dosage: medication?.tagMetaData?.rxDrugInfo?.dosage,
        frequency: medication?.tagMetaData?.rxDrugInfo?.frequency,
        medDuration: medication?.tagMetaData?.rxDrugInfo?.duration,
        symptoms: medication?.tagMetaData?.rxDrugInfo?.symptomsBeingTreated,
        provider:
          medication?.tagMetaData?.rxDrugInfo?.prescribingProvider === 'Internal'
            ? 'Confidant Provider'
            : 'External Provider',
        confidantProvider: medication?.tagMetaData?.rxDrugInfo?.confidantProviderId,
        providerName: medication?.tagMetaData?.rxDrugInfo?.prescribingProvider,
        // additionalNotes: '',
        isInterference: false,
        // dose: {
        //   amount: rxDrugInfo.dose,
        //   unit: rxDrugInfo.doseUnit,
        // },
        // refillable: rxDrugInfo.refillable,
        // frequency: {
        //   amount: rxDrugInfo.doseFrequency,
        //   unit: rxDrugInfo.supplyUnit,
        // },
        // duration: {
        //   amount: rxDrugInfo.supply,
        //   unit: rxDrugInfo.supplyUnit,
        // },
        // refillTimes: {
        //   amount: 1,
        //   unit: 'TIMES',
        // },
      };
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(newObj).forEach(async key => {
        await form.setFieldValue(key, newObj[key]);
      });
    }
  }, [medication]);

  useEffect(() => {
    if (!isOpen) {
      form.handleReset({ ...defaultValues });
      form.setErrors({});
    }
  }, [isOpen]);

  const renderContent = () => (
    <Box className={classes.wrapper}>
      <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            Update medication - {medication?.name || ''}
          </Text>
          {/* {medication?.description && (
            <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
              {medication?.description?.toLowerCase()}
            </Text>
          )} */}
        </Box>
        <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
      </Box>
      <Box className={clsx(classes.body, { [classes.bodyNoDrawer]: isNoDrawer })}>
        <Box className={classes.headWrap}>
          <MedicationBottomCard medication={medication} showBadge />
        </Box>
        <form className={classes.form} onSubmit={form.handleSubmit}>
          <MedicationForm
            form={form}
            error={error}
            lookupData={lookupData}
            options={domainOptions}
            medication={medication}
            symptomsList={symtomsList}
          />
        </form>
      </Box>
      <Box className={classes.footer}>
        <Button variant={btnType.TEXT} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={form.handleSubmit} disabled={isSaving}>
          {isSaving ? 'Saving...' : 'Update'}
        </Button>
      </Box>
    </Box>
  );

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {medication && renderContent()}
    </Drawer>
  );
};

export { MedicationEditing };
