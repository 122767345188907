import { FC } from 'react';
import clsx from 'clsx';

// components
import { Box, Stack } from '@mui/material';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

// styles
import { useStyles } from './CardTop.styles';

type Props = {
  title: string;
  subtitle: string;
  className?: string;
  badge: string;
  onClick?: () => void;
};

const CardTop: FC<Props> = ({ title, subtitle, badge, className, onClick }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.cardTop, className)}>
      <Box>
        <Stack gap={1} alignItems="center" direction="row">
          <Text weight={fontWeight.BOLD} className={classes.cardTopTitle}>
            {title}
          </Text>
          <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
            {badge}
          </Badge>
        </Stack>
        <Text weight={fontWeight.MEDIUM} className={classes.cardTopSubtitle}>
          {subtitle}
        </Text>
      </Box>
      <IconButton onClick={onClick} className={classes.viewResponseBtn}>
        View responses by members
      </IconButton>
    </Box>
  );
};

export { CardTop };
