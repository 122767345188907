import axios, { AxiosError, AxiosResponse, Method } from 'axios';

import { ApiEndpoints } from 'constants/ApiEndpoints';
import getConfig from 'config';

const LOGIN_PATH = '/login';

export interface CHEndpoint {
  path: string;
  method: string;
}

function authHeader() {
  return localStorage.getItem('authorizationToken');
}

function isAuthenticationEndpoint(endpoint: string): boolean {
  return (
    endpoint === ApiEndpoints.LOGIN.path ||
    endpoint === ApiEndpoints.VERIFY_OTP.path ||
    endpoint === ApiEndpoints.USER_ACCOUNT_RECOVERY.path ||
    endpoint === ApiEndpoints.UPDATE_PASSWORD.path ||
    endpoint === ApiEndpoints.VERIFY_CONFIRMATION_CODE.path ||
    endpoint === ApiEndpoints.GET_CONNECTIONS.path ||
    endpoint === ApiEndpoints.GET_PROVIDER_SCHEDULE.path
  );
}

export function setAuthToken(token: string): void {
  try {
    localStorage.setItem('authorizationToken', `Bearer ${String(token)}`);
  } catch (e) {
    console.log(e);
  }
}

export function deleteAuthToken(): boolean {
  try {
    localStorage.removeItem('authorizationToken');
    localStorage.removeItem('meta');
  } catch (e) {
    console.log(e);
    return false;
  }
  return true;
}

function checkTokenExpiry(response, endpoint) {
  if (response && response.errorCode && response.errorCode === 'UNAUTHORIZED') {
    if (!this.isAuthenticationEndpoint(endpoint)) {
      deleteAuthToken();
      window.location.replace(LOGIN_PATH);
    }
  }
}

function generateQueryParams(queryParams: { [key in string]: any }) {
  const esc = encodeURIComponent;
  return `?${String(
    Object.keys(queryParams)
      .map(k => `${esc(k)}=${esc(queryParams[k])}`)
      .join('&'),
  )}`;
}

function formatEndpoint(
  endpoint: CHEndpoint,
  pathParams: { [key in string]: any },
  queryParams: { [key in string]: any },
) {
  // generating fully qualified path for request
  let formattedEndpoint = endpoint.path;
  if (pathParams) {
    // Replacing url path params with actual provided values.
    Object.keys(pathParams).forEach(key => {
      formattedEndpoint = formattedEndpoint.replace(`{${String(key)}}`, pathParams[key]);
    });
  }

  if (formattedEndpoint.includes('{')) {
    // Some path params aren't replaced with actual values.
    throw new Error(
      'Path parameters are required and their keys must' +
        'match with the placeholder keys defined in endpoint declaration',
    );
  }
  if (queryParams) {
    // Appending Query params with url path
    formattedEndpoint += generateQueryParams(queryParams);
  }

  return formattedEndpoint;
}

export const signOff = (): void => {
  deleteAuthToken();
};

function methodType(method: string): Method {
  switch (method.toLowerCase()) {
    case 'get':
      return 'GET';
    case 'delete':
      return 'DELETE';
    case 'head':
      return 'HEAD';
    case 'options':
      return 'OPTIONS';
    case 'post':
      return 'POST';
    case 'put':
      return 'PUT';
    case 'patch':
      return 'PATCH';
    case 'purge':
      return 'PURGE';
    case 'link':
      return 'LINK';
    case 'unlink':
      return 'UNLINK';
    default:
      console.log('Invalid Type returning get');
      return 'GET';
  }
}

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error.response);
  },
);

export function baseRequest(
  endpoint: CHEndpoint,
  requestBody?: { [key in string]: any },
  pathParams?: { [key in string]: any },
  queryParams?: { [key in string]: any },
  requireAuth = true,
  isBase = true,
  isBlob = false,
  isMultiPart = false,
  formDataKey: string = null,
  stringifyMultipart = true,
): Promise<AxiosResponse> {
  let elkBody = {};
  let headers;
  let formData;
  let endpointPath =
    pathParams || queryParams ? formatEndpoint(endpoint, pathParams, queryParams) : endpoint.path;
  if (isMultiPart) {
    formData = new FormData();
    if (stringifyMultipart) {
      formData.append(formDataKey, JSON.stringify(requestBody[formDataKey]));
    } else {
      formData.append(formDataKey, requestBody[formDataKey]);
    }
    requestBody = formData;
    headers = requireAuth
      ? { 'Content-Type': 'multipart/form-data', Authorization: authHeader() }
      : { 'Content-Type': 'multipart/form-data' };
  } else {
    headers = requireAuth
      ? { 'Content-Type': 'application/json', Authorization: authHeader() }
      : { 'Content-Type': 'application/json' };
  }
  let elkBaseUrl;
  let elkPrefix = '';
  if (!isBase) {
    // elkBaseUrl = getConfig.api.elkUrl.split('/').slice(0, -1).join('/');
    // elkPrefix = getConfig.api.elkUrl.split('/').pop();
    // converting the call to proxy server
    elkBaseUrl = getConfig.api.elkUrl.split('/').slice(0, -1).join('/');
    elkPrefix = getConfig.api.elkUrl.split('/').pop();
    const finalUrl = `${elkBaseUrl}/${elkPrefix}${endpointPath}`;
    elkBody = {
      url: finalUrl,
      data: { ...requestBody },
    };

    elkBaseUrl = getConfig.api.baseUrl;
    elkPrefix = '/report-service-node/elastic-proxy';

    endpointPath = '';

    // const { username, password } = getConfig.api;
    // if (username && password) {
    //   headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;
    // }
  }

  const conf = isBlob
    ? {
        method: methodType(endpoint.method),
        baseURL: isBase ? getConfig.api.baseUrl : elkBaseUrl,
        headers,
        responseType: 'blob',
        url: `${elkPrefix}${endpointPath}`,
        data: isBase ? requestBody : elkBody,
      }
    : {
        method: methodType(endpoint.method),
        baseURL: isBase ? getConfig.api.baseUrl : elkBaseUrl,
        headers,
        url: `${elkPrefix}${endpointPath}`,
        data: isBase ? requestBody : elkBody,
      };
  return axios
    .request(conf as any)
    .then(response => {
      if (response && response.status === 200 && response.headers && response.data) {
        if (response.data.accessToken) {
          console.log('authorization found');
          setAuthToken(response.data.accessToken);
        }
      }
      return response;
    })
    .catch(error => {
      if (error?.status === 401) {
        if (!isAuthenticationEndpoint(endpoint.path)) {
          localStorage.removeItem('authorizationToken');
          window.location.replace(LOGIN_PATH);
        } else {
          return Promise.reject(error.data?.errors?.[0]?.endUserMessage || 'Unauthorized');
        }
      }

      if (error?.response) {
        // The client was given an error response (5xx, 4xx)
        if (error.response.status && error.response.status === 404) {
          throw new Error(
            `404 error occured. Reason:  ${String(error.response.data.error)} ENDPOINT : ${String(
              error.response.config.url,
            )}`,
          );
        } else if (error.response.status && error.response.status === 401) {
          if (!isAuthenticationEndpoint(endpoint.path)) {
            console.log('Routing to Login');
            window.location.replace(LOGIN_PATH);
          }
        } else if (error.response.status && error.response.status !== 200) {
          throw new Error(`An unexpected error occurred. Reason:  ${String(error.response.data.message)}`);
        }
        checkTokenExpiry(error.response, endpoint);
      }
      return Promise.reject(error);
    });
}
