import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { IIconButtonProps, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box } from '@mui/material';
import cx from 'clsx';
import BoxShadow from 'components/v2/BoxShadow';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

import { useStyles } from '../index.styles';

type Props = {
  imgSrc: string;
  description: string;
  action?: IIconButtonProps;
};

const ItemCard = ({ imgSrc, description, action }: Props) => {
  const classes = useStyles();
  return (
    <BoxShadow>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: {
            md: 'row',
            xs: 'column',
          },
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <img src={imgSrc} className={classes.img40} alt="default images" />
          <Typography {...typography.body.m.medium}>{description}</Typography>
        </Box>
        {action && (
          <IconButton
            variant={btnType.OUTLINE}
            className={cx(classes.outlineBtn, classes.xsmallBtn)}
            {...action}
          />
        )}
      </Box>
    </BoxShadow>
  );
};

export default ItemCard;
