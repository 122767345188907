import { createAction } from 'redux-actions';

export const FETCH_STATES = 'report-service/states/fetch';
export const FETCH_STATES_SUCCESSFUL = 'report-service/states/fetchSuccessful';
export const FETCH_STATES_FAILED = 'report-service/states/fetchFailed';

export const UPDATE_STATE = 'report-service/state/update';
export const UPDATE_STATE_SUCCESSFUL = 'report-service/state/updateSuccessful';
export const UPDATE_STATE_FAILED = 'report-service/state/updateFailed';

export const ADD_REGISTERED_ENTITY = 'report-service/state/registeredEntity/add';
export const ADD_REGISTERED_ENTITY_SUCCESSFUL = 'report-service/state/registeredEntity/addSuccessful';
export const ADD_REGISTERED_ENTITY_FAILED = 'report-service/state/registeredEntity/addFailed';

export const UPDATE_REGISTERED_ENTITY = 'report-service/state/registeredEntity/update';
export const UPDATE_REGISTERED_ENTITY_SUCCESSFUL = 'report-service/state/registeredEntity/updateSuccessful';
export const UPDATE_REGISTERED_ENTITY_FAILED = 'report-service/state/registeredEntity/updateFailed';

export const FETCH_STATE_DETAIL = 'report-service/state-detail/fetch';
export const FETCH_STATE_DETAIL_SUCCESSFUL = 'report-service/state-detail/fetchSuccessful';
export const FETCH_STATE_DETAIL_FAILED = 'report-service/state-detail/fetchFailed';

export const FETCH_PAYERS = 'report-service/payers/fetch';
export const FETCH_PAYERS_SUCCESSFUL = 'report-service/payers/fetchSuccessful';
export const FETCH_PAYERS_FAILED = 'report-service/payers/fetchFailed';

export const FETCH_PAYER_DETAIL = 'report-service/payer-detail/fetch';
export const FETCH_PAYER_DETAIL_SUCCESSFUL = 'report-service/payer-detail/fetchSuccessful';
export const FETCH_PAYER_DETAIL_FAILED = 'report-service/payer-detail/fetchFailed';

export const ADD_PAYER = 'report-service/payer/add';
export const ADD_PAYER_SUCCESSFUL = 'report-service/payer/addSuccessful';
export const ADD_PAYER_FAILED = 'report-service/payer/addFailed';

export const UPDATE_PAYER = 'report-service/payer/update';
export const UPDATE_PAYER_SUCCESSFUL = 'report-service/payer/updateSuccessful';
export const UPDATE_PAYER_FAILED = 'report-service/payer/updateFailed';

export const ADD_PAYER_PLAN = 'report-service/payer-plan/add';
export const ADD_PAYER_PLAN_SUCCESSFUL = 'report-service/payer-plan/addSuccessful';
export const ADD_PAYER_PLAN_FAILED = 'report-service/payer-plan/addFailed';

export const UPDATE_PAYER_PLAN = 'report-service/payer-plan/update';
export const UPDATE_PAYER_PLAN_SUCCESSFUL = 'report-service/payer-plan/updateSuccessful';
export const UPDATE_PAYER_PLAN_FAILED = 'report-service/payer-plan/updateFailed';

export const ADD_PAYER_PROVIDER = 'report-service/payer-provider/add';
export const ADD_PAYER_PROVIDER_SUCCESSFUL = 'report-service/payer-provider/addSuccessful';
export const ADD_PAYER_PROVIDER_FAILED = 'report-service/payer-provider/addFailed';

export const UPDATE_PAYER_PROVIDER = 'report-service/payer-provider/update';
export const UPDATE_PAYER_PROVIDER_SUCCESSFUL = 'report-service/payer-provider/updateSuccessful';
export const UPDATE_PAYER_PROVIDER_FAILED = 'report-service/payer-provider/updateFailed';

export const FETCH_PAYER_GROUPS = 'report-service/payersGrouped/fetch';
export const FETCH_PAYER_GROUPS_SUCCESSFUL = 'report-service/payersGrouped/fetchSuccessful';
export const FETCH_PAYER_GROUPS_FAILED = 'report-service/payersGrouped/fetchFailed';
export const FETCH_CPTS = 'report-service/cpts/fetch';
export const FETCH_CPTS_SUCCESSFUL = 'report-service/cpts/fetchSuccessful';
export const FETCH_CPTS_FAILED = 'report-service/cpts/fetchFailed';

export const FETCH_ICD10 = 'report-service/icd10/fetch';
export const FETCH_ICD10_SUCCESSFUL = 'report-service/icd10/fetchSuccessful';
export const FETCH_ICD10_FAILED = 'report-service/icd10/fetchFailed';

export const ADD_PAYER_PLAN_CPT = 'report-service/payer-plan-cpt/add';
export const ADD_PAYER_PLAN_CPT_SUCCESSFUL = 'report-service/payer-plan-cpt/addSuccessful';
export const ADD_PAYER_PLAN_CPT_FAILED = 'report-service/payer-plan-cpt/addFailed';

export const UPDATE_PAYER_PLAN_CPT = 'report-service/payer-plan-cpt/update';
export const UPDATE_PAYER_PLAN_CPT_SUCCESSFUL = 'report-service/payer-plan-cpt/updateSuccessful';
export const UPDATE_PAYER_PLAN_CPT_FAILED = 'report-service/payer-plan-cpt/updateFailed';

export const stateActionCreators = {
  fetchStates: createAction(FETCH_STATES),
  updateState: createAction(UPDATE_STATE),
  fetchStateDetail: createAction(FETCH_STATE_DETAIL),
  addRegisteredEntity: createAction(ADD_REGISTERED_ENTITY),
  updateRegisteredEntity: createAction(UPDATE_REGISTERED_ENTITY),
  fetchPayers: createAction(FETCH_PAYERS),
  fetchPayerDetail: createAction(FETCH_PAYER_DETAIL),
  addPayer: createAction(ADD_PAYER),
  updatePayer: createAction(UPDATE_PAYER),
  addPayerPlan: createAction(ADD_PAYER_PLAN),
  updatePayerPlan: createAction(UPDATE_PAYER_PLAN),
  addPayerProvider: createAction(ADD_PAYER_PROVIDER),
  updatePayerProvider: createAction(UPDATE_PAYER_PROVIDER),
  fetchCPTs: createAction(FETCH_CPTS),
  fetchPayerGroups: createAction(FETCH_PAYER_GROUPS),
  fetchICD10: createAction(FETCH_ICD10),
  addPayerPlanCPT: createAction(ADD_PAYER_PLAN_CPT),
  updateCPT: createAction(UPDATE_PAYER_PLAN_CPT),
};
