import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '800px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    position: 'relative',
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerNoDrawer: {
    padding: 16,
    '& $headTitle': {
      fontSize: 18,
      lineHeight: '24px',
    },
    '& $headSubtitle': {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  headWrap: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  headSubtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    display: 'block',
    margin: 0,
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    position: 'absolute',
    right: 24,
    top: 24,
    '& svg': {
      width: 30,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  content: {
    minHeight: 'calc(100vh - 238px)',
    // overflowY: 'auto',
  },
  contentNoDrawer: {
    minHeight: 'calc(100vh - 288px)',
  },
  contentStep2: {
    backgroundColor: colors.neutral50,
  },
  stepWrap: {
    marginTop: 20,
  },
});
