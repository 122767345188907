import { colors } from '@confidant-health/lib/colors';

export const getChartOpts = (label: string, value: string, series: any[]): any => ({
  chart: {
    height: '100%',
  },
  title: {
    // text: '1.7 average completion rate',
    offsetX: 285,
    offsetY: 20,
    style: {
      fontSize: '18px',
      fontWeight: '600',
      lineHieght: '28px',
      fontFamily: 'Manrope',
      color: colors.neutral900,
    },
  },
  labels: [`${series[0]} completed`, `${series[1]} in progress`, `${series[2]} not started`],
  plotOptions: {
    pie: {
      offsetX: -150,
      offsetY: 0,
      donut: {
        size: '80%',
        labels: {
          show: true,
          total: {
            showAlways: true,
            show: true,
            label,
            fontSize: '14px',
            fontFamily: 'Manrope',
            fontWeight: 600,
            color: colors.neutral600,
            formatter: () => value,
          },
          name: {
            show: true,
            offsetY: 20,
          },
          value: {
            show: true,
            fontSize: '28px',
            fontFamily: 'Manrope',
            fontWeight: 700,
            color: colors.neutral900,
            offsetY: -20,
          },
        },
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: [colors.success500, colors.orange500, colors.neutral500],
  stroke: {
    width: 4,
  },
  legend: {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    offsetX: 200,
    offsetY: 40,
    markers: {
      offsetX: -10,
    },
    // formatter: (seriesName: string, opt: { [key: string]: any }): string => {
    //   return `<div class="legend-wrap">
    //       <div class="legend-wrap-left">
    //         <span>${opt.w.globals.series[opt.seriesIndex]} ${seriesName}</span>
    //         <span class="legend-badge-value">${opt.w.globals.series[opt.seriesIndex]}% </span>
    //       </div>
    //       <div class="badges">
    //         <span class="badge badge-1">P: 2</span>
    //         <span class="badge badge-2">M: 1</span>
    //         <span class="badge badge-3">S: 0</span>
    //       </div>
    //     </div>`;
    // },
    itemMargin: {
      horizontal: 0,
      vertical: 8,
    },
  },
});
