import * as yup from 'yup';
import { mapValues } from 'lodash';

const AvailabilitySchema = yup.lazy(obj =>
  yup.object(
    mapValues(obj, () =>
      yup
        .array()
        .of(
          yup.object({
            start: yup.string().required('Start time is required'),
            end: yup.string().required('End time is required'),
          }),
        )
        .min(1, 'Please add time slot or set this day to off!')
        .nullable(),
    ),
  ),
);

export { AvailabilitySchema };
