import { useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'clsx';
import dayjs from 'dayjs';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Gif } from '@giphy/react-components';
import { Box, Dialog } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Rating } from '@confidant-health/lib/ui/atoms/rating';
import { Icons } from '@confidant-health/lib/icons';
import { showSnackbar } from 'redux/modules/snackbar';
import { getAuth } from 'redux/modules/auth/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import * as scheduleServices from 'services/schedule/schedule.service';
import Heading from 'components/v2/Heading';
import Typography from 'components/v2/Typography';
import { HeadingFont, HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import { typography } from 'components/v2/Typography/index.constant';
import Card from './components/Card';
import { useStyles } from './index.styles';

export enum CompletedType {
  APPOINTMENT = 'APPOINTMENT',
  TODO = 'TODO',
}

type Props = {
  variant?: CompletedType;
};

const giphyFetch = new GiphyFetch('4bect4Gt6ALJXRMxUx48NZW64ZeptAts');

function CelebrateGif() {
  const [gif, setGif] = useState<IGif | null>(null);
  useAsync(async () => {
    const { data } = await giphyFetch.search('celebration');
    const randomIndex = Math.floor(Math.random() * data.length);
    setGif(data[randomIndex]);
  }, []);
  return gif && <Gif gif={gif} width={575} height={323} />;
}

const CompletedAppointments = ({ variant = CompletedType.APPOINTMENT }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { meta } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);

  const [open, setOpen] = useState(false);
  const [reviewData, setReviewData] = useState({
    rating: 0,
    comment: '',
    date: dayjs(),
  });

  useEffect(() => {
    void fetchProviderReviews();
  }, []);

  const fetchProviderReviews = async () => {
    try {
      const { data } = await scheduleServices.getProviderReviews({
        actor: meta.userId,
        pageNumber: 0,
        pageSize: 3,
      });

      if (data?.feedbackList?.length > 0) {
        const latestFiveStarReview = data.feedbackList.find(review => review?.rating === 5);
        if (latestFiveStarReview) {
          setReviewData({
            rating: Number(latestFiveStarReview.rating),
            comment: latestFiveStarReview.publicComment,
            date: dayjs(latestFiveStarReview.lastModified),
          });
        }
      }
    } catch (e) {
      const message = e?.data?.message || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: message,
        }),
      );
      console.warn(e);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        img={
          // variant === CompletedType.APPOINTMENT ? completedAppt : todoscompleted
          variant === CompletedType.APPOINTMENT ? (
            <Icons glyph="confetti-for-completed-appointments" className={classes.img64} />
          ) : (
            <Icons glyph="fireworks-for-complete-notes" className={classes.img64} />
          )
        }
        action={{
          className: cx(classes.outlineBtn, classes.xsmallBtn),
          children: <Typography {...typography.body.s.bold}>Let&apos;s celebrate!</Typography>,
          onClick: () => setOpen(true),
        }}
        extended={
          variant === CompletedType.APPOINTMENT ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Heading variant={HeadingLevel.H6} letterSpacing="-0.5px">
                Congratulations, {profile?.firstName} {profile?.lastName}!
              </Heading>
              <Box>
                <Typography {...typography.body.m.medium} color={colors.neutral600}>
                  You have completed all your
                </Typography>
                <Typography {...typography.body.m.medium} color={colors.neutral600}>
                  appointments for today!
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Heading variant={HeadingLevel.H6} letterSpacing="-0.5px">
                Amazing work {profile?.firstName} {profile?.lastName}!
              </Heading>
              <Typography {...typography.body.m.medium} color={colors.neutral600}>
                You&apos;ve completed all of your to-dos.
              </Typography>
            </Box>
          )
        }
      />
      <Dialog open={open} onClose={onClose}>
        <Box
          sx={{ width: 639, p: 4, display: 'flex', flexDirection: 'column', gap: 4, position: 'relative' }}
        >
          <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 3.5 }}>
            <Heading
              variant={HeadingLevel.H3}
              fontWeight={HeadingWeight.SEMI_BOLD}
              fontFamily={HeadingFont.SECONDARY}
            >
              Let&apos;s celebrate!
            </Heading>
            <CelebrateGif />
          </Box>
          {reviewData?.rating === 5 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Heading variant={HeadingLevel.SUB} letterSpacing="-0.5px">
                Recent review
              </Heading>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Rating
                    value={reviewData?.rating}
                    readOnly
                    className={classes.rating}
                    name="rating"
                    showValue={false}
                  />
                  <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                    {dayjs(reviewData?.date).isSame(dayjs(), 'day')
                      ? 'Today'
                      : dayjs(reviewData?.date).format('dddd, MMMM D')}
                    , {dayjs(reviewData?.date).format('hh:mm A')}
                  </Typography>
                </Box>
                <Typography {...typography.body.normal.small.medium}>{reviewData?.comment}</Typography>
              </Box>
            </Box>
          )}
          <Box sx={{ position: 'absolute', top: 24, right: 0 }}>
            <IconButton icon="close" onClick={onClose} />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CompletedAppointments;
