import { ScoredIem } from './scored-card/ScoredCard.types';

export const scores: ScoredIem[] = [
  {
    name: 'DCT name',
    version: 'v1.1',
    responses: 7,
    reports: [
      {
        title: 'current (8/05/2022)',
        value: 8,
        changeValue: -2,
      },
      {
        title: 'previous (12/04/2022)',
        value: 10,
        changeValue: 4,
      },
      {
        title: 'initial (1/01/2022)',
        value: 6,
        changeValue: 0,
      },
    ],
  },
];
