import { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Heading } from '@confidant-health/lib/ui/atoms/typography';
import { Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IProfileElement } from 'redux/modules/conversation/types';

import { useStyles } from './PriorityDataDomainTableCard.styles';
import {
  tableColumns,
  getProfileElementPriorityAndDataDomainListQuery,
  mapResponseToTableObject,
} from './PriorityDataDomainTableCard.constants';
import { getElkProfileElementPriorityDataDomainList } from '../../../../../services/profile/profile.service';
import {
  IProfileElementAssignmentQueryParams,
  IProfileElementPriorityDataDomains,
} from '../../ProfileElementDetail.types';

type Props = {
  profile: IProfileElement;
};
const PriorityDataDomainTableCard: FC<Props> = ({ profile }) => {
  const classes = useStyles();
  const [List, setList] = useState<IProfileElementPriorityDataDomains[]>([]);
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: { searchKey: '', filter: null },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const [listTotalCount, setListTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getQueryParams = () => {
    const queryParams: IProfileElementAssignmentQueryParams = {
      profileKey: profile.profileElementInfo.key,
    };
    return queryParams;
  };
  const fetchProfileElementPriorityAndDataDomainsList = async () => {
    setIsLoading(true);
    const connectedList = await getElkProfileElementPriorityDataDomainList(
      getProfileElementPriorityAndDataDomainListQuery(getQueryParams()),
    );

    const combineList = mapResponseToTableObject(connectedList.data);
    const listCount = Number(connectedList?.data?.aggregations?.by_assignments?.buckets?.length);
    setListTotalCount(listCount);
    setIsLoading(false);
    return combineList;
  };

  const dctListCallBack = useCallback(() => {
    const { pagination } = tableParams;
    const currentPage = pagination?.currentPage || 1;
    const rowsPerPage = pagination?.rowsPerPage || 6;
    let appointmentsFiltered = [];

    if (List.length > 0)
      appointmentsFiltered = List?.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return appointmentsFiltered;
  }, [tableParams, List]);

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'domainType') {
      return {
        ...column,
        renderCell: domainType => <span>{domainType || '-'}</span>,
      };
    }
    if (column.id === 'domainElement') {
      return {
        ...column,
        renderCell: domainElement => <span>{domainElement || '-'}</span>,
      };
    }
    if (column.id === 'importanceValue') {
      return {
        ...column,
        renderCell: importanceValue => <span>{importanceValue || '-'}</span>,
      };
    }
    if (column.id === 'importanceLevel') {
      return {
        ...column,
        renderCell: (val: any) => {
          return (
            <Badge
              variant={badgeType.FILLED}
              style={
                val === 'CRITICAL'
                  ? badgeStyle.CRITICAL
                  : val === 'HIGH'
                  ? badgeStyle.HIGH
                  : val === 'MEDIUM'
                  ? badgeStyle.MEDIUM
                  : val === 'NEGATIVE'
                  ? badgeStyle.MISREPORTED
                  : badgeStyle.LOW
              }
            >
              {val}
            </Badge>
          );
        },
      };
    }
    if (column.id === 'assignment') {
      return {
        ...column,
        renderCell: assignment => <span>{assignment || '-'}</span>,
      };
    }
    // if (column.id === 'actions') {
    //   return {
    //     ...column,
    //     renderCell: assignment => <Icons color={colors.neutral200} glyph="chevron-right" />,
    //   };
    // }
    return column;
  });

  const dctList = dctListCallBack();

  useEffect(() => {
    void (async () => {
      const list = await fetchProfileElementPriorityAndDataDomainsList();
      setList(list);
    })();
  }, [profile]);

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Connected to Priorities Data Domains</Heading>
      </Box>
      <Box>
        <Table
          gridProps={{
            columns: renderColumns,
            data: dctList,
            isLoading,
          }}
          paginationProps={{
            currentRows: tableParams.pagination.currentPage,
            totalCount: listTotalCount,
            showRowsPerPage: false,
          }}
          value={tableParams}
          onChange={(newValues: any) => {
            setTableParams(newValues);
          }}
          className={classes.table}
        />
      </Box>
    </Box>
  );
};

export { PriorityDataDomainTableCard };
