import { FC } from 'react';
import { Box, Stack } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Text } from '@confidant-health/lib/ui/atoms/typography';

import AutomationEvent from './automation-event';
// styles
import { useStyles } from './DuplicateAutomation.styles';
// constants
import { AutomationProps } from './DuplicateAutomation.types';
import { listEvent } from './DuplicateAutomation.constants';

const DuplicateAutomation: FC<AutomationProps> = ({ isOpen, onClose, item }) => {
  const classes = useStyles();

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Stack direction="column">
            <Text className={classes.headerTitle}>Automation details</Text>
            <Text className={classes.headerSubtitle}>{item.name}</Text>
          </Stack>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <Box className={classes.content}>
          {listEvent.map((event, index) => (
            <AutomationEvent key={index} {...event} />
          ))}
        </Box>
        <Box className={classes.footer}>
          <IconButton onClick={onClose}>Close</IconButton>
          <IconButton variant={iconBtnType.PRIMARY} className={classes.duplicateBtn}>
            Duplicate automation
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export { DuplicateAutomation };
