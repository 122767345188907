import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';

import { getProfile } from 'redux/modules/profile/selectors';
import { profileActionCreators } from 'redux/modules/profile/actions';
import history from 'utils/history';

import { memberColumns, filterOptionsMock } from './Members.constants';
import { useStyles } from './Members.styles';

const MemberList: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const {
    isLoading,
    payload: { patientsList, totalRecords },
  } = useSelector(getProfile);
  const [patients, setPatients] = useState([]);

  const onRowClick = item => {
    history.push(`/admin/members/${item.member.id}?name=general-information`);
  };

  const onViewProfile = (memberId: string) => () => {
    history.push(`/admin/members/${memberId}?name=general-information`);
  };

  const onViewChat = (memberId: string) => () => {
    history.push(`/admin/chats?userId=${memberId}&type=members`);
  };

  const onScheduleAppointment = (memberId: string) => () => {
    history.push(`/admin/members/${memberId}?name=appointments-past`);
  };

  useEffect(() => {
    const formatPatients = patientsList.map(item => ({
      ...item,
      provider: {
        nickName: item.fullName,
        fullName: `${item.firstName || ''} ${item.lastName || ''}`,
        photo: item.profileImage || '',
      },
      appointments: '8 appointments',
      paymentMethod: 'Insurance',
      payer: 'BCBS',
      plan: 'Plan name',
      actions: {
        appointmentId: 1,
        disabled: false,
      },
    }));
    setPatients(formatPatients);
  }, [patientsList]);

  useEffect(() => {
    const { search, pagination, sorter } = tableParams;
    const bodyRequest = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction,
      sortBy: sorter.column,
    };
    dispatch(profileActionCreators.fetchPatients(bodyRequest));
  }, [tableParams]);

  const renderColumns = useCallback(() => {
    return memberColumns.map(column => {
      if (column.id === 'member') {
        return {
          ...column,
          renderCell: ({ nickName, fullName, photo, id }: any) => (
            <ProfileInfo type="member" photo={photo} nickName={nickName} fullName={fullName} memberId={id} />
          ),
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: ({ id: memberId }) => (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={[
                { label: 'View Profile', onClick: onViewProfile(memberId) },
                { label: 'Go to chat', onClick: onViewChat(memberId) },
                { label: 'Schedule Appointment', onClick: onScheduleAppointment(memberId) },
              ]}
            />
          ),
        };
      }
      return column;
    });
  }, []);

  return (
    <Table
      searchProps={{
        placeholder: 'Search',
        filterProps: {
          variant: Filter.tableFilterType.MULTIPLE,
          options: filterOptionsMock,
          allOptionLabel: 'All members',
        },
      }}
      gridProps={{
        columns: renderColumns(),
        data: patients,
        isLoading,
        onRowClick,
      }}
      paginationProps={{
        currentRows: tableParams.pagination.rowsPerPage,
        totalCount: totalRecords,
        showRowsPerPage: true,
      }}
      value={tableParams}
      onChange={setTableParams}
      className={classes.table}
    />
  );
};

export default MemberList;
