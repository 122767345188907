import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import cx from 'clsx';
import { Icons } from '@confidant-health/lib/icons';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { tabIconPosition, TabPanel, Tabs } from '@confidant-health/lib/ui/atoms/tabs';
import { fontWeight, Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { useDispatch } from 'react-redux';
import { Badge } from '@confidant-health/lib/ui/atoms/badge';
import { useStyles } from './EvaluationDetail.styles';
import { EvaluationHelper } from '../../../../../../utils/EvaluationHelper';
import {
  CbStatus,
  EvaluationItemNode,
  IEvaluationContext,
} from '../../../../../../redux/modules/conversation/types';
import useEvaluationHead from '../../../../../../hooks/useEvaluationHead';
import { IEvaluationItem, IEvaluationUpdateRequest } from '../../Evaluations.types';
import RejectEvaluationModal from '../../evaluation-modals';
import EvaluationSectionCollapse from '../../evaluation-section-collapse';
import {
  getAppointmentEvaluationProgress,
  updateAppointmentEvaluationStatus,
} from '../../../../../../services/conversation/conversation.service';
import { showSnackbar } from '../../../../../../redux/modules/snackbar';
import { Question } from '../../evaluation-section-collapse/Questions';
import { KeyboardShortcutsModal } from '../../../in-person-session/keyboardShortcuts/KeyboardShortcutsModal';

type Props = {
  showAllEvaluations?: () => void;
  listHelper: EvaluationHelper;
  currentBlock: EvaluationItemNode;
  setCurrentBlock: (block: EvaluationItemNode) => void;
  currentEvaluation: IEvaluationItem;
  onClose?: () => void;
  appointmentId: string;
  evaluationContext: IEvaluationContext;
  isSignOffFlow?: boolean;
  tabs?: { tabKey: string; label: string; count: number }[];
  tabType?: string;
  setTabType?: (tab) => void;
  closeHotkeysModal?: () => void;
  openHotkeysModal?: (e: any) => void;
  isHotkeysModalOpen?: boolean;
  isEndedSession?: boolean;
  setToastProperties?: Dispatch<
    SetStateAction<{
      message: string;
      type: string;
      status: boolean;
    }>
  >;
};

const EvaluationDetail: FC<Props> = ({
  showAllEvaluations,
  listHelper,
  currentBlock,
  setCurrentBlock,
  onClose,
  currentEvaluation,
  appointmentId,
  setToastProperties,
  evaluationContext,
  isSignOffFlow,
  tabs,
  tabType,
  setTabType,
  closeHotkeysModal,
  openHotkeysModal,
  isHotkeysModalOpen,
  isEndedSession,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [checkedStates, setCheckedStates] = useState(Array(5).fill(false));
  const [questions, setQuestions] = useState<EvaluationItemNode[]>([]);
  const [evaluationsProgressList, setEvaluationsProgressList] = useState([]);
  const [foundEvaluationWithProgress, setFoundEvaluationWithProgress] = useState(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const { head } = useEvaluationHead();

  useEffect(() => {
    if (head !== null) {
      listHelper.setHead(head);
      if (tabType === 'all' || !isSignOffFlow) {
        setQuestions(listHelper.getQuestions());
      } else {
        setQuestions(listHelper.getFilteredQuestions());
      }
    } else {
      setQuestions([]);
    }
  }, [head, tabType]);

  const totalQuestions = questions.reduce((acc: number, dct: EvaluationItemNode) => {
    return acc + (dct.children.length > 0 ? dct.children.length : 1);
  }, 0);
  const answeredQuestions = questions.reduce((acc: number, dct: EvaluationItemNode) => {
    let count = 0;
    if (dct.children.length > 0) {
      count = dct.children.filter(child => child.status === CbStatus.ANSWERED).length;
    } else {
      dct.status === CbStatus.ANSWERED && count++;
    }
    return acc + count;
  }, 0);

  const onRejectClick = () => {
    setShowRejectModal(true);
  };

  const onEndEvaluation = () => {
    setShowEndModal(true);
  };

  const onCloseRejectEvaluation = () => {
    setShowRejectModal(false);
    setShowEndModal(false);
  };

  const updateEvaluationStatus = async (evaluationUpdateRequest: IEvaluationUpdateRequest) => {
    const { data } = await updateAppointmentEvaluationStatus(evaluationUpdateRequest, {
      appointmentId,
    });
    if (data.errors) {
      console.warn(data.errors);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: data.errors?.[0]?.endUserMessage || 'Something went wrong',
        }),
      );
    }
  };

  const onRejectEndEvaluation = (evaluationUpdateRequest: IEvaluationUpdateRequest) => {
    onClose();
    void updateEvaluationStatus(evaluationUpdateRequest);
  };
  const handleCheckboxChange = (index: number) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(newCheckedStates);
  };

  const getEvaluationProgressList = () => {
    getAppointmentEvaluationProgress({ appointmentId })
      .then(evaluationProgressDetailList => {
        if (evaluationProgressDetailList?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList = evaluationProgressDetailList?.data || [];
          setEvaluationsProgressList(responseList);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  };

  useEffect(() => {
    getEvaluationProgressList();
  }, [evaluationContext]);

  useEffect(() => {
    if (evaluationsProgressList && currentEvaluation) {
      const foundEvaluation = evaluationsProgressList?.find(
        evaluation => evaluation.id === currentEvaluation.id,
      );
      if (foundEvaluation) {
        setFoundEvaluationWithProgress(foundEvaluation);
      }
    }
  }, [evaluationsProgressList, currentEvaluation]);

  const renderTab = tab => {
    return {
      tabKey: tab.tabKey,
      label: tab.label || tab.tabKey,
      icon: <Badge className={classes.tabBadge}>{tab?.count || 0}</Badge>,
      iconPosition: 'end' as tabIconPosition,
    };
  };

  const renderQuestions = () => (
    <>
      <Box className={classes.body} onKeyDown={handleKeyDown} ref={scrollerRef}>
        {isSignOffFlow && (
          <Box sx={{ px: 3, py: 2 }} className={classes.font18}>
            {tabs?.find(tab => tab?.tabKey === tabType)?.label}
          </Box>
        )}
        {questions?.map(item => {
          if (item.cbType === 'dct') {
            const foundDct = foundEvaluationWithProgress?.dctProgress?.find(dct => dct.id === item.dctId);
            return (
              <EvaluationSectionCollapse
                listHelper={listHelper}
                handleChange={handleCheckboxChange}
                key={item.cbId}
                item={item}
                currentBlock={currentBlock}
                setCurrentBlock={setCurrentBlock}
                scrollRef={scrollerRef}
                dctProgress={foundDct?.progress ?? 0}
              />
            );
          }
          return (
            <Question
              key={item.cbId}
              item={item}
              currentBlock={currentBlock}
              scrollRef={scrollerRef}
              setCurrentBlock={setCurrentBlock}
            />
          );
        })}
      </Box>
      <Box
        className={cx(classes.footer, {
          [classes.flexibleFooter]: isEndedSession || isSignOffFlow,
        })}
      >
        <Button variant={btnType.TEXT} className={classes.shortcutsLink} onClick={openHotkeysModal}>
          Keyboard shortcuts (shift +K)
        </Button>
      </Box>
    </>
  );

  const handleKeyDown = e => {
    if (
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowLeft' ||
      (e.key === ' ' && !e.shiftKey)
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <KeyboardShortcutsModal open={isHotkeysModalOpen} onClose={closeHotkeysModal} />
      <RejectEvaluationModal
        open={showRejectModal || showEndModal}
        isEndEvaluation={showEndModal}
        evaluation={currentEvaluation}
        onClose={onCloseRejectEvaluation}
        updateEvaluationStatus={onRejectEndEvaluation}
        setToastProperties={setToastProperties}
      />
      <Box className={classes.container} onKeyDown={handleKeyDown}>
        {!isSignOffFlow ? (
          <>
            <Box className={classes.header}>
              <Box className={classes.dflex}>
                <Button variant={btnType.OUTLINE} className={classes.backBtn} onClick={showAllEvaluations}>
                  <Icons color={colors.primary500} glyph="chevron-left" className={classes.backbtnIcon} />
                </Button>
                <Box className={classes.headerTitleContainer}>
                  <Heading weight={fontWeight.BOLD} className={classes.title}>
                    {currentEvaluation?.name}
                  </Heading>
                  <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
                    {answeredQuestions || 0}/{totalQuestions || 0} completed
                  </Text>
                </Box>
              </Box>
              <Box className={classes.menuContainer}>
                <Menu
                  icon="more"
                  className={classes.menu}
                  itemsWrapperClassName={classes.menuItemsWrapper}
                  disabled={
                    currentEvaluation?.status === 'COMPLETED' || currentEvaluation?.status === 'REJECTED'
                  }
                  items={[
                    {
                      label: 'All evaluations',
                      icon: <Icons glyph="arrow-left" className={cx(classes.icon, classes.pauseIcon)} />,
                      onClick: showAllEvaluations,
                    },
                    {
                      label: 'End evaluation',
                      icon: <Icons glyph="pause-outlined" className={cx(classes.icon, classes.pauseIcon)} />,
                      onClick: onEndEvaluation,
                    },
                    {
                      label: 'Reject evaluation',
                      icon: <Icons glyph="close-circle-outlined" className={classes.icon} />,
                      onClick: onRejectClick,
                    },
                  ]}
                />
              </Box>
            </Box>
            {renderQuestions()}
          </>
        ) : (
          <>
            <Box className={classes.tabContainer}>
              <Tabs
                value={tabType}
                onChange={setTabType}
                className={classes.evaluationTabs}
                options={tabs.map(renderTab)}
              />
            </Box>
            <Box sx={{ flex: 1, overflowY: 'auto' }}>
              {tabs?.map((tab, index) => (
                <TabPanel value={tabType} tabKey={tab.tabKey} key={index}>
                  {renderQuestions()}
                </TabPanel>
              ))}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export { EvaluationDetail };
