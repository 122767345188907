import { FC, Fragment, useState } from 'react';
import { Box, Divider } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';

// components
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { SignOffStatus } from '@confidant-health/lib/constants/CommonConstants';
import { iconBtnStyle, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
// import { IAppointmentDetails } from 'redux/modules/appointment/types';
import history from 'utils/history';
// styles
import { useStyles } from './SignOff.styles';
import SignOffModal from '../signoff-modal';
import { IProfile } from '../../../../../redux/modules/profile/types';
import { patchAppointment } from '../../../../../services/appointment/appointment.service';
import { PatchAction } from '../../../../../redux/modules/appointment/types';

interface ISignoffDates {
  providerSignoffDate: string;
  supervisorSignoffDate: string;
}
type Props = {
  appointmentDetails: IAppointment;
  supervisor?: IProfile;
  signOffDates?: ISignoffDates;
};

const SignOff: FC<Props> = ({ appointmentDetails, supervisor, signOffDates }: Props) => {
  const classes = useStyles();
  const [showSignOff, setShowSignOff] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const onSignOffClick = () => {
    setShowSignOff(true);
  };

  const onCloseSignOffClick = () => {
    setShowSignOff(false);
  };

  const onSubmitSignOffClick = async () => {
    if (!isRequesting) {
      setIsRequesting(true);
      await handleSignOff();
      setTimeout(() => {
        setIsRequesting(false);
        setShowSignOff(false);
        history.back();
      }, 3000);
    }
  };

  const providersSignOff = [
    {
      id: '1',
      name: appointmentDetails?.practitionerName,
      role: 'Provider',
      thumbnail: appointmentDetails?.practitionerImage,
      isSignedOff: appointmentDetails?.signOffStatus !== SignOffStatus.DRAFTED,
      signedDate: dayjs().subtract(1, 'd').local().format('MM/DD/YYYY, H:mm a'),
    },
  ];

  if (appointmentDetails?.requireSupervisorSignOff === true) {
    if (appointmentDetails.signOffStatus === 'REVIEW' && !!supervisor) {
      providersSignOff.push({
        id: '2',
        name: supervisor?.fullName,
        role: 'Supervising Provider',
        thumbnail: supervisor?.profileImage,
        isSignedOff: false,
        signedDate: dayjs().subtract(1, 'd').local().format('MM/DD/YYYY, H:mm a'),
      });
    } else if (appointmentDetails.signOffStatus === 'APPROVED') {
      providersSignOff.push({
        id: '2',
        name: appointmentDetails.supervisorName,
        role: 'Supervising Provider',
        thumbnail: appointmentDetails.supervisorImage,
        isSignedOff: true,
        signedDate: dayjs().subtract(1, 'd').local().format('MM/DD/YYYY, H:mm a'),
      });
    }
  }

  const handleSignOff = async () => {
    try {
      await patchAppointment(
        {
          action: PatchAction.ADD_SUPERVISOR_NOTES,
          supervisorNotesData: {
            notes: '',
            status: 'APPROVED',
          },
        },
        {
          appointmentId: appointmentDetails.appointmentId,
        },
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleReject = async () => {
    try {
      const response = await patchAppointment(
        {
          action: PatchAction.ADD_SUPERVISOR_NOTES,
          supervisorNotesData: {
            notes: '',
            status: 'DRAFTED',
          },
        },
        {
          appointmentId: appointmentDetails.appointmentId,
        },
      );
      if (response.status === 200) {
        history.back();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box className={classes.card}>
        {providersSignOff.map((item, idx) => (
          <Fragment key={idx}>
            <Box className={classes.cardItem}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <ProfileInfo
                  type="provider"
                  photo={item.thumbnail}
                  fullName={item.name}
                  role={item.role}
                  isProvider
                />
                <Box>
                  {item.isSignedOff ? (
                    <Box>
                      <Box display="flex" alignItems="center">
                        <Icons glyph="checkmark" color={colors.success700} />
                        <Text
                          level={textLevel.M}
                          weight={fontWeight.MEDIUM}
                          className={clsx(classes.text700, classes.success700)}
                        >
                          Signed off
                        </Text>
                      </Box>
                      {signOffDates?.providerSignoffDate !== null && (
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.dark60}>
                            {item?.id === '1'
                              ? dayjs(signOffDates?.providerSignoffDate).format('MMM DD, YYYY')
                              : dayjs(signOffDates?.supervisorSignoffDate).format('MMM DD, YYYY')}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <>
                      <Button variant={btnType.OUTLINE} className={classes.rejectBtn} onClick={handleReject}>
                        Reject
                      </Button>
                      <IconButton
                        icon="pencil"
                        onClick={onSignOffClick}
                        style={iconBtnStyle.PRIMARY}
                        className={classes.signoffBtn}
                      >
                        Sign off
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            {idx < providersSignOff.length - 1 && <Divider className={classes.divider} />}
          </Fragment>
        ))}
      </Box>
      <SignOffModal
        open={showSignOff}
        onClose={onCloseSignOffClick}
        isLoading={isRequesting}
        onSubmit={onSubmitSignOffClick}
      />
    </>
  );
};

export { SignOff };
