import { makeStyles } from '@mui/styles';

// colors
// import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  boxes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridRowGap: 16,
    gridColumnGap: 16,
    '& > div': {
      width: '100%',
    },
    '@media (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
    },
  },
  upcoming: {
    minWidth: 736,
  },
  messages: {
    display: 'flex',
    gap: 32,
    justifyContent: 'space-between',
    '& > div': {
      flex: 1,
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
});
