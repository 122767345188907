import { Box, Grid } from '@mui/material';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Icons } from '@confidant-health/lib/icons';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { getAuth } from 'redux/modules/auth/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { profileActionCreators } from 'redux/modules/profile';
import { createNewAssociatedTag } from 'services/member/member.service';

import { useDomainType } from 'hooks/useDomainType';
import { DomainTypesEnum } from 'constants/CommonConstants';
import history from 'utils/history';
import Card from './Card';
import MedicationDetail from '../Medications/medication-detail';
import MedicationEditing from '../Medications/medication-editing';
import { MedicationEditPayload } from '../Medications/medication-editing/MedicationEditing.types';
import AddNewReport from '../Medications/medication-new';

import { useStyles } from './index.styles';
import { IMedication } from '../Medications/medication-card/MedicationCard.types';

const Medications = ({ isAdmin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { meta } = useSelector(getAuth);
  const { profile, selectedPatient } = useSelector(getProfile);

  const [isLoading, domainData, domainOptions, lookupData] = useDomainType({
    type: DomainTypesEnum.MEDICATIONS,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [errorApi, setError] = useState(null);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showAddMedication, setShowAddMedication] = useState(false);

  const onClickMedication = (item, isEdit = false) => {
    setSelectedMedication(item);
    setIsEditing(isEdit);
  };

  const onCloseDrawer = () => {
    setSelectedMedication(null);
    setIsEditing(false);
    setShowAddMedication(false);
  };

  const onClickNewReport = () => {
    setShowAddMedication(true);
  };
  const onUpdateItem = async (data: MedicationEditPayload) => {
    const { status: importanceLevel, notes } = data;

    setIsSaving(true);
    setError(null);
    try {
      const payload = {
        patientId: memberId,
        tagItems: [
          {
            assignedBy: profile.fullName || meta.nickName,
            domainElementId: selectedMedication.domainElementId,
            metaData: {
              notes,
              selectedImportance: importanceLevel,
              rxDrugInfo: {
                dose: 0,
                doseUnit: '',
                doseFrequency: 0,
                supply: 0,
                supplyUnit: '',
                confidantProviderId: data?.confidantProvider,
                confidantProviderName: data?.providerName,
                dosage: data?.dosage,
                prescribingProvider: data?.provider === 'Confidant Provider' ? 'Internal' : 'External',
                providerName: data?.providerName,
                symptomsBeingTreated: data?.symptoms,
                frequency: data?.frequency,
              },
            },
          },
        ],
      };
      await createNewAssociatedTag(payload);
      setIsSaving(false);
      dispatch(profileActionCreators.fetchDomainTypes(memberId));
      onCloseDrawer();
    } catch (error) {
      setError(error.data.message || error.data.errors[0]?.endUserMessage);
      setIsSaving(false);
    }
  };

  const onSubmitNewReport = (payload: IMedication[]) => {
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        metaData: {
          // interferenceInLife: item.isInterference,
          notes: item?.notes,
          selectedImportance: item?.status,
          rxDrugInfo: {
            dose: 0,
            doseUnit: '',
            doseFrequency: 0,
            // refillable: true,
            // refill: item.tutorial.refillTimes.amount,
            supply: 0,
            supplyUnit: '',
            confidantProviderId: item?.tutorial?.confidantProviderId,
            confidantProviderName: item?.tutorial?.confidantProviderName,
            dosage: item?.tutorial?.dosage,
            prescribingProvider:
              item?.tutorial?.prescribingProvider === 'Confidant Provider' ? 'Internal' : 'External',
            providerName: item?.tutorial?.providerName,
            symptomsBeingTreated: item?.tutorial?.symptomsBeingTreated,
            frequency: item?.tutorial?.frequency,
          },
        },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: (errorMsg?: string) => {
          if (!errorMsg) {
            onCloseDrawer();
          }
        },
      }),
    );
  };

  return (
    <>
      <AddNewReport
        isOpen={showAddMedication}
        onClose={onCloseDrawer}
        options={domainOptions}
        lookupData={lookupData}
        onSubmit={onSubmitNewReport}
      />
      <MedicationDetail
        open={!!selectedMedication && !isEditing}
        onClose={onCloseDrawer}
        onClickNewReport={onClickNewReport}
        medication={selectedMedication}
      />
      <MedicationEditing
        isOpen={!!selectedMedication && isEditing}
        onClose={onCloseDrawer}
        medication={selectedMedication}
        onSubmit={onUpdateItem}
        lookupData={lookupData}
        isSaving={isSaving}
        error={errorApi}
        memberId={memberId}
      />
      <Card
        label="Medications"
        actions={[
          {
            label: 'View all',
            onClick: () =>
              history.push(`/${isAdmin ? 'admin' : 'provider'}/members/${memberId}?name=medications`),
          },
        ]}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </Box>
        ) : domainData?.relatedElements?.length > 0 ? (
          <>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Med Name
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Prescriber
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Dosage
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Frequency
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Date prescribed
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.tableHead}>
                  <Text weight={fontWeight.BOLD} level={textLevel.XS} color={colors.neutral700}>
                    Status
                  </Text>
                </Box>
              </Grid>
            </Grid>
            {domainData.relatedElements.slice(0, 3).map((item, idx) => (
              <Grid
                container
                key={idx}
                alignItems="center"
                sx={{ borderBottom: `1px solid ${colors.neutral100}`, cursor: 'pointer' }}
                onClick={() => onClickMedication(item)}
              >
                <Grid item xs={2}>
                  <Box sx={{ p: 2 }}>
                    <Text weight={fontWeight.MEDIUM} level={textLevel.S}>
                      {item?.name || ''}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ p: 2, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                    <Avatar
                      src={item?.avatar}
                      size={40}
                      variant={avatarType.CIRCLE}
                      name={item?.assignedBy}
                    />
                    <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
                      <Text weight={fontWeight.BOLD} level={textLevel.S}>
                        {item?.assignedBy || ''}
                      </Text>
                      <Text weight={fontWeight.MEDIUM} level={textLevel.S}>
                        {dayjs(item?.assignedAt || '').format('MMMM DD, YYYY')}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box sx={{ p: 2 }}>
                    <Text weight={fontWeight.MEDIUM} level={textLevel.S}>
                      {item?.tagMetaData?.rxDrugInfo?.dosage}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ p: 2 }}>
                    <Text weight={fontWeight.MEDIUM} level={textLevel.S}>
                      {item?.tagMetaData?.rxDrugInfo?.frequency}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ p: 2 }}>
                    <Text weight={fontWeight.MEDIUM} level={textLevel.S}>
                      {dayjs(item?.assignedAt || '').format('MM/DD/YYYY')}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={{ p: 2 }}>
                    <Badge
                      size={badgeSize.SMALL}
                      variant={badgeType.OUTLINED}
                      style={badgeStyle[item?.priority.name] || badgeStyle.UNRELATED}
                    >
                      {item?.priority?.name || ''}
                    </Badge>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
            <Text weight={fontWeight.BOLD} level={textLevel.S} color={colors.neutral700}>
              No medications reported{' '}
              {`${selectedPatient?.member?.nickName ? `for ${selectedPatient?.member?.nickName}` : ''}`} yet.
            </Text>
          </Box>
        )}
      </Card>
    </>
  );
};

export default Medications;
