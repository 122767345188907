import { FC, useCallback } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Table, TableGrid, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { ITablePaginationProps } from '@confidant-health/lib/ui/organisms/table/pagination';
import { ITableSearchProps } from '@confidant-health/lib/ui/organisms/table/search';

import {
  assignmentListMock,
  assignmentColumns,
  badgeStatusText,
  badgeStatusStyle,
} from './RecentAssignmentTable.mock';
import { useStyles } from './RecentAssignmentTable.styles';

type Props = {
  className?: string;
  isShowAll?: boolean;
  searchText?: string;
  tableColumns?: TableGrid.TableColumn[];
  data?: any[];
  onRowClick?: (id: string) => void;
  renderMenuAction?(payload: any): void;
  paginationProps?: ITablePaginationProps;
  searchProps?: ITableSearchProps;
  isLoading?: boolean;
  value?: tableParamsType;
  onChange?: (params: tableParamsType) => void;
};
const RecentAssignmentTable: FC<Props> = ({
  className,
  isShowAll,
  searchText = '',
  data = assignmentListMock,
  onRowClick,
  tableColumns = assignmentColumns,
  renderMenuAction,
  paginationProps = null,
  searchProps = null,
  value,
  isLoading = false,
  onChange,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const renderColumns = tableColumns.map(column => {
    if (column.id === 'member') {
      return {
        ...column,
        renderCell: ({ name, thumbnail, fullname, memberId }) => (
          <Box
            display="flex"
            gap={1.5}
            alignItems="center"
            className={classes.pointer}
            justifyContent="flex-start"
            onClick={e => {
              e.stopPropagation();
              navigate(`/admin/members/${memberId}?name=general-information`);
            }}
          >
            <Avatar src={thumbnail} name={name} size={40} variant={avatarType.CIRCLE} />
            <Box display="flex" flexDirection="column">
              <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
                {name}
              </Text>
              <Text className={clsx(classes.name, classes.fullname)} weight={fontWeight.MEDIUM}>
                {fullname}
              </Text>
            </Box>
          </Box>
        ),
      };
    }
    if (column.id === 'provider') {
      return {
        ...column,
        renderCell: ({ role, thumbnail, name, providerId }) => (
          <Box
            display="flex"
            gap={1.5}
            alignItems="center"
            className={classes.pointer}
            onClick={e => {
              e.stopPropagation();
              navigate(`/admin/providers/${providerId}?name=general-information`);
            }}
          >
            <Avatar src={thumbnail} name={name} size={40} variant={avatarType.CIRCLE} />
            <Box>
              <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
                {name}
              </Text>
              <Badge className={clsx(classes.badge, role)} variant={badgeType.FILLED}>
                {role}
              </Badge>
            </Box>
          </Box>
        ),
      };
    }
    if (column.id === 'assignBy') {
      return {
        ...column,
        renderCell: ({ role, type }) => {
          const systemText = type === 'system' ? 'Automation' : 'Self assigned';
          return type === 'provider' ? role : systemText;
        },
      };
    }
    if (column.id === 'status') {
      return {
        ...column,
        renderCell: (status: string) => (
          <Badge style={badgeStatusStyle[status]} variant={badgeType.FILLED}>
            {badgeStatusText[status]}
          </Badge>
        ),
      };
    }
    if (column.id === 'assignedDate') {
      return {
        ...column,
        renderCell: (date: string) => dayjs(date).format('MM/DD/YYYY'),
      };
    }
    if (column.id === 'actions' && renderMenuAction) {
      return {
        ...column,
        renderCell: renderMenuAction,
      };
    }
    return column;
  });

  const formatList = useCallback(list => list.map(item => ({ ...item, actions: { id: item.id } })), []);

  const listFormatted = formatList(data);
  const list = isShowAll
    ? listFormatted.filter(
        item => item.member.name?.includes(searchText) || item.member.fullname?.includes(searchText),
      )
    : listFormatted.slice(0, 5);

  return (
    <Table
      className={clsx(classes.table, className)}
      gridProps={{
        columns: renderColumns,
        onRowClick,
        data: list,
        isLoading,
      }}
      value={value}
      onChange={onChange}
      paginationProps={paginationProps ?? null}
      searchProps={searchProps ?? null}
    />
  );
};

export { RecentAssignmentTable };
