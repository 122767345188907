import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  item: {
    backgroundColor: colors.white,
    borderRadius: 8,
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
  },
  heading: {
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  content: {
    padding: 24,
  },
  badge: {
    borderRadius: 20,
  },
  badgeMisreported: {
    backgroundColor: colors.secondary50,
    color: colors.secondary500,
  },
  interference: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    color: colors.neutral500,
  },
  interferenceActive: {
    color: colors.secondary500,
  },
  note: {
    marginTop: 22,
    fontSize: 14,
    lineHeight: '20px',
    display: 'block',
    fontWeight: 500,
    color: colors.neutral700,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.neutral500,
    display: 'block',
  },
  value: {
    color: colors.neutral900,
  },
  time: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    margin: 0,
    color: colors.neutral600,
  },
  notes: {
    marginBottom: 24,
    display: 'block',
  },
});
