import { FC } from 'react';
import cx from 'clsx';
// components
import { Box, Modal } from '@mui/material';
import { theme } from '@confidant-health/lib/theme';
import { colors } from '@confidant-health/lib/colors';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { Icons } from '@confidant-health/lib/icons';
// types
import { IModalProps } from './Modal.types';
// styles
import { useStyles } from './Modal.styles';

const UpdatesModal: FC<IModalProps> = ({
  open,
  title,
  icon,
  confirmText = 'Confirm',
  onClose,
  onConfirm,
  children,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.root}>
        <Box
          sx={{
            position: 'relative',
            width: { sm: '480px', xs: 'auto' },
            padding: { sm: '40px 30px', xs: `40px 24px` },
            background: theme.palette.background.default,
            borderRadius: '10px',
          }}
          className={cx({ [className]: true })}
        >
          {icon && (
            <div className={classes.iconWrapper}>
              <Icons glyph={icon} className={classes.icon} />
            </div>
          )}
          <div className={classes.header}>
            <div className={classes.title}>{title}</div>
            <Icons glyph="close" onClick={onClose} className={classes.closeBtn} color={colors.neutral400} />
          </div>
          <div className={classes.content}>
            <div>{children}</div>

            <div className={classes.actions}>
              {onConfirm && (
                <Button sx={{ width: '100%' }} onClick={onConfirm}>
                  {confirmText}
                </Button>
              )}
            </div>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export { UpdatesModal };
