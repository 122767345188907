// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    border: `1px solid ${colors.neutral100}`,
    borderRadius: 8,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
    background: colors.white,
  },
  cardContent: {
    padding: '8px 16px 16px 16px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  cardFooter: {
    padding: 16,
    display: 'flex',
    gap: 16,
    flexFlow: 'wrap',
  },
  // cardFooter: {
  //   display: 'flex',
  //   gap: 16,
  //   flexFlow: 'wrap',
  //   flex: 1,
  //   justifyContent: 'flex-end',
  // },
  badge: {
    borderRadius: 100,
    height: 18,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  text22: {
    fontWeight: 600,
    fontSize: 22,
    lineHeight: '28px',
  },
  text14: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  text18: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  text16: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  text16Second: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
  },
  text12: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  menu: {
    float: 'right',
    padding: '8px 12px',
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      '&:last-child': {
        color: colors.destructive500,
        '& svg path': {
          fill: colors.destructive500,
        },
      },
    },
    '& .menu-item-custom': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  icon: {
    marginRight: 8,
  },
  pauseIcon: {
    width: 18,
  },
  progress: {
    fontSize: 12,
    lineHeight: '16px',
    color: colors.neutral600,
    margin: 0,
  },
  progressBar: {
    flex: 1,
    minWidth: 166,
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.textSecondary,
    },
  },
  progressWrapper: {
    display: 'flex',
    width: '166px',
  },
  dflex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 4,
    gap: 8,
  },
});
