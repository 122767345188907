import * as yup from 'yup';

const SymptomEditSchema = yup.object({
  // status: yup.string().required('Importance level is required'),
  // notes: yup.string().required('Notes is required'),
  // icd10: yup.string(),
});

const types = yup.object().shape({
  amount: yup.number().min(1, 'Minimal amount is 1'),
  unit: yup.string().required('Unit is required'),
});

const SubstanceUseEditSchema = yup.object({
  status: yup.string().required('Name is required'),
  notes: yup.string().required('Notes is required'),
  method: yup.string().required('Method is required'),
  dose: types,
  frequency: yup.string().required('Frequency is required'),
  duration: yup.string().required('Duration is required'),
  refillTimes: types,
});

const MedicationEditSchema = yup.object({
  // status: yup.string().required('Name is required'),
  // notes: yup.string().required('Notes is required'),
});

export { SymptomEditSchema, SubstanceUseEditSchema, MedicationEditSchema };
