import { Box } from '@mui/material';
import { Drawer } from '@confidant-health/lib/ui/organisms/drawer';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { useStyles } from '../kpis/index.styles';
import EvaluationCard from '../../sessions/evaluations-v2/evaluation-card';
import { IEvaluationItem } from '../../sessions/evaluations-v2/Evaluations.types';
import { IAssignedEvaluation } from '../../../../redux/modules/conversation/types';

type Props = {
  open: boolean;
  onClose: () => void;
  onEvaluationSelected: (evaluation: any) => void;
  evaluations: IEvaluationItem[] | IAssignedEvaluation[];
  past?: boolean;
};

const AssignedEvaluationDrawer = ({ open, onClose, onEvaluationSelected, evaluations, past }: Props) => {
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={onClose}>
      <div className={classes.header}>
        <Box className={classes.text22}>{`${past ? 'Past' : 'Assigned'}`} evaluations</Box>
        <Box className={classes.text14} color={colors.neutral600}>
          {evaluations.length} total
        </Box>
        <Box onClick={onClose} className={classes.close}>
          <Icons glyph="close" />
        </Box>
      </div>
      <div className={classes.content}>
        {evaluations.map(evaluation => (
          <EvaluationCard key={evaluation?.id} evaluation={evaluation} onClick={onEvaluationSelected} />
        ))}
      </div>
    </Drawer>
  );
};

export default AssignedEvaluationDrawer;
