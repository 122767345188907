/* eslint-disable max-len */
import { INavItemType } from '@confidant-health/lib/ui/templates/side-navigation';

export const rightSideNavList: INavItemType[] = [
  {
    text: 'General information',
    link: '?name=general-information',
    query: { type: 'name', value: 'general-information' },
  },
  {
    text: 'Connections',
    link: '?name=connections',
    query: { type: 'name', value: 'connections' },
  },
  {
    text: 'Appointments',
    link: '?name=appointments-current',
    children: [
      {
        text: 'Current',
        query: { type: 'name', value: 'appointments-current' },
      },
      {
        text: 'Past',
        query: { type: 'name', value: 'appointments-past' },
      },
      {
        text: 'Pending',
        query: { type: 'name', value: 'appointments-pending' },
      },
    ],
  },
  {
    text: 'Groups',
    link: '?name=groups',
    query: { type: 'name', value: 'groups' },
  },
  {
    text: 'Availability',
    link: '?name=availability',
    query: { type: 'name', value: 'availability' },
  },
  {
    text: 'Services',
    link: '?name=services',
    query: { type: 'name', value: 'services' },
  },
];

export const headings = {
  'general-information': 'General information',
  connections: '',
  'appointments-current': '',
  'appointments-past': '',
  'appointments-pending': '',
  groups: 'Groups',
  availability: 'Availability',
  services: 'Services',
};
