import { FC } from 'react';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Box } from '@mui/material';
import { PlanItems } from 'pages/provider/member-detail/components';

import { DrawerProps } from '../drawer.types';
import { useStyles } from './PlanItemsDrawer.style';

const PlanItemsDrawer: FC<DrawerProps> = ({ open, isNoDrawer, onClose, appointment }) => {
  const classes = useStyles();

  const renderContent = () => {
    return (
      <Box marginX="40px" marginTop="10px" display="flex" flexDirection="column" gap="20px">
        <PlanItems memberID={appointment?.participantId} />
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="Plan Items"
      onClose={onClose}
      variant={drawerType.FORM}
      className={classes.drawer}
      footer={<div />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { PlanItemsDrawer };
