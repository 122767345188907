import * as yup from 'yup';

const AddProviderSchema = yup.object({
  email: yup.string().required('Email is required').email('Please enter valid email'),
  name: yup.string().required('Name is required'),
  password: yup.string().min(8).required('Password is required'),
  phone: yup.string().required('Phone is required'),
  designation: yup.string().required('Designation is required'),
  approach: yup.string().required('Approach is required'),
  speciality: yup.array().min(1, 'At least one speciality is required'),
});

export { AddProviderSchema };
