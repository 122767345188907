/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

interface StyleProps {
  collapsed?: boolean;
}

export const useStyles = makeStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: colors.neutral50,
    padding: '12px 16px',
  },
  promptIcon: {
    objectFit: 'contain',
    transition: '0.4s',
  },
  header: {
    cursor: 'pointer',
  },
  content: {
    paddingTop: '8px',
  },
  promptText: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  label: {
    fontSize: '14px',
    color: colors.neutral900,
    lineHeight: '20px',
    flex: 1,
    textTransform: 'initial',
  },
  chevronIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    objectFit: 'contain',
    transition: '0.4s',
    transform: ({ collapsed }: StyleProps) => `rotate(${collapsed ? '180deg' : 0})`,
  },
});
