import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { INavItemType } from '@confidant-health/lib/ui/templates/side-navigation';

export const tableColumns: TableGrid.TableColumn[] = [
  { id: 'name', label: 'Name', sortable: false },
  { id: 'isActive', label: 'Active', sortable: false },
  { id: 'event', label: 'Event trigger', sortable: false },
  { id: 'filters', label: 'Filters', sortable: false },
  { id: 'planItemName', label: 'Plan item name', sortable: false },
  { id: 'planItemType', label: 'Plan item type', sortable: false },
  { id: 'delay', label: 'Delay', sortable: false },
  { id: 'repeat', label: 'Repeat', sortable: false },
  { id: 'tags', label: 'Tags', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const rightSideNavList: INavItemType[] = [
  {
    text: 'Appointment automations',
    badge: 0,
    link: '?name=appointment',
    query: { type: 'name', value: 'appointment' },
  },
  {
    text: 'Chatbot automations',
    badge: 0,
    link: '?name=chatbot',
    query: { type: 'name', value: 'chatbot' },
  },
  {
    text: 'Plan Item Automations',
    badge: 0,
    link: '?name=plan-item',
    query: { type: 'name', value: 'plan-item' },
  },
  {
    text: 'Group Automations',
    badge: 0,
    link: '?name=group',
    query: { type: 'name', value: 'group' },
  },
  {
    text: 'Profile Element automations',
    badge: 0,
    link: '?name=profile-element',
    query: { type: 'name', value: 'profile-element' },
  },
  {
    text: 'Payment automations',
    badge: 0,
    link: '?name=payment',
    query: { type: 'name', value: 'payment' },
  },
  {
    text: 'Education automations',
    badge: 0,
    link: '?name=education',
    query: { type: 'name', value: 'education' },
  },
];

export const automationTypes = {
  appointment: {
    title: 'Appointment automations',
    countType: 'appointmentAutomations',
    category: 'APPOINTMENT_AUTOMATIONS',
  },
  chatbot: {
    title: 'Chatbot automations',
    countType: 'chatBotAutomations',
    category: 'CHAT_BOT_AUTOMATIONS',
  },
  'plan-item': {
    title: 'Plan Item automations',
    countType: 'planItemAutomations',
    category: 'PLAN_ITEM_AUTOMATIONS',
  },
  group: { title: 'Group automations', countType: 'groupAutomations', category: 'GROUP_AUTOMATIONS' },
  'profile-element': {
    title: 'Profile Element automations',
    countType: 'profileElementAutomations',
    category: 'PROFILE_ELEMENT_AUTOMATIONS',
  },
  payment: {
    title: 'Payment automations',
    countType: 'paymentAutomations',
    category: 'PAYMENT_AUTOMATIONS',
  },
  education: {
    title: 'Education automations',
    countType: 'educationAutomations',
    category: 'EDUCATION_AUTOMATIONS',
  },
};

export const automationCategories = [
  { key: 'APPOINTMENT_AUTOMATIONS', value: 'Appointment automations' },
  { key: 'CHAT_BOT_AUTOMATIONS', value: 'Chatbot Automations' },
  { key: 'PLAN_ITEM_AUTOMATIONS', value: 'Plan Item Automations' },
  { key: 'GROUP_AUTOMATIONS', value: 'Group Automations' },
  { key: 'PROFILE_ELEMENT_AUTOMATIONS', value: 'Profile Element Automations' },
  { key: 'PAYMENT_AUTOMATIONS', value: 'Payment Automations' },
  { key: 'EDUCATION_AUTOMATIONS', value: 'Education Automations' },
];
