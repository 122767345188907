import { FC, useState } from 'react';

// components
import { Box } from '@mui/material';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import { Avatar } from '@confidant-health/lib/ui/atoms/avatar';

import AddConversation from 'pages/admin/conversations/components/add-conversation';

// services
import { updateConversation, updateEvaluation } from 'services/conversation/conversation.service';

// constants
import { ConversationPriority } from 'constants/CommonConstants';

// types
import { IConversationAssignments } from 'pages/admin/conversations/Conversations.types';
import AddEvaluation from 'pages/admin/evaluations/components/add-evaluation';

// styles
import { useStyles } from './ConversationDetail.styles';

interface Props {
  conversation: IConversationAssignments;
  getConversationDetails: () => void;
  isEvaluation: boolean;
}

const ConversationDetail: FC<Props> = ({
  conversation: conversationAssignments,
  getConversationDetails,
  isEvaluation,
}) => {
  const classes = useStyles();
  const { conversation, evaluation } = conversationAssignments;
  const [showEdit, setShowEdit] = useState(false);
  // const [selectedEvaluation, setSelectedEvaluation] = useState(evaluation);

  const onAddEditConversation = (data, isFileChanged) => {
    const { conversationId } = conversationAssignments;

    const payload = {
      ...data,
      assignableBy: {
        providerAssignable: data.providerAssignable,
        selfAssignable: data.selfAssignable,
        systemAssignable: data.systemAssignable,
      },
      defaultPriority: ConversationPriority.LOW,
      reorder: false,
      selfContained: true,
      contentBlock: [],
      avatar: !isFileChanged ? (isEvaluation ? evaluation?.avatar : conversation.avatar) : data.avatar,
      whoCanBenefit: data.benefits,
      tags: data.tags,
    };
    delete payload.providerAssignable;
    delete payload.systemAssignable;
    delete payload.selfAssignable;
    delete payload.benefits;
    if (isEvaluation) {
      updateEvaluation(payload, { evaluationId: conversationId })
        .then(res => {
          if (res.status === 200) {
            getConversationDetails();
          }
        })
        .catch(err => console.log('err', err));
    } else {
      updateConversation(payload, { conversationId })
        .then(res => {
          if (res.status === 200) {
            getConversationDetails();
          }
        })
        .catch(err => console.log('err', err));
    }
  };

  const renderLabel = (label: string) => (
    <Text className={classes.label} weight={fontWeight.SEMI_BOLD}>
      {label}
    </Text>
  );

  const renderInfoText = (label: string, value: string) => (
    <Box className={classes.info}>
      {renderLabel(label)}
      <Text className={classes.value} weight={fontWeight.MEDIUM}>
        {value}
      </Text>
    </Box>
  );

  const renderInfoTags = (label: string, tags: string[]) => (
    <Box className={classes.info}>
      {renderLabel(label)}
      <Box display="flex" gap={1} flex={0.6}>
        {tags.map((tag, idx) => (
          <Badge
            key={idx}
            className={classes.badge}
            variant={badgeType.OUTLINED}
            style={badgeStyle.UNRELATED}
          >
            {tag}
          </Badge>
        ))}
      </Box>
    </Box>
  );

  // const renderInfoIcon = (label: string, icon: ReactNode, value = '') => (
  //   <Box className={classes.info} style={{ alignItems: 'center' }}>
  //     {renderLabel(label)}
  //     <Box display="flex" gap={1} flex={0.6} alignItems="center">
  //       {icon}
  //       <Text className={classes.value} weight={fontWeight.MEDIUM}>
  //         {value}
  //       </Text>
  //     </Box>
  //   </Box>
  // );

  return (
    <>
      {isEvaluation ? (
        <AddEvaluation
          isOpen={showEdit}
          onClose={() => setShowEdit(false)}
          evaluation={evaluation}
          onSubmit={onAddEditConversation}
        />
      ) : (
        <AddConversation
          isOpen={showEdit}
          onClose={() => setShowEdit(false)}
          conversation={conversation}
          onSubmit={onAddEditConversation}
        />
      )}

      <Box className={classes.root}>
        <Box className={classes.top}>
          <Heading className={classes.heading} level={headingLevel.S} weight={fontWeight.BOLD}>
            Conversation details
          </Heading>

          <IconButton
            icon="edit-underline"
            iconPosition={iconBtnPosition.RIGHT}
            className={classes.exportBtn}
            onClick={() => setShowEdit(true)}
          >
            Edit
          </IconButton>
        </Box>

        {isEvaluation ? (
          <Box className={classes.content}>
            {renderInfoText('Name', evaluation.name)}
            {renderInfoText('Description', evaluation.description)}

            {renderInfoTags('Rejection Reasons', evaluation.rejectionTemplate || [])}
            {renderInfoTags('CPT codes', evaluation.cptCodes || [])}
            <Box className={classes.info}>
              {renderLabel('Evaluation image')}
              <Box flex={0.6}>
                <Avatar src={evaluation.avatar} size={120} />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={classes.content}>
            {renderInfoText('Name', conversation.name)}
            {renderInfoText('Description', conversation.description)}

            {renderInfoTags('Tags', conversation.tags || [])}
            {renderInfoTags('Benefits', conversation.whoCanBenefit || [])}
            <Box className={classes.info}>
              {renderLabel('Chatbot image')}
              <Box flex={0.6}>
                <Avatar src={conversation.avatar} size={120} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export { ConversationDetail };
