import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { colors } from '@confidant-health/lib/colors';
import { IInvoiceRecord } from 'redux/modules/reporting/types';
import { INVOICES_STATUSES } from 'pages/admin/invoices/Invoices.constants';
import { reportingActionCreators } from 'redux/modules/reporting/actions';
import { useStyles } from '../ClaimDetail.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  data: IInvoiceRecord;
};

const headerLabels = ['member', 'provider', 'session type'];

const EditInvoice = ({ open, onClose, data }: Props) => {
  const styles = useStyles();
  const [, setError] = useState('');
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState(data);

  const onSubmit = () => {
    try {
      const {
        member,
        provider,
        sessionType,
        cptList,
        status,
        insuranceDetail,
        sessionDate,
        appointmentId,
        totalAmount,
        memberAmount,
        insuranceAmount,
        insurancePaid,
        memberPaid,
        reason,
      } = invoiceData || {};
      const requestBody = {
        reason,
        totalAmount,
        memberAmount,
        insuranceAmount,
        amountPaid: 0,
        amountDue: 0,
        insurancePaid,
        memberPaid,
        memberId: member?.id,
        providerId: provider?.id,
        appointmentId,
        sessionType,
        status,
        cptList,
        sessionDate,
        insuranceDetail,
      };
      dispatch(reportingActionCreators.updateInvoiceById({ payload: requestBody, id: data?._id }));
      onClose();
    } catch (err) {
      setError(err?.data?.message || err?.data?.errors[0]?.endUserMessage);
    }
  };

  const updateField = (dataProps: IInvoiceRecord, field: keyof IInvoiceRecord, value: any) => {
    setInvoiceData({ ...dataProps, [field]: value });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title="Update Invoice"
      submitBtnTitle="Update Invoice"
      onSubmit={onSubmit}
    >
      {/* {error && (
        <Box mb={2}>
          <TextError errorMsg={error} />
        </Box>
      )} */}
      <Box>
        <Grid container>
          {headerLabels.map(label => (
            <Grid item xs={4} key={label}>
              <Text className={styles.headerLabel}>{label.toUpperCase()}</Text>
            </Grid>
          ))}
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <ProfileInfo
              type="member"
              photo={invoiceData?.member?.profileImage}
              nickName={invoiceData?.member?.fullName || ''}
              fullName={`${invoiceData?.member?.firstName ? `${invoiceData?.member?.firstName} ` : ''}${
                invoiceData?.member?.lastName || ''
              }`}
              memberId={invoiceData?.member?.id}
            />
          </Grid>
          <Grid item xs={4}>
            <ProfileInfo
              type="provider"
              photo={invoiceData?.provider?.profileImage}
              nickName={invoiceData?.provider?.fullName || ''}
              // eslint-disable-next-line jsx-a11y/aria-role
              role="Provider"
              fullName={`${invoiceData?.provider?.firstName} ${invoiceData?.provider?.lastName}`}
              memberId={invoiceData?.provider?.id}
            />
          </Grid>
          <Grid item xs={4} fontSize={14} lineHeight="20px">
            {invoiceData?.sessionType || 'N/A'}
          </Grid>
        </Grid>
      </Box>
      <Box mt={7} mb={4} display="flex" justifyContent="space-between" gap={4}>
        <Box
          padding={3}
          border={`1px solid ${colors.neutral100}`}
          sx={{ background: colors.neutral25 }}
          flexGrow={1}
          borderRadius={1}
        >
          <Typography fontSize={16} lineHeight="24px" color={colors.neutral900} fontWeight={600}>
            Insurance details
          </Typography>
          <Typography
            fontSize={12}
            lineHeight="20px"
            color={colors.neutral600}
            fontWeight={800}
            textTransform="uppercase"
            mt={2}
            letterSpacing="1px"
          >
            Insurance carrier
          </Typography>
          <Typography fontSize={14} lineHeight="20px" color={colors.neutral900} fontWeight={500}>
            {invoiceData?.insuranceDetail?.payer || 'BSBC'}
          </Typography>
          <Typography
            fontSize={12}
            lineHeight="20px"
            color={colors.neutral600}
            fontWeight={800}
            textTransform="uppercase"
            mt={2}
            letterSpacing="1px"
          >
            Plan
          </Typography>
          <Typography fontSize={14} lineHeight="20px" color={colors.neutral900} fontWeight={500}>
            {invoiceData?.insuranceDetail?.plan || 'Confidant Default'}
          </Typography>
          <Typography
            fontSize={12}
            lineHeight="20px"
            color={colors.neutral600}
            fontWeight={800}
            textTransform="uppercase"
            mt={2}
            letterSpacing="1px"
          >
            Status
          </Typography>
          <Typography fontSize={14} lineHeight="20px" color={colors.neutral900} fontWeight={500}>
            {invoiceData?.insuranceDetail?.isActive ? 'Active' : 'InActive'}
          </Typography>
        </Box>
        <Box
          padding={3}
          border={`1px solid ${colors.neutral100}`}
          sx={{ background: colors.neutral25 }}
          borderRadius={1}
        >
          <Typography fontSize={16} lineHeight="24px" color={colors.neutral900} fontWeight={600}>
            CPT codes & fees
          </Typography>
          {invoiceData?.cptList?.map((cpt, index) => (
            <Box
              display="flex"
              justifyContent="space-between"
              key={`${cpt?._id}${index}${cpt?.cptCode}`}
              mt={2}
            >
              <Typography>{cpt?.cptCode || ''}</Typography>
              <Typography>{cpt?.fee ? `$${cpt?.fee}` : '' || ''}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box gap={2.5} flexDirection="column" display="flex">
        <Box>
          <Typography fontWeight={700} fontSize={14} lineHeight="20px" marginBottom="4px">
            Status
          </Typography>
          <Select
            value={invoiceData?.status}
            name="invoiceStatus"
            variant={selectType.SECONDARY}
            emptyText=""
            displayEmpty
            options={INVOICES_STATUSES}
            onChange={e => {
              updateField(invoiceData, 'status', e?.target?.value);
            }}
          />
        </Box>
        <Box>
          <Typography fontWeight={700} fontSize={14} lineHeight="20px" marginTop="4px">
            Amount For The Invoice
          </Typography>
          <TextField
            fullWidth
            sx={{ mt: 1, height: 48 }}
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { height: 48 },
            }}
            variant="outlined"
            value={invoiceData?.totalAmount}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateField(invoiceData, 'totalAmount', e?.target?.value);
            }}
          />
        </Box>
        {/*        <Box>
          <Typography fontWeight={700} fontSize={14} lineHeight="20px" marginTop="4px">
            Member Amount
          </Typography>
          <TextField
            fullWidth
            sx={{ mt: 1, height: 48 }}
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { height: 48 },
            }}
            variant="outlined"
            value={invoiceData?.memberAmount}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateField(invoiceData, 'memberAmount', e?.target?.value);
            }}
          />
        </Box> */}
        <Box>
          <Typography fontWeight={700} fontSize={14} lineHeight="20px" marginTop="4px">
            Insurance Amount
          </Typography>
          <TextField
            fullWidth
            sx={{ mt: 1, height: 48 }}
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { height: 48 },
            }}
            variant="outlined"
            value={invoiceData?.insuranceAmount}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateField(invoiceData, 'insuranceAmount', e?.target?.value);
            }}
          />
        </Box>
        <Box>
          <Typography fontWeight={700} fontSize={14} lineHeight="20px">
            Reason
          </Typography>
          <TextField
            fullWidth
            sx={{ mt: 1 }}
            placeholder="Enter invoice reason"
            variant="outlined"
            InputProps={{ style: { height: 48 } }}
            value={invoiceData?.reason}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateField(invoiceData, 'reason', e?.target?.value);
            }}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default EditInvoice;
