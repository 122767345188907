import { FC } from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import ChevronRight from '@confidant-health/lib/icons/glyphs/ChevronRight';
import { colors } from '@confidant-health/lib/colors';
import { theme } from '@confidant-health/lib/theme';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';

import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import { TodoItemProps } from './TodoItem.types';
import { useStyles } from './TodoItem.styles';

const TodoItem: FC<TodoItemProps> = ({
  id,
  text,
  dueDate,
  assignedByPhoto,
  assignedByProfilePhotoText,
  assignedByFullName,
  sourcePhoto,
  sourceFullName,
  patientUuid,
  sourceSubtitle,
  todoType,
  checked,
  subtext,
  designation,
  onItemChecked,
  onItemUnchecked,
  onItemClicked,
}) => {
  const classes = useStyles();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = e => {
    if (e.target.checked) {
      onItemChecked(id);
    } else {
      onItemUnchecked(id);
    }
  };
  return (
    <Box>
      <Box
        className={clsx({
          [classes.bodyBox]: true,
          [classes.checkboxChecked]: checked,
        })}
      >
        <Checkbox
          checked={checked}
          onChange={handleChange}
          name="isDone"
          className={classes.checkbox}
          color={colors.success400}
        />
        <Box
          className={classes.childBody}
          gap="16px"
          onClick={() => {
            onItemClicked(id);
          }}
        >
          <Box display="flex" justifyContent="space-between" flex={1} alignItems="center">
            {!isTablet && (
              <Box display="flex" flex={1} alignItems="center">
                <Box className={classes.todoItemProfileContainer}>
                  <Box className={classes.todoItemMemberProfile}>
                    {sourceSubtitle === 'Group Chat' ||
                    sourceSubtitle === 'Care Team' ||
                    todoType === 'NOTES' ? (
                      <ProfileInfo
                        type="member"
                        photo={sourcePhoto}
                        fullName={patientUuid || sourceSubtitle}
                        nickName={sourceFullName}
                        profilePhotoName={sourceFullName}
                        showProfilePhotoName
                      />
                    ) : (
                      <ProfileInfo
                        type="provider"
                        photo={sourcePhoto}
                        fullName={sourceFullName}
                        role={sourceSubtitle}
                        profilePhotoName={sourceFullName}
                        showProfilePhotoName
                      />
                    )}
                  </Box>
                  <Box className={classes.todoItemProviderProfile}>
                    <ProfileInfo
                      type="provider"
                      role={designation}
                      photo={assignedByPhoto}
                      fullName={assignedByFullName}
                      profilePhotoName={assignedByProfilePhotoText}
                      showProfilePhotoName
                    />
                  </Box>
                </Box>
                <Box className={classes.todoTextContainer}>
                  <Typography
                    {...typography.body.s.medium}
                    color={colors.neutral600}
                    className={classes.textOverflow}
                  >
                    {subtext}
                  </Typography>
                  <Typography
                    {...typography.body.s.medium}
                    color={colors.neutral900}
                    className={classes.textOverflow}
                  >
                    {text}
                  </Typography>
                </Box>
              </Box>
            )}
            <Box flex={isTablet && 1}>
              {isTablet && (
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      {...typography.body.s.medium}
                      sx={{
                        width: {
                          xs: 200,
                          sm: 300,
                        },
                        overflow: 'hidden',
                        height: 20,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {text}
                    </Typography>
                    <Box
                      className={classes.badgeMobile}
                      sx={{
                        border: `1px solid ${
                          dueDate?.includes('Past')
                            ? colors.destructive300
                            : dueDate?.includes('No')
                            ? colors.neutral300
                            : dueDate?.includes('Marked as complete')
                            ? colors.success400
                            : colors.primary300
                        }`,
                      }}
                    >
                      <Typography
                        {...typography.body.normal.xsmall.semibold}
                        color={
                          dueDate?.includes('Past')
                            ? colors.destructive600
                            : dueDate?.includes('No')
                            ? colors.neutral600
                            : dueDate?.includes('Marked as complete')
                            ? colors.success600
                            : colors.primary600
                        }
                      >
                        {dueDate}
                      </Typography>
                    </Box>
                  </Box>
                  <Avatar
                    variant={avatarType.CIRCLE}
                    src={assignedByPhoto}
                    size={40}
                    name={assignedByProfilePhotoText}
                  />
                </Box>
              )}
            </Box>
            {!isTablet && (
              <Box className={classes.todoItemDueDateContainer}>
                <Box
                  className={classes.badge}
                  sx={{
                    border: `1px solid ${
                      dueDate?.includes('Past')
                        ? colors.destructive300
                        : dueDate?.includes('No')
                        ? colors.neutral300
                        : colors.primary300
                    }`,
                  }}
                >
                  <Typography
                    {...typography.body.normal.small.semibold}
                    color={
                      dueDate?.includes('Past')
                        ? colors.destructive600
                        : dueDate?.includes('No')
                        ? colors.neutral600
                        : colors.primary600
                    }
                  >
                    {dueDate}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {!isTablet && (
            <Box>
              <ChevronRight color="#A4B4C3" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default TodoItem;
