import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';

// styles
import { useStyles } from './SideEffectCard.styles';
import { ISideEffectCardProps } from './SideEffectCard.types';

const SideEffectBottomCard: React.FC<ISideEffectCardProps> = ({ item, showBadge, isEditing }) => {
  const classes = useStyles();

  const renderContent = () => {
    return (
      <Box>
        <Text className={clsx(classes.time, classes.notes)}>{item.notes}</Text>
      </Box>
    );
  };

  const priority = item.priority || item.importanceLevel;

  return (
    <Box className={classes.borderTop}>
      <Box className={clsx(classes.rowCenter, classes.flexStart)}>
        <Box display="flex" gap="12px" alignItems="center" flex={showBadge ? 1 : 0.4}>
          <Avatar src={item.avatar} size={40} variant={avatarType.CIRCLE} name={item.assignedBy} />
          <Box display="flex" gap="4px" flexDirection="column" justifyContent="flex-start">
            <Text className={classes.username}>By {item.assignedBy}</Text>
            <Text className={classes.time}>{dayjs(item.assignedAt).format('MMMM DD, YYYY')}</Text>
          </Box>
        </Box>
        {showBadge ? (
          <Badge
            variant={badgeType.FILLED}
            className={clsx(classes.badge, classes[`badge${priority.name}`])}
            style={badgeStyle[priority.name] || badgeStyle.UNRELATED}
          >
            {priority.name}
          </Badge>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            className={clsx(classes.interference, {
              // [classes.interferenceResolved]: priority.name === DomainPriorities.RESOLVED,
            })}
            flex="0.6"
          >
            {renderContent()}
          </Box>
        )}
      </Box>
      {showBadge && !isEditing && <Box className={classes.bottomBox}>{renderContent()}</Box>}
    </Box>
  );
};

export { SideEffectBottomCard };
