import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import history from 'utils/history';
import { stateActionCreators } from 'redux/modules/state/actions';
import { IState } from 'redux/modules/state/types';

// components

import { useStyles } from './StateCard.styles';

interface Props {
  item: IState;
  onClickUpdate: () => void;
}

const StateCard: FC<Props> = ({ item, onClickUpdate }) => {
  const { payers, providers, state } = item;
  const classes = useStyles(state.isActive);
  const dispatch = useDispatch();

  const onClickDetails = () => {
    const { _id: stateId } = state;
    dispatch(stateActionCreators.fetchStateDetail(stateId));
    history.push(`/admin/states/${stateId}?name=general-information`);
  };

  return (
    <Box className={classes.card}>
      <Box display="flex" alignItems="center" gap={3}>
        <Box className={classes.dot} />
        <Text className={classes.state}>{state.name}</Text>
        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
          {payers?.length} payer{payers?.length > 1 ? 's' : ''}
        </Text>
        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
          {providers?.length} provider{providers?.length > 1 ? 's' : ''}
        </Text>
      </Box>
      <Box className={classes.action}>
        <IconButton
          icon="arrow-right"
          variant={iconBtnType.TEXT}
          className={classes.button}
          onClick={onClickDetails}
        >
          See Details
        </IconButton>
        <IconButton variant={iconBtnType.SECONDARY} className={classes.button} onClick={onClickUpdate}>
          Update
        </IconButton>
      </Box>
    </Box>
  );
};

export { StateCard };
