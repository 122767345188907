export const stepTitles = {
  0: 'Step 1 - select symptoms',
  1: 'Step 2 - symptom details',
  2: 'Step 3 - review symptoms',
};

export const symptomItems = [
  {
    label: 'Feelings',
    groupId: 'Feelings',
    items: [
      { label: 'Feeling Agitated', value: 'Feeling Agitated' },
      { label: 'Feeling Anxious', value: 'Feeling Anxious' },
      { label: 'Feeling Aggressive', value: 'Feeling Aggressive' },
      { label: 'Feeling Angry', value: 'Feeling Angry' },
    ],
  },
  {
    label: 'Mental',
    groupId: 'Mental',
    items: [
      { label: 'Mental Agitated', value: 'Mental Agitated' },
      { label: 'Mental Anxious', value: 'Mental Anxious' },
      { label: 'Mental Aggressive', value: 'Mental Aggressive' },
      { label: 'Mental Angry', value: 'Mental Angry' },
    ],
  },
  {
    label: 'Sleep',
    groupId: 'Sleep',
    items: [
      { label: 'Sleep Agitated', value: 'Sleep Agitated' },
      { label: 'Sleep Anxious', value: 'Sleep Anxious' },
      { label: 'Sleep Aggressive', value: 'Sleep Aggressive' },
      { label: 'Sleep Angry', value: 'Sleep Angry' },
    ],
  },
  {
    label: 'Energy',
    groupId: 'Energy',
    items: [
      { label: 'Energy Agitated', value: 'Energy Agitated' },
      { label: 'Energy Anxious', value: 'Energy Anxious' },
      { label: 'Energy Aggressive', value: 'Energy Aggressive' },
      { label: 'Energy Angry', value: 'Energy Angry' },
    ],
  },
  {
    label: 'Substance use',
    groupId: 'Substance use',
    items: [
      { label: 'Substance Agitated', value: 'Substance Agitated' },
      { label: 'Substance Anxious', value: 'Substance Anxious' },
      { label: 'Substance Aggressive', value: 'Substance Aggressive' },
      { label: 'Substance Angry', value: 'Substance Angry' },
    ],
  },
  {
    label: 'Physical',
    groupId: 'Physical',
    items: [
      { label: 'Physical Agitated', value: 'Physical Agitated' },
      { label: 'Physical Anxious', value: 'Physical Anxious' },
      { label: 'Physical Aggressive', value: 'Physical Aggressive' },
      { label: 'Physical Angry', value: 'Physical Angry' },
    ],
  },
  {
    label: 'Sexual',
    groupId: 'Sexual',
    items: [
      { label: 'Sexual Agitated', value: 'Sexual Agitated' },
      { label: 'Sexual Anxious', value: 'Sexual Anxious' },
      { label: 'Sexual Aggressive', value: 'Sexual Aggressive' },
      { label: 'Sexual Angry', value: 'Sexual Angry' },
    ],
  },
];
