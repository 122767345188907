// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  headWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    marginBottom: 64,
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 18,
    color: colors.neutral600,
    lineHeight: '28px',
  },
});
