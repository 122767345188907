import { SNACK_PAYLOAD } from './types';

export const SHOW_SNACKBAR = 'snackbar/SHOW_SNACKBAR';
export const CLEAR_SNACKBAR = 'snackbar/CLEAR_SNACKBAR';

export const clearSnackbar = () => ({
  type: CLEAR_SNACKBAR,
});

export const showSnackbar = (payload: SNACK_PAYLOAD) => ({
  type: SHOW_SNACKBAR,
  payload,
});
