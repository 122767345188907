import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';

import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { colors } from '@confidant-health/lib/colors';
import { createInvoice } from 'services/reporting/reporting.service';
import { showSnackbar } from 'redux/modules/snackbar';
import { useStyles } from '../ClaimDetail.styles';
import { selectClaim } from '../../../../redux/modules/reporting/selectors';
import { IPayer } from '../../../../redux/modules/state/types';

type Props = {
  open: boolean;
  onClose: () => void;
  payerObj?: IPayer;
};

const headerLabels = ['member', 'provider', 'session type'];

const CreateInvoice = ({ payerObj, open, onClose }: Props) => {
  const { data, isLoading } = useSelector(selectClaim);
  const [updatedData, setUpdatedData] = useState(data);
  const styles = useStyles();
  const [, setError] = useState('');
  const [totalAmount, setTotalAmount] = useState(updatedData?.claim?.totalClaim || '0');
  const [memberAmount, setMemberAmount] = useState(updatedData?.claim?.patientResponsibility || '0');
  const [insuranceAmount, setInsuranceAmount] = useState('0');
  const [reason, setReason] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setUpdatedData(data);
    setTotalAmount(data?.claim?.totalClaim || '0');
    setMemberAmount(data?.claim?.patientResponsibility || '0');
  }, [data]);

  const onSubmit = async () => {
    try {
      await createInvoice({
        reason,
        totalAmount,
        memberAmount,
        insuranceAmount,
        amountPaid: 0,
        amountDue: 0,
        insurancePaid: false,
        memberPaid: false,
        memberId: updatedData?.patient?.userAccountId,
        providerId: updatedData?.renderingProvider?.userAccountId,
        appointmentId: updatedData?.appointmentId,
        sessionType: updatedData?.name,
        status: 'PENDING',
        cptList: updatedData?.claim?.cptList || [],
        sessionDate: updatedData?.appointmentStartTime,
        insuranceDetail: {
          payer: payerObj?.name,
          plan: payerObj?.plans[0]?.name,
          isActive: payerObj?.isActive,
        },
      });
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'Invoice created successfully',
        }),
      );
      onClose();
    } catch (err) {
      console.log({ err });
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: err?.data?.error || 'Something went wrong!',
        }),
      );
      setError(err?.data?.error || err?.data?.errors[0]?.endUserMessage);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      title="Create Invoice"
      submitBtnTitle="Create Invoice"
      onSubmit={onSubmit}
    >
      {/* {error && (
        <Box mb={2}>
          <TextError errorMsg={error} />
        </Box>
      )} */}
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          Loading..
        </Box>
      ) : (
        <Box>
          <Box>
            <Grid container>
              {headerLabels.map(label => (
                <Grid item xs={4} key={label}>
                  <Text className={styles.headerLabel}>{label.toUpperCase()}</Text>
                </Grid>
              ))}
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <ProfileInfo
                  type="member"
                  photo={updatedData?.patient?.profileImage}
                  nickName={`${updatedData?.patient?.firstName || ''} ${
                    updatedData?.patient?.lastName || ''
                  }`}
                  fullName={updatedData?.patient?.uuid}
                  memberId={updatedData?.patient?.userAccountId}
                />
              </Grid>
              <Grid item xs={4}>
                <ProfileInfo
                  type="provider"
                  photo={updatedData?.renderingProvider?.profileImage}
                  nickName={updatedData?.renderingProvider?.fullName || ''}
                  // eslint-disable-next-line jsx-a11y/aria-role
                  role="Provider"
                  fullName={`${updatedData?.renderingProvider?.firstName} ${updatedData?.renderingProvider?.lastName}`}
                  memberId={updatedData?.renderingProvider?.userAccountId}
                />
              </Grid>
              <Grid item xs={4} fontSize={14} lineHeight="20px">
                {updatedData?.name || ''}
              </Grid>
            </Grid>
          </Box>
          <Box mt={7} mb={4} display="flex" justifyContent="space-between" gap={4}>
            <Box
              padding={3}
              border={`1px solid ${colors.neutral100}`}
              sx={{ background: colors.neutral25 }}
              flexGrow={1}
              borderRadius={1}
            >
              <Typography fontSize={16} lineHeight="24px" color={colors.neutral900} fontWeight={600}>
                Insurance details
              </Typography>
              <Typography
                fontSize={12}
                lineHeight="20px"
                color={colors.neutral600}
                fontWeight={800}
                textTransform="uppercase"
                mt={2}
                letterSpacing="1px"
              >
                Insurance carrier
              </Typography>
              <Typography fontSize={14} lineHeight="20px" color={colors.neutral900} fontWeight={500}>
                {payerObj?.name || ''}
              </Typography>
              <Typography
                fontSize={12}
                lineHeight="20px"
                color={colors.neutral600}
                fontWeight={800}
                textTransform="uppercase"
                mt={2}
                letterSpacing="1px"
              >
                Plan
              </Typography>
              <Typography fontSize={14} lineHeight="20px" color={colors.neutral900} fontWeight={500}>
                {payerObj?.plans[0]?.name || 'N/A'}
              </Typography>
              <Typography
                fontSize={12}
                lineHeight="20px"
                color={colors.neutral600}
                fontWeight={800}
                textTransform="uppercase"
                mt={2}
                letterSpacing="1px"
              >
                Status
              </Typography>
              <Typography fontSize={14} lineHeight="20px" color={colors.neutral900} fontWeight={500}>
                {payerObj?.isActive ? 'Active' : 'InActive'}
              </Typography>
            </Box>
            <Box
              padding={3}
              border={`1px solid ${colors.neutral100}`}
              sx={{ background: colors.neutral25 }}
              borderRadius={1}
            >
              <Typography fontSize={16} lineHeight="24px" color={colors.neutral900} fontWeight={600}>
                CPT codes & fees
              </Typography>
              {updatedData?.claim?.cptList?.map((cpt, index) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  key={`${cpt?._id}${index}${cpt?.cptCode}`}
                  mt={2}
                >
                  <Typography>{cpt?.cptCode || ''}</Typography>
                  <Typography>{cpt?.fee ? `$${cpt?.fee}` : '' || ''}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          {/* <Box>
            <Typography fontWeight={700} fontSize={14} lineHeight="20px" marginBottom="6px">
              Status
            </Typography>
            <Select
              value="PENDING"
              name="invoiceStatus"
              variant={selectType.SECONDARY}
              emptyText=""
              displayEmpty
              options={INVOICES_STATUSES}
              disabled
            />
          </Box> */}
          <Box gap={3} flexDirection="column" display="flex">
            <Box>
              <Typography
                fontWeight={700}
                fontSize={14}
                lineHeight="20px"
                marginBottom="2px"
                marginTop="27px"
              >
                Amount For The Invoice
              </Typography>
              <TextField
                fullWidth
                sx={{ mt: 1, height: 48 }}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { height: 48 },
                }}
                variant="outlined"
                value={totalAmount}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setTotalAmount(e.target.value)}
              />
            </Box>
            {/* <Box>
            <Typography fontWeight={700} fontSize={14} lineHeight="20px" marginBottom="2px">
              Member Amount
            </Typography>
            <TextField
              fullWidth
              sx={{ mt: 1, height: 48, marginBottom: 4 }}
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                style: { height: 48 },
              }}
              variant="outlined"
              value={memberAmount}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setMemberAmount(e?.target?.value)}
            />
          </Box> */}
            <Box>
              <Typography fontWeight={700} fontSize={14} lineHeight="20px">
                Insurance Paid
              </Typography>
              <TextField
                fullWidth
                sx={{ mt: 1, height: 48, marginBottom: 0 }}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { height: 48 },
                }}
                variant="outlined"
                value={insuranceAmount}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setInsuranceAmount(e?.target?.value)}
              />
            </Box>
            <Box>
              <Typography fontWeight={700} fontSize={14} lineHeight="20px">
                Reason
              </Typography>
              <TextField
                fullWidth
                sx={{ mt: 1 }}
                placeholder="Enter invoice reason"
                variant="outlined"
                value={reason}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setReason(e?.target?.value)}
                multiline
              />
            </Box>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export default CreateInvoice;
