// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '480px !important',
    },
    '& .drawerBody': {
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
      padding: '38px 32px',
    },
  },
  badge: {
    padding: '0 10px',
  },
  payer: {
    paddingTop: 24,
    paddingBottom: 32,
    '&:not(:last-of-type)': { borderBottom: `1px solid ${colors.neutral100}` },
  },
  member: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
    fontSize: 14,
    fontWeight: 500,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  memberDate: {
    color: colors.neutral600,
  },
});
