import { ReactNode } from 'react';

export enum AvatarSize {
  XSMALL = 'xsmall',
  SMALL = 'small',
}

export enum AvatarShape {
  ROUNDED = 'rounded', // default
  SQUARE = 'square',
  CIRCULAR = 'circular',
}

export enum AvatarType {
  IMAGE = 'image', // default
  // PLACEHOLDER = 'placeholder',
  INITIALS = 'initials',
}

export enum AvatarColor {
  IMAGE = 'image', // default
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INDIGO = 'indigo',
  ORANGE = 'orange',
  LIME = 'lime',
  NEUTRAL = 'neutral',
}

export interface IAvatarProps {
  src?: string;
  name?: string;
  type?: AvatarType;
  shape?: AvatarShape;
  size?: AvatarSize;
  color?: AvatarColor;
  className?: string;
  children?: ReactNode;
  [prop: string]: any;
}
