import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Toggle, positionType } from '@confidant-health/lib/ui/atoms/toggle';

// schema
import { CollectionTemplateSchema } from './AddCollectionTemplate.schema';
// styles
import { useStyles } from './AddCollectionTemplate.styles';
// constants
import { IAddCollectionTemplateProps, ResponderTypes } from './AddCollectionTemplate.types';

const defaultValues = {
  name: '',
  description: '',
  scorable: false,
  reorder: false,
  usePreviousResponses: false,
  fullPage: false,
  contentBlocks: [],
  responderType: ResponderTypes[0].value,
  prompt: '',
};

const AddCollectionTemplate: React.FC<IAddCollectionTemplateProps> = ({ dct, isOpen, onClose, onSubmit }) => {
  const classes = useStyles();
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: dct?.name || defaultValues.name,
      description: dct?.description || defaultValues.description,
      scorable: dct?.scorable || defaultValues.scorable,
      reorder: dct?.reorder || defaultValues.reorder,
      usePreviousResponses: dct?.usePreviousResponses || defaultValues.usePreviousResponses,
      fullPage: dct?.fullPage || defaultValues.fullPage,
      contentBlocks: dct?.contentBlocks || defaultValues.contentBlocks,
      responderType: dct?.responderType || ResponderTypes[0].value,
      prompt: dct?.prompt || defaultValues.prompt,
    },
    validationSchema: CollectionTemplateSchema,
    onSubmit: (payload: typeof defaultValues) => {
      onSubmit(payload);
    },
  });

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeToggle = (name: string) => async () => {
    await setFieldValue(name, !values[name]);
  };

  const onChangeResponderType = async ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    await setFieldValue('responderType', target.value);
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{dct ? 'Update' : 'Add'} data collection template</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('DCT name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter a DCT name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Description')}
              <Textarea
                value={values.description}
                name="description"
                type="text"
                placeholder="Enter description"
                onChange={handleChange}
                minRows={1}
                maxRows={5}
                onBlur={setTouched('description')}
                className={classes.textarea}
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Questions for')}
              <Select
                variant={selectType.SECONDARY}
                value={values.responderType}
                className={classes.select}
                onChange={onChangeResponderType}
                options={ResponderTypes.map(s => ({ label: s.name, value: s.value }))}
              />
              <TextError errorMsg={touched.responderType ? errors.responderType?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Prompt Text')}
              <Textarea
                value={values.prompt}
                name="prompt"
                type="text"
                placeholder="Enter a Prompt Text"
                onChange={handleChange}
                minRows={1}
                maxRows={5}
                className={classes.textarea}
                onBlur={setTouched('prompt')}
              />
              <TextError errorMsg={touched.prompt ? errors.prompt?.toString() : null} />
            </Box>
            <Box display="flex" gap="32px">
              <Toggle
                checked={values.scorable}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('scorable')}
              >
                Scorable
              </Toggle>
              {/* <Toggle
                checked={values.reorder}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('reorder')}
              >
                Reorder
              </Toggle> */}
              <Toggle
                checked={values.usePreviousResponses}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('usePreviousResponses')}
              >
                Use Previous Responses
              </Toggle>
              <Toggle
                checked={values.fullPage}
                labelPosition={positionType.RIGHT}
                onChange={onChangeToggle('fullPage')}
              >
                Full Page
              </Toggle>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>{dct ? 'Update' : 'Add'} DCT</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddCollectionTemplate };
