/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    color: colors.neutral600,
  },
  helperText: {
    fontFamily: 'Manrope',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '20px',
  },
  info: {
    borderRadius: 6,
    backgroundColor: colors.neutral50,
    padding: 16,
    marginTop: 32,
    marginBottom: 42,
  },
  infoTitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral700,
    margin: 0,
    marginBottom: 8,
  },
  infoKey: {
    fontSize: 14,
    lineHeight: '20px',
    display: 'block',
    margin: 0,
  },
  formLabel: {
    '& .MuiTypography-body1': {
      fontSize: 16,
    },
  },
  infoValue: {
    fontWeight: 500,
    color: colors.neutral700,
  },
});
