export enum NotificationType {
  READ_EDUCATION = 'READ_EDUCATION',
  SCHEDULED_APPOINTMENT = 'SCHEDULED_APPOINTMENT',
  COMPLETED_APPOINTMENT = 'COMPLETED_APPOINTMENT',
  COMPLETED_CONVERSATION = 'COMPLETED_CONVERSATION',
  POSTED_PROVIDER_REVIEW = 'POSTED_PROVIDER_REVIEW',
  ASKED_QUESTION = 'ASKED_QUESTION',
  WROTE_SESSION_NOTES = 'WROTE_SESSION_NOTES',
  POSTED_APPOINTMENT_RECAP = 'POSTED_APPOINTMENT_RECAP',
  GROUP_MESSAGE = 'GROUP_MESSAGE',
  NEW_MESSAGE = 'NEW_MESSAGE',
}

export interface INotification {
  activityType: NotificationType;
  memberId: string;
  timestamp: string;
  metaData: {
    cost: number;
    providerId: string;
    appointmentId: string;
    memberName: string;
    memberImage: string;
    providerName: string;
    memberId: string;
    status: string;
    isUnRead?: boolean;
    rating: number;
    conversationName: string;
    appointmentType?: string;
    educationName?: string;
    amountMessage?: number;
  };
}
export interface IAdminNotification {
  title: string;
  download: boolean;
  downloadUrl: string;
  recipient: string;
  id: string;
  type: string;
}

export interface NotificationState {
  isLoading: boolean;
  errorMsg: string;
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  notifications: INotification[];
  adminNotifications: IAdminNotification[];
}
