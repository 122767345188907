import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { tz } from 'moment-timezone';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabPanel } from '@confidant-health/lib/ui/atoms/tabs';
import { Text, fontWeight, textLevel, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Icons, IGlyphs } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import MuiDatePicker from '@confidant-health/lib/ui/templates/date-select';
import dayjs from 'utils/dayjs';
import { ISearchScheduleParams, searchSchedule } from 'services/appointment/appointment.service';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { selectProviderServicesState } from 'redux/modules/appointment/selectors';
import { ISlot } from 'redux/modules/appointment/types';
import { getAuth } from 'redux/modules/auth/selectors';
import { UPDATE_APPOINTMENT_SUCCESSFUL } from 'redux/modules/appointment/actions';
import { ISearchScheduleResponse } from 'pages/admin/appointments/add-schedule';
import AppointmentCancel from '../appointment-cancel';
import { appointmentSchedulingTabs } from '../../AppointmentList.constants';
import { useStyles } from './AppointmentScheduling.styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  updateAppointment: (item: IAppointment) => void;
  appointment: IAppointment;
  setIsApptCreated?: (isCreated) => void;
  hideCancelBtn?: boolean;
}

const AppointmentScheduling: FC<IProps> = memo(
  ({ open, onClose, appointment, updateAppointment, setIsApptCreated, hideCancelBtn }) => {
    const classes = useStyles();
    const [serviceId, setServiceId] = useState(appointment?.serviceId);
    const [error, setError] = useState(null);
    const [slotError, setSlotError] = useState(null);
    const [currentTab, setCurrentTab] = useState(appointmentSchedulingTabs[0].tabKey);
    const [timeSlots, setTimeSlots] = useState<{ date: string; slots: ISlot[] }[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<ISlot | null>(null);
    const [hasSelectedTimeSlot, setHasSelectedTimeSlot] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showCancel, setShowCancel] = useState(false);
    const [errorApi, setErrorApi] = useState(null);
    const dispatch = useDispatch();
    const { isLoading: isRequesting, errorMsg, services } = useSelector(selectProviderServicesState);
    const { isAdmin } = useSelector(getAuth);

    useEffect(() => {
      if (appointment && open) {
        setServiceId(appointment.serviceId);
        setSelectedTimeSlot({
          start: appointment.startTime,
          end: appointment.endTime,
        });
        dispatch(appointmentActionCreators.fetchProviderServices(appointment.practitionerId));
      }
    }, [appointment, open]);

    useEffect(() => {
      if (!open) {
        return;
      }
      if (currentTab === appointmentSchedulingTabs[1].tabKey && selectedDate) {
        void getSlotsByDate(dayjs(selectedDate));
      }
      if (currentTab === appointmentSchedulingTabs[0].tabKey && appointment) {
        void getSlotsByDate(dayjs(appointment.startTime));
      }
    }, [currentTab, selectedDate, serviceId, open]);

    useEffect(() => {
      if (!open) {
        setError(null);
        setSlotError(null);
      }
    }, [open]);

    const toggleCancelDrawer = () => {
      setShowCancel(!showCancel);
      // fetchAppointments();
    };

    const onRescheduleSubmit = () => {
      if (!serviceId) {
        setError('Please select a session type');
        return;
      }
      if (!hasSelectedTimeSlot) {
        setSlotError('Please select a time slot');
        return;
      }
      dispatch(
        appointmentActionCreators.updateAppointment({
          appointmentId: appointment.appointmentId,
          bodyRequest: {
            action: 'REQUEST_CHANGES',
            appointmentRequestDto: {
              serviceId,
              memberId: appointment.participantId,
              startTime: selectedTimeSlot.start,
            },
          },
          callback: err => {
            if (!err) {
              const service = services.find(item => item.id === serviceId);
              const newData = {
                ...appointment,
                serviceId,
                startTime: selectedTimeSlot.start,
                endTime: selectedTimeSlot.end,
              };
              if (service) {
                newData.serviceName = service.name;
                newData.serviceDuration = service.duration;
              }
              updateAppointment(newData);
              dispatch({
                type: UPDATE_APPOINTMENT_SUCCESSFUL,
                payload: newData,
              });
              onClose();
              if (setIsApptCreated) setIsApptCreated(true);
            }
          },
        }),
      );
    };

    const onAppointmentCancel = newData => {
      updateAppointment(newData);
      dispatch({
        type: UPDATE_APPOINTMENT_SUCCESSFUL,
        payload: newData,
      });
      if (setIsApptCreated) setIsApptCreated(true);
    };

    const getAvailableSlotsCb = useCallback(
      async (date: Dayjs) => {
        if (!appointment?.serviceId) {
          return null;
        }
        try {
          setErrorApi(null);
          const params: ISearchScheduleParams = {
            participantIds: appointment.practitionerId,
            memberId: appointment.participantId,
            serviceId: appointment?.serviceId,
            timezone: tz.guess(),
            viewProviderDetails: false,
          };
          if (date) {
            const startDate = date.format('DD-MM-YYYY');
            const endDate = dayjs(date).add(7, 'd').format('DD-MM-YYYY');
            params.startDate = startDate;
            params.endDate = endDate;
          }

          const { data }: { data: ISearchScheduleResponse } = await searchSchedule(params);

          const slots = [];
          if (data.results.length > 0) {
            const reduced = data.results[0].slots.reduce((prev: any, slot) => {
              const dayString = dayjs(slot.start).format('DD-MM-YYYY');
              if (prev[dayString]) {
                prev[dayString].push(slot);
              } else {
                prev[dayString] = [slot];
              }
              return prev;
            }, {});
            Object.keys(reduced).forEach(dayString => {
              slots.push({
                date: dayjs(dayString, 'DD-MM-YYYY').toISOString(),
                slots: reduced[dayString],
              });
            });
          }

          return slots.sort(
            (a, b) => dayjs(a.date, 'DD-MM-YYYY').unix() - dayjs(b.date, 'DD-MM-YYYY').unix(),
          );
        } catch (err) {
          setErrorApi(err?.data?.errors[0]?.endUserMessage || 'Something went wrong!');
          return null;
        }
      },
      [appointment, serviceId, services],
    );

    const getSlotsByDate = async (date: Dayjs) => {
      if (!isLoading) {
        setIsLoading(true);
        const slot = await getAvailableSlotsCb(date);
        setTimeSlots(slot || []);
        setIsLoading(false);
      }
    };

    const onChangeSessionType = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
      setServiceId(target.value);
      error && setError(null);
      slotError && setSlotError(null);
    };

    const onTimeSlotClick = (timeSlot: ISlot) => () => {
      setSelectedTimeSlot(timeSlot);
      setHasSelectedTimeSlot(true);
      slotError && setSlotError(null);
    };

    const handleDateChange = e => {
      slotError && setSlotError(null);
      setHasSelectedTimeSlot(false);
      setSelectedTimeSlot(null);
      setSelectedDate(e);
    };

    const handleTabChange = e => {
      slotError && setSlotError(null);
      setHasSelectedTimeSlot(false);
      setSelectedTimeSlot(null);
      setCurrentTab(e);
    };

    const renderInfo = (icon: IGlyphs['glyph'], value) => (
      <Box className={classes.boxInfo}>
        <Icons color={colors.neutral600} glyph={icon} />
        <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
          {value}
        </Text>
      </Box>
    );

    const renderLabel = (label: string) => (
      <Text className={clsx(classes.text14, classes.label)} level={textLevel.S} weight={fontWeight.BOLD}>
        {label}
      </Text>
    );

    const slotSliceFactor = currentTab === appointmentSchedulingTabs[0].tabKey ? 1 : timeSlots.length;
    return (
      <Drawer
        open={open}
        onClose={onClose}
        title="Reschedule appointment"
        variant={drawerType.FORM}
        footer={<div />}
        className={classes.drawer}
      >
        {!!appointment && (
          <>
            <Box flex={1}>
              <Box className={classes.boxTop}>
                <Box>
                  <Text weight={fontWeight.BOLD} className={classes.title}>
                    {appointment.serviceName}
                  </Text>
                  <Text weight={fontWeight.MEDIUM} className={clsx(classes.text14, classes.duration)}>
                    {appointment.serviceDuration} minutes session
                  </Text>
                </Box>
                <Box style={{ height: 24 }} />

                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <ProfileInfo
                    type={appointment.providerRole ? 'provider' : 'member'}
                    photo={appointment.participantImage}
                    nickName={appointment.participantName}
                    fullName={
                      appointment.providerName ||
                      `${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`
                    }
                    role={appointment.providerRole}
                    memberId={
                      appointment?.providerRole
                        ? appointment?.provider?.userAccountId
                        : appointment?.patientId
                    }
                    isProvider={!isAdmin}
                  />
                  <Box sx={{ paddingRight: 6 }}>
                    {renderInfo('event-outlined', dayjs(appointment.startTime).format('MMMM DD, YYYY'))}
                    {renderInfo('clock', `${dayjs(appointment.startTime).format('hh:mma')} ${tz.guess()}`)}
                  </Box>
                </Box>
              </Box>
              <Tabs
                options={appointmentSchedulingTabs}
                value={currentTab}
                onChange={handleTabChange}
                className={classes.tabs}
              />
              <Box className={classes.selectWrap}>
                {renderLabel('Session type')}
                <Select
                  variant={selectType.SECONDARY}
                  value={serviceId || ''}
                  className={classes.select}
                  onChange={onChangeSessionType}
                  options={services.map(s => ({ label: s.name, value: s.id }))}
                />
                <TextError errorMsg={error} />
              </Box>
              <TabPanel value={currentTab} tabKey={appointmentSchedulingTabs[1].tabKey}>
                <Box className={classes.tabPanel}>
                  <Box sx={{ width: '100%' }}>
                    {renderLabel('Select date')}
                    <MuiDatePicker
                      inputClassName={classes.select}
                      value={selectedDate.toISOString()}
                      onChange={handleDateChange}
                      minDate={new Date()}
                    />
                  </Box>
                </Box>
              </TabPanel>
              {errorApi && (
                <Box display="flex" justifyContent="center" flex={1} padding={2}>
                  <TextError errorMsg={errorApi || error} />
                </Box>
              )}
              {isLoading ? (
                <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
                  <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                </Box>
              ) : (
                <Box className={classes.timeSlots}>
                  {timeSlots.slice(0, slotSliceFactor).map((item, idx) => {
                    const slots: ISlot[] =
                      currentTab === appointmentSchedulingTabs[0].tabKey
                        ? item.slots.slice(0, 2)
                        : item.slots;
                    return (
                      <Box key={idx}>
                        <Text className={classes.day}>{dayjs(item.date).format('MMMM DD, YYYY')}</Text>
                        <Box className={classes.timeSlotItems}>
                          {slots.map((slot, index) => (
                            <Box
                              key={index}
                              className={clsx(classes.timeSlot, {
                                timeSlotActive: selectedTimeSlot?.start === slot.start,
                              })}
                              onClick={onTimeSlotClick(slot)}
                            >
                              {`${dayjs(slot.start).format('h:mm a')} — ${dayjs(slot.end).format('h:mm a')}`}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    );
                  })}
                  <TextError errorMsg={slotError} />
                  {!isLoading && timeSlots.length === 0 && (
                    <Text className={classes.day}>No time slot available</Text>
                  )}
                </Box>
              )}
            </Box>

            <Box className={classes.actions}>
              <Box>
                <TextError errorMsg={errorMsg} />
              </Box>
              <Box>
                {!hideCancelBtn && (
                  <IconButton
                    variant={btnType.SECONDARY}
                    className={classes.btnCancel}
                    onClick={toggleCancelDrawer}
                  >
                    Cancel appointment
                  </IconButton>
                )}
                <IconButton
                  variant={iconBtnType.PRIMARY}
                  className={clsx(classes.btn)}
                  onClick={onRescheduleSubmit}
                  disabled={isRequesting}
                >
                  {isRequesting && (
                    <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                  )}
                  Reschedule
                </IconButton>
              </Box>
            </Box>
          </>
        )}
        {showCancel && (
          <AppointmentCancel
            open={showCancel}
            onClose={() => {
              toggleCancelDrawer();
              onClose();
            }}
            appointment={appointment}
            updateAppointment={onAppointmentCancel}
          />
        )}
      </Drawer>
    );
  },
);

AppointmentScheduling.displayName = 'AppointmentScheduling';

export { AppointmentScheduling };
