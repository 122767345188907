import { createAction } from 'redux-actions';

export const FETCH_APPOINTMENTS_V4 = 'report-service/appointments/fetch';
export const FETCH_APPOINTMENTS_V4_SUCCESSFUL = 'report-service/appointments/fetchSuccessful';
export const FETCH_APPOINTMENTS_V4_FAILED = 'report-service/appointments/fetchFailed';
export const GET_APPOINTMENT_CPT_CODE = 'report-service/appointment-cpt-code/fetch';
export const GET_APPOINTMENT_CPT_CODE_SUCCESSFUL = 'report-service/appointment-cpt-code/fetchSuccessful';
export const GET_APPOINTMENT_CPT_CODE_FAILED = 'report-service/appointment-cpt-code/fetchFailed';
export const ADD_APPOINTMENT_CPT_CODE = 'report-service/appointment-cpt-code/add';
export const ADD_APPOINTMENT_CPT_CODE_SUCCESSFUL = 'report-service/appointment-cpt-code/addSuccessful';
export const ADD_APPOINTMENT_CPT_CODE_FAILED = 'report-service/appointment-cpt-code/addFailed';
export const UPDATE_APPOINTMENT_CPT_CODE = 'report-service/appointment-cpt-code/update';
export const UPDATE_APPOINTMENT_CPT_CODE_SUCCESSFUL = 'report-service/appointment-cpt-code/updateSuccessful';
export const UPDATE_APPOINTMENT_CPT_CODE_FAILED = 'report-service/appointment-cpt-code/updateFailed';

export const reportServiceActionCreators = {
  fetchAppointments: createAction(FETCH_APPOINTMENTS_V4),
  fetchAppointmentCptCodeById: createAction(GET_APPOINTMENT_CPT_CODE),
  addAppointmentCptCode: createAction(ADD_APPOINTMENT_CPT_CODE),
  updateAppointmentCptCode: createAction(UPDATE_APPOINTMENT_CPT_CODE),
};
