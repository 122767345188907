import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cardTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
  },
  cardTopTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});
