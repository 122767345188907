import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  body: { display: 'flex', flexDirection: 'column', gap: 24 },
  // general information
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginRight: 8,
    backgroundColor: colors.neutral400,
  },
  activeDot: {
    backgroundColor: colors.success600,
  },
  stateBadge: {
    borderRadius: 100,
    border: `1px solid ${colors.neutral200}`,
    background: colors.white,
  },
  tabs: {
    '& > div > div': {
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 1,
        backgroundColor: colors.neutral100,
        bottom: 0,
      },
    },
    '& button': {
      textTransform: 'none',
      paddingTop: 26,
      paddingBottom: 26,
      paddingLeft: 20,
      paddingRight: 20,
      color: colors.neutral600,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '20px',
      border: 'none',
      '&.Mui-selected': {
        color: colors.neutral900,
      },
    },
    '& .MuiTabs-indicator': {
      height: 1,
      backgroundColor: colors.neutral900,
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '640px !important',
    },
    '& .drawerBody': {
      padding: 0,
    },
  },
  videoContainer: {
    paddingTop: 32,
    paddingBottom: 32,
  },
  video: {
    border: 'none',
    borderRadius: 10,
  },
  tab1Container: {
    '& ul': {
      paddingLeft: 20,
    },
  },
  heading2: {
    fontFamily: 'Source Serif Pro',
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '48px',
  },
  text: {
    fontFamily: 'Manrope',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '28px',
  },
});
