import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  cardTop: {
    padding: '28px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  dfex: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  cardTopTitle: {
    fontSize: 24,
    lineHeight: '32px',
    margin: 0,
  },
  cardTopIconBtn: {
    fontSize: 14,
    '& svg': {
      width: 15,
      marginRight: 8,
    },
  },
});
