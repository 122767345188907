import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { profileActionCreators } from 'redux/modules/profile/actions';
import { getProfile, selectConnections } from 'redux/modules/profile/selectors';

import { IAddConnectionDTO } from '../Connections.types';
import { useStyles } from './AddConnection.styles';
import { memberColumns } from './AddConnection.constants';

type IAddConnectionProps = {
  isOpen: boolean;
  isSaving: boolean;
  onClose: () => void;
  onAddConnection?: (payload: IAddConnectionDTO) => void;
};

const AddConnection: React.FC<IAddConnectionProps> = ({ isOpen, isSaving, onClose, onAddConnection }) => {
  const classes = useStyles();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [listedMembers, setListedMembers] = useState([]);
  const [filteredCount, setFilteredCount] = useState<number | null>(0);
  const [value, setValue] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const {
    isLoading,
    payload: { patientsList, totalRecords },
  } = useSelector(getProfile);
  const { connections } = useSelector(selectConnections);
  const dispatch = useDispatch();

  const tableData = useMemo(
    () =>
      listedMembers.map(patient => ({
        ...patient,
        connection: patient.member,
        actions: { userId: patient.member.id },
      })),
    [listedMembers],
  );

  useEffect(() => {
    setSelectedUserId(null);
    const newMembers = patientsList.filter(
      member => !connections.some(item => item.connectionId === member.id),
    );
    setListedMembers([...newMembers]);
    setFilteredCount(newMembers.length);
  }, [patientsList]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedUserId(null);
    }
  }, [isOpen]);

  const handleFetchPatientsParamsFormat = ({ search, pagination, sorter }: tableParamsType) => {
    const sortBy = sorter.column === 'member' ? 'name' : sorter.column || '';
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction,
      sortBy,
    };

    return params;
  };

  useEffect(() => {
    dispatch(profileActionCreators.fetchPatients(handleFetchPatientsParamsFormat(value)));
  }, [value]);

  const handleRowClick = useCallback(
    item => {
      setSelectedUserId(item.member.id);
    },
    [setSelectedUserId],
  );

  const handleSubmit = useCallback(() => {
    onAddConnection && onAddConnection({ userId: selectedUserId });
    setValue({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: { direction: 'asc', column: '' },
    });
  }, [onAddConnection, selectedUserId]);

  const handleClose = () => {
    onClose();
    setValue({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: { direction: 'asc', column: '' },
    });
  };

  const renderColumns = memberColumns.map(column => {
    if (column.id === 'connection') {
      return {
        ...column,
        renderCell: ({ photo, fullName, nickName }: any) => (
          <div className={classes.member}>
            <Avatar size={40} variant={avatarType.CIRCLE} src={photo} name={nickName} />
            <div className={classes.info}>
              <span className={classes.id}>{nickName}</span>
              <span className={classes.name}>{fullName}</span>
            </div>
          </div>
        ),
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ userId }) => (
          <div className={classes.checkIcon}>
            {selectedUserId === userId && <Icons glyph="checkmark" color={colors.success500} />}
          </div>
        ),
      };
    }
    return column;
  });

  return (
    <Drawer open={isOpen} onClose={handleClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text weight={fontWeight.BOLD} className={classes.headTitle}>
            Add Connection
          </Text>
          <IconButton icon="close" className={classes.closeBtn} onClick={handleClose} />
        </Box>
        <Box className={clsx(classes.content, { [classes.showFooter]: !!selectedUserId })}>
          <Table
            searchProps={{
              placeholder: 'Search patients by name, phone, or email',
            }}
            gridProps={{
              columns: renderColumns,
              data: tableData,
              isLoading,
              onRowClick: handleRowClick,
            }}
            paginationProps={{
              currentRows: listedMembers.length,
              totalCount: filteredCount,
              siblingCount: 0,
              boundaryCount: 0,
              size: 'small',
            }}
            value={value}
            onChange={setValue}
            className={classes.table}
          />
        </Box>
        {selectedUserId && (
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={!selectedUserId || isSaving}>
              {!isSaving ? 'Add connection' : 'Saving...'}
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export { AddConnection };
