import { FC } from 'react';
import { Box } from '@mui/system';
import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { EVALUATION_STATUS } from 'constants/CommonConstants';
import history from 'utils/history';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useStyles } from './index.styles';
import { IEvaluationDetailProps } from './EvaluationDetail.types';
import EvaluationDetails from './EvaluationDetails';

const EvaluationDetail: FC<IEvaluationDetailProps> = ({
  isOpen,
  onClose,
  evaluation,
  isView = true,
  appointmentId,
  memberId,
  fullScreen,
}) => {
  const classes = useStyles();

  const renderContent = () => (
    <EvaluationDetails
      fullScreen={fullScreen}
      evaluation={evaluation}
      appointmentId={appointmentId}
      memberId={memberId}
    />
  );

  const statusBadgeStyle =
    (evaluation as any)?.aiCompletionStatus === 'IN_PROGRESS'
      ? badgeStyle.MISREPORTED
      : evaluation.status === 'NOT_STARTED'
      ? badgeStyle.INACTIVE
      : evaluation.status === 'IN_PROGRESS'
      ? badgeStyle.PRIMARY
      : evaluation.status === 'REJECTED'
      ? badgeStyle.CRITICAL
      : badgeStyle.SUCCESS;

  const renderStatusBadge = () => {
    if ((evaluation as any)?.aiCompletionStatus === 'IN_PROGRESS') return 'Waiting for AI';
    return EVALUATION_STATUS[evaluation.status];
  };

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      variant={drawerType.NORMAL}
      className={clsx(classes.drawer, fullScreen && classes.fullScreen)}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtn} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Text className={classes.title}>{evaluation.name}</Text>
                <Badge style={statusBadgeStyle} variant={badgeType.OUTLINED} className={classes.evBadge}>
                  {renderStatusBadge()}
                </Badge>
              </Box>
              {fullScreen && (
                <Box display="flex" justifyContent="flex-start" width="100%">
                  <Text className={classes.subTitle}>
                    Completed {dayjs(evaluation?.completedAt || '').format('MM/DD/YYYY')}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
          {isView && (
            <Button
              className={classes.editEvaluationDetail}
              onClick={() =>
                history.push(`/admin/collection-evaluations-states/${evaluation.id}/${appointmentId}`)
              }
            >
              Edit
            </Button>
          )}
        </Box>
        {renderContent()}
      </Box>
    </Drawer>
  );
};

export default EvaluationDetail;
