/* eslint-disable jsx-a11y/aria-role */
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { theme } from '@confidant-health/lib/theme';
import ProfileInfo from 'components/profile-info';

import { useStyles } from './MasterScheduleItem.styles';

const MasterScheduleItem: React.FC<any> = ({ data, onClickSchedule }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { providerDetailsDto, slots, providerName } = data;

  const firstAvailableDate =
    Object.keys(slots).length > 0 ? slots[Object.keys(slots)[0]]?.[0]?.start : '9999-01-01';

  const dateList = useMemo(() => {
    if (firstAvailableDate?.includes('9999')) {
      return [];
    }
    return isMobile
      ? Array.from({ length: 5 }, (_, index) => dayjs().add(index, 'day').format('YYYY-MM-DD'))
      : Array.from({ length: 7 }, (_, index) => dayjs().add(index, 'day').format('YYYY-MM-DD'));
  }, [firstAvailableDate, isMobile]);

  return (
    <>
      <Grid
        container
        className={classes.root}
        onClick={() => {
          onClickSchedule();
        }}
      >
        <Grid item xs={3}>
          <ProfileInfo
            fullName={providerDetailsDto !== undefined ? providerDetailsDto.name : providerName}
            userRole={providerDetailsDto !== undefined ? providerDetailsDto?.designation : providerName}
          />
        </Grid>
        <Grid item container xs={9}>
          <Grid item xs={2} display="flex" flexDirection="column" justifyContent="space-around" gap={0.5}>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral600}>
              {slots && Object.keys(slots).length > 0 ? `Next available` : `Not Available`}
            </Text>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
              {slots && Object.keys(slots).length > 0
                ? dayjs(slots[Object.keys(slots)[0]]?.[0]?.start).format('MMMM D')
                : ``}
            </Text>
          </Grid>
          <Grid item container xs={10}>
            {dateList?.length > 0 &&
              dateList.map((date, index) => (
                <Grid
                  item
                  key={index}
                  xs={isMobile ? 2.4 : 1.5}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={0.5}
                >
                  <Text level={textLevel.S} weight={fontWeight.SEMI_BOLD}>
                    {dayjs(date).format('ddd')}
                  </Text>
                  <Text level={textLevel.S} weight={fontWeight.MEDIUM} color={colors.neutral600}>
                    {dayjs(date).format('M/D')}
                  </Text>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 3,
                      border:
                        slots[dayjs(date).format('YYYY-MM-DD')]?.length > 0
                          ? `1px solid ${colors.primary600}`
                          : `1px solid ${colors.neutral200}`,
                      paddingX: 1,
                      paddingY: 0.5,
                    }}
                  >
                    <Text
                      level={textLevel.XS}
                      weight={fontWeight.SEMI_BOLD}
                      color={
                        slots[dayjs(date).format('YYYY-MM-DD')]?.length > 0
                          ? colors.primary600
                          : colors.neutral700
                      }
                    >
                      {slots[dayjs(date).format('YYYY-MM-DD')]?.length > 0
                        ? `${slots[dayjs(date).format('YYYY-MM-DD')]?.length} slots`
                        : 'No slots'}
                    </Text>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { MasterScheduleItem };
