/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

interface StyleProps {
  currentIndex: number;
  choices: any[];
}

export const useStyles = makeStyles({
  radioGroupRoot: ({ currentIndex, choices }: StyleProps) => {
    const choicesChilds = choices.reduce((acc, cur, index) => {
      return {
        ...acc,
        [`&:nth-child(${index + 1})`]: {
          backgroundColor: index === currentIndex ? colors.neutral50 : colors.white,
        },
      };
    }, {});
    return {
      display: 'flex',
      '& .MuiFormGroup-root': {
        gap: '0px',
        flex: 1,
        '& .MuiFormControlLabel-root': {
          minHeight: '40px',
          alignItems: 'center !important',
          ...choicesChilds,
          '&:hover': {
            backgroundColor: colors.neutral50,
          },
        },
      },
    };
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginBottom: 4,
  },
  helperText: {
    fontFamily: 'Manrope',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '20px',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    color: colors.neutral600,
  },
  radioGroup: {
    gap: 16,
    '& .MuiTypography-body1': {
      fontSize: 16,
    },
  },
  adjustHeight: {
    height: '32px !important',
  },
});
