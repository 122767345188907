import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, RadioGroup, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Radio } from '@confidant-health/lib/ui/atoms/radio';
import { Toggle } from '@confidant-health/lib/ui/atoms/toggle';

import { priorityActionCreators } from 'redux/modules/priority';

import { titleCase } from 'utils/CommonUtils';

import { levelSelects, requireList, TAXONOMY_TYPES } from './AddEditPriorityType.constants';
import { PriorityTypeSchema } from './AddEditPriorityType.schema';
import { IAddNewProps } from './AddEditPriorityType.types';
import { useStyles } from './AddEditPriorityType.styles';

const AddEditPriorityType: React.FC<IAddNewProps> = ({ isOpen, onClose, fetchDomainTypes, editData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = editData?.metaData?.metaDataSpec;
  const level = data?.importanceLevels
    ? Object.keys(data.importanceLevels)
        .filter(flt => data?.importanceLevels[flt])
        .map(item => {
          return { title: titleCase(item), value: titleCase(item) };
        })
    : [];
  const defaultValues = {
    name: editData?.name ?? '',
    level: level ?? [],
    orderBy: data?.orderBy ?? 'importance_level',
    requireDiagnosisInfo: data?.requireDiagnosisInfo || false,
    requireInterferenceWithLifeInfo: data?.requireInterferenceWithLifeInfo || false,
    requireNotes: data?.requireNotes || false,
    requireRxInfo: data?.requireRxInfo || false,
    requireRelatedToInfo: data?.requireRelatedToInfo || false,
    requireSubstanceUse: data?.requireSubstanceUse || false,
    requireHistoryInfo: data?.requireHistoryInfo || false,
    requireRevampInfo: data?.requireRevampInfo || false,
    requireSpecification: data?.requireSpecification || false,
    requireRelatedToMedicalCondition: data?.requireRelatedToMedicalCondition || false,
    requireRelatedToMedication: data?.requireRelatedToMedication || false,
    requireRelatedToSubstanceUse: data?.requireRelatedToSubstanceUse || false,
    requireRelatedToWithdrawal: data?.requireRelatedToWithdrawal || false,
    requireIcd10Codes: data?.requireIcd10Codes || false,
  };

  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: PriorityTypeSchema,
    onSubmit: () => {
      const importanceLevelsObj = {};
      levelSelects.forEach(item => {
        importanceLevelsObj[item.value.toUpperCase()] = !!values.level.find(lev => lev.value === item.value);
      });
      const metaDataSpec = {
        importanceLevels: importanceLevelsObj,
        requireDiagnosisInfo: values.requireDiagnosisInfo,
        requireHistoryInfo: values.requireHistoryInfo,
        requireInterferenceWithLifeInfo: values.requireInterferenceWithLifeInfo,
        requireNotes: values.requireNotes,
        requireRelatedToInfo: values.requireRelatedToInfo,
        requireRevampInfo: values.requireRevampInfo,
        requireRxInfo: values.requireRxInfo,
        requireSpecification: values.requireSpecification,
        requireSubstanceUse: values.requireSubstanceUse,
        requireRelatedToMedicalCondition: values.requireRelatedToMedicalCondition,
        requireRelatedToMedication: values.requireRelatedToMedication,
        requireRelatedToSubstanceUse: values.requireRelatedToSubstanceUse,
        requireRelatedToWithdrawal: values.requireRelatedToWithdrawal,
        requireIcd10Codes: values.requireIcd10Codes,
        orderBy: values.orderBy || '',
      };
      const payload = {
        name: values.name,
        type: TAXONOMY_TYPES.PATIENT_DATA_DOMAIN,
        metaData: {
          metaDataSpec,
        },
        parent: 'CONCEPT',
        children: [],
        tags: [],
      };
      if (editData) {
        dispatch(
          priorityActionCreators.updatePriorityDomainType({
            data: { ...payload, Id: editData.Id },
            callback: (isSucceed: boolean) => {
              if (isSucceed) {
                fetchDomainTypes();
                onClose();
              }
            },
          }),
        );
      } else {
        dispatch(
          priorityActionCreators.createPriorityDomainType({
            data: payload,
            callback: (isSucceed: boolean) => {
              if (isSucceed) {
                fetchDomainTypes();
                onClose();
              }
            },
          }),
        );
      }
    },
  });

  const onChangeTags = async (_, val) => {
    await rest.setFieldValue('level', val);
  };

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeToggle = (name: string) => {
    handleChange({ target: { name, value: !values[name] } });
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{`${editData ? 'Edit' : 'Add'} data domain type`}</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter a data domain type name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Importance level')}
              <Input
                value={values.level}
                name="level"
                className={classes.valuesInput}
                options={levelSelects
                  .filter(item => !values.level?.includes(item))
                  ?.sort((a, b) => a.title.localeCompare(b.title))}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={onChangeTags}
                variant={inputType.TAGS}
                size={inputSize.M}
                fullWidth
                showchipcolor
              />
              <TextError errorMsg={touched.level ? errors.level?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Order by:')}
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                value={values.orderBy}
                name="orderBy"
                onChange={handleChange}
                className={classes.radioGroup}
              >
                <FormControlLabel value="importance_level" control={<Radio />} label="Importance level" />
                <FormControlLabel value="date_time" control={<Radio />} label="Date & time" />
                <FormControlLabel value="fixed" control={<Radio />} label="Fixed order" />
              </RadioGroup>
            </Box>
            <Box className={classes.section}>
              {renderLabel('Required information:')}
              <Box className={classes.checkboxGroup}>
                {requireList.map(({ label, name }) => (
                  <FormControlLabel
                    key={label}
                    checked={values[name]}
                    name={name}
                    onChange={() => onChangeToggle(name)}
                    control={<Toggle />}
                    label={label}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose} className={classes.cancel}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} className={classes.submit}>
              {`${editData ? 'Update' : 'Add'} data domain type`}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddEditPriorityType };
