import { FC } from 'react';
import { Box, Stack } from '@mui/material';

import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import RecentAssignmentsTable from 'pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';

import { useStyles } from './Appointments.styles';

type Props = {
  isOpen: boolean;
  onClose(): void;
  title?: string;
  subtitle?: string;
  tableColumns?: TableGrid.TableColumn[];
  renderMenuAction?: (payload: any) => void;
  data?: unknown[];
  onRowClick?: (item: any) => void;
};

const ViewAllDrawer: FC<Props> = ({
  isOpen,
  onClose,
  title = '',
  subtitle = '',
  tableColumns = [],
  renderMenuAction,
  data,
  onRowClick,
}) => {
  const classes = useStyles();
  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {isOpen && (
        <Box className={classes.drawerContent}>
          <Box className={classes.headerDetail}>
            <Stack direction="column">
              <Text className={classes.headerTitle} weight={fontWeight.BOLD}>
                {title}
              </Text>
              <Text className={classes.headerSubtitle}>{subtitle}</Text>
            </Stack>
            <IconButton icon="close" onClick={onClose} className={classes.closeIconBtn} />
          </Box>
          <Box className={classes.drawerTable}>
            <RecentAssignmentsTable
              isShowAll
              tableColumns={tableColumns}
              data={data}
              renderMenuAction={renderMenuAction}
              onRowClick={onRowClick}
            />
          </Box>
          <Box className={classes.drawerFooter}>
            <Button variant={btnType.PRIMARY} onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export { ViewAllDrawer };
