import cx from 'clsx';
import { Drawer } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { colors } from '@confidant-health/lib/colors';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';
import { Icons } from '@confidant-health/lib/icons';
import { Box, Divider } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Heading from 'components/v2/Heading';
import { HeadingLevel } from 'components/v2/Heading/index.types';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

import { useStyles } from './index.styles';
import dayjs from '../../../../utils/dayjs';
import { InsuranceStates } from './index.constants';

type Props = {
  open: boolean;
  onClose: () => void;
  insurance: any;
};

const SubmissionStatusDrawer = ({ open, onClose, insurance }: Props) => {
  const classes = useStyles();
  const [postSubmissionEmails, setPostSubmissionEmails] = useState([]);
  const [par, setPar] = useState(null);

  useEffect(() => {
    if (insurance?.submission?.responses) {
      const emails = [...insurance.submission.responses];
      if (insurance?.submission?.currentStatus === InsuranceStates.APPROVED) {
        const latestEmail = emails.pop();
        setPar(latestEmail);
      }
      setPostSubmissionEmails(emails);
    }
  }, []);

  const renderBadge = () => {
    if (insurance?.submission?.currentStatus === InsuranceStates.IN_PROGRESS) {
      return (
        <Badge variant={badgeType.OUTLINED} size={badgeSize.XSMALL} className={classes.submittingBadge}>
          <Typography {...typography.body.s.semibold}>Submitting</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.REQUESTED) {
      return (
        <Badge variant={badgeType.OUTLINED} size={badgeSize.XSMALL} className={classes.submittedBadge}>
          <Typography {...typography.body.s.semibold}>Requested</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.ACTION_REQUIRED) {
      return (
        <Badge variant={badgeType.OUTLINED} size={badgeSize.XSMALL} className={classes.actionRequiredBadge}>
          <Typography {...typography.body.s.semibold}>Action required</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.SUBMITTED) {
      return (
        <Badge variant={badgeType.OUTLINED} size={badgeSize.XSMALL} className={classes.submittedBadge}>
          <Typography {...typography.body.s.semibold}>Submitted</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.FAILED) {
      return (
        <Badge variant={badgeType.OUTLINED} size={badgeSize.XSMALL} className={classes.failedBadge}>
          <Typography {...typography.body.s.semibold}>Failed</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.APPROVED) {
      return (
        <Badge variant={badgeType.OUTLINED} size={badgeSize.XSMALL} className={classes.approvedBadge}>
          <Typography {...typography.body.s.semibold}>Approved</Typography>
        </Badge>
      );
    }
    if (insurance?.submission?.currentStatus === InsuranceStates.REJECTED) {
      return (
        <Badge variant={badgeType.OUTLINED} size={badgeSize.XSMALL} className={classes.failedBadge}>
          <Typography {...typography.body.s.semibold}>Rejected</Typography>
        </Badge>
      );
    }
    return null;
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Heading variant={HeadingLevel.H5}>Aetna submission status</Heading>
          <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
        </Box>
        <Box className={classes.gridBox}>
          <Box className={classes.gridItem} borderBottom={`1px solid ${colors.neutral100}`}>
            <Typography {...typography.overline.small} color={colors.neutral500}>
              SUBMISSION DATE
            </Typography>
            <Typography {...typography.body.m.medium} color={colors.neutral900}>
              {insurance?.submission?.submissionDate
                ? dayjs(insurance?.submission?.submissionDate).format('MMMM D, YYYY')
                : 'TBD'}
            </Typography>
          </Box>
          <Box
            className={classes.gridItem}
            borderLeft={`1px solid ${colors.neutral100}`}
            borderRight={`1px solid ${colors.neutral100}`}
            borderBottom={`1px solid ${colors.neutral100}`}
          >
            <Typography {...typography.overline.small} color={colors.neutral500}>
              SUBMISSION ID
            </Typography>
            <Typography
              {...typography.body.m.medium}
              color={colors.neutral900}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                maxWidth: '140px',
                wordBreak: 'break-all',
                '&:hover': {
                  whiteSpace: 'normal',
                  overflow: 'visible',
                },
              }}
            >
              {insurance?.submission?.submissionId || 'TBD'}
            </Typography>
          </Box>
          <Box className={classes.gridItem} borderBottom={`1px solid ${colors.neutral100}`}>
            <Typography {...typography.overline.small} color={colors.neutral500}>
              CURRENT STATUS
            </Typography>
            {renderBadge()}
          </Box>
          <Box className={classes.gridItem} borderBottom={`1px solid ${colors.neutral100}`}>
            <Typography {...typography.overline.small} color={colors.neutral500}>
              LAST COMMUNICATION
            </Typography>
            <Typography {...typography.body.m.medium} color={colors.neutral900}>
              {insurance?.submission?.lastCommunicationDate
                ? dayjs(insurance?.submission?.lastCommunicationDate).format('MMMM D, YYYY')
                : 'TBD'}
            </Typography>
          </Box>
          <Box
            className={classes.gridItem}
            borderLeft={`1px solid ${colors.neutral100}`}
            borderRight={`1px solid ${colors.neutral100}`}
            borderBottom={`1px solid ${colors.neutral100}`}
          >
            <Typography {...typography.overline.small} color={colors.neutral500}>
              APPROVAL DATE
            </Typography>
            <Typography {...typography.body.m.medium} color={colors.neutral900}>
              {insurance?.submission?.approvalDate
                ? dayjs(insurance?.submission?.approvalDate).format('MMMM D, YYYY')
                : 'TBD'}
            </Typography>
          </Box>
          <Box className={classes.gridItem} borderBottom={`1px solid ${colors.neutral100}`}>
            <Typography {...typography.overline.small} color={colors.neutral500}>
              PROVIDER PIN
            </Typography>
            <Typography {...typography.body.m.medium} color={colors.neutral900}>
              {insurance?.submission?.providerPin || 'TBD'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            justifyContent: 'space-between',
            padding: '48px',
          }}
        >
          <Collapsable
            open
            className={cx(
              insurance?.submission?.screenshots?.length > 0
                ? classes.collapsable
                : classes.collapsableDisabled,
            )}
            iconColor={colors.neutral700}
            iconPosition={IiconPosition.RIGHT}
            iconHidden={insurance?.submission?.screenshots?.length === 0}
            labelExtend={
              <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                <Icons
                  glyph="checkbox-multiple"
                  color={
                    insurance?.submission?.screenshots?.length > 0 ? colors.primary500 : colors.neutral500
                  }
                />
                <Typography {...typography.body.m.semibold}>Submission</Typography>
                <Typography {...typography.body.s.medium} color={colors.neutral500}>
                  3842964
                </Typography>
              </Box>
            }
          >
            {insurance?.submission?.screenshots?.length > 0 && (
              <Box className={classes.preSubmissionGrid}>
                {insurance?.submission?.screenshots?.map((image, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        height: '101px',
                        overflow: 'hidden',
                        cursor: 'pointer',
                      }}
                      onClick={() => window.open(image?.signedUrl, '_blank')}
                    >
                      <img src={image?.signedUrl} alt="downloading" className={classes.image} />
                    </Box>
                  );
                })}
              </Box>
            )}
          </Collapsable>
          <Collapsable
            open
            className={cx(
              insurance?.submission?.responses?.length > 0
                ? classes.collapsable
                : classes.collapsableDisabled,
            )}
            iconColor={colors.neutral700}
            iconPosition={IiconPosition.RIGHT}
            iconHidden={insurance?.submission?.responses?.length === 0}
            labelExtend={
              <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                <Icons
                  glyph="email"
                  color={insurance?.submission?.responses?.length > 0 ? colors.primary500 : colors.neutral500}
                />
                <Typography {...typography.body.m.semibold}>Post submission</Typography>
                <Typography {...typography.body.s.medium} color={colors.neutral500}>
                  3842964
                </Typography>
              </Box>
            }
          >
            {postSubmissionEmails?.length > 0 &&
              postSubmissionEmails?.map((response, index) => (
                <Collapsable
                  key={index}
                  open
                  className={classes.collapsableEmail}
                  iconColor={colors.neutral700}
                  iconPosition={IiconPosition.RIGHT}
                  labelExtend={
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'flex-start' }}
                    >
                      <Typography {...typography.body.s.medium} color={colors.neutral900}>
                        {dayjs(response?.date).format('MMMM D, YYYY')}
                      </Typography>
                      <Typography {...typography.body.xs.medium} color={colors.neutral500}>
                        {response?.emailFrom}
                      </Typography>
                    </Box>
                  }
                >
                  <Box sx={{ marginTop: '16px' }}>
                    <Typography {...typography.body.s.medium} color={colors.neutral700}>
                      {response?.body}
                    </Typography>
                  </Box>
                </Collapsable>
              ))}
          </Collapsable>
          <Collapsable
            open
            className={cx(
              insurance?.submission?.currentStatus === InsuranceStates.APPROVED
                ? classes.collapsable
                : classes.collapsableDisabled,
            )}
            iconColor={colors.neutral700}
            iconPosition={IiconPosition.RIGHT}
            iconHidden={insurance?.submission?.currentStatus !== InsuranceStates.APPROVED}
            labelExtend={
              <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                <Icons
                  glyph="email-par"
                  color={
                    insurance?.submission?.currentStatus === InsuranceStates.APPROVED
                      ? colors.primary500
                      : colors.neutral500
                  }
                />
                <Typography {...typography.body.m.semibold}>Par</Typography>
              </Box>
            }
          >
            {insurance?.submission?.currentStatus === InsuranceStates.APPROVED && (
              <Box className={classes.collapsableEmail}>
                <Box>
                  <Typography {...typography.overline.small} color={colors.neutral500}>
                    ID NUMBER
                  </Typography>
                  <Typography {...typography.body.m.semibold} color={colors.neutral900}>
                    {insurance?.submission?.requestId}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '24px', marginBottom: '24px' }}>
                  <Divider />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'flex-start' }}>
                  <Typography {...typography.body.s.medium} color={colors.neutral900}>
                    {dayjs(par?.date).format('MMMM D, YYYY')}
                  </Typography>
                  <Typography {...typography.body.xs.medium} color={colors.neutral500}>
                    {par?.emailFrom}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '16px' }}>
                  <Typography {...typography.body.s.medium} color={colors.neutral700}>
                    {par?.body}
                  </Typography>
                </Box>
              </Box>
            )}
          </Collapsable>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SubmissionStatusDrawer;
