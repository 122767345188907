/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { REPORT_FILTERS } from 'constants/CommonConstants';
import { IReport, ReportFilterRule } from 'redux/modules/reporting/types';
import { getTimeDifference, getLocalDateAsUTC } from 'utils/dayjs';
import { toDateString } from 'utils/CommonUtils';
import {
  getReportAllFiltersSubQuery,
  getReportAllFiltersSubQueryReferralPartner,
  getReportFilterSubQuery,
  getReportInternalFiltersSubQuery,
  REPORT_CONSTANTS,
  REPORT_SECTION_NAMES,
} from '../../ReportDetail.constants';
import { IEvaluationReport } from './Evaluations.types';

const boxDataMap = {
  'Total EVALUTAION’s': 'totalEvaluations',
  'Total Assignments': 'totalAssignments',
  'Last Assigned': 'lastAssigned',
};

export const getBoxData = (evaluationReport: IEvaluationReport, report: IReport) => {
  const boxData = [
    { label: 'Total EVALUTAION’s', value: evaluationReport?.total_evaluations ?? 0 },
    { label: 'Total Assignments', value: evaluationReport?.total_assignments ?? 0 },
    {
      label: 'Last Assigned',
      value: evaluationReport?.aggregations.last_assigned_date.hits.hits[0]
        ? getTimeDifference(
            new Date(evaluationReport.aggregations.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
            getLocalDateAsUTC(),
          )
        : '',
    },
  ];

  return boxData.filter(item => report?.data?.evaluationsSection?.[boxDataMap[item.label]]?.isChecked) ?? [];
};

export const getLast30DaysAssignedCounts = (report: IEvaluationReport) =>
  report
    ? report.aggregations.last_30_days_assigned.counts.buckets.map(bucket => bucket.doc_count).slice(0, 30)
    : [];

export const getLast30DaysCompletedCounts = (report: IEvaluationReport) =>
  report
    ? report.aggregations.last_30_days_completed.counts.buckets.map(bucket => bucket.doc_count).slice(0, 30)
    : [];

export const getMostCompletedEvaluations = (report: IEvaluationReport) =>
  report
    ? report.aggregations.by_most_completed.by_members.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.doc_count,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const getMostMembersEvaluations = (report: IEvaluationReport) =>
  report
    ? report.aggregations.by_most_members.by_members.buckets.map(bucket => ({
        name: bucket.key,
        total: bucket.doc_count,
        lastUpdated: toDateString(bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate),
      }))
    : [];

export const memberColumns: TableGrid.TableColumn[] = [
  {
    id: 'name',
    label: 'Evaluation',
    sortable: false,
  },
  {
    id: 'total',
    label: 'Completion',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

const chartColors = [
  colors.success500,
  colors.orange400,
  colors.neutral500,
  colors.secondary500,
  colors.primary500,
  colors.cyan500,
  colors.indigo500,
  colors.purple500,
  colors.rose500,
  colors.destructive500,
];
export const getChartSeries = (report: IEvaluationReport) => {
  return report
    ? report.aggregations.evaluation_list.buckets.map(bucket => ({
        title: bucket.key,
        subtitle: `Last assigned: ${toDateString(
          bucket.last_assigned_date.hits.hits[0]._source.lastModifiedDate,
        )}`,
        chartData: bucket.by_status.buckets.map((item, i) => ({
          name: item.key,
          color: chartColors[i],
          data: [item.doc_count],
        })),
        donutOptTitle: 'Assignments',
        donutOptTotal: bucket.doc_count,
      }))
    : [];
};

export const getEvaluationReportQuery = (report: IReport) => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    return {
      size: 0,
      query: {
        bool: {
          ...(report?.data?.evaluationsSection?.evaluationIds?.evaluationIds?.length
            ? {
                filter: [
                  {
                    terms: {
                      'evaluation_id.keyword': report.data.evaluationsSection.evaluationIds.evaluationIds,
                    },
                  },
                ],
              }
            : {}),
          must: [
            ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
            ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
            ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
            ...getReportInternalFiltersSubQuery(report, REPORT_SECTION_NAMES.evaluationsSection),
          ]?.filter(obj => Object.keys(obj)?.length > 0),
        },
      },
      aggs: {
        total_members: {
          cardinality: {
            field: 'member_id.keyword',
          },
        },
        total_evaluations: {
          cardinality: {
            field: 'evaluation_id.keyword',
          },
        },
        total_assignments: {
          cardinality: {
            field: 'mongo_id.keyword',
          },
        },
        last_assigned_date: {
          top_hits: {
            _source: 'lastModifiedDate',
            sort: [{ lastModifiedDate: { order: 'desc' } }],
            size: 1,
          },
        },
        last_30_days_assigned: {
          filter: {
            bool: {
              must: [
                {
                  exists: {
                    field: 'evaluation_id',
                  },
                },
                {
                  range: {
                    assignedAt: {
                      gte: 'now-30d/d',
                      lte: 'now',
                    },
                  },
                },
              ],
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'assignedAt',
                calendar_interval: '1d',
              },
            },
          },
        },
        last_30_days_completed: {
          filter: {
            bool: {
              must: [
                {
                  term: {
                    'evaluation_status.keyword': {
                      value: 'COMPLETED',
                    },
                  },
                },
                {
                  range: {
                    lastModifiedDate: {
                      gte: 'now-30d/d',
                      lte: 'now',
                    },
                  },
                },
              ],
            },
          },
          aggs: {
            counts: {
              date_histogram: {
                field: 'lastModifiedDate',
                calendar_interval: '1d',
              },
            },
          },
        },
        by_most_completed: {
          filter: {
            term: {
              'evaluation_status.keyword': 'COMPLETED',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'evaluation_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [{ lastModifiedDate: { order: 'desc' } }],
                    size: 1,
                  },
                },
              },
            },
          },
        },
        by_most_members: {
          filter: {
            exists: {
              field: 'member_id',
            },
          },
          aggs: {
            by_members: {
              terms: {
                field: 'evaluation_name.keyword',
                size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
              },
              aggs: {
                last_assigned_date: {
                  top_hits: {
                    _source: 'lastModifiedDate',
                    sort: [{ lastModifiedDate: { order: 'desc' } }],
                    size: 1,
                  },
                },
              },
            },
          },
        },

        evaluation_list: {
          terms: {
            field: 'evaluation_name.keyword',
            size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
          },
          aggs: {
            by_status: {
              terms: {
                field: 'evaluation_status.keyword',
                size: 10,
              },
            },
            last_assigned_date: {
              top_hits: {
                _source: 'lastModifiedDate',
                sort: [{ assignedAt: { order: 'desc' } }],
                size: 1,
              },
            },
          },
        },
      },
    };
  }

  return {
    size: 0,
    query: {
      bool: {
        ...(report?.data?.evaluationsSection?.evaluationIds?.evaluationIds?.length
          ? {
              filter: [
                {
                  terms: {
                    'evaluation_id.keyword': report.data.evaluationsSection.evaluationIds.evaluationIds,
                  },
                },
              ],
            }
          : {}),
        must: [],
      },
    },
    aggs: {
      total_members: {
        cardinality: {
          field: 'member_id.keyword',
        },
      },
      total_evaluations: {
        cardinality: {
          field: 'evaluation_id.keyword',
        },
      },
      total_assignments: {
        cardinality: {
          field: 'mongo_id.keyword',
        },
      },
      last_assigned_date: {
        top_hits: {
          _source: 'lastModifiedDate',
          sort: [{ lastModifiedDate: { order: 'desc' } }],
          size: 1,
        },
      },
      last_30_days_assigned: {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: 'evaluation_id',
                },
              },
              {
                range: {
                  assignedAt: {
                    gte: 'now-30d/d',
                    lte: 'now',
                  },
                },
              },
            ],
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'assignedAt',
              calendar_interval: '1d',
            },
          },
        },
      },
      last_30_days_completed: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  'evaluation_status.keyword': {
                    value: 'COMPLETED',
                  },
                },
              },
              {
                range: {
                  lastModifiedDate: {
                    gte: 'now-30d/d',
                    lte: 'now',
                  },
                },
              },
            ],
          },
        },
        aggs: {
          counts: {
            date_histogram: {
              field: 'lastModifiedDate',
              calendar_interval: '1d',
            },
          },
        },
      },
      by_most_completed: {
        filter: {
          term: {
            'evaluation_status.keyword': 'COMPLETED',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'evaluation_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [{ lastModifiedDate: { order: 'desc' } }],
                  size: 1,
                },
              },
            },
          },
        },
      },
      by_most_members: {
        filter: {
          exists: {
            field: 'member_id',
          },
        },
        aggs: {
          by_members: {
            terms: {
              field: 'evaluation_name.keyword',
              size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
            },
            aggs: {
              last_assigned_date: {
                top_hits: {
                  _source: 'lastModifiedDate',
                  sort: [{ lastModifiedDate: { order: 'desc' } }],
                  size: 1,
                },
              },
            },
          },
        },
      },

      evaluation_list: {
        terms: {
          field: 'evaluation_name.keyword',
          size: REPORT_CONSTANTS.MAX_RECORDS_IN_QUERY,
        },
        aggs: {
          by_status: {
            terms: {
              field: 'evaluation_status.keyword',
              size: 10,
            },
          },
          last_assigned_date: {
            top_hits: {
              _source: 'lastModifiedDate',
              sort: [{ assignedAt: { order: 'desc' } }],
              size: 1,
            },
          },
        },
      },
    },
  };
};

export const getEvaluationCountQuery = () => ({
  query: {
    bool: {
      must: [
        {
          term: {
            'type.keyword': {
              value: 'EVALUATION',
            },
          },
        },
        {
          match: {
            status: 'ACTIVE',
          },
        },
      ],
    },
  },
});

export const getEvaluationTotalAssignedQuery = () => ({
  size: 0,
  aggs: {
    the_assignedEvals: {
      nested: {
        path: 'assignedEvaluations',
      },
      aggs: {
        last_assigned: {
          top_hits: {
            size: 1,
            _source: ['assignedEvaluations.assignedAt'],
            sort: [
              {
                'assignedEvaluations.assignedAt.keyword': {
                  order: 'desc',
                },
              },
            ],
          },
        },
      },
    },
  },
});
