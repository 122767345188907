import { ChangeEvent, FC, useEffect, useState } from 'react';
import { isUndefined } from 'lodash';
import { Box, Rating } from '@mui/material';
import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { useStyles } from './EvaluationRatingScale.styles';

type Props = {
  selectedSection: any;
  forceRender: () => void;
  onChange?: (value: string) => void;
  getRatingValue?: (e?: any) => number | null;
  hotkeysEnabled?: boolean;
};

const EvaluationRatingScale: FC<Props> = ({
  selectedSection,
  forceRender,
  onChange,
  getRatingValue,
  hotkeysEnabled,
}) => {
  const classes = useStyles();
  const [, setSelectedValue] = useState(0);
  useEffect(() => {
    if (isUndefined(selectedSection.evaluationResponses)) {
      if (selectedSection?.userResponse?.userResponseList.length > 0) {
        if (selectedSection?.ratingScale?.values[0] === 0) {
          selectedSection.evaluationResponses = selectedSection.userResponse.userResponseList;
          const value = parseInt(selectedSection.userResponse.userResponseList[0], 10);
          setSelectedValue(value + 1);
        } else {
          selectedSection.evaluationResponses = selectedSection.userResponse.userResponseList;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isUndefined(selectedSection.evaluationResponses)) {
      if (selectedSection?.userResponse?.userResponseList.length > 0) {
        selectedSection.evaluationResponses = selectedSection.userResponse.userResponseList;
      }
    }
  }, []);

  const onChangeRating = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (selectedSection?.ratingScale?.values[0] === 0) {
      const valueForZeroScale = value - 1;
      selectedSection.evaluationResponses = [valueForZeroScale.toString()];
      setSelectedValue(value);
    } else {
      selectedSection.evaluationResponses = [e.target.value];
      setSelectedValue(value);
    }
    if (onChange) {
      onChange(selectedSection.evaluationResponses[0]);
    }
    forceRender();
  };

  const checkAnswer = (() => {
    if (selectedSection?.evaluationResponses?.length > 0) {
      if (selectedSection?.ratingScale?.values[0] === 0) {
        // selectedSection.evaluationResponses = selectedSection.userResponse.userResponseList;
        let value = parseInt(selectedSection.evaluationResponses, 10);
        value++;
        return [value.toString()];
        // setSelectedValue(value + 1);
      }
      return selectedSection.evaluationResponses;
    }
    if (selectedSection?.userResponse?.userResponseList?.length > 0) {
      if (selectedSection?.ratingScale?.values[0] === 0) {
        selectedSection.evaluationResponses = selectedSection.userResponse.userResponseList;
        let value = parseInt(selectedSection.userResponse.userResponseList[0], 10);
        value++;
        return [value.toString()];
        // setSelectedValue(value + 1);
      }

      return selectedSection.userResponse.userResponseList;
    }
    return 0;

    // if (res.distance === 0) return 'a';
    // if (res.distance === 1 && res.difference > 3) return 'b';
    // if (res.distance === 2 && res.difference > 5 && String(res.key).length > 5) return 'c';
    // return 'd';
  })();
  return (
    <>
      <Box>
        <Rating
          value={hotkeysEnabled ? getRatingValue() : checkAnswer}
          max={selectedSection?.ratingScale?.values?.length}
          defaultValue={0}
          onChange={onChangeRating}
        />
        <Box style={{ marginTop: '16px' }}>
          <Text className={classes.helperText}>{selectedSection?.helperText}</Text>
        </Box>
      </Box>
    </>
  );
};

export { EvaluationRatingScale };
