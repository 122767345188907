import Box from '@mui/material/Box';

import { useState } from 'react';

import { IconButton, iconBtnPosition, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { SignOffModal } from 'pages/provider/member-appointment-notes/components/signoff-modal/SignOffModal';
import { patchAppointment } from 'services/appointment/appointment.service';
import history from 'utils/history';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getAuth } from 'redux/modules/auth/selectors';
import { getUserTimeZone } from 'utils/dayjs';

import { useStyles } from './SessionSignOffV2.styles';
import { PatchAction } from '../../../../redux/modules/appointment/types';

interface Props {
  onBack: () => void;
  signOffStatus: any;
  appointment: IAppointment;
  onStep: (step: number) => void;
  template: string;
  isSupervisor: boolean;
}

const SignOff = ({ onBack, signOffStatus, appointment, onStep, isSupervisor }: Props) => {
  const classes = useStyles();
  const { meta } = useSelector(getAuth);

  const [isRequesting, setIsRequesting] = useState(false);
  const [openSignOffModal, setOpenSignOffModal] = useState(false);

  const onSignOff = async (skip = false) => {
    if (!isRequesting) {
      setIsRequesting(true);
      try {
        // await addNoteAppointment({ objective: name, skip }, { appointmentId: appointment.appointmentId });
        if (isSupervisor && appointment.signOffStatus === 'REVIEW') {
          await patchAppointment(
            {
              action: PatchAction.ADD_SUPERVISOR_NOTES,
              supervisorNotesData: {
                notes: '',
                status: 'APPROVED',
              },
            },
            {
              appointmentId: appointment.appointmentId,
            },
          );
        } else {
          await patchAppointment(
            {
              action: PatchAction.PROVIDER_SIGN_OFF,
              skipProviderSignOff: skip,
            },
            {
              appointmentId: appointment.appointmentId,
            },
          );
        }
        setIsRequesting(false);
        setOpenSignOffModal(false);
        history.push(
          `/provider/${meta?.userId}/day-schedule?date=${dayjs().tz(getUserTimeZone()).format('YYYY-MM-DD')}`,
        );
        // eslint-disable-next-line no-empty
      } catch (err) {}
      setIsRequesting(false);
      setOpenSignOffModal(false);
    }
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentHeader}>
            <Box className={classes.contentHeaderTitle}>Sign off on a note</Box>
          </Box>
          <Box className={classes.content}>
            {Object.values(signOffStatus).map((value: any, index) => (
              <Box
                className={classes.signOffCard}
                key={index}
                onClick={() => {
                  onStep(index);
                }}
              >
                <Box className={classes.signOffContent}>
                  <Box className="icon">
                    <Icons glyph={value.icon} />
                  </Box>
                  <Box>
                    <Box className="title">{value.title}</Box>
                    <Box className="added">
                      {value.icon === 'lab-bottle-outlined'
                        ? `${value.labs} lab request notes, ${value.releases} release of information notes ${value.description}`
                        : `${value.number} ${value.description}`}
                    </Box>
                  </Box>
                </Box>
                <Badge
                  icon="check-circle"
                  style={badgeStyle.RESOLVED}
                  variant={badgeType.ICON}
                  size={28}
                  className={classes.badgeComplete}
                >
                  Completed
                </Badge>
              </Box>
            ))}
          </Box>
          <SignOffModal
            open={openSignOffModal}
            onClose={() => setOpenSignOffModal(false)}
            isLoading={isRequesting}
            onSubmit={onSignOff}
          />
        </Box>
      </Box>
      <Box className={classes.footer}>
        <IconButton
          icon="chevron-left"
          iconPosition={iconBtnPosition.LEFT}
          className={classes.previousBtn}
          onClick={onBack}
          variant={iconBtnType.TEXT}
        >
          Previous
        </IconButton>
        <IconButton
          icon="chevron-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.nextBtn}
          onClick={() => setOpenSignOffModal(true)}
        >
          Sign Off
        </IconButton>
      </Box>
    </>
  );
};

export default SignOff;
