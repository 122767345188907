import { FC, useState } from 'react';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Input, inputType, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';

import Chatbots from 'pages/provider/member-detail/components/Chatbots';

import { useStyles } from '../past-appointments-drawer/PastAppointmentsDrawer.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  items?: any[];
  appointment?: Partial<IAppointment>;
  isNoDrawer?: boolean;
};

const ChatbotsDrawer: FC<Props> = ({ open, onClose, isNoDrawer, items = [], appointment }) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState('');

  const renderContent = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.searchWrap}>
          <Input
            variant={inputType.SEARCH}
            className={classes.searchInput}
            placeholder="Search chatbot"
            size={inputSize.M}
            value={searchKey}
            onChange={setSearchKey}
          />
        </Box>
        <Box className={classes.appointments}>
          <Chatbots showHeader={false} participantId={appointment.participantId} searchKey={searchKey} />
        </Box>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer
      open={open}
      title="Chatbots"
      subTitle={`${items.length} total`}
      onClose={onClose}
      variant={drawerType.FORM}
      className={classes.drawer}
      footer={<div />}
    >
      {renderContent()}
    </Drawer>
  );
};

export { ChatbotsDrawer };
