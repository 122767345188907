import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    position: 'relative',
    backgroundColor: colors.white,
    borderRadius: 8,
    width: '100%',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  exportBtn: {
    fontSize: 14,
    fontWeight: 700,
    '& svg': {
      width: 16,
      height: 17,
      marginLeft: 12,
    },
  },
  title: {
    color: colors.neutral900,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 8,
      '& path': {
        fill: colors.neutral400,
      },
    },
  },
  name: {
    color: colors.neutral900,
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    display: 'block',
  },
  fullname: {
    color: colors.neutral600,
  },
  badge: {
    borderRadius: 20,
    height: 18,
    padding: '2px 8px',
    fontSize: 12,
    width: 'fit-content',
    border: 'none',
    marginTop: 2,
    '&.Provider': {
      backgroundColor: colors.indigo50,
      color: colors.indigo600,
    },
    '&.Coach': {
      backgroundColor: colors.warning50,
      color: colors.warning600,
    },
  },
  statusBadge: {
    padding: '4px 12px',
    height: 'auto',
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: 14,
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul li': {
      padding: '10px 16px',
    },
  },
  table: {
    width: '100%',
    boxShadow: 'none',
  },
  loader: {
    height: 'calc(100vh - 158px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
