import { FC, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'clsx';
import { Collapse, Box } from '@mui/material';
import { useFormik } from 'formik';

import { fontWeight, Heading, headingLevel, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { iconBtnStyle, iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { selectProfileElementList } from 'redux/modules/conversation/selectors';
import { PROFILE_ELEMENT_DEF_TYPE, REPORT_FILTERS } from 'constants/CommonConstants';
import AutomationEventItem from 'pages/admin/automations/duplicate-automation/automation-event-item';
import { selectStatePayers, stateSelector } from 'redux/modules/state/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { stateActionCreators } from 'redux/modules/state';
import { profileActionCreators } from 'redux/modules/profile';
import { getAllPayers } from 'services/state/state.service';
import { conversationActionCreators } from 'redux/modules/conversation';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { selectLoeReport } from 'redux/modules/schedule/selectors';

import { IReportFilterFormProps } from './AutomationFilterForm.types';
import {
  automationRuleSelectNumeric,
  automationRuleSelectOption,
  automationRuleSelectText,
  automationRuleSelectDate,
} from './AutomationFilterForm.constants';
import { useStyles } from './AutomationFilterForm.styles';

const defaultValues = {
  type: '',
  states: [],
  memberState: { title: '', value: '' },
  insuranceCarriers: [],
  levelOfEngagements: [],
  profileElement: '',
  automationRule: '',
  automationValue: '',
  providers: [],
};

const isUserDefinedValuesSelected = (type: string) =>
  PROFILE_ELEMENT_DEF_TYPE[type] === PROFILE_ELEMENT_DEF_TYPE.USER_DEFINED_VALUES ||
  PROFILE_ELEMENT_DEF_TYPE[type] === PROFILE_ELEMENT_DEF_TYPE.YES_NO ||
  PROFILE_ELEMENT_DEF_TYPE[type] === PROFILE_ELEMENT_DEF_TYPE.DATE ||
  PROFILE_ELEMENT_DEF_TYPE[type] === PROFILE_ELEMENT_DEF_TYPE.DATE_TIME;

const AutomationFilterForm: FC<IReportFilterFormProps> = ({
  open = false,
  filter,
  filterLogicOptions,
  onChange,
  onDelete,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandedForm, setExpandedForm] = useState(open || false);
  const [isLoading, setIsLoading] = useState(false);
  const profileElementList = useSelector(selectProfileElementList);
  const [updatedFilter, setUpdatedFilter] = useState(filter);
  const { states } = useSelector(stateSelector);
  const { providers } = useSelector(getProfile);
  const { levelOfEngagementStats } = useSelector(selectLoeReport);
  const payers = useSelector(selectStatePayers);
  const profileElementOptions = useMemo(
    () => profileElementList.map(element => element.profileElementInfo),
    [profileElementList],
  );
  const payersOptions = useMemo(
    () =>
      payers?.map(payer => {
        return { title: payer.name, value: payer._id };
      }) ?? [],
    [payers],
  );

  const onEditClick = () => {
    setExpandedForm(true);
  };

  const onDeleteClick = () => {
    setExpandedForm(false);
    onDelete();
  };
  const onSubmit = payload => {
    setExpandedForm(false);
    const value = isUserDefinedValuesSelected(payload.profileElement?.type)
      ? payload.automationValue.value
      : payload.automationValue;
    onChange({
      ...payload,
      profileElement: payload.profileElement?.key || '',
      automationValue: value,
      // memberState: payload?.memberState?.value || '',
    });
    const foundMemberState = states?.find(state => state?.state?._id === payload?.memberState);
    getAllPayers()
      .then(allPayers => {
        const pyrs = allPayers?.data?.data?.length ? allPayers?.data?.data : payers;
        setUpdatedFilter({
          type: payload.type,
          states: payload.states?.[0]?.title
            ? payload.states
            : payload.states?.map(state => ({ title: state, value: state })),
          automationRule: payload.automationRule,
          automationValue: payload.automationValue,
          openForm: payload.openForm,
          memberState: foundMemberState
            ? {
                ...foundMemberState,
                title: foundMemberState?.state?.name,
                value: foundMemberState?.state?.name,
              }
            : payload.memberState,
          insuranceCarriers: payload.insuranceCarriers?.[0]?.title
            ? payload.insuranceCarriers
            : payload.insuranceCarriers?.map(insuranceCarrier => {
                const foundPayer = pyrs?.find(payer => payer._id === insuranceCarrier);
                return { ...foundPayer, value: foundPayer._id, title: foundPayer.name };
              }),
          levelOfEngagements: payload.levelOfEngagements?.[0]?.title
            ? payload.levelOfEngagements
            : payload.levelOfEngagements?.map(level => {
                const foundLevel = levelOfEngagementStats?.levelOfEngagementStatsCount?.find(
                  levelOfEngagement =>
                    levelOfEngagement.levelOfEngagementStatus === level.value ||
                    levelOfEngagement.levelOfEngagementStatus === level,
                );
                return {
                  ...foundLevel,
                  value: foundLevel.levelOfEngagementStatus,
                  title: foundLevel.levelOfEngagementStatus,
                };
              }),
          // profileElement:
          //   profileElementOptions?.find(element => element.key === filter.profileElement) ||
          //   filter.profileElement,
          profileElement: payload.profileElement,
          providers: payload.providers?.[0]?.title
            ? payload.providers
            : payload.providers?.map(provider => {
                const foundProvider = providers?.find(providerItem => providerItem.providerId === provider);
                return { ...foundProvider, value: foundProvider.providerId, title: foundProvider.fullName };
              }),
        });
      })
      .catch(err => {
        console.log({ err });
      });
  };
  const updateFilter = (flter = null) => {
    setIsLoading(true);
    const updatedFltr = flter === null ? updatedFilter : flter;
    const foundMemberState = states?.find(state => state?.state?.name === updatedFltr?.memberState);
    getAllPayers()
      .then(allPayers => {
        const pyrs = allPayers?.data?.data?.length ? allPayers?.data?.data : payers;
        setUpdatedFilter({
          type: updatedFltr.type,
          states: updatedFltr.states?.[0]?.title
            ? updatedFltr.states
            : updatedFltr.states?.map(state => ({ title: state, value: state })),
          automationRule: updatedFltr.automationRule,
          automationValue: updatedFltr.automationValue,
          openForm: updatedFltr.openForm,
          memberState: foundMemberState
            ? {
                ...foundMemberState,
                title: foundMemberState?.state?.name,
                value: foundMemberState?.state?.name,
              }
            : updatedFltr.memberState,
          insuranceCarriers: updatedFltr.insuranceCarriers?.[0]?.title
            ? updatedFltr.insuranceCarriers
            : updatedFltr.insuranceCarriers?.map(insuranceCarrier => {
                const foundPayer = pyrs?.find(
                  payer => payer._id === insuranceCarrier || payer._id === insuranceCarrier.value,
                );
                return { ...foundPayer, value: foundPayer?._id, title: foundPayer?.name };
              }),
          levelOfEngagements: updatedFltr.levelOfEngagements?.[0]?.title
            ? updatedFltr.levelOfEngagements
            : updatedFltr.levelOfEngagements?.map(level => {
                const foundLevel = levelOfEngagementStats?.levelOfEngagementStatsCount?.find(
                  levelOfEngagement => levelOfEngagement.levelOfEngagementStatus === level,
                );
                return {
                  ...foundLevel,
                  value: foundLevel.levelOfEngagementStatus,
                  title: foundLevel.levelOfEngagementStatus,
                };
              }),
          // profileElement:
          //   profileElementOptions?.find(element => element.key === filter.profileElement) ||
          //   filter.profileElement,
          profileElement: updatedFltr.profileElement,
          providers: updatedFltr.providers?.[0]?.title
            ? updatedFltr.providers
            : updatedFltr.providers?.map(provider => {
                const foundProvider = providers?.find(providerItem => providerItem.providerId === provider);
                return { ...foundProvider, value: foundProvider.providerId, title: foundProvider.fullName };
              }),
        });
        setIsLoading(false);
      })
      .catch(err => {
        console.log({ err });
        setIsLoading(false);
      });
  };
  useEffect(() => {
    updateFilter();
  }, []);
  useEffect(() => {
    updateFilter(filter);
  }, [filter]);

  const { errors, values, handleChange, setTouched, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: { ...defaultValues },
    // validationSchema: AutomationFilterSchema,
    validate: () => {
      const errs = {} as any;
      if (!values.type) {
        // setTouched('type');
        errs.type = 'Type is required';
      }
      switch (values.type) {
        case REPORT_FILTERS.PROFILE_ELEMENT:
          if (!values.profileElement) errs.profileElement = 'Profile element is required';
          if (!values.automationRule) errs.automationRule = 'Rule is required';
          if (!values.automationValue) errs.automationValue = 'Value is required';
          break;
        case REPORT_FILTERS.STATE:
          if (!values.states || !values.states?.length) errs.states = 'State is required';
          break;
        case REPORT_FILTERS.LEVEL_OF_ENGAGEMENT:
          if (!values.levelOfEngagements || !values.levelOfEngagements?.length)
            errs.levelOfEngagements = 'Level of engagement is required';
          break;
        case REPORT_FILTERS.INSURANCE:
          if (!values.memberState) errs.memberState = 'Member state is required';
          if (!values.insuranceCarriers || !values.insuranceCarriers?.length)
            errs.insuranceCarriers = 'Insurance carrier is required';
          break;
        case REPORT_FILTERS.PROVIDER:
          if (!values.providers || !values.providers?.length) errs.providers = 'Provider is required';
          break;
        default:
          break;
      }
      // setErrors(errors);
      return errs;
    },
    onSubmit,
  });

  const automationRuleSelect = useMemo(() => {
    switch (PROFILE_ELEMENT_DEF_TYPE[values.profileElement?.type]) {
      case PROFILE_ELEMENT_DEF_TYPE.TEXT_INPUT:
        return automationRuleSelectText;
      case PROFILE_ELEMENT_DEF_TYPE.NUMERIC:
      case PROFILE_ELEMENT_DEF_TYPE.SCORE_BASED:
      case PROFILE_ELEMENT_DEF_TYPE.RATING_SCALE:
        return automationRuleSelectNumeric;
      case PROFILE_ELEMENT_DEF_TYPE.YES_NO:
      case PROFILE_ELEMENT_DEF_TYPE.USER_DEFINED_VALUES:
        return automationRuleSelectOption;
      case PROFILE_ELEMENT_DEF_TYPE.DATE:
      case PROFILE_ELEMENT_DEF_TYPE.DATE_TIME:
        return automationRuleSelectDate;

      default:
        return [];
    }
  }, [values.profileElement]);

  const profilElementValues = useMemo(
    () => values.profileElement?.values?.map(value => ({ title: value, value })) ?? [],
    [values.profileElement],
  );

  useEffect(() => {
    if (updatedFilter) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(updatedFilter).forEach(async key => {
        if (key === 'profileElement') {
          await setFieldValue(
            key,
            profileElementOptions.find(option => option.key && option.key === updatedFilter.profileElement) ||
              null,
          );
        } else if (key === 'automationValue' && !isUserDefinedValuesSelected(values.profileElement?.type)) {
          await setFieldValue(key, updatedFilter[key]);
        } else {
          await setFieldValue(key, updatedFilter[key]);
        }
      });
    }
  }, [updatedFilter]);

  useEffect(() => {
    if (profilElementValues && isUserDefinedValuesSelected(values.profileElement?.type)) {
      void setFieldValue(
        'automationValue',
        profilElementValues.find(option => option.value === updatedFilter.automationValue) || null,
      );
    }
  }, [updatedFilter, profilElementValues]);

  useEffect(() => {
    setExpandedForm(open || false);
  }, [open]);

  useEffect(() => {
    dispatch(scheduleActionCreators.fetchLevelOfEngagements());
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 10000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
    dispatch(stateActionCreators.fetchStates());
    dispatch(profileActionCreators.fetchDemographicMetadata());
    const queryParams = {
      searchQuery: '',
      type: '',
      method: '',
      pageNumber: 0,
      pageSize: 10000,
    };
    dispatch(conversationActionCreators.fetchProfileElements(queryParams));
  }, []);

  // const setTouched = (name: string) => async () => {
  //   await rest.setTouched({ ...touched, [name]: true });
  // };

  const renderLabel = (str: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  const onChangeProfile = async (_: any, newValue: any) => {
    if (newValue) {
      await setFieldValue('profileElement', newValue);
      await setFieldValue('automationRule', '');
      await setFieldValue('automationValue', isUserDefinedValuesSelected(newValue.type) ? null : '');
    }
  };

  const onChangeValues = async (_: any, newValue: any) => {
    if (newValue) {
      await setFieldValue('automationValue', newValue);
    }
  };

  // remove `if` in string
  const removeIfInText = (str = '') => {
    return str.toLocaleLowerCase().replace(/if /g, '');
  };

  const renderSelect = (label, keyName, opts, emptyText) => (
    <Box className={classes.section}>
      {renderLabel(label)}
      <Select
        value={values[keyName]}
        name={keyName}
        variant={selectType.SECONDARY}
        options={opts}
        emptyText={emptyText}
        onBlur={setTouched}
        displayEmpty
        onChange={handleChange}
      />
      <TextError errorMsg={touched[keyName] ? errors[keyName]?.toString() : null} />
    </Box>
  );

  const renderValue = () => {
    if (isUserDefinedValuesSelected(values.profileElement?.type)) {
      return (
        <Box className={classes.section}>
          {renderLabel('Select Value')}
          <Input
            fullWidth
            name="automationValue"
            type="text"
            value={values.automationValue || null}
            options={profilElementValues}
            getOptionLabel={option => `${option.title}`}
            placeholder="Select value"
            variant={inputType.AUTOCOMPLETE}
            size={inputSize.M}
            onBlur={setTouched}
            onChange={onChangeValues}
          />
          <TextError errorMsg={touched.automationValue ? errors.automationValue?.toString() : null} />
        </Box>
      );
    }

    const type = PROFILE_ELEMENT_DEF_TYPE[values.profileElement?.type];

    return (
      <Box className={classes.section}>
        {renderLabel('Enter value')}
        <Input
          value={values.automationValue || ''}
          name="automationValue"
          type={type === PROFILE_ELEMENT_DEF_TYPE.TEXT_INPUT ? 'text' : 'number'}
          placeholder="Enter value"
          onChange={handleChange}
          size={inputSize.M}
          onBlur={setTouched}
          fullWidth
        />
        <TextError errorMsg={touched.automationValue ? errors.automationValue?.toString() : null} />
      </Box>
    );
  };

  const renderFilterFields = (type: REPORT_FILTERS) => {
    switch (type) {
      case REPORT_FILTERS.PROFILE_ELEMENT:
        return (
          <Box className={classes.section}>
            {renderLabel('Select profile element')}
            <Input
              fullWidth
              name="profileElement"
              value={values.profileElement || null}
              options={profileElementOptions}
              getOptionLabel={option => `${option.key}`}
              renderOption={(props: any, option) => (
                <Box key={option.id} {...props}>
                  {option.key}
                </Box>
              )}
              size={inputSize.M}
              placeholder="Select profile element"
              variant={inputType.AUTOCOMPLETE}
              onBlur={setTouched}
              onChange={onChangeProfile}
            />
            <TextError errorMsg={touched.profileElement ? errors.profileElement?.toString() : null} />
          </Box>
        );
      case REPORT_FILTERS.STATE:
        return (
          <Box className={classes.section}>
            {renderLabel('Select states')}
            <Input
              value={values.states}
              name="states"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              className={classes.valuesInput}
              onBlur={setTouched}
              options={states
                ?.map(state => ({ value: state?.state?.name, title: state?.state?.name }))
                ?.sort((a, b) => (a.title > b.title ? 1 : -1))}
              onChange={(event, newValue) => {
                void setFieldValue('states', newValue);
              }}
              variant={inputType.TAGS}
              size={inputSize.M}
              fullWidth
            />
            <TextError errorMsg={touched.states ? errors.states?.toString() : null} />
          </Box>
        );
      case REPORT_FILTERS.LEVEL_OF_ENGAGEMENT:
        return (
          <Box className={classes.section}>
            {renderLabel('Select level of engagements')}
            <Input
              value={values.levelOfEngagements}
              name="levelOfEngagements"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              className={classes.valuesInput}
              onBlur={setTouched}
              options={levelOfEngagementStats?.levelOfEngagementStatsCount
                .map(level => ({
                  value: level?.levelOfEngagementStatus,
                  title: level?.levelOfEngagementStatus,
                }))
                ?.sort((a, b) => (a.title > b.title ? 1 : -1))}
              onChange={(event, newValue) => {
                void setFieldValue('levelOfEngagements', newValue);
              }}
              variant={inputType.TAGS}
              size={inputSize.M}
              fullWidth
            />
            <TextError errorMsg={touched.levelOfEngagements ? errors.levelOfEngagements?.toString() : null} />
          </Box>
        );
      case REPORT_FILTERS.INSURANCE:
        return (
          <Box className={classes.section}>
            {renderLabel('Select member state')}
            <Input
              value={values.memberState}
              name="memberState"
              getOptionLabel={option => option?.title || ''}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              className={classes.valuesInput}
              onBlur={setTouched}
              options={states
                ?.map(state => ({ value: state?.state?.name, title: state?.state?.name }))
                ?.sort((a, b) => (a.title > b.title ? 1 : -1))}
              onChange={(event, newValue) => {
                void setFieldValue('insuranceCarriers', []);
                const foundState = states.find(state => state.state.name === newValue.value);
                if (foundState) {
                  dispatch(stateActionCreators.fetchPayers(foundState._id));
                }
                void setFieldValue('memberState', newValue);
              }}
              variant={inputType.AUTOCOMPLETE}
              size={inputSize.M}
              fullWidth
              placeholder="Select member state"
            />
            <TextError errorMsg={touched.memberState ? errors.memberState?.toString() : null} />
            {renderLabel('Select State Carriers')}
            <Input
              value={values.insuranceCarriers}
              name="insuranceCarriers"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              className={classes.valuesInput}
              onBlur={setTouched}
              options={payersOptions?.sort((a, b) => (a.title > b.title ? 1 : -1))}
              onChange={(event, newValue) => {
                void setFieldValue('insuranceCarriers', newValue);
              }}
              variant={inputType.TAGS}
              size={inputSize.M}
              fullWidth
            />
            <TextError errorMsg={touched.insuranceCarriers ? errors.insuranceCarriers?.toString() : null} />
          </Box>
        );
      case REPORT_FILTERS.PROVIDER:
        return (
          <Box className={classes.section}>
            {renderLabel('Select providers')}
            <Input
              value={values.providers}
              name="providers"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              className={classes.valuesInput}
              onBlur={setTouched}
              options={providers
                .map(provider => ({
                  value: provider?.providerId,
                  title: provider?.fullName,
                }))
                ?.sort((a, b) => (a.title > b.title ? 1 : -1))}
              onChange={(event, newValue) => {
                void setFieldValue('providers', newValue);
              }}
              variant={inputType.TAGS}
              size={inputSize.M}
              fullWidth
            />
            <TextError errorMsg={touched.providers ? errors.providers?.toString() : null} />
          </Box>
        );
      default:
        return <></>;
    }
  };
  const renderStartValue = (filtr: any) => {
    switch (filtr?.type) {
      case REPORT_FILTERS.PROFILE_ELEMENT:
        if (profileElementList.find(element => element.profileElementInfo.id === filtr?.profileElement)) {
          return (
            profileElementList.find(element => element.profileElementInfo.id === filtr?.profileElement)
              ?.profileElementInfo.key || filtr.profileElement?.key
          );
        }
        return filtr?.profileElement?.key ?? filtr?.profileElement;
      // case REPORT_FILTERS.STATE:
      //   return '';
      // case REPORT_FILTERS.LEVEL_OF_ENGAGEMENT:
      //   return '';
      // case REPORT_FILTERS.INSURANCE:
      //   return '';
      // case REPORT_FILTERS.PROVIDER:
      //   return '';
      default:
        return '';
    }
  };
  const renderEndValue = (filtr: any) => {
    switch (filtr?.type) {
      case REPORT_FILTERS.INSURANCE:
        return filtr.insuranceCarriers?.map(carrier => carrier.title)?.toString() || '';
      default:
        return filtr?.automationRule || '';
    }
  };
  const renderEndLabel = (filtr: any) => {
    switch (filtr?.type) {
      case REPORT_FILTERS.PROFILE_ELEMENT:
        return filter?.automationValue || '';
      case REPORT_FILTERS.STATE:
        return filtr.states?.map(stt => stt.title)?.toString();
      case REPORT_FILTERS.LEVEL_OF_ENGAGEMENT:
        return filtr.levelOfEngagements?.map(lvl => lvl.name ?? lvl.title)?.toString();
      case REPORT_FILTERS.INSURANCE:
        return (
          states.find(
            state =>
              state?.state?.name === filter.memberState || state?.state?.name === filter.memberState?.value,
          )?.state?.name || ''
        );
      case REPORT_FILTERS.PROVIDER:
        return filtr.providers?.map(prvdr => prvdr.fullName ?? prvdr.title)?.toString();
      default:
        return '';
    }
  };
  return (
    <Box>
      <Collapse in={!expandedForm}>
        {isLoading ? (
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        ) : (
          <AutomationEventItem
            event={{
              startLabel: removeIfInText(updatedFilter?.type),
              startValue: renderStartValue(updatedFilter),
              endLabel: renderEndLabel(updatedFilter),
              endValue: renderEndValue(updatedFilter),
              isDeleteable: true,
              ...updatedFilter,
            }}
            onEdit={onEditClick}
            onDelete={() => onDelete()}
          />
        )}
      </Collapse>
      <Collapse in={expandedForm}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {renderSelect(
              'Set filter logic',
              'type',
              filterLogicOptions.filter(option => option?.label),
              'Select logic',
            )}
            {renderFilterFields(values.type)}
            {values.type === REPORT_FILTERS.PROFILE_ELEMENT &&
              renderSelect('Select rule', 'automationRule', automationRuleSelect, 'Select rule')}
            {values.type === REPORT_FILTERS.PROFILE_ELEMENT && renderValue()}
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.PRIMARY} className={classes.btnFooter} onClick={handleSubmit}>
              Save
            </Button>
            <IconButton
              className={cx(classes.btnFooter, classes.removeBtn)}
              variant={iconBtnType.TEXT}
              style={iconBtnStyle.SECONDARY}
              onClick={onDeleteClick}
            >
              Delete
            </IconButton>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

export { AutomationFilterForm };
