// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
    },
  },
  boxTop: {
    borderBottom: `1px solid ${colors.neutral100}`,
    padding: 24,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  text14: {
    fontSize: 14,
    lineHeight: '20px',
  },
  selectWrap: {
    padding: 24,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  tabPanel: {
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  select: {
    width: '100%',
  },
  label: {
    display: 'block',
    marginBottom: 8,
  },
  duration: {
    color: colors.neutral600,
    display: 'block',
    marginTop: 4,
  },
  info: {
    color: colors.neutral700,
  },
  fullname: {
    color: colors.neutral500,
    display: 'block',
  },
  boxInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  btn: {
    color: colors.white,
  },
  tabs: {
    borderBottom: `1px solid ${colors.neutral100}`,
    '& button': {
      flex: 1,
      textTransform: 'none',
      paddingTop: 24,
      paddingBottom: 20,
    },
  },
  day: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#0D0F11',
    marginBottom: 10,
    display: 'block',
  },
  timeSlots: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 24,
  },
  timeSlotItems: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
  },
  timeSlot: {
    fontSize: 14,
    lineHeight: '20px',
    color: colors.neutral700,
    cursor: 'pointer',
    width: '49%',
    borderRadius: 100,
    border: `1px solid ${colors.neutral200}`,
    textAlign: 'center',
    padding: 4,
    '&:hover, &.timeSlotActive': {
      backgroundColor: colors.primary500,
      color: colors.white,
      borderColor: colors.primary500,
    },
  },
  btnCancel: {
    marginRight: '1rem',
    color: colors.white,
    backgroundColor: colors.destructive500,
    '&:hover': {
      backgroundColor: colors.destructive400,
    },
  },
});
