import clsx from 'clsx';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Box, Stack } from '@mui/material';
import RecentAssignmentsTable from 'pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import { useStyles } from './RenderTable.style';

const RenderTable = props => {
  const { title, data, columns, onClickViewAll, renderMenuAction, className, onRowClick } = props;
  const classes = useStyles();

  const newColoumns = columns?.map(col => {
    if (col.id === 'level') {
      return {
        ...col,
        renderCell: (val: string) => {
          const key = val.toUpperCase();
          return (
            <Badge style={badgeStyle[key]} variant={badgeType.FILLED} className={classes.badge}>
              {val}
            </Badge>
          );
        },
      };
    }
    return col;
  });
  const { length } = data;
  return (
    <Box className={clsx(classes.tableWrap, className)} flex={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
          {title}
        </Text>
        {onClickViewAll && (
          <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
            View top ({length})
          </IconButton>
        )}
      </Stack>
      <RecentAssignmentsTable
        className={classes.tableBordered}
        isShowAll={false}
        tableColumns={newColoumns}
        data={data}
        onRowClick={onRowClick}
        renderMenuAction={renderMenuAction}
      />
    </Box>
  );
};

export { RenderTable };
