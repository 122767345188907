import cx from 'clsx';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { colors } from '@confidant-health/lib/colors';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Button, btnSize } from '@confidant-health/lib/ui/atoms/button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import history from 'utils/history';
import { useSelector } from 'react-redux';
import { selectEvaluationContext } from 'redux/modules/conversation/selectors';
// import { EvaluationHelper } from 'utils/EvaluationHelper';
// import { showSnackbar } from '../../../../redux/modules/snackbar';
import { IEvaluationItem } from '../evaluations-v2/Evaluations.types';
import { useStyles } from './SessionSignOffV2.styles';
import { EVALUATION_STATUS_V2, evaluationStatusStyleV2 } from '../evaluations-v2/Evaluations.constants';
import { evaluationStatusStyle } from './SessionSignOffV2.constant';
// import { updateAppointmentEvaluationStatus } from '../../../../services/conversation/conversation.service';

interface Props {
  onClose?: () => void;
  onCloseSignOff?: () => void;
  evaluation?: IEvaluationItem;
  isSession?: boolean;
  evaluationId?: string;
  appointmentId?: string;
  completeEvaluation?: () => void;
  UpdateOnCompletion?: () => void;
}

// const list = new EvaluationHelper();

const EvaluationReviewHeader = ({
  evaluation,
  onClose,
  onCloseSignOff,
  // isSession = false,
  // evaluationId = '',
  // appointmentId = '',
  completeEvaluation,
  UpdateOnCompletion,
}: Props) => {
  const classes = useStyles();
  const evaluationContext = useSelector(selectEvaluationContext);
  // const dispatch = useDispatch();
  // const [isDisabled, setIsDisabled] = useState(true);
  const [cbAnswered, setCbAnswered] = useState(0);

  const onBackToAppointments = () => {
    if (UpdateOnCompletion) UpdateOnCompletion();
    if (onClose) {
      onClose();
    } else {
      onCloseSignOff();
      history.back();
    }
  };

  // const updateEvaluationStatus = async () => {
  //   try {
  //     const payload = {
  //       evaluationUpdatedStatus: 'COMPLETED',
  //       evaluationId,
  //       remarks: 'Evaluation completed',
  //     };
  //     const { data } = await updateAppointmentEvaluationStatus(payload, {
  //       appointmentId,
  //     });
  //     if (data?.message === 'Evaluation Status updated successfully') {
  //       void onBackToAppointments();
  //     }
  //   } catch (e) {
  //     console.warn(e?.data?.errors[0]?.endUserMessage);
  //     dispatch(
  //       showSnackbar({
  //         snackType: 'error',
  //         snackMessage: e?.data?.errors[0]?.endUserMessage || 'Something went wrong',
  //       }),
  //     );
  //   }
  // };

  useEffect(() => {
    if (evaluationContext && evaluationContext?.evaluationId === evaluation?.id) {
      const allQuestions = evaluationContext.cbResponseList.flatMap(question => {
        if (question.cbType === 'dct') {
          const cbList = [];
          question.dctContentBlockList.forEach(cb => {
            if (cb.cbType !== 'dct') {
              cbList.push({ ...cb, dctId: question.dctId, responderType: question.responderType });
            }
          });
          return cbList;
        }
        return question;
      });
      const questionsAnswered = allQuestions.filter(
        item => item.userResponse !== null && item.userResponse?.userResponseList.length > 0,
      );
      setCbAnswered(questionsAnswered.length);
    }
  }, [evaluationContext]);

  return (
    <Box className={cx(classes.reviewHeader, classes.reviewHeaderBottom)}>
      <Box className={cx(classes.flexContainer, classes.gap24)}>
        <IconButton className={classes.backBtnHeader} onClick={onBackToAppointments}>
          <ChevronLeftIcon />
        </IconButton>
        <Box className={cx(classes.flexContainer, classes.flexColumn, classes.gap4)}>
          <Box className={cx(classes.flexContainer, classes.gap12)}>
            <Box className={classes.font22}>{evaluation?.name}</Box>
            <Badge
              variant={badgeType.OUTLINED}
              style={
                evaluationContext?.aiCompletionStatus === 'IN_PROGRESS'
                  ? evaluationStatusStyle.AI_IN_PROGRESS
                  : evaluationContext?.status === 'NEEDS_REVIEW'
                  ? badgeStyle.MEDIUM
                  : evaluationStatusStyleV2[evaluationContext?.status]
              }
              size={28}
              className={classes.badge}
            >
              <Box display="flex" justifyContent="center" alignItems="center" flex={1} gap="4px">
                {evaluationContext?.aiCompletionStatus === 'IN_PROGRESS'
                  ? 'Waiting for AI'
                  : evaluationContext?.status === 'NEEDS_REVIEW'
                  ? 'For review'
                  : EVALUATION_STATUS_V2[evaluationContext?.status]}
              </Box>
            </Badge>
          </Box>
          <Box
            className={classes.font16}
            display="flex"
            justifyContent="flex-start"
            width="100%"
            color={colors.neutral600}
          >
            {cbAnswered}/{evaluation?.evaluationStats?.totalCb} completed {/* todo : verify these stats */}
          </Box>
        </Box>
      </Box>
      {evaluationContext?.status !== 'COMPLETED' && evaluationContext?.aiCompletionStatus !== 'IN_PROGRESS' && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button size={btnSize.SMALL} className={classes.activeBtn} onClick={onBackToAppointments}>
            Finish later
          </Button>
          <Button size={btnSize.SMALL} className={classes.btn} onClick={() => completeEvaluation()}>
            Complete evaluation
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EvaluationReviewHeader;
