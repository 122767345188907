import React, { FC } from 'react';
import { Box } from '@mui/material';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import cx from 'clsx';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Icons } from '@confidant-health/lib/icons';
import { useStyles } from './AlfieValidationMultiSelect.styles';

type Props = {
  selectedSection: any; // IEvaluationSectionItem;
  provider: any;
  trainingAI?: boolean;
  amendingNote: boolean;
  wrongAlfieChoices?: string[];
  addCorrection: (correction: string) => void;
  selectChoiceByReviewer: (choice: string, checked: boolean) => void;
  reviewerResponses: string[];
};
const AlfieValidationMultiSelect: FC<Props> = ({
  selectedSection,
  provider,
  trainingAI,
  amendingNote,
  wrongAlfieChoices,
  addCorrection,
  selectChoiceByReviewer,
  reviewerResponses,
}) => {
  const classes = useStyles();

  const hasStringInChoices = (choices, item) => {
    if (item?.choice) {
      return choices?.some(
        choice =>
          (choice?.trim && choice.trim().toLowerCase()) ===
          (item?.choice?.trim && item?.choice?.trim().toLowerCase()),
      );
    }
    return choices?.some(
      choice => (choice?.trim && choice.trim().toLowerCase()) === (item?.trim && item?.trim().toLowerCase()),
    );
  };

  const checkedByProvider = item => {
    return hasStringInChoices(selectedSection.evaluationResponses, item);
  };

  const checkedByAlfie = item => {
    return hasStringInChoices(selectedSection.alfieResponses, item);
  };

  const checkedByReviewer = item => {
    return hasStringInChoices(reviewerResponses, item);
  };

  const checked = item => checkedByProvider(item) || checkedByAlfie(item);

  const markedAsWrong = item => {
    return (
      wrongAlfieChoices?.find(choice =>
        choice.toLowerCase().includes(item?.choice?.toLowerCase() ?? item.toLowerCase()),
      ) || false
    );
  };

  const choices = selectedSection?.choices ?? [];
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {selectedSection.cbType === 'text-input' ? (
        <>
          <Box
            className={cx(classes.amendSelectBox, {
              [classes.selectedBorder]: checkedByReviewer(selectedSection.evaluationResponses?.[0]),
            })}
            onClick={() => {
              if (amendingNote) {
                selectChoiceByReviewer(
                  selectedSection.evaluationResponses?.[0],
                  !checkedByReviewer(selectedSection.evaluationResponses?.[0]),
                );
              }
            }}
          >
            <Box className={classes.avatar}>
              <Box display="flex" alignItems="center" gap={1.5}>
                <Avatar
                  src={provider?.profilePicture}
                  name={provider?.name}
                  size={40}
                  variant={avatarType.CIRCLE}
                />
                <Text weight={fontWeight.BOLD} className={classes.textInputChoiceHeading}>
                  Provider Response:
                </Text>
              </Box>
              {checkedByReviewer(selectedSection.evaluationResponses?.[0]) && (
                <Icons className={classes.check} glyph="check-circle" />
              )}
            </Box>
            <Text weight={fontWeight.REGULAR} className={classes.textInputChoice}>
              {selectedSection.evaluationResponses?.[0]}
            </Text>
          </Box>
          <Box
            className={cx(classes.amendSelectBox, {
              [classes.selectedBorder]: checkedByReviewer(selectedSection.alfieResponses?.[0]),
            })}
            onClick={() => {
              if (amendingNote) {
                selectChoiceByReviewer(
                  selectedSection.alfieResponses?.[0],
                  !checkedByReviewer(selectedSection.alfieResponses?.[0]),
                );
              }
            }}
          >
            <Box className={classes.avatar}>
              <Box display="flex" alignItems="center" gap={1.5}>
                <Icons className={classes.logo} glyph="story-icon-logo" />
                <Text weight={fontWeight.BOLD} className={classes.textInputChoiceHeading}>
                  Alfie Proposed Response:
                </Text>
              </Box>
              {checkedByReviewer(selectedSection.alfieResponses?.[0]) && (
                <Icons className={classes.check} glyph="check-circle" />
              )}
            </Box>
            <Text weight={fontWeight.REGULAR} className={classes.textInputChoice}>
              {selectedSection.alfieResponses?.[0]}
            </Text>
          </Box>
          {/* <Text weight={fontWeight.BOLD} className={classes.textInputChoiceHeading}>
            Provider Response:
          </Text>
          <Text weight={fontWeight.REGULAR} className={classes.textInputChoice}>
            {selectedSection.evaluationResponses?.[0]}
          </Text>
          <FormControlLabel
            control={
              <Checkbox
                className={cx({
                  [classes.noMatch]:
                    checked(selectedSection.evaluationResponses?.[0]) &&
                    selectedSection.matchStatus === 'no-match',
                  [classes.alfieOnly]:
                    checkedByAlfie(selectedSection.evaluationResponses?.[0]) &&
                    !checkedByProvider(selectedSection.evaluationResponses?.[0]),
                  [classes.resolved]:
                    checked(selectedSection.evaluationResponses?.[0]) &&
                    selectedSection.matchStatus === 'resolved',
                })}
              />
            }
            checked={checkedByReviewer(selectedSection.evaluationResponses?.[0])}
            onChange={e => {
              if (amendingNote) {
                selectChoiceByReviewer(selectedSection.evaluationResponses?.[0], e.target.checked);
              }
            }}
            label="Use this response"
            className={classes.formLabel}
          />
          <Box className={classes.selectedByBox}>
            <Avatar
              src={provider?.profilePicture}
              name={provider?.name}
              size={40}
              variant={avatarType.CIRCLE}
            />
            <Text weight={fontWeight.REGULAR} className={classes.selectedByText}>
              Selected by {provider?.name}
            </Text>
          </Box>
          <Box className={classes.selectedByBox}>
            <Icons className={classes.logo} glyph="story-icon-logo" />
            <Box display="flex" flexDirection="column" gap="5px">
              <Text weight={fontWeight.REGULAR} className={classes.selectedByText}>
                Alfie Disagree
              </Text>
              {trainingAI && !markedAsWrong(selectedSection.evaluationResponses?.[0]) && (
                <Text
                  weight={fontWeight.REGULAR}
                  className={classes.markAsWrongBtn}
                  onClick={() => {
                    addCorrection(selectedSection.evaluationResponses?.[0]);
                  }}
                >
                  Mark as Wrong Alfie Selection
                </Text>
              )}
              {trainingAI && markedAsWrong(selectedSection.evaluationResponses?.[0]) && (
                <Text weight={fontWeight.REGULAR} className={classes.markedAsWrong}>
                  Marked as wrong
                </Text>
              )}
            </Box>
          </Box>
          <Text weight={fontWeight.BOLD} className={clsx(classes.textInputChoiceHeading, classes.mt20)}>
            Alfie Proposed Response:
          </Text>
          <Text weight={fontWeight.REGULAR} className={classes.textInputChoice}>
            {selectedSection.alfieResponses?.[0]}
          </Text>
          <FormControlLabel
            control={
              <Checkbox
                className={cx({
                  [classes.noMatch]:
                    checked(selectedSection.alfieResponses?.[0]) &&
                    selectedSection.matchStatus === 'no-match',
                  [classes.alfieOnly]:
                    checkedByAlfie(selectedSection.alfieResponses?.[0]) &&
                    !checkedByProvider(selectedSection.alfieResponses?.[0]),
                  [classes.resolved]:
                    checked(selectedSection.alfieResponses?.[0]) &&
                    selectedSection.matchStatus === 'resolved',
                })}
              />
            }
            checked={checkedByReviewer(selectedSection.alfieResponses?.[0])}
            onChange={e => {
              if (amendingNote) {
                selectChoiceByReviewer(selectedSection.alfieResponses?.[0], e.target.checked);
              }
            }}
            label="Use this response"
            className={classes.formLabel}
          /> */}
          {/* <Box className={classes.selectedByBox}>
            <Icons className={classes.logo} glyph="story-icon-logo" />
            <Box display="flex" flexDirection="column" gap="5px">
              <Text weight={fontWeight.REGULAR} className={classes.selectedByText}>
                Proposed by Alfie
              </Text>
              {trainingAI && !markedAsWrong(selectedSection.alfieResponses?.[0]) && (
                <Text
                  weight={fontWeight.REGULAR}
                  className={classes.markAsWrongBtn}
                  onClick={() => {
                    addCorrection(selectedSection.alfieResponses?.[0]);
                  }}
                >
                  Mark as Wrong Alfie Selection
                </Text>
              )}
              {trainingAI && markedAsWrong(selectedSection.alfieResponses?.[0]) && (
                <Text weight={fontWeight.REGULAR} className={classes.markedAsWrong}>
                  Marked as wrong
                </Text>
              )}
            </Box>
          </Box> */}
        </>
      ) : (
        choices?.map(item => (
          <React.Fragment key={item?.choice}>
            <FormControlLabel
              control={
                <Checkbox
                  className={cx({
                    [classes.fullMatch]: checkedByProvider(item) && checkedByAlfie(item),
                    [classes.partialMatch]: checked(item) && selectedSection.matchStatus === 'partial-match',
                    [classes.noMatch]: checked(item) && selectedSection.matchStatus === 'no-match',
                    [classes.alfieOnly]: checkedByAlfie(item) && !checkedByProvider(item),
                    [classes.resolved]: checked(item) && selectedSection.matchStatus === 'resolved',
                    [classes.notChecked]: !checked(item),
                  })}
                />
              }
              checked={checkedByReviewer(item)}
              onChange={e => {
                if (amendingNote) {
                  selectChoiceByReviewer(item.choice, e.target.checked);
                }
              }}
              label={item?.choice}
              className={classes.formLabel}
            />
            {checkedByProvider(item) && (
              <Box className={classes.selectedByBox}>
                <Avatar
                  src={provider?.profilePicture}
                  name={provider?.name}
                  size={40}
                  variant={avatarType.CIRCLE}
                />
                <Text weight={fontWeight.REGULAR} className={classes.selectedByText}>
                  Selected by {provider?.name}
                </Text>
              </Box>
            )}
            {checkedByAlfie(item) && checkedByProvider(item) && (
              <Box className={classes.selectedByBox}>
                <Icons className={classes.logo} glyph="story-icon-logo" />
                <Text weight={fontWeight.REGULAR} className={classes.selectedByText}>
                  Validated by Alfie
                </Text>
              </Box>
            )}
            {!checkedByAlfie(item) && checkedByProvider(item) && (
              <Box className={classes.selectedByBox}>
                <Icons className={classes.logo} glyph="story-icon-logo" />
                <Box display="flex" flexDirection="column" gap="5px">
                  <Text weight={fontWeight.REGULAR} className={classes.selectedByText}>
                    Alfie Disagree
                  </Text>
                  {trainingAI && !markedAsWrong(item) && (
                    <Text
                      weight={fontWeight.REGULAR}
                      className={classes.markAsWrongBtn}
                      onClick={() => {
                        addCorrection(item?.choice);
                      }}
                    >
                      Mark as Wrong Alfie Selection
                    </Text>
                  )}
                  {trainingAI && markedAsWrong(item) && (
                    <Text weight={fontWeight.REGULAR} className={classes.markedAsWrong}>
                      Marked as wrong
                    </Text>
                  )}
                </Box>
              </Box>
            )}
            {checkedByAlfie(item) && !checkedByProvider(item) && (
              <Box className={classes.selectedByBox}>
                <Icons className={classes.logo} glyph="story-icon-logo" />
                <Box display="flex" flexDirection="column" gap="5px">
                  <Text weight={fontWeight.REGULAR} className={classes.selectedByText}>
                    Proposed by Alfie
                  </Text>
                  {trainingAI && !markedAsWrong(item) && (
                    <Text
                      weight={fontWeight.REGULAR}
                      className={classes.markAsWrongBtn}
                      onClick={() => {
                        addCorrection(item?.choice);
                      }}
                    >
                      Mark as Wrong Alfie Selection
                    </Text>
                  )}
                  {trainingAI && markedAsWrong(item) && (
                    <Text weight={fontWeight.REGULAR} className={classes.markedAsWrong}>
                      Marked as wrong
                    </Text>
                  )}
                </Box>
              </Box>
            )}
          </React.Fragment>
        ))
      )}
    </Box>
  );
};

export { AlfieValidationMultiSelect };
