export const timeSelects = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
  { label: '1 hour 30 minutes', value: 90 },
  { label: '2 hours', value: 120 },
  { label: '2 hours 30 minutes', value: 150 },
  { label: '3 hours', value: 180 },
  { label: '3 hours 30 minutes', value: 210 },
  { label: '4 hours', value: 240 },
  { label: '4 hours 30 minutes', value: 270 },
];
export const timeSelectsBuffer = [
  { label: 'No buffer', value: 0 },
  { label: '5 minutes', value: 5 },
  { label: '10 minutes', value: 10 },
  { label: '15 minutes', value: 15 },
  { label: '20 minutes', value: 20 },
  { label: '25 minutes', value: 25 },
  { label: '30 minutes', value: 30 },
];
