/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { useSelector } from 'react-redux';
import { DomainPriorities } from 'redux/modules/profile/types';
import { getAuth } from '../../../../../../redux/modules/auth/selectors';
import { getProfile } from '../../../../../../redux/modules/profile/selectors';

import { IDiagnosCardProps } from './DiagnosCard.types';
import { DiagnosBottomCard } from './DiagnosBottomCard';
import { useStyles } from './DiagnosCard.styles';
import { ProfileInfoRole } from '../../../../../../components/v2/ProfileInfo';

const DiagnosCard: React.FC<IDiagnosCardProps> = ({
  item,
  className,
  onClick,
  isOnlyView,
  onClickFullReport,
}) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);

  const onClickTitle = () => {
    onClick && onClick(item, false);
  };

  const onClickEdit = () => {
    onClick && onClick(item, true);
  };

  return (
    <Box className={clsx(classes.card, className)}>
      <Box className={classes.rowCenter} sx={{ p: 3, minHeight: 96 }}>
        <Box className={classes.topLeft}>
          <Box className={classes.topLeftHead}>
            <Text className={classes.title} onClick={onClickTitle}>
              {item.name}
            </Text>
            <Badge
              variant={badgeType.FILLED}
              className={clsx(classes.badge, {
                [classes.badgeMisreported]: item.priority.name === DomainPriorities.MISREPORTED,
              })}
              style={badgeStyle[item.priority.name]}
            >
              {item.priority.name}
            </Badge>
          </Box>
          <Box>
            <Text className={classes.title2}>{item.description}</Text>
          </Box>
        </Box>
        <Box className={classes.topRight}>
          {/* <IconButton
            icon="clock-rotate"
            variant={iconBtnType.TEXT}
            className={classes.btn}
            onClick={onClickTitle}
          >
            See All Reports ({(item.history ?? []).length + 1})
          </IconButton> */}
          {!isOnlyView &&
            (profile?.designation === ProfileInfoRole.NURSE_PRACTITIONER ||
              profile?.designation === ProfileInfoRole.PHYSICIAN ||
              profile?.designation === ProfileInfoRole.THERAPIST ||
              isAdmin) && (
              <>
                {/* <IconButton
                  icon="report-outlined"
                  variant={iconBtnType.TEXT}
                  className={classes.btn}
                  onClick={onClickTitle}
                >
                  Full report
                </IconButton> */}
                <IconButton variant={iconBtnType.SECONDARY} className={classes.btn} onClick={onClickEdit}>
                  Update
                </IconButton>
              </>
            )}
        </Box>
      </Box>
      <DiagnosBottomCard item={item} onClickFullReport={onClickFullReport} />
    </Box>
  );
};

export { DiagnosCard };
