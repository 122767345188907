import { FC } from 'react';
import { Box } from '@mui/material';

// types
import { IConversationAssignments } from 'pages/admin/conversations/Conversations.types';

// components
import ReportChartCard from '../report-chart-card-conversation';
import AssignmentsCard from '../assignments-chart-card';
import ConversationTableCard from '../conversation-table-card';

type Props = {
  conversation: IConversationAssignments;
  isEvaluation: boolean;
  version: number;
  evaluationId?: string;
};

const AssignmentDetail: FC<Props> = ({ conversation, isEvaluation, version, evaluationId }) => {
  // const series = [conversation.]

  return (
    <Box display="flex" flexDirection="column" gap={4} sx={{ marginTop: 7 }}>
      <ReportChartCard
        completed={conversation?.completed}
        inProgress={conversation?.inProgress}
        notStarted={conversation?.notStarted}
      />
      <AssignmentsCard
        conversation={conversation}
        isEvaluation={isEvaluation}
        version={version}
        evaluationId={evaluationId}
      />
      <ConversationTableCard conversation={conversation} />
    </Box>
  );
};

export { AssignmentDetail };
