import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// hooks
import { useQuery } from '@confidant-health/lib/hooks';

// components
import { Box } from '@mui/material';
import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { stateSelector } from 'redux/modules/state/selectors';
import { BaseLayout } from 'layouts/base';

import { rightSideNavList } from './StateDetail.constants';
import { GeneralInformation, Payers, Members, Providers } from './components';

// styles
import { useStyles } from './StateDetail.styles';

const StateDetail: FC = () => {
  const classes = useStyles();
  const query = useQuery();
  const { selectedState } = useSelector(stateSelector);

  const [selectedScreen, setSelectedScreen] = useState<string>('');

  const renderSelectedScreen = () => {
    switch (selectedScreen) {
      case 'general-information':
        return <GeneralInformation />;
      case 'payers':
        return <Payers />;
      case 'providers':
        return <Providers />;
      case 'members':
        return <Members />;
      default:
        return <GeneralInformation />;
    }
  };

  useEffect(() => {
    const getQuery = query.get('name');
    setSelectedScreen(getQuery || 'general-information');
  }, [query]);

  return (
    <BaseLayout
      rightSideNavList={rightSideNavList}
      rightSidebarHeadCustom={
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          State details
        </Heading>
      }
      rightSideBarWidth="320px"
    >
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Breadcrumbs links={[{ href: '/admin/states', text: 'States' }, { text: selectedState.name }]} />
        </Box>

        {renderSelectedScreen()}
      </Box>
    </BaseLayout>
  );
};

export { StateDetail };
