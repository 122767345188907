import { call, put, takeLatest } from 'redux-saga/effects';

import { IAction } from 'redux/store/types';
import * as PriorityService from 'services/priority/priority.service';

import { titleCase } from 'utils/CommonUtils';
import { INewTypePayload } from 'pages/admin/priority-types/components/add-edit-type/AddEditPriorityType.types';
import { INewGroupPayload } from 'pages/admin/priority-groupings/add-edit-grouping/AddEditPriorityGrouping.types';
import { INewElementPayload } from 'pages/admin/priority-elements/add-edit-elements/AddEditPriorityElement.types';

import {
  FETCH_PRIORITY_DOMAIN_TYPES,
  FETCH_PRIORITY_DOMAIN_TYPES_FAILED,
  FETCH_PRIORITY_DOMAIN_TYPES_SUCCESSFUL,
  FETCH_PRIORITY_DOMAIN_ELEMENTS,
  FETCH_PRIORITY_DOMAIN_ELEMENTS_FAILED,
  FETCH_PRIORITY_DOMAIN_ELEMENTS_SUCCESSFUL,
  FETCH_PRIORITY_DOMAIN_GROUPS,
  FETCH_PRIORITY_DOMAIN_GROUPS_SUCCESSFUL,
  FETCH_PRIORITY_DOMAIN_GROUPS_FAILED,
  CREATE_DOMAIN_TYPE,
  CREATE_DOMAIN_TYPE_SUCCESSFUL,
  CREATE_DOMAIN_TYPE_FAILED,
  DELETE_DOMAIN_TYPE,
  DELETE_DOMAIN_TYPE_SUCCESSFUL,
  DELETE_DOMAIN_TYPE_FAILED,
  DELETE_DOMAIN_ELEMENT_SUCCESSFUL,
  DELETE_DOMAIN_ELEMENT_FAILED,
  DELETE_DOMAIN_GROUP_SUCCESSFUL,
  DELETE_DOMAIN_GROUP_FAILED,
  DELETE_DOMAIN_ELEMENT,
  DELETE_DOMAIN_GROUP,
  UPDATE_DOMAIN_TYPE,
  UPDATE_DOMAIN_TYPE_SUCCESSFUL,
  UPDATE_DOMAIN_TYPE_FAILED,
  FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_SUCCESSFUL,
  FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_FAILED,
  FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS,
  CREATE_DOMAIN_GROUP,
  CREATE_DOMAIN_GROUP_SUCCESSFUL,
  CREATE_DOMAIN_GROUP_FAILED,
  UPDATE_DOMAIN_GROUP,
  UPDATE_DOMAIN_GROUP_FAILED,
  UPDATE_DOMAIN_GROUP_SUCCESSFUL,
  FETCH_PRIORITY_LOOKUP_SUCCESSFUL,
  FETCH_PRIORITY_LOOKUP_FAILED,
  FETCH_PRIORITY_LOOKUP,
  CREATE_DOMAIN_ELEMENT_SUCCESSFUL,
  CREATE_DOMAIN_ELEMENT_FAILED,
  CREATE_DOMAIN_ELEMENT,
  UPDATE_DOMAIN_ELEMENT_SUCCESSFUL,
  UUPDATE_DOMAIN_ELEMENT_FAILED,
  UPDATE_DOMAIN_ELEMENT,
  FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID,
  FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_SUCCESSFUL,
  FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_FAILED,
} from './actions';
import { showSnackbar } from '../snackbar/actions';

function* handleFetchPriorityDomainTypes(action: IAction) {
  const { searchQuery, pageNumber, pageSize, orderBy, sortBy } = action.payload
    ? action.payload
    : { searchQuery: '', pageNumber: 1, pageSize: 10000, orderBy: '', sortBy: [] };

  try {
    const response = yield call(PriorityService.getPriorityDomainTypes, {
      searchQuery,
      pageNumber: pageNumber - 1,
      pageSize,
      orderBy,
      sortBy,
    });
    if (response.status === 200) {
      const { data } = response;
      const typesList = data.records.map(item => {
        const { importanceLevels } = item.metaData.metaDataSpec;
        const importanceLevelsArray = [];
        Object.keys(importanceLevels).forEach(key => {
          if (importanceLevels[key] === true) {
            importanceLevelsArray.push(titleCase(key));
          }
        });
        const importanceLevelsString =
          // eslint-disable-next-line no-nested-ternary
          importanceLevelsArray.length === 0
            ? 'Not applicable'
            : Object.keys(importanceLevels).length === importanceLevelsArray.length
            ? 'All importance levels'
            : importanceLevelsArray.join(', ');
        return {
          type: item.type,
          Id: item.Id,
          name: item.name,
          importanceLevels: importanceLevelsString,
          requireNotes: item.metaData?.metaDataSpec?.requireNotes,
          requireRxInfo: item.metaData?.metaDataSpec?.requireRxInfo,
          requireRelatedToInfo: item.metaData?.metaDataSpec?.requireRelatedToInfo,
          requireInterferenceWithLifeInfo: item.metaData?.metaDataSpec?.requireInterferenceWithLifeInfo,
          relatedGroups: item.Id,
          relatedElements: item.children?.length,
          actions: item,
        };
      });
      yield put({
        type: FETCH_PRIORITY_DOMAIN_TYPES_SUCCESSFUL,
        payload: {
          currentPage: pageNumber,
          totalPages: data.totalPages,
          totalRecords: data.totalRecords,
          domainTypes: typesList,
        },
      });
    }
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PRIORITY_DOMAIN_TYPES_FAILED,
      payload: { message },
    });
  }
}

function* handleFetchPriorityDomainElements(action: IAction) {
  const { searchQuery, pageNumber, pageSize, orderBy, sortBy, typeIds } = action.payload
    ? action.payload
    : { searchQuery: '', pageNumber: 1, pageSize: 10000, orderBy: '', sortBy: [], typeIds: '' };

  try {
    const response = yield call(PriorityService.getPriorityDomainElements, {
      searchQuery,
      pageNumber: pageNumber - 1,
      pageSize,
      orderBy,
      sortBy,
      typeIds,
    });
    if (response.status === 200) {
      const { data } = response;
      const elementsList = data.records.map(item => {
        const importanceMapping = item.metaData?.metaDataValues?.importanceLevelsValues;
        const rxDrugsInfo = item.metaData?.metaDataValues?.rxDrugInfo;
        const relatedToMapping = item.metaData?.metaDataValues?.relatedToMapping;
        const interferenceMapping = item.metaData?.metaDataValues?.relatedToInterferenceWithLife;
        return {
          type: item.parent,
          Id: item.Id,
          name: item.name,
          importanceMapping,
          rxDrugsInfo,
          relatedToMapping,
          interferenceMapping,
          actions: item,
        };
      });
      yield put({
        type: FETCH_PRIORITY_DOMAIN_ELEMENTS_SUCCESSFUL,
        payload: {
          currentPage: pageNumber,
          totalPages: data.totalPages,
          totalRecords: data.totalRecords,
          elementsTypes: elementsList,
        },
      });
    }
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PRIORITY_DOMAIN_ELEMENTS_FAILED,
      payload: { message },
    });
  }
}

function* fetchDomainElementsByTypeId(action: IAction) {
  try {
    const { data } = yield call(PriorityService.getDomainElementsByTypeId, action.payload);
    const elementsList = data.elements.map(item => {
      const importanceMapping = item.metaData?.metaDataValues?.importanceLevelsValues;
      const rxDrugsInfo = item.metaData?.metaDataValues?.rxDrugInfo;
      const relatedToMapping = item.metaData?.metaDataValues?.relatedToMapping;
      const interferenceMapping = item.metaData?.metaDataValues?.relatedToInterferenceWithLife;
      return {
        type: item.parent,
        Id: item.Id,
        name: item.name,
        importanceMapping,
        rxDrugsInfo,
        relatedToMapping,
        interferenceMapping,
        actions: item,
      };
    });
    yield put({
      type: FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_SUCCESSFUL,
      payload: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 1,
        elementsTypes: elementsList,
      },
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID_FAILED,
      payload: { message },
    });
  }
}

function* handleFetchPriorityDomainProfileElements(action: IAction) {
  const { searchQuery, pageNumber, pageSize, orderBy, sortBy } = action.payload
    ? action.payload
    : { searchQuery: '', pageNumber: 1, pageSize: 10000, orderBy: '', sortBy: [] };

  try {
    const response = yield call(PriorityService.getPriorityDomainProfileElements, {
      searchQuery,
      pageNumber: pageNumber - 1,
      pageSize,
      orderBy,
      sortBy,
    });
    if (response.status === 200) {
      const { data } = response;
      yield put({
        type: FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_SUCCESSFUL,
        payload: {
          currentPage: pageNumber,
          totalPages: data.totalPages,
          totalRecords: data.totalRecords,
          elementsTypes:
            data.profileElementList.filter(
              profileElement =>
                profileElement?.profileElementInfo.values &&
                profileElement?.profileElementInfo.values.length > 0,
            ) || [],
        },
      });
    }
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS_FAILED,
      payload: { message },
    });
  }
}

function* handleFetchPriorityDomainGroups(action: IAction) {
  const { searchQuery, pageNumber, pageSize, orderBy, sortBy, typeIds } = action.payload
    ? action.payload
    : { searchQuery: '', pageNumber: 1, pageSize: 10000, orderBy: '', sortBy: [], typeIds: '' };

  try {
    const response = yield call(PriorityService.getPriorityDomainGroups, {
      searchQuery,
      pageNumber: pageNumber - 1,
      pageSize,
      orderBy,
      sortBy,
      typeIds,
    });
    if (response.status === 200) {
      const { data } = response;
      const { domainGroups } = data;
      yield put({
        type: FETCH_PRIORITY_DOMAIN_GROUPS_SUCCESSFUL,
        payload: {
          currentPage: pageNumber,
          totalPages: data.totalPages,
          totalRecords: data.totalRecords,
          domainGroups: domainGroups.map(item => {
            return {
              type: item.type?.name,
              typeId: item.type?.Id,
              Id: item.Id,
              name: item.name,
              relatedElements: item.relatedElements?.length || 0,
              actions: item,
            };
          }),
        },
      });
    }
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PRIORITY_DOMAIN_GROUPS_FAILED,
      payload: { message },
    });
  }
}

function* createDomainType(action: {
  type: string;
  payload: {
    data: INewElementPayload;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.createNewDomainType, data);
    yield put({ type: CREATE_DOMAIN_TYPE_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Your type has created',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: CREATE_DOMAIN_TYPE_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* updateDomainType(action: {
  type: string;
  payload: {
    data: INewTypePayload;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.updateDomainType, data);
    yield put({ type: UPDATE_DOMAIN_TYPE_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Your type has updated',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: UPDATE_DOMAIN_TYPE_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* createDomainGroup(action: {
  type: string;
  payload: {
    data: INewGroupPayload;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.createNewDomainGroup, data);
    yield put({ type: CREATE_DOMAIN_GROUP_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Your group has created',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: CREATE_DOMAIN_GROUP_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* updateDomainGroup(action: {
  type: string;
  payload: {
    data: INewGroupPayload;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.updateDomainGroup, data);
    yield put({ type: UPDATE_DOMAIN_GROUP_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Your group has updated',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: UPDATE_DOMAIN_GROUP_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* deleteDomainType(action: {
  type: string;
  payload: {
    data: string;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.deleteDomainType, data);
    yield put({ type: DELETE_DOMAIN_TYPE_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Type has deleted',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: DELETE_DOMAIN_TYPE_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* deleteDomainElement(action: {
  type: string;
  payload: {
    data: string;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.deleteDomainElement, data);
    yield put({ type: DELETE_DOMAIN_ELEMENT_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Element has deleted',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: DELETE_DOMAIN_ELEMENT_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* deleteDomainGroup(action: {
  type: string;
  payload: {
    data: string;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.deleteDomainGroup, data);
    yield put({ type: DELETE_DOMAIN_GROUP_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Group has deleted',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: DELETE_DOMAIN_GROUP_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* fetchLookupData() {
  try {
    const { data } = yield call(PriorityService.getDomainLookups);

    yield put({
      type: FETCH_PRIORITY_LOOKUP_SUCCESSFUL,
      payload: data.lookupMap,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PRIORITY_LOOKUP_FAILED,
      payload: { message },
    });
  }
}

function* createDomainElement(action: {
  type: string;
  payload: {
    data: INewElementPayload;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.createNewElementType, data);
    yield put({ type: CREATE_DOMAIN_ELEMENT_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Your element has created',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: CREATE_DOMAIN_ELEMENT_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* updateDomainElement(action: {
  type: string;
  payload: {
    data: INewElementPayload;
    callback: (isSucceed: boolean) => void;
  };
}) {
  const { data, callback } = action.payload;
  try {
    yield call(PriorityService.updateDomainElement, data);
    yield put({ type: UPDATE_DOMAIN_ELEMENT_SUCCESSFUL });
    yield callback(true);
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Your element has updated',
      }),
    );
  } catch (e) {
    const message = e.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: UUPDATE_DOMAIN_ELEMENT_FAILED,
      payload: { message },
    });
    yield callback(false);
  }
}

function* prioritySaga(): IterableIterator<any> {
  yield takeLatest(FETCH_PRIORITY_DOMAIN_TYPES, handleFetchPriorityDomainTypes);
  yield takeLatest(FETCH_PRIORITY_DOMAIN_ELEMENTS, handleFetchPriorityDomainElements);
  yield takeLatest(FETCH_PRIORITY_DOMAIN_GROUPS, handleFetchPriorityDomainGroups);
  yield takeLatest(CREATE_DOMAIN_TYPE, createDomainType);
  yield takeLatest(DELETE_DOMAIN_TYPE, deleteDomainType);
  yield takeLatest(DELETE_DOMAIN_ELEMENT, deleteDomainElement);
  yield takeLatest(DELETE_DOMAIN_GROUP, deleteDomainGroup);
  yield takeLatest(UPDATE_DOMAIN_TYPE, updateDomainType);
  yield takeLatest(FETCH_PRIORITY_DOMAIN_PROFILE_ELEMENTS, handleFetchPriorityDomainProfileElements);
  yield takeLatest(CREATE_DOMAIN_GROUP, createDomainGroup);
  yield takeLatest(UPDATE_DOMAIN_GROUP, updateDomainGroup);
  yield takeLatest(FETCH_PRIORITY_LOOKUP, fetchLookupData);
  yield takeLatest(CREATE_DOMAIN_ELEMENT, createDomainElement);
  yield takeLatest(UPDATE_DOMAIN_ELEMENT, updateDomainElement);
  yield takeLatest(FETCH_DOMAIN_ELEMENTS_BY_TYPE_ID, fetchDomainElementsByTypeId);
}

export default prioritySaga;
