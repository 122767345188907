import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  idCard: {
    width: 180,
    height: 113,
    objectFit: 'cover',
    borderRadius: 8,
  },
  lottieSize: {
    width: 80,
    height: 80,
  },
});
