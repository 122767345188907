import { FC } from 'react';
import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Heading } from '@confidant-health/lib/ui/atoms/typography';

import { ColumnChartOpts, getChartOptions } from './ColumnChart.config';
import { useStyles } from './ColumnChart.styles';

type Props = {
  title: string;
  className?: string;
  chart: ColumnChartOpts;
};

const ColumnChart: FC<Props> = ({ title, className, chart }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.card, className)}>
      <Box className={clsx(classes.head, 'chart-head')}>
        <Heading className={classes.title}>{title}</Heading>
        {/* <Box className={classes.headRight}>
          <Menu
            icon="chevron-down"
            items={times}
            itemsWrapperClassName={classes.menuLabel}
            className={classes.menu}
          >
            <Text className={classes.menuLabel} level={textLevel.S}>
              {selectedTime.label}
            </Text>
          </Menu>
          <Menu
            icon="chevron-down"
            items={statuses}
            itemsWrapperClassName={classes.menuLabel}
            className={classes.menu}
          >
            <Text className={classes.menuLabel} level={textLevel.S}>
              {selectedStatus.label}
            </Text>
          </Menu>
        </Box> */}
      </Box>
      <Box className={classes.chart}>
        <ApexChart options={getChartOptions(chart)} series={chart.series} type="bar" height={330} />
      </Box>
    </Box>
  );
};

export { ColumnChart };
