import { AnyObject } from 'yup/lib/types';
import { isNull } from './utils';

class SendBirdAction2 {
  previousMessageQuery: any;

  sb: SendBird.SendBirdInstance;

  /**
   * Channel
   */
  getChannel = (
    channelUrl: string,
    isOpenChannel = true,

    // TODO implement a promise type??
    // TODO implement then()
  ): Promise<AnyObject> => {
    return new Promise((resolve, reject) => {
      if (isOpenChannel) {
        SendBirdAction2.getInstance()
          .sb.OpenChannel.getChannel(channelUrl, (openChannel, error) => {
            error ? reject(error) : resolve(openChannel);
          })
          .then()
          .catch(err => console.log('error fetching channel', err));
      } else {
        SendBirdAction2.getInstance()
          .sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
            error ? reject(error) : resolve(groupChannel);
          })
          .then()
          .catch(err => console.log('error fetching channel', err));
      }
    });
  };

  // /**
  //  * Message
  //  */
  getMessageList = (
    channel: { createPreviousMessageListQuery: () => any },
    isInit: false,

    // TODO implement a promise type??
  ): Promise<AnyObject> => {
    if (isInit || isNull(this.previousMessageQuery)) {
      this.previousMessageQuery = channel.createPreviousMessageListQuery();
    }
    return new Promise((resolve, reject) => {
      if (this.previousMessageQuery.hasMore && !this.previousMessageQuery.isLoading) {
        this.previousMessageQuery.load(50, true, (messageList, error) => {
          error ? reject(error) : resolve(messageList);
        });
      } else {
        resolve([]);
      }
    });
  };

  // TODO tried this type  SendBird.OpenChannel and it doesn't have markAsRead, setting any for now and disabling eslint for this
  // follow this for correct syntax https://eslint.org/docs/latest/rules/class-methods-use-this
  // eslint-disable-next-line class-methods-use-this
  markAsRead(channel: { markAsRead: () => void }): void {
    channel.markAsRead();
  }

  static myTest(): void {
    console.log('aaa');
  }

  static getInstance(): SendBirdAction2 {
    return new SendBirdAction2();
  }
}

export { SendBirdAction2 };
