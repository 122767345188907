import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';

import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Input } from '@confidant-health/lib/ui/atoms/input';

import { useStyles } from './SelectUnit.styles';

type Props = {
  value: string;
  disabled?: boolean;
  disabledUnit?: boolean;
  unit: string;
  options?: {
    label: string;
    value: string;
  }[];
  onChange: (payload: { amount: number; unit: string }) => void;
};

const SelectUnit: FC<Props> = ({ value, options = [], disabled, disabledUnit, unit, onChange }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState(value);
  const [inputUnit, setInputUnit] = React.useState(unit);

  const onChangeInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value);
    onChange({
      amount: +evt.target.value,
      unit: inputUnit,
    });
  };

  const onChangeUnit = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setInputUnit(evt.target.value);
    onChange({
      amount: +inputValue,
      unit: evt.target.value,
    });
  };

  useEffect(() => {
    setInputUnit(unit);
    setInputValue(value);
  }, [unit, value]);

  return (
    <Box className={classes.wrap}>
      <Input
        value={inputValue}
        onChange={onChangeInput}
        type="number"
        fullWidth
        min={1}
        disabled={disabled}
        className={classes.input}
      />
      <Select
        options={options}
        value={inputUnit}
        disabled={disabledUnit}
        onChange={onChangeUnit}
        variant={selectType.SECONDARY}
        className={classes.select}
      />
    </Box>
  );
};

export { SelectUnit };
