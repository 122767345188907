import { FC } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { tz } from 'moment-timezone';
import clsx from 'clsx';
import { getAuth } from 'redux/modules/auth/selectors';
import { useSelector } from 'react-redux';
import { Text, fontWeight } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Icons, IGlyphs } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  AppointmentStatusText,
  AppointmentStatusBagde,
} from '@confidant-health/lib/constants/CommonConstants';
import { getTimeZoneText } from 'constants/CommonConstants';

import { useStyles } from './AppointmentsDrawer.styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSelectAppointment: (item: IAppointment) => void;
  appointments: IAppointment[];
}

const AppointmentsDrawer: FC<IProps> = ({ open, onClose, appointments, onSelectAppointment }) => {
  const classes = useStyles();
  const { isAdmin } = useSelector(getAuth);

  const onClickAppointment = (item: IAppointment) => () => onSelectAppointment(item);

  const renderInfo = (icon: IGlyphs['glyph'], value) => (
    <Box className={classes.boxInfo}>
      <Icons color={colors.neutral600} glyph={icon} />
      <Text weight={fontWeight.SEMI_BOLD} className={clsx(classes.text14, classes.info)}>
        {value}
      </Text>
    </Box>
  );

  const renderAppointment = (appointment: IAppointment) => (
    <Box className={classes.appointment}>
      <Box display="flex" justifyContent="space-between" sx={{ marginBottom: 4 }}>
        <Box>
          <Text weight={fontWeight.BOLD} className={classes.title}>
            {appointment.serviceName}
          </Text>
          <Text weight={fontWeight.MEDIUM} className={clsx(classes.text14, classes.duration)}>
            {appointment.serviceDuration} minutes session
          </Text>
        </Box>
        <Badge
          variant={badgeType.FILLED}
          style={AppointmentStatusBagde[appointment.status]}
          className={classes.badge}
        >
          {AppointmentStatusText[appointment.status] || 'Scheduled'}
        </Badge>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ProfileInfo
          type={appointment.providerRole ? 'provider' : 'member'}
          photo={appointment.participantImage}
          nickName={appointment.participantName}
          fullName={
            appointment.providerName ||
            `${appointment.patientFirstName || ''} ${appointment.patientLastName || ''}`
          }
          role={appointment.providerRole}
          memberId={appointment?.providerRole ? appointment?.provider?.userAccountId : appointment?.patientId}
          isProvider={!isAdmin}
        />
        <Box display="flex" flexDirection="column" gap={0.5}>
          {renderInfo('event-outlined', dayjs(appointment.startTime).format('MMMM DD, YYYY'))}
          {renderInfo(
            'clock',
            `${dayjs(appointment.startTime).format('hh:mma')} ${
              appointment.providerTimezone !== null
                ? getTimeZoneText(appointment.providerTimezone)
                : tz.guess()
            }`,
          )}
        </Box>
        <IconButton
          icon="arrow-right"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.viewBtn}
          onClick={onClickAppointment(appointment)}
        >
          View details
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={dayjs(appointments[0]?.startTime).format('dddd, MMMM DD, YYYY')}
      subTitle={`${appointments.length} Sessions`}
      variant={drawerType.FORM}
      footer={<div />}
      className={classes.drawer}
    >
      <Box>{appointments.map(renderAppointment)}</Box>
    </Drawer>
  );
};

export { AppointmentsDrawer };
