import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrap: {
    height: 48,
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  select: {
    border: 'none !important',
    height: 48,
    width: 'auto',
    borderWidth: 0,
    '& fieldset': {
      borderWidth: 0,
      border: 'none',
    },
    '& .MuiSelect-select': {
      paddingRight: '32px !important',
      fontSize: '14px !important',
      lineHeight: '20px',
      fontWeight: 600,
      color: colors.neutral700,
    },
  },
  input: {
    border: 'none',
    height: 48,
    flex: 1,
    '& input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '-moz-appearance': 'textfield',
    },
  },
});
