// colors
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { colors } from '@confidant-health/lib/colors';

const generateAddPlus = (size: number) => ({
  content: "'+'",
  position: 'absolute',
  width: size,
  height: size,
  textAlign: 'center',
  fontSize: size - 8,
  lineHeight: `${size - 8}px`,
  borderRadius: 4,
  color: colors.primary500,
  border: `1px solid ${colors.primary300}`,
  left: `calc(50% - ${size / 2}px)`,
  top: `calc(50% - ${size / 2}px)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const useStyles = makeStyles({
  // header for weekdays
  dayHeadText: {
    textAlign: 'left',
    position: 'absolute',
    left: 16,
    top: 16,
  },
  dayHeadDay: {
    fontSize: 18,
    color: colors.neutral900,
    fontWeight: 600,
  },
  dayHeadMonth: {
    fontSize: 14,
    color: colors.neutral500,
    fontWeight: 500,
  },

  calendarView: {
    position: 'relative',
    fontFamily: 'Manrope',
    '& .fc-timegrid .fc-col-header-cell': {
      height: 80,
    },
    '& .temp-cell': {
      border: 'none',
      cursor: 'pointer',
      position: 'relative',
      '&:hover:before': generateAddPlus(36),
    },
    // timegrid
    '& .fc-timegrid-event-harness': {
      // height: 64,
    },
    '& .fc-timegrid-event': {
      boxShadow: 'none',
      border: 'none',
      // height: 'calc(100% - 1px)',
      // overflow: 'hidden',
      left: -2,
      borderRadius: 0,
      '& .fc-event-main': {
        padding: 0,
        border: 'none',
        margin: '0 -2px',
        '& $eventTitle': {
          // paddingTop: 12,
        },
      },
      '& $event > div': {
        // height: '100%',
      },
    },
    '& .fc-timegrid-col-events': {
      margin: '0 0 0 2px',
    },
    '& .fc .fc-timegrid-slot-minor': {
      borderTop: 'none',
    },
    '& .fc .fc-timegrid-col.fc-day-today': {
      backgroundColor: colors.white,
    },
    '& .fc-non-business': {
      background: 'unset',
    },
    '& .fc-timeGridDay-view': {
      '& .fc-timegrid-slot:hover:before': generateAddPlus(32),
      '& .fc-timegrid-slot .day-available': {
        cursor: 'pointer',
      },
    },
    '& .fc .fc-timegrid-slot': {
      height: 64,
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
    },
    '& .fc-view-harness.fc-view-harness-active': {
      height: '779px !important',
    },
    '& .fc-scrollgrid-sync-table': {
      height: '724px !important',
    },
    '& .fc-header-toolbar.fc-toolbar ': {
      padding: 24,
      marginBottom: 0,
      backgroundColor: colors.neutral25,
      '& .fc-toolbar-chunk': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .fc-toolbar-title': {
      fontSize: 18,
      fontWeight: 800,
      color: colors.neutral900,
    },
    '& .fc-button-group .fc-button': {
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
      '&:active': {
        outline: 'none !important',
        boxShadow: 'none !important',
      },
    },
    '& .fc-button-group .fc-prev-button, & .fc-button-group .fc-next-button': {
      backgroundColor: alpha(colors.primary, 0.1),
      color: colors.primary,
      width: 40,
      height: 40,
      borderRadius: '4px !important',
      borderColor: 'transparent !important',
      '&:active': {
        backgroundColor: alpha(colors.primary, 0.8),
      },
      '&:hover': {
        backgroundColor: alpha(colors.primary, 0.8),
        color: colors.white,
      },
      '&:first-child': {
        marginRight: 16,
        '& span': {
          left: -4,
        },
      },
      '&:last-child span': {
        left: -2,
      },
      '& span': {
        position: 'relative',
        top: -1,
      },
    },
    '& .fc-daygrid-day, & .fc-timegrid-col': {
      '&.fc-day-today': {
        backgroundColor: 'transparent',
        '& $dayCellText': {
          color: colors.primary,
          fontWeight: 700,
        },
      },
      '&:not(.fc-day-past)': {
        '&.day-available': {
          backgroundColor: colors.white,
          '& .fc-daygrid-day-events': {
            '&:hover': {
              cursor: 'pointer',
              '&:before': generateAddPlus(40),
            },
          },
          '& .& .fc-timegrid-col-frame': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      },
      '&.day-unavailable': {
        backgroundColor: colors.neutral50,
      },
    },
    '& .fc-daygrid-event': {
      height: '100%',
      margin: '0 !important',
      marginTop: '0 !important',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  },
  btnGroup: {
    position: 'absolute',
    left: 24,
    top: 24,
    borderRadius: 4,
  },
  btn: {
    border: `1px solid ${colors.neutral200}`,
    height: 48,
    minWidth: 'auto',
    backgroundColor: colors.white,
    color: colors.neutral700,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: colors.neutral700,
      color: colors.white,
    },
  },
  btnActive: {
    border: 'none',
    backgroundColor: colors.neutral800,
    color: colors.white,
  },
  dayHead: {
    fontWeight: '800',
    fontSize: 12,
    lineHeight: '20px',
    color: colors.neutral700,
    height: 52,
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      position: 'relative',
      paddingTop: 16,
    },
  },
  dayCell: {
    '& .fc-daygrid-day-top': {
      textAlign: 'center',
      justifyContent: 'center',
    },
    '& .fc-scrollgrid-sync-inner': {
      position: 'relative',
      height: 120,
      '& .fc-daygrid-day-top': {
        position: 'absolute',
        zIndex: 10,
        width: '100%',
      },
      '& .fc-daygrid-day-events': {
        position: 'absolute',
        height: '100%',
        minHeight: '100%',
        top: 0,
        width: '100%',
        marginTop: 0,
        '& .fc-daygrid-event-harness': {
          height: '100%',
          '& .fc-daygrid-event': {
            '&:focus': {
              backgroundColor: 'unset',
              boxShadow: 'none',
              '&:after': {
                backgroundColor: 'unset',
              },
            },
          },
        },
      },
    },
  },
  dayCellText: {
    fontWeight: '600',
    fontSize: 12,
    color: colors.neutral500,
  },
  event: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    '& > div': {
      fontSize: 14,
      lineHeight: '20px',
    },
    backgroundColor: colors.primary50,
    '& $eventTitle': {
      color: colors.primary600,
    },
  },
  eventToday: {
    backgroundColor: colors.orange50,
    '& $eventTitle': {
      color: colors.success600,
    },
  },
  eventContent: {
    padding: '0 10px',
  },
  eventTitle: {
    fontWeight: 600,
    color: colors.primary600,
    maxHeight: '100%',
    width: '100%',
    whiteSpace: 'break-spaces',
  },
  eventTime: {
    fontWeight: 500,
    color: colors.neutral700,
    whiteSpace: 'pre-wrap',
  },
});
