import { AxiosResponse } from 'axios';
import { baseRequest } from 'utils/HttpClient';
import { ApiEndpoints } from 'constants/ApiEndpoints';

type ProxyRequest = {
  body?: any;
  method: string;
  params?: any;
  url: string;
  memberId: string;
};

export const bypassProxy = (payload: ProxyRequest): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.BYPASS_PROXY, payload);
};
