import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { DashboardBox, boxType } from '@confidant-health/lib/ui/templates/dashboard-box';
import { Heading, Text, fontWeight } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { colors } from '@confidant-health/lib/colors';
import { IInvoiceRecord } from 'redux/modules/reporting/types';
import { sentenceCase } from 'sentence-case';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';
import { useStyles } from './InvoiceDetailDrawer.styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  invoice: IInvoiceRecord;
  updateInvoiceStatus: (invoice: IInvoiceRecord, status: string) => void;
}

const InvoiceDetailDrawer: FC<IProps> = ({ open, onClose, invoice, updateInvoiceStatus }) => {
  const classes = useStyles();

  const renderLabel = (label: string) => <Text className={classes.infoBlockLabel}>{label}</Text>;

  const renderBoldLabel = (label: string) => <Text className={classes.infoBlockBold}>{label}</Text>;

  const renderInsuranceRow = (label: string, value: string) => (
    <Box display="flex" flexDirection="column" marginBottom={1}>
      <Text className={clsx(classes.infoBlockLabel, classes.nobottom)}>{label}</Text>
      <Text className={clsx(classes.infoBlockBold, classes.fontWeight500)}>{value}</Text>
    </Box>
  );

  const Col = ({ label, value }) => (
    <Box display="flex" flexDirection="column" minWidth={140}>
      <Text className={clsx(classes.infoBlockBold, classes.fontWeight400)}>{label}</Text>
      <Text className={classes.infoBlockBold}>{value || 'N/A'}</Text>
    </Box>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Invoice details"
      variant={drawerType.FORM}
      footer={<Box />}
      className={classes.drawer}
      position={drawerPosition.RIGHT}
    >
      {invoice && (
        <Box>
          <Box className={classes.top}>
            <Stack direction="row" alignItems="center" gap={1.5}>
              <Heading className={classes.amount}>${invoice?.totalAmount || 0}</Heading>
              <Stack direction="column" justifyContent="space-between" flex={1}>
                <Badge
                  variant={badgeType.OUTLINED}
                  style={invoice?.status === 'PAID' ? badgeStyle.RESOLVED : badgeStyle.HIGH}
                  className={clsx(classes.status, { [classes.unpaidStatus]: invoice?.status !== 'PAID' })}
                >
                  {sentenceCase(invoice?.status || '') || 'N/A'}
                </Badge>
                <Text className={classes.time}>
                  {invoice?.invoiceDate
                    ? `${dayjs(invoice?.invoiceDate).format(`${GLOBAL_DATE_FORMAT}- hh:mm A`)}`
                    : 'N/A'}
                </Text>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              gap={2}
              sx={{ marginTop: 4 }}
            >
              {[
                {
                  title: 'date of invoice',
                  value: invoice?.invoiceDate
                    ? `${dayjs(invoice?.invoiceDate).format(`${GLOBAL_DATE_FORMAT}- hh:mm A`)}`
                    : 'N/A',
                },
                {
                  title: 'date of session',
                  value: invoice?.sessionDate
                    ? `${dayjs(invoice?.sessionDate).format(`${GLOBAL_DATE_FORMAT}- hh:mm A`)}`
                    : 'N/A',
                },
                {
                  title: 'due date',
                  value: invoice?.dueDate
                    ? `${dayjs(invoice?.dueDate).format(`${GLOBAL_DATE_FORMAT}- hh:mm A`)}`
                    : 'N/A',
                },
                {
                  title: 'insurance paid',
                  value: invoice?.insuranceAmount ? `$${invoice?.insuranceAmount}` : 'N/A',
                },
                {
                  title: 'member paid',
                  value: invoice?.status === 'PAID' ? `$${invoice?.totalAmount}` : 'N/A',
                },
                {
                  title: 'amount due',
                  value: invoice?.status === 'PAID' ? 'N/A' : `$${invoice?.totalAmount || 0}`,
                },
              ].map(item => (
                <DashboardBox
                  key={item.title}
                  className={classes.item}
                  label={item.title}
                  isInvoice
                  value={item.value}
                  type={boxType.TOTAL}
                />
              ))}
            </Stack>
          </Box>
          <Box className={classes.infoWrap}>
            <Box className={classes.infoBlock}>
              {renderLabel('Member')}
              <ProfileInfo
                type="member"
                photo={invoice?.member?.profileImage}
                nickName={
                  invoice?.member?.firstName && invoice?.member?.lastName
                    ? `${invoice?.member?.firstName || ''} ${invoice?.member?.lastName || ''}`
                    : invoice?.member?.fullName ?? 'No Name'
                }
                fullName={invoice?.member?.uuid}
                memberId={invoice?.member?.id}
              />
            </Box>
            <Box className={classes.infoBlock}>
              {renderLabel('Provider')}
              <ProfileInfo
                type="provider"
                photo={invoice?.provider?.profileImage}
                // eslint-disable-next-line jsx-a11y/aria-role
                role="provider"
                fullName={`${invoice?.provider?.firstName || ''} ${invoice?.provider?.lastName || ''}`}
                memberId={invoice?.provider?.id}
              />
            </Box>
            <Box className={classes.infoBlock}>
              {renderLabel('Session type')}
              <Box className={classes.member}>
                <Text className={classes.id} weight={fontWeight.MEDIUM}>
                  {invoice?.sessionType || 'N/A'}
                </Text>
              </Box>
            </Box>
          </Box>
          {/* <Box className={classes.row} margin={4} flex={1}>
            {renderInsuranceRow('Insurance Reason', invoice?.reason || 'N/A')}
          </Box> */}
          <Box className={classes.infoWrap}>
            <Box className={classes.infoBlock}>
              <Box display="flex" flexWrap="wrap">
                {renderBoldLabel('CPT Codes')}
                {invoice?.cptList?.map((c, index) => (
                  <Box
                    key={`${index}-${c._id}`}
                    display="flex"
                    flexDirection="row"
                    mx={-3}
                    py={2}
                    px={3}
                    sx={{ borderTop: `1px solid ${colors.neutral100}` }}
                  >
                    <Col label="CPT Code" value={c?.cptCode} />
                    <Col label="ICD 10 Code" value={c?.icd10Code} />
                    <Col label="Related diagnosis" value={c?.relatedDiagnosis} />
                    <Col label="Fee" value={c?.fee ? `$${c?.fee}` : 'N/A'} />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box className={classes.row} marginRight={4} marginLeft={4} marginBottom={4} flex={1}>
            {renderBoldLabel('Insurance details')}
            {renderInsuranceRow('Insurance Carrier', invoice?.insuranceDetail?.payer || 'N/A')}
            {renderInsuranceRow('Plan Carrier', invoice?.insuranceDetail?.plan || 'N/A')}
            {renderInsuranceRow('Status', invoice?.insuranceDetail?.isActive ? 'Active' : 'InActive')}
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Box className={classes.btnGroup}>
              {invoice?.status !== 'WRITE_OFF' && (
                <Button
                  variant={btnType.OUTLINE}
                  className={classes.btn}
                  onClick={() => {
                    updateInvoiceStatus(invoice, 'WRITE_OFF');
                    onClose();
                  }}
                >
                  Write off
                </Button>
              )}
              <Button
                onClick={() => {
                  updateInvoiceStatus(invoice, 'PENDING');
                  onClose();
                }}
              >
                Request payment
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export { InvoiceDetailDrawer };
