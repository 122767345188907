/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { toDateString } from 'utils/CommonUtils';
import { DCT_TABLES } from '../DataCollectionTemplates.constants';
import { Bucket3, IDCTDetailReport } from './DCTDetail.types';

export const tableColumns: TableGrid.TableColumn[] = [
  {
    id: 'member',
    label: 'Memeber',
    sortable: false,
  },
  {
    id: 'lastUpdated',
    label: 'Date assigned',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

// export const getLast30DaysData = (profileElementReport: IDemographicsDetailReport) =>
//   profileElementReport
//     ? profileElementReport.aggregations.last_30_days.count.buckets.map(bucket => bucket.doc_count)
//     : [];

// export const getPast30DaysData = (profileElementReport: IDemographicsDetailReport) =>
//   profileElementReport
//     ? profileElementReport.aggregations.past_30_days.counts.buckets.map(bucket => bucket.doc_count)
//     : [];
const getFieldsFromBucket3 = (bucket: Bucket3) => {
  const name =
    bucket.last_assigned_date.hits.hits[0].fields.firstName?.[0] &&
    bucket.last_assigned_date.hits.hits[0].fields.lastName?.[0]
      ? `${bucket.last_assigned_date.hits.hits[0].fields.firstName[0]} ${bucket.last_assigned_date.hits.hits[0].fields.lastName[0]}`
      : bucket.last_assigned_date.hits.hits[0].fields.fullName?.[0];
  const lastModifiedDate = bucket.last_assigned_date.hits.hits[0].fields.lastModifiedDate?.[0] || '';
  const memberId = bucket.last_assigned_date.hits.hits[0].fields.memberId?.[0] || '';
  return { name, lastModifiedDate, memberId };
};
const getFormatedData = (buckets: Bucket3[], searchKeyword: string) => {
  if (searchKeyword) {
    return buckets
      .map(bucket => ({
        name: getFieldsFromBucket3(bucket).name,
        member: {
          name: getFieldsFromBucket3(bucket).name,
          memberId: getFieldsFromBucket3(bucket).memberId,
        },
        lastUpdated: toDateString(getFieldsFromBucket3(bucket).lastModifiedDate),
      }))
      .filter(item => item.name?.toLowerCase().includes(searchKeyword.toLowerCase()))
      .sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime());
  }
  return buckets
    .map(bucket => ({
      name: getFieldsFromBucket3(bucket).name,
      member: {
        name: getFieldsFromBucket3(bucket).name,
        memberId: getFieldsFromBucket3(bucket).memberId,
      },
      lastUpdated: toDateString(getFieldsFromBucket3(bucket).lastModifiedDate),
    }))
    .sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime());
};
export const getRecentCompletionData = (DCTDetailReport: IDCTDetailReport, searchKeyword?: string) =>
  DCTDetailReport
    ? searchKeyword
      ? getFormatedData(DCTDetailReport.aggregations.recent_completion?.by_members?.buckets, searchKeyword)
      : getFormatedData(DCTDetailReport.aggregations.recent_completion?.by_members?.buckets, null)
    : [];
export const getRecentAttemptsData = (DCTDetailReport: IDCTDetailReport, searchKeyword?: string) =>
  DCTDetailReport
    ? searchKeyword
      ? getFormatedData(DCTDetailReport.aggregations.recent_attempts?.by_members?.buckets, searchKeyword)
      : getFormatedData(DCTDetailReport.aggregations.recent_attempts?.by_members?.buckets, null)
    : [];
// mostCompletedMembers: 'mostCompletedMembers',
//   leastCompletedMembers: 'leastCompletedMembers',
//   mostCompletedAttemps: 'mostCompletedAttemps',
//   leastCompletedAttemps: 'leastCompletedAttemps',
//   averageInitialScore: 'averageInitialScore',
//   averageCurrentScore: 'averageCurrentScore',
//   greatestChangeInScore: 'greatestChangeInScore',
//   averageNumberOfCompletions: 'averageNumberOfCompletions',
export const getDCTDctDetailQuery = (name: string, type: string) => {
  switch (type) {
    case DCT_TABLES.mostCompletedMembers:
    case DCT_TABLES.leastCompletedMembers:
      return {
        size: 0,
        query: {
          bool: {
            must: [
              {
                terms: {
                  'dct_name.keyword': [name],
                },
              },
              {
                terms: {
                  'status.keyword': ['COMPLETED'],
                },
              },
            ],
          },
        },
        aggs: {
          total_assignments: {
            cardinality: {
              field: 'mongo_id.keyword',
            },
          },
          by_status: {
            terms: {
              field: 'status.keyword',
              size: 10,
            },
          },
          last_30_days: {
            filter: {
              bool: {
                must: [
                  {
                    exists: {
                      field: 'dct_id',
                    },
                  },
                  {
                    range: {
                      lastModifiedDate: {
                        gte: 'now-30d/d',
                        lte: 'now',
                      },
                    },
                  },
                ],
              },
            },
            aggs: {
              counts: {
                date_histogram: {
                  field: 'lastModifiedDate',
                  calendar_interval: '1d',
                },
              },
            },
          },
          past_30_days: {
            filter: {
              bool: {
                must: [
                  {
                    exists: {
                      field: 'dct_id',
                    },
                  },
                  {
                    range: {
                      lastModifiedDate: {
                        gte: 'now-120d/d',
                        lt: 'now-30d/d',
                      },
                    },
                  },
                ],
              },
            },
            aggs: {
              counts: {
                date_histogram: {
                  field: 'lastModifiedDate',
                  calendar_interval: '1d',
                },
              },
            },
          },
          recent_completion: {
            filter: {
              term: {
                'status.keyword': 'COMPLETED',
              },
            },
            aggs: {
              by_members: {
                terms: {
                  field: 'member_name.keyword',
                  size: 10000,
                },
                aggs: {
                  last_assigned_date: {
                    top_hits: {
                      _source: ['lastModifiedDate', 'member_id', 'member_first_name', 'member_last_name'],
                      sort: [
                        {
                          lastModifiedDate: {
                            order: 'desc',
                          },
                        },
                      ],
                      size: 1,
                      script_fields: {
                        memberId: {
                          script: {
                            source: "params['_source']['member_id']",
                            lang: 'painless',
                          },
                        },
                        firstName: {
                          script: {
                            source: "params['_source']['member_first_name']",
                            lang: 'painless',
                          },
                        },
                        fullName: {
                          script: {
                            source: "params['_source']['member_name']",
                            lang: 'painless',
                          },
                        },
                        lastName: {
                          script: {
                            source: "params['_source']['member_last_name']",
                            lang: 'painless',
                          },
                        },
                        lastModifiedDate: {
                          script: {
                            source: "params['_source']['lastModifiedDate']",
                            lang: 'painless',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    case DCT_TABLES.mostCompletedAttemps:
    case DCT_TABLES.leastCompletedAttemps:
      return {
        size: 0,
        query: {
          bool: {
            must: [
              {
                terms: {
                  'dct_name.keyword': [name],
                },
              },
            ],
          },
        },
        aggs: {
          total_attempts: {
            cardinality: {
              field: 'mongo_id.keyword',
            },
          },
          by_status: {
            terms: {
              field: 'status.keyword',
              size: 10,
            },
          },
          last_30_days: {
            filter: {
              bool: {
                must: [
                  {
                    exists: {
                      field: 'dct_id',
                    },
                  },
                  {
                    range: {
                      lastModifiedDate: {
                        gte: 'now-30d/d',
                        lte: 'now',
                      },
                    },
                  },
                ],
              },
            },
            aggs: {
              counts: {
                date_histogram: {
                  field: 'lastModifiedDate',
                  calendar_interval: '1d',
                },
              },
            },
          },
          past_30_days: {
            filter: {
              bool: {
                must: [
                  {
                    exists: {
                      field: 'dct_id',
                    },
                  },
                  {
                    range: {
                      lastModifiedDate: {
                        gte: 'now-120d/d',
                        lt: 'now-30d/d',
                      },
                    },
                  },
                ],
              },
            },
            aggs: {
              counts: {
                date_histogram: {
                  field: 'lastModifiedDate',
                  calendar_interval: '1d',
                },
              },
            },
          },
          recent_attempts: {
            filter: {
              exists: {
                field: 'total_attempt',
              },
            },
            aggs: {
              by_members: {
                terms: {
                  field: 'member_name.keyword',
                  size: 10000,
                },
                aggs: {
                  last_assigned_date: {
                    top_hits: {
                      _source: ['lastModifiedDate', 'member_id', 'member_first_name', 'member_last_name'],
                      sort: [
                        {
                          lastModifiedDate: {
                            order: 'desc',
                          },
                        },
                      ],
                      size: 1,
                      script_fields: {
                        memberId: {
                          script: {
                            source: "params['_source']['member_id']",
                            lang: 'painless',
                          },
                        },
                        firstName: {
                          script: {
                            source: "params['_source']['member_first_name']",
                            lang: 'painless',
                          },
                        },
                        fullName: {
                          script: {
                            source: "params['_source']['member_name']",
                            lang: 'painless',
                          },
                        },
                        lastName: {
                          script: {
                            source: "params['_source']['member_last_name']",
                            lang: 'painless',
                          },
                        },
                        lastModifiedDate: {
                          script: {
                            source: "params['_source']['lastModifiedDate']",
                            lang: 'painless',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    case DCT_TABLES.averageInitialScore:
      return {};
    case DCT_TABLES.averageCurrentScore:
      return {};
    case DCT_TABLES.greatestChangeInScore:
      return {};
    case DCT_TABLES.averageNumberOfCompletions:
      return {};

    default:
      return {};
  }
};
