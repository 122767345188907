export const TAGS = {
  TAGS_PRIORITY_LEVELS: [
    { label: 'Critical', value: 'CRITICAL' },
    { label: 'Normal', value: 'NORMAL' },
    { label: 'High', value: 'HIGH' },
    { label: 'Low', value: 'LOW' },
    { label: 'None', value: 'NONE' },
  ],
  TAGS_COLOR: {
    RED: 'red',
    YELLOW: 'yellow',
    GREEN: 'green',
    PURPLE: 'purple',
  },
  TAGS_TYPES: [
    { label: 'Alerts', value: 'ALERTS' },
    { label: 'Life Events', value: 'LIFE_EVENTS' },
    { label: 'Symptoms', value: 'SYMPTOMS' },
    { label: 'Side Effects', value: 'SIDE_EFFECTS' },
    { label: 'Diagnosis', value: 'DIAGNOSIS' },
    { label: 'Prescriptions', value: 'PRESCRIPTIONS' },
    { label: 'Improvements', value: 'IMPROVEMENTS' },
    { label: 'Substance Use', value: 'SUBSTANCE_USE' },
    { label: 'GENERAL', value: 'General' },
  ],
  TAGS_ASSIGNMENT_METHOD: [
    { label: 'Profile element', value: 'BASED_ON_PROFILE_ELEMENT' },
    { label: 'DCT', value: 'BASED_ON_DCT' },
  ],
  BASED_ON_TAGS_ASSIGNMENT_METHOD: {
    BASED_ON_PROFILE_ELEMENT: 'BASED_ON_PROFILE_ELEMENT',
    BASED_ON_DCT: 'BASED_ON_DCT',
  },
};
