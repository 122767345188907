import { SHOW_SNACKBAR, CLEAR_SNACKBAR } from './actions';
import { SnackState, SNACK_ACTION } from './types';

export const initialState: SnackState = {
  show: false,
  snackType: 'success',
  snackMessage: '',
  vertical: 'bottom',
  horizontal: 'right',
};

const snackReducer = (state = initialState, { type, payload }: SNACK_ACTION) => {
  switch (type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        show: true,
        snackType: payload.snackType,
        snackMessage: payload.snackMessage,
        vertical: payload.vertical,
        horizontal: payload.horizontal,
      };
    case CLEAR_SNACKBAR:
      return {
        ...state,
        show: false,
        snackMessage: '',
      };
    default:
      return state;
  }
};

export default snackReducer;
