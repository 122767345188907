import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';
import { theme } from '@confidant-health/lib/theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    borderRadius: 12,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
  },
  infoIcon: {
    color: colors.warning500,
  },
  documentIcon: {
    color: colors.indigo500,
  },
  discrepancyIcon: {
    color: colors.secondary500,
  },
  expiredIcon: {
    color: colors.destructive500,
  },
  expiringSoonIcon: {
    color: colors.orange500,
  },
  successIcon: {
    color: colors.success500,
  },
  disabledIcon: {
    color: colors.neutral400,
  },
});
