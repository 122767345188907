import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Heading, Text, headingLevel, fontWeight } from '@confidant-health/lib/ui/atoms/typography';
import SearchIcon from '@mui/icons-material/Search';
import { getAuth } from 'redux/modules/auth/selectors';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { profileActionCreators } from 'redux/modules/profile';
import { AppointmentEvaluationTopBar } from './evaluation-validation-topbar/AppointmentEvaluationTopBar';
import { useStyles } from './AflieValidationPlayground.styles';
import { ApointmentEvaluationSubtitles } from './evaluation-validation-subtitles/ApointmentEvaluationSubtitles';
import { AppointmentEvaluationDctQuestion } from './evaluation-validation-dct-question/AppointmentEvaluationDctQuestion';
import { AppointmentEvaluationAudioPlayer } from './evaluation-validation-audio-player/AppointmentEvaluationAudioPlayer';
import { selectEvaluationContext } from '../../../redux/modules/conversation/selectors';
import { getAppointment, selectAppointmentDetailsById } from '../../../redux/modules/appointment/selectors';
import { EVALUATION_TYPES } from '../../provider/sessions/evaluations-v2/Evaluations.constants';
import EvaluationSectionDetail from '../../provider/sessions/evaluations/evaluation-section-detail';
import {
  provideAIFeedback,
  updateSessionTranscript,
} from '../../../services/conversation/conversation.service';
import { showSnackbar } from '../../../redux/modules/snackbar';
import { conversationActionCreators } from '../../../redux/modules/conversation';
import AIReminderModal from './ai-reminder-modal';
import { ICBResponse } from '../../../redux/modules/conversation/types';

const AlfieValidationPlayground: FC = () => {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const { isAdmin } = useSelector(getAuth);
  const { appointmentDetailById2 } = useSelector(getAppointment);
  const [selectedSection, setSelectedSection] = useState(null);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const [sessionDuration, setSessionDuration] = useState(null);
  const [isLastSection, setLastSection] = useState(false);
  const [audioTimestamp, setAudioTimestamp] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [providerSpeaker, setProviderSpeaker] = useState('spk_0');
  const [oldProviderSpeaker, setOldProviderSpeaker] = useState('spk_0');
  const [transcriptions, setTranscriptions] = useState([]);
  const [oldTranscriptions, setOldTranscriptions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [audioScrollLocked, setAudioScrollLocked] = useState(false);
  const [timedCB, setTimedCB] = useState(null);
  const { appointmentId, evaluationId } = useParams();
  const evaluationContext = useSelector(selectEvaluationContext);
  const appointmentDetails = useSelector(selectAppointmentDetailsById(appointmentId));
  const matches = useMediaQuery('(max-width:920px)');
  const provider = useMemo(
    () => appointmentDetails?.participants?.filter(participant => participant?.isPractitioner)?.[0],
    [appointmentDetails],
  );
  const provider2 = useMemo(
    () => appointmentDetailById2?.participants?.filter(participant => participant?.isPractitioner)?.[0],
    [appointmentDetailById2],
  );
  const member = useMemo(
    () => appointmentDetails?.participants?.filter(participant => !participant?.isPractitioner)?.[0],
    [appointmentDetails],
  );
  const member2 = useMemo(
    () => appointmentDetailById2?.participants?.filter(participant => !participant?.isPractitioner)?.[0],
    [appointmentDetailById2],
  );

  const dispatch = useDispatch();

  const getQuestionSectionsByType = (evaluationDtoList: any[]) => {
    const questionSections = [];
    const dctIds = [];
    evaluationDtoList?.forEach(evaluationDto => {
      if (evaluationDto?.cbType) {
        if (evaluationDto?.cbType === EVALUATION_TYPES.DCT) {
          if (dctIds?.includes(evaluationDto.dctId)) {
            questionSections.forEach(section => {
              if (evaluationDto?.dctId === section?.dctId) {
                section.items = evaluationDto?.dctContentBlockList;
              }
            });
          } else if (
            !evaluationDto?.responderType ||
            evaluationDto?.responderType.toUpperCase() === 'MEMBER'
          ) {
            dctIds.push(evaluationDto?.dctId);
            questionSections.push({
              title: evaluationDto?.text,
              isDct: true,
              score: 0,
              items: evaluationDto?.dctContentBlockList,
              dctId: evaluationDto?.dctId,
              dctScore: evaluationDto?.dctScore,
              isDCTScoreable: evaluationDto?.isDCTScoreable,
              isAttemptable: evaluationDto?.isAttemptable,
              displayLogics: evaluationDto?.displayLogics,
              cbId: evaluationDto?.cbId,
              responderType: evaluationDto?.responderType,
              description: evaluationDto?.description,
              prompt: evaluationDto?.prompt,
            });
          }
        } else {
          questionSections.push({
            title: evaluationDto?.text,
            type: 'CB',
            score: 0,
            isAttemptable: evaluationDto?.isAttemptable,
            displayLogics: evaluationDto?.displayLogics,
            cbId: evaluationDto?.cbId,
            items: [
              {
                ...evaluationDto,
                description: '',
                type: evaluationDto?.cbType,
                isDone: evaluationDto?.isDone || false,
              },
            ],
          });
        }
      }
    });
    if (searchQuery !== '') {
      return questionSections.filter(section => {
        const titleMatched = section.title.search(new RegExp(searchQuery.toLowerCase(), 'i')) !== -1;
        return (
          titleMatched ||
          section.items.some(item => item.text.search(new RegExp(searchQuery.toLowerCase(), 'i')) !== -1)
        );
      });
    }

    return questionSections;
  };

  const evaluation = {
    sections: getQuestionSectionsByType(evaluationContext?.cbResponseList),
  };

  const getMatchBetweenResponses = (evaluationResponses: string[], alfieResponses: string[]) => {
    // Check match between both arrays and return full-match for exact match, no-match for no match and partial-match for partial match
    let matchCount = 0;
    evaluationResponses.forEach(response => {
      const found = alfieResponses.find(alfieResponse => {
        return alfieResponse.trim().toLowerCase() === response.trim().toLowerCase();
      });
      if (found) {
        matchCount++;
      }
    });
    if (matchCount === evaluationResponses.length && matchCount === alfieResponses.length) {
      return 'full-match';
    }
    if (matchCount === 0) {
      return 'no-match';
    }
    return 'partial-match';
  };

  const applyValidationMarkers = (corrections: Map<string, string[]>) => {
    const alfieBlocks = evaluationContext?.alfieEvaluations?.evaluations
      ?.map(ev => ev?.contentBlocks)
      ?.flat();
    evaluation.sections = evaluation?.sections.map(section => {
      section.items = section?.items?.map(item => {
        const validatedBlock = alfieBlocks?.find(alfieBlock => {
          return alfieBlock.cbid === item?.cbId;
        });
        if (validatedBlock) {
          item.alfieResponses = validatedBlock?.answers;
          item.evaluationResponses = item.userResponse?.userResponseList;
          item.matchStatus =
            (validatedBlock.amendNote && validatedBlock.amendNote !== '') ||
            (validatedBlock.wrongAlfieChoices && validatedBlock.wrongAlfieChoices.length > 0)
              ? 'resolved'
              : getMatchBetweenResponses(item.userResponse?.userResponseList, validatedBlock.answers);
          item.amendNote = validatedBlock.amendNote;
          item.corrections = corrections?.[item.cbId] ?? [];
          item.reviewerResponses = validatedBlock.reviewerResponses;
          item.wrongAlfieChoices = validatedBlock.wrongAlfieChoices;
        } else {
          item.matchStatus = 'no-match';
        }
        return item;
      });
      return section;
    });
  };

  const onClickNext = () => {
    if (isLastSection) {
      if (searchQuery !== '') {
        dispatch(
          showSnackbar({
            snackType: 'warning',
            snackMessage: 'Remove search filter to submit the validation result',
          }),
        );
        return;
      }
      if (evaluationContext.alfieValidationInProgress) {
        dispatch(
          showSnackbar({
            snackType: 'warning',
            snackMessage: 'Cannot submit while AI Analysis is in progress',
          }),
        );
        return;
      }
      const request = {
        feedbackType: 'SUBMIT',
      };
      provideAIFeedback(request, appointmentDetails?.appointmentId)
        .then(() => {
          console.log('Feedback provided for AI validation');
          setSelectedSection(evaluation.sections[0]);
          setLastSection(false);
        })
        .catch(err => {
          console.warn('Error providing feedback to AI validation', err);
        });
    } else if (selectedSection.items && selectedSection.items.length > 0) {
      setSelectedSection(selectedSection.items[0]);
    } else {
      const parentSection = evaluation.sections.find(section =>
        section.items.some(item => item.cbId === selectedSection.cbId),
      );
      if (parentSection) {
        const childIndex: number = parentSection.items.findIndex(item => item.cbId === selectedSection.cbId);
        if (childIndex < parentSection.items.length - 1) {
          setSelectedSection(parentSection.items[childIndex + 1]);
        } else {
          const parentIndex: number = evaluation.sections.findIndex(
            section => section.cbId === parentSection.cbId,
          );
          if (parentIndex < evaluation.sections.length - 1) {
            setSelectedSection(evaluation.sections[parentIndex + 1]);
          }
        }
      }
    }
  };

  const onClickPrev = () => {
    if (isLastSection) {
      setLastSection(false);
    }
    if (selectedSection.items && selectedSection.items.length > 0) {
      const parentIndex: number = evaluation.sections.findIndex(
        section => section.cbId === selectedSection.cbId,
      );
      if (parentIndex > 0) {
        const previousSectionItems = evaluation.sections[parentIndex - 1].items;
        setSelectedSection(previousSectionItems[previousSectionItems.length - 1]);
      }
    } else {
      const parentSection = evaluation.sections.find(section =>
        section.items.some(item => item.cbId === selectedSection.cbId),
      );
      const childIndex: number = parentSection.items.findIndex(item => item.cbId === selectedSection.cbId);
      if (childIndex === 0) {
        setSelectedSection(parentSection);
      } else {
        setSelectedSection(parentSection.items[childIndex - 1]);
      }
    }
  };
  useEffect(() => {
    if (isAdmin) {
      const queryParams = {
        searchQuery: '',
        pageNumber: 1,
        pageSize: 10000,
        orderBy: '',
        sortBy: '',
      };
      dispatch(profileActionCreators.fetchProviders(queryParams));
      dispatch(profileActionCreators.fetchAdminValidationTodos());
    }
    if (!isAdmin) {
      dispatch(appointmentActionCreators.fetchAppointmentById({ appointmentId2: appointmentId }));
    }
    dispatch(conversationActionCreators.fetchEvaluationContext({ appointmentId, evaluationId }));
    setReminderModalOpen(true);
  }, []);

  useEffect(() => {
    if (evaluationContext) {
      if (evaluationContext?.alfieEvaluations !== undefined && evaluationContext?.alfieEvaluations !== null) {
        const { transcriptParts, speaker0 } = evaluationContext?.alfieEvaluations;
        if (speaker0 && speaker0.toLowerCase() === 'provider') {
          setProviderSpeaker('spk_0');
        } else {
          setProviderSpeaker('spk_1');
        }
        if (transcriptParts.length > 0) {
          setTranscriptions(transcriptParts);
        }
      }
    }
  }, [evaluationContext]);

  const onInvertSpeakers = () => {
    if (providerSpeaker === 'spk_0') {
      setProviderSpeaker('spk_1');
    } else {
      setProviderSpeaker('spk_0');
    }
  };

  const onChangeTranscriptSpeaker = useCallback(
    (index: number, isProvider: boolean) => {
      const memberSpeaker = providerSpeaker === 'spk_0' ? 'spk_1' : 'spk_0';
      const newTranscriptParts = transcriptions.map((part, idx) => {
        return {
          ...part,
          speaker: index === idx ? (isProvider ? memberSpeaker : providerSpeaker) : part.speaker,
          switched: index === idx ? true : part.switched,
        };
      });
      setTranscriptions([...newTranscriptParts]);
    },
    [transcriptions, oldTranscriptions],
  );

  useEffect(() => {
    if (audioScrollLocked) {
      const alfieBlocks = evaluationContext?.alfieEvaluations?.evaluations
        ?.map(ev => ev?.contentBlocks)
        ?.flat();
      const stampedBlock = alfieBlocks?.find(
        block => block?.from > 0 && block?.to > 0 && Math?.floor(block?.from) === Math?.floor(audioTimestamp),
      );
      if (stampedBlock) {
        let newSection = evaluation.sections.find(section => section.cbId === stampedBlock.cbid);
        if (!newSection) {
          evaluation.sections.forEach(section => {
            const innerItem = section.items.find(item => item.cbId === stampedBlock.cbid);
            if (innerItem) {
              newSection = innerItem;
            }
          });
        }
        if (newSection) {
          setSelectedSection(newSection);
        }
      }
    }
  }, [audioTimestamp, audioScrollLocked]);

  useEffect(() => {
    if (selectedSection) {
      const alfieBlocks = evaluationContext?.alfieEvaluations?.evaluations
        ?.map(ev => ev?.contentBlocks)
        ?.flat();
      const stampedBlock = alfieBlocks?.find(
        block => block?.from > 0 && block?.to > 0 && block?.cbid === selectedSection?.cbId,
      );
      setTimedCB(stampedBlock);
      let parentSectionIndex = evaluation?.sections?.findIndex(
        section => section?.cbId === selectedSection?.cbId,
      );
      if (parentSectionIndex === 0) {
        setHasPrevious(false);
        setLastSection(false);
      } else if (parentSectionIndex > 0) {
        setHasPrevious(true);
        setLastSection(false);
      } else {
        const parentSection = evaluation.sections?.find(section =>
          section.items.some(item => item?.cbId === selectedSection?.cbId),
        );
        parentSectionIndex = evaluation?.sections?.findIndex(section =>
          section.items.some(item => item?.cbId === selectedSection?.cbId),
        );
        if (parentSection) {
          const childIndex: number = parentSection?.items?.findIndex(
            item => item.cbId === selectedSection?.cbId,
          );
          if (childIndex >= 0) {
            setHasPrevious(true);
          }
          if (
            parentSectionIndex === evaluation?.sections?.length - 1 &&
            childIndex === parentSection?.items?.length - 1
          ) {
            setLastSection(searchQuery === '');
          } else {
            setLastSection(false);
          }
        }
      }
    }
  }, [selectedSection, evaluationContext]);

  const dctCount = evaluationContext?.cbResponseList?.filter(item => item?.cbType === 'dct')?.length;
  const cbCount = evaluationContext?.cbResponseList
    ?.filter(item => item?.cbType === 'dct')
    ?.map((item: ICBResponse) => item?.dctContentBlockList)
    ?.flat().length;
  applyValidationMarkers(evaluationContext?.corrections);

  return (
    <>
      <AIReminderModal open={reminderModalOpen} onClose={() => setReminderModalOpen(false)} />
      <Box className={classes.root}>
        <AppointmentEvaluationTopBar
          appointment={isAdmin ? appointmentDetails : appointmentDetailById2}
          sessionDuration={sessionDuration}
          analysisInProgress={evaluationContext?.alfieValidationInProgress}
          onReprocess={() => {
            dispatch(
              conversationActionCreators.reRunAIAnalysis({ appointmentId, evaluationId, mode: 'VALIDATION' }),
            );
          }}
        />
        {evaluationContext && (
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Grid container={!matches} className={classes.container}>
              <Grid item xs={!matches ? 3.5 : 12} className={classes.column}>
                <Box className={classes.column1_1_1}>
                  <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.title}>
                    {evaluationContext?.name}
                  </Heading>
                  <Text weight={fontWeight.MEDIUM} className={classes.subtitle}>
                    {dctCount} DCT&apos;s, {cbCount} content blocks
                  </Text>
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                  <Box className={classes.column1_2}>
                    <Box
                      className={classes.column1_2_1}
                      onClick={() => {
                        setShow(!show);
                      }}
                    >
                      {show ? 'Show all' : 'Hide all'}
                      {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </Box>
                    <Text weight={fontWeight.MEDIUM} className={classes.subtitle1}>
                      {dctCount} DCT&apos;s, {cbCount} content blocks
                    </Text>
                  </Box>
                  <TextField
                    id="search"
                    type="search"
                    placeholder="Search"
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
                    sx={{ paddingRight: 2 }}
                    fullWidth
                    className={classes.searchBox}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <AppointmentEvaluationDctQuestion
                    evaluation={evaluation}
                    setSelectedSection={setSelectedSection}
                    selectedSection={selectedSection}
                    evaluationContext={evaluationContext}
                    allSectionsOpen={show}
                  />
                </Box>
              </Grid>
              <Grid item xs={!matches ? 5 : 12}>
                <Grid container className={classes.column2_1_1}>
                  <AppointmentEvaluationAudioPlayer
                    src={evaluationContext?.alfieEvaluations?.sessionAudioUrl}
                    onTimeUpdated={timestamp => {
                      setAudioTimestamp(timestamp);
                    }}
                    editMode={editMode}
                    onEditClicked={() => {
                      if (!editMode) {
                        setEditMode(true);
                        setOldTranscriptions([...transcriptions]);
                        setOldProviderSpeaker(providerSpeaker);
                      }
                    }}
                    scrollLocked={audioScrollLocked}
                    onLockChanged={setAudioScrollLocked}
                    timedCB={timedCB}
                    onSessionDurationCaptured={duration => {
                      setSessionDuration(duration);
                    }}
                    analysisInProgress={evaluationContext?.alfieValidationInProgress}
                  />
                </Grid>
                <ApointmentEvaluationSubtitles
                  audioTimestamp={audioTimestamp}
                  scrollLocked={audioScrollLocked}
                  providerSpeaker={providerSpeaker}
                  parts={transcriptions}
                  member={isAdmin ? member : member2}
                  provider={isAdmin ? provider : provider2}
                  onChangeTranscriptSpeaker={onChangeTranscriptSpeaker}
                  onCancelChanges={() => {
                    setEditMode(false);
                    setProviderSpeaker(oldProviderSpeaker);
                    setTranscriptions([...oldTranscriptions]);
                  }}
                  onSaveChanges={() => {
                    setEditMode(false);
                    setOldProviderSpeaker(providerSpeaker);
                    setOldTranscriptions([...transcriptions]);
                    updateSessionTranscript(appointmentId, {
                      providerSpeaker,
                      transcriptParts: transcriptions,
                    });
                  }}
                  onInvertSpeakers={onInvertSpeakers}
                  editMode={editMode}
                  timedCB={timedCB}
                  analysisInProgress={evaluationContext?.alfieValidationInProgress}
                />
              </Grid>
              <Grid item xs={3.5}>
                {selectedSection && (
                  <EvaluationSectionDetail
                    isDisablePrevious={!hasPrevious}
                    onClickNext={onClickNext}
                    onClickPrevious={onClickPrev}
                    selectedSection={selectedSection}
                    selectMultiSelect={false}
                    alfieValidation
                    provider={isAdmin ? provider : provider2}
                    appointmentId={appointmentId}
                    evaluationId={evaluationId}
                    isLastSection={isLastSection}
                    onSelectSection={setSelectedSection}
                    analysisInProgress={evaluationContext?.alfieValidationInProgress}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

export { AlfieValidationPlayground };
