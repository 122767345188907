// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
  },
  headerTitleContainer: {
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
  },
  header: {
    height: 'auto',
    padding: '16px 16px 16px 24px',
    backgroundColor: colors.neutral25,
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
    marginBottom: 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    color: colors.neutral600,
  },
  body: {
    height: 'calc(100vh - 211px)',
    backgroundColor: colors.white,
    width: '100%',
    paddingBottom: 16,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0px', // Set the width of the scrollbar
    },
  },
  dflex: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    gap: 16,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
  },
  menu: {
    height: 'auto',
    margin: '0 !important',
    padding: '0 !important',
    '& svg': {
      width: 18,
    },
  },
  menuItemsWrapper: {
    '& ul li': {
      '&:first-child': {
        color: colors.primary600,
        '& svg path': {
          fill: colors.primary600,
        },
      },
      color: colors.destructive600,
      '& svg path': {
        fill: colors.destructive600,
      },
    },
  },
  icon: {
    marginRight: 8,
  },
  pauseIcon: {
    width: 18,
  },
  backBtn: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary300,
    width: 40,
    height: 40,
    padding: 0,
    minWidth: 40,
  },
  backbtnIcon: {
    width: 16,
    height: 16,
  },
  tabBadge: {
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 100,
    color: colors.neutral700,
    background: colors.white,
    height: 20,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  font18: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  tabContainer: {
    padding: 16,
    borderBottom: `1px solid ${colors.neutral200}`,
  },
  evaluationTabs: {
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon': {
      margin: 0,
      minHeight: 44,
      padding: '12px 16px',
      height: 44,
      textTransform: 'capitalize',
      color: colors.neutral700,
      '&.Mui-selected': {
        color: colors.neutral900,
        border: `1px solid ${colors.neutral200}`,
        borderRadius: 8,
        '& div': {
          background: colors.neutral700,
          color: colors.white,
          border: 'none',
        },
      },
    },
  },
  footer: {
    padding: '16px',
    width: '360px',
    borderTop: `1px solid ${colors.neutral100}`,
    position: 'fixed',
    backgroundColor: colors.white,
    bottom: 0,
    left: 0,
  },
  flexibleFooter: {
    width: '50% !important',
  },
  shortcutsLink: {
    color: colors.textPrimary,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    margin: '0px !important',
    padding: '0px !important',
    height: 'unset !important',
  },
});
