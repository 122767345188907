import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    borderRadius: 8,
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    border: `1px solid ${colors.neutral100}`,
  },
  checkboxWrap: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${colors.neutral100}`,
    gap: 24,
    padding: 24,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  valuesInput: {
    padding: 0,
    '& > div': {
      '& input': {
        fontSize: '14px !important',
      },
    },
    '& .MuiAutocomplete-tag': {
      fontSize: 14,
    },
  },
});
