import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    '& .drawerBody': {
      padding: 0,
    },
  },
  wrapper: {
    flex: 1,
    justifyItems: 'space-between',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
  },
  form: {
    flex: 1,
  },
  formItem: {
    borderRadius: 8,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    alignItems: 'flex-start',
    width: '100%',
    border: `1px solid ${colors.neutral100}`,
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: 24,
    minHeight: 'calc(100vh - 160px)',
  },
  section: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  disableLabel: {
    textDecorationLine: 'line-through',
  },
  deleteBtn: {
    color: colors.destructive600,
    fontSize: 14,
    '& svg': {
      width: 18,
      marginRight: 8,
    },
    '& svg path': {
      fill: colors.destructive600,
    },
  },
  addStateBtn: {
    width: 150,
  },
});
