import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  modal: {
    position: 'relative',
    width: 480,
    padding: 10,
    background: '#fff',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '10px',
  },
  iconWrapper: {
    position: 'absolute',
    top: -36,
    left: 36,
    width: 72,
    height: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '6px solid white',
    borderRadius: '50%',
    backgroundColor: '#f1f8ff',
  },
  icon: {
    width: 33,
    height: 33,
    '& path': {
      fill: '#0374DD',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 32,
    color: colors.neutral900,
    fontWeight: 700,
    margin: 0,
    marginBottom: 8,
    fontFamily: 'Source Serif Pro',
  },
  content: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  closeBtn: {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: 'pointer',
  },
  actions: {
    paddingTop: 25,
  },
});
