import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    padding: 24,
    height: 'calc(100vh - 288px)',
    overflowY: 'auto',
  },
  sections: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    marginBottom: 30,
  },
  searchInput: {
    height: 56,
  },
  label: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  count: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    color: colors.neutral500,
  },
  noData: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.neutral600,
    textAlign: 'center',
  },
});
