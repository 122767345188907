import { Box } from '@mui/system';
import { FormControl, RadioGroup } from '@mui/material';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Radio } from '@confidant-health/lib/ui/atoms/radio';

import { useEffect, useState } from 'react';
import cx from 'clsx';
import { selectDctState } from 'redux/modules/conversation/selectors';
import { useSelector } from 'react-redux';
import { IDCT1 } from 'redux/modules/conversation/types';

import { useStyles } from './EvaluationStates.styles';

type AddDctProps = {
  isOpen: boolean;
  onClose: () => void;
  addDcts: (val) => void;
};

const AddDctDrawer = ({ isOpen, onClose, addDcts }: AddDctProps) => {
  const classes = useStyles();
  const [filteredDcts, setFilteredDcts] = useState<IDCT1[]>([]);
  const [selectedDcts, setSelectedDcts] = useState(null);
  const { dcts } = useSelector(selectDctState);

  const [searchKey, setSearchKey] = useState<string>('');

  const handleSearchKeyChange = (keyword: string) => {
    setSearchKey(keyword);
    setFilteredDcts(dcts.filter(dct => dct.name.toLowerCase().includes(keyword.toLowerCase())));
  };

  useEffect(() => {
    setFilteredDcts(dcts);
  }, [dcts]);

  return (
    <Drawer
      open={isOpen}
      variant={drawerType.FORM}
      className={classes.drawer2}
      onClose={() => {
        setSearchKey('');
        setSelectedDcts(null);
        onClose();
      }}
      header={
        <div className={classes.header2}>
          <Box className={classes.headerTitle}>Add DCT</Box>
          <IconButton
            icon="close"
            onClick={() => {
              setSearchKey('');
              setSelectedDcts(null);
              onClose();
            }}
            className={cx(classes.icon, classes.editIcon)}
          />
        </div>
      }
      footer={
        <Box className={classes.footer}>
          <Button
            variant={btnType.TEXT}
            onClick={() => {
              setSearchKey('');
              setSelectedDcts(null);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              addDcts(selectedDcts);
              setSearchKey('');
              setSelectedDcts(null);
              onClose();
            }}
          >
            Save
          </Button>
        </Box>
      }
    >
      <Box padding={4}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Input
            fullWidth
            className={classes.searchInput}
            variant={inputType.SEARCH}
            placeholder="Find question"
            value={searchKey}
            onChange={handleSearchKeyChange}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup sx={{ gap: 2 }}>
              {filteredDcts.map((item, index) => (
                <>
                  <FormControlLabel
                    key={`radio-${index}-item`}
                    value={item?.dctId}
                    control={<Radio />}
                    onChange={() => setSelectedDcts(item)}
                    label={
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box className={classes.questionDCTLabel}>{item?.name}</Box>
                        <Box className={classes.questionDesc}>{item?.description}</Box>
                      </Box>
                    }
                  />
                </>
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddDctDrawer;
