export const stepTitles = {
  0: 'Event',
  1: 'Filters',
  2: 'Action',
};

export const eventTypeSelect = [{ label: 'DCT completed', value: 'DCT completed' }];

export const eventReferenceSelect = [{ label: 'Select event', value: 'Select Event' }];

export const actionTypeSelect = [
  { label: 'ASSIGN CONVERSATION', value: 'ASSIGN_CONVERSATION' },
  { label: 'ASSIGN EDUCATION', value: 'ASSIGN_EDUCATION' },
  { label: 'ASSIGN PLAN ITEM', value: 'ASSIGN_PLAN_ITEM' },
];

const defaultUnitSelect = [
  { label: 'Min', value: 'MIN' },
  { label: 'Hour', value: 'HOUR' },
  { label: 'Day', value: 'DAY' },
];
export const actionDelayUnitSelect = [{ label: 'No delay', value: 'none' }, ...defaultUnitSelect];
export const actionRepeatUnitSelect = [{ label: 'No repeat', value: 'none' }, ...defaultUnitSelect];

export const automationGoalSelect = [{ label: 'Schedule appointment', value: 'Schedule appointment' }];
export const automationGoalReferenceSelect = [{ label: 'With therapist', value: 'With therapist' }];
