import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';

export const templatesColumns: TableGrid.TableColumn[] = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'version', label: 'Version', sortable: false },
  { id: 'cbs', label: "CB's", sortable: false },
  { id: 'lastUpdated', label: 'Last updated', sortable: false },
  { id: 'usedIn', label: 'Used In', sortable: false },
  { id: 'assignments', label: 'Assignments', sortable: false },
  { id: 'scorable', label: 'Scorable', sortable: false },
  { id: 'actions', label: 'Actions', sortable: false },
];

export const filterOptionsMock = [{ label: 'Type 1', value: 'type_01' }];

export const DCTMultiSelectFilterOptionsMock = [
  {
    sectionTitle: 'Scorable',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'Scorable', value: 'Scorable' },
      { label: 'Not Scorable', value: 'Not Scorable' },
    ],
  },
];
export const viewGroups = [
  { type: 'ACTIVE', label: 'Active' },
  { type: 'DRAFT', label: 'Drafts' },
];
