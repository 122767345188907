import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.neutral50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginBackground: {
    width: '75%',
    height: '60%',
    objectFit: 'contain',
  },
  loginHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 60,
  },
  headerText: {
    fontFamily: 'Source Serif Pro',
  },
  rightContainer: {
    flex: 1,
  },
  form: { width: '100%' },
  loginContainer: {
    padding: '40px 100px',
    flex: 1,
    height: '100%',
    width: '100%',
  },
  loginContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    paddingTop: '10%',
  },
  outlineBtn: {
    height: 'unset',
    padding: '10px 16px',
    fontSize: 14,
  },
  inputContainer: {
    width: '100%',
    marginTop: 16,
  },
  input: {
    width: '100%',
    paddingLeft: 18,
    marginTop: 8,
  },
  eye: {
    cursor: 'pointer',
  },
  buttonContainer: {
    width: '100%',
    marginTop: 24,
    height: 64,
  },
  errorText: {
    color: colors.textError,
    marginBottom: 0,
  },
  buttonTextContainer: {
    width: '100%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 40,
    display: 'flex',
  },
});
