import { makeStyles } from '@mui/styles';

import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  cardHeader: {
    padding: '20px 24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapperTitleHeader: {
    '& span': {
      color: colors.neutral900,
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  btnEdit: {
    color: colors.primary600,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    textTransform: 'capitalize',
    '& svg': {
      marginLeft: 12,
      width: 14,
    },
  },
});
