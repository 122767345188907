import { badgeStyle } from '@confidant-health/lib/ui/atoms/badge';

export const radioOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const EVALUATION_STATUS = {
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  NEEDS_REVIEW: 'For review',
  INCOMPLETE: 'Incomplete',
};

export const evaluationStatusStyle = {
  REJECTED: badgeStyle.CRITICAL,
  COMPLETED: badgeStyle.RESOLVED,
  NOT_STARTED: badgeStyle.UNRELATED,
  IN_PROGRESS: badgeStyle.PRIMARY,
  AI_IN_PROGRESS: badgeStyle.MISREPORTED,
  NEEDS_REVIEW: badgeStyle.MEDIUM,
  INCOMPLETE: badgeStyle.PRIMARY,
};

export const EVALUATION_STATUS_TYPES = {
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const initialStatus = {
  evaluations: {
    icon: 'evaluation',
    title: 'Evaluations',
    description: 'completed',
    number: 0,
  },
  diagnoses: {
    icon: 'diagnoses',
    title: 'Diagnoses',
    description: 'active',
    number: 0,
  },
  codes: {
    icon: 'procedure',
    title: 'Procedure codes',
    description: 'added',
    number: 0,
  },
  medications: {
    icon: 'medication-2',
    title: 'Medications',
    description: 'prescribed',
    number: 0,
  },
  notes: {
    icon: 'lab-bottle-outlined',
    title: 'Labs & releases',
    description: 'completed',
    labs: 0,
    releases: 0,
  },
  appointment: {
    icon: 'calendar-today-outlined',
    title: 'Next appointment',
    description: 'scheduled',
    number: 0,
  },
};
