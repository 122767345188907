import React, { useState, useEffect, FC, Dispatch, SetStateAction, useRef } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { EvaluationItemNode } from 'redux/modules/conversation/types';
import { useDispatch, useSelector } from 'react-redux';
import { ContentfulClient } from 'config/contentful-config';
import { useStyles } from './InPersonSession.styles';
import { InProgressEvaluation } from './InProgressEvaluation';
import { EvaluationHelper } from '../../../../utils/EvaluationHelper';
import { CompletedEvaluation } from './CompletedEvaluation';
import { KeyboardShortcutsModal } from './keyboardShortcuts/KeyboardShortcutsModal';
import { EvaluationBox } from './EvaluationBox';
import { conversationActionCreators } from '../../../../redux/modules/conversation';
import { RightSection } from './RightSection';
import { EducationContent, IEvaluationItem } from '../evaluations-v2/Evaluations.types';
import {
  updateAppointmentEvaluationStatus,
  updateEvaluationContext,
} from '../../../../services/conversation/conversation.service';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { selectEvaluationContext } from '../../../../redux/modules/conversation/selectors';
import useEvaluationHead from '../../../../hooks/useEvaluationHead';
import EvaluationExercise from '../evaluations/evaluation-section-detail/components/evaluation-exercise';
import { RouteState } from '../evaluations-v2/Evaluations.constants';

type Props = {
  appointmentId: string;
  updateEvaluationProgress: () => void;
  setToastProperties: Dispatch<
    SetStateAction<{
      message: string;
      type: string;
      status: boolean;
    }>
  >;
  initialEvaluation: IEvaluationItem;
};
const list = new EvaluationHelper();
const InPersonSession: FC<Props> = ({
  appointmentId,
  updateEvaluationProgress,
  setToastProperties,
  initialEvaluation,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bodyRef = useRef(null);
  // const isWidth735 = useMediaQuery('(max-width:1240px)');
  // const isWidth850 = useMediaQuery('(max-width:1400px)');
  // const isWidth900 = useMediaQuery('(max-width:1571px)');
  const { updateValues } = useEvaluationHead();
  const [routeState, setRouteState] = useState<RouteState>(RouteState.INITIAL);
  const [currentEvaluation, setCurrentEvaluation] = useState<IEvaluationItem>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBlock, setCurrentBlock] = useState<EvaluationItemNode>(null);
  const [leftSectionExpanded, setLeftSectionExpanded] = useState(true);
  const [rightSectionExpanded, setRightSectionExpanded] = useState(false);
  const [nextInProgress, setNextInProgress] = useState(false);
  const [showAddEvaluation, setShowAddEvaluation] = useState(false);
  const [textInputInFocus, setTextInputInFocus] = useState(false);
  const [leftTrigger, setLeftTrigger] = useState(false);
  const [rightTrigger, setRightTrigger] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { head, processEvaluationData } = useEvaluationHead();
  const [educationContent, setEducationContent] = useState<EducationContent>({});
  const evaluationContext = useSelector(selectEvaluationContext);

  useEffect(() => {
    if (currentEvaluation) {
      dispatch(
        conversationActionCreators.fetchEvaluationContext({
          appointmentId,
          evaluationId: currentEvaluation.id,
        }),
      );
    }
    // Cleanup function
    return () => {
      dispatch(
        conversationActionCreators.evaluationContextUpdated({
          evaluationContext: null,
        }),
      );
    };
  }, [currentEvaluation]);

  const evaluationSelected = evaluation => {
    setCurrentBlock(null);
    setCurrentEvaluation(evaluation);
    setRouteState(RouteState.IN_PROGRESS);
  };

  const fetchEducationalContent = async educationContentSlug => {
    try {
      const educationalContentRecord = await ContentfulClient.getEntry(educationContentSlug);
      return educationalContentRecord.fields; // Assuming you have defined the fields you want to display.
    } catch (error) {
      console.error('Error fetching educational content:', error);
      return null;
    }
  };

  useEffect(() => {
    if (initialEvaluation) {
      evaluationSelected(initialEvaluation);
    }
  }, [initialEvaluation]);
  const completeEvaluation = () => {
    const evalCompleted = list.isEvaluationCompleted();
    if (evalCompleted) {
      void updateEvaluationStatus();
    }
    setRouteState(RouteState.COMPLETED);
  };

  const setNextBlock = (block, context, forceNext = false) => {
    if (block) {
      if (nextInProgress || forceNext) {
        const next = list.getNextApplicableBlock(block.cbId);
        if (next) {
          setCurrentBlock(next);
        } else {
          completeEvaluation();
        }
        setNextInProgress(false);
      } else {
        setCurrentBlock(list.getContentBlock(block.cbId));
      }
    } else {
      setCurrentBlock(context);
    }
  };

  const setPreviousBlock = block => {
    if (block) {
      const prev = list.getPreviousApplicableBlock(block.cbId);
      if (prev) {
        setCurrentBlock(prev);
      }
    }
  };

  useEffect(() => {
    if (evaluationContext) {
      processEvaluationData(evaluationContext);
    }
  }, [evaluationContext]);

  useEffect(() => {
    if (head) {
      setNextBlock(currentBlock, head);
    }
  }, [head]);

  const openModal = e => {
    e.preventDefault();
    e.target.blur();
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderLeftSection = () => {
    switch (routeState) {
      case RouteState.INITIAL:
        return (
          <EvaluationBox
            appointmentId={appointmentId}
            routeState={routeState}
            onBack={() => {
              if (currentEvaluation) {
                setRouteState(RouteState.IN_PROGRESS);
              }
            }}
            onSelectEvaluation={evaluation => {
              if (evaluation.status !== 'COMPLETED') {
                evaluationSelected(evaluation);
              }
            }}
            showAddEvaluation={showAddEvaluation}
            setShowAddEvaluation={setShowAddEvaluation}
            updateEvaluationProgress={updateEvaluationProgress}
            setToastProperties={setToastProperties}
          />
        );
      case RouteState.IN_PROGRESS:
        return (
          <InProgressEvaluation
            listHelper={list}
            currentBlock={currentBlock}
            currentEvaluation={currentEvaluation}
            showAllEvaluations={() => {
              setCurrentEvaluation(null);
              setRouteState(RouteState.INITIAL);
            }}
            activeBox={leftSectionExpanded}
            setActiveBox={setLeftSectionExpanded}
            setCurrentBlock={setCurrentBlock}
          />
        );
      default:
        return null;
    }
  };
  const submitAnswer = async () => {
    if (currentBlock && currentBlock.userResponse) {
      const { userResponse } = currentBlock;
      let evaluationResponses = userResponse?.userResponseList;

      if (evaluationResponses && evaluationResponses.length === 1 && evaluationResponses[0] === '')
        evaluationResponses = [];

      const payload = {
        contentBlockId: currentBlock.cbId,
        dctId: currentBlock.dctId,
        evaluationResponses,
        providerNarrative: userResponse?.providerNarrative || '',
      };
      try {
        const { data } = await updateEvaluationContext(payload, {
          appointmentId,
          evaluationId: currentEvaluation.id,
        });
        if (data.errors) {
          console.warn(data.errors);
        } else {
          list.transformEvaluation(data);
          dispatch(
            conversationActionCreators.evaluationContextUpdated({
              evaluationContext: data,
              linkedEvaluationNodes: list.getHead(),
            }),
          );
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const updateEvaluationStatus = async () => {
    const payload = {
      evaluationUpdatedStatus: 'COMPLETED',
      evaluationId: currentEvaluation.id,
      remarks: 'Evaluation completed',
    };
    const { data } = await updateAppointmentEvaluationStatus(payload, {
      appointmentId,
    });
    if (data.errors) {
      console.warn(data.errors);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: data.errors?.[0]?.endUserMessage || 'Something went wrong',
        }),
      );
    }
  };

  const respondAndGoNext = () => {
    setNextInProgress(true);
    void submitAnswer();
    // dispatch(
    //   conversationActionCreators.fetchEvaluationContext({
    //     appointmentId,
    //     evaluationId: currentEvaluation.id,
    //   }),
    // );
  };

  const nextQuestion = e => {
    e.preventDefault();
    e.target.blur();
    if (currentBlock) {
      if (currentBlock.cbType !== 'dct' && currentBlock.isAttemptable) {
        if (currentBlock.userResponse) {
          respondAndGoNext();
        } else {
          setNextBlock(currentBlock, head, true);
        }
      } else if (currentBlock.next) {
        setCurrentBlock(currentBlock.next);
      } else {
        completeEvaluation();
      }
    }
  };

  const handleKeyboardShortcuts = e => {
    if (isModalOpen === true || routeState !== RouteState.IN_PROGRESS || textInputInFocus) {
      return;
    }
    if (e.key === ' ' && !e.shiftKey) {
      nextQuestion(e);
    }

    if (e.key === ' ' && e.shiftKey) {
      setPreviousBlock(currentBlock);
    }

    if (e.key === 'Backspace' && e.shiftKey) {
      setLeftSectionExpanded(!leftSectionExpanded);
    }

    if (e.key === 'Enter' && e.shiftKey) {
      setRightSectionExpanded(!rightSectionExpanded);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setRightSectionExpanded(true);
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setRightSectionExpanded(true);
      if (selectedIndex < currentBlock.choices.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    }

    if (e.key === 'ArrowRight') {
      if (selectedIndex > -1 || (currentBlock && currentBlock.cbType === 'rating-scale')) {
        setRightTrigger(prev => !prev);
      }
    }

    if (e.key === 'ArrowLeft') {
      if (selectedIndex > -1 || (currentBlock && currentBlock.cbType === 'rating-scale')) {
        setLeftTrigger(prev => !prev);
      }
    }

    if (e.key === 'K' && e.shiftKey) {
      openModal(e);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcuts);
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts);
    };
  }, [
    isModalOpen,
    currentBlock,
    leftSectionExpanded,
    rightSectionExpanded,
    routeState,
    textInputInFocus,
    selectedIndex,
    leftTrigger,
    rightTrigger,
  ]);
  useEffect(() => {
    if (bodyRef) {
      if (bodyRef.current) {
        bodyRef.current.scrollTop = 0;
      }
    }
    setSelectedIndex(-1);
  }, [currentBlock]);

  useEffect(() => {
    if (currentBlock && currentBlock.cbType === 'education') {
      if (currentBlock?.educationContentSlug) {
        void fetchEducationalContent(currentBlock?.educationContentSlug).then(content => {
          setEducationContent(content);
        });
      }
    }
  }, [currentBlock]);

  const handleKeyDown = event => {
    const body = bodyRef?.current;
    if (body) {
      switch (event.key) {
        case 'ArrowUp':
          body.scrollTop -= 10;
          break;
        case 'ArrowDown':
          body.scrollTop += 10;
          break;
        // Add more cases for other keys if needed
        default:
          break;
      }
    }
  };

  const handleScroll = () => {
    const eRef = bodyRef?.current;
    const scrollPercentage = (eRef.scrollTop / (eRef.scrollHeight - eRef.clientHeight)) * 100;
    if (scrollPercentage >= 80) {
      updateValues({ id: currentBlock.cbId, narrative: '', value: ['true'] });
    }
  };

  useEffect(() => {
    const eRef = bodyRef?.current;
    const isScrollEnabled = eRef?.scrollHeight > eRef?.clientHeight;
    if (eRef && (currentBlock?.cbType === 'exercise' || currentBlock?.cbType === 'education')) {
      eRef.focus();
    }
    if (
      eRef &&
      isScrollEnabled === false &&
      (currentBlock?.cbType === 'exercise' || currentBlock?.cbType === 'education')
    ) {
      updateValues({ id: currentBlock.cbId, narrative: '', value: ['true'] });
    }
    if (
      eRef &&
      isScrollEnabled &&
      (currentBlock?.cbType === 'exercise' || currentBlock?.cbType === 'education')
    ) {
      eRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (
        eRef &&
        isScrollEnabled &&
        (currentBlock?.cbType === 'exercise' || currentBlock?.cbType === 'education')
      ) {
        eRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [bodyRef, currentBlock?.cbType]);
  const getQuestionType = () => {
    if (currentBlock) {
      if (currentBlock.cbType === 'dct') {
        return currentBlock.responderType === 'MEMBER'
          ? 'Questions for the Member'
          : 'Questions for the Provider';
      }
      switch (currentBlock.cbType) {
        case 'single-select':
          return 'SINGLE SELECT';
        case 'multi-select':
          return 'MULTI SELECT';
        case 'text-input':
          return 'TEXT INPUT';
        case 'rating-scale':
          return 'RATING SCALE';
        case 'exercise':
          return 'EXERCISE';
        case 'education':
          return 'EDUCATION';
        default:
          return null;
      }
    }
    return null;
  };

  if (routeState === RouteState.COMPLETED) {
    return (
      <CompletedEvaluation
        appointmentId={appointmentId}
        onSelectEvaluation={evaluation => {
          evaluationSelected(evaluation);
        }}
        updateEvaluationProgress={updateEvaluationProgress}
        onAddEvaluation={() => {
          setCurrentEvaluation(null);
          setShowAddEvaluation(true);
          setRouteState(RouteState.INITIAL);
        }}
      />
    );
  }

  const renderExerciseContent = () => {
    return (
      <>
        <EvaluationExercise selectedSection={currentBlock?.exerciseContent} />
      </>
    );
  };

  const getElements = item => {
    // console.log(item);
    if (item) {
      switch (item.nodeType) {
        case 'paragraph':
          return (
            <>
              {item?.content?.map(itm => (
                <>
                  {itm.nodeType === 'text' && <Text className={classes.paragraph}>{itm?.value}</Text>}
                  {itm.nodeType === 'hyperlink' && (
                    <>
                      {itm?.content?.map((itm2, indx2) => (
                        <>
                          <a className={classes.hyperlinK1} key={indx2} href={itm?.data?.uri}>
                            {itm2?.value}
                          </a>
                        </>
                      ))}
                    </>
                  )}
                </>
              ))}
              <br />
            </>
          );
        case 'unordered-list':
          return (
            <ul className={classes.ulList}>
              {item?.content?.map(itm => (
                <>
                  {itm?.content?.map(itm2 => (
                    <>
                      {itm2.nodeType === 'paragraph' && (
                        <li>
                          {itm2?.content?.map(itm3 => (
                            <>
                              {itm3.nodeType === 'text' && (
                                <Text className={classes.ulList}>{itm3?.value}</Text>
                              )}
                              {itm3.nodeType === 'hyperlink' && (
                                <>
                                  {itm3?.content?.map((itm4, indx4) => (
                                    <a className={classes.hyperlink2} key={indx4} href={itm3?.data?.uri}>
                                      {itm4?.value}
                                    </a>
                                  ))}
                                </>
                              )}
                            </>
                          ))}
                        </li>
                      )}
                    </>
                  ))}
                </>
              ))}
            </ul>
          );
        case 'ordered-list':
          return (
            <ol className={classes.ulList}>
              {item?.content?.map(itm => (
                <>
                  {itm?.content?.map(itm2 => (
                    <>
                      {itm2.nodeType === 'paragraph' && (
                        <li>
                          {itm2?.content?.map(itm3 => (
                            <>
                              {itm3.nodeType === 'text' && (
                                <Text className={classes.ulList}>{itm3?.value}</Text>
                              )}
                              {itm3.nodeType === 'hyperlink' && (
                                <>
                                  {itm3?.content?.map((itm4, indx4) => (
                                    <a className={classes.hyperlink2} key={indx4} href={itm3?.data?.uri}>
                                      {itm4?.value}
                                    </a>
                                  ))}
                                </>
                              )}
                            </>
                          ))}
                        </li>
                      )}
                    </>
                  ))}
                </>
              ))}
            </ol>
          );
        case 'heading-1':
          return (
            <>
              {item?.content?.map(itm => (
                <>
                  <br />
                  <Text className={classes.heading1}>{itm.value}</Text>
                </>
              ))}
            </>
          );
        case 'heading-2':
          return (
            <>
              {item?.content?.map(itm => (
                <>
                  <br />
                  <Text className={classes.heading2}>{itm.value}</Text>
                  <br />
                  <br />
                </>
              ))}
            </>
          );
        case 'heading-3':
          return (
            <>
              {item?.content?.map(itm => (
                <>
                  <br />
                  <Text className={classes.heading3}>{itm.value}</Text>
                  <br />
                  <br />
                </>
              ))}
            </>
          );
        case 'heading-4':
          return (
            <>
              {item?.content?.map(itm => (
                <>
                  <br />
                  <Text className={classes.heading3}>{itm.value}</Text>
                  <br />
                  <br />
                </>
              ))}
            </>
          );
        default:
          return null;
      }
    }
    return null;
  };

  const renderEducationContent = () => {
    return (
      <>
        {educationContent?.content?.content?.map((item, index) => (
          <React.Fragment key={index}>{getElements(item)}</React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Box className={classes.rootBox}>
      <Box className={classes.evaluationRootBox}>{renderLeftSection()}</Box>
      {currentEvaluation && currentBlock && (
        <Box className={classes.inPersonRootBox}>
          <Box
            className={`${classes.parentQuestionBox} ${
              currentBlock.cbType === 'exercise' || currentBlock.cbType === 'education'
                ? classes.isExercise
                : ''
            }`}
            ref={bodyRef}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            {currentBlock.cbType !== 'exercise' && currentBlock.cbType !== 'education' ? (
              <>
                <Text className={classes.questionType}>{getQuestionType()}</Text>
                <Heading className={classes.question}>{currentBlock?.text}</Heading>
              </>
            ) : (
              <Box className={classes.headerContainer}>
                <Text className={classes.questionType}>{getQuestionType()}</Text>
                <Typography className={classes.question2}>{currentBlock?.text}</Typography>
              </Box>
            )}
            {(currentBlock.cbType === 'exercise' || currentBlock.cbType === 'education') && (
              <Box className={classes.exContainer}>
                <Divider className={classes.divider} />
                <Box className={classes.container2}>
                  {currentBlock.cbType === 'education' ? renderEducationContent() : renderExerciseContent()}
                  <br />
                  <br />
                </Box>
              </Box>
            )}
            {currentBlock.cbType !== 'exercise' && currentBlock.cbType !== 'education' && (
              <Box className={classes.nxtBtnAndTxtBox}>
                <Text className={classes.BtnLabel}>Next question</Text>
                <Button className={classes.spaceBarBtn} onClick={nextQuestion}>
                  spacebar
                </Button>
              </Box>
            )}
          </Box>
          {currentBlock.cbType !== 'exercise' && currentBlock.cbType !== 'education' && (
            <Box className={classes.shortcutBtnBox}>
              <Button variant={btnType.TEXT} className={classes.shortcutsLink} onClick={openModal}>
                Keyboard shortcuts (shift +K)
              </Button>
            </Box>
          )}
          {(currentBlock.cbType === 'exercise' || currentBlock.cbType === 'education') && (
            <>
              <Box className={classes.bottomOpacity2} />
              <Box className={classes.shortcutContainer2}>
                <Box className={classes.btnContainer}>
                  <Button variant={btnType.TEXT} className={classes.shortcutsLink} onClick={openModal}>
                    Keyboard shortcuts (shift +K)
                  </Button>
                </Box>
                <Box className={classes.nxtBtnAndTxtBox2}>
                  <Text className={classes.BtnLabel}>Next question</Text>
                  <Button className={classes.spaceBarBtn} onClick={nextQuestion}>
                    spacebar
                  </Button>
                </Box>
              </Box>
            </>
          )}
          <KeyboardShortcutsModal open={isModalOpen} onClose={closeModal} showHideKeys />
        </Box>
      )}
      {currentEvaluation &&
        currentBlock &&
        currentBlock.cbType !== 'dct' &&
        currentBlock?.cbType !== 'exercise' &&
        currentBlock.cbType !== 'education' && (
          <Box className={classes.rightSectionRootBox}>
            <RightSection
              currentBlock={currentBlock}
              activeBox={rightSectionExpanded}
              setActiveBox={setRightSectionExpanded}
              setInputFocused={setTextInputInFocus}
              selectedIndex={selectedIndex}
              rightTrigger={rightTrigger}
              leftTrigger={leftTrigger}
            />
          </Box>
        )}
    </Box>
  );
};

export { InPersonSession };
