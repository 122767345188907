import { colors } from '@confidant-health/lib/colors';
import { Box } from '@mui/material';

import { useEffect, useMemo, useState } from 'react';
import AverageFrame from '../components/frame/AverageFrame';

type Props = {
  memberId?: string;
  isWaiting?: boolean;
};

const RenderEvaluations = ({ isWaiting, memberId }: Props) => {
  const [isPhq9, setIsPhq9] = useState(false);
  const [isGad7, setIsGad7] = useState(false);
  const [isHospitalization, setIsHospitalization] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState({
    phq9: false,
    gad7: false,
    hospitalization: false,
  });
  const [avgScores] = useState([
    {
      title: 'PHQ-9',
      drawerTitle: 'Current PHQ-9 scores',
      count: 0,
      point: 0,
      initialScore: 0,
      color: colors.warning600,
      type: 'phq9',
    },
    {
      title: 'GAD-7',
      drawerTitle: 'Current GAD 7 scores',
      count: 0,
      point: 0,
      initialScore: 0,
      color: colors.warning600,
      type: 'gad7',
    },
    {
      title: 'Hospitalizations',
      drawerTitle: 'Current GAD 7 scores',
      count: 0,
      point: 0,
      initialScore: 0,
      color: colors.neutral700,
      type: 'hospitalizations',
    },
  ]);
  // console.log(isDataAvailable);
  useEffect(() => {
    setIsDataAvailable({
      phq9: isPhq9,
      gad7: isGad7,
      hospitalization: isHospitalization,
    });
  }, [isPhq9, isGad7, isHospitalization]);
  const renderEvaluation = useMemo(() => {
    if (isWaiting === false) {
      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <AverageFrame
            avgScores={avgScores[0]}
            setData={item => setIsPhq9(item > 0)}
            isDataAvailable={isDataAvailable}
            onClick={() => console.log('')}
            memberId={memberId}
          />
          <AverageFrame
            avgScores={avgScores[1]}
            setData={item => setIsGad7(item > 0)}
            isDataAvailable={isDataAvailable}
            onClick={() => console.log('')}
            memberId={memberId}
          />
          <AverageFrame
            avgScores={avgScores[2]}
            setData={item => setIsHospitalization(item)}
            isDataAvailable={isDataAvailable}
            onClick={() => console.log('')}
            memberId={memberId}
          />
        </Box>
      );
    }
    return (
      <Box display="flex" flexDirection="row" gap={2}>
        <AverageFrame
          avgScores={avgScores[0]}
          setData={item => setIsPhq9(item > 0)}
          isDataAvailable={isDataAvailable}
          onClick={() => console.log('')}
          memberId={memberId}
        />
        <AverageFrame
          avgScores={avgScores[1]}
          setData={item => setIsGad7(item > 0)}
          isDataAvailable={isDataAvailable}
          onClick={() => console.log('')}
          memberId={memberId}
        />
        <AverageFrame
          avgScores={avgScores[2]}
          setData={item => setIsHospitalization(item)}
          isDataAvailable={isDataAvailable}
          onClick={() => console.log('')}
          memberId={memberId}
        />
      </Box>
    );
  }, [avgScores, isWaiting, isDataAvailable]);

  return <>{renderEvaluation}</>;
};

export default RenderEvaluations;
