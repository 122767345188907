import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    height: 'calc(100vh - 288px)',
    overflowY: 'auto',
  },
});
