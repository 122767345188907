/* eslint no-underscore-dangle: 0 */
import { FC, useMemo, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// components
import { Stack, Box } from '@mui/material';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { BaseLayout } from 'layouts/base';
import { reportingActionCreators } from 'redux/modules/reporting';
import { selectReport } from 'redux/modules/reporting/selectors';
import { IReport } from 'redux/modules/reporting/types';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';

import AddReport from './add-report';
import { tableColumns } from './ReportList.mock';
import { useStyles } from './ReportList.styles';
import { DeleteReport } from './delete-report/DeleteReport';

const ReportList: FC = () => {
  const [showNewReport, setShowAddReport] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IReport>(null);
  const { totalRecords, isLoading, isSaving, reportList } = useSelector(selectReport);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [value, setValue] = useState<tableParamsType>({
    search: {
      searchKey: '',
    },
    pagination: {
      currentPage: 1,
      rowsPerPage: 10,
    },
    sorter: {
      direction: 'desc',
      column: '',
    },
  });
  const classes = useStyles();
  const toggleDeleteReport = () => {
    setShowDeleteReport(s => !s);
  };
  const toggleAddNewReport = () => {
    if (showNewReport) {
      setSelectedItem(null);
    }
    setShowAddReport(!showNewReport);
  };

  const handleFetchReportsParamsFormat = ({ search, pagination, sorter }: tableParamsType) => {
    const sortBy = sorter.column === 'createdBy' ? 'Date Created' : sorter.column || 'Date Created';
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction.toUpperCase(),
      sortBy,
    };

    return params;
  };

  useEffect(() => {
    dispatch(reportingActionCreators.fetchReportList(handleFetchReportsParamsFormat(value)));
  }, [value]);

  useEffect(() => {
    if (showNewReport && !isSaving) {
      dispatch(reportingActionCreators.fetchReportList(handleFetchReportsParamsFormat(value)));
    }
  }, [isSaving]);

  const onAddItem = () => {
    setSelectedItem(null);
    setShowAddReport(true);
  };

  const onEditItem = (id: string) => () => {
    setSelectedItem(reportList.find(item => item._id === id));
    setShowAddReport(true);
  };

  const onViewItem = (id: string) => () => {
    navigate(`/admin/reports/${id}`);
  };

  const onDeleteItem = (id?: string) => {
    dispatch(reportingActionCreators.deleteReport(id || selectedItem._id));
    toggleDeleteReport();
  };
  const tableData = useMemo(
    () =>
      reportList.map(report => ({
        ...report,
        reportName: { reportName: report.reportName, _id: report._id },
        actions: { id: report._id },
      })),
    [reportList],
  );

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'reportName') {
      return {
        ...column,
        renderCell: ({ reportName }) => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {reportName}
          </Text>
        ),
      };
    }
    if (column.id === 'createdBy') {
      return {
        ...column,
        renderCell: ({ fullName, firstName, lastName, profileImage }) => (
          <Box display="flex" gap={1.5} alignItems="center">
            <Avatar src={profileImage} variant={avatarType.CIRCLE} name={fullName || 'S'} size={40} />
            <Box>
              <Text className={classes.name} weight={fontWeight.SEMI_BOLD}>
                {fullName ?? `${firstName} ${lastName}`}
              </Text>
              {/* <Badge className={clsx(classes.badge, firstName)} variant={badgeType.FILLED}>
                {`${firstName} ${lastName}`}
              </Badge> */}
            </Box>
          </Box>
        ),
      };
    }
    if (column.id === 'createdAt') {
      return {
        ...column,
        renderCell: (val: string) => dayjs(val).format(GLOBAL_DATE_FORMAT),
      };
    }

    if (column.id === 'updatedAt') {
      return {
        ...column,
        renderCell: (val: string) => dayjs(val).format(GLOBAL_DATE_FORMAT),
      };
    }

    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              { label: 'Edit Report', onClick: onEditItem(id) },
              { label: 'View Report', onClick: onViewItem(id) },
              {
                label: 'Delete Report',
                onClick: () => {
                  setSelectedItem(reportList.find(item => item._id === id));
                  toggleDeleteReport();
                },
              },
            ]}
          />
        ),
      };
    }
    return column;
  });

  return (
    <>
      <DeleteReport
        onClose={toggleDeleteReport}
        onDeleteReport={() => onDeleteItem(selectedItem._id)}
        open={showDeleteReport}
        selectedReport={selectedItem}
      />
      <AddReport report={selectedItem} isOpen={showNewReport} onClose={toggleAddNewReport} />
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Reports
              </Heading>
              <Badge
                className={classes.totalMemberBadge}
                variant={badgeType.OUTLINED}
                style={badgeStyle.UNRELATED}
              >
                {totalRecords} total
              </Badge>
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onAddItem}
            >
              Add report
            </IconButton>
          </Stack>
          <Table
            searchProps={{
              placeholder: 'Search reports by name',
            }}
            gridProps={{
              columns: renderColumns,
              data: tableData,
              isLoading,
              onRowClick: item => navigate(`/admin/reports/${item._id}`),
            }}
            paginationProps={{
              currentRows: tableData.length,
              totalCount: totalRecords,
            }}
            value={value}
            onChange={setValue}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { ReportList };
