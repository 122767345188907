import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  badge: {
    borderRadius: 99,
    whiteSpace: 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px',
    color: colors.neutral700,
  },
  addBtn: {
    color: '#fff',
    fontSize: 16,
    whiteSpace: 'nowrap',
    height: 64,
    padding: '20px 24px',
    '& svg': {
      width: 24,
      '& path': {
        fill: '#fff',
      },
    },
  },
  tableBox: {
    marginTop: '32px',
  },
  table: {
    borderRadius: '8px',
    backgroundColor: 'white',
    '& .MuiTableCell-body': {
      padding: '24px 16px 24px 24px !important',
    },
    '& .MuiTableCell-head:nth-child(1)': {
      borderTopLeftRadius: '8px',
    },
    '& .MuiTableCell-head:nth-child(2)': {
      borderTopRightRadius: '8px',
    },
  },
  menu: {
    float: 'right',
    right: 20,
    '& svg': {
      width: 16,
    },
  },
  menuItemsWrapper: {
    '& ul': {
      padding: 0,
    },
    '& ul li': {
      padding: '10px 16px',
      // '&:last-child': {
      //   color: colors.destructive500,
      //   '& svg path': {
      //     fill: colors.destructive500,
      //   },
      // },
    },
    '& .menu-item-custom': {
      borderBottom: `1px solid ${colors.neutral100}`,
    },
  },
  icon: {
    marginRight: 12,
    width: 16,
  },
});
