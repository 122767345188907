import React from 'react';
import clsx from 'clsx';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';

// types
import { ActivityDataT } from '../../MemberDetail.types';

// styles
import { useStyles } from '../../MemberDetail.styles';

const ActivityCard: React.FC<ActivityDataT> = props => {
  const { title, subTitle, icon, date, color, isLastItem } = props;

  // public enum ActivityType {
  //   LOGIN("LOGIN"),
  //   CONVERSATION("CONVERSATION"),
  //   CONTENT("CONTENT"),
  //   DCT("DCT"),
  //   APPOINTMENT("APPOINTMENT"),
  //   CONNECTION("CONNECTION"),
  //   GROUP("GROUP");

  //   public enum ActivityActionType {
  //     LOGIN("LOGIN"),
  //     CONTENT_BOOKMARKED("CONTENT_BOOKMARKED"),
  //     CONTENT_REMOVED_BOOKMARKED("CONTENT_REMOVED_BOOKMARKED"),
  //     CONTENT_MARKED_AS_COMPLETE("CONTENT_MARKED_AS_COMPLETE"),
  //     CONVERSATION_ASSIGNED("CONVERSATION_ASSIGNED"), ok
  //     CONVERSATION_COMPLETED("CONVERSATION_COMPLETED"), ok
  //     DCT_STARTED("DCT_STARTED"),
  //     DCT_COMPLETED("DCT_COMPLETED"),
  //     APPOINTMENT_SCHEDULED("APPOINTMENT_SCHEDULED"), ok
  //     APPOINTMENT_COMPLETED("APPOINTMENT_COMPLETED"), ok
  //     APPOINTMENT_MARK_FOR_SIGNOFF("APPOINTMENT_MARK_FOR_SIGNOFF"),
  //     CONNECTED("CONNECTED"), ok
  //     DISCONNECTED("DISCONNECTED"), ok
  //     JOINED_GROUP("JOINED_GROUP"),
  //     LEFT_GROUP("LEFT_GROUP"),
  //     JOINED_GROUP_SESSION("JOINED_GROUP_SESSION");

  // not in enum
  // education viewed maybe  == CONTENT_BOOKMARKED("CONTENT_BOOKMARKED"),
  // education marked as read == CONTENT_MARKED_AS_COMPLETE("CONTENT_MARKED_AS_COMPLETE"),
  // Education added to favorites = CONTENT_BOOKMARKED

  // have laout
  // APPOINTMENT_SCHEDULED("APPOINTMENT_SCHEDULED"),
  // APPOINTMENT_COMPLETED("APPOINTMENT_COMPLETED"),
  // CONVERSATION_ASSIGNED("CONVERSATION_ASSIGNED"),
  // CONVERSATION_COMPLETED("CONVERSATION_COMPLETED"),
  // Connected with a provider == CONNECTED("CONNECTED")
  // Disconnected from provider == DISCONNECTED("DISCONNECTED")

  // Joined a group == JOINED_GROUP("JOINED_GROUP"),
  // Left a group == LEFT_GROUP("LEFT_GROUP")
  // Joined a group session == JOINED_GROUP_SESSION("JOINED_GROUP_SESSION");

  const classes = useStyles({ activityIconColor: color });
  return (
    <div className={clsx(classes.activityCard, { [classes.activityCardLast]: isLastItem })}>
      <div className={classes.activityCardLeftSide}>
        <div className={classes.activityIconWrapper}>
          <Icons color={colors[color ? `${color}500` : 'primary500']} glyph={icon} />
        </div>
        <div>
          <Heading className={classes.activityCardTitle} weight={fontWeight.SEMI_BOLD} level={headingLevel.S}>
            {title}
          </Heading>
          <Text className={classes.activityCardSubTitle} weight={fontWeight.MEDIUM} level={textLevel.S}>
            {subTitle}
          </Text>
        </div>
      </div>
      <Text className={classes.activityCardDate} weight={fontWeight.MEDIUM} level={textLevel.S}>
        {date}
      </Text>
    </div>
  );
};

export default ActivityCard;
