import { AxiosResponse } from 'axios';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { baseRequest } from 'utils/HttpClient';

type FilteredAppointmentMetricsPayload = {
  days?: number;
  providerId?: string;
  status?: string;
};

type appointmentMetricsPayload = {
  providerId?: string;
};

export const getAppointmentsMetrics = (queryParams: appointmentMetricsPayload) => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENTS_METRICS, null, null, queryParams);
};

export const getAppointmentsMetricsV2 = (requestBody: any) => {
  return baseRequest(ApiEndpoints.GET_ELK_APPOINTMENT_REPORT, requestBody, null, null, false, false);
};

export const getFilteredAppointmentsMetrics = (
  bodyRequest: FilteredAppointmentMetricsPayload,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_FILTERED_APPOINTMENTS_METRICS, bodyRequest, null);
};
