import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';

import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';

import { reportingActionCreators } from 'redux/modules/reporting';
import { selectClaim, selectProviderFee } from 'redux/modules/reporting/selectors';
import { showSnackbar } from 'redux/modules/snackbar';
import { stateActionCreators } from 'redux/modules/state/actions';
import { selectStatePayer, selectStatePayers } from 'redux/modules/state/selectors';
import { sentenceCase } from 'sentence-case';
import { BaseLayout } from 'layouts/base';
import { useStyles } from './ClaimDetail.styles';
import Submitter from './components/Submitter';
import Payer from './components/Payer';
import Subscriber from './components/Subscriber';
import Patient from './components/Patient';
import Provider from './components/Provider';
import Claim from './components/Claim';
import StatusDetail from './components/StatusDetail';
import ProviderFee from './components/ProviderFee';
import CreateInvoice from './components/CreateInvoice';
import CandidDetail from './components/Candid';

const ClaimDetail: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const state = location?.state as { appointmentId: string };
  const dispatch = useDispatch();
  const { claimId } = useParams();

  useEffect(() => {
    dispatch(stateActionCreators.fetchPayers('unique'));
    dispatch(reportingActionCreators.fetchClaimById({ id: claimId }));
    dispatch(reportingActionCreators.fetchProviderFeeByAppointmentId({ id: state?.appointmentId }));
    dispatch(stateActionCreators.fetchCPTs());
  }, []);

  const { isLoading, isSaving, errorMsg, data } = useSelector(selectClaim);
  const { providerFeeData } = useSelector(selectProviderFee);
  const [currentProviderFeeDtata, setCurrentProviderFeeDtata] = useState(providerFeeData);
  const payers = useSelector(selectStatePayers);
  const payer = useSelector(selectStatePayer);

  const [openInvoice, setOpenInvoice] = useState(false);
  useEffect(() => {
    setCurrentProviderFeeDtata(providerFeeData);
  }, [providerFeeData]);
  useEffect(() => {
    if (data?.payerRef) {
      dispatch(stateActionCreators.fetchPayerDetail(data?.payerRef));
    }
  }, [data?.payerRef]);

  const onSubmit = useCallback((formData, type) => {
    if (type === 'Provider_Fee') {
      dispatch(
        reportingActionCreators.updateProviderFeeByProviderId({
          formData: {
            cptList: currentProviderFeeDtata?.cptList,
            insuranceDetail: {
              isActive: formData?.isActive,
              payer: formData?.payer,
              plan: formData?.plan,
            },
            amount: formData?.amount,
          },
          providerId: currentProviderFeeDtata?._id,
        }),
      );
      dispatch(reportingActionCreators.fetchProviderFeeByAppointmentId({ id: state?.appointmentId }));
    } else {
      dispatch(
        reportingActionCreators.updateClaimDetail({
          type,
          formData: {
            [type]: { ...data[type], ...formData },
            billingProviderRef: data?.billingProviderRef,
            renderingProviderRef: data?.renderingProviderRef,
            submitterRef: data?.submitterRef,
            payerRef: data?.payerRef,
            patientRef: data?.patientRef,
          },
          claimId,
        }),
      );
    }
    setTimeout(() => {
      if (errorMsg) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: errorMsg || 'Something went wrong!',
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Record updated successfully',
          }),
        );
      }
    }, 1000);
  }, []);
  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Breadcrumbs links={[{ href: '/admin/claims', text: 'Claims' }, { text: data?.name || 'N/A' }]} />
        </Box>
        {isLoading || isSaving ? (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        ) : (
          <>
            <Box display="flex" flexDirection="column">
              <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 5 }}>
                <Stack direction="column">
                  <Stack direction="row" alignItems="center" gap={2} sx={{ height: 48 }}>
                    <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                      {data?.name || 'N/A'}
                    </Heading>
                    <Badge
                      variant={badgeType.OUTLINED}
                      style={
                        data?.status === 'PAID' ||
                        data?.status === 'PARTIALLY_PAID' ||
                        data?.status === 'OVERPAID' ||
                        data?.status === 'UNDERPAID' ||
                        data?.status === 'APPEALED'
                          ? badgeStyle.RESOLVED
                          : data?.status === 'CREATED' || data?.status === 'COMPLETED'
                          ? badgeStyle.PRIMARY
                          : data?.status === 'DENIED' || data?.status === 'REJECTED'
                          ? badgeStyle.HIGH
                          : badgeStyle.MEDIUM
                      }
                      className={clsx(
                        classes.badge,
                        { [classes.unPaid]: data?.status === 'DENIED' || data?.status === 'REJECTED' },
                        {
                          [classes.submittedStatus]:
                            data?.status === 'CREATED' || data?.status === 'COMPLETED',
                        },
                        {
                          [classes.paid]:
                            data?.status === 'PAID' ||
                            data?.status === 'PARTIALLY_PAID' ||
                            data?.status === 'OVERPAID' ||
                            data?.status === 'UNDERPAID' ||
                            data?.status === 'APPEALED',
                        },
                      )}
                    >
                      {data?.status !== undefined ? sentenceCase(data?.status || '') : 'N/A'}
                    </Badge>
                  </Stack>
                  <Stack direction="row">
                    <Heading
                      className={classes.subHeading}
                      level={headingLevel.S}
                      weight={fontWeight.REGULAR}
                    >
                      {dayjs(data?.updatedAt || data?.createdAt).format('MM/DD/YYYY, hh:mm a')}
                    </Heading>
                  </Stack>
                </Stack>
                <Button variant={btnType.PRIMARY} onClick={() => setOpenInvoice(true)}>
                  Create Invoice
                </Button>
              </Stack>
            </Box>
            <Box>
              <StatusDetail
                data={{
                  ...data?.statusDetail,
                  amountDenied: data?.statusDetail?.amountDenied || 0,
                  trackingNumber: data?.statusDetail?.trackingNumber || '',
                  notes: data?.statusDetail?.notes || '',
                  initialBillingDate: data?.statusDetail?.initialBillingDate || '',
                  profileImage: data?.patient?.profileImage,
                  fullName: data?.patient?.fullName || 'N/A',
                  firstName: data?.patient?.firstName || 'N/A',
                  lastName: data?.patient?.lastName || 'N/A',
                  memberId: data?.patient?.userAccountId,
                  uuid: data?.patient?.uuid,
                }}
                onSubmit={onSubmit}
              />
              <CandidDetail data={data?.candidClaimMetadata} sectionNumber={1} />
              <CandidDetail data={data?.candidClaimMetadata} sectionNumber={2} />
              <CandidDetail data={data?.candidClaimMetadata} sectionNumber={3} />
              <Claim data={data?.claim} patientId={data?.patient?.userAccountId} onSubmit={onSubmit} />
              <Subscriber
                data={{ ...data?.subscriber, userAccountId: data?.patient?.userAccountId }}
                onSubmit={onSubmit}
              />
              {data?.dependent && Object.values(data?.dependent)?.filter(val => val)?.length > 0 && (
                <Subscriber data={data?.dependent} type onSubmit={onSubmit} />
              )}
              <Patient data={data?.patient} />
              {currentProviderFeeDtata && (
                <ProviderFee
                  data={currentProviderFeeDtata}
                  payers={payers}
                  payer={payer}
                  onSubmit={onSubmit}
                />
              )}
              <Provider data={data?.renderingProvider} />
              <Provider data={data?.billingProvider} type onSubmit={onSubmit} />
              <Submitter data={data?.submitter} onSubmit={onSubmit} />
              <Payer
                data={{ ...data?.payer, stateName: data?.stateName }}
                onSubmit={onSubmit}
                payers={payers}
              />
            </Box>
          </>
        )}
        {openInvoice && (
          <CreateInvoice payerObj={payer} open={openInvoice} onClose={() => setOpenInvoice(false)} />
        )}
      </Box>
    </BaseLayout>
  );
};

export { ClaimDetail };
