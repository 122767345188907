import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { Heading } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import { Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { IProfileElement } from 'redux/modules/conversation/types';

import { useStyles } from './ProfileAssignmentTableCard.styles';
import {
  mapChatBotResponseToTableObject,
  getProfileElementAssignmentChatBotListQuery,
  tableColumns,
  getProfileElementAssignmentEvaluationListQuery,
} from './ProfileElementAssignmen.constants';
import {
  getElkProfileElementAssignmentChatBotList,
  getElkProfileElementAssignmentEvaluationList,
} from '../../../../../services/profile/profile.service';
import {
  IProfileElementAssignment,
  IProfileElementAssignmentQueryParams,
} from '../../ProfileElementDetail.types';

type Props = {
  profile: IProfileElement;
  disableExport: boolean;
};
const ProfileAssignmentTableCard: FC<Props> = ({ profile, disableExport = false }) => {
  const classes = useStyles();
  const [assignmentList, setAssignmentList] = useState<IProfileElementAssignment[]>([]);
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: { searchKey: '', filter: null },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const [listTotalCount, setListTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getQueryParams = () => {
    const queryParams: IProfileElementAssignmentQueryParams = {
      size: tableParams.pagination.rowsPerPage - 5,
      from: tableParams.pagination.currentPage - 1,
      profileKey: profile.profileElementInfo.key,
      searchKey: tableParams.search.searchKey,
    };
    return queryParams;
  };
  const fetchProfileElementAssignmentList = async () => {
    setIsLoading(true);
    let combineList: IProfileElementAssignment[] = [];
    const chatBotList = await getElkProfileElementAssignmentChatBotList(
      getProfileElementAssignmentChatBotListQuery(getQueryParams()),
    );
    const evaluationList = await getElkProfileElementAssignmentEvaluationList(
      getProfileElementAssignmentEvaluationListQuery(getQueryParams()),
    );
    combineList = [
      ...mapChatBotResponseToTableObject(evaluationList.data),
      ...mapChatBotResponseToTableObject(chatBotList.data),
    ];
    const listCount =
      Number(chatBotList.data.hits.total.value) + Number(evaluationList.data.hits.total.value);
    setListTotalCount(listCount);
    setIsLoading(false);
    return combineList;
  };

  const renderColumns = tableColumns.map(column => {
    if (column.id === 'member') {
      return {
        ...column,
        renderCell: ({ firstName, lastName, fullName, profileImage, memberId }) => (
          <ProfileInfo
            type="member"
            photo={profileImage}
            nickName={fullName}
            fullName={`${firstName} ${lastName}`}
            memberId={memberId}
          />
        ),
      };
    }
    if (column.id === 'assignMethod') {
      return {
        ...column,
        renderCell: ({ type }) => <span>{type || '-'}</span>,
      };
    }
    if (column.id === 'previousValue') {
      return {
        ...column,
        renderCell: ({ previous, current }) => <span>{previous || current || '-'}</span>,
      };
    }
    if (column.id === 'currentValue') {
      return {
        ...column,
        renderCell: ({ current, previous }) => <span>{current || previous || '-'}</span>,
      };
    }
    if (column.id === 'date') {
      return {
        ...column,
        renderCell: (date: string) => dayjs(date).format('MM/DD/YYYY'),
      };
    }
    return column;
  });
  useEffect(() => {
    void (async () => {
      const list = await fetchProfileElementAssignmentList();

      const formattedList = list.map(item => ({
        ...item,
        previousValue: { previous: item.previousValue.previous, current: item.currentValue.current },
        currentValue: { previous: item.previousValue.previous, current: item.currentValue.current },
      }));

      setAssignmentList(formattedList);
    })();
  }, [profile, tableParams]);

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Profile Element Assignment</Heading>
        <IconButton
          icon="export"
          iconPosition={iconBtnPosition.RIGHT}
          className={classes.exportBtn}
          disabled={disableExport}
        >
          Export
        </IconButton>
      </Box>
      <Box>
        <Table
          searchProps={{
            placeholder: 'Search',
          }}
          gridProps={{
            columns: renderColumns,
            data: assignmentList,
            isLoading,
          }}
          paginationProps={{
            currentRows: tableParams.pagination.currentPage,
            totalCount: listTotalCount,
            showRowsPerPage: false,
          }}
          value={tableParams}
          onChange={(newValues: any) => {
            setTableParams(newValues);
          }}
          className={classes.table}
        />
      </Box>
    </Box>
  );
};

export { ProfileAssignmentTableCard };
