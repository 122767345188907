import { useSelector } from 'react-redux';
import cx from 'clsx';
import { Box } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { getProfile } from 'redux/modules/profile/selectors';
import history from 'utils/history';
import todosImg from 'assets/images/todos.png';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import Heading from 'components/v2/Heading';
import { HeadingLevel } from 'components/v2/Heading/index.types';
import Card from './components/Card';
import CompletedAppointments, { CompletedType } from './CompletedAppointments';
import { useStyles } from './index.styles';

const Todo = () => {
  const classes = useStyles();
  const { todosStats } = useSelector(getProfile);

  const { activeTodoStats } = todosStats || {};

  const viewToDos = () => {
    history.push('/provider/todos');
  };

  if (activeTodoStats?.total === 0) {
    return <CompletedAppointments variant={CompletedType.TODO} />;
  }

  if (activeTodoStats?.total > 0) {
    return (
      <Card
        img={todosImg}
        action={{
          className: cx(classes.outlineBtn, classes.xsmallBtn),
          children: <Typography {...typography.body.s.bold}>Open to-dos</Typography>,
          onClick: viewToDos,
        }}
        extended={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Heading variant={HeadingLevel.H6} letterSpacing="-0.5px">
              {activeTodoStats?.signOffCount} note sign off to-dos
            </Heading>
            <Box>
              <Typography {...typography.body.m.medium} color={colors.neutral600}>
                You also have {activeTodoStats?.chatCount} chat to-dos
              </Typography>
              <Typography {...typography.body.m.medium} color={colors.neutral600}>
                and {activeTodoStats?.notesCount} general note to-dos.
              </Typography>
            </Box>
          </Box>
        }
      />
    );
  }

  return <></>;
};

export default Todo;
