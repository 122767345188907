import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';

import { showSnackbar } from 'redux/modules/snackbar/actions';
import { IService } from 'redux/modules/appointment/types';
import { addNewService, deleteService, updateService } from 'services/member/member.service';
import { scheduleActionCreators } from 'redux/modules/schedule';

import { IServicePayload } from '../profile-card/ProfileCard.types';
import ServiceItem from './service-item';
import AddService from './add-service';
import { useStyles } from './Services.styles';

type IServicesDrawerProps = {
  isOpen: boolean;
  providerId?: string;
  onClose: () => void;
  services: IService[];
};

const EditServices: React.FC<IServicesDrawerProps> = ({ isOpen, providerId, onClose, services }) => {
  const classes = useStyles();
  const [editService, setEditService] = useState<IService | null>(null);
  const [showAddService, setShowAddService] = useState(false);
  const dispatch = useDispatch();

  const onCloseEdit = () => {
    setEditService(null);
    setShowAddService(false);
  };

  const onEditService = (service: IService) => {
    setEditService(service);
  };

  const toggleService = async (service: IService) => {
    try {
      await updateService(service.id, {
        ...service,
        providerId,
        buffer: service?.bufferTime,
        active: !service.serviceAvailable,
      });
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'Service updated successfully',
        }),
      );
      dispatch(scheduleActionCreators.fetchProviderServices(providerId));
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error.data?.errors?.[0]?.endUserMessage || 'Can not update service!',
        }),
      );
    }
  };

  const onDeleteService = async () => {
    try {
      await deleteService(editService.id, providerId);
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'Service deleted successfully',
        }),
      );
      dispatch(scheduleActionCreators.fetchProviderServices(providerId));
      onCloseEdit();
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error.data?.errors?.[0]?.endUserMessage || 'Can not delete service!',
        }),
      );
    }
  };

  const onUpdateService = async (service: IServicePayload) => {
    const serviceMerged = {
      ...editService,
      ...service,
      buffer: service?.bufferTime,
      providerId,
      active: editService.serviceAvailable,
      serviceId: editService.id,
      serviceTypes: service.serviceTypes.map(type => type.value),
      operatingStates: service.stateUsageInAppointment
        ? service.operatingStates?.map(type => type.value) ?? []
        : [],
    };
    try {
      await updateService(editService.id, serviceMerged);
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'Service updated successfully',
        }),
      );
      dispatch(scheduleActionCreators.fetchProviderServices(providerId));
      onCloseEdit();
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error.data?.errors?.[0]?.endUserMessage || 'Can not update service!',
        }),
      );
    }
  };

  const onAddNewService = async (service: IServicePayload) => {
    const payload = {
      buffer: service.bufferTime,
      providerId,
      cost: service.cost,
      description: service.description,
      duration: service.duration,
      marketCost: service.marketCost,
      name: service.name,
      privateService: service.privateService,
      initialService: service.initialService,
      recommendedCost: service.recommendedCost,
      requireSupervisorSignOff: service.requireSupervisorSignOff,
      serviceTypes: service.serviceTypes.map(type => type.value),
      stateLimited: true,
      stateUsageInAppointment: service.stateUsageInAppointment,
      providerFee: service.providerFee,
      cashRate: service.cashRate,
      operatingStates: service.stateUsageInAppointment
        ? service.operatingStates?.map(type => type.value) ?? []
        : [],
    };
    try {
      await addNewService(payload as any);
      dispatch(scheduleActionCreators.fetchProviderServices(providerId));
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'New service added successfully',
        }),
      );
      onCloseEdit();
    } catch (error) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error.data.errors?.[0]?.endUserMessage || 'Can not create service!',
        }),
      );
    }
  };

  const onClickAddService = () => {
    setShowAddService(true);
  };

  return (
    <>
      <Drawer
        title="Edit services"
        open={isOpen}
        onClose={onClose}
        variant={drawerType.FORM}
        submitBtnTitle="Add service"
        onSubmit={onClickAddService}
      >
        <Box className={classes.drawerContent}>
          {services?.map(service => (
            <ServiceItem
              key={service.id}
              service={service}
              isEditable
              onEditService={onEditService}
              toggleService={toggleService}
            />
          ))}
        </Box>
      </Drawer>
      <AddService
        isOpen={!!editService || showAddService}
        onClose={onCloseEdit}
        isEdit={!!editService}
        service={editService}
        onDeleteService={onDeleteService}
        onUpdateService={onUpdateService}
        onAddService={onAddNewService}
      />
    </>
  );
};

export { EditServices };
