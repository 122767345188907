import { Box } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { IGlyphs, Icons } from '@confidant-health/lib/icons';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';

type CardProps = {
  label: string;
  color: string;
  iconColor: string;
  icon: IGlyphs['glyph'];
  count: string;
};

const Card = ({ label, icon, color, iconColor, count }: CardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        background: colors.white,
        boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 10px 20px 0px #0000000A',
        borderRadius: 2,
        p: 2,
      }}
    >
      <Box
        sx={{
          background: color,
          width: 64,
          height: 64,
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& svg': {
            width: 24,
          },
        }}
      >
        <Icons glyph={icon} color={iconColor} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 1,
          '& span': { letterSpacing: '1px' },
          pt: 0.5,
          pb: 0.5,
        }}
      >
        <Text level={textLevel.XS} weight={fontWeight.EXTRA_BOLD} color={colors.neutral600}>
          {label.toUpperCase()}
        </Text>
        <Text size={28} weight={fontWeight.BOLD}>
          {count}
        </Text>
      </Box>
    </Box>
  );
};

export default Card;
