import { FC, useState } from 'react';
import { saveAs } from 'file-saver';
// components
import { Box } from '@mui/material';
import { IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { useReactToPrint } from 'react-to-print';
import { downloadExcelReport } from 'services/reporting/reporting.service';
import { useQuery } from '@confidant-health/lib/hooks';

import ToastMessage from '@confidant-health/lib/ui/templates/toast-message';
import { sentenceCase } from 'sentence-case';
import { useStyles } from './HeadCard.styles';

type Props = {
  title: string;
  onPrintClick?(): void;
  onDownloadClick?(): void;
  componentRef?: any;
};

const HeadCard: FC<Props> = ({ title, onPrintClick, componentRef = null }) => {
  const query = useQuery();
  const reportName = query.get('name');
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page {size: 14in 8.3in; margin: 0;}',
  });
  const classes = useStyles();

  const [downloading, setDownloading] = useState(false);

  const renderBtn = (lable, icon, onClick) => (
    <IconButton
      icon={icon}
      onClick={onClick}
      className={classes.cardTopIconBtn}
      iconPosition={iconBtnPosition.LEFT}
    >
      {lable}
    </IconButton>
  );

  const getReportName = () => {
    switch (reportName) {
      case 'appointments':
        return 'appointment';
      case 'demographics':
        return 'patient';
      case 'profile-elements':
        return 'profileElementReport';
      case 'chatbots':
        return 'chatbot';
      case 'chatbot-dcts':
        return 'dctConversation';
      case 'evaluation-dcts':
        return 'dctEvaluation';
      case 'evaluations':
        return 'chatbotEvaluation';
      case 'logins':
        return 'logins';
      // return 'chatbotEvaluation';
      // return 'userActivityLog';
      case 'symptoms':
      case 'diagnoses':
      case 'medications':
      case 'side-effects':
      case 'substance-use':
      case 'life-events':
      case 'social-determinants':
        return 'dataDomainElementReport';
      default:
        return '';
    }
  };
  const getFilter = () => {
    switch (reportName) {
      case 'appointments':
      case 'demographics':
      case 'profile-elements':
      case 'chatbots':
      case 'chatbot-dcts':
      case 'evaluation-dcts':
      case 'evaluations':
      case 'logins':
        return '';
      case 'symptoms':
        return 'Symptoms';
      case 'diagnoses':
        return 'Diagnoses';
      case 'medications':
        return 'Medications';
      case 'side-effects':
        return 'Side Effects';
      case 'substance-use':
        return 'Substance Use';
      case 'life-events':
        return 'Life Events';
      case 'social-determinants':
        return 'Social Determinants';
      default:
        return reportName;
    }
  };

  return (
    <Box className={classes.cardTop}>
      <Text className={classes.cardTopTitle} weight={fontWeight.EXTRA_BOLD}>
        {title}
      </Text>
      <Box className={classes.dfex}>
        {renderBtn('Print', 'print', e => {
          handlePrint(e);
          onPrintClick && onPrintClick();
        })}

        <ToastMessage
          show={downloading}
          snackType="info"
          snackMessage="Downloading report..."
          vertical="bottom"
          horizontal="right"
          clearSnackbarAction={() => {
            setDownloading(false);
          }}
          duration={50000}
        />

        {renderBtn('Download xls', 'download', () => {
          setDownloading(true);
          downloadExcelReport({ report: getReportName(), filter: getFilter() })
            .then(res => {
              const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel',
              });
              saveAs(blob, `${sentenceCase(reportName || '')} report.xlsx`);
              setTimeout(() => {
                setDownloading(false);
              }, 1000);
            })
            .catch(err => {
              console.warn(err);
              setDownloading(false);
              // dispatch(
              //   showSnackbar({
              //     snackType: 'error',
              //     snackMessage: 'Error downloading!',
              //   }),
              // );
            });
        })}
      </Box>
    </Box>
  );
};

export { HeadCard };
