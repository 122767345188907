import { AppState } from 'redux/store/types';
import { ReportingState } from './types';

export const getContentBlockHistory = (state: AppState): ReportingState => state.reporting;

export const selectReport = (state: AppState) => state.reporting.report;

export const selectReportList = (state: AppState) => state.reporting.report?.reportList ?? [];

export const selectReportSaving = (state: AppState) => state.reporting.report?.isSaving;

export const selectClaimsList = (state: AppState) => state.reporting.claims;

export const selectClaim = (state: AppState) => state.reporting.claim;

export const selectInvoiceList = (state: AppState) => state.reporting.invoices;

export const selectReportDetailTotalMembers = (state: AppState) => state.reporting.reportDetailTotalMembers;

export const selectProviderFee = (state: AppState) => state.reporting.providerFee;

export const selectInvoice = (state: AppState) => state.reporting.invoice;
