// types
import { IConversationAssignments } from 'pages/admin/conversations/Conversations.types';

// components
import AssignmentDetail from './components/assignments';
import CompletionDetail from './components/completions';
import DctList from './components/dct-list';
import ConversationDetail from './components/detail';

type ComponentT = React.FC<{
  selectedScreen: string;
  conversation: IConversationAssignments;
  getConversationDetails: () => void;
  isEvaluation: boolean;
  version: number;
  evaluationId?: string;
}>;

const SelectedComponent: ComponentT = ({
  selectedScreen,
  conversation,
  getConversationDetails,
  isEvaluation,
  version,
  evaluationId,
}) => {
  switch (selectedScreen) {
    case 'assignments':
      return (
        <AssignmentDetail
          conversation={conversation}
          isEvaluation={isEvaluation}
          version={version}
          evaluationId={evaluationId}
        />
      );
    case 'responses-completion':
      return <CompletionDetail />;
    case 'dct':
      return <DctList />;
    default:
      return (
        <ConversationDetail
          conversation={conversation}
          getConversationDetails={getConversationDetails}
          isEvaluation={isEvaluation}
        />
      );
  }
};

export default SelectedComponent;
