import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Icons, IGlyphs } from '@confidant-health/lib/icons';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Text } from '@confidant-health/lib/ui/atoms/typography';

import { useStyles } from './PriorityEmpty.styles';

type Props = {
  title: string;
  description: ReactNode | string;
  addText?: string;
  className?: string;
  icon?: IGlyphs['glyph'];
  onAdd?: () => void;
  glyph?: IGlyphs['glyph'];
  glyphOrientation?: string;
  isEmptyWithLottie?: boolean;
  lottieSize?: number;
};

const PriorityEmpty: FC<Props> = ({
  title,
  description,
  addText,
  className,
  icon = 'plus',
  onAdd,
  glyph = 'alfie-outlined',
  glyphOrientation = 'square',
  isEmptyWithLottie = false,
  lottieSize = 128,
}) => {
  const classes = useStyles({ lottieSize });
  const isLandscape = glyphOrientation === 'landscape';
  const isPortrait = glyphOrientation === 'portrait';
  const isSquare = glyphOrientation === 'square';
  return (
    <Box className={clsx(classes.noContent, className)}>
      <Icons
        glyph={glyph}
        className={clsx({
          [classes.alifie_landscape]: isLandscape,
          [classes.alfie_portrait]: isPortrait,
          [classes.alfie]: isSquare,
          [classes.lottieSize]: isEmptyWithLottie,
        })}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Text className={clsx(classes.title, className)}>{title}</Text>
        <Text className={classes.description}>{description}</Text>
      </Box>
      {onAdd && addText && (
        <IconButton className={classes.addBtn} icon={icon} variant={iconBtnType.PRIMARY} onClick={onAdd}>
          {addText}
        </IconButton>
      )}
    </Box>
  );
};

export { PriorityEmpty };
