export const payersMock = [
  {
    name: 'BCBS',
    active: true,
    planType: true,
    hmo: true,
  },
  {
    name: 'Aetna',
    active: true,
    planType: true,
    hmo: false,
  },
];

export const membersMock = [
  { nickName: 'Koray', fullName: 'Koray Okumus', date: 'Since May 12, 2022' },
  { nickName: 'Natali', fullName: 'Natali Craig', date: 'Since May 12, 2022' },
  { nickName: 'Zahir', fullName: 'Zahir Mays', date: 'Since May 12, 2022' },
  { nickName: 'Nala90', fullName: 'Nala Goins', date: 'Since May 12, 2022' },
];
