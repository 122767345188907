import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { IMedication } from '../AddNewReport.types';
import { useStyles } from './StepThree.styles';

type Props = {
  selectedMedications: IMedication[];
};

const StepThreeMedications: FC<Props> = ({ selectedMedications }) => {
  const classes = useStyles();

  const renderMedicationItem = (item: IMedication, index: number) => {
    return (
      <Box className={classes.item} key={index}>
        <Box className={classes.heading}>
          <Text className={classes.headTitle}>{item.item.label}</Text>
        </Box>
        <Box className={classes.content}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Badge
              className={clsx(classes.badge, {
                [classes.badgeMisreported]: item.status === 'Misreported',
              })}
              variant={badgeType.FILLED}
              style={badgeStyle[item.status.toUpperCase()]}
            >
              {item.status}
            </Badge>
            <Box display="flex" gap="10px" alignItems="center">
              <Icons
                glyph="interference"
                color={item.isInterference ? colors.secondary500 : colors.neutral500}
              />
              <Text
                className={clsx(classes.interference, { [classes.interferenceActive]: item.isInterference })}
              >
                Interference with life
              </Text>
            </Box>
          </Box>
          <Box sx={{ paddingTop: 3 }}>
            <Text className={clsx(classes.time, classes.notes)}>{item.notes}</Text>
          </Box>
        </Box>
      </Box>
    );
  };

  return <Box className={classes.container}>{selectedMedications.map(renderMedicationItem)}</Box>;
};

export default StepThreeMedications;
