import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Icons } from '@confidant-health/lib/icons';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { ProgressBar } from '@confidant-health/lib/ui/atoms/ProgressBar';
import { colors } from '@confidant-health/lib/colors';

import RejectEvaluationModal from '../evaluation-modals';
import {
  evaluationStatusStyleV2,
  EVALUATION_STATUS_V2,
  EVALUATION_STATUS_TYPES,
} from '../Evaluations.constants';
import { useStyles } from './EvaluationCard.styles';
import { IEvaluationItem, IEvaluationUpdateRequest } from '../Evaluations.types';
import dayjs from '../../../../../utils/dayjs';

type Props = {
  evaluation: IEvaluationItem;
  updateEvaluationStatus?: (evaluationUpdateRequest: IEvaluationUpdateRequest) => void;
  onClick: (evaluation: IEvaluationItem) => void;
  showMenu?: boolean;
  setToastProperties?: Dispatch<
    SetStateAction<{
      message: string;
      type: string;
      status: boolean;
    }>
  >;
};

const EvaluationCard: FC<Props> = ({
  evaluation,
  updateEvaluationStatus,
  onClick,
  setToastProperties,
  showMenu,
}) => {
  const classes = useStyles();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);

  /**
   * @Name onRejectClick
   * @description This method is used to open reject evaluation Modal
   */
  const onRejectClick = () => {
    setShowRejectModal(true);
  };

  /**
   * @Name onCloseRejectEvaluation
   * @description This method is used to close reject/end evaluation modal
   */
  const onCloseRejectEvaluation = () => {
    setShowRejectModal(false);
    setShowEndModal(false);
  };

  /**
   * @Name onEndEvaluation
   * @description This method is used to open end evaluation modal
   */
  const onEndEvaluation = () => {
    setShowEndModal(true);
  };

  /**
   * @Name onSelectEvaluationItem
   * @description This method is used to select/click evaluation Item
   */
  const onSelectEvaluationItem = () => {
    onClick(evaluation);
  };

  const isDisabledMoreIcon =
    evaluation?.status === EVALUATION_STATUS_TYPES.COMPLETED ||
    evaluation?.status === EVALUATION_STATUS_TYPES.REJECTED;

  const dateToShow = evaluation?.status === 'COMPLETED' ? evaluation?.completedAt : evaluation?.assignedAt;
  const { totalCb, totalExercises, totalRequiredCb, forProviderCount, riskFactorCount } =
    evaluation.evaluationStats;
  return (
    <>
      <RejectEvaluationModal
        open={showRejectModal || showEndModal}
        isEndEvaluation={showEndModal}
        onClose={onCloseRejectEvaluation}
        evaluation={evaluation}
        updateEvaluationStatus={updateEvaluationStatus}
        setToastProperties={setToastProperties}
      />
      <Box className={classes.card}>
        <Box className={classes.cardContent}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Box display="flex" gap={1.5} alignItems="center">
              <Badge
                variant={badgeType.OUTLINED}
                style={evaluationStatusStyleV2[evaluation?.status]}
                className={classes.badge}
              >
                {EVALUATION_STATUS_V2[evaluation?.status]}
              </Badge>
              {evaluation?.status === EVALUATION_STATUS_TYPES.IN_PROGRESS ? (
                <Box className={classes.dflex}>
                  <ProgressBar value={evaluation?.progress} className={classes.progressBar} />
                  <Text weight={fontWeight.MEDIUM} className={classes.progress}>
                    {evaluation?.progress}%
                  </Text>
                </Box>
              ) : (
                <Box className={classes.text14} color={colors.neutral500}>
                  {dayjs(dateToShow, 'MM-DD-YYYY').format('MM/DD/YYYY')}
                </Box>
              )}
            </Box>
            {showMenu && (
              <Menu
                icon="more"
                className={classes.menu}
                itemsWrapperClassName={classes.menuItemsWrapper}
                disabled={isDisabledMoreIcon}
                items={[
                  {
                    label: 'End evaluation',
                    icon: <Icons glyph="pause-outlined" className={clsx(classes.icon, classes.pauseIcon)} />,
                    onClick: onEndEvaluation,
                  },
                  {
                    label: 'Reject evaluation',
                    icon: <Icons glyph="close-circle-outlined" className={classes.icon} />,
                    onClick: onRejectClick,
                  },
                ]}
              />
            )}
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, cursor: 'pointer' }}
            onClick={onSelectEvaluationItem}
          >
            <Box className={classes.text16}>{evaluation?.name}</Box>
            <Box className={classes.text14} color={colors.neutral600}>
              {evaluation?.description || 'N/A'}
            </Box>
          </Box>
        </Box>
        {evaluation?.status !== EVALUATION_STATUS_TYPES.REJECTED && (
          <Box className={classes.cardFooter}>
            {totalCb > 0 && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box className={classes.text14}>All</Box>
                <Box>
                  <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                    {totalCb}
                  </Badge>
                </Box>
              </Box>
            )}
            {riskFactorCount > 0 && evaluation?.status === 'COMPLETED' && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box color={colors.destructive600} className={classes.text14}>
                  Risk factors
                </Box>
                <Box>
                  <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.CRITICAL}>
                    {riskFactorCount}
                  </Badge>
                </Box>
              </Box>
            )}
            {totalRequiredCb > 0 && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box className={classes.text14}>Required</Box>
                <Box>
                  <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                    {totalRequiredCb}
                  </Badge>
                </Box>
              </Box>
            )}
            {forProviderCount > 0 && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box className={classes.text14}>For provider</Box>
                <Box>
                  <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                    {forProviderCount}
                  </Badge>
                </Box>
              </Box>
            )}
            {totalExercises > 0 && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box className={classes.text14}>Exercises</Box>
                <Box>
                  <Badge className={classes.badge} variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                    {totalExercises}
                  </Badge>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export { EvaluationCard };
