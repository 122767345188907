import Header from 'components/v2/Header';
import { BaseLayout } from 'layouts/base';
import AverageScores from './AverageScores';
import Reviews from './Reviews';
import Payments from './Payments';

import { useStyles } from './index.styles';

const Practice = () => {
  const classes = useStyles();

  return (
    <BaseLayout>
      <div className={classes.root}>
        <Header label="My practice" />
        <div className={classes.body}>
          <AverageScores />
          <Reviews />
          <Payments />
        </div>
      </div>
    </BaseLayout>
  );
};

export default Practice;
