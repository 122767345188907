import { FC } from 'react';
import clsx from 'clsx';
import { Box, Modal } from '@mui/material';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { Button } from '@confidant-health/lib/ui/atoms/button';

import { useStyles } from './PublishConversation.styles';

type Props = {
  open: boolean;
  onPublishConvesation: () => void;
  onClose: () => void;
  type: string;
  automationsCount: number;
  dctCount: number;
};

const PublishConversationModal: FC<Props> = ({
  open,
  onClose,
  onPublishConvesation,
  type,
  automationsCount,
  dctCount,
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box className={classes.modalContent}>
          <Box className={classes.upIconWrap}>
            <Icons glyph="up-circle" className={classes.upIcon} />
          </Box>
          <Box className={classes.content}>
            <Text className={classes.label}>
              You&apos;re about to publish a new version of {type} that will be assignable to all the members
              in the system publicly?
            </Text>
            {automationsCount !== undefined && automationsCount > 0 && (
              <p>
                {automationsCount} automation rules will be updated along with this version automatically.
              </p>
            )}
            {dctCount !== undefined && dctCount > 0 && (
              <p>
                {dctCount} DCT&apos;s in this conversation will be updated to the latest version available.
              </p>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="16px">
              <Button className={clsx(classes.btn, classes.cancelBtn)} onClick={onClose}>
                Review Draft
              </Button>
              <Button onClick={onPublishConvesation} className={clsx(classes.btn, classes.okBtn)}>
                Proceed
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { PublishConversationModal };
