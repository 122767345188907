import React, { useEffect, useState } from 'react';
import { Box, TextField, Chip } from '@mui/material';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { UploadFile } from '@confidant-health/lib/ui/templates/upload-file';
import { stateSelector } from 'redux/modules/state/selectors';

import UploadFileProgress from 'pages/provider/profile/components/upload-flie-progress';
import { EvaluationSchema } from './AddEvaluation.schema';
import { useStyles } from './AddEvaluation.styles';
import { IAddEvaluationProps } from './AddEvaluation.types';
import { IEvaluation } from '../../Evaluations.types';

const AddEvaluation: React.FC<IAddEvaluationProps> = ({ isOpen, onClose, evaluation, onSubmit }) => {
  const classes = useStyles();
  const [rejectionTemplateValue, setRejectionTemplateValue] = useState('');
  const [rejectionTemplate, setRejectionTemplate] = useState(evaluation?.rejectionTemplate || []);
  const [cptCodeList, setCptCodeList] = useState(evaluation?.cptCodes || []);
  const [rejectionTemplateValuesError, setRejectionReasonError] = useState('');
  const [imageFileError] = useState('');
  const [isAvatarUpdated, setIsAvatarUpdated] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  const defaultValues = {
    avatar: evaluation?.avatar || '',
    description: evaluation?.description || '',
    name: evaluation?.name || '',
    rejectionTemplate: evaluation?.rejectionTemplate || [],
    cptCodes: evaluation?.cptCodes || [],
    reorder: false,
  };

  const { cpts } = useSelector(stateSelector);
  const cptsList = cpts.map(cptsVal => {
    return { label: cptsVal.code, value: cptsVal.code };
  });

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    validationSchema: EvaluationSchema,
    onSubmit: (payload: IEvaluation) => {
      let data;
      if (rejectionTemplate.length > 0) {
        data = {
          ...payload,
          rejectionTemplate,
          cptCodes: cptCodeList,
          avatar: isAvatarUpdated ? imageUrl : evaluation?.avatar,
        };
        onSubmit(data, isAvatarUpdated);
      } else {
        setRejectionReasonError('At least one rejection reason is required');
      }
    },
  });

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    } else if (evaluation) {
      Object.keys(evaluation).forEach(key => {
        void setFieldValue(key, evaluation[key]);
      });
    }
  }, [isOpen, evaluation]);

  /**
   * @Name useEffect
   * @description This method is used when we get avatar URl from update flow
   */
  useEffect(() => {
    if (evaluation?.avatar) {
      setImageFile(evaluation.avatar);
    }
  }, []);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  /**
   * @Name onAcceptedFiles
   * @description This method is used when we select/accept any file
   */
  const onAcceptedFiles = (files: File[]) => {
    const file = files[0];
    setImageFile(file);
    setIsAvatarUpdated(true);
  };

  /**
   * @Name renderLabel
   * @description This method is used to render label
   */
  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  /**
   * @Name onHandleDelete
   * @description This method is used to handle delete operation on rejection
   */
  const onHandleDelete = (index: number) => {
    const updatedItem = rejectionTemplate[index];
    const updatedRejectionTemplateValues = rejectionTemplate.filter(
      rejectionValue => updatedItem !== rejectionValue,
    );
    setRejectionTemplate(updatedRejectionTemplateValues);
  };

  /**
   * @Name onHandleDeleteCpts
   * @description This method is used to handle delete cpts
   */
  const onHandleDeleteCPTS = (data, key) => {
    const updatedList = cptCodeList.filter((chip, index) => index !== key);
    setCptCodeList(updatedList);
  };

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>
            {evaluation ? 'Edit evaluation detail' : 'Add evaluation'}
          </Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Avatar')}
              {imageFile ? (
                <UploadFileProgress
                  file={imageFile}
                  fileName={imageFile?.name || 'logo.png'}
                  fileSize={imageFile?.size || 1024}
                  s3Folder="chatBot/"
                  onUploaded={url => {
                    setImageUrl(url);
                  }}
                  onRemove={() => {
                    setImageFile(null);
                  }}
                />
              ) : (
                <UploadFile
                  onAcceptedFiles={onAcceptedFiles}
                  description="PNG, JPG, GIF up to 5MB"
                  fileType="image/*"
                />
              )}
              {!imageFile && <TextError errorMsg={imageFileError} />}
            </Box>
            <Box className={classes.section}>
              {renderLabel('Name')}
              <Input
                value={values?.name}
                name="name"
                placeholder="Enter a evaluation name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Description')}
              <Input
                value={values?.description}
                name="description"
                placeholder="Enter description"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('description')}
                fullWidth
              />
              <TextError errorMsg={touched?.description ? errors?.description?.toString() : null} />
            </Box>

            <Box className={classes.section}>
              {renderLabel('Rejection Reasons')}
              <TextField
                label="Rejection Reasons"
                value={rejectionTemplateValue}
                onChange={e => {
                  setRejectionTemplateValue(e.target.value);
                }}
                onKeyDown={event => {
                  if (event.keyCode === 13 && rejectionTemplateValue) {
                    setRejectionTemplateValue('');
                    setRejectionTemplate([...rejectionTemplate, rejectionTemplateValue?.trim()]);
                  }
                }}
              />
              {rejectionTemplate.length < 1 && <TextError errorMsg={rejectionTemplateValuesError} />}
            </Box>
            {rejectionTemplate?.length > 0 && (
              <div>
                {rejectionTemplate?.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      label={data}
                      onDelete={() => {
                        onHandleDelete(index);
                      }}
                      className="chip"
                    />
                  );
                })}
              </div>
            )}
            <Box className={classes.section}>
              {renderLabel('CPT codes')}
              <Select
                options={cptsList}
                value=""
                displayEmpty
                emptyText=""
                onChange={({ target }) => {
                  if (!cptCodeList.includes(target.value)) {
                    setCptCodeList([...cptCodeList, target.value]);
                  }
                }}
                variant={selectType.SECONDARY}
                fullWidth
              />
              {/* <TextError errorMsg={touched.name ? errors.name?.toString() : null} /> */}
            </Box>
            {cptCodeList?.length > 0 && (
              <div>
                {cptCodeList?.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      label={data}
                      onDelete={() => {
                        onHandleDeleteCPTS(data, index);
                      }}
                      className="chip"
                    />
                  );
                })}
              </div>
            )}
            <Box className={classes.section}>
              {renderLabel('Avatar')}
              {imageFile ? (
                <UploadFileProgress
                  file={imageFile}
                  fileName={imageFile?.name || 'logo.png'}
                  fileSize={imageFile?.size || 1024}
                  s3Folder="chatBot/"
                  onUploaded={url => {
                    setImageUrl(url);
                  }}
                  onRemove={() => {
                    setImageFile(null);
                  }}
                />
              ) : (
                <UploadFile
                  onAcceptedFiles={onAcceptedFiles}
                  description="PNG, JPG, GIF up to 5MB"
                  fileType="image/*"
                />
              )}
              {!imageFile && <TextError errorMsg={imageFileError} />}
            </Box>
          </Box>

          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>{evaluation ? 'Save' : 'Add'} evaluation</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddEvaluation };
