/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { fontWeight, Heading, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { useStyles } from './EvaluationEducation.styles';

type Props = {
  title: string;
  duration: string;
  educationalContent: ReactNode;
  educationalContentTextOnly: string;
  selectedSection: any;
  onClose: () => void;
};
const EvaluationEducationDetail: FC<Props> = ({
  title,
  duration,
  educationalContent,
  educationalContentTextOnly,
  selectedSection,
  onClose,
}) => {
  const classes = useStyles();
  selectedSection.evaluationResponses = [true];
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtn} />
          <Heading weight={fontWeight.BOLD} className={classes.title}>
            Reading
          </Heading>
        </Box>
        <Box className={classes.body}>
          <Box>
            <Heading weight={fontWeight.BOLD} className={clsx(classes.infoTitle, classes.titleDetail)}>
              {title}
            </Heading>
            <Text weight={fontWeight.MEDIUM} className={classes.infoSubtitle}>
              {duration} read
            </Text>
          </Box>
          <Box display="flex" flexDirection="column" style={{ marginTop: 32 }}>
            <Text className={classes.text}>{educationalContentTextOnly}</Text>
            {educationalContent}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { EvaluationEducationDetail };
