export const typography = {
  body: {
    m: {
      bold: {
        fontFamily: 'Manrope',
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: 16,
      },
      semibold: {
        fontFamily: 'Manrope',
        fontWeight: 600,
        lineHeight: '24px',
        fontSize: 16,
      },
      medium: {
        fontFamily: 'Manrope',
        fontWeight: 500,
        lineHeight: '24px',
        fontSize: 16,
      },
      regular: {
        fontFamily: 'Manrope',
        fontWeight: 400,
        lineHeight: '24px',
        fontSize: 16,
      },
    },
    s: {
      bold: {
        fontFamily: 'Manrope',
        fontWeight: 700,
        lineHeight: '20px',
        fontSize: 14,
      },
      semibold: {
        fontFamily: 'Manrope',
        fontWeight: 600,
        lineHeight: '20px',
        fontSize: 14,
      },
      medium: {
        fontFamily: 'Manrope',
        fontWeight: 500,
        lineHeight: '20px',
        fontSize: 14,
      },
    },
    normal: {
      xsmall: {
        semibold: {
          fontFamily: 'Manrope',
          fontWeight: 600,
          lineHeight: '16px',
          fontSize: 12,
        },
      },
      small: {
        medium: {
          fontFamily: 'Manrope',
          fontWeight: 500,
          lineHeight: '20px',
          fontSize: 14,
        },
        semibold: {
          fontFamily: 'Manrope',
          fontWeight: 600,
          lineHeight: '20px',
          fontSize: 14,
        },
        bold: {
          fontFamily: 'Manrope',
          fontWeight: 700,
          lineHeight: '20px',
          fontSize: 14,
        },
      },
      medium: {
        bold: {
          fontFamily: 'Manrope',
          fontWeight: 700,
          lineHeight: '24px',
          fontSize: 16,
        },
        semibold: {
          fontFamily: 'Manrope',
          fontWeight: 600,
          lineHeight: '24px',
          fontSize: 16,
        },
        medium: {
          fontFamily: 'Manrope',
          fontWeight: 500,
          lineHeight: '24px',
          fontSize: 16,
        },
      },
    },
    l: {
      medium: {
        fontFamily: 'Manrope',
        fontWeight: 500,
        lineHeight: '28px',
        fontSize: 18,
      },
      semibold: {
        fontFamily: 'Manrope',
        fontWeight: 600,
        lineHeight: '28px',
        fontSize: 18,
      },
    },
    xs: {
      medium: {
        fontFamily: 'Manrope',
        fontWeight: 500,
        lineHeight: '16px',
        fontSize: 12,
      },
      bold: {
        fontFamily: 'Manrope',
        fontWeight: 700,
        lineHeight: '16px',
        fontSize: 12,
      },
      semibold: {
        fontFamily: 'Manrope',
        fontWeight: 600,
        lineHeight: '16px',
        fontSize: 12,
      },
    },
  },
  overline: {
    small: {
      fontFamily: 'Manrope',
      fontWeight: 800,
      lineHeight: '20px',
      fontSize: 12,
    },
  },
};
