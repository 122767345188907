/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* jshint sub:true */
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Chip } from '@mui/material';
import { useFormik } from 'formik';
import cx from 'clsx';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { iconBtnStyle, iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { priorityActionCreators } from 'redux/modules/priority';

import { stateSelector } from 'redux/modules/state/selectors';
import { Toggle, positionType } from '@confidant-health/lib/ui/atoms/toggle';

import { PriorityElementSchema } from './AddEditPriorityElement.schema';
import { IAddNewProps } from './AddEditPriorityElement.types';
import {
  SPECIFICATION_LIST,
  DOMAIN_TYPE_SPECIFICATIONS_VALUES,
  RX_DRUG_INFO,
  DIAGNOSIS_INFO,
  SUBSTANCE_USE,
} from '../../../../constants/CommonConstants';
import { createCopy } from '../../../../utils/CommonUtils';
import { useStyles } from './AddEditPriorityElement.styles';

interface UpdatedJson {
  [key: string]: {
    PROFILE_ELEMENT: {
      [key: string]: string[];
    };
  };
}

const DEFAULT_RX_DRUG_INFO = {
  highEndDose: 0,
  highEndSupply: 0,
  lowEndDose: 0,
  lowEndSupply: 0,
  medicationType: '',
  generic: '',
  medicationClass: '',
  relatedEducationContent: '',
};
const DEFAULT_SUBSTANCE_USE_INFO = {
  methodOfUse: [],
  unitOfUse: [],
  lastUse: [],
  currentFrequencyOfUse: [],
  howLongUsingThisLevel: [],
};

const DEFAULT_DIAGNOSIS_INFO = {
  approach: '',
  relatedEducationContent: '',
  summary: '',
  therapeuticPhilosophy: '',
};

const DEFAULT_SPECIFICATION_INFO = {
  relatedToMedicalCondition: false,
  relatedToMedication: false,
  relatedToSubstanceUse: false,
  relatedToWithdrawal: false,
};

const listItems = [
  'allergies',
  'personalStatements',
  'previouslyDiagnosedMedicalConditions',
  'previouslyDiagnosedMentalHealthConditions',
  'medicalConditionsCurrentlyTreatedFor',
  'mentalHealthConditionsCurrentlyTreatedFor',
  'familyMedicationConditions',
  'familyMentalHealthConditions',
];

const dropDownItems = ['sexAssigned', 'supplyUnit', 'doseUnit'];

const AddEditPriorityElement: React.FC<IAddNewProps> = ({
  isOpen,
  onClose,
  fetchDomainElements,
  editData,
  domainTypes,
  profileElements,
  lookupMap,
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [levelSelects, setLevelSelects] = useState([]);
  const [profileElementLevel, setProfileElementLevel] = useState(1);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [isPrimaryChecked, setIsPrimaryChecked] = useState(editData?.primary ?? false);
  const [stateObj, setStateObj] = useState({
    metaDataSpec: {},
    metaDataValues: {},
    selectedImportanceLevel: '',
    interferenceElementId: '',
    interferenceValues: {
      Yes: '',
      No: '',
    },
  } as any);
  const getProfileElement = () => {
    if (stateObj?.metaDataValues?.importanceLevelsValues) {
      const propertyName = Object.keys(stateObj?.metaDataValues?.importanceLevelsValues)[
        Object.keys(stateObj?.metaDataValues?.importanceLevelsValues).length - 1
      ];
      if (stateObj?.metaDataValues?.importanceLevelsValues[propertyName]) {
        const obj = stateObj?.metaDataValues?.importanceLevelsValues[propertyName].PROFILE_ELEMENT;
        const foundProfileElement = profileElements.find(elem => {
          return obj && elem.profileElementInfo.id === Object.keys(obj)[0];
        });

        return {
          value: foundProfileElement?.profileElementInfo?.id,
          label: foundProfileElement?.profileElementInfo?.key,
          implevel: propertyName || '',
        };
      }
    }
    return '' as any;
  };

  const defaultValues = {
    name: editData?.name ?? '',
    type: editData?.parent ?? '',
    profile: getProfileElement(),
    primary: editData?.primary ?? false,
    CRITICAL: '',
    HIGH: '',
    MEDIUM: '',
    LOW: '',
    RESOLVED: '',
    MISREPORTED: '',
    UNRELATED: '',
  };

  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: PriorityElementSchema,
    enableReinitialize: editData !== null,
    onSubmit: () => {
      const { metaDataSpec, metaDataValues, interferenceElementId, interferenceValues } = stateObj;
      if (interferenceElementId !== '') {
        const valuesData: any = {};
        if (interferenceValues.Yes !== '') {
          valuesData.yes = [interferenceValues.Yes];
        }
        if (interferenceValues.No !== '') {
          valuesData.no = [interferenceValues.No];
        }
        if (Object.keys(valuesData).length > 0) {
          metaDataValues.relatedToInterferenceWithLife = {
            relatedProfileElements: { [interferenceElementId]: values },
          };
        }
      } else {
        metaDataValues.relatedToInterferenceWithLife = null;
      }
      if (!metaDataSpec.requireSubstanceUse) {
        metaDataValues.substanceUse = null;
      }
      if (!metaDataSpec.requireRxInfo) {
        metaDataValues.rxDrugInfo = null;
      }
      if (!metaDataSpec.requireDiagnosisInfo) {
        metaDataValues.diagnosisInfo = null;
      }
      metaDataValues.specification = null;
      metaDataValues.relatedToMapping = null;

      const presentProfileEle = profile?.map(pv => pv?.value);

      const updatedJson: UpdatedJson = Object.keys(stateObj?.metaDataValues?.importanceLevelsValues).reduce(
        (result: UpdatedJson, key: string) => {
          result[key] = { PROFILE_ELEMENT: {} };

          Object.keys(stateObj?.metaDataValues?.importanceLevelsValues[key]?.PROFILE_ELEMENT || {}).forEach(
            (innerKey: string) => {
              if (
                presentProfileEle.includes(innerKey) &&
                stateObj?.metaDataValues?.importanceLevelsValues[key].PROFILE_ELEMENT
              ) {
                result[key].PROFILE_ELEMENT[innerKey] =
                  stateObj?.metaDataValues?.importanceLevelsValues[key].PROFILE_ELEMENT[innerKey];
              }
            },
          );

          return result;
        },
        {},
      );

      const payload =
        values.type === getDomainTypeIdByname('Diagnoses')
          ? {
              name: values.name,
              type: 'PATIENT_DATA_ELEMENT',
              metaData: {
                metaDataSpec,
                metaDataValues: {
                  ...stateObj?.metaDataValues,
                  importanceLevelsValues: updatedJson,
                },
              },
              parent: values.type,
              children: [],
              tags: [],
              primary: isPrimaryChecked || false,
            }
          : {
              name: values.name,
              type: 'PATIENT_DATA_ELEMENT',
              metaData: {
                metaDataSpec,
                metaDataValues: {
                  ...stateObj?.metaDataValues,
                  importanceLevelsValues: updatedJson,
                },
              },
              parent: values.type,
              children: [],
              tags: [],
            };
      if (editData) {
        dispatch(
          priorityActionCreators.updatePriorityDomainElement({
            data: { ...payload, Id: editData.Id },
            callback: (isSucceed: boolean) => {
              if (isSucceed) {
                fetchDomainElements();
                onClose();
              }
            },
          }),
        );
      } else {
        dispatch(
          priorityActionCreators.createPriorityDomainElement({
            data: payload,
            callback: (isSucceed: boolean) => {
              if (isSucceed) {
                fetchDomainElements();
                onClose();
              }
            },
          }),
        );
      }
    },
  });

  const addedProfileEle = profile?.map(pf => pf?.value);
  let profileElementsOptions = profileElements
    .filter(element => {
      return (
        element.profileElementInfo &&
        element.profileElementInfo.values &&
        element.profileElementInfo.values.length > 0 &&
        !addedProfileEle?.includes(element.profileElementInfo?.id)
      );
    })
    .sort((a, b) => a.profileElementInfo.key.trim().localeCompare(b.profileElementInfo.key.trim()))
    .map(item => {
      return { label: item.profileElementInfo.key, value: item.profileElementInfo.id };
    });
  const { icd10 } = useSelector(stateSelector);
  const icd10List = icd10.map(icd10Val => {
    return { label: `${icd10Val?.code} ${icd10Val?.description}`, value: icd10Val?.code };
  });

  const selectedType = domainTypes?.find(item => item.Id === values.type);
  const metaDataSpec = selectedType?.actions?.metaData?.metaDataSpec;
  const domainElement = editData;

  const getObjectSize = obj => {
    let size = 0;
    if (obj) {
      Object.keys(obj).forEach(key => {
        if (key) {
          size++;
        }
      });
    }
    return size;
  };

  const populateMetaData = async () => {
    let metaDataValues: any = {};
    metaDataValues = {
      importanceLevelsValues: domainElement
        ? domainElement?.metaData?.metaDataValues?.importanceLevelsValues
        : {},
      rxDrugInfo: metaDataSpec?.requireRxInfo
        ? domainElement
          ? domainElement.metaData?.metaDataValues?.rxDrugInfo
          : createCopy(DEFAULT_RX_DRUG_INFO)
        : {},
      icd10Codes: metaDataSpec?.requireIcd10Codes
        ? domainElement
          ? domainElement.metaData?.metaDataValues.icd10Codes
          : []
        : [],
      diagnosisInfo: metaDataSpec?.requireDiagnosisInfo
        ? domainElement
          ? domainElement.metaData?.metaDataValues?.diagnosisInfo
          : createCopy(DEFAULT_DIAGNOSIS_INFO)
        : {},
      specification: metaDataSpec?.requireSpecification
        ? domainElement
          ? domainElement.metaData?.metaDataValues?.specification
          : createCopy(DEFAULT_SPECIFICATION_INFO)
        : {},
      relatedToInterferenceWithLife: metaDataSpec?.requireInterferenceWithLifeInfo
        ? domainElement?.metaData?.metaDataValues?.relatedToInterferenceWithLife || []
        : {},
      relatedToMapping: metaDataSpec?.requireRelatedToInfo
        ? domainElement?.metaData?.metaDataValues?.relatedToMapping || []
        : [],
      substanceUse: metaDataSpec?.requireSubstanceUse
        ? domainElement?.metaData?.metaDataValues?.substanceUse || createCopy(DEFAULT_SUBSTANCE_USE_INFO)
        : createCopy(DEFAULT_SUBSTANCE_USE_INFO),
    };

    let importanceElementId = '';
    if (metaDataValues.importanceLevelsValues) {
      Object.keys(metaDataValues.importanceLevelsValues).forEach(imp => {
        if (
          importanceElementId === '' &&
          metaDataValues.importanceLevelsValues[imp].PROFILE_ELEMENT &&
          Object.keys(metaDataValues.importanceLevelsValues[imp].PROFILE_ELEMENT).length > 0
        ) {
          const data = metaDataValues.importanceLevelsValues[imp].PROFILE_ELEMENT;
          importanceElementId = Object.keys(data) ? Object.keys(data)[0] : '';
        }
      });
      let counter = 0;
      const counterArray = [];
      Object.keys(metaDataValues.importanceLevelsValues).forEach(imp => {
        Object.keys(metaDataValues.importanceLevelsValues[imp]?.PROFILE_ELEMENT || {})?.forEach(prof => {
          if (!counterArray.includes(prof)) {
            counterArray.push(prof);
            counter++;
          }
        });
      });

      const filteredProfileELements = profileElementsOptions.filter(item =>
        counterArray.includes(item.value),
      );

      await rest.setFieldValue('profile', filteredProfileELements);
      setProfile(filteredProfileELements);
      setProfileElementLevel(counter);
    }

    let interferenceElementId = '';
    const interferenceValues = {
      Yes: '',
      No: '',
    };
    if (
      metaDataValues.relatedToInterferenceWithLife &&
      metaDataValues.relatedToInterferenceWithLife.relatedProfileElements
    ) {
      interferenceElementId = Object.keys(metaDataValues.relatedToInterferenceWithLife.relatedProfileElements)
        .length
        ? Object.keys(metaDataValues.relatedToInterferenceWithLife.relatedProfileElements).length[0]
        : '';
      const valuesData =
        metaDataValues.relatedToInterferenceWithLife.relatedProfileElements[interferenceElementId];
      if (valuesData?.yes && valuesData?.yes?.length > 0) {
        interferenceValues.Yes = valuesData.yes[0];
      }
      if (valuesData?.no && valuesData?.no?.length > 0) {
        interferenceValues.No = valuesData.no[0];
      }
    }

    setStateObj({
      ...stateObj,
      metaDataSpec,
      metaDataValues,
      interferenceElementId,
      interferenceValues,
    });
  };

  const addImportanceLevel = (importanceLevel, profileElementId, profileElementValue) => {
    const { metaDataValues } = stateObj;
    const { importanceLevelsValues } = metaDataValues;
    const selectedValues = [profileElementValue];
    if (
      importanceLevelsValues &&
      Object.keys(importanceLevelsValues).length > 0 &&
      Object.prototype.hasOwnProperty.call(importanceLevelsValues, importanceLevel)
    ) {
      importanceLevelsValues[importanceLevel].PROFILE_ELEMENT[profileElementId] = selectedValues;

      metaDataValues.importanceLevelsValues = {
        ...importanceLevelsValues,
      };
    } else {
      const selectedMappingDetail = { PROFILE_ELEMENT: { [profileElementId]: selectedValues } };
      metaDataValues.importanceLevelsValues = {
        ...importanceLevelsValues,
        [importanceLevel]: selectedMappingDetail,
      };
    }

    setStateObj({
      ...stateObj,
      metaDataValues,
    });
  };

  const updateInfo = (value, fieldName, listData, type, fieldType) => {
    const { metaDataValues } = stateObj;
    if (fieldType === 'boolean' || fieldType === 'string' || fieldType === 'dropDowm') {
      metaDataValues[type][fieldName] = value;
    } else {
      const list = metaDataValues[type][fieldName] || [];
      metaDataValues[type][fieldName] = list;
      if (!metaDataValues[type][fieldName].includes(value)) {
        metaDataValues[type][fieldName].push(value);
      }
    }
    setStateObj({
      ...stateObj,
      metaDataValues,
    });
  };

  const domainTypesOptions = domainTypes.map(item => {
    return { label: item.name, value: item.Id, key: item?.Id };
  });
  function getDomainTypeIdByname(domainTypeName: string) {
    const foundDomainType = domainTypesOptions.find(item => item.label === domainTypeName);
    if (foundDomainType) {
      return foundDomainType.value;
    }
    return '';
  }

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (values.type) {
      void (async () => {
        setLoading(true);
        await populateMetaData();
        setLoading(false);
      })();

      const typeImportanceLevels = metaDataSpec?.importanceLevels;
      setLevelSelects(Object.keys(typeImportanceLevels).filter(item => typeImportanceLevels[item]));
    }
  }, [values.type]);

  useEffect(() => {
    if (profile) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      levelSelects.forEach(async key => {
        await rest.setFieldValue(key, '');
      });
    }
  }, [profile]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderSelect = (label, name, opts, emptyText) => (
    <Box className={classes.section}>
      {renderLabel(label)}
      <Select
        options={opts}
        value={values[name]}
        displayEmpty
        emptyText={emptyText}
        name={name}
        onChange={handleChange}
        variant={selectType.SECONDARY}
        fullWidth
      />
      <TextError errorMsg={touched[name] ? errors[name]?.toString() : null} />
    </Box>
  );

  const renderElementValueDropdown = (name, index) => {
    const selectedElement = profileElements?.find(
      element => element.profileElementInfo.id === profile[index]?.value,
    );
    let elementsOptions = [];
    if (selectedElement) {
      if (selectedElement?.profileElementInfo?.values?.length === 0) {
        elementsOptions = [];
      } else if (selectedElement?.profileElementInfo?.values?.length > 0) {
        elementsOptions = selectedElement?.profileElementInfo?.values.map(item => {
          return { label: item, value: item };
        });
        // .filter(item => !usedOptions.includes(item.label));
      }
    }

    let selectValue: any;
    const { metaDataValues } = stateObj;
    const { importanceLevelsValues } = metaDataValues;

    if (getObjectSize(importanceLevelsValues) > 0) {
      selectValue =
        selectedElement?.profileElementInfo?.id && importanceLevelsValues[name]?.PROFILE_ELEMENT
          ? importanceLevelsValues[name]?.PROFILE_ELEMENT[selectedElement?.profileElementInfo?.id]?.[0]
          : '';
    }
    return (
      <Box style={{ marginTop: 5 }}>
        <Select
          options={elementsOptions}
          value={selectValue || ''}
          // displayEmpty
          emptyText="Select value"
          name={name}
          onChange={e => {
            handleChange(e);
            addImportanceLevel(name, profile[index]?.value, e?.target?.value);
          }}
          variant={selectType.SECONDARY}
          defaultValue={[]}
          fullWidth
        />
        <TextError
          errorMsg={touched[name.toLocaleLowerCase()] ? errors[name.toLocaleLowerCase()]?.toString() : null}
        />
      </Box>
    );
  };

  const shouldDisplay = key => {
    if (metaDataSpec[key]) {
      return SPECIFICATION_LIST.includes(key);
    }
    return false;
  };

  const getMetaDataValueField = key => {
    switch (key) {
      case 'requireDiagnosisInfo':
        return 'diagnosisInfo';
      case 'requireInterferenceWithLifeInfo':
        return 'relatedToInterferenceWithLife';
      case 'requireRelatedToInfo':
        return 'relatedToMapping';
      case 'requireRxInfo':
        return 'rxDrugInfo';
      default:
        return key;
    }
  };

  const getFieldLabel = (field, key) => {
    switch (field) {
      case 'rxDrugInfo':
        return RX_DRUG_INFO[key];
      case 'diagnosisInfo':
        return DIAGNOSIS_INFO[key];
      default:
        return key;
    }
  };

  const getDropDownList = value => {
    const lookupValues = lookupMap[value];
    if (!lookupValues) {
      return '';
    }
    return lookupValues.reduce((prev, current) => {
      prev[current.name] = current.value;
      return prev;
    }, {});
  };

  const specDetails = (fieldName, fieldKey, type) => {
    const { metaDataValues } = stateObj;
    const fieldType = typeof metaDataValues[type][fieldKey];
    const isDropDown = dropDownItems.includes(fieldKey);
    let renderList = null;
    if (isDropDown) {
      renderList = getDropDownList(fieldKey);
    }

    return (
      <div key={fieldName}>
        {fieldType === 'boolean' ? (
          <div>
            <Box className={classes.section}>
              {renderLabel(fieldName)}
              <Select
                options={[
                  { label: 'True', value: 1 },
                  { label: 'False', value: 0 },
                ]}
                value={metaDataValues[type][fieldKey] === true ? 1 : 0}
                displayEmpty
                emptyText="Select"
                name={fieldKey}
                onChange={e => {
                  updateInfo(e.target.value === 1, fieldKey, null, type, 'boolean');
                }}
                variant={selectType.SECONDARY}
                fullWidth
              />
            </Box>
          </div>
        ) : fieldKey === 'relatedEducationContent' ? (
          <div />
        ) : isDropDown ? (
          <div>
            <Box className={classes.section}>
              {renderLabel(fieldName)}
              <Select
                options={Object.keys(renderList).map(item => {
                  return { label: renderList[item], value: item, key: '12' };
                })}
                value={metaDataValues[type][fieldKey] || ''}
                displayEmpty
                emptyText="Select"
                name={fieldKey}
                onChange={e => {
                  updateInfo(e.target.value, fieldKey, null, type, 'dropDowm');
                }}
                variant={selectType.SECONDARY}
                fullWidth
              />
            </Box>
          </div>
        ) : (
          <div>
            <Box className={classes.section}>
              {renderLabel(fieldName)}
              <Input
                value={
                  (listItems.includes(fieldKey) ? stateObj[fieldKey] : metaDataValues[type][fieldKey]) || ''
                }
                name={fieldName}
                type={fieldKey.includes('At') ? 'date' : ''}
                onChange={e => {
                  if (listItems.includes(fieldKey)) {
                    setStateObj({ ...stateObj, [fieldKey]: e.target.value });
                  } else {
                    updateInfo(e.target.value, fieldKey, null, type, 'string');
                  }
                }}
                size={inputSize.M}
                fullWidth
              />
            </Box>
          </div>
        )}
      </div>
    );
  };

  const renderSpecDetails = () => {
    return (
      metaDataSpec &&
      Object.keys(metaDataSpec)
        .filter(item => shouldDisplay(item))
        .map(key => {
          const field = getMetaDataValueField(key);

          return (
            <>
              <Heading level={headingLevel.S} className={classes.importanceLevel} weight={fontWeight.BOLD}>
                {DOMAIN_TYPE_SPECIFICATIONS_VALUES[field]}
              </Heading>
              <Box display="flex" flexDirection="column" gap={2}>
                {stateObj.metaDataValues[field] &&
                  Object.keys(stateObj.metaDataValues[field]).map(innerKey => {
                    return specDetails(getFieldLabel(field, innerKey), innerKey, field);
                  })}
              </Box>
            </>
          );
        })
    );
  };

  const getLookupKey = key => {
    switch (key) {
      case 'methodOfUse': {
        return 'methodsOfSubstanceUse';
      }
      case 'unitOfUse': {
        return 'unitsOfSubstanceUse';
      }
      case 'lastUse': {
        return 'lastSubstanceUse';
      }
      case 'currentFrequencyOfUse': {
        return 'currentFrequencyOfSubstanceUse';
      }
      case 'howLongUsingThisLevel': {
        return 'continuousLevelOfSubstanceUse';
      }
      default: {
        return key;
      }
    }
  };

  const renderDropDownFor = (key, placeHolder, onSelect) => {
    const options = getDropDownList(getLookupKey(key));
    const itemValues = stateObj.metaDataValues?.substanceUse[key]?.map(item => {
      return { title: item, value: item };
    });
    return (
      <Box className={classes.section}>
        {renderLabel(placeHolder)}
        <Input
          value={itemValues}
          name={key}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={Object.keys(options).map(item => {
            return { title: options[item], value: item };
          })}
          onChange={(_, val) => {
            onSelect(val);
          }}
          variant={inputType.TAGS}
          size={inputSize.M}
          fullWidth
        />
      </Box>
    );
  };

  const renderSubstanceUseInfo = () => {
    const { metaDataValues } = stateObj;

    return (
      <>
        <Heading level={headingLevel.S} className={classes.importanceLevel} weight={fontWeight.BOLD}>
          Substance Use Info
        </Heading>
        {metaDataValues?.substanceUse &&
          Object.keys(DEFAULT_SUBSTANCE_USE_INFO)?.map(substanceKey => {
            return (
              <>
                {renderDropDownFor(substanceKey, 'Select '.concat(SUBSTANCE_USE[substanceKey]), value => {
                  metaDataValues.substanceUse[substanceKey] = value.map(item => item.value);
                  metaDataValues.substanceUse[substanceKey.concat('Values')] = value;
                  setStateObj({ ...stateObj, metaDataValues });
                })}
              </>
            );
          })}
      </>
    );
  };

  const handleDeleteIcd = (data, key) => {
    const updatedList = stateObj?.metaDataValues.icd10Codes.filter((chip, index) => index !== key);
    stateObj.metaDataValues.icd10Codes = updatedList;
    setStateObj({ ...stateObj, metaDataValues: stateObj.metaDataValues });
  };

  const removeKey = (data: any, keyToDelete: string) => {
    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'object') {
        removeKey(data[key], keyToDelete);
      }
      if (key === keyToDelete) {
        delete data[key];
      }
    });
    return data;
  };

  const selectedProfilesValues = Object.values(values?.profile);

  profileElementsOptions = profileElementsOptions?.filter(pr => !selectedProfilesValues?.includes(pr?.value));

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {loading ? (
        <div className={classes.loader}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          Loading...
        </div>
      ) : (
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Text className={classes.headerTitle}>{`${editData ? 'Edit' : 'Add'} data domain element`}</Text>
            <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
          </Box>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Box className={classes.formContent}>
              <Box className={classes.section}>
                {renderLabel('Name')}
                <Input
                  value={values.name}
                  name="name"
                  placeholder="Enter a data domain element name"
                  onChange={handleChange}
                  size={inputSize.M}
                  onBlur={setTouched('name')}
                  fullWidth
                />
                <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
              </Box>
              {values.name && renderSelect('Element type', 'type', domainTypesOptions, 'Select type')}
              {values.type === getDomainTypeIdByname('Diagnoses') && (
                <Box>
                  <label htmlFor="primary">
                    <Toggle
                      checked={isPrimaryChecked}
                      labelPosition={positionType.RIGHT}
                      onChange={value => {
                        setIsPrimaryChecked(value);
                      }}
                    >
                      <Text level={textLevel.S} weight={fontWeight.BOLD}>
                        <span>Primary</span>
                      </Text>
                    </Toggle>
                  </label>
                </Box>
              )}
              {values.type && (
                <>
                  <Box
                    display="flex"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: 80,
                      padding: 16,
                      borderTop: `1px solid ${colors.neutral100}`,
                    }}
                  >
                    <Heading
                      level={headingLevel.S}
                      className={classes.importanceLevel}
                      weight={fontWeight.BOLD}
                    >
                      Importance Levels Mapping
                    </Heading>
                    <IconButton
                      icon="plus"
                      variant={btnType.PRIMARY}
                      className={classes.addBtn}
                      onClick={() => {
                        setProfileElementLevel(profileElementLevel + 1);
                      }}
                    >
                      Add profile element
                    </IconButton>
                  </Box>
                  {!loading &&
                    Array.from({ length: profileElementLevel }, (_, index) => {
                      return (
                        <Box key={index}>
                          <Box className={classes.section}>
                            {renderLabel('Profile element')}
                            <Input
                              fullWidth
                              name="profile"
                              variant={inputType.AUTOCOMPLETE}
                              size={inputSize.M}
                              value={profile ? profile[index] : ''}
                              getOptionLabel={(option: any) => {
                                if (option.label) return option.label;
                                return option;
                              }}
                              options={profileElementsOptions}
                              onChange={(env: SyntheticEvent, newValue: any) => {
                                const tempArr = [...profile];
                                tempArr[index] = newValue;
                                values.profile = tempArr;
                                setProfile(tempArr);
                                handleChange(env);
                              }}
                            />
                            <TextError errorMsg={touched.profile ? errors.profile?.toString() : null} />
                          </Box>
                          <Box display="flex" flexDirection="column" gap={2}>
                            {profile &&
                              profile[index] &&
                              // ||
                              // (stateObj?.metaDataValues?.imp
                              // (stateObj?.metortanceLevelsValues &&
                              //   Object.keys(stateObj?.metaDataValues?.importanceLevelsValues).length !==
                              //     0)
                              levelSelects.map(level => (
                                <Box
                                  display="flex"
                                  key={level}
                                  justifyContent="space-between"
                                  alignItems="center"
                                  gap={4}
                                >
                                  <Box style={{ minWidth: 100 }}>
                                    <Badge
                                      variant={badgeType.FILLED}
                                      className={cx(classes.badge, level)}
                                      style={badgeStyle[level.toLocaleUpperCase()]}
                                    >
                                      {level}
                                    </Badge>
                                  </Box>
                                  <Icons
                                    glyph="arrow-right"
                                    color={colors.neutral400}
                                    className={classes.arrowRight}
                                  />
                                  {renderElementValueDropdown(level, index)}
                                </Box>
                              ))}

                            <Box display="flex">
                              <IconButton
                                className={cx(classes.btnFooter, classes.removeBtn)}
                                variant={iconBtnType.TEXT}
                                style={iconBtnStyle.SECONDARY}
                                onClick={() => {
                                  setLoading(true);
                                  const record = profile[index];
                                  const tempArr = [...profile];
                                  tempArr.splice(index, 1);
                                  values.profile = tempArr;
                                  setProfile([...tempArr]);
                                  const importanceLevelObjects =
                                    stateObj?.metaDataValues?.importanceLevelsValues;
                                  if (getObjectSize(importanceLevelObjects) > 0) {
                                    const updatedStateObject = removeKey(
                                      importanceLevelObjects,
                                      record?.value,
                                    );
                                    setStateObj({ ...stateObj, importanceLevelsValues: updatedStateObject });
                                  }

                                  setTimeout(() => {
                                    setProfileElementLevel(tempArr?.length); // update the other state variable with the new length
                                    setLoading(false);
                                  }, 0);
                                }}
                              >
                                Delete
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                </>
              )}
              {metaDataSpec && metaDataSpec?.requireIcd10Codes && (
                <>
                  <Heading
                    level={headingLevel.S}
                    className={classes.importanceLevel}
                    weight={fontWeight.BOLD}
                  >
                    Icd 10 codes
                  </Heading>
                  <Select
                    options={icd10List}
                    value=""
                    displayEmpty
                    emptyText=""
                    onChange={({ target }) => {
                      if (!stateObj.metaDataValues?.icd10Codes?.includes(target.value)) {
                        stateObj.metaDataValues.icd10Codes = [
                          ...stateObj.metaDataValues.icd10Codes,
                          target.value,
                        ];
                        setStateObj({
                          ...stateObj,
                          metaDataValues: stateObj.metaDataValues,
                        });
                      }
                    }}
                    variant={selectType.SECONDARY}
                    fullWidth
                  />
                  <span>
                    {stateObj?.metaDataValues?.icd10Codes?.map((data, index) => {
                      return (
                        <Chip
                          label={data}
                          key={data}
                          onDelete={() => {
                            handleDeleteIcd(data, index);
                          }}
                          className={classes.chip}
                        />
                      );
                    })}
                  </span>
                </>
              )}
              {renderSpecDetails()}
              {metaDataSpec?.requireSubstanceUse && renderSubstanceUseInfo()}
              {/* {metaDataSpec?.requireInterferenceWithLifeInfo && renderInterferenceInLife()} */}
            </Box>

            <Box className={classes.footer}>
              <Button variant={btnType.TEXT} onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>{`${editData ? 'Update' : 'Add'} data domain element`}</Button>
            </Box>
          </form>
        </Box>
      )}
    </Drawer>
  );
};

export { AddEditPriorityElement };
