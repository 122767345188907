import { Box } from '@mui/system';
import { FormControl, Grid, RadioGroup } from '@mui/material';

import { Button, btnSize, btnType } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Radio } from '@confidant-health/lib/ui/atoms/radio';

import { useEffect, useState } from 'react';
import cx from 'clsx';
import { getContentBlockInLibrary } from 'services/conversation/conversation.service';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import useDebounce from 'hooks/useDebounce';
import { getConversation } from 'redux/modules/conversation/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { conversationActionCreators } from 'redux/modules/conversation';
import { viewGroups } from '../data-collection-templates/DataCollectionTemplates.mock';

import { useStyles } from './EvaluationStates.styles';
import { ContentBlockDrawer } from '../content-block-library/components/ContentBlockDrawer';

type QuestionDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  addCB?: (val) => void;
};

const QuestionDrawer = ({ isOpen, onClose, addCB }: QuestionDrawerProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    profileElements: { profileElementList },
  } = useSelector(getConversation);
  const [searchKey, setSearchKey] = useState<string>('');
  const [openCbDrawer, setOpenCbDrawer] = useState(false);
  const [filteredCB, setFilteredCB] = useState([]);
  const [selectedCB, setSelectedCB] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [addCbButtonPressed, setAddCbButtonPressed] = useState(false);
  const searchName = useDebounce(searchKey, 1000);

  const handleSearchKeyChange = (keyword: string) => {
    setSearchKey(keyword);
    setFilteredCB([]);
    setPage(0);
    setTotalPages(0);
  };

  const closeCbDrawer = () => {
    setOpenCbDrawer(false);
    setAddCbButtonPressed(false);
  };

  const addContentBlock = data => {
    setOpenCbDrawer(false);
    data.status = viewGroups[0].type;
    dispatch(conversationActionCreators.addContentBlock(data));
    void getCB();
  };

  const getCB = async () => {
    setLoading(true);
    try {
      const { data } = await getContentBlockInLibrary({
        orderBy: 'desc',
        pageNumber: page,
        pageSize: 20,
        status: 'ACTIVE',
        sortBy: '',
        types: '',
        searchQuery: searchKey,
      });
      setPage(page + 1);
      setFilteredCB([...filteredCB, ...data?.results]);
      setTotalPages(data?.totalPages);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    void getCB();
  }, [searchName]);

  return (
    <>
      <Drawer
        open={isOpen}
        variant={drawerType.FORM}
        className={classes.drawer2}
        onClose={() => {
          setSearchKey('');
          setFilteredCB([]);
          setPage(0);
          setTotalPages(0);
          setSelectedCB(null);
          onClose();
        }}
        header={
          <div className={classes.header2}>
            <Box className={classes.headerTitle}>Add question</Box>
            <IconButton
              icon="close"
              onClick={() => {
                setSearchKey('');
                setFilteredCB([]);
                setPage(0);
                setTotalPages(0);
                setSelectedCB(null);
                onClose();
              }}
              className={cx(classes.icon, classes.editIcon)}
            />
          </div>
        }
        footer={
          <Box className={classes.footer}>
            <Button
              variant={btnType.TEXT}
              onClick={() => {
                setSearchKey('');
                setFilteredCB([]);
                setPage(0);
                setTotalPages(0);
                setSelectedCB(null);
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                addCB(selectedCB);
                setSearchKey('');
                setFilteredCB([]);
                setPage(0);
                setTotalPages(0);
                setSelectedCB(null);
                onClose();
              }}
            >
              Save
            </Button>
          </Box>
        }
      >
        <Box padding={4}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Input
              fullWidth
              className={classes.searchInput}
              variant={inputType.SEARCH}
              placeholder="Find question"
              value={searchKey}
              onChange={handleSearchKeyChange}
            />
            <IconButton
              size={btnSize.SMALL}
              icon="plus"
              className={classes.iconBtn}
              onClick={() => {
                setAddCbButtonPressed(true);
                setOpenCbDrawer(true);
              }}
            >
              New question
            </IconButton>
          </Box>
          <Box sx={{ mt: 4 }}>
            {loading && (
              <div className={classes.loader}>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                Loading...
              </div>
            )}
            {!loading && (
              <FormControl component="fieldset" fullWidth>
                <RadioGroup sx={{ gap: 2 }}>
                  {filteredCB.map((item, index) => (
                    <FormControlLabel
                      key={`radio-${index}-item`}
                      value={item.cbId}
                      control={<Radio />}
                      onChange={() => setSelectedCB(item)}
                      label={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box className={classes.questionDCTLabel}>{item?.basicInfo?.text}</Box>
                          <Box className={classes.questionDesc}>{item?.basicInfo?.type}</Box>
                        </Box>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
            {filteredCB.length === 0 && !loading && (
              <Grid className={classes.noResult}>
                <Typography {...typography.body.normal.small.medium} color={colors.neutral600}>
                  No question available
                </Typography>
              </Grid>
            )}
            {totalPages !== 0 && page <= totalPages && !loading && (
              <Box className={classes.btnContainer}>
                <Button variant={btnType.TEXT} className={classes.loadMoreBtn} onClick={() => void getCB()}>
                  Load more
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>
      {openCbDrawer && (
        <ContentBlockDrawer
          titleLabel="New question"
          isOpen={openCbDrawer}
          profileElements={profileElementList}
          onClose={closeCbDrawer}
          onDelete={cbId => {
            console.log(cbId);
          }}
          onSave={addContentBlock}
          addCbButtonPressed={addCbButtonPressed}
          getCBs={getCB}
        />
      )}
    </>
  );
};

export default QuestionDrawer;
