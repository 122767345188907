import { ReportViewType } from 'redux/modules/reporting/types';

export const appointmentCheckList = [
  {
    title: 'Total appointments',
    viewType: ReportViewType.COUNT,
    type: 'totalAppointments',
  },
  {
    title: 'Total members',
    viewType: ReportViewType.COUNT,
    type: 'totalMembers',
  },
  {
    title: 'Total rating',
    viewType: ReportViewType.COUNT,
    type: 'totalRating',
  },
  {
    title: 'Completed appointments',
    viewType: ReportViewType.COUNT,
    type: 'completedAppointments',
  },
  {
    title: 'Canceled appointments',
    viewType: ReportViewType.COUNT,
    type: 'canceledAppointments',
  },
  {
    title: 'No shows',
    viewType: ReportViewType.COUNT,
    type: 'noShows',
  },
  {
    title: 'Appointments last 30 days',
    viewType: ReportViewType.LINE_CHART,
    type: 'appointmentsInLast30Days',
  },
  {
    title: 'Most appointments',
    viewType: ReportViewType.TABLE,
    type: 'mostAppointments',
  },
  {
    title: 'Most no shows',
    viewType: ReportViewType.TABLE,
    type: 'mostNoShows',
  },
  {
    title: 'Most recent appointments',
    viewType: ReportViewType.TABLE,
    type: 'mostRecentAppointments',
  },
];

export const demoGraphicCheckList = [
  {
    title: 'Gender breakdown',
    viewType: ReportViewType.PIE_CHART,
    type: 'genderBreakDown',
  },
  {
    title: 'Race breakdown',
    viewType: ReportViewType.PIE_CHART,
    type: 'raceBreakDown',
  },
  { title: 'Age breakdown', viewType: ReportViewType.BAR_GRAPH, type: 'ageBreakDown' },
  {
    title: 'Education breakdown',
    viewType: ReportViewType.BAR_GRAPH,
    type: 'educationBreakDown',
  },
  {
    title: 'Marital status',
    viewType: ReportViewType.PIE_CHART,
    type: 'maritalStatus',
  },
  {
    title: 'Housing status',
    viewType: ReportViewType.PIE_CHART,
    type: 'housingStatus',
  },
  { title: 'Top 5 cities', viewType: ReportViewType.TABLE, type: 'topFiveCities' },
  { title: 'Top 5 states', viewType: ReportViewType.TABLE, type: 'topFiveStates' },
];

export const dataDomainCheckList = [
  { title: 'Top 10 symptoms by member', viewType: ReportViewType.BAR_GRAPH, type: 'top10tByMembers' },
  { title: 'Most assigned', viewType: ReportViewType.TABLE, type: 'mostAssigned' },
  { title: 'Least assigned', viewType: ReportViewType.TABLE, type: 'leastAssigned' },
  { title: 'Most important by count', viewType: ReportViewType.TABLE, type: 'mostCountByImportance' },
  { title: 'Most changed from start', viewType: ReportViewType.TABLE, type: 'mostChangedFromStart' },
];

export const dataCollectionCheckList = [
  { title: 'Total DCT’s', viewType: ReportViewType.COUNT, type: 'totalDCTs' },
  { title: 'Total Completed', viewType: ReportViewType.COUNT, type: 'totalCompleted' },
  { title: 'Total Attempts', viewType: ReportViewType.COUNT, type: 'totalAttempts' },
  {
    title: 'Completed in the last 30 days',
    viewType: ReportViewType.LINE_CHART,
    type: 'completedLastThirtyDays',
  },
  { title: 'Most completed by members', viewType: ReportViewType.TABLE, type: 'mostCompleted' },
  { title: 'Least completed by members', viewType: ReportViewType.TABLE, type: 'leastCompleted' },
  { title: 'Most attempted', viewType: ReportViewType.TABLE, type: 'mostAttempted' },
  { title: 'Least attempted', viewType: ReportViewType.TABLE, type: 'leastAttempted' },
  {
    title: 'Average initial score',
    viewType: ReportViewType.TABLE,
    type: 'averageInitialScore',
  },
  { title: 'Average currrent score', viewType: ReportViewType.TABLE, type: 'averageCurrentScore' },
  { title: 'Greatest change in score', viewType: ReportViewType.TABLE, type: 'greatestChangeInScore' },
  {
    title: 'Average number of completions',
    viewType: ReportViewType.TABLE,
    type: 'averageNumberOfCompletions',
  },
];

export const profileElementsCheckList = [
  { title: 'Total Profile Elements', viewType: ReportViewType.COUNT, type: 'totalProfileElements' },
  { title: 'Average Assignments Count', viewType: ReportViewType.COUNT, type: 'averageAssignmentsCount' },
  { title: 'Last Assigned', viewType: ReportViewType.COUNT, type: 'lastAssigned' },
  { title: 'Updated in the last 30 days', viewType: ReportViewType.LINE_CHART, type: 'updatedInLast30Days' },
  { title: 'Most Assigned', viewType: ReportViewType.TABLE, type: 'mostAssigned' },
  { title: 'Least Assigned', viewType: ReportViewType.TABLE, type: 'leastAssigned' },
  {
    title: 'Top 10 profile elements by member',
    viewType: ReportViewType.BAR_GRAPH,
    type: 'top10ProfileElements',
  },
  { title: 'Top Profile Elements', viewType: ReportViewType.PIE_CHART, type: 'topProfileElements' },
];

export const chatbotsCheckList = [
  { title: 'Total Chatbots', viewType: ReportViewType.COUNT, type: 'totalChatbots' },
  { title: 'Average Assignments', viewType: ReportViewType.COUNT, type: 'averageAssignments' },
  { title: 'Last Assigned', viewType: ReportViewType.COUNT, type: 'lastAssigned' },
  { title: 'Updated in the last 30 days', viewType: ReportViewType.LINE_CHART, type: 'chatbotsInLast30Days' },
  { title: 'Most Completed', viewType: ReportViewType.TABLE, type: 'mostCompleted' },
  { title: 'Most Members', viewType: ReportViewType.TABLE, type: 'mostMembers' },
  { title: 'Chatbots List', viewType: ReportViewType.PIE_CHART, type: 'chatbotsList' },
];

export const evaluationsCheckList = [
  { title: 'Total Evaluations', viewType: ReportViewType.COUNT, type: 'totalEvaluations' },
  { title: 'Total Assignments', viewType: ReportViewType.COUNT, type: 'totalAssignments' },
  { title: 'Last Assigned', viewType: ReportViewType.COUNT, type: 'lastAssigned' },
  {
    title: 'Evaluations in the last 30 days',
    viewType: ReportViewType.LINE_CHART,
    type: 'updatedInLast30Days',
  },
  { title: 'By Most Completed', viewType: ReportViewType.TABLE, type: 'mostCompleted' },
  { title: 'By Most Members', viewType: ReportViewType.TABLE, type: 'mostMembers' },
  { title: 'Evaluations List', viewType: ReportViewType.PIE_CHART, type: 'evaluationList' },
];

export const loginsCheckList = [
  { title: 'Total Logins', viewType: ReportViewType.COUNT, type: 'totalLogins' },
  { title: 'Average Logins', viewType: ReportViewType.COUNT, type: 'averageLogins' },
  { title: 'Last Login', viewType: ReportViewType.COUNT, type: 'lastLogin' },
  { title: 'Logins last 30 days', viewType: ReportViewType.LINE_CHART, type: 'updatedInLast30Days' },
  { title: 'Most Logins', viewType: ReportViewType.TABLE, type: 'mostLogins' },
  { title: 'Fewest Logins', viewType: ReportViewType.TABLE, type: 'fewestLogins' },
  { title: 'Most recent logins', viewType: ReportViewType.TABLE, type: 'mostRecentLogins' },
  { title: 'Most time since last login', viewType: ReportViewType.TABLE, type: 'mostSinceLastLogin' },
];
