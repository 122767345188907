import * as yup from 'yup';

export const AddMedicationSchema = yup.object({
  medication: yup.string().required('Medication is required'),
  dosage: yup.string().required('Dosage is required'),
  frequency: yup.string().required('Frequency is required'),
  // symptoms: yup.array().of(yup.string()).min(1, 'Symptoms is required'),
  provider: yup.string().required('Prescribing Provider is required'),
  confidantProvider: yup.string().when('provider', {
    is: 'Confidant Provider',
    then: yup.string().required('Confidant Provider is required'),
  }),
  providerName: yup.string().when('provider', {
    is: 'External Provider',
    then: yup.string().required('Provider Name is required'),
  }),
  status: yup.string().required('Status is required'),
  additionalNotes: yup.string().required('Additional notes for team is required'),
});
