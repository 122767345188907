import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnSize, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Rating } from '@confidant-health/lib/ui/atoms/rating';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import {
  AppointmentStatus,
  AppointmentStatusBagde,
  AppointmentStatusText,
  AppointmentType,
  SignOffStatus,
} from '@confidant-health/lib/constants/CommonConstants';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { useReactToPrint } from 'react-to-print';

import history from 'utils/history';
import { profileActionCreators } from 'redux/modules/profile';
import { scheduleActionCreators } from 'redux/modules/schedule/actions';
import { appointmentActionCreators } from 'redux/modules/appointment/actions';
import { ISearchAppointmentParams, getAppointments } from 'services/appointment/appointment.service';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';

import AddSchedule, { INewSchedulePayload } from 'pages/admin/appointments/add-schedule';
import AppointmentDetail from 'pages/provider/appointments/components/appointment-detail';
import AppointmentScheduling from 'pages/provider/appointments/components/appointment-scheduling';
import AppointmentCancel from 'pages/provider/appointments/components/appointment-cancel';
import AppointmentsDrawer from 'pages/provider/appointments/components/appointments-drawer';
import { getTimeZoneText } from 'constants/CommonConstants';
import { searchParamsType } from '@confidant-health/lib/ui/organisms/table/search';
import { getUserTimeZone } from 'utils/dayjs';
import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from '../../../../../redux/modules/auth/selectors';
import * as actionType from '../../../../../redux/modules/appointment/actions';
import { conversationActionCreators } from '../../../../../redux/modules/conversation';
import { selectEvaluation } from '../../../../../redux/modules/conversation/selectors';
import AppointmentGroupButton from '../../../appointments/components/AppointmentGroupButton';
import AppointmentCardv2 from './AppointmentCardv2';
import {
  pastAppointmentColumns,
  appointmentFilterStatusOptions,
} from '../../../appointments/AppointmentList.constants';
import { AppointmentTitles, pastAppointmentsFilterOption } from './Appointments.constants';
import PriorityEmpty from '../PriorityEmpty';

import { useStyles } from '../../MemberDetail.styles';
import { isAppointmentDone } from '../../../../../utils/CommonUtils';

interface IAppointmentsProps {
  selectedScreen: string;
  toggle?: (screen: string) => void;
}

const ProviderAppointments: React.FC<IAppointmentsProps> = ({ selectedScreen, toggle }) => {
  const classes = useStyles({});
  const { isAdmin } = useSelector(getAuth);
  const { pathname } = useLocation();
  const { profile } = useSelector(getProfile);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const [appointmentsCount] = useState(0);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedAppointmentList, setSelectedAppointmentList] = useState<IAppointment[]>([]);
  const [showCancel, setShowCancel] = useState(false);
  const [viewAppointmentDetail, setViewAppointmentDetail] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const providerSchedule = useSelector(selectProviderSchedule);
  const [isPastAppointments, setIsPastAppointments] = useState(selectedScreen === 'appointments-past');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentSize, setCurrentSize] = useState(50);
  const [formatedAppointmentForDownload] = useState(null);
  const [openTableToPrintModal, setOpenTableToPrintModal] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const evaluations = useSelector(selectEvaluation);
  const [totalAppointments, setTotalAppointmentss] = useState(0);

  const [resetMultiSelectFilter] = useState(false);
  const [screenChangeLoading] = useState(false);
  const [searchParams] = useState({} as searchParamsType);
  const [value, setValue] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: 'startTime' },
  });

  const openTableToPrint = () => {
    setOpenTableToPrintModal(true);
  };

  const closeTableToPrint = () => {
    setOpenTableToPrintModal(false);
  };
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => {
      const currentComponent = componentRef.current as any;
      currentComponent.style.overflow = 'visible';
      return currentComponent;
    },
  });
  const onViewClick = (appointment: IAppointment) => {
    if (appointment.status === AppointmentStatus.FULFILLED) {
      dispatch(profileActionCreators.updateAppointmentNote(appointment));
      if (appointment.signOffStatus === 'DRAFTED') {
        history.push(`/provider/appointments/current/${appointment.appointmentId}/session-signoff/v2`);
      } else {
        history.push(`${pathname}/appointments/${appointment.appointmentId}`);
      }
    } else {
      setSelectedAppointment(appointment);
      setViewAppointmentDetail(true);
    }
  };
  // const formatAppointmentsForDownload = (
  //   downloadAppointmentList: IDownloadAppointment[],
  //   medications: IDownloadMedication[],
  //   diagnosis: IDownloadDiagnosis[],
  //   notes: any[],
  // ) => {
  //   if (!downloadAppointmentList || downloadAppointmentList.length === 0) {
  //     setIsExportDisabled(true);
  //     return;
  //   }
  //   if (downloadAppointmentList) {
  //     memberService
  //       .getAdminProfile({ userId: memberId || '' })
  //       .then(patientResponse => {
  //         setFormatedAppointmentForDownload(
  //           <div ref={componentRef} className={classes.documentToPrint}>
  //             <div className={classes.coverPage}>
  //               <div className={classes.coverDownlad}>
  //                 <div className={classes.topBox}>
  //                   <p className={classes.c4}>FOR CLINICAL USE ONLY</p>
  //                 </div>
  //                 <div className={classes.middleBox}>
  //                   <p className={classes.c4}>
  //                     <span className={classes.imageBox}>
  //                       <img alt="" src={logo} className={classes.imageTag} title="" />
  //                     </span>
  //                   </p>
  //                   <p className={classes.c2}>Patient Clinical Notes Export</p>
  //                 </div>
  //                 <div className={classes.bottomBox}>
  //                   <p className={classes.c5}>Confidential:</p>
  //                   <div className={classes.bottomInnerBox}>
  //                     <p className={classes.c1}>
  //                       These clinical notes contain sensitive patient information. If received in error,
  //                       please notify us immediately and destroy all copies. Unauthorized access, disclosure,
  //                       or use is prohibited by law and subject to penalties.
  //                     </p>
  //                     <p className={classes.c1}>
  //                       Protect patient privacy, it&#39;s our shared responsibility.
  //                     </p>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             {/* Patient information */}
  //             <div className={classes.sectionBox}>
  //               <p className={classes.title}>FOR CLINICAL USE ONLY</p>
  //               <div className={classes.exportDate}>
  //                 <p className={classes.textRight}>Date of Export: {dayjs().format(GLOBAL_DATE_FORMAT)}</p>
  //               </div>

  //               <p className={classes.question}>
  //                 <b>
  //                   Patient:{' '}
  //                   <span className={classes.answer}>
  //                     {patientResponse.data?.firstName || patientResponse.data?.lastName
  //                       ? `${patientResponse.data?.firstName || ''} ${patientResponse.data?.lastName || ''}`
  //                       : patientResponse.data?.fullName ?? ''}{' '}
  //                   </span>
  //                 </b>
  //               </p>
  //               <p className={`${classes.question} ${classes.cptList}`}>
  //                 <b>
  //                   Date of birth:{' '}
  //                   {patientResponse.data?.dob
  //                     ? dayjs(patientResponse.data?.dob).format(GLOBAL_DATE_FORMAT)
  //                     : ''}
  //                 </b>
  //               </p>
  //             </div>

  //             {/* Diagnosis */}
  //             {diagnosis?.length > 0 && (
  //               <div className={classes.sectionBoxTop}>
  //                 <p className={classes.sectionTitleLeft}>Diagnoses:</p>
  //                 <ul>
  //                   {diagnosis?.map((diag, index) => (
  //                     <li key={`diag-${index}`} className={classes.appointmentBox}>
  //                       <p className={classes.sectionSubTitleLeft}>{diag.elementName}</p>
  //                       <ul>
  //                         <li className={classes.question}>Importance level: {diag.importanceLevel?.name}</li>
  //                         <li className={classes.question}>ICD10 code: {diag.selectedIct10code}</li>
  //                         <li className={classes.question}>Report notes: {diag.notes}</li>
  //                       </ul>
  //                     </li>
  //                   ))}
  //                 </ul>
  //               </div>
  //             )}

  //             {/* Medications */}
  //             {medications?.length > 0 && (
  //               <div className={cx(classes.sectionBoxPageBreakBefore, classes.paddingTop10)}>
  //                 <p className={classes.sectionTitleLeft}>Medications:</p>
  //                 <ul>
  //                   {medications?.map((medic, index) => (
  //                     <li key={`medic-${index}`} className={classes.appointmentBox}>
  //                       <p className={classes.sectionSubTitleLeft}>{medic.elementName}</p>
  //                       <ul>
  //                         <li className={classes.question}>
  //                           Importance level: {medic.importanceLevel?.name}
  //                         </li>
  //                         <li className={classes.question}>Dosage: {medic.dosage}</li>
  //                         <li className={classes.question}>Frequency: {medic.frequency}</li>
  //                         <li className={classes.question}>
  //                           Prescribing Provider:{' '}
  //                           {medic.provider?.providerName ?? medic.provider?.confidantProviderName}
  //                         </li>
  //                         <li className={classes.question}>Report notes: {medic.notes}</li>
  //                       </ul>
  //                     </li>
  //                   ))}
  //                 </ul>
  //               </div>
  //             )}

  //             {/* Appointments */}
  //             {downloadAppointmentList?.length > 0 && (
  //               <div className={classes.sectionBox}>
  //                 <p className={classes.sectionTitleCenter}>Appointments</p>
  //                 {downloadAppointmentList?.map((appointmnt, index) => (
  //                   <div
  //                     key={`appointment-${index}`}
  //                     className={cx({
  //                       [classes.sectionBoxPageBreakAfter]: true,
  //                       [classes.paddingTop10]: index >= 1,
  //                     })}
  //                   >
  //                     <p className={classes.sectionSubTitleLeft}>{appointmnt.serviceName}</p>
  //                     <div>
  //                       <p className={classes.question}>Appointment Start Date and Time:</p>
  //                       <p className={classes.cptList}>
  //                         {dayjs(appointmnt.appointmentTime).format(`${GLOBAL_DATE_FORMAT} hh:mmA`)}
  //                       </p>
  //                       <p className={classes.question}>Clinician: {appointmnt.clinician}</p>
  //                       <p className={classes.question}>Signoff Status: {appointmnt.signOffStatus}</p>
  //                       {appointmnt?.signOffAt && (
  //                         <p className={classes.question}>
  //                           Signoff Date and Time:{' '}
  //                           {dayjs(appointmnt.signOffAt).format(`${GLOBAL_DATE_FORMAT} hh:mmA`)}
  //                         </p>
  //                       )}
  //                       <div className={classes.question}>
  //                         Codes billed:{' '}
  //                         {appointmnt.cptCodes?.cptList?.map((cpt, indx) => (
  //                           <div key={`cpt-${indx}`}>
  //                             <p className={classes.answer}>{cpt.cptCode}</p>
  //                             <div className={classes.codes}>
  //                               <ul>
  //                                 <li className={`${classes.question} ${classes.cptList}`}>
  //                                   Description: {cpt.diagnosis}
  //                                 </li>
  //                                 <li className={classes.question}>Related diagnosis: {cpt.icd10Code}</li>
  //                               </ul>
  //                             </div>
  //                           </div>
  //                         ))}
  //                       </div>
  //                     </div>
  //                     <div>
  //                       {appointmnt.evaluations?.map((eva, ind) => (
  //                         <div key={`evaluation-${ind}`} className={classes.evaluationBox}>
  //                           <p className={classes.sectionSubTitleLeft}>{eva.name}</p>
  //                           <ul>
  //                             {eva.dctList?.map((dct, indx) => (
  //                               <li key={`dct-${indx}`} className={classes.dctBox}>
  //                                 <p className={classes.question}>
  //                                   {dct.dctName} {dct.dctScore ? `- Score: ${dct.dctScore}` : ''}
  //                                 </p>
  //                                 <div className={classes.qnaBox}>
  //                                   <ul>
  //                                     {dct.dctQuestionAnswer?.map((qna, i) => (
  //                                       <li key={`qna-${i}`}>
  //                                         <p className={classes.question}>
  //                                           {qna.question}{' '}
  //                                           <ul>
  //                                             {qna?.answer?.map((ans, indexNumber) => (
  //                                               <li key={`ans-${indexNumber}`} className={classes.answer}>
  //                                                 {ans}
  //                                                 {qna.providerNarrative && (
  //                                                   <ul>
  //                                                     <li className={classes.question}>
  //                                                       Provider Narative:
  //                                                       <ul>
  //                                                         <li className={classes.answer}>
  //                                                           {qna.providerNarrative}
  //                                                         </li>
  //                                                       </ul>
  //                                                     </li>
  //                                                   </ul>
  //                                                 )}
  //                                               </li>
  //                                             ))}
  //                                           </ul>
  //                                         </p>
  //                                       </li>
  //                                     ))}
  //                                   </ul>
  //                                 </div>
  //                               </li>
  //                             ))}
  //                           </ul>
  //                         </div>
  //                       ))}
  //                     </div>
  //                   </div>
  //                 ))}
  //               </div>
  //             )}

  //             {/* Additional Notes */}
  //             {notes?.length > 0 && (
  //               <div className={classes.sectionBox}>
  //                 <p className={classes.sectionTitleCenter}>Additional Notes</p>
  //                 {notes?.map((note, index) => (
  //                   <div
  //                     key={`note-${index}`}
  //                     className={cx({
  //                       [classes.sectionBoxPageBreakAfter]: true,
  //                       [classes.paddingTop10]: index >= 1,
  //                     })}
  //                   >
  //                     <p className={classes.sectionSubTitleLeft}>{note.name}</p>
  //                     <div>
  //                       <p className={classes.cptList}>Type: {note.type}</p>
  //                       <p className={classes.cptList}>Status: {note.status}</p>
  //                       <p className={classes.question}>Auther: {note.createdBy?.name ?? ''}</p>
  //                       <p className={classes.cptList}>
  //                         Updated on: {dayjs(note.lastModified).format(`${GLOBAL_DATE_FORMAT} hh:mmA`)}
  //                       </p>
  //                       <p className={classes.question}>Additional notes:</p>
  //                       <p className={classes.cptList}>{note.description}</p>
  //                     </div>
  //                   </div>
  //                 ))}
  //               </div>
  //             )}
  //           </div>,
  //         );
  //         setIsExportDisabled(false);
  //       })
  //       .catch(err => {
  //         console.log({ err });
  //         setIsExportDisabled(true);
  //       });
  //   }
  // };
  const onStartSession = (appointment: IAppointment) => {
    if (appointment.status === AppointmentStatus.BOOKED && !isAdmin) {
      history.push(`/provider/appointments/current/${appointment.appointmentId}/session`);
    }
    if (appointment.status === AppointmentStatus.NEEDS_ACTION) {
      dispatch(
        appointmentActionCreators.acceptAppointment({
          appointmentId: appointment.appointmentId,
          callback: (isSucceed: boolean) => {
            if (isSucceed) {
              // forceRefresh: true
              // fetch the latest record from the database
              // instead of querying an internal map
              fetchAppointments();
            }
          },
        }),
      );
      onCloseAppointmentList();
      onCloseDrawer();
    }
  };

  // const multiSelectFilterOptions = useMemo(() => {
  //   if (searchParams?.multiSelectFilter && Object.keys(searchParams?.multiSelectFilter)?.length > 0) {
  //     setResetMultiSelectFilter(true);
  //   }
  //   setScreenChangeLoading(true);
  //   setTimeout(() => {
  //     setScreenChangeLoading(false);
  //   }, 1000);
  //   switch (selectedScreen?.split('-')[1]) {
  //     case AppointmentType.CURRENT:
  //       return currentAppointmentsFilterOption;
  //     case AppointmentType.PAST:
  //       return pastAppointmentsFilterOption;
  //     default:
  //       return defaultAppointmentFilterOptions;
  //   }
  // }, [selectedScreen]);

  useEffect(() => {
    setIsPastAppointments(selectedScreen === 'appointments-past');
    setValue({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: { direction: 'asc', column: 'startTime' },
    });
  }, [selectedScreen]);

  useEffect(() => {
    if (isPastAppointments) {
      toggle('appointments-past');
    } else {
      toggle('appointments-current');
    }
  }, [isPastAppointments]);

  useEffect(() => {
    fetchAppointments();
  }, [value, searchParams]);

  const generateMultiselectParams = () => {
    const queryParams: ISearchAppointmentParams = {
      participantIds: memberId,
      type:
        selectedScreen?.split('-')[1] === AppointmentType.PAST
          ? 'PAST'
          : selectedScreen?.split('-')[1] === AppointmentType.CURRENT
          ? 'PENDING,CURRENT'
          : 'PENDING',
      page: value.pagination.currentPage - 1,
      size: value.pagination.rowsPerPage,
      orderBy: 'desc',
      sortBy: 'startTime',
      statuses: '',
      states: null,
      searchQuery: value.search.searchKey || '',
    };

    if (selectedScreen?.split('-')[1] === AppointmentType.PAST) {
      queryParams.orderBy = 'desc';
      queryParams.includeOtherProviders = true;
    }
    const multiselectFilters = value.search?.multiSelectFilter;
    if (multiselectFilters && Object.keys(multiselectFilters)?.length > 0) {
      if (multiselectFilters.State?.length > 0) {
        queryParams.states = multiselectFilters.State?.toString() ?? '';
      }
      if (multiselectFilters.Role?.length > 0) {
        queryParams.practitionerDesignations = multiselectFilters.Role?.toString() ?? '';
      }
      if (
        selectedScreen?.split('-')[1] === AppointmentType.CURRENT &&
        multiselectFilters.Status?.length > 0
      ) {
        if (multiselectFilters.Status[0] === 'Scheduled') {
          queryParams.dateStatus = 'NOT_TODAY';
        } else if (multiselectFilters.Status[0] === 'Today') {
          queryParams.dateStatus = 'TODAY';
        }
        queryParams.timeZone = getUserTimeZone();
      } else if (
        selectedScreen?.split('-')[1] === AppointmentType.PAST &&
        multiselectFilters.Status?.length > 0
      ) {
        queryParams.statuses = multiselectFilters.Status?.toString() ?? '';
      }
    }
    if (!queryParams.states) {
      delete queryParams.states;
    }
    if (value?.search?.dateFilter?.startDate) {
      queryParams.startTime = dayjs(value?.search?.dateFilter?.startDate || '')?.toISOString();
    }
    if (value?.search?.dateFilter?.endDate) {
      queryParams.endTime = dayjs(value?.search?.dateFilter?.endDate || '')?.toISOString();
    }

    return { queryParams };
  };

  const fetchAppointments = () => {
    setAppointments([]);
    const { queryParams } = generateMultiselectParams();
    setIsLoading(true);
    if (!providerSchedule && !isAdmin) {
      dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: profile.providerId }));
    }
    getAppointments(queryParams)
      .then(({ data }) => {
        dispatch({
          type: actionType.FETCH_APPOINTMENTS_SUCCESSFUL,
          payload: {
            appointments: data.results.map(item => ({ ...item, participantName: item.participantName })),
            currentPage: data?.page,
            totalPages: Math.ceil(data?.total / data?.size),
            total: data?.total,
            loadingMore: false,
          },
        });
        setAppointments(data.results.map(item => ({ ...item, participantName: item.participantName })));
        setTotalAppointmentss(data.total);
        setIsLoading(false);
      })
      .catch(err => console.log({ err }));
  };

  const toggleAddSchedule = () => {
    if (selectedDateTime) {
      setSelectedDateTime(null);
    } else {
      setShowAddSchedule(!showAddSchedule);
    }
  };

  const fetchAppointments2 = (providerId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: '',
      type: 'PENDING,CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  // Please remove unused code, this function is called only in AppointmentList.tsx
  const onSubmitNewSchedule = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: payload,
        callback: (isSucceed: boolean, isInstantSession: boolean, appointmentId: string) => {
          if (isSucceed) {
            toggleAddSchedule();
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  const appointmentss = useMemo(() => {
    const formatAppointments = (appointments || []).map(item => ({
      ...item,
      provider: {
        id: item.practitionerId,
        appointmentId: item.appointmentId,
        nickName: item.practitionerName,
        fullName: `${item.practitionerFirstName || ''} ${item.practitionerLastName || ''}`,
        photo: item.practitionerImage,
        role: item.practitionerDesignation,
      },
      appointmentStatus: {
        patientStatus: item.patientStatus,
        practitionerStatus: item.practitionerStatus,
        statusChangingAuthority: item.statusChangingAuthority,
        status: item.status,
        time: item.startTime,
      },
      appointmentTime: {
        timezone: item.providerTimezone,
        startTime: item.startTime,
        endTime: item.endTime,
        isRecurring: !!item.recurringType,
      },
      sessionType: item.serviceType || item.serviceName,
      action: {
        ...item,
        disabled: isAppointmentDone(item.status),
      },
      rating: {
        rating: item.feedback?.rating,
      },
    }));
    return formatAppointments;
  }, [appointments]);

  const renderColumns = useCallback(() => {
    return pastAppointmentColumns.map(column => {
      if (column.id === 'provider') {
        return {
          ...column,
          renderCell: ({ photo, nickName, fullName, role }: any) => (
            <ProfileInfo
              type="provider"
              photo={photo}
              nickName={nickName}
              fullName={fullName}
              role={role}
              isProvider={!isAdmin}
            />
          ),
        };
      }
      if (column.id === 'appointmentStatus') {
        return {
          ...column,
          renderCell: ({ status, time, practitionerStatus, patientStatus, statusChangingAuthority }: any) => {
            const isToday = dayjs(time).isSame(dayjs(), 'day');
            let statusText = AppointmentStatusText[status];

            if (status === AppointmentStatus.BOOKED) {
              statusText = isToday ? 'Today' : 'Scheduled';
            } else if (status === AppointmentStatus.CANCELLED) {
              if (patientStatus === AppointmentStatus.DECLINED) {
                statusText = 'Cancelled - M';
              } else if (practitionerStatus === AppointmentStatus.DECLINED) {
                statusText = 'Cancelled - P';
              }
            }

            if (status === AppointmentStatus.NO_SHOW) {
              if (statusChangingAuthority === 'PATIENT') {
                statusText = 'No Show - M';
              } else if (statusChangingAuthority === 'PRACTITIONER') {
                statusText = 'No Show - P';
              }
            }

            return (
              <Badge
                variant={badgeType.FILLED}
                style={
                  isToday
                    ? badgeStyle.MISREPORTED
                    : ((AppointmentStatusBagde[status] || badgeStyle.UNRELATED) as badgeStyle)
                }
                className={classes.status}
              >
                {statusText?.toLowerCase()?.includes('cancel') ? (
                  <span className={classes.cancelledStatusText}>{statusText}</span>
                ) : (
                  statusText
                )}
              </Badge>
            );
          },
        };
      }
      if (column.id === 'appointmentTime') {
        return {
          ...column,
          renderCell: ({ startTime, endTime, timezone, isRecurring }: any) => (
            <div className={classes.timeWrap}>
              <div className={clsx(classes.date, classes.row)}>
                {dayjs(startTime)
                  .tz(providerSchedule?.timezone || getUserTimeZone())
                  .format('MM/DD/YYYY')}
                {isRecurring && <Icons glyph="recurring" color={colors.secondary500} />}
              </div>
              <div className={classes.date}>{getTimeZoneText(timezone) ?? ''}</div>
              <div className={classes.time}>
                {dayjs(startTime)
                  .tz(providerSchedule?.timezone || getUserTimeZone())
                  .format('h:mm a')}{' '}
                -{' '}
                {dayjs(endTime)
                  .tz(providerSchedule?.timezone || getUserTimeZone())
                  .format('h:mm a')}
              </div>
            </div>
          ),
        };
      }
      if (column.id === 'rating') {
        return {
          ...column,
          renderCell: ({ rating }) => {
            return <Rating value={rating || 0} className={classes.rating} showValue={false} />;
          },
        };
      }
      if (column.id === 'action') {
        return {
          ...column,
          renderCell: () => {
            return <ArrowForwardIosIcon sx={{ color: colors.neutral400, width: 20, height: 20 }} />;
          },
        };
      }
      return column;
    });
  }, [appointmentss]);

  // const onDateClick = (datetime: string) => {
  //   const format = 'YYYY-MM-DD';
  //   const selectedDate = dayjs(datetime).format(format);
  //   const now = dayjs().format(format);

  //   if (dayjs(selectedDate, format).unix() >= dayjs(now, format).unix()) {
  //     setSelectedDateTime(datetime);
  //   }
  // };

  const onCloseDrawer = () => {
    setSelectedAppointment(null);
    setViewAppointmentDetail(false);
  };

  const toggleCancelDrawer = () => {
    setShowCancel(!showCancel);
  };

  const toggleRescheduleDrawer = () => {
    setShowReschedule(!showReschedule);
  };

  const onCloseAppointmentList = () => {
    setSelectedAppointmentList([]);
  };

  // const onClickAppointmentOnCalendar = (items: IAppointment[]) => {
  //   if (items.length === 1) {
  //     setSelectedAppointment(items[0]);
  //   } else {
  //     setSelectedAppointmentList(items);
  //   }
  // };

  const updateAppointment = (appointment: IAppointment) => {
    setSelectedAppointment(appointment);
    setAppointments(s =>
      s.map(item => (item.appointmentId === appointment.appointmentId ? appointment : item)),
    );
  };

  const selectAppointment = appointment => {
    setSelectedAppointment(appointment);
  };

  const onLoadMoreClick = () => {
    setIsLoadingMore(true);
    setPageNumber(pageNumber + 1);
    setValue({
      ...value,
      pagination: {
        currentPage: 1,
        rowsPerPage: value.pagination.rowsPerPage + 10,
      },
    });
    void fetchAppointments();
    setCurrentSize(currentSize + 50);
    setIsLoadingMore(false);
  };

  const viewDetail = appt => {
    setSelectedAppointment(appt);
    setViewAppointmentDetail(true);
  };

  const handleSupervisorSignOff = appt => {
    history.push(`/provider/members/${appt.patientId}/appointments/${appt.appointmentId}`);
  };

  useEffect(() => {
    dispatch(conversationActionCreators.fetchEvaluations());
    void fetchAppointments();

    setIsExportDisabled(true);
    // appointmentsDownload(memberId, downloadDateRange.startDate, downloadDateRange.endDate)
    //   .then(res => {
    //     const downloadAppiontmentList = res.data?.data?.appointmentDetails?.map(appt => {
    //       return {
    //         ...appt,
    //         evaluations: appt.evaluations?.map(eva => {
    //           return {
    //             ...eva,
    //             dctList: [
    //               ...eva.detail
    //                 ?.filter(item => item.question && item.answer)
    //                 ?.map(eveQnA => {
    //                   return {
    //                     ...eveQnA,
    //                     dctId: '',
    //                     dctName: '',
    //                     dctQuestionAnswer: [
    //                       {
    //                         ...eveQnA,
    //                         answer: eveQnA.answer?.userResponseList,
    //                       },
    //                     ],
    //                   };
    //                 }),
    //               ...eva.detail
    //                 ?.filter(item => item.dctName)
    //                 ?.map(dctItem => {
    //                   return {
    //                     ...dctItem,
    //                     dctId: '',
    //                     dctQuestionAnswer: dctItem.questionAnswer
    //                       ?.filter(item => item.answer)
    //                       ?.map(item => {
    //                         return {
    //                           ...item,
    //                           providerNarrative: item.answer?.providerNarrative,
    //                           answer: item.answer?.userResponseList,
    //                         };
    //                       }),
    //                   };
    //                 }),
    //             ],
    //           };
    //         }),
    //       };
    //     });
    //
    //     const downloadMedications = res.data?.data?.associatedTags?.medication?.map(med => {
    //       return {
    //         ...med,
    //         dose: med.tagMetaData?.rxDrugInfo?.dose,
    //         dosage: med.tagMetaData?.rxDrugInfo?.dosage,
    //         frequency: med.tagMetaData?.rxDrugInfo?.frequency,
    //         provider: {
    //           confidantProviderName: med.tagMetaData?.rxDrugInfo?.confidantProviderName,
    //           providerName: med.tagMetaData?.rxDrugInfo?.providerName,
    //           prescribingProvider: med.tagMetaData?.rxDrugInfo?.prescribingProvider,
    //         },
    //       };
    //     });
    //     const downloadDiagnosis = res.data?.data?.associatedTags?.diagnosis;
    //     memberService
    //       .getMemberNotes(
    //         { userId: memberId },
    //         { startDate: downloadDateRange.startDate, endDate: downloadDateRange.endDate },
    //       )
    //       .then(resp => {
    //         const formatedNotes =
    //           resp.data?.patientContactNotes?.length > 0 ? resp.data?.patientContactNotes : [];
    //         formatAppointmentsForDownload(
    //           downloadAppiontmentList,
    //           downloadMedications,
    //           downloadDiagnosis,
    //           formatedNotes,
    //         );
    //       })
    //       .catch(err => {
    //         formatAppointmentsForDownload(
    //           downloadAppiontmentList,
    //           downloadMedications,
    //           downloadDiagnosis,
    //           null,
    //         );
    //         console.warn(err);
    //       });
    //   })
    //   .catch(err => {
    //     setIsExportDisabled(true);
    //     console.log({ err });
    //   });
  }, [selectedScreen, searchParams]);
  const resetOverflow = () => {
    const currentComponent = componentRef.current as any;
    currentComponent.style.overflow = 'scroll';
    setOpenTableToPrintModal(false);
  };
  return (
    <>
      <Modal open={openTableToPrintModal} onClose={closeTableToPrint}>
        <Box className={classes.modal}>
          <Box className={classes.modalContent}>
            <Box className={classes.content}>
              <Box className={classes.sectionAutoSize}>{formatedAppointmentForDownload}</Box>
              <Box display="flex" alignItems="center" gap={3} mt={3}>
                <Button
                  size={btnSize.SMALL}
                  onClick={() => {
                    handlePrint();
                    resetOverflow();
                  }}
                >
                  Export/Print
                </Button>
                <Button size={btnSize.SMALL} onClick={closeTableToPrint}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {(showAddSchedule || !!selectedDateTime) && (
        <AddSchedule
          isOpen={showAddSchedule || !!selectedDateTime}
          onClose={toggleAddSchedule}
          selectedDateTime={selectedDateTime}
          onSubmit={onSubmitNewSchedule}
          isSaving={isLoading}
          memberId={memberId}
          onClickReschedule={toggleRescheduleDrawer}
        />
      )}
      <AppointmentDetail
        open={viewAppointmentDetail}
        onClose={onCloseDrawer}
        onCancelClick={toggleCancelDrawer}
        onRescheduleClick={toggleRescheduleDrawer}
        onAcceptAppointment={onStartSession}
        appointment={selectedAppointment}
        evaluations={evaluations}
      />
      <AppointmentScheduling
        open={showReschedule}
        onClose={toggleRescheduleDrawer}
        appointment={selectedAppointment}
        updateAppointment={setSelectedAppointment}
        setIsApptCreated={isRefreshed => isRefreshed && void fetchAppointments()}
      />
      <AppointmentCancel
        open={showCancel}
        onClose={() => {
          toggleCancelDrawer();
          onCloseDrawer();
          void fetchAppointments();
        }}
        appointment={selectedAppointment}
        updateAppointment={updateAppointment}
      />
      <AppointmentsDrawer
        open={selectedAppointmentList?.length > 0}
        onClose={onCloseAppointmentList}
        appointments={selectedAppointmentList}
        onSelectAppointment={setSelectedAppointment}
      />
      <div className={classes.headingWithBtnWrapper}>
        <div className={classes.noteHeaderLeftSide}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            {AppointmentTitles[selectedScreen]}
          </Heading>
          <Badge variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED} className={classes.totalBadge}>
            {totalAppointments || appointments?.length || 0} total
          </Badge>
        </div>
        <div className={classes.noteHeaderRightSide}>
          <AppointmentGroupButton
            isPastAppointments={isPastAppointments}
            setIsPastAppointments={setIsPastAppointments}
          />
          {isAdmin && selectedScreen === 'appointments-past' && (
            <IconButton
              icon="export"
              variant={btnType.PRIMARY}
              className={classes.addBtn}
              onClick={openTableToPrint}
              disabled={isExportDisabled}
            >
              Export
            </IconButton>
          )}
        </div>
      </div>
      <div className={classes.noteCardWrapper} style={{ gap: 16 }}>
        <>
          {screenChangeLoading || !appointments ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            providerSchedule &&
            (isPastAppointments ? (
              <div className={classes.appointmentTable}>
                <Table
                  searchProps={{
                    placeholder: 'Search appointment by service name and member',
                    resetMultiSelectFilter,
                    filterProps: {
                      variant: Filter.tableFilterType.MULTIPLE,
                      options: appointmentFilterStatusOptions,
                      multiSelectOptions: pastAppointmentsFilterOption,
                      allOptionLabel: 'All',
                      dateFilter: { startDate: '', endDate: '' },
                      // endDateExists: false,
                    },
                  }}
                  gridProps={{
                    columns: renderColumns(),
                    data: appointmentss,
                    isLoading,
                    onRowClick: onViewClick,
                    emptyState: (
                      <PriorityEmpty
                        title="There are no past appointments for this member yet."
                        description=""
                        addText="Schedule appointment"
                        icon="calendar-primary-outlined"
                        glyph="lottie-current-and-past"
                        glyphOrientation="portrait"
                        isEmptyWithLottie
                        lottieSize={128}
                      />
                    ),
                  }}
                  paginationProps={{
                    currentRows: appointmentss.length,
                    totalCount: totalAppointments,
                    showRowsPerPage: false,
                  }}
                  value={value}
                  onChange={setValue}
                  className={classes.table}
                />
              </div>
            ) : appointments?.length ? (
              appointments?.slice(0, currentSize).map((item, idx) => (
                <AppointmentCardv2
                  isAdmin={isAdmin}
                  key={idx}
                  appointment={{
                    ...item,
                    startTime: item?.startTime,
                  }}
                  onViewClick={
                    isAdmin
                      ? item?.signOffStatus === SignOffStatus.DRAFTED ||
                        item?.status === AppointmentStatus.CANCELLED
                        ? null
                        : onViewClick
                      : onViewClick
                  }
                  viewDetail={
                    profile.signOffRole === 'SUPERVISOR' &&
                    item.signOffStatus === 'REVIEW' &&
                    item.status === 'FULFILLED'
                      ? handleSupervisorSignOff
                      : viewDetail
                  }
                  onStartSessionClick={onStartSession}
                  onRescheduleClick={toggleRescheduleDrawer}
                  selectAppointment={selectAppointment}
                  isRecurring={!!item.recurringType}
                  onGotoSignOff={appointment => {
                    if (appointment?.appointmentId)
                      history.push(
                        `/provider/appointments/current/${appointment.appointmentId}/session-signoff`,
                      );
                  }}
                  appointmentSection={
                    selectedScreen === 'appointments-past'
                      ? 'past'
                      : selectedScreen === 'appointments-pending'
                      ? 'pending'
                      : 'current'
                  }
                  showProvider
                />
              ))
            ) : (
              <Box sx={{ width: '100%' }}>
                <Box className={classes.card}>
                  <PriorityEmpty
                    title="There are no current appointments for this member yet."
                    description=""
                    glyph="lottie-current-and-past"
                    glyphOrientation="portrait"
                    isEmptyWithLottie
                    lottieSize={128}
                  />
                </Box>
              </Box>
            ))
          )}
          <Box display="flex" justifyContent="center" alignItems="center" className={classes.loadingWrap}>
            {isLoadingMore ? (
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            ) : (
              <>
                {totalAppointments >= value?.pagination?.rowsPerPage && !isPastAppointments && (
                  <Button variant={btnType.TEXT} className={classes.loadMoreBtn} onClick={onLoadMoreClick}>
                    Load more
                  </Button>
                )}
              </>
            )}
          </Box>
        </>
      </div>
    </>
  );
};

export default ProviderAppointments;
