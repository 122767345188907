import { ILevelOfEngagement, IProvider } from 'redux/modules/profile/types';
import { IPayer, IState } from 'redux/modules/state/types';
import { AutomationLogics, IAutomationEvent, IAutomationFilter } from '../../AddAutomation.types';

export interface IReportFilterFormProps {
  open?: boolean;
  filter: IAutomationFilter;
  onChange: (payload: IAutomationFilter) => void;
  onDelete: () => void;
}

export interface IAutomationFilterFormProps {
  open?: boolean;
  filter: AutomationLogics;
  onChange: (payload: AutomationLogics) => void;
  onDelete: () => void;
  profileElementList?: any[];
  tagList?: any[];
  event?: IAutomationEvent;
  filterLength?: any;
  isEditMode?: boolean;
  payersList?: IPayer[];
  providers?: IProvider[];
  levelOfEngagements?: ILevelOfEngagement[];
  states?: IState[];
  isLoadingPayers?: boolean;
}

export enum AutomationFilterRule {
  EQUALS = 'equals',
  NOT_EQUALS = 'not-equals',
  GREATER_THAN = 'greater-than',
  LESS_THAN = 'less-than',
  CONTAINS = 'contains',
  NOT_CONTAIN = 'not-contain',
  BEFORE_DATE = 'before-date',
  AFTER_DATE = 'after-date',
}
