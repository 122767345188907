// import React, { useEffect, useMemo, useState } from 'react';
// import { Box } from '@mui/material';
// import { useFormik } from 'formik';
// import clsx from 'clsx';

// import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
// import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
// import {
//   fontWeight,
//   Heading,
//   headingLevel,
//   Text,
//   TextError,
// } from '@confidant-health/lib/ui/atoms/typography';
// import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
// import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
// import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
// import { getDomainElements } from 'services/member/member.service';
// import { IDomainElement } from 'redux/modules/profile/types';
// // components
// import SelectLevel from '../../select-level';
// import { DiagnosBottomCard } from '../diagnoses-card/DiagnosBottomCard';
// // schema
// import { SymptomEditSchema } from './DiagnorsEditing.schema';
// // styles
// import { useStyles } from './DiagnorsEditing.styles';
// // types
// import { IDiagnosEditProps } from './DiagnorsEditing.types';

// const defaultValues = {
//   status: '',
//   notes: '',
//   isInterference: false,
//   selectedIct10code: '',
// };

// const DiagnorsEditing: React.FC<IDiagnosEditProps> = ({
//   title,
//   isOpen,
//   isLifeEvent,
//   onClose,
//   item,
//   isSaving,
//   error,
//   isNoDrawer,
//   onSubmit,
//   options,
// }) => {
//   console.log({ isLifeEvent });
//   const classes = useStyles();
//   const [importanceLevels, setImportanceLevels] = useState([]);
//   const [icd10Options, setIcd10Options] = useState([]);
//   const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
//     initialValues: { ...defaultValues },
//     validationSchema: SymptomEditSchema,
//     onSubmit,
//   });

//   // const icd10Options = useMemo(() => {
//   //   const domainEl = domainElements?.find((element: IDomainElement) => values.diagnosis === element.Id);
//   //   const icd10List = icd10
//   //     ?.filter(icdF => domainEl?.actions?.metaData?.metaDataValues?.icd10Codes?.includes(icdF?.code))
//   //     .map(icd10Val => {
//   //       return { label: `${icd10Val?.code}`, value: icd10Val.code };
//   //     });
//   //   if (icd10List?.length) {
//   //     values.icd10Code = icd10List[0].value;
//   //   }
//   //   return icd10List;
//   // }, [values.diagnosis]);

//   useEffect(() => {
//     if (item) {
//       getDomainElements({ domainTypeId: item.domainElementId })
//         .then(res => {
//           const tempImportanceLevels = [];
//           const tempICD10codes = [];
//           if (res.status === 200) {
//             Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
//               if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
//                 tempImportanceLevels.push({ value: key, label: key });
//               }
//             });
//             setImportanceLevels(tempImportanceLevels);

//             res?.data?.metaData?.metaDataValues?.icd10Codes?.forEach(icd10 => {
//               tempICD10codes.push({ value: icd10, label: icd10 });
//             });

//             setIcd10Options(tempICD10codes);
//             // Object.keys(res?.data?.metaData?.metaDataValues
//             //   ?.importanceLevels).forEach(key => {
//             //   if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
//             //     tempImportanceLevels.push({ value: key, label: key });
//             //   }
//             // });
//           }
//         })
//         .catch(err => {
//           console.log({ err });
//         });

//       // const selectedDiagnosis = options[0].relatedElements?.filter(
//       //   option => option?.Id === item?.domainElementId,
//       // );

//       // // const selectedRelatedElement = selectedDiagnosis[0]?.relatedElements?.filter(
//       // //   element => element.Id === item.item.value,
//       // // );

//       // if (selectedDiagnosis) {
//       //   const tempImportanceLevels = [];
//       //   Object.keys(selectedDiagnosis[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
//       //     if (selectedDiagnosis[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
//       //       tempImportanceLevels.push({ value: key, label: key });
//       //     }
//       //   });

//       //   setImportanceLevels(tempImportanceLevels);
//       // }

//       // console.log(newValue);
//     }
//   }, [item]);

//   useEffect(() => {
//     if (item) {
//       // eslint-disable-next-line @typescript-eslint/no-misused-promises
//       Object.keys(item).forEach(async key => {
//         if (key === 'tagMetaData') {
//           await setFieldValue('isInterference', item[key].interferenceInLife);
//         } else if (key === 'priority') {
//           await setFieldValue('status', item[key].name);
//         } else if (key === 'selectedIct10code') {
//           await setFieldValue('selectedIct10code', item[key]);
//         } else {
//           await setFieldValue(key, item[key]);
//         }
//       });
//     }
//   }, [item, icd10Options]);

//   useEffect(() => {
//     if (options.length > 0) {
//       const selectedDiagnosis = options[0].relatedElements?.filter(
//         option => option?.Id === item?.domainElementId,
//       );

//       // const selectedRelatedElement = selectedDiagnosis[0]?.relatedElements?.filter(
//       //   element => element.Id === item.item.value,
//       // );
//       // console.log('selected diagnosis', selectedRelatedElement);
//       const tempImportanceLevels = [];
//       Object.keys(selectedDiagnosis[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
//         if (selectedDiagnosis[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
//           tempImportanceLevels.push({ value: key, label: key });
//         }
//       });

//       setImportanceLevels(tempImportanceLevels);
//       // console.log(newValue);
//     }
//   }, [options]);

//   useEffect(() => {
//     if (!isOpen) {
//       rest.handleReset({ ...defaultValues });
//       rest.setErrors({});
//     }
//   }, [isOpen]);

//   const setTouched = (name: string) => async () => {
//     await rest.setTouched({ ...touched, [name]: true });
//   };

//   const renderLabel = (label: string) => (
//     <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
//       {label}
//     </Heading>
//   );

//   const renderContent = () => {
//     return (
//       <Box className={classes.wrapper}>
//         <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
//           <Box>
//             <Text weight={fontWeight.BOLD} className={classes.headTitle}>
//               {title} - {item.name}
//             </Text>
//             <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
//               {item.description}
//             </Text>
//           </Box>
//           <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
//         </Box>
//         <Box className={classes.headWrap}>
//           <DiagnosBottomCard item={item} showBadge isEditing />
//         </Box>
//         <form className={clsx(classes.form, { [classes.formNoDrawer]: isNoDrawer })} onSubmit={handleSubmit}>
//           <Box className={classes.formContent}>
//             {error && (
//               <Box display="flex" justifyContent="center">
//                 <TextError errorMsg={error} />
//               </Box>
//             )}
//             <Box className={classes.section}>
//               {renderLabel('Importance level')}
//               <SelectLevel
//                 value={values.status}
//                 options={importanceLevels}
//                 onChange={handleChange}
//                 isLifeEvent
//               />
//             </Box>
//             <Box className={classes.section}>
//               {renderLabel('ICD-10 Code')}
//               <Select
//                 name="selectedIct10code"
//                 value={values.selectedIct10code}
//                 variant={selectType.SECONDARY}
//                 options={icd10Options?.sort((a, b) => a.label.localeCompare(b.label)) || []}
//                 emptyText="Select item"
//                 displayEmpty
//                 onChange={handleChange}
//               />
//               <TextError errorMsg={touched.icd10Code ? errors.icd10Code?.toString() : null} />
//             </Box>
//             <Box className={classes.section}>
//               {renderLabel('Report notes')}
//               <Textarea
//                 value={values.notes}
//                 name="notes"
//                 onChange={handleChange}
//                 onBlur={setTouched('notes')}
//                 minRows={5}
//                 placeholder="Enter your notes"
//               />
//               <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
//             </Box>
//           </Box>
//           <Box className={classes.footer}>
//             <Button variant={btnType.TEXT} onClick={onClose}>
//               Cancel
//             </Button>
//             <Button onClick={handleSubmit} disabled={isSaving}>
//               Update
//             </Button>
//           </Box>
//         </form>
//       </Box>
//     );
//   };

//   if (isNoDrawer) {
//     return renderContent();
//   }

//   return (
//     <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
//       {item && renderContent()}
//     </Drawer>
//   );
// };

// export { DiagnorsEditing };

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { getDomainElements } from 'services/member/member.service';
import { DomainTypesEnum } from 'constants/CommonConstants';
// components
import SelectLevel from '../../select-level';
import { DiagnosBottomCard } from '../diagnoses-card/DiagnosBottomCard';
// schema
import { SymptomEditSchema } from './DiagnorsEditing.schema';
// styles
import { useStyles } from './DiagnorsEditing.styles';
// types
import { IDiagnosEditProps } from './DiagnorsEditing.types';

const defaultValues = {
  status: '',
  notes: '',
  isInterference: false,
  selectedIct10code: '',
};

const DiagnorsEditing: React.FC<IDiagnosEditProps> = ({
  title,
  isOpen,
  onClose,
  item,
  isSaving,
  error,
  isNoDrawer,
  onSubmit,
  type,
}) => {
  const classes = useStyles();
  const [importanceLevels, setImportanceLevels] = useState([]);
  const [icd10Options, setIcd10Options] = useState([]);
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: SymptomEditSchema,
    onSubmit,
  });

  // const icd10Options = useMemo(() => {
  //   const domainEl = domainElements?.find((element: IDomainElement) => values.diagnosis === element.Id);
  //   const icd10List = icd10
  //     ?.filter(icdF => domainEl?.actions?.metaData?.metaDataValues?.icd10Codes?.includes(icdF?.code))
  //     .map(icd10Val => {
  //       return { label: `${icd10Val?.code}`, value: icd10Val.code };
  //     });
  //   if (icd10List?.length) {
  //     values.icd10Code = icd10List[0].value;
  //   }
  //   return icd10List;
  // }, [values.diagnosis]);

  useEffect(() => {
    if (item) {
      getDomainElements({ domainTypeId: item.domainElementId })
        .then(res => {
          const tempImportanceLevels = [];
          const tempICD10codes = [];
          if (res.status === 200) {
            Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
              if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                tempImportanceLevels.push({ value: key, label: key });
              }
            });
            setImportanceLevels(tempImportanceLevels);

            res?.data?.metaData?.metaDataValues?.icd10Codes?.forEach(icd10 => {
              tempICD10codes.push({ value: icd10, label: icd10 });
            });

            setIcd10Options(tempICD10codes);
            // Object.keys(res?.data?.metaData?.metaDataValues
            //   ?.importanceLevels).forEach(key => {
            //   if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
            //     tempImportanceLevels.push({ value: key, label: key });
            //   }
            // });
          }
        })
        .catch(err => {
          console.log({ err });
        });

      // const selectedDiagnosis = options[0].relatedElements?.filter(
      //   option => option?.Id === item?.domainElementId,
      // );

      // // const selectedRelatedElement = selectedDiagnosis[0]?.relatedElements?.filter(
      // //   element => element.Id === item.item.value,
      // // );

      // if (selectedDiagnosis) {
      //   const tempImportanceLevels = [];
      //   Object.keys(selectedDiagnosis[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
      //     if (selectedDiagnosis[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
      //       tempImportanceLevels.push({ value: key, label: key });
      //     }
      //   });

      //   setImportanceLevels(tempImportanceLevels);
      // }

      // console.log(newValue);
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(item).forEach(async key => {
        if (key === 'tagMetaData') {
          await setFieldValue('isInterference', item[key].interferenceInLife);
        } else if (key === 'priority') {
          await setFieldValue('status', item[key].name);
        } else if (key === 'selectedIct10code') {
          await setFieldValue('selectedIct10code', item[key]);
        } else {
          await setFieldValue(key, item[key]);
        }
      });
    }
  }, [item, icd10Options]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderContent = () => {
    return (
      <Box className={classes.wrapper}>
        <Box className={clsx(classes.header, { [classes.headerNoDrawer]: isNoDrawer })}>
          <Box>
            <Text weight={fontWeight.BOLD} className={classes.headTitle}>
              {title} - {item.name}
            </Text>
            <Text weight={fontWeight.BOLD} className={classes.headSubtitle}>
              {item.description}
            </Text>
          </Box>
          <IconButton icon="close" className={classes.closeBtn} onClick={onClose} />
        </Box>
        <Box className={classes.headWrap}>
          <DiagnosBottomCard item={item} showBadge isEditing />
        </Box>
        <form className={clsx(classes.form, { [classes.formNoDrawer]: isNoDrawer })} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center">
                <TextError errorMsg={error} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Importance level')}
              <SelectLevel
                value={values.status}
                options={importanceLevels}
                onChange={handleChange}
                isLifeEvent
              />
            </Box>
            {type !== DomainTypesEnum.LIFE_EVENTS && (
              <Box className={classes.section}>
                {renderLabel('ICD-10 Code')}
                <Select
                  name="selectedIct10code"
                  value={values.selectedIct10code}
                  variant={selectType.SECONDARY}
                  options={icd10Options?.sort((a, b) => a.label.localeCompare(b.label)) || []}
                  emptyText="Select item"
                  displayEmpty
                  onChange={handleChange}
                />
                <TextError errorMsg={touched.icd10Code ? errors.icd10Code?.toString() : null} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Report notes')}
              <Textarea
                value={values.notes}
                name="notes"
                onChange={handleChange}
                onBlur={setTouched('notes')}
                minRows={5}
                placeholder="Enter your notes"
              />
              <TextError errorMsg={touched.notes ? errors.notes?.toString() : null} />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={isSaving}>
              Update
            </Button>
          </Box>
        </form>
      </Box>
    );
  };

  if (isNoDrawer) {
    return renderContent();
  }

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      {item && renderContent()}
    </Drawer>
  );
};

export { DiagnorsEditing };
