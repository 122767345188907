import { createAction } from 'redux-actions';

export const PERFORM_LOGIN = 'auth/performLogin';
export const LOGIN_SUCCESSFUL = 'auth/loginSuccessful';
export const LOGIN_FAILED = 'auth/loginFailed';
export const USER_LOGOUT = 'auth/logout';
export const REQUIRE_OTP = 'auth/requireOTP';
export const VERIFY_OTP = 'auth/verifyOTP';

export const VERIFY_FORGOT_PASSWORD_OTP = 'auth/verifyForgotPasswordOTP';
export const FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL = 'auth/forgotPasswordOTPVerificationSuccessful';
export const FORGOT_PASSWORD_OTP_VERIFICATION_FAILED = 'auth/forgotPasswordOTPVerificationFailed';

export const PROVIDER_UPDATE_PASSWORD = 'auth/PROVIDER_UPDATE_PASSWORD';
export const PROVIDER_PASSWORD_UPDATED = 'auth/PROVIDER_PASSWORD_UPDATED';
export const PROVIDER_UPDATE_PASSWORD_FAILED = 'auth/PROVIDER_UPDATE_PASSWORD_FAILED';

export const AUTHENTICATED_LINK_NAVIGATION = 'auth/AuthenticatedLinkNavigation';

export const authActionCreators = {
  login: createAction(PERFORM_LOGIN),
  verifyOTP: createAction(VERIFY_OTP),
  verifyForgotPasswordOTP: createAction(VERIFY_FORGOT_PASSWORD_OTP),
  updatePassword: createAction(PROVIDER_UPDATE_PASSWORD),
  logout: createAction(USER_LOGOUT),
  authenticatedLinkNavigation: createAction(AUTHENTICATED_LINK_NAVIGATION),
};
