import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  valuesInput: {
    padding: 0,
    '& > div': {
      '& input': {
        fontSize: '14px !important',
      },
    },
    '& .MuiAutocomplete-tag': {
      fontSize: 14,
    },
  },
  form: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 8,
    border: `1px solid ${colors.neutral100}`,
    padding: 24,
  },
  formContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    gap: 16,
  },
  btnFooter: {
    height: 40,
    fontSize: 14,
  },
  removeBtn: {
    color: colors.destructive500,
  },
});
