/* eslint-disable max-len */
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddNewReport from 'pages/provider/member-detail/components/AddNewReport';
import NewMedicationReport from 'pages/provider/member-detail/components/Medications/medication-new';
import NewSubstanceReport from 'pages/provider/member-detail/components/SubstanceUse/substance-use-new';
import { ISymptomPayload } from 'pages/provider/member-detail/components/Symptoms/symptom-card/SymptomCard.types';
import { ReportItem } from 'pages/provider/member-detail/components/AddNewReport/AddNewReport.types';
import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';

import { priorityListCard } from 'pages/provider/sessions/priority-list/PriorityList.constants';
import { stepTitles as diagnosesSteps } from 'pages/provider/member-detail/components/Diagnoses/Diagnoses.constants';
import { stepTitles as lifeEventSteps } from 'pages/provider/member-detail/components/LifeEvents/LifeEvent.constants';
import { stepTitles } from 'pages/provider/member-detail/components/AddNewReport/AddNewReport.constants';
import { stepTitles as socialSteps } from 'pages/provider/member-detail/components/SocialDeterminants/SocialDeterminants.constants';
import { DomainTypesEnum } from 'constants/CommonConstants';
import { IMedication } from 'pages/provider/member-detail/components/Medications/medication-card/MedicationCard.types';
import { ISubstanceUse } from 'pages/provider/member-detail/components/SubstanceUse/substance-use-card/SubstanceUseCard.types';
import { HistoryLookup } from 'redux/modules/profile/types';
import { getAuth } from 'redux/modules/auth/selectors';
import AddFactorDrawer, { DrawerType } from 'pages/provider-v2/member-detail/add-factor-drawer';

type Props = {
  sectionType: string;
  isNoDrawer?: boolean;
  memberId?: string;
  domainOptions?: ReportItem[];
  lookupData?: HistoryLookup;
  onCloseCard: (isSubmitted?: boolean) => void;
  onSelectInnerElementType?: (type: string) => void;
  isLoadingOptions?: boolean;
};

const CreateNewReport: FC<Props> = ({
  sectionType,
  isNoDrawer = false,
  memberId,
  onCloseCard,
  domainOptions = [],
  lookupData,
  onSelectInnerElementType,
  isLoadingOptions = false,
}) => {
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const { profile } = useSelector(getProfile);

  const onClose = () => {
    onCloseCard(false);
  };

  const onSubmitNewReport = (payload: ISymptomPayload[]) => {
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        selectedIct10code: item.icd10,
        metaData: {
          interferenceInLife: item.isInterference,
          notes: item.notes,
          selectedImportance: item.status,
        },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: (errorMsg?: string) => {
          if (!errorMsg) {
            onCloseCard(true);
          }
        },
      }),
    );
  };

  const onSubmitNewMedicationReport = (payload: IMedication[]) => {
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        metaData: {
          // interferenceInLife: item.isInterference,
          notes: item?.notes,
          selectedImportance: item?.status,
          rxDrugInfo: {
            dose: 0,
            doseUnit: '',
            doseFrequency: 0,
            duration: item?.medDuration,
            // refillable: true,
            // refill: item.tutorial.refillTimes.amount,
            supply: 0,
            supplyUnit: '',
            confidantProviderId: item?.tutorial?.confidantProviderId,
            confidantProviderName: item?.tutorial?.confidantProviderName,
            dosage: item?.tutorial?.dosage,
            prescribingProvider:
              item?.tutorial?.prescribingProvider === 'Confidant Provider' ? 'Internal' : 'External',
            providerName: item?.tutorial?.providerName,
            symptomsBeingTreated: item?.tutorial?.symptomsBeingTreated,
            frequency: item?.tutorial?.frequency,
          },
        },
        // rxDrugInfo: {
        //   confidantProviderId: payload?.confidantProvider,
        //   confidantProviderName: payload?.confidantProviderName,
        //   dosage: payload?.dosage,
        //   dose: 0,
        //   doseFrequency: 0,
        //   doseUnit: '',
        //   frequency: payload?.frequency,
        //   prescribingProvider: payload?.provider === 'Confidant Provider' ? 'Internal' : 'External',
        //   providerName: payload?.providerName,
        //   refillable: true,
        //   supply: 0,
        //   supplyUnit: '',
        //   symptomsBeingTreated: payload?.symptoms,
        // },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: (errorMsg?: string) => {
          if (!errorMsg) {
            onCloseCard(true);
          }
        },
      }),
    );
  };

  const onSubmitNewSubstanceUseReport = (payload: ISubstanceUse[]) => {
    const data = {
      patientId: memberId,
      tagItems: payload.map(item => ({
        assignedBy: profile.fullName || meta.nickName,
        domainElementId: item.item.value,
        metaData: {
          interferenceInLife: item.isInterference,
          notes: item.notes,
          selectedImportance: item.status,
          substanceUse: {
            currentFrequencyOfUse: item.tutorial.frequency,
            howLongUsingThisLevel: item.tutorial.duration,
            lastUse: item.tutorial.lastUse,
            methodOfUse: item.tutorial.method,
            unitOfUse: item.tutorial.dose.unit,
            dose: item.tutorial.dose.amount,
            refillable: item.tutorial.refillable,
            refillTimes: item.tutorial.refillTimes.amount,
          },
        },
      })),
    };
    dispatch(
      profileActionCreators.createNewAssociatedTag({
        data,
        callback: (errorMsg?: string) => {
          if (!errorMsg) {
            onCloseCard(true);
          }
        },
      }),
    );
  };

  const getCard = () => {
    switch (sectionType) {
      case priorityListCard[4].type:
      case priorityListCard[9].type: {
        const isSocial = sectionType === priorityListCard[4].type;
        return (
          <AddNewReport
            isOpen
            isNoDrawer={isNoDrawer}
            onClose={onClose}
            type={isSocial ? DomainTypesEnum.SOCIALS_DETERMINANTS : DomainTypesEnum.DIAGNOSES}
            title={isSocial ? 'Report new social determinant' : 'Report new diagnoses'}
            stepTitles={isSocial ? socialSteps : diagnosesSteps}
            showInterference={false}
            searchLabel={`Select one or more ${isSocial ? 'social determinants' : 'diagnoses'}`}
            options={domainOptions}
            onSubmit={onSubmitNewReport}
            isLoadingOptions={isLoadingOptions}
          />
        );
      }
      case priorityListCard[5].type:
        return (
          <AddNewReport
            isOpen
            isNoDrawer={isNoDrawer}
            type={DomainTypesEnum.LIFE_EVENTS}
            onClose={onClose}
            title="Report new life events"
            stepTitles={lifeEventSteps}
            isLifeEvent={false}
            showInterference={false}
            searchLabel="Select one or more life events"
            options={domainOptions}
            onSubmit={onSubmitNewReport}
            isLoadingOptions={isLoadingOptions}
          />
        );
      case priorityListCard[6].type: {
        const isSocial = sectionType === priorityListCard[6].type;
        return (
          <AddNewReport
            isOpen
            isNoDrawer={isNoDrawer}
            onClose={onClose}
            type={isSocial ? DomainTypesEnum.SOCIALS_DETERMINANTS : DomainTypesEnum.DIAGNOSES}
            title={isSocial ? 'Report new social determinant' : 'Report new daignoses'}
            stepTitles={isSocial ? socialSteps : diagnosesSteps}
            showInterference={false}
            searchLabel={`Select one or more ${isSocial ? 'social determinants' : 'diagnoses'}`}
            options={domainOptions}
            onSubmit={onSubmitNewReport}
            isLoadingOptions={isLoadingOptions}
          />
        );
      }
      // return (
      //   <AddNewReport
      //     title="Report new symptoms"
      //     stepTitles={stepTitles}
      //     showInterference
      //     searchLabel="Select one or more symptoms"
      //     options={domainOptions}
      //     onClose={onClose}
      //     isNoDrawer={isNoDrawer}
      //     onSubmit={onSubmitNewReport}
      //     isOpen
      //   />
      // );
      case priorityListCard[7].type:
        return (
          <AddNewReport
            isOpen
            isNoDrawer={isNoDrawer}
            type={DomainTypesEnum.LIFE_EVENTS}
            onClose={onClose}
            title="Report new life events"
            stepTitles={lifeEventSteps}
            isLifeEvent={false}
            showInterference={false}
            searchLabel="Select one or more life events"
            options={domainOptions}
            onSubmit={onSubmitNewReport}
            isLoadingOptions={isLoadingOptions}
          />
        );
      // return (
      //   <AddNewReport
      //     title="Report new diagnoses"
      //     stepTitles={diagnosesSteps}
      //     showInterference
      //     type={DomainTypesEnum.DIAGNOSES}
      //     searchLabel="Select one or more diagnoses"
      //     options={domainOptions}
      //     onClose={onClose}
      //     isNoDrawer={isNoDrawer}
      //     onSubmit={onSubmitNewReport}
      //     isOpen
      //   />
      // );
      case priorityListCard[8].type: {
        // const isSocial = sectionType === priorityListCard[4].type;
        // console.log('social = ', isSocial);
        // return (
        //   <NewMedicationReport
        //     isOpen
        //     isNoDrawer={isNoDrawer}
        //     onClose={onClose}
        //     options={domainOptions}
        //     lookupData={lookupData}
        //     onSubmit={onSubmitNewMedicationReport}
        //   />
        // );

        return (
          <AddNewReport
            title="Report new symptoms"
            stepTitles={stepTitles}
            showInterference
            searchLabel="Select one or more symptoms"
            options={domainOptions}
            onClose={onClose}
            isNoDrawer={isNoDrawer}
            onSubmit={onSubmitNewReport}
            type={DomainTypesEnum.SYMPTOMS}
            isOpen
            isLoadingOptions={isLoadingOptions}
          />
        );
      }
      case priorityListCard[10].type:
        return (
          <NewMedicationReport
            isOpen
            isNoDrawer={isNoDrawer}
            onClose={onClose}
            options={domainOptions}
            lookupData={lookupData}
            onSubmit={onSubmitNewMedicationReport}
            memberId={memberId}
            isLoadingOptions={isLoadingOptions}
          />
        );
      case priorityListCard[11].type:
        return (
          <AddNewReport
            isOpen
            onClose={onClose}
            title="Report new side effect"
            stepTitles={stepTitles}
            showInterference={false}
            searchLabel="Select one or more side effects"
            options={domainOptions}
            onSubmit={onSubmitNewReport}
            isLoadingOptions={isLoadingOptions}
          />
        );
      case priorityListCard[12].type:
        return (
          <NewSubstanceReport
            isOpen
            isNoDrawer={isNoDrawer}
            onClose={onClose}
            options={domainOptions}
            lookupData={lookupData}
            onSubmit={onSubmitNewSubstanceUseReport}
            isLoadingOptions={isLoadingOptions}
          />
        );
      case 'symptomatic-factor': {
        return (
          <AddFactorDrawer
            open
            onClose={onClose}
            title="Add symptomatic factor"
            // domainType="symptomatic-factor"
            selectedType={sectionType}
            variant={DrawerType.PRIMARY}
            onChangeSelect={onSelectInnerElementType}
            increasedWidth
          />
        );
      }
      case 'environmental-factor': {
        return (
          <AddFactorDrawer
            open
            onClose={onClose}
            title="Add environmental factor"
            // domainType="environmental-factor"
            variant={DrawerType.SECONDARY}
            selectedType={sectionType}
            increasedWidth
            onChangeSelect={onSelectInnerElementType}
          />
        );
      }
      default:
        return null;
    }
  };

  return <>{getCard()}</>;
};

export { CreateNewReport };
