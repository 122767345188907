import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  formContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: '24px 24px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  disableLabel: {
    textDecorationLine: 'line-through',
  },
  checkboxLabel: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
});
