import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  menuButton: {
    height: 40,
    minWidth: '40px !important',
    width: 40,
    padding: 0,
    background: colors.white,
    // position: 'absolute',
  },
  menuText: {
    color: colors.primary500,
    fontSize: 14,
    fontWeight: '700',
    // lineHeight: 20,
  },
  menuItem: {
    gap: 10,
  },
  calendarBtn: {
    width: '24px !important',
    height: '24px !important',
  },
  TodoContainer: {
    border: 'solid 1px',
    marginLeft: 58,
    width: 360,
    minHeight: 161,
    padding: '16px 24px 16px 16px',
    borderRadius: 4,
    background: 'white',
    borderColor: colors.neutral100,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19)',
  },
  TodoContainer2: {
    border: 'solid 1px',
    marginLeft: 126,
    width: 360,
    minHeight: 161,
    padding: '16px 24px 16px 16px',
    borderRadius: 4,
    background: 'white',
    borderColor: colors.neutral100,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19)',
  },
  todoMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  todoTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 24,
  },
  todoHeading: {
    width: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  todoCloseBtnContainer: {
    marginTop: -14,
    width: 10,
  },
  todoCloseBtn: {
    height: 40,
    minWidth: '40px !important',
    width: 40,
    padding: 0,
  },
  todoContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 0px 8px 0px',
    height: 33,
    gap: 16,
  },
  todoBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  todoBtn: {
    height: 40,
    minWidth: '121px !important',
    width: 121,
    padding: 0,
    fontSize: 14,
  },
  inputCalender: {
    height: 36,
    width: 140,
  },
  todoToggleBtn: {
    marginTop: -2,
  },
});
