import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
    gap: 40,
  },
  header: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  head: {
    display: 'flex',
    gap: 12,
    height: 88,
    alignItems: 'center',
    // padding: '0 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  name: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    margin: 0,
  },
  badge: {
    fontSize: 12,
    lineHeight: '16px',
    height: 20,
    textTransform: 'capitalize',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
});
