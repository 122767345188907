import { FC, SyntheticEvent, useMemo, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/material';

import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';

// types
import { IDomainElement as IProfileDomainElement } from 'redux/modules/profile/types';
import { IDomainElement } from 'redux/modules/priority/types';
import { stateActionCreators } from 'redux/modules/state';
import { getDomainElements } from 'services/member/member.service';

import { SelectLevel } from 'pages/provider/member-detail/components/select-level/SelectLevel';
import { stateSelector } from '../../../../../../redux/modules/state/selectors';
// schema
import { AddDiagnosisSchema } from './AddDiagnosis.schema';

// styles
import { useStyles } from './AddDiagnosis.styles';

interface Props {
  selectedDomainElement: IProfileDomainElement;
  domainElements: IDomainElement[];
  onSubmit: (payload) => void;
  onClose: () => void;
}

const AddDiagnosis: FC<Props> = ({ selectedDomainElement, domainElements = [], onSubmit, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { icd10 } = useSelector(stateSelector);

  const [selectedDiagnosis, setSelectedDiagnosis] = useState<IDomainElement>();
  const [importanceLevels, setImportanceLevels] = useState([]);
  const defaultValues = {
    diagnosis: selectedDomainElement?.domainElementId || '',
    icd10Code: selectedDomainElement?.selectedIct10code || '',
    status: selectedDomainElement?.priority.name || '',
    additionalNotes: selectedDomainElement?.notes || '',
  };

  const { errors, values, handleChange, handleSubmit, setFieldValue, touched, ...rest } = useFormik({
    initialValues: defaultValues,
    enableReinitialize: !!selectedDomainElement?.domainElementId,
    validationSchema: AddDiagnosisSchema,
    onSubmit: (payload: typeof defaultValues) => {
      onSubmit(payload);
    },
  });

  const icd10Options = useMemo(() => {
    const domainEl = domainElements?.find((element: IDomainElement) => values.diagnosis === element.Id);
    const icd10List = icd10
      ?.filter(icdF => domainEl?.actions?.metaData?.metaDataValues?.icd10Codes?.includes(icdF?.code))
      .map(icd10Val => {
        return { label: `${icd10Val?.code}`, value: icd10Val.code };
      });
    if (icd10List?.length) {
      values.icd10Code = icd10List[0].value;
    }
    return icd10List;
  }, [values.diagnosis]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  useEffect(() => {
    setSelectedDiagnosis(
      domainElements?.find(
        (element: IDomainElement) => element.Id === selectedDomainElement?.domainElementId,
      ),
    );
  }, [selectedDomainElement, domainElements]);

  useEffect(() => {
    if (selectedDomainElement?.domainElementId) {
      getDomainElements({ domainTypeId: selectedDomainElement.domainElementId })
        .then(res => {
          const tempImportanceLevels = [];
          if (res.status === 200) {
            Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
              if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                tempImportanceLevels.push({ value: key, label: key });
              }
            });
            setImportanceLevels(tempImportanceLevels);
          }
        })
        .catch(err => {
          console.log({ err });
        });
      // setFieldValue('diagnosis', selectedDomainElement?.domainElementId);
    }
    dispatch(stateActionCreators.fetchICD10());
  }, []);
  useEffect(() => {
    rest.handleReset({ ...defaultValues });
    rest.setErrors({});
  }, []);

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtnSquare} />
        <Text className={classes.headerTitle}>{selectedDomainElement ? 'Update' : 'Add'} diagnosis</Text>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box className={classes.formContent}>
          <Box className={classes.section}>
            {renderLabel('Diagnosis')}
            <Input
              fullWidth
              name="diagnosis"
              placeholder="Select item"
              size={inputSize.M}
              variant={inputType.AUTOCOMPLETE}
              value={selectedDiagnosis || ''}
              options={domainElements?.sort((a, b) => a.name.localeCompare(b.name))}
              getOptionLabel={(option: IDomainElement) => option?.name || ''}
              onChange={async (_: SyntheticEvent, newValue: IDomainElement) => {
                await setFieldValue('diagnosis', newValue.Id);
                setSelectedDiagnosis(newValue);
                const tempImportanceLevels = [];
                Object.keys(newValue?.actions?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                  if (newValue?.actions?.metaData?.metaDataSpec?.importanceLevels[key]) {
                    tempImportanceLevels.push({ value: key, label: key });
                  }
                });
                setImportanceLevels(tempImportanceLevels);
              }}
            />
            <TextError errorMsg={touched.diagnosis ? errors.diagnosis?.toString() : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('ICD-10 Code')}
            <Select
              name="icd10Code"
              value={values.icd10Code}
              variant={selectType.SECONDARY}
              options={icd10Options?.sort((a, b) => a.label.localeCompare(b.label)) || []}
              emptyText="Select item"
              displayEmpty
              onChange={handleChange}
            />
            <TextError errorMsg={touched.icd10Code ? errors.icd10Code?.toString() : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Status')}
            <SelectLevel
              value={values.status}
              options={importanceLevels?.sort((a, b) => a.label.localeCompare(b.label)) || []}
              isLifeEvent
              onChange={handleChange}
            />
            <TextError errorMsg={touched.status ? errors.status?.toString() : null} />
          </Box>

          <Box className={classes.section}>
            {renderLabel('Additional notes for team')}
            <Textarea
              name="additionalNotes"
              value={values.additionalNotes}
              placeholder="Enter your notes"
              onChange={handleChange}
              minRows={2}
              maxRows={3}
              onBlur={setTouched('additionalNotes')}
            />
            <TextError errorMsg={touched.additionalNotes ? errors.additionalNotes?.toString() : null} />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>{selectedDomainElement ? 'Update' : 'Add'}</Button>
        </Box>
      </form>
    </Box>
  );
};

export { AddDiagnosis };
