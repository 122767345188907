import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  badge: {
    height: 28,
    borderRadius: 100,
  },
  submittingBadge: {
    color: colors.warning500,
    border: `1px solid ${colors.warning300}`,
  },
  actionRequiredBadge: {
    color: colors.orange500,
    border: `1px solid ${colors.orange300}`,
  },
  submittedBadge: {
    color: colors.indigo600,
    border: `1px solid ${colors.indigo300}`,
  },
  failedBadge: {
    color: colors.destructive500,
    border: `1px solid ${colors.destructive300}`,
  },
  approvedBadge: {
    color: colors.success500,
    border: `1px solid ${colors.success300}`,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    position: 'relative',
    padding: '16px 8px 24px 24px',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  closeBtn: {
    margin: 0,
    minWidth: 'auto',
    position: 'absolute',
    right: 24,
    top: 24,
    '& svg': {
      width: 30,
    },
  },
  stepWrap: {
    marginTop: 20,
    '& .Mui-completed': {
      '& #stepCircle': {
        background: '#000000',
        border: '2px solid #ffffff',
      },
    },
  },
  btn: {
    padding: '12px 24px',
    gap: 8,
    border: '1px solid',
    borderColor: colors.primary300,
    borderRadius: 4,
    cursor: 'pointer',
    height: 40,
    '& svg': {
      width: 18,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  primaryBtn: {
    color: colors.white,
  },
  gridBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'start',
    justifyContent: 'flex-start',
    height: '100px',
    padding: '24px',
    boxSizing: 'border-box',
  },
  collapsable: {
    borderRadius: 8,
    padding: 24,
    backgroundColor: colors.primary50,
  },
  collapsableDisabled: {
    borderRadius: 8,
    padding: 24,
    backgroundColor: colors.neutral50,
    cursor: 'default',
  },
  collapsableEmail: {
    borderRadius: 4,
    padding: 24,
    backgroundColor: colors.white,
    marginTop: 16,
    marginBottom: 16,
  },
  collapsableContainer: {
    padding: '48px',
  },
  preSubmissionGrid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(1, 1fr)',
    gap: '16px',
    paddingTop: '16px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  rotate: {
    animation: `$spin 2s linear infinite`,
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});
