import { FC } from 'react';
import { Box } from '@mui/material';

// components
import { Heading, headingLevel, fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

// constants
import {
  evaluationStatusStyle,
  EVALUATION_STATUS,
} from 'pages/provider/sessions/evaluations-v2/Evaluations.constants';
import { IDomainElement } from 'redux/modules/profile/types';

// types
import { useStyles } from './MedicationCard.styles';

type Props = {
  domainElements: IDomainElement[];
  status: string;
  onClick: () => void;
  isLoading?: boolean;
};

const MedicationCard: FC<Props> = ({ status, domainElements, onClick, isLoading }) => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Box className={classes.cardContent} onClick={onClick}>
        <Box className={classes.iconWrapper}>
          <Icons color={colors.primary500} glyph="medication-2" />
        </Box>
        <Box>
          <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.title}>
            Medication reconciliation
          </Heading>
          {domainElements?.map(element => (
            <Box key={element.id} display="flex" alignItems="center" gap={1}>
              <Text className={classes.subtitle}>{element?.name}</Text>
              <Text className={classes.status}>{element?.priority?.name}</Text>
              <Text className={classes.subtitle}>
                {element?.tagMetaData?.rxDrugInfo?.dosage?.toString() ||
                  element?.tagMetaData?.rxDrugInfo?.dose?.toString() ||
                  'N/A'}
              </Text>
              <Text className={classes.subtitle}>
                {element.tagMetaData?.rxDrugInfo?.frequency?.toString() ||
                  element.tagMetaData?.rxDrugInfo?.doseFrequency?.toString() ||
                  'N/A'}
              </Text>
            </Box>
          ))}
        </Box>
        {!domainElements?.length && (
          <Box className={classes.statusBadge}>
            <Badge
              className={classes.badge}
              variant={badgeType.OUTLINED}
              style={evaluationStatusStyle[status]}
            >
              {EVALUATION_STATUS[status]}
            </Badge>
          </Box>
        )}
        {isLoading && (
          <div className={classes.loader}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </div>
        )}
      </Box>
      {/* <Menu
        icon="more"
        className={classes.menu}
        // itemsWrapperClassName={classes.menuItemsWrapper}
        // items={items}
      /> */}
    </Box>
  );
};

export { MedicationCard };
