// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  asterick: {
    color: colors.bgCritical,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    marginBottom: 8,
  },
  valuesInput: {
    padding: 0,
    '& > div': {
      '& input': {
        fontSize: '14px !important',
      },
    },
    '& .MuiAutocomplete-tag': {
      fontSize: 14,
    },
  },
  root: {
    height: '100%',
  },
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  content: {},
  block: {
    marginBottom: 16,
  },
  addRecipientLink: {
    marginTop: '24px',
  },
  horizontalBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  innerBlock: {
    marginBottom: 16,
    padding: 16,
    backgroundColor: colors.neutral25,
    borderColor: colors.neutral100,
    border: 'solid 1px',
    borderRadius: 8,
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
    marginBottom: 8,
  },
  input: {},
  attachmentWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    gap: 8,
  },
  attachment: {
    borderRadius: 4,
    width: '100%',
    border: `1px solid ${colors.neutral100}`,
    padding: '8px 16px',
  },
  footer: {
    margin: '0 -40px -40px',
    padding: '16px 24px 16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${colors.neutral100}`,
  },
  submit: {
    fontSize: 14,
    height: 48,
  },
  root2: {
    border: `2px dashed ${colors.neutral100}`,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 152,
    width: '100%',
    padding: '36px 24px 32px',
  },
  dragging: {
    border: `1px solid ${colors.primary}`,
  },
  disabled: {
    opacity: 0.7,
    '& $uploadText span': {
      cursor: 'default',
    },
  },
  content2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    color: colors.primary,
    width: 32,
    height: 32,
  },
  uploadText: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    color: colors.neutral500,
    margin: '8px 0',
    '& span': {
      color: colors.primary,
      cursor: 'pointer',
    },
  },
  filesize: {
    color: colors.neutral500,
    fontSize: 12,
    fontWeight: 600,
  },
});
