import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { fontWeight, Heading } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import PriorityEmpty from 'pages/provider/member-detail/components/PriorityEmpty';
import {
  updateAppointmentEvaluationStatus,
  getEvaluationList,
  getAppointmentEvaluationProgress,
  updateAppointmentAddMultipleEvaluations,
} from 'services/conversation/conversation.service';
import { iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button/IconButton.types';
import EvaluationCard from '../evaluation-card';
import { IEvaluationItem, IEvaluationUpdateRequest } from '../Evaluations.types';
import AddEvaluation from '../add-evaluation';
import { useStyles } from './Evaluations.styles';
import { EVALUATION_STATES } from '../Evaluations.constants';
import { TOAST_TYPES } from '../../../../../constants/CommonConstants';

type Props = {
  // onBack: () => void;
  appointmentId: string;
  onSelectEvaluation: (evaluation: IEvaluationItem) => void;
  showAddEvaluation: boolean;
  setShowAddEvaluation: (showAddEvaluation: boolean) => void;
  updateEvaluationProgress: () => void;
  setToastProperties: Dispatch<
    SetStateAction<{
      message: string;
      type: string;
      status: boolean;
    }>
  >;
};
const EvaluationsV2: FC<Props> = ({
  appointmentId,
  onSelectEvaluation,
  showAddEvaluation,
  setShowAddEvaluation,
  updateEvaluationProgress,
  setToastProperties,
}) => {
  const [evaluationsProgressList, setEvaluationsProgressList] = useState(null);
  const [allEvaluations, setAllEvaluations] = useState([]);
  const classes = useStyles();

  const getEvaluationProgressList = async () => {
    try {
      const response = await getAppointmentEvaluationProgress({ appointmentId });
      if (response.status !== 200) {
        throw new Error('Whoops ! Something went wrong . Please try later');
      } else {
        const responseList = response?.data || [];
        setEvaluationsProgressList(responseList);
      }
    } catch (e) {
      console.log('Whoops ! Something went wrong . Please try later', e);
      setEvaluationsProgressList([]);
    }
  };

  const getEvaluationsList = async () => {
    try {
      const evaluationsProgressListIds =
        evaluationsProgressList?.map(progressEvaluationItem => progressEvaluationItem?.id) || [];
      const evaluationsListResponse = await getEvaluationList({ status: EVALUATION_STATES.ACTIVE });
      if (evaluationsListResponse?.status !== 200) {
        throw new Error('Whoops ! Something went wrong . Please try later');
      }
      let allEvaluationsList = evaluationsListResponse?.data?.EvaluationSummaryList || [];
      allEvaluationsList = allEvaluationsList.filter(
        (evaluation: { evaluationId: string }) =>
          !evaluationsProgressListIds?.includes(evaluation?.evaluationId),
      );
      setAllEvaluations(allEvaluationsList);
    } catch (error) {
      console.log('Whoops ! Something went wrong . Please try later', error);
      setAllEvaluations([]);
    }
  };

  useEffect(() => {
    void getEvaluationProgressList();
  }, [showAddEvaluation]);

  useEffect(() => {
    if (evaluationsProgressList) {
      void getEvaluationsList();
    }
  }, [evaluationsProgressList]);

  const onAdd = () => {
    setShowAddEvaluation(true);
  };

  const onCloseAddEvaluation = () => {
    setShowAddEvaluation(false);
  };

  const onAddEvaluationClick = async (selectedEvaluationIds: string[]) => {
    try {
      const res = await updateAppointmentAddMultipleEvaluations(
        { evaluationIds: selectedEvaluationIds },
        { appointmentId },
      );
      if (res.status === 200) {
        await getEvaluationProgressList();
        setShowAddEvaluation(false);
        updateEvaluationProgress();
      }
    } catch (error) {
      console.log({ error });
      setShowAddEvaluation(false);
    }
  };

  const updateEvaluationStatus = (evaluationUpdateRequest: IEvaluationUpdateRequest) => {
    if (evaluationUpdateRequest) {
      updateAppointmentEvaluationStatus(evaluationUpdateRequest, { appointmentId })
        .then(res => {
          if (!res.data?.errors) {
            void getEvaluationProgressList();
            updateEvaluationProgress();
          } else {
            throw new Error(res?.data?.errors[0]?.endUserMessage);
          }
        })
        .catch(error => {
          setToastProperties({
            message: error?.data?.errors?.[0]?.endUserMessage || 'Something went wrong',
            type: TOAST_TYPES.ERROR,
            status: true,
          });
        });
    } else {
      throw new Error('Whoops ! Something went wrong . Please try later');
    }
  };

  if (showAddEvaluation) {
    return (
      <AddEvaluation
        onClose={onCloseAddEvaluation}
        evaluationsList={allEvaluations}
        onAddEvaluationClick={onAddEvaluationClick}
      />
    );
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Heading weight={fontWeight.BOLD} className={classes.title}>
          Evaluations
        </Heading>
        {allEvaluations?.length > 0 && (
          <IconButton icon="plus" onClick={onAdd} variant={iconBtnType.TEXT} className={classes.backBtn}>
            Add Evaluation
          </IconButton>
        )}
      </Box>
      <Box className={classes.body}>
        {!evaluationsProgressList || evaluationsProgressList.length === 0 ? (
          <PriorityEmpty
            title="No Assigned Evaluations"
            description={`Evaluations collect the information
            we need to power the system.
            Please assign the appropriate Eval for this appointment.
            `}
            onAdd={onAdd}
            addText="Add evaluation"
            className="session-evaluations"
            glyph="face-talking-amazed"
          />
        ) : (
          <Box display="flex" flexDirection="column" gap="16px">
            {evaluationsProgressList?.map((evaluation: IEvaluationItem) => (
              <EvaluationCard
                evaluation={evaluation}
                key={evaluation?.id}
                onClick={onSelectEvaluation}
                updateEvaluationStatus={updateEvaluationStatus}
                setToastProperties={setToastProperties}
                showMenu
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { EvaluationsV2 };
