// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 88,
  },
  breadcrumbs: {
    position: 'absolute',
    top: -48,
    left: -64,
    height: 88,
    width: '100vw',
    borderBottom: `1px solid ${colors.neutral100}`,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    alignItems: 'center',
  },
  titleSection: {
    marginBottom: 24,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
    fontSize: 40,
  },
  heading2: {
    fontSize: 32,
  },
  headBadge: {
    color: colors.white,
    fontSize: 14,
    borderRadius: 100,
    marginLeft: 12,
    marginRight: '1rem',
    textWrap: 'nowrap',
  },
  in_progressBadge: {
    backgroundColor: '#ef4444',
    color: colors.white,
    borderColor: '#ef4444',
  },
  incompletedBadge: {
    backgroundColor: colors.orange500,
    borderColor: colors.orange500,
  },
  incompleteBadge: {
    backgroundColor: colors.primary500,
    borderColor: colors.primary500,
  },
  completedBadge: {
    backgroundColor: colors.success500,
    borderColor: colors.success500,
  },
  rejectedBadge: {
    backgroundColor: colors.destructive500,
    borderColor: colors.destructive500,
  },
  not_startedBadge: {
    color: '#667786',
    backgroundColor: colors.white,
    borderColor: '#66778640',
  },
  badge: {
    border: 0,
    height: 24,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    whiteSpace: 'nowrap',
  },
  scoreBadge: {
    backgroundColor: colors.white,
    color: colors.secondary500,
    border: `1px solid ${colors.secondary300}`,
    fontSize: 14,
    lineHeight: '20px',
  },
  providerCards: {
    marginTop: 48,
    display: 'flex',
    gap: 16,
    width: '100%',
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  evaluationDetailBody: { margin: 0, backgroundColor: '#fff', borderRadius: 8 },
});
