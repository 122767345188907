/* eslint-disable @typescript-eslint/no-use-before-define */
import { IAction } from 'redux/store/types';
import {
  FETCH_CONTENT_BLOCK_HISTORY,
  FETCH_CONTENT_BLOCK_HISTORY_FAILED,
  FETCH_CONTENT_BLOCK_HISTORY_SUCCESSFUL,
  FETCH_REPORTS_LIST,
  FETCH_REPORTS_LIST_FAILED,
  FETCH_REPORTS_LIST_SUCCESSFUL,
  CREATE_REPORT,
  CREATE_REPORT_FAILED,
  CREATE_REPORT_SUCCESSFUL,
  UPDATE_REPORT,
  UPDATE_REPORT_FAILED,
  UPDATE_REPORT_SUCCESSFUL,
  DELETE_REPORT,
  DELETE_REPORT_FAILED,
  DELETE_REPORT_SUCCESSFUL,
  FETCH_CLAIMS,
  FETCH_CLAIMS_FAILED,
  FETCH_CLAIMS_SUCCESSFUL,
  FETCH_INVOICES,
  FETCH_INVOICES_FAILED,
  FETCH_INVOICES_SUCCESSFUL,
  FETCH_CLAIM_BY_ID,
  FETCH_CLAIM_BY_ID_FAILED,
  FETCH_CLAIM_BY_ID_SUCCESSFUL,
  PUT_CLAIM_DETAIL,
  PUT_CLAIM_DETAIL_FAILED,
  PUT_CLAIM_DETAIL_SUCCESSFUL,
  SET_REPORT_DETAIL_TOTAL_MEMBERS,
  PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID,
  PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_FAILED,
  PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_SUCCESSFUL,
  FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID,
  FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_FAILED,
  FFETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_SUCCESSFUL,
  FETCH_INVOICE_BY_ID,
  FETCH_INVOICE_BY_ID_FAILED,
  FETCH_INVOICE_BY_ID_SUCCESSFUL,
  UPDATE_INVOICE_BY_ID,
  UPDATE_INVOICE_BY_ID_FAILED,
  UPDATE_INVOICE_BY_ID_SUCCESSFUL,
  UPDATE_INVOICE_STATUS_BY_ID,
  UPDATE_INVOICE_STATUS_BY_ID_FAILED,
  UPDATE_INVOICE_STATUS_BY_ID_SUCCESSFUL,
} from './actions';
import { ReportingState } from './types';

export const DEFAULT: ReportingState = {
  isLoading: true,
  errorMsg: '',
  contentBlockHistory: null,
  report: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    reportList: [],
  },
  claims: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    claimsList: [],
  },
  claim: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    data: null,
  },
  invoices: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    invoicesList: [],
  },
  providerFee: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    providerFeeData: null,
  },
  reportDetailTotalMembers: 0,
  invoice: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    data: null,
  },
};

export default function reportingReducer(state = DEFAULT, action: IAction): ReportingState {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONTENT_BLOCK_HISTORY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_CONTENT_BLOCK_HISTORY_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload,
        contentBlockHistory: null,
      };
    }
    case FETCH_CONTENT_BLOCK_HISTORY_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        contentBlockHistory: payload,
      };
    }
    case FETCH_REPORTS_LIST: {
      return {
        ...state,
        report: {
          ...state?.report,
          isLoading: true,
          errorMsg: '',
          reportList: [],
        },
      };
    }
    case FETCH_REPORTS_LIST_FAILED: {
      return {
        ...state,
        report: {
          ...state?.report,
          isLoading: false,
          errorMsg: payload,
        },
      };
    }
    case FETCH_REPORTS_LIST_SUCCESSFUL: {
      return {
        ...state,
        report: {
          ...state?.report,
          isLoading: false,
          errorMsg: '',
          currentPage: payload?.currentPage,
          pageSize: payload?.pageSize,
          totalPages: payload?.totalPages,
          totalRecords: payload?.totalRecords,
          reportList: payload?.reportList,
        },
      };
    }
    case CREATE_REPORT: {
      return {
        ...state,
        report: {
          ...state.report,
          isSaving: true,
          errorMsg: '',
        },
      };
    }
    case CREATE_REPORT_FAILED: {
      return {
        ...state,
        report: {
          ...state?.report,
          isSaving: false,
          errorMsg: payload,
        },
      };
    }
    case CREATE_REPORT_SUCCESSFUL: {
      return {
        ...state,
        report: {
          ...state.report,
          isSaving: false,
          errorMsg: '',
        },
      };
    }
    case UPDATE_REPORT:
    case DELETE_REPORT: {
      return {
        ...state,
        report: {
          ...state.report,
          isSaving: true,
          errorMsg: '',
        },
      };
    }
    case UPDATE_REPORT_FAILED:
    case DELETE_REPORT_FAILED: {
      return {
        ...state,
        report: {
          ...state.report,
          isSaving: false,
          errorMsg: payload,
        },
      };
    }
    case UPDATE_REPORT_SUCCESSFUL: {
      return {
        ...state,
        report: {
          ...state.report,
          isSaving: false,
          reportList: state?.report?.reportList?.map(report =>
            report._id === payload.report._id ? payload.report : report,
          ),
        },
      };
    }
    case DELETE_REPORT_SUCCESSFUL: {
      return {
        ...state,
        report: {
          ...state?.report,
          isSaving: false,
        },
      };
    }
    case FETCH_CLAIMS: {
      return {
        ...state,
        claims: {
          ...state?.claims,
          isLoading: true,
          errorMsg: '',
          claimsList: [],
        },
      };
    }
    case FETCH_CLAIMS_FAILED: {
      return {
        ...state,
        claims: {
          ...state?.claims,
          isLoading: false,
          errorMsg: payload,
        },
      };
    }
    case FETCH_CLAIMS_SUCCESSFUL: {
      return {
        ...state,
        claims: {
          ...state?.claims,
          isLoading: false,
          errorMsg: '',
          nextPage: payload?.metaData?.nextPage,
          currentPage: payload?.metaData?.pageNumber,
          pageSize: payload?.metaData?.pageSize,
          totalPages: payload?.metaData?.totalPages,
          totalRecords: payload?.metaData?.totalRecords,
          claimsList: payload?.data,
          metaData: payload?.metaData,
        },
      };
    }
    case FETCH_CLAIM_BY_ID: {
      return {
        ...state,
        claim: {
          ...state?.claim,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_CLAIM_BY_ID_FAILED: {
      return {
        ...state,
        claim: {
          ...state?.claim,
          isLoading: false,
          errorMsg: payload,
        },
      };
    }
    case FETCH_CLAIM_BY_ID_SUCCESSFUL: {
      return {
        ...state,
        claim: {
          ...state.claim,
          isLoading: false,
          errorMsg: '',
          data: payload.data,
        },
      };
    }
    case PUT_CLAIM_DETAIL: {
      return {
        ...state,
        claim: {
          ...state?.claim,
          isSaving: true,
          errorMsg: null,
        },
      };
    }
    case PUT_CLAIM_DETAIL_FAILED: {
      return {
        ...state,
        claim: {
          ...state?.claim,
          isSaving: false,
          errorMsg: payload?.message,
        },
      };
    }
    case PUT_CLAIM_DETAIL_SUCCESSFUL: {
      return {
        ...state,
        claim: {
          ...state?.claim,
          isSaving: false,
          errorMsg: '',
          data: payload?.data,
        },
      };
    }
    case FETCH_INVOICES: {
      return {
        ...state,
        invoices: {
          ...state?.invoices,
          isLoading: true,
          errorMsg: '',
          invoicesList: [],
        },
      };
    }
    case FETCH_INVOICES_FAILED: {
      return {
        ...state,
        invoices: {
          ...state?.invoices,
          isLoading: false,
          errorMsg: payload,
        },
      };
    }
    case FETCH_INVOICES_SUCCESSFUL: {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoading: false,
          errorMsg: '',
          nextPage: payload?.metadata?.nextPage,
          currentPage: payload?.metadata?.pageNumber,
          pageSize: payload?.metadata?.pageSize,
          totalPages: payload?.metadata?.totalPages,
          totalRecords: payload?.metadata?.totalRecords,
          invoicesList: payload?.data,
          additionalMetaData: payload?.additionalMetaData,
        },
      };
    }
    case SET_REPORT_DETAIL_TOTAL_MEMBERS: {
      return {
        ...state,
        reportDetailTotalMembers: action?.payload,
      };
    }
    case FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID: {
      return {
        ...state,
        providerFee: {
          ...state?.providerFee,
          isLoading: true,
          errorMsg: '',
        },
      };
    }
    case FETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_FAILED: {
      return {
        ...state,
        providerFee: {
          ...state?.providerFee,
          isLoading: false,
          errorMsg: payload,
          providerFeeData: null,
        },
      };
    }
    case FFETCH_PROVIDER_FEE_BY_APPOINTMENT_ID_SUCCESSFUL: {
      return {
        ...state,
        providerFee: {
          ...state?.providerFee,
          isLoading: false,
          errorMsg: '',
          providerFeeData: payload?.data,
        },
      };
    }
    case PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID: {
      return {
        ...state,
        providerFee: {
          ...state?.providerFee,
          isLoading: true,
          errorMsg: '',
        },
      };
    }
    case PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_FAILED: {
      return {
        ...state,
        providerFee: {
          ...state?.providerFee,
          isLoading: false,
          errorMsg: payload,
        },
      };
    }
    case PUT_PROVIDER_FEE_DETAIL_BY_PROVIDER_ID_SUCCESSFUL: {
      return {
        ...state,
        providerFee: {
          ...state?.providerFee,
          isLoading: false,
          errorMsg: '',
          providerFeeData: payload?.data,
        },
      };
    }
    case FETCH_INVOICE_BY_ID: {
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_INVOICE_BY_ID_FAILED: {
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          isLoading: false,
          errorMsg: payload,
        },
      };
    }
    case FETCH_INVOICE_BY_ID_SUCCESSFUL: {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          isLoading: false,
          errorMsg: null,
          data: payload.data,
        },
      };
    }
    case UPDATE_INVOICE_BY_ID: {
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case UPDATE_INVOICE_BY_ID_FAILED: {
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          isLoading: false,
          errorMsg: payload,
        },
      };
    }
    case UPDATE_INVOICE_BY_ID_SUCCESSFUL: {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          isLoading: false,
          errorMsg: null,
        },
      };
    }
    case UPDATE_INVOICE_STATUS_BY_ID: {
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case UPDATE_INVOICE_STATUS_BY_ID_FAILED: {
      return {
        ...state,
        invoice: {
          ...state?.invoice,
          isLoading: false,
          errorMsg: payload,
        },
      };
    }
    case UPDATE_INVOICE_STATUS_BY_ID_SUCCESSFUL: {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          isLoading: false,
          errorMsg: null,
        },
      };
    }
    default: {
      return state;
    }
  }
}
