/* eslint-disable no-nested-ternary */
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import cx from 'clsx';
import { Collapse, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { iconBtnStyle, iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { DomainTypesEnum } from 'constants/CommonConstants';

import SelectLevel from '../select-level';
// types
import { IReportFormCollapsableProps } from './ReportFormCollapse.types';
import { showSnackbar } from '../../../../../redux/modules/snackbar';

// styles
import { ReportItemProgress } from '../AddNewReport.types';

import { useStyles } from './ReportFormCollapse.styles';

const defaultValues = {
  status: '',
  notes: '',
  icd10: '',
  medicalConditions: [],
  isInterference: false,
};

const ReportFormCollapse: FC<IReportFormCollapsableProps> = ({
  open = false,
  showInterference,
  type,
  symptom,
  onChange,
  onRemoveClick,
  onOpenCollapse,
  options,
  medicalConditions,
}) => {
  const dispatch = useDispatch();
  const medicalConditionList = medicalConditions?.map(condition => condition?.value);
  const [collapsed, setCollapsed] = useState(open || false);
  const [importanceLevels, setImportanceLevels] = useState([]);
  const [icd10codes, setIcd10Codes] = useState([]);
  const [status, setStatus] = useState('');
  const [icd10, setIcd10] = useState('');
  const [isInterference, setIsInterference] = useState(false);
  const [notes, setNotes] = useState('');
  const [medicalConditionsList, setMedicalConditionsList] = useState([]);
  const classes = useStyles();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    onOpenCollapse && onOpenCollapse(!collapsed);
  };

  useEffect(() => {
    const selectedDiagnosis = options?.filter(option => option?.groupId === symptom?.groupId);
    const selectedRelatedElement = selectedDiagnosis[0].relatedElements?.filter(
      element => element.Id === symptom.item.value,
    );

    const tempImportanceLevels = [];
    const tempIcd10List = [];
    Object.keys(selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
      if (selectedRelatedElement[0]?.metaData?.metaDataSpec?.importanceLevels[key]) {
        tempImportanceLevels.push({ value: key, label: key });
      }
    });
    if (selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes) {
      selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes.forEach(val => {
        tempIcd10List.push({ value: val, label: val });
      });

      // setIcd10(selectedRelatedElement[0]?.metaData?.metaDataValues?.icd10Codes);
    }
    setImportanceLevels(tempImportanceLevels);
    setIcd10Codes(tempIcd10List);
  }, []);
  const onChangeHandler = (key: string, value: any) => {
    switch (key) {
      case 'status':
        setStatus(value);
        break;
      case 'icd10':
        setIcd10(value);
        break;
      case 'isInterference':
        setIsInterference(value);
        break;
      case 'notes':
        setNotes(value);
        break;
      case 'medicalConditions':
        setMedicalConditionsList(value);
        break;
      default:
        break;
    }
  };
  const { handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    onSubmit: (payload: typeof defaultValues) => {
      let buttonEnabled = !((touched.notes && notes.length === 0) || (touched.status && status.length === 0));

      if (type === DomainTypesEnum.DIAGNOSES && icd10codes?.length > 0) {
        buttonEnabled = !(
          (touched.notes && notes.length === 0) ||
          (touched.icd10 && icd10.length === 0) ||
          (touched.status && status.length === 0)
        );
      }
      if (buttonEnabled) {
        const newPayload = {
          ...payload,
          notes,
          importanceLevels,
          icd10,
          status,
        } as any;
        if (showInterference) {
          newPayload.isInterference = isInterference;
        }
        if (medicalConditionList?.length > 0 && type === DomainTypesEnum.SYMPTOMS) {
          newPayload['medical conditions'] = medicalConditionsList;
        }
        onChange(newPayload);
      } else {
        let errorMessage =
          touched.status && status.length === 0
            ? 'Importance level'
            : touched.notes && notes.length === 0
            ? 'Report notes'
            : '';
        if (
          type === DomainTypesEnum.DIAGNOSES &&
          icd10codes?.length > 0 &&
          touched.icd10 &&
          icd10.length === 0
        ) {
          errorMessage =
            touched.status && status.length === 0
              ? 'Importance level'
              : touched.icd10 && icd10.length === 0
              ? 'ICD10 codes'
              : 'Report notes';
        }

        if (errorMessage !== '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: `${errorMessage} is required`,
            }),
          );
        }
      }
    },
  });

  useEffect(() => {
    if (symptom) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(symptom).forEach(async key => {
        if (type === 'Life Events' && key === 'status') {
          await setFieldValue(key, 'NEGATIVE');
        } else if (type === 'Diagnoses' && key === 'status') {
          await setFieldValue(key, 'MISREPORTED');
        } else {
          await setFieldValue(key, symptom[key]);
        }
      });
    }
    if (symptom.progress) {
      setCollapsed(symptom.progress === ReportItemProgress.InProgress);
    }
    if (type === 'Diagnoses') {
      void setFieldValue('icd10', icd10codes?.length !== 0 ? icd10codes[0]?.value : '');
    }
  }, [symptom]);

  useEffect(() => {
    setCollapsed(open || false);
  }, [open]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const getType = () => {
    if (type) {
      if (type === 'Diagnoses') {
        return 'diagnosis';
      }
      if (type.charAt(type.length - 1) === 's') {
        return type.substring(0, type.length - 1);
      }
      return type;
    }
    return 'symptom';
  };

  const renderLabel = (str: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {str}
    </Heading>
  );

  return (
    <Box className={classes.root}>
      <Box className={cx(classes.header, { [classes.headerBorder]: collapsed })} onClick={toggleCollapsed}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Text className={classes.headTitle}>{symptom.item.label}</Text>
          <Badge
            variant={badgeType.OUTLINED}
            className={symptom?.progress === 'In progress' ? classes.badge2 : classes.badge}
            style={
              symptom.progress === ReportItemProgress.Completed
                ? badgeStyle.SUCCESS
                : symptom.progress === ReportItemProgress.Incomplete
                ? badgeStyle.MISREPORTED
                : badgeStyle.CRITICAL
            }
          >
            {symptom.progress}
          </Badge>
        </Box>
      </Box>
      <Collapse in={collapsed}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Importance level')}
              <SelectLevel
                value={status}
                isLifeEvent
                options={importanceLevels}
                onChange={event => onChangeHandler('status', event.target.value)}
                name="status"
              />
              <TextError
                errorMsg={touched.status && status.length === 0 ? 'Importance level is required' : null}
              />
            </Box>
            {icd10codes.length > 0 && (
              <Box className={classes.section}>
                {renderLabel('Icd 10 codes')}
                <SelectLevel
                  name="icd10"
                  value={icd10}
                  isLifeEvent
                  options={icd10codes}
                  onChange={event => onChangeHandler('icd10', event.target.value)}
                />
                <TextError
                  errorMsg={touched.icd10 && icd10.length === 0 ? 'ICD10 codes are required' : null}
                />
              </Box>
            )}
            {showInterference && (
              <Box className={classes.section}>
                {renderLabel('Interference with life')}
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isInterference}
                  name="isInterference"
                  onChange={() => {
                    onChangeHandler('isInterference', !isInterference);
                    // void setFieldValue('isInterference', !values.isInterference);
                  }}
                  className={classes.checkboxLabel}
                  label="This symptom has interference with patient’s life"
                />
              </Box>
            )}

            <Box className={classes.section}>
              {renderLabel('Report notes')}
              <Textarea
                value={notes}
                name="notes"
                onChange={event => onChangeHandler('notes', event.target.value)}
                onBlur={setTouched('notes')}
                minRows={5}
                placeholder="Enter your notes"
              />
              <TextError errorMsg={touched.notes && notes.length === 0 ? 'Notes is required' : null} />
            </Box>

            {medicalConditionList?.length > 0 && type === DomainTypesEnum.SYMPTOMS && (
              <>
                {renderLabel('Related info')}
                <Box className={classes.section}>
                  {renderLabel('Related to medical conditions')}
                  <Input
                    // freeSolo
                    fullWidth
                    name="medical conditions"
                    value={medicalConditionsList}
                    options={medicalConditionList}
                    variant={inputType.TAGS}
                    size={inputSize.M}
                    getOptionLabel={(option: string) => option}
                    onBlur={setTouched('medicalConditions')}
                    onChange={(_: SyntheticEvent, newValue: string) => {
                      onChangeHandler('medicalConditions', newValue);
                      // await setFieldValue('medicalConditions', newValue);
                    }}
                  />
                  {/* <TextError errorMsg={touched.symptoms ? errors.symptoms?.toString() : null} /> */}
                </Box>
              </>
            )}
          </Box>
          <Box className={classes.footer}>
            <IconButton
              icon="delete-outlined-2"
              className={classes.removeBtn}
              variant={iconBtnType.TEXT}
              style={iconBtnStyle.SECONDARY}
              onClick={onRemoveClick}
            >
              Remove this {getType().toLocaleLowerCase()}
            </IconButton>
            <Button onClick={handleSubmit}>Done</Button>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

export { ReportFormCollapse };
