import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/system';

import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import { Button, btnSize } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Badge } from '@confidant-health/lib/ui/atoms/badge';
import { TabPanel, Tabs, tabIconPosition } from '@confidant-health/lib/ui/atoms/tabs';
import { ICBResponse, IDctContentBlock, IEvaluationContext } from 'redux/modules/conversation/types';
import {
  addNewContentBlock,
  getEvaluationContext,
  getEvaluationDCTAssignmentDetail,
  getEvaluationList,
  updateDCT,
  updateEvaluation,
} from 'services/conversation/conversation.service';
import {
  ICounters,
  ITabItem,
} from 'pages/provider/priorities/appointments/evaluation-detail/EvaluationDetail.types';
import { getAppointmentById } from 'services/appointment/appointment.service';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import { showSnackbar } from 'redux/modules/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getDctCBIds } from 'utils/CommonUtils';
import { conversationActionCreators } from 'redux/modules/conversation';
import { getAuth } from 'redux/modules/auth/selectors';
import AddDctDrawer from './AddDctDrawer';
import { BaseLayout } from '../../../layouts/base';

import DctSection from './DctSection';

import { useStyles } from './EvaluationStates.styles';

const EvaluationStates = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { evaluationId, appointmentId } = useParams();
  const { meta } = useSelector(getAuth);

  const [tabType, setTabType] = useState('all');
  const [openAddDct, setOpenAddDct] = useState(false);
  const [evaluationContext, setEvaluationContext] = useState<IEvaluationContext>(null);
  const [appointmentDetails, setAppointmentDetails] = useState<IAppointment>(null);
  const [evaluationTabs, setEvaluationTabs] = useState<ITabItem[]>([]);
  const [orderedItems, setOrderedItems] = useState([]);
  const [allEvaluations, setAllEvaluations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalDCT, setTotalDCT] = useState(0);
  const selectedEvaluation: any = allEvaluations.find(ev => ev.evaluationId === evaluationId);

  useEffect(() => {
    if (evaluationId && appointmentId) {
      void fetchAppointmentsDetails();
      void fetchEvaluationContext();
      void getAllEvaluations();
      dispatch(
        conversationActionCreators.fetchDCTs({
          pageNumber: 0,
          pageSize: 10000,
          status: 'ACTIVE',
          reportingView: false,
          assignmentCount: true,
          type: 'EVALUATION',
        }),
      );
      dispatch(
        conversationActionCreators.fetchEvaluations({
          pageNumber: 1,
          pageSize: 10000,
          status: 'ACTIVE',
          reportingView: true,
          searchQuery: '',
          orderBy: '',
          sortBy: '',
          // isAiGenerated: true,
        }),
      );
    }
  }, [appointmentId, evaluationId]);

  useEffect(() => {
    if (evaluationContext) {
      const counts = countBlocks();
      const evalTabs = Object.keys(counts)
        .map(key => {
          return {
            tabKey: key,
            count: counts[key].count,
            label: counts[key].label,
            filter: counts[key].filter,
            contentLabel: counts[key].contentLabel,
          };
        })
        .filter(tab => tab.count > 0);
      setEvaluationTabs(evalTabs);
      setLoading(false);
    }
  }, [evaluationContext]);

  const allContentBlocks = useMemo((): Array<ICBResponse & IDctContentBlock> => {
    if (!evaluationContext) {
      return [];
    }
    const blocks = evaluationContext.cbResponseList
      .map(cb => {
        if (cb.cbType === 'dct' && cb?.dctContentBlockList) {
          return cb?.dctContentBlockList?.map(innerCb => {
            return {
              ...innerCb,
              dctId: cb.dctId,
              responderType: cb.responderType,
            };
          });
        }
        return cb;
      })
      .flat();
    return blocks as Array<ICBResponse & IDctContentBlock>;
  }, [evaluationContext]);

  const countBlocks = (): ICounters => {
    const initialCounters: ICounters = {
      all: {
        count: 0,
        filter: null,
        label: 'All',
        contentLabel: 'All questions',
      },
      required: {
        count: 0,
        filter: (cb: ICBResponse & IDctContentBlock) => cb.required,
        label: 'Required',
        contentLabel: 'Required questions',
      },
      provider: {
        count: 0,
        filter: (cb: ICBResponse & IDctContentBlock) => cb.responderType === 'PROVIDER',
        label: 'For provider',
        contentLabel: 'Questions for provider',
      },
    };
    return allContentBlocks.reduce((acc, cb) => {
      acc.all.count++;
      if (cb.required) {
        acc.required.count++;
      }
      if (cb.responderType === 'PROVIDER') {
        acc.provider.count++;
      }
      return acc;
    }, initialCounters);
  };

  const fetchEvaluationContext = async () => {
    setLoading(true);
    try {
      const { data } = await getEvaluationContext({ appointmentId, evaluationId });
      setEvaluationContext(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const getAllEvaluations = async () => {
    try {
      const responseNoAi = await getEvaluationList({
        pageNumber: 0,
        pageSize: 10000,
        status: 'ACTIVE',
        reportingView: true,
        searchQuery: '',
        orderBy: '',
        isAiGenerated: false,
      });
      const responseAi = await getEvaluationList({
        pageNumber: 0,
        pageSize: 10000,
        status: 'ACTIVE',
        reportingView: true,
        searchQuery: '',
        orderBy: '',
        isAiGenerated: true,
      });
      setAllEvaluations([
        ...responseNoAi?.data?.EvaluationSummaryList,
        ...responseAi?.data?.EvaluationSummaryList,
      ]);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAppointmentsDetails = async () => {
    try {
      const { data } = await getAppointmentById({ appointmentId });
      setAppointmentDetails(data);
    } catch (e) {
      console.error(e);
    }
  };

  const addDcts = async selectedDcts => {
    const requests = {
      type: 'dct',
      conversationId: evaluationId,
      dctId: selectedDcts?.dctId,
      referenceId: `DCT-CB-${totalDCT + 1}`,
    };
    try {
      const response = await addNewContentBlock(requests);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'DCTs added successfully',
          }),
        );
        void fetchEvaluationContext();
      }
    } catch (err) {
      const msg = err.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: msg,
        }),
      );
    }
  };

  const onSubmit = async () => {
    try {
      if (orderedItems.length === 0) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Please change the order of evaluation.',
          }),
        );
        return;
      }
      // code for dct order change
      let conversationRequest = {
        id: '',
        dct: {},
        contentBlocks: [],
        selfContained: true,
        defaultPriority: 'LOW',
        tags: ['Assessment'],
        whoCanBenefit: ['Clients seeing providers'],
        description: 'Confidant chatbot',
        avatar: 'https://i.imgur.com/Tgbdv8K.png',
        reorder: true,
      };
      const allBlocks = evaluationContext.cbResponseList;
      // console.log(allBlocks);
      const allBlocksDCT = allBlocks.filter(i => i.cbType === 'dct');
      const allOrderItemsDCTS = orderedItems.filter(i => i.cbType === 'dct');
      if (allBlocksDCT.length !== 0) {
        const dctss = getDctCBIds(allBlocksDCT, allOrderItemsDCTS);
        if (dctss.length !== 0) {
          for (let i = 0; i < dctss.length; i++) {
            /* eslint-disable no-await-in-loop */
            const { data } = await getEvaluationDCTAssignmentDetail(dctss[i].dctId, 1);
            conversationRequest = {
              ...conversationRequest,
              ...data,
              id: dctss[i].dctId,
              dct: { ...data.dct, status: 'ACTIVE' },
              contentBlocks: dctss[i].ids,
            };
            await updateDCT(dctss[i].dctId, conversationRequest);
          }
        } else {
          console.log('No order change in DCTs.');
        }
      }

      // code for main Eval order
      const allBlocksIds = allBlocks.map(item => item.cbId);
      const orderItemsIds = orderedItems.map(item => item.cbId);
      const isEvaluationOrderChanged = allBlocksIds.some((id, index) => id !== orderItemsIds[index]);
      const payload = {
        appointmentsCount: selectedEvaluation?.appointmentsCount,
        avatar: selectedEvaluation?.avatar || '',
        contentBlocks: [],
        cptCodes: selectedEvaluation?.cptCodes || [],
        dctCount: selectedEvaluation?.dctCount,
        defaultPriority: 'LOW',
        description: selectedEvaluation?.description || '',
        evaluationId,
        lastUpdated: dayjs().valueOf(),
        name: selectedEvaluation?.name || '',
        rejectionTemplate: selectedEvaluation?.rejectionTemplate || [],
        reorder: true,
        selfContained: true,
        status: 'ACTIVE',
        tags: [],
        version: selectedEvaluation?.version,
        whoCanBenefit: [],
      };
      if (isEvaluationOrderChanged) {
        payload.contentBlocks = orderItemsIds;
        await updateEvaluation(payload, { evaluationId });
      } else {
        console.log('No order change in main Evaluations.');
      }
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'Changes saved successfully.',
        }),
      );
    } catch (err) {
      const msg = err.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: msg,
        }),
      );
    }
    setTimeout(() => {
      void fetchEvaluationContext();
    }, 1000);
  };

  const renderTab = tab => {
    return {
      tabKey: tab.tabKey,
      label: tab.label || tab.tabKey,
      icon: <Badge className={classes.badge3}>{tab?.count || 0}</Badge>,
      iconPosition: 'end' as tabIconPosition,
    };
  };

  const renderTabPanel = (tabConfig: ITabItem) => {
    let blocks = evaluationContext?.cbResponseList?.map(item => {
      if (item?.dctContentBlockList) {
        const responderType = item?.responderType?.toLowerCase() === 'provider' ? 'PROVIDER' : null;
        const updatedContentBlockList = item?.dctContentBlockList?.map(block => {
          return {
            ...block,
            responderType,
          };
        });
        return {
          ...item,
          dctContentBlockList: updatedContentBlockList,
        };
      }
      return item;
    });
    if (tabConfig.filter) {
      blocks = allContentBlocks.filter(tabConfig.filter);
    }
    // setPreviousOrderedItems(blocks);
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box className={classes.evaluationTitle}>
          <span className={classes.capitalize}>{tabType}</span> questions
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <DctSection
            evaluations={blocks}
            fetchEvaluation={() => void fetchEvaluationContext()}
            tabType={tabType}
            saveDct={val => setOrderedItems(val)}
            getDCT={val => setTotalDCT(val)}
          />
        </Box>
      </Box>
    );
  };

  return (
    <BaseLayout propType="EVALUTIONS">
      <Box className={classes.root}>
        <Box className={classes.breadcrumbs}>
          <Breadcrumbs
            links={
              meta.authority === 'ADMIN'
                ? [
                    { href: `/admin/collection-evaluations`, text: 'Evaluations' },
                    { text: evaluationContext?.name },
                  ]
                : [{ text: 'Evaluations' }, { text: evaluationContext?.name }]
            }
          />
        </Box>
        {loading && (
          <div className={classes.loader}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading...
          </div>
        )}
        {!loading && (
          <>
            <Box className={classes.header}>
              <Box className={classes.title}>
                {appointmentDetails && (
                  <>
                    {appointmentDetails?.serviceName} with{' '}
                    {appointmentDetails?.patientFirstName && appointmentDetails?.patientLastName
                      ? `${appointmentDetails?.patientFirstName ?? ''} ${appointmentDetails?.patientLastName}`
                      : appointmentDetails?.participantName ?? 'No Name'}
                  </>
                )}
              </Box>
              {tabType === 'all' && (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <IconButton
                    size={btnSize.SMALL}
                    icon="plus"
                    className={classes.iconBtn}
                    onClick={() => setOpenAddDct(true)}
                  >
                    Add DCT
                  </IconButton>
                  <Button size={btnSize.SMALL} onClick={onSubmit}>
                    Save
                  </Button>
                </Box>
              )}
            </Box>
            {evaluationTabs.length !== 0 && (
              <Box className={classes.content}>
                <Box className={classes.tabContainer}>
                  <Tabs
                    value={tabType}
                    onChange={setTabType}
                    className={classes.evaluationTabs}
                    options={evaluationTabs.map(renderTab)}
                  />
                </Box>
                <Box className={classes.tabContent}>
                  {evaluationTabs.map((tabConfig: ITabItem, index) => (
                    <TabPanel value={tabType} tabKey={tabConfig.tabKey} key={index}>
                      {renderTabPanel(tabConfig)}
                    </TabPanel>
                  ))}
                </Box>
              </Box>
            )}
            {evaluationTabs.length === 0 && <Box paddingLeft="5px">No data found.</Box>}
          </>
        )}
      </Box>
      {openAddDct && (
        <AddDctDrawer isOpen={openAddDct} onClose={() => setOpenAddDct(false)} addDcts={addDcts} />
      )}
    </BaseLayout>
  );
};

export default EvaluationStates;
