import { IAction } from 'redux/store/types';
import { TOGGLE_SIDEBAR } from './actions';
import { CommonState } from './types';

export const DEFAULT = {
  showSidebar: true,
};

export default function commonReducer(state = DEFAULT, action: IAction): CommonState {
  const { type } = action;

  switch (type) {
    case TOGGLE_SIDEBAR: {
      return {
        showSidebar: !state.showSidebar,
      };
    }
    default: {
      return state;
    }
  }
}
