import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    backgroundColor: colors.white,
    borderRadius: 8,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '14px 24px',
  },
  headerBorder: {
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  headTitle: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
  },
  label: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  badge: {
    height: 28,
  },
  form: {
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    padding: 24,
    borderTop: `1px solid ${colors.neutral100}`,
    '& button': {
      height: 48,
      fontSize: 14,
    },
  },
  removeBtn: {
    height: 48,
    fontSize: 14,
    '& svg': {
      width: 18,
      marginRight: 8,
    },
  },
});
