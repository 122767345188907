import { FC } from 'react';
import clsx from 'clsx';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Button } from '@confidant-health/lib/ui/atoms/button';

import { useStyles } from './AppointmentGroupButton.styles';

interface Props {
  isPastAppointments: boolean;
  setIsPastAppointments: (showCalendar: boolean) => void;
}

const AppointmentGroupButton: FC<Props> = ({ isPastAppointments, setIsPastAppointments }) => {
  const classes = useStyles();

  const setPastAppointments = () => {
    setIsPastAppointments(true);
  };

  const setCurrentAppointments = () => {
    setIsPastAppointments(false);
  };

  return (
    <ButtonGroup variant="outlined" aria-label="outlined button group">
      <Button
        className={clsx(classes.btnGroupIcon, { [classes.btnGroupIconActive]: !isPastAppointments })}
        onClick={setCurrentAppointments}
      >
        Current
      </Button>
      <Button
        className={clsx(classes.btnGroupIcon, { [classes.btnGroupIconActive]: isPastAppointments })}
        onClick={setPastAppointments}
      >
        Past
      </Button>
    </ButtonGroup>
  );
};

export default AppointmentGroupButton;
