import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { fontWeight, Text, TextError, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { colors } from '@confidant-health/lib/colors';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import { Calendar } from '@confidant-health/lib/ui/organisms/calendar';
import { Toggle } from '@confidant-health/lib/ui/atoms/toggle';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { getAppointment } from 'redux/modules/appointment/selectors';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { US_TIMEZONES } from 'pages/provider/profile/Profile.constants';
import { showSnackbar } from 'redux/modules/snackbar';
import getConfig from 'config';
import { RadioGroup, radioGroupType } from '@confidant-health/lib/ui/molecules/radio-group';
import { DateRange } from '@mui/x-date-pickers-pro';

import { useStyles } from './StepFour.styles';
import { RECURRING_APPOINTMENT_FREQUENCIED, appointmentType } from '../AddSchedule.constants';
import {
  getDateLabel,
  getDuration,
  getFullImagePath,
  groupTimeSlotsByDate,
} from '../../../../../utils/CommonUtils';
import { IRecurringSlot, ISlot } from '../AddSchedule.types';
import { IPatient, IProvider } from '../../../../../redux/modules/profile/types';
import SlotComponent from '../SlotsView';

type Props = {
  formData: {
    member: IPatient;
    provider: IProvider;
    time: ISlot;
    serviceId?: string;
    selectedService?: any;
  };
  isNewProviderSelected?: boolean;
  onChangeSelectedTimeSlot?: (time: any) => void;
  onChangeProvider?: (provider: any) => void;
  selectedProvider?: any;
  setAppointmentType?: (type: any) => void;
  setSelectedServiceId?: (id: any) => void;
  selectedAppointmentType?: string;
  providerr?: any;
  error?: string;
  isVideoSession?: boolean;
  selectedDateTime?: any;
  updateSelectedDateTime?: (time: any) => void;
  setRecurringType?: (reccrenceRate: string) => void;
  recurringType?: string;
  recurringSlots?: IRecurringSlot[];
  setRecurringSlots?: (slots: IRecurringSlot[]) => void;
  isLoadingRecurringSlots?: boolean;
  setSelectedDateRange?: (dateRange: Dayjs[]) => void;
  selectedDateRange?: Dayjs[];
  contentRef: React.RefObject<HTMLDivElement>;
};

const StepFourNew: FC<Props> = ({
  formData,
  isNewProviderSelected,
  onChangeSelectedTimeSlot,
  onChangeProvider,
  setAppointmentType,
  setSelectedServiceId,
  selectedAppointmentType,
  providerr,
  error,
  isVideoSession = true,
  selectedDateTime,
  updateSelectedDateTime,
  setRecurringType,
  recurringType,
  recurringSlots = [],
  setRecurringSlots,
  isLoadingRecurringSlots,
  setSelectedDateRange,
  selectedDateRange,
  contentRef,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(appointmentType.SPECIFIC_DATE);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [recurringTimeSlots, setRecurringTimeSlots] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>(null);
  const [monthChanged, setMonthChanged] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<any>();
  const [availableProviders, setAvailableProviders] = useState<any>([]);
  const { isAdmin } = useSelector(getAuth);
  const { masterSchedule2 } = useSelector(getAppointment);
  const { profile } = useSelector(getProfile);
  const [providers, setProviders] = useState([]);
  const [selectedProvider2, setSelectedProvider2] = useState(null);
  const [isTimeZoneVisible, setIsTimeZoneVisible] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState('America/New_York');
  const [showAllTimeSlots, setShowAllTimeSlots] = useState(false);
  const recurringRateRadioOptions = [
    { label: 'Weekly', value: RECURRING_APPOINTMENT_FREQUENCIED.WEEKLY },
    { label: 'Bi-weekly', value: RECURRING_APPOINTMENT_FREQUENCIED.BI_WEEKLY },
    { label: 'Monthly', value: RECURRING_APPOINTMENT_FREQUENCIED.MONTHLY },
  ];
  const selectedDateAvailableSlots =
    currentTab === appointmentType.RECURRING && showAllTimeSlots
      ? recurringTimeSlots?.find(t => selectedDateRange?.[0]?.isSame(dayjs(t?.date), 'day'))
      : timeSlots?.find(t => selectedDate?.isSame(dayjs(t?.date), 'day'));
  const isSameProvider = formData?.provider?.id === profile?.providerId;
  const [filter, setFilter] = useState({
    before9AM: true,
    btwn9AMTo12PM: true,
    btwn12PMTo3PM: true,
    btwn3PMTo5PM: true,
    after5PM: true,
    insurance: true,
    cashPayment: true,
  });
  const onChangeDate = useCallback(date => {
    setSelectedDate(date);
    if (selectedDateTime) {
      const selectedDateTimeMonth = dayjs(selectedDateTime, 'DD-MM-YYYY').month() + 1;
      const month = Number(date.month()) + 1;

      if (selectedDateTimeMonth > month) updateSelectedDateTime(dayjs(date, 'DD-MM-YYYY'));
    }
  }, []);

  const onFetchSlots = () => {
    let commaSeparatedParticipantIds;
    if (isNewProviderSelected) {
      const serviceIds = formData?.selectedService?.providerServices?.map(
        providerService => providerService?.providerId,
      );
      commaSeparatedParticipantIds = serviceIds.join(',');
    }
    dispatch(
      appointmentActionCreators.fetchMasterSchedule2({
        participantIds: isNewProviderSelected ? commaSeparatedParticipantIds : formData?.provider?.id,
        memberId: formData?.member?.id,
        serviceId: isNewProviderSelected ? '' : formData?.selectedService?.id,
        viewProviderDetails: isNewProviderSelected,
        viewInsurances: true,
        timezone: selectedTimeZone,
        startDate:
          currentTab === appointmentType.RECURRING
            ? selectedDateRange[0]?.startOf('month')?.format('DD-MM-YYYY')
            : selectedDate?.startOf('month')?.format('DD-MM-YYYY'),
        endDate:
          currentTab === appointmentType.RECURRING
            ? selectedDateRange[0]?.endOf('month')?.format('DD-MM-YYYY')
            : selectedDate?.endOf('month')?.format('DD-MM-YYYY'),
        state: formData?.member?.state,
        duration: formData?.selectedService?.duration,
      }),
    );
  };
  const onFetchSlotsRecurring = () => {
    let commaSeparatedParticipantIds;
    if (isNewProviderSelected) {
      const serviceIds = formData?.selectedService?.providerServices?.map(
        providerService => providerService?.providerId,
      );
      commaSeparatedParticipantIds = serviceIds.join(',');
    }
    dispatch(
      appointmentActionCreators.fetchMasterSchedule2({
        participantIds: isNewProviderSelected ? commaSeparatedParticipantIds : formData?.provider?.id,
        memberId: formData?.member?.id,
        serviceId: isNewProviderSelected ? '' : formData?.selectedService?.id,
        viewProviderDetails: isNewProviderSelected,
        viewInsurances: true,
        timezone: selectedTimeZone,
        startDate:
          currentTab === appointmentType.RECURRING
            ? selectedDateRange[0]?.format('DD-MM-YYYY')
            : selectedDate?.startOf('month')?.format('DD-MM-YYYY'),
        endDate:
          currentTab === appointmentType.RECURRING
            ? selectedDateRange[0]?.format('DD-MM-YYYY')
            : selectedDate?.endOf('month')?.format('DD-MM-YYYY'),
        state: formData?.member?.state,
        duration: formData?.selectedService?.duration,
        viewAllPossibleSlots: showAllTimeSlots,
      }),
    );
  };

  useEffect(() => {
    onFetchSlotsRecurring();
  }, [showAllTimeSlots, selectedDateRange]);

  useEffect(() => {
    setMonthChanged(true);
    setSelectedSlot(null);
    onFetchSlots();
  }, [selectedMonth, selectedTimeZone]);

  useEffect(() => {
    if (currentTab === appointmentType.RECURRING) {
      setSelectedDateRange([
        selectedSlot?.startTime ? dayjs(selectedSlot?.startTime) : selectedDateRange?.[0],
        selectedDateRange?.[1],
      ]);
    }
  }, [selectedSlot]);

  const scheduledDays = useMemo(() => {
    const availableDays = [] as any[];
    timeSlots.forEach(t => {
      if (t.slots.length > 0) {
        const anySlotAvailableForInsurance = t.slots.some(slot => slot.availableForInsurance);
        const isAvailable = t.slots.some(slot => slot.isAvailable);
        availableDays.push({
          date: t.date,
          availableForInsurance: anySlotAvailableForInsurance,
          isAvailable,
        });
      }
    });
    return availableDays;
  }, [timeSlots]);

  const getTimeSlots = uniqueSlots => {
    uniqueSlots = uniqueSlots.filter(slot => {
      return filterByAppliedFilters(slot.start);
    });
    const groupedObjects = groupTimeSlotsByDate(uniqueSlots);
    const slots: any[] = [];
    groupedObjects.forEach(daySlots => {
      const dateISO = dayjs(daySlots.date, 'YYYY-MM-DD').toISOString();
      const slotsFormatted = daySlots.slots.map(item =>
        showAllTimeSlots
          ? {
              startTime: item.start,
              endTime: item.end,
              isAvailable: item.isAvailable ?? item.available,
              isFocused: item.isFocused ?? item.focusSlot,
            }
          : {
              startTime: item.start,
              endTime: item.end,
              availableForInsurance: item.availableForInsurance,
              isAvailable: true,
            },
      );

      if (slotsFormatted.length) {
        slots.push({
          date: dateISO,
          slots: slotsFormatted,
        });
      }
    });
    const sorted = slots.sort(
      (a, b) => dayjs(a.date, 'YYYY-MM-DD').unix() - dayjs(b.date, 'YYYY-MM-DD').unix(),
    );
    return sorted;
  };

  useEffect(() => {
    let availabilities = masterSchedule2;
    if (!availabilities.length) {
      availabilities = [];
    }
    let filteredProviders = availabilities?.map(schedule => {
      /* if (!formData?.member?.insurance) {
        return schedule-link;
      } */
      const acceptsCurrentInsurance = schedule.providerDetailsDto.insuranceDetails.some(
        entry =>
          entry.state === formData?.member?.state &&
          entry.insurances.some(insurance => insurance?.name === formData?.member?.insurance),
      );
      return {
        ...schedule,
        providerDetailsDto: {
          ...schedule.providerDetailsDto,
          acceptsCurrentInsurance,
        },
      };
    });

    if (!filter.cashPayment) {
      filteredProviders = filteredProviders.filter(
        provider => provider.providerDetailsDto.acceptsCurrentInsurance,
      );
    }
    if (!filter.insurance && !filter.cashPayment) filteredProviders = [];

    setAvailableProviders(filteredProviders);

    const uniqueSlots = filteredProviders.reduce((slots, matchmaker) => {
      matchmaker.slots.forEach(slot => {
        const existingSlot = slots.find(s => s.start === slot.start && s.end === slot.end);
        if (!existingSlot) {
          slots.push({
            start: slot.start,
            end: slot.end,
            availableForInsurance: matchmaker.providerDetailsDto.acceptsCurrentInsurance && filter.insurance,
          });
        }
      });
      return slots;
    }, []);

    const uniqueRecurringSlots = filteredProviders.reduce((slots, matchmaker) => {
      matchmaker?.recurringSlots?.forEach(slot => {
        const existingSlot = slots?.find(s => s?.start === slot?.start && s?.end === slot?.end);
        if (!existingSlot) {
          slots.push({
            start: slot.start,
            end: slot.end,
            isAvailable: slot.available,
            isFocused: slot.focusSlot,
          });
        }
      });
      return slots;
    }, []);

    if (selectedAppointmentType === appointmentType.RECURRING) {
      const firstSlot = uniqueRecurringSlots?.sort(
        (a, b) => dayjs(a?.start)?.unix() - dayjs(b?.start)?.unix(),
      )?.[0];
      if (firstSlot) {
        const firstSlotMonth = dayjs(firstSlot.start).month() + 1;
        const selectedDateTimeMonth = dayjs(selectedDateRange?.[0], 'DD-MM-YYYY').month() + 1;

        if (firstSlotMonth === selectedDateTimeMonth) {
          setSelectedDate(dayjs(selectedDateRange?.[0], 'DD-MM-YYYY'));
        } else if (selectedDateTimeMonth > firstSlotMonth) {
          setSelectedMonth(selectedDateTimeMonth);
          setSelectedDate(dayjs(selectedDateRange?.[0], 'DD-MM-YYYY'));
        }
      }
    } else {
      const firstSlot = uniqueSlots.sort((a, b) => dayjs(a.start).unix() - dayjs(b.start).unix())[0];

      // if (firstSlot) {
      //   setSelectedDate(dayjs(firstSlot.start));
      // }
      // // To show the selected date on the calendar if coming from schedule-link page
      // if (selectedDateTime) setSelectedDate(dayjs(selectedDateTime, 'DD-MM-YYYY'));

      // To show the selected date on the calendar if coming from schedule-link page, otherwise the selected date
      if (firstSlot) {
        const firstSlotMonth = dayjs(firstSlot.start).month() + 1;
        const selectedDateTimeMonth = dayjs(selectedDateTime, 'DD-MM-YYYY').month() + 1;

        if (firstSlotMonth === selectedDateTimeMonth) {
          setSelectedDate(dayjs(selectedDateTime, 'DD-MM-YYYY'));
        } else if (selectedDateTimeMonth > firstSlotMonth) {
          setSelectedMonth(selectedDateTimeMonth);
          setSelectedDate(dayjs(selectedDateTime, 'DD-MM-YYYY'));
        }
      }
    }
    if (currentTab !== appointmentType.RECURRING || monthChanged) {
      setTimeSlots(getTimeSlots(uniqueSlots));
      setMonthChanged(false);
    }
    setRecurringTimeSlots(getTimeSlots(uniqueRecurringSlots));
  }, [masterSchedule2, filter]);

  const filterByAppliedFilters = start => {
    const dayStart = dayjs(start).startOf('day');
    const filters: any[] = [];
    if (!filter.before9AM) {
      // return that start is not before 9am
      filters.push(dayjs(start).isAfter(dayStart.set('hour', 9).set('minute', 0).set('second', 0)));
    }
    if (!filter.btwn9AMTo12PM) {
      // return that start is not after 9am and before 12pm
      filters.push(
        dayjs(start).isBefore(dayStart.set('hour', 9).set('minute', 0).set('second', 0)) ||
          dayjs(start).isAfter(dayStart.set('hour', 12).set('minute', 0).set('second', 0)),
      );
    }
    if (!filter.btwn12PMTo3PM) {
      // return that start is not between 12pm and 3pm
      filters.push(
        dayjs(start).isBefore(dayStart.set('hour', 12).set('minute', 0).set('second', 0)) ||
          dayjs(start).isAfter(dayStart.set('hour', 15).set('minute', 0).set('second', 0)),
      );
    }
    if (!filter.btwn3PMTo5PM) {
      // return that start is not between 3pm and 5pm
      filters.push(
        dayjs(start).isBefore(dayStart.set('hour', 15).set('minute', 0).set('second', 0)) ||
          dayjs(start).isAfter(dayStart.set('hour', 17).set('minute', 0).set('second', 0)),
      );
    }
    if (!filter.after5PM) {
      // return that start is not after 5pm
      filters.push(dayjs(start).isBefore(dayStart.set('hour', 17).set('minute', 0).set('second', 0)));
    }
    if (filters.length === 0) {
      return [false];
    }
    return filters.every(Boolean);
  };

  const onChangeTimezone = e => {
    const updatedTz = e.target.value && e.target.value.length > 0 ? e.target.value : null;
    setSelectedTimeZone(updatedTz);
    setIsTimeZoneVisible(false);
  };

  const onTimeSlotClick = (timeSlot: any, date) => () => {
    // dispatch(appointmentActionCreators.setSelectedSlot({ date, slot: timeSlot }));
    const filteredProviders = availableProviders?.filter(provider => {
      return provider?.slots?.some(slot => {
        return selectedAppointmentType === appointmentType.RECURRING
          ? slot?.start === timeSlot?.startTime
          : slot?.start === timeSlot?.startTime && slot?.end === timeSlot?.endTime;
      });
    });
    // dispatch(appointmentActionCreators.setSelectedSlotProviders(filteredProviders));
    setProviders(filteredProviders);
    setSelectedSlot(timeSlot);

    onChangeSelectedTimeSlot({
      start: timeSlot?.startTime ?? null,
      end: timeSlot?.endTime ?? null,
    });

    if (isNewProviderSelected && filteredProviders?.length > 0 && contentRef.current) {
      setTimeout(() => {
        contentRef.current.scrollTo({
          top: contentRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  const copyToClipboard = async link => {
    await navigator.clipboard.writeText(link);
    dispatch(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Scheduling link copied successfully',
        vertical: 'top',
        horizontal: 'center',
      }),
    );
  };

  useEffect(() => {
    setSelectedMonth(selectedDate?.month() + 1);
  }, [selectedDate]);

  useEffect(() => {
    setAppointmentType(currentTab);
  }, [currentTab]);

  const appointmentValues = {
    currentState: formData?.member?.state?.replaceAll(' ', '-') || null,
    selectedInsurance: formData?.member?.insurance?.replaceAll(' ', '-') || null,
    selectedServiceId: formData?.selectedService?.id || formData?.selectedService?.serviceId || null,
    selectedProvider: !isNewProviderSelected ? formData?.provider?.id || null : null,
    startTime: !isNewProviderSelected ? formData?.time?.start || null : null,
    memberId: formData?.member?.id || null,
  };
  const generateAppointmentLink = (values: Record<string, string | number | boolean>) => {
    const baseUrl = `${getConfig.memberAppUrl}public/book-appointment`;

    const queryParams = Object.entries(values)
      .filter(([, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
  };

  const schedulingLink = generateAppointmentLink(appointmentValues);

  const renderAppointmentInfo = () => {
    return (
      <Box>
        {currentTab === appointmentType.SCHEDULING_LINK && (
          <Box className={classes.infoContainer}>
            <Box className={classes.schedulingLinkBox}>
              <Box className={classes.schedulingLinkBoxLabel}>Your scheduling link:</Box>
              <Box className={classes.schedulingLinkBoxContainer}>
                <Box className={classes.schedulingLinkContainer}>
                  <Box className={classes.iconContainer}>
                    <Icons glyph="connect" color={colors.neutral400} />
                  </Box>
                  <Box className={classes.linkText}>
                    <span className={classes.linkText2}>{schedulingLink}</span>
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    icon="copy-outlined"
                    variant={iconBtnType.OUTLINE}
                    className={classes.btn}
                    onClick={() => copyToClipboard(schedulingLink)}
                  >
                    Copy link
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box className={classes.userInfoBox}>
          <Box
            className={clsx(classes.userInfoContainer, classes.underlineBottom, {
              [classes.instantTab]: currentTab === appointmentType.INSTANT_SESSION,
            })}
          >
            <Box className={classes.userInfoLabel}>Member</Box>
            <Box>
              <ProfileInfo
                type="member"
                photo={getFullImagePath(formData?.member?.photo)}
                nickName={`${formData?.member?.firstName || ''} ${formData?.member?.lastName || ''}`}
                fullName={formData?.member?.uuid}
                role={formData?.member?.designation}
              />
            </Box>
          </Box>
          {!isNewProviderSelected && (
            <Box
              className={clsx(classes.userInfoContainer, classes.underlineBottom, {
                [classes.instantTab]: currentTab === appointmentType.INSTANT_SESSION,
              })}
            >
              <Box className={classes.userInfoLabel}>Provider</Box>
              <Box>
                {currentTab === appointmentType.SCHEDULING_LINK && isNewProviderSelected ? (
                  <span className={classes.text16}>TBD</span>
                ) : (
                  <Box>
                    <ProfileInfo
                      type="provider"
                      photo={
                        !isNewProviderSelected && selectedAppointmentType !== appointmentType.INSTANT_SESSION
                          ? getFullImagePath(formData?.provider?.profileImage)
                          : isNewProviderSelected &&
                            selectedAppointmentType !== appointmentType.INSTANT_SESSION
                          ? getFullImagePath(providerr?.profileImage)
                          : getFullImagePath(profile?.profileImage)
                      }
                      nickName={
                        !isNewProviderSelected && selectedAppointmentType !== appointmentType.INSTANT_SESSION
                          ? formData?.provider?.fullName
                          : isNewProviderSelected &&
                            selectedAppointmentType !== appointmentType.INSTANT_SESSION
                          ? providerr?.fullName
                          : 'You'
                      }
                      fullName={
                        !isNewProviderSelected && selectedAppointmentType !== appointmentType.INSTANT_SESSION
                          ? formData?.provider?.fullName
                          : isNewProviderSelected &&
                            selectedAppointmentType !== appointmentType.INSTANT_SESSION
                          ? providerr?.fullName
                          : 'You'
                      }
                      role={
                        selectedAppointmentType !== appointmentType.INSTANT_SESSION
                          ? formData?.provider?.designation
                          : ''
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
          <Box
            className={clsx(classes.userInfoContainer, {
              [classes.instantTab]: currentTab === appointmentType.INSTANT_SESSION,
              [classes.underlineBottom]: currentTab !== appointmentType.SCHEDULING_LINK,
            })}
          >
            <Box className={classes.userInfoLabel}>Service</Box>
            <Box className={classes.valueSection}>
              <Text className={classes.text16_1} level={textLevel.M} weight={fontWeight.MEDIUM}>
                {formData?.selectedService?.name}
              </Text>
              <Text className={classes.text16_1_1} level={textLevel.M} weight={fontWeight.MEDIUM}>
                {getDuration(formData?.selectedService)}, ${formData?.selectedService?.cost}
              </Text>
            </Box>
          </Box>
          {currentTab !== appointmentType.INSTANT_SESSION && currentTab !== appointmentType.SCHEDULING_LINK && (
            <Box
              className={clsx(classes.userInfoContainer, classes.underlineBottom, {
                [classes.instantTab]: currentTab === appointmentType.INSTANT_SESSION,
              })}
            >
              <Box className={classes.userInfoLabel}>Type</Box>
              <Box className={classes.typeContainer}>
                <Icons
                  glyph={
                    currentTab === appointmentType.SCHEDULING_LINK
                      ? 'connect'
                      : 'instant-appointment-outlined'
                  }
                  color={colors.primary}
                  // className={classes.bookNewProviderIcon}
                />
                <Text className={classes.text16_2} level={textLevel.M} weight={fontWeight.MEDIUM}>
                  {currentTab === appointmentType.SCHEDULING_LINK ? 'Scheduling Link' : 'Instant Session'}
                </Text>
              </Box>
            </Box>
          )}
          {currentTab !== appointmentType.SCHEDULING_LINK && (
            <Box
              className={clsx(classes.userInfoContainer, {
                [classes.instantTab]: currentTab === appointmentType.INSTANT_SESSION,
              })}
            >
              <Box className={classes.userInfoLabel}>Date & time</Box>
              <Box>
                {currentTab === appointmentType.SCHEDULING_LINK ? (
                  <span className={classes.text16}>TBD</span>
                ) : (
                  <Box className={classes.valueSection}>
                    <Text className={classes.text16_1} level={textLevel.M} weight={fontWeight.MEDIUM}>
                      {dayjs().format('MM/DD/YYYY')}
                    </Text>
                    <Text className={classes.text16_1_1} level={textLevel.M} weight={fontWeight.MEDIUM}>
                      {dayjs().format('hh:mm A')}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderSpecificDate = () => {
    return (
      <Box className={classes.specificDateandTimeContainer}>
        <Box className={classes.specificDateContainer}>
          <Box className={classes.dateTimeContainer}>
            <Box
              sx={{
                width: 540,
                '& .MuiPickersLayout-root': {
                  backgroundColor: 'transparent',
                },
                '& .MuiDateCalendar-root': {
                  width: { sm: '456px', xs: '100%' },
                  maxHeight: '400px',
                  height: 400,
                  marginTop: '0px !important',
                  margin: 0, // decide at end
                },
                '& .MuiYearCalendar-root': {
                  width: { sm: '456px', xs: '100%' },
                  maxHeight: '400px',
                },
                '& .Mui-selected': {
                  border: 'solid 1px #1976d2',
                },
                '& .MuiDayCalendar-slideTransition': {
                  minHeight: { sm: '392px !important', xs: '303px !important' },
                },
                '& .MuiPickersCalendarHeader-root': {
                  paddingLeft: { sm: '18px', xs: '0px' },
                  paddingRight: '5px',
                  // marginTop: '0px !important',
                  // marginBottom: '16px',
                },
                '& .MuiDayCalendar-header': {
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '16px',
                  fontFamily: 'inter',
                  color: '#666E77',
                  display: 'flex',
                  gap: '8px',
                },
                '& .MuiDayCalendar-weekDayLabel': {
                  width: { sm: '48px', xs: '48px' },
                  height: { sm: '48px', xs: '48px' },
                  margin: { sm: '4px', xs: '4px' },
                },
                '& .MuiDayCalendar-weekContainer': {
                  lineHeight: '16px',
                  fontFamily: 'inter',
                  color: '#666E77',
                  gap: '8px',
                  display: 'flex',
                  '& .MuiPickersDay-root': {
                    width: { sm: '48px', xs: '48px' },
                    height: { sm: '48px', xs: '48px' },
                    margin: { sm: '4px', xs: '4px' },
                  },
                },
              }}
            >
              <Calendar
                value={selectedDate}
                onChange={onChangeDate}
                scheduledDays={scheduledDays}
                isFilteredCash={!filter.insurance && filter.cashPayment}
              />
              <Box className={classes.informationContainer}>
                <Box className={classes.infoLabels}>
                  <Icons glyph="circle-cash-only" />
                  Only cash Pay
                </Box>
                <Box className={classes.infoLabels}>
                  <Icons glyph="circle-insurance-only" />
                  Insurance appointment available
                </Box>
              </Box>
            </Box>
            <Box className={classes.filterBoxContainer}>
              <Box className={classes.toggleFilter}>
                <Box className={classes.providerTitle}>By available slots</Box>
                <Box className={classes.filterToggle}>
                  <Toggle
                    checked={filter.before9AM}
                    onChange={() => setFilter({ ...filter, before9AM: !filter.before9AM })}
                  >
                    Before 9am
                  </Toggle>
                  <Toggle
                    checked={filter.btwn9AMTo12PM}
                    onChange={() => setFilter({ ...filter, btwn9AMTo12PM: !filter.btwn9AMTo12PM })}
                  >
                    9am - 12pm
                  </Toggle>
                  <Toggle
                    checked={filter.btwn12PMTo3PM}
                    onChange={() => setFilter({ ...filter, btwn12PMTo3PM: !filter.btwn12PMTo3PM })}
                  >
                    12pm - 3pm
                  </Toggle>
                  <Toggle
                    checked={filter.btwn3PMTo5PM}
                    onChange={() => setFilter({ ...filter, btwn3PMTo5PM: !filter.btwn3PMTo5PM })}
                  >
                    3pm - 5pm
                  </Toggle>
                  <Toggle
                    checked={filter.after5PM}
                    onChange={() => setFilter({ ...filter, after5PM: !filter.after5PM })}
                  >
                    After 5pm
                  </Toggle>
                </Box>
              </Box>
              <Box className={classes.toggleFilter}>
                <Box className={classes.providerTitle}>By payment method</Box>
                <Box className={classes.filterToggle}>
                  <Toggle
                    checked={filter.insurance}
                    onChange={() => setFilter({ ...filter, insurance: !filter.insurance })}
                    // isDisabled={!formData?.member?.insurance}
                  >
                    Insurance
                  </Toggle>
                  <Toggle
                    checked={filter.cashPayment}
                    onChange={() => setFilter({ ...filter, cashPayment: !filter.cashPayment })}
                  >
                    Cash payment
                  </Toggle>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.slotsssContainer}>
            <Box className={classes.timezoneLabel} onClick={() => setIsTimeZoneVisible(!isTimeZoneVisible)}>
              <Icons glyph="edit-underline" className={classes.editIcon} />
              Time slots are in {selectedTimeZone?.split('/')[1].replaceAll('_', ' ')} EST
            </Box>
            {isTimeZoneVisible && (
              <Box className={classes.timezoneContainer}>
                <Box className={classes.slotHeading}>Timezone</Box>
                <Select
                  variant={selectType.SECONDARY}
                  value={selectedTimeZone}
                  displayEmpty
                  emptyText="Select timezone"
                  className={classes.select}
                  onChange={onChangeTimezone}
                  options={US_TIMEZONES}
                />
              </Box>
            )}
            <Box className={classes.slotsssContainer2}>
              {selectedDateAvailableSlots && selectedDateAvailableSlots?.slots?.length > 0 ? (
                <Box display="flex" flexDirection="column" width="100%" justifyContent="center">
                  <Box className={classes.slotHeading}>
                    {getDateLabel(selectedDateAvailableSlots?.date, 'MMMM DD, YYYY')}
                  </Box>
                  <Box className={classes.slotContainer2}>
                    {selectedDateAvailableSlots?.slots
                      .sort((a, b) => {
                        return dayjs(a?.startTime)?.diff(dayjs(b?.startTime));
                      })
                      .map((slot, index) => {
                        const isSelected =
                          dayjs(selectedDateAvailableSlots.date)?.format('MMMM DD, YYYY') ===
                            selectedDate?.format('MMMM DD, YYYY') &&
                          dayjs(slot?.startTime)?.format('h:mm a') ===
                            dayjs(selectedSlot?.startTime)?.format('h:mm a');
                        return (
                          <Box
                            key={index}
                            className={clsx(classes.timeSlot, {
                              [classes.activetab]: isSelected,
                            })}
                            onClick={onTimeSlotClick(
                              slot,
                              dayjs(selectedDateAvailableSlots.date).format('DD-MM-YYYY'),
                            )}
                          >
                            <span className={classes.slotIndicator}>
                              {slot.availableForInsurance ? (
                                <Icons glyph="circle-insurance-only" />
                              ) : (
                                <Icons glyph="circle-cash-only" />
                              )}
                            </span>
                            {dayjs(slot.startTime).format('h:mm a')}
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              ) : (
                <Box className={classes.noSlotOneTime}>No slot available</Box>
              )}
            </Box>
          </Box>
        </Box>
        {isNewProviderSelected && providers.length !== 0 && (
          <Box className={clsx(classes.plOneTime, classes.providerListContainer)}>
            <Box className={classes.providerTitle}>
              There are {providers.length} providers with this slot available
            </Box>
            {providers.map((provider, index) => (
              <Box
                className={clsx(classes.providerContainer, {
                  [classes.activetab]: provider?.actor === selectedProvider2?.actor,
                })}
                key={index}
                onClick={() => {
                  setSelectedProvider2(provider);
                  const selectedService = formData?.selectedService?.providerServices?.find(
                    providerService => providerService?.providerId === provider?.actor,
                  );
                  onChangeProvider(selectedService);
                  setSelectedServiceId(selectedService.serviceId);
                }}
              >
                <ProfileInfo
                  type="provider"
                  photo={getFullImagePath(provider.providerDetailsDto.providerImage)}
                  nickName={provider.providerDetailsDto?.name}
                  fullName={provider.providerDetailsDto?.name}
                  role={provider.providerDetailsDto.designation}
                />
                <Box className={classes.providerAppDetailContainer}>
                  <Box>{provider?.providerDetailsDto?.activePatientsCount} active patients</Box>
                  <Box>{provider?.slots?.length} available slots</Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };
  const showValidatedSlots = () => {
    if (isNewProviderSelected) {
      if (
        currentTab === appointmentType.RECURRING &&
        recurringType &&
        selectedDateRange &&
        selectedProvider2
      ) {
        return true;
      }
      return false;
    }
    if (
      currentTab === appointmentType.RECURRING &&
      recurringType &&
      selectedDateRange &&
      recurringSlots?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const renderRecurring = () => {
    return (
      <Box className={classes.specificDateandTimeContainer}>
        <Box className={classes.specificDateContainer2}>
          <Box className={classes.dateTimeContainer}>
            <Box
              sx={{
                width: 540,
                '& .MuiPickersLayout-root': {
                  backgroundColor: 'transparent',
                },
                '& .MuiDateCalendar-root': {
                  width: { sm: '456px', xs: '100%' },
                  maxHeight: '400px',
                  height: 400,
                  marginTop: '0px !important',
                  margin: 0, // decide at end
                },
                '& .MuiYearCalendar-root': {
                  width: { sm: '456px', xs: '100%' },
                  maxHeight: '400px',
                },
                '& .Mui-selected': {
                  border: 'solid 1px #1976d2',
                },
                '& .MuiDayCalendar-slideTransition': {
                  minHeight: { sm: '392px !important', xs: '303px !important' },
                },
                '& .MuiPickersCalendarHeader-root': {
                  paddingLeft: { sm: '18px', xs: '0px' },
                  paddingRight: '5px',
                  // marginTop: '0px !important',
                  // marginBottom: '16px',
                },
                '& .MuiDayCalendar-header': {
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '16px',
                  fontFamily: 'inter',
                  color: '#666E77',
                  display: 'flex',
                  gap: '8px',
                },
                '& .MuiDayCalendar-weekDayLabel': {
                  width: { sm: '48px', xs: '48px' },
                  height: { sm: '48px', xs: '48px' },
                  margin: { sm: '4px', xs: '4px' },
                },
                '& .MuiDayCalendar-weekContainer': {
                  lineHeight: '16px',
                  fontFamily: 'inter',
                  color: '#666E77',
                  gap: '8px',
                  display: 'flex',
                  '& .MuiPickersDay-root': {
                    width: { sm: '48px', xs: '48px' },
                    height: { sm: '48px', xs: '48px' },
                    margin: { sm: '4px', xs: '4px' },
                  },
                },
              }}
            >
              <Calendar
                value={selectedDate}
                onChange={(date: Dayjs) => {
                  setRecurringSlots([]);
                  onChangeDate(date);
                  const rangeUnit =
                    recurringType === RECURRING_APPOINTMENT_FREQUENCIED.WEEKLY ||
                    recurringType === RECURRING_APPOINTMENT_FREQUENCIED.BI_WEEKLY
                      ? 'week'
                      : 'month';
                  const rangeAmount =
                    recurringType === RECURRING_APPOINTMENT_FREQUENCIED.WEEKLY ||
                    recurringType === RECURRING_APPOINTMENT_FREQUENCIED.MONTHLY
                      ? 3
                      : 6;
                  setSelectedDateRange([date, date.add(rangeAmount, rangeUnit)] as DateRange<Dayjs>);
                }}
                scheduledDays={scheduledDays}
                isRecurring
              />
              <Box className={classes.informationContainer}>
                <Box className={classes.infoLabels} color={colors.success500}>
                  <Icons glyph="circle-cash-only" width={18} height={18} />
                  Available
                </Box>
                <Box className={classes.infoLabels}>
                  <Icons glyph="circle-outlined" color={colors.neutral200} />
                  Not generally available
                </Box>
              </Box>
            </Box>

            <Box className={classes.filterBoxContainer}>
              <Box className={classes.toggleFilter}>
                <Box className={classes.providerTitle}>Recurrence rate</Box>
                <RadioGroup
                  variant={radioGroupType.HORIZONTAL}
                  options={recurringRateRadioOptions}
                  defaultValue={recurringRateRadioOptions[0].value}
                  onChange={event => {
                    setRecurringType(event.target.value);
                  }}
                  name="recurringType"
                  value={recurringType}
                  className={classes.text14}
                />
              </Box>
              {isNewProviderSelected && providers.length !== 0 && (
                <Box className={clsx(classes.pr, classes.providerListContainer)}>
                  <Box className={classes.providerTitle}>
                    There are {providers.length} providers with this slot available
                  </Box>
                  {providers.map((provider, index) => (
                    <Box
                      className={clsx(classes.providerContainer, {
                        [classes.activetab]: provider?.actor === selectedProvider2?.actor,
                      })}
                      key={index}
                      onClick={() => {
                        setSelectedProvider2(provider);
                        const selectedService = formData?.selectedService?.providerServices?.find(
                          providerService => providerService?.providerId === provider?.actor,
                        );
                        onChangeProvider(selectedService);
                        setSelectedServiceId(selectedService.serviceId);
                      }}
                    >
                      <ProfileInfo
                        type="provider"
                        photo={getFullImagePath(provider.providerDetailsDto.providerImage)}
                        nickName={provider.providerDetailsDto?.name}
                        fullName={provider.providerDetailsDto?.name}
                        role={provider.providerDetailsDto.designation}
                      />
                      <Box className={classes.providerAppDetailContainer}>
                        <Box>{provider?.providerDetailsDto?.activePatientsCount} active patients</Box>
                        {/* <Box>{provider?.slots?.length} available slots</Box> */}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
              <Box className={classes.fetchedSlotsContainer}>
                {isLoadingRecurringSlots ? (
                  <div className={classes.loader}>
                    <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                    Loading...
                  </div>
                ) : (
                  showValidatedSlots() &&
                  recurringSlots?.map((item, index) => (
                    <SlotComponent
                      key={`${index}-slot`}
                      slot={item}
                      showNotAvailableReason={false}
                      isSmallView
                    />
                  ))
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.slotsssContainer}>
            <Box className={classes.timezoneLabel} onClick={() => setIsTimeZoneVisible(!isTimeZoneVisible)}>
              <Icons glyph="edit-underline" className={classes.editIcon} />
              Time slots are in {selectedTimeZone?.split('/')[1].replaceAll('_', ' ')} EST
            </Box>
            {isTimeZoneVisible && (
              <Box className={classes.timezoneContainer}>
                <Box className={classes.slotHeading}>Timezone</Box>
                <Select
                  variant={selectType.SECONDARY}
                  value={selectedTimeZone}
                  displayEmpty
                  emptyText="Select timezone"
                  className={classes.select}
                  onChange={onChangeTimezone}
                  options={US_TIMEZONES}
                />
              </Box>
            )}
            <Box>
              <Toggle checked={showAllTimeSlots} onChange={() => setShowAllTimeSlots(!showAllTimeSlots)}>
                Show all possible times
              </Toggle>
            </Box>
            <Box className={classes.slotsssContainerRecuring}>
              {selectedDateAvailableSlots && selectedDateAvailableSlots?.slots?.length > 0 ? (
                <Box className={classes.slotContainer2}>
                  {selectedDateAvailableSlots.slots
                    .sort((a, b) => {
                      return dayjs(a.startTime).diff(dayjs(b.startTime));
                    })
                    .map((slot, index) => {
                      const isSelected =
                        dayjs(slot?.startTime).format('h:mm a') ===
                        dayjs(selectedSlot?.startTime).format('h:mm a');
                      return (
                        <Box
                          key={index}
                          className={clsx(classes.justifyFlexSpaceBetween, classes.timeSlotRecurring, {
                            [classes.activetab]: isSelected,
                          })}
                          onClick={onTimeSlotClick(
                            slot,
                            dayjs(selectedDateAvailableSlots.date).format('DD-MM-YYYY'),
                          )}
                        >
                          <Box
                            className={clsx(classes.text16, {
                              [classes.text16Selected]: isSelected,
                              [classes.colorDark]: !isSelected,
                            })}
                          >
                            {dayjs(slot.startTime).format('h:mm a')}
                          </Box>
                          {slot.isAvailable ? (
                            <Box className={classes.availablelabel}>Available</Box>
                          ) : (
                            <Box className={classes.notAvailableLabel}>Not generally available</Box>
                          )}
                        </Box>
                      );
                    })}
                </Box>
              ) : (
                <Box className={classes.noSlot}>No slot available</Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case appointmentType.SPECIFIC_DATE:
        return renderSpecificDate();
      case appointmentType.RECURRING:
        return renderRecurring();

      default:
        return renderAppointmentInfo();
    }
  };
  return (
    <>
      <Box className={classes.tabContainer}>
        <Box
          className={clsx(classes.tabs, {
            [classes.activetab]: currentTab === appointmentType.SPECIFIC_DATE,
          })}
          onClick={() => {
            setCurrentTab(appointmentType.SPECIFIC_DATE);
          }}
        >
          <Icons glyph="specific-date" />
          <Text weight={fontWeight.MEDIUM} className={classes.tabLabel}>
            One-time
          </Text>
        </Box>
        <Box
          className={clsx(classes.tabs, {
            [classes.activetab]: currentTab === appointmentType.RECURRING,
          })}
          onClick={() => {
            setCurrentTab(appointmentType.RECURRING);
          }}
        >
          <Icons glyph="restart" />
          <Text weight={fontWeight.MEDIUM} className={classes.tabLabel}>
            Recurring
          </Text>
        </Box>
        {!isNewProviderSelected && !isAdmin && isSameProvider && isVideoSession && (
          <Box
            className={clsx(classes.tabs, {
              [classes.activetab]: currentTab === appointmentType.INSTANT_SESSION,
            })}
            onClick={() => {
              setCurrentTab(appointmentType.INSTANT_SESSION);
            }}
          >
            <Icons
              glyph={
                currentTab === appointmentType.INSTANT_SESSION
                  ? 'InstantAppointment'
                  : 'instant-appointment-outlined'
              }
            />
            <Text weight={fontWeight.MEDIUM} className={classes.tabLabel}>
              Instant
            </Text>
          </Box>
        )}
        <Box
          className={clsx(classes.tabs, {
            [classes.activetab]: currentTab === appointmentType.SCHEDULING_LINK,
          })}
          onClick={() => {
            setCurrentTab(appointmentType.SCHEDULING_LINK);
          }}
        >
          <Icons glyph="connect" />
          <Text weight={fontWeight.MEDIUM} className={classes.tabLabel}>
            Link
          </Text>
        </Box>
      </Box>
      {error && (
        <Box display="flex" justifyContent="center" flex={1} padding={2}>
          <TextError errorMsg={error} />
        </Box>
      )}
      {renderTabContent()}
    </>
  );
};
export default StepFourNew;
