import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
// import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { useDispatch, useSelector } from 'react-redux';
import { SelectLevel } from 'pages/provider/member-detail/components/select-level/SelectLevel';

// types
import { IDomainElement as IProfileDomainElement, IDomainType } from 'redux/modules/profile/types';
import { IDomainElement } from 'redux/modules/priority/types';
import { ReportItem } from 'pages/provider/member-detail/components/AddNewReport/AddNewReport.types';
import { getProfile } from 'redux/modules/profile/selectors';
import { profileActionCreators } from 'redux/modules/profile';
import { getDomainElements } from 'services/member/member.service';
// schema
import { AddMedicationSchema } from './AddMedication.schema';

// styles
import { useStyles } from './AddMedication.styles';

interface Props {
  selectedDomainElement: IProfileDomainElement;
  domainElements: IDomainElement[];
  domainOptions: ReportItem[];
  symptoms?: IDomainType;
  onSubmit: (payload) => void;
  onClose: () => void;
}

const AddMedication: FC<Props> = ({
  selectedDomainElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  domainElements = [],
  domainOptions,
  onSubmit,
  onClose,
  symptoms,
}) => {
  const frequencies = [
    'Daily',
    'Twice a Day',
    'Three times a Day',
    'Every other Day',
    'As needed',
    'Other',
  ].map(frequency => ({ label: frequency, value: frequency }));

  const dispatch = useDispatch();

  const classes = useStyles();
  const location = useLocation();
  const { providers } = useSelector(getProfile);
  const providerListOptions = providers.map(provider => ({
    label: provider.fullName,
    value: provider.providerId,
  }));

  const symtomsList = symptoms?.relatedElements?.map(item => item.name);
  const [importanceLevels, setImportanceLevels] = useState([]);
  const groupOptions = useMemo(
    () => domainOptions?.map((domain: ReportItem) => ({ label: domain.label, value: domain.groupId })),
    [domainOptions],
  );

  useEffect(() => {
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  }, []);

  useEffect(() => {
    if (selectedDomainElement) {
      const tempImportanceLevels = [];
      getDomainElements({ domainTypeId: selectedDomainElement.domainElementId })
        .then(res => {
          if (res.status === 200) {
            Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
              if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                tempImportanceLevels.push({ value: key, label: key });
              }
            });
            setImportanceLevels(tempImportanceLevels);
          }
        })
        .catch(err => {
          console.log({ err });
        });
    }
  }, []);

  useEffect(() => {
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  }, []);

  useEffect(() => {
    if (selectedDomainElement) {
      const tempImportanceLevels = [];
      getDomainElements({ domainTypeId: selectedDomainElement.domainElementId })
        .then(res => {
          if (res.status === 200) {
            Object.keys(res?.data?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
              if (res?.data?.metaData?.metaDataSpec?.importanceLevels[key]) {
                tempImportanceLevels.push({ value: key, label: key });
              }
            });
            setImportanceLevels(tempImportanceLevels);
          }
        })
        .catch(err => {
          console.log({ err });
        });
    }
  }, []);

  useEffect(() => {
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  }, []);

  // TODO: should update backend api to be included 'grouping' info
  let selectedGrouping = '';
  if (selectedDomainElement) {
    domainOptions?.forEach((domain: ReportItem) => {
      domain.relatedElements.forEach(element => {
        if (element.Id === selectedDomainElement?.domainElementId) {
          selectedGrouping = domain.groupId;
        }
      });
    });
  }

  const defaultValues = {
    grouping: selectedGrouping,
    medication: selectedDomainElement?.domainElementId || '',
    dosage: selectedDomainElement?.tagMetaData?.rxDrugInfo?.dosage || '',
    frequency: selectedDomainElement?.tagMetaData?.rxDrugInfo?.frequency || '',
    symptoms: selectedDomainElement?.tagMetaData?.rxDrugInfo?.symptomsBeingTreated || [],
    provider:
      selectedDomainElement?.tagMetaData?.rxDrugInfo?.prescribingProvider === 'Internal'
        ? 'Confidant Provider'
        : 'External Provider' || 'Confidant Provider',
    confidantProvider: selectedDomainElement?.tagMetaData?.rxDrugInfo?.confidantProviderId || '',
    providerName: selectedDomainElement?.assignedBy || '',
    status: selectedDomainElement?.priority?.name || '',
    additionalNotes: selectedDomainElement?.notes || '',
  };

  const { errors, values, handleChange, setFieldValue, handleSubmit, touched, ...rest } = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: AddMedicationSchema,
    onSubmit: (payload: typeof defaultValues) => {
      let confidantProviderName = '';
      if (payload.confidantProvider) {
        confidantProviderName = providerListOptions.find(
          providerValue => providerValue.value === payload.provider,
        )?.label;
      }

      onSubmit({ ...payload, confidantProviderName });
    },
  });

  const medicationOptions = useMemo(() => {
    const group = domainOptions?.find(
      (domain: ReportItem) => domain.groupId === (selectedGrouping || values.grouping),
    );
    return group?.relatedElements?.map(element => ({ label: element.name, value: element.Id })) || [];
  }, [selectedGrouping, values.grouping]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  // const onChangeTag = async (_, newValue: string[]) => {
  //   await setFieldValue('symptoms', newValue);
  // };

  useEffect(() => {
    rest.handleReset({ ...defaultValues });
    rest.setErrors({});
  }, []);

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <IconButton icon="chevron-left" onClick={onClose} className={classes.backBtnSquare} />
        <Text className={classes.headerTitle}>{selectedDomainElement ? 'Update' : 'Add'} medication</Text>
      </Box>
      <form
        className={location.pathname.includes('session-signoff') ? classes.form2 : classes.form}
        onSubmit={handleSubmit}
      >
        <Box className={classes.formContent}>
          <Box className={classes.section}>
            {renderLabel('Grouping')}
            <Select
              name="grouping"
              value={values.grouping}
              variant={selectType.SECONDARY}
              options={groupOptions?.sort((a, b) => a.label.localeCompare(b.label))}
              emptyText="Select item"
              displayEmpty
              onChange={handleChange}
            />
            <TextError errorMsg={touched.grouping ? errors.grouping?.toString() : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Medication')}
            <Select
              name="medication"
              value={values.medication}
              variant={selectType.SECONDARY}
              options={medicationOptions?.sort((a, b) => a.label.localeCompare(b.label))}
              emptyText="Select item"
              displayEmpty
              onChange={async (_: any) => {
                await setFieldValue(_.target.name, _.target.value);

                const foundMedicineOption = domainOptions
                  ?.find((domain: ReportItem) => domain.groupId === (selectedGrouping || values.grouping))
                  ?.relatedElements?.find(element => element.Id === _.target.value);
                const tempImportanceLevels = [];
                Object.keys(foundMedicineOption?.metaData?.metaDataSpec?.importanceLevels).forEach(key => {
                  if (foundMedicineOption?.metaData?.metaDataSpec?.importanceLevels[key]) {
                    tempImportanceLevels.push({ value: key, label: key });
                  }
                });
                setImportanceLevels(tempImportanceLevels);
              }}
            />
            <TextError errorMsg={touched.medication ? errors.medication?.toString() : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Dosage')}
            <Input
              fullWidth
              name="dosage"
              value={values.dosage}
              size={inputSize.M}
              placeholder="Enter dosage"
              onChange={handleChange}
              onBlur={setTouched('dosage')}
            />
            <TextError errorMsg={touched.dosage ? errors.dosage?.toString() : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Frequency')}
            <Select
              name="frequency"
              value={values.frequency}
              variant={selectType.SECONDARY}
              options={frequencies?.sort((a, b) => a.label.localeCompare(b.label))}
              emptyText="Select item"
              displayEmpty
              onChange={handleChange}
            />
            <TextError errorMsg={touched.frequency ? errors.frequency?.toString() : null} />
          </Box>
          {symtomsList.length > 0 && (
            <Box className={classes.section}>
              {renderLabel('Symptoms being treated')}
              <Input
                // freeSolo
                fullWidth
                name="symptoms"
                value={values.symptoms}
                options={symtomsList}
                variant={inputType.TAGS}
                size={inputSize.M}
                getOptionLabel={(option: string) => option}
                onBlur={setTouched('symptoms')}
                onChange={async (_: SyntheticEvent, newValue: string) => {
                  await setFieldValue('symptoms', newValue);
                }}
              />
              <TextError errorMsg={touched.symptoms ? errors.symptoms?.toString() : null} />
            </Box>
          )}
          <Box className={classes.section}>
            {renderLabel('Prescribing Provider')}
            <Select
              name="provider"
              value={values.provider}
              variant={selectType.SECONDARY}
              options={[
                { label: 'Confidant Provider', value: 'Confidant Provider' },
                { label: 'External Provider', value: 'External Provider' },
              ]}
              onChange={handleChange}
            />
            <TextError errorMsg={touched.provider ? errors.provider?.toString() : null} />
          </Box>
          {values.provider === 'Confidant Provider' ? (
            <Box className={classes.section}>
              {renderLabel('Confidant Provider')}
              <Select
                name="confidantProvider"
                value={values.confidantProvider}
                variant={selectType.SECONDARY}
                options={providerListOptions?.sort((a, b) => a.label.localeCompare(b.label)) || []}
                emptyText="Select item"
                displayEmpty
                onChange={handleChange}
              />
              <TextError errorMsg={touched.confidantProvider ? errors.confidantProvider?.toString() : null} />
            </Box>
          ) : (
            <Box className={classes.section}>
              {renderLabel('Provider Name')}
              <Input
                fullWidth
                name="providerName"
                value={values.providerName}
                size={inputSize.M}
                placeholder="Enter provider name"
                onChange={handleChange}
                onBlur={setTouched('providerName')}
              />
              <TextError errorMsg={touched.providerName ? errors.providerName?.toString() : null} />
            </Box>
          )}
          <Box className={classes.section}>
            {renderLabel('Status')}
            <SelectLevel
              value={values.status}
              options={importanceLevels?.sort((a, b) => a.label.localeCompare(b.label))}
              isLifeEvent
              onChange={handleChange}
            />
            <TextError errorMsg={touched.status ? errors.status?.toString() : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Additional notes for team')}
            <Textarea
              name="additionalNotes"
              value={values.additionalNotes}
              placeholder="Enter your notes"
              onChange={handleChange}
              minRows={2}
              maxRows={3}
              onBlur={setTouched('additionalNotes')}
            />
            <TextError errorMsg={touched.additionalNotes ? errors.additionalNotes?.toString() : null} />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>{selectedDomainElement ? 'Update' : 'Add'}</Button>
        </Box>
      </form>
    </Box>
  );
};

export { AddMedication };
