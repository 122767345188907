/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  header: {
    height: 80,
    padding: '16px 16px 16px 24px',
    backgroundColor: colors.neutral25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'inset 0px -1px 0px #EDF1F5',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 32,
    gap: 16,
  },
  headingText: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    fontWeight: 600,
  },
  headingTitle: {
    fontSize: 22,
    lineHeight: '28px',
    color: colors.secondary500,
    margin: 0,
  },
  badge: {
    fontSize: 14,
    lineHeight: '22px',
    width: 80,
    whiteSpace: 'nowrap',
    borderRadius: 20,
    borderColor: colors.secondary300,
    color: colors.secondary500,
    backgroundColor: colors.white,
  },
  body: {
    height: 'calc(100vh - 240px)',
    padding: 32,
    backgroundColor: colors.white,
    overflowY: 'auto',
  },
  backBtn: {
    width: 40,
    height: 40,
    minWidth: 40,
    '& svg': {
      width: 24,
    },
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    display: 'block',
    fontWeight: 400,
    //     font-size: 16px;
    // line-height: 24px;
    // font-weight: 400;
  },
  description: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    color: colors.neutral700,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  completeBtn: {
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primary600,
      color: colors.white,
    },
  },
  subText: {
    fontSize: 18,
    color: '#0374DD',
    fontWeight: 600,
    //     font-size: 18px;
    // color: #0374DD;
    // font-weight: 600;
  },
  previousBtn: {
    gap: 12,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
});
