import { FC, useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import cx from 'clsx';

import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { ISource } from '../share-screen-selection/ShareScreenSelection';
import { useStyles } from './TopbarView.styles';

type Props = {
  appointment: IAppointment;
  isVideoCall: boolean;
  isAudio: boolean;
  isSharing: boolean;
  shareScreen: ISource;
  onEndSession: () => void;
  toggleVideCall: () => void;
  toggleMic: () => void;
  onShareScreenClick: () => void;
  onStopSharingClick: () => void;
  inPersonSession: boolean;
};

const TopbarView: FC<Props> = ({
  appointment,
  shareScreen,
  isVideoCall,
  isAudio,
  isSharing,
  onEndSession,
  toggleVideCall,
  toggleMic,
  onShareScreenClick,
  onStopSharingClick,
  inPersonSession,
}) => {
  const classes = useStyles();
  const [showDropDownSharing, setShowDropDownSharing] = useState(false);

  const onShareClick = () => {
    if (isSharing) {
      setShowDropDownSharing(true);
    } else {
      onShareScreenClick();
    }
  };

  const onStopClick = () => {
    onStopSharingClick();
    setShowDropDownSharing(false);
  };
  if (inPersonSession) {
    return null;
  }
  return (
    <>
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" gap="12px">
          {isVideoCall && (
            <>
              <Avatar
                src={appointment?.participantImage}
                name={appointment?.participantName}
                size={56}
                className={classes.avatar}
                variant={avatarType.CIRCLE}
              />
              <Box>
                <Heading level={headingLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.username}>
                  {appointment?.participantName?.split(' ').slice(0, -1).join(' ')}
                </Heading>
                <Text className={classes.fullname}>{appointment?.participantName}</Text>
              </Box>
            </>
          )}
        </Box>
        <Box className={classes.actions}>
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, { [classes.shareActive]: isSharing })}
            onClick={onShareClick}
            icon="share-screen"
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, { [classes.videoActive]: isVideoCall })}
            icon={isVideoCall ? 'camera-filled' : 'camera-off'}
            onClick={toggleVideCall}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, { [classes.AudioActive]: isAudio })}
            onClick={toggleMic}
            icon={isAudio ? 'mic-on' : 'mic-off'}
          />
          <IconButton
            variant={iconBtnType.PRIMARY}
            className={cx(classes.iconBtn, classes.iconBtnActive)}
            icon="end-call-filled"
            onClick={onEndSession}
          />
          {showDropDownSharing && (
            <ClickAwayListener onClickAway={() => setShowDropDownSharing(false)}>
              <Box className={classes.dropdown}>
                <Box className={classes.wrapTitle}>
                  <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.title}>
                    Sharing your screen - {shareScreen?.name}
                  </Heading>
                </Box>
                <Box className={classes.wrapContent}>
                  <IconButton
                    className={cx(classes.btn, { [classes.stopShareBtn]: isSharing })}
                    onClick={onStopClick}
                  >
                    Stop sharing screen
                  </IconButton>
                </Box>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      </Box>
    </>
  );
};

export { TopbarView };
