import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';

// components
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { colors } from '@confidant-health/lib/colors';
import { DROPDOWN_FILTER_COLLAPSABLE_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { Icons } from '@confidant-health/lib/icons';
import { Tooltip } from '@confidant-health/lib/ui/atoms/tooltip';
import { tabIconPosition, Tabs } from '@confidant-health/lib/ui/atoms/tabs';

import { saveAs } from 'file-saver';
import * as ReportingService from 'services/reporting/reporting.service';
import { reportingActionCreators } from 'redux/modules/reporting';
import { selectClaim, selectClaimsList } from 'redux/modules/reporting/selectors';
import { IClaimRecord } from 'redux/modules/reporting/types';
import dayjs from 'utils/dayjs';
import { showSnackbar } from 'redux/modules/snackbar';
import { BaseLayout } from 'layouts/base';
import { AppState } from 'redux/store/types';
import { sentenceCase } from 'sentence-case';
import { profileActionCreators } from 'redux/modules/profile';

import Header from 'components/v2/Header';

import CreateInvoice from '../claim-detail/components/CreateInvoice';
import { StatusDetailDrawer } from '../claim-detail/components/StatusDetail';
import { UploadDrawer } from './UploadDrawer';

import {
  tableColumns,
  multiSelectFilterOptionsMock,
  CandidStatus,
  CANDID_CLAIM_FILTERS,
} from './Claims.constants';
import { stateFilterOptions } from '../payments/Payments.constants';
import { useStyles } from './Claims.styles';
import { IPayer } from '../../../redux/modules/state/types';

const ClaimList: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterChangeLoading, setFilterChangeLoading] = useState(false);
  const [resetMultiSelectFilter, setResetMultiSelectFilter] = useState(false);
  const [filterOptionsMock, setFilterOptionsMock] = useState([]);
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: {
      searchKey: '',
      filter: [],
      dateFilter: {
        startDate: '',
        endDate: '',
      },
    },
    pagination: { currentPage: 0, rowsPerPage: 10 },
    sorter: { direction: 'desc', column: '' },
  });
  const [openInvoice, setOpenInvoice] = useState(false);
  const [payerObj, setPayerObj] = useState<IPayer>({});
  const providersList = useSelector((state: AppState) => state.profile.providers2);
  const [selectedClaimId, setSelectedClaimId] = useState('');
  const [openStatusDetailDrawer, setOpenStatusDetailDrawer] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [showUploadDrawer, setShowUploadDrawer] = useState(false);
  const [isFileUpdated, setIsFileUpdated] = useState(false);
  const [tabs, setTabs] = useState([
    { tabKey: CANDID_CLAIM_FILTERS.ALL, label: 'All' },
    {
      tabKey: CANDID_CLAIM_FILTERS.PENDING,
      label: 'Pending',
      count: 0,
    },
    {
      tabKey: CANDID_CLAIM_FILTERS.PAID,
      label: 'Paid',
      count: 0,
    },
    {
      tabKey: CANDID_CLAIM_FILTERS.SUBMITTED,
      label: 'Submitted',
      count: 0,
    },
    {
      tabKey: CANDID_CLAIM_FILTERS.NO_CHECK_NUMBER,
      label: 'No check number',
      count: 0,
    },
    {
      tabKey: CANDID_CLAIM_FILTERS.FUNDS_NOT_RECEIVED,
      label: 'Funds not received',
      count: 0,
    },
    {
      tabKey: CANDID_CLAIM_FILTERS.DENIED,
      label: 'Denied',
      count: 0,
    },
  ]);
  const [tabType, setTabType] = useState(CANDID_CLAIM_FILTERS.ALL);

  const { isLoading, claimsList = [], totalRecords, metaData } = useSelector(selectClaimsList);
  const { data, errorMsg } = useSelector(selectClaim);

  useEffect(() => {
    if (providersList?.length <= 0) dispatch(profileActionCreators.fetchAllProviders());
  }, []);

  useEffect(() => {
    setTabs([
      { tabKey: CANDID_CLAIM_FILTERS.ALL, label: 'All' },
      {
        tabKey: CANDID_CLAIM_FILTERS.PENDING,
        label: 'Pending',
        count: metaData?.candidClaimMetadata?.PENDING ?? 0,
      },
      {
        tabKey: CANDID_CLAIM_FILTERS.PAID,
        label: 'Paid',
        count: metaData?.candidClaimMetadata?.PAID ?? 0,
      },
      {
        tabKey: CANDID_CLAIM_FILTERS.SUBMITTED,
        label: 'Submitted',
        count: metaData?.candidClaimMetadata?.SUBMITTED ?? 0,
      },
      {
        tabKey: CANDID_CLAIM_FILTERS.NO_CHECK_NUMBER,
        label: 'No check number',
        count: metaData?.candidClaimMetadata?.NO_CHECK_NUMBER ?? 0,
      },
      {
        tabKey: CANDID_CLAIM_FILTERS.FUNDS_NOT_RECEIVED,
        label: 'Funds not received',
        count: metaData?.candidClaimMetadata?.FUNDS_NOT_RECEIVED ?? 0,
      },
      {
        tabKey: CANDID_CLAIM_FILTERS.DENIED,
        label: 'Denied',
        count: metaData?.candidClaimMetadata?.DENIED ?? 0,
      },
    ]);
  }, [metaData]);

  const getFilterForStatus = () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const statuses = [];
    if (multiselectFilters?.Status) {
      multiselectFilters.Status.forEach(filter => {
        switch (filter) {
          case 'Created':
            statuses.push('CREATED');
            break;
          case 'Paid':
            statuses.push('PAID');
            break;
          case 'Denied':
            statuses.push('DENIED');
            break;
          case 'Info Requested':
            statuses.push('INFO_REQUESTED');
            break;
          case 'Rejected':
            statuses.push('REJECTED');
            break;
          case 'Completed':
            statuses.push('COMPLETED');
            break;
          case 'Partially Paid':
            statuses.push('PARTIALLY_PAID');
            break;
          case 'Overpaid':
            statuses.push('OVERPAID');
            break;
          case 'Underpaid':
            statuses.push('UNDERPAID');
            break;
          case 'Appealed':
            statuses.push('APPEALED');
            break;
          case 'Submitted':
            statuses.push('SUBMITTED');
            break;
          case 'Unbilled':
            statuses.push('UNBILLED');
            break;
          default:
            break;
        }
      });
    }

    return statuses;
  };

  const handleFetchClaimsFormat = ({ search, pagination, sorter }: tableParamsType) => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const statuses = getFilterForStatus();
    const states = multiselectFilters?.State ?? [];
    const roles = multiselectFilters?.Role ?? [];
    const vbcFlag = multiselectFilters?.VBC?.length > 0 || false;
    const candidStatus = multiselectFilters?.Candid?.toString() ?? '';
    const filterByPaidAt = multiselectFilters?.['Date filter preference']?.length > 0 || false;
    const selectedProviders = multiselectFilters?.[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.map(
      provider => {
        return provider.id;
      },
    );

    return {
      pageSize: pagination.rowsPerPage,
      pageNumber: pagination.currentPage,
      sortBy: 'createdAt',
      sortOrder: sorter.direction,
      body: {
        searchQuery: search.searchKey,
        startDate: search.dateFilter?.startDate ? dayjs(search.dateFilter?.startDate)?.toISOString() : null,
        endDate: search.dateFilter?.endDate ? dayjs(search.dateFilter?.endDate)?.toISOString() : null,
        statuses,
        states,
        roles,
        providerIds: selectedProviders,
        vbcFlag,
        candidStatus,
        filterByPaidAt,
        candidClaimFilter: tabType,
      },
    };
  };

  const getClaimsList = () => {
    dispatch(reportingActionCreators.fetchClaims(handleFetchClaimsFormat(tableParams)));
  };

  useEffect(() => {
    getClaimsList();
  }, [tableParams, tabType]);

  const claimList = useMemo(() => {
    return claimsList.map((claim: IClaimRecord) => ({
      ...claim,
      cptCodes: claim.claim?.cptList?.map(cpt => `${cpt?.cptCode}`),
      actions: { ...claim },
      paidAt: {
        paidAt: claim.statusDetail.datePaid,
        status: claim.statusDetail.claimStatus,
      },
      status: {
        status: claim.statusDetail.claimStatus,
        candidStatus: claim.candidStatus,
      },
      candidStatus: claim?.candidClaimMetadata?.claimStatus,
    }));
  }, [claimsList]);

  const onExportClick = () => {
    const multiselectFilters = tableParams.search?.multiSelectFilter;
    const statuses = getFilterForStatus();
    const states = multiselectFilters?.State ?? [];
    const roles = multiselectFilters?.Role ?? [];
    const vbcFlag = multiselectFilters?.VBC?.length > 0 || false;
    const candidStatus = multiselectFilters?.Candid?.toString() ?? '';
    const filterByPaidAt = multiselectFilters?.['Date filter preference']?.length > 0 || false;
    const selectedProviders = multiselectFilters?.[DROPDOWN_FILTER_COLLAPSABLE_TYPE.PROVIDERS]?.map(
      provider => {
        return provider.id;
      },
    );
    ReportingService.downloadClaims(
      {
        download: true,
        pageSize: 1000,
        pageNumber: 0,
        sortBy: 'createdAt',
        sortOrder: tableParams?.sorter?.direction,
      },
      {
        searchQuery: tableParams?.search?.searchKey,
        startDate: tableParams?.search?.dateFilter?.startDate
          ? dayjs(tableParams?.search?.dateFilter?.startDate)?.toISOString()
          : null,
        endDate: tableParams?.search?.dateFilter?.endDate
          ? dayjs(tableParams?.search?.dateFilter?.endDate)?.toISOString()
          : null,
        statuses,
        states,
        roles,
        providerIds: selectedProviders,
        vbcFlag,
        candidStatus,
        filterByPaidAt,
      },
    )
      .then(res => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel',
        });

        saveAs(blob, 'Claims list.xlsx');
        setIsLoadingExport(false);
      })
      .catch(err => {
        console.warn(err);
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Error downloading!',
          }),
        );
        setIsLoadingExport(false);
      });
  };

  const onEditClick = useCallback(row => {
    row?._id &&
      navigate(`/admin/claims/${row?._id}`, {
        state: {
          appointmentId: row?.appointmentId,
        },
      });
  }, []);

  const onRowClick = useCallback(row => {
    dispatch(reportingActionCreators.fetchClaimById({ id: row?._id }));
    setSelectedClaimId(row?._id);
    setTimeout(() => {
      setOpenStatusDetailDrawer(true);
    }, 1000);
  }, []);

  const renderColumns = useCallback(() => {
    return tableColumns.map(column => {
      if (column.id === 'appointmentStartTime') {
        return {
          ...column,
          renderCell: (appointmentStartTime: string) => (
            <div className={classes.timeWrap}>
              <div className={classes.date}>{dayjs(appointmentStartTime).format('MM/DD/YYYY')}</div>
              <div className={classes.time}>{dayjs(appointmentStartTime).format('h:mm A')}</div>
            </div>
          ),
        };
      }
      if (column.id === 'renderingProvider') {
        return {
          ...column,
          renderCell: ({ fullName, profileImage, designation, role, userAccountId }) => (
            <ProfileInfo
              type="provider"
              photo={profileImage}
              role={designation || role || 'N/A'}
              fullName={fullName}
              memberId={userAccountId}
            />
          ),
        };
      }
      if (column.id === 'patient') {
        return {
          ...column,
          renderCell: ({ profileImage, fullName, firstName, lastName, userAccountId, uuid }) => (
            <ProfileInfo
              type="member"
              photo={profileImage}
              nickName={
                firstName && lastName ? `${firstName || ''} ${lastName || ''}` : fullName ?? 'No Name'
              }
              fullName={uuid}
              memberId={userAccountId}
            />
          ),
        };
      }
      if (column.id === 'claim') {
        return {
          ...column,
          renderCell: dataItem => <Box>${dataItem?.totalClaim || ''}</Box>,
        };
      }
      if (column.id === 'payer') {
        return {
          ...column,
          renderCell: payer => <Box>{payer?.name}</Box>,
        };
      }
      if (column.id === 'status') {
        return {
          ...column,
          renderCell: ({ status, candidStatus }) => {
            return (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Tooltip
                  title={candidStatus === CandidStatus.CANDID_SUCCESS ? 'Candid success' : 'Candid failed'}
                  placement="top"
                >
                  <span>
                    <Box
                      style={{
                        backgroundColor: candidStatus === CandidStatus.CANDID_SUCCESS ? 'green' : 'orange',
                        width: 8,
                        height: 8,
                        borderRadius: 25,
                      }}
                    />
                  </span>
                </Tooltip>
                <Badge
                  variant={badgeType.OUTLINED}
                  style={
                    status === 'APPEALED' ||
                    status === 'PAID' ||
                    status === 'PARTIALLY_PAID' ||
                    status === 'OVERPAID' ||
                    status === 'UNDERPAID' ||
                    status === 'APPEALED'
                      ? badgeStyle.RESOLVED
                      : status === 'CREATED' || status === 'COMPLETED'
                      ? badgeStyle.PRIMARY
                      : status === 'REJECTED' || status === 'DENIED'
                      ? badgeStyle.HIGH
                      : badgeStyle.MEDIUM
                  }
                  className={clsx(
                    classes.status,
                    { [classes.rejectedStatus]: status === 'REJECTED' || status === 'DENIED' },
                    { [classes.submittedStatus]: status === 'CREATED' || status === 'COMPLETED' },
                    {
                      [classes.approvedStatus]:
                        status === 'PAID' ||
                        status === 'PARTIALLY_PAID' ||
                        status === 'OVERPAID' ||
                        status === 'UNDERPAID' ||
                        status === 'APPEALED',
                    },
                  )}
                >
                  {sentenceCase(status || '')}
                </Badge>
              </Box>
            );
          },
        };
      }
      if (column.id === 'candidStatus') {
        return {
          ...column,
          renderCell: candidStatus => {
            return (
              <Box display="flex" flexDirection="row" alignItems="center" gap={1} minWidth={100}>
                {candidStatus ? (
                  <Badge
                    variant={badgeType.OUTLINED}
                    style={
                      candidStatus === 'APPEALED' ||
                      candidStatus === 'PAID' ||
                      candidStatus === 'PARTIALLY_PAID' ||
                      candidStatus === 'OVERPAID' ||
                      candidStatus === 'UNDERPAID' ||
                      candidStatus === 'APPEALED'
                        ? badgeStyle.RESOLVED
                        : candidStatus === 'CREATED' || candidStatus === 'COMPLETED'
                        ? badgeStyle.PRIMARY
                        : candidStatus === 'REJECTED' || candidStatus === 'DENIED'
                        ? badgeStyle.HIGH
                        : badgeStyle.MEDIUM
                    }
                    className={clsx(
                      classes.status,
                      { [classes.rejectedStatus]: candidStatus === 'REJECTED' || candidStatus === 'DENIED' },
                      {
                        [classes.submittedStatus]: candidStatus === 'CREATED' || candidStatus === 'COMPLETED',
                      },
                      {
                        [classes.approvedStatus]:
                          candidStatus === 'PAID' ||
                          candidStatus === 'PARTIALLY_PAID' ||
                          candidStatus === 'OVERPAID' ||
                          candidStatus === 'UNDERPAID' ||
                          candidStatus === 'APPEALED',
                      },
                    )}
                  >
                    {sentenceCase(candidStatus || '')}
                  </Badge>
                ) : (
                  <Box display="flex" justifyContent="center" minWidth={100}>
                    -
                  </Box>
                )}
              </Box>
            );
          },
        };
      }
      if (column.id === 'cptCodes') {
        return {
          ...column,
          renderCell: cptCodes => (
            <Box>
              {cptCodes?.map((cpt, index) => (
                <Box key={index}>{cpt}</Box>
              ))}
            </Box>
          ),
        };
      }
      if (column.id === 'paidAt') {
        return {
          ...column,
          renderCell: ({ paidAt, status }) => {
            return paidAt && status === 'PAID' ? (
              <div className={classes.timeWrap}>
                <div className={classes.date}>{dayjs(paidAt).format('MM/DD/YYYY')}</div>
                <div className={classes.time}>{dayjs(paidAt).format('h:mm A')}</div>
              </div>
            ) : (
              <Box display="flex" justifyContent="center" minWidth={70}>
                -
              </Box>
            );
          },
        };
      }
      if (column.id === 'actions') {
        return {
          ...column,
          renderCell: row => (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={[
                {
                  label: 'Edit claim',
                  onClick: () => {
                    onRowClick(row);
                  },
                },
                {
                  label: 'Create Invoice',
                  onClick: () => {
                    dispatch(reportingActionCreators.fetchClaimById({ id: row?._id }));
                    setPayerObj(row?.payer);
                    setOpenInvoice(true);
                  },
                },
              ]}
            />
          ),
        };
      }
      return column;
    });
  }, []);
  const renderStatusTotal = (status: string, value: number, badge: string) => (
    <Box
      padding={2}
      sx={{
        background: colors.white,
        flex: '1 1 0',
        boxShadow:
          '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        borderRadius: '8px',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Text className={classes.label}>{status.toUpperCase()}</Text>
        <Badge
          className={clsx(
            classes.statusLabel,
            { [classes.unpaidStatus]: badge === 'Denied' },
            { [classes.submittedStatus]: badge === 'Created' },
          )}
          variant={badgeType.OUTLINED}
          style={
            badge === 'Paid'
              ? badgeStyle.RESOLVED
              : badge === 'Created'
              ? badgeStyle.PRIMARY
              : badgeStyle.HIGH
          }
        >
          {badge}
        </Badge>
      </Box>
      <Text className={classes.statusFee}>${Number.isInteger(value) ? value : value?.toFixed(2)}</Text>
    </Box>
  );
  const onSubmit = useCallback(
    (formData, type) => {
      dispatch(
        reportingActionCreators.updateClaimDetail({
          type,
          formData: {
            [type]: { ...data[type], ...formData },
            billingProviderRef: data?.billingProviderRef,
            renderingProviderRef: data?.renderingProviderRef,
            submitterRef: data?.submitterRef,
            payerRef: data?.payerRef,
            patientRef: data?.patientRef,
          },
          claimId: data?._id || selectedClaimId,
        }),
      );

      setTimeout(() => {
        if (errorMsg) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: errorMsg || 'Something went wrong!',
            }),
          );
        } else {
          setOpenStatusDetailDrawer(false);
          getClaimsList();
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Record updated successfully',
            }),
          );
        }
      }, 2000);
    },
    [data],
  );
  const displayData = claimList || [];

  useEffect(() => {
    setFilterOptionsMock(multiSelectFilterOptionsMock(providersList));
    setResetMultiSelectFilter(true);
  }, [providersList]);

  useEffect(() => {
    setResetMultiSelectFilter(false);
  }, [tableParams]);

  useEffect(() => {
    setFilterChangeLoading(true);
    setTimeout(() => {
      setFilterChangeLoading(false);
    }, 100);
  }, [filterOptionsMock]);

  const renderTab = tab => {
    return {
      tabKey: tab.tabKey,
      label: tab.label || tab.tabKey,
      icon: tab?.count ? (
        <Badge className={classes.countBadge} size={badgeSize.XSMALL}>
          {tab?.count || 0}
        </Badge>
      ) : (
        <></>
      ),
      iconPosition: 'end' as tabIconPosition,
    };
  };

  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Stack spacing={2} sx={{ marginBottom: 5, flex: 1 }}>
          <Header
            label="Claims"
            badge={
              <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
                {totalRecords || 0} total
              </Badge>
            }
            actions={[
              {
                variant: btnType.OUTLINE,
                className: clsx(classes.outlineBtn, classes.rounded8),
                children: 'Export',
                onClick: onExportClick,
                loading: isLoadingExport,
              },
              {
                variant: btnType.PRIMARY,
                className: clsx(classes.addBtn, classes.rounded8),
                icon: 'upload-file-outlined',
                children: 'Upload',
                onClick: () => {
                  setIsFileUpdated(false);
                  setShowUploadDrawer(true);
                },
              },
            ]}
          />
        </Stack>
        <Box display="flex" flexDirection="column" mb={4} gap={0}>
          <Box display="flex" mb={2} gap={2}>
            {renderStatusTotal('total Created', metaData?.totalCreated || 0, 'Created')}
            {renderStatusTotal('total Paid', metaData?.totalPaid || 0, 'Paid')}
            {renderStatusTotal('total Denied', metaData?.totalDenied || 0, 'Denied')}
          </Box>
        </Box>
        <Tabs value={tabType} onChange={setTabType} options={tabs.map(renderTab)} className={classes.tabs} />
        <div className={classes.claimList}>
          {filterChangeLoading ? (
            <div className={classes.loader}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              Loading...
            </div>
          ) : (
            <Table
              searchProps={{
                placeholder: 'Search claims by member, provider and insurance',
                filterProps: {
                  variant: Filter.tableFilterType.MULTIPLE,
                  options: stateFilterOptions,
                  multiSelectOptions: filterOptionsMock,
                  dateFilter: {
                    startDate: tableParams?.search?.dateFilter?.startDate,
                    endDate: tableParams?.search?.dateFilter?.endDate,
                  },
                },
                resetMultiSelectFilter,
              }}
              gridProps={{
                columns: renderColumns(),
                data: displayData,
                isLoading,
                onRowClick: onEditClick,
              }}
              paginationProps={{
                currentRows: displayData?.length || 0,
                totalCount: totalRecords,
                showRowsPerPage: true,
              }}
              value={tableParams}
              onChange={setTableParams}
            />
          )}
        </div>
        {openInvoice && (
          <CreateInvoice payerObj={payerObj} open={openInvoice} onClose={() => setOpenInvoice(false)} />
        )}
        {openStatusDetailDrawer && data && (
          <StatusDetailDrawer
            data={{
              ...data?.statusDetail,
              amountDenied: data?.statusDetail?.amountDenied || 0,
              trackingNumber: data?.statusDetail?.trackingNumber || '',
              notes: data?.statusDetail?.notes || '',
              initialBillingDate: data?.statusDetail?.initialBillingDate || '',
            }}
            open={openStatusDetailDrawer}
            onClose={() => setOpenStatusDetailDrawer(false)}
            onSubmit={onSubmit}
          />
        )}
      </Box>
      <UploadDrawer
        open={showUploadDrawer}
        onClose={() => {
          if (isFileUpdated) {
            getClaimsList();
          }
          setShowUploadDrawer(false);
        }}
        setIsFileUpdated={setIsFileUpdated}
      />
    </BaseLayout>
  );
};

export { ClaimList };
