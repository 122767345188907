import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { useStyles } from './NotificationItem.styles';
import { IAdminNotificationItemProps } from './NotificationItem.types';

const NotificationItem: FC<IAdminNotificationItemProps> = ({ onClick, item }) => {
  const classes = useStyles();
  const [downloaded, setDownloaded] = useState(false);

  let icon = <Icons glyph="question-outlined" color={colors.primary500} />;

  if (item.download) {
    icon = <Icons glyph="export" color={colors.primary500} />;
  }

  const handleDownloadClick = () => {
    if (item.downloadUrl) {
      window.open(item.downloadUrl, '_blank');
      setDownloaded(true);
      if (onClick) {
        onClick(item.id);
      }
    }
  };
  return (
    <Box className={classes.notification}>
      <Box className={classes.leftSide}>
        {icon}
        <Text className={classes.text} level={textLevel.S} weight={fontWeight.MEDIUM}>
          {item.title}
        </Text>
      </Box>
      <Box className={classes.rightSide}>
        <Text className={classes.time} level={textLevel.S}>
          {item.download && (
            <IconButton
              icon="export"
              variant={btnType.PRIMARY}
              className={classes.addBtn}
              onClick={handleDownloadClick}
              disabled={downloaded}
            >
              Download
            </IconButton>
          )}
        </Text>
      </Box>
    </Box>
  );
};

export { NotificationItem };
