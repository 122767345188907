import { IInfoCardProps } from './AlfieAnalysisPlayground.types';

export const PrioritiesCard = {
  Symptoms: 'symptoms',
  SocialDeterminants: 'socialDeterminants',
  LifeEvents: 'lifeEvents',
  Medications: 'medications',
  SideEffects: 'sideEffects',
  CognitiveIssues: 'cognitiveIssues',
};

export const PrioritiesCardLabel = {
  symptoms: 'Symptoms',
  socialDeterminants: 'Social Determinants',
  lifeEvents: 'Life Events',
  medications: 'Medications',
  sideEffects: 'Side Effects',
  cognitiveIssues: 'Cognitive Issues',
};

export const priorityListCard: IInfoCardProps[] = [
  {
    title: 'Symptoms',
    type: PrioritiesCard.Symptoms,
    isCompleted: false,
    icon: 'symptom',
  },
  {
    title: 'Social Determinants',
    type: PrioritiesCard.SocialDeterminants,
    isCompleted: false,
    icon: 'stack',
  },
  {
    title: 'Life Events',
    type: PrioritiesCard.LifeEvents,
    isCompleted: false,
    icon: 'stack',
  },
  {
    title: 'Medications',
    type: PrioritiesCard.Medications,
    isCompleted: false,
    icon: 'medication-2',
  },
  {
    title: 'Side effects',
    type: PrioritiesCard.SideEffects,
    isCompleted: false,
    icon: 'question-outlined',
  },
  {
    title: 'Cognitive Issues',
    type: PrioritiesCard.CognitiveIssues,
    isCompleted: false,
    icon: 'question-outlined',
  },
];

export const levelSelects = [
  { title: 'Active', value: 'Active' },
  { title: 'ActiveExternalRX', value: 'Active_external_rx' },
  { title: 'Critical', value: 'Critical' },
  { title: 'High', value: 'High' },
  { title: 'InActive', value: 'Inactive' },
  { title: 'InactiveExternalRX', value: 'Inactive_external_rx' },
  { title: 'Low', value: 'Low' },
  { title: 'Medium', value: 'Medium' },
  { title: 'Misreported', value: 'Misreported' },
  { title: 'Negative', value: 'Negative' },
  { title: 'Neutral', value: 'Neutral' },
  { title: 'Possible', value: 'Possible' },
  { title: 'Positive', value: 'Positive' },
  { title: 'Resolved', value: 'Resolved' },
  { title: 'Unrelated', value: 'Unrelated' },
];
