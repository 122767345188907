import dayjs from 'dayjs';
import { DomainPriorities, IDomainElement } from 'redux/modules/profile/types';

/* eslint-disable max-len */
export const diagonseList: IDomainElement[] = [
  {
    id: '1',
    name: 'Alcohol Use Disorder',
    description: 'Related to Substance Use',
    assignedBy: 'Eva Bond',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: null,
    priority: { name: DomainPriorities.MEDIUM, color: '#fff' },
    history: [],
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
  },
  {
    id: '2',
    name: 'Nicotine Use Disorder',
    description: 'Related to Substance Use',
    assignedBy: 'Eva Bond',
    assignedAt: dayjs().subtract(1, 'd').toISOString(),
    avatar: null,
    priority: { name: DomainPriorities.HIGH, color: '#fff' },
    history: [],
    notes:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam sem eget libero egestas, ut dignissim nunc vehicula.',
  },
];

export const diagnorsesItems = [
  {
    label: 'Bipolar',
    groupId: 'Bipolar',
    items: [
      { label: 'Bipolar I Disorder - Mixed', value: 'Bipolar I Disorder - Mixed' },
      { label: 'Bipolar I Disorder - Depressive', value: 'Bipolar I Disorder - Depressive' },
      { label: 'Bipolar I Disorder - Manic/Hypomanic', value: 'Bipolar I Disorder - Manic/Hypomanic' },
      { label: 'Bipolar Disorder - Unspecified', value: 'Bipolar Disorder - Unspecified' },
    ],
  },
  {
    label: 'ADHD',
    groupId: 'ADHD',
    items: [
      { label: 'ADHD I Disorder - Mixed', value: 'ADHD I Disorder - Mixed' },
      { label: 'ADHD I Disorder - Depressive', value: 'ADHD I Disorder - Depressive' },
      { label: 'ADHD I Disorder - Manic/Hypomanic', value: 'ADHD I Disorder - Manic/Hypomanic' },
      { label: 'ADHD Disorder - Unspecified', value: 'ADHD Disorder - Unspecified' },
    ],
  },
  {
    label: 'Disorders',
    groupId: 'Disorders',
    items: [
      { label: 'Disorders I Disorder - Mixed', value: 'Disorders I Disorder - Mixed' },
      { label: 'Disorders I Disorder - Depressive', value: 'Disorders I Disorder - Depressive' },
      { label: 'Disorders I Disorder - Manic/Hypomanic', value: 'Disorders I Disorder - Manic/Hypomanic' },
      { label: 'Disorders Disorder - Unspecified', value: 'Disorders Disorder - Unspecified' },
    ],
  },
];

export const stepTitles = {
  0: 'Step 1 - select diagnoses',
  1: 'Step 2 - diagnosis details',
  2: 'Step 3 - review diagnoses',
};
