import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
  loadMoreBtn: {
    fontSize: 14,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: colors.primary300,
      color: colors.primary500,
    },
  },
  noNotification: {
    height: 'unset',
  },
});
