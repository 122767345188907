import { AxiosResponse } from 'axios';
import { baseRequest } from 'utils/HttpClient';
import { ApiEndpoints } from 'constants/ApiEndpoints';
import { IScheduleUpdateRequest } from 'redux/modules/schedule/types';

export const getProviderSchedule = (actor: string, queryParams = null): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_SCHEDULE, null, { actor }, queryParams);
};

export const updateProviderSchedule = (
  actor: string,
  scheduleUpdateRequest: IScheduleUpdateRequest,
): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.UPDATE_PROVIDER_SCHEDULE, scheduleUpdateRequest, null, { actor });
};

export const getProviderServices1 = (providerId: string): Promise<AxiosResponse> => {
  const queryParams = {
    providerId,
    inactiveService: true,
  };
  return baseRequest(ApiEndpoints.GET_PROVIDER_SERVICES_BY_ID, null, null, queryParams);
};

export const getAllServices = (): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_SYSTEM_SERVICES, null, null);
};

interface IAppointmentReportRequest {
  download?: boolean;
  endDate: string;
  orderBy: 'ASC' | 'DESC';
  pageNumber: number;
  pageSize: number;
  payee?: string;
  startDate: string;
}
export const getAppointmentReport = (bodyRequest: IAppointmentReportRequest): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_APPOINTMENT_REPORT, bodyRequest, null);
};
export const getAIJobs = () => {
  return baseRequest(ApiEndpoints.GET_ALL_AI_JOBS);
};

export const getLevelOfEngagements = () => {
  return baseRequest(ApiEndpoints.GET_LEVEL_OF_ENGAGEMENTS);
};

export const getLoeDetails = (payload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_LOE_DETAILS, null, null, payload);
};

export const getLoeMembersList = (payload): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_LOE_MEMBERS_LIST, null, null, payload);
};

export const getProviderReviews = (params): Promise<AxiosResponse> => {
  return baseRequest(ApiEndpoints.GET_PROVIDER_REVIEWS, null, null, params);
};
