/* eslint-disable @typescript-eslint/no-use-before-define */
import { createEntityAdapter } from '@reduxjs/toolkit';
import { IAction } from 'redux/store/types';
import { IDomainType } from 'redux/modules/profile/types';
import {
  ConversationState,
  IAssignedPlanItem,
  IChatbot,
  IChatbotAttemptHistory,
  IGiftedMessage,
} from './types';
import {
  FETCH_CHATBOTS,
  FETCH_CHATBOTS_FAILED,
  FETCH_CHATBOTS_SUCCESSFUL,
  FETCH_CHATBOT_CONTACTS,
  FETCH_CHATBOT_CONTACTS_FAILED,
  FETCH_CHATBOT_CONTACTS_SUCCESSFUL,
  CHAT_ADD_MESSAGE,
  CHAT_EXIT,
  CHAT_INITIALIZING,
  CHAT_MEDIA_SENT,
  CHAT_MEDIA_UPLOAD_PROGRESS,
  CHAT_MESSAGE_RECEIVED,
  CHAT_READY,
  CHAT_SEND_ATTACHMENT,
  SENDBIRD_CONNECT_FAILED,
  SENDBIRD_CONNECTED,
  SENDBIRD_CONNECTING,
  SENDBIRD_RECONNECTING,
  CHAT_INIT_FAILED,
  GET_DATA_COLLECTION_TEMPLATES,
  GET_DATA_COLLECTION_TEMPLATES_SUCCESSFUL,
  GET_DATA_COLLECTION_TEMPLATES_FAILED,
  CREATE_SESSION_TYPE,
  CREATE_SESSION_TYPE_FAILED,
  CREATE_SESSION_TYPE_SUCCESSFUL,
  DELETE_SESSION_TYPE,
  DELETE_SESSION_TYPE_FAILED,
  DELETE_SESSION_TYPE_SUCCESSFUL,
  FETCH_SESSION_TYPES,
  FETCH_SESSION_TYPES_FAILED,
  FETCH_SESSION_TYPES_SUCCESSFUL,
  UPDATE_SESSION_TYPE,
  UPDATE_SESSION_TYPE_FAILED,
  UPDATE_SESSION_TYPE_SUCCESSFUL,
  FETCH_PLAN_ITEMS,
  FETCH_PLAN_ITEMS_FAILED,
  FETCH_PLAN_ITEMS_SUCCESSFUL,
  FETCH_ASSIGNED_PLAN_ITEMS,
  FETCH_ASSIGNED_PLAN_ITEMS_SUCCESSFUL,
  FETCH_ASSIGNED_PLAN_ITEMS_FAILED,
  ADD_ASSIGNED_PLAN_ITEMS,
  ADD_ASSIGNED_PLAN_ITEMS_SUCCESSFUL,
  ADD_ASSIGNED_PLAN_ITEMS_FAILED,
  CREATE_PLAN_ITEM,
  CREATE_PLAN_ITEM_FAILED,
  CREATE_PLAN_ITEM_SUCCESSFUL,
  UPDATE_PLAN_ITEM,
  UPDATE_PLAN_ITEM_FAILED,
  UPDATE_PLAN_ITEM_SUCCESSFUL,
  DELETE_PLAN_ITEM,
  DELETE_PLAN_ITEM_FAILED,
  DELETE_PLAN_ITEM_SUCCESSFUL,
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATIONS_FAILED,
  FETCH_CONVERSATIONS_SUCCESSFUL,
  FETCH_EVALUATIONS,
  FETCH_EVALUATIONS_FAILED,
  FETCH_EVALUATIONS_SUCCESSFUL,
  FETCH_REVAMP_TYPES_LIST,
  FETCH_REVAMP_TYPES_LIST_SUCCESSFUL,
  FETCH_REVAMP_TYPES_LIST_FAILED,
  FETCH_EVALUATION_CONTEXT,
  FETCH_EVALUATION_CONTEXT_SUCCESSFUL,
  FETCH_EVALUATION_CONTEXT_FAILED,
  FETCH_ASSOCIATED_TAG,
  FETCH_ASSOCIATED_TAG_SUCCESSFUL,
  FETCH_ASSOCIATED_TAG_FAILED,
  FETCH_TAGS,
  FETCH_TAGS_SUCCESSFUL,
  FETCH_TAGS_FAILED,
  FETCH_CHATBOT_ATTEMPT_HISTORY,
  FETCH_CHATBOT_ATTEMPT_HISTORY_FAILED,
  FETCH_CHATBOT_ATTEMPT_HISTORY_SUCCESSFUL,
  FETCH_PROFILE_ELEMENTS,
  FETCH_PROFILE_ELEMENTS_SUCCESSFUL,
  FETCH_PROFILE_ELEMENTS_FAILED,
  FETCH_AUTOMATION_RULE_LIST,
  FETCH_AUTOMATION_RULE_LIST_SUCCESSFUL,
  FETCH_AUTOMATION_RULE_LIST_FAILED,
  ADD_PROFILE_ELEMENT,
  ADD_PROFILE_ELEMENT_SUCCESSFUL,
  ADD_PROFILE_ELEMENT_FAILED,
  UPDATE_PROFILE_ELEMENT,
  UPDATE_PROFILE_ELEMENT_SUCCESSFUL,
  UPDATE_PROFILE_ELEMENT_FAILED,
  TOGGLE_EVALUATION_STAUTS,
  FETCH_ASSIGNED_EVALUATION,
  FETCH_ASSIGNED_EVALUATION_SUCCESSFUL,
  FETCH_ASSIGNED_EVALUATION_FAILED,
  FETCH_AUTOMATION_COUNT,
  FETCH_AUTOMATION_COUNT_SUCCESSFUL,
  FETCH_AUTOMATION_COUNT_FAILED,
  SENDBIRD_CHANNELS_FETCHED,
  CHAT_MARK_AS_READ,
  RE_RUN_AI_ANALYSIS,
  FETCH_CHAT_RECOMMENDATION_SUCCESSFUL,
  FETCH_CHAT_RECOMMENDATION,
  FETCH_CHAT_RECOMMENDATION_FAILED,
  ADD_RECENT_MESSAGES,
  REMOVE_RECENT_MESSAGES,
  FETCH_AI_JOBS_SUCCESSFUL,
  FETCH_AI_JOBS_FAILED,
  FETCH_ALL_TODO_SUCCESSFUL,
  SENDBIRD_UNREAD_CHANNELS_FETCHED,
  FETCH_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL,
  FETCH_LIBRARY_CONTENT_BLOCKS_FAILED,
  FETCH_LIBRARY_CONTENT_BLOCKS,
  LOAD_MORE_MESSAGES,
  LOAD_MORE_MESSAGES_SUCCESS,
  LOAD_MORE_MESSAGES_FAILED,
  LOAD_OLD_MESSAGES,
} from './actions';

import { USER_LOGOUT } from '../auth/actions';
import { uniqueSBChannels } from '../../../utils/CommonUtils';

export const associatedTagsAdapter = createEntityAdapter<IDomainType>({
  selectId: data => data.typeId,
});

export const assignedPlanItemAdapter = createEntityAdapter<{
  userId: string;
  items: Array<IAssignedPlanItem>;
}>({
  selectId: data => data.userId,
});

export const DEFAULT = {
  isLoading: true,
  isLoadingMoreMessages: false,
  isLoadedAllMessages: false,
  errorMsg: '',
  chatbots: [] as IChatbot[],
  isCBContactsLoading: true,
  chatbotContacts: null,
  isCBAttemptHistoryLoading: true,
  chatbotAttemptHistory: [] as IChatbotAttemptHistory[],
  chatbotcompleteHistory: [] as IChatbotAttemptHistory[],
  connectionStatus: 0,
  channelMembers: [],
  recentMessageSend: [],
  allTodos: [],
  messages: [],
  sendingMessage: false,
  loadEarlier: true,
  isLoadingEarlier: false,
  channelUrl: null,
  sendbirdStatus: 0,
  dct: {
    isLoading: false,
    dcts: [],
    totalRecords: 0,
    errorMsg: '',
  },
  sessionType: {
    isLoading: false,
    errorMsg: '',
    sessionTypes: [],
  },
  planItem: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
    planItems: [],
  },
  profileElements: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
    profileElementList: [],
  },
  assignedPlanItems: assignedPlanItemAdapter.getInitialState({ isLoading: false, errorMsg: null }),
  conversation: {
    isLoading: false,
    errorMsg: '',
    conversations: [],
  },
  evaluation: {
    isLoading: false,
    errorMsg: '',
    evaluations: [],
  },
  revampType: {
    isLoading: false,
    errorMsg: '',
    revampTypes: [],
  },
  evaluationContext: {
    isLoading: false,
    errorMsg: '',
    evaluationContext: null,
    evaluationHead: null,
    toggleStatus: false,
  },
  associatedTags: associatedTagsAdapter.getInitialState({ isLoading: false, errorMsg: null }),
  tags: {
    isLoading: false,
    errorMsg: '',
    tags: [],
  },
  automationRules: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
    automationRules: [],
  },
  automationRulesCount: {
    isLoading: false,
    errorMsg: '',
    counts: {
      appointmentAutomations: 0,
      chatBotAutomations: 0,
      educationAutomations: 0,
      groupAutomations: 0,
      paymentAutomations: 0,
      planItemAutomations: 0,
      profileElementAutomations: 0,
    },
  },
  evaluations: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
    evaluations: [],
  },
  assignedEvaluations: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
    evaluations: [],
  },
  chats: {
    channels: [],
  },
  recommendationLoading: false,
  boundedChats: [],
  aiJobs: [],
  recommendationError: false,
  libraryContentBlocks: {
    totalPages: 0,
    currentPage: 0,
    totalRecords: 0,
    results: [],
    isLoading: false,
  },
};

export default function conversationReducer(state = DEFAULT, action: IAction): ConversationState {
  const { type, payload } = action;

  switch (type) {
    case USER_LOGOUT: {
      return {
        ...DEFAULT,
      };
    }
    case FETCH_CHATBOTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_CHATBOTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        chatbots: [] as IChatbot[],
      };
    }
    case FETCH_AI_JOBS_SUCCESSFUL: {
      return {
        ...state,
        aiJobs: payload,
      };
    }
    case FETCH_AI_JOBS_FAILED: {
      return {
        ...state,
        aiJobs: [],
      };
    }
    case CHAT_INIT_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload.message,
      };
    }
    case FETCH_CHAT_RECOMMENDATION: {
      return {
        ...state,
        recommendationLoading: true,
        recommendationError: false,
      };
    }
    case FETCH_CHAT_RECOMMENDATION_FAILED: {
      return {
        ...state,
        recommendationLoading: false,
        recommendationError: true,
      };
    }
    case FETCH_CHAT_RECOMMENDATION_SUCCESSFUL: {
      return {
        ...state,
        recommendationLoading: false,
        recommendationError: false,
        boundedChats: [...state.boundedChats.filter(chat => chat.patientId !== payload.patientId), payload],
      };
    }
    case FETCH_CHATBOTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        chatbots: payload,
      };
    }
    case FETCH_CHATBOT_CONTACTS: {
      return {
        ...state,
        isCBContactsLoading: true,
      };
    }
    case FETCH_CHATBOT_CONTACTS_FAILED: {
      return {
        ...state,
        isCBContactsLoading: false,
        chatbotContacts: null,
      };
    }
    case SENDBIRD_CHANNELS_FETCHED: {
      return {
        ...state,
        chats: {
          ...state.chats,
          channels: payload.initialFetch
            ? payload.channels
            : uniqueSBChannels([...state.chats.channels, ...payload.channels]),
        },
      };
    }
    case SENDBIRD_UNREAD_CHANNELS_FETCHED: {
      const newChannels = state.chats.channels.map(channel => {
        const newChannel = payload.channels.find(incomingChannel => incomingChannel.url === channel.url);
        if (newChannel) {
          return newChannel;
        }
        return channel;
      });
      payload.channels.forEach(channel => {
        const existing = newChannels.find(existingCh => existingCh.url === channel.url);
        if (!existing) {
          newChannels.push(channel);
        }
      });
      return {
        ...state,
        chats: {
          ...state.chats,
          channels: uniqueSBChannels(newChannels),
        },
      };
    }
    case FETCH_CHATBOT_CONTACTS_SUCCESSFUL: {
      return {
        ...state,
        isCBContactsLoading: false,
        chatbotContacts: payload,
      };
    }
    case FETCH_CHATBOT_ATTEMPT_HISTORY: {
      return {
        ...state,
        isCBAttemptHistoryLoading: true,
      };
    }
    case ADD_RECENT_MESSAGES: {
      return {
        ...state,
        recentMessageSend: [...state.recentMessageSend, payload.id],
      };
    }
    case REMOVE_RECENT_MESSAGES: {
      const index = state.recentMessageSend.indexOf(payload.id);
      if (index !== -1) {
        state.recentMessageSend.splice(index, 1);
      }
      return {
        ...state,
        recentMessageSend: state.recentMessageSend,
      };
    }
    case FETCH_CHATBOT_ATTEMPT_HISTORY_FAILED: {
      return {
        ...state,
        isCBAttemptHistoryLoading: false,
        chatbotAttemptHistory: [] as IChatbotAttemptHistory[],
      };
    }
    case FETCH_CHATBOT_ATTEMPT_HISTORY_SUCCESSFUL: {
      return {
        ...state,
        isCBAttemptHistoryLoading: false,
        chatbotAttemptHistory: payload.last30Days,
        chatbotcompleteHistory: payload.completeHistory,
      };
    }
    case SENDBIRD_CONNECTED: {
      return {
        ...state,
        sendbirdStatus: 2,
      };
    }
    case FETCH_ALL_TODO_SUCCESSFUL: {
      return {
        ...state,
        allTodos: payload,
      };
    }
    case SENDBIRD_CONNECT_FAILED: {
      return {
        ...state,
        sendbirdStatus: 0,
      };
    }
    case SENDBIRD_RECONNECTING: {
      return {
        ...state,
        sendbirdStatus: 3,
      };
    }
    case SENDBIRD_CONNECTING: {
      return {
        ...state,
        sendbirdStatus: 1,
      };
    }
    case CHAT_INITIALIZING: {
      return {
        ...state,
        isLoading: true,
        isLoadedAllMessages: false,
        errorMsg: null,
        connectionStatus: payload.connectionStatus,
        messages: [],
      };
    }
    case CHAT_READY: {
      return {
        ...state,
        isLoading: false,
        isLoadedAllMessages: payload.chatMessages.length < 50,
        errorMsg: null,
        recentMessageSend: [],
        connectionStatus: payload.connectionStatus,
        channelMembers: payload.channelMembers || state.channelMembers,
        messages: payload.chatMessages || [],
        channelUrl: payload.channelUrl,
      };
    }
    case LOAD_MORE_MESSAGES: {
      return {
        ...state,
        isLoadingMoreMessages: true,
        isLoadedAllMessages: false,
        errorMsg: null,
      };
    }
    case LOAD_MORE_MESSAGES_SUCCESS: {
      return {
        ...state,
        isLoadingMoreMessages: false,
        errorMsg: null,
        recentMessageSend: [],
        connectionStatus: payload.connectionStatus,
        channelMembers: payload.channelMembers || state.channelMembers,
        messages: [...payload.chatMessages, ...state.messages],
        channelUrl: payload.channelUrl,
        isLoadedAllMessages: payload.isLoadedAllMessages,
      };
    }
    case LOAD_OLD_MESSAGES: {
      return {
        ...state,
        messages: [...payload.chatMessages, ...state.messages],
      };
    }
    case LOAD_MORE_MESSAGES_FAILED: {
      return {
        ...state,
        isLoadingMoreMessages: false,
        isLoadedAllMessages: false,
        errorMsg: payload?.message || null,
      };
    }
    case CHAT_MARK_AS_READ: {
      const { channelUrl } = payload;
      return {
        ...state,
        chats: {
          ...state.chats,
          channels: state.chats.channels.map(channel => {
            if (channel.url === channelUrl) {
              channel.unreadMessageCount = 0;
            }
            return channel;
          }),
        },
      };
    }
    case CHAT_ADD_MESSAGE: {
      return {
        ...state,
        recentMessageSend:
          payload?.message?.mentionedProvidersIds?.length !== 0
            ? [...state.recentMessageSend, payload.message._id]
            : state.recentMessageSend,
        messages: [...state.messages, payload.message],
      };
    }
    case CHAT_MESSAGE_RECEIVED: {
      const res = state.messages.map(message => {
        if (message._id === payload.message.parentMessageId) {
          return { ...message, thread: [...message.thread, payload.message] };
        }
        if (message._id === 0 || message._id === null) {
          return { ...message, thread: [] };
        }
        return message;
      });
      const { channelUrl, sbChannel } = payload;
      return {
        ...state,
        messages:
          state.channelUrl &&
          state.channelUrl === channelUrl &&
          (payload.message.parentMessageId === 0 || payload.message.parentMessageId === null)
            ? [...state.messages, { ...payload.message, thread: [] }]
            : state.channelUrl &&
              state.channelUrl === channelUrl &&
              payload.message.parentMessageId !== 0 &&
              payload.message.parentMessageId
            ? res
            : state.messages,
        chats: {
          ...state.chats,
          channels: state.chats.channels.map(channel => {
            if (channel.url === sbChannel.url) {
              return sbChannel;
            }
            return channel;
          }),
        },
      };
    }
    case CHAT_MEDIA_SENT: {
      let { messages } = state;
      let updated = false;
      messages = messages.map(message => {
        /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
        if (payload.message.parentMessageId === 0 || payload.message.parentMessageId === null) {
          if (message._id === payload._id) {
            message._id = payload.messageId;
            message.user.avatar = payload.message?.sender?.plainProfileUrl;
            message.prevMsg =
              payload?.prevMsg?.messageId === undefined
                ? []
                : payload?.prevMsg?.messageId !== 0
                ? [payload.prevMsg]
                : [];
            message.fileMeta = {
              loading: false,
              url: payload.location,
              type: payload.type,
            };
            message.createdAt = payload.msgCreatedAt;
            // message.fileMeta.loading = false;
            // message.fileMeta.url = payload.location;
            updated = true;
          }
        }
        if (payload.message.parentMessageId !== 0 && payload.message.parentMessageId) {
          if (message._id === payload.message.parentMessageId) {
            message._id = payload.messageId;
            message.createdAt = payload.msgCreatedAt;
            message.user.avatar = payload.message?.sender?.plainProfileUrl;
            message.prevMsg =
              payload?.prevMsg?.messageId === undefined
                ? []
                : payload?.prevMsg?.messageId !== 0
                ? [payload.prevMsg]
                : [];
            message.thread.map(thread => {
              if (thread._id === payload._id) {
                thread.fileMeta = {
                  loading: false,
                  url: payload.location,
                  type: payload.type,
                };
              }
              return thread;
            });
          }
          updated = true;
        }
        return message;
      });
      if (!updated) {
        const message = getArtificialLoadingMediaMessage(payload, payload.meta);
        message.fileMeta.loading = false;
        message.fileMeta.url = payload.location;
        message.createdAt = payload.msgCreatedAt;
        message.user.avatar = payload.message?.sender?.plainProfileUrl;
        messages = [message, ...messages];
      }
      return {
        ...state,
        messages,
        chats: {
          ...state.chats,
          channels: state.chats.channels.map(channel => {
            if (channel.url === payload.channelUrl) {
              channel.lastMessage = payload.message;
            }
            return channel;
          }),
        },
      };
    }

    case CHAT_MEDIA_UPLOAD_PROGRESS: {
      let { messages } = state;
      let updated = false;
      messages = messages.map(message => {
        if (message.id === payload.id && message.fileMeta) {
          message.fileMeta.progress = payload.progress;
          updated = true;
        }
        return message;
      });
      if (!updated) {
        const message = getArtificialLoadingMediaMessage(payload, payload.meta);
        message.fileMeta.progress = payload.progress;
        messages = [message, ...messages];
      }
      return {
        ...state,
        messages,
      };
    }

    case CHAT_SEND_ATTACHMENT: {
      const res = state.messages.map(message => {
        if (message._id === action.payload.parentMessageId) {
          return {
            ...message,
            thread: [
              ...message.thread,
              getArtificialLoadingMediaMessage(action.payload, action.payload.meta),
            ],
          };
        }
        return message;
      });
      const giftedMessage = getArtificialLoadingMediaMessage(payload, payload.meta);
      return {
        ...state,
        messages:
          action.payload.parentMessageId === 0 || payload.message.parentMessageId === null
            ? [{ ...giftedMessage, thread: [] }, ...state.messages]
            : res,
      };
    }
    case GET_DATA_COLLECTION_TEMPLATES: {
      return {
        ...state,
        dct: {
          ...state.dct,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case GET_DATA_COLLECTION_TEMPLATES_SUCCESSFUL: {
      return {
        ...state,
        dct: {
          ...state.dct,
          isLoading: false,
          dcts: payload.dcts,
          totalRecords: payload.totalRecords,
        },
      };
    }
    case GET_DATA_COLLECTION_TEMPLATES_FAILED: {
      return {
        ...state,
        dct: {
          ...state.dct,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_SESSION_TYPES: {
      return {
        ...state,
        sessionType: { ...state.sessionType, isLoading: true, errorMsg: null },
      };
    }
    case FETCH_SESSION_TYPES_SUCCESSFUL: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: false,
          errorMsg: null,
          sessionTypes: payload?.sessionTypes,
        },
      };
    }
    case FETCH_SESSION_TYPES_FAILED: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: false,
          errorMsg: payload?.message || null,
          sessionTypes: [],
        },
      };
    }
    case CREATE_SESSION_TYPE: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case CREATE_SESSION_TYPE_SUCCESSFUL: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: false,
          errorMsg: null,
          sessionTypes: [...state.sessionType.sessionTypes, payload.sessionType],
        },
      };
    }
    case CREATE_SESSION_TYPE_FAILED: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case UPDATE_SESSION_TYPE: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case UPDATE_SESSION_TYPE_SUCCESSFUL: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: false,
          errorMsg: null,
          sessionTypes: state.sessionType.sessionTypes.map(sessionType =>
            sessionType.id === payload.sessionType.id
              ? { ...payload.sessionType, id: payload.sessionType.name }
              : sessionType,
          ),
        },
      };
    }
    case UPDATE_SESSION_TYPE_FAILED: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case DELETE_SESSION_TYPE: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case DELETE_SESSION_TYPE_SUCCESSFUL: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: false,
          errorMsg: null,
          sessionTypes: state.sessionType.sessionTypes.filter(
            sessionType => sessionType.id !== payload.sessionTypeId,
          ),
        },
      };
    }
    case DELETE_SESSION_TYPE_FAILED: {
      return {
        ...state,
        sessionType: {
          ...state.sessionType,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_PLAN_ITEMS: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_PLAN_ITEMS_SUCCESSFUL: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isLoading: false,
          errorMsg: null,
          currentPage: payload.currentPage,
          pageSize: payload.pageSize,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          planItems: payload.planItems,
        },
      };
    }
    case FETCH_PLAN_ITEMS_FAILED: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isLoading: false,
          errorMsg: payload?.message || null,
          currentPage: 1,
          totalPages: 1,
          totalRecords: 0,
          planItems: [],
        },
      };
    }
    case CREATE_PLAN_ITEM: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: true,
          errorMsg: null,
        },
      };
    }
    case CREATE_PLAN_ITEM_SUCCESSFUL: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: false,
        },
      };
    }
    case CREATE_PLAN_ITEM_FAILED: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case UPDATE_PLAN_ITEM: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: true,
          errorMsg: null,
        },
      };
    }
    case UPDATE_PLAN_ITEM_SUCCESSFUL: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: false,
        },
      };
    }
    case UPDATE_PLAN_ITEM_FAILED: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case DELETE_PLAN_ITEM: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: true,
          errorMsg: null,
        },
      };
    }
    case DELETE_PLAN_ITEM_SUCCESSFUL: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: false,
        },
      };
    }
    case DELETE_PLAN_ITEM_FAILED: {
      return {
        ...state,
        planItem: {
          ...state.planItem,
          isSaving: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_ASSIGNED_PLAN_ITEMS: {
      return {
        ...state,
        assignedPlanItems: {
          ...state.assignedPlanItems,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_ASSIGNED_PLAN_ITEMS_SUCCESSFUL: {
      return {
        ...state,
        assignedPlanItems: {
          ...state.assignedPlanItems,
          ...assignedPlanItemAdapter.setAll(state.assignedPlanItems, [action.payload]),
          isLoading: false,
        },
      };
    }
    case FETCH_ASSIGNED_PLAN_ITEMS_FAILED: {
      return {
        ...state,
        assignedPlanItems: {
          ...state.assignedPlanItems,
          ...assignedPlanItemAdapter.removeAll(state.assignedPlanItems),
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case ADD_ASSIGNED_PLAN_ITEMS: {
      return {
        ...state,
        assignedPlanItems: {
          ...state.assignedPlanItems,
          isSaving: true,
          errorMsg: null,
        },
      };
    }
    case ADD_ASSIGNED_PLAN_ITEMS_SUCCESSFUL: {
      return {
        ...state,
        assignedPlanItems: {
          ...state.assignedPlanItems,
          isSaving: false,
        },
      };
    }
    case ADD_ASSIGNED_PLAN_ITEMS_FAILED: {
      return {
        ...state,
        assignedPlanItems: {
          ...state.assignedPlanItems,
          isSaving: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_CONVERSATIONS: {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_CONVERSATIONS_SUCCESSFUL: {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          isLoading: false,
          errorMsg: null,
          conversations: payload.conversations,
          totalRecords: payload.totalRecords,
        },
      };
    }
    case FETCH_CONVERSATIONS_FAILED: {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          isLoading: false,
          errorMsg: payload?.message || null,
          conversations: [],
          totalRecords: 0,
        },
      };
    }
    case FETCH_EVALUATIONS: {
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_EVALUATIONS_SUCCESSFUL: {
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          isLoading: false,
          errorMsg: null,
          evaluations: payload.evaluations,
          totalRecords: payload.totalRecords,
        },
      };
    }
    case FETCH_EVALUATIONS_FAILED: {
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          isLoading: false,
          errorMsg: payload?.message || null,
          evaluations: [],
        },
      };
    }
    case FETCH_REVAMP_TYPES_LIST: {
      return {
        ...state,
        revampType: {
          ...state.revampType,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_REVAMP_TYPES_LIST_SUCCESSFUL: {
      return {
        ...state,
        revampType: {
          ...state.revampType,
          isLoading: false,
          errorMsg: null,
          revampTypes: payload.revampTypes,
        },
      };
    }
    case FETCH_REVAMP_TYPES_LIST_FAILED: {
      return {
        ...state,
        revampType: {
          ...state.revampType,
          isLoading: false,
          errorMsg: payload?.message || null,
          revampTypes: [],
        },
      };
    }
    case FETCH_TAGS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_TAGS_SUCCESSFUL: {
      return {
        ...state,
        tags: {
          ...state.tags,
          isLoading: false,
          errorMsg: null,
          tags: payload.tagList,
        },
      };
    }
    case FETCH_TAGS_FAILED: {
      return {
        ...state,
        tags: {
          ...state.tags,
          isLoading: false,
          errorMsg: payload?.message || null,
          tags: [],
        },
      };
    }
    case FETCH_EVALUATION_CONTEXT: {
      return {
        ...state,
        evaluationContext: {
          ...state.evaluationContext,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case RE_RUN_AI_ANALYSIS: {
      return {
        ...state,
        evaluationContext: {
          ...state.evaluationContext,
          evaluationContext: {
            ...state.evaluationContext.evaluationContext,
            alfieValidationInProgress: true,
          },
        },
      };
    }
    case FETCH_EVALUATION_CONTEXT_SUCCESSFUL: {
      return {
        ...state,
        evaluationContext: {
          ...state.evaluationContext,
          isLoading: false,
          errorMsg: null,
          evaluationContext: payload.evaluationContext,
        },
      };
    }
    case FETCH_EVALUATION_CONTEXT_FAILED: {
      return {
        ...state,
        evaluationContext: {
          ...state.evaluationContext,
          isLoading: false,
          errorMsg: payload?.message || null,
          evaluationContext: null,
        },
      };
    }
    case TOGGLE_EVALUATION_STAUTS: {
      return {
        ...state,
        evaluationContext: {
          ...state.evaluationContext,
          toggleStatus: !state.evaluationContext.toggleStatus,
        },
      };
    }
    case FETCH_ASSOCIATED_TAG: {
      return {
        ...state,
        associatedTags: {
          ...state.associatedTags,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_ASSOCIATED_TAG_SUCCESSFUL: {
      return {
        ...state,
        associatedTags: {
          ...state.associatedTags,
          ...associatedTagsAdapter.upsertOne(state.associatedTags, action.payload.domainType),
          isLoading: false,
        },
      };
    }
    case FETCH_ASSOCIATED_TAG_FAILED: {
      return {
        ...state,
        associatedTags: {
          ...state.associatedTags,
          isLoading: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_PROFILE_ELEMENTS: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_PROFILE_ELEMENTS_SUCCESSFUL: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isLoading: false,
          errorMsg: null,
          currentPage: payload.currentPage,
          pageSize: payload.pageSize,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          profileElementList: payload.profileElementList,
        },
      };
    }
    case FETCH_PROFILE_ELEMENTS_FAILED: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isLoading: false,
          errorMsg: payload?.message || null,
          currentPage: 1,
          totalPages: 1,
          totalRecords: 0,
          profileElementList: [],
        },
      };
    }
    case FETCH_AUTOMATION_RULE_LIST: {
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_AUTOMATION_RULE_LIST_SUCCESSFUL: {
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          isLoading: false,
          errorMsg: null,
          currentPage: payload.currentPage,
          pageSize: payload.pageSize,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
          automationRules: payload.automationsList,
        },
      };
    }
    case FETCH_AUTOMATION_RULE_LIST_FAILED: {
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          isLoading: false,
          errorMsg: payload?.message || null,
          currentPage: 1,
          totalPages: 1,
          totalRecords: 0,
          automationRules: [],
        },
      };
    }
    case FETCH_AUTOMATION_COUNT: {
      return {
        ...state,
        automationRulesCount: {
          ...state.automationRulesCount,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_AUTOMATION_COUNT_SUCCESSFUL: {
      return {
        ...state,
        automationRulesCount: {
          ...state.automationRulesCount,
          isLoading: false,
          errorMsg: null,
          counts: payload.data,
        },
      };
    }
    case FETCH_AUTOMATION_COUNT_FAILED: {
      return {
        ...state,
        automationRulesCount: {
          ...state.automationRulesCount,
          isLoading: false,
          errorMsg: payload?.message || null,
          counts: DEFAULT.automationRulesCount.counts,
        },
      };
    }
    case ADD_PROFILE_ELEMENT: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isSaving: true,
          errorMsg: null,
        },
      };
    }
    case ADD_PROFILE_ELEMENT_SUCCESSFUL: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isSaving: false,
          errorMsg: null,
        },
      };
    }
    case ADD_PROFILE_ELEMENT_FAILED: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isSaving: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case UPDATE_PROFILE_ELEMENT: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isSaving: true,
          errorMsg: null,
        },
      };
    }
    case UPDATE_PROFILE_ELEMENT_SUCCESSFUL: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isSaving: false,
          errorMsg: null,
        },
      };
    }
    case UPDATE_PROFILE_ELEMENT_FAILED: {
      return {
        ...state,
        profileElements: {
          ...state.profileElements,
          isSaving: false,
          errorMsg: payload?.message || null,
        },
      };
    }
    case FETCH_ASSIGNED_EVALUATION: {
      return {
        ...state,
        assignedEvaluations: {
          ...state.assignedEvaluations,
          isLoading: true,
          errorMsg: null,
        },
      };
    }
    case FETCH_ASSIGNED_EVALUATION_SUCCESSFUL: {
      return {
        ...state,
        assignedEvaluations: {
          ...state.assignedEvaluations,
          isLoading: false,
          errorMsg: null,
          evaluations: payload,
        },
      };
    }
    case FETCH_LIBRARY_CONTENT_BLOCKS: {
      return {
        ...state,
        libraryContentBlocks: {
          ...state.libraryContentBlocks,
          isLoading: true,
        },
      };
    }
    case FETCH_LIBRARY_CONTENT_BLOCKS_SUCCESSFUL: {
      return {
        ...state,
        libraryContentBlocks: {
          totalRecords: payload.totalRecords,
          totalPages: payload.totalPages,
          currentPage: payload.currentPage,
          results: payload.results,
          isLoading: false,
        },
      };
    }
    case FETCH_LIBRARY_CONTENT_BLOCKS_FAILED: {
      return {
        ...state,
        libraryContentBlocks: {
          totalRecords: 0,
          totalPages: 0,
          currentPage: 0,
          results: [],
          isLoading: false,
        },
      };
    }
    case FETCH_ASSIGNED_EVALUATION_FAILED: {
      return {
        ...state,
        assignedEvaluations: {
          ...state.assignedEvaluations,
          isLoading: false,
          errorMsg: payload?.message || null,
          evaluations: null,
        },
      };
    }
    case CHAT_EXIT: {
      return {
        ...state,
        channelUrl: null,
        messages: [],
      };
    }
    default: {
      return state;
    }
  }
}

export const getArtificialLoadingMediaMessage = (
  message: { _id: any; message: any; parentMessageId: any; file: { uri: any; type: any }; messageId: any },
  meta: { userId: any; nickName: any },
) => {
  let giftedMessage = {} as IGiftedMessage;
  giftedMessage = {
    _id: message._id,
    parentMessageId: message.parentMessageId,
    message: {
      text: null,
    },
    nickName: giftedMessage.system ? 'system' : meta.nickName,
    createdAt: new Date().getTime(),
    type: 'file',
    fileMeta: {
      url: message.file.uri,
      type: message.file.type,
      loading: true,
      progress: 0,
    },
    system: false,
    user: null,
  };
  if (giftedMessage.system) {
    giftedMessage.user = {
      userId: message.messageId,
      name: 'System',
      avatar: null,
    };
  } else {
    giftedMessage.user = {
      userId: meta.userId,
      name: meta.nickName,
      avatar: null,
    };
  }
  return giftedMessage;
};
