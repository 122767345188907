import * as yup from 'yup';

const PriorityGroupingSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Domain type is required'),
  relatedElements: yup
    .array()
    .of(yup.object())
    .required('Related Elements are required')
    .min(1, 'At least one related element is required'),
});

export { PriorityGroupingSchema };
