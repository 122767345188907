import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
// import { positionType, Toggle } from '@confidant-health/lib/ui/atoms/toggle';
import { Icons } from '@confidant-health/lib/icons';
import DateSelect from '@confidant-health/lib/ui/templates/date-select';
import { colors } from '@confidant-health/lib/colors';
import { getConversation } from 'redux/modules/conversation/selectors';
import { conversationActionCreators } from 'redux/modules/conversation';
import { profileActionCreators } from 'redux/modules/profile';
import { useStyles } from './ChatsMenu.style';

type Props = {
  id?: number;
  toDoIds?: string[];
  msg?: string;
  type?: string;
  currentUserId?: string;
  isEvaluation?: boolean;
  closeToDoDialog?: () => void;
};

const TodoDialog: FC<Props> = ({ id, toDoIds, msg, type, currentUserId, isEvaluation, closeToDoDialog }) => {
  const { allTodos } = useSelector(getConversation);
  const classes = useStyles();
  const dispatch = useDispatch();
  const lastDate = dayjs().toDate().toISOString();
  const todoDate = allTodos?.find(item => item?.contentIdentifier === id);
  const [dateValue, setDateValue] = React.useState(
    todoDate === undefined
      ? lastDate
      : Object.keys(todoDate)?.length !== 0 && todoDate?.dueDate !== null
      ? todoDate?.dueDate
      : lastDate,
  );
  // const [sendSms, setSendSms] = React.useState(false);
  return (
    <div className={type === 'Parent' ? classes.TodoContainer : classes.TodoContainer2}>
      <div className={classes.todoMainContainer}>
        <div className={classes.todoTitleContainer}>
          <div className={classes.todoHeading}>
            <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD}>
              {msg}
            </Text>
          </div>
          <div className={classes.todoCloseBtnContainer}>
            <Button
              variant={btnType.TEXT}
              className={classes.todoCloseBtn}
              onClick={() => {
                isEvaluation
                  ? closeToDoDialog()
                  : dispatch(conversationActionCreators.removeRecentMessage({ id }));
              }}
            >
              <Icons glyph="close" color={colors.primary600} />
            </Button>
          </div>
        </div>
        <div className={classes.todoContainer}>
          <div>
            <DateSelect
              value={dateValue?.replaceAll('-', '/')}
              onChange={value => setDateValue(value.toDateString())}
              inputClassName={classes.inputCalender}
              minDate={new Date()}
            />
          </div>
          {/* <div className={classes.todoToggleBtn}>
            <Toggle
              labelPosition={positionType.RIGHT}
              checked={sendSms}
              onChange={value => setSendSms(value)}
            >
              Notify via sms
            </Toggle>
          </div> */}
        </div>
      </div>
      <div className={classes.todoBtnContainer}>
        <Button
          variant={btnType.PRIMARY}
          className={classes.todoBtn}
          onClick={() => {
            if (isEvaluation) {
              toDoIds.forEach(toDoId => {
                const bodyRequest = {
                  id: toDoId,
                  status: false,
                  dueDate: dayjs(dateValue).format('MM-DD-YYYY'),
                  createdBy: currentUserId,
                };
                dispatch(profileActionCreators.updateTodo({ bodyRequest }));
                closeToDoDialog();
              });
            } else {
              const bodyRequest = {
                contentIdentifier: id,
                dueDate: dayjs(dateValue).format('MM-DD-YYYY'),
                createdBy: currentUserId,
              };
              dispatch(profileActionCreators.updateTodo({ bodyRequest }));
              dispatch(conversationActionCreators.removeRecentMessage({ id }));
            }
          }}
        >
          Add due date
        </Button>
        <Button
          variant={btnType.OUTLINE}
          className={classes.todoBtn}
          onClick={() => {
            isEvaluation
              ? closeToDoDialog()
              : dispatch(conversationActionCreators.removeRecentMessage({ id }));
          }}
        >
          No, Thanks
        </Button>
      </div>
    </div>
  );
};

export { TodoDialog };
