import React, { FC, useEffect, useState } from 'react';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Box } from '@mui/material';
import dayjs from '../../../../../utils/dayjs';
import { getDctResponses } from '../../../../../services/reporting/reporting.service';

type Props = {
  memberId: string;
  cbId: string;
  contextId: string;
};
const sx = {
  completionDate: {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.neutral900,
  },
  response: {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    lineHeight: '24px',
    color: colors.neutral800,
  },
};
const PreviousResponses: FC<Props> = ({ memberId, cbId, contextId }) => {
  const [previousResponses, setPreviousResponses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getDctResponses({ userId: memberId, cbId, beforeDate: 30 })
      .then(response => {
        setPreviousResponses(
          response.data?.data?.responseHistory.filter(r => r.contextId !== contextId) || [],
        );
        setLoading(false);
      })
      .catch(e => {
        console.warn(e);
        setPreviousResponses([]);
        setLoading(false);
      });
  }, [memberId, cbId]);

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 10 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <Box>
          {previousResponses.length > 0 ? (
            <Box display="flex" flexDirection="column" gap={2}>
              {previousResponses.map((response, index) => (
                <Box key={index} display="flex" flexDirection="column" gap={0.5}>
                  <Box sx={sx.completionDate}>{dayjs(response.completedAt).format('MMMM D, YYYY')}</Box>
                  <Box sx={sx.response}>{response.responses}</Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>No previous responses</Box>
          )}
        </Box>
      )}
    </>
  );
};
export { PreviousResponses };
