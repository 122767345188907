import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Heading,
  headingLevel,
  Text,
  textLevel,
  fontWeight,
} from '@confidant-health/lib/ui/atoms/typography';
import { Box, Divider } from '@mui/material';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { S3_BUCKET_URL } from '@confidant-health/lib/constants/CommonConstants';
import dayjs from 'dayjs';
import { profileActionCreators } from 'redux/modules/profile/actions';
import { getProfile, selectMemberInsurance } from 'redux/modules/profile/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import { getStatesSimple } from 'services/state/state.service';
import { stateActionCreators } from 'redux/modules/state';
import { validateInsuranceInfo } from 'services/member/member.service';
import { showSnackbar } from 'redux/modules/snackbar';
import { collapseObject } from 'utils/CommonUtils';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';
import PriorityEmpty from 'pages/provider/member-detail/components/PriorityEmpty';
import IdCardUploadDrawer from './upload-drawer';
import InsuranceInformationEdit from './edit';
import { RELATIONSHIPS_TO_CLIENTS_ENUM, validationKeyLabels } from './constants';
import { useStyles } from '../../MemberDetail.styles';

const InsuranceInformation = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { demographicDetails } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);

  const [openInsuranceEdit, setOpenInsuranceEdit] = useState<boolean>(false);
  const [openIDCardUpload, setOpenIDCardUpload] = useState<boolean>(false);
  const [statesSimpleList, setStatesSimpleList] = useState(null);
  const [validatedData, setValidatedData] = useState({} as any);
  const { isLoading, insurance } = useSelector(selectMemberInsurance);
  const getAllStates = async () => {
    try {
      const response = await getStatesSimple();
      setStatesSimpleList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const updateValidatedInsuranceData = data => {
    const vData = collapseObject({
      vob: data?.vob,
      insuranceEligibilityStatus: data?.insuranceEligibilityStatus,
      insuranceEligibilityEligible: data?.insuranceEligibilityEligible,
      soharSystemId: data?.soharSystemId,
      ...data?.insuranceEligibilityDetail,
      timestamp: dayjs(data?.insuranceEligibilityDetail?.timestamp)?.format(`${GLOBAL_DATE_FORMAT} - hh:mma`),
      coverageStartDate: dayjs(data?.insuranceEligibilityDetail?.coverageStartDate)?.format(
        GLOBAL_DATE_FORMAT,
      ),
      coverageEndDate: dayjs(data?.insuranceEligibilityDetail?.coverageEndDate)?.format(GLOBAL_DATE_FORMAT),
    });
    setValidatedData(vData);
  };
  useEffect(() => {
    updateValidatedInsuranceData({
      insuranceEligibilityStatus: insurance?.insuranceEligibilityStatus,
      insuranceEligibilityEligible: insurance?.insuranceEligibilityEligible,
      soharSystemId: insurance?.soharSystemId,
      insuranceEligibilityDetail: insurance?.insuranceEligibilityDetail,
      vob: insurance?.vob,
    });
  }, [insurance]);
  useEffect(() => {
    dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
    getAllStates()?.then(r => console.log(r));
  }, []);

  const submitIDCard = useCallback(urls => {
    if (!!urls?.front && !!urls?.back) {
      const photoFront = urls.front ? urls.front : insurance?.patientInsuranceData?.memberIdPhotoFront;
      const photoBack = urls.back ? urls.back : insurance?.patientInsuranceData?.memberIdPhotoBack;
      dispatch(
        profileActionCreators.updateMemberInsurance({
          memberId,
          payload: {
            userAccountId: memberId,
            currentInsurance: insurance?.currentInsurance?._id,
            patientInsuranceData: {
              primaryInsured: insurance?.patientInsuranceData?.primaryInsured,
              fullName: insurance?.patientInsuranceData?.fullName,
              dob: insurance?.patientInsuranceData?.dob,
              memberId: insurance?.patientInsuranceData?.memberId,
              memberIdPhotoFront: photoFront?.replaceAll(S3_BUCKET_URL, '') || '',
              memberIdPhotoBack: photoBack?.replaceAll(S3_BUCKET_URL, '') || '',
              gender: insurance?.patientInsuranceData?.gender,
              relationshipToPrimarySubscriber:
                insurance?.patientInsuranceData?.relationshipToPrimarySubscriber,
              employerName: insurance?.patientInsuranceData?.employerName,
              employerGroupId: insurance?.patientInsuranceData?.employerGroupId,
            },
          },
        }),
      );
      setOpenIDCardUpload(false);
    }
  }, []);

  const renderCardHeader = (label: string, btnLabel: string, onClick?: () => void) => (
    <Box padding="24px" display="flex" justifyContent="space-between" alignItems="center">
      <Text className={classes.insuranceItemHead} weight={fontWeight.SEMI_BOLD}>
        {label}
      </Text>
      {onClick && (
        <Button className={classes.noPadding} variant={btnType.TEXT} onClick={onClick}>
          {btnLabel}
        </Button>
      )}
    </Box>
  );
  const renderCardItem = (label: string, content: string) => (
    <>
      <Divider />
      <Box padding="24px" display="flex">
        <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.insuranceItemHeadSub}>
          {label}
        </Text>
        <Text level={textLevel.M} weight={fontWeight.MEDIUM} className={classes.insuranceItemLabel}>
          {content}
        </Text>
      </Box>
    </>
  );

  const renderCardItemImage = (label: string, content: string) => (
    <>
      <Divider />
      <Box padding="24px" display="flex">
        <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.insuranceItemHeadSub}>
          {label}
        </Text>
        {content ? (
          <img style={{ width: '233px', height: '141px' }} src={content} alt="front" />
        ) : (
          <Box className={classes.noImage}>
            {isLoading ? (
              <div className={classes.loaderFlex}>
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              </div>
            ) : (
              <Text level={textLevel.S}>Not available</Text>
            )}
          </Box>
        )}
      </Box>
    </>
  );

  useEffect(() => {
    if (insurance?.currentInsurance?.stateId) {
      dispatch(stateActionCreators.fetchPayers(insurance?.currentInsurance?.stateId));
    }
  }, [openInsuranceEdit]);

  const onValidateClickHandler = () => {
    validateInsuranceInfo(memberId)
      .then(res => {
        if (!res.data?.success) {
          dispatch(
            showSnackbar({ snackType: 'error', snackMessage: res.data?.message || 'Something went wrong' }),
          );
          return;
        }
        dispatch(profileActionCreators.fetchMemberInsurance({ memberId }));
        dispatch(showSnackbar({ snackType: 'success', snackMessage: res.data?.message }));
      })
      .catch(error => {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: error.message || `Unable to validate insurance information.`,
          }),
        );
      });
  };

  const formatBooleanValue = value => {
    if (typeof value === 'boolean') {
      if (value === true) return 'Yes';
      return 'No';
    }
    return value;
  };

  return (
    <Box>
      {/* {errorMsg && <TextError errorMsg={errorMsg} />} */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Insurance Information
          </Heading>
        </Box>
        <Box className={classes.insuranceHeaderButtons}>
          {insurance && (
            <Button className="validateBtn" onClick={onValidateClickHandler}>
              Validate
            </Button>
          )}
          {!insurance && (
            <IconButton
              className="addBtn"
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={() => setOpenInsuranceEdit(true)}
            >
              Add Insurance
            </IconButton>
          )}
        </Box>
      </Box>

      {isLoading && (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      )}
      {!isLoading && !insurance && (
        <Box className={classes.card} marginTop="40px">
          <PriorityEmpty
            title="Insurance information has not been updated yet."
            description=""
            glyph="lottie-insurance"
            glyphOrientation="portrait"
            isEmptyWithLottie
            lottieSize={128}
          />
        </Box>
      )}
      {insurance && (
        <>
          <Box className={classes.card} marginTop="40px">
            {renderCardHeader('Insurance information', 'Edit insurance information', () =>
              setOpenInsuranceEdit(true),
            )}
            {renderCardItem('VBC', demographicDetails?.vbcFlag ? 'Active' : 'Inactive')}
            {renderCardItem('Insurance carrier', insurance?.currentInsurance?.name || 'N/A')}
            {renderCardItem('Insurance plan', insurance?.currentInsurance?.plans[0]?.name || 'N/A')}
            {renderCardItem(
              'State',
              statesSimpleList?.find(state => state?._id === insurance?.currentInsurance?.stateId)?.name ||
                'N/A',
            )}
            {renderCardItem('First name', insurance?.patientInsuranceData?.firstName || 'N/A')}
            {renderCardItem('Last name', insurance?.patientInsuranceData?.lastName || 'N/A')}

            {renderCardItem('Name', insurance?.patientInsuranceData?.fullName || 'N/A')}
            {renderCardItem(
              'DOB',
              dayjs(insurance?.patientInsuranceData?.dob)?.format('MM/DD/YYYY') || 'N/A',
            )}
            {renderCardItem('Gender', insurance?.patientInsuranceData?.gender || 'N/A')}
            {renderCardItem(
              'Primary Insured',
              insurance?.patientInsuranceData?.primaryInsured ? 'Yes' : 'No',
            )}
            {renderCardItem('Member ID', insurance?.patientInsuranceData?.memberId || 'N/A')}
            {!insurance?.patientInsuranceData?.primaryInsured &&
              renderCardItem('Employer Group Name', insurance?.patientInsuranceData?.employerName || 'N/A')}
            {!insurance?.patientInsuranceData?.primaryInsured &&
              renderCardItem('Employer Group Id', insurance?.patientInsuranceData?.employerGroupId || 'N/A')}
            {!insurance?.patientInsuranceData?.primaryInsured &&
              renderCardItem(
                'Relationship',
                RELATIONSHIPS_TO_CLIENTS_ENUM[
                  insurance.patientInsuranceData?.relationshipToPrimarySubscriber
                ],
              )}
            {renderCardItem('Co-pay', insurance?.isCopay ? insurance?.coPayAmount?.toString() : 'N/A')}
          </Box>
          <Box marginY="16px" className={classes.card}>
            {renderCardHeader('Insurance card', 'Edit', () => setOpenIDCardUpload(true))}
            {renderCardItemImage('Front side', insurance?.patientInsuranceData?.memberIdPhotoFront)}
            {renderCardItemImage('Back side', insurance?.patientInsuranceData?.memberIdPhotoBack)}
          </Box>

          {/* <Box className={classes.card}>
            {renderCardHeader('Benefits information', 'Edit')}
            {renderCardItem('Eligible for benefits', '-')}
            {renderCardItem('Co-pay', insurance?.isCopay ? insurance?.coPayAmount?.toString() : 'N/A')}
            {renderCardItem('Deductible', '-')}
            {renderCardItem('Co-insurance', '-')}
          </Box> */}
          {isAdmin && (
            <Box className={classes.card}>
              {renderCardHeader('Validation', 'Edit')}
              {validatedData
                ? Object.keys(validatedData)?.length > 0 &&
                  Object.keys(validatedData).map(item => {
                    return renderCardItem(validationKeyLabels[item], formatBooleanValue(validatedData[item]));
                  })
                : ''}
            </Box>
          )}
        </>
      )}
      {openInsuranceEdit && (
        <InsuranceInformationEdit
          openDrawer={openInsuranceEdit}
          onClose={() => setOpenInsuranceEdit(false)}
          data={insurance}
          states={statesSimpleList}
          vbc={demographicDetails.vbcFlag}
          patientInfo={demographicDetails}
        />
      )}
      {openIDCardUpload && (
        <IdCardUploadDrawer
          openDrawer={openIDCardUpload}
          onClose={() => setOpenIDCardUpload(false)}
          onSubmit={submitIDCard}
          isInsurance
        />
      )}
    </Box>
  );
};

export default InsuranceInformation;
