import { FC } from 'react';
import { Box, Divider } from '@mui/material';
import clsx from 'clsx';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
// styles
import { useStyles } from './ProviderCard.styles';
// types
import { IProviderCardProps } from './ProviderCard.types';

const ProviderCard: FC<IProviderCardProps> = ({ title, subtitle, provider, label }) => {
  const classes = useStyles();
  // const [showChat, setShowChat] = useState(false);
  // const { isAdmin } = useSelector(getAuth);

  return (
    <>
      {/* <MessageProvider
        isOpen={showChat}
        onClose={toggleShowChat}
        user={{
          id: '1',
          name: provider?.name,
          fullname: provider?.fullname,
          role: provider?.role,
          thumbnail: provider?.thumbnail,
          isProvider: !!provider?.role,
          lastLogin: dayjs().subtract(1, 'd').toISOString(),
        }}
        me={{
          id: '2',
          name: 'John Doe',
          thumbnail: 'https://i.pravatar.cc/300',
        }}
      /> */}
      <Box className={classes.card}>
        <Box className={classes.cardTop} display="flex" justifyContent="space-between" alignItems="center">
          <Text className={classes.cardTopTitle} weight={fontWeight.MEDIUM} level={textLevel.S}>
            {label}
          </Text>
          {/* {provider && !isAdmin && (
            <Box className={classes.btnLinkWrap}>
              <IconLink
                icon="chat-square"
                onClick={toggleShowChat}
                iconPosition={iconLinkPosition.RIGHT}
                className={classes.btnLink}
                href="#"
              />
            </Box>
          )} */}
        </Box>
        <Divider />
        <Box className={classes.cardBody}>
          {provider ? (
            <Box display="flex" alignItems="center" gap="12px">
              <Avatar variant={avatarType.CIRCLE} size={40} src={provider.thumbnail} name={provider.name} />
              <Box>
                <Text className={classes.username} level={textLevel.S}>
                  {provider.name}
                </Text>
                {provider.role ? (
                  <Badge className={classes.badge} variant={badgeType.FILLED}>
                    {provider.role}
                  </Badge>
                ) : (
                  <Text className={clsx(classes.username, classes.usernameFull)} level={textLevel.S}>
                    {provider.fullname}
                  </Text>
                )}
              </Box>
            </Box>
          ) : (
            <Box>
              <Text className={classes.title} level={textLevel.S}>
                {title}
              </Text>
              <Box />
              <Text className={classes.subtitle} level={textLevel.S}>
                {subtitle}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export { ProviderCard };
