import { FC } from 'react';
import { Box, Divider, Paper } from '@mui/material';
import { IGlyphs, Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Text, fontWeight, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import history from 'utils/history';

import { DashboardHeading } from '../dashboard-heading';
import { MessageItem } from '../message-item/MessageItem';
import { IMessageItemProps } from '../message-item/MessageItem.types';
import { useStyles } from './Messages.styles';

type Props = {
  className?: string;
  title: string;
  items: IMessageItemProps[];
  onViewAll?: () => void;
  loading?: boolean;
  emptyLabel?: string;
  emptyDescription1?: string;
  emptyDescription2?: string;
  icon?: IGlyphs['glyph'];
};

const Messages: FC<Props> = ({
  items,
  title,
  loading,
  icon,
  emptyLabel,
  emptyDescription1,
  emptyDescription2,
  onViewAll,
}) => {
  const classes = useStyles();

  return (
    <Paper
      sx={{
        boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 10px 20px 0px #0000000A',
        borderRadius: 2,
      }}
    >
      <DashboardHeading title={title} count={items?.length} onClick={onViewAll} />
      <Divider />
      <div className={classes.upcomingItems}>
        {loading ? (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            Loading..
          </Box>
        ) : !items || items?.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              gap: 3,
              '& svg': {
                width: 56,
                height: 56,
              },
            }}
          >
            {icon && <Icons glyph={icon} color={colors.secondary500} />}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {emptyLabel && (
                <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD}>
                  {emptyLabel}
                </Text>
              )}
              {emptyDescription1 && (
                <Text level={textLevel.M} weight={fontWeight.MEDIUM} color={colors.neutral600}>
                  {emptyDescription1}
                </Text>
              )}
              {emptyDescription2 && (
                <Text level={textLevel.M} weight={fontWeight.MEDIUM} color={colors.neutral600}>
                  {emptyDescription2}
                </Text>
              )}
            </Box>
          </Box>
        ) : (
          <>
            {items?.slice(0, 5).map(item => {
              let chatType = 'members';
              if (item.type === 'PRACTITIONER') {
                chatType = 'providers';
              } else if (item.type === 'CHAT_GROUP') {
                chatType = 'groups';
              }
              return (
                <MessageItem
                  {...item}
                  key={item.id}
                  className={classes.item}
                  onClick={() => {
                    history.push(`/provider/chats?userId=${item.id}&type=${chatType}`);
                  }}
                />
              );
            })}
          </>
        )}
      </div>
    </Paper>
  );
};

export { Messages };
