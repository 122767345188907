/* eslint-disable react/destructuring-assignment */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDownloadExcel } from 'table-to-excel-react';

import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box, Grid, Stack } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { boxType, DashboardBox } from '@confidant-health/lib/ui/templates/dashboard-box';

import { getElkLoginReport } from 'services/reporting/reporting.service';
import { IReport } from 'redux/modules/reporting/types';
import RecentAssignmentsTable from 'pages/admin/plan-items/components/plan-item-detail/recent-assignments-table';
import HeadCard from '../head-card';
import AssignmentsChart from '../assignments-chart';
import { ViewAllDrawer } from '../appointments/ViewAllDrawer';

import {
  loginColumns,
  lastLoginColumns,
  getLoginsQuery,
  getBoxData,
  getCurrent30DaysCounts,
  getPast30DaysCounts,
  getMostLoginsData,
  getFewestLoginsData,
  getMostRecentLoginsData,
  getMostSinceLastLoginData,
} from './Logins.constants';
import { ILoginReport } from './Logins.types';
import { useStyles } from './Logins.styles';

type Props = {
  report: IReport;
  showNewReport: boolean;
};

const Logins: FC<Props> = ({ report, showNewReport }) => {
  const [loginsReport, setLoginsReport] = useState<ILoginReport>(null);
  const componentRef = useRef();
  const { onDownload } = useDownloadExcel({
    fileName: 'logins_report',
    table: 'table_to_xls',
    sheet: 'sheet 1',
  });
  const classes = useStyles();
  const [showViewAllType, setShowViewAllType] = useState(null);
  const boxData = useMemo(() => getBoxData(loginsReport, report), [loginsReport, report]);
  const current30DaysCounts = useMemo(() => getCurrent30DaysCounts(loginsReport), [loginsReport]);
  const past30DaysCounts = useMemo(() => getPast30DaysCounts(loginsReport), [loginsReport]);
  const mostLoginsData = useMemo(() => getMostLoginsData(loginsReport), [loginsReport]);
  const fewestLoginsData = useMemo(() => getFewestLoginsData(loginsReport), [loginsReport]);
  const mostRecentLoginsData = useMemo(() => getMostRecentLoginsData(loginsReport), [loginsReport]);
  const mostSinceLastLoginData = useMemo(() => getMostSinceLastLoginData(loginsReport), [loginsReport]);
  const loginSection = useMemo(() => report?.data?.loginSection, [report]);

  useEffect(() => {
    !showNewReport &&
      void (async () => {
        const resp = await getElkLoginReport(getLoginsQuery(report), null);
        setLoginsReport(resp.data);
      })();
  }, [showNewReport]);

  const onCloseViewAll = () => {
    setShowViewAllType(null);
  };

  const onClickAll = (type: string) => () => {
    setShowViewAllType(type);
  };

  // eslint-disable-next-line react/display-name
  const renderMenu = () => () => {
    return <></>;
    // return (
    //   <Menu
    //     icon="more"
    //     className={classes.menu}
    //     items={[
    //       { label: 'Menu Item 1', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 1') },
    //       { label: 'Menu Item 2', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 2') },
    //       { label: 'Menu Item 3', onClick: onClickMenu(menuTable, action?.id, 'Menu Item 3') },
    //     ]}
    //   />
    // );
  };

  const renderTable = (title, data, columns, onClickViewAll, renderMenuAction, className = '') => (
    <Box className={clsx(classes.tableWrap, className)} flex={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text className={classes.recentTitle} weight={fontWeight.SEMI_BOLD}>
          {title}
        </Text>
        <IconButton onClick={onClickViewAll} className={classes.viewAllBtn}>
          View top ({data.length})
        </IconButton>
      </Stack>
      <RecentAssignmentsTable
        className={classes.tableBordered}
        isShowAll={false}
        tableColumns={columns}
        data={data}
        renderMenuAction={renderMenuAction}
      />
    </Box>
  );

  const getContentsInViewAll = (): {
    title: string;
    subtitle: string;
    tableColumns: TableGrid.TableColumn[];
    renderMenuAction: (payload: any) => void;
    data: unknown[];
  } => {
    if (showViewAllType === 'mostLogins') {
      return {
        title: 'Most logins',
        subtitle: `${mostLoginsData.length} total`,
        tableColumns: loginColumns,
        data: mostLoginsData,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === 'fewestLogins') {
      return {
        title: 'Fewest logins',
        subtitle: `${fewestLoginsData.length} total`,
        tableColumns: loginColumns,
        data: fewestLoginsData,
        renderMenuAction: renderMenu(),
      };
    }
    if (showViewAllType === 'mostRecentLogins') {
      return {
        title: 'Most recent logins',
        subtitle: `${mostRecentLoginsData.length} total`,
        tableColumns: loginColumns,
        data: mostRecentLoginsData,
        renderMenuAction: renderMenu(),
      };
    }
    return {
      title: 'Most time since last login',
      subtitle: `${mostSinceLastLoginData.length} total`,
      tableColumns: lastLoginColumns,
      data: mostSinceLastLoginData,
      renderMenuAction: renderMenu(),
    };
  };

  const renderAllTables = () => {
    return (
      <div id="table_to_xls" hidden>
        {...[
          {
            title: 'Most Logins',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: loginColumns,
            data: mostLoginsData as any,
          },
          {
            title: 'Fewest Logins',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: loginColumns,
            data: fewestLoginsData as any,
          },
          {
            title: 'Most Recent Logins',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: loginColumns,
            data: mostRecentLoginsData as any,
          },
          {
            title: 'Most Times Since Last Login',
            tableColumns: null,
            data: null,
          },
          {
            tableColumns: lastLoginColumns,
            data: mostSinceLastLoginData as any,
          },
        ].map((item, index) => {
          return (
            <table key={`${index}_t`}>
              <thead key={`${index}_th`}>
                <tr key={`${index}_trh`}>
                  {item?.title && <th>{item.title}</th>}
                  {item?.tableColumns?.find(col => col.id === 'member') &&
                    ['Member_fullName', 'Member_Name', 'Member_Id'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns?.find(col => col.id === 'provider') &&
                    ['Provider_Name', 'provider_Role'].map(colItem => (
                      <th key={`${colItem}_thr`}>{colItem}</th>
                    ))}
                  {item?.tableColumns
                    ?.filter(col => !(col.id === 'member' || col.id === 'provider'))
                    .map((column, indx) => {
                      return <th key={`${indx}_thr`}>{column.label}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {item?.data?.map((row, ind) => {
                  return (
                    <tr key={`${ind}_tr`}>
                      {item.tableColumns.find(col => col.id === 'member') &&
                        ['fullname', 'name', 'memberId'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.member[colItem]}</td>
                        ))}
                      {item.tableColumns.find(col => col.id === 'provider') &&
                        ['name', 'role'].map(colItem => (
                          <td key={`${colItem}_thr`}>{row.provider[colItem]}</td>
                        ))}
                      {item.tableColumns
                        .filter(col => !(col.id === 'member' || col.id === 'provider'))
                        .map((column, i) => {
                          return <td key={`${i}_tir`}>{row[column.id]}</td>;
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {renderAllTables()}
      <ViewAllDrawer isOpen={!!showViewAllType} onClose={onCloseViewAll} {...getContentsInViewAll()} />
      <Box className={classes.card}>
        <HeadCard title="Logins" componentRef={componentRef} onDownloadClick={onDownload} />
        <Box className={classes.card} ref={componentRef}>
          <Box className={classes.reports}>
            <Grid container spacing={2}>
              {boxData.map(box => (
                <Grid item xs={4} key={box.label}>
                  <DashboardBox className={classes.reportItem} {...box} type={boxType.TOTAL} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {loginSection.updatedInLast30Days?.isChecked && (
            <Box className={classes.chartWrap}>
              <AssignmentsChart
                title="Logins last 30 days"
                className={classes.chart}
                chart={{
                  series: [
                    { name: 'Current 30 days', color: colors.primary500, data: current30DaysCounts },
                    { name: 'Past 30 days', color: colors.neutral600, data: past30DaysCounts },
                  ],
                  tooltipConfig: {
                    isShowTotal: false,
                    title: 'Total',
                  },
                }}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={6}>
            {loginSection.mostLogins?.isChecked &&
              renderTable(
                'Most logins',
                mostLoginsData,
                loginColumns,
                onClickAll('mostLogins'),
                renderMenu(),
                !loginSection.fewestLogins?.isChecked ? classes.halfTable : '',
              )}
            {loginSection.fewestLogins?.isChecked &&
              renderTable(
                'Fewest logins',
                fewestLoginsData,
                loginColumns,
                onClickAll('fewestLogins'),
                renderMenu(),
                !loginSection.mostLogins?.isChecked ? classes.halfTable : '',
              )}
          </Box>
          <Box display="flex" justifyContent="space-between" gap={6}>
            {loginSection.mostRecentLogins?.isChecked &&
              renderTable(
                'Most recent logins',
                mostRecentLoginsData,
                loginColumns,
                onClickAll('mostRecentLogins'),
                renderMenu(),
                !loginSection.mostSinceLastLogin?.isChecked ? classes.halfTable : '',
              )}
            {loginSection.mostSinceLastLogin?.isChecked &&
              renderTable(
                'Most time since last login',
                mostSinceLastLoginData,
                lastLoginColumns,
                onClickAll('mostSinceLastLogin'),
                renderMenu(),
                !loginSection.mostRecentLogins?.isChecked ? classes.halfTable : '',
              )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { Logins };
