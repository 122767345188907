import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import weekdayPlugin from 'dayjs/plugin/weekday';

import { Formik, Field, FieldArray } from 'formik';

import { Button, btnSize, btnType } from '@confidant-health/lib/ui/atoms/button';
import { IconButton, iconBtnPosition } from '@confidant-health/lib/ui/molecules/icon-button';
import { TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { scheduleActionCreators } from 'redux/modules/schedule';
import { selectProviderSchedule, selectProviderScheduleLoading } from 'redux/modules/schedule/selectors';
import { getAuth } from 'redux/modules/auth/selectors';

import history from 'utils/history';

import { WorkingDays } from 'constants/CommonConstants';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import Header from 'components/v2/Header';
import { US_TIMEZONES } from 'pages/provider/profile/Profile.constants';
import { AvailabilitySchema } from 'pages/provider/profile/components/general-availability/GeneralAvailability.schema';

import ScheduleLink from 'pages/provider/schedule/components/schedule-link/ScheduleLink';
import { TimeSlot } from './components/time-slot/TimeSlot';

import { useStyles } from './GeneralAvailability.styles';

dayjs.extend(weekdayPlugin);

type Props = {
  providerId: string;
};

const GeneralAvailability = ({ providerId }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const providerSchedule = useSelector(selectProviderSchedule);
  const providerScheduleLoading = useSelector(selectProviderScheduleLoading);
  const { isAuthenticated, meta: loggedInMeta } = useSelector(getAuth);
  const navigate = useNavigate();

  const [selectedTimeZone] = useState(providerSchedule?.timezone || US_TIMEZONES[0].value);
  const [editAvailable, setEditAvailable] = useState({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  });

  useEffect(() => {
    if (isAuthenticated) {
      if (loggedInMeta.userId === providerId) {
        dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: providerId }));
      } else {
        navigate('/');
      }
    }
  }, []);

  const filteredSchedule = useMemo(
    () => providerSchedule?.availability.filter(item => item.active === true),
    [providerSchedule],
  );

  const workingDays = useMemo(
    () =>
      filteredSchedule?.reduce((prev, item) => {
        const convertedSlots = item.slots.map(v => {
          return {
            start: dayjs(v.start).tz(selectedTimeZone).format('HH:mm'),
            end: dayjs(v.end).tz(selectedTimeZone).format('HH:mm'),
          };
        });
        return {
          ...prev,
          [item.day.toLowerCase()]: prev[item.day.toLowerCase()]
            ? [...prev[item.day.toLowerCase()], ...convertedSlots]
            : convertedSlots,
        };
      }, {}),
    [providerSchedule, filteredSchedule],
  );

  const onSubmit = values => {
    const availability = WorkingDays.map(day => ({
      day,
      slots: values[day]
        ? values[day].map(slot => ({
            start: slot.start,
            end: slot.end,
          }))
        : [],
      active: values[day]?.length > 0,
      removed: values[day] ? values[day].length === 0 : true,
    }));

    dispatch(
      scheduleActionCreators.updateProviderSchedule({
        providerId,
        data: {
          timezone: selectedTimeZone,
          availability,
        },
      }),
    );
  };

  const getNextDay = (day, week) => {
    const startOfWeek = dayjs().startOf('week');
    switch (day) {
      case 'sunday':
        return startOfWeek.add(week, 'week').weekday(7);
      case 'monday':
        return startOfWeek.add(week, 'week').weekday(1);
      case 'tuesday':
        return startOfWeek.add(week, 'week').weekday(2);
      case 'wednesday':
        return startOfWeek.add(week, 'week').weekday(3);
      case 'thursday':
        return startOfWeek.add(week, 'week').weekday(4);
      case 'friday':
        return startOfWeek.add(week, 'week').weekday(5);
      case 'saturday':
        return startOfWeek.add(week, 'week').weekday(6);

      default:
        return startOfWeek.add(week, 'week').weekday(7);
    }
  };

  if (providerScheduleLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
      </Box>
    );
  }

  return (
    <>
      <Header label="Availability" />
      <Box className={classes.root}>
        <ScheduleLink />
        <Formik
          initialValues={
            workingDays !== undefined
              ? workingDays
              : {
                  monday: null,
                  tuesday: null,
                  wednesday: null,
                  thursday: null,
                  friday: null,
                  saturday: null,
                  sunday: null,
                }
          }
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={AvailabilitySchema}
        >
          {({ isValid, values, handleSubmit, errors }) => {
            return (
              <Box className={classes.content}>
                {WorkingDays.map(day => (
                  <Box key={`day-${day}`} className={classes.dayCard}>
                    <Box className={classes.dayCardHeader}>
                      <Box className={classes.weekDayTitle}>{day}</Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Icons
                          glyph={values?.[day]?.length ? 'checkmark' : 'close-circle-outlined'}
                          color={values?.[day]?.length ? colors.success600 : colors.destructive500}
                        />
                        <Typography {...typography.body.s.medium} color={colors.neutral700}>
                          {values?.[day]?.length ? 'Available' : 'Not available'}
                        </Typography>
                      </Box>
                    </Box>
                    <FieldArray
                      name={day}
                      render={arrayHelpers => (
                        <Box className={classes.dayCardContent}>
                          {!editAvailable?.[day] ? (
                            <Box className={classes.dayCardLeft}>
                              {!!values?.[day] || values?.[day]?.length === 0 ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  {values[day]?.map((times, index) => {
                                    const timeFormatOptions = {
                                      hour12: true,
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    };

                                    const startTime = times?.start?.split(':');
                                    const endTime = times?.end?.split(':');
                                    const start =
                                      startTime?.length > 0
                                        ? new Date(
                                            0,
                                            0,
                                            0,
                                            startTime?.[0],
                                            startTime?.[1],
                                          )?.toLocaleTimeString('en-US', timeFormatOptions as any)
                                        : null;
                                    const end =
                                      endTime?.length > 0
                                        ? new Date(0, 0, 0, endTime?.[0], endTime?.[1])?.toLocaleTimeString(
                                            'en-US',
                                            timeFormatOptions as any,
                                          )
                                        : null;
                                    if (start && !!end) {
                                      return (
                                        <Box key={index}>
                                          {start} ~ {end}
                                        </Box>
                                      );
                                    }

                                    return <></>;
                                  })}
                                </Box>
                              ) : (
                                <>
                                  <span className="emptyLabel">
                                    You are not available on <span className="emptyDayLabel">{day}</span>s.
                                  </span>
                                  <span className="emptyContent">
                                    {/* Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint
                                    non deserunt. */}
                                  </span>
                                </>
                              )}
                              <Button
                                variant={btnType.TEXT}
                                size={btnSize.SMALL}
                                className="editBtn"
                                onClick={() =>
                                  setEditAvailable(prevState => ({
                                    ...prevState,
                                    [day]: true,
                                  }))
                                }
                              >
                                Edit availability
                              </Button>
                            </Box>
                          ) : (
                            <Box className={classes.dayCardLeftSecondary}>
                              <TextError
                                errorMsg={typeof errors[day] === 'string' ? String(errors[day]) : ''}
                              />
                              {values[day]?.map((_, index) => (
                                <Field key={`slot-${index}`}>
                                  {({ field, form, meta }) => (
                                    <TimeSlot
                                      fieldName={`${day}.${index}`}
                                      onRemove={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                      field={field}
                                      form={form}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                              ))}
                              <IconButton
                                variant={btnType.OUTLINE}
                                className={classes.addTimeSlot}
                                icon="plus"
                                onClick={() => {
                                  const lastTimeSlot = values?.[day]
                                    ? values[day][values[day].length - 1]
                                    : { start: '00:00', end: '00:00' };
                                  arrayHelpers.insert(
                                    values?.[day]?.length || 0,
                                    lastTimeSlot
                                      ? {
                                          start: lastTimeSlot.end,
                                          end: lastTimeSlot.end,
                                        }
                                      : {
                                          start: '00:00',
                                          end: '00:00',
                                        },
                                  );
                                }}
                              >
                                Add time slot
                              </IconButton>
                              <Button
                                className={classes.saveAvailability}
                                onClick={
                                  isValid
                                    ? e => {
                                        handleSubmit(e);
                                        setEditAvailable(prevState => ({
                                          ...prevState,
                                          [day]: false,
                                        }));
                                      }
                                    : null
                                }
                              >
                                Save availability
                              </Button>
                            </Box>
                          )}

                          <Box className={classes.dayCardRight}>
                            <Box className={classes.dayCardRightHead}>Upcoming {day}s</Box>
                            <Box className={classes.dayCardRightContent}>
                              <IconButton
                                iconPosition={iconBtnPosition.RIGHT}
                                icon="arrow-right"
                                className={classes.icon}
                                onClick={() =>
                                  history.push(
                                    `/provider/${providerId}/day-availability?date=${getNextDay(
                                      day,
                                      0,
                                    ).format('YYYY-MM-DD')}`,
                                  )
                                }
                              >
                                {getNextDay(day, 0).format('dddd, MMMM D')}
                              </IconButton>
                              <IconButton
                                iconPosition={iconBtnPosition.RIGHT}
                                icon="arrow-right"
                                className={classes.icon}
                                onClick={() =>
                                  history.push(
                                    `/provider/${providerId}/day-availability?date=${getNextDay(
                                      day,
                                      1,
                                    ).format('YYYY-MM-DD')}`,
                                  )
                                }
                              >
                                {getNextDay(day, 1).format('dddd, MMMM D')}
                              </IconButton>
                              <IconButton
                                iconPosition={iconBtnPosition.RIGHT}
                                icon="arrow-right"
                                className={classes.icon}
                                onClick={() =>
                                  history.push(
                                    `/provider/${providerId}/day-availability?date=${getNextDay(
                                      day,
                                      2,
                                    ).format('YYYY-MM-DD')}`,
                                  )
                                }
                              >
                                {getNextDay(day, 2).format('dddd, MMMM D')}
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    />
                  </Box>
                ))}
              </Box>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default GeneralAvailability;
