import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  heading: {
    fontFamily: 'Source Serif Pro',
    margin: 0,
  },
});
