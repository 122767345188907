// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    padding: 16,
    display: 'flex',
    alignItems: 'start',
    borderBottom: `1px solid ${colors.neutral100}`,
    flex: 1,
  },
  leftPane: {
    display: 'flex',
  },
  rightPane: {
    display: 'flex',
    marginRight: '8px',
  },
  divider: {
    width: '1px',
    backgroundColor: colors.inputBorder,
  },
  btnBack: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary300,
    width: 48,
    height: 48,
    padding: 0,
    minWidth: 0,
    marginRight: 16,
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  info: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    margin: 0,
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    color: colors.neutral600,
    marginTop: 5,
  },
  btnOpenChat: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  shareBtn: {
    minWidth: 40,
    maxWidth: 40,
  },
});
