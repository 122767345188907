import * as yup from 'yup';

export const AddProcedureCodesSchema = yup.object({
  cptList: yup.array().of(
    yup.object().shape({
      cptCode: yup.string().required('Procedure code is required'),
      diagnosis: yup.string().required('Related Diagnosis is required'),
      icd10Code: yup.string().required('ICD-10 Code is required'),
      notes: yup.string(),
      // useDefaultTime: yup.boolean(),
    }),
  ),
});
