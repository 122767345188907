import { FC, useState, useEffect } from 'react';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Box, Stack } from '@mui/material';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Table } from '@confidant-health/lib/ui/organisms/table';
import { useDispatch, useSelector } from 'react-redux';
import { BaseLayout } from 'layouts/base';
import { selectLoeReport } from 'redux/modules/schedule/selectors';
import { scheduleActionCreators } from '../../../redux/modules/schedule';
import { useStyles } from './LevelOfEngagement.styles';
import DonutChart from './components/donut-chart';
import { LoeAssignmentsDrawer } from './components/loeAssignmentDrawer/LoeAssignmentsDrawer';

const LevelOfEngagement: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { levelOfEngagementStats } = useSelector(selectLoeReport);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState({});

  useEffect(() => {
    dispatch(scheduleActionCreators.fetchLevelOfEngagements());
  }, []);

  const statusDetail = item => {
    setDrawerData(item);
    setOpenDrawer(true);
  };

  const sortMap = {
    'New member': 1,
    Referral: 2,
    Active: 3,
    'Referral Active': 4,
    'Signup incomplete': 5,
    Invited: 6,
    'Invite pending': 7,
    'Successful Discharge': 8,
    'Self Discharge': 9,
    'Discharged to HLOC': 10,
    'Lost to Care': 11,
    'Not admitted to care': 12,
    DIY: 13,
    Ghost: 14,
    'No Status': 15,
  };

  const sorted = levelOfEngagementStats.levelOfEngagementStatsCount.sort((a, b) => {
    return sortMap[a.levelOfEngagementStatus] - sortMap[b.levelOfEngagementStatus];
  });

  const loeData = sorted.map(item => {
    return {
      name: item.levelOfEngagementStatus,
      members: `${item.memberCount} members`,
    };
  });

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <BaseLayout>
      <Box>
        <Stack direction="row" alignItems="center" gap={2}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Level of Engagement
          </Heading>
          <Badge
            variant={badgeType.OUTLINED}
            style={badgeStyle.UNRELATED}
            className={classes.totalMemberBadge}
          >
            {levelOfEngagementStats.totalUniqueStatuses} total
          </Badge>
        </Stack>
        <Box>
          <DonutChart sorted={sorted} />
        </Box>
        <Box className={classes.tableBox}>
          <Table
            className={classes.table}
            gridProps={{
              columns: [
                { id: 'name', label: 'Name', sortable: false },
                { id: 'members', label: 'Members', sortable: false },
              ],
              onRowClick: statusDetail,
              data: loeData,
              isLoading: false,
            }}
          />
        </Box>
        <LoeAssignmentsDrawer open={openDrawer} onClose={handleDrawerClose} data={drawerData} />
      </Box>
    </BaseLayout>
  );
};

export { LevelOfEngagement };
