import { AppointmentStatus } from '@confidant-health/lib/constants/CommonConstants';
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';

export enum AppointmentTitles {
  'appointments-pending' = 'Pending appointments',
  'appointments-past' = 'Past appointments',
  'appointments-current' = 'Current appointments',
}

export const defaultAppointmentFilterOptions = [
  {
    sectionTitle: 'State',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Texas', value: 'Texas' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'New Hampshire', value: 'New Hampshire' },
    ],
  },
  {
    sectionTitle: 'VBC',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [{ label: 'VBC flag', value: 'VBC flag' }],
  },
];
export const currentAppointmentsFilterOption = [
  {
    sectionTitle: 'Status',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'Today', value: 'Today' },
      { label: 'Scheduled', value: 'Scheduled' },
    ],
  },
  ...defaultAppointmentFilterOptions,
];

export const pastAppointmentsFilterOption = [
  {
    sectionTitle: 'Status',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Cancelled', value: AppointmentStatus.CANCELLED },
      { label: 'Completed', value: AppointmentStatus.FULFILLED },
      { label: 'No Show', value: AppointmentStatus.NO_SHOW },
    ],
  },
  ...defaultAppointmentFilterOptions,
];
