import { FC } from 'react';
// components
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';

import { useSelector } from 'react-redux';
import { getProfile } from 'redux/modules/profile/selectors';
import { IDemographicDetails } from 'redux/modules/profile/types';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';

// styles
import { Box, Divider } from '@mui/material';
import { useStyles } from '../../MemberDetail.styles';

type Props = {
  btnLabel?: string;
  onClickBtn?: () => void;
};
const IdCard: FC<Props> = ({ btnLabel, onClickBtn }) => {
  const classes = useStyles({});
  const { demographicDetails = {} as IDemographicDetails } = useSelector(getProfile);
  const { idCardFront, idCardBack } = demographicDetails;

  return (
    <div className={classes.card}>
      <Divider />
      <div className={classes.cardTopSection}>
        <Heading level={headingLevel.S} weight={fontWeight.BOLD}>
          Driver’s license or photo ID card
        </Heading>
        {onClickBtn ? (
          <Button className={classes.noPadding} variant={btnType.TEXT} onClick={onClickBtn}>
            {btnLabel}
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div>
        <div style={{ padding: '24px', display: 'flex', gap: '122px' }}>
          <label htmlFor="frontside">
            <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.insuranceItemHeadSub}>
              Front Side
            </Text>
          </label>
          {idCardFront ? (
            <img style={{ width: '233px', height: '141px' }} src={idCardFront} alt="id-front" />
          ) : (
            <Box className={classes.noImage}>
              <Text level={textLevel.S}>Not available</Text>
            </Box>
          )}
        </div>
        <Divider />
        <div style={{ padding: '24px', display: 'flex', gap: '122px' }}>
          <label htmlFor="backside">
            <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD} className={classes.insuranceItemHeadSub}>
              Back Side
            </Text>
          </label>
          {idCardBack ? (
            <img style={{ width: '233px', height: '141px' }} src={idCardBack} alt="id-back" />
          ) : (
            <Box className={classes.noImage}>
              <Text level={textLevel.S}>Not available</Text>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default IdCard;
