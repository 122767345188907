import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.neutral50,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  loginBackground: {
    width: '75%',
    height: '60%',
    objectFit: 'contain',
  },
  loginHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // marginBottom: 10,
  },
  headerText: {
    fontFamily: 'Source Serif Pro',
  },
  rightContainer: {
    flex: 1,
  },
  form: { width: '100%', marginBottom: 32 },
  loginContainer: {
    padding: '40px 100px',
    flex: 1,
    height: '100%',
    width: '100%',
  },
  loginContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
    width: '100%',
    paddingTop: '10%',
  },
  outlineBtn: {
    height: 'unset',
    padding: '10px 16px',
    fontSize: 14,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  input: {
    width: 448,
    height: 64,
    paddingLeft: 16,
  },
  eye: {
    cursor: 'pointer',
  },
  errorText: {
    color: colors.textError,
    marginBottom: 0,
  },
  buttonTextContainer: {
    width: '100%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 40,
    display: 'flex',
  },
  sideBarToggler: {
    height: 40,
    minWidth: 40,
    maxWidth: 40,
    borderRadius: 99,
    padding: '0px !important',
    border: `1px solid ${colors.neutral300}`,
    color: colors.neutral700,
  },
  togglerIcon: {
    width: 24,
    height: 24,
  },
  sideBarActiveBtn: {
    minWidth: '40px !important',
    width: 40,
    height: 40,
    padding: '0px !important',
    '& > svg , & > * > svg': { padding: 0 },
  },
  sideBarBackBtn: {
    minWidth: '40px !important',
    width: 40,
    height: 40,
    position: 'absolute',
    top: 40,
    left: 40,
    padding: '0px !important',
    '& > svg , & > * > svg': { padding: 0 },
  },
  errorMsg: {
    display: 'flex',
    gap: 18,
    background: colors.destructive50,
    borderRadius: 8,
    padding: 16,
  },
  buttonContainer: {
    width: '100%',
    height: 64,
    '&.Mui-disabled': {
      background: colors.primary50,
      color: colors.primary300,
    },
  },
});
