import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { HistoryLookup } from 'redux/modules/profile/types';
import { ISubstanceUse } from '../AddNewReport.types';
import { useStyles } from './StepThree.styles';

type Props = {
  selectedItems: ISubstanceUse[];
  lookupData?: HistoryLookup;
};

const StepThreeSubStance: FC<Props> = ({ selectedItems, lookupData }) => {
  const classes = useStyles();

  const renderBlock = (label: string, value: string) => {
    return (
      <Box>
        <Text className={classes.label}>{label}</Text>
        <Text className={clsx(classes.label, classes.value)}>{value}</Text>
      </Box>
    );
  };

  const getValue = (key: string, opts: { name: string; value: string }[] = []): string => {
    const opt = opts.find(dx => dx.name === key);
    return opt?.value || key;
  };

  const renderItem = (item: ISubstanceUse, index: number) => {
    return (
      <Box className={classes.item} key={index}>
        <Box className={classes.heading}>
          <Text className={classes.headTitle}>{item.item.label}</Text>
        </Box>
        <Box className={classes.content}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Badge
              className={clsx(classes.badge, {
                [classes.badgeMisreported]: item.status === 'Misreported',
              })}
              variant={badgeType.FILLED}
              style={badgeStyle[item.status.toUpperCase()]}
            >
              {item.status}
            </Badge>
            <Box display="flex" gap="10px" alignItems="center">
              <Icons
                glyph="interference"
                color={item.isInterference ? colors.secondary500 : colors.neutral500}
              />
              <Text
                className={clsx(classes.interference, { [classes.interferenceActive]: item.isInterference })}
              >
                Interference with life
              </Text>
            </Box>
          </Box>
          <Box sx={{ paddingTop: 3 }}>
            <Text className={clsx(classes.time, classes.notes)}>{item.notes}</Text>
            <Box display="flex" justifyContent="space-between" alignItems="center" flex={1}>
              {renderBlock('Method', getValue(item.tutorial.method, lookupData.methodsOfSubstanceUse))}
              {renderBlock('Amount', getValue(item.tutorial.dose.unit, lookupData.unitsOfSubstanceUse))}
              {renderBlock(
                'Frequency',
                getValue(item.tutorial.frequency, lookupData.currentFrequencyOfSubstanceUse),
              )}
              {renderBlock(
                'Duration',
                getValue(item.tutorial.duration, lookupData.continuousLevelOfSubstanceUse),
              )}
              {renderBlock('Last use', getValue(item.tutorial.lastUse, lookupData.lastSubstanceUse))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return <Box className={classes.container}>{!!lookupData && selectedItems.map(renderItem)}</Box>;
};

export default StepThreeSubStance;
