import { FC, useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Icons } from '@confidant-health/lib/icons';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { BUILDER_CONSTANTS } from 'constants/CommonConstants';

import { IProvider } from 'redux/modules/profile/types';
import * as AppointmentService from 'services/appointment/appointment.service';
import { getServicesOnServiceName, getServicesOnServiceType } from 'services/member/member.service';
import { IPlanItem } from 'redux/modules/conversation/types';

import { PLAN_ITEMS, typeSelects } from '../../../plan-items/components/add-plan-item/AddPlanItem.constants';

import { AddAutomationEventSchema } from './AutomationEvent.schema';
import { useStyles } from './AutomationEvent.styles';
import { Option } from '../AddAutomation.types';

const APPOINTMEMT_EVENT_SUB_TYPES = {
  SERVICE_NAME: 'SERVICE_NAME',
  SERVICE_TYPE: 'SERVICE_TYPE',
  PROVIDER_SERVICE: 'PROVIDER_SERVICE',
};
const defaultValues = {
  name: '',
  reference: '',
  ruleName: '',
  providers: {},
  iterateAllVersions: false,
  allAppointmentEventRule: false,
  providerFilter: [],
  references: [],
  selectedType: '',
  generalServiceFilter: [],
  appointmentEventSubType: '',
};

type Props = {
  isEditMode?: boolean;
  onClose: () => void;
  onContinue: (payload: any) => void;
  eventdata?: any;
  conversations: Option[];
  dcts: Option[];
  evaluations: Option[];
  groups: Option[];
  educations: Option[];
  profileElements: Option[];
  providers: IProvider[];
  providerRoles: Option[];
  planItems: IPlanItem[];
  actiondata?: any;
};

const isAppointment = value => {
  if (
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_REQUESTED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_SCHEDULED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_COMPLETED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_CANCELLED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.APPOINTMENT_NO_SHOW
  ) {
    return true;
  }
  return false;
};
const isConversation = value => {
  if (
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_COMPLETED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_ASSIGNED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.DCT_COMPLETED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EVALUATION_COMPLETED
  ) {
    return true;
  }
  return false;
};

const isPlan = value => {
  if (
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PLAN_ITEM_ASSIGNED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PLAN_ITEM_COMPLETED ||
    value === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PLAN_ITEM_STARTED
  ) {
    return true;
  }
  return false;
};

const AutomationEvent: FC<Props> = ({
  isEditMode,
  eventdata,
  onClose,
  onContinue,
  conversations,
  dcts,
  evaluations,
  groups,
  educations,
  profileElements,
  providers,
  providerRoles,
  planItems,
}) => {
  const classes = useStyles();
  const [eventReferenceList, setEventReferenceList] = useState([]);
  const [providerServicesLoading, setProviderServicesLoading] = useState(false);
  const [isSelectAllProviders, setIsSelectAllProviders] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedReference, setSelectedReference] = useState(null);
  const [actionList, setActionList] = useState([]);
  const [services, setServices] = useState([]);
  const { errors, values, handleChange, handleSubmit, touched, ...rest } = useFormik({
    initialValues: isEditMode ? { ...eventdata } : { ...defaultValues },
    enableReinitialize: true,
    validationSchema: AddAutomationEventSchema,
    onSubmit: eventRequest => {
      const { providers: selectProvider, ...requests } = eventRequest || {};
      if (isSelectAllProviders) {
        requests.providerFilter = providers.map(provider => provider.providerId);
      } else {
        requests.providerFilter = requests.providerFilter?.map(item => item?.value ?? item) ?? [];
        if (requests.appointmentEventSubType === APPOINTMEMT_EVENT_SUB_TYPES.PROVIDER_SERVICE) {
          const providerFilterValues = eventRequest.providers
            ? [eventRequest.providers?.providerId]
            : [eventRequest.referenceParent];
          if (providerFilterValues?.filter(item => item)?.length > 0) {
            requests.providerFilter = providerFilterValues;
          }
        }
      }
      if (requests.generalServiceFilter?.length > 0) {
        requests.generalServiceFilter = requests.generalServiceFilter?.map(item => item.value) ?? [];
      }
      if (isAppointment(values?.name)) {
        onContinue({ ...requests, referenceParent: selectProvider?.id });
      } else {
        onContinue(requests);
      }
      if (isPlan(values?.name)) {
        onContinue({ ...requests, referenceParent: values.selectedType.value });
      }
    },
  });
  const appointmentEventSubTypeOptions = useMemo(
    () => [
      {
        label: 'Service Name',
        value: APPOINTMEMT_EVENT_SUB_TYPES.SERVICE_NAME,
      },
      {
        label: 'Service Type',
        value: APPOINTMEMT_EVENT_SUB_TYPES.SERVICE_TYPE,
      },
      {
        label: 'Provider Service',
        value: APPOINTMEMT_EVENT_SUB_TYPES.PROVIDER_SERVICE,
      },
    ],
    [],
  );

  const servicesOptions = useMemo(
    () => services?.map(service => ({ label: service, value: service })),
    [services],
  );
  useEffect(() => {
    setProviderServicesLoading(true);
    if (values?.appointmentEventSubType === APPOINTMEMT_EVENT_SUB_TYPES.SERVICE_NAME) {
      getServicesOnServiceName({
        providerIdList:
          values?.providerFilter?.map(item => item?.providerId)?.filter(item => item)?.length > 0
            ? values?.providerFilter?.map(item => item?.providerId)
            : [],
      })
        .then(res => {
          setServices(res.data?.serviceNameList);
          setProviderServicesLoading(false);
        })
        .catch(() => {
          setProviderServicesLoading(false);
        });
    } else if (values?.appointmentEventSubType === APPOINTMEMT_EVENT_SUB_TYPES.SERVICE_TYPE) {
      getServicesOnServiceType({
        providerIdList:
          values?.providerFilter?.map(item => item?.providerId)?.filter(item => item)?.length > 0
            ? values?.providerFilter?.map(item => item?.providerId)
            : [],
      })
        .then(res => {
          setServices(res.data?.serviceTypeList);
          setProviderServicesLoading(false);
        })
        .catch(() => {
          setProviderServicesLoading(false);
        });
    } else setProviderServicesLoading(false);
  }, [values.providerFilter, values.appointmentEventSubType]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const getEventList = val => {
    let conversationListTemp = [];

    if (
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_COMPLETED ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONVERSATION_ASSIGNED
    ) {
      conversationListTemp = conversations;
    } else if (val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.DCT_COMPLETED) {
      conversationListTemp = dcts;
    } else if (val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EVALUATION_COMPLETED) {
      conversationListTemp = evaluations;
    } else if (val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PROFILE_ELEMENT_UPDATED) {
      conversationListTemp = profileElements;
    } else if (
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.GROUP_JOINED ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.GROUP_LEFT ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.GROUP_SESSION_JOINED ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.GROUP_CONTRIBUTION_MADE
    ) {
      conversationListTemp = groups;
    } else if (
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EDUCATION_MARKED_READ ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EDUCATION_VIEWED ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EDUCATION_SHARED ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.EDUCATION_ADDED_TO_FAVOURITES
    ) {
      conversationListTemp = educations;
    } else if (
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PAYMENT_TYPE_SELECTED ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.PAYMENT_MADE ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONTRIBUTION_SET ||
      val === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.CONTRIBUTION_CANCELLED
    ) {
      conversationListTemp = providerRoles;
    }
    return conversationListTemp?.sort((a, b) => (a?.label > b?.label ? 1 : -1));
  };

  const selectEvent = async (event?: any) => {
    const val = event && event?.target?.value !== '' ? event.target.value : isEditMode ? eventdata.name : '';
    await rest.setFieldValue('name', val);
    await rest.setFieldValue('reference', '');
    await rest.setFieldValue('providers', '');
    setSelectedProvider(null);
    setSelectedReference(null);
    setEventReferenceList([]);
    const conversationListTemp = getEventList(val);
    const isAppointmentEvent = isAppointment(eventdata?.name);
    if (!isAppointmentEvent) {
      if (!event?.target?.value && eventdata?.name && isEditMode) {
        setSelectedReference(conversationListTemp?.find(ev => ev.value === eventdata.reference));
      }
      setEventReferenceList(conversationListTemp);
    }
  };

  const onSelectProviderFilter = async (_, val) => {
    void rest.setFieldValue('generalServiceFilter', []);
    if (val?.length > 0 && val.find(item => item.value === 'all')) {
      setIsSelectAllProviders(true);
      await rest.setFieldValue('providerFilter', [{ label: 'All Providers', value: 'all' }]);
    } else await rest.setFieldValue('providerFilter', val);
  };

  const onSelectProvider = async (_, val) => {
    void rest.setFieldValue('generalServiceFilter', []);
    await rest.setFieldValue('providers', val);
    await rest.setFieldValue('reference', '');
    setSelectedReference(null);
    if (!val) {
      await rest.setFieldValue('providers', {});
      return;
    }
    setSelectedProvider(val);
    setProviderServicesLoading(true);
    const { data = [] } = await AppointmentService.getProviderServices({ providerId: val?.id });
    setProviderServicesLoading(false);
    setEventReferenceList(data?.map(d => ({ label: d.name, value: d.id })));
  };

  const onSelectService = async (_, val) => {
    if (!val) {
      await rest.setFieldValue('generalServiceFilter', []);
      return;
    }
    await rest.setFieldValue('generalServiceFilter', val);
  };

  const getPlanItems = typeValue => {
    return planItems.filter(p => p.type === typeValue);
  };

  const onSelectScheduledType = async (e?: any) => {
    const val = e?.target?.value ? e?.target?.value : '';

    await rest.setFieldValue(
      'selectedType',
      typeSelects.find(type => type.value === val),
    );
    await rest.setFieldValue('references', []);
    const selectedPlanItems = getPlanItems(val);
    setActionList(selectedPlanItems?.map(sp => ({ label: sp.name, value: sp.id })));
  };
  const initPlanItemTypesAndRefrences = () => {
    if (!isEditMode && (isPlan(eventdata.name) || isPlan(values.name))) {
      const plnItems = getPlanItems(PLAN_ITEMS.PLAN_ITEM_TYPE.EDUCATION);
      setActionList(plnItems?.map(sp => ({ label: sp.name, value: sp.id })));
    }
  };
  const initEvents = async () => {
    await selectEvent();
    if (
      isAppointment(eventdata.name) &&
      (eventdata.referenceParent || eventdata.providerFilter?.[0]) &&
      isEditMode
    ) {
      setSelectedProvider(
        providers?.find(p => p.id === eventdata.referenceParent || p.id === eventdata.providerFilter?.[0]),
      );
      setProviderServicesLoading(true);
      const { data = [] } = await AppointmentService.getProviderServices({
        providerId: eventdata.referenceParent || eventdata.providerFilter?.[0],
      });
      setProviderServicesLoading(false);
      const lists = data
        ?.map(d => ({ label: d.name, value: d.id }))
        ?.sort((a, b) => (a?.label > b?.label ? 1 : -1));
      setEventReferenceList(lists);
      setSelectedReference(lists.find(l => l.value === eventdata?.reference));
    }
    if (isAppointment(eventdata.name) && isEditMode) {
      await rest.setFieldValue(
        'providerFilter',
        eventdata?.providerFilter
          ?.filter(itm => itm)
          ?.map(p => {
            const foundProvider = providers.find(prov => prov.id === p);
            if (foundProvider) {
              return { label: foundProvider.fullName, value: foundProvider.providerId };
            }
            return { label: '', value: p };
          }),
      );
      if (eventdata?.generalServiceFilter?.[0]?.label) {
        await rest.setFieldValue('generalServiceFilter', eventdata?.generalServiceFilter);
      } else {
        const servcNames = eventdata?.eventMetaData?.serviceName?.split(',');
        if (servcNames?.length > 0) {
          await rest.setFieldValue(
            'generalServiceFilter',
            eventdata?.generalServiceFilter?.map((p, i) => ({ label: servcNames?.[i], value: p })),
          );
        } else {
          await rest.setFieldValue(
            'generalServiceFilter',
            eventdata?.generalServiceFilter?.map(p => ({ label: p, value: p })),
          );
        }
      }
      await rest.setFieldValue(
        'providers',
        eventdata?.providerFilter?.map(p => {
          const foundProvider = providers.find(prov => prov.id === p);
          if (foundProvider) {
            return { label: foundProvider.fullName, value: foundProvider.providerId };
          }
          return { label: '', value: p };
        }),
      );
      await rest.setFieldValue('appointmentEventSubType', eventdata?.appointmentEventSubType);
      await rest.setFieldValue('allAppointmentEventRule', eventdata?.allAppointmentEventRule);
    }
    if (isPlan(eventdata.name) && isEditMode) {
      const planItemType = typeSelects.find(type => type.value === eventdata?.referenceParent);
      await rest.setFieldValue('selectedType', planItemType);

      // set available options in select dropdown
      const plnItems = getPlanItems(planItemType?.label ?? PLAN_ITEMS.PLAN_ITEM_TYPE.EDUCATION);
      setActionList(plnItems?.map(sp => ({ label: sp.name, value: sp.id })));
      await rest.setFieldValue(
        'references',
        // matches IDs of plan items with IDs received from Payload and returns matching objects and then maps only their IDs and labels as required in Options prop of <Select component
        plnItems
          .filter(UserSelectedPlanItems => eventdata?.references.includes(UserSelectedPlanItems.id))
          .map(sp => ({ label: sp.name, value: sp.id })),
      );
    }
  };
  useEffect(() => {
    if (eventdata.name.trim() !== '') {
      void initEvents();
    }
  }, []);

  useEffect(() => {
    void initPlanItemTypesAndRefrences();
  }, [values.name]);

  const onSelectReference = async (_, val) => {
    if (!val) return;
    await rest.setFieldValue('reference', val?.value);
    setSelectedReference(val);
  };

  const onSelectReferences = async (_?: any, keyValue?: any) => {
    if (keyValue?.length > 0 && keyValue.find(item => item.value === 'all')) {
      setIsSelectAllProviders(true);
      await rest.setFieldValue('references', [{ label: 'All Providers', value: 'all' }]);
    } else await rest.setFieldValue('references', keyValue);
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );
  const showReferenceField = () => {
    if (isAppointment(values.name)) return false;
    if (
      !values.allAppointmentEventRule &&
      values.appointmentEventSubType !== APPOINTMEMT_EVENT_SUB_TYPES.SERVICE_NAME &&
      values.appointmentEventSubType !== APPOINTMEMT_EVENT_SUB_TYPES.SERVICE_TYPE &&
      values.appointmentEventSubType !== APPOINTMEMT_EVENT_SUB_TYPES.PROVIDER_SERVICE
    ) {
      return true;
    }
    return false;
  };
  const isSelectedProvider = () => {
    if (values.providers || values.providerFilter?.length > 0) {
      return true;
    }
    return false;
  };
  const getServiceFieldLabel = () => {
    switch (values.appointmentEventSubType) {
      case APPOINTMEMT_EVENT_SUB_TYPES.SERVICE_NAME:
        return 'Select service name';
      case APPOINTMEMT_EVENT_SUB_TYPES.SERVICE_TYPE:
        return 'Select service type';
      case APPOINTMEMT_EVENT_SUB_TYPES.PROVIDER_SERVICE:
        return 'Select provider service';
      default:
        return 'Select service';
    }
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.topWrap}>
        <Icons glyph="schedule" />
        <Text className={classes.title}>Event</Text>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box className={classes.formContent}>
          <Box className={classes.section}>
            {renderLabel('Automation name')}
            <Input
              value={values.ruleName}
              name="ruleName"
              placeholder="Enter automation name"
              onChange={handleChange}
              size={inputSize.M}
              onBlur={setTouched('ruleName')}
              fullWidth
            />
            <TextError errorMsg={touched.ruleName ? errors.ruleName?.toString() : null} />
          </Box>
          <Box className={classes.section}>
            {renderLabel('Select event')}
            <Select
              value={values.name}
              name="name"
              variant={selectType.SECONDARY}
              options={BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS_DROPDOWN_VALUES}
              emptyText="Select event"
              displayEmpty
              onChange={selectEvent}
            />
            <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
          </Box>
          {(isAppointment(values.name) ||
            values.name === BUILDER_CONSTANTS.AUTOMATION_RULES.EVENTS.Values.TRANSCRIPT_READY) && (
            <Box className={classes.subSectionsWrapper}>
              <Box display="flex" flexDirection="row" gap={1}>
                <Checkbox
                  checked={values.allAppointmentEventRule}
                  onChange={() =>
                    rest.setFieldValue('allAppointmentEventRule', !values.allAppointmentEventRule)
                  }
                />{' '}
                <Text size={textLevel.XS} weight={fontWeight.BOLD}>
                  Use automation for all appointments
                </Text>
              </Box>
              {!values.allAppointmentEventRule &&
                (values.appointmentEventSubType !== APPOINTMEMT_EVENT_SUB_TYPES.PROVIDER_SERVICE ? (
                  <Box className={classes.section}>
                    {renderLabel('Select providers')}
                    <Input
                      fullWidth
                      name="providerFilter"
                      placeholder="Select providers"
                      size={inputSize.M}
                      variant={inputType.TAGS}
                      value={values.providerFilter}
                      options={
                        providers
                          .map(p => ({ ...p, label: p.fullName, value: p.providerId }))
                          .filter(p => !values.providerFilter?.find(f => f?.value === p?.value)) || []
                      }
                      getOptionLabel={option => option?.label || ''}
                      onBlur={setTouched('providerFilter')}
                      onChange={onSelectProviderFilter}
                    />
                    <TextError errorMsg={touched.providerFilter ? errors.providerFilter?.toString() : null} />
                  </Box>
                ) : (
                  <Box className={classes.section}>
                    {renderLabel('Select provider')}
                    <Input
                      fullWidth
                      name="providers"
                      placeholder="Select provider"
                      size={inputSize.M}
                      variant={inputType.AUTOCOMPLETE}
                      value={selectedProvider || {}}
                      options={providers}
                      getOptionLabel={option => option?.fullName || ''}
                      onBlur={setTouched('providers')}
                      onChange={onSelectProvider}
                    />
                    <TextError errorMsg={touched.providers ? errors.providers?.toString() : null} />
                  </Box>
                ))}
              {!values.allAppointmentEventRule && (
                <Box className={classes.section}>
                  {renderLabel('Select appointment event sub type')}
                  <Select
                    value={values.appointmentEventSubType}
                    name="appointmentEventSubType"
                    variant={selectType.SECONDARY}
                    options={appointmentEventSubTypeOptions}
                    emptyText="Select event sub type"
                    displayEmpty
                    onChange={e => {
                      void rest.setFieldValue('appointmentEventSubType', e.target.value);
                      void rest.setFieldValue('generalServiceFilter', []);
                      void rest.setFieldValue('providers', {});
                      setSelectedProvider({});
                      setServices([]);
                      setEventReferenceList([]);
                      void rest.setFieldValue('providerFilter', []);
                    }}
                  />
                  <TextError
                    errorMsg={
                      touched.appointmentEventSubType ? errors.appointmentEventSubType?.toString() : null
                    }
                  />
                </Box>
              )}
              {!values.allAppointmentEventRule && (
                <Box className={classes.section}>
                  {renderLabel(getServiceFieldLabel())}
                  <Input
                    fullWidth
                    name="generalServiceFilter"
                    placeholder="Select services"
                    size={inputSize.M}
                    variant={inputType.TAGS}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    value={values.generalServiceFilter}
                    options={
                      values.appointmentEventSubType === APPOINTMEMT_EVENT_SUB_TYPES.PROVIDER_SERVICE
                        ? eventReferenceList
                        : servicesOptions
                    }
                    getOptionLabel={option => option?.label || ''}
                    onBlur={setTouched('generalServiceFilter')}
                    onChange={onSelectService}
                  />
                  <TextError
                    errorMsg={touched.generalServiceFilter ? errors.generalServiceFilter?.toString() : null}
                  />
                </Box>
              )}
            </Box>
          )}
          {isConversation(values.name) && (
            <Box className={classes.section}>
              <Heading className={classes.label} level={headingLevel.S} weight={fontWeight.BOLD}>
                <Checkbox
                  checked={values.iterateAllVersions}
                  onChange={() => rest.setFieldValue('iterateAllVersions', !values.iterateAllVersions)}
                />{' '}
                Iterate all versions
              </Heading>
            </Box>
          )}
          {providerServicesLoading ? (
            <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            </Box>
          ) : eventReferenceList.length > 0 ? (
            <>
              {showReferenceField() && (
                <Box className={classes.section}>
                  {renderLabel('Select reference')}
                  <Input
                    fullWidth
                    name="reference"
                    placeholder="Select reference"
                    size={inputSize.M}
                    variant={inputType.AUTOCOMPLETE}
                    value={selectedReference || {}}
                    options={eventReferenceList}
                    getOptionLabel={option => option?.label || ''}
                    onBlur={setTouched('reference')}
                    onChange={onSelectReference}
                  />
                  <TextError errorMsg={touched.reference ? errors.reference?.toString() : null} />
                </Box>
              )}
            </>
          ) : (
            isAppointment(values.name) &&
            showReferenceField() &&
            isSelectedProvider() && (
              <TextError errorMsg="This provider has no values, please choose another provider" />
            )
          )}

          {isPlan(values.name) && (
            <Box className={classes.section}>
              <Box className={classes.section}>
                {renderLabel('Select Plan Item Type')}
                <Select
                  value={
                    isEditMode
                      ? values.selectedType?.label ?? eventdata?.referenceParent
                      : values.selectedType?.label
                  }
                  name="selectedType"
                  variant={selectType.SECONDARY}
                  emptyText="Select type"
                  displayEmpty
                  options={typeSelects}
                  onChange={onSelectScheduledType}
                />
                <TextError errorMsg={touched.selectedType ? errors.selectedType?.toString() : null} />
              </Box>

              {values.references && (
                <Box className={classes.section}>
                  {renderLabel('References')}
                  <Input
                    fullWidth
                    name="references"
                    placeholder="References"
                    size={inputSize.M}
                    variant={inputType.TAGS}
                    value={values.references || []}
                    options={actionList}
                    getOptionLabel={option => option?.label || ''}
                    onBlur={setTouched('references')}
                    onChange={onSelectReferences}
                  />
                  <TextError errorMsg={touched.references ? errors.references?.toString() : null} />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Continue</Button>
        </Box>
      </form>
    </Box>
  );
};

export { AutomationEvent };
