import { ReactNode, FC, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import cx from 'clsx';

import { Icons } from '@confidant-health/lib/icons';
import { fontWeight, Text } from '@confidant-health/lib/ui/atoms/typography';
import { colors } from '@confidant-health/lib/colors';

import { useStyles } from './RelatedElements.styles';

type Props = {
  label: string;
  children: ReactNode;
};

const RelatedElementsSection: FC<Props> = ({ label, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const classes = useStyles({ collapsed });

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box className={cx(classes.root)}>
      <Box
        className={cx(classes.header, 'collapsableHeader')}
        onClick={() => {
          toggleCollapsed();
        }}
      >
        <Box display="flex" alignItems="center">
          <Text className={classes.label} weight={fontWeight.SEMI_BOLD}>
            {label}
          </Text>
          <Icons
            className={cx(classes.chevronIcon, 'collapsable-icon')}
            glyph="chevron-down"
            color={colors.neutral500}
          />
        </Box>
      </Box>
      <Collapse in={collapsed}>
        <Box className={classes.content}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export { RelatedElementsSection };
