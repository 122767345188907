import dayjs from 'dayjs';
import { tz } from 'moment-timezone';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
// dayjs.tz.setDefault('America/New_York');

export const getUserTimeZone = (): string => {
  return tz.guess();
};

export const numToTimeString = (mins: number) => {
  return dayjs(`2022-01-01T${Math.floor(mins / 100)}:${mins % 100}:00`).format('hh:mm a');
};

export const numToTime = (mins: number) => {
  return mins === 0 || mins
    ? dayjs(`2022-01-01T${Math.floor(mins / 100)}:${mins % 100}:00`).format('HH:mm')
    : '';
};

export const timeToNum = (str: string) => {
  return Number(str.split(':').join(''));
};

export const addTime = (num: number, addition: number) => {
  const mins = Math.floor(num / 100) * 60 + (num % 100) + addition;

  return Math.floor(mins / 60) * 100 + (mins % 60);
};

export const getTimeDifference = (date1: Date, date2: Date) => {
  const differenceInTime = Math.abs(date2.getTime() - date1.getTime());
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  const differenceInHours = Math.ceil(differenceInTime / (1000 * 3600));
  return differenceInDays > 1
    ? `${differenceInDays} Days ago`
    : differenceInHours
    ? `${differenceInHours}h ago`
    : '-';
};

// This function returns the current local datetime in UTC
// but without the GMT string to restrict the Date object to convert it back to local datetime
export const getLocalDateAsUTC = () => {
  const localDate = new Date();
  const localUTCString = localDate.toUTCString().replace('GMT', '');
  return new Date(localUTCString);
};

export default dayjs;
