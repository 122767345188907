import { FC, useEffect, useState } from 'react';
import { Box, ButtonGroup, IconButton } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// components
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';

import { BaseLayout } from 'layouts/base';
import { getNotifications } from 'redux/modules/notification/selectors';
import { notificationActionCreators } from 'redux/modules/notification/actions';
import { NotificationType } from 'redux/modules/notification/types';

import { NotificationTabs } from './Notifications.mock';
import { NotificationItem } from './components/notification-item';
import { useStyles } from './Notifications.styles';
import PriorityEmpty from '../member-detail/components/PriorityEmpty';

const Notifications: FC = () => {
  const classes = useStyles();
  const { isLoading, notifications, currentPage, totalPages } = useSelector(getNotifications);
  const [notificationsFiltered, setNotificationsFiltered] = useState(notifications);
  const [selectedType, setSelectedType] = useState<string>('all');
  const dispatch = useDispatch();

  const onChangeType = (type: string) => () => {
    setSelectedType(type);
  };

  const onLoadMoreClick = () => {
    if (!isLoading) {
      dispatch(
        notificationActionCreators.fetchNotifications({
          pageNumber: +currentPage + 1,
          pageSize: 10,
          isLoadMore: true,
        }),
      );
    }
  };

  useEffect(() => {
    let newList = [];
    switch (selectedType) {
      case NotificationTabs[1].type:
        newList = notifications.filter(item =>
          [NotificationType.SCHEDULED_APPOINTMENT, NotificationType.COMPLETED_APPOINTMENT].includes(
            item.activityType,
          ),
        );
        break;
      case NotificationTabs[2].type:
        newList = notifications.filter(item => item.activityType === NotificationType.READ_EDUCATION);
        break;
      case NotificationTabs[3].type:
        newList = notifications.filter(item => item.activityType === NotificationType.COMPLETED_CONVERSATION);
        break;
      default:
        newList = notifications;
        break;
    }
    setNotificationsFiltered(newList);
  }, [selectedType, notifications]);

  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
            Notifications
          </Heading>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {NotificationTabs.map(({ type, label }) => (
              <Button
                key={type}
                className={clsx(classes.btnGroupIcon, {
                  [classes.btnGroupIconActive]: type === selectedType,
                })}
                onClick={onChangeType(type)}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box gap="16px" display="flex" flexDirection="column">
          {isLoading && notifications.length === 0 ? (
            <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            </Box>
          ) : (
            <>
              {notificationsFiltered.length ? (
                notificationsFiltered.map((item, index) => <NotificationItem key={index} item={item} />)
              ) : (
                <PriorityEmpty
                  title="No more Notifications."
                  description="It's possible they are hiding. Click Load More to see if you uncover any old ones."
                  glyph="dog-14-treat"
                  className={classes.noNotification}
                  glyphOrientation="portrait"
                />
              )}
            </>
          )}
          {notifications.length > 0 && currentPage < totalPages && (
            <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
              <IconButton onClick={onLoadMoreClick} className={classes.loadMoreBtn}>
                {isLoading ? 'Loading' : 'Load more'}
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { Notifications };
