export enum HeadingLevel {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  SUB = 'SUB',
}

export enum HeadingFont {
  PRIMARY = 'Manrope',
  SECONDARY = 'Source Serif Pro',
}

export enum HeadingWeight {
  LIGHT = '300',
  REGULAR = '400',
  MEDIUM = '500',
  SEMI_BOLD = '600',
  BOLD = '700',
  EXTRA_BOLD = '800',
}
