import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'redux/modules/auth/selectors';
import clsx from 'clsx';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Box, List, ListItem } from '@mui/material';
import { useStyles } from './index.styles';
import dayjs from '../../../../../utils/dayjs';
import { addDctComment, getDctComments } from '../../../../../services/conversation/conversation.service';
import { getProfile } from '../../../../../redux/modules/profile/selectors';
import { CREATE_TODO } from '../../../../../redux/modules/conversation/actions';
import { TodoDialog } from '../../../chats/TodoDialog';

type Props = {
  cbId: string;
  contextId: string;
  memberId: string;
  appointmentId?: string;
  evaluationId?: string;
  onCommentCount: (count: number) => void;
};

const sx = {
  subText: {
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.neutral600,
  },
  comment: {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.neutral800,
  },
};

const Comments: FC<Props> = ({ cbId, contextId, memberId, appointmentId, evaluationId, onCommentCount }) => {
  const classes = useStyles();
  const { meta, isAdmin } = useSelector(getAuth);
  const dispatch = useDispatch();
  const [comment, setComment] = useState(null);
  const [commentMessage, setCommentMessage] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { providers } = useSelector(getProfile);
  const [userList, setUserList] = useState(providers);
  const [showUserList, setShowUserList] = useState(false);
  const [userTagClicked, setUserTagClicked] = useState(false);
  const [cursorWordIndex, setCursorWordIndex] = useState(0);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showTodoDialog, setShowTodoDialog] = useState(false);
  const [toDoIds, setToDoIds] = useState(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = () => {
    getDctComments({ contextId, cbId })
      .then(response => {
        setComments(response.data?.comments);
        onCommentCount(response.data?.commentsCount ?? 0);
        setLoading(false);
      })
      .catch(e => {
        console.warn(e);
        setComments([]);
        setLoading(false);
      });
  };

  const findWordIndex = (sentence, cursorIndex) => {
    const words = sentence.split(' ');
    let charCount = 0;
    for (let i = 0; i < words.length; i++) {
      charCount += Number(words[i].length) + 1;
      if (charCount > cursorIndex) {
        return i;
      }
    }
    return words.length;
  };

  const convertToLinks = text => {
    if (!text) return text;
    const urlRegex =
      /((?:https?:\/\/)?(?:www\.[^\s]+)|(?:www\.[^\s]+)|[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})[^\s]*)/g;
    const parts = text.split(' ');
    const convertedParts = parts.map((part, index) => {
      if (part.match(urlRegex)) {
        const url = part.startsWith('https') || part.startsWith('http') ? part : `https://${part}`;
        return (
          <span key={index}>
            <a href={url} target="_blank" className={classes.link} rel="noreferrer">
              {part}
            </a>{' '}
          </span>
        );
      }
      return <span key={index}>{`${part} `}</span>;
    });
    return convertedParts;
  };

  const highlightMentionedUsers = sentence => {
    const words = sentence?.split(' ');
    const elements = [];
    words?.forEach(word => {
      if (word.startsWith('@')) {
        elements.push(
          <span key={word} style={{ color: colors.primary600 }}>
            {word}
          </span>,
        );
      } else {
        elements.push(convertToLinks(word));
      }
      elements.push(' ');
    });
    return <>{elements}</>;
  };

  const onChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
    const text = e.target.value;
    const cursorIndex = e.target.selectionStart;
    const wordIndex = findWordIndex(text, cursorIndex);
    const lastWord = text.split(' ');
    const usernames = text
      .split(' ')
      .filter(str => str.startsWith('@'))
      .map(str => str.replace('@', '').replaceAll('_', ' '));
    const newSelectedUser = selectedUser.filter(item => usernames.includes(item.fullName));
    setSelectedUser(newSelectedUser);
    setCursorWordIndex(wordIndex);
    if (lastWord[wordIndex].startsWith('@')) {
      const matchedNames = providers?.filter(
        user =>
          (user.firstName?.toLowerCase().includes(lastWord[wordIndex].slice(1).toLowerCase()) ||
            user.lastName?.toLowerCase().includes(lastWord[wordIndex].slice(1).toLowerCase()) ||
            user.fullName?.toLowerCase().includes(lastWord[wordIndex].slice(1).toLowerCase())) &&
          user.id !== meta.userId &&
          !newSelectedUser.some(existingUser => existingUser.id === user.id),
      );
      setUserList(matchedNames);
      setShowUserList(true);
    } else {
      setUserList(providers);
      setShowUserList(false);
    }
    if (comment?.length < 2) {
      setSelectedUser([]);
    }
  };
  const handleKeyDown = e => {
    if (userList.length === 0) return;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : userList.length - 1));
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prevIndex => (prevIndex < userList.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const newText = comment.split(' ');
      if (newText[cursorWordIndex].startsWith('@')) {
        const userSelected = userList[focusedIndex];
        const selectedPIDs = selectedUser?.map(provider => provider.providerId);
        if (userSelected === undefined) {
          // void handleSubmit();
          setShowUserList(false);
          setFocusedIndex(-1);
        } else if (!selectedPIDs.includes(userSelected?.providerId)) {
          if (!userTagClicked) {
            newText[cursorWordIndex] = `@${userSelected?.fullName.replaceAll(' ', '_')} `;
            const modifiedText = newText.join(' ');
            setComment(modifiedText);
            setSelectedUser([...selectedUser, userSelected]);
          } else {
            // void handleSubmit();
            setShowUserList(false);
            setFocusedIndex(-1);
          }
          setUserTagClicked(false);
          setShowUserList(false);
          setFocusedIndex(-1);
          inputRef.current.focus();
        } else if (e.key === 'Enter' && !e.shiftKey) {
          // void handleSubmit();
          setShowUserList(false);
          setFocusedIndex(-1);
        }
      } else if (e.key === 'Enter' && !e.shiftKey) {
        // void handleSubmit();
        setShowUserList(false);
        setFocusedIndex(-1);
      }
    }
  };

  const closeToDoDialog = () => {
    setShowTodoDialog(false);
    setToDoIds(null);
  };

  const handleCommentClick = () => {
    setLoading(true);
    setComment(null);

    const payload = { text: comment, senderId: meta?.userId };

    addDctComment(payload, { contextId, cbId })
      .then(response => {
        if (response.status === 200) {
          fetchComments();
        } else console.log('Error : ', response);
      })
      .catch(err => console.log('Error adding comment : ', err));

    const uniqueProviders = selectedUser?.filter(
      (obj, index) => selectedUser?.findIndex(item => item.providerId === obj.providerId) === index,
    );
    let mentionedProvidersIds = [];
    if (uniqueProviders?.length !== 0) {
      mentionedProvidersIds = uniqueProviders?.map(provider => provider.providerId);
    }

    if (mentionedProvidersIds !== undefined && mentionedProvidersIds?.length !== 0) {
      const bodyRequest = {
        message: comment,
        recipients: mentionedProvidersIds,
        createdBy: meta.userId,
        memberId,
        contentIdentifier: cbId,
        channelId: null,
        status: false,
        type: 'EVALUATION',
        notifyBySms: false,
        dueDate: '',
        contextId,
        appointmentId,
        evaluationId,
      };
      setCommentMessage(comment);
      dispatch({
        type: CREATE_TODO,
        payload: {
          message: bodyRequest,
          onSuccess: ids => {
            setToDoIds(ids);
            setShowTodoDialog(true);
          },
        },
      });
    }
  };

  const handleUserSelect = user => {
    const newText = comment.split(' ');
    const selectedPIDs = selectedUser?.map(provider => provider.providerId);
    if (!selectedPIDs.includes(user.providerId)) {
      newText[cursorWordIndex] = `@${user.fullName.replaceAll(' ', '_')} `;
      const modifiedText = newText.join(' ');
      setComment(modifiedText);
      setSelectedUser([...selectedUser, user]);
      setUserTagClicked(true);
      setShowUserList(false);
      inputRef.current.focus();
    }
  };

  const renderCommentInput = () => (
    <Box display="flex" flexDirection="column" position="relative">
      {showTodoDialog && (
        <Box sx={{ position: 'absolute', top: '-162px' }}>
          <TodoDialog
            type="Parent"
            msg={commentMessage}
            toDoIds={toDoIds}
            currentUserId={meta.userId}
            isEvaluation
            closeToDoDialog={closeToDoDialog}
          />
        </Box>
      )}
      {showUserList && (
        <>
          {userList.length > 0 && (
            <List
              className={clsx(
                classes.mentionUserList,
                userList.length === 1
                  ? classes.oneUser
                  : userList.length === 2
                  ? classes.twoUser
                  : userList.length === 3
                  ? classes.threeUser
                  : classes.allUser,
              )}
            >
              {userList.map((user, index) => (
                <ListItem
                  key={user.id}
                  className={clsx(classes.mentionUserListData, index === focusedIndex ? classes.focused : '')}
                  onMouseEnter={() => setFocusedIndex(index)}
                  onMouseLeave={() => setFocusedIndex(-1)}
                  button
                  onClick={() => handleUserSelect(user)}
                >
                  <ProfileInfo
                    type="provider"
                    photo={user?.profileImage}
                    role={user?.designation}
                    fullName={user?.fullName}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
      <Box display="flex" paddingTop={1.5} gap={2}>
        <Textarea
          disabled={loading}
          placeholder="Enter comment"
          value={comment !== null ? comment : ''}
          onChange={onChangeComment}
          onKeyDown={handleKeyDown}
          className={classes.commentInput}
          minRows={1}
        />
        <Button
          disabled={!comment}
          variant={btnType.PRIMARY}
          className={classes.btn}
          onClick={handleCommentClick}
        >
          Comment
        </Button>
      </Box>
    </Box>
  );

  const renderComment = (response, index: number) => {
    const self = response?.senderId === meta?.userId;

    return (
      <Box key={index} display="flex" gap={2.5}>
        <Box sx={{ width: '12rem' }}>
          <ProfileInfo
            type="provider"
            role={!self && response?.senderDesignation}
            photo={response?.senderProfile}
            fullName={self ? 'You' : response?.senderFullName}
            profilePhotoName={response?.senderFullName}
            showProfilePhotoName
          />
        </Box>
        <Box flex={1} display="flex" flexDirection="column" gap={0.5}>
          <Box sx={sx.subText}>{dayjs(response.date).format('MM/DD/YYYY')}</Box>
          <Box sx={sx.comment}>{highlightMentionedUsers(response.text)}</Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.section}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <Box>
          {comments.length > 0 ? (
            <Box className={classes.section} display="flex" flexDirection="column" gap={2.5}>
              {comments.map((response, index) => renderComment(response, index))}
            </Box>
          ) : (
            <Box
              className={classes.section}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={1}
            >
              <Icons className={classes.noCommentsIcon} glyph="chat-square" color="currentColor" />
              <Box sx={sx.subText}>No comments yet</Box>
            </Box>
          )}
        </Box>
      )}
      <div className={classes.divider} />
      {!isAdmin && renderCommentInput()}
    </>
  );
};
export { Comments };
