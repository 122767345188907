import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import clsx from 'clsx';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { selectDctState } from 'redux/modules/conversation/selectors';

import { IDCT1 } from 'redux/modules/conversation/types';

import { useStyles } from './FlowAdd.styles';

type Props = {
  isOpen: boolean;
  // dctId: string;
  onClose: () => void;
  onSave: (dctIds: string[]) => void;
};

const AddDctDrawer: FC<Props> = ({ isOpen, onClose, onSave }) => {
  const classes = useStyles();
  const { dcts } = useSelector(selectDctState);
  const [filteredDcts, setFilteredDcts] = useState<IDCT1[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [selectedDcts, setSelectedDcts] = useState<string[]>([]);

  const handleSearchKeyChange = (keyword: string) => {
    setSearchKey(keyword);
    setFilteredDcts(dcts.filter(dct => dct.name.toLowerCase().includes(keyword.toLowerCase())));
  };

  const toggleDCTSelection = (dct: IDCT1) => {
    if (selectedDcts.includes(dct.dctId)) {
      setSelectedDcts(selectedDcts.filter((dctId: string) => dct.dctId !== dctId));
    } else {
      setSelectedDcts([...selectedDcts, dct.dctId]);
    }
  };

  useEffect(() => {
    setFilteredDcts(dcts);
  }, [dcts]);

  useEffect(() => {
    if (isOpen) {
      setSelectedDcts([]);
    }
  }, [isOpen]);

  const handleAddDct = () => {
    onSave(selectedDcts);
    setSearchKey('');
  };
  const renderDCT = (dct: IDCT1) => {
    const { dctId, name, scorable } = dct;
    const isSelected = selectedDcts.includes(dctId);
    return (
      <Box
        key={dctId}
        className={clsx(classes.dct, { [classes.selected]: isSelected })}
        onClick={() => toggleDCTSelection(dct)}
      >
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Text level={textLevel.S} weight={fontWeight.BOLD}>
            {name}
          </Text>
          <Text level={textLevel.S} weight={fontWeight.MEDIUM} style={{ color: '#4C5965' }}>
            {scorable ? 'Scorable' : 'Not scorable'}
          </Text>
        </Box>
        {isSelected && <Icons glyph="checkmark" color={colors.primary500} />}
      </Box>
    );
  };

  return (
    <Drawer
      open={isOpen}
      variant={drawerType.FORM}
      className={classes.drawer2}
      onClose={onClose}
      header={
        <div className={classes.header2}>
          <IconButton
            icon="chevron-left"
            variant={btnType.OUTLINE}
            onClick={onClose}
            className={classes.backBtn}
          />
          <Heading level={headingLevel.M} className={classes.headerTitle} weight={fontWeight.BOLD}>
            Add DCT
          </Heading>
        </div>
      }
      footer={
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={selectedDcts.length === 0} onClick={handleAddDct}>
            {selectedDcts.length === 1 ? 'Add DCT' : 'Add DCTs'}
          </Button>
        </Box>
      }
    >
      <Box>
        <Box className={classes.searchBox}>
          <Input
            fullWidth
            className={classes.searchInput}
            variant={inputType.SEARCH}
            placeholder="Search"
            value={searchKey}
            onChange={handleSearchKeyChange}
          />
        </Box>
        <Box className="list-item">
          {filteredDcts.length > 0 ? (
            filteredDcts?.map(renderDCT)
          ) : (
            <Box style={{ padding: '16px 24px' }}>
              <Text level={textLevel.M} weight={fontWeight.MEDIUM}>
                No DCTs available
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export { AddDctDrawer };
