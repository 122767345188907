import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/material';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { UploadFile } from '@confidant-health/lib/ui/templates/upload-file';
import UploadFileProgress from 'pages/provider/profile/components/upload-flie-progress';

import { IPayer } from 'redux/modules/state/types';

// schema
import { PayerSchema } from './AddPayerDrawer.schema';

// styles
import { useStyles } from './AddPayerDrawer.styles';

interface IAddPayerProps {
  isOpen: boolean;
  selectedPayer: IPayer;
  onSubmit: (payload: IPayer, isAvatarUpdated: boolean) => void;
  onClose: () => void;
}

const AddPayerDrawer: FC<IAddPayerProps> = ({ isOpen, selectedPayer, onSubmit, onClose }) => {
  const classes = useStyles();
  const [imageFile, setImageFile] = useState(null);
  const [imageFileURL, setImageFileURL] = useState('');
  const [imageFileError] = useState('');
  const [isImageUpdated, setIsImageUpdated] = useState(false);

  const defaultValues = {
    name: selectedPayer?.name || '',
    code: selectedPayer?.code || '',
    isCreditCardOptional: selectedPayer?.isCreditCardOptional || false,
    isActive: selectedPayer?.isActive || false,
    takeDeposit: selectedPayer?.takeDeposit || false,
    depositAmount: selectedPayer?.takeDeposit ? selectedPayer?.depositAmount : 0,
    candidPayerId: selectedPayer?.candidPayerId ?? '',
    candidPayerName: selectedPayer?.candidPayerName ?? '',
  };

  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: PayerSchema,
    onSubmit: (payload: IPayer) => {
      onSubmit(
        {
          ...payload,
          depositAmount: selectedPayer?.takeDeposit ? payload?.depositAmount : 0,
          image: isImageUpdated ? imageFileURL : payload?.image,
        },
        isImageUpdated,
      );
    },
  });

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  /**
   * @Name useEffect
   * @description This method is used when we get avatar URl from update flow
   */
  useEffect(() => {
    if (selectedPayer?.image) {
      setImageFile(selectedPayer.image);
    } else {
      setImageFile(null);
    }
  }, [selectedPayer]);

  /**
   * @Name onAcceptedFiles
   * @description This method is used when we select/accept any file
   */
  const onAcceptedFiles = (files: File[]) => {
    const file = files[0];
    setImageFile(file);
    setIsImageUpdated(true);
  };
  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>{selectedPayer ? 'Edit' : 'Add'} Payer</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Box className={classes.section}>
              {renderLabel('Payer Name')}
              <Input
                fullWidth
                name="name"
                value={values.name}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('name')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.name ? errors.name : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Payer Code')}
              <Input
                fullWidth
                name="code"
                value={values.code}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('code')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.code ? errors.code : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Candid Payer Id')}
              <Input
                fullWidth
                name="candidPayerId"
                value={values.candidPayerId}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('candidPayerId')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.candidPayerId ? errors.candidPayerId : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Candid Payer Name')}
              <Input
                fullWidth
                name="candidPayerName"
                value={values.candidPayerName}
                placeholder=""
                size={inputSize.M}
                onBlur={setTouched('candidPayerName')}
                onChange={handleChange}
              />
              <TextError errorMsg={touched.candidPayerName ? errors.candidPayerName : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Take Deposit')}
              <FormControlLabel
                control={<Checkbox />}
                checked={values?.takeDeposit}
                name="takeDeposit"
                onChange={() => {
                  void setFieldValue('takeDeposit', !values?.takeDeposit);
                }}
                label="Take Deposit"
              />
              <TextError errorMsg={touched.takeDeposit ? errors.takeDeposit : null} />
            </Box>
            {values?.takeDeposit && (
              <Box className={classes.section}>
                {renderLabel('Deposit Amount')}
                <Input
                  fullWidth
                  name="depositAmount"
                  value={values.depositAmount}
                  placeholder=""
                  size={inputSize.M}
                  onBlur={setTouched('depositAmount')}
                  onChange={handleChange}
                />
                <TextError errorMsg={touched.depositAmount ? errors.depositAmount : null} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Status')}
              <FormControlLabel
                control={<Checkbox />}
                checked={values.isActive}
                name="isActive"
                onChange={() => {
                  void setFieldValue('isActive', !values.isActive);
                }}
                label="Active"
              />
              <TextError errorMsg={touched.isActive ? errors.isActive : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Credit Card Details')}
              <FormControlLabel
                control={<Checkbox />}
                checked={values.isCreditCardOptional}
                name="isCreditCardOptional"
                onChange={() => {
                  void setFieldValue('isCreditCardOptional', !values.isCreditCardOptional);
                }}
                label="Credit Card Optional"
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Image')}
              {imageFile ? (
                <UploadFileProgress
                  file={imageFile}
                  fileName={imageFile?.name || 'image.png'}
                  fileSize={imageFile?.size || 1024}
                  s3Folder="chatBot/"
                  onUploaded={url => {
                    setImageFileURL(url);
                  }}
                  onRemove={() => {
                    setImageFile(null);
                  }}
                />
              ) : (
                <UploadFile
                  onAcceptedFiles={onAcceptedFiles}
                  description="PNG, JPG, GIF up to 5MB"
                  fileType="image/*"
                />
              )}
              {!imageFile && <TextError errorMsg={imageFileError} />}
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save</Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export { AddPayerDrawer };
