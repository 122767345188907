import { useEffect, useState } from 'react';
import ApexChart from 'react-apexcharts';
import { Box } from '@mui/material';

import { Heading, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';

import { AppointmentsFilterDates } from 'constants/CommonConstants';
import { chartOptions } from './AssignmentsCard.utils';
import { useStyles } from './AssignmentsCard.styles';

export type IAssignmentCardProps = {
  tagReport: any[];
};

const AssignmentsCard: React.FC<IAssignmentCardProps> = ({ tagReport }) => {
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState(AppointmentsFilterDates[2]);
  const [chartSeries, setChartSeries] = useState([]);

  const times = AppointmentsFilterDates.map(time => ({
    ...time,
    onClick: () => setSelectedTime(time),
  }));

  /**
   * @Name populatedChartSeries
   * @description This method is used to format list according to UI
   */
  // const populatedChartSeries = (listToBeFormatted: any[]) => {
  //   const dateList = [];
  //   listToBeFormatted?.forEach(element => {
  //     dateList.push(element?.date);
  //   });
  //   return dateList;
  // };

  /**
   * @function getChartYAxisSeries
   * @description This method is used to create Y-series number range
   */
  const getChartYAxisSeries = (YAxisList: any[]) => {
    const maxNumber = Math.max(...YAxisList?.map(YItem => YItem?.count));
    return maxNumber > 0 ? Array.from(Array(0).keys()) : [];
  };

  /**
   * @function useEffect
   * @description This method is used to create chartSeries Data
   */
  useEffect(() => {
    const chartSeriesAccordingToChart = [
      {
        name: 'Tag assigned',
        data: getChartYAxisSeries(tagReport),
      },
    ];
    setChartSeries(chartSeriesAccordingToChart);
  }, []);

  return (
    <Box className={classes.card}>
      <Box className={classes.head}>
        <Heading className={classes.title}>Assignments chart</Heading>
        <Box className={classes.headRight}>
          <Menu
            icon="chevron-down"
            items={times}
            itemsWrapperClassName={classes.menuLabel}
            className={classes.menu}
          >
            <Text className={classes.menuLabel} level={textLevel.S}>
              {selectedTime.label}
            </Text>
          </Menu>
        </Box>
      </Box>
      <Box className={classes.chart}>
        <ApexChart options={chartOptions} series={chartSeries} type="line" height={330} />
        <Box className={classes.chartLegend}>
          <Box className={classes.circle} />
          <Text className={classes.legendText}>{chartSeries[0]?.name}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export { AssignmentsCard };
