import { colors } from '@confidant-health/lib/colors';
import { IGlyphs } from '@confidant-health/lib/icons';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IconButton, iconBtnPosition, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Box, Grid } from '@mui/material';
import { ReactNode } from 'react';

type CardProps = {
  label: string;
  badge?: string;
  actions?: Array<{
    label: string;
    icon?: IGlyphs['glyph'];
    iconPosition?: iconBtnPosition;
    onClick: () => void;
  }>;
  children: ReactNode;
};

const Card = ({ label, actions = [], badge, children }: CardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
        borderRadius: 2,
        background: colors.white,
      }}
    >
      <Box
        sx={{
          pt: 2.5,
          pr: 2,
          pb: 2.5,
          pl: 3,
          borderBottom: `1px solid ${colors.neutral100}`,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={7}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Box sx={{ fontSize: 18, fontWeight: 600, lineHeight: '24px', color: colors.neutral900 }}>
                {label}
              </Box>
              {badge && (
                <Box
                  sx={{
                    height: 24,
                    '& > div': {
                      height: '100%',
                    },
                  }}
                >
                  <Badge variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE}>
                    {badge}
                  </Badge>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={5} display="flex" justifyContent="flex-end">
            {actions.length > 0 && (
              <Box sx={{ display: 'flex', gap: 3 }}>
                {actions.map((action, index) => (
                  <Box key={index} sx={{ svg: { marginLeft: 1 }, '& button': { fontSize: 14 } }}>
                    <IconButton
                      onClick={action.onClick}
                      icon={action.icon || 'arrow-right'}
                      iconPosition={action.iconPosition || iconBtnPosition.RIGHT}
                      variant={iconBtnType.TEXT}
                    >
                      {action.label}
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {children}
    </Box>
  );
};

export default Card;
