import { Box } from '@mui/material';
import { FC, memo } from 'react';
import Slider from 'react-slick';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import { HeadingFont } from 'components/v2/Heading/index.types';

import { useStyles } from './index.styles';

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 2000,
};

const carouselInfo = [
  {
    emoji: '🤝',
    title: 'Collaborative',
    description:
      'The best way to help people is by putting our brains together. We encourage and celebrate collaboration across disciplines and teams.',
  },
  {
    emoji: '💖',
    title: 'Compassionate',
    description: `We are here to help people. That is our #1 priority and we're in it together. That means helping each other thrive as well as our clients.`,
  },
  {
    emoji: '🥇',
    title: 'Honorable',
    description: `We follow our moral compass and always come back to integrity. Our mission will always be more important than money.`,
  },
  {
    emoji: '🏆',
    title: 'Outcome Oriented',
    description: `We are here to help our clients achieve the goals they set for themselves using a combination of the best clinical tools, resources, and technology.`,
  },
  {
    emoji: '🧠',
    title: 'Open Minded',
    description: `We're consciously looking for the future of treatment and technology. We strive to identify and evaluate "what's next" and aren't stuck in "what's now".`,
  },
  {
    emoji: '📚',
    title: 'Principled',
    description: `We are here to stand for the future of the healthcare experience. We believe in transparency, patient centered care, and provider decision making based on efficacy, not billing.`,
  },
];

const VSlider: FC = memo(() => {
  const classes = useStyles();

  return (
    <Slider {...settings}>
      {carouselInfo.map((info, index) => (
        <Box key={index} className={classes.card}>
          <Box fontWeight={400} fontFamily={HeadingFont.SECONDARY} fontSize={40} lineHeight="48px">
            {info.emoji}
          </Box>
          <Box className={classes.flex}>
            <Typography {...typography.body.m.semibold}>{info.title}</Typography>
            <Typography {...typography.body.m.medium}>{info.description}</Typography>
          </Box>
        </Box>
      ))}
    </Slider>
  );
});

VSlider.displayName = 'VSlider';

export default VSlider;
